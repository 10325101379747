import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';
import { TangiStepper } from '_components/TangiLibrary/TangiStepper/TangiStepper';
import { Header } from './components/Header/Header';
import { ipAuditModalBodyStyle, ipAuditStepperViewStyle } from './styles';
import { useIpAuditContext } from './IpAudit.provider';
import { TangiToast } from '_components/TangiLibrary/TangiToast';
import QuickStartAuditBanner from 'pages/Lobby/components/QuickStartAudit/QuickStartAuditBanner';
import { IRoles } from 'utils/roles';
import { useIpAuditPopup } from './components/context/IpAuditPopup.provider';
import UnsavePopup from './components/UnsavePopup/UnsavePopup';
import { useState } from 'react';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { useMediaQuery } from '_hooks/useMediaQuery';

interface Props {
  role: IRoles;
  handleRequestAccess: () => void;
}

export const IpAudit = ({ role, handleRequestAccess }: Props) => {
  const { hasStepChanges, isOpenIpAuditPopup, setIsOpenIpAuditPopup } = useIpAuditPopup();
  const { ipAuditSteps, ipAuditToastProps } = useIpAuditContext();
  const [isOpenUnsavePopup, setIsOpenUnsavePopup] = useState(false);
  const { handleStepChange } = useHandleSteps();
  const [stepToSkip, setStepToSkip] = useState('');

  const onQuickStartAudit = (isAdmin: boolean) => {
    if (isAdmin) setIsOpenIpAuditPopup(true);
    else handleRequestAccess();
  };

  const onHide = () => {
    if (hasStepChanges) setIsOpenUnsavePopup(true);
    else setIsOpenIpAuditPopup(false);
  };

  const onDiscard = () => {
    if (stepToSkip) {
      handleStepChange(stepToSkip);
      setStepToSkip('');
    } else {
      setIsOpenIpAuditPopup(false);
    }
    setIsOpenUnsavePopup(false);
  };

  const customHandleStepSkip = (key: string) => {
    if (hasStepChanges) {
      setIsOpenUnsavePopup(true);
      setStepToSkip(key);
    } else {
      handleStepChange(key);
    }
  };
  const isSmallScreen = useMediaQuery('(max-width: 1440px)');

  return (
    <>
      <QuickStartAuditBanner role={role} onQuickStartAudit={onQuickStartAudit} />
      <TangiModal
        show={isOpenIpAuditPopup}
        header={<Header />}
        onHide={onHide}
        body={<TangiStepper steps={ipAuditSteps} slotsStyle={{ view: ipAuditStepperViewStyle }} customHandleStepSkip={customHandleStepSkip} />}
        slotsStyles={{ bodyContainer: { style: ipAuditModalBodyStyle } }}
        containerStyle={{ height: isSmallScreen ? '84vh' : '74vh' }}
        dataTestId={'ip-audit'}
      />
      <TangiToast {...ipAuditToastProps} />
      <UnsavePopup isOpenUnsavePopup={isOpenUnsavePopup} onDiscard={onDiscard} onCancel={() => setIsOpenUnsavePopup(false)} />
    </>
  );
};
