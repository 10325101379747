import { Props as TypographyProps } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';

//-------------------------------------------------Style utils-------------------------------------------------------------//

export const getStepItemTypographyStyle = (selected: boolean = false, disabled: boolean = false): Pick<TypographyProps, 'color' | 'weight'> => {
  return {
    color: disabled ? NEUTRAL_SHADES[900] : selected ? PRIMARY_SHADES[800] : undefined,
    weight: selected ? 'semibold' : 'regular',
  };
};
