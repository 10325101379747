import styled, { css } from 'styled-components';

import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

type StyledIconButtonProps = {
  variant?: string;
  isActive?: boolean;
};

export const ButtonAndTextContainer = styled.button<StyledIconButtonProps>`
  ${({ variant = BUTTON_VARIANTS.PRIMARY, isActive = false }) => css`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: none;
    padding-right: ${SPACING[2]};
    border-radius: ${SPACING[1]};
    height: ${SPACING[5]};
    ${getVariantProps(variant, isActive)};
  `}
`;

export const StyledText = styled.div`
  padding-right: 4px;
`;
export const StyledIcon = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  flex-direction: row;
`;

const getVariantProps = (variant: string, isActive: boolean) => {
  switch (variant) {
    case BUTTON_VARIANTS.TERTIARY_GREY:
      return css`
        background-color: ${isActive ? `${NEUTRAL_SHADES.WHITE}` : 'transparent'};
        .tangi-typography {
          color: ${!isActive && `${NEUTRAL_SHADES[900]}`} !important;
        }
        path,
        rect,
        svg.tangi-icon-svg path {
          fill: ${isActive ? NEUTRAL_SHADES[1100] : NEUTRAL_SHADES[900]} !important;
        }
        :disabled {
          background-color: ${isActive ? `${NEUTRAL_SHADES[100]}` : 'transparent'};
          path,
          rect,
          svg.tangi-icon-svg path {
            fill: ${NEUTRAL_SHADES[600]} !important;
          }
          .tangi-typography {
            color: ${`${NEUTRAL_SHADES[600]}`} !important;
          }
        }
        :hover:not(:disabled) {
          background-color: ${NEUTRAL_SHADES.WHITE};
          .tangi-typography {
            color: ${!isActive && `${NEUTRAL_SHADES.BLACK}`} !important;
          }
          path,
          rect,
          svg.tangi-icon-svg path {
            fill: ${!isActive && NEUTRAL_SHADES.BLACK} !important;
          }
        }
      `;
  }
};
