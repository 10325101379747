import { forwardRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';
import WidgetCard from '../WidgetCard';
import { WidgetCardProps, WidgetDataObject } from '../Widgets.types';
import WidgetTitle from '../WidgetTitle';
import { TangiTypography } from '_components/TangiLibrary';
import HorizontalBarChart from '../../Charts/HorizontalBarChart';

const BAR_COLORS = [
  PRIMARY_SHADES[1200],
  PRIMARY_SHADES[1100],
  PRIMARY_SHADES[900],
  PRIMARY_SHADES[700],
  PRIMARY_SHADES[600],
  PRIMARY_SHADES[400],
  PRIMARY_SHADES[300],
  PRIMARY_SHADES[200],
  PRIMARY_SHADES[100],
];

const EMPTY_COLORS = [NEUTRAL_SHADES[300]];

const WidgetContainer = styled.div`
  display: flex;
  padding: 0 ${SPACING[3]};
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: ${SPACING[4]};
`;

const TitleContainer = styled.div`
  position: absolute;
  right: ${SPACING[3]};
  top: ${SPACING[3]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

interface Props extends WidgetCardProps {
  data: {
    total: number;
    people: WidgetDataObject[];
  };
}

const PeopleByRole = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();
  const isEmpty = !props.data.people.length;

  const renderTitle = (total: number) => {
    return (
      <TitleContainer data-testid="people-by-role-title">
        <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]} data-testid="total-people-by-role">
          {total} {t('DASHBOARD_PAGE.WIDGETS.COMMON.TOTAL')}
        </TangiTypography>
      </TitleContainer>
    );
  };

  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.PEOPLE_BY_ROLE')} />
        <WidgetContainer>
          {renderTitle(props.data.total)}
          <HorizontalBarChart data={props.data.people} colors={isEmpty ? EMPTY_COLORS : BAR_COLORS} isEmpty={isEmpty} />
        </WidgetContainer>
        {props.children}
      </>
    </WidgetCard>
  );
});

export default PeopleByRole;
