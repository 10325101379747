import styled, { css } from 'styled-components';

import { ERROR_SHADES, NEUTRAL_SHADES, SPACING } from '../../../utils/theme';

export const disabledStyle = {
  backgroundColor: '#f2f2f2',
  borderColor: '#e6e6e6',
  color: '#999999',
};

export const TangiInputContainer = styled.div`
  input {
    &:hover:not(:focus) {
      border: ${({ isDisabled }) => !isDisabled && `1px solid ${NEUTRAL_SHADES[600]}`};
    }
  }
`;

export const InvalidFeedback = styled.div`
  ${({ isError = false }) => css`
    width: 100%;
    margin-top: ${isError && SPACING[1]};
    font-size: 80%;
    color: ${ERROR_SHADES[500]};
    box-sizing: content-box;
  `}
`;
