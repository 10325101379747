import styled from 'styled-components';
import { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';

import { ERROR_SHADES, NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const StyledFormGroup = styled(Form.Group)`
  width: 100%;
`;

export const StyledLabelContainer = styled(Form.Label)`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  margin-bottom: ${SPACING[0]};
`;

export const StyledInput = styled(Form.Control)`
  padding: ${SPACING[2.5]};

  &:hover:not(:focus):not(:disabled):not(.is-invalid) {
    border: 1px solid ${NEUTRAL_SHADES[600]};
  }

  &.is-invalid {
    background-image: none;
    padding-right: ${SPACING[2.5]};
    border: 1px solid ${ERROR_SHADES.BOOTSTRAP};
    &:focus {
      border: 1px solid ${ERROR_SHADES.BOOTSTRAP};
    }
  }
  ::placeholder {
    color: ${NEUTRAL_SHADES[800]} !important;
    font-family: 'DIN2014-Regular' !important;
  }
  &:disabled {
    background-color: ${NEUTRAL_SHADES[200]};
    border-color: ${NEUTRAL_SHADES[400]};
    color: ${NEUTRAL_SHADES[600]};
    ::placeholder {
      color: ${NEUTRAL_SHADES[600]} !important;
    }
  }
`;

export const customStarStyles: CSSProperties = {
  marginLeft: SPACING[0],
  marginBottom: SPACING[0],
};

export const ErrorContainer = styled(FlexContainer)`
  min-height: ${SPACING[3.5]};
`;
