import React from 'react';

import { PreviewAsset } from '../../_components/PreviewAsset';
import { Container } from './style';
import './PreviewAssetPage.scss';

function PreviewAssetPage() {
  return (
    <Container className="preview-asset">
      <PreviewAsset />
    </Container>
  );
}

export { PreviewAssetPage };
