import styled from 'styled-components';
import { PRIMARY_SHADES, NEUTRAL_SHADES, SPACING } from '../../utils/theme';

export const Container = styled.div`
  .form-check {
    padding: 0;
    display: flex;
    input {
      margin: 0;
    }
    label {
      margin-left: ${SPACING[3]};
    }
  }
  .error-alert {
    margin-top: ${SPACING[3]};
  }
`;

export const DuoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${SPACING[4]};
  box-sizing: border-box;
  > div {
    flex: 1;
  }
`;

export const FileDropContainer = styled.div`
  display: flex;
  background-color: ${PRIMARY_SHADES[50]};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${NEUTRAL_SHADES[600]};
  margin-top: ${SPACING[4]};
  height: 44px;
  &:last-child {
    margin-bottom: ${SPACING[4]};
  }
`;
