import { WidgetDataObject } from './components/Widgets/Widgets.types';
import { dashboardLayout, LOCAL_STORAGE_LATEST_VERSION, LOCAL_STORAGE_KEY } from './consts';

export const getLayoutLocalStorage = () => {
  try {
    const lsLayout = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lsLayout) {
      const layoutConfig = JSON.parse(lsLayout);
      if (layoutConfig.version === LOCAL_STORAGE_LATEST_VERSION) {
        return layoutConfig.value;
      }
    }
  } catch {}
  return dashboardLayout;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveLayoutToLocalStorage = (value: any) => {
  // TODO: fix any type
  localStorage.setItem(
    LOCAL_STORAGE_KEY,
    JSON.stringify({
      version: LOCAL_STORAGE_LATEST_VERSION,
      value,
    }),
  );
};

export const getTotalAssetsByDepartment = (assetsByDepartments: WidgetDataObject[]) => {
  return assetsByDepartments.reduce((sum, department) => sum + department.value, 0);
};
