import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { competitiveAnalysisActions } from 'redux-toolkit/slices/competitiveAnalysisSlice';
import { getCompetitiveAnalysisGraphsResults, getCompetitiveAnalysisTradeSecretsTable, getCompetitiveAnalysisTradeSecretsExportTable } from 'redux-toolkit/thunks/competitiveAnalysisThunks';

export const useCompetitiveAnalysis = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const initialFilter = {
    patents: [],
    years: [],
    subjects: [],
    companies: [],
    search: '',
  };
  const [filter, setFilter] = useState(initialFilter);

  const [searchValue, setSearchValue] = useState('');
  const [enteredSearchValue, setEnteredSearchValue] = useState('');

  const totalTableData = useSelector((state) => state.competitiveAnalysis.tableData.metadata?.[0]?.total);
  const pagination = useSelector((state) => state.competitiveAnalysis.pagination);

  const isFilterApplied = useMemo(() => {
    const isAnyArrayNonEmpty = Object.values(filter).some((array) => array.length > 0);
    const isSearchValueNonEmpty = searchValue !== '';
    return isAnyArrayNonEmpty || isSearchValueNonEmpty;
  }, [filter, searchValue]);

  const filtersForServer = useMemo(() => {
    const filters = {
      patents: filter.patents.map((patent) => patent.id),
      years: filter.years.map((year) => year.id),
      subjects: filter.subjects.map((subject) => subject.id),
      companies: filter.companies.map((company) => company.id),
      search: enteredSearchValue,
    };
    return filters;
  }, [enteredSearchValue, filter]);

  const handleClearSearch = () => {
    setSearchValue('');
    setEnteredSearchValue('');
    dispatch(competitiveAnalysisActions.setInitialPagination());
  };

  const handleSearch = useCallback((value) => {
    setEnteredSearchValue(value);
  }, []);

  const handleExportTable = () => {
    dispatch(getCompetitiveAnalysisTradeSecretsExportTable({ limit: totalTableData, page: 1, filters: filtersForServer }));
  };

  useEffect(() => {
    setFilter(initialFilter);
    setSearchValue('');
    setEnteredSearchValue('');
  }, [clientId]);

  useEffect(() => {
    dispatch(getCompetitiveAnalysisGraphsResults());
    dispatch(competitiveAnalysisActions.setInitialPagination());
  }, []);

  useEffect(() => {
    dispatch(getCompetitiveAnalysisTradeSecretsTable({ ...pagination, filters: filtersForServer }));
  }, [pagination]);

  useEffect(() => {
    dispatch(competitiveAnalysisActions.setPagination({ ...pagination, page: 1 }));
  }, [filtersForServer]);

  return {
    searchValue,
    filter,
    initialFilter,
    isFilterApplied,
    setFilter,
    setSearchValue,
    handleClearSearch,
    handleSearch,
    handleExportTable,
  };
};
