/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getDashboardAssetsRawData, getDashboardAccountsRawData, getDashboardRecipientsRawData } from 'redux-toolkit/thunks/dashboardThunks';
import { WidgetAccountObject, WidgetDataObject } from 'pages/ClientDashboard/components/Widgets/Widgets.types';
import { AccountRawData, AssetRawData, FILTER_BY, FilterBy, RecipientAsset, RecipientsRawData, SharedBy } from 'utils/types/dashboard/dashboard';
import { prepareDashboardAssetsData, prepareDashboardAccountsData, prepareDashboardInsightsData } from 'utils/dashboardFuncs';
import { IdAndName } from 'utils/types/types';

interface LoadersObject {
  [key: string]: boolean;
}
interface DashboardState {
  error: string;
  loaders: LoadersObject;
  metadata: {
    assetsTotal: number;
    accountsTotal: number;
  };
  activeAssetsRawData: AssetRawData[];
  allAssetsRawData: AssetRawData[];
  accountsRawData: AccountRawData[];
  assetsByAssetType: WidgetDataObject[];
  assetsByDepartments: WidgetDataObject[];
  assetsByProject: WidgetDataObject[];
  assetsByTag: WidgetDataObject[];
  assetsByBusinessValues: WidgetDataObject[];
  assetsCreatedFromXRay: number;
  assetsByThirdParty: number;
  unpublishedAssets: number;
  topAssetCreator: WidgetAccountObject[];
  contributorsByDepartments: WidgetDataObject[];
  topContributors: WidgetAccountObject[];
  departments: IdAndName[];
  assetTypes: string[];
  users: { total: number; contributors: number; employees: number; external: number };
  accountsByDepartments: WidgetDataObject[];
  accountsByRole: WidgetDataObject[];
  accountsStatus: { active: number; pending: number; disabled: number };
  mostNotifiedAsset: RecipientAsset[];
  mostSharedBy: SharedBy[];
  recipientsRawData: RecipientsRawData[];
  departmentFilter: FilterBy;
}

const initialState: DashboardState = {
  error: '',
  loaders: {
    assets: false,
    accounts: false,
    recipients: false,
  },
  metadata: {
    assetsTotal: 0,
    accountsTotal: 0,
  },
  activeAssetsRawData: [],
  allAssetsRawData: [],
  accountsRawData: [],
  assetsByAssetType: [],
  assetsByDepartments: [],
  assetsByProject: [],
  assetsByTag: [],
  assetsByBusinessValues: [],
  assetsCreatedFromXRay: 0,
  assetsByThirdParty: 0,
  unpublishedAssets: 0,
  topAssetCreator: [],
  contributorsByDepartments: [],
  topContributors: [],
  departments: [],
  assetTypes: [],
  users: { total: 0, contributors: 0, employees: 0, external: 0 },
  accountsByDepartments: [],
  accountsByRole: [],
  accountsStatus: { active: 0, pending: 0, disabled: 0 },
  mostNotifiedAsset: [],
  mostSharedBy: [],
  recipientsRawData: [],
  departmentFilter: FILTER_BY.ALL_DEPARTMENT,
};

const dashboardSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    filterDashboardDataByDepartment: (state, action: PayloadAction<FilterBy>) => {
      state.departmentFilter = action.payload;
      const { data: assetData, metadata: assetMetadata } = prepareDashboardAssetsData({ includeActiveAndAllData: false })(state.activeAssetsRawData, action.payload);
      const { data: accountData, metadata: accountMetadata } = prepareDashboardAccountsData(state.accountsRawData, action.payload);
      const { data: recipientsData } = prepareDashboardInsightsData(state.recipientsRawData, action.payload);
      state.metadata = { ...assetMetadata, ...accountMetadata };
      state.assetsByAssetType = assetData.assetsByAssetType;
      state.assetsByDepartments = assetData.assetsByDepartments;
      state.assetsByProject = assetData.assetsByProject;
      state.assetsByTag = assetData.assetsByTag;
      state.assetsByBusinessValues = assetData.assetsByBusinessValues;
      state.assetsCreatedFromXRay = assetData.assetsCreatedFromXRay;
      state.assetsByThirdParty = assetData.assetsByThirdParty;
      state.unpublishedAssets = assetData.unpublishedAssets;
      state.contributorsByDepartments = assetData.contributorsByDepartments;
      state.topAssetCreator = assetData.topAssetCreator;
      state.topContributors = assetData.topContributors;
      state.users = { total: accountMetadata.accountsTotal, contributors: assetData.contributors, external: accountData.externalUsers, employees: accountData.employees };
      state.accountsByDepartments = accountData.accountsByDepartments;
      state.accountsByRole = accountData.accountsByRole;
      state.accountsRawData = accountData.accountsRawData;
      state.accountsStatus = accountData.accountsStatus;
      state.mostNotifiedAsset = recipientsData.mostNotifiedAsset;
      state.mostSharedBy = recipientsData.mostSharedBy;
    },
  },
  extraReducers: (builder) => {
    // getDashboardAssetsRawData
    builder.addCase(getDashboardAssetsRawData.pending, (state) => {
      state.loaders.assets = true;
      state.error = '';
    });
    builder.addCase(getDashboardAssetsRawData.fulfilled, (state, action: PayloadAction<any>) => {
      state.departmentFilter = FILTER_BY.ALL_DEPARTMENT;
      state.metadata = { ...state.metadata, ...action.payload.metadata };
      state.departments = action.payload.data.departments;
      state.assetTypes = action.payload.data.assetTypes;
      state.assetsByAssetType = action.payload.data.assetsByAssetType;
      state.assetsByDepartments = action.payload.data.assetsByDepartments;
      state.assetsByProject = action.payload.data.assetsByProject;
      state.assetsByTag = action.payload.data.assetsByTag;
      state.assetsByBusinessValues = action.payload.data.assetsByBusinessValues;
      state.assetsCreatedFromXRay = action.payload.data.assetsCreatedFromXRay;
      state.assetsByThirdParty = action.payload.data.assetsByThirdParty;
      state.unpublishedAssets = action.payload.data.unpublishedAssets;
      state.topAssetCreator = action.payload.data.topAssetCreator;
      state.contributorsByDepartments = action.payload.data.contributorsByDepartments;
      state.topContributors = action.payload.data.topContributors;
      state.activeAssetsRawData = action.payload.data.activeAssetsRawData;
      state.users = { ...state.users, contributors: action.payload.data.contributors };
      state.allAssetsRawData = action.payload.data.allAssetsRawData;
      state.loaders.assets = false;
    });
    builder.addCase(getDashboardAssetsRawData.rejected, (state, action) => {
      state.loaders.assets = false;
      state.error = action?.error?.message ?? i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
    });

    // getDashboardAccountsRawData
    builder.addCase(getDashboardAccountsRawData.pending, (state) => {
      state.loaders.accounts = true;
      state.error = '';
    });
    builder.addCase(getDashboardAccountsRawData.fulfilled, (state, action: PayloadAction<any>) => {
      state.metadata = { ...state.metadata, ...action.payload.metadata };
      state.accountsByDepartments = action.payload.data.accountsByDepartments;
      state.users = { ...state.users, external: action.payload.data.externalUsers, employees: action.payload.data.employees, total: action.payload.metadata.accountsTotal };
      state.accountsByRole = action.payload.data.accountsByRole;
      state.accountsRawData = action.payload.data.accountsRawData;
      state.accountsStatus = action.payload.data.accountsStatus;
    });
    builder.addCase(getDashboardAccountsRawData.rejected, (state, action) => {
      state.loaders.accounts = false;
      state.error = action?.error?.message ?? i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
    });

    // getDashboardRecipientsRawData
    builder.addCase(getDashboardRecipientsRawData.pending, (state) => {
      state.loaders.recipients = true;
      state.error = '';
    });
    builder.addCase(getDashboardRecipientsRawData.fulfilled, (state, action: PayloadAction<any>) => {
      state.loaders.recipients = false;
      state.mostNotifiedAsset = action.payload.data.mostNotifiedAsset;
      state.mostSharedBy = action.payload.data.mostSharedBy;
      state.recipientsRawData = action.payload.data.recipientsRawData;
    });
    builder.addCase(getDashboardRecipientsRawData.rejected, (state, action) => {
      state.loaders.recipients = false;
      state.error = action?.error?.message ?? i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
    });
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
