import React from 'react';
import './styles.scss';

function Loader({ isLoading }) {
  return (
    <div>
      { isLoading ?
      <div className="col-12" id="loading">
        <div className="bookshelf_wrapper pt-5">
          <ul className="books_list">
            <li className="book_item first" />
            <li className="book_item second" />
            <li className="book_item third" />
            <li className="book_item fourth" />
            <li className="book_item fifth" />
            <li className="book_item sixth" />
          </ul>
          <div className="shelf" />
        </div>
      </div> : ''
      }
    </div>
  );
}

export { Loader };
