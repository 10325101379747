import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { CREATED_FROM } from 'utils/enums';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { formatDate } from 'utils/dateUtils';
import { FlexContainer } from 'utils/globalStyles';
import { emptyAvatar } from 'assets/icons';
import { LeanAccount, LeanClient } from 'utils/types/activityLog/asset/types';
import { ActivityAvatarContainer } from '../style';

interface Props {
  account: LeanAccount | LeanAccount[] | null;
  client: LeanClient;
}

export const Container = styled(FlexContainer)`
  flex-wrap: wrap;
  align-items: center;
`;

const ActivityAvatar = ({ account, client }: Props) => {
  const { t } = useTranslation();

  const renderMagicIcon = (createdFrom: string | undefined, createdAt: string | undefined) => {
    if (!createdFrom || !createdAt) return null;

    return (
      <Tippy
        content={<div>{t(createdFrom === CREATED_FROM.EMAIL_FINDER ? 'ASSET_PAGE.TOOLTIP.CREATED_BY_FINDER' : 'ASSET_PAGE.TOOLTIP.CREATED_BY_TRACKER', { createdAt: formatDate(createdAt) })}</div>}
      >
        <span>
          <TangiSvgIcon component="discovery" />
        </span>
      </Tippy>
    );
  };

  const renderByAsAccount = () => {
    if (Array.isArray(account)) {
      return (
        <>
          {account.map((acc, index) => (
            <ActivityAvatarContainer key={index}>
              <Avatar size={AVATAR_SIZES.XS} name={acc.displayName || acc.user?.email || ''} email={acc.user?.email || ''} />
              <TangiTypography type="subheading" weight="bold">
                {acc.displayName}
              </TangiTypography>
              {renderMagicIcon(acc.createdFrom, acc.createdAt)}
            </ActivityAvatarContainer>
          ))}
        </>
      );
    }
    return (
      <>
        <Avatar size={AVATAR_SIZES.XS} name={account?.displayName || account?.user?.email || ''} email={account?.user?.email || ''} />
        <TangiTypography type="subheading" weight="bold">
          {account?.displayName}
        </TangiTypography>
        {renderMagicIcon(account?.createdFrom, account?.createdAt)}
      </>
    );
  };

  const renderByAsClient = () => {
    return (
      <>
        <Avatar size={AVATAR_SIZES.XS} name={client.name} image={emptyAvatar} />
        <TangiTypography type="subheading" weight="bold">
          {client.name}
        </TangiTypography>
      </>
    );
  };

  return <Container data-testid="activity-avatar">{account ? renderByAsAccount() : renderByAsClient()}</Container>;
};

export default ActivityAvatar;
