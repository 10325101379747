import { headers, headersmultipart } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { Partnership, PartnershipCreationPayload, PartnershipUpdatePayload, UploadPartnershipLogo } from 'utils/types/partnership/partnership';

class PartnershipAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;

  static partnershipEndpoint = `${config.apiUrl}${config.apiVersion}/partnership`;
  static logoEndpoint = `${config.apiUrl}${config.apiVersion}/file/profile`;

  async getPartnerships(): Promise<Partnership[] | []> {
    const res = await HttpClientService.get<Partnership[]>(`${PartnershipAdapter.partnershipEndpoint}/all-partnerships`, { headers: headers() });
    return res.data ?? [];
  }
  async uploadPartnershipsPhoto({ data, partnershipId }: UploadPartnershipLogo) {
    const formData = new FormData();
    formData.append('file', data[0]);

    const res = await HttpClientService.post(`${PartnershipAdapter.logoEndpoint}`, formData, {
      headers: headersmultipart(),
      params: { partnershipId },
    });
    return res.data;
  }

  async getPartnershipById(partnershipId: string): Promise<Partnership> {
    const res = await HttpClientService.get<Partnership>(`${PartnershipAdapter.partnershipEndpoint}/${partnershipId}`, { headers: headers() });
    return res.data;
  }

  async createPartnership(partnership: PartnershipCreationPayload): Promise<Partnership> {
    const res = await HttpClientService.post<Partnership>(`${PartnershipAdapter.partnershipEndpoint}/`, partnership, { headers: headers() });
    return res.data;
  }

  async updatePartnership(partnership: PartnershipUpdatePayload): Promise<Partnership> {
    const res = await HttpClientService.put<Partnership>(`${PartnershipAdapter.partnershipEndpoint}/${partnership._id}`, partnership, { headers: headers() });
    return res.data;
  }

  async deactivatePartnership(partnershipId: string): Promise<Partnership> {
    const res = await HttpClientService.post<Partnership>(`${PartnershipAdapter.partnershipEndpoint}/deactivate/${partnershipId}`, {}, { headers: headers() });
    return res.data;
  }
}

const patentAdapter = new PartnershipAdapter();
export default patentAdapter;
