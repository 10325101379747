import { IconTypes } from '_components/TangiLibrary/TangiSvgIcon/types';
import { TradeSecretFromPatent } from 'utils/types/patent/patent';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';

export enum FOUND_INDICATION {
  FOUND = 'found',
  NOT_FOUND = 'notFound',
}

export interface ActiveBulletData {
  index: number;
  originalTexts: string[];
  isTextForBulletFound: boolean;
  indexOfTextToShow: number;
  incrementIndexOfTextToShow?: () => void;
  decrementIndexOfTextToShow?: () => void;
}

export interface Bullet {
  groupingIndex: number;
  bulletIndex: number;
  bullet: string;
}

export interface ActiveBulletLocation {
  globalIndex: number;
}

export interface PatentData {
  choice_0: null | TradeSecretFromPatent[];
}

export interface GroupingAndBulletIndex {
  groupingIndex: number;
  bulletIndex: number;
}

export interface GroupingAndBulletIndex {
  groupingIndex: number;
  bulletIndex: number;
}
export enum EXPORT_OPTIONS {
  MAPPING_ANALYSIS_EXCEL = 'mappingAnalysisExcel',
  TRADE_SECRET_ANALYSIS_PDF = 'tradeSecretAnalysisPdf',
}

export interface ExportCheckboxOption {
  id: string;
  label: string;
  checked: boolean;
  isLoading: boolean;
  icon: IconTypes;
  toastLabel: string;
  visible: boolean;
  status?: XRAY_JOB_STATUS;
}
