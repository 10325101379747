import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { ProgressBar } from '../../Charts';
import { WidgetDataObject } from '../Widgets.types';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${SPACING[3]};
`;

const StyledTagName = styled(TangiTypography)`
  min-width: 180px;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: 0px;
`;

const UsedTagItem = ({ label, value }: WidgetDataObject) => {
  return (
    <ItemContainer data-testid="used-tag-item">
      <StyledTagName>{label}</StyledTagName>
      <StyledProgressBar now={value} hideBackground={true} />
      <TangiTypography color={NEUTRAL_SHADES[700]}>{value}</TangiTypography>
    </ItemContainer>
  );
};

export default UsedTagItem;
