import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TangiToast } from '_components/TangiLibrary';
import EditTextModal from '_components/EditTextModal/EditTextModal';
import ConfirmationModal from '_components/CreateEditAsset/components/ConfirmationModal';
import CreateEditAssetModal from '_components/CreateEditAsset/CreateEditAssetModal';

import { createTag, getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { getAgreementVersions } from 'redux-toolkit/thunks/clientThunks';
import { clientSettingsActions } from '_actions';
import { RootState } from '_helpers';
import { CurrentTradeSecret, DropdownOption } from './types';
import { Option, Tag } from 'utils/types/assetMetaData/assetMetaData';
import { createPDF, generateDropdownOptions, getDepartmentsOptionsFromIds } from './components/utils';
import { IRoles, isRoleMatch } from 'utils/roles';
import { CREATED_FROM, RESULT_STATUS } from 'utils/enums';

// Props added by the HOC to the wrapped component, including the HOC-specific parameter
export interface WithAssetCreationProps {
  optionsWithCustomCallbacks: (currTradesecret: CurrentTradeSecret, index: number) => DropdownOption[];
}

// Props that the HOC itself depends on, passed as a prop
interface HOCProps {
  potentialAssetName?: string;
  clientId: string;
  currentTradeSecret: CurrentTradeSecret;
  onAssetCreationComplete: () => void;
  handleClickOnDropdownItem: (currTradesecret: CurrentTradeSecret, index: number) => void;
  createdFrom: CREATED_FROM;
}

/**
 * A Higher-Order Component (HOC) that enhances a given component with asset creation functionalities,
 * including the ability to edit and turn current trade secrets into assets, and manage the asset creation process.
 * It leverages specific props (`HOCProps`) for internal logic and injects additional functionalities (`WithAssetCreationProps`)
 * into the wrapped component.
 *
 * @param {React.ComponentType<P & WithAssetCreationProps>} WrappedComponent - The component to be enhanced by the HOC.
 *        It expects to receive both its original props (P) and additional props provided by this HOC (WithAssetCreationProps).
 * @returns {React.FC<P & HOCProps>} - A functional component that receives a combination of original props and HOC-specific props,
 *         and returns the enhanced component along with modal dialogs and toast notifications related to asset creation.
 *
 * @template P - The type of the props expected by the WrappedComponent, excluding the extra props provided by this HOC.
 *
 * Inside this HOC:
 * - It defines a series of state hooks and Redux hooks to manage asset creation, editing, confirmation modals, and toast notifications.
 * - It contains logic for creating a PDF attachment, adding tags to assets, and handling the completion of asset creation.
 * - Custom callback functions (`editAndTurnIntoAssetCB` and `turnIntoAssetCB`) are generated to provide specific functionalities
 *   for dropdown options related to asset actions.
 * - It fetches necessary metadata and manages client settings through Redux actions.
 *
 * HOC-specific props (`HOCProps`) include:
 * - `potentialAssetName`: A name identifier for the potential asset.
 * - `clientId`: Identifier for the client associated with the asset.
 * - `currentTradeSecret`: The current trade secret data being processed.
 * - `onAssetCreationComplete`: Callback function executed upon successful asset creation.
 * - `handleClickOnDropdownItem`: Function to handle actions when a dropdown item is selected.
 *
 * Additional functionalities (`WithAssetCreationProps`) injected into the WrappedComponent:
 * - `optionsWithCustomCallbacks`: Functions generated within the HOC to handle specific dropdown actions related to asset creation.
 */
function withAssetCreation<P>(WrappedComponent: React.ComponentType<P & WithAssetCreationProps>): React.ComponentType<P & HOCProps> {
  return function WithExtra(props: P & HOCProps) {
    // Here, you can use HOCProps for your internal logic
    const { potentialAssetName = '', clientId, currentTradeSecret, onAssetCreationComplete, handleClickOnDropdownItem, createdFrom, ...restProps } = props;

    const [showEditTextModal, setShowEditTextModal] = useState<boolean>(false);
    const [showCreateAsset, setShowCreateAsset] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [filesToAttach, setFilesToAttach] = useState<File[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [defaultTags, setDefaultTags] = useState<any[]>([]); //TODO: Fix any type
    const [defaultDepartments, setDefaultDepartments] = useState<Option[]>([]);

    const assetCreationToastProps = useSelector((state: RootState) => state.asset.assetCreationToastProps);
    const { activeAccount, Role } = useSelector((state: RootState) => state.authentication);
    const { tags, departments, loading: metaDataLoading } = useSelector((state: RootState) => state.assetMetaData);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Finish asset creation and close the assets modal
    const handleCloseCreateAsset = () => {
      setShowConfirmModal(false);
      setShowCreateAsset(false);
    };

    //Create a new pdf and attach it to the asset
    const createAndAttachPdf = (currTradeSecret: CurrentTradeSecret) => {
      const pdfFile = createPDF({ ...currTradeSecret, potentialAssetName });
      setFilesToAttach([pdfFile]);
    };

    // If potentialAssetName was provided - create same tag, or use existing if was previously created, and add it to asset
    const addTagToAsset = () => {
      if (potentialAssetName) {
        const xrayAssetTag = tags?.find((tag: Tag) => tag.name === potentialAssetName);
        if (!xrayAssetTag) {
          dispatch(createTag({ name: potentialAssetName, client: [clientId] }));
        } else {
          setDefaultTags([{ value: xrayAssetTag.id, label: xrayAssetTag.name }]);
        }
      }
    };

    // Perform necessary logic after edited the text of a potential trade secret
    const handleContinue = (editedText: string): void => {
      createAndAttachPdf({ text: editedText, title: currentTradeSecret.title });
      setShowEditTextModal(false);
      addTagToAsset();
      setShowCreateAsset(true);
    };

    /**
     * Generates dropdown options with custom callback functions based on the current trade secret and its index.
     *
     * The function creates two specific callback functions: `editAndTurnIntoAssetCB` and `turnIntoAssetCB`.
     * - `editAndTurnIntoAssetCB` is designed to handle editing and turning the current trade secret into an asset.
     *   It marks the current trade secret for editing and displays an edit modal.
     * - `turnIntoAssetCB` handles the process of turning the current trade secret directly into an asset without editing.
     *   It triggers tag addition, PDF creation, and marks the asset creation process to begin.
     *
     * @param {CurrentTradeSecret} currTradesecret - The current trade secret object being processed.
     * @param {number} index - The index of the current trade secret in the list.
     * @returns {DropdownOption[]} An array of dropdown options with the customized callback functions.
     */
    const optionsWithCustomCallbacks = (currTradesecret: CurrentTradeSecret, index: number): DropdownOption[] => {
      const editAndTurnIntoAssetCB = () => {
        handleClickOnDropdownItem(currTradesecret, index);
        setShowEditTextModal(true);
      };
      const turnIntoAssetCB = () => {
        handleClickOnDropdownItem(currTradesecret, index);
        addTagToAsset();
        createAndAttachPdf(currTradesecret);
        setShowCreateAsset(true);
      };
      return generateDropdownOptions(editAndTurnIntoAssetCB, turnIntoAssetCB);
    };

    /**
     * Handles the actions to be performed after the successful creation of an asset. This function:
     * - Displays a success toast message to inform the user that the asset has been successfully created.
     * - Calls `onAssetCreationComplete` to execute any additional logic after asset creation.
     * - Closes the edit text modal, if it is currently opened.
     * - Executes `handleCloseCreateAsset` to close the asset creation modal.
     */
    const handleSuccessfulCreation = () => {
      batch(() => {
        dispatch(assetsActions.setAssetCreationToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: `${t('PATENT_TOOL.TOAST.ASSET_CREATED_SUCCESSFULLY')}` }));
        onAssetCreationComplete();
      });
      if (showEditTextModal) {
        setShowEditTextModal(false);
      }
      handleCloseCreateAsset();
    };

    const renderModals = () => {
      return (
        <>
          <EditTextModal text={currentTradeSecret?.text || ''} onClose={() => setShowEditTextModal(false)} onContinue={handleContinue} isOpen={showEditTextModal} />
          <CreateEditAssetModal
            asset={{
              createdFrom,
              name: `${potentialAssetName ? `${potentialAssetName}_TS_${currentTradeSecret.title}` : `TS_${currentTradeSecret.title}`}`,
              tags: defaultTags,
              departments: defaultDepartments,
            }}
            filesToAttach={filesToAttach}
            onClose={() => setShowConfirmModal(true)}
            onCreate={handleSuccessfulCreation}
            onEdit={null}
            isPatentMode={true}
            isShow={showCreateAsset}
            onHide={() => setShowConfirmModal(true)}
          />
          <ConfirmationModal isShow={showConfirmModal} onHide={() => setShowConfirmModal(true)} handleConfirmation={handleCloseCreateAsset} onCancel={() => setShowConfirmModal(false)} />
        </>
      );
    };

    // Fetch metadata and client settings
    useEffect(() => {
      batch(() => {
        dispatch(getAssetMetaData(clientId));
        dispatch(assetsActions.setClearAsset());
        dispatch(clientSettingsActions.getClientSettings(clientId));
        dispatch(getAgreementVersions({ client: clientId }));
      });
    }, [clientId]);

    // Set default departments for Manager
    useEffect(() => {
      if (!metaDataLoading && isRoleMatch(Role, IRoles.EMPLOYEE_MANAGER)) {
        setDefaultDepartments(getDepartmentsOptionsFromIds(departments, activeAccount.departments));
      }
    }, [metaDataLoading, Role]);

    // Prepare the combined props to pass to WrappedComponent, including both the original props and the extra props
    const combinedProps: P & WithAssetCreationProps = {
      ...(restProps as P), // Ensure we only pass the original component props here
      optionsWithCustomCallbacks, // Injected by the HOC
    };

    return (
      <>
        <WrappedComponent {...combinedProps} />
        {renderModals()}

        {/* Toast */}
        <TangiToast
          {...assetCreationToastProps}
          onSuccess={() => {
            dispatch(assetsActions.setAssetCreationToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
          }}
        />
      </>
    );
  };
}

export default withAssetCreation;
