import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableContainer } from './style';

const TableSkeleton = ({ count = 10 }) => {
  return (
    <TableContainer>
      <Skeleton height={64} width="100%" />
      <Skeleton height={45} count={count} width="100%" />
      <Skeleton height={56} width="100%" />
    </TableContainer>
  );
};
export default TableSkeleton;
