import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '_helpers';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XrayLoaders } from 'utils/types/xRay/xRay';
import { PRIMARY_SHADES } from 'utils/theme';
import XrayStatusNotification from '_components/XrayStatusNotification/XrayStatusNotification';
import { LOADING_TRANSLATION_KEY } from '_components/XrayStatusNotification/types';
import { NOTIFICATION_VARIANTS, TangiNotification, TangiTypography } from '_components/TangiLibrary';

interface Props {
  status: XRAY_JOB_STATUS;
  patentIdentifier: string;
  details: string;
  patentSource: XRAY_SOURCE_TYPE;
  onCancel: () => void;
}

const ContinuationAnalysisStatusNotification = ({ status, patentIdentifier, details, patentSource, onCancel }: Props) => {
  const { t } = useTranslation();

  const continuationLoaders: XrayLoaders = useSelector((state: RootState) => state.patent.continuationLoaders);

  const renderJobSucceededNotification = () => {
    return (
      <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO} startIcon={false}>
        <TangiTypography weight="semibold" type="heading-md" color={PRIMARY_SHADES[800]}>
          {t('DISCOVERY_LOBBY.CONTINUATION_ANALYSIS_NOTIFICATION.RECENT_REPORT_DOWNLOAD')}
        </TangiTypography>
        <TangiTypography weight="semibold" type="subheading">
          {t('DISCOVERY_LOBBY.CONTINUATION_ANALYSIS_NOTIFICATION.REPORT_FOR', { PATENT_IDENTIFIER: patentIdentifier })}
        </TangiTypography>
      </TangiNotification>
    );
  };

  return (
    <XrayStatusNotification
      status={status}
      loadingTranslationKey={LOADING_TRANSLATION_KEY.CONTINUATION_ANALYSIS}
      isCancelDisabled={continuationLoaders.getResultLoader || continuationLoaders.startLoader}
      errorDetails={details}
      onCancel={onCancel}
      statusSucceededComponent={renderJobSucceededNotification()}
      patentIdentifier={patentIdentifier}
      patentSource={patentSource}
    />
  );
};

export default ContinuationAnalysisStatusNotification;
