import { CSSProperties } from 'react';
import { SPACING } from 'utils/theme';

export const leaveModalContainerStyle: CSSProperties = {
  width: '424px',
};

export const leaveModalHeaderStyle: CSSProperties = {
  borderBottom: 'none',
};

export const leaveModalBodyStyle: CSSProperties = {
  padding: `${SPACING[0]} ${SPACING[3.5]} ${SPACING[3]} ${SPACING[3]}`,
};

export const leaveModalFooterStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: SPACING[3],
  padding: SPACING[3.5],
};
