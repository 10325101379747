import styled from 'styled-components';

import { SECONDARY_SHADES, NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import { TangiIconButton } from '_components/TangiLibrary';

export const StyledSnackbar = styled.div`
  width: 100%;
  background: ${SECONDARY_SHADES[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 48px;
  position: relative;
`;

export const SnackbarContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SnackbarMessage = styled.div`
  color: ${NEUTRAL_SHADES.WHITE};
  font-size: 16px;
  font-family: 'DIN2014-DemiBold';
  margin-inline: 10px 24px;
`;

export const SnackbarButton = styled.button`
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${NEUTRAL_SHADES.WHITE};
  color: ${NEUTRAL_SHADES.WHITE};
  outline: none;
  padding-inline: 16px;
  font-family: 'DIN2014-Regular';
  background: none;
  font-size: 14px;
  &:hover {
    background: ${SECONDARY_SHADES[400]}60;
  }
`;

export const StyledTangiIconButton = styled(TangiIconButton)`
  cursor: pointer;
  position: absolute;
  right: ${SPACING[2.5]};
  background-color: transparent;

  svg.tangi-icon-svg path {
    fill: ${NEUTRAL_SHADES.WHITE};
  }

  :hover:not(:disabled) {
      background-color: transparent;
      svg.tangi-icon-svg path {
      fill: ${NEUTRAL_SHADES.WHITE};
    }
  }
`;