/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import _ from 'lodash';

import { SharedRecipient } from 'utils/types/assets/assets';

export interface PartnershipGroup {
  partnershipId: string;
  partnershipName: string;
  recipients: SharedRecipient[];
}

export const groupPartnersByPartnership = (recipientsPartners: SharedRecipient[]): PartnershipGroup[] => {
  const grouped = _.groupBy(recipientsPartners, (recipient) => recipient?.account?.partnership?._id || '');

  return Object.keys(grouped).map((partnershipId) => {
    const recipients = grouped[partnershipId];
    const partnershipName = recipients[0]?.account?.partnership?.name || '';

    return {
      partnershipId,
      partnershipName,
      recipients,
    };
  });
};

export const filterRecipientsByName = (array: any[], value: string): any[] => {
  return array.filter((elem) => elem?.fullName?.includes(value) || elem.email.includes(value));
};

export const hasNoPartnerships = (recipientsPartners: any[]): boolean => {
  return recipientsPartners.some((recipient) => !recipient?.account?.partnership?._id || !recipient?.account?.partnership?.name);
};
