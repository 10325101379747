import { agreementService } from './../_services/agreementService';
import { agreementConstants } from './../_constants/agreementConstants';

// TODO: ------ PLEASE DO NOT ANYTHING NEW HERE ------
// NEED TO REFACTOR AND REMOVE THIS ACTIONS
// FOR THE NEW IMPLEMENTATION, ADD TO agreementThunks!!!

function createAgreement() {
  return { type: agreementConstants.CREATING_AGREEMENT };
}

function agreementCreated() {
  return { type: agreementConstants.AGREEMENT_CREATED };
}

function agreementFailure() {
  return { type: agreementConstants.AGREEMENT_FAILURE };
}

function agreementSucess() {
  return { type: agreementConstants.AGREEMENT_SUCCESS };
}

function agreementFileSuccess() {
  return { type: agreementConstants.AGREEMENT_FILEUPLOAD_SUCCESS };
}

function agreementFileFailure(error) {
  return { type: agreementConstants.AGREEMENT_FILEUPLOAD_FAILURE, error };
}

function createAgreementWithFiles(agreements, clientid, accountId, partnershipId, entityType, successDispatch, failureDispatch) {
  return (dispatch) => {
    const agreementPromises = [];

    for (const [i, agreement] of agreements.entries()) {
      agreement.client = clientid;
      if (accountId) {
        agreement.account = accountId;
      } else if (partnershipId) {
        agreement.partnership = partnershipId;
      }

      agreementPromises.push(agreementService.createAgreement(agreement));
    }
    dispatch(createAgreement());
    Promise.all(agreementPromises)
      .then((responseAgreement) => {
        dispatch(agreementCreated());
        const filesPromise = [];
        for (const agreement of agreements) {
          if (agreement.files && agreement.files[0]) {
            const type = agreement.agreementType;
            const id = responseAgreement.find((result) => result.agreementType == type)?.id;
            const entityId = agreement?.account ?? agreement.partnership;
            filesPromise.push(agreementService.uploadAgreementFiles(agreement.files, id, entityType, entityId));
          }
        }

        if (filesPromise.length > 0) {
          Promise.all(filesPromise)
            .then((fileResults) => {
              dispatch(agreementFileSuccess());
              dispatch(agreementSucess());
              successDispatch && dispatch(successDispatch());
            })
            .catch((error) => {
              dispatch(agreementFileFailure(error));
              failureDispatch && dispatch(failureDispatch(error));
            });
        } else {
          dispatch(agreementSucess());
          successDispatch && dispatch(successDispatch());
        }
      })
      .catch((error) => {
        dispatch(agreementFailure());
        failureDispatch && dispatch(failureDispatch(error));
      });
  };
}

function createAgreementType(data) {
  function request() {
    return { type: agreementConstants.CREATE_AGREEMENT_TYPE };
  }
  function success() {
    return { type: agreementConstants.CREATE_AGREEMENT_TYPE_SUCCESS };
  }
  function failure(error) {
    return { type: agreementConstants.CREATE_AGREEMENT_TYPE_FAILED, error };
  }

  return (dispatch) => {
    dispatch(request());
    agreementService
      .createAgreementType(data)
      .then((res) => {
        dispatch(success());
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}
function editAgreementType(data, firmid) {
  function request() {
    return { type: agreementConstants.EDIT_AGREEMENT_TYPE };
  }
  function success(result) {
    return { type: agreementConstants.EDIT_AGREEMENT_TYPE_SUCCESS, result };
  }
  function failure(error) {
    return { type: agreementConstants.EDIT_AGREEMENT_TYPE_FAILED, error };
  }

  return (dispatch) => {
    dispatch(request());
    agreementService
      .editAgreementType(data, firmid)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}
function deleteAgreementType(firmid) {
  function request() {
    return { type: agreementConstants.DELETE_AGREEMENT_TYPE };
  }
  function success() {
    return { type: agreementConstants.DELETE_AGREEMENT_TYPE_SUCCESS };
  }
  function failure(error) {
    return { type: agreementConstants.DELETE_AGREEMENT_TYPE_FAILED, error };
  }

  return (dispatch) => {
    dispatch(request());
    agreementService
      .deleteAgreementType(firmid)
      .then((res) => {
        dispatch(success());
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}

function getAgreementTypes(limit = 1000) {
  function request() {
    return { type: agreementConstants.GET_AGREEMENT_TYPES };
  }
  function success(payload) {
    return { type: agreementConstants.GET_AGREEMENT_TYPES_SUCCESS, payload };
  }
  function failure(error) {
    return { type: agreementConstants.GET_AGREEMENT_TYPES_FAILED, error };
  }

  return (dispatch) => {
    dispatch(request());
    agreementService
      .getAgreementTypes(limit)
      .then((res) => {
        dispatch(success(res));
      })
      .catch((err) => {
        dispatch(failure(err));
      });
  };
}

function clearStatus() {
  return { type: agreementConstants.CLEAR_STATUS };
}

function clearEditType() {
  return { type: agreementConstants.CLEAR_AGREEMENT_TYPE_SUCCESS };
}

function updateAgreement() {
  return { type: agreementConstants.UPDATING_AGREEMENT };
}

function agreementUpdated(payload) {
  return { type: agreementConstants.AGREEMENT_UPDATED, payload };
}

function agreementUpdateFailure(errors) {
  return { type: agreementConstants.AGREEMENT_UPDATED_FAILED, errors };
}

function agreementUpdateSucess(payload) {
  return { type: agreementConstants.AGREEMENT_UPDATED_SUCCESS, payload };
}

function updateAgreementWithFiles(data, clientid, entityType, entityId, successDispatch) {
  const { agreement: agreements } = data;
  return (dispatch) => {
    const agreementPromises = [];

    for (const [i, agreement] of agreements.entries()) {
      if (!agreement?.signed) {
        agreement.signed_date = '';
        agreement.expiry_date = '';
        agreement.version = null;
        agreement.files = [];
      }
      if (clientid) agreement.client = clientid;
      agreementPromises.push(agreementService.updateAgreement(agreement, entityType));
    }

    dispatch(updateAgreement());
    Promise.allSettled([...agreementPromises])
      .then((responseAgreement) => {
        const filesUploadPromises = [];
        responseAgreement.forEach((agreement, index) => {
          // check if you have new files in the updated agreement
          if (agreements[index]?.files?.length > 0 && !agreements[index]?.files[0]?.fileId && !agreements[index]?.files[0]?._id) {
            filesUploadPromises.push(agreementService.uploadAgreementFiles(agreements[index].files, agreement.value.id, entityType, entityId));
          }
        });
        Promise.all(filesUploadPromises)
          .then((filesUploadResponse) => {
            dispatch(agreementUpdateSucess(filesUploadResponse));
            successDispatch && dispatch(successDispatch());
          })
          .catch((error) => {
            dispatch(agreementFileFailure(error));
          });
      })
      .catch((error) => {
        dispatch(agreementUpdateFailure(error));
        successDispatch && dispatch(successDispatch());
      });
  };
}

export const agreementActions = {
  createAgreementWithFiles,
  createAgreementType,
  editAgreementType,
  deleteAgreementType,
  getAgreementTypes,
  clearStatus,
  clearEditType,
  updateAgreementWithFiles,
};
