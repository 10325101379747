import styled from "styled-components";
import { FlexContainer } from "utils/globalStyles";
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from "utils/theme";

export const RecommendationContainer = styled.div`
  margin: ${SPACING[2]} ${SPACING[0]};
  display: flex;
  padding: ${SPACING[2]};
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: ${SPACING[1]};
  border: 1px solid ${PRIMARY_SHADES[300]};
  background: ${PRIMARY_SHADES[100]};
`;

export const ParameterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContentSummaryContainer = styled(FlexContainer)`
  display: flex;
  padding: ${SPACING[4]};
  flex-direction: column;
  align-items: flex-start;
  gap: ${SPACING[3]};
  align-self: stretch;
  border-radius: ${SPACING[2]};
  border: 1px solid ${NEUTRAL_SHADES[300]};
  background: ${NEUTRAL_SHADES.WHITE};
`;

export const CollapseParameterContainer = styled.div`
  padding: ${SPACING[1]};
`;

export const CollapseKeyContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
`;

export const CollapseDivider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin: ${SPACING[0]};
`;

export const CollapseContentContainer = styled.div`
  padding: ${SPACING[0]} 50px ${SPACING[0]} 20px;
  margin-bottom: 4px;
`;

export const CollapseContainer = styled.div`
  display: flex;
  padding: ${SPACING[2]} ${SPACING[0]};
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
`;
