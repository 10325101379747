import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING, NEUTRAL_SHADES } from 'utils/theme';

export const HeaderContainer = styled(FlexContainer)`
  flex-direction: row;
  align-items: center;
  height: 92px;
  gap: ${SPACING[3]};
  padding: ${SPACING[5]} ${SPACING[5]} ${SPACING[4]} ${SPACING[5]};
  position: fixed;
  top: 68px;
  right: 0px;
  left: 226px;
  z-index: 98;
  background-color: ${NEUTRAL_SHADES[50]};
`;
