import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';

import WidgetCard from '../WidgetCard';
import { SPACING } from 'utils/theme';
import { Divider } from 'utils/globalStyles';
import { WidgetAccountObject, WidgetCardProps } from '../Widgets.types';
import WidgetTitle from '../WidgetTitle';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { RecipientAsset, SharedBy } from 'utils/types/dashboard/dashboard';
import MostNotifiedAsset from './MostNotifiedAsset';
import MostSharedBy from './MostSharedBy';
import TopAssetCreator from './TopAssetCreator';

const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 ${SPACING[3]};
  margin-top: ${SPACING[8]};
  overflow-y: auto;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 12px;
`;

export interface Props extends WidgetCardProps {
  data: {
    mostNotifiedAsset: RecipientAsset[];
    mostSharedBy: SharedBy[];
    topAssetCreator: WidgetAccountObject[];
  };
}

const Insights = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();

  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.TITLE')} icon={<TangiSvgIcon component="magicWand" />} />
        <InsightsContainer>
          <TopAssetCreator creators={props.data.topAssetCreator} />
          <StyledDivider />

          <MostSharedBy accounts={props.data.mostSharedBy} />
          <StyledDivider />

          <MostNotifiedAsset assets={props.data.mostNotifiedAsset} />
        </InsightsContainer>
        {props.children}
      </>
    </WidgetCard>
  );
});

export default Insights;
