import styled from 'styled-components';

import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const AliasIdContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${SPACING[1]};
  margin-top: ${SPACING[1]};
`;

export const AliasLinkContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${PRIMARY_SHADES[800]};
  gap: ${SPACING[1]};
  cursor: pointer;
`;

export const TooltipContent = styled.div`
  width: 206px;
  text-align: center;
`;
