import { TangiButton } from '_components/TangiLibrary/TangiButton/TangiButton';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { useState } from 'react';
import UnsavePopup from '_components/IpAudit/components/UnsavePopup/UnsavePopup';
import { useIpAuditPopup } from '_components/IpAudit/components/context/IpAuditPopup.provider';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';

interface StepFooterProps {
  onSubmit: () => void;
  isContinueBtnDisabled?: boolean;
  isContinueBtnLoading?: boolean;
  onContinueText?: string;
}

export const StepFooter = ({ onSubmit, onContinueText, isContinueBtnDisabled = false, isContinueBtnLoading = false }: StepFooterProps) => {
  const { t } = useTranslation();
  const { hasStepChanges } = useIpAuditPopup();

  const { handleStepChange, currentStepKey } = useHandleSteps();
  const { getNextStepKey, isIpAuditStepKey } = useIpAuditContext();
  const [isOpenUnsavePopup, setIsOpenUnsavePopup] = useState(false);

  const isKeyValid = isIpAuditStepKey(currentStepKey);

  const onSkip = () => {
    if (hasStepChanges) setIsOpenUnsavePopup(true);
    else moveToNextStep(true);
  };

  const moveToNextStep = (isMoveToFirstStep = false) => {
    const nextStepKey = isKeyValid ? getNextStepKey(currentStepKey, isMoveToFirstStep) : null;
    if (nextStepKey) handleStepChange(nextStepKey);
  };

  return (
    <>
      <Container>
        <TangiButton variant="tertiary-grey" text={t('GENERAL.BUTTONS_TEXT.SKIP')} onClick={onSkip} smallbtn />
        <TangiButton
          text={t(onContinueText ?? 'GENERAL.BUTTONS_TEXT.SAVE_AND_CONTINUE')}
          onClick={onSubmit}
          smallbtn
          disabled={!hasStepChanges || isContinueBtnDisabled}
          loading={isContinueBtnLoading}
        />
      </Container>

      <UnsavePopup isOpenUnsavePopup={isOpenUnsavePopup} onDiscard={() => moveToNextStep(true)} onCancel={() => setIsOpenUnsavePopup(false)} />
    </>
  );
};
