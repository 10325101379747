import styled from 'styled-components';

export const ErrorMessage = styled.div`
  font-family: Courier;
  display: flex;
  margin: 10px 0px;
  font-size: 14px;
`;
export const MessagesTitle = styled.div`
  font-family: DIN 2014;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${(props) => (props.valid ? '#27AE60' : '#EB5757')};
`;
export const DownloadButton = styled.button`
  text-decoration: none;
  border: none;
  &:hover {
    cursor: 'pointer';
    text-decoration: none;
    color: #2f80ed !important;
    font-size: 14px;
  }
`;
export const ErrorContainer = styled.div`
  cursor: 'pointer';
  max-height: 150px;
  overflow-y: scroll;
`;
export const DropZoneContainer = styled.div`
  cursor: 'pointer';
`;
export const ModalContainer = styled.div`
  width: 50vw;
`;
