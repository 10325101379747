import { ReactNode } from 'react';
import { PolicyFileDetails } from 'utils/types/client/client';
import { SystemSecuritySelection } from './components/Steps/SystemSecurity/types';
import { SystemKey, SystemTagName } from './components/Steps/KeyAssestsFromSystem/types';
import { TangiStepperStep } from '_components/TangiLibrary/TangiStepper/TangiStepper';

export enum IpAuditStepKey {
  OVERVIEW = 'overview',
  TRADE_SECRET_POLICY = 'tradeSecretPolicy',
  SYSTEM_SECURITY = 'systemSecurity',
  HR_PROCESS = 'hrProcess',
  LLM_POLICY = 'llmPolicy',
  CONTRACTS = 'contracts',
  KEY_ASSETS_SYSTEMS = 'keyAssetsFromSystems',
  KEY_ASSETS_PATENT_X_RAY = 'keyAssetsFromPatentXray',
}

export type IpAuditStepsInfoMap = Partial<Record<IpAuditStepKey, IpAuditStepData>>;

export interface IpAuditStepData {
  name: string;
  component: ReactNode;
  isCompleted?: boolean;
}

export enum IP_AUDIT_TEMPLATE {
  LLM = 'LLM.docx',
  TRADE_SECRET = 'Sample Trade Secret Policy.docx',
}

export type IpAuditUserStepsKey = Exclude<IpAuditStepKey, IpAuditStepKey.OVERVIEW>;

export interface IpAuditData {
  hrProcess: HrProcessData | null;
  systemSecurity: SystemSecurityData | null;
  contracts: ContractsData | null;
  keyAssetsSystemNoneSelection: SystemKey[] | null;
}

export type KeyAssetsSystemMappedData = Record<SystemKey, string[]>;

export interface IpAuditExtendedData extends Omit<IpAuditData, 'keyAssetsSystemNoneSelection'> {
  policyFile: PolicyFileDetails | null;
  llmFile: PolicyFileDetails | null;
  keyAssetsSystem: KeyAssetsSystemData;
}

export type KeyAssetsSystemData = { assets: string[] | null; noneSelectionSystems: SystemKey[] | null };

export type StepDataTypeMap = {
  [IpAuditStepKey.HR_PROCESS]: IpAuditExtendedData['hrProcess'] | null;
  [IpAuditStepKey.SYSTEM_SECURITY]: IpAuditExtendedData['systemSecurity'] | null;
  [IpAuditStepKey.LLM_POLICY]: IpAuditExtendedData['llmFile'] | null;
  [IpAuditStepKey.TRADE_SECRET_POLICY]: IpAuditExtendedData['policyFile'] | null;
  [IpAuditStepKey.KEY_ASSETS_SYSTEMS]: IpAuditExtendedData['keyAssetsSystem'] | null;
  [IpAuditStepKey.CONTRACTS]: IpAuditExtendedData['contracts'] | null;
};

export type SystemSecurityData = SystemSecuritySelection[];

export interface HrProcessData {
  isIncorporateProtocolsConfidential: boolean | null;
  employeeTraining: boolean | null;
}

export interface ContractsData {
  hasConfidentialityProvisions: boolean;
}

export type KeyAssetsFromSystemData = {
  clientId: string;
  data: IpAuditBatchAssetData;
};

export interface IpAuditBatchAssetData {
  systems: SystemTagName[];
  assets: { name: string; systemTag: SystemTagName }[];
}

export interface IpAuditActionStepSubmission {
  hasData: boolean;
  isLoading: boolean;
}

export interface IpAuditContextType {
  ipAuditSteps: TangiStepperStep<IpAuditStepKey>[];
  isStepCompleted: (stepKey: IpAuditStepKey) => boolean | undefined;
  getStepCurrentData: <K extends keyof StepDataTypeMap>(stepKey: K) => StepDataTypeMap[K];
  getNextStepKey: (currentStep: string, fallbackToFirst: boolean) => IpAuditStepKey | null;
  isIpAuditStepKey: (key: string | null) => key is IpAuditStepKey;
  getNextStepForCompleteFlow: () => IpAuditStepKey | null;
  numberOfSteps: number;
  numberOfCompletedSteps: number;
  userSteps: TangiStepperStep<IpAuditStepKey>[];
  submitSystemSecurity: (data: SystemSecurityData) => void;
  submitHrProcess: (data: HrProcessData) => void;
  submitKeyAssetsSystem: (assetsData: IpAuditBatchAssetData, noneOfTheAboveArr: SystemKey[]) => void;
  submitContracts: (data: ContractsData) => void;
  hiddenSteps: IpAuditStepKey[];
  isAssetDisable: (system: SystemKey, asset: string) => boolean;
  isStepHasWarning: (stepKey: IpAuditStepKey) => boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ipAuditToastProps: any;
}
