import styled, { css } from 'styled-components';

import { ReactComponent as ArrownDown } from '../../../assets/tangiIcons/arrow-down.svg';
import { NEUTRAL_SHADES, PRIMARY_SHADES, THEME_COLORS, SPACING } from '../../../utils/theme';

// ----- Dropdown Menu ----- //

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding-top: 12px;
  padding-inline: 8px 12px;
`;

export const StyledInput = styled.input`
  margin: 8px 0px 16px 6px;
  height: 32px;
  border: 1px solid ${NEUTRAL_SHADES[500]};
  border-radius: 4px;
  padding-left: 8px;
  font-size: 14px;
  &::placeholder {
    font-family: 'DIN2014-Regular' !important;
    color: ${NEUTRAL_SHADES[800]};
  }
  &:focus {
    border-color: ${THEME_COLORS.PRIMARY_DEFAULT};
    background: ${NEUTRAL_SHADES.WHITE};
  }
  &:hover:not(:focus) {
    background: ${NEUTRAL_SHADES[50]};
    border-color: ${NEUTRAL_SHADES[600]};
  }
`;

export const ItemsContainer = styled.div`
  min-height: 88px;
  max-height: 126px;
  overflow-y: scroll;
  padding-inline: 4px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${NEUTRAL_SHADES[600]};
    visibility: hidden;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[600]};
    border-radius: 5px;
    opacity: 1;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
  height: 42px;
  cursor: pointer;
  padding-left: 4px;
  &:hover {
    background: ${NEUTRAL_SHADES[100]};
  }
`;

export const MenuItemTypography = styled.span`
  padding-top: 2px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 18px;
  width: 18px;
  cursor: pointer;
`;

// ----- Dropdown Menu Footer ----- //

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-inline: ${SPACING[2]};
  justify-content: space-between;
`;

// ----- Filter Button ----- //

export const FilterButton = styled.button`
  ${({ $isSelected: isSelected, show, disabled }) => css`
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    min-width: 70px;
    height: 32px;
    outline: none;
    background: ${isSelected ? PRIMARY_SHADES[200] : 'none'};
    border: 1px solid ${isSelected || show ? THEME_COLORS.PRIMARY_DEFAULT : NEUTRAL_SHADES[600]};
    border-radius: 4px;
    font-size: 14px;
    padding-inline: 8px;
    color: ${isSelected || show ? THEME_COLORS.PRIMARY_DEFAULT : NEUTRAL_SHADES[800]};
    pointer-events: ${disabled ? 'not-allowed' : 'auto'}; // Disabling pointer events when button is disabled

    &:hover:not(:focus) {
      border-color: ${show ? `${THEME_COLORS.PRIMARY_DEFAULT} !important` : !isSelected && NEUTRAL_SHADES[800]};
      color: ${show ? THEME_COLORS.PRIMARY_DEFAULT : !isSelected && NEUTRAL_SHADES[800]};
      background: ${isSelected && PRIMARY_SHADES[200]};
      .multiselect-dropdown-icon path {
        fill: ${show ? THEME_COLORS.PRIMARY_DEFAULT : !isSelected && NEUTRAL_SHADES[800]};
      }
    }
    & .multiselect-dropdown-icon {
      path {
        fill: ${isSelected || show ? THEME_COLORS.PRIMARY_DEFAULT : NEUTRAL_SHADES[800]};
      }
    }
    &:focus {
      color: ${THEME_COLORS.PRIMARY_DEFAULT};
      border: 1px solid ${!isSelected && THEME_COLORS.PRIMARY_DEFAULT};
      & .multiselect-dropdown-icon {
        path {
          fill: ${THEME_COLORS.PRIMARY_DEFAULT};
        }
      }
    }
  `}
`;

export const IconContainer = styled.span`
  margin-left: 8px;
`;

export const StyledArrownDownIcon = styled(ArrownDown)`
  width: 10px;
  path {
    fill: ${({ $isSelected: isSelected }) => isSelected && THEME_COLORS.PRIMARY_DEFAULT};
  }
`;

export const StyledSelectedItem = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledSelectedNum = styled.span`
  white-space: nowrap;
  margin-left: 4px;
`;
