import styled, { css } from 'styled-components';
import { Modal, Form } from 'react-bootstrap';

import { TangiInput, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, ERROR_SHADES, SPACING } from '../../utils/theme';

export const StyledForm = styled(Form)`
  width: 816px;
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[0]} ${SPACING[3]};
  max-height: ${SPACING[10]};
  min-height: 208px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: ${SPACING[2]};
  }
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 6px;
  }
`;

export const ContentRowContributors = styled.div`
  ${({ error }) => css`
    display: flex;
    width: 100%;
    gap: ${SPACING[3]};
    font-size: 14px;
    font-family: 'DIN2014-Regular';
    align-items: center;
    margin-bottom: ${SPACING[4]};
    .email-avatar-container,
    .remove-item-button {
      margin-bottom: ${error && SPACING[3.5]};
    }
  `}
`;

export const TangiTypographyLabel = styled(TangiTypography)`
  width: 214px;
`;

export const DuoContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};

  div.tangi-selector-container {
    min-width: 160px;
    max-width: 160px;
  }
  div.tangi-datepicker-container {
    width: 168px;
  }
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding: ${SPACING[3]};
`;

export const LabelsContainer2 = styled.div`
  display: flex;
  div:first-of-type {
    margin-left: 198px;
  }
  gap: ${SPACING[3]};
  > div:first-of-type {
    width: 180px;
  }
  > div:nth-of-type(2) {
    width: 160px;
  }
  > div:last-of-type {
    width: 168px;
  }
`;

export const StyledTangiInput = styled(TangiInput).attrs({
  type: 'email',
  shouldRenderLabel: false,
  placeholder: 'example@example.com',
})`
  margin-bottom: 0.25rem;
  margin-top: ${({ error }) => (error ? SPACING[3.5] : SPACING[0])};
  & .invalid-feedback {
    position: absolute;
    height: ${SPACING[2]};
  }
`;

export const TangiInputContainerContributors = styled.div`
  position: relative;
  input {
    &:hover:not(:focus) {
      border: ${({ isDisabled }) => !isDisabled && `1px solid ${NEUTRAL_SHADES[600]}`};
    }
    min-width: 180px;
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[3]};
  gap: ${SPACING[2]};
  align-items: flex-start;
  font-family: 'DIN2014-Regular';
  font-size: 16px;
  width: 650px;

  div:first-of-type {
    font-size: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

export const RequiredStar = styled.label`
  color: ${ERROR_SHADES[500]};
  padding-left: ${SPACING[1.5]};
`;
