import { isEqual } from 'lodash';
import { systemKeys } from './components/Steps/KeyAssestsFromSystem/consts';
import { KeyAssetsSystemMappedData } from './types';
import { Asset } from 'utils/types/assets/assets';

export const isKeyAssetsCompleted = (keyAssetsSystem: KeyAssetsSystemMappedData | null): boolean => {
  if (!keyAssetsSystem) {
    return false;
  }
  return systemKeys.every((key) => {
    const value = keyAssetsSystem[key];
    return Array.isArray(value) && value.length > 0;
  });
};

export const isArraysEqual = (arr1: string[] | null, arr2: string[] | null): boolean => {
  if (!arr1 && !arr2) {
    return true;
  }
  return isEqual(arr1, arr2);
};

export const parseKeyAssetsItem = (assets: Asset[] | null | undefined): string[] | null => {
  if (!assets?.length) return null;
  return assets.map(({ name }) => name);
};
