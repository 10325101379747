import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './style';
import { ACCOUNT_STATUS } from '../../../utils/enums';

export const TangiAccountStatus = ({ status, ...props }) => {
  return (
    <Container status={status} {...props} data-testid="account-status">
      {status}
    </Container>
  );
};

TangiAccountStatus.propTypes = { status: PropTypes.oneOf([ACCOUNT_STATUS.ACTIVE, ACCOUNT_STATUS.DISABLED, ACCOUNT_STATUS.PENDING]) };
