import { PARTNERSHIP_STATUS } from 'utils/enums';
import { Client } from 'utils/types/client/client';
import { Partnership } from 'utils/types/partnership/partnership';

export const isPartnershipDisabled = (partnership: Partnership) => {
  return partnership?.status === PARTNERSHIP_STATUS.DISABLED;
};

/**
 * Type guard function to determine if a given object is a Partnership.
 *
 * @param {Partnership | Client | undefined} object - The object to evaluate, which may be of type Partnership, Client, or undefined.
 * @returns {object is Partnership} - Returns `true` if the object is a Partnership, `false` otherwise.
 */
export const isObjectPartnership = (object: Partnership | Client | undefined): object is Partnership => {
  return object != null && 'status' in object;
};
