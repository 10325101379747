export const discoveryConstants = {
  SET_CURRENT_EMAIL_THREAD: 'SET_CURRENT_EMAIL_THREAD',
  CLEAR_CURRENT_EMAIL: 'CLEAR_CURRENT_EMAIL',

  GET_THREADS_REQUEST: 'GET_THREADS_REQUEST',
  GET_THREADS_FAILURE: 'GET_THREADS_FAILURE',
  GET_THREADS_SUCCESS: 'GET_THREADS_SUCCESS',

  GET_EMAIL_BY_ID_REQUEST: 'GET_EMAIL_BY_ID_REQUEST',
  GET_EMAIL_BY_ID_FAILURE: 'GET_EMAIL_BY_ID_FAILURE',
  GET_EMAIL_BY_ID_SUCCESS: 'GET_EMAIL_BY_ID_SUCCESS',

  FINDER_AUTHORIZE_REQUEST: 'FINDER_AUTHORIZE_REQUEST',
  FINDER_AUTHORIZE_FAILURE: 'FINDER_AUTHORIZE_FAILURE',
  FINDER_AUTHORIZE_SUCCESS: 'FINDER_AUTHORIZE_SUCCESS',

  FINDER_FETCH_REQUEST: 'FINDER_FETCH_REQUEST',
  FINDER_FETCH_FAILURE: 'FINDER_FETCH_FAILURE',
  FINDER_FETCH_SUCCESS: 'FINDER_AUTHORIZE_SUCCESS',

  GET_LAST_JOB_REQUEST: 'GET_LAST_JOB_REQUEST',
  GET_LAST_JOB_FAILURE: 'GET_LAST_JOB_FAILURE',
  GET_LAST_JOB_SUCCESS: 'GET_LAST_JOB_SUCCESS',

  GET_TRACKER_RESULTS_REQUEST: 'GET_TRACKER_RESULTS_REQUEST',
  GET_TRACKER_RESULTS_FAILURE: 'GET_TRACKER_RESULTS_FAILURE',
  GET_TRACKER_RESULTS_SUCCESS: 'GET_TRACKER_RESULTS_SUCCESS',

  SET_CURRENT_TRACKER_ASSET_ID: 'SET_CURRENT_TRACKER_ASSET_ID',

  REFETCH_TRACKER_RESULTS_SUCCESS: 'REFETCH_TRACKER_RESULTS_SUCCESS',
};
