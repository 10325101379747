import styled from 'styled-components';
import { BORDER_RADIUS, NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';

export const ProgressBarContainer = styled.div<{ $backgroundColor?: string; height?: number }>`
  position: relative;
  height: ${({ height }) => (height ? `${height}px` : '10px')};
  border-radius: ${BORDER_RADIUS[50]};
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? NEUTRAL_SHADES[150]};
`;

export const ProgressBar = styled.span<{ $percentage: number; height?: number; $backgroundColor?: string }>`
  position: absolute;
  height: ${({ height }) => (height ? `${height}px` : '10px')};
  border-radius: ${BORDER_RADIUS[50]};
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? PRIMARY_SHADES[800]};
  width: ${({ $percentage }) => `${$percentage}%`};
  transition: width 0.3s;
`;
