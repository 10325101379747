import Skeleton from 'react-loading-skeleton';
import { Row, Col, Card } from 'react-bootstrap';

const AssetsSkeleton = () => {
  const NUM_CARDS = 9;
  return (
    <Row xs={1} md={2} lg={3} className="g-4">
      {Array.from({ length: NUM_CARDS }, (_, index) => (
        <Col key={index}>
          <Card className="card-rounded-2 shadow position-relative">
            <Card.Header>
              <Skeleton height={44} />
            </Card.Header>
            <Card.Body>
              <Skeleton height={135} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default AssetsSkeleton;
