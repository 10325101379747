import { config } from 'config/config';
import AddUsersStep from './components/AddUsersStep/AddUsersStep';
import AllowAccessStep from './components/AllowAccessStep/AllowAccessStep';
import ConnectSitesAndFoldersStep from './components/ConnectSitesAndFoldersStep/ConnectSitesAndFoldersStep';
import HowItWorksStep from './components/HowItWorksStep/HowItWorksStep';
import SendAcknowledgementStep from './components/SendAcknowledgementStep/SendAcknowledgementStep';
import { FormSchema, SharePointWizardStepKey, SharePointWizardStepsMap } from './types';

export const sharePointWizardStepsConfig: SharePointWizardStepsMap = {
  [SharePointWizardStepKey.HOW_IT_WORKS]: {
    id: SharePointWizardStepKey.HOW_IT_WORKS,
    component: <HowItWorksStep />,
    stepRequiredFormFields: [],
  },
  [SharePointWizardStepKey.ALLOW_ACCESS]: {
    id: SharePointWizardStepKey.ALLOW_ACCESS,
    component: <AllowAccessStep />,
    stepRequiredFormFields: ['applicationId', 'directoryId', 'clientSecret', 'isGrantedPermission'],
  },
  [SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS]: {
    id: SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS,
    component: <ConnectSitesAndFoldersStep />,
    stepRequiredFormFields: ['sites'],
  },
  [SharePointWizardStepKey.ADD_USERS]: {
    id: SharePointWizardStepKey.ADD_USERS,
    component: <AddUsersStep />,
    stepRequiredFormFields: ['users'],
  },
  [SharePointWizardStepKey.SEND_ACKNOWLEDGEMENT]: {
    id: SharePointWizardStepKey.SEND_ACKNOWLEDGEMENT,
    component: <SendAcknowledgementStep />,
    stepRequiredFormFields: [],
  },
};

export enum IncludeContent {
  SPECIFIC_FOLDERS = 'specificFolders',
  ALL_CONTENT = 'allContent',
}

export const radioOptions = [
  { id: IncludeContent.SPECIFIC_FOLDERS, labelKey: 'SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.RADIO_OPTIONS.SPECIFIC_FOLDERS' },
  { id: IncludeContent.ALL_CONTENT, labelKey: 'SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.RADIO_OPTIONS.ALL_CONTENT' },
];

// -------------------- INITIAL FORM VALUES --------------------
export const initialSiteValue = {
  siteLink: '',
  includeContent: IncludeContent.ALL_CONTENT,
  folders: [],
};

export const initialFolderValue = { path: '' };

// Default values - envs are used for testing
export const wizardFormDefaultValues: FormSchema = {
  applicationId: config.sharepointApplicationId || '',
  directoryId: config.sharepointDirectoryId || '',
  clientSecret: config.sharepointClientSecret || '',
  sites: [initialSiteValue],
  users: [],
  isGrantedPermission: false
};
// -------------------------------------------------------------
