import { config } from '../../config/config';
import { headers } from '_services/utils/headers';
import { HttpClientService } from '../../_services/HttpClientService';
import { UPLOAD_FILE_TYPES, ENTITY_TYPES } from 'utils/enums';
import { DownloadAgreementParams } from 'utils/types/agreement/agreement';
import { FilePayload } from 'utils/types/types';

// TODO: ------ THIS IS NEW ------
// agreementService NEED TO BE REFACTORED AND MOVED HERE

class AgreementAdapter {
  static fileEndpoint = `${config.apiUrl}${config.apiVersion}/file`;
  static downloadEndpoint = '/authenticated/path';

  async downloadAgreement({ fileName, agreementId, partnershipId }: DownloadAgreementParams) {
    const res = await HttpClientService.get<FilePayload>(`${AgreementAdapter.fileEndpoint}${AgreementAdapter.downloadEndpoint}`, {
      headers: headers(),
      params: { fileName, type: UPLOAD_FILE_TYPES.AGREEMENTS, entityType: ENTITY_TYPES.PARTNERSHIPS, id: agreementId, entityId: partnershipId },
    });
    return res.data;
  }
}

const agreementAdapter = new AgreementAdapter();
export default agreementAdapter;
