import { useSharePointWizardContext } from '_components/SharePointWizard/context/useSharePointWizardContext';
import { TangiModal, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { StyledBodyContainer, StyledSpinner, descriptionCustomStyles, headerCustomStyles } from './style';
import { getLoaderTranslationKeys } from './utils';

interface Props {
  showLoader: boolean;
}

const SharePointLoader = ({ showLoader }: Props) => {
  const { t } = useTranslation();
  const { currentStepId, isEditMode } = useSharePointWizardContext();

  const content = getLoaderTranslationKeys(isEditMode, currentStepId);
  if (!content) return null;

  return (
    <TangiModal
      show={showLoader}
      header={null}
      body={
        <StyledBodyContainer>
          <StyledSpinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </StyledSpinner>
          <TangiTypography type="heading-lg" weight="semibold" customStyles={headerCustomStyles}>
            {t(content.header)}
          </TangiTypography>
          {content.description1 && (
            <TangiTypography type="subheading" customStyles={descriptionCustomStyles}>
              {t(content.description1)}
              <>
                <br />
                {t(content.description2)}
              </>
            </TangiTypography>
          )}
        </StyledBodyContainer>
      }
      containerStyle={{ width: '526px', height: '332px' }}
      isCloseButton={false}
      dataTestId="sp-wizard-loading-modal"
      onHide={() => {}}
    />
  );
};

export default SharePointLoader;
