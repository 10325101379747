import { HttpClientService } from '_services/HttpClientService';
import { headers } from '_services/utils/headers';
import { config } from 'config/config';
import { CompetitiveAnalysisGraphsData, CompetitiveAnalysisTableData } from 'utils/types/competitiveAnalysis/competitiveAnalysis';
import { FiltersParams } from 'utils/types/predictedPortfolio/predictedPortfolio';

class competitiveAnalysisAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;
  static competitiveAnalysisEndPoint = `${competitiveAnalysisAdapter.baseEndPoint}/ts-analysis/ip-competitive/results`;
  static tableEndPoint = '/table';
  static graphsEndPoint = '/graphs';

  async getCompetitiveAnalysisTradeSecretsTable(limit: number, page: number, filters: FiltersParams): Promise<CompetitiveAnalysisTableData> {
    const params = {
      page,
      limit,
    };
    const res = await HttpClientService.post<CompetitiveAnalysisTableData>(`${competitiveAnalysisAdapter.competitiveAnalysisEndPoint}${competitiveAnalysisAdapter.tableEndPoint}`, filters, {
      headers: headers(),
      params,
    });
    return res.data;
  }

  async getCompetitiveAnalysisGraphsResults(): Promise<CompetitiveAnalysisGraphsData> {
    const res = await HttpClientService.get<CompetitiveAnalysisGraphsData>(`${competitiveAnalysisAdapter.competitiveAnalysisEndPoint}${competitiveAnalysisAdapter.graphsEndPoint}`, {
      headers: headers(),
    });
    return res.data;
  }
}

const ipCompetitiveAdapter = new competitiveAnalysisAdapter();
export default ipCompetitiveAdapter;
