import { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AppRoutes, XrayRoutes } from 'routing/consts';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';
import { IRoles } from 'utils/roles';

interface Props extends RouteProps {
  component: FunctionComponent<RouteProps>;
  unauthenticatedOnly?: boolean;
  permissions?: IRoles[];
}

const PrivateRoute = ({ component: Component, permissions = [], ...rest }: Props) => {
  const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);
  const { specialAdminPermissions = {} } = activeAccount ?? {};

  return (
    <Route
      {...rest}
      render={(props) => {
        // Not logged in or not permitted - redirect to main page with the return url
        if (!activeAccount || !permissions.includes(activeAccount.role.name)) {
          return <Redirect to={{ pathname: AppRoutes.MAIN, state: { from: props.location } }} />;
        }

        const path = props.location.pathname;

        // Xray permissions
        const hasNoPermissionsToXray = !specialAdminPermissions?.Patent && Object.values(XrayRoutes).some((route) => path.includes(route));
        const hasNoPermissionsToContinuation = !(specialAdminPermissions?.Patent && specialAdminPermissions?.ContinuationXRay) && path.includes(XrayRoutes.CONTINUATION_ANALYSIS);

        // Should redirect to MAIN route if user has no permissions to specific features
        const shouldRedirectToMain = hasNoPermissionsToXray || hasNoPermissionsToContinuation;

        if (shouldRedirectToMain) {
          return <Redirect to={{ pathname: AppRoutes.MAIN, state: { from: props.location } }} />;
        }

        // Logged in or permitted so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
