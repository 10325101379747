import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

import { NEUTRAL_SHADES, PRIMARY_SHADES, THEME_COLORS } from '../../utils/theme';

export const ModalContentContainer = styled.div`
  width: 576px;
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  gap: 4px;
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px;
  max-height: 464px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 6px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    margin-top: 2px;
    font-size: 18px;
  }
`;

export const SubTitle = styled.div`
  align-self: flex-start;
  font-family: 'DIN2014-Regular';
`;

export const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AssetRequest = styled.div`
  ${({ isSelected = false, isWithCheckbox = false }) => css`
    width: 100%;
    display: flex;
    gap: 12px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid ${PRIMARY_SHADES[200]};
    background: ${isSelected ? PRIMARY_SHADES[50] : NEUTRAL_SHADES[50]};
    cursor: ${isWithCheckbox && 'pointer'};
  `}
`;

export const RequestBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AssetName = styled.span`
  font-size: 16px;
  font-family: 'DIN2014-DemiBold';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 432px;
  margin-bottom: 16px;
`;

export const AssetFileContainer = styled.div`
  font-family: 'DIN2014-Regular';
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
`;

export const AssetFileIconAndName = styled.div`
  font-family: 'DIN2014-DemiBold';
  min-width: 384px;
  max-width: 384px;
  height: 40px;
  border: 1px dashed ${NEUTRAL_SHADES[500]};
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const SharedByContainer = styled.div`
  font-family: 'DIN2014-Regular';
  height: 22px;
  font-size: 14px;
  margin-top: 8px;
  span:first-of-type {
    color: ${NEUTRAL_SHADES[700]};
    margin-right: 4px;
  }
  span:nth-of-type(2) {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 45%;
  }
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-top: 2px;
`;

export const ConfirmationText = styled.div`
  font-size: 14px;
  align-items: center;
  margin-block: 24px 16px;
  font-family: 'DIN2014-Regular';
  strong {
    font-family: 'DIN2014-DemiBold';
    margin-inline: 4px;
  }
`;

export const StyledInput = styled.input`
  border: 1px solid ${NEUTRAL_SHADES[500]};
  padding: 6px 8px;
  border-radius: 4px;
  font-family: 'DIN2014-Regular';
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding: 24px 24px 16px;
  span:first-of-type {
    margin-right: 24px;
  }
`;

export const ManageAllText = styled.div`
  color: ${THEME_COLORS.PRIMARY_DEFAULT};
  width: fit-content;
  font-family: 'DIN2014-Regular';
  margin-top: 18px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${THEME_COLORS.PRIMARY_HOVER};
  }
`;
