import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Card } from 'react-bootstrap';

const DistributeAssetModalSkeleton = () => {
  return (
    <>
      <Card.Body>
        <Skeleton height={50} />
      </Card.Body>
      <Card.Body>
        <Skeleton height={150} />
      </Card.Body>
    </>
  );
};

export default DistributeAssetModalSkeleton;
