import { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { TangiAlert } from '../../../TangiLibrary';
import AssetItem from '../AssetItem';
import { acceptedFilesList, handleDuplicateFilenames } from './utils';
import { RESULT_STATUS } from 'utils/enums';
import { formatLongFileName } from 'utils/fileNameUtils';
import { MAX_FILE_SIZE, LARGE_FILE_CODE_ERROR, ONE_MB } from '_constants/fileConstants';
import { handleFileName } from './utils';
import { TangiNotification, NOTIFICATION_VARIANTS } from '../../../TangiLibrary/TangiNotification';

const FilesDropZone = ({ files, setFiles, removeCurrentAsset, control, checkPermissions, errors, filesToAttach, existingAssetFiles = [] }) => {
  const [isInvalidFileName, setIsInvalidFileName] = useState(false);
  const [alertProps, setAlertProps] = useState({
    show: false,
    text: '',
    type: RESULT_STATUS.BLANK,
  });
  const { t } = useTranslation();

  const [showModifiedFileWarning, setShowModifiedFileWarning] = useState(false);

  useEffect(() => {
    if (!files.length) {
      setShowModifiedFileWarning(false);
    }
  }, [files?.length]);

  const resetAlertProps = () => {
    setAlertProps({ show: false, text: '', type: RESULT_STATUS.BLANK });
  };

  const parseFileNames = (acceptedFiles) => {
    const files = [];
    let isModified = false;
    acceptedFiles.forEach((inputFile) => {
      const { file, isModified: _isModified } = handleFileName(inputFile);
      files.push(file);
      isModified ||= _isModified;
    });
    return { files, isModified };
  };

  // Check the size of rejected files and display an alert if a file is too large (checks one file at a time)
  const checkFileSize = (fileRejections) => {
    const MAX_REJECTED_FILENAME_LENGTH = 97;
    if (fileRejections[0]?.errors[0]?.code === LARGE_FILE_CODE_ERROR) {
      const rejectedFileName = formatLongFileName(fileRejections[0].file.name, MAX_REJECTED_FILENAME_LENGTH);
      const maxAcceptedFileSize = MAX_FILE_SIZE / ONE_MB;
      setAlertProps({ show: true, text: t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_TOO_LARGE', { FILE_NAME: rejectedFileName, FILE_SIZE: maxAcceptedFileSize }), type: RESULT_STATUS.ERROR });
    }
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    setIsInvalidFileName(false);
    resetAlertProps();
    if (!!acceptedFiles?.length) {
      const { files: parsedFiles, isModified } = parseFileNames(acceptedFiles);
      isModified && setShowModifiedFileWarning(true);
      const droppedFilesWithUniqueNames = handleDuplicateFilenames(parsedFiles, existingAssetFiles, files);
      const mergeResult = [...files, ...droppedFilesWithUniqueNames];
      setFiles(mergeResult);
    }

    fileRejections?.length && checkFileSize(fileRejections);
  };

  useEffect(() => {
    if (filesToAttach?.length) {
      const existingFileNames = new Set(files.map((file) => file.name));
      const newFilesArray = filesToAttach.filter((file) => !existingFileNames.has(file.name));
      if (newFilesArray.length) {
        onDrop(newFilesArray);
      }
    }
    //TODO: checkFileSize?
  }, [filesToAttach]);

  return (
    <Form.Group controlId="assetFiles">
      <Form.Label>{t('ASSET_PAGE.CREATE_EDIT_ASSET.LABEL.ADD_FILES')}</Form.Label>
      {files && files.length > 0 && (
        <section>
          <ul className="p-0">
            {files.map((acceptedFile) => (
              <div key={uuidv4}>
                <AssetItem action="create" asset={acceptedFile} assetType="File" removeAssetFunc={removeCurrentAsset} />
              </div>
            ))}
          </ul>
          {showModifiedFileWarning && (
            <div style={{ marginBottom: '14px' }}>
              <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('ASSET_PAGE.MODAL.FILE_RENAME')}</TangiNotification>
            </div>
          )}
        </section>
      )}
      <Controller
        render={({ field }) => (
          <Dropzone multiple {...field} accept={acceptedFilesList} disabled={!checkPermissions('UPLOAD_FILES')} onDrop={onDrop} maxSize={MAX_FILE_SIZE}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div>
                {!isDragActive && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-0 dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <span>
                      <i aria-hidden="true" className="fas fa-upload me-2" />
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DRAG_AND_DROP')}
                    </span>
                  </div>
                )}
                {isDragActive && !isDragReject && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-secondary p-5 text-center rounded text-muted dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <span>
                      <i aria-hidden="true" className="fas fa-upload me-2" />
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DROP_FILES')}
                    </span>
                  </div>
                )}
                {isDragActive && isDragReject && (
                  <div
                    {...getRootProps()}
                    className="container w-100 bg-danger p-5 text-center rounded text-white dropZone"
                    style={{ cursor: !checkPermissions('UPLOAD_FILES') ? 'no-drop' : 'pointer' }}
                  >
                    <input {...getInputProps()} />
                    <p style={{ margin: 0 }}>{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.REJECT_PLEASE_UPLOAD_ONLY_EXCEPTED')}</p>
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        )}
        name="files"
        control={control}
      />
      <p className="text-muted fs-7">{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.UPLOAD_ONLY_ACCEPTED_FORMATS')}</p>
      {isInvalidFileName && <p className="d-block invalid-feedback">{t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.FILE_CANNOT_CONTAIN_SPECIAL_CHARACTERS')}</p>}
      {errors.files && <div className="d-block invalid-feedback">{errors.files && errors.files.message}</div>}
      <TangiAlert {...alertProps} />
    </Form.Group>
  );
};

export default FilesDropZone;
