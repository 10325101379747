import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from '../../../../utils/theme';
import { StyledAssetCard, AssetBodyContainer, AssetCardHeader, AssetCardFooter } from '../../style';

// ----- Locked asset card ----- //

export const StyledLockedAssetCard = styled(StyledAssetCard)`
  background: ${NEUTRAL_SHADES[100]};
`;

export const LockedAssetCardHeader = styled(AssetCardHeader)`
  height: ${SPACING[5]};
`;

export const Divider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin: 0;
`;

export const LockedAssetBodyContainer = styled(AssetBodyContainer)`
  height: 53px;
`;

export const LockedAssetCardFooter = styled(AssetCardFooter)`
  align-self: flex-end;
  width: 152px;
`;

export const AssetDeletedContainer = styled.div`
  display: flex;
  width: 152px;
  max-width: 107px;
  height: 38px;
  padding: ${SPACING[2]} 10px;
  background-color: ${NEUTRAL_SHADES[300]};
  border: 1px solid ${NEUTRAL_SHADES[400]};
  border-radius: ${SPACING[1]};
  justify-content: center;
  align-items: center;
`;
