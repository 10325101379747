import styled, { css } from 'styled-components';

import { NEUTRAL_SHADES } from '../../utils/theme';

export const StyledDropDown = styled.div`
  ${({ dropdownStyles }) => css`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    background-color: ${NEUTRAL_SHADES.WHITE};
    font-family: 'DIN2014-Regular';
    background-color: white;
    border-radius: 4px;
    position: absolute;
    z-index: 101;
    padding-block: 8px;
    font-size: 14px;
    top: 44px;
    ${dropdownStyles && dropdownStyles}
  `}
`;

export const StyledDropDownItem = styled.div`
  font-size: 14px;
  padding: 8px 16px;
  color: ${({ disabled }) => disabled && NEUTRAL_SHADES[700]};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    background-color: ${({ disabled }) => !disabled && NEUTRAL_SHADES[100]};
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  b {
    font-size: 18px;
    font-weight: 700;
  }
`;
