import React from 'react';

import { Container, SkeletonCircle, SkeletonStyled, SkeletonWrapper } from './style';


interface IconAndTextSkeletonProps {
  count?: number;
  textWidth?: number;
}

export const IconAndTextSkeleton = ({
  count = 5,
  textWidth = 150,
}: IconAndTextSkeletonProps) => {
  return (
    <Container>
      {Array.from(Array(count)).map((_, index) => (
        <React.Fragment key={index}>
          <SkeletonWrapper>
            <SkeletonCircle />
            <SkeletonStyled width={textWidth} />
          </SkeletonWrapper>
        </React.Fragment>
      ))}
    </Container>
  );
};
