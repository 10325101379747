import { createAsyncThunk } from '@reduxjs/toolkit';
import { authConstants, clientConstants } from '_constants';
import sharePointAdapter from 'redux-toolkit/adapters/sharepointAdapter';
import { INTERVAL_IN_MILLISECONDS, JOB_STATUS } from 'utils/enums';
import { loadStateFromLocalStorage, removeStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import {
  BaseIntegrationResponse,
  StartSPIntegrationResponse,
  SharePointMembersAndAccounts,
  ValidateSPFoldersPayload,
  ValidateSPFoldersResponse,
  ValidateSPSecretsPayload,
  ValidateSPSecretsResponse,
  CheckSPIntegrationStatusPayload,
  StartIntegrationThunkPayload,
  IntegrationDataResponse,
  SharePointAddedUser,
  LastSyncResponseType,
} from 'utils/types/sharepoint/sharepoint';
import { getLawyerClient } from './lawFirmThunks';
import { Account } from 'utils/types/account/account';
import { AppDispatch, RootState } from '_helpers';
import { setAccount } from 'redux-toolkit/slices/authSlice';

export const validateSPSecrets = createAsyncThunk<ValidateSPSecretsResponse | null, ValidateSPSecretsPayload>('sharePoint/validateSPSecrets', async ({ data }) => {
  const res = await sharePointAdapter.validateSPSecrets(data);
  return res ?? null;
});

export const validateSPFolders = createAsyncThunk<ValidateSPFoldersResponse | null, ValidateSPFoldersPayload>('sharePoint/validateSPFolders', async ({ data }, { rejectWithValue }) => {
  const res = await sharePointAdapter.validateSPFolders(data);

  if (!res.isValid) {
    return rejectWithValue(res);
  }

  return res ?? null;
});

export const startSPIntegration = createAsyncThunk<StartSPIntegrationResponse | null, StartIntegrationThunkPayload, { dispatch: AppDispatch }>(
  'sharePoint/startSPIntegration',
  async ({ formData, clientId, isLawyer, isEditMode }, { dispatch }) => {
    const data = {
      secrets: {
        applicationId: formData.applicationId,
        directoryId: formData.directoryId,
        clientSecret: formData.clientSecret,
      },
      folders: formData.sites,
      users: formData.users.map((user) => ({ ...user, role: user.role.value })),
    };

    const res = await sharePointAdapter.startSPIntegration(data, isEditMode);
    if (res?.jobId) {
      const localStorageKey = `${clientConstants.SHAREPOINT_INTEGRATION_JOB_ID}[${clientId}]`;
      saveStateToLocalStorage(localStorageKey, res.jobId);
      dispatch(checkSPIntegrationStatus({ jobId: res.jobId, clientId, isLawyer, isEditMode }));
    }
    return res ?? null;
  },
);

export const checkSPIntegrationStatus = createAsyncThunk<BaseIntegrationResponse | null, CheckSPIntegrationStatusPayload, { state: RootState; dispatch: AppDispatch }>(
  'sharePoint/checkSPIntegrationStatus',
  async ({ jobId, clientId, isLawyer, isEditMode }, { dispatch, getState }) => {
    const res = await sharePointAdapter.checkSPIntegrationStatus(jobId);
    const { jobStatus } = res ?? {};

    if (jobStatus === JOB_STATUS.STARTED) {
      setTimeout(() => {
        dispatch(checkSPIntegrationStatus({ jobId, clientId, isLawyer, isEditMode }));
      }, INTERVAL_IN_MILLISECONDS.FiveSeconds);
    }

    if ([JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(jobStatus)) {
      removeStateFromLocalStorage(`${clientConstants.SHAREPOINT_INTEGRATION_JOB_ID}[${clientId}]`);
    }

    // If the job is successful and the user is not in edit mode, update the isConnected field in sharepoint permissions
    if (jobStatus === JOB_STATUS.SUCCEEDED && !isEditMode) {
      const accountId = getState().authentication.activeAccount?._id || '';
      isLawyer ? await dispatch(getLawyerClient({ accountId, clientId })) : updateIsConnectedNotLawyer(dispatch);
    }
    
    return res ?? null;
  },
);

export const getMembers = createAsyncThunk<SharePointMembersAndAccounts, ValidateSPFoldersPayload>('sharePoint/getMembers', async ({ data }) => {
  const res = await sharePointAdapter.getMembers(data);
  return res ?? null;
});

export const getIntegrationData = createAsyncThunk<IntegrationDataResponse | null>('sharePoint/getIntegationData', async () => {
  const res = await sharePointAdapter.getIntegrationData();
  return res ?? null;
});

export const getNewAddedUsers = createAsyncThunk<SharePointAddedUser[] | null>('sharePoint/getNewAddedUsers', async () => {
  const res = await sharePointAdapter.getNewAddedUsers();
  return res ?? [];
});

export const dismissNewAddedUsers = createAsyncThunk('sharePoint/dismissNewAddedUsers', async () => {
  await sharePointAdapter.dismissNewAddedUsers();
});

export const sharepointSync = createAsyncThunk<LastSyncResponseType>('sharePoint/sharepointSync', async () => {
  const res = await sharePointAdapter.sharepointSync();
  return res ?? null;
});

export const getLastSharePointSync = createAsyncThunk<LastSyncResponseType>('sharePoint/getLastSharepointSync', async () => {
  const res = await sharePointAdapter.getLastSharePointSync();
  return res ?? null;
});

const updateIsConnectedNotLawyer = (dispatch: AppDispatch) => {
  const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);
  const user = loadStateFromLocalStorage(authConstants.USER);
  const activeAccountndex = user.accounts.findIndex((account: Account) => account._id === activeAccount._id);

  if (!activeAccount || !user || activeAccountndex === -1) return;

  activeAccount.client.permissions.sharePoint.isConnected = true;
  user.accounts[activeAccountndex] = activeAccount;

  saveStateToLocalStorage(authConstants.ACTIVATE_ACCOUNT, activeAccount);
  saveStateToLocalStorage(authConstants.USER, user);

  dispatch(setAccount(activeAccount));
};