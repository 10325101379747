import { XRayData } from '../xRay/xRay';

export interface IDFTextFinderResponse {
  inventionDisclosureSearchId: string;
}

export interface IDFRequestInfo extends IDFTextFinderResponse {
  clientId: string;
}

export interface InventionDisclosureTextFinderParams {
  inventionDisclosureText: string;
  clientId: string;
}

export interface InventionDisclosureData extends XRayData {
  response: null | IDFResultsResponse;
  input_text?: string;
}

export interface IDFResultsResponse {
  trade_secrets: PotentialTradeSecret[];
  claims: PotentialClaim[];
  ts_embeddings: null;
  claims_embeddings: null;
  mapping: null | Mapping;
  scores: null | number[][];
}

export interface PotentialTradeSecret {
  subject: string;
  bullets: PotentialTradeSecretBullet[];
  id: string;
  isAssetCreated?: boolean;
}
export interface PotentialTradeSecretBullet {
  id: string;
  text: string;
  claim_ids: string[];
  isAssetCreated?: boolean;
}

export interface PotentialClaim {
  id: string;
  text: string;
  bullets_ids: string[];
  modes: Modes;
}

export type Modes = Record<MODE_TYPE, boolean>;

export enum MODE_TYPE {
  IS_CLAIM_CREATE_MODE = 'isClaimCreateMode',
  IS_CLAIM_EDIT_MODE = 'isClaimEditMode',
}

export interface InventionDisclosureFormValues {
  claims: PotentialClaimFormValue[];
}

type PotentialClaimFormValue = { text: string };

// Mapping type aliases for clarity
type TradeSecretIndex = number;
type BulletIndex = number;
type ClaimToTradeSecretAndBulletMapping = [TradeSecretIndex, BulletIndex][];
type Mapping = ClaimToTradeSecretAndBulletMapping[];
