import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const TreeMapChartContainer = styled(FlexContainer)`
  height: 630px;
  width: 700px;
  margin: ${SPACING[3]};
  flex-direction: column;
  align-items: center;
`;
