import styled, { css } from 'styled-components';

import { ASSET_TYPE_COLORS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const ColorBlockContainer = styled.div<{ assetType?: string }>`
  ${({ assetType = '' }) => css`
    width: ${SPACING[3]};
    height: ${SPACING[4]};
    display: flex;
    flex-wrap: nowrap;
    div:first-of-type {
      width: 10px;
      background: ${ASSET_TYPE_COLORS[assetType] ?? NEUTRAL_SHADES[1000]};
      height: 100%;
    }
    div:last-of-type {
      width: ${SPACING[1.5]};
      background: ${ASSET_TYPE_COLORS[assetType] ?? NEUTRAL_SHADES[1000]}50;
      height: 100%;
    }
  `}
`;

export const SharePointIconContainer = styled.div`
  width: ${SPACING[3]};
  height: ${SPACING[4]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
