import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { acknowledgePreviewAsset, deleteAssetFile, getFilePathUrl } from 'redux-toolkit/thunks/assetsThunks';
import { TangiButton, TangiTypography } from '../TangiLibrary';
import { Loader } from '../Loader';
import AssetOverview from '../AssetOverview/AssetOverview';
import AssetTypeColorBlock from '../../_components/AssetTypeColorBlock/AssetTypeColorBlock';
import { InvalidError } from '../InvalidError/InvalidError';
import { OtpScreenPreviewForm } from '../OtpScreenForm';
import { IRoles, isRoleMatch, regularRoles } from '../../utils/roles';
import { assetTypeNameFormatter } from '../../utils/assetTypes';
import { MAX_PREVIEW_IN_EMBED_FILE_SIZE } from '../../_constants/assetConstants';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { BOOK_A_DEMO_URL } from '_constants/consts';
import { Container, AssetContainer, SideMenu, TypeAndColor, WatermarkContainer } from './style';

function PreviewAsset() {
  const history = useHistory();
  const PreviewURL = process.env.REACT_APP_previewURL;
  const urlString = window.location.href;
  const url = new URL(urlString);
  const encodeToken = url.searchParams.get('token');
  const dispatch = useDispatch();

  const [isSpinner, setSpinner] = useState(false);
  const [OTPUI, setOTPUI] = useState(false);
  const [previeweData, setPreviewData] = useState(null);
  const { filePath, previewPostAsset, previewAsset } = useSelector((state) => state.asset);
  const { loggedIn, user, activeAccount } = useSelector((state) => state.authentication);
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [showFile, setFileShow] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (filePath && filePath.file) {
      setFileUrl(`${PreviewURL + filePath.file}`);
      reload();
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [filePath]);

  useEffect(() => {
    setOTPUI(true);
    dispatch(assetsActions.setAcknowledgePostClear());
    dispatch(assetsActions.setAcknowledgeGetClear());
    dispatch(acknowledgePreviewAsset(encodeToken));
  }, [dispatch]);

  useEffect(() => {
    if (previewPostAsset && previewPostAsset.name) {
      setOTPUI(false);
      setPreviewData(previewPostAsset);
      setSpinner(false);
    } else if (previewPostAsset && previewPostAsset.message) {
      setSpinner(false);
    } else {
      setTimeout(() => {
        setSpinner(false);
      }, 7000);
    }
  }, [previewPostAsset]);

  const handleOnClick = (urls) => {
    dispatch(assetsActions.setClearFilePathUrl());
    const name = urls && urls.name ? urls.name : '';
    if (name) {
      const assetparam = {
        fileName: urls.name,
        /* eslint no-underscore-dangle: 0 */
        clientId: previewPostAsset?.client?._id,
        assetId: previewPostAsset.id,
      };
      dispatch(getFilePathUrl({ data: assetparam, token: encodeToken, isPreview: true }));
      setFileName(name);
      handleFileShow();
    }
  };

  const handleFileClose = () => {
    if (fileName) {
      const assetparam = {
        fileName: fileName,
        assetId: previewPostAsset.id,
      };

      dispatch(deleteAssetFile({ data: assetparam, token: encodeToken }));
    }
    setFileShow(false);
    setLoading(true);
  };

  const handleFileShow = () => setFileShow(true);
  function reload() {
    setIndex((i) => i + 1);
  }

  function typeOfDocument() {
    const binaryData = atob(filePath?.base64);
    const dataSize = binaryData.length;
    const clientName = previewPostAsset?.createdBy?.partnership?.name || previewPostAsset?.client?.name || '';

    if ((filePath?.mimeType.includes('pdf') || filePath?.mimeType.includes('image')) && dataSize <= MAX_PREVIEW_IN_EMBED_FILE_SIZE) {
      return (
        <WatermarkContainer clientName={clientName || ''}>
          <embed title={fileName} src={`data:${filePath?.mimeType};base64,${filePath?.base64}#toolbar=0&navpanes=0&scrollbar=0`} width="100%" height="500" className="text-center" />
        </WatermarkContainer>
      );
    } else {
      return (
        <WatermarkContainer clientName={clientName || ''}>
          <iframe key={index} title={fileName} src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`} width="100%" height="500" className="text-center" />
        </WatermarkContainer>
      );
    }
  }

  const isRegularAdmin = (activeAccount) => {
    return (
      [IRoles.CONTRACTOR_ADMIN, IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER].includes(activeAccount?.role?.name) ||
      (isRoleMatch(activeAccount?.role?.name, IRoles.CLIENT_ADMIN) && activeAccount?.client)
    );
  };

  const isNormalUser = (activeAccount) => {
    return regularRoles.includes(activeAccount?.role?.name) && activeAccount?.client?._id;
  };

  const userRedirection = () => {
    if (isRegularAdmin(activeAccount) || isNormalUser(activeAccount)) {
      history.push(generateRoute(AppRoutes.HOME_LOBBY, { clientId: activeAccount?.client?._id }));
      window.location.reload();
    } else {
      history.push(AppRoutes.MAIN);
      window.location.reload();
    }
  };

  return (
    <div className="container-fluid">
      <Loader isLoading={isSpinner} />
      {(previewAsset && previewAsset.message && previewAsset.message === 'Unauthorized access') ||
      (previewPostAsset && previewPostAsset.message && previewPostAsset.message === 'Unauthorized access') ? (
        <InvalidError type="acknowledge" />
      ) : (
        <div>
          {OTPUI ? (
            <OtpScreenPreviewForm encodeToken={encodeToken} />
          ) : (
            <>
              <TangiTypography type="heading-xl">{previeweData?.name}</TangiTypography>
              {previeweData?.assetType?.name && (
                <TypeAndColor>
                  <AssetTypeColorBlock assetTypeName={previeweData?.assetType?.name || ''} />
                  <TangiTypography type="subheading">{assetTypeNameFormatter(previeweData.assetType.name)}</TangiTypography>
                </TypeAndColor>
              )}
              <Container>
                {previeweData && (
                  <AssetContainer>
                    <AssetOverview asset={previeweData} handleFileClick={handleOnClick} isPreview={true} />
                  </AssetContainer>
                )}
                <SideMenu>
                  <div className="d-flex justify-content-center">
                    {loggedIn ? (
                      <div className="d-block mt-15">
                        <h4 className="ff-din-regular-2 text-muted text-center">Hi, You are logged in as</h4>
                        <div className="fw-bolder fs-5 text-center">{user?.username}</div>
                        <div>
                          <TangiButton text="Go to Dashboard" onClick={() => userRedirection()} />
                        </div>
                      </div>
                    ) : previewAsset.recipients && (!previewAsset.recipients[0].registered || previewAsset.recipients[0].isPartner) ? (
                      <div className="d-flex flex-column mt-15">
                        <h3 className="ff-din-regular-2 text-center">Are you ready to start managing your trade secrets?</h3>
                        <br />
                        <h4 className="ff-din-regular-2 mx-4 text-muted text-center">Implement best practice and ensure your company’s most valuable assets are safe</h4>
                        <br />
                        <h4 className="ff-din-regular-2 text-muted text-center">Try Tangibly free for 30 days</h4>
                        <a className="btn btn-lg px-5 btn btn-primary text-white text-center align-self-center" href={BOOK_A_DEMO_URL}>
                          Book A Demo
                        </a>
                      </div>
                    ) : (
                      <div className="d-block">
                        <div className="h2 font-weight-normal text-dark m-0 mb-0 card-title ff-din-regular-2">Login or register and access more features</div>
                        <ul className="list-unstyled my-4 w-100">
                          <li className="my-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs icon-shape text-white shadow rounded-circle">
                                  <i aria-hidden="true" className="text-primary fas fa-check" />
                                </div>
                              </div>
                              <div>
                                <span className="ps-2 h4 text-dark font-weight-normal ff-din-regular-2">Asset registry</span>
                              </div>
                            </div>
                          </li>
                          <li className="my-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs icon-shape text-white shadow rounded-circle">
                                  <i aria-hidden="true" className="text-primary fas fa-check" />
                                </div>
                              </div>
                              <div>
                                <span className="ps-2 h4 text-dark font-weight-normal ff-din-regular-2">Digital acknowledgement </span>
                              </div>
                            </div>
                          </li>
                          <li className="my-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs icon-shape text-white shadow rounded-circle">
                                  <i aria-hidden="true" className="text-primary fas fa-check" />
                                </div>
                              </div>
                              <div>
                                <span className="ps-2 h4 text-dark font-weight-normal ff-din-regular-2">Training modules</span>
                              </div>
                            </div>
                          </li>
                          <li className="my-3">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="icon icon-xs icon-shape text-white shadow rounded-circle">
                                  <i aria-hidden="true" className="text-primary fas fa-check" />
                                </div>
                              </div>
                              <div>
                                <span className="ps-2 h4 text-dark font-weight-normal ff-din-regular-2">Complete documentation</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="text-left w-100 container-action-form-auth">
                          <h5 className="text-primary font-weight-normal">To learn more, please email info@tangibly.com</h5>
                        </div>
                        <h4 className="fw-lighter ff-din-regular-2 text-muted">Receive access to your account in your inbox</h4>
                      </div>
                    )}
                  </div>
                </SideMenu>
              </Container>
            </>
          )}
        </div>
      )}
      <Modal className="fileViewer" show={showFile} onHide={() => handleFileClose()}>
        {loading ? (
          <div className="content-center m-4">
            <Spinner animation="border" variant="primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div>
            <Modal.Header className="text-muted" closeButton>
              <Modal.Title>
                <span className="h3 font-weight-normal ff-din-regular text-dark mb-4">{fileName}</span>
                <span className="px-2">
                  <Button className="btn-secondary btnreload btn-sm px-2 py-1 shadow-none" onClick={() => reload()} variant="primary">
                    <i className="fas fa-sync" />
                  </Button>
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{typeOfDocument()}</Modal.Body>
          </div>
        )}
      </Modal>
    </div>
  );
}

export { PreviewAsset };
