import styled from 'styled-components';

import { SPACING } from 'utils/theme';
import BetaChip from '_components/BetaChip/BetaChip';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100%);
  padding: ${SPACING[5]};
  gap: ${SPACING[5]};
`;

export const MainSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: ${SPACING[7]};
  padding: 0 ${SPACING[9]};
  text-align: center;
`;

export const InnerMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 670px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING[5]};
  margin-top: ${SPACING[3]};
`;

export const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  width: 200px;
  padding-top: ${SPACING[2]};
  margin-right: ${SPACING[4]};
  position: sticky;
  position: -webkit-sticky; // Safari
  top: 80px;
`;

export const IconContainer = styled.div`
  position: relative;
  margin-bottom: ${SPACING[4]};
`;

export const TextAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const StyledBetaChip = styled(BetaChip)`
  position: absolute;
  top: 6px;
  left: -12px;
`;
