import { TangiSvgIcon } from '../_components/TangiLibrary';
import { getFileExtensionCard } from './fileUtils';
import { NEUTRAL_SHADES } from './theme';
import { FileNameText, NoFilesText } from '../pages/ClientAssets/styles';
import { isPartnershipDisabled } from '_components/ThirdPartyTag/utils';

// TODO: use enums instead - /utils/theme.ts
export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  TERTIARY_GREY: 'tertiary-grey',
  SECONDARY_PURPLE: 'secondary-purple',
  DANGER: 'danger',
};

// TODO: use enums instead - /utils/theme.ts
export const BUTTON_SIZES = {
  XS: 20,
  SM: 32,
  MD: 36,
  LG: 40,
};

// TODO: use enums instead - /utils/theme.ts
export const AVATAR_SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const FILE_EXTENSIONS = {
  eps: 'eps',
  xls: 'xls',
  xlsx: 'xlsx',
  jpeg: 'jpeg',
  jpg: 'jpg',
  pdf: 'pdf',
  png: 'png',
  ppt: 'ppt',
  pptx: 'pptx',
  doc: 'doc',
  docx: 'docx',
};

export const getFileIcon = (extension) => {
  switch (extension) {
    case FILE_EXTENSIONS.eps:
      return 'fileEps';

    case FILE_EXTENSIONS.xls:
    case FILE_EXTENSIONS.xlsx:
      return 'fileExcel';

    case FILE_EXTENSIONS.jpeg:
    case FILE_EXTENSIONS.jpg:
      return 'fileJpeg';

    case FILE_EXTENSIONS.pdf:
      return 'filePdf';

    case FILE_EXTENSIONS.png:
      return 'filePng';

    case FILE_EXTENSIONS.ppt:
    case FILE_EXTENSIONS.pptx:
      return 'filePp';

    case FILE_EXTENSIONS.doc:
    case FILE_EXTENSIONS.docx:
      return 'fileWord';

    default:
      return 'file';
  }
};

export const getIconFromFileName = (name, color = NEUTRAL_SHADES.BLACK) => {
  return <TangiSvgIcon color={color} component={getFileIcon(getFileExtensionCard(name))} />;
};

/**
 * Extracts information about attachments (files or URLs) and returns an object with relevant details for display.
 *
 * @param {Array} filesArr - An array of file objects, where each file object contains information about the file, such as its name, size, type, etc.
 * @param {Array} urlsArr - An array of URL objects, where each URL object contains information about the URL, such as its label, target, etc.
 * @param {boolean} partnership - An optional boolean flag indicating whether the user has a partnership. If `true`, the function will allow access to attachments; otherwise, attachments will be disabled.
 *
 * @returns {Object} An object containing attachment information with the following properties:
 *   - additionalAmount (number): An integer representing the number of additional attachments beyond the first one.
 *   - icon (React component): A React component representing the icon for the attachment (file type icon or link icon).
 *   - name (React component): A React component representing the name or label of the attachment (file name or "A link is attached").
 */
// TODO: remove and use useAssetAttachmentsInfo hook instead
export const getAttachmentsInfo = (filesArr, urlsArr, partnership = null) => {
  const attachmentsInfo = { additionalAmount: 0, icon: null, name: null };
  // Check if partnership is disabled, if yes, display "No access" message.
  if (isPartnershipDisabled(partnership)) {
    attachmentsInfo.name = <NoFilesText>No access</NoFilesText>;
    return attachmentsInfo;
  }
  if (filesArr?.length > 0) {
    attachmentsInfo.icon = getIconFromFileName(filesArr[0]?.name || filesArr[0]);
    attachmentsInfo.name = <FileNameText className="file-attachment-name">{filesArr[0]?.name || filesArr[0]}</FileNameText>;
    attachmentsInfo.additionalAmount = filesArr.length > 1 ? filesArr.length - 1 : 0;
    return attachmentsInfo;
  }
  if (urlsArr?.length > 0) {
    attachmentsInfo.icon = <TangiSvgIcon component="link" />;
    attachmentsInfo.name = <FileNameText>{urlsArr[0]?.label || 'A link is attached'}</FileNameText>;
    attachmentsInfo.additionalAmount = urlsArr.length > 1 ? urlsArr.length - 1 : 0;
    return attachmentsInfo;
  }
  attachmentsInfo.icon = <div style={{ marginLeft: '-12px' }} />;
  attachmentsInfo.name = <NoFilesText>No Files / URLs Included</NoFilesText>;
  return attachmentsInfo;
};
