export const adminClientContants = {
  ADMIN_CLIENTS_REQUEST: 'ADMIN_CLIENTS_REQUEST',
  ADMIN_CLIENTS_SUCCESS: 'ADMIN_CLIENTS_SUCCESS',
  ADMIN_CLIENTS_FAILURE: 'ADMIN_CLIENTS_FAILURE',
  ADMIN_CLIENTS_COUNT_REQUEST: 'ADMIN_CLIENTS_COUNT_REQUEST',
  ADMIN_CLIENTS_COUNT_SUCCESS: 'ADMIN_CLIENTS_COUNT_SUCCESS',
  ADMIN_CLIENTS_COUNT_FAILURE: 'ADMIN_CLIENTS_COUNT_FAILURE',
  ADMIN_LAWFIRM_REQUEST: 'ADMIN_LAWFIRM_REQUEST',
  ADMIN_LAWFIRM_SUCCESS: 'ADMIN_LAWFIRM_SUCCESS',
  ADMIN_LAWFIRM_FAILURE: 'ADMIN_LAWFIRM_FAILURE',
  ADMIN_LAWFIRM_EDIT_REQUEST: 'ADMIN_LAWFIRM_EDIT_REQUEST',
  ADMIN_LAWFIRM_EDIT_SUCCESS: 'ADMIN_LAWFIRM_EDIT_SUCCESS',
  ADMIN_LAWFIRM_EDIT_FAILURE: 'ADMIN_LAWFIRM_EDIT_FAILURE',
  ADMIN_LAWFIRM_DELETE_REQUEST: 'ADMIN_LAWFIRM_DELETE_REQUEST',
  ADMIN_LAWFIRM_DELETE_SUCCESS: 'ADMIN_LAWFIRM_DELETE_SUCCESS',
  ADMIN_LAWFIRM_DELETE_FAILURE: 'ADMIN_LAWFIRM_DELETE_FAILURE',
  ADMIN_LAWFIRM_GET_REQUEST: 'ADMIN_LAWFIRM_GET_REQUEST',
  ADMIN_LAWFIRM_GET_SUCCESS: 'ADMIN_LAWFIRM_GET_SUCCESS',
  ADMIN_LAWFIRM_GET_FAILURE: 'ADMIN_LAWFIRM_GET_FAILURE',

  ADMIN_CLIENTS_CREATE: 'ADMIN_CLIENTS_CREATE',
  ADMIN_CLIENTS_CREATE_SUCCESS: 'ADMIN_CLIENTS_CREATE_SUCCESS',
  ADMIN_CLIENTS_CREATE_FAILURE: 'ADMIN_CLIENTS_CREATE_FAILURE',

  ADMIN_CLIENTS_LOGO_SUCCESS: 'ADMIN_CLIENTS_LOGO_SUCCESS',
  ADMIN_CLIENTS_LOGO_FAILURE: 'ADMIN_CLIENTS_LOGO_FAILURE',

  ADMIN_CLIENT_REQUEST: 'ADMIN_CLIENT_REQUEST',
  ADMIN_CLIENT_SUCCESS: 'ADMIN_CLIENT_SUCCESS',
  ADMIN_CLIENT_FAILURE: 'ADMIN_CLIENT_FAILURE',

  ADMIN_CLIENT_EDIT: 'ADMIN_CLIENT_EDIT',
  ADMIN_CLIENT_EDIT_SUCCESS: 'ADMIN_CLIENT_EDIT_SUCCESS',
  ADMIN_CLIENT_EDIT_FAILURE: 'ADMIN_CLIENT_EDIT_FAILURE',

  ADMIN_CLIENTS_SAVE_SORT: 'ADMIN_CLIENTS_SAVE_SORT',
};
