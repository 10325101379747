import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import XrayStatusNotification from '_components/XrayStatusNotification/XrayStatusNotification';
import { RootState } from '_helpers';
import { XRAY_JOB_STATUS, XrayLoaders } from 'utils/types/xRay/xRay';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';
import { LOADING_TRANSLATION_KEY } from '_components/XrayStatusNotification/types';
import { NOTIFICATION_VARIANTS, TangiButton, TangiNotification, TangiTypography } from '_components/TangiLibrary';

const RecentResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${SPACING[2]};
`;

const SeeResultsBtnContainer = styled.div`
  margin-top: -${SPACING[2]};
  margin-left: ${SPACING[3]};
`;

interface Props {
  status: XRAY_JOB_STATUS;
  details: string;
  onClick: () => void;
  onCancel: () => void;
}

const InventionDisclosureStatusNotification = (props: Props) => {
  const { t } = useTranslation();

  const idfLoaders: XrayLoaders = useSelector((state: RootState) => state.inventionDisclosure.idfLoaders);
  const mapping = useSelector((state: RootState) => state.inventionDisclosure.inventionDisclosureData?.response?.mapping);

  const renderJobSucceededNotification = () => {
    return (
      <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO} startIcon={false}>
        <TangiTypography weight="semibold" type="heading-md" color={PRIMARY_SHADES[800]}>
          {t('DISCOVERY_LOBBY.PATENT_NOTIFICATION.RECENT_SEARCH')}
        </TangiTypography>
        <RecentResultsContainer>
          <TangiTypography weight="semibold" type="subheading">
            {t('DISCOVERY_LOBBY.INVENTION_DISCLOSURE_NOTIFICATION.WE_FOUND_POTENTIAL_TS_IN_IDF')}
          </TangiTypography>
          {mapping && (
            <SeeResultsBtnContainer>
              <TangiButton
                variant="secondary"
                text={t('DISCOVERY_LOBBY.PATENT_NOTIFICATION_BUTON.SEE_RESULTS')}
                onClick={props.onClick}
                endIcon={true}
                svgIcon="arrowRight"
                data-testid="idf-recent-results"
              />
            </SeeResultsBtnContainer>
          )}
        </RecentResultsContainer>
      </TangiNotification>
    );
  };

  return (
    <XrayStatusNotification
      status={props.status}
      loadingTranslationKey={LOADING_TRANSLATION_KEY.INVENTION_DISCLOSURE}
      isCancelDisabled={idfLoaders.startLoader || idfLoaders.getResultLoader}
      errorDetails={props.details}
      onCancel={props.onCancel}
      statusSucceededComponent={renderJobSucceededNotification()}
    />
  );
};

export default InventionDisclosureStatusNotification;
