import { useTranslation } from 'react-i18next';

import { Content, IconAndText, Point, PointsContainer, StyledDivider, StyledModalBody, StyledModalFooter, StyledModalHeader, StyledTangiSvgIcon, TitleCardContainer } from './style';
import { TangiButton, TangiIconButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { StyledModal } from 'utils/globalStyles';
import { LOBBY_CARD_ID } from 'pages/Lobby/types';
import { BUTTON_VARIANTS, NEUTRAL_SHADES, SECONDARY_SHADES } from 'utils/theme';
import { getIconByCardId, getMediaContentByCardId, pointsByCardId } from './utils';

interface Props {
  cardId: LOBBY_CARD_ID | undefined;
  isButtonLoading: boolean;
  onClose: () => void;
  onRequestAccess: () => void;
}

const RequestAccessModal = ({ cardId, isButtonLoading, onClose, onRequestAccess }: Props) => {
  const { t } = useTranslation();

  const points = cardId ? pointsByCardId[cardId] ?? [] : [];
  const icon = getIconByCardId(cardId);

  const renderModalHeader = () => {
    return (
      <StyledModalHeader>
        <TitleCardContainer>
          <IconAndText>
            <StyledTangiSvgIcon component={icon} color={SECONDARY_SHADES[800]} />
            <TangiTypography data-testid="request-access-modal-header-text" type="heading-lg" color={SECONDARY_SHADES[800]}>
              {t(`LOBBY_PAGE.REQUEST_ACCESS_MODAL.${cardId}.HEADER`)}
            </TangiTypography>
          </IconAndText>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onClose} icon="close" data-testid="close-modal-button" />
        </TitleCardContainer>
      </StyledModalHeader>
    );
  };

  const renderModalBody = (): JSX.Element => {
    return (
      <StyledModalBody data-testid="request-access-modal">
        {!!cardId && getMediaContentByCardId(cardId)}
        <Content>
          <TangiTypography type="heading-md" weight="bold">
            {t(`LOBBY_PAGE.REQUEST_ACCESS_MODAL.${cardId}.TITLE`)}
          </TangiTypography>
          <TangiTypography>{t(`LOBBY_PAGE.REQUEST_ACCESS_MODAL.${cardId}.SUB_TITLE`)}</TangiTypography>
          <PointsContainer>
            {points.map((point, index) => (
              <Point key={index}>
                <TangiSvgIcon component="circleChecked" color={SECONDARY_SHADES[800]} />
                <TangiTypography color={NEUTRAL_SHADES[900]}>{t(point)}</TangiTypography>
              </Point>
            ))}
          </PointsContainer>
        </Content>
      </StyledModalBody>
    );
  };

  const renderModalFooter = () => {
    return (
      <StyledModalFooter>
        <TangiButton
          text={t('LOBBY_PAGE.REQUEST_ACCESS_MODAL.BUTTON_TEXT')}
          variant={BUTTON_VARIANTS.PRIMARY_PURPLE}
          onClick={onRequestAccess}
          loading={isButtonLoading}
          data-testid="request-access-button"
          smallbtn
        />
      </StyledModalFooter>
    );
  };

  return (
    <StyledModal transition="Fade" show={!!cardId} onHide={onClose} data-testid="mapping-pop-up">
      {renderModalHeader()}
      <StyledDivider />
      {renderModalBody()}
      <StyledDivider />
      {renderModalFooter()}
    </StyledModal>
  );
};

export default RequestAccessModal;
