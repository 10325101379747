import styled, { css } from 'styled-components';
import { Spinner } from 'react-bootstrap';

import { TangiTypography } from '../../_components/TangiLibrary';
import { StyledCard, PageHeaderContainer, ContentViewContainer, FlexContainer } from '../../utils/globalStyles';
import { SUCCESS_SHADES, SPACING } from '../../utils/theme';

// --- Page header ---

export const TopPageContainer = styled(PageHeaderContainer)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: ${SPACING[5]};
`;

export const GridContainerRight = styled.div<{ loading: boolean }>``;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${SPACING[3]};
`;

export const FlexRow = styled(FlexContainer)`
  gap: ${SPACING[1]};
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  gap: ${SPACING[3]};
`;

export const ContentGridContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${SPACING[3]};
  margin-bottom: ${SPACING[3]};
`;

export const MappingContainer = styled.div`
  padding: ${SPACING[0]} ${SPACING[5]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const CenteredGridColumn = styled(FlexContainer)`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  gap: ${SPACING[3]};
`;

export const RightColumnCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
`;

// --- TS form patent cards ---

export const SubjectCard = styled(StyledCard)`
  border-radius: ${SPACING[2]};
  padding: ${SPACING[4]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;

export const CardTitle = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  gap: ${SPACING[4]};
  align-items: center;
`;

// --- Inventors ---

export const InventorsContainer = styled(FlexRow)`
  display: flex;
  align-items: flex-start;
`;

export const InventorsNamesRow = styled(FlexContainer)`
  flex-wrap: wrap;
  gap: ${SPACING[1]};
  flex: 1;
`;

export const ExportInventorsNamesRow = styled(FlexContainer)`
  flex-wrap: wrap;
  gap: ${SPACING[1]};
  flex: 1;
`;

export const InventorsNameTypography = styled(TangiTypography)`
  white-space: nowrap;
  line-height: ${SPACING[3.5]};
`;

export const ExportInventorsNameTypography = styled(TangiTypography)`
  white-space: nowrap;
  line-height: ${SPACING[5]};
  font-family: 'NotoSansJP-Regular';
`;

// --- Loader ---

export const LoaderContainer = styled(ContentViewContainer)`
  display: flex;
  justify-content: center;
  gap: ${SPACING[3]};
  height: calc(100vh - ${SPACING[9]});
  align-items: center;
  flex-direction: column;
  .spinner-border {
    width: 56px;
    height: 56px;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0; // Adjust as needed
  right: 0; // Adjust as needed
  padding: ${SPACING[1]}; // Provides some space from the edges
`;

export const UploadModifiedFileCard = styled(SubjectCard)`
  padding: ${SPACING[3]};
  width: 190px;
  gap: ${SPACING[3]};
  transition: transform 0.5s ease-in-out;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1500;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
  }
  &.visible {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

// --- Bullets ---
interface IBulletContainerProps {
  isFound: boolean;
  isActive: boolean;
}

export const BulletContainer = styled(FlexContainer)<IBulletContainerProps>`
  ${({ isFound, isActive }) => css`
    .tangi-typography {
      background-color: ${isActive && isFound ? SUCCESS_SHADES[100] : isActive && !isFound ? SUCCESS_SHADES[1000] : 'none'};
    }
    &:hover {
      .tangi-typography {
        background-color: ${isFound ? SUCCESS_SHADES[100] : SUCCESS_SHADES[1000]};
      }
    }
    cursor: pointer;
    ${isActive &&
    css`
      .tangi-typography {
        background-color: ${isFound ? SUCCESS_SHADES[100] : SUCCESS_SHADES[1000]};
      }
    `}
  `}
`;

export const customTitleTypographyStyles = `
  .tangi-icon-container {
    display: inline;
    svg.tangi-icon-svg{
      margin-bottom: ${SPACING[1]};
    }
  }
`;

export const customBulletTypographyStyles = `
  padding-inline: 2px;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${SPACING[2]};
`;

export const CustomSpinner = styled(Spinner)`
  width: 50px;
  height: 50px;
`;
