import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import Logout from '../Logout';
import SwitchUserLanguage from '../SwitchUserLanguage';
import AccountDropdownItem from '../AccountDropdownItem';
import { config } from 'config/config';
import { RootState } from '_helpers';
import { history } from '_helpers/history';
import { Account } from 'utils/types/account/account';
import { Role } from 'utils/types/role/role';
import { lawyers, getRedirectRoute } from 'utils/roles';
import { parseEnvBoolean } from 'utils/parseEnvBoolean';
import { CLIENT_TYPE } from 'utils/enums';
import { removeStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { StyledAccountNav, NavTitle, AccountsTitle, UserAccountsContainer } from './style';
import { authConstants, lawFirmConstants } from '_constants';

interface Props {
  handleLogout: () => void;
}

const SwitchAccountDropdown = ({ handleLogout }: Props) => {
  const { user, activeAccount, Role } = useSelector((state: RootState) => state.authentication);
  const aliasIdEmail = useSelector((state: RootState) => state.authentication?.user?.aliasIdEmail);

  const { t } = useTranslation();

  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);

  //Handle switching to another user's account and updating the active account.
  const handleSwitchAccount = (newAccount: Account) => {
    if (activeAccount._id !== newAccount._id) {
      saveStateToLocalStorage(authConstants.ACTIVATE_ACCOUNT, newAccount);
      isLawyer && removeStateFromLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT);
      history.push(getRedirectRoute(newAccount));
      window.location.reload();
    }
  };

  return (
    <StyledAccountNav>
      <NavDropdown
        className="profile-dropdown-toggle navbar-account"
        data-testid="user-dropdown"
        title={
          <NavTitle>
            {activeAccount.client?.name ?? activeAccount.lawFirm?.name}
            <Avatar name={user?.fullName} tooltip={false} />
          </NavTitle>
        }
        id="nav-dropdown1"
      >
        <NavDropdown.ItemText className="navbar-text-small">{user?.email}</NavDropdown.ItemText>
        <NavDropdown.Divider />
        <AccountsTitle>
          <TangiTypography type="body" weight="bold">
            {t('SETTINGS_PAGE.TITLE.ACCOUNTS')}
          </TangiTypography>
        </AccountsTitle>
        <UserAccountsContainer>
          {user?.accounts?.map((account: Account) => {
            const clientType = lawyers.includes((account.role as Role).name) ? CLIENT_TYPE.LAW_FIRM : CLIENT_TYPE.CLIENT;
            return (
              <AccountDropdownItem
                key={account._id}
                account={account}
                clientType={clientType}
                aliasIdEmail={aliasIdEmail}
                handleSwitchAccount={handleSwitchAccount}
                activeAccountId={activeAccount._id}
              />
            );
          })}
        </UserAccountsContainer>
        <NavDropdown.Divider />

        {parseEnvBoolean(config.isShowLanguage || '') && <SwitchUserLanguage userId={user?._id || ''} />}
        <Logout handleLogout={handleLogout} />
      </NavDropdown>
    </StyledAccountNav>
  );
};

export default SwitchAccountDropdown;
