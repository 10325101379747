import styled from 'styled-components';
import AvatarName from '../../Common/AvatarName';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { WidgetAccountObject } from '../Widgets.types';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-right: ${SPACING[3]};
`;

type Props = WidgetAccountObject;

const ContributorItem = (props: Props) => {
  return (
    <ItemContainer data-testid="contributor-item">
      <AvatarName {...props} />
      <TangiTypography color={NEUTRAL_SHADES[700]}>{props.value}</TangiTypography>
    </ItemContainer>
  );
};

export default ContributorItem;
