export const adminAgreementContants = {
  ADMIN_AGREEMENT_REQUEST: 'ADMIN_AGREEMENT_REQUEST',
  ADMIN_AGREEMENT_SUCCESS: 'ADMIN_AGREEMENT_SUCCESS',
  ADMIN_AGREEMENT_FAILURE: 'ADMIN_AGREEMENT_FAILURE',
  ADMIN_AGREEMENT_TYPE_REQUEST: 'ADMIN_AGREEMENT_TYPE_REQUEST',
  ADMIN_AGREEMENT_TYPE_SUCCESS: 'ADMIN_AGREEMENT_TYPE_SUCCESS',
  ADMIN_AGREEMENT_TYPE_FAILURE: 'ADMIN_AGREEMENT_TYPE_FAILURE',

  CREATING_AGREEMENT: 'CREATING_AGREEMENT',
  AGREEMENT_CREATED: 'AGREEMENT_CREATED',
  AGREEMENT_SUCCESS: 'AGREEMENT_SUCCESS',
  AGREEMENT_FAILURE: 'AGREEMENT_FAILURE',
  AGREEMENT_FILEUPLOAD_SUCCESS: 'AGREEMENT_FILEUPLOAD_SUCCESS',
  AGREEMENT_FILEUPLOAD_FAILURE: 'AGREEMENT_FILEUPLOAD_FAILURE',
  CLEAR_CREATE_STATUS: 'CLEAR_CREATE_STATUS',

  UPDATING_AGREEMENT: 'UPDATING_AGREEMENT',
  AGREEMENT_UPDATED: 'AGREEMENT_UPDATED',
  AGREEMENT_UPDATED_SUCCESS: 'AGREEMENT_UPDATED_SUCCESS',
  AGREEMENT_UPDATED_FAILED: 'AGREEMENT_UPDATED_FAILED',
  CLEAR_UPDATE_STATUS: 'CLEAR_UPDATE_STATUS',
};
