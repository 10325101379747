import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Card } from 'react-bootstrap';

const AcknowledgementModalSkeleton = () => {
  return (
    <>
      {[1, 2].map((elem, index) => (
        <Card className="my-2" key={index}>
          <Card.Body>
            <Skeleton key={elem} height={90} />
          </Card.Body>
        </Card>
      ))}
    </>
  );
};

export default AcknowledgementModalSkeleton;
