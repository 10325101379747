import { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFormLabel = styled(Form.Label)`
  margin-left: 5px;
`;

interface Props {
  customStyles?: CSSProperties;
}

const Star = ({ customStyles }: Props) => {
  return (
    <StyledFormLabel className="text-danger" style={customStyles}>
      *
    </StyledFormLabel>
  );
};

export { Star };
