import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import XRayPageLayout from '_components/XRayPageLayout/XRayPageLayout';
import { RootState } from '_helpers';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import { MIME_TYPES } from 'utils/enums';
import { getContinuationAnalysisByFile } from 'redux-toolkit/thunks/patentThunks';
import { PRIMARY_SHADES } from 'utils/theme';
import ContinuationAnalysisContent from './ContinuationAnalysisContent';
import { CircleBackground, StyledTangiSvgIcon } from 'pages/PatentXRay/styled';
import ContinuationAnalysisFilesToast from './ContinuationAnalysisFilesToast';

const ContinuationAnalysis = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { clientId } = useParams<{ clientId: string }>();
  const isModalOpen = useSelector((state: RootState) => state.patent.isModalOpen);

  const patentModel = useSelector((state: RootState) => state.patent.patentModel);

  const isAdmin = true; //TODO: who is admin?

  const handleUploadFile = useCallback(
    (file: File[]) => {
      dispatch(getContinuationAnalysisByFile({ isAdmin, files: file, clientId }));
    },
    [clientId, dispatch, patentModel],
  );

  const handleSubmitFileModal = (file: File[]) => {
    handleUploadFile(file);
    dispatch(patentActions.toggleUploadPatentFileModal(false));
  };

  return (
    <XRayPageLayout
      iconComponent={
        <CircleBackground>
          <StyledTangiSvgIcon component="frame" size="130px" color={PRIMARY_SHADES[800]} />
        </CircleBackground>
      }
      title={t('DISCOVERY_LOBBY.TITLE.CONTINUATION_ANALYSIS')}
      subTitle={t('DISCOVERY_LOBBY.CARD_TEXT.CONTINUATION_ANALYSIS_DESCRIPTION')}
      showBetaChip
    >
      <>
        <ContinuationAnalysisContent />
        <UploadFileModal
          acceptedFilesList={[MIME_TYPES.DOC, MIME_TYPES.DOCX].join(', ')}
          isOpen={isModalOpen.uploadPatentFile}
          title={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_PATENT_APPLICATION_FILE')}
          onClose={() => dispatch(patentActions.toggleUploadPatentFileModal(false))}
          onSubmit={handleSubmitFileModal}
          submitButtonText={t('DISCOVERY_LOBBY.BUTTONS_TEXT.ANALYZE_AND_DOWNLOAD_REPORT')}
          customDragNDropText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.DRAG_AND_DROP_WORD_FILE')}
          customDragNDropRejectText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_WORD_FILE')}
        />
      </>
      <ContinuationAnalysisFilesToast />
    </XRayPageLayout>
  );
};

export default ContinuationAnalysis;
