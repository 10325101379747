import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${SPACING[3]};
  color: #333;
  width: 100%;
  gap: ${SPACING[2]};
`;

export const BreadcrumbItemSpan = styled.div`
  max-width: 120px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
