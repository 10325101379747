import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { NOTIFICATION_VARIANTS, TangiNotification, TangiTypography } from '_components/TangiLibrary';
import { RootState } from '_helpers';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { SPACING } from 'utils/theme';

export const ErrorNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[3]};
  b {
    font-family: 'DIN2014-DemiBold';
  }
`;

interface ErrorMessageConfig {
  text: string;
  testId: string;
  parameters?: object;
}

const PatentToolHeaderError = () => {
  const { t } = useTranslation();
  const patentData = useSelector((state: RootState) => state.patent.patentData);

  const ERROR_MESSAGE_MAP: Record<string, ErrorMessageConfig> = {
    'Claims not found': {
      text: 'PATENT_MAPPING_TOOL.NOTIFICATION.NO_CLAIMS',
      testId: 'no-claims-file-message',
      parameters: { FILE_TYPE: XRAY_SOURCE_TYPE.FILE },
    },
    'Description not found': {
      text: 'PATENT_MAPPING_TOOL.NOTIFICATION.DESC_NOT_FOUND',
      testId: 'no-description-message',
    },
  };

  const defaultErrorMessage: ErrorMessageConfig = {
    text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_SEARCHING_AGAIN_EXPORT',
    testId: 'something-wrong-message',
  };

  const errorMessage = useMemo(() => {
    return ERROR_MESSAGE_MAP[patentData?.details] ?? defaultErrorMessage;
  }, [patentData?.details]);

  return (
    <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>
      <ErrorNotificationContainer data-testid={errorMessage?.testId}>
        <TangiTypography type="subheading">{t(errorMessage?.text)}</TangiTypography>
      </ErrorNotificationContainer>
    </TangiNotification>
  );
};

export default PatentToolHeaderError;
