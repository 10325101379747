import styled from 'styled-components';

export const UL = styled.ul`
  margin: 0;
  padding: 0;
  &.rtl svg {
    margin-left: 5px;
    margin-right: 0;
  }
`;
export const LI = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 4px 0;
  & > span {
    padding-top: 2px;
    opacity: ${(props) => (props.className === 'valid' ? 1 : 0.5)};
    flex: 1;
  }
`;
export const Svg = styled.svg`
  margin-right: 5px;
`;
