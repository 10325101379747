import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  onHide: () => void;
  isShow: boolean;
  handleConfirmation: () => void;
  onCancel: () => void;
}

const ConfirmationModal = ({ onHide, isShow, handleConfirmation, onCancel }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal transition="Fade" backdrop="static" size="sm" className="modal-close-confirm" show={isShow} onHide={onHide}>
      <Modal.Header />
      <Modal.Body className="my-4">
        <span className="font-weight-normal text-dark text-center mb-4">{t('PATENT_TOOL.MODAL.ARE_YOU_SURE')}</span>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button type="button" className="px-5 btn btn-outline-primary" onClick={handleConfirmation}>
          {t('GENERAL.BUTTONS_TEXT.YES')}
        </button>
        <button type="button" className="px-5 btn btn-primary text-white" onClick={onCancel}>
          {t('GENERAL.BUTTONS_TEXT.NO')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
