import { useComponentVisible } from '../../../utils/customHooks';
import { StyledDropdownButton, StyledDropDown, StyledDropDownItem } from './style';

export const TangiDropdownButton = ({ arrow = true, svgIcon = '', options, dropdownStyles = {}, ...props }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleClick = (handler) => {
    handler();
    setIsComponentVisible(false);
  };

  return (
    <div ref={ref}>
      <StyledDropdownButton isActive={isComponentVisible} {...props} onClick={() => setIsComponentVisible((prev) => !prev)} svgIcon={arrow ? 'arrowDown' : svgIcon || ''} />
      {isComponentVisible && (
        <StyledDropDown dropdownStyles={dropdownStyles} data-testid="dropdown-menu">
          {options.map((item, index) => (
            <StyledDropDownItem
              data-testid="dropdown-item"
              disabled={item?.disabled}
              key={index}
              onClick={() => (item?.disabled ? undefined : handleClick(item.handleItem))}
              className="ff-din-regular tangi-dropdown-item"
            >
              {item.text}
            </StyledDropDownItem>
          ))}
        </StyledDropDown>
      )}
    </div>
  );
};
