import { clientSettingsConstants } from '../_constants/clientSettingsConstants';

const initialState = {
  settings: {},
  loading: false,
};

export function clientSettings(state = initialState, action) {
  switch (action.type) {
    case clientSettingsConstants.CLEAR_CLIENT_SETTINGS:
      return {
        ...state,
        settings: {},
        loading: false,
      };
    case clientSettingsConstants.CREATE_CLIENT_SETTINGS_REQUEST:
      return {
        ...state,
        settings: {},
        loading: true,
      };
    case clientSettingsConstants.CREATE_CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data,
        loading: false,
      };
    case clientSettingsConstants.UPDATE_CLIENT_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {},
        loading: false,
      };
    case clientSettingsConstants.GET_CLIENT_SETTINGS_REQUEST:
      return {
        ...state,
        settings: {},
        loading: true,
      };
    case clientSettingsConstants.GET_CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data,
        loading: false,
      };
    case clientSettingsConstants.GET_CLIENT_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {},
        loading: false,
      };
    case clientSettingsConstants.UPDATE_CLIENT_SETTINGS_REQUEST:
      return {
        ...state,
        settings: {},
        loading: true,
      };
    case clientSettingsConstants.UPDATE_CLIENT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data,
        loading: false,
      };
    case clientSettingsConstants.UPDATE_CLIENT_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {},
        loading: false,
      };
    default:
      return state;
  }
}
