import React, { useMemo, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { createUser, updateUser } from 'redux-toolkit/thunks/usersThunks';
import { createAgreementVersion } from 'redux-toolkit/thunks/clientThunks';
import { createBusinessUnit, createDepartment } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { assetMetaDataActions } from 'redux-toolkit/slices/assetMetaDataSlice';
import { clientActions as clientRedux } from 'redux-toolkit/slices/clientSlice';
import { agreementActions } from '../../_actions';
import { TangiInput, TangiSelector, TangiCreateableSelector, TangiDatePicker, TangiToggle, TangiCheckbox, TangiAlert, TangiButton, TangiAccountStatus } from '../TangiLibrary';
import { Agreement } from '../Agreement';
import ManagerNotification from './components/ManagerNotification';
import { allPermissionsExceptLearn, IRoles, createableRoles, createableRolesLearn } from '../../utils/roles';
import { getSelectorOptions, countriesOptions } from '../../utils/getOptions';
import { apiToFormFormat, formToApiFormat } from './utils';
import { getSettingRoleName } from '../ViewEmployee/utils';
import { validEmailPattern, linkedinUrlPattern } from '../../utils/formUtils';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { RESULT_STATUS, ACCOUNT_STATUS } from '../../utils/enums';
import { FormFieldNames, TranslationKeys } from './types';
import { Container, FormContainer, DuoContainer, Separator, SectionTitle, StatusContainer } from './style';

export const InviteAccountForm = ({ accountData = null, isEditMode = false, handleClose, createdFrom = '', currentRecipientEmail = '', assetId = '' }) => {
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });
  const [isAccountActive, setIsAccountActive] = useState(true);

  const settings = useSelector((state) => state.clientSettings.settings);
  const Role = useSelector((state) => state.authentication.Role);
  const user = useSelector((state) => state.authentication.user);
  const { loading: metaDataLoading, departments, businessUnits, creatableLoading, newBusinessUnit, newDepartment } = useSelector((state) => state.assetMetaData);
  const { agreementVersions, newAgreementVersion, newAgreementVersionIndex, loading: versionsLoading } = useSelector((state) => state.client);
  const { loading: userLoading, error: userError, status: userStatus } = useSelector((state) => state.users);
  const { loading: agreementLoading, error: agreementError, agreementSuccess } = useSelector((state) => state.agreement);

  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: isEditMode
      ? apiToFormFormat(accountData)
      : {
          externalUser: user.externalUser,
          createdFrom: createdFrom,
          email: currentRecipientEmail,
          assetId,
        },
  });

  const selectedRole = useWatch({
    control: control,
    name: 'role',
  })?.value;

  const isUserLearn = useMemo(() => {
    return Role.includes('Learn');
  }, [Role]);

  const agreementsByRoleAndSettings = useMemo(() => {
    if (settings?.agreements) {
      switch (selectedRole) {
        case IRoles.CONTRACTOR:
        case IRoles.CONTRACTOR_OTHER:
        case IRoles.CONTRACTOR_ADMIN:
          settings.agreements[IRoles.CONTRACTOR].forEach((agreementType, index) => {
            setValue(`agreements[${index}].agreementType`, agreementType.id);
          });
          return settings.agreements[IRoles.CONTRACTOR];
        case IRoles.EMPLOYEE:
        case IRoles.EMPLOYEE_ADMIN:
        case IRoles.EMPLOYEE_MANAGER:
        case IRoles.EMPLOYEE_HR:
          settings.agreements[IRoles.EMPLOYEE].forEach((agreementType, index) => {
            setValue(`agreements[${index}].agreementType`, agreementType.id);
          });
          return settings.agreements[IRoles.EMPLOYEE];
        default:
          return [];
      }
    } else {
      return [];
    }
  }, [selectedRole, settings]);

  const handleCreateOption = async (inputValue, type, index) => {
    switch (type) {
      case 'department':
        dispatch(createDepartment({ name: inputValue, client: [clientId] }));
        break;
      case 'businessUnit':
        dispatch(createBusinessUnit({ name: inputValue, client: [clientId] }));
        break;
      case 'version':
        dispatch(createAgreementVersion({ agreement: { name: inputValue, client: [clientId] }, index }));
        break;
      default:
    }
  };

  useEffect(() => {
    if (newDepartment) {
      const departmentsCurrentValues = getValues().departments;
      const departmentsValue = departmentsCurrentValues
        ? [...departmentsCurrentValues, { value: newDepartment.id, label: newDepartment.name }]
        : [{ value: newDepartment.id, label: newDepartment.name }];
      setValue(FormFieldNames.DEPARTMENTS, departmentsValue);
    }
  }, [newDepartment]);

  useEffect(() => {
    if (newAgreementVersion && newAgreementVersionIndex !== null) {
      const versionsValue = { value: newAgreementVersion.id, label: newAgreementVersion.name };
      setValue(`agreements[${newAgreementVersionIndex}].version`, versionsValue);
    }
  }, [newAgreementVersion]);

  useEffect(() => {
    if (newBusinessUnit) {
      const currentValues = getValues().businessUnits;
      const filteredValue = currentValues ? [...currentValues, { value: newBusinessUnit.id, label: newBusinessUnit.name }] : [{ value: newBusinessUnit.id, label: newBusinessUnit.name }];
      setValue(FormFieldNames.BUSINESS_UNITS, filteredValue);
    }
  }, [newBusinessUnit]);

  useEffect(() => {
    if (userError) {
      setAlertProps(() => {
        return { show: true, type: 'error', text: userError };
      });
    } else if (agreementError?.message) {
      setAlertProps({ show: true, type: 'error', text: agreementError.message });
    }
  }, [userError, agreementError]);

  useEffect(() => {
    batch(() => {
      dispatch(agreementActions.clearStatus());
      dispatch(usersActions.setClearUser());
    });
    return () => {
      batch(() => {
        dispatch(agreementActions.clearStatus());
        dispatch(usersActions.setClearUser());
        dispatch(clientRedux.setClearGetAgreementVersions());
        dispatch(assetMetaDataActions.setClearCreatable());
      });
    };
  }, []);

  useEffect(() => {
    if (userStatus) {
      if (isUserLearn) {
        handleClose();
        dispatch(usersActions.setPeopleToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: userStatus }));
      } else if (agreementSuccess) {
        handleClose();
        dispatch(usersActions.setPeopleToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: userStatus }));
      }
    }
  }, [userStatus, agreementSuccess]);

  const handleFiles = (acceptedFiles, fileRejections, id) => {
    if (acceptedFiles.length > 0) {
      setValue(id, acceptedFiles);
    }
  };

  const onSubmit = (data) => {
    let payload = formToApiFormat(data);
    if (isEditMode) {
      delete payload.email;
      delete payload.externalUser;
      payload = {
        ...payload,
        client: clientId,
        id: accountData.id,
      };
      dispatch(updateUser({ user: payload, id: accountData.id }));
    } else {
      payload = {
        ...payload,
        client: clientId,
        status: isAccountActive ? ACCOUNT_STATUS.PENDING : ACCOUNT_STATUS.DISABLED,
        createdFrom,
        assetId,
      };
      const shouldCreateAgreement = !isUserLearn;
      dispatch(createUser({ user: payload, shouldCreateAgreement }));
      dispatch(
        mixpanelEvents.inviteAccount({
          accountData: payload,
        }),
      );
    }
  };

  const AgreementsSection = ({ agreements, settings, role }) => {
    const roleTypeName = getSettingRoleName(role.name);
    const agreementsData = agreements;
    switch (agreementsData.length) {
      case 2:
        return agreementsData.map((agreement, index) => {
          return (
            <Agreement
              key={agreement.id}
              register={register}
              files={agreement.files}
              agreementVersions={agreementVersions}
              isView={true}
              versionsLoading={versionsLoading}
              control={control}
              agreementIndex={index}
              agreement={agreement.agreementType}
              handleFiles={handleFiles}
              handleCreateOption={handleCreateOption}
            />
          );
        });
      case 1: {
        // --- first agreement --- //
        const agreement = agreementsData[0];
        // --- second agreement --- //
        const settingsAgreements = settings?.agreements[roleTypeName].sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0));
        const agreementNotSigned = settingsAgreements.find((agreement) => agreement.id !== agreement.agreementType?._id);
        return (
          <>
            <Agreement
              register={register}
              agreementVersions={agreementVersions}
              isView={false}
              versionsLoading={versionsLoading}
              control={control}
              agreementIndex={0}
              agreement={agreement}
              handleFiles={handleFiles}
              handleCreateOption={handleCreateOption}
            />
            <Agreement
              register={register}
              agreementVersions={agreementVersions}
              isView={false}
              versionsLoading={versionsLoading}
              control={control}
              agreementIndex={1}
              agreement={agreementNotSigned}
              handleFiles={handleFiles}
              handleCreateOption={handleCreateOption}
            />
          </>
        );
      }
      case 0:
        return agreementsByRoleAndSettings.map((agreement, index) => {
          return (
            <Agreement
              key={index}
              register={register}
              agreementVersions={agreementVersions}
              isView={false}
              versionsLoading={versionsLoading}
              control={control}
              agreementIndex={index}
              agreement={agreement}
              handleFiles={handleFiles}
              handleCreateOption={handleCreateOption}
            />
          );
        });
      default:
        return <div> - </div>;
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} className="invite-account-form">
        <FormContainer>
          <div>
            {isEditMode ? (
              <StatusContainer>
                {t(TranslationKeys.STATUS)}:
                <TangiAccountStatus status={accountData.status} />
              </StatusContainer>
            ) : (
              <TangiToggle
                label={t(TranslationKeys.ACTIVE)}
                checked={isAccountActive}
                setChecked={() => setIsAccountActive((prevValue) => !prevValue)}
                tooltip={
                  <>
                    <div>{t(TranslationKeys.ACTIVE_TOOLTIP)}</div>
                    <br />
                    <div>{t(TranslationKeys.NONACTIVE_TOOLTIP)}</div>
                  </>
                }
                disabled={isEditMode}
              />
            )}
          </div>

          <div>
            <DuoContainer>
              <TangiInput
                type="email"
                placeholder={t(TranslationKeys.EMAIL_PLACEHOLDER)}
                label={t(TranslationKeys.EMAIL)}
                name={FormFieldNames.EMAIL}
                required
                register={register}
                registerRules={{
                  pattern: {
                    value: validEmailPattern,
                    message: t(TranslationKeys.VALID_EMAIL),
                  },
                }}
                smallText={!errors.email && t(TranslationKeys.NEVER_SHARE_EMAIL)}
                error={errors.email}
                disabled={isEditMode}
              />

              <TangiSelector
                required
                label={t(TranslationKeys.ROLE)}
                name={FormFieldNames.ROLE}
                options={isUserLearn ? createableRolesLearn : createableRoles}
                error={errors.role}
                control={control}
                disabled={isEditMode}
              />
            </DuoContainer>
            {selectedRole === IRoles.EMPLOYEE_MANAGER && <ManagerNotification />}
          </div>

          <DuoContainer>
            <TangiInput label={t(TranslationKeys.DISPLAY_NAME)} name={FormFieldNames.DISPLAY_NAME} required register={register} error={errors.displayName} />
            <TangiInput label={t(TranslationKeys.TITLE)} name={FormFieldNames.TITLE} register={register} />
          </DuoContainer>

          <DuoContainer>
            <TangiInput type="number" label={t(TranslationKeys.PHONE)} name={FormFieldNames.PHONE} register={register} />
            {selectedRole?.includes(IRoles.EMPLOYEE) && (
              <TangiInput
                label={t(TranslationKeys.LINKEDIN_PROFILE)}
                name={FormFieldNames.LINKEDIN}
                register={register}
                registerRules={{
                  pattern: {
                    value: linkedinUrlPattern,
                    message: t(TranslationKeys.VALID_LINKEDIN),
                  },
                }}
              />
            )}
          </DuoContainer>

          {selectedRole?.includes(IRoles.EMPLOYEE) && (
            <>
              <TangiInput label={t(TranslationKeys.EMPLOYEE_ID)} name={FormFieldNames.EMPLOYEE_ID} register={register} />
              <DuoContainer>
                <TangiSelector label={t(TranslationKeys.COUNTRY)} name={FormFieldNames.COUNTRY} options={countriesOptions()} error={errors.country} control={control} />
                <TangiInput label={t(TranslationKeys.LOCATION)} name={FormFieldNames.LOCATION} register={register} />
              </DuoContainer>
            </>
          )}

          <DuoContainer>
            <TangiCreateableSelector
              options={getSelectorOptions(departments)}
              label={t(TranslationKeys.DEPARTMENTS)}
              name={FormFieldNames.DEPARTMENTS}
              isLoading={metaDataLoading || creatableLoading}
              onCreateOption={(value) => handleCreateOption(value, 'department')}
              control={control}
              error={errors.departments}
              isMulti
              required={selectedRole === IRoles.EMPLOYEE_MANAGER}
            />
            <TangiCreateableSelector
              options={getSelectorOptions(businessUnits)}
              label={t(TranslationKeys.BUSINESS_UNITS)}
              name={FormFieldNames.BUSINESS_UNITS}
              isLoading={metaDataLoading || creatableLoading}
              onCreateOption={(value) => handleCreateOption(value, 'businessUnit')}
              control={control}
              error={errors.businessUnits}
              isMulti
            />
          </DuoContainer>

          <DuoContainer>
            <TangiDatePicker name={FormFieldNames.DATE_OF_JOINING} label={t(TranslationKeys.HIRE_DATE)} error={errors.dateOfJoining} register={register} />
            <TangiDatePicker name={FormFieldNames.DATE_OF_LEAVING} label={t(TranslationKeys.EXIT_DATE)} error={errors.dateOfLeaving} register={register} />
          </DuoContainer>

          <DuoContainer>
            {selectedRole?.includes(IRoles.EMPLOYEE) && (
              <DuoContainer>
                <TangiInput placeholder={t(TranslationKeys.ADDITIONAL_INFORMATION)} label={t(TranslationKeys.OTHERS_1)} name={FormFieldNames.OTHERS_1} register={register} />
                <TangiInput placeholder={t(TranslationKeys.ADDITIONAL_INFORMATION)} label={t(TranslationKeys.OTHERS_2)} name={FormFieldNames.OTHERS_2} register={register} />
              </DuoContainer>
            )}
          </DuoContainer>

          {allPermissionsExceptLearn.includes(Role) && Role !== IRoles.EMPLOYEE_MANAGER && (
            <>
              <Separator />
              <SectionTitle>
                <span className="agreements-title">{t(TranslationKeys.AGREEMENTS)}</span>
              </SectionTitle>

              {isEditMode ? (
                <AgreementsSection agreements={accountData.agreements} settings={accountData.settings} role={accountData.role} />
              ) : !!agreementsByRoleAndSettings.length ? (
                agreementsByRoleAndSettings.map((agreement, index) => (
                  <Agreement
                    key={agreement.id}
                    register={register}
                    agreementVersions={agreementVersions}
                    isView={false}
                    versionsLoading={versionsLoading}
                    control={control}
                    agreementIndex={index}
                    agreement={agreement}
                    handleFiles={handleFiles}
                    handleCreateOption={handleCreateOption}
                  />
                ))
              ) : (
                <div>{t(TranslationKeys.SELECT_A_ROLE)}</div>
              )}
            </>
          )}

          <Separator />
          <TangiCheckbox label={t(TranslationKeys.OKTA_MESSAGE)} register={register} name={FormFieldNames.EXTERNAL_USER} disabled={isEditMode} />
          <TangiAlert {...alertProps} />
          <TangiButton type="submit" loading={userLoading || agreementLoading} text={isEditMode ? t(TranslationKeys.UPDATE_USER) : t(TranslationKeys.ADD_USER)} data-testid="update-user" />
        </FormContainer>
      </Form>
    </Container>
  );
};
