import styled from 'styled-components';

export const RegisterContainer = styled.div`
  font-family: 'DIN2014-Regular';
  display: flex;
  justify-content: space-around;
  margin: 0px auto;
  width: 70%;
  min-height: ${(props) => (props.differentHigh ? '620px' : '520px')};
  height: fit-content;
  background-color: white;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-sizing: border-box;
`;
export const GifContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 30px;
  padding-bottom: 50px;
  background-color: rgba(0, 102, 255, 0.02);
`;
export const GifMessage = styled.div`
  padding-top: 30px;
  align-self: center;
`;
export const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 24px 40px 40px 40px;
  width: 100%;
`;
