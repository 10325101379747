/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { Control, UseFormRegister, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { TangiButton, TangiIconButton, TangiInput, TangiSelector } from '_components/TangiLibrary';
import { getAgreementVersions } from 'redux-toolkit/thunks/clientThunks';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { clientSettingsActions } from '_actions';
import { countriesOptions, fileSizeValidator } from '../utils';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import CameraIcon from '../../../assets/icons/camera.svg';
import {
  FirstPageButtonsContainer,
  CameraButton,
  CameraImg,
  InnerCircle,
  InputContainer,
  LogoContainer,
  OuterCircle,
  PhotoContainer,
  Separator,
  StyledClientName,
  StyledHeader,
  StyledModalHeader,
} from '../style';

interface IFirstPageProps {
  register: UseFormRegister<NonNullable<unknown>>;
  control: Control<NonNullable<unknown>, object>;
  handleClose: () => void;
  logo: any;
  setLogo: React.Dispatch<React.SetStateAction<never[]>>;
  clientName: string;
  activeClientId: string;
}

const FirstPage = ({ register, control, handleClose, logo, setLogo, clientName, activeClientId }: IFirstPageProps) => {
  //Redux
  const dispatch = useDispatch();
  const { modalPage } = useSelector((state: RootState) => state.partnership);
  //I18-next
  const { t } = useTranslation();

  const nameChanged: any = useWatch<any>({
    control: control,
    name: 'name',
  });

  //components
  const renderAddModalHeader = () => {
    return (
      <StyledHeader data-testid="add-partnership-first-page-header">
        <StyledModalHeader>
          {t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.CREATE_NEW_PARTNER_IN')} <StyledClientName> {clientName}</StyledClientName>
        </StyledModalHeader>
        <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} />
      </StyledHeader>
    );
  };

  const renderPhotoContainer = () => {
    return (
      <>
        <span>{t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.ADD_PARTNER_LOGO')}</span>
        <PhotoContainer>
          <OuterCircle {...getRootProps()} className="outer-circle">
            <input {...getInputProps()} />
            <InnerCircle $isLogo={!!logo.length} className="inner-circle">
              <CameraButton onClick={handleOpen}>
                {!isEmpty(logo) && <LogoContainer width="120" height="120" src={logo[0]?.preview ? logo[0].preview : logo[0]} />}
                <CameraImg src={CameraIcon} alt="add-logo" />
              </CameraButton>
            </InnerCircle>
          </OuterCircle>
        </PhotoContainer>
      </>
    );
  };

  const renderInputs = () => {
    return (
      <InputContainer>
        <TangiInput required name="name" placeholder="" label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.PARTNER_NAME')} error={null} smallText="" registerRules={{}} register={register} />
        <TangiSelector label={t('PARTNERSHIP_ADD_EDIT_PARTNER.SELECT_LABEL.COUNTRY')} name="country" options={countriesOptions()} control={control} error={''} required={false} disabled={false} />
        <TangiInput required={false} name="location" placeholder="" label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.ADDRESS')} error={null} smallText="" registerRules={{}} register={register} />
      </InputContainer>
    );
  };

  const renderButtons = () => {
    return (
      <FirstPageButtonsContainer>
        <TangiButton
          disabled={!nameChanged}
          text={t('GENERAL.BUTTONS_TEXT.CONTINUE')}
          onClick={() => {
            dispatch(partnershipActions.setModalPage(modalPage + 1));
          }}
          data-testid="add-partnership-first-page-continue"
        />
      </FirstPageButtonsContainer>
    );
  };

  //Functions
  // TODO: we can notify the user of the types of the accepted files
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ['.jpg', '.png', '.jpeg'],
    validator: fileSizeValidator,
    onDrop: (acceptedFiles: any) => {
      setLogo(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
    noClick: true,
    noKeyboard: true,
  });

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    open();
  };

  //Side Effects
  useEffect(() => {
    if (activeClientId) {
      batch(() => {
        dispatch(clientSettingsActions.getClientSettings(activeClientId));
        dispatch(getAgreementVersions({ client: activeClientId }));
        dispatch(usersActions.setClearInvitedMultiple());
      });
    }
  }, [activeClientId]);

  return (
    <>
      {renderAddModalHeader()}
      <ProgressBar now={(modalPage - 1) * 50} />
      {renderPhotoContainer()}
      {renderInputs()}
      <Separator />
      {renderButtons()}
    </>
  );
};

export default FirstPage;
