import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { NEUTRAL_SHADES } from 'utils/theme';

export const StyledAddUsersInPartnershipForm = styled(Form)`
  width: 720px;
  padding: 16px 16px 16px;
`;

export const StyledModalHeader = styled(Modal.Header)`
  font-family: 'DIN2014-Regular';
  color: black;
  font-size: 24px;
  padding-inline: 0px;
  gap: 4px;
`;

export const StyledModalBody = styled(Modal.Body)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 400px;
`;

export const StyledHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledAddUserComponent = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 8px;
  padding-bottom: 8px;
`;

export const Separator = styled.div`
  border-bottom: 1.5px solid #00000020;
  margin: 12px 0;
`;
export const DuoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  box-sizing: border-box;
  > div {
    flex: 1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: fit-content;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  width: 30%;
  gap: 8px;
  margin-left: auto;
`;

export const Divider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin: 0px;
  margin-bottom: 16px;
  margin-top: 4px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 8px;
  img {
    margin-top: 2px;
    height: 16px;
    width: 16px;
  }
`;
