import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { ProgressBar } from 'pages/ClientDashboard/components/Charts';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
`;

export const TooltipContainer = styled.div`
  padding-block: 10px;
  background: ${NEUTRAL_SHADES.WHITE};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
`;

export const TooltipHeader = styled.div`
  padding: ${SPACING[0]} ${SPACING[2.5]} ${SPACING[2]};
  border-bottom: 1px solid ${NEUTRAL_SHADES[200]};
`;

export const TrainingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const RowContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  padding-inline: ${SPACING[3]};
  .tangi-typography.course-name {
    text-overflow: ellipsis;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const DuoCenteredContainer = styled(FlexContainer)`
  align-items: center;
`;

export const ViewItemContainer = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  padding-right: ${SPACING[1]};
  align-items: center;
`;

export const TitleOfItemContainer = styled.div`
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

export const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: ${SPACING[0]};
  width: 80px;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;
