import { TFunction } from 'i18next';

import { EXPORT_OPTIONS, ExportCheckboxOption } from 'pages/PatentTool/types';
import { exportCsv } from 'utils/exportToCsv';
import { PatentApplicationData } from 'utils/types/patent/patent';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
interface TransformedData {
  Subject: string;
  'Potential Trade Secrets': string;
  [key: string]: string;
}

export interface PatenExportPageData {
  pageNum: number;
  totalPages: number;
}

export interface PatentExportToCsvProps {
  patentData: PatentApplicationData;
}

enum Score {
  MidLow = 25,
  Mid = 50,
  MidHigh = 75,
  High = 100,
}

const findMaxMappingColumns = ({ choice_0 }: PatentApplicationData) => {
  let maxMappingColumns = 0;
  if (choice_0) {
    for (const tradeSecret of choice_0) {
      if (tradeSecret?.mapping) {
        const { mapping } = tradeSecret;
        const mappingLength = mapping ? Math.max(...mapping.map((arr) => arr?.length || 0)) : 0;
        maxMappingColumns = Math.max(maxMappingColumns, mappingLength);
      }
    }
  }
  return maxMappingColumns;
};

export const exportPatentToCSV = ({ patentData }: PatentExportToCsvProps) => {
  const transformedData: TransformedData[] = [];
  let currentSubject = '';
  const maxMappingColumns = findMaxMappingColumns(patentData);

  if (patentData?.choice_0) {
    for (const tradeSecret of patentData.choice_0) {
      const { mapping, bullets, subject } = tradeSecret;

      for (let i = 0; i < bullets.length; i++) {
        const bullet = bullets[i];

        const newData: TransformedData = {
          Subject: currentSubject === subject ? '' : subject,
          'Potential Trade Secrets': bullet,
        };

        for (let j = 0; j < maxMappingColumns; j++) {
          newData[`Mapping ${j + 1}`] = (mapping?.[i]?.[j] || '').replace(/\n/g, ' ');
        }

        transformedData.push(newData);
        currentSubject = subject;
      }
    }
  }
  const exportConfig = { useKeysAsHeaders: true, filename: `Patent X-Ray Report ${patentData.patent_id || patentData.filename || ''}` };
  exportCsv({ data: transformedData, config: exportConfig });
};

export const getScoreBasedOnInventorsAndAge = (inventors: number, age: number): number | undefined => {
  // Return undefined if age or inventors are not greater than 0
  if (age <= 0 || inventors <= 0) return undefined;

  // Define edge cases where the score is not 100
  if (inventors === 1 && age <= 4) return Score.MidLow; // Mid - Low
  else if (inventors === 1 && age < 6) return Score.Mid; // Mid
  else if (inventors === 1 && age <= 7) return Score.MidHigh; // Mid - High
  else if (inventors === 2 && age <= 5) return Score.Mid; // Mid
  else if (inventors === 2 && age <= 7) return Score.MidHigh; // Mid - High
  else if (inventors === 3 && age <= 5) return Score.Mid; // Mid
  else if (inventors === 3 && age <= 7) return Score.MidHigh; // Mid - High
  else if (inventors === 4 && age <= 7) return Score.MidHigh; // Mid - High

  // Return the score, defaulting to High (100) if not in the edge cases
  return 100;
};

export const getInitialCheckboxes = (patentData: PatentApplicationData, isAdmin: boolean, t: TFunction): ExportCheckboxOption[] => {
  const { status_mapper: statusMapper, patent_id: patentId } = patentData;

  const allCheckboxes: ExportCheckboxOption[] = [
    {
      id: EXPORT_OPTIONS.MAPPING_ANALYSIS_EXCEL,
      label: statusMapper === XRAY_JOB_STATUS.FAILED ? t('PATENT_MAPPING_TOOL.MODAL.EXTRACTION_ANALYSIS_EXCEL') : t('PATENT_MAPPING_TOOL.MODAL.MAPPING_ANALYSIS_EXCEL'),
      checked: false,
      isLoading: true,
      icon: 'fileExcel',
      toastLabel: t('PATENT_MAPPING_TOOL.TOAST.MAPPING_ANALYSIS', { patentId }),
      visible: true,
    },
    {
      id: EXPORT_OPTIONS.TRADE_SECRET_ANALYSIS_PDF,
      label: t('PATENT_MAPPING_TOOL.MODAL.TRADE_SECRET_ANALYSIS_PDF'),
      checked: false,
      isLoading: true,
      icon: 'filePdf',
      toastLabel: t('PATENT_MAPPING_TOOL.TOAST.TRADE_SECRET', { patentId }),
      visible: true,
    },
  ];

  return allCheckboxes.filter((checkbox) => checkbox.visible !== false);
};
