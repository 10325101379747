import React, { useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { debounce, isEmpty, isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BodyContainer, Footer, ItemsContainer, MenuItem, MenuItemTypography, StyledCheckbox, StyledInput } from '_components/TangiLibrary/TangiMultiSelect/style';
import { TangiButton, TangiIconButton } from '_components/TangiLibrary';
import { FILTERS_ID, FilterDataItem, SelectedFilter } from '../types';
import { BUTTON_VARIANTS } from 'utils/theme';
import { BackArrowContainer } from './style';
import { formatNameBasedOnFilter, normalizeData } from './utils';
import { CenteredContainer } from 'utils/globalStyles';
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import FilterEmptyState from './FilterEmptyState/FilterEmptyState';
import { renderCompliantTraining } from '../utils';
import DateRangeFilter from '_components/DateRangeFilter/DateRangeFilter';

interface Props {
  filterTitle: string;
  onChange: (selectedItems: SelectedFilter[], id: FILTERS_ID) => void;
  selectedItems: SelectedFilter[] | [];
  itemList: FilterDataItem[];
  id: FILTERS_ID;
  handleBackNavigation: () => void;
}
const GeneratePeopleFiltersDropDown = ({ filterTitle, onChange, selectedItems, itemList, id, handleBackNavigation }: Props) => {
  const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems);
  const [localItemList, setLocalItemList] = useState(itemList);
  const [searchValue, setSearchValue] = useState('');
  const [showEmptyState, setShowEmptyState] = React.useState(false);
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const { loading } = useSelector((state: RootState) => state.users);

  const isItemSelected = (id: string) => {
    return !!localSelectedItems.find((item: SelectedFilter) => {
      return item.id === id;
    });
  };

  useEffect(() => {
    if (searchValue === '' && itemList.length === 0) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
    }
  }, [searchValue, itemList]);

  const debouncedSetLocalItemList = debounce((setFunction, itemList, value) => {
    if (isEmpty(value)) {
      setFunction(itemList);
    } else {
      const filteredItemList = itemList.filter((item: FilterDataItem) => {
        const name = item?.formattedName || item?.name || item?.displayName || item.id;
        return name?.toLowerCase()?.includes(value.toLowerCase());
      });
      setFunction(filteredItemList);
    }
  }, 300);

  const handleToggle = () => {
    if (!show) {
      setLocalSelectedItems(selectedItems);
      setLocalItemList(itemList);
    }
    setShow((prev) => !prev);
  };

  const handleSelectRow = (item: FilterDataItem) => {
    const isFound = isItemSelected(item.id);
    if (isFound) {
      setLocalSelectedItems((state) => state.filter((obj) => obj.id !== item.id));
    } else {
      setLocalSelectedItems((state) => [...state, { name: item?.formattedName || item?.name || item.displayName || '', id: item.id }]);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedSetLocalItemList(setLocalItemList, itemList, value);
  };

  const handleApply = () => {
    onChange(localSelectedItems, id);
    setShow(false);
    if (searchValue !== '') {
      setSearchValue('');
    }
  };

  const handleClear = () => {
    if (!isEmpty(selectedItems)) {
      onChange([], id);
    }
    setSearchValue('');
    setLocalSelectedItems([]);
    setLocalItemList(normalizeData(itemList, id));
  };

  useEffect(() => {
    setLocalItemList(normalizeData(itemList, id));
  }, [itemList]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateChange = (newRange: any) => {
    // TODO: fix any type
    onChange(newRange, id);
  };

  const renderCheckBoxFilters = () => {
    return (
      <Dropdown.Menu>
        <BackArrowContainer>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="arrowSideLeft" onClick={() => handleBackNavigation()} />
          <span>{filterTitle}</span>
        </BackArrowContainer>
        <BodyContainer>
          {loading && searchValue === '' ? (
            <CenteredContainer>
              <Spinner animation="border" variant="primary" />
            </CenteredContainer>
          ) : showEmptyState ? (
            <FilterEmptyState filterName={filterTitle} />
          ) : (
            <>
              <StyledInput
                value={searchValue}
                name="search"
                placeholder={t('ASSET_PAGE.INPUT.SEARCH_OPTION')}
                onChange={(e) => handleSearch(e.target.value)}
                className="ff-din-regular"
                data-testid="multiselect-input"
              />
              <ItemsContainer>
                {localItemList?.map((item: FilterDataItem) => (
                  <MenuItem key={item.id} onClick={() => handleSelectRow(item)} data-testid="multiselect-menu-item">
                    <StyledCheckbox checked={isItemSelected(item.id)} readOnly type="checkbox" />
                    {isFunction(renderCompliantTraining) && id === FILTERS_ID.TRAINING ? (
                      renderCompliantTraining(item.name)
                    ) : (
                      <MenuItemTypography data-testid="multiselect-menu-text" className="ff-din-regular">
                        {formatNameBasedOnFilter(item)}
                      </MenuItemTypography>
                    )}
                  </MenuItem>
                ))}
              </ItemsContainer>
            </>
          )}
        </BodyContainer>

        <Dropdown.Divider />

        <Footer>
          <div>
            <TangiButton
              variant="tertiary"
              text={t('GENERAL.BUTTONS_TEXT.CLEAR_ALL')}
              size="small"
              onClick={handleClear}
              disabled={isEmpty(localSelectedItems) && isEmpty(selectedItems)}
              data-testid="clear-filter"
            />
          </div>
          <div>
            <TangiButton text={t('GENERAL.BUTTONS_TEXT.APPLY')} size="small" onClick={handleApply} disabled={isEmpty(localSelectedItems) && isEmpty(selectedItems)} data-testid="apply-filter" />
          </div>
        </Footer>
      </Dropdown.Menu>
    );
  };

  const renderDatePickerFilter = () => <DateRangeFilter id={FILTERS_ID.HIRE_DATE} onChange={handleDateChange} handleBackNavigation={handleBackNavigation} filterTitle={filterTitle} />;
  return (
    <Dropdown onToggle={handleToggle} show={show} drop="down" align={{ lg: 'start' }}>
      {id === FILTERS_ID.HIRE_DATE ? renderDatePickerFilter() : renderCheckBoxFilters()}
    </Dropdown>
  );
};

export default GeneratePeopleFiltersDropDown;
