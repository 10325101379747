import { adminAgreementContants } from '../_constants/adminAgreementContants';

const initialState = { results: [], agreementTypes: [], createAgreement: {}, status: '', agreementFile: {}, errors: {} };

export function adminAgreementReducer(state = initialState, action) {
  switch (action.type) {
    case adminAgreementContants.ADMIN_AGREEMENT_REQUEST:
      return {
        ...state,
        results: [],
      };
    case adminAgreementContants.ADMIN_AGREEMENT_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    case adminAgreementContants.ADMIN_AGREEMENT_FAILURE:
      return {
        ...state,
        results: [],
      };
    case adminAgreementContants.ADMIN_AGREEMENT_TYPE_REQUEST:
      return {
        ...state,
        agreementTypes: [],
      };
    case adminAgreementContants.ADMIN_AGREEMENT_TYPE_SUCCESS:
      return {
        ...state,
        agreementTypes: action.payload,
      };
    case adminAgreementContants.ADMIN_AGREEMENT_TYPE_FAILURE:
      return {
        ...state,
        agreementTypes: [],
      };

    case adminAgreementContants.CREATING_AGREEMENT:
      return {
        ...state,
        status: 'creatingAgreement',
      };
    case adminAgreementContants.AGREEMENT_CREATED:
      return {
        ...state,
        status: 'agreementCreated',
        createAgreement: action.payload,
      };
    case adminAgreementContants.AGREEMENT_FAILURE:
      return {
        ...state,
        status: 'agreementFailed',
        createAgreement: {},
        errors: action.errors,
      };
    case adminAgreementContants.AGREEMENT_SUCCESS:
      return {
        ...state,
        status: 'agreementSuccess',
        createAgreement: action.payload,
      };
    case adminAgreementContants.AGREEMENT_FILEUPLOAD_SUCCESS:
      return {
        ...state,
        status: 'agreementFileSuccess',
        agreementFile: action.payload,
      };
    case adminAgreementContants.AGREEMENT_FILEUPLOAD_FAILURE:
      return {
        ...state,
        status: 'agreementFileFailed',
        agreementFile: {},
        errors: action.errors,
      };
    case adminAgreementContants.CLEAR_CREATE_STATUS:
      return {
        ...state,
        status: '',
        agreementFile: {},
        createAgreement: {},
        errors: {},
      };

    case adminAgreementContants.UPDATING_AGREEMENT:
      return {
        ...state,
        status: 'updatingAgreement',
      };
    case adminAgreementContants.AGREEMENT_UPDATED:
      return {
        ...state,
        status: 'agreementUpdated',
        updateAgreement: action.payload,
      };
    case adminAgreementContants.AGREEMENT_UPDATED_FAILED:
      return {
        ...state,
        status: 'agreementUpdateFailed',
        updateAgreement: {},
        errors: action.errors,
      };
    case adminAgreementContants.AGREEMENT_UPDATED_SUCCESS:
      return {
        ...state,
        status: 'agreementUpdateSuccess',
        updateAgreement: action.payload,
      };
    default:
      return state;
  }
}
