import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { PieChart } from '../Charts';
import { WidgetCard } from '_components/AssetRecipients/style';
import { Contributors } from '../Widgets/Contributors/Contributors';
import Users from '../Widgets/Users/Users';
import AssetsOverTime from '../Widgets/AssetsOverTime/AssetsOverTime';
import WidgetTitle from '../Widgets/WidgetTitle';
import AssetsData from '../Widgets/AssetsData/AssetsData';
import Insights from '../Widgets/Insights/Insights';
import ProjectsByAssets from '../Widgets/ProjectsByAssets/ProjectsByAssets';
import PeopleStatus from '../Widgets/PeopleStatus/PeopleStatus';
import UsedTags from '../Widgets/UsedTags/UsedTags';
import PeopleByRole from '../Widgets/PeopleByRole/PeopleByRole';
import { DASHBOARD_WIDGETS_KEYS } from 'pages/ClientDashboard/consts';
import { getTodayDate } from 'utils/dateUtils';

const ClientDashboardPDF = forwardRef<HTMLDivElement>((props, ref) => {
  const { t } = useTranslation();
  const {
    allAssetsRawData,
    assetsByAssetType,
    assetsByDepartments,
    assetsByThirdParty,
    unpublishedAssets,
    assetsByProject,
    topContributors,
    metadata,
    users,
    assetsByTag,
    assetsByBusinessValues,
    accountsStatus,
    accountsByRole,
    mostNotifiedAsset,
    mostSharedBy,
    topAssetCreator,
  } = useSelector((state: RootState) => state.dashboard);

  return (
    <div
      style={{
        position: 'absolute',
        left: '-10000px',
        top: 0,
        fontFamily: 'DIN2014-Bold',
      }}
    >
      {/* @ts-expect-error PDF export ref typing issue */}
      <PDFExport ref={ref} fileName={`tangibly${getTodayDate()}.pdf`} scale={0.8} paperSize="A2" margin="1cm" keepTogether=".prevent-split">
        <div className="px-4">
          <div className="pt-3 d-flex align-items-center justify-content-between">
            <TangiTypography type="heading-md" weight="semibold">
              {t('DASHBOARD_PAGE.HEADER.TITLE')}
            </TangiTypography>
          </div>
          <div className="py-3 dashboard-layout-container">
            {!!assetsByAssetType.length && (
              <div className="widget_asset_by_type prevent-split">
                <WidgetCard isEmpty={!assetsByAssetType?.length} key={DASHBOARD_WIDGETS_KEYS.ASSETS_BY_TYPE} style={{ height: '100%', width: '100%' }}>
                  <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.ASSETS_BY_TYPE')} />
                  <PieChart margin={{ top: 100, right: 0, bottom: 120, left: 0 }} data={assetsByAssetType} total={metadata.assetsTotal} totalText={t('DASHBOARD_PAGE.WIDGETS.TOTAL_ASSETS')} />
                </WidgetCard>
              </div>
            )}
            {!!allAssetsRawData.length && (
              <div className="widget_asset_over_time prevent-split">
                <AssetsOverTime key={DASHBOARD_WIDGETS_KEYS.ASSETS_OVER_TIME} />
              </div>
            )}
            {!!assetsByDepartments.length && (
              <div className="widget_data_by_departments prevent-split">
                <WidgetCard isEmpty={!assetsByDepartments?.length} key="data_by_departments" style={{ height: '100%' }}>
                  <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.DATA_BY_DEPARTMENTS.TITLE')} />
                  <PieChart margin={{ top: 100, right: 0, bottom: 120, left: 0 }} data={assetsByDepartments} total={metadata.assetsTotal} totalText={t('DASHBOARD_PAGE.WIDGETS.TOTAL_ASSETS')} />
                </WidgetCard>
              </div>
            )}
            {(!!unpublishedAssets || !!assetsByThirdParty) && (
              <div className="widget_assets_data prevent-split">
                <AssetsData key={DASHBOARD_WIDGETS_KEYS.ASSETS_DATA} data={{ total: metadata.assetsTotal, unpublishedAssets, assetsByThirdParty }} />
              </div>
            )}
            {!!users.total && (
              <div className="widget_users prevent-split">
                <Users key={DASHBOARD_WIDGETS_KEYS.USERS} data={users} />
              </div>
            )}
            {(!!mostNotifiedAsset.length || !!mostSharedBy.length || !!topAssetCreator.length) && (
              <div className="widget_insights prevent-split">
                <Insights key={DASHBOARD_WIDGETS_KEYS.INSIGHTS} data={{ mostNotifiedAsset, mostSharedBy, topAssetCreator }} />
              </div>
            )}
            {!!assetsByProject.length && (
              <div className="widget_projects_by_assets prevent-split">
                <ProjectsByAssets key={DASHBOARD_WIDGETS_KEYS.PROJECTS_BY_ASSETS} data={assetsByProject} />
              </div>
            )}
            {!!topContributors.length && (
              <div className="widget_contributors prevent-split">
                <Contributors key={DASHBOARD_WIDGETS_KEYS.CONTRIBUTORS} data={topContributors} />
              </div>
            )}
            {!!metadata.accountsTotal && (
              <div className="widget_people_status prevent-split">
                <PeopleStatus key={DASHBOARD_WIDGETS_KEYS.PEOPLE_STATUS} data={{ total: metadata.accountsTotal, ...accountsStatus }} />
              </div>
            )}
            {!!assetsByTag.length && !!assetsByBusinessValues.length && (
              <div className="widget_used_tags prevent-split">
                <UsedTags key={DASHBOARD_WIDGETS_KEYS.USED_TAGS} data={{ tags: assetsByTag, businessValues: assetsByBusinessValues }} />
              </div>
            )}
            {!!metadata.accountsTotal && (
              <div className="widget_people_by_role prevent-split">
                <PeopleByRole key={DASHBOARD_WIDGETS_KEYS.PEOPLE_BY_ROLE} data={{ total: metadata.accountsTotal, people: accountsByRole }} isEmpty={!accountsByRole?.length} />
              </div>
            )}
          </div>
        </div>
      </PDFExport>
    </div>
  );
});
export default ClientDashboardPDF;
