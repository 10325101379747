import { IpAuditExtendedData, HrProcessData, IpAuditStepKey, SystemSecurityData, StepDataTypeMap, ContractsData, IpAuditBatchAssetData, IpAuditContextType } from './types';
import { useClient } from './components/Steps/hooks/useClient';
import { POLICIES } from 'utils/types/client/client';
import { createIpAuditStepsData } from './consts';
import { TangiStepperStep } from '_components/TangiLibrary/TangiStepper/TangiStepper';
import { useSelector } from 'react-redux';
import { updateContractsIpAudit, updateHrProcessIpAudit, updateKeyAssetsSystemNoneSelectionIpAudit, updateSystemSecurityIpAudit } from 'redux-toolkit/thunks/clientThunks';
import { RootState, useAppDispatch } from '_helpers';
import { createAssetsIpAudit, getIpAuditAssets } from 'redux-toolkit/thunks/assetsThunks';
import { createContext, useContext, useEffect } from 'react';
import { Asset } from 'utils/types/assets/assets';
import { isArraysEqual, parseKeyAssetsItem } from './utils';
import { SystemKey } from './components/Steps/KeyAssestsFromSystem/types';
import { buildAssetName } from './components/Steps/KeyAssestsFromSystem/utils';
import { keysTagMap, systemKeys } from './components/Steps/KeyAssestsFromSystem/consts';
import useToast from '_hooks/useToast';
import { useTranslation } from 'react-i18next';

export const IpAuditContext = createContext<IpAuditContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

export const IpAuditContextProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { showSnackbar } = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getIpAuditAssets());
  }, []);

  const { ipAuditToastProps } = useSelector((state: RootState) => state.client);
  const { activeAccount, getClientPolicyFile, clientId, isLawyer, getClientIpAudit } = useClient();

  const hasContractAnalyzingPermission = activeAccount?.specialAdminPermissions?.Patent;

  const hiddenSteps = [...(!hasContractAnalyzingPermission ? [IpAuditStepKey.CONTRACTS] : [])];

  //------------------------------------------------------------------ Data ----------------------------------------------------------------------------------------//

  const tradePolicyFiles = getClientPolicyFile(POLICIES.TRADE_SECRET);
  const llmPolicyFiles = getClientPolicyFile(POLICIES.LLM);
  const ipAuditData = getClientIpAudit();
  const ipAuditAssets: Asset[] = useSelector((state: RootState) => state.asset.ipAuditAssets);

  const ipAuditExtendedData: IpAuditExtendedData = {
    hrProcess: ipAuditData?.hrProcess ?? null,
    systemSecurity: ipAuditData?.systemSecurity ?? null,
    policyFile: tradePolicyFiles?.[0] ?? null,
    llmFile: llmPolicyFiles?.[0] ?? null,
    keyAssetsSystem: { assets: parseKeyAssetsItem(ipAuditAssets), noneSelectionSystems: ipAuditData?.keyAssetsSystemNoneSelection ?? null },
    contracts: ipAuditData?.contracts ?? null,
  };

  //---------------------------------------------------------- Steps Submission Handling ----------------------------------------------------------------------------//

  const submitSystemSecurity = (data: SystemSecurityData) => {
    const updatedIpAuditData = { ...ipAuditData, [IpAuditStepKey.SYSTEM_SECURITY]: data };
    const params = {
      clientId,
      accountId: activeAccount._id,
      isLawyer,
      ipAudit: updatedIpAuditData,
    };
    dispatch(updateSystemSecurityIpAudit(params));
  };

  const submitHrProcess = (data: HrProcessData) => {
    const updatedIpAuditData = { ...ipAuditData, [IpAuditStepKey.HR_PROCESS]: data };
    const params = {
      clientId,
      accountId: activeAccount._id,
      isLawyer,
      ipAudit: updatedIpAuditData,
    };
    dispatch(updateHrProcessIpAudit(params));
  };

  const submitKeyAssetsSystem = async (assetsData: IpAuditBatchAssetData, noneOfTheAboveArr: SystemKey[]) => {
    const isSubmitNoneOfTheAboveArr = !isArraysEqual(noneOfTheAboveArr, ipAuditExtendedData?.keyAssetsSystem?.noneSelectionSystems ?? []);
    if (isSubmitNoneOfTheAboveArr) {
      const updatedIpAuditData = { ...ipAuditData, ['keyAssetsSystemNoneSelection']: noneOfTheAboveArr };
      const params = {
        clientId,
        accountId: activeAccount._id,
        isLawyer,
        ipAudit: updatedIpAuditData,
      };
      await dispatch(updateKeyAssetsSystemNoneSelectionIpAudit(params)).unwrap();
    }
    if (assetsData.assets.length) {
      await dispatch(createAssetsIpAudit({ data: assetsData, clientId })).unwrap();
      showSnackbar('success', 'top', 'center', t('IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.ASSETS_CREATED_NOTIFICATION'));
    }
  };

  const submitContracts = (data: ContractsData) => {
    const updatedIpAuditData = { ...ipAuditData, [IpAuditStepKey.CONTRACTS]: data };
    const params = {
      clientId,
      accountId: activeAccount._id,
      isLawyer,
      ipAudit: updatedIpAuditData,
    };
    dispatch(updateContractsIpAudit(params));
  };

  //---------------------------------------------------------------- Steps Helpers ------------------------------------------------------------------------------------//
  const getStepCurrentData = <K extends keyof StepDataTypeMap>(stepKey: K): StepDataTypeMap[K] => {
    let data: StepDataTypeMap[K];
    switch (stepKey) {
      case IpAuditStepKey.HR_PROCESS:
        data = ipAuditExtendedData.hrProcess as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.SYSTEM_SECURITY:
        data = ipAuditExtendedData.systemSecurity as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.LLM_POLICY:
        data = ipAuditExtendedData.llmFile as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.TRADE_SECRET_POLICY:
        data = ipAuditExtendedData.policyFile as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.KEY_ASSETS_SYSTEMS:
        data = ipAuditExtendedData.keyAssetsSystem as StepDataTypeMap[K];
        break;
      case IpAuditStepKey.CONTRACTS:
        data = ipAuditExtendedData.contracts as StepDataTypeMap[K];
        break;
      default:
        throw new Error(`Unhandled step key: ${stepKey}`);
    }
    return data;
  };

  const isStepCompleted = (stepKey: IpAuditStepKey) => {
    switch (stepKey) {
      case IpAuditStepKey.TRADE_SECRET_POLICY:
        return !!ipAuditExtendedData.policyFile;
      case IpAuditStepKey.LLM_POLICY:
        return !!ipAuditExtendedData.llmFile;
      case IpAuditStepKey.HR_PROCESS:
        return ipAuditExtendedData.hrProcess?.employeeTraining != null && ipAuditExtendedData.hrProcess?.isIncorporateProtocolsConfidential != null;
      case IpAuditStepKey.SYSTEM_SECURITY:
        return !!ipAuditExtendedData.systemSecurity?.length;
      case IpAuditStepKey.CONTRACTS:
        return ipAuditExtendedData.contracts?.hasConfidentialityProvisions != null;
      case IpAuditStepKey.KEY_ASSETS_SYSTEMS:
        return isKeyAssetsCompleted();
      default:
        break;
    }
  };

  const isKeyAssetsCompleted = () => {
    return !systemKeys.some((system) => {
      const isSomeAssetExistsForCurrentSystem = ipAuditExtendedData?.keyAssetsSystem?.assets?.some((name) => name.includes(keysTagMap[system]));
      const isSystemExistsInNoneSelection = ipAuditExtendedData?.keyAssetsSystem?.noneSelectionSystems?.includes(system);
      return !isSomeAssetExistsForCurrentSystem && !isSystemExistsInNoneSelection;
    });
  };

  const isStepHasWarning = (stepKey: IpAuditStepKey) => {
    switch (stepKey) {
      case IpAuditStepKey.TRADE_SECRET_POLICY:
        return !!ipAuditExtendedData.policyFile?.isDeclaredNoFile;
      case IpAuditStepKey.LLM_POLICY:
        return !!ipAuditExtendedData.llmFile?.isDeclaredNoFile;
      case IpAuditStepKey.HR_PROCESS:
        return ipAuditExtendedData.hrProcess?.employeeTraining === false || ipAuditExtendedData.hrProcess?.isIncorporateProtocolsConfidential === false;
      case IpAuditStepKey.SYSTEM_SECURITY:
        return !!ipAuditExtendedData.systemSecurity?.includes('noneOfTheAbove');
      case IpAuditStepKey.CONTRACTS:
        return ipAuditExtendedData.contracts?.hasConfidentialityProvisions === false;
      case IpAuditStepKey.KEY_ASSETS_SYSTEMS:
        return !ipAuditExtendedData.keyAssetsSystem.assets?.length || !!ipAuditExtendedData.keyAssetsSystem?.noneSelectionSystems?.length;
      default:
        break;
    }
  };

  const getNextStepKey = (currentStep: string, fallbackToFirst: boolean = false): IpAuditStepKey | null => {
    const currentStepIdx = userSteps.findIndex(({ key }) => key === currentStep);
    if (currentStepIdx < 0 || currentStepIdx === userSteps.length - 1) {
      return fallbackToFirst ? ipAuditSteps[0].key : null;
    }
    return userSteps[currentStepIdx + 1].key;
  };

  const isIpAuditStepKey = (key: string | null): key is IpAuditStepKey => Object.values(IpAuditStepKey).includes(key as IpAuditStepKey);

  const getNextStepForCompleteFlow = (): IpAuditStepKey | null => {
    const step = userSteps.find(({ key }) => !isStepCompleted(key));
    if (step) return step.key;
    return null;
  };

  const isAssetDisable = (system: SystemKey, asset: string) => {
    const assetName = buildAssetName(system, asset);
    return !!ipAuditExtendedData?.keyAssetsSystem?.assets?.includes(assetName);
  };

  //---------------------------------------------------------------- Steps Data ----------------------------------------------------------------------------------------//

  const ipAuditSteps: TangiStepperStep<IpAuditStepKey>[] = Object.entries(createIpAuditStepsData({ hide: hiddenSteps })).map(([key, value], idx) => {
    const stepKey = key as IpAuditStepKey;
    return {
      key: stepKey,
      name: value.name,
      component: value.component,
      index: idx,
      ...(isStepCompleted(stepKey) && { icon: isStepHasWarning(stepKey) ? 'completeWithWarning' : 'completed' }),
      isComingSoon: !value.component,
    };
  });

  // steps which the user can fill
  const userSteps = ipAuditSteps.filter(({ key, isComingSoon }) => key !== IpAuditStepKey.OVERVIEW && !isComingSoon);

  const numberOfSteps = userSteps.length;

  const numberOfCompletedSteps = userSteps.filter(({ key }) => isStepCompleted(key)).length;

  const value = {
    ipAuditSteps,
    isStepCompleted,
    getStepCurrentData,
    getNextStepKey,
    isIpAuditStepKey,
    getNextStepForCompleteFlow,
    numberOfSteps,
    numberOfCompletedSteps,
    ipAuditToastProps,
    userSteps,
    submitSystemSecurity,
    submitHrProcess,
    submitKeyAssetsSystem,
    submitContracts,
    hiddenSteps,
    isAssetDisable,
    isStepHasWarning,
  };

  return <IpAuditContext.Provider value={value}>{children}</IpAuditContext.Provider>;
};

export const useIpAuditContext = () => {
  const context = useContext(IpAuditContext);
  if (!context) {
    throw new Error('useIpAuditContext has to be used within <IpAuditContext.Provider>');
  }
  return context;
};
