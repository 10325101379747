import styled, { css } from 'styled-components';

import { ChipType } from './TangiChip';
import { Props } from './TangiChip';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SECONDARY_SHADES, SPACING } from 'utils/theme';

export type StyledChipProps = Pick<Props, 'maxWidth' | 'variant'>;

export const StyledChip = styled.div<StyledChipProps>`
  ${({ maxWidth = 88, variant = 'filled' }) => css`
    font-family: 'DIN2014-Regular';
    width: fit-content;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${maxWidth}px;
    ${getVariantProps(variant)}
  `}
`;

const getVariantProps = (variant: ChipType) => {
  switch (variant) {
    /**
     * Generates CSS styles for 'primary' variant with light blue background, blue text and uppercase letters.
     */
    case 'primary':
      return css`
        background-color: ${PRIMARY_SHADES[200]};
        border-radius: 12px;
        padding-inline: ${SPACING[2]};
        padding-block: 3px 2px;
        color: ${PRIMARY_SHADES[800]};
        font-family: 'DIN2014-DemiBold';
        text-transform: uppercase;
      `;
    /**
     * Generates CSS styles for 'secondary' variant with light purple background, purple text and uppercase letters.
     */
    case 'secondary':
      return css`
        background-color: ${SECONDARY_SHADES[200]};
        border-radius: 12px;
        padding-inline: ${SPACING[2]};
        padding-block: 3px 2px;
        color: ${SECONDARY_SHADES[800]};
        font-family: 'DIN2014-DemiBold';
        text-transform: uppercase;
      `;
    /**
     * Generates CSS styles for 'filled' variant with light grey background and grey border.
     */
    case 'filled':
      return css`
        border: 1px solid ${NEUTRAL_SHADES[300]};
        background-color: ${NEUTRAL_SHADES[50]};
        padding: ${SPACING[1]} 10px;
        border-radius: 14px;
        color: ${NEUTRAL_SHADES.BLACK};
      `;
    /**
     * Generates CSS styles for 'outline' variant with no background and dark border.
     */
    case 'outline':
      return css`
        border: 1px solid ${NEUTRAL_SHADES[900]};
        background-color: none;
        padding-inline: ${SPACING[2]};
        border-radius: ${SPACING[6]};
        color: ${NEUTRAL_SHADES[900]};
        padding-top: 1px;
      `;
    /**
     * Generates CSS styles for 'tag' variant with a dark background and white font.
     */
    case 'tag':
      return css`
        border: 1px solid ${NEUTRAL_SHADES[1100]};
        background-color: ${NEUTRAL_SHADES[1100]};
        padding: ${SPACING[0]} ${SPACING[2]};
        border-radius: ${SPACING[1]};
        color: ${NEUTRAL_SHADES.WHITE};
      `;
  }
};
