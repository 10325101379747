import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { useTranslation } from 'react-i18next';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';

interface DropZoneExplanatoryTextsProps {
  customDragAndDropText?: string;
  isEdit?: boolean;
}

const textsPathMap = {
  editMode: {
    first: 'GENERAL.UPLOAD_FILES_MODAL.DRAG_AND_DROP_FILE_EDIT_MODE',
    second: 'GENERAL.UPLOAD_FILES_MODAL.DRAG_AND_DROP_CLICK',
  },
  regularMode: {
    first: 'GENERAL.UPLOAD_FILES_MODAL.DRAG_AND_DROP_FILE',
    second: 'GENERAL.UPLOAD_FILES_MODAL.SELECT_FILE_FROM_COMPUTER',
  },
};

export const DropZoneExplanatoryTexts = ({ isEdit, customDragAndDropText }: DropZoneExplanatoryTextsProps) => {
  const { t } = useTranslation();
  return (
    <>
      {customDragAndDropText && !isEdit ? (
        <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]}>
          {customDragAndDropText}
        </TangiTypography>
      ) : (
        <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]}>
          {t(isEdit ? textsPathMap.editMode.first : textsPathMap.regularMode.first)}
        </TangiTypography>
      )}
      <TangiTypography weight="semibold" color={PRIMARY_SHADES[800]}>
        {t(isEdit ? textsPathMap.editMode.second : textsPathMap.regularMode.second)}
      </TangiTypography>{' '}
    </>
  );
};
