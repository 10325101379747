import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { NOTIFICATION_VARIANTS, TangiButton, TangiNotification, TangiToast } from '_components/TangiLibrary';
import { RootState } from '_helpers';
import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';
import { BUTTON_VARIANTS, SPACING } from 'utils/theme';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { RESULT_STATUS } from 'utils/enums';
import { joinDiscoveryEnquiry } from 'redux-toolkit/thunks/clientThunks';
import { DemoBannerContainer } from './style';

interface Props {
  source: string;
}

const DemoBanner = ({ source }: Props) => {
  const { discoveryDataLoaders, clientToastProps } = useSelector((state: RootState) => state.client);
  const { activeAccount, user, Role } = useSelector((state: RootState) => state.authentication);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const DemoButton = styled(TangiButton)`
    font-weight: 400;
    height: ${SPACING[5]};
  `;

  const handleJoinBeta = () => {
    dispatch(
      joinDiscoveryEnquiry({
        name: user?.fullName || activeAccount?.displayName || '',
        email: user.email,
        clientName: activeAccount?.client?.name || activeClient?.name || '',
        role: Role,
        source,
      }),
    );
  };

  return (
    <DemoBannerContainer>
      <TangiNotification
        variant={NOTIFICATION_VARIANTS.MARKETING}
        title={t(TRANSLATION_KEY.BANNER_TITLE)}
        rightAnchor={
          <DemoButton text={t(TRANSLATION_KEY.BANNER_REQUEST_ACCESS)} variant={BUTTON_VARIANTS.PRIMARY_PURPLE} loading={discoveryDataLoaders.joinDiscoveryLoading} onClick={handleJoinBeta} />
        }
      >
        {t(TRANSLATION_KEY.BANNER_INFO)}
      </TangiNotification>
      <TangiToast
        {...clientToastProps}
        onSuccess={() => {
          dispatch(clientActions.setClientToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </DemoBannerContainer>
  );
};

export default DemoBanner;
