import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { TangiIconButton } from '../TangiLibrary';
import AssetOptions from '../AssetOptions/AssetOptions';
import AssetTypeColorBlock from '../AssetTypeColorBlock/AssetTypeColorBlock';
import AcknowledgementTooltip from '../ClientAssetCards/components/AcknowledgementTooltip/AcknowledgementTooltip';
import ThirdPartyTag from '_components/ThirdPartyTag/ThirdPartyTag';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { LOCKED_ASSET, assetTypeNameFormatter } from 'utils/assetTypes';
import { formatDate } from 'utils/dateUtils';
import { IRoles, checkAssetSharePermission } from 'utils/roles';
import { CREATED_FROM } from 'utils/enums';
import { TableHeaderText, TableBodyText, DuoContainer } from 'utils/globalStyles';
import { StyledMagicIcon } from '_components/ClientAssetCards/components/AssetCardActive/style';
import { HeaderText, ColorAndType, AssetTagsContainer, RecipientNumber, ActionsContainer, customAssetOptionsStyles } from './style';

export const createTableColum = (permissions) => {
  const { t } = useTranslation();
  return [
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.NAME')}</TableHeaderText>,
      selector: ({ name }) => name,
      fieldName: 'name',
      maxWidth: '240px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.ASSET_TYPE')}</TableHeaderText>,
      selector: ({ assetType }) => assetType,
      fieldName: 'assetType',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.TAGS')}</TableHeaderText>,
      selector: ({ tags }) => tags,
      fieldName: 'tags',
      minWidth: '240px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.CREATION_DATE')}</TableHeaderText>,
      selector: ({ createdAt }) => createdAt,
      fieldName: 'createdAt',
      maxWidth: '144px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.RECIPIENTS')}</TableHeaderText>,
      selector: ({ recipients }) => recipients,
      omit: !permissions?.Assetshare,
      maxWidth: '120px',
    },
    {
      name: <TableHeaderText>{t('ASSET_PAGE.ASSET_TABLE.ACTIONS')}</TableHeaderText>,
      selector: ({ action }) => action,
      fieldName: 'action',
      maxWidth: '120px',
    },
  ];
};

const renderMagicIcon = (value) => {
  return (
    value?.createdFrom === CREATED_FROM.EMAIL_FINDER && (
      <Tippy content={<div className="text-xs">{`${i18next.t('ASSET_PAGE.TOOLTIP.ASSETS_CREATED_BY_EMAIL_XRAY')} ${formatDate(value?.createdAt)}`}</div>}>
        <StyledMagicIcon />
      </Tippy>
    )
  );
};

export const makeTableRow = ({ value, handleDistributeAsset, role, options, permissions, isAdmin, handleUnlockAsset, handleRowClick }) => {
  const allAcknowledged = value?.recipients?.every((recipient) => recipient.acknowledged === true);
  const isLocked = value?.needsToBeAcknowledgedByMe || (value?.isThirdParty && !value?.hasAccessToThirdPartyAsset);
  const row = { id: value.id, clientId: value.client._id, isLocked };
  const isShowRecipients = !!value?.recipients?.length && checkAssetSharePermission(role, permissions, value?.isThirdParty);
  const isEditDisable = value?.isThirdParty && role === IRoles.PARTNER;
  const editTooltipContent = isEditDisable ? i18next.t('ASSET_PAGE.TOOLTIP.CANNOT_EDIT_ASSET') : i18next.t('ASSET_PAGE.TOOLTIP.EDIT_ASSET');
  return {
    id: value.id,
    clientId: value.client._id,
    isLocked,
    name: (
      <HeaderText data-testid="table-header" isLocked={isLocked} onClick={() => handleRowClick(row)}>
        <DuoContainer>
          <div>
            {renderMagicIcon(value)}
            {value.name}
          </div>
          {value?.isThirdParty && <ThirdPartyTag asset={value} />}
        </DuoContainer>
      </HeaderText>
    ),
    assetType: (
      <ColorAndType isLocked={isLocked} onClick={() => handleRowClick(row)}>
        <AssetTypeColorBlock assetTypeName={isLocked ? LOCKED_ASSET : value?.assetType?.name || ''} />
        <span>{isLocked ? LOCKED_ASSET : value?.assetType?.name ? assetTypeNameFormatter(value.assetType.name) : ''}</span>
      </ColorAndType>
    ),
    tags: (
      <AssetTagsContainer isLocked={isLocked} onClick={() => handleRowClick(row)}>
        {!!value?.tags?.length && (
          <>
            <span>{value.tags[0].name}</span>
            {value.tags.length > 1 && (
              <Tippy
                content={value.tags.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
                placement="top-end"
              >
                <span>+ {value.tags.length - 1}</span>
              </Tippy>
            )}
          </>
        )}
      </AssetTagsContainer>
    ),
    createdAt: <TableBodyText onClick={() => handleRowClick(row)}>{value?.createdAt && !value.needsToBeAcknowledgedByMe ? formatDate(value?.createdAt) : ''}</TableBodyText>,
    recipients: (
      <Tippy
        disabled={!isShowRecipients}
        content={<AcknowledgementTooltip acknowledgedRecipientsList={value.acknowledgedAccountsList} notAcknowledgedRecipientsList={value.notAcknowledgedAccountsList} />}
        placement="top-end"
      >
        {isShowRecipients && (
          <RecipientNumber allAcknowledged={allAcknowledged} onClick={() => handleRowClick(row)}>
            {!isLocked && (
              <>
                <span>
                  {allAcknowledged
                    ? value.recipients.length
                    : value.acknowledgedAccountsList.length
                    ? value.acknowledgedAccountsList.length + '/' + value.recipients.length
                    : '0/' + value.recipients.length}
                </span>
                <span>{i18next.t('ASSET_PAGE.ASSET_TABLE.RECIPIENTS')}</span>
              </>
            )}
          </RecipientNumber>
        )}
      </Tippy>
    ),
    action: (
      <>
        {value?.isThirdParty && !value?.hasAccessToThirdPartyAsset ? null : value?.needsToBeAcknowledgedByMe ? (
          <ActionsContainer>
            <Tippy content={i18next.t('ASSET_PAGE.TOOLTIP.ACKNOWLEDGE_ASSET')} placement="top-end">
              <span>
                <TangiIconButton icon="lock" variant={BUTTON_VARIANTS.SECONDARY} onClick={() => handleUnlockAsset(value)} />
              </span>
            </Tippy>
          </ActionsContainer>
        ) : (
          <ActionsContainer>
            {checkAssetSharePermission(role, permissions, value?.isThirdParty) && (
              <Tippy content={i18next.t('ASSET_PAGE.TOOLTIP.NOTIFY')} placement="top-end">
                <span>
                  <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => handleDistributeAsset(value)} icon="send" />
                </span>
              </Tippy>
            )}
            {isAdmin ? (
              <Tippy content={i18next.t('ASSET_PAGE.TOOLTIP.MORE_OPTIONS')} placement="top-end">
                <span>
                  <AssetOptions item={value} options={options} styles={customAssetOptionsStyles} />
                </span>
              </Tippy>
            ) : (
              <>
                {permissions?.Assetedit && (
                  <Tippy content={editTooltipContent} placement="top-end">
                    <span>
                      <TangiIconButton disabled={isEditDisable} icon="edit" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => options[0].handleItem(value)} />
                    </span>
                  </Tippy>
                )}
              </>
            )}
          </ActionsContainer>
        )}
      </>
    ),
  };
};
