import Excel, { Alignment, Buffer, Style } from 'exceljs';
import { ContinuationAnalysisResultsResponse, ETradeSecretCategory } from './types/patent/patent';

const categoryIndex = {
  [ETradeSecretCategory.TradeSecretsCandidates]: 1,
  [ETradeSecretCategory.ContinuationCandidates]: 2,
  [ETradeSecretCategory.SupportedClaims]: 3,
  [ETradeSecretCategory.RemovedTooSimilar]: 4,
};
const columnStyles = {
  subject: {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFDAD2E9' },
    },
    font: {
      bold: true,
      size: 12,
    },
    alignment: {
      horizontal: 'center',
      vertical: 'middle',
      wrapText: true,
    },
    border: {
      right: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      top: { style: 'thin', color: { argb: 'FF000000' } },
    },
  } as Style,
  potentialTradeSecrets: {
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF3F3F3' },
    },
    border: {
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
    },
  } as Style,
  originalText: {
    font: {
      bold: true,
      size: 11,
    },
  } as Style,
  tradeSecretColors: [{ argb: 'FFFFF0F7' }, { argb: 'FFFFF7E0' }, { argb: 'FFE4F1FF' }, { argb: 'FFEDFFE7' }],
  headerFont: {
    bold: true,
    size: 12,
  },
  alignmentTopLeft: {
    vertical: 'top',
    horizontal: 'left',
    wrapText: true,
  } as Alignment,
  paragraphHeaderFont: {
    bold: true,
    size: 12,
  },
};

export const createContinuationExcelFromJson = async (response: ContinuationAnalysisResultsResponse): Promise<Buffer> => {
  const { subjects } = response;
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Trade Secrets');

  // Set column widths
  worksheet.getColumn('A').width = 35;
  worksheet.getColumn('B').width = 45;
  worksheet.getColumn('C').width = 25;
  worksheet.getColumn('D').width = 25;

  let maxMappings = 0;
  subjects.forEach((subject) => {
    subject.tradeSecrets.forEach((tradeSecret) => {
      maxMappings = Math.max(maxMappings, tradeSecret.mappings.length);
    });
  });

  // Adding the headers with the styles
  const headers = ['Subject', 'Potential Trade Secrets', 'Category', 'Original Text'];
  const headerRow = worksheet.addRow(headers);
  headerRow.eachCell((cell, colNumber) => {
    cell.font = columnStyles.headerFont;
    cell.alignment = columnStyles.alignmentTopLeft;
    cell.border = columnStyles.subject.border;
    // Fill the header and the second row cells with the subject and potentialTradeSecrets fill
    if (colNumber === 1) {
      cell.fill = columnStyles.subject.fill;
    } else if (colNumber === 2 || colNumber === 3) {
      cell.fill = columnStyles.potentialTradeSecrets.fill;
    }
    cell.border = columnStyles.subject.border;
  });

  // Adding paragraph headers and making them bold
  const paragraphHeaderRow = worksheet.addRow(['', '', '', '', ...Array.from({ length: maxMappings }, (_, i) => `Paragraph ${i + 1}`)]);
  paragraphHeaderRow.eachCell((cell) => {
    if (Number(cell.col) >= 2 && Number(cell.col) <= 3) {
      cell.fill = columnStyles.potentialTradeSecrets.fill;
    }
    if (Number(cell.col) >= 5) {
      cell.font = columnStyles.paragraphHeaderFont;
      cell.alignment = columnStyles.alignmentTopLeft;
    }
  });

  // Apply freeze panes
  worksheet.views = [{ state: 'frozen', xSplit: 3, ySplit: 2 }];

  // Fill row 2 column A with the subject fill
  worksheet.getRow(2).getCell(1).fill = columnStyles.subject.fill;

  // Style header cells for categories and paragraphs
  worksheet.getRow(1).eachCell((cell) => {
    cell.alignment = columnStyles.alignmentTopLeft;
    cell.font = columnStyles.headerFont;
  });

  // Populate the worksheet with subject data
  let currentRowNumber = 3;
  subjects.forEach((subject) => {
    const originalTextCount: Record<string, { startRow: number; count: number }> = {};
    // let categoryCounter = 1;
    subject.tradeSecrets.forEach((tradeSecret) => {
      // Keep track of 'Original Text' occurrences for merging
      const originalText = `From ${tradeSecret.originalText}`;
      if (!originalTextCount[originalText]) {
        originalTextCount[originalText] = { startRow: currentRowNumber, count: 0 };
      }
      originalTextCount[originalText].count++;

      // Prefix each category item with "Category number X -"
      const categoryText = getCategoryText(tradeSecret.category);
      // categoryCounter++;
      const row = worksheet.addRow([subject.title, tradeSecret.title, categoryText, originalText, ...tradeSecret.mappings.map((m) => `${m.text} - score = ${m.score?.toFixed(2)}`)]);

      // Style the subject cells
      const subjectCell = row.getCell(1);
      subjectCell.style = columnStyles.subject;

      // Style the trade secret cells with color based on index
      const potentialTSCell = row.getCell(2);
      potentialTSCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: getCategoryFgColor(tradeSecret.category),
      };
      const categoryCell = row.getCell(3);

      categoryCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: getCategoryFgColor(tradeSecret.category),
      };
      potentialTSCell.border = columnStyles.potentialTradeSecrets.border;

      const originalTextCell = row.getCell(4);
      originalTextCell.style = columnStyles.originalText;
      // Style all cells with top-left alignment
      row.eachCell((cell) => {
        cell.alignment = columnStyles.alignmentTopLeft;
        cell.border = columnStyles.subject.border;
      });

      currentRowNumber++;
    });

    // Merge cells with the same 'Original Text'
    for (const [, { startRow, count }] of Object.entries(originalTextCount)) {
      if (count > 1) {
        worksheet.mergeCells(`D${startRow}:D${startRow + count - 1}`);
      }
    }

    // Merge subject cells if more than one trade secret
    if (subject.tradeSecrets.length > 1) {
      worksheet.mergeCells(`A${currentRowNumber - subject.tradeSecrets.length}:A${currentRowNumber - 1}`);
    }
  });

  const getColumnLetter = (columnNumber: number): string => {
    let temp = columnNumber,
      letter = '';
    while (temp > 0) {
      const remainder = (temp - 1) % 26;
      letter = String.fromCharCode(65 + remainder) + letter;
      temp = Math.floor((temp - 1) / 26);
    }
    return letter;
  };
  // Set paragraph column widths
  for (let i = 0; i < maxMappings; i++) {
    const columnLetter = getColumnLetter(5 + i);
    worksheet.getColumn(columnLetter).width = 100;
  }

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const getCategoryText = (category: ETradeSecretCategory): string => {
  return `Category ${categoryIndex[category]} - ${category}`;
};

const getCategoryFgColor = (category: ETradeSecretCategory) => {
  return columnStyles.tradeSecretColors[categoryIndex[category] - 1];
};

export const triggerContinuationExcelDownload = (buffer: Buffer, filename: string) => {
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
