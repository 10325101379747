import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import InventionTradeSecretCard from './InventionTradeSecretCard';
import { PotentialTradeSecret } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { CurrentTradeSecret, DropdownOption } from '_hocs/XRayAssetCreation/types';
import { SPACING } from 'utils/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;

interface Props {
  tradeSecrets: PotentialTradeSecret[];
  optionsWithCustomCallbacks?: (currTradesecret: CurrentTradeSecret, index: number) => DropdownOption[];
}

const InventionTradeSecrets = ({ tradeSecrets, ...props }: Props) => {
  return (
    <Container>
      {!!tradeSecrets?.length &&
        tradeSecrets.map((tradeSecret, index) => <InventionTradeSecretCard renderOptions={props.optionsWithCustomCallbacks} tradeSecret={tradeSecret} key={uuidv4()} tradeSecretIndex={index} />)}
    </Container>
  );
};
export default InventionTradeSecrets;
