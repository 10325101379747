import { Fragment, forwardRef, useMemo } from 'react';

import { WidgetAccountObject, WidgetCardProps } from '../Widgets.types';
import WidgetCard from '../WidgetCard';
import { Divider } from 'utils/globalStyles';
import ContributorItem from './ContributorItem';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';
import WidgetTitle from '../WidgetTitle';
import { useTranslation } from 'react-i18next';
import WidgetTableTitle from '../../Common/WidgetTableTitle';

const ContributorsWidgetContainer = styled.div`
  display: flex;
  padding-block: 0;
  padding-inline: ${SPACING[3]} 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 64px;
  overflow-y: auto;

  .widget-table-title {
    margin-right: ${SPACING[3]};
  }
`;

const TopContributorsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 12px;
`;

interface Props extends WidgetCardProps {
  data: WidgetAccountObject[];
}

export const Contributors = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();

  const contributorItems = useMemo(() => {
    return props.data.map((item) => {
      return (
        <Fragment key={item._id}>
          <div>
            <ContributorItem {...item} />
            <StyledDivider />
          </div>
        </Fragment>
      );
    });
  }, [props.data]);
  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.CONTRIBUTORS')} />
        <ContributorsWidgetContainer>
          <WidgetTableTitle suffix1="COMMON.NAME" suffix2="COMMON.ASSETS" />
          <TopContributorsContainer>{contributorItems}</TopContributorsContainer>
        </ContributorsWidgetContainer>
        {props.children}
      </>
    </WidgetCard>
  );
});
