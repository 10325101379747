import styled, { css } from 'styled-components';
import { NEUTRAL_SHADES } from '../../utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'DIN2014-REGULAR';
`;

export const PhotoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  gap: 8px;
  max-height: 75vh;
  padding: 0 16px 16px 16px;
  height: 100%;
  width: 100%;
`;

export const DuoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  box-sizing: border-box;
  > div {
    flex: 1;
  }
`;

export const Separator = styled.div`
  border-bottom: 1.5px solid #00000020;
  margin: 12px 0;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  .documents-title {
    color: black;
    font-size: 24px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  width: 48%;
  gap: 24px;
  box-sizing: border-box;
  align-self: flex-end;
`;

export const OuterCircle = styled.div`
  display: flex;
  border: dashed 2px;
  border-color: ${NEUTRAL_SHADES[400]};
  border-radius: 50%;
  background: transparent;
  width: 130px;
  height: 130px;
  fill: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const InnerCircle = styled.div`
  ${({ $isLogo: isLogo }) => css`
    border-radius: 50%;
    background: ${isLogo ? 'none' : NEUTRAL_SHADES.LIGHT_GRAY};
    height: 118px;
    width: 118px;
    display: flex;
    justify-content: center;
    align-items: center
  `}
`;

export const CameraButton = styled.button`
  border-color: transparent;
  background-color: transparent;
`;
export const LogoContanier = styled.img`
  border-radius: 50%;
  background: ${NEUTRAL_SHADES.GRAY};
  opacity: 0.6;
  height: 118px;
  width: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CameraImg = styled.img`
  position: absolute;
  top: 52px;
  right: 52px;
  z-index: 1;
`;
