import React from 'react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { isRoleMatch, IRoles } from './roles';
import { NEUTRAL_SHADES } from './theme';
import { TangiTypography } from '_components/TangiLibrary';
import { FlexContainerGap1 } from '_components/PartnersSideMenuDrawer/style';

function getSortIcon(activeSelectorRole, field) {
  return activeSelectorRole.selector === field && activeSelectorRole.direction === 'asc' ? faSortUp : faSortDown;
}

export const getColumns = (activeSelectorRole = false, isPartner = false, isPeople = true, role = '') => [
  {
    name: (
      <FlexContainerGap1>
        <TangiTypography weight="bold" color="inherit">
          {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.NAME')}
        </TangiTypography>
        <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'name')} />
      </FlexContainerGap1>
    ),
    selector: ({ name }) => name,
    sortable: true,
    fieldName: 'name',
  },
  {
    name: (
      <FlexContainerGap1>
        <TangiTypography weight="bold" color="inherit">
          {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.STATUS')}
        </TangiTypography>
        <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'accountStatus')} />
      </FlexContainerGap1>
    ),
    selector: ({ accountStatus }) => accountStatus,
    fieldName: 'accountStatus',
    sortable: true,
  },
  ...(!isPartner
    ? [
        {
          name: (
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.ACKNOWLEDGMENT')}
            </TangiTypography>
          ),
          selector: ({ acknowledgment }) => acknowledgment,
          sortable: false,
          style: { width: '140px' },
        },
      ]
    : []),
  ...(!isPartner
    ? [
        {
          name: (
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.AGREEMENTS')}
            </TangiTypography>
          ),
          selector: ({ agreements }) => agreements,
          sortable: false,
          style: { width: '140px', display: 'flex', alignItems: 'center' },
        },
      ]
    : []),
  ...(!isPartner
    ? [
        {
          name: (
            <TangiTypography weight="bold" color="inherit">
              {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.TRAINING')}
            </TangiTypography>
          ),
          selector: ({ training }) => training,
          sortable: false,
          style: { width: '150px' },
        },
      ]
    : []),
  {
    name: (
      <FlexContainerGap1>
        <FlexContainerGap1 style={{ flexDirection: 'column', gap: 0 }}>
          <TangiTypography weight="bold" color="inherit">
            {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.START_DATE')}
          </TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES[800]} type="sub-body">
            {i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.EXIT_DATE')}
          </TangiTypography>
        </FlexContainerGap1>
        <FontAwesomeIcon className="fs-6" icon={getSortIcon(activeSelectorRole, 'startdate')} />
      </FlexContainerGap1>
    ),
    selector: ({ startdate }) => startdate,
    sortable: true,
    fieldName: 'startdate',
  },
  isPeople && {
    name: <></>,
    selector: ({ editdelete }) => editdelete,
    omit: role && isRoleMatch(role, IRoles.EMPLOYEE_MANAGER) && !isPartner,
    maxWidth: '120px',
    style: { justifyContent: 'flex-end', display: 'flex' },
  },
];

export const customStyles = {
  table: {
    style: {
      overflow: 'visible !important',
    },
  },
  tableWrapper: {
    style: {
      overflow: 'visible !important',
    },
  },
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[150]}`,
    },
  },
  headCells: {
    style: {
      color: `${NEUTRAL_SHADES[1100]}`,
      '&:not(:last-of-type)': {
        marginLeft: '10px',
      },
      '&:first-of-type': {
        minWidth: '224px',
      },
    },
  },
  cells: {
    style: {
      '& > *': {
        overflow: 'visible !important',
        '& div': {
          overflow: 'visible !important',
        },
      },
      '&:hover': {
        cursor: 'pointer',
      },
      '&:not(:last-of-type)': {
        marginLeft: '10px',
      },
      '&:first-of-type': {
        minWidth: '224px',
      },
    },
  },
  rows: {
    style: {
      overflow: 'visible !important',
    },
  },
};
