import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const PercentageTextContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const scoreTextStyles = `
  font-size: 40px;
  margin-right: 2px;
`;

export const ScoreContainer = styled(FlexContainer)`
  width: 220px;
  height: 180px;
  padding: ${SPACING[3]} ${SPACING[3]};
  flex-direction: column;
  align-items: flex-start;
  gap: ${SPACING[2]};
  border-radius: ${SPACING[2]};
  border: 1px solid ${NEUTRAL_SHADES[300]};
  background: ${NEUTRAL_SHADES.WHITE};
`;

export const percentageTextStyles = `
  font-size: 30px;
`;

export const ContractContainer = styled(FlexContainer)`
  width: 100%;
  flex-direction: column;
  gap: ${SPACING[3]};
`;
