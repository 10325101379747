/**
 * Constructs a new email address by inserting a specific alias into the user part of an email.
 * @param {string} email The base email address.
 * @param {string} alias The alias to insert.
 * @returns {string} The new email address.
 */
export const constructEmailWithAlias = (email: string, alias: string) => {
  const parts = email.split('@');
  return `${parts[0]}+${alias}@${parts[1]}`;
};
