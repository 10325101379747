import styled from "styled-components";
import { DuoContainer } from "utils/globalStyles";

export const DropZoneTextContainer = styled(DuoContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  .tangi-typography:last-of-type {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;