import styled, { css } from 'styled-components';

import { NEUTRAL_SHADES, THEME_COLORS } from '../../../utils/theme';
import { search } from '../TangiSvgIcon/svgFiles';

export const SearchContainer = styled.div`
  position: relative;
`;

export const StyledSearchInput = styled.input<{ width: string }>`
  ${({ width = '160px' }) => css`
    width: ${width};
    height: 32px;
    padding-left: 34px;
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid ${NEUTRAL_SHADES[600]};
    &::placeholder {
      font-family: 'DIN2014-Regular' !important;
      color: ${NEUTRAL_SHADES[800]};
    }
    &:focus {
      border-color: ${THEME_COLORS.PRIMARY_DEFAULT};
      background: ${NEUTRAL_SHADES.WHITE};
    }
    &:hover:not(:focus):not(:disabled) {
      border-color: ${NEUTRAL_SHADES[800]};
    }
    &:disabled {
      border-color: ${NEUTRAL_SHADES[600]};
      color: ${NEUTRAL_SHADES[600]};
      background: ${NEUTRAL_SHADES[50]};
      cursor: not-allowed;
    }
  `}
`;

export const SearchIcon = styled(search)<{ disabled: boolean }>`
  ${({ disabled = false }) => css`
    height: 20px;
    width: 20px;
    position: absolute;
    left: 10px;
    top: 6px;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    path {
      fill: ${disabled ? NEUTRAL_SHADES[600] : NEUTRAL_SHADES[800]};
    }
    &.active {
      background: ${NEUTRAL_SHADES.WHITE};
    }
  `}
`;
