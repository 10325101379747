import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { courseProgressionsActions } from '../../_actions';
import GoToQuiz from '../GoToQuiz/GoToQuiz';
import { useTranslation } from 'react-i18next';

export const LearnSingleCourse = ({ course, courseProgression }) => {
  const { user } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    // If courseProgressions is not found -> create a new process
    if (courseProgression === 'not found') {
      dispatch(courseProgressionsActions.createCourseProgression(course.id, user._id));
    }
  }, [courseProgression]);
  return (
    <Row className="px-39 m-3 mx-3">
      <Col className="col-auto col-sm-12 p-0">
        <a href={`/courses/${clientId}`} className="btn btnback btn-secondary mb-4 shadow-none text-muted border border-black fw-lighter" type="button">
          {t('LEARN_PAGE.GO_BACK_TO_COURSES')}
        </a>
      </Col>
      <Col className="col-auto col-sm-12">
        <h3 className="h1 font-weight-normal text-blk">{course.title}</h3>
      </Col>
      <Card style={{ padding: '0' }}>
        <ReactPlayer
          data-testid="player"
          width="100%"
          height="500px"
          url={course.featuredVideoUrl}
          controls
          config={{
            youtube: {
              playerVars: { modestbranding: 1 },
            },
          }}
        />
        <Card.Body className="p-4 mt-2">
          <Row>
            <Col sm={8}>
              <Card.Text>{course.description}</Card.Text>
            </Col>
            <Col sm={4}>
              <GoToQuiz courseId={course.id} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};
