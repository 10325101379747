/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import { Fragment, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Overlay } from 'react-bootstrap';
import styled from 'styled-components';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import AcknowledgmentPeopleTableHover from './AcknowledgmentPeopleTableHover';

const StyledPopover = styled(Popover)`
  width: 300px;
  border: none;
  box-shadow: 0px 3px 16px 0px #0000001f;
`;

const AcknowledgmentPeopleTable = ({ acknowledgments }: { acknowledgments: any }) => {
  const { t } = useTranslation();

  const [hovered, setHovered] = useState(false);

  const target = useRef(null);
  const totalAcknowledgments = useMemo(() => acknowledgments.length, [acknowledgments]);
  const signedAcknowledgments = useMemo(() => acknowledgments.filter((acknowledgment: any) => acknowledgment.acknowledged).length, [acknowledgments]);
  const isFullySigned = useMemo(() => signedAcknowledgments === totalAcknowledgments, [totalAcknowledgments, signedAcknowledgments]);
  const isZeroAcknowledgments = useMemo(() => totalAcknowledgments === 0 && signedAcknowledgments === 0, [totalAcknowledgments, signedAcknowledgments]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Fragment>
      {!isZeroAcknowledgments && (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={target}>
          {isFullySigned ? (
            <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
          ) : (
            <TangiTypography color={NEUTRAL_SHADES[800]}>
              {signedAcknowledgments}/{totalAcknowledgments} {t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.IS_SIGNED')}
            </TangiTypography>
          )}
          <Overlay target={target.current} show={hovered} placement="top">
            {(props) => (
              <StyledPopover {...props}>
                <AcknowledgmentPeopleTableHover acknowledgments={acknowledgments} />
              </StyledPopover>
            )}
          </Overlay>
        </div>
      )}
    </Fragment>
  );
};

export default AcknowledgmentPeopleTable;
