import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { AdminUsersForm } from '../../../_components/Admin/AdminUsersForm';
import { AdminDashboard } from '../AdminDashboard';

function AdminUserEditView() {
  const { type: formType, id: accountId } = useParams();

  return (
    <AdminDashboard title="Users">
      <Container fluid className="mrx-4  pb-8 pt-3 pt-md-8">
        <Row>
          <Col sm={12}>
            <AdminUsersForm type={formType} accountId={accountId} />
          </Col>
        </Row>
      </Container>
    </AdminDashboard>
  );
}

export { AdminUserEditView };
