import DataTable from 'react-data-table-component';
import { createTableColum, makeTableRow } from './utils';
import TableSkeleton from '_components/TableSkeleton/TableSkeleton';
import { TableContainer, customTableStyles } from './style';
import useScrollToElement from '../../../../_hooks/useScrollToElement'

export const CompetitiveDataTable = ({ tradeSecrets, pagination, loading, setPagination }) => {
  const { ref: tableRef, scrollToElement: scrollToTopOfTable } = useScrollToElement();
  const metadata = tradeSecrets?.metadata[0];

  const tableData = tradeSecrets?.data?.map((value) =>
    makeTableRow({
      value,
    }),
  );

  const handleChangePage = (newPage) => {
    if (newPage !== pagination?.page) {
      if (setPagination) {
        setPagination({ ...pagination, page: newPage });
      }
      scrollToTopOfTable();
    }
  };

  const clientTableViewColumn = createTableColum();

  return (
    <TableContainer ref={tableRef}>
      <DataTable
        columns={clientTableViewColumn}
        customStyles={customTableStyles}
        data={tableData}
        progressPending={loading.table}
        progressComponent={<TableSkeleton count={pagination.limit} />}
        pagination
        paginationServer
        paginationTotalRows={metadata?.total || 0}
        paginationPerPage={pagination.limit}
        paginationDefaultPage={metadata?.page ?? 1}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={handleChangePage}
      />
    </TableContainer>
  );
};
