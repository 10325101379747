import { Dashboard } from 'pages/Dashboard';
import { Container, ImageContainer, LeftNavButton, NavPoint, NavPointsContainer, RightNavButton } from './style';
import { ReactComponent as EmailTrackerExample1 } from './images/emailTrackerExample1.svg';
import { useEffect, useReducer, useState } from 'react';
import { ReactComponent as EmailTrackerExample2 } from './images/emailTrackerExample2.svg';
import { NOTIFICATION_VARIANTS, TangiButton, TangiNotification, TangiToast } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { BUTTON_VARIANTS } from 'utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { joinDiscoveryEnquiry } from 'redux-toolkit/thunks/clientThunks';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { RESULT_STATUS } from 'utils/enums';

const IMAGES = [<EmailTrackerExample1 key="emailTrackerExample1" height="100%" width="100%" />, <EmailTrackerExample2 key="emailTrackerExample2" height="100%" width="100%" />];

const SWITCH_INTERVAL = 6500;

const EmailTrackerMainPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { activeAccount, user, Role } = useSelector((state: RootState) => state.authentication);
  const { clientToastProps, discoveryDataLoaders } = useSelector((state: RootState) => state.client);

  const [displayedImageIndex, setDisplayedImageIndex] = useState(0);
  const [shouldRestartInterval, setShouldRestartInterval] = useReducer((prev) => !prev, false);

  const isLoading = discoveryDataLoaders.joinDiscoveryLoading;

  const hasLeftNav = displayedImageIndex > 0;
  const hasRightNav = displayedImageIndex < IMAGES.length - 1;

  const handleLeftNav = () => {
    setShouldRestartInterval();
    setDisplayedImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleRightNav = () => {
    setShouldRestartInterval();
    setDisplayedImageIndex((prev) => (prev < IMAGES.length - 1 ? prev + 1 : prev));
  };

  const handleKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'ArrowLeft') {
      handleLeftNav();
    } else if (event.key === 'ArrowRight') {
      handleRightNav();
    }
  };

  const onPointClick = (index: number) => {
    setShouldRestartInterval();
    setDisplayedImageIndex(index);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedImageIndex((prev) => (prev < IMAGES.length - 1 ? prev + 1 : 0));
    }, SWITCH_INTERVAL);

    return () => clearInterval(interval);
  }, [shouldRestartInterval]);

  const handleJoinBeta = () => {
    dispatch(
      joinDiscoveryEnquiry({
        name: user?.fullName || activeAccount?.displayName || '',
        email: user.email,
        clientName: activeAccount?.client?.name || activeAccount?.lawFirm?.name || '',
        role: Role,
      }),
    );
  };

  return (
    <>
      <Dashboard title="">
        <Container>
          <TangiNotification
            variant={NOTIFICATION_VARIANTS.MARKETING}
            title={t('EMAIL_TRACKER_MAIN_PAGE.NOTIFICATION.TITLE')}
            rightAnchor={<TangiButton text={t('GENERAL.BUTTONS_TEXT.REQUEST_ACCESS')} variant={BUTTON_VARIANTS.PRIMARY_PURPLE} loading={isLoading} onClick={handleJoinBeta} />}
          >
            {t('EMAIL_TRACKER_MAIN_PAGE.NOTIFICATION.DESCRIPTION')}
          </TangiNotification>
          <ImageContainer>
            {IMAGES[displayedImageIndex]}
            {hasLeftNav && <LeftNavButton icon="leftCircleArrow" variant={BUTTON_VARIANTS.SECONDARY_PURPLE} onClick={handleLeftNav} />}
            {hasRightNav && <RightNavButton icon="rightCircleArrow" variant={BUTTON_VARIANTS.SECONDARY_PURPLE} onClick={handleRightNav} />}
          </ImageContainer>
          <NavPointsContainer>
            {IMAGES.map((_, index) => (
              <NavPoint key={index} isActive={displayedImageIndex === index} onClick={() => onPointClick(index)} />
            ))}
          </NavPointsContainer>
        </Container>
      </Dashboard>
      <TangiToast
        {...clientToastProps}
        onSuccess={() => {
          dispatch(clientActions.setClientToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </>
  );
};

export default EmailTrackerMainPage;
