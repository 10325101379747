import { adminAssetContants } from '../_constants/adminAssetContants';
import { adminAssetService } from './../_services/adminAssetService';

function getAdminAsset(page, search, sorting) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAsset(page, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetById(assetId) {
  function request() {
    return { type: adminAssetContants.GET_ASSET_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.GET_ASSET_SUCCESS, payload };
  }
  function failure(errors) {
    return { type: adminAssetContants.GET_ASSET_FAILURE, errors };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetById(assetId)
      .then((asset) => {
        if (asset?.data.length > 0) dispatch(success(asset.data[0]));
      })
      .catch((errors) => {
        dispatch(failure(errors));
      });
  };
}

function getAdminAssetType(page, search, sorting) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetType(page, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetStatus(page, search, sorting) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_STATUS_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_STATUS_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_STATUS_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetStatus(page, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetBusinessValue(page, search, sorting) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_BUSINESS_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_BUSINESS_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_BUSINESS_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetBusinessValue(page, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTags(page, search, sorting) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTags(page, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTagsEdit(data, firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_EDIT_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_EDIT_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_EDIT_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTagsEdit(data, firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTypeEdit(data, firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_EDIT_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_EDIT_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_EDIT_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTypeEdit(data, firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetBvEdit(data, firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_BV_EDIT_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_BV_EDIT_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_BV_EDIT_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetBvEdit(data, firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTagsView(firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_VIEW_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_VIEW_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_VIEW_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTagsView(firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTypeView(firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_VIEW_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_VIEW_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_VIEW_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTypeView(firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetBvView(firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_BV_VIEW_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_BV_VIEW_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_BV_VIEW_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetBvView(firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTagsDelete(firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_DELETE_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_DELETE_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TAGS_DELETE_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTagsDelete(firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetTypeDelete(firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_DELETE_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_DELETE_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_TYPE_DELETE_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetTypeDelete(firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAssetBvDelete(firmid) {
  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_BV_DELETE_REQUEST };
  }
  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_BV_DELETE_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAssetContants.ADMIN_ASSET_BV_DELETE_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .getAdminAssetBvDelete(firmid)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function createAdminAsset(data) {
  const { recipients, files, contributor } = data;
  recipients && delete data.recipients;
  files && delete data.files;
  data.contributor = [contributor];

  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_CREATE };
  }

  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_CREATE_SUCCESS, payload };
  }

  function failure(errors) {
    return { type: adminAssetContants.ADMIN_ASSET_CREATE_FAILURE, errors };
  }

  function fileSuccess(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_FILE_SUCCESS, payload };
  }

  function fileFailure(errors) {
    return { type: adminAssetContants.ADMIN_ASSET_FILE_FAILURE, errors };
  }

  function shareSuccess(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_SHARE_SUCCESS, payload };
  }

  function shareFailure(errors) {
    return { type: adminAssetContants.ADMIN_ASSET_SHARE_FAILURE, errors };
  }

  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .createAdminAsset(data)
      .then((asset) => {
        const assetId = asset.id;
        const client = data.client;

        const shareNfilesPromises = [];
        recipients && recipients.length > 0 && shareNfilesPromises.push(adminAssetService.shareAdminAsset(recipients, assetId));
        files && files.length > 0 && shareNfilesPromises.push(adminAssetService.uploadAssetFiles(files, client, assetId));

        if (shareNfilesPromises.length > 0) {
          Promise.all(shareNfilesPromises)
            .then((results) => {
              dispatch(shareSuccess(results[0]));
              dispatch(fileSuccess(results[1]));
              dispatch(success(asset));
            })
            .catch((error) => {
              dispatch(failure(error));
            });
        } else {
          dispatch(success(asset));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function updateAdminAsset(data, assetId) {
  const { newRecipients, newFiles } = data;
  newRecipients && delete data.newRecipients;
  newFiles && delete data.newFiles;

  function request() {
    return { type: adminAssetContants.ADMIN_ASSET_UPDATE };
  }

  function success(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_UPDATE_SUCCESS, payload };
  }

  function failure(errors) {
    return { type: adminAssetContants.ADMIN_ASSET_UPDATE_FAILURE, errors };
  }

  function fileSuccess(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_FILE_SUCCESS, payload };
  }

  function shareSuccess(payload) {
    return { type: adminAssetContants.ADMIN_ASSET_SHARE_SUCCESS, payload };
  }

  return (dispatch) => {
    dispatch(request());
    adminAssetService
      .updateAdminAsset(data, assetId)
      .then((asset) => {
        const assetId = asset.id;
        const client = data.client;

        const shareNfilesPromises = [];
        newRecipients && newRecipients.length > 0 && shareNfilesPromises.push(adminAssetService.shareAdminAsset(newRecipients, assetId));
        newFiles && newFiles.length > 0 && shareNfilesPromises.push(adminAssetService.uploadAssetFiles(newFiles, client, assetId));

        if (shareNfilesPromises.length > 0) {
          Promise.all(shareNfilesPromises)
            .then((results) => {
              dispatch(shareSuccess(results[0]));
              dispatch(fileSuccess(results[1]));
              dispatch(success(asset));
            })
            .catch((error) => {
              dispatch(failure(error));
            });
        } else {
          dispatch(success(asset));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function clearAdminAsset() {
  return { type: adminAssetContants.ADMIN_ASSET_CREATE };
}

export const adminAssetActions = {
  getAdminAsset,
  getAdminAssetBusinessValue,
  getAdminAssetStatus,
  getAdminAssetTags,
  getAdminAssetType,
  getAdminAssetTagsView,
  getAdminAssetTagsDelete,
  getAdminAssetTagsEdit,
  getAdminAssetTypeView,
  getAdminAssetTypeEdit,
  getAdminAssetTypeDelete,
  getAdminAssetBvView,
  getAdminAssetBvEdit,
  getAdminAssetBvDelete,

  createAdminAsset,
  clearAdminAsset,
  getAdminAssetById,
  updateAdminAsset,
};
