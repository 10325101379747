import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

export const RadioInput = styled.input`
  margin-right: ${SPACING[1]};
  margin-bottom: 1px;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
`;

export const Title = styled(TangiTypography)`
  margin-bottom: 6px;
`;
