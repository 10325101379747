import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const PageContentWrapper = styled(Col)<{ $isSideMenuVisible: boolean }>`
  @media only screen and (min-width: 768px) {
    min-width: 0;
    width: ${({ $isSideMenuVisible: isSideMenuVisible }) => (isSideMenuVisible ? 'calc(100% - 226px)' : '100%')};
    min-height: calc(100vh - 68px);
    margin-top: 68px;
  }

  @media only screen and (max-width: 768px) {
    min-width: 0;
    width: 100%;
    min-height: calc(100vh - 65px);
  }
`;
