import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import { ReactComponent as ArrownDown } from '../../assets/tangiIcons/arrow-down.svg';
import { NEUTRAL_SHADES, PRIMARY_SHADES, THEME_COLORS, SPACING } from '../../utils/theme';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-inline: ${SPACING[2]};
`;

// ----- Datepicker styles ----- //

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  .rdp {
    font-family: DIN2014-Regular;
    font-size: 14px;
    margin-block: 10px;
    --rdp-outline: 2px solid ${NEUTRAL_SHADES.WHITE};
    --rdp-outline-selected: 3px solid ${NEUTRAL_SHADES.WHITE};
    --rdp-background-color: ${NEUTRAL_SHADES.WHITE};
    --rdp-cell-size: 36px;
    .dates-selected:not([disabled]) {
      background: ${PRIMARY_SHADES[200]};
  }
  .dates-selected:hover:not([disabled]) { 
    background: ${PRIMARY_SHADES[400]};
  }
  .rdp-cell button:hover{
    background:  ${PRIMARY_SHADES[200]};
  }
  .rdp-button.rdp-day.dates-selected.rdp-day_range_start, .rdp-button.rdp-day.dates-selected.rdp-day_range_end {
    background: ${PRIMARY_SHADES[800]};
    color: ${NEUTRAL_SHADES.WHITE};
  }
  .rdp-button.rdp-day.dates-selected.rdp-day_range_start:hover, .rdp-button.rdp-day.dates-selected.rdp-day_range_end:hover {
    background: ${PRIMARY_SHADES[900]};
  }
  .rdp-caption_label {
    font-size: 18px;
  }
  .rdp-caption_dropdowns {
    cursor: pointer;
    gap: 8px;
  }
  .rdp-nav button:hover{
    background:  ${PRIMARY_SHADES[200]};
  }
  .rdp-dropdown_icon:hover {
    background:  ${PRIMARY_SHADES[200]};
  }
  .rdp-dropdown_month, .rdp-dropdown_year {
    border-radius: 4px;
  }
  .rdp-dropdown_month:hover, .rdp-dropdown_year:hover {
    background:  ${PRIMARY_SHADES[100]};
  }
`;
