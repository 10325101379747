import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TangiIconButton, TangiButton, TangiTypography, TangiSvgIcon } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { StyledModal, Divider } from '../../utils/globalStyles';
import { StyledModalHeader, StyledModalBody, StyledModalFooter, TitleContainer, IconAndText, StyledTextArea } from './style';

interface IEditTextModalProps {
  text: string;
  isOpen: boolean;
  onClose: () => void;
  onContinue: (value: string) => void;
}

const EditTextModal = ({ text, isOpen, onClose, onContinue }: IEditTextModalProps) => {
  const [textareaValue, setTextareaValue] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    setTextareaValue(text);
  }, [text]);

  return (
    <StyledModal transition="Fade" show={isOpen} onHide={onClose}>
      <StyledModalHeader>
        <TitleContainer>
          <IconAndText>
            <TangiSvgIcon component="edit" size="24px" />
            <TangiTypography type="heading-lg">{t('PATENT_TOOL.EDIT_TEXT.EDIT_SUBJECT')}</TangiTypography>
          </IconAndText>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onClose} icon="close" />
        </TitleContainer>
      </StyledModalHeader>
      <StyledModalBody>
        <StyledTextArea value={textareaValue} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setTextareaValue(e.target.value)} />
      </StyledModalBody>
      <StyledModalFooter>
        <Divider />
        <span>
          <TangiButton text={t('GENERAL.BUTTONS_TEXT.CONTINUE')} onClick={() => onContinue(textareaValue)} />
        </span>
      </StyledModalFooter>
    </StyledModal>
  );
};

export default EditTextModal;
