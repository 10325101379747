import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const DragHandleContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    .drag-handle {
      visibility: visible;
    }
  }
`;

export const DragHandle = styled.div`
  visibility: hidden;
  cursor: grab;
`;

export const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;
