import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// ---------------------------- Redux Toolkit ----------------------------
import assetMetaDataSlice from 'redux-toolkit/slices/assetMetaDataSlice';
import assetsSlice from 'redux-toolkit/slices/assetsSlice';
import clientSlice from 'redux-toolkit/slices/clientSlice';
import authSlice from '../redux-toolkit/slices/authSlice';
import usersSlice from '../redux-toolkit/slices/usersSlice';
import patentSlice from '../redux-toolkit/slices/patentSlice';
import partnershipSlice from '../redux-toolkit/slices/partnershipSlice';
import dashboardSlice from '../redux-toolkit/slices/dashboardSlice';
import lawFirmSlice from '../redux-toolkit/slices/lawfirmSlice';
import contractSlice from 'redux-toolkit/slices/contractSlice';
import inventionDisclosureSlice from 'redux-toolkit/slices/inventionDisclosureSlice';
import agreementSlice from 'redux-toolkit/slices/agreementSlice';
import predictedPortfolioSlice from 'redux-toolkit/slices/predictedPortfolioSlice';
import competitiveAnalysisSlice from 'redux-toolkit/slices/competitiveAnalysisSlice';
import sharePointSlice from 'redux-toolkit/slices/sharePointSlice';

// ---------------------------- Redux ----------------------------
import agreementReducer from '../_reducers/agreementReducer';
import { clientSettings } from '../_reducers/clientSettingsReducer';
import { courseReducer } from '../_reducers/courseReducer';
import { courseProgressionsReducer } from '../_reducers/courseProgressionsReducer';
import { clientAdmin } from '../AdminRedux/_reducers/adminClientReducer';
import { adminAuthentication } from '../AdminRedux/_reducers/adminAuthReducer';
import { adminAssetReducer } from '../AdminRedux/_reducers/adminAssetReducer';
import { adminAgreementReducer } from '../AdminRedux/_reducers/adminAgreementReducer';
import { adminCourseReducer } from '../AdminRedux/_reducers/adminCourseReducer';
import { adminUserReducer } from '../AdminRedux/_reducers/adminUserReducer';
import { acknowledgementReducer } from '../_reducers/acknowledgementReducer';
import { discoveryReducer } from '../_reducers/discoveryReducer';

// ---------------------------- Middleware ----------------------------
import { trackingMiddleware } from '_services/utils/MixPanel/mixpanelConfig';

// TODO: any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const store = configureStore<any>({
  reducer: {
    authentication: authSlice,
    asset: assetsSlice,
    users: usersSlice,
    client: clientSlice,
    clientSettings: clientSettings,
    assetMetaData: assetMetaDataSlice,
    patent: patentSlice,
    contract: contractSlice,
    inventionDisclosure: inventionDisclosureSlice,
    predictedPortfolio: predictedPortfolioSlice,
    competitiveAnalysis: competitiveAnalysisSlice,
    lawfirm: lawFirmSlice,
    agreement: agreementReducer, //TODO: old - please do not anyting new here - needs to be removed
    agreementNew: agreementSlice, //new - please use this one
    courseReducer: courseReducer,
    courseProgressionsReducer: courseProgressionsReducer,
    partnership: partnershipSlice,
    acknowledgementReducer: acknowledgementReducer,
    discovery: discoveryReducer,
    dashboard: dashboardSlice,
    sharePoint: sharePointSlice,
    // super admin reducers
    clientAdmin,
    adminAuthentication,
    adminAssetReducer,
    adminAgreementReducer,
    adminCourseReducer,
    adminUserReducer,
  },
  // FIXME: need to fix the type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  middleware(getDefaultMiddleware): any {
    return getDefaultMiddleware().concat(trackingMiddleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
