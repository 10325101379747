import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Modal, Button, Form, InputGroup, FormControl } from 'react-bootstrap';

import './AdminLawFirm.scss';
import { AdminDashboard } from '../AdminDashboard';
import { AdminClientForm } from '../../../_components/Admin/AdminClientForm';
import { Loader } from '../../../_components/Loader';
import { adminClientActions } from '../../../AdminRedux/_actions';
import { Table } from '../../../_components/Admin/DataTable/Table';

function AdminLawFirm({ match, history }) {
  const [show, setShow] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({ searchTxt: '' });
  const [usernametxt, setusernametxt] = useState(false);
  const [userData, setUserData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTxt } = inputs;

  const { LawFirm, getFirm, deleteFirm, sortBy } = useSelector((state) => state.clientAdmin && state.clientAdmin);
  const { metadata } = useSelector((state) => state.clientAdmin && state.clientAdmin.LawFirm);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setIsloading(true);
    dispatch(adminClientActions.getAdminLawFirm(1, ''));
  }, [dispatch]);

  useEffect(() => {
    if (match.params.firmid) {
      setIsloading(true);
      dispatch(adminClientActions.getAdminLawFirmView(match.params.firmid));
    }
  }, [match]);

  useEffect(() => {
    if (LawFirm && LawFirm.data && LawFirm.data.length > 0) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
      setComments(LawFirm.data);
    }

    if (getFirm) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
    }
    if (deleteFirm && deleteFirm.message) {
      onSuccess();
    }
  }, [LawFirm, getFirm, deleteFirm]);

  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'Name', field: 'name', sortable: true },
    { name: 'Description', field: 'description', sortable: true },
    { name: 'Slug', field: 'slug', sortable: true },
    { name: 'Count', field: 'count', sortable: false },
    { name: 'Actions', field: 'actions', sortable: false },
  ];

  const handleClose = () => {
    setShow(false);
  };

  const deleteFirmID = (deldata) => {
    setUserData(deldata);
    setShow(true);
  };

  const deleteFirmFunc = (id) => {
    setIsloading(true);
    dispatch(adminClientActions.getAdminLawFirmDelete(id));
  };
  const onSuccess = () => {
    history.push(`/admin/lawfirm`);
    window.location.reload();
  };

  const onPageChanged = (page) => {
    setIsloading(true);
    setCurrentPage(page.currentPage);
    dispatch(adminClientActions.getAdminLawFirm(page.currentPage, searchTxt && searchTxt ? searchTxt : '', sortBy));
  };

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'searchTxt' && !value) {
      dispatch(adminClientActions.getAdminLawFirm(1));
    } else {
      setusernametxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (!searchTxt) {
      setusernametxt(true);
    } else {
      setusernametxt(false);
      setIsloading(true);
      setComments([]);
      dispatch(adminClientActions.getAdminLawFirm(1, searchTxt));
    }
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <AdminDashboard title="Law Firm">
      <Loader isLoading={isloading} />
      <Container fluid className="pb-8 pt-3 pt-md-8">
        {match.params.type === 'edit' || match.params.type === 'view' ? (
          <Row>
            <nav className="mb-3 navbar navbar-expand-md">
              <ul className="mr-auto navbar-nav">
                <li className="nav-item">
                  <h6 className="h3 font-weight-bold text-dark m-0">Law Firm</h6>
                </li>
              </ul>
            </nav>
            <Col sm={10}>
              <AdminClientForm type={match.params.type === 'view' ? 'View Firm' : 'Edit firm'} onSuccess={onSuccess} action={match.params.type} getFirm={getFirm} firmid={match.params.firmid} />
            </Col>
          </Row>
        ) : (
          <Row>
            <nav className="mb-3 navbar navbar-expand-md">
              <ul className="mr-auto navbar-nav">
                <li className="nav-item">
                  <h6 className="h3 font-weight-bold text-dark m-0">Law Firm</h6>
                </li>
              </ul>
            </nav>
            <Col sm={6}>
              <AdminClientForm type="Add new firm" />
            </Col>
            <Col sm={6} className="h-75">
              <div className="row m-2">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                    <InputGroup className="mb-3">
                      <FormControl
                        className={usernametxt ? 'border border-invalid' : ''}
                        name="searchTxt"
                        onKeyUp={handleKeypress}
                        onChange={handleChange}
                        type="text"
                        placeholder="Search"
                        aria-label="searchTxt"
                        aria-describedby="basic-addon2"
                        value={searchTxt}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                          <i className="fas fa-search" aria-hidden="true" />
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
                  </Form.Group>
                </div>
              </div>
              <Table
                currentPage={currentPage}
                ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                onPageChanged={onPageChanged}
                headers={headers}
                comments={comments}
                totalResults={metadata && metadata.length > 0 && metadata[0].total}
                type="lawfirm"
                deleteFirmID={deleteFirmID}
              />
            </Col>
          </Row>
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure ? You want to delete Firm:</Modal.Title>
          </Modal.Header>
          <Modal.Body>You want to delete the firm - {userData && userData.name} </Modal.Body>
          <Modal.Footer>
            <Button className="add-btn" variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              className="add-btn"
              variant="secondary"
              onClick={() => {
                deleteFirmFunc(userData && userData.id);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </AdminDashboard>
  );
}

export { AdminLawFirm };
