import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  width: fit-content;
  color: black;
  font-size: 24px;
  align-items: center;
  gap: 8px;
`;
