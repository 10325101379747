import { TangiSvgIcon } from '../TangiSvgIcon';
import { Spinner } from 'react-bootstrap';
import { StyledButton, ButtonChildrenContainer } from './style';

export const TANGI_BUTTON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const TangiButton = ({ svgIcon = '', text = '', loading = false, disabled = false, icon = '', onClick, smallbtn = false, endIcon = false, isActive = false, ...props }) => {
  const handleClick = (event) => {
    event.currentTarget.blur();
    if (onClick) {
      onClick();
    }
  };

  return (
    <StyledButton disabled={loading || disabled} onClick={handleClick} $isActive={isActive} $smallbtn={smallbtn} {...props}>
      {/* old icon */}
      {icon && <img src={icon} alt="" />}
      {loading ? <Spinner as="div" size="sm" animation="border" variant="secondary" /> : !svgIcon && text}

      {/* new icon  */}
      {!loading && svgIcon && (
        <ButtonChildrenContainer>
          {endIcon ? (
            <>
              {text && <div>{text}</div>}
              <TangiSvgIcon component={svgIcon} />
            </>
          ) : (
            <>
              <TangiSvgIcon component={svgIcon} />
              {text && <div className="tangi-button-textContainer">{text}</div>}
            </>
          )}
        </ButtonChildrenContainer>
      )}
    </StyledButton>
  );
};
