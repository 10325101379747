import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TANGI_BUTTON_SIZES, TangiDropdownButton, TangiTypography } from '_components/TangiLibrary';
import AssetCreatedChip from '_components/AssetCreatedChip/AssetCreatedChip';
import InventionTradeSecretBullet from './InventionTradeSecretBullet';
import { PotentialTradeSecret, PotentialTradeSecretBullet } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { RootState } from '_helpers';
import { CurrentTradeSecret, DropdownOption } from '_hocs/XRayAssetCreation/types';
import { BUTTON_VARIANTS, SPACING } from 'utils/theme';
import { StyledCard } from 'utils/globalStyles';

export const SubjectCard = styled(StyledCard)`
  border-radius: ${SPACING[2]};
  padding: ${SPACING[3]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const CardTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${SPACING[4]};
  align-items: center;
`;

interface Props {
  tradeSecret: PotentialTradeSecret;
  tradeSecretIndex: number;
  renderOptions?: (currTradesecret: CurrentTradeSecret, index: number) => DropdownOption[];
}

const InventionTradeSecretCard = ({ tradeSecret, tradeSecretIndex, renderOptions }: Props) => {
  const metaDataLoading = useSelector((state: RootState) => state.assetMetaData.loading);

  const renderTurnIntoAssetDropDown = (tradeSecret: PotentialTradeSecret, index: number) => {
    return (
      <div style={{ position: 'relative' }}>
        <TangiDropdownButton
          variant={BUTTON_VARIANTS.TERTIARY_GREY}
          size={TANGI_BUTTON_SIZES.SMALL}
          arrow={false}
          svgIcon="threeDotsVertical"
          options={
            renderOptions
              ? renderOptions(
                  {
                    text: tradeSecret.bullets.map((bullet) => bullet.text).join('\n'),
                    title: tradeSecret.subject,
                  },
                  index,
                )
              : []
          }
          disabled={metaDataLoading}
          smallbtn={true}
          data-testid="turn-into-asset-button"
          dropdownStyles={{ right: '0px', width: '220px' }}
        />
      </div>
    );
  };

  return (
    <SubjectCard>
      <CardTitle>
        <TangiTypography type="heading-md" weight="semibold">
          {tradeSecret.subject}
        </TangiTypography>
        {tradeSecret.isAssetCreated ? <AssetCreatedChip /> : renderTurnIntoAssetDropDown(tradeSecret, tradeSecretIndex)}
      </CardTitle>
      {!!tradeSecret?.bullets?.length && tradeSecret.bullets.map((bullet: PotentialTradeSecretBullet) => <InventionTradeSecretBullet key={bullet.id} bullet={bullet} />)}
    </SubjectCard>
  );
};

export default InventionTradeSecretCard;
