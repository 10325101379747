import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TangiButton, TangiTypography } from '_components/TangiLibrary';
import ThirdPartyTag from '_components/ThirdPartyTag/ThirdPartyTag';
import { ContentViewContainer, DuoContainer } from 'utils/globalStyles';
import { lockWithBackground as Lock, papers as Papers } from 'assets/img';
import { Asset } from 'utils/types/assets/assets';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const AcknowledgeBtnContainer = styled.div`
  margin-top: ${SPACING[3]};
  width: 160px;
`;

export const LockedAssetContainer = styled.div`
  border: 1px solid ${NEUTRAL_SHADES[300]};
  background: ${NEUTRAL_SHADES[100]};
  border-radius: ${SPACING[2]};
  height: calc(100vh - 376px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 80px ${SPACING[4]};
`;

export const LockedAssetContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .tangi-typography {
    margin-top: -${SPACING[3]};
  }
`;

export const StyledPapersIcon = styled(Papers)`
  position: relative;
`;

export const StyledLockIcon = styled(Lock)`
  position: relative;
  width: ${SPACING[6]};
  height: ${SPACING[6]};
  top: -${SPACING[4]};
  left: ${SPACING[4]};
`;

interface Props {
  handleUnlockAsset: () => void;
  asset: Asset;
}

const AssetLocked = ({ asset, handleUnlockAsset }: Props) => {
  const { t } = useTranslation();

  return (
    <ContentViewContainer>
      {asset && (
        <DuoContainer>
          <TangiTypography type="heading-xl">{asset?.name}</TangiTypography>
          {asset?.isThirdParty && <ThirdPartyTag asset={asset} placement="bottom" />}
        </DuoContainer>
      )}
      <LockedAssetContainer>
        <LockedAssetContent>
          <StyledPapersIcon />
          <StyledLockIcon />
          <TangiTypography type="subheading">
            {asset?.needsToBeAcknowledgedByMe
              ? t('ASSET_PAGE.CONTENT.ASSET_REQUIRES_ACKNOWLEDGEMENT')
              : asset?.isThirdParty && !asset?.hasAccessToThirdPartyAsset
              ? t('ASSET_PAGE.CONTENT.CONTACT_PARTNER_TO_GAIN_ACCESS')
              : ''}
          </TangiTypography>
          {asset?.needsToBeAcknowledgedByMe && (
            <AcknowledgeBtnContainer>
              <TangiButton data-testid="acknowledge-asset-button" text={t('ASSET_PAGE.BUTTONS_TEXT.ACKNOWLEDGE')} svgIcon="lock" onClick={handleUnlockAsset} />
            </AcknowledgeBtnContainer>
          )}
        </LockedAssetContent>
      </LockedAssetContainer>
    </ContentViewContainer>
  );
};

export default AssetLocked;
