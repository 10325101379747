import { SharePointWizardStepKey } from '_components/SharePointWizard/types';
import { LoaderTranslationKeys, LoaderTranslationKeysMap } from './types';

export const getLoaderTranslationKeys = (isEditMode: boolean, currentStepId: SharePointWizardStepKey): LoaderTranslationKeys | null => {
  const loaderTranslationKeys: LoaderTranslationKeysMap = {
    [SharePointWizardStepKey.SEND_ACKNOWLEDGEMENT]: {
      header: isEditMode ? 'SHARE_POINT_WIZARD.LOADER.INTEGRATING.HEADER_EDIT' : 'SHARE_POINT_WIZARD.LOADER.INTEGRATING.HEADER',
      description1: 'SHARE_POINT_WIZARD.LOADER.INTEGRATING.DESCRIPTION1',
      description2: 'SHARE_POINT_WIZARD.LOADER.INTEGRATING.DESCRIPTION2',
    },
    [SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS]: {
      header: 'SHARE_POINT_WIZARD.LOADER.MEMBERS.HEADER',
      description1: '',
      description2: '',
    },
  };
  return loaderTranslationKeys[currentStepId] ?? null;
};
