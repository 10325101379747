import { useMemo } from 'react';
import { ComputedNode, ComputedNodeWithoutStyles, ResponsiveTreeMap } from '@nivo/treemap';
import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, tradeSecretsTreeMapPalette } from 'utils/theme';
import { ChartContainer, ColorIcon, SubjectContainer, ToolTipRow, TooltipContainer } from '../styles';
import { TradeSecretsSubjectData, TreeMapData } from './types';
import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';
import { HexColor, HexColorArray, NodeType } from '../types';
import { TreeMapChartContainer } from './styles';
import { generateTreeMapColorScale, truncateStringToFitSquare } from './utils';
import { PredictedPortfolioGraphsData } from 'utils/types/predictedPortfolio/predictedPortfolio';
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { getTextColor } from '../HeatMapChart/utils';

interface Props {
  handleClick: (node: NodeType) => void;
}

const TreeMapChart = ({ handleClick }: Props) => {
  const { t } = useTranslation();
  const graphsData: PredictedPortfolioGraphsData = useSelector((state: RootState) => state.predictedPortfolio.graphsData);
  const data: TradeSecretsSubjectData[] = graphsData?.graphs?.treemap || [];
  const chartData: TreeMapData = { subject: '', children: data };

  const palette: HexColorArray = useMemo(() => generateTreeMapColorScale(data, tradeSecretsTreeMapPalette), [data]);

  const textColor = (
    p: ComputedNodeWithoutStyles<TreeMapData> & {
      color: string;
    },
  ) => {
    return getTextColor(p.color as HexColor);
  };

  const renderLabel = (p: Omit<ComputedNodeWithoutStyles<TreeMapData>, 'label' | 'parentLabel'>) => {
    return truncateStringToFitSquare(p.id, p.width);
  };

  const renderTooltip = ({ node }: { node: ComputedNode<TradeSecretsSubjectData> }) => {
    return (
      <TooltipContainer data-testid="tree-map-tooltip">
        <ToolTipRow>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.CHARTS_SUBJECT)}:`}</TangiTypography>
          <SubjectContainer>
            <ColorIcon color={node.color} />
            <TangiTypography color={NEUTRAL_SHADES.BLACK}>{node.id}</TangiTypography>
          </SubjectContainer>
        </ToolTipRow>
        <ToolTipRow>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.CHARTS_POTENTIAL_TRADE_SECRETS)}:`}</TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES.BLACK}>{node.value}</TangiTypography>
        </ToolTipRow>
        <ToolTipRow>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{`${t(TRANSLATION_KEY.CHARTS_PATENTS)}:`}</TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES.BLACK}>{node.data.patents_count}</TangiTypography>
        </ToolTipRow>
      </TooltipContainer>
    );
  };

  return (
    <ChartContainer>
      <TreeMapChartContainer data-testid="tree-map-chart">
        <ResponsiveTreeMap
          data={chartData}
          identity="subject"
          value="ts_count"
          leavesOnly={true}
          innerPadding={2}
          tooltip={({ node }) => renderTooltip({ node: node as ComputedNode<TradeSecretsSubjectData> })}
          enableLabel={true}
          label={(p: Omit<ComputedNodeWithoutStyles<TreeMapData>, 'label' | 'parentLabel'>) => {
            return renderLabel(p);
          }}
          labelTextColor={(
            p: ComputedNodeWithoutStyles<TreeMapData> & {
              color: string;
            },
          ) => {
            return textColor(p);
          }}
          orientLabel={false}
          colors={palette}
          nodeOpacity={1}
          borderWidth={0}
          onClick={(node) => handleClick(node as ComputedNode<TradeSecretsSubjectData>)}
        />
      </TreeMapChartContainer>
    </ChartContainer>
  );
};

export default TreeMapChart;
