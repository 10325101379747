import { Modal, ProgressBar, ProgressBarProps } from 'react-bootstrap';
import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { FlexContainer } from 'utils/globalStyles';
import { SECONDARY_SHADES, SPACING } from 'utils/theme';

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[3]} ${SPACING[3]} ${SPACING[4]} ${SPACING[3]};
  width: 420px;
  justify-content: center;
  gap: ${SPACING[3.5]};
`;

export const ChipContentContainer = styled(FlexContainer)`
  gap: ${SPACING[1]};
  display: flex;
  align-items: flex-start;
  text-align: center;
  span {
    margin-top: 2px;
  }
`;

export const Container = styled(FlexContainer)`
  gap: ${SPACING[2.5]};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const IconAndText = styled(FlexContainer)`
  .tangi-icon-container {
    margin-top: ${SPACING[1.5]};
  }
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: ${SPACING[0]} ${SPACING[3.5]} ${SPACING[3.5]} ${SPACING[3.5]};
  align-items: center;
`;

export const PDFSidebar = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f3fb;
  width: 250px;
  height: 100%;
  height: 100vh;
  padding: ${SPACING[3.5]} 60px 60px 60px;
  box-sizing: border-box;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledTangiTypography = styled(TangiTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
export const StyledValueTypo = styled(TangiTypography)`
  font-size: 27px;
`;

export const PDFPageContainer = styled.div`
  display: flex;
  height: 1122px; // Height for an A4 page; adjust as needed for A2
`;

export const PDFContent = styled.div`
  width: 100%;
  padding: ${SPACING[3.5]} 60px 60px 60px;
  margin-left: 250px;
`;

export type MyProgressBarProps = ProgressBarProps & {
  hideBackground?: boolean;
  customBackgroundColor?: string;
};

export const StyledProgressBar = styled(ProgressBar)<MyProgressBarProps>`
  width: 100%;
  height: ${SPACING[3]} !important;
  background-color: ${SECONDARY_SHADES[500]};
  box-shadow: none !important;
  margin-bottom: 0px;

  .progress-bar {
    border-radius: ${SPACING[1]} ${SPACING[0]} ${SPACING[1]};
    background: ${SECONDARY_SHADES[800]};
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const VulnerabilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const customBulletTypographyStyles = `
  padding-inline: 2px;
`;

export const FooterDiv = styled.div`
  position: absolute;
  bottom: ${SPACING[3.5]};
  right: 40px;
  gap: ${SPACING[2.5]};
  display: flex;
`;
