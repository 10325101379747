import React, { useEffect, useState } from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Select from 'react-select';
import _ from 'lodash';
import { Loader } from '../../Loader';
import { adminCourseActions } from '../../../AdminRedux/_actions';
import { NestedChoices } from '../../NestedChoices';
import { EditAsyncSelect, optionTypes } from '../../EditAsyncSelect';

// import { EditAsyncSelect, optionTypes } from '../../EditAsyncSelect';

import './AdminCoursesForm.scss';
import 'react-datepicker/dist/react-datepicker.css';

function AdminCoursesForm({ type }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isloading, setIsloading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [defaultValues, setDefaultValues] = useState({ description: '<p> Test </p>' });
  const { user } = useSelector((state) => state.authentication);
  const { getCategory, createCourse, updateCourse, statusUpdateCourse, errors, getCourseByID, statusCourse } = useSelector((state) => state.adminCourseReducer);

  const difficult = [
    { value: '1', label: 'Beginner' },
    { value: '2', label: 'Intermediate' },
    { value: '3', label: 'Advanced' },
  ];

  useEffect(() => {
    dispatch(adminCourseActions.getAdminCategory(1000));
  }, []);

  useEffect(() => {
    if (type === 'Edit course' && id) {
      dispatch(adminCourseActions.getAdminCourseById(id));
    }
  }, []);

  useEffect(() => {
    if (statusCourse === 'success' || statusUpdateCourse === 'success') {
      setIsloading(true);
      setTimeout(() => {
        history.push('/admin/course');
      }, 3000);
    }
  }, [statusCourse, statusUpdateCourse]);

  useEffect(() => {
    if (!_.isEmpty(getCourseByID)) {
      const { author, client, category, date, difficulty, featuredVideoUrl, title, questions, description } = getCourseByID;

      // setDefaultValues({ description: getCourseByID?.description ?? '', questions: getCourseByID.questions ?? [] });

      reset({
        author,
        client,
        date,
        description,
        difficulty: {
          value: difficulty,
          label: difficult?.find((item) => item.label === difficulty)?.label,
        },
        category: {
          value: category,
          label: getCategory?.results?.find((item) => item.id === category)?.name,
        },
        featuredVideoUrl,
        title,
        questions,
      });
    }
  }, [getCourseByID]);

  useEffect(() => {
    if (createCourse && createCourse.id) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, 7000);
    }
  }, [createCourse]);

  useEffect(() => {
    if (updateCourse && updateCourse.id) {
      setTimeout(() => {
        setIsloading(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, 7000);
    }
  }, [updateCourse]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    // reset,
    // formState: { errors },
  } = useForm({
    mode: 'all',
  });

  // function getSelectOptions(items, accessor = 'name') {
  //   const temp = [];
  //   items.forEach((item) => {
  //     // eslint-disable-next-line no-unused-expressions
  //     item && temp.push({ value: item.id, label: item[accessor], data: item });
  //   });

  //   return temp;
  // }

  function onSubmit(data) {
    setIsloading(true);
    data = { ...data, difficulty: data?.difficulty.label, author: user._id };

    dispatch(adminCourseActions.adminCreateCourse(data));
  }
  function onSubmitUpdate(data) {
    setIsloading(true);

    data = { ...data, difficulty: data?.difficulty.label, author: user._id, category: data?.category.value };
    dispatch(adminCourseActions.updateAdminCourse(id, data));
  }

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'questions',
  });

  const handleAppendQuestion = () => {
    append({ text: '' });
  };

  return (
    <>
      <Loader isLoading={isloading} />
      <Row className="m-0">
        <div className="col-md-12 col-lg-12 mx-auto mt-4">
          <div className="border-0 rounded">
            <h3 className="font-weight-normal">{type}</h3>
            {type === 'Add new item to Courses' && (
              <div className="mb-2 card">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="px-3 mt-3 border-0 row">
                    <Form.Group className="mb-3" controlId="formBasicFullName">
                      <Form.Label className="text-dark fw-600">Title</Form.Label>
                      <Form.Control data-testid="courseTitle" type="text" placeholder="Add Title" {...register('title', { required: { value: true, message: 'Title is required' } })} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicFullName">
                      <Form.Label className="text-dark fw-600">Description</Form.Label>
                      <Form.Control
                        data-testid="description"
                        as="textarea"
                        rows={6}
                        placeholder="description"
                        {...register('description', { required: { value: true, message: 'description is required' } })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label className="text-dark fs-6">Featured Video</Form.Label>
                      <Form.Control data-testid="featuredVideoUrl" type="text" placeholder="Enter URL" {...register('featuredVideoUrl', { required: { value: true, message: 'url is required' } })} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formClient">
                      <Form.Label className="text-dark fw-600 txt-size">Client</Form.Label>
                      <Controller
                        data-testid="client"
                        name="client"
                        render={({ field: { ref, ...rest } }) => <EditAsyncSelect data-testid="client-selector" inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.CLIENTS} {...rest} />}
                        control={control}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label className="text-dark fs-6">Difficulty</Form.Label>

                      <Controller
                        control={control}
                        name="difficulty"
                        render={({ field: { ref, ...rest } }) => <Select options={difficult} inputRef={ref} classNamePrefix="addl-class" {...rest} />}
                        rules={{
                          required: { value: true, message: 'Difficulty is required' },
                          // validate: (type) => isUniquetType(type, index),
                        }}
                      />
                    </Form.Group>

                    <h2 className="font-weight-normal">Quiz</h2>
                    {fields.map((field, index) => (
                      <div key={field.id}>
                        <div key={field.id} className="mb-2">
                          <Form.Group className="mb-3" controlId="formBasicFullName">
                            <Form.Label className="text-dark fw-600">Question {index + 1}</Form.Label>
                            <Form.Control type="text" data-testid="question" placeholder="Question" {...register(`questions[${index}].text`)} />
                          </Form.Group>
                          <h3 className="font-weight-normal mt-3">Choices</h3>
                          <div>
                            <NestedChoices nestIndex={index} {...{ control, register }} />
                          </div>
                          <Form.Group className="mb-3" controlId="formBasicFullName">
                            <Form.Label className="text-dark fw-600">Answer - Choice</Form.Label>
                            <Form.Control data-testid="answer" type="text" placeholder="Enter Choice No" {...register(`questions[${index}].answer`)} />
                          </Form.Group>
                        </div>
                        <button type="button" onClick={() => remove(index)} className="btn btn-danger text-white pull-right">
                          REMOVE QUESTION
                        </button>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between">
                      <button type="button" onClick={handleAppendQuestion} className="btn btn-primary text-white">
                        ADD NEW QUESTION
                      </button>
                    </div>
                  </div>

                  <div className="d-flex pull-right p-3">
                    <Button type="submit" className="btn btn-primary text-white">
                      SUBMIT
                    </Button>
                  </div>
                </Form>
                <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${statusCourse === 'success' ? 'show' : ''} text-center w-100`}>
                  Created Successfully
                </div>
              </div>
            )}
            {type === 'Edit course' && (
              <div className="mb-2 card">
                <Form onSubmit={handleSubmit(onSubmitUpdate)}>
                  <div className="px-3 mt-3 border-0 row">
                    <Form.Group className="mb-3" controlId="formBasicFullName">
                      <Form.Label className="text-dark fw-600">Add Title</Form.Label>
                      <Form.Control data-testid="editTitle" type="text" placeholder="Add Title" {...register('title', { required: { value: true, message: 'Title is required' } })} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicFullName">
                      <Form.Label className="text-dark fw-600">Description</Form.Label>
                      <Form.Control
                        // defaultValue={getCourseByID?.description ?? ''}
                        as="textarea"
                        rows={6}
                        placeholder="description"
                        {...register('description', { required: { value: true, message: 'description is required' } })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label className="text-dark fs-6">Difficulty</Form.Label>
                      <Controller
                        control={control}
                        // defaultValue={}
                        data-testid="difficulty"
                        name="difficulty"
                        render={({ field: { ref, ...rest } }) => <Select options={difficult} inputRef={ref} classNamePrefix="addl-class" {...rest} />}
                        rules={{
                          required: { value: true, message: 'Difficulty is required' },
                          // validate: (type) => isUniquetType(type, index),
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formClient">
                      <Form.Label className="text-dark fw-600 txt-size">Client</Form.Label>
                      <Controller
                        data-testid="client"
                        name="client"
                        render={({ field: { ref, ...rest } }) => <EditAsyncSelect data-testid="client-selector" inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.CLIENTS} {...rest} />}
                        control={control}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPhone">
                      <Form.Label className="text-dark fs-6">Featured Video</Form.Label>
                      <Form.Control type="text" placeholder="Enter URL" {...register('featuredVideoUrl', { required: { value: true, message: 'url is required' } })} />
                    </Form.Group>

                    <h2 className="font-weight-normal">Quiz</h2>
                    {fields?.map((field, index) => (
                      <div key={index}>
                        <div key={index} className="mb-2">
                          <Form.Group className="mb-3" controlId="formBasicFullName">
                            <Form.Label className="text-dark fw-600">Question {index + 1}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Question"
                              {...register(`questions.${index}.text`)}
                              // defaultValue={getCourseByID.questions[index].text}
                            />
                          </Form.Group>
                          <h3 className="font-weight-normal mt-5">Choices</h3>
                          <div>
                            <NestedChoices nestIndex={index} {...{ control, register }} />
                          </div>
                          <Form.Group className="mb-3" controlId="formBasicFullName">
                            <Form.Label className="text-dark fw-600">Answer - Choice</Form.Label>
                            <Form.Control type="text" placeholder="Enter Choice No" {...register(`questions[${index}].answer`)} />
                          </Form.Group>
                        </div>
                        <button type="button" onClick={() => remove(index)} className="btn btn-danger text-white pull-right">
                          REMOVE QUESTION
                        </button>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between">
                      <button type="button" onClick={handleAppendQuestion} className="btn btn-primary text-white">
                        ADD NEW QUESTION
                      </button>
                    </div>
                  </div>

                  <div className="d-flex pull-right p-3">
                    <Button type="submit" className="btn btn-primary text-white">
                      UPDATE
                    </Button>
                  </div>
                </Form>
                <div className={`alert alert-primary fade ${statusUpdateCourse === 'failed' ? 'show' : ''} text-center w-100`}>{errors && errors.message}</div>
                <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${statusUpdateCourse === 'success' ? 'show' : ''} text-center w-100`}>
                  Updated Successfully
                </div>
              </div>
            )}
          </div>
        </div>
      </Row>
    </>
  );
}

export { AdminCoursesForm };
