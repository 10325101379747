/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types;
import styled from 'styled-components';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { formatDate } from 'utils/dateUtils';
import { NEUTRAL_SHADES, SPACING, SUCCESS_SHADES } from 'utils/theme';

interface Props {
  acknowledgments: any;
}

const HoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Padding = styled.div`
  padding: ${SPACING[2]};
`;

const AcknowledgmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

const AcknowledgmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAssetTitle = styled(TangiTypography)`
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledAssetDate = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  color: ${SUCCESS_SHADES[500]};
`;

const MORE_ACK_LIMIT = 3;

const AcknowledgmentPeopleTableHover = ({ acknowledgments }: Props) => {
  const { t } = useTranslation();

  const totalAcknowledgments = useMemo(() => acknowledgments.length, [acknowledgments]);

  const hasMoreAcknowledgments = useMemo(() => totalAcknowledgments >= MORE_ACK_LIMIT, [totalAcknowledgments]);

  const acknowledgmentsToDisplay = useMemo(() => {
    return acknowledgments.slice(0, MORE_ACK_LIMIT);
  }, [acknowledgments]);

  return (
    <HoverWrapper>
      <Padding>
        <TangiTypography color={NEUTRAL_SHADES[800]}>
          {totalAcknowledgments} {t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.ASSETS_RECEIVED')}
        </TangiTypography>
      </Padding>
      {!!acknowledgments.length && (
        <Padding style={{ borderTop: `1px solid ${NEUTRAL_SHADES[200]}` }}>
          <AcknowledgmentsWrapper>
            {acknowledgmentsToDisplay.map((acknowledgment: any, index: number) => {
              return (
                <AcknowledgmentItem key={index}>
                  <StyledAssetTitle weight="semibold" color={NEUTRAL_SHADES[1000]}>
                    {acknowledgment.name}
                  </StyledAssetTitle>
                  <StyledAssetDate>
                    {acknowledgment.acknowledged ? (
                      <Fragment>
                        <TangiTypography color="inherit">{formatDate(acknowledgment.sharedOn)}</TangiTypography>
                        <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
                      </Fragment>
                    ) : (
                      <TangiTypography color={NEUTRAL_SHADES[800]} type="sub-body">
                        {t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.NOT_SIGNED')}
                      </TangiTypography>
                    )}
                  </StyledAssetDate>
                </AcknowledgmentItem>
              );
            })}
            {!!hasMoreAcknowledgments && (
              <TangiTypography weight="semibold" color={NEUTRAL_SHADES[1000]}>
                {t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.AND_MORE', { count: totalAcknowledgments - MORE_ACK_LIMIT })}
              </TangiTypography>
            )}
          </AcknowledgmentsWrapper>
        </Padding>
      )}
    </HoverWrapper>
  );
};

export default AcknowledgmentPeopleTableHover;
