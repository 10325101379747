import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { AgreementContainer } from './style';

const AgreementSkeleton = () => {
  return (
    <AgreementContainer>
      <Skeleton height={175} count={1} />
    </AgreementContainer>
  );
};
export default AgreementSkeleton;
