import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { IconTypes, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { DROPDOWN_TRANSLATION_KEYS } from '../types';
import { SPACING } from 'utils/theme';

const DropdownItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2.5]};
`;

interface Props {
  dropdownText: DROPDOWN_TRANSLATION_KEYS;
  icon: IconTypes;
  dataTestId?: string;
}

const CreateAssetDropdownOption = ({ dropdownText, icon, dataTestId = '' }: Props) => {
  const { t } = useTranslation();
  return (
    <DropdownItemContainer data-testid={dataTestId}>
      <TangiSvgIcon component={icon} />
      <TangiTypography>{t(dropdownText)}</TangiTypography>
    </DropdownItemContainer>
  );
};

export default CreateAssetDropdownOption;
