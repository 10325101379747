import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { TangiTypography } from '../TangiTypography';
import { Option } from './types';
import { NEUTRAL_SHADES } from 'utils/theme';
import { RadioInput, RadioLabel, StyledFormGroup } from './style';

type Props<T extends FieldValues> = {
  name: Path<T>;
  options?: Option[];
  label: string;
};

export function TangiRadioGroup<T extends FieldValues>({ name, options, label }: Props<T>) {
  const { control } = useFormContext<T>();

  return (
    <StyledFormGroup>
      <TangiTypography weight="bold">{label}</TangiTypography>
      {!!options?.length && (
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <>
              {options.map((option) => {
                const isDisabled = option?.disabled === true;
                return (
                  <RadioLabel key={option.id}>
                    <RadioInput type="radio" value={option.id} checked={field.value === option.id} onChange={() => field.onChange(option.id)} disabled={isDisabled} />
                    <TangiTypography type="subheading" color={isDisabled ? NEUTRAL_SHADES[400] : NEUTRAL_SHADES.BLACK}>
                      {option.label}
                    </TangiTypography>
                  </RadioLabel>
                );
              })}
            </>
          )}
        />
      )}
    </StyledFormGroup>
  );
}
export default TangiRadioGroup;
