import React, { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { adminClientService } from '../../AdminRedux/_services/adminClientService';
import { assetMetadataService } from '../../_services/assetMetadataService';
import { userService } from '../../_services/userService';
import { agreementService } from '../../_services/agreementService';
import { adminUserService } from '../../AdminRedux/_services/adminUserService';

const optionTypes = {
  ASSET_TYPES: 'assetTypes',
  ASSET_CONTRIBUTORS: 'assetContributors',
  AGREEMENT_TYPES: 'agreementTypes',
  BUSINESS_VALUES: 'businessValues',
  CLIENTS: 'clients',
  TAGS: 'tags',
  LAWFIRMS: 'lawfirms',
  USERS: 'users',
  ROLES: 'roles',
  LAWYERS: 'lawyers',
  CLIENT_LAWYERS: 'CLIENT_LAWYERS',
};

function AsyncSelect(props) {
  const {
    name,
    onBlur,
    addNone,
    selectStyles,
    isMulti,
    isEdit,
    inputRef,
    reloadOptions,
    reloadVar,
    clientId = null,
    lawFirmId = null,
    type,
    sortBy = 'name:asc',
    defaults,
    isDisabled = false,
  } = props;

  const [value, onChange] = useState([]);
  const [reload, setReload] = useState('');

  useEffect(() => {
    if (reloadOptions) {
      onChange([]);
      setReload(uuidv4());
    }
  }, [reloadVar]);

  function getSelectOptions(items, accessor = 'name') {
    const temp = [];
    items.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item && temp.push({ value: item.id, label: item[accessor], data: item });
    });

    return temp;
  }

  function handleChange(e) {
    const { onChange } = props;
    if (isMulti) {
      const values = [];
      e.forEach((s) => {
        values.push(s.value);
      });

      onChange(values);
    } else if (!_.isEmpty(e)) {
      onChange(_.isArray(e) ? e[0].value : e.value);
    }
  }

  useEffect(() => {
    if (isEdit) {
      onChange(getSelectOptions(_.isArray(defaults) ? defaults : [defaults]));
    }
  }, [isEdit, defaults]);

  useEffect(() => {
    handleChange(value);
  }, [value]);

  async function getAssetContributors(client) {
    const promises = [];

    promises.push(adminClientService.getAdminClient(client), adminUserService.getAdminUsersByClient(client));

    const resultArray = await Promise.all(promises);

    const lawyers = await resultArray[0]?.lawyers;
    const clientUsers = await resultArray[1]?.data;

    const data = [...lawyers, ...clientUsers];
    return { data };
  }

  async function getOptions(optionType, page, limit, previous) {
    let response = null;
    let results = [];
    let hasMore = true;
    let accessor = 'name';

    switch (optionType) {
      case optionTypes.AGREEMENT_TYPES:
        response = await agreementService.getAgreementTypes({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.ASSET_CONTRIBUTORS:
        response = await getAssetContributors(clientId);
        return { results: getSelectOptions(response.data, 'displayName'), hasMore: false };
      case optionTypes.ASSET_TYPES:
        response = await assetMetadataService.getAssetTypes({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.BUSINESS_VALUES:
        response = await assetMetadataService.getBusinessValues({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.CLIENTS:
        response = await adminClientService.getAdminClients(page, null, sortBy, limit);
        break;
      case optionTypes.CLIENT_LAWYERS:
        response = await adminClientService.getAdminClient(clientId);
        return { results: getSelectOptions(response.lawyers, 'displayName'), hasMore: false };
      case optionTypes.LAWFIRMS:
        response = await adminClientService.getAdminLawFirmService(page, null, limit, sortBy);
        break;
      case optionTypes.LAWYERS:
        response = await adminClientService.getAdminLawFirmLawyers({ lawFirm: lawFirmId, page, limit: 1000, sortBy });
        accessor = 'displayName';
        break;
      case optionTypes.ROLES:
        response = await userService.getRoles();
        break;
      case optionTypes.TAGS:
        response = await assetMetadataService.getTags({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.USERS:
        response = await adminUserService.getAdminUsers({ page, limit, sortBy });
        accessor = 'displayName';
        break;
      default:
        break;
    }

    results = response.data;
    hasMore = previous !== response?.metadata[0]?.total;

    if (addNone && page === 1) {
      const none = [{ value: 'null', label: 'None' }];
      results = [...none, ...getSelectOptions(results, accessor)];
      return { results, hasMore };
    }
    return { results: getSelectOptions(results, accessor), hasMore };
  }

  const defaultAdditional = {
    page: 1,
    limit: 50,
    previous: 0,
  };

  const loadOpions = async (search, prevOptions, { page, limit, previous }) => {
    const data = await getOptions(type, page, limit, previous);
    const { results, hasMore } = data;

    return {
      options: results,
      hasMore,
      additional: {
        page: page + 1,
        limit,
        previous: previous + results?.length,
      },
    };
  };
  return (
    <AsyncPaginate
      isDisabled={isDisabled}
      name={name}
      selectRef={inputRef}
      loadOptionsOnMenuOpen
      onBlur={onBlur}
      className={selectStyles}
      additional={defaultAdditional}
      value={value}
      isSearchable={false}
      loadOptions={loadOpions}
      isMulti={isMulti}
      closeMenuOnSelect
      onChange={onChange}
      key={reload}
    />
  );
}

export { AsyncSelect, optionTypes };
