import styled, { css, CSSObject } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import { TangiSvgIcon } from '_components/TangiLibrary/TangiSvgIcon/TangiSvgIcon';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from '../../utils/theme';
import { SPACING } from 'utils/theme';

export const SideBarContainer = styled.div`
  background-color: ${NEUTRAL_SHADES.WHITE};
  padding: ${SPACING[3]} ${SPACING[2]} ${SPACING[0]};
  border-right: 1px solid ${NEUTRAL_SHADES[300]};
  position: fixed;
  top: 68px;
  bottom: 0;
  height: calc(100vh - 116px);
  z-index: 100;
  width: 226px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${NEUTRAL_SHADES.WHITE};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[500]};
    border-radius: 5px;
  }
`;

export const DrawerToggleIcon = styled(TangiSvgIcon)`
  margin-inline: auto 0;
`;

export const SideBarNavItem = styled.div<{ isActive?: boolean; isCollapseLabel?: boolean }>`
  ${({ isActive = false, isCollapseLabel = false }) => css`
    display: flex;
    flex-wrap: nowrap;
    gap: ${SPACING[2.5]};
    align-items: center;
    cursor: pointer;
    height: ${SPACING[6]};
    padding: 10px ${SPACING[2]};
    border-radius: 4px;
    background: ${isActive && NEUTRAL_SHADES[100]};

    .tangi-icon-container {
      width: ${SPACING[3.5]};
      display: flex;
      justify-content: center;
    }

    ${!isCollapseLabel &&
    css`
      &:hover {
        background: ${NEUTRAL_SHADES[100]};
        .sidebar-toggle-icon path {
          fill: ${NEUTRAL_SHADES[800]};
        }
      }
    `}
  `}
`;

export const StyledReactRouterNavLink = styled(NavLink)`
  //Styles for the active link
  &.active-react-router-link {
    .sidebar-nav-item {
      background: ${PRIMARY_SHADES[100]};
    }
    svg.tangi-icon-svg path {
      fill: ${PRIMARY_SHADES[800]};
    }
    .tangi-typography {
      color: ${PRIMARY_SHADES[800]};
    }
  }
`;

export const LawyerSideMenuHidden = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  minheight: 98vh !important;
  zindex: 100;
  padding: 1em 0;
  width: 100px;
  overflow-y: auto;
  background-color: ${NEUTRAL_SHADES[50]};
`;

export const collapseLabelStyles: CSSObject = {
  borderRadius: '4px',
  paddingRight: SPACING[2],
  '&:hover': {
    background: NEUTRAL_SHADES[100],
  },
};

export const MenuGroupContainer = styled.div<{ isLastGroup?: boolean }>`
  padding-top: ${SPACING[2]};
  border-top: 1px solid ${NEUTRAL_SHADES[400]};
  ${({ isLastGroup = false }) =>
    isLastGroup &&
    css`
       {
        border-bottom: 1px solid ${NEUTRAL_SHADES[400]};
        padding-bottom: ${SPACING[2]};
      }
    `}
`;

export const CollapseContentContainer = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  flex-direction: column;
  :first-child {
    padding-top: 4px;
  }
`;

export const LayoutColumn = styled(Col)`
  background-color: ${NEUTRAL_SHADES.WHITE};
  border-right: 1px solid ${NEUTRAL_SHADES[300]};
`;
