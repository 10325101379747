import styled, { css } from "styled-components";
import { FlexContainer } from "utils/globalStyles";
import { ERROR_SHADES, SPACING, SUCCESS_SHADES, WARNING_SHADES } from "utils/theme";
import { ChipType, StyledChipProps } from "./types";
import { VARIANT_COLOR } from "./consts";

export const ChipContentContainer = styled(FlexContainer)`
  gap: ${SPACING[1]};
  justify-content: center;
  align-items: center;
`;


export const StyledChip = styled.div<StyledChipProps>`
  ${({ variant }) => css`
    font-family: 'DIN2014-Regular';
    font-size: 12px;
    ${getVariantProps(variant)};
    text-overflow: ellipsis;
    display: flex;
    height: fit-content;
    width: fit-content;
    padding: ${SPACING[1]} ${SPACING[2]};
    justify-content: center;
    align-items: center;
  `}
`;

const getVariantProps = (variant: ChipType) => {
    switch (variant) {
      case ChipType.SUCCESS:
        return css`
          background-color: ${SUCCESS_SHADES[100]};
          border-radius: 12px;
          padding-inline: ${SPACING[2]};
          padding-block: 3px 2px;
          color: ${SUCCESS_SHADES[600]};
          font-family: 'DIN2014-DemiBold';
        `;
      case ChipType.WARNING:
        return css`
          background-color: ${WARNING_SHADES[100]};
          border-radius: 12px;
          padding-inline: ${SPACING[2]};
          padding-block: 3px 2px;
          color: ${VARIANT_COLOR[variant]};
          font-family: 'DIN2014-DemiBold';
        `;
  
      case ChipType.ERROR:
        return css`
          background-color: ${ERROR_SHADES[100]};
          border-radius: 12px;
          padding-inline: ${SPACING[2]};
          padding-block: 3px 2px;
          color: ${VARIANT_COLOR[variant]};
          font-family: 'DIN2014-DemiBold';
        `;
    }
  };