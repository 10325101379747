import React from 'react';
import { Form } from 'react-bootstrap';

import { StyledInputGroup } from './style';
import { Star } from '../../Star';

export const TangiDatePicker = ({ required = false, label, error, smallText, register, name, registerRules, ...props }) => {
  return (
    <div>
      {label && <Form.Label className="text-dark fw-600 txt-size">{label}</Form.Label>}
      {required && <Star />}
      <StyledInputGroup>
        <Form.Control
          data-testid={name}
          style={error && { borderColor: 'red' }}
          type="date"
          {...register(name ?? label, {
            required: { value: required, message: `${label} is required` },
            ...registerRules,
          })}
          {...props}
        />
        {smallText && <small className="form-text text-muted txt-size">{smallText}</small>}
        <div className="d-block invalid-feedback">{error && error.message}</div>
      </StyledInputGroup>
    </div>
  );
};
