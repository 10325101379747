/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getInventorsAccounts,
  getTradeSecretsFromPatent,
  uploadPatentApplication,
  getPatentResults,
  getContinuationAnalysisById,
  getContinuationAnalysisResults,
  getContinuationAnalysisByFile,
} from 'redux-toolkit/thunks/patentThunks';
import {
  PatentApplicationData,
  PatentRequestInfo,
  PATENT_MODEL_TYPE,
  ContinuationAnalysisResponse,
  ContinuationAnalysisResultsResponse,
  ContinuationAnalysisData,
  FileType,
} from '../../utils/types/patent/patent';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XrayLoaders } from 'utils/types/xRay/xRay';
import { Account } from 'utils/types/account/account';
import { patentConstants } from '_constants/patentConstants';
import { loadStateFromLocalStorage, removeStateFromLocalStorage } from 'utils/localStorage';
import { ExportCheckboxOption } from 'pages/PatentTool/types';

export interface PatentState {
  patentResultId: string;
  continuationResultId: string;
  patentSource: XRAY_SOURCE_TYPE;
  isModalOpen: {
    uploadPatentFile: boolean;
  };
  accounts: Account[];
  loading: boolean;
  error: string;
  isAssetCreatedIndex: number;
  patentLoaders: XrayLoaders;
  continuationLoaders: XrayLoaders;
  patentData: PatentApplicationData;
  continuationData: ContinuationAnalysisData;
  patentRequestInfo: PatentRequestInfo | null;
  patentModel: PATENT_MODEL_TYPE;
  isMappingTool: boolean;
  exportFilesCheckboxes: ExportCheckboxOption[];
  isShowExportToast: boolean;
  currentPatentFile: File[] | undefined;
}
const patentResult = JSON.parse(localStorage?.getItem(patentConstants.PATENT_REQUEST_INFO) || 'null');
const patentResultId = patentResult && patentResult.patentResultId ? patentResult.patentResultId : '';

const initializePatentData = (): PatentApplicationData => {
  return {
    accountsOfInventors: [],
    patent_id: '',
    regenerate: 0,
    status: XRAY_JOB_STATUS.BLANK,
    details: '',
    created_date: '',
    source_type: XRAY_SOURCE_TYPE.BLANK,
    choice_0: [],
    file_flow: '',
    filename: '',
    status_finder: XRAY_JOB_STATUS.BLANK,
    status_mapper: XRAY_JOB_STATUS.BLANK,
    account: '',
    client: '',
    inventors: [],
    title: '',
    year: null,
    url: '',
  };
};

const initializeContinuationData = (): ContinuationAnalysisData => {
  return {
    response: initializeContinuationDataResponse(),
    patentId: '',
    fileName: '',
    excelExportStatus: XRAY_JOB_STATUS.BLANK,
    wordExportStatus: XRAY_JOB_STATUS.BLANK,
    showContinuationExportToast: false,
  };
};

const initializeContinuationDataResponse = (): ContinuationAnalysisResultsResponse => {
  return {
    subjects: [],
    status: XRAY_JOB_STATUS.BLANK,
    details: '',
    sourceType: XRAY_SOURCE_TYPE.BLANK,
  };
};

/**
 * The  state for patent-related data and UI state management.
 *
 * @property {PatentData} patentData - Contains data related to the trade secrets extracted from Google Patents search.
 * @property {any[]} accounts - An array of account information related to the patent from Google Patents search.
 * @property {string} patentResultId - patent result ID, obtained from local storage if available.
 * @property {XRAY_SOURCE_TYPE} patentSource - Patent source type of patent finder (Google Patents/patent application), defaulting to BLANK.
 * @property {boolean} isModalOpen.uploadPatentFile - State of the upload patent file modal, defaulting to false.
 * @property {boolean} isModalOpen.uploadPatentFile - State of the upload patent file for mapping modal, defaulting to false.
 * @property {boolean} loading - Loading state, defaulting to false.
 * @property {string} error - Error message related to patent operations, defaulting to an empty string.
 * @property {number} currentChoiceNumber - The number of the current choice related to the patent from Google Patents search, defaulting to DEFAULT_CHOICE_NUM.
 * @property {number} isAssetCreatedIndex - Index indicating if the asset from Google Patents search is created, defaulting to -1.
 * @property {XrayLoaders} patentLoaders - State of patent-related loaders, including flags for specific loading states.
 * @property {PatentApplicationData} patentData - Contains data related to the trade secrets extracted and mapping from the uploaded patent application.
 * @property {PatentRequestInfo} patentRequestInfo - Information related to the patent mapping request, including patentResultId, accountId, and clientId.
 * @property {boolean} isMappingTool - state showing the correlated data, deciding whether to show patent mapping data or patent basic data
 *
 */
const initialState: PatentState = {
  accounts: [],
  patentResultId,
  continuationResultId: '',
  patentSource: XRAY_SOURCE_TYPE.BLANK,
  isModalOpen: {
    uploadPatentFile: false,
  },
  loading: false,
  error: '',
  isAssetCreatedIndex: -1,
  patentLoaders: { startLoader: false, getResultLoader: false },
  continuationLoaders: { startLoader: false, getResultLoader: false },
  patentData: initializePatentData(),
  continuationData: initializeContinuationData(),
  patentRequestInfo: loadStateFromLocalStorage(patentConstants.PATENT_REQUEST_INFO),
  patentModel: PATENT_MODEL_TYPE.TANGIBLY,
  isMappingTool: false,
  exportFilesCheckboxes: [],
  isShowExportToast: false,
  currentPatentFile: undefined,
};

const patentDataSlice = createSlice({
  name: 'patentData',
  initialState,
  reducers: {
    resetEntireState: () => {
      return {
        ...initialState,
      };
    },
    resetEntirePatentState: (state) => {
      const { continuationData, continuationLoaders, continuationResultId } = state;
      return {
        ...initialState,
        continuationData,
        continuationLoaders,
        continuationResultId,
      };
    },
    resetStateExceptRequestInfo: (state) => {
      const { patentResultId, patentRequestInfo } = state;
      return {
        ...initialState,
        patentResultId,
        patentRequestInfo,
      };
    },
    setAssetCreatedToTradeSecret(state, action: PayloadAction<number>) {
      if (state.patentData?.choice_0) {
        state.patentData.choice_0[action.payload].isAssetCreated = true;
      }
    },
    setIsAssetCreatedIndex(state, action: PayloadAction<number>) {
      state.isAssetCreatedIndex = action.payload;
    },
    setAccounts(state, action: PayloadAction<any>) {
      state.accounts = action.payload;
    },
    setPatentInitState(state) {
      state.accounts = [];
      state.loading = false;
      state.error = '';
      state.isAssetCreatedIndex = -1;
      state.patentLoaders = { startLoader: false, getResultLoader: false };
    },
    initializeContinuationData(state) {
      state.loading = false;
      state.error = '';
      state.continuationData.response = initializeContinuationDataResponse();
      state.continuationData.fileName = '';
      state.continuationData.patentId = '';
      state.continuationData.showContinuationExportToast = false;
      state.continuationData.excelExportStatus = XRAY_JOB_STATUS.BLANK;
      state.continuationData.wordExportStatus = XRAY_JOB_STATUS.BLANK;
    },
    setPatentApplicationDataInitState(state) {
      state.patentData = initializePatentData();
    },
    setPatentDataInitState(state) {
      state.accounts = [];
      state.error = '';
      state.isAssetCreatedIndex = -1;
      state.patentLoaders = state.patentLoaders;
      state.patentData = initializePatentData();
    },
    toggleUploadPatentFileModal(state, action: PayloadAction<boolean>) {
      state.isModalOpen.uploadPatentFile = action.payload;
    },
    setAssetCreatedInPatentApplicationData(state, action: PayloadAction<number>) {
      if (state.patentData?.choice_0) {
        state.patentData.choice_0[action.payload].isAssetCreated = true;
      }
    },
    setPatentModelType(state, action: PayloadAction<PATENT_MODEL_TYPE>) {
      state.patentModel = action.payload;
    },
    setAssetCreatedInPatentData(state, action: PayloadAction<number>) {
      if (state.patentData?.choice_0) {
        state.patentData.choice_0[action.payload].isAssetCreated = true;
      }
    },
    setIsMappingTool(state, action: PayloadAction<boolean>) {
      state.isMappingTool = action.payload;
    },
    setExportFilesCheckboxes(state, action: PayloadAction<ExportCheckboxOption[]>) {
      state.exportFilesCheckboxes = action.payload;
    },
    setContinuationExportFileStatus(state, action: PayloadAction<{ fileType: FileType; status: XRAY_JOB_STATUS }>) {
      switch (action.payload.fileType) {
        case 'excel':
          state.continuationData.excelExportStatus = action.payload.status;
          break;
        case 'word':
          state.continuationData.wordExportStatus = action.payload.status;
          break;

        default:
          break;
      }
    },
    handleExportCheckboxChange(state, action: PayloadAction<Partial<ExportCheckboxOption>>) {
      const updatedCheckboxes = state.exportFilesCheckboxes.map((checkbox) => {
        if (checkbox.id === action.payload.id) {
          return { ...checkbox, ...action.payload };
        }
        return checkbox;
      });

      state.exportFilesCheckboxes = updatedCheckboxes;
    },
    // Added a status of the exporting for each checkbox - for now implemented only for word files
    // the other export logic is on the handleExportCheckboxChange which related to all the logic of exporting.
    // This fix is for word only, for the future need to implement for all the other exports
    setExportCheckboxStatus(state, action: PayloadAction<{ id: string; status: XRAY_JOB_STATUS }>) {
      const updatedCheckboxes = state.exportFilesCheckboxes.map((checkbox) => {
        if (checkbox.id === action.payload.id) {
          return { ...checkbox, status: action.payload.status };
        }
        return checkbox;
      });

      state.exportFilesCheckboxes = updatedCheckboxes;
    },

    setShowExportToast(state, action: PayloadAction<boolean>) {
      state.isShowExportToast = action.payload;
    },
    setShowContinuationExportToast(state, action: PayloadAction<boolean>) {
      state.continuationData.showContinuationExportToast = action.payload;
    },
    setCurrentPatentFile(state, action: PayloadAction<File[] | undefined>) {
      state.currentPatentFile = action.payload;
    },
    setContinuationFile(state, action: PayloadAction<string>) {
      state.continuationData.fileName = action.payload;
    },
    setContinuationPatentId(state, action: PayloadAction<string>) {
      state.continuationData.patentId = action.payload;
    },
    resetEntireContinuationState(state) {
      state.continuationData = initializeContinuationData();
      state.continuationLoaders = { startLoader: false, getResultLoader: false };
      state.continuationResultId = '';
    },
  },
  extraReducers: (builder) => {
    // getPatentById
    builder.addCase(getTradeSecretsFromPatent.pending, (state) => {
      state.loading = true;
      state.patentLoaders.startLoader = true;
    });
    builder.addCase(getTradeSecretsFromPatent.fulfilled, (state, action: PayloadAction<any>) => {
      state.patentResultId = action.payload.patentResultId;
      state.patentSource = action.payload.source;
      state.patentRequestInfo = action.payload;
      state.loading = false;
      state.patentLoaders.startLoader = false;
    });
    builder.addCase(getTradeSecretsFromPatent.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.patentData.details = action.error.message;
      state.patentData.status = XRAY_JOB_STATUS.FAILED;
      state.patentSource = XRAY_SOURCE_TYPE.BLANK;
      removeStateFromLocalStorage(patentConstants.PATENT_REQUEST_INFO);
      state.loading = false;
      state.patentLoaders.startLoader = false;
    });

    // getInventorsAccounts
    builder.addCase(getInventorsAccounts.fulfilled, (state, action: PayloadAction<any>) => {
      state.patentData.accountsOfInventors = action.payload;
    });
    builder.addCase(getInventorsAccounts.rejected, (state, action: any) => {
      state.error = action.error.message;
    });

    // uploadPatentApplication
    builder.addCase(uploadPatentApplication.pending, (state) => {
      state.loading = true;
      state.patentLoaders.startLoader = true;
    });
    builder.addCase(uploadPatentApplication.fulfilled, (state, action: PayloadAction<PatentRequestInfo & { files: File[] }>) => {
      state.patentRequestInfo = action.payload;
      state.patentLoaders.startLoader = false;
      state.loading = false;
    });
    builder.addCase(uploadPatentApplication.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.patentData.status = XRAY_JOB_STATUS.FAILED;
      state.patentData.details = action.error.message;
      removeStateFromLocalStorage(patentConstants.PATENT_REQUEST_INFO);
      state.patentLoaders.startLoader = false;
      state.loading = false;
      state.isModalOpen.uploadPatentFile = false;
    });

    // getPatentResults
    builder.addCase(getPatentResults.pending, (state) => {
      if (state.patentData.status_finder === XRAY_JOB_STATUS.SUCCEEDED) return;
      state.loading = true;
      state.patentLoaders.getResultLoader = true;
    });
    builder.addCase(getPatentResults.fulfilled, (state, action: PayloadAction<PatentApplicationData>) => {
      state.patentData = action.payload;
      state.patentLoaders.getResultLoader = false;
      state.loading = false;
    });
    builder.addCase(getPatentResults.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.patentData.status_finder = XRAY_JOB_STATUS.FAILED;
      state.patentData.status_mapper = XRAY_JOB_STATUS.FAILED;
      state.patentData.status = XRAY_JOB_STATUS.FAILED;
      state.patentData.details = action.error.message;
      removeStateFromLocalStorage(patentConstants.PATENT_REQUEST_INFO);
      state.patentLoaders.getResultLoader = false;
      state.loading = false;
    });

    // getContinuationAnalysisById
    builder.addCase(getContinuationAnalysisById.pending, (state) => {
      state.continuationLoaders.startLoader = true;
    });
    builder.addCase(getContinuationAnalysisById.fulfilled, (state, action: PayloadAction<ContinuationAnalysisResponse, string>) => {
      state.continuationResultId = action.payload.continuationResultId;
      state.continuationLoaders.startLoader = false;
    });
    builder.addCase(getContinuationAnalysisById.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.continuationData.response.details = action.error.message;
      state.continuationData.response.status = XRAY_JOB_STATUS.FAILED;
      state.continuationData.response.sourceType = XRAY_SOURCE_TYPE.BLANK;
      state.continuationLoaders.startLoader = false;
    });

    //getContinuationAnalysisByFile
    builder.addCase(getContinuationAnalysisByFile.pending, (state) => {
      state.continuationLoaders.startLoader = true;
    });
    builder.addCase(getContinuationAnalysisByFile.fulfilled, (state, action: PayloadAction<ContinuationAnalysisResponse, string>) => {
      state.continuationResultId = action.payload.continuationResultId;
      state.continuationLoaders.startLoader = false;
    });
    builder.addCase(getContinuationAnalysisByFile.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.continuationData.response.details = action.error.message;
      state.continuationData.response.status = XRAY_JOB_STATUS.FAILED;
      state.continuationData.response.sourceType = XRAY_SOURCE_TYPE.BLANK;
      state.continuationLoaders.startLoader = false;
    });

    // getContinuationAnalysisResults
    builder.addCase(getContinuationAnalysisResults.pending, (state) => {
      if (state.continuationData?.response?.status === XRAY_JOB_STATUS.SUCCEEDED) return;
      state.continuationLoaders.getResultLoader = true;
    });
    builder.addCase(getContinuationAnalysisResults.fulfilled, (state, action: PayloadAction<ContinuationAnalysisResultsResponse, string>) => {
      state.continuationData.response = action.payload;
      state.continuationLoaders.getResultLoader = false;
    });
    builder.addCase(getContinuationAnalysisResults.rejected, (state, action: any) => {
      state.error = action.error.message;
      if (state.continuationData.response) {
        state.continuationData.response.status = XRAY_JOB_STATUS.FAILED;
        state.continuationData.response.details = action.error.message;
      }
      state.continuationLoaders.getResultLoader = false;
    });
  },
});

export const patentActions = patentDataSlice.actions;

export default patentDataSlice.reducer;
