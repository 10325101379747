import { HeatMapData, HeatmapItem } from './types';
import { HexColor, HexColorArray } from '../types';
import { NEUTRAL_SHADES, darkColorsPalette } from 'utils/theme';

export const PERCENTAGE = 100;
export const SCROLL_GRADIENT_SIZE = 50;

export const getHeatMapChartData = (heatMapData: HeatmapItem[]) => {
  return heatMapData?.map((item) => ({
    id: item.subject,
    data: item.groups?.map((group) => ({
      x: group.patent_application_id,
      y: group.ts_count,
      ts_count: 0,
    })),
  }));
};

export const maxSubjectLength = (heatMapData: HeatMapData[], fontSize: number = 11) => {
  let max = heatMapData[0]?.id.length || 10;
  const avgCharWidth = 0.5 * fontSize;

  heatMapData.forEach((item) => {
    max = item?.id.length > max ? item.id.length : max;
  });

  return max * avgCharWidth;
};

export const getLegendValues = (heatMapData: HeatMapData[], palette: HexColorArray) => {
  let min = 0;
  let max = 0;

  if (heatMapData?.[0]?.data?.[0]) {
    min = heatMapData[0].data[0].y;
    max = heatMapData[0].data[0].y;
  }

  heatMapData.forEach((data) => {
    data.data.forEach((item) => {
      max = item.y > max ? item.y : max;
      min = item.y < min ? item.y : min;
    });
  });

  const fallBackColor = NEUTRAL_SHADES.WHITE;
  const topColor = palette?.[palette.length - 5] ?? fallBackColor;
  const bottomColor = palette?.[2] ?? fallBackColor;
  const maxValue = max;
  const minValue = min;

  return { topColor, bottomColor, maxValue, minValue };
};

export const getFormat = (p: number, isCompetitiveChart: boolean) => {
  if (isCompetitiveChart) {
    return p < 0.1 ? `< 0.1%` : `${p}%`
  }

  return `${p}`
};

export const getTextColor = (squareColor: HexColor) => {
  return darkColorsPalette.includes(squareColor) ? NEUTRAL_SHADES[100] : NEUTRAL_SHADES[1100];
};
