import React from 'react';
import { useHistory } from 'react-router-dom';

import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { TangiIconButton } from '../TangiIconButton';
import { BackToContainer } from 'utils/globalStyles';

interface Props {
  navigationPath: string;
  icon?: string;
  variant?: typeof BUTTON_VARIANTS[keyof typeof BUTTON_VARIANTS];
  buttonText: string;
}

export const TangiBackNavigation = ({ navigationPath, icon = 'arrowLeft', variant = BUTTON_VARIANTS.TERTIARY_GREY, buttonText }: Props) => {
  const history = useHistory();

  return (
    <BackToContainer>
      <TangiIconButton data-testid="back-navigation-button" icon={icon} variant={variant} onClick={() => history.push(navigationPath)} />
      <span>{buttonText}</span>
    </BackToContainer>
  );
};
