import styled from 'styled-components';
import { useMemo } from 'react';

import { NEUTRAL_SHADES } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { TangiTypography } from '_components/TangiLibrary';
import ProgressBar from '../../Charts/ProgressBar';
import { AssetDataItem } from '../Widgets.types';

const AssetsContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
`;
const TitleContainer = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
`;

const AmountContainer = styled.div`
  display: flex;
`;

interface Props extends AssetDataItem {
  total: number;
}

const CreatedAssets = ({ label, amount, total }: Props) => {
  const progressBarNumber = useMemo(() => +((amount / total) * 100).toFixed(0), [amount, total]);

  return (
    <AssetsContainer data-testid={label}>
      <TitleContainer>
        <TangiTypography weight="semibold" color={amount ? NEUTRAL_SHADES[1000] : NEUTRAL_SHADES[800]}>
          {label}
        </TangiTypography>
        <AmountContainer data-testid="created-assets-amount">
          <TangiTypography color={amount ? NEUTRAL_SHADES[1000] : NEUTRAL_SHADES[700]}>{amount}</TangiTypography>
          {amount ? <TangiTypography color={NEUTRAL_SHADES[700]}>/{total}</TangiTypography> : null}
        </AmountContainer>
      </TitleContainer>
      <ProgressBar now={progressBarNumber} />
    </AssetsContainer>
  );
};

export default CreatedAssets;
