import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import NewIntegrationModal from './components/NewIntegrationModal/NewIntegrationModal';
import { TANGI_BUTTON_SIZES, TangiButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import useLocalStorage from '_hooks/useLocalStorage';
import useLocalStorageListener from '_hooks/useLocalStorageListener';
import useRole from '_hooks/useRole';
import { RootState } from '_helpers';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { DISCLAIMER_KEY, generateIntegrationPopupKey } from './consts';
import { PRIMARY_SHADES, BUTTON_VARIANTS } from 'utils/theme';
import { BannerBodyContainer, NewTag, StyledBanner } from './style';

interface Props {
  onStartIntegration: () => void;
}

const SharePointIntegrationBanner = ({ onStartIntegration }: Props) => {
  const { isLawyer } = useRole();

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const loading = useSelector((state: RootState) => state.sharePoint.isSPIntegrationInProgress);

  const accountClientId = isLawyer ? activeClient?.id : activeAccount?.client?._id;
  const seenFirstTimeDisclaimer = activeAccount?.seenDisclaimer;

  const [seenSPIntegrationPopup, setSeenSPIntegrationPopup] = useLocalStorage<boolean>(generateIntegrationPopupKey(accountClientId), false);
  const seenEntryDisclaimer = useLocalStorageListener<boolean>(`${DISCLAIMER_KEY}[${activeAccount._id}]`, false); // Listen for changes in localStorage for the seenLoginDisclaimer key

  const [isNewIntegrationModalOpen, setIsNewIntegrationModalOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  // Re-sync the local state with localStorage when lawyer switches between clients
  useEffect(() => {
    const localStorageKey = generateIntegrationPopupKey(accountClientId);
    const value = loadStateFromLocalStorage(localStorageKey);
    if (value === null) {
      setSeenSPIntegrationPopup(false);
    }
  }, [accountClientId, setSeenSPIntegrationPopup]);

  // For non-lawyers: show modal only after disclaimer is confirmed
  // For lawyers: after first-time disclaimer is confirmed or when switch between clients
  useEffect(() => {
    const shouldShowForLawyer = isLawyer && activeClient && seenFirstTimeDisclaimer;
    const shouldShowForNonLawyer = !isLawyer && seenEntryDisclaimer;

    if (!seenSPIntegrationPopup && (shouldShowForLawyer || shouldShowForNonLawyer)) {
      setIsNewIntegrationModalOpen(true);
    }
  }, [seenSPIntegrationPopup, seenEntryDisclaimer, isLawyer, activeClient, seenFirstTimeDisclaimer]);

  const handleCloseModal = () => {
    if (!seenSPIntegrationPopup) {
      setSeenSPIntegrationPopup(true);
    }
    setIsNewIntegrationModalOpen(false);
  };

  const handleConnect = () => {
    handleCloseModal();
    onStartIntegration();
  };

  return (
    <>
      <StyledBanner data-testid="sp-integration-banner">
        <BannerBodyContainer>
          <TangiSvgIcon component="sharepointLogo" />
          <TangiTypography type="heading-md" weight="semibold">
            {t('SHARE_POINT_INTEGRATION.SP_INTEGRATION_BANNER.TEXT')}
          </TangiTypography>
          <NewTag>
            <TangiSvgIcon component="discovery" color={PRIMARY_SHADES[800]} />
            <TangiTypography type="sub-body" weight="semibold" color={PRIMARY_SHADES[800]}>
              {t('GENERAL.CHIP.NEW')}
            </TangiTypography>
          </NewTag>
        </BannerBodyContainer>
        <TangiButton
          size={TANGI_BUTTON_SIZES.SMALL}
          variant={loading ? BUTTON_VARIANTS.SECONDARY : BUTTON_VARIANTS.PRIMARY}
          smallbtn
          text={t('GENERAL.BUTTONS_TEXT.CONNECT')}
          onClick={handleConnect}
          loading={loading}
          data-testid="open-integration-modal"
        />
      </StyledBanner>
      <NewIntegrationModal isOpenModal={isNewIntegrationModalOpen} onCloseModal={handleCloseModal} onConnect={handleConnect} />
    </>
  );
};

export default SharePointIntegrationBanner;
