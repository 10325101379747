import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getAssetMetaData } from 'redux-toolkit/thunks/assetMetaDataThunks';
import { clientSettingsActions } from '../../_actions/clientSettingsActions';

import { isUserIsRole } from '../../utils/roles';

export const useViewEmployee = (userData) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const metaDataState = useSelector((state) => state.assetMetaData);
  const { settings } = useSelector((state) => state.clientSettings);
  const { activeAccount } = useSelector((state) => state.authentication);

  const [ownedSortState, setOwnedSortState] = useState({ selector: '', direction: '' });
  const [sharedSortState, setSharedSortState] = useState({ selector: '', direction: '' });
  const [createdAssets, setCreatedAssets] = useState([]);
  const [sharedAssets, setSharedAssets] = useState([]);
  const [contributorsAssets, setContributorsAssets] = useState([]);

  useEffect(() => {
    if (!metaDataState) {
      dispatch(getAssetMetaData(clientId));
    }
    if (!settings) {
      dispatch(clientSettingsActions.getClientSettings(clientId));
    }
  }, [settings, metaDataState]);

  const isUserLearnType = useMemo(() => isUserIsRole(activeAccount, 'Learn'), [activeAccount]);

  useEffect(() => {
    if (userData?.assets) {
      setCreatedAssets(userData.assets.created || []);
      setSharedAssets(userData.assets.sharedWith || []);
      setContributorsAssets(userData.assets.assetsContributors || []);
    }
  }, [userData]);

  const handleOwnedSort = (column, sortDirection) => {
    setOwnedSortState({
      selector: column.selector,
      direction: sortDirection,
    });
  };

  const handleSharedSort = (column, sortDirection) => {
    setSharedSortState({
      selector: column.selector,
      direction: sortDirection,
    });
  };

  return {
    clientId,
    metaDataState,
    settings,
    ownedSortState,
    sharedSortState,
    handleSharedSort,
    handleOwnedSort,
    createdAssets,
    sharedAssets,
    isUserLearnType,
    contributorsAssets,
  };
};
