import styled from 'styled-components';
import { Divider } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const StyledHeader = styled.div`
  display: flex;
  padding: ${SPACING[3.5]};
  justify-content: space-between;
  align-items: center;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const ModalContent = styled.div`
  max-height: 62vh;
  overflow-y: auto;
  min-width: 1200px;
`;

export const Container = styled.div`
  padding: ${SPACING[3.5]};
`;

export const TotalResults = styled.div`
  padding-bottom: ${SPACING[3.5]};
`;
