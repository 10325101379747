import { TangiTypography } from '../TangiTypography/TangiTypography';
import { PercentageTextContainer, percentageTextStyles, ScoreContainer, scoreTextStyles } from './styles';
import ScoreProgressBar from './components/ScoreProgressBar/ScoreProgressBar';
import { useMemo } from 'react';
import { getProgressBarColor } from './utils';
import { useTranslation } from 'react-i18next';

interface Props {
  score: number;
}

export const TangiContractMatchScore = ({ score }: Props) => {
  const { t } = useTranslation();
  const progressBarColor = useMemo(() => getProgressBarColor(score), [score]);
  return (
    <ScoreContainer>
      <PercentageTextContainer>
        <TangiTypography weight="bold" type="heading-xl" customStyles={scoreTextStyles}>
          {score}
        </TangiTypography>
        <TangiTypography type="heading-xl" customStyles={percentageTextStyles}>
          %
        </TangiTypography>
      </PercentageTextContainer>
      <ScoreProgressBar now={score} customBackgroundColor={progressBarColor} />
      <TangiTypography type="subheading">{t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.TRADE_SECRET_PROTECTION_SCORE')}</TangiTypography>
    </ScoreContainer>
  );
};
