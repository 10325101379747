import { Account, AccountMinimalData } from './account';

export const isTypeAccountMinimalData = (account: AccountMinimalData | Account | null): account is AccountMinimalData => {
  if (!account) return false;
  return (
    account &&
    typeof account === 'object' &&
    '_id' in account &&
    'user' in account &&
    typeof account.user === 'object' &&
    '_id' in account.user &&
    Object.keys(account).length === 2 &&
    Object.keys(account.user).length === 1
  );
};
