import { TangiCard } from '_components/TangiLibrary/TangiCard/TangiCard';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const Container = styled(TangiCard)`
  display: flex;
  padding: ${SPACING[3]};
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${SPACING[1]};
`;

export const Border = styled.span`
  border: 1px solid ${NEUTRAL_SHADES[400]};
  width: 0;
  height: 100%;
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  flex: 1;
`;

export const XRaySearchContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2.5]};
`;

export const ContractToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  height: 100%;
  padding: ${SPACING[3]};
`;

export const ContractToolBodyContainer = styled.div`
  display: flex;
  gap: ${SPACING[4]};
  height: calc(100% - 28px);
`;

export const ContractToolBodyStyle = {
  width: '950px',
  overflow: 'hidden',
  height: 'calc(100% - 70px)',
  padding: SPACING[0],
};

export const contractToolModalContainer = {
  height: '800px',
};

export const OverflowContainer = styled.div`
  overflow-y: scroll;
  flex: 1;
  padding-bottom: ${SPACING[4]};
`;
