import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';

import { Container, StyledFormGroup } from './style';

// TODO: add the labels and `required` styles when necessary
interface Props<T extends FieldValues> {
  name: Path<T>;
  startLabel?: string | React.ReactNode;
  endLabel?: string | React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  dataTestId?: string;
}

// NOTE! This is a toggle switch component like TangiToggle but to be used with `react-hook-form`!

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TangiFormToggle<T extends FieldValues>({ name, required = false, dataTestId = 'tangi-form-toggle', disabled = false }: Props<T>) {
  const { control } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <StyledFormGroup controlId={name}>
            <Container>
              <div className="toggle-switch">
                {/* Hidden checkbox for form control */}
                <input
                  type="checkbox"
                  className="checkbox"
                  name={name}
                  id={name}
                  checked={field.value || false}
                  onChange={(e) => field.onChange(e.target.checked)}
                  disabled={disabled}
                  data-testid={dataTestId}
                />
                {/* Custom label that mimics a switch */}
                <label className={`label ${disabled ? 'disabled' : ''}`} htmlFor={name}>
                  <span className="inner" />
                  <span className="switch" />
                </label>
              </div>
            </Container>
          </StyledFormGroup>
        );
      }}
    />
  );
}

export default TangiFormToggle;
