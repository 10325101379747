import RGL from 'react-grid-layout';

import { NEUTRAL_SHADES } from '../../utils/theme';

export enum DASHBOARD_WIDGETS_KEYS {
  ASSETS_BY_TYPE = 'assets_by_type',
  ASSETS_OVER_TIME = 'assets_over_time',
  DATA_BY_DEPARTMENTS = 'data_by_departments',
  ASSETS_DATA = 'assets_data',
  USERS = 'users',
  INSIGHTS = 'insights',
  PROJECTS_BY_ASSETS = 'projects_by_assets',
  CONTRIBUTORS = 'contributors',
  PEOPLE_STATUS = 'people_status',
  USED_TAGS = 'used_tags',
  PEOPLE_BY_ROLE = 'people_by_role',
}

const ROW_HEIGHT_UNITS = 5;
export const rowHeight = 334 / ROW_HEIGHT_UNITS;
export const numberOfColumns = 3;

export const dashboardLayout: RGL.Layout[] = [
  { i: DASHBOARD_WIDGETS_KEYS.ASSETS_BY_TYPE, x: 0, y: 0, w: 1, h: ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS },
  { i: DASHBOARD_WIDGETS_KEYS.ASSETS_OVER_TIME, x: 1, y: 0, w: 2, h: ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS * 0.8, minW: 2 },
  { i: DASHBOARD_WIDGETS_KEYS.DATA_BY_DEPARTMENTS, x: 0, y: ROW_HEIGHT_UNITS, w: 1, h: ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS },
  { i: DASHBOARD_WIDGETS_KEYS.ASSETS_DATA, x: 1, y: ROW_HEIGHT_UNITS, w: 1, h: (3 / 5) * ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS * 0.6 },
  { i: DASHBOARD_WIDGETS_KEYS.USERS, x: 1, y: ROW_HEIGHT_UNITS + 3, w: 1, h: (2 / 5) * ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS * 0.4 },
  { i: DASHBOARD_WIDGETS_KEYS.INSIGHTS, x: 2, y: ROW_HEIGHT_UNITS, w: 1, h: 3 },
  { i: DASHBOARD_WIDGETS_KEYS.PROJECTS_BY_ASSETS, x: 0, y: ROW_HEIGHT_UNITS * 2, w: 1, h: ROW_HEIGHT_UNITS },
  { i: DASHBOARD_WIDGETS_KEYS.CONTRIBUTORS, x: 1, y: ROW_HEIGHT_UNITS * 2, w: 1, h: ROW_HEIGHT_UNITS },
  { i: DASHBOARD_WIDGETS_KEYS.PEOPLE_STATUS, x: 1, y: ROW_HEIGHT_UNITS * 2 + 3, w: 1, h: (2 / 5) * ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS * 0.4 },
  { i: DASHBOARD_WIDGETS_KEYS.CONTRIBUTORS, x: 1, y: ROW_HEIGHT_UNITS * 2, w: 1, h: ROW_HEIGHT_UNITS },
  { i: DASHBOARD_WIDGETS_KEYS.USED_TAGS, x: 2, y: ROW_HEIGHT_UNITS * 2, w: 1, h: ROW_HEIGHT_UNITS },
  { i: DASHBOARD_WIDGETS_KEYS.PEOPLE_BY_ROLE, x: 0, y: ROW_HEIGHT_UNITS * 2 + 3, w: 1, h: (2 / 5) * ROW_HEIGHT_UNITS, minH: ROW_HEIGHT_UNITS * 0.4 },
];

export const LOCAL_STORAGE_KEY = 'DASHBOARD_LAYOUT_LOCAL_STORAGE_KEY';
export const LOCAL_STORAGE_LATEST_VERSION = 3;

export const PIE_CHART_CONSTS = {
  OTHER: 'Other',
  MAX_GROUPS: 5,
  OTHER_COLOR: NEUTRAL_SHADES[600],
  MAX_LEGEND_ITEM_TEXT_LENGTH: 24,
  LEGEND_ITEM_WIDTH: 140,
  MAX_ITEMS_PER_LEGEND: 2,
};

export const MAX_LEGEND_BAR_CHART_TEXT = 24;
export const MAX_ITEMS_PER_BAR_CHART_LEGEND = 3;

export const BAR_CHART_CONSTS = {
  MAX_ITEMS_PER_LEGEND: 3,
  MAX_LEGEND_ITEM_TEXT_LENGTH: 24,
  LEGEND_ITEM_WIDTH: 180,
};
