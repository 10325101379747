export const validEmailPattern = /^[a-z0-9._%+\-'"]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
export const linkedinUrlPattern = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/[A-z0-9_-]+\/?/;

/**
 * Validates whether a given string matches a predefined email pattern. This function checks the string against
 * a regular expression designed to match most commonly accepted email formats.
 *
 * @param value The string to be validated as an email address.
 * @returns Returns `true` if the value matches the email pattern, otherwise `false`.
 */
export const validateEmailPattern = (value: string): boolean => validEmailPattern.test(value);
