/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  checkSPIntegrationStatus,
  dismissNewAddedUsers,
  getNewAddedUsers,
  getMembers,
  startSPIntegration,
  validateSPFolders,
  validateSPSecrets,
  getIntegrationData,
  sharepointSync,
  getLastSharePointSync,
} from 'redux-toolkit/thunks/sharePointThunks';
import {
  StepsAsyncResultsMap,
  SharePointMembersAndAccounts,
  ValidateSPFoldersPayload,
  ValidateSPFoldersResponse,
  ValidateSPSecretsPayload,
  ValidateSPSecretsResponse,
  StartSPIntegrationResponse,
  BaseIntegrationResponse,
  IntegrationDataResponse,
  SharePointAddedUser,
  LastSyncResponseType,
} from 'utils/types/sharepoint/sharepoint';
import { SharePointWizardStepKey, SiteSchema } from '_components/SharePointWizard/types';
import { JOB_STATUS, RESULT_STATUS } from 'utils/enums';
import { ToastProps } from 'utils/types/types';

interface SharePointState {
  isWizardOpen: boolean;
  validationLoading: boolean;
  stepsAsyncResults: StepsAsyncResultsMap;
  stepsAsyncErrors: Partial<Record<SharePointWizardStepKey, string>>;
  isSPIntegrationInProgress: boolean;
  spJobStatus?: JOB_STATUS;
  members: SharePointMembersAndAccounts;
  membersLoading: boolean;
  sitesValidation: Partial<SiteSchema>[] | undefined;
  integrationDataLoading: boolean;
  integrationData: IntegrationDataResponse;
  integrationDataToastProps: ToastProps;
  newAddedUsersLoading: boolean;
  newAddedUsers: SharePointAddedUser[];
  isNewAddedUsersDismissed: boolean;
  isSyncLoading: boolean;
  isSyncSuccess: boolean;
  lastSync: Date | undefined;
  isLastSyncLoading: boolean;
}

const initialState: SharePointState = {
  isWizardOpen: false,
  validationLoading: false,
  stepsAsyncResults: {
    [SharePointWizardStepKey.ALLOW_ACCESS]: false,
    [SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS]: false,
    [SharePointWizardStepKey.ADD_USERS]: false,
  },
  stepsAsyncErrors: {},
  isSPIntegrationInProgress: false,
  spJobStatus: JOB_STATUS.BLANK,
  members: {
    existingUsers: [],
    newMembers: [],
  },
  membersLoading: false,
  sitesValidation: [],
  integrationDataLoading: false,
  integrationData: { secrets: { applicationId: '', directoryId: '', clientSecret: '' }, sites: [] },
  integrationDataToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  newAddedUsersLoading: false,
  newAddedUsers: [],
  isNewAddedUsersDismissed: false,
  isSyncLoading: false,
  isSyncSuccess: false,
  lastSync: undefined,
  isLastSyncLoading: false,
};

const sharePointSlice = createSlice({
  name: 'sharePoint',
  initialState,
  reducers: {
    resetEntireState: () => {
      return {
        ...initialState,
      };
    },
    setIsWizardOpen: (state, action: PayloadAction<boolean>) => {
      state.isWizardOpen = action.payload;
    },
    resetStepAsyncResults: (state, action: PayloadAction<SharePointWizardStepKey[]>) => {
      action.payload.forEach((stepId) => {
        state.stepsAsyncResults[stepId] = false;
      });
    },
    setIntegrationDataToastProps(state, action: PayloadAction<ToastProps>) {
      state.integrationDataToastProps = action.payload;
    },
  },
  extraReducers: (builder) => {
    // validateSPSecrets
    builder.addCase(validateSPSecrets.pending, (state) => {
      state.stepsAsyncErrors = {};
      state.validationLoading = true;
    });
    builder.addCase(validateSPSecrets.fulfilled, (state, action: PayloadAction<ValidateSPSecretsResponse | null, string, { arg: ValidateSPSecretsPayload }>) => {
      const { stepId } = action.meta.arg;

      if (action.payload?.isValid) {
        state.stepsAsyncResults[stepId] = true;
      } else {
        state.stepsAsyncErrors[stepId] = action.payload?.message ?? 'Validation failed';
      }
      state.validationLoading = false;
    });
    builder.addCase(validateSPSecrets.rejected, (state, action: PayloadAction<unknown, string, { arg: ValidateSPSecretsPayload }, any>) => {
      const { stepId } = action.meta.arg;
      state.stepsAsyncErrors[stepId] = action.error?.message ?? 'Validation failed';
      state.validationLoading = false;
    });

    // validateSPFolders
    builder.addCase(validateSPFolders.pending, (state) => {
      state.stepsAsyncErrors = {};
      state.validationLoading = true;
      state.sitesValidation = []; // Reset sites validation
    });
    builder.addCase(validateSPFolders.fulfilled, (state, action: PayloadAction<ValidateSPFoldersResponse | null, string, { arg: ValidateSPFoldersPayload }>) => {
      const { stepId } = action.meta.arg;
      if (action.payload?.isValid) {
        state.stepsAsyncResults[stepId] = true;
      } else {
        state.stepsAsyncErrors[stepId] = 'Validation failed';
        state.sitesValidation = action.payload?.sites;
      }
      state.validationLoading = false;
    });
    builder.addCase(validateSPFolders.rejected, (state, action: PayloadAction<any, string, { arg: ValidateSPFoldersPayload }, any>) => {
      // TODO: Fix any
      const { stepId } = action.meta.arg;
      state.stepsAsyncErrors[stepId] = action.error?.message ?? 'Validation failed';
      state.sitesValidation = action.payload?.sites;
      state.validationLoading = false;
    });

    // getMembers
    builder.addCase(getMembers.pending, (state) => {
      state.stepsAsyncErrors = {};
      state.membersLoading = true;
    });
    builder.addCase(getMembers.fulfilled, (state, action: PayloadAction<SharePointMembersAndAccounts | null, string, { arg: ValidateSPFoldersPayload }>) => {
      const { stepId } = action.meta.arg;
      state.members.existingUsers = action.payload?.existingUsers ?? [];
      state.members.newMembers = action.payload?.newMembers ?? [];
      state.stepsAsyncResults[stepId] = true;
      state.membersLoading = false;
    });
    builder.addCase(getMembers.rejected, (state, action: PayloadAction<unknown, string, { arg: ValidateSPFoldersPayload }, any>) => {
      const { stepId } = action.meta.arg;
      state.stepsAsyncErrors[stepId] = action.error?.message ?? 'Could not find members';
      state.membersLoading = false;
    });

    // startSPIntegration
    builder.addCase(startSPIntegration.pending, (state) => {
      state.isSPIntegrationInProgress = true;
    });
    builder.addCase(startSPIntegration.fulfilled, (state, { payload }: PayloadAction<StartSPIntegrationResponse | null>) => {
      if (!payload) return;
      const { jobStatus } = payload;

      state.spJobStatus = jobStatus;
      if ([JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(jobStatus)) {
        state.isSPIntegrationInProgress = false;
      }
    });
    builder.addCase(startSPIntegration.rejected, (state) => {
      state.isSPIntegrationInProgress = false;
    });

    // checkSPIntegrationStatus
    builder.addCase(checkSPIntegrationStatus.pending, (state) => {
      state.isSPIntegrationInProgress = true;
    });
    builder.addCase(checkSPIntegrationStatus.fulfilled, (state, { payload }: PayloadAction<BaseIntegrationResponse | null>) => {
      if (!payload) return;
      const { jobStatus } = payload;

      if ([JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(jobStatus)) {
        state.isSPIntegrationInProgress = false;
      }
      state.spJobStatus = jobStatus;
    });
    builder.addCase(checkSPIntegrationStatus.rejected, (state) => {
      state.isSPIntegrationInProgress = false;
    });

    // getIntegrationData
    builder.addCase(getIntegrationData.pending, (state) => {
      state.integrationDataLoading = true;
    });
    builder.addCase(getIntegrationData.fulfilled, (state, { payload }: PayloadAction<IntegrationDataResponse | null>) => {
      if (payload) {
        state.integrationData = payload;
      }
      state.integrationDataLoading = false;
    });
    builder.addCase(getIntegrationData.rejected, (state) => {
      state.integrationDataLoading = false;
    });

    // getNewAddedUsers
    builder.addCase(getNewAddedUsers.pending, (state) => {
      state.isNewAddedUsersDismissed = false;
      state.newAddedUsersLoading = true;
    });
    builder.addCase(getNewAddedUsers.fulfilled, (state, { payload }: PayloadAction<SharePointAddedUser[] | null>) => {
      if (!payload) return;

      state.newAddedUsers = payload ?? [];
      state.newAddedUsersLoading = false;
    });
    builder.addCase(getNewAddedUsers.rejected, (state) => {
      state.newAddedUsersLoading = false;
    });

    // dismissNewAddedUsers
    builder.addCase(dismissNewAddedUsers.pending, (state) => {
      state.isNewAddedUsersDismissed = true;
    });

    // sharepointSync
    builder.addCase(sharepointSync.pending, (state) => {
      state.isSyncLoading = true;
      state.isSyncSuccess = false;
    });
    builder.addCase(sharepointSync.fulfilled, (state, { payload }: PayloadAction<LastSyncResponseType>) => {
      if (!payload) return;

      state.isSyncLoading = false;
      state.isSyncSuccess = true;

      state.lastSync = payload.lastSync;
    });
    builder.addCase(sharepointSync.rejected, (state) => {
      state.isSyncLoading = false;
      state.isSyncSuccess = false;
    });

    // getLastSharePointSync
    builder.addCase(getLastSharePointSync.pending, (state) => {
      state.isLastSyncLoading = true;
    });
    builder.addCase(getLastSharePointSync.fulfilled, (state, { payload }: PayloadAction<LastSyncResponseType>) => {
      if (!payload) return;

      state.lastSync = payload.lastSync;
      state.isLastSyncLoading = false;
    });
    builder.addCase(getLastSharePointSync.rejected, (state) => {
      state.isLastSyncLoading = false;
    });
  },
});

export const sharePointActions = sharePointSlice.actions;

export default sharePointSlice.reducer;
