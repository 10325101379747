import React, { useState, useEffect, useCallback } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, batch, useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TangiTypography, TangiSearch, TangiButton, TangiSvgIcon } from '_components/TangiLibrary';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { Partnership, Partnerships } from '../../utils/types/partnership/partnership';
import { NonNullablePartnerships, TOGGLE_KEYS } from './types';
import { RootState } from '_helpers';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { filterPartnershipsByName } from './utils';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { ReactComponent as PartnershipIcon } from '../../assets/tangiIcons/partnership.svg';
import {
  DrawerTopContainer,
  AddPartner,
  PartnershipAvatarContainer,
  PartnersScrollContainer,
  DrawerExplanatoryContainer,
  PartnershipIconContainer,
  CreateButtonContainer,
  StyledList,
  NoSearchResultsContainer,
  FlexContainerGap1,
  EmptyStateContainer,
  partnerSideMenuToggledStyles,
  partnerSideMenuDrawerStyles,
  sidebarMenuStyles,
  submenuStyles,
  menuItemStyles,
} from './style';
import { IconAndTextSkeleton } from '_components/TangiLibrary/TangiIconAndTextSkeleton/IconAndTextSkeleton';

interface IPartnerSideMenuDrawer {
  toggled: boolean;
  onToggle: () => void;
  partnerships: Partnerships | null;
  onPartnershipClick?: () => void;
}

const PartnerSideMenuDrawer = ({ toggled, onToggle, partnerships, onPartnershipClick }: IPartnerSideMenuDrawer) => {
  const { activeClient } = useSelector((state: RootState) => state.lawfirm);

  const loading = useSelector((state: RootState) => state.partnership.loadingPartnerships);
  const [localPartnerships, setLocalPartnerships] = useState<NonNullablePartnerships>({
    active: partnerships?.active ?? [],
    disabled: partnerships?.disabled ?? [],
  });

  useEffect(() => {
    if (!partnerships || (partnerships?.active?.length === 0 && partnerships?.disabled?.length === 0)) return;
    setLocalPartnerships({
      active: partnerships?.active ?? [],
      disabled: partnerships?.disabled ?? [],
    });
  }, [partnerships]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [isNoSearchResult, setIsNoSearchResult] = useState<boolean>(false);

  const [activeToggleKey, setActiveToggleKey] = useState<TOGGLE_KEYS>(TOGGLE_KEYS.ACTIVE);

  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAddPartner = () => {
    batch(() => {
      dispatch(partnershipActions.changeAddModalVisibility(true));
      dispatch(partnershipActions.toggleDrawer(false));
    });
  };

  const resetLocalPartnerships = () => {
    setLocalPartnerships({
      active: partnerships?.active ?? [],
      disabled: partnerships?.disabled ?? [],
    });
  };

  const resetState = () => {
    setSearchValue('');
    setIsNoSearchResult(false);
    setActiveToggleKey(TOGGLE_KEYS.ACTIVE);
  };

  const updateLocalPartnerships = (filteredActivePartnerships: Partnership[], filteredDisabledPartnerships: Partnership[]) => {
    const noResults = !Boolean(filteredActivePartnerships?.length) && !Boolean(filteredDisabledPartnerships?.length);
    setIsNoSearchResult(noResults);
    setLocalPartnerships({ active: filteredActivePartnerships, disabled: filteredDisabledPartnerships });
  };

  const handleSearchPartner = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      if (isEmpty(value)) {
        resetLocalPartnerships();
        setIsNoSearchResult(false);
        setActiveToggleKey(TOGGLE_KEYS.ACTIVE);
      } else {
        const filteredActivePartnerships = !!partnerships?.active?.length ? filterPartnershipsByName(partnerships.active, value) : [];
        const filteredDisabledPartnerships = !!partnerships?.disabled?.length ? filterPartnershipsByName(partnerships.disabled, value) : [];
        updateLocalPartnerships(filteredActivePartnerships, filteredDisabledPartnerships);
      }
    }, 300),
    [partnerships, debounce],
  );

  const handleToggleClick = (key: TOGGLE_KEYS) => {
    setActiveToggleKey((prev) => (prev === key ? TOGGLE_KEYS.NOT_SELECTED : key));
  };

  const renderPartnerExplanation = (): JSX.Element => {
    return (
      <>
        <DrawerExplanatoryContainer>
          <TangiTypography color={NEUTRAL_SHADES[1100]} type="heading-md" weight="semibold">
            {t('SIDE_BAR.PARTNER_DRAWER.PARTNERS')}
          </TangiTypography>
          <PartnershipIconContainer>
            <PartnershipIcon />
          </PartnershipIconContainer>
          <StyledList>
            <li>
              <TangiTypography>{t('SIDE_BAR.PARTNER_DRAWER.AI_TECHNOLOGY')}</TangiTypography>
            </li>
            <li>
              <TangiTypography>{t('SIDE_BAR.PARTNER_DRAWER.DATA_AND_MANAGEMENT')}</TangiTypography>
            </li>
            <li>
              <TangiTypography>{t('SIDE_BAR.PARTNER_DRAWER.ENSURE_AGREEMENTS')}</TangiTypography>
            </li>
            <li>
              <TangiTypography>{t('SIDE_BAR.PARTNER_DRAWER.NO_CONFUSION')}</TangiTypography>
            </li>
          </StyledList>
        </DrawerExplanatoryContainer>
        <CreateButtonContainer>
          <TangiButton text="Create Partner" size="small" onClick={handleAddPartner} data-testid="create-partnership" />
        </CreateButtonContainer>
      </>
    );
  };

  const renderNoSearchResults = (): JSX.Element => {
    return (
      <NoSearchResultsContainer>
        <TangiTypography weight="semibold">{t('SIDE_BAR.PARTNER_DRAWER.PARTNER_NOT_FOUND')}</TangiTypography>
        <TangiTypography color={NEUTRAL_SHADES[1000]}>{t('SIDE_BAR.PARTNER_DRAWER.TRY_ANOTHER_NAME')}</TangiTypography>
      </NoSearchResultsContainer>
    );
  };

  const renderDrawerHeader = (): JSX.Element => {
    return (
      <DrawerTopContainer>
        <TangiTypography color={NEUTRAL_SHADES[1100]} type="heading-md" weight="semibold">
          {t('SIDE_BAR.PARTNER_DRAWER.PARTNERS')}
        </TangiTypography>
        <AddPartner onClick={handleAddPartner} data-testid="add-partnership">
          <TangiSvgIcon component="add" />
          <TangiTypography type="button-sm">{t('SIDE_BAR.PARTNER_DRAWER.ADD_PARTNER')}</TangiTypography>
        </AddPartner>
        <TangiSearch
          placeholder={t('SIDE_BAR.PARTNER_DRAWER.SEARCH_BY_NAME')}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearchPartner}
          onChange={handleSearchPartner}
          disabled={false}
          width="190px"
        />
      </DrawerTopContainer>
    );
  };

  // TODO: change the links to be generated by the generateRoute function
  const renderPartnershipPageLink = (partnershipId: string) => {
    return <Link to={`/client/${clientId ?? activeClient?.id}/partners/${partnershipId}`} />;
  };

  const renderPartnerMenuItem = (partnership: Partnership): React.ReactNode => {
    return (
      <MenuItem onClick={onPartnershipClick} component={renderPartnershipPageLink(partnership._id)} rootStyles={menuItemStyles}>
        <PartnershipAvatarContainer data-testid="partnership-avatar-container">
          <PartnershipOrClientLogo isPartnership={true} name={partnership.name} logo={partnership.logo} size={partnership?.logo && AVATAR_SIZES.XS} />
          <TangiTypography>{partnership.name}</TangiTypography>
        </PartnershipAvatarContainer>
      </MenuItem>
    );
  };

  const renderActivePartnershipsSubMenu = (): JSX.Element => {
    return (
      <>
        <MenuItem onClick={() => handleToggleClick(TOGGLE_KEYS.ACTIVE)} rootStyles={submenuStyles}>
          <FlexContainerGap1>
            <TangiTypography>{t('SIDE_BAR.PARTNER_DRAWER.ENABLED')}</TangiTypography>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{`• ${localPartnerships?.active?.length || 0}`}</TangiTypography>
          </FlexContainerGap1>
          <TangiSvgIcon component="arrowDown" className={activeToggleKey === TOGGLE_KEYS.ACTIVE ? 'active' : ''} />
        </MenuItem>
        <Collapse in={activeToggleKey === TOGGLE_KEYS.ACTIVE}>
          {!!localPartnerships?.active?.length ? (
            <PartnersScrollContainer>
              {localPartnerships.active.map((partnership: Partnership) => (
                <React.Fragment key={partnership._id}>{renderPartnerMenuItem(partnership)}</React.Fragment>
              ))}
            </PartnersScrollContainer>
          ) : (
            <EmptyStateContainer>
              <TangiTypography color={NEUTRAL_SHADES[1000]}>{t('SIDE_BAR.PARTNER_DRAWER.NO_ACTIVE_PARTNERS_TO_SHOW')}</TangiTypography>
            </EmptyStateContainer>
          )}
        </Collapse>
      </>
    );
  };

  const renderDisabledPartnershipsSubMenu = (): JSX.Element => {
    return (
      <>
        <MenuItem onClick={() => handleToggleClick(TOGGLE_KEYS.DISABLED)} rootStyles={submenuStyles}>
          <FlexContainerGap1>
            <TangiTypography>{t('SIDE_BAR.PARTNER_DRAWER.DISABLED')}</TangiTypography>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{`• ${localPartnerships?.disabled?.length || 0}`}</TangiTypography>
          </FlexContainerGap1>
          <TangiSvgIcon component="arrowDown" className={activeToggleKey === TOGGLE_KEYS.DISABLED ? 'active' : ''} />
        </MenuItem>
        <Collapse in={activeToggleKey === TOGGLE_KEYS.DISABLED}>
          {!!localPartnerships?.disabled?.length ? (
            <PartnersScrollContainer>
              {localPartnerships.disabled.map((partnership: Partnership) => (
                <React.Fragment key={partnership._id}>{renderPartnerMenuItem(partnership)}</React.Fragment>
              ))}
            </PartnersScrollContainer>
          ) : (
            <EmptyStateContainer>
              <TangiTypography color={NEUTRAL_SHADES[1000]}>{t('SIDE_BAR.PARTNER_DRAWER.NO_DISABLED_PARTNERS_TO_SHOW')}</TangiTypography>
            </EmptyStateContainer>
          )}
        </Collapse>
      </>
    );
  };

  useEffect(() => {
    if (!toggled) {
      resetState();
    } else {
      resetLocalPartnerships();
    }
  }, [toggled]);

  return (
    <Sidebar
      data-testid="partner-sidebar-drawer"
      className="partner-sidebar-drawer"
      onBackdropClick={onToggle}
      toggled={toggled}
      width="232px"
      breakPoint="all"
      backgroundColor={PRIMARY_SHADES[50]}
      rootStyles={partnerSideMenuDrawerStyles}
      style={partnerSideMenuToggledStyles(toggled)}
    >
      <Menu
        rootStyles={sidebarMenuStyles}
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active ? PRIMARY_SHADES[100] : undefined,
          }),
        }}
      >
        {loading ? (
          (renderDrawerHeader(), IconAndTextSkeleton({}))
        ) : !!partnerships?.active.length || !!partnerships?.disabled.length ? (
          <>
            {renderDrawerHeader()}
            {isNoSearchResult ? (
              renderNoSearchResults()
            ) : (
              <>
                {renderActivePartnershipsSubMenu()}
                {renderDisabledPartnershipsSubMenu()}
              </>
            )}
          </>
        ) : (
          renderPartnerExplanation()
        )}
      </Menu>
    </Sidebar>
  );
};

export default PartnerSideMenuDrawer;
