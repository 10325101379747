import * as Sentry from '@sentry/react';
import { useContext, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';

import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { AuthContext } from '_hocs/Cognito/Auth';
import { LogoutType } from '_services/utils/MixPanel/types';

export function useAxiosInterceptor() {
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    _requestListener();
    _responseListener();
  }, []);

  const _requestListener = () => {
    axios.interceptors.request.use((config) => {
      try {
        const newToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);
        if (newToken) {
          config.headers.Authorization = `Bearer ${newToken}`;
        }
      } catch (error) {
        console.error(error);
      }
      return config;
    });
  };

  const _responseListener = () => {
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        Sentry.captureException(error);
        if (error?.response?.status === 401) {
          logout({ logoutType: LogoutType.Automatic });
        }
        return Promise.reject(error);
      },
    );
  };
}
