import styled from 'styled-components';

import watermark from 'assets/img/watermarkInline.png';

export const Container = styled.div`
  display: flex;
`;

export const AssetContainer = styled.div`
  flex: 1;
`;

export const SideMenu = styled.div`
  width: 300px;
  padding-inline: 16px;
  height: fit-content;
  margin-left: 20px;
  margin-top: 40px;
`;

export const TypeAndColor = styled.div`
  display: flex;
  gap: 16px;
  font-size: 16px;
  align-items: center;
  margin-top: 8px;
`;

export const WatermarkContainer = styled.div<{ clientName: string }>`
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    background-image: url(${watermark});
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.4;
    width: 50%;
    height: 100%;
    z-index: 999;
    top: 5px;
    left: 10%;
  }

  &::after {
    font-family: 'BankGothic Md BT';
    position: absolute;
    display: flex;
    justify-content: center;
    content: 'PROPERTY OF ${({ clientName }) => clientName}';
    opacity: 0.4;
    font-size: 1em;
    top: 22px;
    left: 49%;
    width: 50%;
    height: 100%;
    z-index: 999;
    @media screen and (max-width: 1000px) {
      font-size: 0.9em;
      top: 20px;
    }
  }
`;
