export const adminUserContants = {
  ADMIN_USER_REQUEST: 'ADMIN_USER_REQUEST',
  ADMIN_USER_SUCCESS: 'ADMIN_USER_SUCCESS',
  ADMIN_USER_FAILURE: 'ADMIN_USER_FAILURE',

  CREATE_ADMIN_USER: 'CREATE_ADMIN_USER',
  CREATE_ADMIN_USER_SUCCESS: 'CREATE_ADMIN_USER_SUCCESS',
  CREATE_ADMIN_USER_FAILURE: 'CREATE_ADMIN_USER_FAILURE',

  UPDATE_ADMIN_USER: 'UPDATE_ADMIN_USER',
  UPDATE_ADMIN_USER_SUCCESS: 'UPDATE_ADMIN_USER_SUCCESS',
  UPDATE_ADMIN_USER_FAILURE: 'UPDATE_ADMIN_USER_FAILURE',

  ADMIN_USER_PROFILE_SUCCESS: 'ADMIN_USER_PROFILE_SUCCESS',
  ADMIN_USER_PROFILE_FAILURE: 'ADMIN_USER_PROFILE_FAILURE',

  CLEAR_ADMIN_USER_ERRORS: 'CLEAR_ADMIN_USER_ERRORS',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  ADMIN_RESET_PASSWORD_REQUEST: 'ADMIN_RESET_PASSWORD_REQUEST',
  ADMIN_RESET_PASSWORD_SUCCESS: 'ADMIN_RESET_PASSWORD_SUCCESS',
  ADMIN_RESET_PASSWORD_FAILURE: 'ADMIN_RESET_PASSWORD_FAILURE',
  CLEAR_ADMIN_RESET_PASSWORD_REQUEST: 'CLEAR_ADMIN_RESET_PASSWORD_REQUEST',

  GET_USERS_BY_ID_REQUEST: 'GET_USERS_BY_ID_REQUEST',
  GET_USERS_BY_ID_SUCCESS: 'GET_USERS_BY_ID_SUCCESS',
  GET_USERS_BY_ID_FAILURE: 'GET_USERS_BY_ID_FAILURE',
};
