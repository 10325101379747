import styled from 'styled-components';

import { FlexSpan } from '_components/ActivityLog/style';
import { Avatar } from '_components/Avatar';
import { TangiTypography } from '_components/TangiLibrary';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Recipient } from 'utils/types/assets/assets';
import { SPACING } from 'utils/theme';

interface Props {
  people: Recipient[][];
  marginTop?: string;
}

const Container = styled.div`
  padding: ${SPACING[0]} ${SPACING[7]} ${SPACING[0]} ${SPACING[3.5]};
`;

export const AssetActivityNotifyContent = ({ people, marginTop }: Props) => {
  const renderByAsAccount = (personAccount: Recipient) => {
    return (
      <FlexSpan key={personAccount._id} marginTop={marginTop}>
        <Avatar size={AVATAR_SIZES.XS} name={personAccount?.fullName || personAccount.email || ''} email={personAccount.email || ''} />
        <TangiTypography type="subheading" weight="bold">
          {personAccount?.fullName || personAccount.email}
        </TangiTypography>
      </FlexSpan>
    );
  };

  return <Container style={{ marginTop }}>{people.flatMap((personArray: Recipient[]) => personArray.map((person: Recipient) => renderByAsAccount(person)))}</Container>;
};
