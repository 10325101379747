import styled from 'styled-components';

import { SPACING, NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const DrawerTopContainer = styled.div`
  padding: ${SPACING[0]} ${SPACING[3]} ${SPACING[3]};
  border-bottom: 1px solid ${NEUTRAL_SHADES[400]};
  .tangi-typography:first-of-type {
    margin-bottom: ${SPACING[3]};
  }
  .tangi-search-container {
    height: ${SPACING[5]};
    svg.tangi-search-icon {
      left: ${SPACING[3]};
      path {
        fill: ${NEUTRAL_SHADES.BLACK};
      }
    }
    &:hover {
      .tangi-search-input:not(:focus):not(:disabled) {
        background: ${PRIMARY_SHADES[200]};
        &::placeholder {
          color: ${PRIMARY_SHADES[800]} !important;
        }
      }
      svg.tangi-search-icon:not(.active) path {
        fill: ${PRIMARY_SHADES[800]};
      }
    }
  }
  .tangi-search-input {
    &:placeholder-shown {
      border: 1px solid ${PRIMARY_SHADES[50]};
      background: none;
    }
    &:not(:placeholder-shown) {
      border: 1px solid ${PRIMARY_SHADES[800]};
      background: ${NEUTRAL_SHADES.WHITE};
    }
    padding-left: ${SPACING[6]};
    height: 34px;
    &::placeholder {
      color: ${NEUTRAL_SHADES.BLACK} !important;
    }
    &:focus {
      border-color: ${PRIMARY_SHADES[800]};
      background: ${NEUTRAL_SHADES.WHITE};
      &::placeholder {
        color: ${NEUTRAL_SHADES[700]} !important;
      }
    }
    &:hover:not(:focus):not(:disabled) {
      background: ${PRIMARY_SHADES[200]};
      border-color: ${PRIMARY_SHADES[50]};
      &::placeholder {
        color: ${PRIMARY_SHADES[800]} !important;
      }
    }
  }
`;

export const AddPartner = styled.div`
  max-width: fit-content;
  display: flex;
  gap: 12px;
  border-radius: ${SPACING[1]};
  height: ${SPACING[5]};
  padding-inline: ${SPACING[3]};
  align-items: center;
  cursor: pointer;
  margin-bottom: ${SPACING[2]};
  &:hover {
    background: ${PRIMARY_SHADES[200]};
    .tangi-typography {
      color: ${PRIMARY_SHADES[800]};
    }
    svg.tangi-icon-svg path {
      fill: ${PRIMARY_SHADES[800]};
    }
  }
`;

export const PartnersScrollContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 368px);
  margin-right: ${SPACING[1]};
  padding-top: ${SPACING[2]};
  ::-webkit-scrollbar {
    width: ${SPACING[1]};
  }
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 6px;
    margin-block: ${SPACING[1]};
  }
  ::-webkit-scrollbar-thumb {
    background: #c5c5c5;
    border-radius: 6px;
  }
`;

export const PartnershipAvatarContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${SPACING[2]};
  .tangi-typography {
    text-overflow: ellipsis;
    width: 152px;
    white-space: nowrap;
    overflow: hidden;
  }
  height: ${SPACING[6]};
  cursor: pointer;
`;

export const PartnershipIconContainer = styled.div`
  width: 92px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 76px;
    height: 68px;
    path {
      fill: ${PRIMARY_SHADES[300]};
    }
  }
`;

export const DrawerExplanatoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  padding-inline: ${SPACING[3]};
  margin-bottom: ${SPACING[4]};
`;

export const CreateButtonContainer = styled.div`
  width: 58%;
  margin-left: ${SPACING[3]};
`;

export const StyledList = styled.ul`
  padding-inline: ${SPACING[0]} ${SPACING[2]};
  list-style-type: none;
  li {
    position: relative;
    padding-left: 12px;
    margin-bottom: 12px;
    &::before {
      content: '•';
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;

export const NoSearchResultsContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${SPACING[5]} auto ${SPACING[0]};
`;

export const EmptyStateContainer = styled(NoSearchResultsContainer)`
  margin: ${SPACING[2]} auto ${SPACING[2]};
  width: 50%;
`;

export const FlexContainerGap1 = styled(FlexContainer)`
  gap: ${SPACING[1]};
  align-items: center;
`;

export const partnerSideMenuToggledStyles = (toggled: boolean) => {
  return {
    left: toggled ? '226px' : `-${SPACING[10]}`,
    zIndex: 99,
  };
};

export const partnerSideMenuDrawerStyles = {
  paddingTop: '68px',
  borderRight: `1px solid ${NEUTRAL_SHADES[300]}`,
  '.ps-sidebar-backdrop': {
    background: 'none',
    cursor: 'default',
  },
};

export const sidebarMenuStyles = {
  paddingBlock: SPACING[3],
  maxHeight: 'calc(100vh - 68px)',
};

export const menuItemStyles = {
  ['.ps-menu-button']: {
    height: `${SPACING[6]} !important`,
    paddingLeft: `${SPACING[3]} !important`,
    marginBottom: `${SPACING[2]}`,
    '&:hover': {
      background: `${PRIMARY_SHADES[200]} !important`,
    },
  },
};

export const submenuStyles = {
  ['.ps-menu-button']: {
    height: `${SPACING[7]} !important`,
    paddingLeft: `${SPACING[3]} !important`,
    '&:hover': {
      background: `${PRIMARY_SHADES[200]} !important`,
      ['.tangi-icon-container.active, .tangi-icon-svg.active']: {
        background: `${PRIMARY_SHADES[200]} !important`,
      },
    },
  },
  ['.ps-menu-label']: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ['.tangi-icon-svg.active']: {
    transition: '0.3s ease all',
    transform: 'rotate(180deg)',
  },
  ['.tangi-icon-container.active, .tangi-icon-container.active']: {
    background: `${PRIMARY_SHADES[50]}`,
  },
};
