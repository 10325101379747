import React, { useState, KeyboardEvent } from 'react';

import { SearchContainer, StyledSearchInput, SearchIcon } from './style';

interface ITangiSearchProps {
  width?: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  handleSearch: (value: string) => void;
  placeholder?: string;
  onChange?: null | ((value: string) => void);
  disabled?: boolean;
}

export const TangiSearch = ({ handleSearch, searchValue, setSearchValue, width = '160px', placeholder = 'Search', onChange = null, disabled = false }: ITangiSearchProps) => {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const handleEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch(searchValue);
    }
  };

  const handleChange = (value: string) => {
    setSearchValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <SearchContainer className="tangi-search-container">
      <StyledSearchInput
        value={searchValue}
        data-testid="tangi-search"
        className="ff-din-regular tangi-search-input"
        tabIndex={0}
        onKeyDown={handleEnter}
        onChange={(event) => handleChange(event.target.value)}
        placeholder={placeholder}
        width={width}
        disabled={disabled}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
      />
      <SearchIcon className={`tangi-search-icon${+isInputFocused ? ' active' : ''}`} disabled={disabled} role="button" tabIndex={0} onClick={() => handleSearch(searchValue)} onKeyDown={handleEnter} />
    </SearchContainer>
  );
};
