import React from 'react';
import { RegistrationCard } from '../../_components/RegistrationCard/RegistrationCard';
import { ResetPasswordCognito } from '../../_components/ResetPasswordCognito/ResetPasswordCognito';
import './ResetPassword.scss';

function ResetPassword() {
  return (
    <RegistrationCard isLockGif={false} differentHigh={true}>
      <ResetPasswordCognito />
    </RegistrationCard>
  );
}

export { ResetPassword };
