import { useState, useMemo } from 'react';

import SharePointFormModal from '../components/SharePointFormModal/SharePointFormModal';
import { SharePointWizardContext } from './SharePointWizardContext';
import { SharePointWizardStep, SharePointWizardStepKey } from '../types';
import { sharePointWizardStepsConfig } from '../consts';
import { getInitialStepId } from '../utils/utils';
import { requiresAsyncValidation, requiresAdditionalAsyncAction } from '../utils/asyncActionsUtils';

interface Props {
  isEditMode: boolean;
  isWizardOpen: boolean;
  onCloseWizard: () => void;
}

export const SharePointWizardProvider = ({ isEditMode, isWizardOpen, onCloseWizard }: Props) => {
  const [currentStepId, setCurrentStepId] = useState<SharePointWizardStepKey>(getInitialStepId(isEditMode));

  // ------------------------------  STEPS ARRAY -----------------------------
  const spWizardSteps: SharePointWizardStep[] = useMemo(
    () =>
      Object.values(sharePointWizardStepsConfig).map((value, index) => ({
        id: value.id,
        component: value.component,
        index,
        requiresAsyncValidation: requiresAsyncValidation(value.id),
        requiresAdditionalAsyncAction: requiresAdditionalAsyncAction(value.id),
        stepRequiredFormFields: value.stepRequiredFormFields,
      })),
    [isEditMode],
  );
  // -------------------------------------------------------------------------

  // ---------------------------  NAVIGATIONAL DATA --------------------------
  const { currentStepIndex, currentStep, nextStep, isLastStep, isFirstStep, initialStepId } = useMemo(() => {
    const index = spWizardSteps.findIndex((step) => step.id === currentStepId);
    const initialStepId = getInitialStepId(isEditMode);
    return {
      currentStepIndex: index,
      currentStep: spWizardSteps[index],
      nextStep: spWizardSteps[index + 1],
      isLastStep: index === spWizardSteps.length - 1,
      isFirstStep: index === 0,
      initialStepId,
    };
  }, [currentStepId, spWizardSteps]);
  // -------------------------------------------------------------------------

  // ----------------------------  NAVIGATION UTILS --------------------------
  const goToNextStep = (stepId: SharePointWizardStepKey) => {
    setCurrentStepId(stepId);
  };

  const goToPreviousStep = () => {
    const previousStep = spWizardSteps[currentStepIndex - 1];
    if (previousStep) {
      setCurrentStepId(previousStep.id);
    }
  };
  // -------------------------------------------------------------------------

  return (
    <SharePointWizardContext.Provider
      value={{
        initialStepId,
        spWizardSteps,
        currentStepId,
        currentStepIndex,
        currentStep,
        isLastStep,
        isFirstStep,
        nextStep,
        goToNextStep,
        goToPreviousStep,
        setCurrentStepId,
        isEditMode,
      }}
    >
      <SharePointFormModal isWizardOpen={isWizardOpen} onCloseWizard={onCloseWizard} />
    </SharePointWizardContext.Provider>
  );
};
