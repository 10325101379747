// File: SharePointActivityHeader.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { Asset } from 'utils/types/assets/assets'; // Adjust the import path
import { formatDate } from 'utils/dateUtils'; // Import the formatDate function
import styled from 'styled-components';
import { SHARE_POINT } from 'utils/assetTypes';

interface SharePointActivityHeaderProps {
  asset: Asset;
}

const HeaderContainer = styled.div`
  padding: 26px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

const SharePointActivityHeader: React.FC<SharePointActivityHeaderProps> = ({ asset }) => {
  const { t } = useTranslation();

  const isSharePointAsset = asset.assetType.name === SHARE_POINT;

  if (!isSharePointAsset) {
    return null;
  }

  const lastUpdatedDate = asset.updatedAt ? formatDate(asset.updatedAt, 'DD.MMM.YYYY HH:mm') : '';

  return (
    <HeaderContainer>
      <TangiTypography color={NEUTRAL_SHADES[1100]}>{t('ACTIVITY_LOG.SHAREPOINT.HEADER_MESSAGE')}</TangiTypography>
      <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ACTIVITY_LOG.SHAREPOINT.LAST_UPDATED_ON', { DATE: lastUpdatedDate })}</TangiTypography>
    </HeaderContainer>
  );
};

export default SharePointActivityHeader;
