import { saveAs } from 'file-saver';

/**
 * Downloads a file from a base64 encoded string.
 * @async
 * @param {string} mimeType - The MIME type of the file.
 * @param {string} base64 - The base64 encoded string representing the file content.
 * @param {string} fileName - The name to be used for the downloaded file.
 * @returns {Promise<void>} - A Promise that resolves when the file is downloaded successfully.
 */
export const downloadFile = async (mimeType: string, base64: string, fileName: string) => {
  //  Decode the base64 data to create a Blob
  const decodedFileData = atob(base64);
  const byteArray = new Uint8Array(decodedFileData.length);
  for (let i = 0; i < decodedFileData.length; i++) {
    byteArray[i] = decodedFileData.charCodeAt(i);
  }
  const blob = new Blob([byteArray], { type: mimeType });

  saveAs(blob, fileName);
};