import { Dashboard } from 'pages/Dashboard';
import { ReactNode } from 'react';
import { Container, Content, IconContainer, InnerMainContainer, InsightsContainer, MainSection, StyledBetaChip, TextAndIconContainer } from './styles';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import Tippy from '@tippyjs/react';
import { PopupInfoWrapper } from '_components/PatentXRaySearch/style';
import { isFunction } from 'lodash';

interface Props {
  children: ReactNode;
  iconComponent: ReactNode;
  title: string;
  subTitle?: string;
  insights?: ReactNode;
  infoText?: string;
  showBetaChip?: boolean;
  onInfoClick?: () => void;
}

const XRayPageLayout = ({ iconComponent, title, subTitle, children, insights, infoText, showBetaChip = false, onInfoClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Dashboard title="">
      <Container>
        <MainSection>
          <InnerMainContainer>
            <IconContainer>
              {iconComponent}
              {showBetaChip && <StyledBetaChip />}
            </IconContainer>
            <TangiTypography weight="semibold" type="heading-xl" customStyles={`margin-bottom: ${SPACING[6]}`}>
              {title}
            </TangiTypography>
            <TextAndIconContainer>
              <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
                {subTitle}
              </TangiTypography>
              {isFunction(onInfoClick) ? (
                <PopupInfoWrapper onClick={onInfoClick}>
                  <TangiSvgIcon component="info" color={NEUTRAL_SHADES[800]} size="18px" />
                </PopupInfoWrapper>
              ) : !!infoText ? (
                <Tippy content={infoText} placement="right">
                  <span>
                    <TangiSvgIcon component="info" size="16px" />
                  </span>
                </Tippy>
              ) : null}
            </TextAndIconContainer>
            <Content>{children}</Content>
          </InnerMainContainer>
        </MainSection>
        {!!insights && (
          <InsightsContainer>
            <TangiTypography weight="semibold" type="heading-md">
              {t('DISCOVERY_LOBBY.TITLE.INSIGHTS')}
            </TangiTypography>
            {insights}
          </InsightsContainer>
        )}
      </Container>
    </Dashboard>
  );
};

export default XRayPageLayout;
