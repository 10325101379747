import { countries } from 'countries-list';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fileSizeValidator = (file: any) => {
  //TODO: fix any type
  const maxSize = 2000000;
  if (file.size > maxSize) {
    return {
      code: 'file-too-large',
      message: `File should be less than ${maxSize / 1000000} mb`,
    };
  }
  return null;
};

export const countriesOptions = () => {
  const formattedCountries = [];
  for (const [, value] of Object.entries(countries)) {
    formattedCountries.push({ value: value.name, label: value.name });
  }
  return formattedCountries;
};
