import { apiCall } from './utils/restClient';
import { config } from '../config/config';
import { headers, headersmultipart } from './utils/headers';

// TODO: old service created before redux toolkit, need to remove if not used

function createUser(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/invite`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function createMultipleUsers(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/inviteMultiple`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function validateEmployees(employees) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/validateEmployees`,
    method: 'POST',
    headers: headers(),
    data: employees,
  };
  return apiCall(requestOptions);
}
function createEmployees(employees) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/batchEmployees`,
    method: 'POST',
    headers: headers(),
    data: employees,
  };
  return apiCall(requestOptions);
}
function acceptInvitation(token) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/acceptInvite`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return apiCall(requestOptions);
}
function getRoles({ name }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/roles`,
    method: 'GET',
    headers: headers(),
    params: {
      name,
    },
  };

  return apiCall(requestOptions);
}

function uploadProfile(data, id) {
  var formData = new FormData();
  formData.append('file', data[0]);

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file/profile`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: { userId: id },
  };

  return apiCall(requestOptions);
}

function updateUser(data, id) {
  delete data.agreements;
  delete data.profile;
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/${id}`,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function sendAlias(accountId) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/aliasid/${accountId}`,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

const deleteAccount = (accountId) => {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/${accountId}`,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
};

const reactivateAccount = (accountId) => {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/reactivate/${accountId}`,
    method: 'POST',
    headers: headers(),
  };

  return apiCall(requestOptions);
};

const deactivateAccount = (accountId) => {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/deactivate/${accountId}`,
    method: 'POST',
    headers: headers(),
  };

  return apiCall(requestOptions);
};

function updateFullName(userId, fullName, token) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/user/fullname/${userId}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: { fullName },
  };
  return apiCall(requestOptions);
}

export const userService = {
  createUser,
  createMultipleUsers,
  getRoles,
  uploadProfile,
  updateUser,
  sendAlias,
  validateEmployees,
  createEmployees,
  deleteAccount,
  deactivateAccount,
  reactivateAccount,
  acceptInvitation,
  updateFullName,
};
