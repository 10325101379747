import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { SystemKey } from './types';
import { IpAuditBatchAssetData, IpAuditStepKey, KeyAssetsSystemMappedData } from '_components/IpAudit/types';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { useIpAuditPopup } from '../../context/IpAuditPopup.provider';
import { KeyAssetsSection } from './components/KeyAssetsSection';
import { isArraysEqual } from '_components/IpAudit/utils';
import { buildAssetName, buildInitialValues, getAssetsByKey } from './utils';
import { keysTagMap, keysTitleMap, noAssetSelection, systemKeys } from './consts';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';
import { StepContainer, StepInnerContainer, StepTextContainer } from '../styles';

export const KeyAssetsFromSystem = () => {
  const { t } = useTranslation();

  const { setHasStepChanges } = useIpAuditPopup();
  const { handleSubmit } = useForm();

  const isCreatingIpAuditAssets = useSelector((state: RootState) => state.asset.isCreatingIpAuditAssets);
  const isUpdatingNonSelection = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.KEY_ASSETS_SYSTEMS].isLoading);

  const { getStepCurrentData, submitKeyAssetsSystem } = useIpAuditContext();

  const stepData = getStepCurrentData(IpAuditStepKey.KEY_ASSETS_SYSTEMS);

  const initialValues = buildInitialValues(stepData);

  const [selectedOptions, setSelectedOptions] = useState<KeyAssetsSystemMappedData>(initialValues);

  const [isCollapsed, setIsCollapsed] = useState<Record<SystemKey, boolean>>(
    systemKeys.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as Record<SystemKey, boolean>),
  );

  useEffect(() => {
    const hasChanges = systemKeys.some((key) => !isArraysEqual(initialValues?.[key], selectedOptions[key]));
    setHasStepChanges(hasChanges);

    return () => setHasStepChanges(false);
  }, [selectedOptions]);

  const onSubmit = useCallback(() => {
    const data = Object.entries(selectedOptions).reduce<{ assetsData: IpAuditBatchAssetData; noneOfTheAboveArr: SystemKey[] }>(
      (acc, [key, assets]) => {
        const system = key as SystemKey;
        if (assets?.length && !assets.includes(noAssetSelection)) {
          acc.assetsData.systems.push(keysTagMap[system]);
          const parsedAssets = assets.filter((asset) => !initialValues[system].includes(asset)).map((asset) => ({ name: buildAssetName(system, asset), systemTag: keysTagMap[system] }));
          acc.assetsData.assets.push(...parsedAssets);
        } else if (assets?.length) {
          acc.noneOfTheAboveArr.push(system);
        }
        return acc;
      },
      { assetsData: { systems: [], assets: [] }, noneOfTheAboveArr: [] },
    );

    submitKeyAssetsSystem(data.assetsData, data.noneOfTheAboveArr);
    setHasStepChanges(false);
  }, [selectedOptions]);

  const handleOptionSelection = (key: SystemKey, option: string) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionsForKey = prevSelectedOptions[key];
      const isOptionExists = (option: string) => optionsForKey?.length && optionsForKey.includes(option);
      if (isOptionExists(noAssetSelection)) {
        return {
          ...prevSelectedOptions,
          [key]: [option],
        };
      }
      const isOptionSelected = isOptionExists(option);
      const newOptions = isOptionSelected ? optionsForKey?.length && optionsForKey.filter((item) => item !== option) : [...optionsForKey, option];
      return {
        ...prevSelectedOptions,
        [key]: newOptions,
      };
    });
  };

  const handleNoneOfTheAbove = (key: SystemKey) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionsForKey = prevSelectedOptions[key];
      const isNoneOfTheAboveSelected = optionsForKey.includes(noAssetSelection);
      return {
        ...prevSelectedOptions,
        [key]: isNoneOfTheAboveSelected ? [] : [noAssetSelection],
      };
    });
  };

  const toggleBox = (key: SystemKey) => {
    setIsCollapsed((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const isAllSystemsChecked = !systemKeys.some((system) => !selectedOptions[system].length);

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTextContainer>
          <StepTitle stepKey={IpAuditStepKey.KEY_ASSETS_SYSTEMS} />
          <TangiTypography>{t('IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.DESCRIPTION')}</TangiTypography>
          <TangiTypography>{t('IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.SELECTION')}</TangiTypography>
        </StepTextContainer>

        <form>
          {systemKeys.map((key) => (
            <KeyAssetsSection
              key={key}
              systemKey={key}
              isOptionSelected={!!selectedOptions[key]?.length}
              title={t(keysTitleMap[key])}
              isCollapsed={isCollapsed[key]}
              toggleBox={() => toggleBox(key)}
              options={getAssetsByKey(key)}
              selectedOptions={selectedOptions[key]}
              handleOptionSelection={(option) => handleOptionSelection(key, option)}
              handleNoneOfTheAbove={() => handleNoneOfTheAbove(key)}
              isNoneOfTheAboveSelected={selectedOptions[key].includes(noAssetSelection)}
              isDisableNonOfTheAbove={!!initialValues[key].length && !initialValues[key].includes(noAssetSelection)}
              dataCy={key}
            />
          ))}
        </form>
      </StepInnerContainer>

      <StepFooter
        onSubmit={handleSubmit(onSubmit)}
        isContinueBtnLoading={isCreatingIpAuditAssets || isUpdatingNonSelection}
        onContinueText={t('GENERAL.BUTTONS_TEXT.SAVE')}
        isContinueBtnDisabled={!isAllSystemsChecked}
      />
    </StepContainer>
  );
};
