import { Dropdown } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';

import { NEUTRAL_SHADES, SPACING, PRIMARY_SHADES } from 'utils/theme';

export const ClientDropdownButton = styled.button`
  box-sizing: border-box;
  outline: none;
  background: none;
  font-size: 14px;
  border-radius: ${SPACING[1]};
  border: 1px solid ${NEUTRAL_SHADES.BLACK};
  display: flex;
  height: ${SPACING[5]};
  align-items: center;
  gap: 10px;
  max-width: 240px;
  .tangi-icon-svg {
    width: 10px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    padding-block: ${SPACING[0]};
    right: ${SPACING[0]};
    top: 36px;
    max-height: 264px;
    overflow-y: auto;
    
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${NEUTRAL_SHADES.WHITE};
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${NEUTRAL_SHADES[600]};
      border-radius: 5px;
    }
`;

export const customToggleTypographyStyles = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DropdownItemContainer = styled(FlexContainer)`
  ${({ isActive = false, isEmptyState = false }: { isActive?: boolean; isEmptyState?: boolean }) => css`
    height: 44px;
    cursor: ${isEmptyState || isActive ? 'default' : 'pointer'};
    display: flex;
    align-items: center;
    background-color: ${isActive && PRIMARY_SHADES[200]};
    padding-inline: 10px;
    &:hover {
      background-color: ${!isEmptyState && !isActive && NEUTRAL_SHADES[100]};
    }
    max-width: 240px;
    .tangi-typography:first-of-type {
      margin-top: -2px;
      color: ${isActive && PRIMARY_SHADES[800]};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

export const DropdownLoaderContainer = styled(FlexContainer)`
  height: 88px;
  align-items: center;
  justify-content: center;
  .spinner-border {
    width: ${SPACING[6]};
    height: ${SPACING[6]};
  }
`;
