/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import React from 'react';
import { LeanAccount, LeanClient, NormalizedData } from 'utils/types/activityLog/asset/types';
import { TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Container, FlexDiv, FlexSpan } from '_components/ActivityLog/style';
import { useTranslation } from 'react-i18next';
import { NEUTRAL_SHADES } from 'utils/theme';
import { getDisplayName } from '_components/ActivityLog/utils';

interface AssetActivityDeleteNotifyLabelProps {
  event: NormalizedData;
  initiator: {
    account: LeanAccount | undefined;
    client: LeanClient;
  };
}

export const AssetActivityDeleteNotifyLabel = ({ event, initiator }: AssetActivityDeleteNotifyLabelProps) => {
  const { account, client } = initiator;
  const { t } = useTranslation();
  const displayText = getDisplayName(account, client);

  const renderByAsAccount = (personAccountArray: string[]) => {
    const name = personAccountArray[0];

    return (
      <FlexSpan>
        <Avatar size={AVATAR_SIZES.XS} name={name} email={name} />
        <TangiTypography type="subheading" weight="bold">
          {/* Renders the name with a possessive 's' using HTML entity for single quote. */}
          {name}&#39;s
        </TangiTypography>
        {t('ACTIVITY_LOG.ASSET.RECIPIENT_REMOVED')}
      </FlexSpan>
    );
  };

  const renderNotificationText = (event: any) => {
    return (
      <FlexSpan>
        <React.Fragment>{renderByAsAccount(event.from)}</React.Fragment>
      </FlexSpan>
    );
  };

  return (
    <Container>
      {event.data?.map((item: any, index: number) => (
        <FlexDiv key={index}>{renderNotificationText(item)}</FlexDiv>
      ))}
      <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
        {t('ACTIVITY_LOG.BY', { NAME: displayText })}
      </TangiTypography>
    </Container>
  );
};
