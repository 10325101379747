import { TangiCard } from '_components/TangiLibrary/TangiCard/TangiCard';
import styled from 'styled-components';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const OverViewCard = styled(TangiCard)`
  padding: ${SPACING[3]} ${SPACING[6]} ${SPACING[3]} ${SPACING[3]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    border: 1px solid ${PRIMARY_SHADES[800]};
  }
`;

export const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
`;

export const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2.5]};
`;

export const disableTypographyMargin = 'margin: 0px';
