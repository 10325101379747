import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { SPACING } from 'utils/theme';
import { ActivityGridContainer } from '../style';

const ActivitySkeleton = () => {
  return (
    <ActivityGridContainer>
      {Array.from(Array(10)).map((n, index) => (
        <React.Fragment key={index}>
          <Skeleton height={35} borderRadius={SPACING[2]} />
          <Skeleton height={40} circle={true} />
          <Skeleton height={76} borderRadius={SPACING[2]} />
        </React.Fragment>
      ))}
    </ActivityGridContainer>
  );
};

export default ActivitySkeleton;
