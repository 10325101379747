import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Row, Col, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './AdminAssets.scss';
import { AdminDashboard } from '../AdminDashboard';
import { AdminAssetsForm } from '../../../_components/Admin/AdminAssetsForm';
import { Loader } from '../../../_components/Loader';
import { adminAssetActions } from '../../../AdminRedux/_actions';
import { Table } from '../../../_components/Admin/DataTable/Table';

function AdminAssets({ match, history }) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const patharray = location.pathname.split('type=');
  const type = patharray && patharray[1] ? patharray[1] : '';
  const [isloading, setIsloading] = useState(false);
  const [totalRes, setTotalRes] = useState(0);
  const [viewResult, setViewResult] = useState({});
  const [inputs, setInputs] = useState({ searchTxt: '' });
  const [usernametxt, setusernametxt] = useState(false);
  const { searchTxt } = inputs;
  const [deleteId, setDeleteId] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const handleShow = (deletedata) => {
    setShow(true);
    setDeleteId(deletedata);
  };

  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.clientAdmin);

  const { data, metadata } = useSelector((state) => state.adminAssetReducer && state.adminAssetReducer.results);

  const { types, tags, business, viewTag, deleteTag, viewtype, deletetype, viewbv, deletebv, editTag, editbv, edittype } = useSelector((state) => state.adminAssetReducer);

  const [comments, setComments] = useState([]);

  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'Title', field: 'name', sortable: true },
    { name: 'Lawyer', field: 'lawyer', sortable: false },
    { name: 'Tags', field: 'createdAt', sortable: false },
    { name: 'Date', field: 'createdAt', sortable: true },
    { name: 'Actions', field: 'lawFirm', sortable: false },
  ];

  const Tableheaders = [
    { name: 'Name', field: 'name', sortable: true },
    { name: 'Description', field: 'description', sortable: true },
    { name: 'Slug', field: 'slug', sortable: true },
    { name: 'Count', field: 'lawFirm', sortable: false },
    { name: 'Actions' },
  ];

  useEffect(() => {
    setIsloading(true);
    if (type === 'type' || type.includes('type')) {
      dispatch(adminAssetActions.getAdminAssetType(1));
    } else if (type === 'tags' || type.includes('tags')) {
      dispatch(adminAssetActions.getAdminAssetTags(1));
    } else if (type === 'bv' || type.includes('bv')) {
      dispatch(adminAssetActions.getAdminAssetBusinessValue(1));
    } else {
      dispatch(adminAssetActions.getAdminAsset(1, ''));
    }
  }, [dispatch]);

  useEffect(() => {
    if (type === 'type' && types && types.data && types.data.length > 0) {
      setIsloading(false);
      setComments(types.data);
      setTotalRes(types.metadata && types.metadata[0].total);
    } else if (type === 'tags' && tags && tags.data && tags.data.length > 0) {
      setIsloading(false);
      setComments(tags.data);
      setTotalRes(tags.metadata && tags.metadata[0].total);
    } else if (type === 'bv' && business && business.data && business.data.length > 0) {
      setIsloading(false);
      setComments(business.data);
      setTotalRes(business.metadata && business.metadata[0].total);
    } else if (data && data.length > 0) {
      setIsloading(false);
      setComments(data);
    } else {
      setTotalRes(1);
      setTimeout(() => {
        setIsloading(false);
      }, 7000);
    }
  }, [data, business, tags, types]);

  const onPageChanged = (page) => {
    setIsloading(true);
    setCurrentPage(page.currentPage);
    if (type === 'tags') {
      dispatch(adminAssetActions.getAdminAssetTags(page.currentPage, searchTxt, sortBy));
    } else if (type === 'type') {
      dispatch(adminAssetActions.getAdminAssetType(page.currentPage, searchTxt, sortBy));
    } else if (type === 'status') {
      dispatch(adminAssetActions.getAdminAssetStatus(page.currentPage, searchTxt, sortBy));
    } else if (type === 'bv') {
      dispatch(adminAssetActions.getAdminAssetBusinessValue(page.currentPage, searchTxt, sortBy));
    } else {
      dispatch(adminAssetActions.getAdminAsset(page.currentPage, searchTxt, sortBy));
    }
  };

  useEffect(() => {
    if (match && match.params) {
      setIsloading(true);
      if ((match.params.type === 'edit' || match.params.type === 'view') && type.includes('tags')) {
        dispatch(adminAssetActions.getAdminAssetTagsView(match.params.firmid));
      } else if ((match.params.type === 'edit' || match.params.type === 'view') && type.includes('type')) {
        dispatch(adminAssetActions.getAdminAssetTypeView(match.params.firmid));
      } else if ((match.params.type === 'edit' || match.params.type === 'view') && type.includes('bv')) {
        dispatch(adminAssetActions.getAdminAssetBvView(match.params.firmid));
      }
    }
    // if (match && match.params && match.params.type && match.params.type.includes('delete')) {
    //   handleShow();
    // }
  }, [match]);

  const handleDelete = () => {
    setIsloading(true);
    if (type === 'type' || type.includes('type')) {
      dispatch(adminAssetActions.getAdminAssetTypeDelete(deleteId.id));
    } else if (type === 'tags' || type.includes('tags')) {
      dispatch(adminAssetActions.getAdminAssetTagsDelete(deleteId.id));
    } else if (type === 'bv' || type.includes('bv')) {
      dispatch(adminAssetActions.getAdminAssetBvDelete(deleteId.id));
    }
    setShow(false);
  };

  const handleClose = () => {
    // setIsloading(true);
    // if (type === 'type' || type.includes('type')) {
    //   history.push(`/admin/assets&type=type`);
    //   window.location.reload();
    // } else if (type === 'tags' || type.includes('tags')) {
    //   history.push(`/admin/assets&type=tags`);
    //   window.location.reload();
    // } else if (type === 'bv' || type.includes('bv')) {
    //   history.push(`/admin/assets&type=bv`);
    //   window.location.reload();
    // }
    setShow(false);
    setDeleteId({});
  };

  useEffect(() => {
    if (type.includes('tags') && viewTag) {
      setIsloading(false);
      setViewResult(viewTag);
    } else if (type.includes('type') && viewtype) {
      setIsloading(false);
      setViewResult(viewtype);
    } else if (type.includes('bv') && viewbv) {
      setIsloading(false);
      setViewResult(viewbv);
    }

    if ((deleteTag && deleteTag.message) || (editTag && editTag.id)) {
      setIsloading(false);
      history.push(`/admin/assets&type=tags`);
      window.location.reload();
    }
    if ((deletetype && deletetype.message) || (edittype && edittype.id)) {
      setIsloading(false);
      history.push(`/admin/assets&type=type`);
      window.location.reload();
    }
    if ((deletebv && deletebv.message) || (editbv && editbv.id)) {
      setIsloading(false);
      history.push(`/admin/assets&type=bv`);
      window.location.reload();
    }
  }, [viewTag, deleteTag, viewtype, deletetype, viewbv, deletebv, editTag, editbv, edittype]);

  const onEditAPi = () => {
    setIsloading(true);
  };

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'searchTxt' && !value) {
      if (type === 'type' || type.includes('type')) {
        dispatch(adminAssetActions.getAdminAssetType(1));
      } else if (type === 'tags' || type.includes('tags')) {
        dispatch(adminAssetActions.getAdminAssetTags(1));
      } else if (type === 'bv' || type.includes('bv')) {
        dispatch(adminAssetActions.getAdminAssetBusinessValue(1));
      } else {
        dispatch(adminAssetActions.getAdminAsset(1, ''));
      }
    } else {
      setusernametxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (!searchTxt) {
      setusernametxt(true);
    } else {
      setusernametxt(false);
      setComments([]);
      setIsloading(true);
      if (type === 'type' || type.includes('type')) {
        dispatch(adminAssetActions.getAdminAssetType(1, searchTxt));
      } else if (type === 'tags' || type.includes('tags')) {
        dispatch(adminAssetActions.getAdminAssetTags(1, searchTxt));
      } else if (type === 'bv' || type.includes('bv')) {
        dispatch(adminAssetActions.getAdminAssetBusinessValue(1, searchTxt));
      } else {
        dispatch(adminAssetActions.getAdminAsset(1, searchTxt));
      }
    }
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  let adminassets;
  if (!type) {
    adminassets = (
      <div>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Assets</h6>
            </li>
          </ul>
          <div className="navbar-brand mx-2">
            <Button className="m-1 p-2 rounded" href="assets&type=addnew" variant="outline-primary">
              Add New
            </Button>{' '}
          </div>
        </nav>
        <div className="bg-white border-0 card-header shadow-sm p-2 pl-2 rounded-3">
          <div className="row m-2">
            <div className="col-sm-8 col-md-8 col-lg-8">
              <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                <InputGroup className="mb-3">
                  <FormControl
                    className={usernametxt ? 'border border-invalid' : ''}
                    name="searchTxt"
                    onKeyUp={handleKeypress}
                    onChange={handleChange}
                    type="text"
                    placeholder="Search"
                    aria-label="searchTxt"
                    aria-describedby="basic-addon2"
                    value={searchTxt}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                      <i className="fas fa-search" aria-hidden="true" />
                    </button>
                  </InputGroup.Text>
                </InputGroup>
                <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
              </Form.Group>
            </div>
          </div>
          <Table
            currentPage={currentPage}
            ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            onPageChanged={onPageChanged}
            headers={headers}
            comments={comments}
            totalResults={metadata && metadata.length > 0 && metadata[0].total ? metadata[0].total : 0}
            type="asset"
          />
        </div>
      </div>
    );
  } else if (type === 'addnew') {
    adminassets = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Add New</h6>
            </li>
          </ul>
        </nav>
        <Col sm={12}>
          <AdminAssetsForm type="Add new item to Assets" />
        </Col>
      </Row>
    );
  } else if (type === 'tags' || type.includes('tags')) {
    adminassets = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Tags</h6>
            </li>
          </ul>
        </nav>
        {match.params.type && match.params.type !== 'delete' ? (
          <Row>
            <Col sm={10}>
              <AdminAssetsForm type={match.params.type === 'edit' ? 'Edit Tag' : 'View Tags'} onEditAPi={onEditAPi} viewResult={viewResult} firmid={match.params.firmid} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <AdminAssetsForm type="Add New Tag" />
            </Col>
            <Col sm={6}>
              <div className="row m-2">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                    <InputGroup className="mb-3">
                      <FormControl
                        className={usernametxt ? 'border border-invalid' : ''}
                        name="searchTxt"
                        onKeyUp={handleKeypress}
                        onChange={handleChange}
                        type="text"
                        placeholder="Search"
                        aria-label="searchTxt"
                        aria-describedby="basic-addon2"
                        value={searchTxt}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                          <i className="fas fa-search" aria-hidden="true" />
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
                  </Form.Group>
                </div>
              </div>
              <Table
                currentPage={currentPage}
                ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                onPageChanged={onPageChanged}
                headers={Tableheaders}
                comments={comments}
                totalResults={totalRes && totalRes ? totalRes : 0}
                param="/admin/assets&type=tags/"
                type="assettag"
                handleDeleteFun={handleShow}
              />
            </Col>
          </Row>
        )}
      </Row>
    );
  } else if (type === 'type' || type.includes('type')) {
    adminassets = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Type</h6>
            </li>
          </ul>
        </nav>
        {match.params.type && match.params.type !== 'delete' ? (
          <Row>
            <Col sm={10}>
              <AdminAssetsForm type={match.params.type === 'edit' ? 'Edit Type' : 'View Type'} onEditAPi={onEditAPi} viewResult={viewResult} firmid={match.params.firmid} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <AdminAssetsForm type="Add New Type" />
            </Col>
            <Col sm={6}>
              <div className="row m-2">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                    <InputGroup className="mb-3">
                      <FormControl
                        className={usernametxt ? 'border border-invalid' : ''}
                        name="searchTxt"
                        onKeyUp={handleKeypress}
                        onChange={handleChange}
                        type="text"
                        placeholder="Search"
                        aria-label="searchTxt"
                        aria-describedby="basic-addon2"
                        value={searchTxt}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                          <i className="fas fa-search" aria-hidden="true" />
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
                  </Form.Group>
                </div>
              </div>
              <Table
                currentPage={currentPage}
                ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                onPageChanged={onPageChanged}
                headers={Tableheaders}
                comments={comments}
                totalResults={totalRes && totalRes ? totalRes : 0}
                param="/admin/assets&type=type/"
                type="assettype"
                handleDeleteFun={handleShow}
              />
            </Col>
          </Row>
        )}
      </Row>
    );
  } else if (type === 'bv' || type.includes('bv')) {
    adminassets = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Business Value</h6>
            </li>
          </ul>
        </nav>
        {match.params.type && match.params.type !== 'delete' ? (
          <Row>
            <Col sm={10}>
              <AdminAssetsForm type={match.params.type === 'edit' ? 'Edit Business Value' : 'View Business Value'} onEditAPi={onEditAPi} viewResult={viewResult} firmid={match.params.firmid} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <AdminAssetsForm type="Add New Business Value" />
            </Col>
            <Col sm={6}>
              <div className="row m-2">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                    <InputGroup className="mb-3">
                      <FormControl
                        className={usernametxt ? 'border border-invalid' : ''}
                        name="searchTxt"
                        onKeyUp={handleKeypress}
                        onChange={handleChange}
                        type="text"
                        placeholder="Search"
                        aria-label="searchTxt"
                        aria-describedby="basic-addon2"
                        value={searchTxt}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                          <i className="fas fa-search" aria-hidden="true" />
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
                  </Form.Group>
                </div>
              </div>
              <Table
                currentPage={currentPage}
                ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                onPageChanged={onPageChanged}
                headers={Tableheaders}
                comments={comments}
                totalResults={totalRes && totalRes ? totalRes : 0}
                param="/admin/assets&type=bv/"
                type="bv"
                handleDeleteFun={handleShow}
              />
            </Col>
          </Row>
        )}
      </Row>
    );
  } else if (type === 'status') {
    adminassets = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Status</h6>
            </li>
          </ul>
        </nav>
        <Col sm={6}>
          <AdminAssetsForm type="Add new status" />
        </Col>
        <Col sm={6}>
          <Table
            currentPage={currentPage}
            ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            onPageChanged={onPageChanged}
            headers={Tableheaders}
            comments={comments}
            totalResults={totalRes && totalRes ? totalRes : 0}
            type="status"
          />
        </Col>
      </Row>
    );
  }
  return (
    <AdminDashboard title="Assets">
      <Loader isLoading={isloading} />
      <Container fluid className="pb-8 pt-3 pt-md-8">
        {adminassets}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>You want to delete: {deleteId ? deleteId.name : ''} </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
}

export { AdminAssets };
