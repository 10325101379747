import { headers, headersmultipart } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import {
  GetTradeSecretsFromPatent,
  GetPatentLastResult,
  GetInventorsAccounts,
  UploadPatentApplication,
  PatentStartJobResponse,
  PatentApplicationData,
  ContinuationAnalysisResponse,
  ContinuationAnalysisRequest,
  ContinuationAnalysisResultsResponse,
} from '../../utils/types/patent/patent';

class PatentAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;

  static patentsEndpoint = `${config.apiUrl}${config.apiVersion}/patents`;

  static patentServerEndPoint = `${config.patentUrl}`;

  async getPatentById({ patentId, clientId, patentModel }: GetTradeSecretsFromPatent): Promise<PatentStartJobResponse> {
    const res = await HttpClientService.post<PatentStartJobResponse>(`${PatentAdapter.patentsEndpoint}/get_patent_by_id`, { patentId, clientId, patentModel }, { headers: headers() });
    return res.data;
  }

  async getInventorsAccounts({ inventors, clientId }: GetInventorsAccounts) {
    const res = await HttpClientService.post(`${PatentAdapter.patentsEndpoint}/get_inventors_accounts`, { inventors, clientId }, { headers: headers() });
    return res.data;
  }

  async uploadPatentApplication({ clientId, files, patentModel }: UploadPatentApplication) {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('fileName', files[0].name);
    formData.append('clientId', clientId);
    if (patentModel) {
      formData.append('patentModel', patentModel);
    }

    const res = await HttpClientService.post(`${PatentAdapter.patentsEndpoint}/upload_patent_application`, formData, {
      headers: headersmultipart(),
    });
    return res.data;
  }

  async getPatentResults({ patentResultId, clientId }: GetPatentLastResult): Promise<PatentApplicationData> {
    const res = await HttpClientService.post<PatentApplicationData>(`${PatentAdapter.patentsEndpoint}/get_results_by_id`, { patentResultId, clientId }, { headers: headers() });
    return res.data;
  }

  async getContinuationAnalysisById({ patentId, isAdmin }: ContinuationAnalysisRequest): Promise<ContinuationAnalysisResponse> {
    const res = await HttpClientService.get<ContinuationAnalysisResponse>(`${PatentAdapter.patentsEndpoint}/get_continuation_analysis_by_id_patent`, {
      headers: headers(),
      params: { isAdmin, patentId },
    });
    return res.data;
  }

  async getContinuationAnalysisByFile({ isAdmin, files }: ContinuationAnalysisRequest): Promise<ContinuationAnalysisResponse> {
    const formData = new FormData();
    if (files && files.length > 0) {
      formData.append('file', files[0]);
      formData.append('fileName', files[0].name);
    }
    const res = await HttpClientService.post<ContinuationAnalysisResponse>(`${PatentAdapter.patentsEndpoint}/get_continuation_analysis_by_file`, formData, {
      headers: headersmultipart(),
      params: { isAdmin },
    });
    return res.data;
  }

  async getContinuationAnalysisResults({ continuationResultId }: ContinuationAnalysisResponse): Promise<ContinuationAnalysisResultsResponse> {
    const res = await HttpClientService.get<ContinuationAnalysisResultsResponse>(`${PatentAdapter.patentsEndpoint}/get_continuation_analysis_results/${continuationResultId}`, {
      headers: headers(),
    });
    return res.data;
  }
}

const patentAdapter = new PatentAdapter();
export default patentAdapter;
