import { agreementConstants } from './../_constants';

// TODO: ------ PLEASE DO NOT ANYTHING NEW HERE ------
// NEED TO REFACTOR AND REMOVE THIS REDUCER
// FOR THE NEW IMPLEMENTATION, ADD TO agreementSlice!!!

const initialState = {
  error: {},
  loading: false,
  status: '',
  types: [],
  editType: {},
  agreementSuccess: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case agreementConstants.CREATING_AGREEMENT:
      return {
        ...state,
        loading: true,
      };
    case agreementConstants.AGREEMENT_CREATED:
      return {
        ...state,
      };
    case agreementConstants.AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agreementSuccess: true,
      };
    case agreementConstants.AGREEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case agreementConstants.AGREEMENT_FILEUPLOAD_SUCCESS:
      return {
        ...state,
      };
    case agreementConstants.AGREEMENT_FILEUPLOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case agreementConstants.CREATE_AGREEMENT_TYPE:
      return {
        ...state,
        loading: true,
      };
    case agreementConstants.CREATE_AGREEMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };
    case agreementConstants.CREATE_AGREEMENT_TYPE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'failed',
      };

    case agreementConstants.GET_AGREEMENT_TYPES:
      return {
        ...state,
        loading: true,
      };
    case agreementConstants.GET_AGREEMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        types: action.payload,
      };
    case agreementConstants.GET_AGREEMENT_TYPES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case agreementConstants.CLEAR_STATUS:
      return {
        ...state,
        loading: false,
        error: {},
        status: '',
        agreementSuccess: false,
      };
    case agreementConstants.EDIT_AGREEMENT_TYPE:
      return {
        ...state,
        editType: {},
      };
    case agreementConstants.EDIT_AGREEMENT_TYPE_SUCCESS:
      return {
        ...state,
        editType: action.result,
      };
    case agreementConstants.CLEAR_AGREEMENT_TYPE_SUCCESS:
      return {
        ...state,
        editType: {},
      };
    case agreementConstants.EDIT_AGREEMENT_TYPE_FAILED:
      return {
        ...state,
        editType: {},
      };
    case agreementConstants.UPDATING_AGREEMENT:
      return {
        ...state,
        loading: true,
      };
    case agreementConstants.AGREEMENT_UPDATED:
      return {
        ...state,
        loading: false,
        updateAgreement: action.payload,
      };
    case agreementConstants.AGREEMENT_UPDATED_FAILED:
      return {
        ...state,
        loading: false,
        updateAgreement: {},
        errors: action.errors,
      };
    case agreementConstants.AGREEMENT_UPDATED_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'User Updated Successfully',
        updateAgreement: action.payload,
        agreementSuccess: true,
      };
    default:
      return state;
  }
}
