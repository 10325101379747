import { TangiCard } from '_components/TangiLibrary/TangiCard/TangiCard';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const CheckboxWithIconCard = styled(TangiCard)`
  height: 73px;
  width: 210px;
  display: flex;
  align-items: center;
  gap: ${SPACING[0]};
  padding-left: ${SPACING[3]};
  justify-content: flex-start;
  border-radius: ${SPACING[2]};
  cursor: pointer;
  background-color: ${NEUTRAL_SHADES.WHITE};
`;