import React from 'react';
import { isFunction } from 'lodash';

import { useComponentVisible } from '../../utils/customHooks';
import { TangiIconButton } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { StyledDropDown, StyledDropDownItem } from './style';

const AssetOptions = ({ item, options, styles }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const handleClick = (event, option) => {
    if (isFunction(option.handleItem)) {
      event.preventDefault();
      option.handleItem(item);
      setIsComponentVisible(false);
    }
  };
  return (
    <div data-testid="asset-options" ref={ref} style={styles?.divRefStyles ? styles.divRefStyles : {}}>
      <TangiIconButton icon="threeDotsVertical" onClick={() => setIsComponentVisible((prevState) => !prevState)} variant={BUTTON_VARIANTS.TERTIARY_GREY} isActive={isComponentVisible} />
      {isComponentVisible && (
        <StyledDropDown style={styles?.dropdownStyles ? styles.dropdownStyles : {}}>
          {options.map((option, index) => (
            <StyledDropDownItem key={index} tabIndex="0" onClick={(e) => !option.disabled && handleClick(e, option)} disabled={option.disabled}>
              {option.text}
            </StyledDropDownItem>
          ))}
        </StyledDropDown>
      )}
    </div>
  );
};

export default AssetOptions;
