export const clientSettingsConstants = {
  CREATE_CLIENT_SETTINGS_REQUEST: 'CREATE_CLIENT_SETTINGS_REQUEST',
  CREATE_CLIENT_SETTINGS_SUCCESS: 'CREATE_CLIENT_SETTINGS_SUCCESS',
  CREATE_CLIENT_SETTINGS_FAILURE: 'CREATE_CLIENT_SETTINGS_FAILURE',
  GET_CLIENT_SETTINGS_REQUEST: 'GET_CLIENT_SETTINGS_REQUEST',
  GET_CLIENT_SETTINGS_SUCCESS: 'GET_CLIENT_SETTINGS_SUCCESS',
  GET_CLIENT_SETTINGS_FAILURE: 'GET_CLIENT_SETTINGS_FAILURE',
  UPDATE_CLIENT_SETTINGS_REQUEST: 'UPDATE_CLIENT_SETTINGS_REQUEST',
  UPDATE_CLIENT_SETTINGS_SUCCESS: 'UPDATE_CLIENT_SETTINGS_SUCCESS',
  UPDATE_CLIENT_SETTINGS_FAILURE: 'UPDATE_CLIENT_SETTINGS_FAILURE',
  CLEAR_CLIENT_SETTINGS: 'CLEAR_CLIENT_SETTINGS',
};

export const importanceDefaultText = ['Do not share without CEO permission', 'Do not share outside of the company', 'Sharing permission with NDA + approval', 'Sharing permission with NDA'];

export const complianceRequirementsRoles = ['Employee', 'Contractor', 'Partner'];
