import { ResponsivePie, PieLayer, ComputedDatum } from '@nivo/pie';
import { Margin } from '@nivo/core';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { NEUTRAL_SHADES } from '../../../../utils/theme';
import { WidgetDataObject } from '../Widgets/Widgets.types';
import { PIE_CHART_CONSTS } from 'pages/ClientDashboard/consts';
import { getLegends } from './utils';

export const TotalAssetsText = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${NEUTRAL_SHADES[700]};
  margin-bottom: 16px;
`;

type TextOverLayContainerProps = { $marginLeft: number; $marginRight: number };
const TextOverLayContainer = styled.div<TextOverLayContainerProps>`
  ${({ $marginLeft: marginLeft, $marginRight: marginRight }: TextOverLayContainerProps) => css`
    position: absolute;
    top: 0;
    right: ${marginRight}px;
    bottom: 0;
    left: ${marginLeft}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-family: 'DIN2014-DemiBold';
    color: ${NEUTRAL_SHADES.BLACK};
    text-align: center;
    pointer-events: none;
  `}
`;

export const BasicTooltip = styled.div`
  background: ${NEUTRAL_SHADES.WHITE};
  padding: 6px 9px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  color: ${NEUTRAL_SHADES.BLACK};
`;

export const TooltipColorBox = styled.span<{ color: string }>`
  width: 12px;
  height: 12px;
  background: ${({ color }) => color};
`;

export interface Props {
  data: WidgetDataObject[];
  innerRadius?: number;
  startAngle?: number;
  sortByValue?: boolean;
  layers?: PieLayer<WidgetDataObject>[];
  arcLinkLabelsTextOffset?: number;
  arcLinkLabelsDiagonalLength?: number;
  arcLinkLabelsStraightLength?: number;
  margin?: Partial<Margin>;
  arcLinkLabelsSkipAngle?: number;
  arcLabelsSkipAngle?: number;
  colors?: string | ((obj: { data: WidgetDataObject }) => string);
  total: number;
  totalText?: React.ReactNode;
  legendTranslateX?: number;
  legendTranslateY?: number;
  legendItemWidth?: number;
  maxLegendItemtTextLength?: number;
  maxItemsPerLegend?: number;
}

const PieChart = ({
  data,
  innerRadius = 0.65,
  startAngle = 0,
  sortByValue = false,
  layers = [],
  arcLinkLabelsTextOffset = 4,
  arcLinkLabelsDiagonalLength = 25,
  arcLinkLabelsStraightLength = 12,
  margin = { top: 20, right: 40, bottom: 40, left: 40 },
  arcLinkLabelsSkipAngle = 1,
  arcLabelsSkipAngle = 1,
  colors = ({ data }: { data: WidgetDataObject }) => data.color || '',
  total = 0,
  totalText = '',
  legendTranslateX = 12,
  legendTranslateY = 65,
  legendItemWidth = PIE_CHART_CONSTS.LEGEND_ITEM_WIDTH,
  maxItemsPerLegend = PIE_CHART_CONSTS.MAX_ITEMS_PER_LEGEND,
  maxLegendItemtTextLength = PIE_CHART_CONSTS.MAX_LEGEND_ITEM_TEXT_LENGTH,
}: Props) => {
  const legends = useMemo(() => {
    return getLegends({ legendKeys: data, maxItemsPerLegend, legendItemWidth, maxLegendItemtTextLength, legendTranslateX, legendTranslateY });
  }, [data]);

  return (
    <>
      <ResponsivePie
        legends={legends}
        data={data}
        margin={margin}
        innerRadius={innerRadius}
        startAngle={startAngle}
        sortByValue={sortByValue}
        cornerRadius={0}
        activeOuterRadiusOffset={0}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsTextColor={NEUTRAL_SHADES[1000]}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLinkLabelsThickness={3}
        enableArcLabels={false}
        colors={colors}
        arcLabelsTextColor={NEUTRAL_SHADES[500]}
        arcLinkLabelsTextOffset={arcLinkLabelsTextOffset}
        arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
        arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
        arcLinkLabel="value"
        arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
        arcLabelsSkipAngle={arcLabelsSkipAngle}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', ...layers]}
        tooltip={({ datum }: { datum: ComputedDatum<WidgetDataObject> }) => (
          <BasicTooltip>
            <TooltipColorBox color={datum.color} />
            {datum.label}: {datum.value}
          </BasicTooltip>
        )}
        theme={{
          labels: {
            text: {
              fontSize: 16,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 24,
            },
          },
          legends: {
            text: {
              fontFamily: 'DIN2014-Regular',
              fontSize: 12,
            },
          },
        }}
      />
      <TextOverLayContainer $marginLeft={margin.left || 0} $marginRight={margin.right || 0} data-testid="text-overlay-container">
        <span>{total.toLocaleString()}</span>
        <TotalAssetsText>{totalText}</TotalAssetsText>
      </TextOverLayContainer>
    </>
  );
};

export default PieChart;
