import styled, { css } from 'styled-components';

import { PRIMARY_SHADES, NEUTRAL_SHADES } from '../../utils/theme';

export const StyledDropDown = styled.div`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  background-color: ${NEUTRAL_SHADES.WHITE};
  border-radius: 4px;
  position: absolute;
  z-index: 101;
  font-family: 'DIN2014-Regular';
  font-size: 14px;
  padding-block: 8px;
  width: 132px;
`;

export const StyledDropDownItem = styled.div`
  ${({ disabled }) => css`
    font-size: 14px;
    cursor: ${disabled ? 'default' : 'pointer'};
    color: ${disabled ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK};
    padding: 8px 16px;
    &:hover {
      background-color: ${!disabled && NEUTRAL_SHADES[100]};
    }
    &:focus {
      background-color: ${!disabled && PRIMARY_SHADES[100]};
    }
  `}
`;
