import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { LANGUAGE, languageOptions } from 'translations/enums';
import { updateUserLanguage } from 'redux-toolkit/thunks/authThunks';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const CustomToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 14px ${SPACING[3]};
  width: 100%;
  gap: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${NEUTRAL_SHADES[100]};
  }
`;

export const typographyStyle = `
  flex: 1;
`;

export const DropdownItem = styled(Dropdown.Item)`
  &:hover {
    background-color: ${PRIMARY_SHADES[200]};
  }
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  right: 100%;
  margin-right: ${SPACING[1.5]} !important;
  top: ${SPACING[0]};
`;

interface Props {
  userId: string;
}

const SwitchUserLanguage = ({ userId }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = (value: LANGUAGE) => {
    dispatch(updateUserLanguage({ userId, language: value }));
  };

  const CustomToggle = forwardRef<HTMLDivElement, { onClick: React.MouseEventHandler<HTMLDivElement> }>(({ onClick }, ref) => (
    <CustomToggleContainer
      data-testid="language-dropdown"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <TangiSvgIcon component="languages" color={PRIMARY_SHADES[100]} />
      <TangiTypography customStyles={typographyStyle}>{t('SETTINGS_PAGE.CHANGE_LANGUAGE')}</TangiTypography>
      <TangiSvgIcon component="arrowSideRight" color={NEUTRAL_SHADES.BLACK} />
    </CustomToggleContainer>
  ));

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <DropdownMenu className="dropdown-menu" data-testid="language-dropdown-menu">
        {languageOptions.map((option) => (
          <DropdownItem data-testid="language-dropdown-item" key={option.value} onClick={() => handleClick(option.value)}>
            <TangiTypography>{option.label}</TangiTypography>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SwitchUserLanguage;
