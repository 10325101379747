import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { TangiButton } from '_components/TangiLibrary';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { RootState } from '_helpers';
import { MODE_TYPE, PotentialClaim } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { defaultModes } from '_constants/inventionDisclosureConstants';
import { useMemo } from 'react';

const AddClaimContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .tangi-button-textContainer {
    margin-top: 4px;
  }
`;

export const AddClaim = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const claims: PotentialClaim[] = useSelector((state: RootState) => state.inventionDisclosure.inventionDisclosureData.response?.claims);

  const isCreateMode = useMemo(() => claims?.some((claim) => claim.modes.isClaimCreateMode), [claims]);

  const handleAddClaim = () => {
    const claim: PotentialClaim = {
      id: uuidv4(),
      text: '',
      bullets_ids: [],
      modes: { ...defaultModes, isClaimCreateMode: true },
    };

    dispatch(inventionDisclosureActions.addClaim(claim));
    dispatch(inventionDisclosureActions.setClaimMode({ mode: MODE_TYPE.IS_CLAIM_EDIT_MODE, value: false, claimId: claim.id }));
  };

  return (
    <AddClaimContainer>
      <TangiButton disabled={isCreateMode} svgIcon="add" text={t('INVENTION_DISCLOSURE.TABS.ADD_CLAIM')} onClick={handleAddClaim} variant="tertiary" data-testid="add-claim" />
    </AddClaimContainer>
  );
};
