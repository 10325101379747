import styled from 'styled-components';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const customTableStyles = {
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[200]} !important`,
      height: '64px',
    },
  },
  rows: {
    style: {
      minHeight: '50px',
      border: 'none',
    },
  },
  pagination: {
    style: {
      marginBottom: SPACING[4],
    },
  },
};

export const PotentialTsTypography = styled(TangiTypography)`
  padding: 5px;
  max-width: 600px;
  white-space: pre-wrap !important;
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  max-height: 100vh;
`;
