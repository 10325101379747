import { Link, useParams } from 'react-router-dom';
import i18next from 'i18next';
import Tippy from '@tippyjs/react';

import AssetTypeColorBlock from '_components/AssetTypeColorBlock/AssetTypeColorBlock';
import { IRoles } from '../../utils/roles';
import { TangiChip, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { formatDate } from 'utils/dateUtils';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { SHARE_POINT } from 'utils/assetTypes';
import { AssetNameContainer, DisplayedTags, RemainingCountContainer, RowContainer, TagsContainer, ValueContainer, ellipsisWordTypographyStyles } from './styles';

export const getSettingRoleName = (role) => {
  switch (role) {
    case IRoles.EMPLOYEE:
    case IRoles.EMPLOYEE_ADMIN:
    case IRoles.EMPLOYEE_MANAGER:
    case IRoles.EMPLOYEE_HR:
      return IRoles.EMPLOYEE;
    case IRoles.PARTNER:
      return IRoles.PARTNER;
    case IRoles.CONTRACTOR:
    case IRoles.CONTRACTOR_ADMIN:
    case IRoles.CONTRACTOR_OTHER:
      return IRoles.EMPLOYEE;
    default:
      break;
  }
};

export const shouldAddComma = (arrayLength, index) => index !== arrayLength && index !== 0;

export const tableTypes = {
  ASSETS_CREATED: 'assets created',
  ASSETS_SHARED: 'assets shared',
  ASSETS_CONTRIBUTORS: 'assets contributed ',
};

const renderAcknowledge = (acknowledgedOn) => {
  return (
    <RowContainer>
      <TangiTypography color={SUCCESS_SHADES[500]}>{formatDate(acknowledgedOn)}</TangiTypography>
      <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
    </RowContainer>
  );
};

const renderValue = (value, color = NEUTRAL_SHADES.BLACK) => {
  return (
    <ValueContainer>
      <TangiTypography color={color} customStyles={ellipsisWordTypographyStyles}>
        {value}
      </TangiTypography>
    </ValueContainer>
  );
};

const renderTags = (tags) => {
  if (!tags || tags.length === 0) return null;

  const displayedTags = tags.slice(0, 2);
  const remainingTags = tags.slice(2);
  const remainingCount = tags.length - displayedTags.length;

  return (
    <TagsContainer>
      <DisplayedTags>
        {displayedTags.map((tag, index) => (
          <Tippy key={tag.id} content={<div>{tag.name}</div>} placement="top-end">
            <span>
              <TangiChip key={index} text={tag.name} maxWidth={150} />
            </span>
          </Tippy>
        ))}
      </DisplayedTags>
      {remainingCount > 0 && (
        <RemainingCountContainer>
          <Tippy
            content={remainingTags.map((tag, index) => (
              <div key={index}>{tag.name}</div>
            ))}
            placement="top-end"
          >
            <span>
              <TangiChip text={`+${remainingCount}`} maxWidth={40} />
            </span>
          </Tippy>
        </RemainingCountContainer>
      )}
    </TagsContainer>
  );
};

const AssetNameCell = ({ row }) => {
  const { clientId } = useParams();
  const isSharePoint = row.assetType === SHARE_POINT;
  const contentStyle = {
    textDecoration: isSharePoint ? 'none' : 'underline',
    width: 'calc(100% - 24px)',
    minWidth: '75px',
    cursor: isSharePoint ? 'default' : 'pointer',
  };

  return (
    <AssetNameContainer>
      <AssetTypeColorBlock assetTypeName={row?.assetType || ''} />
      {isSharePoint ? (
        <span style={contentStyle}>{renderValue(row.name)}</span>
      ) : (
        <Link to={generateRoute(AppRoutes.ASSET_INNER_PAGE, { clientId, assetId: row._id })} className="text-black" style={contentStyle}>
          {renderValue(row.name)}
        </Link>
      )}
    </AssetNameContainer>
  );
};

export const getColumns = (type, sortState) => {
  switch (type) {
    case tableTypes.ASSETS_CREATED:
      return [
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.ASSET_COLUMN'),
          selector: ({ name }) => name,
          sortable: true,
          cell: (row) => <AssetNameCell row={row} />,
          maxWidth: '500px',
          minWidth: '100px',
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.TAGS_COLUMN'),
          selector: ({ tags }) => tags,
          sortable: true,
          cell: (row) => renderTags(row.tags),
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.CREATION_DATE'),
          selector: ({ creationDate }) => creationDate,
          sortable: true,
          cell: (row) => row.creationDate && renderValue(formatDate(row.creationDate)),
          maxWidth: '150px',
          minWidth: '100px',
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.ACKNOWLEDGEMENT'),
          selector: ({ acknowledged }) => acknowledged,
          sortable: true,
          cell: (row) => (row.acknowledged ? renderAcknowledge(row.acknowledgedOn) : renderValue(i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.NOT_SIGNED'), NEUTRAL_SHADES[800])),
          maxWidth: '150px',
          minWidth: '100px',
        },
      ];

    case tableTypes.ASSETS_SHARED:
      return [
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.ASSET_COLUMN'),
          selector: ({ name }) => name,
          sortable: true,
          cell: (row) => <AssetNameCell row={row} />,
          maxWidth: '500px',
          minWidth: '100px',
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.TAGS_COLUMN'),
          selector: ({ tags }) => tags,
          sortable: true,
          cell: (row) => renderTags(row.tags),
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.SENT_DATE'),
          selector: ({ sharedOn }) => sharedOn,
          sortable: true,
          cell: (row) => row.sharedOn && renderValue(formatDate(row.sharedOn)),
          maxWidth: '150px',
          minWidth: '100px',
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.ACKNOWLEDGEMENT'),
          selector: ({ acknowledged }) => acknowledged,
          sortable: true,
          cell: (row) => (row.acknowledged ? renderAcknowledge(row.acknowledgedOn) : renderValue(i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.NOT_SIGNED'), NEUTRAL_SHADES[800])),
          maxWidth: '150px',
          minWidth: '100px',
        },
      ];

    case tableTypes.ASSETS_CONTRIBUTORS:
      return [
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.ASSET_COLUMN'),
          selector: ({ name }) => name,
          sortable: true,
          cell: (row) => <AssetNameCell row={row} />,
          maxWidth: '500px',
          minWidth: '100px',
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.TAGS_COLUMN'),
          selector: ({ tags }) => tags,
          sortable: true,
          cell: (row) => renderTags(row.tags),
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.CREATION_DATE'),
          selector: ({ creationDate }) => creationDate,
          sortable: true,
          cell: (row) => row.creationDate && renderValue(formatDate(row.creationDate)),
          maxWidth: '150px',
          minWidth: '100px',
        },
        {
          name: i18next.t('PEOPLE.VIEW_EMPLOYEE.TABLE.ACKNOWLEDGEMENT'),
          selector: ({ acknowledged }) => acknowledged,
          sortable: true,
          cell: (row) => (row.acknowledged ? renderAcknowledge(row.acknowledgedOn) : renderValue(i18next.t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.NOT_SIGNED'), NEUTRAL_SHADES[800])),
          maxWidth: '150px',
          minWidth: '100px',
        },
      ];
    default:
      return [];
  }
};
