import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { EmptyStateContainer, EmptyStateContent } from './styles';
export interface Props {
  height?: string;
}

const EmptyState = ({ height = 'calc(100vh - 376px)' }: Props) => {
  const { t } = useTranslation();

  return (
    <EmptyStateContainer height={height} data-testid="ts-analysis-empty-state">
      <EmptyStateContent>
        <TangiTypography type="heading-md">{t('GENERAL.ERROR.SOMETHING_WENT_WRONG')}</TangiTypography>
      </EmptyStateContent>
    </EmptyStateContainer>
  );
};

export default EmptyState;
