import styled from 'styled-components';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const FileDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${PRIMARY_SHADES[50]};
  height: 48px;
  flex: 1;
  gap: ${SPACING[1]};
  padding: 0 ${SPACING[2]};
  justify-content: space-between;
`;

export const TemplateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  margin-left: ${SPACING[2]};
  margin-top: ${SPACING[1]};
`;