import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { NOTIFICATION_VARIANTS, TangiButton, TangiNotification, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { getXRayErrorMessage } from './utils';
import { CancelButtonContainer, ErrorNotificationContainer, SpinnerContainer, TrioContainer } from './style';
import { BUTTON_VARIANTS } from 'utils/theme';
import { LOADING_TRANSLATION_KEY } from './types';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';

interface Props {
  status: XRAY_JOB_STATUS;
  loadingTranslationKey: LOADING_TRANSLATION_KEY;
  isCancelDisabled: boolean;
  errorDetails: string;
  statusSucceededComponent: React.ReactNode;
  onCancel: () => void;
  patentIdentifier?: string;
  patentSource?: string;
}

const XrayStatusNotification = (props: Props) => {
  const { t } = useTranslation();

  const renderStartedNotification = () => {
    return (
      <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO} startIcon={false}>
        <TrioContainer>
          <SpinnerContainer>
            <Spinner animation="border" variant="primary" />
          </SpinnerContainer>
          <TangiTypography type="subheading">
            <span data-testid="process-started-notification">
              <b>{t(props.loadingTranslationKey)}</b> {t('DISCOVERY_LOBBY.XRAY_STATUS_NOTIFICATION.YOU_CAN_CONTINUE')}
            </span>
          </TangiTypography>
          <CancelButtonContainer>
            <TangiButton variant={BUTTON_VARIANTS.TERTIARY} text={t('GENERAL.BUTTONS_TEXT.CANCEL')} onClick={props.onCancel} data-testid="cancel-process-started" disabled={props.isCancelDisabled} />
          </CancelButtonContainer>
        </TrioContainer>
      </TangiNotification>
    );
  };

  const renderFailedNotification = () => {
    const errorMessage = getXRayErrorMessage(props.errorDetails, props?.patentIdentifier);
    return (
      <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.WARNING} startIcon={false}>
        <ErrorNotificationContainer>
          <TangiSvgIcon component="alertWithTriangle" color="" size="44px" />
          <TangiTypography type="subheading">
            <b data-testid={errorMessage.testId}>{t(errorMessage.boldText, errorMessage.boldTextParameters)}</b> {t(errorMessage.text, errorMessage.textParameters)}
          </TangiTypography>
        </ErrorNotificationContainer>
      </TangiNotification>
    );
  };

  const NOTIFICATION_STATUSES_MAP: Record<string, React.ReactNode> = {
    [`${XRAY_JOB_STATUS.STARTED}`]: renderStartedNotification(),
    [`${XRAY_JOB_STATUS.SUCCEEDED}`]: props.statusSucceededComponent,
    [`${XRAY_JOB_STATUS.FAILED}`]: renderFailedNotification(),
  };

  return <>{NOTIFICATION_STATUSES_MAP[props.status]}</>;
};

export default XrayStatusNotification;
