import { headers } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';

class DashboardAdapter {
  static clientEndpoint = `${config.apiUrl}${config.apiVersion}/client`;
  static dashboardEndpoint = `${config.apiUrl}${config.apiVersion}/dashboard`;

  async getDashboardAssetsRawData(clientId: string) {
    const res = await HttpClientService.get(`${DashboardAdapter.dashboardEndpoint}/assets/${clientId}`, { headers: headers() });
    return res.data;
  }
  async getDashboardAccountsRawData(clientId: string) {
    const res = await HttpClientService.get(`${DashboardAdapter.dashboardEndpoint}/accounts/${clientId}`, { headers: headers() });
    return res.data;
  }
  async getDashboardRecipientsRawData(clientId: string) {
    const res = await HttpClientService.get(`${DashboardAdapter.dashboardEndpoint}/recipients/${clientId}`, { headers: headers() });
    return res.data;
  }
}

const dashboardAdapter = new DashboardAdapter();
export default dashboardAdapter;
