import dayjs, { Dayjs } from 'dayjs';
import { RRule } from 'rrule';
import { add, endOfWeek, startOfDay, startOfMonth, startOfWeek, subMonths, endOfMonth } from 'date-fns';
import _ from 'lodash';
import i18next from 'i18next';

import { AssetRawData } from '../../../../../utils/types/dashboard/dashboard';
import { LANGUAGE } from '../../../../../translations/enums';
import { assetTypeNameFormatter, isCustomAssetType } from 'utils/assetTypes';
import { PIE_CHART_CONSTS } from 'pages/ClientDashboard/consts';
import { ASSET_TYPE_COLORS, CUSTOM_ASSET_TYPE_COLORS } from 'utils/theme';
import { formatDate } from 'utils/dateUtils';

const DATE_FORMAT_DDMONYY = 'DD MMM YY';

export enum PERIOD_OPTIONS {
  WEEK = '1W',
  MONTH = '1M',
  YEAR = '1Y',
}

export type PERIOD_RANGE = {
  startDate: Dayjs;
  endDate: Dayjs;
};

export type StackedBarChartDataObject = {
  [key: string]: string | number;
  label: string;
  total: number;
};

export interface AssetOverTime {
  startDate: Date;
  endDate: Date;
  data: StackedBarChartDataObject[];
}

export type DataType = {
  type: string;
  amount: number;
  color: string;
};

const OUTSIDE_RANGE = 'Outside Range';
const MAX_BAR_TYPES = 4;

export const calculatePeriod = (period: PERIOD_OPTIONS): PERIOD_RANGE => {
  switch (period) {
    case PERIOD_OPTIONS.WEEK:
      return { startDate: dayjs().subtract(6, 'days').startOf('date'), endDate: dayjs().endOf('date') };
    case PERIOD_OPTIONS.MONTH:
      return { startDate: dayjs().subtract(3, 'week').startOf('week'), endDate: dayjs().endOf('week') };
    case PERIOD_OPTIONS.YEAR:
      return { startDate: dayjs().subtract(11, 'month').startOf('month'), endDate: dayjs().endOf('month') };
    default:
      return { startDate: dayjs(), endDate: dayjs() };
  }
};

// --- NEW FUNCTIONS ---
// TODO: Add tests

/**
 * Generates a human-readable representation of a time interval (like a week, month, or year) based on the provided `period`, `startDate`, and `endDate`.
 *
 * @function calculateTimePeriodTitle
 *
 * @param {PERIOD_OPTIONS} period - The type of time period (e.g., week, month, year).
 * @param {Date} startDate - The starting date of the period.
 * @param {Date} endDate - The ending date of the period.
 *
 * @returns {string} A human-readable title for the given time period.
 */
export const calculateTimePeriodTitle = (period: PERIOD_OPTIONS, startDate: Date, endDate: Date): string => {
  switch (period) {
    case PERIOD_OPTIONS.WEEK:
      return `${formatDate(startDate, DATE_FORMAT_DDMONYY)} - ${formatDate(endDate, DATE_FORMAT_DDMONYY)}`;
    case PERIOD_OPTIONS.MONTH:
      return `${formatDate(startDate, 'MMM YYYY')}`;
    case PERIOD_OPTIONS.YEAR:
      return i18next.t('DASHBOARD_PAGE.WIDGETS.COMMON.PAST_12_MONTH');
    default:
      return '';
  }
};

/**
 * Gets the date boundaries based on the specified time interval.
 *
 * @function getDateBoundaries
 * @param {PERIOD_OPTIONS} [timeInterval=PERIOD_OPTIONS.YEAR] - The desired time interval for the boundaries (MONTH, WEEK, or YEAR).
 * @returns {Date[]} An array of date boundaries based on the time interval.
 */
const getDateBoundaries = (timeInterval = PERIOD_OPTIONS.YEAR) => {
  const currentDate = startOfDay(new Date());
  let ruleConfig;
  switch (timeInterval) {
    case PERIOD_OPTIONS.MONTH:
      ruleConfig = {
        freq: RRule.WEEKLY,
        dtstart: startOfMonth(currentDate),
        until: add(endOfWeek(new Date()), { days: 7 }),
        byhour: 0,
        byminute: 0,
        bysecond: 0,
        byweekday: RRule.MO,
      };
      break;
    case PERIOD_OPTIONS.WEEK:
      ruleConfig = {
        freq: RRule.DAILY,
        dtstart: startOfWeek(currentDate, { weekStartsOn: 1 }), // weekStartsOn: 1 means the week starts on Monday
        count: currentDate.getDay() + 1,
        byhour: 0,
        byminute: 0,
        bysecond: 0,
      };
      break;
    case PERIOD_OPTIONS.YEAR:
    default:
      ruleConfig = {
        freq: RRule.MONTHLY,
        dtstart: subMonths(endOfMonth(currentDate), 12),
        count: 13,
        byhour: 0,
        byminute: 0,
        bysecond: 0,
        bymonthday: 1,
      };
      break;
  }

  const rule = new RRule(ruleConfig);
  return [...rule.all()];
};

/**
 * Determines the group label for an asset based on its creation date and specified date boundaries.
 *
 * @function getAssetOverTimeGroup
 * @param {AssetRawData} asset - The asset object containing raw data, including its creation date.
 * @param {Date[]} dateBoundaries - An array of date boundaries to compare against the asset's creation date.
 * @param {PERIOD_OPTIONS} timeInterval - The desired time interval (MONTH, WEEK, or YEAR) used to determine grouping.
 * @param {string} language - The desired language to use for generating the group label.
 * @returns {string} The generated group label for the asset based on its creation date, or 'OUTSIDE_RANGE' if it falls outside of the given date boundaries.
 */
const getAssetOverTimeGroup = (asset: AssetRawData, dateBoundaries: Date[], timeInterval: PERIOD_OPTIONS, language: string) => {
  const index = findBoundaryIndex(new Date(asset.createdAt), dateBoundaries);

  if (index === -1) {
    return OUTSIDE_RANGE;
  }

  const boundaryStart = dateBoundaries[index];
  return generateGroupLabelForAssetOverTime(boundaryStart, timeInterval, language, index);
};

/**
 * Determines the index of the boundary interval in which a given date (`createdAt`) falls.
 *
 * @function findBoundaryIndex
 * @param {Date} createdAt - The date to find the boundary index for.
 * @param {Date[]} dateBoundaries - An array of date boundaries to compare against.
 * @returns {number} Returns the index of the boundary interval where `createdAt` falls, or -1 if `createdAt` is before the first boundary.
 *                   If the date is greater than or equal to the last boundary, it returns the index of the last boundary.
 */
const findBoundaryIndex = (createdAt: Date, dateBoundaries: Date[]) => {
  const createdAtDate = new Date(createdAt);

  if (createdAtDate < new Date(dateBoundaries[0])) {
    return -1;
  }

  const index = dateBoundaries.findIndex((boundary, i) => {
    const startDate = boundary;
    const endDate = dateBoundaries[i + 1] || boundary; // Use the last boundary for out-of-bounds checks

    return createdAtDate >= startDate && createdAtDate < endDate;
  });

  // If the date is greater than or equal to the last boundary, return the index of the last boundary
  if (createdAt >= dateBoundaries[dateBoundaries.length - 1]) {
    return dateBoundaries.length - 1;
  }
  return index;
};

/**
 * Generates a label for a group based on the provided boundary start date, time interval, and language.
 * This label can represent months, weeks, or custom groupings.
 *
 * @function generateGroupLabelForAssetOverTime
 * @param {Date} boundaryStart - The starting date of the boundary for which the label needs to be generated.
 * @param {PERIOD_OPTIONS} timeInterval - The time interval (e.g., YEAR, MONTH, WEEK) used to determine the label format.
 * @param {string} language - The language in which the label should be generated.
 * @param {number} index - The index or position of the group. Used for WEEK and custom groupings.
 * @returns {string} Returns a formatted label string based on the provided parameters.
 */
const generateGroupLabelForAssetOverTime = (boundaryStart: Date, timeInterval: PERIOD_OPTIONS, language: string, index: number) => {
  switch (timeInterval) {
    case PERIOD_OPTIONS.YEAR:
      return new Date(boundaryStart).toLocaleString(language, { month: 'short' });
    case PERIOD_OPTIONS.MONTH:
      return `${i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.WEEK')} ${index + 1}`;
    case PERIOD_OPTIONS.WEEK:
      return new Date(boundaryStart).toLocaleString(language, { weekday: 'short' });
    default:
      return `${i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.GROUP')} ${index + 1}`;
  }
};

/**
 * Groups the assets by their asset type name and returns an object representing the total count of each asset type for the given group.
 *
 * @function groupAssetsByAssetTypeName
 * @param {AssetRawData[]} group - Array of asset raw data that need to be grouped by their asset type name.
 * @param {string} groupName - Name of the group for which the assets are being processed.
 * @returns {StackedBarChartDataObject} Returns an object with a label (representing the groupName), a total count of all assets in the group,
 *                                   and key-value pairs where the key is the asset type name and the value is the count of assets of that type.
 */
const groupAssetsByAssetTypeName = (group: AssetRawData[], groupName: string): StackedBarChartDataObject => {
  const groupedByAssetType: Record<string, AssetRawData[]> = _.groupBy(group, (item: AssetRawData) => item.assetType.name);
  const totalByAssetType = _.mapValues(groupedByAssetType, 'length');
  const currentYear = assignYearBasedOnCreatedAt(group) ?? 0; // Provide a default value if undefined

  return {
    label: groupName,
    total: group.length,
    currentYear,
    ...totalByAssetType,
  };
};

/**
 * Modifies the provided data object by adding color keys (`${key}Color`) for each asset type.
 * If the number of asset types exceeds the `MAX_BAR_TYPES`, an additional 'Other' key is added with the count of the remaining asset types, along with its corresponding color key.
 *
 * @function addColorKeysToDataObj
 * @param {StackedBarChartDataObject} dataObj - The initial data object containing counts of each asset type.
 * @param {[key: string]: string} customAssetTypeColorMapping - Dictionary who holds the custom asset type color mapping
 * @returns {StackedBarChartDataObject} Returns an object which includes the initial data with color keys for each asset type, and potentially
 *                                   an 'Other' key and its associated color key if required.
 */
const addColorKeysToDataObj = (dataObj: StackedBarChartDataObject, customAssetTypeColorMapping: { [key: string]: string } = {}): StackedBarChartDataObject => {
  const assetTypeKeys = Object.keys(dataObj)
    .filter((key) => key !== 'label' && key !== 'total' && key !== 'currentYear')
    .sort((key1, key2) => Number(dataObj[key2]) - Number(dataObj[key1]));

  // Initialize the base object with label, total, and the max amount of assetTypeKeys
  const baseObj: StackedBarChartDataObject = _.pick(dataObj, ['label', 'total', 'currentYear', ...assetTypeKeys.slice(0, MAX_BAR_TYPES)]) as StackedBarChartDataObject;

  // Add the `${key}Color` properties for each assetTypeKey
  const withColorKeys = assetTypeKeys.slice(0, MAX_BAR_TYPES).reduce((obj, key) => {
    if (isCustomAssetType(key)) {
      if (!customAssetTypeColorMapping[key]) {
        customAssetTypeColorMapping[key] = CUSTOM_ASSET_TYPE_COLORS[Object.keys(customAssetTypeColorMapping).length % CUSTOM_ASSET_TYPE_COLORS.length];
      }
      obj[`${key}Color`] = customAssetTypeColorMapping[key];
    } else {
      obj[`${key}Color`] = ASSET_TYPE_COLORS[key];
    }
    return obj;
  }, baseObj);

  // Add the 'Other' and its color key
  if (assetTypeKeys.length > MAX_BAR_TYPES) {
    const otherCount = _.sumBy(assetTypeKeys.slice(MAX_BAR_TYPES), (key: string) => Number(dataObj[key]));

    withColorKeys[i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.OTHER')] = otherCount;
    withColorKeys[`${i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.OTHER')}Color`] = PIE_CHART_CONSTS.OTHER_COLOR;
  }
  return withColorKeys;
};

/**
 * This function receives two StackedBarChartDataObject and return their combined value
 *
 * @function aggregateAssetOverTimeDataObject
 * @param {StackedBarChartDataObject} obj1 - The initial data object containing counts and colors of each asset type.
 * @param {StackedBarChartDataObject} obj2 - The initial data object containing counts and colors of each asset type.
 * @returns {StackedBarChartDataObject} StackedBarChartDataObject with the combined data of both obj1 and obj2 with the label of option 1
 *
 */
const combineAssetOverTimeDataObject = (obj1: StackedBarChartDataObject, obj2: StackedBarChartDataObject): StackedBarChartDataObject => {
  const combined: StackedBarChartDataObject = { ...obj1 };
  combined.total = obj1.total + obj2.total;
  combined.currentYear = obj1.currentYear ?? obj2.currentYear;

  Object.keys(obj2).forEach((key: string) => {
    if (key != 'total' && key !== 'label' && key !== 'currentYear') {
      if (combined[key] && typeof combined[key] !== 'string') {
        combined[key] = +combined[key] + +obj2[key];
      } else combined[key] = obj2[key];
    }
  });

  return combined;
};

/**
 * This function add the outsideRange StackedBarChartDataObject and add it to the array first index
 * Moreover, it iterate the array and add each object the previous object to it
 *
 * @function aggregateAssetOverTimeDataObject
 * @param {StackedBarChartDataObject[]} data - The initial data object array containing an array of counts of each asset type.
 * @param {StackedBarChartDataObject} outsideRange - The initial data object array containing an array of counts of each asset type.
 * @returns {StackedBarChartDataObject[]} Returns an object which includes the initial data with color keys for each asset type, and potentially
 *                                   an 'Other' key and its associated color key if required.
 */
const aggregateAssetOverTimeDataObject = (data: StackedBarChartDataObject[], outsideRange: StackedBarChartDataObject): StackedBarChartDataObject[] => {
  if (data.length) {
    data[0] = combineAssetOverTimeDataObject(data[0], outsideRange);
  } else data = [outsideRange];
  if (data.length > 1) {
    for (let i = 1; i < data.length; i++) {
      data[i] = combineAssetOverTimeDataObject(data[i], data[i - 1]);
    }
  }
  return data;
};

/**
 * Organizes a list of assets created over a given time interval and sorts them by asset type.
 * The function groups assets into various time periods (like months, weeks, or years) and calculates the number of assets created of each type within each period.
 * It then assigns color codes to each asset type and returns the data structured for visualization.
 *
 * @function getAssetsOvertimeByAssetType
 *
 * @param {AssetRawData[]} assets - List of raw asset data to be processed.
 * @param {PERIOD_OPTIONS} [timeInterval=PERIOD_OPTIONS.YEAR] - The period over which to group the assets (e.g., monthly, weekly, yearly).
 * @param {string} [language=LANGUAGE.ENGLISH] - Language setting for localization of labels.
 *
 * @returns {AssetOverTime} Returns an object containing:
 * - `startDate`: The starting date of the time interval being considered.
 * - `endDate`: The ending date of the time interval being considered.
 * - `data`: Array of processed asset data for each period, ready for visualization.
 */
export const getAssetsOvertimeByAssetType = (assets: AssetRawData[], timeInterval: PERIOD_OPTIONS = PERIOD_OPTIONS.YEAR, language: string = LANGUAGE.ENGLISH): AssetOverTime => {
  const dateBoundaries = getDateBoundaries(timeInterval);
  //TODO: need to be modified if adding custom time interval
  let outsideRangeAssets: AssetRawData[] = [];
  const customAssetTypeColorMapping = {};
  const groupedAssets = _(assets)
    .groupBy((asset: AssetRawData) => getAssetOverTimeGroup(asset, dateBoundaries, timeInterval, language))
    .map((group: AssetRawData[], groupName: string) => {
      if (groupName === OUTSIDE_RANGE) {
        outsideRangeAssets = outsideRangeAssets.concat(group);
        return null; // Filter out the OUTSIDE_RANGE group
      }

      return groupAssetsByAssetTypeName(group, groupName);
    })
    .compact() // Remove null entries
    .orderBy([(obj: StackedBarChartDataObject) => obj.total], ['desc']) // Sort by total in descending order
    .value();

  const outsideRangeAssetOverTimeDataObject: StackedBarChartDataObject = groupAssetsByAssetTypeName(outsideRangeAssets, '');
  const allGroupLabels = dateBoundaries.slice(0, -1).map((boundaryStart, index) => {
    return generateGroupLabelForAssetOverTime(boundaryStart, timeInterval, language, index);
  });

  // Fill in missing groups
  const result: StackedBarChartDataObject[] = allGroupLabels.map((label) => {
    const matchingGroup = groupedAssets.find((group: StackedBarChartDataObject) => group.label === label);
    return matchingGroup || { label, total: 0 };
  });

  const aggregatedResult = aggregateAssetOverTimeDataObject(result, outsideRangeAssetOverTimeDataObject);
  return {
    startDate: new Date(dateBoundaries[0]),
    endDate: new Date(dateBoundaries[dateBoundaries.length - 1]),
    data: aggregatedResult.map((cur: StackedBarChartDataObject) => addColorKeysToDataObj(cur, customAssetTypeColorMapping)),
  };
};

/**
 * Filters and extracts relevant data keys for visualization from a BarDatum object, excluding color keys and formatting data for display.
 *
 * @function filterDataKeys
 * @param {StackedBarChartDataObject} data - The data object representing a single bar in a bar chart.
 * @returns {DataType[]} Array of objects with formatted asset type, value, and color for visualization.
 */
export const filterDataKeys = (data: StackedBarChartDataObject): DataType[] => {
  const filteredData = Object.entries(data).reduce((acc: DataType[], [key, value]) => {
    if (key !== 'label' && key !== 'total' && key !== 'currentYear') {
      if (!key.toLowerCase().includes('color')) {
        const colorKey = `${key}Color`;
        if (colorKey in data) {
          acc.push({ type: assetTypeNameFormatter(key), amount: +value, color: data[colorKey].toString() });
        }
      }
    }
    return acc;
  }, []);
  return filteredData;
};

/**
 * Assigns the year to each object in the array based on its 'createdAt' property.
 * @param {Array} dataGroup - Array of objects containing a 'createdAt' property.
 * @returns {number } The updated array with a 'year' property added to each object.
 */
const assignYearBasedOnCreatedAt = (dataGroup: Array<AssetRawData>): number | undefined => {
  if (dataGroup.length) {
    return new Date(dataGroup[0].createdAt).getFullYear();
  }
};
