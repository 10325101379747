import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { TangiInput, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { RootState } from '_helpers';
import { getContinuationAnalysisById } from 'redux-toolkit/thunks/patentThunks';
import { InputContainer, InputRow, PatentButtonAndInputContainer, UploadButton } from 'pages/PatentXRay/components/PatentXRayContent/style';
import { Button } from './style';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import { ContinuationAnalysisData } from 'utils/types/patent/patent';
import ContinuationAnalysisStatusNotification from './ContinuationAnalysisStatusNotification';

const ContinuationAnalysisContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const continuationData: ContinuationAnalysisData = useSelector((state: RootState) => state.patent.continuationData);

  const patentIdentifier = useMemo(() => continuationData?.patentId || continuationData?.fileName || '', [continuationData]);

  const continuationJobStatus = useXRayJobStatus(
    (state: RootState) => state.patent.continuationLoaders,
    (state: RootState) => state.patent.continuationData.response,
  );

  const isDisabled = continuationJobStatus === XRAY_JOB_STATUS.STARTED;

  const { register, handleSubmit } = useForm({
    mode: 'all',
  });

  const isAdmin = true; //TODO: who is admin?

  const onSubmit = (data: { exposurePatentId: string }) => {
    dispatch(getContinuationAnalysisById({ patentId: data.exposurePatentId, isAdmin, clientId }));
  };

  const handleCancelProcess = () => {
    dispatch(patentActions.resetEntireContinuationState());
  };

  return (
    <PatentButtonAndInputContainer onSubmit={handleSubmit(onSubmit)}>
      <InputRow>
        <InputContainer>
          <TangiInput
            className="patent-xray-input"
            register={register}
            name="exposurePatentId"
            placeholder={t('DISCOVERY_LOBBY.PLACEHOLDER.PATENT_XRAY')}
            label=""
            error={null}
            smallText=""
            registerRules={{}}
            disabled={isDisabled}
          />
        </InputContainer>
        <Button smallbtn text={t('DISCOVERY_LOBBY.BUTTONS_TEXT.ANALYZE_AND_DOWNLOAD_REPORT')} type="submit" data-testid="search-patent" onClick={null} disabled={isDisabled} />
      </InputRow>
      <TangiTypography weight="bold" color={NEUTRAL_SHADES[900]}>
        {t('DISCOVERY_LOBBY.TITLE.OR')}
      </TangiTypography>
      <UploadButton
        smallbtn
        text={t('DISCOVERY_LOBBY.BUTTONS_TEXT.UPLOAD_PATENT_APPLICATION')}
        disabled={isDisabled}
        onClick={() => {
          dispatch(patentActions.toggleUploadPatentFileModal(true));
        }}
        data-testid="upload-mapping-button"
        svgIcon="upload"
      />
      <ContinuationAnalysisStatusNotification
        status={continuationJobStatus}
        patentIdentifier={patentIdentifier}
        details={continuationData.response.details}
        onCancel={handleCancelProcess}
        patentSource={continuationData.response.sourceType || XRAY_SOURCE_TYPE.BLANK}
      />
    </PatentButtonAndInputContainer>
  );
};

export default ContinuationAnalysisContent;
