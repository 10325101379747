import { useTranslation } from 'react-i18next';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';

import { TangiButton } from '_components/TangiLibrary';
import { IDFResultsResponse } from 'utils/types/inventionDisclosure/inventionDisclosure';

interface Props {
  data: IDFResultsResponse;
  input_text: string;
}

export const InventionExportButton = ({ data, input_text }: Props) => {
  const { t } = useTranslation();

  const exportToWord = () => {
    const paragraphStyle = {
      size: 24,
      font: 'Arial',
    };

    const doc = new Document({
      styles: {
        paragraphStyles: [
          {
            id: 'defaultParagraph',
            name: 'Default Paragraph',
            run: {
              size: paragraphStyle.size,
              font: paragraphStyle.font,
            },
          },
        ],
      },
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Claims from Invention Disclosure',
                  font: 'Arial',
                  size: 32,
                  bold: true,
                }),
              ],
              spacing: {
                after: 0,
              },
            }),
            ...data.claims.map(
              (claim) =>
                new Paragraph({
                  text: claim.text,
                  style: 'defaultParagraph',
                  spacing: {
                    after: 100,
                    before: 200,
                  },
                }),
            ),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Trade Secrets',
                  font: 'Arial',
                  size: 32,
                  bold: true,
                }),
              ],
              spacing: {
                after: 200,
              },
            }),
            ...data.trade_secrets.flatMap((secret) => [
              new Paragraph({
                children: [
                  new TextRun({
                    text: secret.subject,
                    font: 'Arial',
                    size: 24,
                    bold: true,
                  }),
                ],
                spacing: {
                  after: 200,
                },
              }),
              ...secret.bullets.map(
                (bullet) =>
                  new Paragraph({
                    text: bullet.text,
                    style: 'defaultParagraph',
                    spacing: {
                      after: 400,
                    },
                  }),
              ),
            ]),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Original Text',
                  font: 'Arial',
                  size: 32,
                  bold: true,
                }),
              ],
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              text: input_text,
              style: 'defaultParagraph',
              spacing: {
                after: 200,
              },
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc)
      .then((blob) => {
        saveAs(blob, 'InventionDisclosure.docx');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return <TangiButton size="large" variant="secondary" data-testid="exportWord" svgIcon="download" onClick={exportToWord} text={t('DASHBOARD_PAGE.HEADER.EXPORT_DATA')} smallbtn={true} />;
};
