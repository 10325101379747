import { PayloadAction, SerializedError, createSlice } from '@reduxjs/toolkit';
import {
  getPredictedPortfolioModalTable,
  getPredictedPortfolioGraphsResults,
  getPredictedPortfolioTradeSecretsExportTable,
  getPredictedPortfolioTradeSecretsTable,
} from 'redux-toolkit/thunks/predictedPortfolioThunks';
import { Pagination, PredictedPortfolioGraphsData, PredictedPortfolioTableData } from 'utils/types/predictedPortfolio/predictedPortfolio';

interface predictedPortfolioState {
  loading: {
    graph: boolean;
    table: boolean;
    exportTable: boolean;
    modalTable: boolean;
    exportModalTable: boolean;
  };
  error: string;
  graphsData: PredictedPortfolioGraphsData;

  tableData: PredictedPortfolioTableData;
  modalTable: PredictedPortfolioTableData;
  pagination: Pagination;
  modalPagination: Pagination;
}

const initPredictedPortfolioGraphsData = () => {
  return {
    count_objects: {
      total_patents: 0,
      total_ts: 0,
      total_subjects: 0,
      total_companies: 0,
    },
    graphs: {
      heatmap: [],
      treemap: [],
    },
    filters: {
      subjects: [{ id: '', value: '' }],
      patents: [{ id: '', value: '' }],
      years: [],
    },
    created_date: '',
    main_company: '',
  };
};

const initPredictedPortfolioTableData = () => {
  return {
    data: [],
    metadata: [{ total: 0, page: 0 }],
  };
};

const initialPagination = { page: 1, limit: 10 };

const initialState: predictedPortfolioState = {
  error: '',
  loading: {
    graph: false,
    table: false,
    exportTable: false,
    modalTable: false,
    exportModalTable: false,
  },
  graphsData: initPredictedPortfolioGraphsData(),
  tableData: initPredictedPortfolioTableData(),
  modalTable: initPredictedPortfolioTableData(),
  pagination: initialPagination,
  modalPagination: initialPagination,
};

const predictedPortfolioSlice = createSlice({
  name: 'predictedPortfolioData',
  initialState,
  reducers: {
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
    setModalPagination(state, action: PayloadAction<Pagination>) {
      state.modalPagination = action.payload;
    },
    setInitialPagination(state) {
      state.pagination = initialPagination;
    },
    setInitialModalPagination(state) {
      state.modalPagination = initialPagination;
    },
  },
  extraReducers: (builder) => {
    // getPredictedPortfolioTradeSecretsTable
    builder.addCase(getPredictedPortfolioTradeSecretsTable.pending, (state) => {
      state.loading.table = true;
    });
    builder.addCase(getPredictedPortfolioTradeSecretsTable.fulfilled, (state, action: PayloadAction<PredictedPortfolioTableData | null>) => {
      if (action.payload !== null) {
        state.tableData = action.payload;
      }
      state.loading.table = false;
    });
    // TODO: fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getPredictedPortfolioTradeSecretsTable.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.loading.table = false;
    });
    // getPredictedPortfolioGraphsResults
    builder.addCase(getPredictedPortfolioGraphsResults.pending, (state) => {
      state.loading.graph = true;
    });
    builder.addCase(getPredictedPortfolioGraphsResults.fulfilled, (state, action: PayloadAction<PredictedPortfolioGraphsData | null>) => {
      if (action.payload !== null) {
        state.graphsData = action.payload;
      }
      state.loading.graph = false;
    });
    // TODO: fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getPredictedPortfolioGraphsResults.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.loading.graph = false;
    });
    // getPredictedPortfolioTradeSecretsExportTable
    builder.addCase(getPredictedPortfolioTradeSecretsExportTable.pending, (state) => {
      state.loading.exportTable = true;
      state.loading.exportModalTable = true;
    });
    builder.addCase(getPredictedPortfolioTradeSecretsExportTable.fulfilled, (state) => {
      state.loading.exportTable = false;
      state.loading.exportModalTable = false;
    });
    builder.addCase(getPredictedPortfolioTradeSecretsExportTable.rejected, (state) => {
      state.loading.exportTable = false;
      state.loading.exportModalTable = false;
    });

    // getPredictedPortfolioModalTable
    builder.addCase(getPredictedPortfolioModalTable.pending, (state) => {
      state.loading.modalTable = true;
    });
    builder.addCase(getPredictedPortfolioModalTable.fulfilled, (state, action: PayloadAction<PredictedPortfolioTableData | null>) => {
      if (action.payload !== null) {
        state.modalTable = action.payload;
      }

      state.loading.modalTable = false;
    });

    // TODO: fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getPredictedPortfolioModalTable.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.loading.modalTable = false;
    });
  },
});

export const predictedPortfolioActions = predictedPortfolioSlice.actions;

export default predictedPortfolioSlice.reducer;
