import React, { useMemo } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import './Avatar.scss';
import defaultProfile from '../../assets/img/default_profile.png';
import { SECONDARY_SHADES, NEUTRAL_SHADES } from '../../utils/theme';
import { AVATAR_SIZES } from '../../utils/componentUtils';
import { AvatarContainerLink, AvatarContainerDiv, LetteredAvatar, AvatarImg, SharePointIconWrapper, TippyContainer } from './style';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';

function AvatarEmail({
  name,
  email,
  image,
  href = '#',
  styles,
  isLinkDisabled = false,
  color = SECONDARY_SHADES[500],
  bordered = false,
  size = AVATAR_SIZES.SM,
  showSharePointIcon = false,
  ...props
}) {
  if (image === 'default') {
    // eslint-disable-next-line
    image = defaultProfile;
  }

  const { t } = useTranslation();

  const bgColor = useMemo(() => {
    return image ? NEUTRAL_SHADES.WHITE : color;
  }, [image]);

  const names = name ? name.split(' ').reverse() : [];
  let initials = '';
  names.forEach((element) => {
    initials = element.slice(0, 1) + initials;
  });

  const tippyContent = useMemo(() => {
    return showSharePointIcon ? (
      <TippyContainer>
        <span>{email}</span>
        <span>{t('PEOPLE.TOOLTIP.SHARE_POINT')}</span>
      </TippyContainer>
    ) : (
      <div className="font-bold text-xs">{email}</div>
    );
  }, [showSharePointIcon, email]);

  return (
    <Tippy content={tippyContent}>
      {!isLinkDisabled ? (
        <AvatarContainerLink {...props} size={size} bgColor={bgColor} to={href} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
          {image ? (
            <AvatarImg className="text-capitalize object-cover" alt={name} src={image} />
          ) : (
            <LetteredAvatar className="fw-bolder uppercase">
              <span>{initials.toUpperCase()}</span>
            </LetteredAvatar>
          )}
          {showSharePointIcon && (
            <SharePointIconWrapper>
              <TangiSvgIcon component={'sharePointAvatar'} color="" />
            </SharePointIconWrapper>
          )}
        </AvatarContainerLink>
      ) : (
        <AvatarContainerDiv {...props} size={size} bgColor={bgColor} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
          {image ? (
            <>
              <AvatarImg className="text-capitalize object-cover" alt={name} src={image} />
            </>
          ) : (
            <LetteredAvatar className="fw-bolder uppercase">
              <span>{initials.toUpperCase()}</span>
            </LetteredAvatar>
          )}
          {showSharePointIcon && (
            <SharePointIconWrapper>
              <TangiSvgIcon component={'sharePointAvatar'} color="" />
            </SharePointIconWrapper>
          )}
        </AvatarContainerDiv>
      )}
    </Tippy>
  );
}

export { AvatarEmail };
