import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { TangiTypography, TangiSvgIcon } from '_components/TangiLibrary';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import PatentStatusNotification from './components/PatentStatusNotification';
import PatentButtonAndInput from '_components/PatentButtonAndInput/PatentButtonAndInput';
import XrayLobbyModal from './components/XrayLobbyModal';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import { getTradeSecretsFromPatent, uploadPatentApplication } from 'redux-toolkit/thunks/patentThunks';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { RootState } from '_helpers';
import { patentConstants, authConstants } from '_constants';
import { MIME_TYPES } from 'utils/enums';
import { loadStateFromLocalStorage, removeStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { NEUTRAL_SHADES } from 'utils/theme';
import { ContentContainer, TitleContainer, PopupInfoWrapper } from './style';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';

export enum ACTION_TYPE {
  PATENT_ID = 'Search By Patent Id',
  FILE = 'Search By Upload File',
}

const PatentXraySearch = () => {
  const [isXrayModal, setIsXrayModal] = useState<boolean>(false);

  const { isModalOpen, patentData, patentModel } = useSelector((state: RootState) => state.patent);
  const { activeAccount } = useSelector((state: RootState) => state.authentication);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const patentJobStatus = useXRayJobStatus(
    (state: RootState) => state.patent.patentLoaders,
    (state: RootState) => state.patent.patentData,
    (state: RootState) => state.patent.patentRequestInfo,
  );

  const { register, handleSubmit, watch } = useForm({
    mode: 'all',
  });

  const loadFirstTimeLogin = () => {
    const storedState = loadStateFromLocalStorage(authConstants.FIRST_TIME_LOGIN);
    if (!storedState) saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, { clientId: activeAccount._id, isFirstTime: true });
    return storedState?.isFirstTime;
  };

  const onSubmit = (data: { exposurePatentId: string }) => {
    if (data.exposurePatentId) {
      dispatch(getTradeSecretsFromPatent({ patentId: data.exposurePatentId.trim(), clientId, patentModel }));
    }
  };

  const handleUploadFile = useCallback(
    (file: File[]) => {
      batch(() => {
        dispatch(uploadPatentApplication({ clientId, files: file, patentModel }));
        dispatch(patentActions.toggleUploadPatentFileModal(false));
      });
    },
    [clientId, dispatch, patentModel],
  );

  const handleCancelProcess = () => {
    removeStateFromLocalStorage(patentConstants.PATENT_REQUEST_INFO);
    dispatch(patentActions.resetEntirePatentState());
  };

  const renderPatentXraySearchContent = () => {
    return (
      <ContentContainer onSubmit={handleSubmit(onSubmit)}>
        <TitleContainer className="d-flex gap-1">
          <TangiTypography color={NEUTRAL_SHADES[900]}>{t('DISCOVERY_LOBBY.CARD_TEXT.WRITE_SMARTER_PATENT_APPLICATIONS')}</TangiTypography>
          <PopupInfoWrapper onClick={() => setIsXrayModal(true)}>
            <TangiSvgIcon component="info" color={NEUTRAL_SHADES[800]} size="18px" />
          </PopupInfoWrapper>
        </TitleContainer>
        <PatentButtonAndInput
          buttonText={t('DISCOVERY_LOBBY.BUTTONS_TEXT.UPLOAD_PATENT_APPLICATION')}
          buttonDisabled={patentJobStatus === XRAY_JOB_STATUS.STARTED}
          buttonOnClick={() => {
            dispatch(patentActions.toggleUploadPatentFileModal(true));
          }}
          dividerText={t('DISCOVERY_LOBBY.TITLE.OR')}
          register={register}
          placeholder={patentJobStatus === XRAY_JOB_STATUS.STARTED ? patentData?.patent_id : `${t('DISCOVERY_LOBBY.PLACEHOLDER.ENTER_PATENT_NUMBER')}`}
          inputDisabled={patentJobStatus === XRAY_JOB_STATUS.STARTED}
          uploadButtonTestId="upload-mapping-button"
          inputTestId="exposurePatentId"
        />
        <PatentStatusNotification
          status={patentJobStatus}
          patentIdentifier={patentData?.filename || patentData?.patent_id || watch('exposurePatentId')}
          details={patentData?.details}
          resultsNum={patentData?.choice_0?.length || 0}
          onCancel={handleCancelProcess}
          onClick={() => history.push(generateRoute(AppRoutes.PATENT_X_RAY_INNER_PAGE, { clientId }))}
          patentSource={patentData?.source_type || XRAY_SOURCE_TYPE.BLANK}
        />
      </ContentContainer>
    );
  };

  useEffect(() => {
    dispatch(patentActions.setIsMappingTool(false));
  }, []);

  useEffect(() => {
    const isFirstTimeLogin = loadFirstTimeLogin();
    setIsXrayModal(isFirstTimeLogin ?? true);
  }, []);

  return (
    <>
      {renderPatentXraySearchContent()}
      <UploadFileModal
        acceptedFilesList={[MIME_TYPES.DOC, MIME_TYPES.DOCX].join(', ')}
        isOpen={isModalOpen.uploadPatentFile}
        title={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_PATENT_APPLICATION_FILE')}
        onClose={() => dispatch(patentActions.toggleUploadPatentFileModal(false))}
        onSubmit={handleUploadFile}
        submitButtonText={t('GENERAL.BUTTONS_TEXT.CONTINUE')}
        customDragNDropText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.DRAG_AND_DROP_WORD_FILE')}
        customDragNDropRejectText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_WORD_FILE')}
      />
      <XrayLobbyModal isOpen={isXrayModal} onClose={() => setIsXrayModal(false)} />
    </>
  );
};

export default PatentXraySearch;
