import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Container } from './style';

export const TangiAlert = ({ show, text, type = 'success', ...props }) => {
  if (show) {
    return (
      <Container className="tangi-alert" type={type} {...props}>
        {text}
      </Container>
    );
  }
  return null;
};

export const TangiAlertModal = ({ type = 'success', text, onSuccess, onError, show }) => {
  const [localShowAlert, setLocalShowAlert] = useState(true);
  useEffect(() => {
    let closeAlertTimer;
    if (show) {
      setLocalShowAlert(true);
      closeAlertTimer = setTimeout(() => {
        if (type === 'success' && onSuccess) {
          onSuccess();
        }
        if (type === 'error' && onError) {
          onError();
        }
        setLocalShowAlert(false);
      }, 3000);
    }
    return () => {
      clearTimeout(closeAlertTimer);
    };
  }, [show, text, type]);
  return (
    <Modal show={localShowAlert} backdrop="static" className="delete-confirm">
      <Container className="tangi-alert" type={type}>
        {text}
      </Container>
    </Modal>
  );
};
