import styled from 'styled-components';
import { Form, Modal } from 'react-bootstrap';

import { SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const ContentContainer = styled(Form)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${SPACING[2]} ${SPACING[0]} ${SPACING[2]} ${SPACING[3]};
  gap: ${SPACING[3]};
`;

export const TitleContainer = styled.div`
  margin-top: ${SPACING[6]};
`;

export const TitleCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PopupInfoWrapper = styled.div`
  cursor: pointer;
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const IconAndText = styled(FlexContainer)`
  .tangi-icon-container {
    margin-top: 6px;
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[0]} ${SPACING[4]};
  max-width: 472px;
  gap: ${SPACING[2]};
  margin-bottom: 10px;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: 0px 20px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
