import styled from 'styled-components';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon } from '_components/TangiLibrary';
import { SPACING } from 'utils/theme';

export const LogoutContainer = styled(NavDropdown.Item)`
  display: flex;
  gap: ${SPACING[1]};
  align-items: center;
`;

interface Props {
  handleLogout: () => void;
}
const Logout = ({ handleLogout }: Props) => {
  const { t } = useTranslation();
  return (
    <LogoutContainer className="ff-din-regular fw-bolder header-dropdown" eventKey="4.2" data-testid="logout" onClick={handleLogout}>
      <TangiSvgIcon component="logout" />
      <div>{t('HEADER.LOG_OUT')}</div>
    </LogoutContainer>
  );
};

export default Logout;
