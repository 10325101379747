import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { RootState } from '_helpers';
import GeneratePeopleFiltersDropDown from './GeneratePeopleFiltersDropDown';
import { DropdownConfig, FILTERS_ID, FilterData, FilterDataItem, RenderSelectedComponentProps, SelectedFilter } from '../types';
import { createableRoles } from 'utils/roles';
import { Role } from 'utils/types/role/role';
import { useCallback, useMemo } from 'react';
import { sharePointOptions, trainingProgressOptions } from '../utils';

const PeopleSelectedFilterDropDown = ({ selectedFilter, filter, onChange, setShowFilterOptions, handleSelectedOptions }: RenderSelectedComponentProps) => {
  const businessUnits = useSelector((state: RootState) => state.assetMetaData.businessUnits);
  const { roles, countries } = useSelector((state: RootState) => state.users.peoplePage.filters.options);

  const filteredRoles = useMemo(
    () =>
      roles
        .filter((role: Role) => createableRoles.some((creatableRole) => role.name === creatableRole.value))
        .map((role: SelectedFilter) => ({
          id: role.id,
          name: role.name,
        })),
    [roles],
  );

  const config: DropdownConfig = useMemo(
    () => ({
      [FILTERS_ID.ROLE]: {
        title: t('PEOPLE.SELECT.ROLE'),
        itemList: filteredRoles,
      },
      [FILTERS_ID.COUNTRY]: { title: t('PEOPLE.SELECT.COUNTRIES'), itemList: countries },
      [FILTERS_ID.BUSINESS_UNIT]: {
        title: t('PEOPLE.SELECT.BUSINESS_UNIT'),
        itemList: businessUnits,
      },
      [FILTERS_ID.HIRE_DATE]: {
        title: t('PEOPLE.SELECT.HIRE_DATE'),
        itemList: [],
      },
      [FILTERS_ID.TRAINING]: {
        title: t('PEOPLE.SELECT.TRAINING'),
        itemList: trainingProgressOptions,
      },
      [FILTERS_ID.SHARE_POINT]: {
        title: t('PEOPLE.SELECT.SHARE_POINT'),
        itemList: sharePointOptions,
      },
    }),
    [filteredRoles, businessUnits, countries, trainingProgressOptions, t, roles],
  );

  const getFilterDataItem = (filter: FilterData, key: FILTERS_ID): FilterDataItem[] | undefined => {
    if (key in filter && !Array.isArray(filter[key])) {
      return undefined;
    }
    return filter[key] as FilterDataItem[] | undefined;
  };

  if (!selectedFilter || !config[selectedFilter.id as keyof DropdownConfig]) {
    return null;
  }

  const selectedData = getFilterDataItem(filter, selectedFilter.id);

  const filterSelected: SelectedFilter[] = useMemo(() => {
    if (selectedData && Array.isArray(selectedData)) {
      return selectedData.map((item) => ({ ...item })) as SelectedFilter[];
    }
    return [];
  }, [selectedData]);

  const currentConfig = useMemo(() => config[selectedFilter.id as keyof DropdownConfig], [filterSelected, selectedFilter, roles, filteredRoles, businessUnits, countries, trainingProgressOptions]);

  const applySelection = useCallback(
    (selectedItems: SelectedFilter[], id: FILTERS_ID) => {
      onChange(selectedItems, id);
      handleSelectedOptions?.(selectedItems, id);
    },
    [handleSelectedOptions, onChange],
  );

  const handleBackNavigation = () => {
    setShowFilterOptions(false);
  };

  return (
    <GeneratePeopleFiltersDropDown
      filterTitle={currentConfig.title}
      id={selectedFilter.id}
      selectedItems={filterSelected}
      itemList={currentConfig.itemList}
      onChange={applySelection}
      handleBackNavigation={handleBackNavigation}
    />
  );
};

export default PeopleSelectedFilterDropDown;
