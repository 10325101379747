import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  width: 766px;
  flex-direction: column;
`;

export const clientNameStyles = {
  textOverflow: 'ellipsis',
  maxWidth: '480px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
