import { useState, useEffect, useCallback } from 'react';
import { Form, Button, Container, Modal } from 'react-bootstrap';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import './AdminClientEditView.scss';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '../../../_components/Loader';
import { AdminDashboard } from '../AdminDashboard';
import { useIsMount } from '../../../utils/customHooks';
import { AsyncSelect, optionTypes } from '../../../_components/AsyncSelect';
import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { adminClientActions } from '../../../AdminRedux/_actions/adminClientActions';
import { TangiToggle } from '_components/TangiLibrary';

function AdminClientEditView({ history }) {
  const acceptedFilesList = 'image/png, image/jpeg';
  const { type, id } = useParams();
  const [show, setShow] = useState(false);
  const formTypes = {
    EDIT: 'edit',
    VIEW: 'view',
  };

  const clientStatusMsg = {
    CLIENT_UPDATED_SUCCESS: 'Client Updated Successfully',
  };

  const [isloading, setIsloading] = useState(false);
  const [defaults, setDefaults] = useState({ lawFirm: {}, lawyers: [] });
  const [reloadLawyer, setReloadLawyers] = useState(false);
  const [reloadVar, setReloadVar] = useState(0);
  const [previousLawyer, setPreviousLawyer] = useState('');

  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [clientStatus, setClientStatus] = useState(null);

  const dispatch = useDispatch();
  const { errors: clientError, editClient, getClient } = useSelector((state) => state.clientAdmin && state.clientAdmin);
  // Get Form data
  useEffect(() => {
    setIsloading(true);
    dispatch(adminClientActions.getAdminClientFromId(id));
  }, []);

  // Form updated Successfully
  useEffect(() => {
    if (!_.isEmpty(editClient)) {
      setIsloading(false);
      setClientStatus(clientStatusMsg.CLIENT_UPDATED_SUCCESS);
    } else if (!_.isEmpty(clientError)) {
      setIsloading(false);
    }
  }, [clientError, editClient]);

  // Reload the page on Success
  useEffect(() => {
    if (clientStatus === clientStatusMsg.CLIENT_UPDATED_SUCCESS) {
      setIsloading(false);
      setTimeout(() => {
        history.push('/adminhome');
      }, [3000]);
    }
  }, [clientStatus]);

  // Form hook methods
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: { lawfirm: '' },
  });

  const lawfirmWatch = useWatch({
    control,
    name: 'lawfirm',
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    delete data.lawfirm;
    if (!dirtyFields.lawfirm) {
      data.lawyers = [];
    }
    setIsloading(true);
    dispatch(adminClientActions.editAdminClient(data, id, _.isArray(data.logo)));
  };

  // Transform - Selected options
  function getOptions(items, ItemId = 'id', nameAccesor = 'name') {
    const temp = [];
    items.forEach((item) => {
      temp.push({ id: item[ItemId], name: item[nameAccesor] });
    });

    return temp;
  }

  // Dropzone side-effects and methods
  const isMount = useIsMount();
  const [errors1, setErrors1] = useState('');
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large' || err.code === 'file-invalid-type') {
          setErrors1(`Please upload only accepted formats! We are trying to update other formats type in future`);
          setTimeout(() => {
            setErrors1('');
          }, 3000);
        }
      });
    });
    if (fileRejections && !fileRejections[0]) {
      setExistingFiles([]);
      setFiles(acceptedFiles);
    }
  }, []);

  useEffect(() => {
    if (!isMount) {
      setValue('logo', files, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
    }
  }, [files]);

  // Populate the form
  function populateForm(data) {
    const { description, lawyers, logo, name, assetsPrefix, refCountStarter, permissions } = data;

    const hasAliasIdPermission = permissions?.aliasId?.hasPermission || false;
    const hasSharePointPermission = permissions?.sharePoint?.hasPermission || false;
    const hasIpAuditPermission = permissions?.ipAudit?.hasPermission || false;
    // If no sharePoint permission, set integratorEmail to an empty string
    const integratorEmail = hasSharePointPermission ? permissions?.sharePoint?.integratorEmail || '' : '';

    const lawfirm = lawyers[0].lawFirm[0];
    if (lawyers.length > 0 && lawyers[0].displayName) {
      setDefaults({ lawFirm: [{ id: lawfirm && lawfirm._id ? lawfirm._id : '', name: lawfirm && lawfirm.name ? lawfirm.name : '' }], lawyers: getOptions(lawyers, '_id', 'displayName') });
    }
    if (logo && logo.trim().length > 0) {
      const url = new URL(logo);
      const pathnames = url.pathname.split('/');
      setExistingFiles([{ name: pathnames.pop(), link: logo }]);
    }

    reset({
      description,
      logo,
      name,
      assetsPrefix,
      refCountStarter,
      permissions: {
        aliasId: { hasPermission: hasAliasIdPermission },
        ipAudit: { hasPermission: hasIpAuditPermission },
        sharePoint: {
          hasPermission: hasSharePointPermission,
          integratorEmail: integratorEmail,
        },
      },
    });
    setIsloading(false);
  }

  useEffect(() => {
    if (!_.isEmpty(getClient)) {
      populateForm(getClient);
    }
  }, [getClient]);

  // To Handle the Lawyer dropdown
  // to avoid the reload of lawyers on page initial render and lawfirm change
  useEffect(() => {
    if (previousLawyer !== lawfirmWatch) {
      if (lawfirmWatch && reloadVar > 0) {
        setReloadLawyers(true);
      } else if (previousLawyer !== lawfirmWatch) {
        setReloadVar((reload) => reload + 1);
      }
      setPreviousLawyer(lawfirmWatch);
    }
  }, [lawfirmWatch]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleExisting = () => {
    setFiles([]);
    setShow(false);
    setExistingFiles([]);
  };

  // Is the form in view mode
  function isView(formType) {
    if (formTypes.VIEW === formType) return true;
    return false;
  }

  return (
    <AdminDashboard title={<span className="text-capitalize">{`${type} Client`}</span>}>
      <Loader isLoading={isloading} />
      <Container fluid className="pb-8 pt-3 pt-md-8">
        <div className="mb-2 card py-4 p-3 px-5">
          {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}

          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Client Name */}
            <div className="mt-3 border-">
              <Form.Group className="mb-3" controlId="formClientName">
                <Form.Label className="text-dark fw-600">
                  Add Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control data-testid="title" disabled={isView(type)} type="text" placeholder="Client Name" {...register('name', { required: { value: true, message: 'Title is required' } })} />
                <div className="d-block invalid-feedback">{errors.name && errors.name.message}</div>
              </Form.Group>
            </div>

            {/* Client Logo */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formClientLogo">
                <Form.Label className="text-dark fw-600">Logo</Form.Label>

                <ul className="p-0">
                  {existingFiles &&
                    existingFiles.length > 0 &&
                    existingFiles.map((file) => (
                      <li key={file.name} style={{ listStyle: 'none' }} className="border border-black rounded-3 cursor-pointer">
                        <div className="px-4 py-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <img src={file.link} className="existing-logo img-responsive" alt="logo" />

                            {!isView(type) && (
                              <button type="button" onClick={() => handleShow('existing')} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                <i className="far fa-trash-alt" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {!isView(type) && (
                  <Controller
                    render={({ field: { ref, ...rest } }) => (
                      <Dropzone maxFiles={1} {...rest} onDrop={onDrop} accept={acceptedFilesList}>
                        {({ getRootProps, getInputProps, open }) => (
                          <div {...getRootProps()} ref={ref} onKeyPress={open} className="bg-secondary text-center rounded text-muted mb-3 dropZone" style={{ cursor: 'pointer' }} role="presentation">
                            <input {...getInputProps()} />
                            <svg className="my-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                fill="#828282"
                                d="M9.375 7.44595H11.107V15.3749C11.107 15.478 11.1914 15.5624 11.2945 15.5624H12.7008C12.8039 15.5624 12.8883 15.478 12.8883 15.3749V7.44595H14.625C14.782 7.44595 14.8687 7.26548 14.7727 7.14361L12.1477 3.82017C12.1301 3.79776 12.1077 3.77964 12.0821 3.76717C12.0565 3.75471 12.0285 3.74823 12 3.74823C11.9715 3.74823 11.9435 3.75471 11.9179 3.76717C11.8923 3.77964 11.8699 3.79776 11.8523 3.82017L9.22734 7.14127C9.13125 7.26548 9.21797 7.44595 9.375 7.44595ZM20.5781 14.6717H19.1719C19.0688 14.6717 18.9844 14.7561 18.9844 14.8592V18.4686H5.01562V14.8592C5.01562 14.7561 4.93125 14.6717 4.82812 14.6717H3.42188C3.31875 14.6717 3.23438 14.7561 3.23438 14.8592V19.4999C3.23438 19.9147 3.56953 20.2499 3.98438 20.2499H20.0156C20.4305 20.2499 20.7656 19.9147 20.7656 19.4999V14.8592C20.7656 14.7561 20.6812 14.6717 20.5781 14.6717Z"
                              />
                            </svg>
                            <span className="text-muted fs-6">
                              Drag &apos;n&apos; drop some files here, or <span className="card-link text-primary text-decoration-underline">Choose file</span>
                            </span>
                          </div>
                        )}
                      </Dropzone>
                    )}
                    name="logo"
                    control={control}
                  />
                )}
                {errors1 && <div className="alert alert-primary fade show text-center w-100">{errors1}</div>}
                <ul className="p-0">
                  {files &&
                    files.length > 0 &&
                    files.map((acceptedFile) => (
                      <li key={acceptedFile.name} style={{ listStyle: 'none' }} className="border border-black rounded-3">
                        <div className="px-4 py-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <i aria-hidden="true" className={`${icons[getFileExtensionCard(acceptedFile.name)]} me-2 icon-big`} />
                              <div className="m-2">
                                <span>{acceptedFile.name}</span>
                              </div>
                            </div>
                            <button type="button" onClick={() => handleShow('new')} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                              <i className="far fa-trash-alt" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </Form.Group>
            </div>

            {/* Client Description */}
            <div className="mb-3 col-12 border-0 row">
              <Form.Group controlId="formDescription">
                <Form.Label className="text-dark fw-600">Description</Form.Label>
                <Form.Control disabled={isView(type)} as="textarea" style={{ height: '100px' }} {...register('description')} />
              </Form.Group>
            </div>

            {/* Client Law Firm  */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formClientLawFirm">
                <Form.Label className="text-dark fw-600">Law Firm</Form.Label>
                <Controller
                  name="lawfirm"
                  render={({ field: { ref, ...rest } }) => (
                    <AsyncSelect
                      isDisabled={isView(type)}
                      inputRef={ref}
                      selectStyles="ff-din-DemiBold"
                      type={optionTypes.LAWFIRMS}
                      {...rest}
                      reloadOptions
                      isEdit
                      defaults={defaults.lawFirm && defaults.lawFirm}
                      reloadVar={defaults.lawFirm && defaults.lawFirm}
                    />
                  )}
                  control={control}
                  // rules={{ required: { value: true, message: 'Lawfirm Field is required' } }}
                />
              </Form.Group>
            </div>

            {/* Client Law Firm - Lawyers */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formClientLawyers">
                <Form.Label className="text-dark fw-600">
                  Lawyers <span className={dirtyFields.lawfirm ? 'text-danger' : 'text-white'}>*</span>
                </Form.Label>
                <Controller
                  name="lawyers"
                  render={({ field: { ref, ...rest } }) => (
                    <AsyncSelect
                      isDisabled={isView(type) || !dirtyFields.lawfirm}
                      reloadOptions={reloadLawyer}
                      inputRef={ref}
                      selectStyles="ff-din-DemiBold"
                      type={optionTypes.LAWYERS}
                      {...rest}
                      isMulti
                      // isDisabled={!dirtyFields.lawfirm}
                      reloadVar={dirtyFields.lawfirm && lawfirmWatch}
                      lawFirmId={dirtyFields.lawfirm && lawfirmWatch}
                      isEdit
                      defaults={defaults?.lawyers}
                    />
                  )}
                  control={control}
                  rules={{ required: { value: dirtyFields.lawfirm, message: 'Lawyers Field is required' } }}
                />
              </Form.Group>
            </div>

            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formClientAssetsPrefix">
                <Form.Label className="text-dark fw-600">
                  Add Assets Prefix <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="6"
                  placeholder="Client Assets Prefix"
                  {...register('assetsPrefix', {
                    maxLength: { value: 6, message: 'Asset Prefix must be exactly 6 characters' },
                    minLength: { value: 6, message: 'Asset Prefix must be exactly 6 characters' },
                    required: { value: true, message: 'Asset Prefix is required' },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Only digits [0-9] are allowed!',
                    },
                  })}
                />
                <div className="d-block invalid-feedback">{errors.assetsPrefix && errors.assetsPrefix.message}</div>
              </Form.Group>
            </div>

            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formRefCountStarter">
                <Form.Label className="text-dark fw-600">
                  Add Reference Counter Starter <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  maxlength="6"
                  type="text"
                  placeholder="Assets Counter Starter"
                  {...register('refCountStarter', {
                    maxLength: { value: 6, message: 'Assets Reference Counter Starter must be exactly 6 characters' },
                    minLength: { value: 6, message: 'Assets Reference Counter Starter must be exactly 6 characters' },
                    required: { value: true, message: 'Assets Reference Counter Starter is required' },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Only digits [0-9] are allowed!',
                    },
                  })}
                />
                <div className="d-block invalid-feedback">{errors.refCountStarter && errors.refCountStarter.message}</div>
              </Form.Group>
            </div>

            <Form.Group className="mb-3" controlId="formXrayFeaturesPatent">
              <TangiToggle
                label="Enable Asset Creation from Alias ID"
                checked={watch('permissions.aliasId.hasPermission')}
                setChecked={() => setValue('permissions.aliasId.hasPermission', !watch('permissions.aliasId.hasPermission'))}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formQuickStart">
              <TangiToggle
                label="Enable Quick Start"
                checked={watch('permissions.ipAudit.hasPermission')}
                setChecked={() => setValue('permissions.ipAudit.hasPermission', !watch('permissions.ipAudit.hasPermission'))}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSharePointPermission">
              <TangiToggle
                label="Enable SharePoint integration"
                checked={watch('permissions.sharePoint.hasPermission')}
                setChecked={() => setValue('permissions.sharePoint.hasPermission', !watch('permissions.sharePoint.hasPermission'))}
              />
            </Form.Group>
            {watch('permissions.sharePoint.hasPermission') && (
              <div className="mt-3 border-0 row">
                <Form.Control
                  type="email"
                  placeholder="Enter user email"
                  {...register('permissions.sharePoint.integratorEmail', {
                    required: {
                      value: true,
                      message: 'Email is required for SharePoint',
                    },
                  })}
                />
              </div>
            )}

            {/* Sumbit Button */}
            {!isView(type) && (
              <div className="d-flex justify-content-end">
                <Button className="my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" type="submit">
                  Update
                </Button>
              </div>
            )}

            <div className="mt-3 row">
              <div style={{ backgroundColor: '#F75676' }} className={`alert alert-primary fade ${clientError && clientError.message ? 'show' : ''} rounded py-2 text-white`}>
                <span style={{ fontSize: '13px' }} className="mx-3">
                  {clientError && clientError.message}
                </span>
              </div>
              <div
                style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }}
                className={`alert alert-primary fade ${clientStatus === clientStatusMsg.CLIENT_UPDATED_SUCCESS ? 'show' : ''} rounded py-2 text-white`}
              >
                <span style={{ fontSize: '13px' }} className="mx-3">
                  {clientStatus && clientStatus}
                </span>
              </div>
            </div>
          </Form>
        </div>
        <div className="delete-confirm">
          <Modal show={show} onHide={handleClose} backdrop="static" className="delete-confirm">
            <Modal.Body>Are you sure you want to delete Logo?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="asset-delete-cancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="asset-delete-confirm" onClick={() => handleExisting()}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* ---------- */}
      </Container>
    </AdminDashboard>
  );
}

export { AdminClientEditView };
