import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';

export const FiltersRowContainer = styled.div`
  border-left: 1px solid ${NEUTRAL_SHADES[400]};
  width: 100%;
`;

export const FiltersContainer = styled.div`
  margin-left: 12px;
  display: flex;
  gap: ${SPACING[2]};
  flex-wrap: nowrap;
`;

// ----- Custom MultiSelect styles ----- //

export const CompliantTrainingContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  marginleft: 10px;
`;

export const CompliantTrainingTypography = styled.span`
  padding-top: ${SPACING[1]};
  font-size: 14px;
`;

export const DropDownItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-width: 240px;
  gap: 10px;
  cursor: pointer;
`;
