import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'react-bootstrap';

import { AuthContext } from '../../_hocs/Cognito/Auth';
import { TangiButton } from '_components/TangiLibrary';
import { RightContainer } from './styles';

export const ResetPasswordModal = ({ show, handleClose }) => {
  const { forgotPassword } = useContext(AuthContext);
  const [fieldValue, setFieldValue] = useState('');
  const [message, setMessage] = useState({ color: '', text: '' });
  const { t } = useTranslation();

  const handleSubmit = async () => {
    try {
      const response = await forgotPassword(fieldValue);
      if (response) {
        setMessage({ color: 'alert-success', text: t('LOGIN_PAGE.RESET_PASSWROD_MODAL.VERIFICATION_CODE') });
        setFieldValue('');
        setTimeout(() => {
          setMessage({ color: '', text: '' });
        }, 3000);
      }
    } catch (error) {
      setMessage({ color: 'alert-primary-fail', text: error.toString() });
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose} className="reset-password" backdrop="static">
      <Modal.Header>
        <h5 className="modal-title">
          <span className="h2 font-weight-normal text-dark mb-4">{t('LOGIN_PAGE.RESET_PASSWROD_MODAL.RESET_PASSWORD')}</span>
        </h5>
        <button type="button" className="close" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <div className="m-3 modal-body">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="text-dark fw-600">{t('LOGIN_PAGE.RESET_PASSWROD_MODAL.ENTER_EMAIL')}</Form.Label>
            <Form.Control
              className={fieldValue && 'border border-invalid'}
              onChange={(e) => setFieldValue(e.target.value)}
              name="email"
              value={fieldValue}
              type="email"
              placeholder={t('LOGIN_PAGE.RESET_PASSWROD_MODAL.EMAIL')}
            />
          </Form.Group>
          <div className="text-center flex-center-v flex-align-m container-action-form-auth">
            <RightContainer>
              <TangiButton text={t('LOGIN_PAGE.RESET_PASSWROD_MODAL.RESET_PASSWORD')} onClick={handleSubmit} />
            </RightContainer>
          </div>
          {message && message.text ? <div className={`alert ${message.color} text-center w-100`}>{message.text} </div> : ''}
        </Form>
      </div>
    </Modal>
  );
};
