import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkSPIntegrationStatus } from 'redux-toolkit/thunks/sharePointThunks';
import { RootState } from '_helpers';
import { clientConstants } from '_constants';
import { hasConnectedSharePoint, hasSharePointPermission } from 'utils/permissions';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import useRole from './useRole';

const useSharePointAccess = (clientId: string) => {
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const user = useSelector((state: RootState) => state.authentication.user);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const dispatch = useDispatch();

  const { isLawyer } = useRole();

  const client = isLawyer ? activeClient : activeAccount?.client ?? null;

  const { isSharePointEnabled, isSharePointConnected } = useMemo(() => {
    return {
      isSharePointEnabled: hasSharePointPermission(client, user),
      isSharePointConnected: hasConnectedSharePoint(client),
    };
  }, [client, user]);

  useEffect(() => {
    const sharepointJobId = loadStateFromLocalStorage(`${clientConstants.SHAREPOINT_INTEGRATION_JOB_ID}[${clientId}]`);
    if (isSharePointEnabled && !isSharePointConnected && sharepointJobId) {
      dispatch(checkSPIntegrationStatus({ jobId: sharepointJobId, clientId, isLawyer, isEditMode: isSharePointConnected }));
    }
  }, [clientId, isSharePointEnabled, isSharePointConnected]);

  return { isSharePointEnabled, isSharePointConnected };
};

export default useSharePointAccess;
