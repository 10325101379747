import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { acknowledgementActions } from '../../_actions/acknowledgementActions';
import { useClientAssets } from './useClientAssets';
import { TANGI_BUTTON_SIZES, TangiButton, TangiDropdownButton, TangiIconButton, TangiSearch, TangiSvgIcon, TangiTypography } from '../../_components/TangiLibrary';
import { Dashboard } from '../Dashboard';
import AssetsValidationModal from '../../_components/ValidationModal/AssetsValidationModal';
import DistributeAssetModal from '../../_components/DistributeAssetModal/DistributeAssetModal';
import SortByDropdown from '../../_components/SortByDropdown/SortByDropdown';
import AssetFilters from '../../_components/AssetFilters/AssetFilters';
import BannerSnackbar from '../../_components/BannerSnackbar/BannerSnackbar';
import AcknowledgementModal from '../../_components/AcknowledgementModal/AcknowledgementModal';
import DownloadAssetsButton from '_components/DownloadAssetsFilesButton/DownloadAssetsFilesButton';
import { CreateEditAsset } from '../../_components/CreateEditAsset';
import { ClientAssetCards } from '../../_components/ClientAssetCards';
import { ClientAssetsTable } from '../../_components/ClientAssetsTable';
import { DeleteModal, AlertModal } from '../../_components/Modals';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { excelExporters } from '../../utils/excelExporters';
import { adminsExceptLearn } from '../../utils/roles';
import { BUTTON_VARIANTS, BUTTON_SIZES } from '../../utils/componentUtils';
import { scrollToTop } from '../../utils/windowFuncs';
import plus from '../../assets/icons/plus.svg';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';
import { PageHeaderContainer, PageHeader, StyledPageTitle, PageHeaderButtonContainer, ContentViewContainer } from '../../utils/globalStyles';
import { AssetsSortContainer, FilterSearch, ViewModeButtonsContainer, SortContainer, AssetsAmountContainer, ClientAssetCardsContainer, SharePointSection } from './styles';
import './ClientAssets.scss';
import { formatDate } from 'utils/dateUtils';
import { getLastSharePointSync, getNewAddedUsers, sharepointSync } from 'redux-toolkit/thunks/sharePointThunks';
import useSharePointAccess from '_hooks/useSharePointAccess';
import { useAppDispatch } from '_helpers';

const ClientAssets = () => {
  const dispatch = useAppDispatch();

  const {
    handleSearch,
    setSearchParams,
    resetBatchAssets,
    setFilter,
    handleClearSearch,
    setSearchValue,
    handleDistributeAsset,
    setShowRecipientModal,
    setShowEditModal,
    handleDelete,
    setShowDeleteModal,
    clearDeleteStatus,
    handleUnlockAsset,
    handleAssetCreate,
    handleAssetEdit,
    setIsSingleSignatureRequest,
    setShowCreateAsset,
    handlePrivilegeLogDownload,
    handleGetAssets,
    searchParams,
    pagination,
    showCreateAsset,
    isSingleSignatureRequest,
    metaDataState,
    permissions,
    clientId,
    assets,
    assetsLoading,
    asset,
    filter,
    searchValue,
    assetOptions,
    showRecipientModal,
    showEditModal,
    showDeleteModal,
    isTableView,
    initialFilter,
    isFilterApplied,
  } = useClientAssets();

  const activeClient = useSelector((state) => state.lawfirm.activeClient);
  const { Role, activeAccount, user } = useSelector((state) => state.authentication);
  const { deleteAssetSuccess, deleteAssetError, deleteAssetStatus, loading: assetDeleteLoading, privilegeLogLoading, pendingAcknowledgementLoading } = useSelector((state) => state.asset);
  const { acknowledgementModalIsOpen, assetsNeedToBeAcknowledged } = useSelector((state) => state.acknowledgementReducer);

  const { isSyncLoading, lastSync, isLastSyncLoading } = useSelector((state) => state.sharePoint);

  const { isSharePointEnabled, isSharePointConnected } = useSharePointAccess(clientId);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);

  const { t } = useTranslation();

  const isDownloadAllAssetFiles = activeAccount?.specialAdminPermissions?.isDownloadAllAssetFiles;

  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);
  const isAdmin = useMemo(() => adminsExceptLearn.includes(Role), [Role]);

  const handleCreateAsset = () => {
    setShowCreateAsset(true);
  };

  const handleCloseAsset = () => {
    setShowConfirmModal(true);
    mixpanelEvents.closeAsset(clientName, Role, user.email);
  };

  const handleCloseConfirm = () => {
    dispatch(usersActions.setClearInvitedMultiple());
    setShowConfirmModal(false);
    setShowCreateAsset(false);
  };

  const closeBatchModal = () => {
    setShowBatchModal(false);
    resetBatchAssets();
  };

  const setSortValue = (sortValue) => {
    setSearchParams((prevState) => {
      return { ...prevState, sortValue };
    });
  };

  const handleSortAssets = (item) => {
    if (item.value === 'newest') {
      setSortValue('createdAt:desc');
    }
    if (item.value === 'recentlyModified') {
      setSortValue('updatedAt:desc');
    }
  };

  const openAllAcknowledgementRequests = () => {
    setIsSingleSignatureRequest(false);
    dispatch(acknowledgementActions.changeModalVisibility(true));
  };

  const getLastSharePointSyncIfConnected = useCallback(() => {
    if (isSharePointEnabled && isSharePointConnected) {
      dispatch(getLastSharePointSync());
    }
  }, [isSharePointEnabled, isSharePointConnected]);

  useEffect(() => {
    dispatch(assetsActions.setClearFilePathUrl());
    getLastSharePointSyncIfConnected();
  }, []);

  const handleSyncSharepoint = useCallback(async () => {
    try {
      const result = await dispatch(sharepointSync());
      unwrapResult(result);
      handleGetAssets();
      dispatch(getNewAddedUsers());
    } catch (error) {
      return null;
    }
  }, []);

  const renderTopPageButtons = () => {
    const isShowSharePointSection = isSharePointEnabled && isSharePointConnected && !!lastSync && !isLastSyncLoading;
    return (
      <PageHeaderButtonContainer>
        {isShowSharePointSection &&
          <SharePointSection>
            <TangiSvgIcon component="sharePointAssetTypeIcon" size={SPACING[4]} />
            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('SHARE_POINT_INTEGRATION.LAST_SYNCED', { DATE: formatDate(lastSync, 'DD MMM YYYY HH:mm') || '' })}</TangiTypography>
            <Tippy content={t('SHARE_POINT_INTEGRATION.TOOLTIP.SYNC')} placement="bottom">
              <span>
                <TangiIconButton onClick={handleSyncSharepoint} size={BUTTON_SIZES.LG} icon="refresh" variant={BUTTON_VARIANTS.TERTIARY} loading={isSyncLoading} />
              </span>
            </Tippy>
          </SharePointSection>
        }
        {isAdmin && (
          <>
            {isDownloadAllAssetFiles && <DownloadAssetsButton clientId={clientId} />}
            <Tippy content={t('ASSET_PAGE.TOOLTIP.PRIVILEGE_LOG_DOWNLOAD')} placement="bottom">
              <span data-testid="privilege-log-download">
                <TangiIconButton
                  variant="secondary"
                  icon="download"
                  size={BUTTON_SIZES.LG}
                  onClick={handlePrivilegeLogDownload}
                  disabled={privilegeLogLoading || !searchParams}
                  loading={privilegeLogLoading}
                />
              </span>
            </Tippy>
            <BatchUploadButton metaDataState={metaDataState} setShowBatchModal={setShowBatchModal} resetBatchAssets={resetBatchAssets} />
          </>
        )}
        {permissions?.Assetcreate &&
          <div>
            <TangiButton size={TANGI_BUTTON_SIZES.LARGE} text={t('ASSET_PAGE.BUTTONS_TEXT.CREATE_ASSET')} onClick={handleCreateAsset} icon={plus} />
          </div>
        }
      </PageHeaderButtonContainer>
    );
  };

  const renderPageHeader = () => {
    return (
      <PageHeaderContainer>
        <PageHeader>
          <StyledPageTitle className="ff-din-DemiBold">{t('ASSET_PAGE.TITLE.ASSETS')}</StyledPageTitle>
          {renderTopPageButtons()}
        </PageHeader>
      </PageHeaderContainer>
    );
  };

  const renderFilters = () => {
    return (
      isAdmin && (
        <FilterSearch className="search-filter-section-assets">
          <Tippy content={<div className="ff-din-regular">{t('ASSET_PAGE.FILTERS.SEARCH_BY_ASSET')}</div>}>
            <div>
              <TangiSearch handleSearch={handleSearch} setSearchValue={setSearchValue} searchValue={searchValue} placeholder={t('ASSET_PAGE.INPUT.SEARCH_ON_ASSETS')} width="250px" />
            </div>
          </Tippy>

          <AssetFilters filter={filter} setFilter={setFilter} initialFilter={initialFilter} isFilterApplied={isFilterApplied} onClearAll={handleClearSearch} />
        </FilterSearch>
      )
    );
  };

  const renderAcknowledgementSnackbar = () => {
    return !!assetsNeedToBeAcknowledged?.length && <BannerSnackbar handleClick={openAllAcknowledgementRequests} needToBeAcknowledgedNum={assetsNeedToBeAcknowledged.length} />;
  };

  const renderViewModeButtons = () => {
    return (
      <ViewModeButtonsContainer className="view-mode-assets-buttons">
        <Tippy content={t('ASSET_PAGE.TOOLTIP.GRID_VIEW')}>
          <span>
            <TangiIconButton icon="grid" isActive={!isTableView} variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => dispatch(assetsActions.setIsTableView(false))} />
          </span>
        </Tippy>
        <Tippy content={t('ASSET_PAGE.TOOLTIP.LIST_VIEW')}>
          <span>
            <TangiIconButton icon="list" isActive={isTableView} variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => dispatch(assetsActions.setIsTableView(true))} />
          </span>
        </Tippy>
      </ViewModeButtonsContainer>
    );
  };

  const renderAssetsSort = () => {
    const shouldDisplayAssetsTotal = assets?.data && assets.metadata && assets.metadata[0] && !!assets.metadata[0]?.total && !assetsLoading;
    return (
      <AssetsSortContainer>
        <AssetsAmountContainer data-testid="assets-number">
          {shouldDisplayAssetsTotal && (
            <>
              <TangiTypography>{assets.metadata[0].total}</TangiTypography>
              <TangiTypography>{t('ASSET_PAGE.TITLE.ASSETS')}</TangiTypography>
            </>
          )}
        </AssetsAmountContainer>
        <SortContainer className="sort-section-assets">
          <SortByDropdown onSelect={handleSortAssets} id="sort-assets" />
          {renderViewModeButtons()}
        </SortContainer>
      </AssetsSortContainer>
    );
  };

  const renderCreateAssetModal = () => {
    return (
      <Modal transition="Fade" size="lg" className="modal-asset" show={showCreateAsset} onHide={handleCloseAsset}>
        <CreateEditAsset handleCloseAsset={handleCloseAsset} onCreate={handleAssetCreate} />
      </Modal>
    );
  };

  const renderConfirmationModal = () => {
    return (
      <Modal transition="Fade" backdrop="static" size="sm" className="modal-close-confirm" show={showConfirmModal} onHide={handleCloseAsset}>
        <Modal.Header />
        <Modal.Body className="my-4">
          <span className="font-weight-normal text-dark text-center mb-4">{t('ASSET_PAGE.MODAL.CLOSE_ASSET')}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button type="button" className="px-5 btn btn-outline-primary" onClick={() => handleCloseConfirm()}>
            {t('GENERAL.BUTTONS_TEXT.YES')}
          </button>
          <button type="button" className="px-5 btn btn-primary text-white" onClick={() => setShowConfirmModal(false)}>
            {t('GENERAL.BUTTONS_TEXT.NO')}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderAssetsValidationModal = () => {
    return (
      <Modal transition="Fade" className="custom-modal-style" show={showBatchModal} onHide={closeBatchModal} backdrop="static">
        <Modal.Body>
          <AssetsValidationModal handleClose={closeBatchModal} clientId={clientId} />
        </Modal.Body>
      </Modal>
    );
  };

  const renderEditAssetModal = () => {
    return (
      <Modal
        transition="Fade"
        size="lg"
        className="edit-modal-asset"
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
      >
        <CreateEditAsset handleCloseAsset={() => setShowEditModal(false)} asset={asset} isEditMode={true} onEdit={handleAssetEdit} />
      </Modal>
    );
  };

  const renderDeleteAssetModal = () => {
    return (
      <div className="delete-confirm">
        <DeleteModal
          show={showDeleteModal}
          title={t('ASSET_PAGE.MODAL.ARE_YOU_SURE')}
          body={`${t('ASSET_PAGE.MODAL.DELETE_ASSET')} ${asset?.name}?`}
          loading={assetDeleteLoading}
          handleDelete={() => handleDelete(asset.id)}
          onClose={() => setShowDeleteModal(false)}
        />
        <AlertModal show={deleteAssetSuccess || deleteAssetError} onClose={clearDeleteStatus} type={deleteAssetSuccess ? 'success' : 'error'} body={deleteAssetStatus?.message} />
      </div>
    );
  };

  return (
    <Dashboard title="">
      {renderAcknowledgementSnackbar()}
      {renderPageHeader()}
      {renderFilters()}
      {renderAssetsSort()}
      <AssetsView
        tableView={isTableView}
        assets={assets}
        assetsLoading={assetsLoading || metaDataState.loading}
        handleDistributeAsset={handleDistributeAsset}
        assetOptions={assetOptions}
        handleUnlockAsset={handleUnlockAsset}
        isAdmin={isAdmin}
        dispatch={dispatch}
        pagination={pagination}
      />
      {/* Modals */}
      {renderCreateAssetModal()}
      {renderConfirmationModal()}
      {renderAssetsValidationModal()}
      <DistributeAssetModal show={showRecipientModal} onClose={() => setShowRecipientModal(false)} asset={asset} />
      {renderEditAssetModal()}
      {renderDeleteAssetModal()}
      <AcknowledgementModal
        isOpen={acknowledgementModalIsOpen}
        onClose={() => {
          dispatch(acknowledgementActions.changeModalVisibility(false));
          dispatch(assetsActions.setClearAsset());
        }}
        isSingleRequest={isSingleSignatureRequest}
        items={assetsNeedToBeAcknowledged}
        singleItem={asset}
        handleManageAllRequests={openAllAcknowledgementRequests}
        loading={pendingAcknowledgementLoading}
      />
    </Dashboard>
  );
};

// --------- UI Sections --------- //

const AssetsView = ({ tableView, assets, assetsLoading, handleDistributeAsset, assetOptions, handleUnlockAsset, isAdmin, dispatch, pagination }) => {
  const pageCount = assets && assets?.metadata && assets?.metadata[0]?.total && pagination?.limit ? Math.ceil(assets.metadata[0].total / pagination.limit) : 0;

  const handlePageClick = (event) => {
    const newPageNum = event.selected + 1;
    dispatch(assetsActions.setPagination({ ...pagination, page: newPageNum }));
  };

  useEffect(() => {
    if (!tableView) {
      scrollToTop();
    }
  }, [pagination, tableView]);

  return (
    <ContentViewContainer className="assets-content-view-container">
      {tableView ? (
        <ClientAssetsTable
          assets={assets}
          handleDistributeAsset={handleDistributeAsset}
          loading={assetsLoading}
          assetOptions={assetOptions}
          handleUnlockAsset={handleUnlockAsset}
          isAdmin={isAdmin}
          pagination={pagination}
        />
      ) : (
        <ClientAssetCardsContainer>
          <ClientAssetCards assets={assets} handleDistributeAsset={handleDistributeAsset} loading={assetsLoading} assetOptions={assetOptions} handleUnlockAsset={handleUnlockAsset} isAdmin={isAdmin} />
          {pageCount > 1 && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={<TangiSvgIcon component="arrowSideRight" color={PRIMARY_SHADES[800]} />}
              previousLabel={<TangiSvgIcon component="arrowSideLeft" color={PRIMARY_SHADES[800]} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              forcePage={pagination?.page - 1 ?? -1}
              containerClassName="justify-content-center pagination"
              pageLinkClassName="pagination-page-link"
              breakLinkClassName="pagination-page-link"
              previousLinkClassName="pagination-page-link-controls"
              nextLinkClassName="pagination-page-link-controls"
              previousClassName="pagination-page-item"
              nextClassName="pagination-page-item"
              pageClassName="pagination-page-item"
              breakClassName="pagination-page-item"
              activeClassName="active"
              disabledClassName="disabled"
            />
          )}
        </ClientAssetCardsContainer>
      )}
    </ContentViewContainer>
  );
};

// --------- UI Components --------- //

const BatchUploadButton = ({ metaDataState, setShowBatchModal, resetBatchAssets }) => {
  const { t } = useTranslation();

  const exportExcelAssets = () => {
    excelExporters.exportAssetsTemplate(metaDataState);
  };

  const handleOpenBatchValidation = () => {
    setShowBatchModal(true);
    resetBatchAssets();
  };

  return (
    <div>
      <TangiDropdownButton
        text={t('ASSET_PAGE.DROP_DOWN.BATCH_UPLOAD')}
        variant="secondary"
        endIcon={true}
        loading={metaDataState.loading}
        options={[
          { text: t('ASSET_PAGE.DROP_DOWN.DOWNLOAD_EXCEL'), handleItem: exportExcelAssets },
          { text: t('ASSET_PAGE.DROP_DOWN.UPLOAD_EXCEL'), handleItem: handleOpenBatchValidation },
        ]}
      />
    </div>
  );
};

export { ClientAssets };
