import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const PatentButtonAndInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: ${SPACING[2]};
  padding-bottom: ${SPACING[2]};
`;

export const UploadButtonContainer = styled.div``;

export const DiverTypography = styled(TangiTypography)`
  padding-inline: ${SPACING[1]};
  word-break: keep-all;
`;

export const InputContainer = styled.div`
  width: 100%;
  input.tangi-input.form-control {
    height: ${SPACING[6]};
    ::placeholder {
      color: ${NEUTRAL_SHADES[900]} !important;
    }
  }
`;

export const SearchButtonContainer = styled.div``;
