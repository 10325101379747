const CryptoJS = require('crypto-js');
import { config } from '../config/config';

// TODO: not used anymore, remove
const encryptPlainText = (plainText, hash = config.passwordHashkey) => {
  return CryptoJS.AES.encrypt(plainText, hash).toString();
};
const decryptPlainText = (encryptedText, hash = config.passwordHashkey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, hash);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const passwordHashing = {
  encryptPlainText,
  decryptPlainText,
};
