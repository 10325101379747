import styled from 'styled-components';

import { RESULT_STATUS } from '../../../utils/enums';
import { ERROR_SHADES, SUCCESS_SHADES, WARNING_SHADES, SPACING } from '../../../utils/theme';

export const Container = styled.div<{ type?: RESULT_STATUS }>`
  font-family: 'DIN2014-REGULAR';
  display: flex;
  background-color: ${({ type }) => getAlertColor(type)};
  color: white;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-size: ${SPACING[3]};
  z-index: 200;
  padding: ${SPACING[2]} ${SPACING[3]} ${SPACING[2]} ${SPACING[2.5]};
  gap: ${SPACING[2]};
  border-radius: ${SPACING[1]};
  img {
    height: ${SPACING[3.5]};
  }
`;

const getAlertColor = (type: RESULT_STATUS = RESULT_STATUS.BLANK) => {
  switch (type) {
    case RESULT_STATUS.SUCCESS:
      return SUCCESS_SHADES[400];
    case RESULT_STATUS.ERROR:
      return ERROR_SHADES[500];
    case RESULT_STATUS.WARNING:
      return WARNING_SHADES[600];
    default:
      return 'transparent'; // Handles both RESULT_STATUS.BLANK and other possible types
  }
};
