import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './AssetStatus.scss';
import successIcon from '../../assets/img/check-circle-solid.png';
import pendingIcon from '../../assets/img/exclamation-circle-solid.png';
import warningIcon from '../../assets/img/exclamation-triangle-solid.png';

function AssetStatus({ disable = false, status }) {
  let div;
  if (status === 'success') {
    div = (
      <Tippy disabled={disable} content={<div className="font-bold text-xs">Compliant</div>}>
        <img data-testid="compliant-icon" className="m-auto" width="16" height="16" alt="..." src={successIcon} />
      </Tippy>
    );
  } else if (status === 'pending') {
    div = (
      <Tippy disabled={disable} content={<div className="font-bold text-xs">Pending Acknowledgement</div>}>
        <img data-testid="pending-icon" className="m-auto" width="16" height="16" alt="..." src={pendingIcon} />
      </Tippy>
    );
  } else if (status === 'warning') {
    div = (
      <Tippy disabled={disable} content={<div className="font-bold text-xs">Pending Acknowledgement</div>}>
        <img data-testid="warning-icon" className="m-auto" width="16" height="16" alt="..." src={warningIcon} />
      </Tippy>
    );
  } else div = <div />;

  return <div>{div}</div>;
}

export { AssetStatus };
