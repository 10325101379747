import { apiCall } from './utils/restClient';
import { config } from '../../config/config';
import { headers, headersmultipart } from './utils/headers';

function getAdminAsset(page = 1, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'createdAt:asc';
  var url = `${config.apiUrl}${config.apiVersion}/assets`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminAssetById(assetId) {
  var url = `${config.apiUrl}${config.apiVersion}/assets`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    params: { id: assetId },
  };

  return apiCall(requestOptions);
}

function getAdminAssetBusinessValue(page = 1, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'name:asc';
  var url = `${config.apiUrl}${config.apiVersion}/businessValues`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminAssetStatus(page = 1, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'name:asc';
  var url = `${config.apiUrl}${config.apiVersion}/businessValues`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminAssetTags(page = 1, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'name:asc';
  var url = `${config.apiUrl}${config.apiVersion}/tags`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminAssetType(page = 1, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'name:asc';
  var url = `${config.apiUrl}${config.apiVersion}/assetTypes`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminAssetBvView(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/businessValue/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAssetTagsView(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/tag/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAssetTypeView(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/assetType/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAssetBvDelete(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/businessValue/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAssetTagsDelete(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/tag/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAssetTypeDelete(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/assetType/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAssetBvEdit(firmid, data) {
  var url = `${config.apiUrl}${config.apiVersion}/businessValue/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getAdminAssetTagsEdit(firmid, data) {
  var url = `${config.apiUrl}${config.apiVersion}/tag/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getAdminAssetTypeEdit(firmid, data) {
  var url = `${config.apiUrl}${config.apiVersion}/assetType/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function createAdminAsset(data) {
  var url = `${config.apiUrl}${config.apiVersion}/asset`;

  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function deleteAdminAsset(assetId) {
  var url = `${config.apiUrl}${config.apiVersion}/asset/${assetId}`;

  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function deleteAgreement(agreementId) {
  var url = `${config.apiUrl}${config.apiVersion}/agreement/${agreementId}`;

  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

// function deleteAdminUser(accountId, reassign) {
//   var url = `${config.apiUrl}${config.apiVersion}/account/permanent/${accountId}`;

//   if (reassign.reassignAssetsTo === 'null') {
//     const requestOptions = {
//       url: url,
//       method: 'DELETE',
//       headers: headers(),
//     };
//     return apiCall(requestOptions);
//   } else {
//     const requestOptions = {
//       url: url,
//       method: 'DELETE',
//       headers: headers(),
//       data: reassign,
//     };
//     return apiCall(requestOptions);
//   }
// }

// THIS IS TO SOFT DELETE ACCOUNT
function deleteAdminUser(accountId, clientId) {
  const url = `${config.apiUrl}${config.apiVersion}/account/${accountId}`;

  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
    data: { clientId },
  };
  return apiCall(requestOptions);
}

function shareAdminAsset(recipients, assetId) {
  const data = { recipients };

  var url = `${config.apiUrl}${config.apiVersion}/asset/${assetId}/recipient`;

  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function uploadAssetFiles(files, client, assetId) {
  const formData = new FormData();
  const filesToUpload = files;
  filesToUpload.forEach((fileToUpload, index) => {
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append(index, fileToUpload.name);
  });

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: { client, id: assetId, type: 'assets' },
  };

  return apiCall(requestOptions);
}

function updateAdminAsset(data, assetId) {
  var url = `${config.apiUrl}${config.apiVersion}/asset/${assetId}`;

  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}
export const adminAssetService = {
  getAdminAsset,
  getAdminAssetBusinessValue,
  getAdminAssetTags,
  getAdminAssetStatus,
  getAdminAssetType,
  getAdminAssetTagsView,
  getAdminAssetTagsDelete,
  getAdminAssetTagsEdit,
  getAdminAssetTypeView,
  getAdminAssetTypeEdit,
  getAdminAssetTypeDelete,
  getAdminAssetBvView,
  getAdminAssetBvEdit,
  getAdminAssetBvDelete,
  deleteAgreement,
  createAdminAsset,
  deleteAdminAsset,
  deleteAdminUser,
  shareAdminAsset,
  uploadAssetFiles,
  getAdminAssetById,
  updateAdminAsset,
};
