import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { TangiButton, TangiTypography } from '_components/TangiLibrary';
import InventionDisclosureStatusNotification from '../InventionDisclosureStatusNotification/InventionDisclosureStatusNotification';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { inventionDisclosureTextFinder } from 'redux-toolkit/thunks/inventionDisclosureThunks';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import { RootState } from '_helpers';
import { useHistory, useParams } from 'react-router-dom';
import { removeStateFromLocalStorage } from 'utils/localStorage';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { FORM_FIELDS, FormData } from '_components/InventionDisclosureSearch/types';
import { countWordsInText, MIN_WORDS, MAX_WORDS } from '_components/InventionDisclosureSearch/utils';
import { inventionDisclosureConstants } from '_constants/inventionDisclosureConstants';
import { ERROR_SHADES, NEUTRAL_SHADES } from 'utils/theme';
import { TextAreaContainer, StyledTextArea, TextAreaFooter, StyledForm, StyledTangiTypography } from './style';

const InventionDisclosureContent = () => {
  const [text, setText] = useState<string>('');
  const [countWords, setCountWords] = useState<number>(0);

  const idfData = useSelector((state: RootState) => state.inventionDisclosure.inventionDisclosureData);

  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const idfJobStatus = useXRayJobStatus(
    (state: RootState) => state.inventionDisclosure.idfLoaders,
    (state: RootState) => state.inventionDisclosure.inventionDisclosureData,
    (state: RootState) => state.inventionDisclosure.idfRequestInfo,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onSubmit',
  });

  const debounceCountWords = useCallback(
    debounce((inputText: string) => {
      setCountWords(inputText.length ? countWordsInText(inputText) : 0);
    }, 300),
    [],
  );

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value;
    setText(inputText);
    debounceCountWords(inputText);
  };

  const onSubmit = (data: FormData) => {
    if (data.inventionDisclosureText) {
      dispatch(inventionDisclosureTextFinder({ inventionDisclosureText: data.inventionDisclosureText.trim(), clientId }));
    }
  };

  const handleCancelProcess = () => {
    removeStateFromLocalStorage(inventionDisclosureConstants.IDF_REQUEST_INFO);
    dispatch(inventionDisclosureActions.resetEntireState());
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <TextAreaContainer disabled={idfJobStatus === XRAY_JOB_STATUS.STARTED}>
          <StyledTextArea
            data-testid="invention-disclosure-textarea"
            disabled={idfJobStatus === XRAY_JOB_STATUS.STARTED}
            value={text}
            rows={4}
            cols={10}
            placeholder={t('DISCOVERY_LOBBY.INVENTION_DISCLOSURE.TEXTAREA_PLACEHOLDER', { MAX_WORDS: `${MAX_WORDS}` })}
            {...register(FORM_FIELDS.inventionDisclosureText, {
              onChange: handleChange,
              validate: {
                minWords: (value: string) => countWordsInText(value) >= MIN_WORDS || t('DISCOVERY_LOBBY.INVENTION_DISCLOSURE.TEXT_TOO_SHORT_ERROR', { MIN_WORDS }),
                maxWords: (value: string) => countWordsInText(value) <= MAX_WORDS || t('DISCOVERY_LOBBY.INVENTION_DISCLOSURE.TEXT_TOO_LONG_ERROR', { MAX_WORDS }),
              },
            })}
          />
          <TextAreaFooter>
            <TangiTypography type="sub-body" color={countWords > MAX_WORDS ? ERROR_SHADES[500] : idfJobStatus === XRAY_JOB_STATUS.STARTED ? NEUTRAL_SHADES[600] : NEUTRAL_SHADES[700]}>
              {t('DISCOVERY_LOBBY.INVENTION_DISCLOSURE.WORDS_COUNT', { WORDS_COUNT: `${countWords}/${MAX_WORDS}` })}
            </TangiTypography>
            <TangiButton
              onClick={() => {}}
              smallbtn={true}
              text={t('DISCOVERY_LOBBY.BUTTONS_TEXT.ANALYZE')}
              type="submit"
              data-testid="invention-disclosure-find"
              disabled={idfJobStatus === XRAY_JOB_STATUS.STARTED}
            />
          </TextAreaFooter>
        </TextAreaContainer>
        {errors.inventionDisclosureText && (
          <StyledTangiTypography color={ERROR_SHADES[500]} type="sub-body">
            {errors.inventionDisclosureText.message}
          </StyledTangiTypography>
        )}
      </StyledForm>
      <InventionDisclosureStatusNotification
        status={idfJobStatus}
        details={idfData?.details}
        onCancel={handleCancelProcess}
        onClick={() => history.push(generateRoute(AppRoutes.INVENTION_DISCLOSURE_INNER_PAGE, { clientId }))}
      />
    </>
  );
};

export default InventionDisclosureContent;
