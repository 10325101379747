import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: center;
`;

export const SessionOverBody = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
`;

export const ModalButtonWrapper = styled.div`
  max-width: fit-content;
  margin-left: auto;
`;

export const customBodyTypographyStyles = `
  word-break: break-word;
  text-align: center;
  max-width: 328px;
`;
