import React, { useEffect, useState } from 'react';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import { useDispatch, useSelector } from 'react-redux';

import { BodyCard, BodyCardTitle, ColumnFlex, ContainerCard, FlexRow, FooterCard, HeaderCard, HeaderContainer, LoadingContainerCard, PointerDiv, TextContainerFooter } from './style';
import { Divider, StyledScrollDiv } from 'utils/globalStyles';
import { TANGI_BUTTON_SIZES, TangiButton, TangiIconButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { BUTTON_VARIANTS, NEUTRAL_SHADES, PRIMARY_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { useCopyToClipboard } from '_hooks/useCopyToClipboard';
import { ActiveBulletData } from 'pages/PatentTool/types';
import { RootState } from '_helpers';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { CloseButtonContainer, CustomSpinner, UploadModifiedFileCard } from 'pages/PatentTool/style';
import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';
import { ReactComponent as DraftApplication } from 'assets/tangiIcons/draftApplication.svg';

interface Props {
  activeBulletData: ActiveBulletData;
  mappingStatus: {
    isMappingToolStarted: boolean;
    isMappingToolFailed: boolean;
    isMappingLoading: boolean;
    isMappingFinished: boolean;
  };
}
export const MappingCard = ({ activeBulletData, mappingStatus }: Props) => {
  const [isShowUploadModifiedFile, setIsShowUploadModifiedFile] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { index, originalTexts, isTextForBulletFound, indexOfTextToShow, incrementIndexOfTextToShow, decrementIndexOfTextToShow } = activeBulletData;

  const icon = isTextForBulletFound ? 'matchIndication' : 'noMatchIndication';
  const text = isTextForBulletFound ? (
    <TangiTypography color={SUCCESS_SHADES[600]}>{t('PATENT_MAPPING_TOOL.TITLE.DISCLOSURE_FOUND_IN_FILE')}</TangiTypography>
  ) : (
    <TangiTypography color={SUCCESS_SHADES[900]}>{t('PATENT_MAPPING_TOOL.TITLE.MISSING_IN_FILE')}</TangiTypography>
  );
  const currentText = originalTexts.length > 0 ? originalTexts[indexOfTextToShow] : '';

  const { copyText, isCopiedText } = useCopyToClipboard(currentText);

  const patentData = useSelector((state: RootState) => state.patent.patentData);

  const { isMappingToolFailed, isMappingLoading } = mappingStatus;

  const renderUploadModifiedFile = () => {
    return (
      <UploadModifiedFileCard className={isShowUploadModifiedFile ? 'visible' : 'hidden'}>
        <CloseButtonContainer>
          <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => setIsShowUploadModifiedFile(false)} />
        </CloseButtonContainer>
        <DraftApplication width="45" height="52" />
        <TangiTypography color={NEUTRAL_SHADES[900]}>{t('PATENT_MAPPING_TOOL.EXTRA.MADE_IMPROVEMENTS_IN_FILE')}</TangiTypography>
        <TangiButton
          variant={BUTTON_VARIANTS.SECONDARY}
          text={t('PATENT_MAPPING_TOOL.EXTRA.UPLOAD_MODIFIED_FILE')}
          size={TANGI_BUTTON_SIZES.SMALL}
          onClick={() => dispatch(patentActions.toggleUploadPatentFileModal(true))}
          font={14}
          data-testid="upload-modified-file"
        />
      </UploadModifiedFileCard>
    );
  };

  //next version
  //   const renderOriginalText = () => {
  //     return (
  //       <>
  //         <TextContainerFooter>
  //           <TangiIconButton icon="arrowSideLeft" onClick={() => {}} disabled={false} />
  //           <TangiTypography>2/10</TangiTypography>
  //           <TangiIconButton icon="arrowSideRight" onClick={() => {}} disabled={false} />
  //         </TextContainerFooter>
  //       </>
  //     );
  //   };

  const renderPagination = () => {
    return (
      <TextContainerFooter>
        <TangiIconButton icon="arrowSideLeft" variant={BUTTON_VARIANTS.TERTIARY} onClick={decrementIndexOfTextToShow} disabled={indexOfTextToShow === 0} />
        <TangiTypography>
          Paragraph {indexOfTextToShow + 1}/{originalTexts?.length}
        </TangiTypography>
        <TangiIconButton icon="arrowSideRight" variant={BUTTON_VARIANTS.TERTIARY} onClick={incrementIndexOfTextToShow} disabled={originalTexts?.length === indexOfTextToShow + 1} />
      </TextContainerFooter>
    );
  };
  const renderHeader = () => {
    return (
      <HeaderCard>
        <HeaderContainer>
          <ColumnFlex>
            <TangiTypography type={TYPOGRAPHY_TYPES.HEADING_MD} weight="semibold">
              Bullet {index ? index + 1 : 1}
            </TangiTypography>

            <FlexRow>
              <TangiSvgIcon component={icon} color={NEUTRAL_SHADES['WHITE']} />
              {text}
            </FlexRow>
          </ColumnFlex>
          {/* next version */}
          {/* <ColumnFlex>{renderOriginalText()}</ColumnFlex> */}
        </HeaderContainer>
        <Divider />
      </HeaderCard>
    );
  };
  const renderBody = () => {
    const text = isTextForBulletFound ? currentText : t('PATENT_MAPPING_TOOL.TITLE.NO_REFERENCE');

    return (
      <BodyCard>
        {isTextForBulletFound && (
          <BodyCardTitle>
            <TangiTypography type={TYPOGRAPHY_TYPES.SUBHEADING} weight="semibold">
              {t('PATENT_MAPPING_TOOL.TOOLTIP.ORIGINAL_TEXT')}
            </TangiTypography>
            <Tippy content={isCopiedText || t('PATENT_MAPPING_TOOL.TOOLTIP.COPY')} placement="auto-start" interactive={true} hideOnClick={false}>
              <PointerDiv onClick={copyText} data-testid="copy-reference">
                <TangiSvgIcon component="copy" color={PRIMARY_SHADES[800]} />
              </PointerDiv>
            </Tippy>
          </BodyCardTitle>
        )}
        <StyledScrollDiv>
          <TangiTypography>{text}</TangiTypography>
        </StyledScrollDiv>
      </BodyCard>
    );
  };
  const renderFooter = () => {
    return <FooterCard>{isTextForBulletFound && renderPagination()}</FooterCard>;
  };

  const renderMappingToolContent = () => (
    <ContainerCard>
      {renderHeader()}
      {renderBody()}
      {renderFooter()}
      {patentData.source_type === XRAY_SOURCE_TYPE.FILE && renderUploadModifiedFile()}
    </ContainerCard>
  );

  /**
   * Sets a timer to update the state to show the upload modified file box after a specified delay.
   * @param {function} setIsShowUploadModifiedFile - A function to set the state for showing the component.
   * @returns {void}
   */
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsShowUploadModifiedFile(true);
    }, INTERVAL_IN_MILLISECONDS.TenSeconds);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {isMappingLoading ? (
        <LoadingContainerCard>
          <CustomSpinner data-testid="spinner" size="sm" animation="border" variant="primary" />
          <TangiTypography color={NEUTRAL_SHADES[800]}>{t('PATENT_MAPPING_TOOL.TITLE.MAPPING_ANALYSIS_IN_PROGRESS')}</TangiTypography>
        </LoadingContainerCard>
      ) : isMappingToolFailed ? (
        <LoadingContainerCard data-testid="errorNoMapping">
          <TangiTypography color={NEUTRAL_SHADES[800]}>{t('PATENT_MAPPING_TOOL.TITLE.MAPPING_ANALYSIS_NOT_FOUND')}</TangiTypography>
        </LoadingContainerCard>
      ) : (
        renderMappingToolContent()
      )}
    </>
  );
};
