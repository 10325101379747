/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';

import lawFirmAdapter from 'redux-toolkit/adapters/lawFirmAdapter';
import { lawFirmActions } from 'redux-toolkit/slices/lawfirmSlice';
import { CreateLawFirm, GetLawyerClient, GetLawyerClients } from 'utils/types/lawFirm/lawFirm';
import { lawFirmConstants } from '_constants';
import { Client } from 'utils/types/client/client';
import { AppDispatch } from '_helpers/store';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';

export const getLawyerClients = createAsyncThunk('/lawFirm/getLawyerClients', async ({ accountId, limit = lawFirmConstants.DEFAULT_LIMIT }: GetLawyerClients) => {
  const res: any = await lawFirmAdapter.getLawyerClients({ accountId, limit });
  return res ?? null;
});

export const getLawyerClient = createAsyncThunk('/lawFirm/getLawyerClient', async ({ accountId, clientId, limit = 1 }: GetLawyerClient) => {
  const res: any = await lawFirmAdapter.getLawyerClient({ accountId, clientId, limit });
  return res ?? null;
});

export const createLawFirm = createAsyncThunk('/partnership/createLawFirm', async (data: CreateLawFirm) => {
  const { logo, ...dataWithoutLogo } = data;
  const createdLawfirm: any = await lawFirmAdapter.createLawFirm(dataWithoutLogo);

  if (logo && typeof logo !== 'string') {
    await lawFirmAdapter.uploadLawfirmLogo({ data: logo, lawFirmId: createdLawfirm.id });
  }

  return createdLawfirm ?? null;
});

export const setActiveClientAndResetPartnerships = (client: Client | null) => {
  return (dispatch: AppDispatch) => {
    dispatch(partnershipActions.resetPartnerships());
    dispatch(lawFirmActions.setActiveClient(client));
  };
};
