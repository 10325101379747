import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormControl } from 'react-bootstrap';

import './AcknowledgeReceipt.scss';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { acknowledgeGetAsset, acknowledgePostAsset } from 'redux-toolkit/thunks/assetsThunks';
import { TangiButton } from '_components/TangiLibrary';
import { Loader } from '../Loader';
import { Star } from '../Star';
import { InvalidError } from '../InvalidError/InvalidError';
import { getFileExtensionCard, icons } from '../../utils/fileUtils';
import { getDataTime } from '../../utils/dateUtils';
import { RightButtonContainer } from './styles';

function AcknowledgeReceipt() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [inputs, setInputs] = useState({ username: null, passcode: null });
  const [usernametxt, setusernametxt] = useState(false);
  const [passcodetxt, setpasscodetxt] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [disablebtn, setDisable] = useState(false);
  const [acknowledgeData, setAcknowledgeData] = useState({});

  const { username, passcode } = inputs;
  const { getAcknowledge, postAcknowledge } = useSelector((state) => state.asset);
  const urlString = window.location.href;
  const url = new URL(urlString);
  const encodeToken = decodeURIComponent(url.searchParams.get('token'));

  useEffect(() => {
    setSpinner(true);
    dispatch(acknowledgeGetAsset(encodeToken));
  }, [dispatch]);

  useEffect(() => {
    if (postAcknowledge && postAcknowledge.tokens && postAcknowledge.tokens.access && postAcknowledge.tokens.access.token) {
      history.push(`/assets/preview?token=${postAcknowledge.tokens.access.token}`);
      setSpinner(false);
    } else if (postAcknowledge && postAcknowledge.message) {
      setSpinner(false);
    } else {
      setTimeout(() => {
        setSpinner(false);
      }, 7000);
    }
    if (getAcknowledge) {
      if (getAcknowledge.message) {
        setDisable(true);
        setSpinner(false);
      } else if (getAcknowledge && getAcknowledge.name) {
        setAcknowledgeData(getAcknowledge);
        setSpinner(false);
      } else {
        setTimeout(() => {
          setSpinner(false);
        }, 7000);
      }
    }
  }, [getAcknowledge, postAcknowledge]);

  function handleChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    if (name === 'username' && !value) {
      setusernametxt(true);
    } else if (name === 'passcode' && !value) {
      setpasscodetxt(true);
    } else {
      setusernametxt(false);
      setpasscodetxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (!username && !passcode) {
      setusernametxt(true);
      setpasscodetxt(true);
    } else if (!username) {
      setusernametxt(true);
      setpasscodetxt(false);
    } else if (!passcode || passcode.length < 6) {
      setusernametxt(false);
      setpasscodetxt(true);
    } else {
      setusernametxt(false);
      setpasscodetxt(false);
      setSpinner(true);
      dispatch(assetsActions.setAcknowledgeGetClear);
      dispatch(acknowledgePostAsset({ code: passcode, token: encodeToken }));
    }
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  return (
    <div>
      <Loader isLoading={isSpinner} />
      <div className={`invalid-feedback ${postAcknowledge && postAcknowledge.message ? 'd-block fs-6 text-center' : ''}`}>{postAcknowledge && postAcknowledge.message}</div>
      {getAcknowledge && getAcknowledge.message ? (
        <InvalidError type="acknowledge" />
      ) : (
        <div className="justify-content-center row acknowledge">
          <div className="col-md-7 col-lg-6">
            <div className="bg-white shadow border-0 card rounded">
              <div className="h2 lead card-header">Acknowledge Receipt</div>
              <div className="card-body">
                <p className="desc text-muted">Shared on : {getDataTime(acknowledgeData?.sharedOn, true)}</p>
                <div>
                  <div className="d-flex flex-column">
                    {acknowledgeData && acknowledgeData.files
                      ? acknowledgeData.files.map((acceptedFile, index) => (
                          <li key={index} style={{ listStyle: 'none', marginBottom: '3%' }} className="border border-black rounded-3">
                            <div className="px-4 py-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i aria-hidden="true" className={`${icons[getFileExtensionCard(acceptedFile.name)]} me-2 icon-big`} />
                                  <div className="m-2">
                                    <span>{acceptedFile.name}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))
                      : ' - '}
                    {acknowledgeData && acknowledgeData.urls
                      ? acknowledgeData.urls.map((acceptedUrl, index) => (
                          <li key={index} style={{ listStyle: 'none', marginBottom: '3%' }} className="border border-black rounded-3">
                            <div className="px-4 py-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i aria-hidden="true" className={`${icons.link} me-2 icon-big text-primary`} />
                                  <div className="m-2">
                                    <span>{acceptedUrl.label}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))
                      : ' - '}
                  </div>
                </div>
                <div>
                  <br />
                  <p className="card-text">
                    Please type your passcode below to confirm you acknowledge this is a trade secret, and that you will adhere to all of your confidentiality, employment, and/or service obligations.
                  </p>
                </div>
              </div>

              <div className="card-footer">
                <Form>
                  {/* Fix For The Chrome AutoComplet Bug */}
                  <input type="text" name="" value="" readOnly={true} style={{ display: 'none' }} />
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-dark fw-600">Your Name</Form.Label>
                    <Star />
                    <FormControl
                      disabled={disablebtn}
                      className={usernametxt ? 'border border-invalid' : ''}
                      onKeyUp={handleKeypress}
                      onChange={handleChange}
                      type="text"
                      name="username"
                      placeholder="Enter your name"
                      value={inputs.username}
                      autocomplete="new-password"
                    />
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Name is required.</div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-dark fw-600">Your Passcode</Form.Label>
                    <Star />
                    <FormControl
                      disabled={disablebtn}
                      className={passcodetxt ? 'border border-invalid' : ''}
                      maxLength="6"
                      onKeyUp={handleKeypress}
                      onChange={handleChange}
                      name="passcode"
                      type="password"
                      placeholder="Enter your 6 digit passcode"
                      value={inputs.passcode}
                      autocomplete="new-password"
                    />
                    <div className={`invalid-feedback ${passcodetxt ? 'd-block' : ''}`}>Passcode must be 6 digit.</div>
                  </Form.Group>
                  <RightButtonContainer>
                    <TangiButton text="Confirm" disabled={disablebtn} onClick={handleSubmit} />
                  </RightButtonContainer>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { AcknowledgeReceipt };
