import { ASSET_FILTERS } from '_components/AssetFilters/types';

/**
 * Represents an individual filter item with a name and an ID.
 */
interface FilterItem {
  name: string;
  id: string;
}

/**
 * Represents a filter object containing filter items for various options.
 */
export interface Filter {
  [option: string]: FilterItem[];
}

/**
 * Represents a transformed filter object where filter items are replaced
 * by arrays of their corresponding IDs.
 */
export interface FilterObject {
  [option: string]: string[];
}

// Define a map `handlers` where each key corresponds to a filter type, and each value is a function that takes an array of `FilterItem` and returns an array of strings.
const handlers: {
  [key: string]: (items: FilterItem[]) => string[];
} = {
  [ASSET_FILTERS.THIRD_PARTY]: (filterItem) => [filterItem.toString()], // Special handler for 'THIRD_PARTY'
  default: (items) => items.map((item) => item.id), // Default handler for other cases
};

/**
 * Transforms a filter object containing arrays of filter items into a new object
 * where arrays are replaced with arrays of their corresponding IDs.
 *
 * @param {Filter | undefined} filter - The input filter object.
 * @returns {FilterObject | undefined} - A new object with arrays replaced by arrays of IDs.
 */
export const getFilterObj = (filter: Filter | undefined): FilterObject | undefined => {
  if (!filter) return filter;

  const filterObj: FilterObject = {};
  for (const [key, filterItems] of Object.entries(filter)) {
    // Apply the appropriate handler, falling back to the default if none specified
    const handler = handlers[key] || handlers.default;
    filterObj[key] = handler(filterItems);
  }

  return filterObj;
};
