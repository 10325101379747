import React, { useCallback } from 'react';
import { isFunction } from 'lodash';

import { StyledTypography } from './style';
import { NEUTRAL_SHADES } from '../../../utils/theme';

export type TypographyType = 'heading-xl' | 'heading-lg' | 'heading-md' | 'subheading' | 'body' | 'sub-body' | 'caption' | 'x-small' | 'btn-medium-big' | 'btn-medium-big-semi' | 'button-sm';

export type WeightType = 'regular' | 'semibold' | 'bold';

export interface Props {
  type?: TypographyType;
  weight?: WeightType;
  color?: string;
  className?: string;
  children: React.ReactNode;
  customStyles?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  dataTestId?: string;
}

export const TangiTypography = ({
  children,
  type = 'body',
  weight = 'regular',
  color = NEUTRAL_SHADES.BLACK,
  className = '',
  customStyles = '',
  onMouseEnter,
  onMouseLeave,
  dataTestId = 'tangi-typography',
}: Props) => {
  const handleOnMouseEnter = useCallback(() => {
    if (isFunction(onMouseEnter)) {
      onMouseEnter();
    }
  }, [onMouseEnter]);

  const handleOnMouseLeave = useCallback(() => {
    if (isFunction(onMouseLeave)) {
      onMouseLeave();
    }
  }, [onMouseLeave]);

  return (
    <StyledTypography
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      data-testid={dataTestId}
      className={`tangi-typography ${className}`}
      type={type}
      weight={weight}
      color={color}
      customStyles={customStyles}
    >
      {children}
    </StyledTypography>
  );
};
