import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  margin-top: ${SPACING[4]};
  width: 100%;
  position: relative;
  gap: ${SPACING[1]};
`;

export const InputContainer = styled(FlexContainer)`
  width: 100%;
  align-items: center;
`;

export const AddFolderBtnContainer = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  .tangi-icon-container svg.tangi-icon-svg {
    width: 10.5px;
    height: 10.5px;
  }
`;
