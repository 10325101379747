import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const TemplateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  margin-left: ${SPACING[2]};
  margin-top: ${SPACING[1]};
`;
