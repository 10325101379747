import styled from 'styled-components';

import { SPACING } from 'utils/theme';

export const SpinnerContainer = styled.div`
  width: ${SPACING[5]};
  height: ${SPACING[5]};
  margin-right: ${SPACING[3]};
`;

export const TrioContainer = styled.div`
  display: flex;
  align-items: center;
  b {
    font-family: 'DIN2014-DemiBold';
  }
  div:nth-of-type(2) {
    flex: 1;
  }
`;

export const CancelButtonContainer = styled.div`
  width: 75px;
  align-self: flex-end;
  margin-inline: ${SPACING[1]} ${SPACING[4]};
`;

export const ErrorNotificationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[3]};
  b {
    font-family: 'DIN2014-DemiBold';
  }
`;
