import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { createUser, updateUser } from 'redux-toolkit/thunks/usersThunks';
import { getPartnershipById } from 'redux-toolkit/thunks/partnershipThunks';
import { TangiInput, TangiToggle, TangiCheckbox, TangiAlert, TangiButton, TangiAccountStatus, TangiTypography } from '../TangiLibrary';
import { validEmailPattern } from '../../utils/formUtils';
import { initialValues } from './utils';
import { IRoles } from '../../utils/roles';
import { DuoContainer } from 'utils/globalStyles';
import { Container, FormContainer, StatusContainer, Separator, FormFooter, ButtonContainer } from './style';

const AddEditPartnerModal = ({ isEditMode, show, partnerData, handleClose }) => {
  const { loading: userLoading, error: userError, status: userStatus } = useSelector((state) => state.users);
  const { partnership } = useSelector((state) => state.partnership);
  const { user } = useSelector((state) => state.authentication);
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });
  const [isAccountActive, setIsAccountActive] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: isEditMode ? partnerData : { ...initialValues, externalUser: user.externalUser },
  });

  const onClose = () => {
    clearErrors(['email', 'displayName']);
    reset({ email: '', displayName: '', externalUser: false });
    setAlertProps({ show: false, text: '', type: '' });
    handleClose();
  };

  useEffect(() => {
    if (userError) {
      setAlertProps(() => {
        return { show: true, type: 'error', text: userError };
      });
    }
  }, [userError]);

  useEffect(() => {
    if (userStatus) {
      dispatch(getPartnershipById(partnership._id ?? partnership.id));
    }
    onClose();
  }, [userStatus]);

  useEffect(() => {
    if (isEditMode) {
      setValue('email', partnerData.email);
      setValue('displayName', partnerData.displayName);
      setValue('externalUser', partnerData.externalUser);
    }
  }, [isEditMode]);

  const onSubmit = (data) => {
    let payload = data;
    if (isEditMode) {
      delete payload.status;
      delete payload.email;
      delete payload.externalUser;
      payload = {
        ...payload,
        role: IRoles.PARTNER,
      };
      dispatch(updateUser({ user: payload, id: partnerData.id }));
    } else {
      payload = {
        ...payload,
        role: IRoles.PARTNER,
        status: isAccountActive ? 'Pending' : 'Disabled',
        client: partnership.client,
        partnership: partnership._id,
      };
      const shouldCreateAgreement = false;
      dispatch(createUser({ user: payload, shouldCreateAgreement }));
    }
  };

  return (
    <Modal transition="Fade" className="partner-modal" show={show} onHide={onClose} backdrop="static" data-testid="partner-modal">
      <Modal.Header closeButton>
        <DuoContainer id="invite-modal-header">
          <TangiTypography type="heading-lg">
            <Trans
              t={t}
              i18nKey={isEditMode ? 'PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.EDIT' : 'PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.ADD_USER_TO'}
              values={{ NAME: isEditMode ? partnerData.displayName : partnership.name }}
            />
          </TangiTypography>
        </DuoContainer>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
              <div>
                {isEditMode ? (
                  <StatusContainer>
                    {t('PARTNERSHIP_ADD_EDIT_PARTNER.TOGGLE.STATUS')}
                    <TangiAccountStatus status={partnerData.status} />
                  </StatusContainer>
                ) : (
                  <TangiToggle
                    label={t('PARTNERSHIP_ADD_EDIT_PARTNER.TOGGLE.ACTIVE')}
                    checked={isAccountActive}
                    setChecked={() => setIsAccountActive((prevValue) => !prevValue)}
                    tooltip={
                      <>
                        <div>{t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.ACTIVE')}</div>
                        <br />
                        <div>{t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.NONACTIVE')}</div>
                      </>
                    }
                    disabled={isEditMode}
                  />
                )}
              </div>
              <TangiInput
                type="email"
                placeholder="email@example.com"
                label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.EMAIL')}
                name="email"
                required
                register={register}
                registerRules={{
                  pattern: {
                    value: validEmailPattern,
                    message: t('PARTNERSHIP_ADD_EDIT_PARTNER.ERROR_MESSAGE.INVALID_EMAIL_MESSAGE'),
                  },
                }}
                error={errors.email}
                disabled={isEditMode}
              />
              <TangiInput label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.DISPLAY_NAME')} name="displayName" required register={register} error={errors.displayName} />
              <TangiCheckbox label={t('PARTNERSHIP_ADD_EDIT_PARTNER.CHECKBOX.OKTA_MESSAGE')} register={register} name="externalUser" disabled={isEditMode} />
              <Separator />
            </FormContainer>
            <FormFooter>
              <ButtonContainer>
                <TangiButton variant="tertiary" text={t('GENERAL.BUTTONS_TEXT.CANCEL')} onClick={onClose} />
              </ButtonContainer>
              <ButtonContainer>
                <TangiButton type="submit" loading={userLoading} text={t('GENERAL.BUTTONS_TEXT.SAVE_CHANGES')} />
              </ButtonContainer>
            </FormFooter>
          </Form>
          <TangiAlert {...alertProps} />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditPartnerModal;
