export enum ChipTextType {
    ERROR = 'DISCOVERY_LOBBY.CHIP.ERROR',
    SUCCESS = 'DISCOVERY_LOBBY.CHIP.SUCCESS',
    WARNING = 'DISCOVERY_LOBBY.CHIP.WARNING',
  }
  
  export enum ChipType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
  }

  export interface ITangiChipProps {
  text: string | React.ReactNode;
  variant: ChipType;
}

  export type StyledChipProps = Pick<ITangiChipProps, 'variant'>;
  