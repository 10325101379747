import { adminClientContants } from '../_constants/adminClientContants';

const initialState = { results: [], LawFirm: [], count: [], editFirm: [], deleteFirm: [], getFirm: [], errors: {}, createClient: {}, uploadClientLogo: {}, getClient: {}, editClient: {}, sortBy: {} };

export function clientAdmin(state = initialState, action) {
  switch (action.type) {
    case adminClientContants.ADMIN_CLIENTS_SAVE_SORT:
      return {
        ...state,
        sortBy: action.sorting,
      };
    case adminClientContants.ADMIN_CLIENTS_REQUEST:
      return {
        ...state,
        results: [],
      };
    case adminClientContants.ADMIN_CLIENTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    case adminClientContants.ADMIN_CLIENTS_FAILURE:
      return {
        ...state,
        results: [],
      };
    case adminClientContants.ADMIN_CLIENTS_COUNT_REQUEST:
      return {
        ...state,
        count: [],
      };
    case adminClientContants.ADMIN_CLIENTS_COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload,
      };
    case adminClientContants.ADMIN_CLIENTS_COUNT_FAILURE:
      return {
        ...state,
        count: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_REQUEST:
      return {
        ...state,
        LawFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_SUCCESS:
      return {
        ...state,
        LawFirm: action.payload,
      };
    case adminClientContants.ADMIN_LAWFIRM_FAILURE:
      return {
        ...state,
        LawFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_DELETE_REQUEST:
      return {
        ...state,
        deleteFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_DELETE_SUCCESS:
      return {
        ...state,
        deleteFirm: action.payload,
      };
    case adminClientContants.ADMIN_LAWFIRM_DELETE_FAILURE:
      return {
        ...state,
        deleteFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_EDIT_REQUEST:
      return {
        ...state,
        editFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_EDIT_SUCCESS:
      return {
        ...state,
        editFirm: action.payload,
      };
    case adminClientContants.ADMIN_LAWFIRM_EDIT_FAILURE:
      return {
        ...state,
        editFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_GET_REQUEST:
      return {
        ...state,
        getFirm: [],
      };
    case adminClientContants.ADMIN_LAWFIRM_GET_SUCCESS:
      return {
        ...state,
        getFirm: action.payload,
      };
    case adminClientContants.ADMIN_LAWFIRM_GET_FAILURE:
      return {
        ...state,
        getFirm: [],
      };

    case adminClientContants.ADMIN_CLIENTS_CREATE:
      return {
        ...state,
        createClient: {},
        errors: {},
        uploadClientLogo: {},
      };

    case adminClientContants.ADMIN_CLIENTS_CREATE_SUCCESS:
      return {
        ...state,
        createClient: action.payload,
      };
    case adminClientContants.ADMIN_CLIENTS_CREATE_FAILURE:
      return {
        ...state,
        errors: action.errors,
        createClient: {},
      };
    case adminClientContants.ADMIN_CLIENTS_LOGO_SUCCESS:
      return {
        ...state,
        uploadClientLogo: action.payload,
      };
    case adminClientContants.ADMIN_CLIENTS_LOGO_FAILURE:
      return {
        ...state,
        errors: action.errors,
        uploadClientLogo: {},
      };

    case adminClientContants.ADMIN_CLIENT_REQUEST:
      return {
        ...state,
        getClient: {},
      };
    case adminClientContants.ADMIN_CLIENT_SUCCESS:
      return {
        ...state,
        getClient: action.payload,
      };
    case adminClientContants.ADMIN_CLIENT_FAILURE:
      return {
        ...state,
        getClient: {},
      };

    case adminClientContants.ADMIN_CLIENT_EDIT:
      return {
        ...state,
        editClient: {},
        errors: {},
        uploadClientLogo: {},
      };

    case adminClientContants.ADMIN_CLIENT_EDIT_SUCCESS:
      return {
        ...state,
        editClient: action.payload,
      };
    case adminClientContants.ADMIN_CLIENT_EDIT_FAILURE:
      return {
        ...state,
        errors: action.errors,
        editClient: {},
      };
    default:
      return state;
  }
}
