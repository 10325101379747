export const adminAuthConstants = {
  ADMIN_LOGIN_REQUEST: 'ADMIN_LOGIN_REQUEST',
  ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  ADMIN_LOGIN_FAILURE: 'ADMIN_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  API_KEY: 'API_KEY',
  SET_TOKEN_EXPIRED: 'SET_TOKEN_EXPIRED',
};
