import React from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import './AgreementTable.scss';

function AgreementTable({datatable}) {
  return (   
    <MDBDataTableV5 className="table-responsive h-75 "
        hover
        entries={10}
        pagesAmount={10}
        data={datatable}
        // checkbox
        // multipleCheckboxes
    />            
  );
}

export { AgreementTable };
