import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import { SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[3.5]};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const IconAndText = styled(FlexContainer)`
  .tangi-icon-container {
    margin-top: 6px;
  }
`;
export const BodyImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
export const BodyText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  width: 50%;
  justify-content: center;
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: row;
  padding: ${SPACING[0]} ${SPACING[4]};
  gap: ${SPACING[3]};
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  padding: 0px ${SPACING[3.5]} ${SPACING[3.5]};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
`;

export const CheckboxInput = styled.input`
  margin-right: ${SPACING[1]};
`;

export const CheckboxText = styled.span`
  min-width: 100%;
`;
