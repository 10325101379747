/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import React, { useEffect, useState, useCallback } from 'react';
import { isEmpty, debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../_helpers/store';
import { joinDiscoveryEnquiry } from 'redux-toolkit/thunks/clientThunks';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { Asset, SharedRecipient } from '../../utils/types/assets/assets';
import { Account } from 'utils/types/account/account';
import { TabKeyType } from '../../pages/Asset/Asset';
import { TangiTypography, TangiSvgIcon, TangiChip, TangiSearch, TangiButton, TangiIconButton, IconTypes, TangiToast } from '../../_components/TangiLibrary';
import { Avatar } from '../../_components/Avatar';
import { Permissions } from '../../utils/types/role/role';
import { ReactComponent as NoPeople } from '../../assets/img/people.svg';
import { ReactComponent as Person } from '../../assets/img/person.svg';
import { CREATED_FROM, RESULT_STATUS } from '../../utils/enums';
import { formatDate } from '../../utils/dateUtils';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { groupPartnersByPartnership, filterRecipientsByName, hasNoPartnerships, PartnershipGroup } from './utils';
import { IRoles, isRoleMatch } from '../../utils/roles';
import emptyAvatar from '../../assets/icons/empty-avatar.svg';
import sendIcon from '../../assets/icons/send-white.svg';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SUCCESS_SHADES } from '../../utils/theme';
import {
  Container,
  RecipientsAndWidgets,
  RecipientsContainer,
  WidgetsContainer,
  RecipientsCard,
  RecipientRow,
  AvatarContainer,
  RecipientsDivider,
  FlexRow,
  WidgetCard,
  WidgetNum,
  WidgetDataRow,
  PartnershipContainer,
  EmptyCard,
  NoSearchTextContainer,
  partnershipLinkStyles,
  ProChipContainer,
  TooltipContainer,
} from './style';
import { Client } from 'utils/types/client/client';

enum RECIPIENT_GROUPS {
  EMPLOYEES = 'Employees',
  CONTRACTORS = 'Contractors',
  PARTNERS = 'Partners',
  LAWYERS = 'Lawyers',
  NO_ACCOUNTS = 'Not Registered',
}

type RecipientsState = {
  employees: SharedRecipient[];
  contractors: SharedRecipient[];
  partners: SharedRecipient[];
  lawyers: SharedRecipient[];
  noAccounts: SharedRecipient[];
};

interface Props {
  asset: Asset;
  activeAccount: Omit<Account, 'client'> & {
    client: Omit<Client, 'id'> & {
      _id: string;
    };
  };
  activeTab: TabKeyType;
  assetSharePermission: boolean;
  handleAddAccount: (email: string) => void;
  permissions: Permissions;
  Role: IRoles;
}

const AssetRecipients = ({ asset, activeAccount, activeTab, assetSharePermission, handleAddAccount, Role, permissions }: Props) => {
  const [recipients, setRecipients] = useState<RecipientsState>({
    employees: asset?.recipientsEmployees || [],
    contractors: asset?.recipientsContractors || [],
    partners: asset?.recipientsPartners || [],
    lawyers: asset?.recipientsLawyers || [],
    noAccounts: asset?.recipientsNoAccounts || [],
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [isNoSearchResult, setIsNoSearchResult] = useState<boolean>(false);
  const [, setShowDistributeModal] = useState(false);

  const clientToastProps = useSelector((state: RootState) => state.client.clientToastProps);
  const loading = useSelector((state: RootState) => state.client.loading);
  const user = useSelector((state: RootState) => state.authentication.user);
  const authors = useSelector((state: RootState) => state.assetMetaData.authors);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUnsetSearchResult = () => {
    setRecipients({
      employees: asset?.recipientsEmployees || [],
      contractors: asset?.recipientsContractors || [],
      partners: asset?.recipientsPartners || [],
      lawyers: asset?.recipientsLawyers || [],
      noAccounts: asset?.recipientsNoAccounts || [],
    });
  };

  const handleSearchRecipient = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      if (value === '') {
        handleUnsetSearchResult();
        setIsNoSearchResult(false);
      } else {
        const filteredEmployees = filterRecipientsByName(recipients.employees, value);
        const filteredContractors = filterRecipientsByName(recipients.contractors, value);
        const filteredPartners = filterRecipientsByName(recipients.partners, value);
        const filteredLawyers = filterRecipientsByName(recipients.lawyers, value);
        const filteredNoAccounts = filterRecipientsByName(recipients.noAccounts, value);

        const noResults = [filteredEmployees, filteredContractors, filteredPartners, filteredLawyers, filteredNoAccounts].every(isEmpty);
        setIsNoSearchResult(noResults);

        setRecipients({
          employees: filteredEmployees,
          contractors: filteredContractors,
          partners: filteredPartners,
          lawyers: filteredLawyers,
          noAccounts: filteredNoAccounts,
        });
      }
    }, 300),
    [recipients],
  );

  const isRecipientContibutor = (accountId: string) => {
    return asset?.contributor?.find((contributor) => contributor?._id === accountId);
  };

  const isRenderAddUser = (recipient: any): boolean => {
    return !recipient?.account && !authors?.find((author: any) => author.email === recipient.email) && permissions.Employeecreate;
  };

  const handleJoinBeta = () => {
    dispatch(
      joinDiscoveryEnquiry({
        name: user?.fullName || activeAccount?.displayName || '',
        email: user.email,
        clientName: activeAccount?.client?.name ?? (activeClient?.name || ''),
        role: Role,
      }),
    );
  };

  useEffect(() => {
    setSearchValue('');
    handleUnsetSearchResult();
  }, [activeTab]);

  const renderRecipientRow = (recipient: SharedRecipient, index: number, arrayLength: number) => {
    return (
      <React.Fragment key={recipient._id}>
        <RecipientRow>
          <AvatarContainer>
            {recipient?.account?.isActive ? <Avatar name={recipient?.account?.displayName || recipient.email} email={recipient.email} /> : <Avatar image={emptyAvatar} />}
            <TangiTypography weight="semibold">{recipient?.account?.isActive ? recipient?.account?.displayName : recipient?.fullName || recipient?.email}</TangiTypography>
            {recipient?.account && renderMagicIcon(recipient?.account?.createdFrom, recipient?.account?.createdAt)}
            {recipient?.account && isRecipientContibutor(recipient?.account?._id) && <TangiChip text={t('ASSET_PAGE.CONTENT.CONTRIBUTOR')} maxWidth={120} />}
          </AvatarContainer>
          <FlexRow>
            {recipient?.acknowledged && recipient?.acknowledgedOn ? (
              <>
                <TangiTypography color={SUCCESS_SHADES[500]}>{formatDate(recipient.acknowledgedOn)}</TangiTypography>
                <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
              </>
            ) : (
              <>
                {isRenderAddUser(recipient) && (
                  <TangiButton svgIcon="addUser" text={t('GENERAL.BUTTONS_TEXT.ADD_USER')} onClick={() => handleAddAccount(recipient.email)} variant="tertiary" data-testid="add-user" />
                )}
                <TangiTypography color={NEUTRAL_SHADES[600]}>{t('ASSET_PAGE.CONTENT.NOT_SIGNED')}</TangiTypography>
              </>
            )}
          </FlexRow>
        </RecipientRow>
        {index !== arrayLength - 1 && <RecipientsDivider />}
      </React.Fragment>
    );
  };

  const renderMagicIcon = (createdFrom: string, createdAt: string) => {
    return createdFrom === CREATED_FROM.EMAIL_FINDER ? (
      <Tippy content={<div className="text-xs">{t('ASSET_PAGE.TOOLTIP.CREATED_BY_FINDER', { createdAt: formatDate(createdAt) })}</div>}>
        <span>
          <TangiSvgIcon component="discovery" />
        </span>
      </Tippy>
    ) : (
      createdFrom === CREATED_FROM.EMAIL_TRACKER && (
        <Tippy content={<div className="text-xs">{t('ASSET_PAGE.TOOLTIP.CREATED_BY_TRACKER', { createdAt: formatDate(createdAt) })}</div>}>
          <span>
            <TangiSvgIcon component="discovery" />
          </span>
        </Tippy>
      )
    );
  };

  const renderAccountsByRole = (recipients: SharedRecipient[], headerTranslation: string, headerKey: RECIPIENT_GROUPS) => {
    return (
      <RecipientsCard>
        <FlexRow>
          <TangiTypography type="heading-md" weight="semibold">
            {headerTranslation}
          </TangiTypography>
          <TangiTypography type="heading-md" weight="semibold" color={NEUTRAL_SHADES[800]}>
            • {recipients.length}
          </TangiTypography>
        </FlexRow>
        {headerKey === RECIPIENT_GROUPS.PARTNERS
          ? // for the old partners with no partnerships
            hasNoPartnerships(recipients)
            ? recipients.map((recipient, index) => renderRecipientRow(recipient, index, recipients.length))
            : // new partners grouped by partnership
              groupPartnersByPartnership(recipients).map((partnership: PartnershipGroup) => (
                <PartnershipContainer key={partnership.partnershipId} data-testid="partnership-container">
                  {activeAccount?.client?._id && !(Role === IRoles.LAWYER || Role === IRoles.LAWYER_ADMIN) ? (
                    <Link
                      to={{
                        pathname: `/client/${activeAccount.client._id}/partners/${partnership.partnershipId}`,
                      }}
                      data-testid="partnership-link"
                    >
                      <TangiTypography customStyles={partnershipLinkStyles}>{partnership?.partnershipName}</TangiTypography>
                    </Link>
                  ) : (
                    <TangiTypography>{partnership?.partnershipName}</TangiTypography>
                  )}
                  {partnership.recipients.map((recipient, index) => renderRecipientRow(recipient, index, partnership.recipients.length))}
                </PartnershipContainer>
              ))
          : recipients.map((recipient, index) => renderRecipientRow(recipient, index, recipients.length))}
      </RecipientsCard>
    );
  };

  const renderExternalUsersWidget = () => {
    const contractorsNum = asset?.recipientsContractors?.length || 0;
    const partnersNum = asset?.recipientsPartners?.length || 0;
    return (
      <Widget
        text={contractorsNum + partnersNum === 0 ? t('ASSET_PAGE.CONTENT.NO_EXTERNAL_USERS') : t('ASSET_PAGE.CONTENT.EXTERNAL_USERS')}
        icon="handshake"
        number={contractorsNum + partnersNum}
        isEmpty={contractorsNum + partnersNum === 0}
      />
    );
  };

  const renderSpecialAdminWidgets = () => {
    return (
      <WidgetsContainer>
        <Widget
          text={!!asset?.numAccountsCreatedFromFinder ? t('ASSET_PAGE.WIDGET.USERS_WERE_CREATED_BY_FINDER') : t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_FINDER')}
          icon="magnifier"
          number={asset?.numAccountsCreatedFromFinder || 0}
          isEmpty={!!!asset?.numAccountsCreatedFromFinder}
        />
        <Widget
          text={!!asset?.numAccountsCreatedFromTracker ? t('ASSET_PAGE.WIDGET.USERS_WERE_CREATED_BY_TRACKER') : t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_TRACKER')}
          icon="binoculars"
          number={asset?.numAccountsCreatedFromTracker || 0}
          isEmpty={!!!asset?.numAccountsCreatedFromTracker}
        />
        {renderExternalUsersWidget()}
      </WidgetsContainer>
    );
  };

  const renderAdminNotSpecialWidgets = () => {
    return (
      <WidgetsContainer>
        {renderExternalUsersWidget()}
        <Widget text={t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_FINDER')} icon="magnifier" number={0} isEmpty={true} invitation={true} handleTooltipClick={handleJoinBeta} loading={loading} />
        <Widget text={t('ASSET_PAGE.WIDGET.NO_USERS_CREATED_BY_TRACKER')} icon="binoculars" number={0} isEmpty={true} invitation={true} handleTooltipClick={handleJoinBeta} loading={loading} />
      </WidgetsContainer>
    );
  };

  return (
    <Container>
      <TangiSearch
        width="224px"
        placeholder={t('ASSET_PAGE.INPUT.SEARCH_BY_NAME_OR_EMAIL')}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleSearch={handleSearchRecipient}
        onChange={handleSearchRecipient}
        disabled={!!!asset?.recipients?.length}
      />
      <RecipientsAndWidgets>
        <>
          {!!asset?.recipients?.length ? (
            <>
              {isNoSearchResult ? (
                <EmptyCard data-testid="recipients-no-results">
                  <Person />
                  <NoSearchTextContainer>
                    <TangiTypography type="heading-md" weight="bold" color={NEUTRAL_SHADES[700]}>
                      {t('ASSET_PAGE.CARD.NO_RECIPIENT_FOUND')}
                    </TangiTypography>
                  </NoSearchTextContainer>
                </EmptyCard>
              ) : (
                <RecipientsContainer>
                  {!!recipients.employees?.length && renderAccountsByRole(recipients.employees, t('ASSET_PAGE.CARD.EMPLOYEES'), RECIPIENT_GROUPS.EMPLOYEES)}
                  {!!recipients.contractors?.length && renderAccountsByRole(recipients.contractors, t('ASSET_PAGE.CARD.CONTRACTORS'), RECIPIENT_GROUPS.CONTRACTORS)}
                  {!!recipients.partners?.length && renderAccountsByRole(recipients.partners, t('ASSET_PAGE.CARD.PARTNERS'), RECIPIENT_GROUPS.PARTNERS)}
                  {!!recipients.lawyers?.length && renderAccountsByRole(recipients.lawyers, t('ASSET_PAGE.CARD.LAWYERS'), RECIPIENT_GROUPS.LAWYERS)}
                  {!!recipients.noAccounts?.length && renderAccountsByRole(recipients.noAccounts, t('ASSET_PAGE.CARD.NOT_REGISTERED'), RECIPIENT_GROUPS.NO_ACCOUNTS)}
                </RecipientsContainer>
              )}
            </>
          ) : (
            <EmptyCard data-testid="recipients-no-people">
              <NoPeople />
              <TangiTypography type="heading-md" weight="bold" color={NEUTRAL_SHADES[700]}>
                {t('ASSET_PAGE.CARD.ASSET_HAS_NO_RECIPIENTS')}
              </TangiTypography>
              {assetSharePermission && (
                <div>
                  <TangiButton text={t('GENERAL.BUTTONS_TEXT.NOTIFY')} onClick={() => setShowDistributeModal(true)} icon={sendIcon} data-testid="notify" />
                </div>
              )}
            </EmptyCard>
          )}
          {isRoleMatch(Role, IRoles.EMPLOYEE_ADMIN) && (activeAccount?.client?.discoverySpecialAdminsCount ?? 0) > 0
            ? renderSpecialAdminWidgets()
            : isRoleMatch(Role, IRoles.EMPLOYEE_ADMIN) && renderAdminNotSpecialWidgets()}
        </>
      </RecipientsAndWidgets>
      <TangiToast
        {...clientToastProps}
        onSuccess={() => {
          dispatch(clientActions.setClientToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </Container>
  );
};

interface WidgetProps {
  text: string;
  icon: IconTypes;
  number: number;
  isEmpty?: boolean;
  invitation?: boolean;
  handleTooltipClick?: () => void;
  loading?: boolean;
}

const Widget = ({ text, icon, number, isEmpty = false, invitation = false, handleTooltipClick, loading }: WidgetProps) => {
  return (
    <WidgetCard isEmpty={isEmpty}>
      <WidgetDataRow>
        <TangiSvgIcon component={icon} color={isEmpty ? PRIMARY_SHADES[600] : NEUTRAL_SHADES.BLACK} />
        {invitation && (
          <Tippy
            disabled={!invitation}
            render={() => <ProTooltip handleTooltipClick={handleTooltipClick} loading={loading} />}
            trigger="mouseenter"
            placement="top-start"
            animation={false}
            interactive={true}
            // prevents Tippy from hiding if the cursor left it
            interactiveBorder={6}
            // makes tooltip to be behind the modal
            zIndex={1000}
          >
            <ProChipContainer>
              <TangiChip variant="primary" text="PRO" />
            </ProChipContainer>
          </Tippy>
        )}
        {!isEmpty && <WidgetNum>{number}</WidgetNum>}
      </WidgetDataRow>
      <TangiTypography color={isEmpty ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK}>{text}</TangiTypography>
    </WidgetCard>
  );
};

const ProTooltip = ({ handleTooltipClick, loading = false }: { handleTooltipClick: undefined | (() => void); loading?: boolean }) => {
  const { t } = useTranslation();

  return (
    <TooltipContainer>
      <TangiSvgIcon component="discovery" color={PRIMARY_SHADES[800]} size="22px" />
      <TangiTypography type="subheading" weight="semibold">
        {t('ASSET_PAGE.TOOLTIP.FINDER_IS_AVAILABLE')}
      </TangiTypography>
      <FlexRow>
        <TangiTypography>{t('ASSET_PAGE.TOOLTIP.CONTACT_US')}</TangiTypography>
        <TangiIconButton icon="arrowRight" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleTooltipClick ? handleTooltipClick : () => {}} loading={loading} />
      </FlexRow>
    </TooltipContainer>
  );
};

export default AssetRecipients;
