import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NOTIFICATION_VARIANTS, TangiCheckbox, TangiNotification, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { SecurityCard } from '../components/SecurityCard/SecurityCard';
import { SystemSecuritySelection, SystemSecurityType } from './types';
import { IpAuditActionStepSubmission, IpAuditStepKey } from '_components/IpAudit/types';
import { NEUTRAL_SHADES } from 'utils/theme';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { StepContainer, StepInnerContainer, StepTextContainer } from '../styles';
import { CheckboxWithTextCard, OptionCardsContainer } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { useIpAuditPopup } from '../../context/IpAuditPopup.provider';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { isArraysEqual } from '_components/IpAudit/utils';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';
import { alignTypographyWithCheckbox } from '_components/IpAudit/styles';
import useScrollToElement from '_hooks/useScrollToElement';

export const SystemSecurity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setHasStepChanges } = useIpAuditPopup();
  const { handleSubmit } = useForm();
  const systemSecurityAction: IpAuditActionStepSubmission = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.SYSTEM_SECURITY]);

  const { ref: scrollRef, scrollToElement } = useScrollToElement<HTMLDivElement>();

  const { handleStepChange } = useHandleSteps();
  const { getStepCurrentData, submitSystemSecurity } = useIpAuditContext();

  const initialValues = getStepCurrentData(IpAuditStepKey.SYSTEM_SECURITY);
  const [selectedOptions, setSelectedOptions] = useState<SystemSecuritySelection[]>(initialValues?.length ? initialValues : []);

  useEffect(() => {
    selectedOptions.includes('noneOfTheAbove') && scrollToElement();
  }, [selectedOptions]);

  useEffect(() => {
    if (systemSecurityAction.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.SYSTEM_SECURITY));
      handleStepChange(IpAuditStepKey.HR_PROCESS);
    }
  }, [systemSecurityAction.hasData]);

  useEffect(() => {
    const isChanges = !isArraysEqual(initialValues || [], selectedOptions);
    if (isChanges) setHasStepChanges(true);
    return () => setHasStepChanges(false);
  }, [selectedOptions]);

  const onSubmit = () => {
    submitSystemSecurity(selectedOptions);
    dispatch(mixpanelEvents.selectSystemSecurity(selectedOptions));
    dispatch(mixpanelEvents.completeIPAuditStep(IpAuditStepKey.SYSTEM_SECURITY));
  };

  const handleNoneOfTheAbove = () => {
    setSelectedOptions(selectedOptions.includes('noneOfTheAbove') ? [] : ['noneOfTheAbove']);
  };

  const handleOptionSelection = (option: SystemSecuritySelection) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes('noneOfTheAbove')) {
        return [option];
      }
      const isOptionSelected = prevSelectedOptions.includes(option);
      return isOptionSelected ? prevSelectedOptions.filter((item) => item !== option) : [...prevSelectedOptions, option];
    });
  };

  const renderText = (textKey: string) => (
    <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
      {t(textKey)}
    </TangiTypography>
  );

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTextContainer>
          <StepTitle stepKey={IpAuditStepKey.SYSTEM_SECURITY} />
          {renderText('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.DESCRIPTION')}
          {renderText('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.SELECTION')}
        </StepTextContainer>
        <form>
          <OptionCardsContainer>
            {Object.values(SystemSecurityType).map((systemOption) => (
              <SecurityCard<SystemSecuritySelection>
                key={systemOption}
                svgComponent={systemOption}
                systemOption={systemOption}
                isSelected={selectedOptions.includes(systemOption)}
                toggleSelection={handleOptionSelection}
              />
            ))}
          </OptionCardsContainer>
          <CheckboxWithTextCard onClick={handleNoneOfTheAbove}>
            <TangiCheckbox type="checkbox" data-cy="checkbox-none" checked={selectedOptions.includes('noneOfTheAbove')} readOnly name="none-of-the-above" />
            <TangiTypography customStyles={alignTypographyWithCheckbox}>{t('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.NONE_OF_THE_ABOVE')}</TangiTypography>
          </CheckboxWithTextCard>
        </form>
        {selectedOptions.includes('noneOfTheAbove') && <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.FALSE_COMPLETION')}</TangiNotification>}
        <div ref={scrollRef}></div>
      </StepInnerContainer>
      <StepFooter onSubmit={handleSubmit(onSubmit)} isContinueBtnDisabled={!selectedOptions.length} isContinueBtnLoading={systemSecurityAction.isLoading} />
    </StepContainer>
  );
};
