import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { IDFRequestInfo, InventionDisclosureData, Modes, MODE_TYPE, PotentialClaim } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { defaultModes, inventionDisclosureConstants } from '_constants/inventionDisclosureConstants';
import { getInventionDisclosureResults, inventionDisclosureTextFinder } from 'redux-toolkit/thunks/inventionDisclosureThunks';
import { loadStateFromLocalStorage, removeStateFromLocalStorage } from 'utils/localStorage';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XrayLoaders } from 'utils/types/xRay/xRay';

const initializeInventionDisclosureData = (): InventionDisclosureData => {
  return {
    response: null,
    status: XRAY_JOB_STATUS.BLANK,
    details: '',
    created_date: '',
    source_type: XRAY_SOURCE_TYPE.BLANK,
    account: '',
    client: '',
    filename: null,
    input_text: '',
  };
};

interface InventionDisclosureState {
  idfRequestInfo: IDFRequestInfo;
  error: string;
  idfLoaders: XrayLoaders;
  inventionDisclosureData: InventionDisclosureData;
  modes: Modes;
}

const initialState: InventionDisclosureState = {
  error: '',
  idfRequestInfo: loadStateFromLocalStorage(inventionDisclosureConstants.IDF_REQUEST_INFO),
  idfLoaders: { startLoader: false, getResultLoader: false },
  inventionDisclosureData: initializeInventionDisclosureData(),
  modes: defaultModes,
};

const inventionDisclosureSlice = createSlice({
  name: 'inventionDisclosure',
  initialState,
  reducers: {
    resetEntireState: () => {
      return {
        ...initialState,
      };
    },
    resetStateExceptRequestInfo: (state) => {
      const { idfRequestInfo } = state;
      return {
        ...initialState,
        idfRequestInfo,
      };
    },
    setInventionDisclosureDataInitState(state) {
      state.error = '';
      state.inventionDisclosureData = initializeInventionDisclosureData();
    },
    addClaim: (state, action: PayloadAction<PotentialClaim>) => {
      if (state.inventionDisclosureData.response) {
        state.inventionDisclosureData.response.claims.push(action.payload);
      }
    },
    deleteClaim: (state, action: PayloadAction<PotentialClaim>) => {
      const claimId = action.payload.id;

      if (state.inventionDisclosureData.response && state.inventionDisclosureData.response.claims) {
        state.inventionDisclosureData.response.claims = state.inventionDisclosureData.response.claims.filter((claim) => claim.id !== claimId);
        if (state.inventionDisclosureData?.response?.trade_secrets?.length) {
          state.inventionDisclosureData.response.trade_secrets = state.inventionDisclosureData.response.trade_secrets.map((ts) => {
            if (ts?.bullets?.length) {
              ts.bullets = ts.bullets.map((bullet) => {
                bullet.claim_ids = bullet.claim_ids.filter((associatedClaimId) => associatedClaimId !== claimId);
                return bullet;
              });
            }
            return ts;
          });
        }
      }
    },
    editClaim: (state, action: PayloadAction<Partial<PotentialClaim>>) => {
      const updatedClaim = action.payload;

      const claims = state.inventionDisclosureData.response?.claims;
      if (!claims) return;

      const claimIndex = claims.findIndex((claim) => claim.id === updatedClaim.id);

      if (claimIndex !== -1) {
        claims[claimIndex] = { ...claims[claimIndex], ...updatedClaim };
      }
    },
    setMode(state, action: PayloadAction<{ mode: MODE_TYPE; value: boolean }>) {
      const { mode, value } = action.payload;
      state.modes[mode] = value;
    },
    setClaimMode(state, action: PayloadAction<{ mode: MODE_TYPE; value: boolean; claimId: string }>) {
      const { mode, value, claimId } = action.payload;

      const claims = state.inventionDisclosureData.response?.claims;
      if (!claims) return;

      const claimIndex = claims.findIndex((claim) => claim.id === claimId);
      if (claimIndex === -1) return;

      claims.forEach((claim, index) => {
        if (index !== claimIndex) {
          claim.modes[mode] = false;
        }
      });

      claims[claimIndex].modes[mode] = value;
    },
    resetModes(state) {
      const claims = state.inventionDisclosureData?.response?.claims;
      const isJobSucceeded = state.inventionDisclosureData.status === XRAY_JOB_STATUS.SUCCEEDED;
      if (!claims || !isJobSucceeded) return;
      claims.forEach((claim) => {
        claim.modes = defaultModes;
      });
    },
    setAssetCreated(state, action: PayloadAction<number>) {
      if (state.inventionDisclosureData?.response?.trade_secrets?.length) {
        state.inventionDisclosureData.response.trade_secrets[action.payload].isAssetCreated = true;
      }
    },
    removeClaimIdFromTS(state, action: PayloadAction<string>) {
      const claimIdToRemove = action.payload;

      const tradeSecrets = state.inventionDisclosureData.response?.trade_secrets;
      if (!tradeSecrets) return;

      tradeSecrets.forEach((subject) => {
        subject.bullets.forEach((bullet) => {
          bullet.claim_ids = bullet.claim_ids.filter((claimId) => claimId !== claimIdToRemove);
        });
      });
    },
    resetTSFromClaim(state, action: PayloadAction<string>) {
      const claimIdToRemove = action.payload;

      const claims = state.inventionDisclosureData.response?.claims;
      if (!claims) return;

      claims.forEach((claim) => {
        if (claim.id === claimIdToRemove) claim.bullets_ids = [];
      });
    },
    setReorderedClaims(state, action: PayloadAction<PotentialClaim[]>) {
      const reorderedClaims = action.payload;
      if (!state.inventionDisclosureData.response?.claims) return;
      state.inventionDisclosureData.response.claims = reorderedClaims;
    },
  },

  extraReducers: (builder) => {
    // inventionDisclosureTextFinder
    builder.addCase(inventionDisclosureTextFinder.pending, (state) => {
      state.idfLoaders.startLoader = true;
    });
    builder.addCase(inventionDisclosureTextFinder.fulfilled, (state, action: PayloadAction<IDFRequestInfo | null>) => {
      //  access `action.payload` safely
      if (action.payload !== null) {
        state.idfRequestInfo = action.payload;
      }
      state.idfLoaders.startLoader = false;
    });
    builder.addCase(inventionDisclosureTextFinder.rejected, (state, action: PayloadAction<unknown, string, never, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.inventionDisclosureData.details = action?.error?.message ?? '';
      state.inventionDisclosureData.status = XRAY_JOB_STATUS.FAILED;
      removeStateFromLocalStorage(inventionDisclosureConstants.IDF_REQUEST_INFO);
      state.idfLoaders.startLoader = false;
    });

    // getInventionDisclosureResults
    builder.addCase(getInventionDisclosureResults.pending, (state) => {
      state.idfLoaders.getResultLoader = true;
    });
    builder.addCase(getInventionDisclosureResults.fulfilled, (state, action: PayloadAction<InventionDisclosureData | null>) => {
      //  access `action.payload` safely
      if (action.payload !== null) {
        state.inventionDisclosureData = action.payload;
        state.idfLoaders.getResultLoader = false;
      }
      state.idfLoaders.getResultLoader = false;
    });
    builder.addCase(getInventionDisclosureResults.rejected, (state, action: PayloadAction<unknown, string, unknown, SerializedError>) => {
      state.error = action?.error?.message ?? '';
      state.inventionDisclosureData.details = action?.error?.message ?? '';
      state.inventionDisclosureData.status = XRAY_JOB_STATUS.FAILED;
      removeStateFromLocalStorage(inventionDisclosureConstants.IDF_REQUEST_INFO);
      state.idfLoaders.getResultLoader = false;
    });
  },
});

export const inventionDisclosureActions = inventionDisclosureSlice.actions;

export default inventionDisclosureSlice.reducer;
