import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { POLICIES, PolicyKey } from 'utils/types/client/client';
import { GenerateOptionsSectionArgs, IPolicyDropDownItemOptions } from './PolicyDropDown';
import i18next from 'i18next';

export const generateOptionsSection = ({ hasUploadPermissions, policyType, isFileExist, handleDownLoadOption, setUploadFileModalData }: GenerateOptionsSectionArgs): IPolicyDropDownItemOptions[] => {
  const sectionTitle: PolicyKey = policyType === POLICIES.TRADE_SECRET ? 'TRADE_SECRET' : 'LLM';
  const options: IPolicyDropDownItemOptions[] = [
    {
      text: i18next.t(`POLICY.UPLOAD.${sectionTitle}_TITLE`),
      color: NEUTRAL_SHADES.BLACK,
      isDisabled: true,
      weight: 'bold',
    },
  ];

  const updateFileModalOpenState = { isOpen: true, policyType };

  if (hasUploadPermissions) {
    if (isFileExist) {
      options.push(
        {
          text: i18next.t('POLICY.OPTIONS.DOWNLOAD'),
          color: PRIMARY_SHADES[800],
          componentIcon: 'download',
          isDisabled: false,
          handleItem: () => handleDownLoadOption(policyType),
        },
        {
          text: i18next.t('POLICY.OPTIONS.EDIT'),
          color: PRIMARY_SHADES[800],
          componentIcon: 'edit',
          isDisabled: false,
          handleItem: () => setUploadFileModalData(updateFileModalOpenState),
        },
      );
    } else {
      options.push({
        text: i18next.t('POLICY.OPTIONS.UPLOAD'),
        color: PRIMARY_SHADES[800],
        componentIcon: 'upload',
        isDisabled: false,
        handleItem: () => setUploadFileModalData(updateFileModalOpenState),
      });
    }
  } else if (!hasUploadPermissions && isFileExist) {
    options.push({
      text: i18next.t('POLICY.OPTIONS.DOWNLOAD'),
      color: PRIMARY_SHADES[800],
      componentIcon: 'download',
      isDisabled: false,
      handleItem: () => handleDownLoadOption(policyType),
    });
  }

  return options;
};
