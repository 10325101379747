import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { TangiTypography } from '_components/TangiLibrary';
import AvatarName from '../../Common/AvatarName';
import { WidgetAccountObject } from '../Widgets.types';
import { InsightContainer } from './Insights.styled';
import { NEUTRAL_SHADES } from 'utils/theme';

interface Props {
  creators: WidgetAccountObject[];
}

const TopAssetCreator = ({ creators }: Props) => {
  const { t } = useTranslation();
  const isEmpty = !creators.length;

  const topAssetCreator = useMemo(() => (isEmpty ? null : creators[0]), [creators]);

  return (
    <InsightContainer isEmpty={isEmpty} data-testid="topAssetCreator">
      <TangiTypography color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>{t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.TOP_ASSET_CREATOR')}</TangiTypography>
      {isEmpty ? (
        <TangiTypography color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>{t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.EMPTY')}</TangiTypography>
      ) : (
        topAssetCreator && <AvatarName _id={topAssetCreator._id} displayName={topAssetCreator.displayName} email={topAssetCreator.email} />
      )}
    </InsightContainer>
  );
};

export default TopAssetCreator;
