import Tippy from '@tippyjs/react';

import { Asset } from '../../utils/types/assets/assets';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { SUCCESS_SHADES, NEUTRAL_SHADES } from 'utils/theme';
import { formatDate } from 'utils/dateUtils';
import { TooltipContainer, TooltipHeader, AssetsContainer, RowContainer, DuoCenteredContainer, AcknowledgementContainer } from './style';

interface AssetAcknowledgmentInfo {
  acknowledgedOn: null | string;
  acknowledged: boolean;
  asset: Asset;
}

interface IAssetAcknowledgmentSummaryProps {
  assetsAcknowledgmentInfo: AssetAcknowledgmentInfo[];
  acknowledgedCount: number;
  totalShared: number;
}

const AssetAcknowledgmentSummary = ({ assetsAcknowledgmentInfo, acknowledgedCount, totalShared }: IAssetAcknowledgmentSummaryProps) => {
  const renderAcknowledgementTooltip = () => {
    return (
      <TooltipContainer>
        <TooltipHeader>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{totalShared} assets received</TangiTypography>
        </TooltipHeader>
        <AssetsContainer>
          {assetsAcknowledgmentInfo.map((recipient, index) => (
            <RowContainer key={index}>
              <TangiTypography className="asset-name" weight="semibold">
                {recipient?.asset?.name || ''}
              </TangiTypography>
              {recipient.acknowledged ? (
                <DuoCenteredContainer>
                  <TangiTypography color={SUCCESS_SHADES[500]}>{recipient.acknowledgedOn ? formatDate(recipient.acknowledgedOn) : ''}</TangiTypography>
                  <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
                </DuoCenteredContainer>
              ) : (
                <TangiTypography color={NEUTRAL_SHADES[600]}>Not signed</TangiTypography>
              )}
            </RowContainer>
          ))}
        </AssetsContainer>
      </TooltipContainer>
    );
  };

  return (
    <Tippy trigger="mouseenter" placement="top" animation={false} render={() => renderAcknowledgementTooltip()}>
      <AcknowledgementContainer>
        {acknowledgedCount === totalShared ? (
          <>
            <TangiTypography>{acknowledgedCount}</TangiTypography>
            <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
          </>
        ) : (
          <TangiTypography color={NEUTRAL_SHADES[600]}>{`${acknowledgedCount}/${totalShared} is signed`}</TangiTypography>
        )}
      </AcknowledgementContainer>
    </Tippy>
  );
};

export default AssetAcknowledgmentSummary;
