import React from 'react';

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { DragHandle, DragHandleContainer, ItemsList } from './style';

interface Item {
  id: string;
}

interface DraggableListProps<T extends Item> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  onReorder: (newItems: T[]) => void;
  dragIcon: JSX.Element;
  isDragDisabled?: boolean;
}

const reorder = <T extends Item>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const TangiDraggableList = <T extends Item>({ items, renderItem, onReorder, dragIcon, isDragDisabled }: DraggableListProps<T>) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(items, result.source.index, result.destination.index);
    onReorder(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <ItemsList ref={provided.innerRef} {...provided.droppableProps} data-testid="items-list">
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragDisabled || false}>
                {(provided, snapshot) => (
                  <DragHandleContainer
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    style={{
                      ...provided.draggableProps.style,
                      backgroundColor: snapshot.isDragging ? PRIMARY_SHADES[200] : NEUTRAL_SHADES.WHITE,
                    }}
                  >
                    <DragHandle {...provided.dragHandleProps} className="drag-handle">
                      {dragIcon}
                    </DragHandle>
                    {renderItem(item, index)}
                  </DragHandleContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ItemsList>
        )}
      </Droppable>
    </DragDropContext>
  );
};
