import { TangiSvgIcon } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';

interface StepIconProps {
  stepIndex: number;
  currentStepIndex: number;
}

const StepIcon = ({ stepIndex, currentStepIndex }: StepIconProps) => {
  if (stepIndex < currentStepIndex) {
    return <TangiSvgIcon color={NEUTRAL_SHADES[600]} component="check" />;
  }
  return <span>{stepIndex + 1}.</span>;
};

export default StepIcon;
