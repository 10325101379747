import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const SharePointMembersModalRow = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AvatarAndNameContainer = styled.section`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const MembersContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  flex-direction: column;
  margin-top: ${SPACING[3]};
`;

export const SubHeader = styled.div`
  margin-bottom: ${SPACING[3]};
`;
