import { apiCall } from './utils/restClient';
import { config } from '../config/config';
import { headers } from './utils/headers';

function exportCsv(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/exportCsv`,
    method: 'GET',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

export const csvService = {
  exportCsv,
};
