import { CSSProperties } from 'react';
import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const TopContainer = styled(FlexContainer)`
  margin-bottom: ${SPACING[3]};
  justify-content: space-between;
`;

export const UserRow = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 232px) 3fr 2fr 1fr;
  align-items: center;
  padding: ${SPACING[2]} ${SPACING[0]};
  grid-gap: ${SPACING[3]};
`;

export const NewUserRow = styled(UserRow)`
  align-items: center;
  padding: ${SPACING[0]};
`;

export const TableContainer = styled.div`
  padding-block: ${SPACING[2]} ${SPACING[3]};
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[200]};
`;

export const DisplayName = styled(FlexContainer)`
  align-items: center;
`;

export const customStarStyles: CSSProperties = {
  marginLeft: SPACING[0.5],
  marginBottom: SPACING[0],
};

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CenterDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const statusCustomStyles = 'padding-right: 10px';

export const NewMembersTableContainer = styled(TableContainer)`
  border-bottom: none;
  padding-block: ${SPACING[2]} ${SPACING[0]};
`;

export const emailCustomTypographyStyles = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`;

export const emailCustomTypographyStyles2 = `
  ${emailCustomTypographyStyles}
  margin-bottom: ${SPACING[3.5]};
`;
