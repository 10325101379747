import { saveAs } from 'file-saver';
import ExcelJS, { Worksheet } from 'exceljs';

import { PrivilegeLogAsset } from './types/assets/assets';
import { IdAndNameWithoutUnderscore } from './types/types';
import { AssetMetaDataState } from 'redux-toolkit/slices/assetMetaDataSlice';
import { ClientSettingAgreements, ClientSettingAgreement } from './types/client/clientSettings';
import { ClientAgreementVersion } from './types/agreement/agreement';
import { MIME_TYPES } from './enums';
import { assetTypeNameFormatter } from './assetTypes';
import { ERROR_SHADES } from './theme';

// ---- CONSTANTS ----
export const requiredAssetsColumns: string[] = ['A1', 'D1', 'I1', 'N1'];

export const requiredEmployeesColumns: string[] = ['A1', 'D1', 'F1'];

export const booleanOptions: IdAndNameWithoutUnderscore[] = [
  { id: '1', name: 'true' },
  { id: '0', name: 'false' },
];

export const uniqueHeadersAssets: IdAndNameWithoutUnderscore[] = [
  { id: 'name', name: '* Title of Trade Secret' },
  { id: 'departments', name: 'Department' },
  { id: 'description', name: 'Description of Trade Secret' },
  { id: 'authors', name: '* Contributor Num.1' },
  { id: 'authors', name: 'Contributor Num.2' },
  { id: 'authors', name: 'Contributor Num.3' },
  { id: 'urlLink', name: 'File Location' },
  { id: 'urlLabel', name: 'File Label' },
  { id: 'tags', name: '* Tag1' },
  { id: 'tags', name: 'Tag2' },
  { id: 'tags', name: 'Tag3' },
  { id: 'tags', name: 'Tag4' },
  { id: 'tags', name: 'Tag5' },
  { id: 'assetTypes', name: '* Asset Type' },
  { id: 'projects', name: 'Project1' },
  { id: 'projects', name: 'Project2' },
  { id: 'projects', name: 'Project3' },
  { id: 'businessValues', name: 'Business Value1' },
  { id: 'businessValues', name: 'Business Value2' },
  { id: 'businessValues', name: 'Business Value3' },
  { id: 'others1', name: 'Others 1' },
  { id: 'others2', name: 'Others 2' },
];

export const uniquePrivilegeLog: IdAndNameWithoutUnderscore[] = [
  { id: 'refNumber', name: 'Reference Number' },
  { id: 'title', name: 'Title' },
  { id: 'type', name: 'Asset Type' },
  { id: 'dateEntered', name: 'Date Entered' },
  { id: 'access', name: 'Access' },
  { id: 'email', name: 'Email' },
];

export const uniqueHeadersEmployees: IdAndNameWithoutUnderscore[] = [
  { id: 'active', name: '* Active' },
  { id: 'employeeId', name: 'No.' },
  { id: 'departments', name: 'Department' },
  { id: 'displayName', name: '* Display Name' },
  { id: 'title', name: 'Title' },
  { id: 'email', name: '* Email Address' },
  { id: 'phone', name: 'Telephone no.' },
  { id: 'countries', name: 'Country' },
  { id: 'location', name: 'Location' },
  { id: 'businessUnits', name: 'Business Unit' },
  { id: 'dateOfJoining', name: 'Hire Date - MM/DD/YYYY' },
  { id: 'dateOfLeaving', name: 'Exit Date - MM/DD/YYYY' },
  { id: 'agreement1Signed', name: 'Signed' },
  { id: 'agreement1Version', name: 'Version' },
  { id: 'agreement1Date', name: 'Date' },
  { id: 'agreement2Signed', name: 'Signed' },
  { id: 'agreement2Version', name: 'Version' },
  { id: 'agreement2Date', name: 'Date' },
  { id: 'others1', name: 'Others 1' },
  { id: 'others2', name: 'Others 2' },
  { id: 'linkedin', name: 'Linkedin profile' },
];

export const assetTemplateFields: Record<string, string | null | never[]> = {
  name: '',
  departments: [],
  description: null,
  auther: [],
  contributor: [],
  urls: null,
  tags: [],
  assetType: [],
  projects: [],
  businessValues: [],
  others1: null,
  others2: null,
};

export const employeeTemplateFields: Record<string, string | never[]> = {
  active: [],
  employeeId: '',
  departments: [],
  displayName: '',
  title: '',
  email: '',
  phone: '',
  countries: [],
  location: '',
  businessUnits: [],
  dateOfJoining: '',
  dateOfLeaving: '',
  agreement1Signed: [],
  agreement1Version: [],
  agreement1Date: '',
  agreement2Signed: [],
  agreement2Version: [],
  agreement2Date: '',
  others1: '',
  others2: '',
  linkedin: '',
};

// ---- TYPES ----
interface ExcelFormattedPrivilegeLogAsset {
  refNumber: string;
  title: string;
  type: string;
  dateEntered: string;
  access: string;
  email: string;
}

type Mode = 'employee' | 'asset';

//  ---- COMMON EXCEL UTILS ----
export let startMetaDataAssetsColumn = 'Z';
export let startMetaDataEmployeesColumn = 'X';

/**
 * Automatically adjusts the width of each column in the given worksheet
 * to fit the content within the cells.
 *
 * @param {Worksheet} sheet - The worksheet whose columns need to be auto-fitted.
 * @returns {void}
 */
const autoFitColumnWidths = (sheet: Worksheet): void => {
  sheet.columns.forEach((column) => {
    let maxLength = 0;

    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
    }

    column.width = maxLength < 10 ? 10 : maxLength;
  });
};

/**
 * Applies a specific font style to the required columns in the worksheet.
 * The columns are identified by their keys provided in the `columnsNames` array.
 * The font color applied corresponds to a predefined error shade, with the color code
 * formatted to exclude the leading `#` character.
 *
 * @param {Worksheet} sheet - The worksheet where the styles will be applied.
 * @param {string[]} columnsNames - An array of column keys where the font styles will be applied.
 * @returns {void}
 */
const applyRequiredColumnStyles = (sheet: Worksheet, columnsNames: string[]): void => {
  columnsNames.map((key) => {
    sheet.getCell(key).font = {
      color: { argb: ERROR_SHADES[500].slice(1) },
    };
  });
};

/**
 * Configures dropdown options for a specified column in an Excel worksheet.
 * This function generates a list of options based on the provided `columnOptions`,
 * applies data validation to the specified column, and hides the column that holds
 * the dropdown options in the worksheet.
 *
 * @param {Worksheet} sheet - The Excel worksheet where the dropdown options will be applied.
 * @param {string} column - The column letter (e.g., 'A', 'B', 'C') where the dropdown will be applied.
 * @param {string} header - The header name that determines how the options are mapped.
 * @param {AssetMetaDataState[] | IdAndNameWithoutUnderscore[]} columnOptions - The list of options to be used for the dropdown.
 *   If the header is 'authors', the options will use `displayName` if available; otherwise, they will use `name`.
 * @param {Mode} mode - Determines the starting column based on whether it's in 'employee' mode or another mode.
 *   This affects which set of columns (employees or assets) will be used to generate the dropdown options.
 *
 * @returns {void}
 */
const dropdownOptions = (sheet: Worksheet, column: string, header: string, columnOptions: AssetMetaDataState[] | IdAndNameWithoutUnderscore[], mode: Mode) => {
  const startFromCol = mode === 'employee' ? startMetaDataEmployeesColumn : startMetaDataAssetsColumn;
  const options = columnOptions.map((data) => (header === 'authors' && 'displayName' in data ? data.displayName : data.name));

  sheet.getColumn(startFromCol).values = [header.toUpperCase(), ...options];
  sheet.getColumn(startFromCol).hidden = true;

  sheet.getCell(`${column}2`).dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: [`$${startFromCol}$2:$${startFromCol}$${options.length + 1}`],
  };

  // Applies a dropdown list validation to each cell in the specified column from row 2 to row 52, allowing only values from the predefined list
  for (let row = 2; row <= 52; row++) {
    sheet.getCell(`${column}${row}`).dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [`$${startFromCol}$2:$${startFromCol}$${options.length + 1}`],
    };
  }

  if (column !== 'D' && column !== 'E') {
    const colPrefix = startFromCol.length > 1 ? 'A' : '';
    const newColumn = startFromCol === 'Z' ? 'AA' : colPrefix + String.fromCharCode(startFromCol.charCodeAt(startFromCol.length > 1 ? 1 : 0) + 1);
    mode === 'employee' ? (startMetaDataEmployeesColumn = newColumn) : (startMetaDataAssetsColumn = newColumn);
  }
};

//  ---- BATCH UPLOAD EMPLOYEE TEMPLATE UTILS ----
const hideAgreementsForLearn = (sheet: Worksheet) => {
  sheet.getColumn('M').hidden = true;
  sheet.getColumn('N').hidden = true;
  sheet.getColumn('O').hidden = true;
  sheet.getColumn('P').hidden = true;
  sheet.getColumn('Q').hidden = true;
  sheet.getColumn('R').hidden = true;
};

/**
 * Formats the date cells in specific columns of an Excel worksheet.
 * This function applies the text format ('@') to cells in columns 'K', 'L', 'O', and 'R'.
 * The text format ensures that the dates in these columns are treated as text strings in the worksheet.
 *
 * @param {Worksheet} sheet - The Excel worksheet where the date cells will be formatted.
 *
 * @returns {void}
 */
const formatDateCells = (sheet: Worksheet) => {
  const dateCellsColK = sheet.getColumn('K');
  const dateCellsColL = sheet.getColumn('L');
  const dateCellsColO = sheet.getColumn('O');
  const dateCellsColR = sheet.getColumn('R');
  dateCellsColK.eachCell((cell) => (cell.numFmt = '@'));
  dateCellsColL.eachCell((cell) => (cell.numFmt = '@'));
  dateCellsColO.eachCell((cell) => (cell.numFmt = '@'));
  dateCellsColR.eachCell((cell) => (cell.numFmt = '@'));
};

/**
 * Updates specific headers in the Excel template based on the provided agreements.
 * This function modifies the names of certain headers in the `headers` array to include details
 * about the agreements, such as "Signed", "Version", and "Date" fields.
 *
 * @param {ClientSettingAgreement[]} agreements - An array of agreement objects used to define the header names.
 * @param {IdAndNameWithoutUnderscore[]} headers - An array of header objects where each header will be modified based on the agreements.
 * @returns {IdAndNameWithoutUnderscore[]} - The modified array of headers with updated names.
 */
const defineExcelHeaders = (agreements: ClientSettingAgreement[], headers: IdAndNameWithoutUnderscore[]) => {
  const temp = headers;
  temp[12].name = agreements[0].name + ' Signed';
  temp[13].name = 'Version of ' + agreements[0].name;
  temp[14].name = 'Date of ' + agreements[0].name + ' - MM/DD/YYYY';
  temp[15].name = agreements[1].name + ' Signed';
  temp[16].name = 'Version of ' + agreements[1].name;
  temp[17].name = 'Date of ' + agreements[1].name + ' - MM/DD/YYYY';
  return temp;
};

/**
 * Formats privilege log data for exporting as an Excel sheet.
 * This function maps through data to create a structured array specifically formatted for Excel export.
 *
 * @param {PrivilegeLogAsset[]} data - An array of privilege log assets, each containing multiple recipients.
 * @returns {ExcelFormattedPrivilegeLogAsset[]} - A formatted array of privilege log data, suitable for Excel export.
 */
const formatPrivilegeLogData = (data: PrivilegeLogAsset[]): ExcelFormattedPrivilegeLogAsset[] => {
  const privilegeLogExcelData = data.flatMap((asset: PrivilegeLogAsset) =>
    asset.recipients.map((recipient) => ({
      refNumber: asset.refNumber,
      title: asset?.name || '',
      type: asset?.assetType?.name ? assetTypeNameFormatter(asset.assetType.name) : '',
      dateEntered: new Date(recipient.acknowledgedOn).toLocaleDateString('en-US'),
      access: recipient?.account?.displayName || recipient.email,
      email: recipient.email,
    })),
  );

  return privilegeLogExcelData;
};

/**
 * Exports an Excel template for assets based on the provided metadata state.
 * The function generates an Excel workbook with a sheet containing asset data,
 * applying styles, setting up dropdowns, and formatting the columns as needed.
 *
 * @param {AssetMetaDataState} metaDataState - The state containing metadata for the assets, used to populate dropdown options in the template.
 * @returns {Promise<void>} - A promise that resolves when the Excel file has been generated and saved.
 */
const exportAssetsTemplate = async (metaDataState: AssetMetaDataState): Promise<void> => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Assets');
  sheet.columns = uniqueHeadersAssets.map((data) => ({ header: data.name, key: data.id }));
  metaDataState &&
    uniqueHeadersAssets.forEach((header, j) => {
      const column = String.fromCharCode((j % 26) + 65);
      const headerTitle = header.id === 'authors' ? 'contributor' : header.id === 'assetTypes' ? 'assetType' : header.id === 'businessValues' ? 'businessValues' : header.id;
      const columnData = metaDataState[`${header.id}`] ? metaDataState[`${header.id}`] : [];
      Array.isArray(assetTemplateFields[headerTitle]) && dropdownOptions(sheet, column, header.id, columnData, 'asset');
    });

  applyRequiredColumnStyles(sheet, requiredAssetsColumns);
  autoFitColumnWidths(sheet);

  const buffer = await workbook.xlsx.writeBuffer();
  const data = new Blob([buffer], { type: MIME_TYPES.XLSX });
  saveAs(data, 'Assets-' + new Date().getTime() + '.xlsx');
};

/**
 * Exports an Excel file containing a privilege log based on the provided data.
 * The function formats the privilege log data, creates an Excel workbook, and saves it as an XLSX file.
 * The file name reflects whether a filter was applied to the data.
 *
 * @param {PrivilegeLogAsset[]} rawData - An array of raw privilege log data to be processed and exported.
 * @param {boolean} isFilterApplied - A flag indicating whether a filter was applied to the data, affecting the file name.
 * @returns {Promise<void>} - A promise that resolves when the Excel file has been generated and saved.
 */
const exportPrivilegeLog = async (rawData: PrivilegeLogAsset[], isFilterApplied: boolean): Promise<void> => {
  const recipientsData = formatPrivilegeLogData(rawData);
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Privilege Log');
  sheet.columns = uniquePrivilegeLog.map((data) => ({ header: data.name, key: data.id }));
  sheet.addRows(recipientsData);
  autoFitColumnWidths(sheet);
  const buffer = await workbook.xlsx.writeBuffer();
  const data = new Blob([buffer], { type: MIME_TYPES.XLSX });
  const nameString = isFilterApplied ? 'Privilege Log Filtered-' : 'Privilege Log-';
  saveAs(data, nameString + new Date().getTime() + '.xlsx');
};

/**
 * Exports an Excel template for employee data based on the provided metadata state and agreements.
 * The function generates an Excel workbook with a sheet for employee data, applying necessary headers,
 * styles, dropdown options, and formats the columns as needed. The output file is saved as an XLSX file.
 *
 * @param {ClientSettingAgreements} metaDataState - The state containing metadata for the employee agreements, used to populate dropdown options in the template.
 * @param {ClientSettingAgreements} agreements - The agreements data used to define the Excel headers and other content.
 * @param {boolean} isLearn - A flag indicating whether to hide certain agreement-related columns for "learn" mode.
 * @param {ClientAgreementVersion[]} [versions=[]] - An array of agreement versions used to populate dropdown options for columns related to agreements in the template.
 * @returns {Promise<void>} - A promise that resolves when the Excel file has been generated and saved.
 */
const exportEmployeeTemplate = async (metaDataState: ClientSettingAgreements, agreements: ClientSettingAgreements, isLearn: boolean, versions: ClientAgreementVersion[] = []): Promise<void> => {
  const headers = defineExcelHeaders(agreements.Employee, uniqueHeadersEmployees);
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Employees');
  sheet.columns = headers.map((data) => ({ header: data.name, key: data.id }));
  isLearn && hideAgreementsForLearn(sheet);
  metaDataState &&
    headers.forEach((header, j) => {
      const column = String.fromCharCode((j % 26) + 65);
      const head = header.id;
      const columnData = head.includes('Version') ? versions : head.includes('Signed') || head.includes('active') ? booleanOptions : metaDataState[`${head}`] ? metaDataState[`${head}`] : [];
      Array.isArray(employeeTemplateFields[head]) && columnData.length > 0 && dropdownOptions(sheet, column, head, columnData, 'employee');
    });

  applyRequiredColumnStyles(sheet, requiredEmployeesColumns);
  autoFitColumnWidths(sheet);
  formatDateCells(sheet);

  const buffer = await workbook.xlsx.writeBuffer();
  const data = new Blob([buffer], { type: MIME_TYPES.XLSX });
  saveAs(data, 'Employees-' + new Date().getTime() + '.xlsx');
};

export const excelExporters = {
  exportAssetsTemplate,
  exportEmployeeTemplate,
  exportPrivilegeLog,
};
