import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dashboard } from 'pages/Dashboard';
import { TangiIconButton } from '_components/TangiLibrary';
import { RootState } from '_helpers';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { NavigationColumn, PageContainer, BackToContainer, ContentColumn, ScoreColumn } from './style';
import { BUTTON_VARIANTS } from 'utils/theme';
import { TangiContractMatchScore } from '_components/TangiLibrary/TangiContractTool/TangiContractMatchScore';
import { TangiContractContent } from '_components/TangiLibrary/TangiContractTool/TangiContractContent';
import { ContractHeader } from '_components/TangiLibrary/TangiContractTool/components/ContractHeader/ContractHeader';

const ContractToolPage = () => {
  const contractData = useSelector((state: RootState) => state.contract).contractData;
  const { getResultLoader } = useSelector((state: RootState) => state.contract.contractLoaders);

  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  useEffect(() => {
    if (!getResultLoader && contractData?.status !== XRAY_JOB_STATUS.SUCCEEDED) {
      history.push(generateRoute(AppRoutes.CONTRACT_ANALYZER_MAIN_PAGE, { clientId }));
    }
  }, [getResultLoader, clientId, contractData.status]);

  const renderNavigationContent = () => {
    return (
      <BackToContainer>
        <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => history.push(generateRoute(AppRoutes.CONTRACT_ANALYZER_MAIN_PAGE, { clientId }))} />
        <span>{t('PATENT_TOOL.CONTENT.BACK_TO_CONTRACT_ANALYZER_HOME')}</span>
      </BackToContainer>
    );
  };

  return (
    <Dashboard title="">
      <PageContainer>
        <NavigationColumn>{renderNavigationContent()}</NavigationColumn>
        <ContentColumn>
          <TangiContractContent header={<ContractHeader score={contractData?.score} filename={contractData?.filename ?? ''} />} />
        </ContentColumn>
        <ScoreColumn>
          <TangiContractMatchScore score={contractData?.score} />
        </ScoreColumn>
      </PageContainer>
    </Dashboard>
  );
};

export default ContractToolPage;
