import { clientSettingsConstants } from '../_constants/clientSettingsConstants';
import { clientSettingsService } from '../_services/clientSettingsService';

function createClientSettings(clientId, data) {
  function request() {
    return {
      type: clientSettingsConstants.CREATE_CLIENT_SETTINGS_REQUEST,
    };
  }
  function success(data) {
    return {
      type: clientSettingsConstants.CREATE_CLIENT_SETTINGS_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: clientSettingsConstants.CREATE_CLIENT_SETTINGS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    clientSettingsService
      .createClientSettings(clientId, data)
      .then((settings) => {
        dispatch(success(settings));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}
function updateClientSettings(clientId, data) {
  function request() {
    return {
      type: clientSettingsConstants.UPDATE_CLIENT_SETTINGS_REQUEST,
    };
  }
  function success(data) {
    return {
      type: clientSettingsConstants.UPDATE_CLIENT_SETTINGS_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: clientSettingsConstants.UPDATE_CLIENT_SETTINGS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    clientSettingsService
      .updateClientSettings(clientId, data)
      .then((settings) => {
        dispatch(success(settings));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}
function getClientSettings(clientId, data) {
  function request() {
    return {
      type: clientSettingsConstants.GET_CLIENT_SETTINGS_REQUEST,
    };
  }
  function success(data) {
    return {
      type: clientSettingsConstants.GET_CLIENT_SETTINGS_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: clientSettingsConstants.GET_CLIENT_SETTINGS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    clientSettingsService
      .getClientSettings(clientId)
      .then((settings) => {
        dispatch(success(settings[0]));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}
function clearSettings() {
  return {
    type: clientSettingsConstants.CLEAR_CLIENT_SETTINGS,
  };
}

export const clientSettingsActions = {
  createClientSettings,
  updateClientSettings,
  getClientSettings,
  clearSettings,
};
