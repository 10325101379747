import { BarLegendProps } from '@nivo/bar';

import { TangiTypography } from '_components/TangiLibrary';
import { ColorBox, LegendContainer, LegendItem } from './style';
import { NEUTRAL_SHADES } from 'utils/theme';

interface Props {
  legends: BarLegendProps[];
}
const TradeSecretBySubjectLegend = ({ legends }: Props) => {
  return (
    <LegendContainer data-testid="bar-chart-custom-legend">
      {legends.map((legend: BarLegendProps) =>
        legend?.data?.map((data) => (
          <LegendItem key={data.id}>
            <ColorBox color={data.color} />
            <TangiTypography color={NEUTRAL_SHADES[1000]} customStyles="cursor: default" type="sub-body">
              {data.label}
            </TangiTypography>
          </LegendItem>
        )),
      )}
    </LegendContainer>
  );
};

export default TradeSecretBySubjectLegend;
