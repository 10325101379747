import { AppDispatch, RootState } from '_helpers';
import { DEMO_ACCOUNT_NAMES, Environment } from './const';
import { lawyers } from 'utils/roles';
import { LogoutType } from './types';
import { mixpanelEvents } from './mixpanelConfig';

/**
 * Determines if an event should be tracked based on the client name and the current environment.
 * Events are only tracked in the production environment and for non-demo accounts.
 *
 * @param {string} [clientName=''] - The name of the client to check. Defaults to an empty string if not provided.
 * @returns {boolean} `true` if the event should be tracked; `false` otherwise.
 *                    Returns `true` only when the environment is production and the client is not a demo account.
 */
export const shouldTrackEvent = (clientName = '') => {
  const isDemoAccount = DEMO_ACCOUNT_NAMES.includes(clientName);
  const currentEnv = process.env.REACT_APP_ENVIRONMENT;

  return currentEnv === Environment.PRODUCTION && !isDemoAccount; // Change this to test locally
};

/**
 * Extracts and returns common properties for tracking and logging purposes from the application state.
 * These properties include user role, email, client details, and optional law firm details for lawyers.
 *
 * @param {RootState} state - The Redux state of the application.
 * @returns {Object} An object containing common properties:
 * - `Role` (string): The role of the user, which determines if they are a lawyer.
 * - `userEmail` (string): The email address of the user.
 * - `clientName` (string): The name of the active client or account's client.
 * - `clientId` (string): The ID of the active client or account's client.
 * - `lawFirmId` (string, optional): The ID of the law firm, if the user is a lawyer.
 * - `lawFirmName` (string, optional): The name of the law firm, if the user is a lawyer.
 */
export const getCommonProperties = (state: RootState) => {
  const { Role, user, activeAccount } = state.authentication;
  const { activeClient } = state.lawfirm;

  const isLawyer = lawyers.includes(Role);
  const clientName = isLawyer ? activeClient?.name : activeAccount?.client?.name;
  const clientId = isLawyer ? activeClient?.id || '' : activeAccount?.client?._id || '';

  const commonProperties = {
    Role,
    userEmail: user?.email || '',
    clientName,
    clientId,
  };

  const lawyerProperties = { lawFirmId: activeAccount?.lawFirm?._id || '', lawFirmName: activeAccount?.lawFirm?.name || '' };

  return { ...commonProperties, ...(isLawyer ? lawyerProperties : {}) };
};

/**
 * Dispatches a Mixpanel event for logging out based on the specified logout type.
 * If the logout type is "Automatic," it sends an automatic logout event.
 * If the logout type is "Active," it sends an intentional logout event.
 *
 * @param {LogoutType} logoutType - The type of logout, either `LogoutType.Automatic` or `LogoutType.Active`.
 * @param {AppDispatch} dispatch - The Redux dispatch function to send the Mixpanel event.
 * @returns {void}
 */
export const sendLogoutMixpanelEvent = (logoutType: LogoutType, dispatch: AppDispatch) => {
  switch (logoutType) {
    case LogoutType.Automatic:
      dispatch(mixpanelEvents.logoutAuto({ message: 'Automatic logout' }));
      break;
    case LogoutType.Active:
      dispatch(mixpanelEvents.logoutActive({ message: 'User intentionally logged out' }));
      break;
    default:
      break;
  }
};
