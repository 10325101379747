import { forwardRef, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { RootState } from '_helpers';
import { PERIOD_OPTIONS, calculateTimePeriodTitle, getAssetsOvertimeByAssetType, AssetOverTime } from './assetsOverTime.logic';
import { filterByDepartment } from '../../../../../utils/dashboardFuncs';
import { WidgetCardProps } from '../Widgets.types';
import { LANGUAGE } from 'translations/enums';
import { TangiTypography } from '_components/TangiLibrary';
import WidgetCard from '../WidgetCard';
import WidgetTitle from '../WidgetTitle';
import { BarChart } from '../../Charts';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import AssetsOverTimeTooltip from './AssetsOverTimeTooltip';

const ButtonsGroupContainer = styled.div`
  position: absolute;
  right: ${SPACING[3]};
  top: ${SPACING[3]};
`;

const StyledButton = styled(Button)`
  border: 1px solid ${NEUTRAL_SHADES[600]} !important;
  background-color: ${NEUTRAL_SHADES.WHITE} !important;
  display: flex;
  align-items: center;
  padding-inline: ${SPACING[3]} !important;
  height: 32px;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${NEUTRAL_SHADES[300]} !important;
      border: 1px solid ${NEUTRAL_SHADES[1000]} !important;
    `};
`;

const StyledButtonText = styled(TangiTypography)<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? NEUTRAL_SHADES.BLACK : NEUTRAL_SHADES[700])};
`;

export interface Props extends WidgetCardProps {}

const AssetsOverTime = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [currentPeriod, setCurrentPeriod] = useState<PERIOD_OPTIONS>(PERIOD_OPTIONS.YEAR);

  const allAssetsRawData = useSelector((state: RootState) => state.dashboard.allAssetsRawData);
  const departmentFilter = useSelector((state: RootState) => state.dashboard.departmentFilter);
  const assetTypes = useSelector((state: RootState) => state.dashboard.assetTypes);

  const { t, i18n } = useTranslation();

  const assetOverTimeData: AssetOverTime = useMemo(
    () => getAssetsOvertimeByAssetType(filterByDepartment(allAssetsRawData, departmentFilter), currentPeriod, i18n?.language || LANGUAGE.ENGLISH),
    [allAssetsRawData, departmentFilter, currentPeriod, i18n?.language],
  );

  const subTitle: string = useMemo(
    () => (assetOverTimeData?.endDate && assetOverTimeData?.startDate ? `• ${calculateTimePeriodTitle(currentPeriod, assetOverTimeData.startDate, assetOverTimeData.endDate)}` : ''),
    [currentPeriod, assetOverTimeData],
  );

  const renderPeriodButton = (period: PERIOD_OPTIONS) => {
    return (
      <StyledButton onClick={() => setCurrentPeriod(period)} disabled={currentPeriod === period} variant="secondary" data-testid={`time-period-button-${period}`}>
        <StyledButtonText type="sub-body" disabled={currentPeriod === period}>
          {period}
        </StyledButtonText>
      </StyledButton>
    );
  };

  const periodButtons = useMemo(() => {
    return (
      <ButtonsGroupContainer>
        <ButtonGroup size="sm">
          {renderPeriodButton(PERIOD_OPTIONS.MONTH)}
          {renderPeriodButton(PERIOD_OPTIONS.YEAR)}
        </ButtonGroup>
      </ButtonsGroupContainer>
    );
  }, [currentPeriod]);

  return (
    <WidgetCard {...props} ref={ref} isEmpty={!assetOverTimeData.data.length}>
      <>
        <WidgetTitle subTitle={subTitle} title={t('DASHBOARD_PAGE.WIDGETS.ASSETS_OVER_TIME')} />
        <BarChart data={assetOverTimeData.data} keys={assetTypes} tooltip={AssetsOverTimeTooltip} />
        {periodButtons}
        {props.children}
      </>
    </WidgetCard>
  );
});

export default AssetsOverTime;
