import { renderAssetTypeComponent } from './utils';
interface Props {
  assetTypeName: string;
}

const AssetTypeColorBlock = ({ assetTypeName }: Props) => {
  return (
    <div data-testid="asset-type-color-block" className="asset-type-color-block">
      {renderAssetTypeComponent(assetTypeName)}
    </div>
  );
};

export default AssetTypeColorBlock;
