import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Spinner, Tab } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { getAssetAuditLog, getAssetById, pendingAcknowledgement } from 'redux-toolkit/thunks/assetsThunks';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { RootState } from '../../_helpers/store';
import { File, SharePointStatusEnums } from '../../utils/types/assets/assets';
import { TangiIconButton, TangiButton, TangiTypography, TangiToast, TangiNotification, NOTIFICATION_VARIANTS } from '../../_components/TangiLibrary';
import { Dashboard } from '../Dashboard';
import AssetOverview from '../../_components/AssetOverview/AssetOverview';
import AssetRecipients from '../../_components/AssetRecipients/AssetRecipients';
import ActivityLog from '_components/ActivityLog/ActivityLog';
import AssetTypeColorBlock from '../../_components/AssetTypeColorBlock/AssetTypeColorBlock';
import DistributeAssetModal from '../../_components/DistributeAssetModal/DistributeAssetModal';
import AssetSkeleton from './components/AssetSkeleton';
import AssetLocked from './components/AssetLocked';
import ThirdPartyTag from '_components/ThirdPartyTag/ThirdPartyTag';
import AcknowledgementModal from '_components/AcknowledgementModal/AcknowledgementModal';
import BreadCrumbs from '_components/BreadCrumbs/BreadCrumbs';
import useAsset from './useAsset';
import { DeleteModal, AlertModal } from '../../_components/Modals';
import { InviteAccountForm } from '../../_components/InviteAccountForm/InviteAccountForm';
import { CreateEditAsset } from '../../_components/CreateEditAsset';
import { AssetDeleted } from '_components/ClientAssetCards/components/AssetCardLocked/AssetDeleted';
import { IRoles, adminsExceptLearn, checkAssetSharePermission } from '../../utils/roles';
import { assetTypeNameFormatter } from '../../utils/assetTypes';
import { parseAssetPath } from '_components/ClientAssetCards/components/AssetCardActive/utils';
import { INITIAL_LIMIT, INITIAL_PAGE } from 'utils/types/activityLog/asset/types';
import { RESULT_STATUS } from 'utils/enums';
import { MAX_PREVIEW_IN_EMBED_FILE_SIZE } from '_constants';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { ContentViewContainer, PageHeaderContainer, PageHeader, PageHeaderButtonContainer, BackToContainer, DuoContainer } from '../../utils/globalStyles';
import { TypeAndColor, StyledTabs, TabsContainer, WatermarkContainer, SharePointNotification } from './style';
import './Asset.scss';

export type TabKeyType = 'overview' | 'recipients' | 'activity';

const Asset = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    asset,
    assetId,
    assetLoading,
    fileUrl,
    deleteAssetSuccess,
    deleteAssetError,
    deleteAssetStatus,
    assetDeleteLoading,
    permissions,
    showDistributeModal,
    assetCreationToastProps,
    clientId,
    setShowDistributeModal,
    deleteAssetFile,
    getFilePath,
    handleDeleteAsset,
    clearDeleteStatus,
    clearPeopleToast,
  } = useAsset();

  const [fileName, setFileName] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showFileModal, setShowFileModal] = useState<boolean>(false);
  const [showAddAccountModal, setShowAddAccountModal] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [tabKey, setTabKey] = useState<TabKeyType>('overview');
  const [currentRecipient, setCurrentRecipient] = useState<string>('');
  const [isSingleSignatureRequest, setIsSingleSignatureRequest] = useState<boolean>(false);
  const [isAcknowledgementModalOpen, setIsAcknowledgementModalOpen] = useState<boolean>(false);

  const Role = useSelector((state: RootState) => state.authentication.Role);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const peopleToastProps = useSelector((state: RootState) => state.users.peopleToastProps);
  const deleteRecipientData = useSelector((state: RootState) => state.asset.deleteRecipientData);
  const isMetaDataFetched = useSelector((state: RootState) => state.assetMetaData.isMetaDataFetched);
  const assetMetaDataLoading = useSelector((state: RootState) => state.assetMetaData.loading);
  const { shareAsset, loading, filePath, pendingAcknowledgementLoading, error } = useSelector((state: RootState) => state.asset);
  const { updatedAssets: updatedAcknowledgedAssets, assetsNeedToBeAcknowledged } = useSelector((state: RootState) => state.acknowledgementReducer);

  const assetSharePermission: boolean = useMemo(() => checkAssetSharePermission(Role, permissions, asset?.isThirdParty), [Role, permissions?.Assetshare, asset?.isThirdParty]);
  const isEditDisable = asset?.isThirdParty && Role === IRoles.PARTNER;

  const isAdmin: boolean = useMemo(() => adminsExceptLearn.includes(Role), [Role]);

  const isAssetLocked: boolean = useMemo(
    () => asset?.needsToBeAcknowledgedByMe || (asset?.isThirdParty && !asset?.hasAccessToThirdPartyAsset),
    [asset?.needsToBeAcknowledgedByMe, asset?.isThirdParty, asset?.hasAccessToThirdPartyAsset],
  );

  const isSharePointType = !!asset?.sharePoint;
  const isInaccessible = isSharePointType && [SharePointStatusEnums.MOVED, SharePointStatusEnums.NOT_TRACKED].includes(asset.sharePoint.status);

  const { t } = useTranslation();
  const isError = Object.keys(error).length !== 0;

  const handleActivityLog = () => {
    dispatch(assetsActions.setInitialAuditLog());
    dispatch(getAssetAuditLog({ assetId: asset.id, page: INITIAL_PAGE, limit: INITIAL_LIMIT }));
  };
  const reload = () => {
    setIndex((i) => i + 1);
  };

  const goBackToAsset = () => {
    activeAccount && activeAccount?.client?._id ? history.push(generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId: activeAccount.client._id })) : history.goBack();
  };

  const handleAddAccount = (email: string) => {
    setCurrentRecipient(email);
    setShowAddAccountModal(true);
  };

  const handleCloseInviteAccountForm = () => {
    dispatch(getAssetById(assetId));
    setShowAddAccountModal(false);
  };

  const handleEditAssetSuccess = () => {
    dispatch(getAssetById(assetId));
    dispatch(assetsActions.setAssetCreationToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: t('ASSET_PAGE.TOAST.ASSET_EDITED_SUCCESSFULLY') }));
    setShowEditModal(false);
    setTabKey('overview');
  };

  // File handlers
  const handleFileClick = (file: File, isNotUseEffect: boolean = true) => {
    const name = file?.name || '';
    if (name) {
      const assetparam = {
        fileName: file.name,
        clientId: asset.client._id,
        assetId: asset.id,
      };

      getFilePath(assetparam);

      setFileName(name);
      if (isNotUseEffect) {
        setShowFileModal(true);
      }
    }
  };

  const handleFileClose = () => {
    if (fileName) {
      const assetparam = {
        fileName: fileName,
        assetId: asset.id,
      };
      deleteAssetFile(assetparam);
    }
    setShowFileModal(false);
  };

  useEffect(() => {
    if (fileUrl) {
      reload();
    }
  }, [fileUrl]);

  const typeOfDocument = () => {
    const binaryData = atob(filePath?.base64);
    const dataSize = binaryData.length;
    const clientName = asset?.createdBy?.role?.name === IRoles.PARTNER ? asset?.createdBy?.partnership?.name || '' : asset?.client?.name || '';
    if ((filePath?.mimeType.includes('pdf') || filePath?.mimeType.includes('image')) && dataSize <= MAX_PREVIEW_IN_EMBED_FILE_SIZE) {
      return (
        <WatermarkContainer clientName={clientName}>
          <embed title={fileName} src={`data:${filePath?.mimeType};base64,${filePath?.base64}#toolbar=0&navpanes=0&scrollbar=0`} width="100%" height="500" className="text-center" />
        </WatermarkContainer>
      );
    } else {
      return (
        <WatermarkContainer clientName={clientName}>
          <iframe key={index} title={fileName} src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`} width="100%" height="500" className="text-center" />
        </WatermarkContainer>
      );
    }
  };

  const handleTabChange = (key: TabKeyType) => {
    setTabKey(key);
  };

  const openAllAcknowledgementRequests = useCallback(() => {
    dispatch(pendingAcknowledgement(clientId));
    setIsSingleSignatureRequest(false);
  }, [assetsNeedToBeAcknowledged, dispatch, clientId, activeAccount._id]);

  const handleUnlockAsset = () => {
    setIsSingleSignatureRequest(true);
    setIsAcknowledgementModalOpen(true);
  };

  useEffect(() => {
    if (deleteAssetSuccess) {
      setShowDeleteModal(false);
      setTimeout(() => {
        clearDeleteStatus();
        history.push(generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId: asset?.client?._id }));
      }, 2000);
    }
  }, [deleteAssetSuccess]);

  useEffect(() => {
    // TODO: fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (shareAsset && shareAsset.length && shareAsset.every((sharedAsset: any) => sharedAsset.shared === true)) {
      dispatch(getAssetById(assetId));
      setTabKey('recipients');
    }
  }, [shareAsset]);

  useEffect(() => {
    if (Boolean(asset?.needsToBeAcknowledgedByMe)) {
      handleUnlockAsset();
    }
  }, [asset?.needsToBeAcknowledgedByMe]);

  useEffect(() => {
    if (!updatedAcknowledgedAssets?.length) {
      return;
    }
    setIsAcknowledgementModalOpen(false);
    dispatch(getAssetById(assetId));
    setTabKey('overview');
    dispatch(pendingAcknowledgement(clientId));
  }, [isSingleSignatureRequest, updatedAcknowledgedAssets]);

  useEffect(() => {
    if (deleteRecipientData && deleteRecipientData[0]) {
      setTabKey('overview');
    }
  }, [deleteRecipientData]);

  const renderPageHeaderButtons = () => {
    const isDeletedSharePoint = asset?.sharePoint && asset.sharePoint.status === SharePointStatusEnums.DELETED;

    if (isSharePointType && isDeletedSharePoint) {
      return (
        <PageHeaderButtonContainer>
          <AssetDeleted />
        </PageHeaderButtonContainer>
      );
    }
    if (isAssetLocked || isSharePointType) return null;
    return (
      <PageHeaderButtonContainer>
        {permissions.Assetdelete && <TangiButton svgIcon="delete" text={t('GENERAL.BUTTONS_TEXT.DELETE')} variant="secondary" onClick={() => setShowDeleteModal(true)} data-testid="delete-asset" />}
        {permissions.Assetedit && (
          <Tippy disabled={!isEditDisable} content={t('ASSET_PAGE.TOOLTIP.CANNOT_EDIT_ASSET')} placement="top-end">
            <span>
              <TangiButton
                loading={assetMetaDataLoading}
                svgIcon="edit"
                disabled={!isMetaDataFetched || isEditDisable}
                text={t('GENERAL.BUTTONS_TEXT.EDIT')}
                variant="secondary"
                onClick={() => setShowEditModal(true)}
                data-testid="edit-asset"
              />
            </span>
          </Tippy>
        )}
        {assetSharePermission && (
          <TangiButton
            disabled={!isMetaDataFetched}
            loading={assetMetaDataLoading}
            svgIcon="send"
            text={t('GENERAL.BUTTONS_TEXT.NOTIFY')}
            onClick={() => setShowDistributeModal(true)}
            data-testid="notify-asset"
          />
        )}
      </PageHeaderButtonContainer>
    );
  };

  const renderPageHeader = () => {
    return (
      <PageHeaderContainer>
        <PageHeader>
          <BackToContainer>
            <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={goBackToAsset} />
            <span>{t('ASSET_PAGE.BUTTONS_TEXT.BACK_TO_ALL_ASSETS')}</span>
          </BackToContainer>
          {renderPageHeaderButtons()}
        </PageHeader>
        {isInaccessible && (
          <SharePointNotification>
            <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO}>
              <div>{t('ASSET_PAGE.TOOLTIP.SHARE_POINT_ACCESS')}</div>
            </TangiNotification>
          </SharePointNotification>
        )}
      </PageHeaderContainer>
    );
  };

  const renderAssetPageContent = () => {
    return (
      <ContentViewContainer>
        {renderAssetTypeAndName()}
        {isAdmin ? renderTabs() : <AssetOverview asset={asset} handleFileClick={handleFileClick} />}
      </ContentViewContainer>
    );
  };

  const renderAssetTypeAndName = () => {
    if (!asset) return null;
    const isSharePointType = !!asset?.sharePoint;
    const breadcrumbData = isSharePointType ? parseAssetPath(asset.sharePoint) : null;
    const { breadcrumbItems, fullPathString } = breadcrumbData || {};

    return (
      <>
        <DuoContainer>
          <TangiTypography type="heading-xl">{asset?.name}</TangiTypography>
          {asset?.isThirdParty && <ThirdPartyTag asset={asset} placement="bottom" />}
        </DuoContainer>
        <TypeAndColor>
          <AssetTypeColorBlock assetTypeName={asset?.assetType?.name || ''} />
          {isSharePointType && breadcrumbItems ? (
            <BreadCrumbs items={breadcrumbItems} tooltipContent={fullPathString} tooltipPlacement="bottom-start" />
          ) : (
            <TangiTypography type="subheading">{asset?.assetType?.name ? assetTypeNameFormatter(asset.assetType.name) : ''}</TangiTypography>
          )}
        </TypeAndColor>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <TabsContainer>
        <StyledTabs id="asset-tabs" className="asset-tabs" activeKey={tabKey} onSelect={(eventKey) => handleTabChange(eventKey as TabKeyType)}>
          <Tab eventKey="overview" title={t('ASSET_PAGE.TAB.OVERVIEW')}>
            <AssetOverview asset={asset} handleFileClick={handleFileClick} isAdmin={isAdmin} />
          </Tab>
          <Tab eventKey="recipients" title={`${t('ASSET_PAGE.TAB.RECIPIENTS')}${asset?.recipients && !!asset?.recipients?.length ? ` (${asset.recipients.length})` : ' (0)'}`}>
            <AssetRecipients
              asset={asset}
              activeAccount={activeAccount}
              activeTab={tabKey}
              assetSharePermission={assetSharePermission}
              handleAddAccount={handleAddAccount}
              permissions={permissions}
              Role={Role}
            />
          </Tab>
          <Tab
            eventKey="activity"
            title={t('ASSET_PAGE.TAB.ACTIVITY')}
            onEnter={() => {
              handleActivityLog();
            }}
          >
            <ActivityLog isEmpty={isError} loading={loading} />
          </Tab>
        </StyledTabs>
      </TabsContainer>
    );
  };

  return (
    <>
      <Dashboard title="">
        {assetLoading || !asset?.name ? (
          <AssetSkeleton permissions={permissions} assetSharePermission={assetSharePermission} goBackToAsset={goBackToAsset} isAdmin={isAdmin} />
        ) : (
          <>
            {renderPageHeader()}

            {isAssetLocked ? <AssetLocked asset={asset} handleUnlockAsset={handleUnlockAsset} /> : renderAssetPageContent()}

            {/* Modals */}
            <AcknowledgementModal
              isOpen={isAcknowledgementModalOpen}
              onClose={() => setIsAcknowledgementModalOpen(false)}
              isSingleRequest={isSingleSignatureRequest}
              items={assetsNeedToBeAcknowledged}
              singleItem={asset}
              handleManageAllRequests={openAllAcknowledgementRequests}
              loading={assetLoading || pendingAcknowledgementLoading}
            />
            <DistributeAssetModal show={showDistributeModal} onClose={() => setShowDistributeModal(false)} asset={asset} shouldCloseAfterCreation={true} />
            <Modal className="fileViewer" show={showFileModal} onHide={() => handleFileClose()} data-testid="file-view-modal">
              {loading ? (
                <div className="content-center m-4">
                  <Spinner animation="border" variant="primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div>
                  <Modal.Header className="text-muted" closeButton data-testid="modal-header">
                    <Modal.Title>
                      <span className="h3 font-weight-normal ff-din-regular text-dark mb-4">{fileName}</span>
                      <button style={{ borderRadius: '10px', marginLeft: 8 }} type="button" className=" pl-2 btn-secondary btn-sm px-2 py-1 shadow-none" onClick={reload}>
                        <i style={{ borderRadius: 10 }} className="fas fa-sync" />
                      </button>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{typeOfDocument()}</Modal.Body>
                </div>
              )}
            </Modal>
            <div className="delete-confirm">
              <DeleteModal
                show={showDeleteModal}
                title={t('ASSET_PAGE.MODAL.ARE_YOU_SURE')}
                body={`${t('ASSET_PAGE.MODAL.DELETE_ASSET')} ${asset.name}?`}
                loading={assetDeleteLoading}
                handleDelete={() => handleDeleteAsset(asset.id)}
                onClose={() => setShowDeleteModal(false)}
              />
              <AlertModal show={deleteAssetSuccess || deleteAssetError} onClose={clearDeleteStatus} type={deleteAssetSuccess ? 'success' : 'error'} body={deleteAssetStatus?.message} />
            </div>
            <Modal
              transition="Fade"
              size="lg"
              className="modal-asset"
              show={showEditModal}
              onHide={() => {
                setShowEditModal(false);
              }}
            >
              <CreateEditAsset handleCloseAsset={() => setShowEditModal(false)} asset={asset} isEditMode={true} onEdit={handleEditAssetSuccess} onCreate={() => {}} />
            </Modal>
            <Modal transition="Fade" className="invite-account-modal" show={showAddAccountModal} onHide={() => setShowAddAccountModal(false)} backdrop="static">
              <Modal.Header closeButton>
                <div id="invite-modal-header">
                  <Trans t={t} i18nKey="ASSET_PAGE.MODAL.ADD_USER_TO" values={{ NAME: activeAccount?.client?.name || '' }} />
                </div>
              </Modal.Header>
              <Modal.Body>
                <InviteAccountForm currentRecipientEmail={currentRecipient} handleClose={handleCloseInviteAccountForm} assetId={asset.id} />
              </Modal.Body>
            </Modal>
          </>
        )}
      </Dashboard>
      <TangiToast {...peopleToastProps} onSuccess={() => clearPeopleToast()} />
      <TangiToast
        {...assetCreationToastProps}
        onSuccess={() => {
          dispatch(assetsActions.setAssetCreationToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </>
  );
};

export { Asset };
