import './Header.scss';
import { useState } from 'react';
import { Container, Navbar, Nav, NavDropdown, Button, Modal, Row, Col } from 'react-bootstrap';

import logo from '../../assets/img/default_profile.png';
import CANOPY from '../../assets/img/CANOPY-logo.png';

const MobileHeader = ({ handleLogout }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <header>
        <Nav id="sidenav-main" className="navbar-vertical fixed-left navbar-light bg-white z-index-5 px-0 navbar navbar-expand-md">
          <Container fluid>
            <Button className="navbar-toggler pl-4" variant="light" onClick={handleShow}>
              <i className="navbar-toggler-icon" />
            </Button>
            <a href="/dashboard" className="navbar-brand-client d-flex flex-column navbar-brand">
              <img className="m-auto" width="200" alt="TANGIBLY" src={CANOPY} />
            </a>
            <Navbar>
              <Nav className="d-flex flex-row">
                <NavDropdown className="m-1" title={<i className="far fa-bell" aria-hidden="true" />} id="nav-dropdown">
                  <NavDropdown.Item className="ff-din-DemiBold fs-6 text-muted px-4" disabled eventKey="4.1">
                    13 DAYS AGO
                  </NavDropdown.Item>
                  <NavDropdown.Item className="ff-din-regular-2 header-dropdown" eventKey="4.4">
                    <div>you have created</div>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className="ff-din-DemiBold fs-7 text-muted px-4" disabled eventKey="4.1">
                    13 DAYS AGO
                  </NavDropdown.Item>
                  <NavDropdown.Item className="ff-din-regular-2 header-dropdown" eventKey="4.4">
                    <div>
                      you have created
                      <br /> this is bigger text
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown className="m-1" title={<img alt="" src={logo} width="30" height="30" className="d-inline-block align-top" />} id="nav-dropdown1">
                  <NavDropdown.Item className="ff-din-regular text-muted header-dropdown-m" eventKey="4.2" onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar>
          </Container>
        </Nav>
        <Modal transition="Fade" show={show} className="modal-container custom-map-modal" onHide={handleClose}>
          <Modal.Body>
            <Container>
              <Row>
                <Col sm={9}>
                  <a href="/dashboard" className="navbar-brand-client d-flex  navbar-brand">
                    <img className="m-auto" width="200" alt="TANGIBLY" src={CANOPY} />
                  </a>
                </Col>
                <Col sm={2} className="p-2">
                  <Button className="navbar-toggler pl-4" variant="light" onClick={handleClose}>
                    <i className="fa fa-times" aria-hidden="true" />
                  </Button>
                </Col>
              </Row>
              <hr />
            </Container>
          </Modal.Body>
        </Modal>
      </header>
    </>
  );
};

export default MobileHeader;
