import { TangiTypography } from '_components/TangiLibrary';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DuoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  box-sizing: border-box;
  > div {
    flex: 1;
  }
`;

export const SubmitContainer = styled.div`
  margin-left: auto;
  margin-top: ${(props) => (props.currentPage === 1 ? SPACING[1] : SPACING[0])};
`;

export const ModalHeader = styled(Modal.Header)`
  padding: ${SPACING[2]} ${SPACING[4]};
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${NEUTRAL_SHADES[400]} !important;
  > div {
    width: 100%;
  }
`;

export const ModalBody = styled(Modal.Body)`
  padding: ${SPACING[3]} ${SPACING[4]};
  max-height: 550px;
  overflow-y: auto;
  scroll-behavior: smooth;

  @media (max-height: 750px) {
    max-height: 470px;
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  border-top: 1px solid ${NEUTRAL_SHADES[400]} !important;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACING[3]};
`;

export const BreadcrumbPageTitle = styled(TangiTypography)`
  display: flex;
  align-items: flex-start;
  gap: ${SPACING[2]};
`;
