/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createAssetType,
  createBusinessUnit,
  createBusinessValue,
  createDepartment,
  createProject,
  createTag,
  getAssetMetaData,
  getAuthors,
  createAssetMetaData,
  getAllRecipients,
} from 'redux-toolkit/thunks/assetMetaDataThunks';
import { assetTypeNameFormatter } from '../../utils/assetTypes';
import { Tag } from '../../utils/types/assetMetaData/assetMetaData';
import { AssetMetaDataValue, ImportanceFieldValue, RecipientsFiltersArray } from 'utils/types/assets/assets';
import { RecipientsFilters } from '_components/AssetFilters/types';

export interface AssetMetaDataState {
  tags: Tag[];
  projects: any[];
  departments: any[];
  countries: any[];
  businessUnits: any[];
  assetTypes: any[];
  authors: any[];
  businessValues: any[];
  error: string;
  status: string;
  loading: boolean;
  newProject: any;
  newTag: any;
  newDepartment: any;
  newBusinessUnit: any;
  newBusinessValue: any;
  newAssetType: any;
  creatableLoading: boolean;
  allRecipients: RecipientsFiltersArray;
  isMetaDataFetched: boolean;

  //new metadata object
  isPublished: boolean;
  importance: ImportanceFieldValue;
  businessRelated: AssetMetaDataValue[];
  commercialProduct: AssetMetaDataValue[];
  newBusinessRelated: null | AssetMetaDataValue;
  newCommercialProduct: null | AssetMetaDataValue;
  [key: string]: any; //TODO - need to change this type
}

const initialState: AssetMetaDataState = {
  tags: [],
  projects: [],
  departments: [],
  countries: [],
  businessUnits: [],
  assetTypes: [],
  authors: [],
  businessValues: [],
  error: '',
  status: '',
  loading: false,
  newProject: null,
  newTag: null,
  newDepartment: null,
  newBusinessUnit: null,
  newBusinessValue: null,
  newAssetType: null,
  creatableLoading: false,
  isPublished: false,
  importance: {
    value: '',
    score: '',
  },
  businessRelated: [],
  commercialProduct: [],
  newBusinessRelated: null,
  newCommercialProduct: null,
  allRecipients: [],
  isMetaDataFetched: false,
};

const assetMetaDataSlice = createSlice({
  name: 'assetMetaData',
  initialState,
  reducers: {
    setClearStatus(state) {
      state.creatableLoading = false;
      state.status = '';
      state.error = '';
      state.loading = false;
    },
    setClearCreatable(state) {
      state.newProject = null;
      state.newTag = null;
      state.newDepartment = null;
      state.newBusinessUnit = null;
      state.newBusinessValue = null;
      state.newAssetType = null;
      state.newBusinessRelated = null;
      state.newCommercialProduct = null;
    },
  },
  extraReducers: (builder) => {
    // createTag
    builder.addCase(createTag.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createTag.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'success';
      if (action?.payload?.newRecords?.length) {
        state.tags = [...state.tags, action.payload.newRecords[0]];
        [state.newTag] = action.payload.newRecords;
      }
      state.creatableLoading = false;
    });
    builder.addCase(createTag.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // createProject
    builder.addCase(createProject.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createProject.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'success';
      if (action?.payload?.newRecords?.length) {
        state.projects = [...state.projects, action.payload.newRecords[0]];
        [state.newProject] = action.payload.newRecords;
      }
      state.creatableLoading = false;
    });

    builder.addCase(createProject.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // createDepartment
    builder.addCase(createDepartment.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createDepartment.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'success';
      if (action?.payload?.newRecords?.length) {
        state.departments = [...state.departments, action.payload.newRecords[0]];
        [state.newDepartment] = action.payload.newRecords;
      }
      state.creatableLoading = false;
    });
    builder.addCase(createDepartment.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // createAssetType
    builder.addCase(createAssetType.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createAssetType.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'success';
      if (action?.payload?.newRecords?.length) {
        state.assetTypes = [...state.assetTypes, action.payload.newRecords[0]];
        state.assetTypes = state.assetTypes.map((type: any) => (type?.name ? { ...type, formattedName: assetTypeNameFormatter(type.name) } : ''));
        [state.newAssetType] = action.payload.newRecords;
      }
      state.creatableLoading = false;
    });
    builder.addCase(createAssetType.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // createBusinessValue
    builder.addCase(createBusinessValue.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createBusinessValue.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'success';
      if (action?.payload?.newRecords?.length) {
        state.businessValues = [...state.businessValues, action.payload.newRecords[0]];
        [state.newBusinessValue] = action.payload.newRecords;
      }
      state.creatableLoading = false;
    });
    builder.addCase(createBusinessValue.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // createBusinessUnit
    builder.addCase(createBusinessUnit.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createBusinessUnit.fulfilled, (state, action: PayloadAction<any>) => {
      state.status = 'success';
      if (action?.payload?.newRecords?.length) {
        state.businessUnits = [...state.businessUnits, action.payload.newRecords[0]];
        [state.newBusinessUnit] = action.payload.newRecords;
      }
      state.creatableLoading = false;
    });
    builder.addCase(createBusinessUnit.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // getAuthors
    builder.addCase(getAuthors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAuthors.fulfilled, (state, action: PayloadAction<any>) => {
      state.authors = action.payload;
      state.loading = false;
    });
    builder.addCase(getAuthors.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.authors = [];
      state.loading = false;
    });

    // getAssetMetaData
    builder.addCase(getAssetMetaData.pending, (state) => {
      state.isMetaDataFetched = false;
      state.loading = true;
    });
    builder.addCase(getAssetMetaData.fulfilled, (state, action: PayloadAction<any>) => {
      // { authors, tags, assetTypes, businessValues, projects, departments, businessUnits, returnCountries }
      state.authors = action.payload.authors;
      state.tags = action.payload.tags.data;
      state.assetTypes = action.payload.assetTypes.data.map((type: any) => (type?.name ? { ...type, formattedName: assetTypeNameFormatter(type.name) } : ''));
      state.businessValues = action.payload.businessValues.data;
      state.projects = action.payload.projects.data;
      state.departments = action.payload.departments.data;
      state.businessUnits = action.payload.businessUnits.data;
      state.countries = action.payload.returnCountries;
      state.businessRelated = action.payload?.metaData?.businessRelated ?? [];
      state.commercialProduct = action.payload?.metaData?.commercialProduct ?? [];
      state.isMetaDataFetched = true;
      state.loading = false;
    });
    builder.addCase(getAssetMetaData.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.isMetaDataFetched = false;
      state.loading = false;
    });

    // createAssetMetaData
    builder.addCase(createAssetMetaData.pending, (state) => {
      state.creatableLoading = true;
    });
    builder.addCase(createAssetMetaData.fulfilled, (state, action: PayloadAction<AssetMetaDataValue>) => {
      const payloadType = action.payload.type;

      state[payloadType] = [...state[payloadType], action.payload];
      const capitalizedPayloadType = payloadType.charAt(0).toUpperCase() + payloadType.slice(1);

      state[`new${capitalizedPayloadType}`] = action.payload;

      state.creatableLoading = false;
    });
    builder.addCase(createAssetMetaData.rejected, (state, action: any) => {
      // when duplicate name - action.payload will contain 'Duplicate name value'
      state.error = action.error.message;
      state.status = 'failed';
      state.creatableLoading = false;
    });

    // get all the unique recipients (for filters)
    builder.addCase(getAllRecipients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllRecipients.fulfilled, (state, action: PayloadAction<RecipientsFilters[]>) => {
      state.allRecipients = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllRecipients.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});
export const assetMetaDataActions = assetMetaDataSlice.actions;

export default assetMetaDataSlice.reducer;
