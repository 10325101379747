import styled from 'styled-components';
import { Divider, FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const TooltipContainer = styled(FlexContainer)`
  background-color: ${NEUTRAL_SHADES.WHITE};
  width: 240px;
  border-radius: ${SPACING[1]};
  padding: ${SPACING[2]} ${SPACING[3]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  box-shadow: 0px 3px 16px 0px #0000001f;
`;

export const DataContainer = styled.div`
  & > ${Divider}:last-child {
    display: none;
  }
`;

export const TypeColor = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
`;

export const CustomDivider = styled(Divider)`
  margin-bottom: ${SPACING[1]};
`;

export const FlexBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipTitleContainer = styled.div`
  display: flex;
  align-items: bottom;
  gap: ${SPACING[1]};
`;

export const TooltipDataContainer = styled(FlexBetweenContainer)`
  align-items: center;
  padding: ${SPACING[1]} 0;
`;

export const TooltipLabel = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  align-items: center;
`;
