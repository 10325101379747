import { getLabelValueFormat, returnValuesFromArray } from '../../utils/getOptions';

/**
 * Strips the time part from a date-time string, returning only the date.
 * If the input is null or undefined, returns an empty string.
 * @param {string} dateString - The date-time string to format.
 * @returns {string} - The formatted date string (YYYY-MM-DD) or an empty string if the input is invalid.
 */
export function stripTimeFromDate(dateString) {
  return dateString?.split('T')[0] || '';
}

/**
 * Formats an agreement item from the API data into a structure suitable for form use.
 *
 * - Strips the time part from the expiry and signed dates.
 * - Converts the version and agreement type into label-value pairs.
 *
 * @param {Object} agreementItem - The agreement item object to be formatted.
 * @param {string} agreementItem.expiry_date - The expiry date of the agreement in ISO format.
 * @param {string} agreementItem.signed_date - The signed date of the agreement in ISO format.
 * @param {boolean} agreementItem.signed - The signed status of the agreement.
 * @param {Array} agreementItem.files - An array of files associated with the agreement.
 * @param {Object} agreementItem.agreementType - The type of the agreement, including its ID.
 * @param {string} agreementItem._id - The unique identifier of the agreement.
 * @param {Object} agreementItem.version - The version of the agreement, including its name and ID.
 *
 * @returns {Object} The formatted agreement object with the following structure:
 * - expiry_date: The formatted expiry date (YYYY-MM-DD).
 * - signed_date: The formatted signed date (YYYY-MM-DD).
 * - signed: The signed status of the agreement.
 * - files: The array of files associated with the agreement.
 * - id: The unique identifier of the agreement.
 * - agreementType: The ID of the agreement type.
 * - version: An object with `label` and `value` representing the agreement version, or `null` if no version exists.ementItem);
 */
const formatAgreementData = (agreementItem) => {
  const { expiry_date: expDate, signed_date: signDate, signed, files, agreementType, _id, version } = agreementItem;
  const expiryDate = stripTimeFromDate(expDate);
  const signedDate = stripTimeFromDate(signDate);
  const agreementVersion = version ? { label: version?.name, value: version?._id } : null;
  return { expiry_date: expiryDate, signed_date: signedDate, signed, files, id: _id, agreementType: agreementType?._id, version: agreementVersion };
};

/**
 * Converts API response data into a format suitable for form fields.
 *
 * - Strips the time part from date fields.
 * - Formats arrays into label-value pairs for use in dropdowns or multi-select fields.
 * - Maps agreements to a specific format for use in the form.
 *
 * @param {Object} apiData - The API response data to be transformed.
 * @param {Array} apiData.businessUnits - The user's business units as returned by the API.
 * @param {string} apiData.dateOfJoining - The date of joining in ISO format (Hire Date).
 * @param {string} apiData.dateOfLeaving - The date of leaving in ISO format (Exit Date).
 * @param {Array} apiData.agreements - An array of agreements as returned by the API.
 * @param {Array} apiData.departments - The user's departments as returned by the API.
 * @param {Object} apiData.role - The user's role object, including the role name.
 * @param {string} apiData.country - The user's country.
 *
 * @returns {Object} The formatted data object ready for use in form fields, with the following structure:
 * - country: An object with `label` and `value` representing the user's country.
 * - businessUnits: An array of label-value pairs for the user's business units.
 * - agreements: An array of formatted agreement objects.
 * - dateOfJoining: The formatted joining date (YYYY-MM-DD).
 * - dateOfLeaving: The formatted leaving date (YYYY-MM-DD).
 * - departments: An array of label-value pairs for the user's departments.
 * - role: An object with `label` and `value` representing the user's role.
 */
const apiToFormFormat = (apiData) => {
  const { businessUnits: userBusinessUnits, dateOfJoining, dateOfLeaving, agreements: apiAgreements, departments: userDepartments, role, country } = apiData;

  const joiningDate = stripTimeFromDate(dateOfJoining);
  const leavingDate = stripTimeFromDate(dateOfLeaving);
  const accountsAgreements = apiAgreements.map((agreementItem) => {
    return formatAgreementData(agreementItem);
  });

  const accountsDepartments = getLabelValueFormat(userDepartments);
  const accountsBusinessUnits = getLabelValueFormat(userBusinessUnits);
  const accountsCountry = { label: country, value: country };
  const accountsRole = { label: role.name, value: role.name };

  return {
    ...apiData,
    country: accountsCountry,
    businessUnits: accountsBusinessUnits,
    agreements: accountsAgreements,
    dateOfJoining: joiningDate,
    dateOfLeaving: leavingDate,
    departments: accountsDepartments,
    role: accountsRole,
  };
};

/**
 * Converts form data into a format suitable for API submission.
 *
 * - Replaces all `null` values in the form data with empty strings.
 * - Extracts and formats specific fields from the form data for API submission.
 *
 * @param {Object} formData - The form data object to be transformed.
 * @param {Object} formData.country - The country object with a value property.
 * @param {Array} formData.businessUnits - An array of business units.
 * @param {Array} formData.agreements - An array of agreements, each with a `signed` status and `version`.
 * @param {Array} formData.departments - An array of departments.
 * @param {Object} formData.role - The role object with a value property.
 * @param {string} formData.dateOfJoining - The date of joining (Hire Date)
 * @param {string} formData.dateOfLeaving - The date of leaving (Exit Date).
 * @param {string} formData.displayName - The display name of the user.
 * @param {string} formData.employeeId - The employee ID.
 * @param {string} formData.linkedin - The LinkedIn profile URL.
 * @param {string} formData.location - The location of the user.
 * @param {string} formData.others1 - Additional information field 1.
 * @param {string} formData.others2 - Additional information field 2.
 * @param {string} formData.phone - The phone number.
 * @param {string} formData.status - The account status.
 * @param {string} formData.title - The job title.
 * @param {string} formData.email - The email address, which will be converted to lowercase and trimmed.
 * @param {boolean} formData.externalUser - Whether the user is an external user (Okta).
 *
 * @returns {Object} The formatted object ready for API submission, with the following structure:
 * - departments: Array of department values.
 * - businessUnits: Array of business unit values.
 * - agreements: Array of formatted agreement objects.
 * - role: The selected role value.
 * - country: The selected country value.
 * - dateOfJoining: The date of joining.
 * - dateOfLeaving: The date of leaving.
 * - displayName: The display name of the user.
 * - employeeId: The employee ID.
 * - linkedin: The LinkedIn profile URL.
 * - location: The location of the user.
 * - others1: Additional information field 1.
 * - others2: Additional information field 2.
 * - phone: The phone number.
 * - status: The account status.
 * - title: The job title.
 * - email: The email address, in lowercase and trimmed format.
 * - externalUser: Whether the user is an external user (Okta).
 */
const formToApiFormat = (formData) => {
  // Change fields with null to empty string
  Object.keys(formData).forEach((key) => {
    if (formData[key] === null) {
      formData[key] = '';
    }
  });

  const {
    country,
    businessUnits,
    agreements,
    departments,
    role,
    dateOfJoining,
    dateOfLeaving,
    displayName,
    employeeId,
    linkedin,
    location,
    others1,
    others2,
    phone,
    status,
    title,
    email,
    externalUser,
  } = formData;

  const formattedDepartments = returnValuesFromArray(departments ?? []);
  const formattedBusinessUnits = returnValuesFromArray(businessUnits ?? []);
  const formattedAgreements = agreements?.map((agreement) => {
    if (agreement?.signed) {
      return { ...agreement, version: agreement.version?.value };
    } else {
      return { ...agreement, version: undefined, expiry_date: undefined, signed_date: undefined, signed: false };
    }
  });

  return {
    departments: formattedDepartments,
    businessUnits: formattedBusinessUnits,
    agreements: formattedAgreements,
    role: role.value,
    country: country?.value,
    dateOfJoining,
    dateOfLeaving,
    displayName,
    employeeId,
    linkedin,
    location,
    others1,
    others2,
    phone,
    status,
    title,
    email: email.toLowerCase().trim(),
    externalUser,
  };
};

export { apiToFormFormat, formToApiFormat };
