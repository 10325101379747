import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Row, Col, FormControl, InputGroup, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './AdminUsers.scss';
import { AdminDashboard } from '../AdminDashboard';
import { AdminUsersForm } from '../../../_components/Admin/AdminUsersForm';
import { Loader } from '../../../_components/Loader';
import { adminUserActions } from '../../../AdminRedux/_actions';
import { Table } from '../../../_components/Admin/DataTable/Table';

function AdminUsers() {
  const location = useLocation();
  const patharray = location.pathname.split('type=');
  const type = patharray && patharray[1] ? patharray[1] : '';
  const [isloading, setIsloading] = useState(false);
  const [istotal, setIsTotal] = useState(0);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({ searchTxt: '' });
  const [usernametxt, setusernametxt] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTxt } = inputs;

  const { data, metadata } = useSelector((state) => state.adminUserReducer && state.adminUserReducer.results);
  const { sortBy } = useSelector((state) => state.clientAdmin);

  useEffect(() => {
    setIsloading(true);
    dispatch(adminUserActions.getAdminUser(1));
  }, [dispatch]);

  useEffect(() => {
    if (data && data.length > 0) {
      setIsTotal(metadata && metadata[0] && metadata[0].total);
      setIsloading(false);
      setComments(data);
    } else {
      setIsTotal(0);
      setTimeout(() => {
        setIsloading(false);
      }, 12000);
    }
  }, [data]);

  const [comments, setComments] = useState([]);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'Username', field: 'username', sortable: true },
    { name: 'Email', field: 'email', sortable: true },
    { name: 'Role', field: 'role.name', sortable: true },
    { name: 'Client/Law Firm', field: 'clientOrLawfirm', sortable: false },
    // { name: 'Law Firm', field: 'lawFirm', sortable: false },
    { name: 'Actions', field: 'actions', sortable: false },
  ];

  function onPageChanged(page) {
    setIsloading(true);
    setCurrentPage(page.currentPage);
    dispatch(adminUserActions.getAdminUser(page.currentPage, searchTxt && searchTxt ? searchTxt : '', sortBy));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'searchTxt' && !value) {
      dispatch(adminUserActions.getAdminUser(1));
    } else {
      setusernametxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (!searchTxt) {
      setusernametxt(true);
    } else {
      setusernametxt(false);
      setComments([]);
      setIsloading(true);
      dispatch(adminUserActions.getAdminUser(1, searchTxt));
    }
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <AdminDashboard title="Users">
      <Loader isLoading={isloading} />
      <Container fluid className="mrx-4  pb-8 pt-3 pt-md-8">
        {!type ? (
          <div>
            <nav className="mb-3 navbar navbar-expand-md">
              <ul className="mr-auto navbar-nav">
                <li className="nav-item">
                  <h6 className="h3 font-weight-bold text-dark m-0">Users</h6>
                </li>
              </ul>
              <div className="navbar-brand mx-2">
                <Button data-testid="newUser" className="m-1 p-2 rounded" href="users&type=addnew" variant="outline-primary">
                  Add New
                </Button>{' '}
              </div>
            </nav>
            <div className="bg-white border-0 card-header shadow-sm p-2 pl-2 rounded-3">
              <div className="row m-2">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                    <InputGroup className="mb-3">
                      <FormControl
                        className={usernametxt ? 'border border-invalid' : ''}
                        name="searchTxt"
                        onChange={handleChange}
                        onKeyUp={handleKeypress}
                        type="text"
                        placeholder="Search"
                        aria-label="searchTxt"
                        aria-describedby="basic-addon2"
                        value={searchTxt}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                          <i className="fas fa-search" aria-hidden="true" />
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
                  </Form.Group>
                </div>
              </div>
              <Table
                currentPage={currentPage}
                ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                onPageChanged={onPageChanged}
                headers={headers}
                comments={comments}
                totalResults={istotal && istotal ? istotal : 0}
                type="user"
              />
            </div>
          </div>
        ) : (
          <Row>
            <nav className="mb-3 navbar navbar-expand-md">
              <ul className="mr-auto navbar-nav">
                <li className="nav-item">
                  <h6 className="h3 font-weight-bold text-dark m-0">Add New</h6>
                </li>
              </ul>
            </nav>
            <Col sm={12}>
              <AdminUsersForm type="Add New User" />
            </Col>
          </Row>
        )}
      </Container>
    </AdminDashboard>
  );
}

export { AdminUsers };
