import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TangiButton, TangiTypography, TangiNotification, NOTIFICATION_VARIANTS } from '../../TangiLibrary';
import XrayStatusNotification from '_components/XrayStatusNotification/XrayStatusNotification';
import { RootState } from '_helpers';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XrayLoaders } from 'utils/types/xRay/xRay';
import { LOADING_TRANSLATION_KEY } from '_components/XrayStatusNotification/types';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const RecentResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${SPACING[2]};
`;

export const SeeResultsBtnContainer = styled.div`
  margin-top: -${SPACING[2]};
  margin-left: ${SPACING[3]};
`;

interface Props {
  status: XRAY_JOB_STATUS;
  fileName: string;
  details: string;
  resultsNum: number;
  contractSource: XRAY_SOURCE_TYPE;
  onClick: () => void;
  onCancel: () => void;
}

const ContractStatusNotification = ({ status, fileName, details, onClick, onCancel }: Props) => {
  const { t } = useTranslation();

  const contractLoaders: XrayLoaders = useSelector((state: RootState) => state.contract.contractLoaders);

  const renderJobSucceededNotification = () => {
    return (
      <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO} startIcon={false}>
        <TangiTypography weight="semibold" type="heading-md" color={PRIMARY_SHADES[800]}>
          {t('DISCOVERY_LOBBY.PATENT_NOTIFICATION.RECENT_SEARCH')}
        </TangiTypography>
        <RecentResultsContainer>
          <TangiTypography weight="semibold" type="subheading">
            {t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.WE_FOUND_RESULTS', { FILE_NAME: fileName })}
          </TangiTypography>
          {fileName && (
            <SeeResultsBtnContainer>
              <TangiButton
                variant="secondary"
                text={t('DISCOVERY_LOBBY.PATENT_NOTIFICATION_BUTON.SEE_RESULTS')}
                onClick={onClick}
                endIcon={true}
                svgIcon="arrowRight"
                data-testid="contract-recent-results"
              />
            </SeeResultsBtnContainer>
          )}
        </RecentResultsContainer>
      </TangiNotification>
    );
  };

  return (
    <XrayStatusNotification
      status={status}
      loadingTranslationKey={LOADING_TRANSLATION_KEY.CONTRACT}
      isCancelDisabled={contractLoaders.startLoader || contractLoaders.getResultLoader}
      errorDetails={details}
      onCancel={onCancel}
      statusSucceededComponent={renderJobSucceededNotification()}
    />
  );
};

export default ContractStatusNotification;
