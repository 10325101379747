import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  justify-content: flex-end;
  width: 100%;
`;

export const LeftButtonContainer = styled.div`
  flex: 1;
`;

export const RightButtonsContainer = styled(FlexContainer)`
  gap: ${SPACING[3]};
`;
