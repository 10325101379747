import { useTranslation } from 'react-i18next';

import { TangiButton } from '_components/TangiLibrary';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import PatentExportModal from './components/PatentExport/PatentExportModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { patentActions } from 'redux-toolkit/slices/patentSlice';

export const PatentExport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const patentData = useSelector((state: RootState) => state.patent.patentData);

  const handleExportClick = () => {
    setIsModalOpen(true);
    dispatch(patentActions.setShowExportToast(false));
  };

  const handleExportOnClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <TangiButton
        size="large"
        variant="secondary"
        data-testid="exportCsv"
        svgIcon="download"
        onClick={handleExportClick}
        text={t('DASHBOARD_PAGE.HEADER.EXPORT_DATA')}
        loading={patentData.status_mapper !== XRAY_JOB_STATUS.SUCCEEDED && patentData.status_mapper !== XRAY_JOB_STATUS.FAILED}
        smallbtn={true}
      />
      <PatentExportModal isOpen={isModalOpen} onClose={handleExportOnClose} />
    </>
  );
};
