import _ from 'lodash';
import Tippy from '@tippyjs/react';
import { AxisProps, AxisTickProps } from '@nivo/axes';
import { Datum } from '@nivo/legends';

import { TangiTypography } from '../../../../_components/TangiLibrary/TangiTypography';
import { HeatmapItem } from '../../CompetitiveAnalysis/types';
import { StackedBarChartDataObject } from 'pages/ClientDashboard/components/Widgets/AssetsOverTime/assetsOverTime.logic';
import { MIN_TOTAL } from './consts';
import { NEUTRAL_SHADES, PIE_CHART_SHUFFLED } from 'utils/theme';

interface ProcessedHeatmapData {
  data: StackedBarChartDataObject[];
  keys: string[];
  aggregatedDataKeys: Datum[]; //Used to generate legends
}

/**
 * Processes heatmap data to generate an array of objects suitable for use in a Nivo stacked bar chart.
 * The function filters out items where the total `ts_count` is less than a specified minimum (`MIN_TOTAL`).
 * It assigns colors to companies based on their order in the input data, up to a maximum number of companies.
 * Companies beyond the maximum are grouped under "Other" and assigned the last color in the provided color palette.
 * The function also generates an array of `aggregatedDataKeys` to store each company with its associated ID and color.
 *
 * @param {HeatmapItem[]} heatMapData - The input data for the heatmap, consisting of multiple subjects with associated company data.
 * @param {string[]} [palette=PIE_CHART_SHUFFLED] - The color palette used to assign colors to the companies. The last color in the palette is reserved for the "Other" group.
 * @returns {{
 *   data: StackedBarChartDataObject[],
 *   keys: string[],
 *   aggregatedDataKeys: { id: number, label: string, color: string }[]
 * }} - An object containing:
 *  - `data`: An array of objects, each representing a subject with associated company counts and colors.
 *  - `keys`: An array of unique company names used in the dataset, including "Other" if applicable.
 *  - `aggregatedDataKeys`: An array of objects where each object contains `id`, `label` (company name), and `color` (associated color) for each company, including "Other" if applicable.
 *
 */
export const getTSBySubjects = (heatMapData: HeatmapItem[], palette: string[] = PIE_CHART_SHUFFLED): ProcessedHeatmapData => {
  const keys: string[] = [];
  const aggregatedDataKeys: Datum[] = [];
  let companyId = 0;

  const maxCompanies = palette.length - 1; // Max number of companies before grouping under "Other" - is the number of colors in the palette

  const result = heatMapData.map((item) => {
    const companyData = item.groups.map((group) => ({
      company: group.company,
      ts_count: group.ts_count,
    }));

    const maxCompaniesData = companyData.slice(0, maxCompanies);

    // Group the rest under "Other"
    if (companyData.length > maxCompanies) {
      const otherCount = _.sumBy(companyData.slice(maxCompanies), 'ts_count');
      maxCompaniesData.push({ company: 'Other', ts_count: otherCount });
    }

    const groupsData = maxCompaniesData.reduce((acc, data, index) => {
      const color = index < maxCompanies ? palette[index] : palette[palette.length - 1];

      acc[`${data.company}`] = data.ts_count;
      acc[`${data.company}Color`] = color;

      if (!keys.includes(data.company)) {
        keys.push(data.company);
        aggregatedDataKeys.push({ id: companyId++, label: data.company, color });
      }

      return acc;
    }, {} as Record<string, number | string>);

    const total = _.sumBy(maxCompaniesData, 'ts_count');

    return {
      total,
      label: item.subject,
      ...groupsData,
    };
  });

  const filteredResult = result.filter((item) => item.total >= MIN_TOTAL);
  const sortedResult = _.orderBy(filteredResult, ['total'], ['desc']);

  return { data: sortedResult, keys, aggregatedDataKeys };
};

/**
 * Generates the configuration for the bottom axis of a Nivo bar chart.
 * This configuration truncates labels that exceed a specified maximum length,
 * adds an ellipsis to truncated labels, and displays the full label in a Tippy tooltip on hover.
 *
 * @returns {AxisProps<string>} Configuration object for the Nivo bottom axis.
 */
export const getAxisBottomConfig = (): AxisProps<string> => {
  const maxLabelLength = 12;
  const translateY = 16;

  return {
    renderTick: (tick: AxisTickProps<string>): JSX.Element => {
      const label = String(tick.value);

      const truncatedLabel = label.length > maxLabelLength ? `${label.slice(0, maxLabelLength)}...` : label;

      return (
        <g transform={`translate(${tick.x},${tick.y + translateY})`}>
          <Tippy placement="top" disabled={!Boolean(label?.length)} content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{label}</TangiTypography>}>
            <text
              textAnchor="middle"
              style={{
                fontSize: 12,
                fill: NEUTRAL_SHADES[700],
                cursor: 'default',
              }}
            >
              {truncatedLabel}
            </text>
          </Tippy>
        </g>
      );
    },
  };
};
