import styled from 'styled-components';
import { NEUTRAL_SHADES } from 'utils/theme';

export const StyledContainer = styled.div`
  width: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'DIN2014-DemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  padding-top: 8px;
  color: ${NEUTRAL_SHADES.BLACK};
`;
export const Description = styled.div`
  font-family: 'DIN2014-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 500px;
  color: ${NEUTRAL_SHADES[900]};

  align-items: center;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  padding-bottom: 8px;
`;

export const SvgContainer = styled.div`
  padding-top: 24px;
`;
