import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFExport } from '@progress/kendo-react-pdf';
import { useDispatch, useSelector } from 'react-redux';

import { TangiButton, TangiIconButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import CheckboxItem from './CheckBoxItem';
import { exportPatentToCSV } from './utils';
import PatentExportPDF from './PatentExportPDF';
import { RootState } from '_helpers';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { EXPORT_OPTIONS, ExportCheckboxOption } from 'pages/PatentTool/types';
import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';
import { BUTTON_VARIANTS } from 'utils/theme';
import { Divider, StyledModal } from 'utils/globalStyles';
import { Container, IconAndText, StyledModalBody, StyledModalFooter, StyledModalHeader, TitleContainer } from './style';
import { PatentApplicationData } from 'utils/types/patent/patent';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface CheckboxAction {
  id: string;
  action: () => void;
}

const PatentExportModal = ({ onClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const pdfExportComponent = useRef<PDFExport>(null);
  const dispatch = useDispatch();

  const patentData = useSelector((state: RootState) => state.patent.patentData) as PatentApplicationData;
  const checkboxes = useSelector((state: RootState) => state.patent.exportFilesCheckboxes) as ExportCheckboxOption[];

  const isAllCheckboxesOff = useMemo(() => checkboxes.every((checkbox) => !checkbox.checked), [checkboxes]);

  const handleClose = () => {
    onClose();
  };

  const checkboxActions: Record<string, CheckboxAction['action']> = {
    mappingAnalysisExcel: () => exportPatentToCSV({ patentData }),
    tradeSecretAnalysisPdf: () => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    },
  };

  const renderModalHeader = () => {
    return (
      <StyledModalHeader>
        <TitleContainer>
          <IconAndText>
            <TangiSvgIcon component="discovery" />
            <TangiTypography type="heading-lg">{t('PATENT_MAPPING_TOOL.MODAL.EXPORT_PATENT_DATA')}</TangiTypography>
          </IconAndText>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} icon="close" data-testid="close-button" />
        </TitleContainer>
      </StyledModalHeader>
    );
  };

  const handleExport = () => {
    dispatch(patentActions.setShowExportToast(true));
    checkboxes.forEach(({ id, checked }) => {
      if (checked && checkboxActions[id]) {
        checkboxActions[id]();
      }
    });

    const updateIds = [EXPORT_OPTIONS.MAPPING_ANALYSIS_EXCEL, EXPORT_OPTIONS.TRADE_SECRET_ANALYSIS_PDF];
    setTimeout(() => {
      updateIds.forEach((id) => {
        dispatch(
          patentActions.handleExportCheckboxChange({
            id,
            isLoading: false,
          }),
        );
      });
    }, INTERVAL_IN_MILLISECONDS.TwoAndHalfSeconds);

    onClose();
  };

  const renderModalBody = () => {
    return (
      <StyledModalBody>
        {checkboxes.map((checkbox) => {
          return (
            <Container key={checkbox.id}>
              <CheckboxItem
                id={checkbox.id}
                label={checkbox.label}
                isChecked={checkbox.checked}
                onCheckboxChange={(isChecked) => dispatch(patentActions.handleExportCheckboxChange({ id: checkbox.id, checked: isChecked }))}
              />
            </Container>
          );
        })}
      </StyledModalBody>
    );
  };

  const renderModalFooter = () => {
    return (
      <StyledModalFooter>
        <div>
          <TangiButton text={t('PATENT_MAPPING_TOOL.POPUP.EXPORT')} onClick={handleExport} loading={false} data-testid="export-button" disabled={isAllCheckboxesOff} />
        </div>
      </StyledModalFooter>
    );
  };

  return (
    <StyledModal transition="Fade" show={isOpen} onHide={onClose} data-testid="ExportPatentModal">
      {renderModalHeader()}
      {renderModalBody()}
      <Divider />
      {renderModalFooter()}
      {isOpen && patentData && <PatentExportPDF ref={pdfExportComponent} data={patentData} />}
    </StyledModal>
  );
};

export default PatentExportModal;
