import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';

export const getStepColor = (stepIndex: number, currentStepIndex: number) => {
  if (stepIndex < currentStepIndex) {
    return NEUTRAL_SHADES[600];
  }
  if (stepIndex === currentStepIndex) {
    return PRIMARY_SHADES[800];
  }
  return NEUTRAL_SHADES[1000];
};
