import { adminAssetContants } from '../_constants/adminAssetContants';

const initialState = {
  results: [],
  types: [],
  status: [],
  tags: [],
  business: [],
  editTag: null,
  viewTag: {},
  deleteTag: null,
  editbv: null,
  viewbv: {},
  deletebv: null,
  edittype: null,
  viewtype: {},
  deletetype: null,
  createAsset: {},
  shareAsset: {},
  uploadAssetFiles: {},
  errors: {},
  getAsset: {},
  updateAsset: {},
};

export function adminAssetReducer(state = initialState, action) {
  switch (action.type) {
    case adminAssetContants.ADMIN_ASSET_REQUEST:
      return {
        ...state,
        results: [],
      };
    case adminAssetContants.ADMIN_ASSET_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_FAILURE:
      return {
        ...state,
        results: [],
      };
    case adminAssetContants.ADMIN_ASSET_BUSINESS_REQUEST:
      return {
        ...state,
        business: [],
      };
    case adminAssetContants.ADMIN_ASSET_BUSINESS_SUCCESS:
      return {
        ...state,
        business: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_BUSINESS_FAILURE:
      return {
        ...state,
        business: [],
      };
    case adminAssetContants.ADMIN_ASSET_STATUS_REQUEST:
      return {
        ...state,
        status: [],
      };
    case adminAssetContants.ADMIN_ASSET_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_STATUS_FAILURE:
      return {
        ...state,
        status: [],
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_REQUEST:
      return {
        ...state,
        tags: [],
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_FAILURE:
      return {
        ...state,
        tags: [],
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_REQUEST:
      return {
        ...state,
        types: [],
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        types: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_FAILURE:
      return {
        ...state,
        types: [],
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_VIEW_REQUEST:
      return {
        ...state,
        viewTag: {},
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_VIEW_SUCCESS:
      return {
        ...state,
        viewTag: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_VIEW_FAILURE:
      return {
        ...state,
        viewTag: {},
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_EDIT_REQUEST:
      return {
        ...state,
        editTag: '',
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_EDIT_SUCCESS:
      return {
        ...state,
        editTag: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_EDIT_FAILURE:
      return {
        ...state,
        editTag: '',
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_DELETE_REQUEST:
      return {
        ...state,
        deleteTag: '',
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_DELETE_SUCCESS:
      return {
        ...state,
        deleteTag: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TAGS_DELETE_FAILURE:
      return {
        ...state,
        deleteTag: '',
      };
    case adminAssetContants.ADMIN_ASSET_BV_VIEW_REQUEST:
      return {
        ...state,
        viewbv: {},
      };
    case adminAssetContants.ADMIN_ASSET_BV_VIEW_SUCCESS:
      return {
        ...state,
        viewbv: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_BV_VIEW_FAILURE:
      return {
        ...state,
        viewbv: {},
      };
    case adminAssetContants.ADMIN_ASSET_BV_EDIT_REQUEST:
      return {
        ...state,
        editbv: '',
      };
    case adminAssetContants.ADMIN_ASSET_BV_EDIT_SUCCESS:
      return {
        ...state,
        editbv: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_BV_EDIT_FAILURE:
      return {
        ...state,
        editbv: '',
      };
    case adminAssetContants.ADMIN_ASSET_BV_DELETE_REQUEST:
      return {
        ...state,
        deletebv: '',
      };
    case adminAssetContants.ADMIN_ASSET_BV_DELETE_SUCCESS:
      return {
        ...state,
        deletebv: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_BV_DELETE_FAILURE:
      return {
        ...state,
        deletebv: '',
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_VIEW_REQUEST:
      return {
        ...state,
        viewtype: {},
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_VIEW_SUCCESS:
      return {
        ...state,
        viewtype: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_VIEW_FAILURE:
      return {
        ...state,
        viewtype: {},
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_EDIT_REQUEST:
      return {
        ...state,
        edittype: '',
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_EDIT_SUCCESS:
      return {
        ...state,
        edittype: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_EDIT_FAILURE:
      return {
        ...state,
        edittype: '',
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_DELETE_REQUEST:
      return {
        ...state,
        deletetype: '',
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        deletetype: action.payload,
      };
    case adminAssetContants.ADMIN_ASSET_TYPE_DELETE_FAILURE:
      return {
        ...state,
        deletetype: '',
      };

    case adminAssetContants.ADMIN_ASSET_CREATE:
      return {
        ...state,
        createAsset: {},
        shareAsset: {},
        uploadAssetFiles: {},
        errors: {},
      };

    case adminAssetContants.ADMIN_ASSET_CREATE_SUCCESS:
      return {
        ...state,
        createAsset: action.payload,
      };

    case adminAssetContants.ADMIN_ASSET_CREATE_FAILURE:
      return {
        ...state,
        createAsset: {},
        errors: action.errors,
      };

    case adminAssetContants.ADMIN_ASSET_SHARE_SUCCESS:
      return {
        ...state,
        shareAsset: action.payload,
      };

    case adminAssetContants.ADMIN_ASSET_SHARE_FAILURE:
      return {
        ...state,
        shareAsset: {},
        errors: action.errors,
      };
    case adminAssetContants.ADMIN_ASSET_FILE_SUCCESS:
      return {
        ...state,
        uploadAssetFiles: action.payload,
      };

    case adminAssetContants.ADMIN_ASSET_FILE_FAILURE:
      return {
        ...state,
        uploadAssetFiles: {},
        errors: action.errors,
      };

    case adminAssetContants.GET_ASSET_REQUEST:
      return {
        ...state,
        getAsset: {},
      };
    case adminAssetContants.GET_ASSET_SUCCESS:
      return {
        ...state,
        getAsset: action.payload,
      };
    case adminAssetContants.GET_ASSET_FAILURE:
      return {
        ...state,
        getAsset: {},
        errors: action.errors,
      };

    case adminAssetContants.ADMIN_ASSET_UPDATE:
      return {
        ...state,
        updateAsset: {},
        shareAsset: {},
        uploadAssetFiles: {},
        errors: {},
      };

    case adminAssetContants.ADMIN_ASSET_UPDATE_SUCCESS:
      return {
        ...state,
        updateAsset: action.payload,
      };

    case adminAssetContants.ADMIN_ASSET_UPDATE_FAILURE:
      return {
        ...state,
        updateAsset: {},
        errors: action.errors,
      };

    default:
      return state;
  }
}
