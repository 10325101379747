import { TangiSvgIcon } from '_components/TangiLibrary/TangiSvgIcon';
import { ChipContentContainer, StyledChip } from './styles';
import { ChipType } from './types';
import { VARIANT_COLOR, VARIANT_SVG } from './consts';

export interface Props {
  text: string | React.ReactNode;
  variant: ChipType;
}

export const ContractChip = ({ text, variant }: Props) => {
  return (
    <StyledChip data-testid={`${variant}-tangi-chip`} variant={variant}>
      <ChipContentContainer>
        <TangiSvgIcon component={VARIANT_SVG[variant]} size="20px" color={VARIANT_COLOR[variant]} />
        <span>{text}</span>
      </ChipContentContainer>
    </StyledChip>
  );
};
