import { HttpClientService } from '_services/HttpClientService';
import { headers } from '_services/utils/headers';
import { config } from 'config/config';
import { FiltersParams, PredictedPortfolioGraphsData, PredictedPortfolioTableData } from 'utils/types/predictedPortfolio/predictedPortfolio';

class predictedPortfolioAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;
  static predictedPortfolioEndPoint = `${predictedPortfolioAdapter.baseEndPoint}/ts-analysis/ip-portfolio/results`;
  static tableEndPoint = '/table';
  static graphsEndPoint = '/graphs';

  async getPredictedPortfolioTradeSecretsTable(limit: number, page: number, filters: FiltersParams): Promise<PredictedPortfolioTableData> {
    const params = {
      page,
      limit,
    };
    const res = await HttpClientService.post<PredictedPortfolioTableData>(`${predictedPortfolioAdapter.predictedPortfolioEndPoint}${predictedPortfolioAdapter.tableEndPoint}`, filters, {
      headers: headers(),
      params,
    });
    return res.data;
  }

  async getPredictedPortfolioGraphsResults(): Promise<PredictedPortfolioGraphsData> {
    const res = await HttpClientService.get<PredictedPortfolioGraphsData>(`${predictedPortfolioAdapter.predictedPortfolioEndPoint}${predictedPortfolioAdapter.graphsEndPoint}`, { headers: headers() });
    return res.data;
  }
}

const ipPortfolioAdapter = new predictedPortfolioAdapter();
export default ipPortfolioAdapter;
