import { TangiButton, TangiTypography } from '_components/TangiLibrary';
import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { useTranslation } from 'react-i18next';
import { Footer } from './styles';
import { SPACING } from 'utils/theme';

interface Props {
  onDiscard: () => void;
  onCancel: () => void;
  isOpenUnsavePopup: boolean;
};

const UnsavePopup = ({ onDiscard, onCancel, isOpenUnsavePopup }: Props) => {
  const { t } = useTranslation();

  const renderUnsavePopupFooter = () => {
    return (
      <Footer>
        <TangiButton variant="tertiary-grey" text={t('GENERAL.BUTTONS_TEXT.CANCEL')} onClick={onCancel} smallbtn />
        <TangiButton text={t('GENERAL.BUTTONS_TEXT.DISCARD')} data-cy='discard-changes' onClick={onDiscard} smallbtn disabled={false} loading={false} />
      </Footer>
    );
  };

  return (
    <TangiModal
      show={isOpenUnsavePopup}
      onHide={onCancel}
      header={
        <TangiTypography type={TYPOGRAPHY_TYPES.HEADING_LG} customStyles={`margin-right: ${SPACING[6]}`}>
          {t('IP_AUDIT.POPUP.DISCARD_CHANGES')}
        </TangiTypography>
      }
      body={<TangiTypography type={TYPOGRAPHY_TYPES.BODY}>{t('IP_AUDIT.POPUP.UNSAVE_INFORMATION')}</TangiTypography>}
      footer={renderUnsavePopupFooter()}
    />
  );
};

export default UnsavePopup;
