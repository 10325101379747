import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { Dropdown } from 'react-bootstrap';
import { addYears, parseISO, subYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import 'react-day-picker/dist/style.css';

import { TangiButton, TangiIconButton } from '../TangiLibrary';
import { StyledDropdownMenu, Footer } from './style';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { BackArrowContainer } from '_components/AssetFilters/MoreFilterDropDown/style';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'redux-toolkit/slices/usersSlice';

const DateRangeFilter = ({ id, onChange, handleBackNavigation, filterTitle }) => {
  const [month, setMonth] = useState(new Date());
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedDatesFromRedux = useSelector((state) => {
    const range = state.users.peoplePage.filters.selectedOptions.dateOfJoining;
    const fromObj = range.find((item) => item.name === 'From');
    const toObj = range.find((item) => item.name === 'To');
    return {
      from: fromObj ? parseISO(fromObj.id) : undefined,
      to: toObj ? parseISO(toObj.id) : undefined,
    };
  });

  const [localSelectedDates, setLocalSelectedDates] = useState(selectedDatesFromRedux);

  const handleApply = () => {
    const dateRangeAsStrings = {
      from: localSelectedDates?.from ? localSelectedDates.from.toISOString() : undefined,
      to: localSelectedDates?.to ? localSelectedDates.to.toISOString() : undefined,
    };
    dispatch(usersActions.setDateRange({ id, dateOfJoining: dateRangeAsStrings }));
    onChange(localSelectedDates, id);
  };

  const handleClear = () => {
    setLocalSelectedDates([]);
    dispatch(usersActions.resetDateRange());
    setMonth(new Date());
    onChange([], id);
  };

  return (
    <StyledDropdownMenu>
      <BackArrowContainer>
        <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="arrowSideLeft" onClick={() => handleBackNavigation()} />
        <span>{filterTitle}</span>
      </BackArrowContainer>
      <DayPicker
        mode="range"
        selected={localSelectedDates}
        onSelect={setLocalSelectedDates}
        modifiersClassNames={{
          selected: 'dates-selected',
        }}
        captionLayout="dropdown"
        fromYear={subYears(new Date(), 25).getFullYear()}
        toYear={addYears(new Date(), 5).getFullYear()}
        month={month}
        onMonthChange={setMonth}
      />
      <Dropdown.Divider />
      <Footer>
        <div>
          <TangiButton variant="tertiary" text={t('GENERAL.BUTTONS_TEXT.CLEAR_ALL')} size="small" onClick={handleClear} disabled={!localSelectedDates} data-testid="clear-filter" />
        </div>
        <div>
          <TangiButton text={t('GENERAL.BUTTONS_TEXT.APPLY')} size="small" onClick={handleApply} disabled={!localSelectedDates} data-testid="apply-filter" />
        </div>
      </Footer>
    </StyledDropdownMenu>
  );
};

export default DateRangeFilter;
