/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { EditAsyncSelect, optionTypes } from '../../../_components/EditAsyncSelect';
import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { AdminDashboard } from '../AdminDashboard';
import { Loader } from '../../../_components/Loader';
import { adminAssetActions } from '../../../AdminRedux/_actions/adminAssetActions';
import { validEmailPattern } from '../../../utils/formUtils';

function AdminAssetEditView() {
  const { type: formType, id: assetId } = useParams();

  const [assetStatus, setAssetStatus] = useState(null);
  const [isloading, setIsloading] = useState(false);

  const [defaults, setdefaults] = useState({});
  const [newFiles, setNewFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [existingRecipients, setExistingRecipients] = useState([]);

  // Form Constants
  const formTypes = {
    EDIT: 'edit',
    VIEW: 'view',
  };

  const assetStatusMsg = {
    ASSET_SUCCESS: 'Asset Updated Successfully',
  };

  const dispatch = useDispatch();

  const { getAsset, errors: assetErrors, updateAsset } = useSelector((state) => state.adminAssetReducer);

  function getSelectOptions(items, valueAccessor = 'id', labelAccessor = 'name') {
    const temp = [];
    items.forEach((item) => {
      temp.push({ value: item[valueAccessor], label: item[labelAccessor] });
    });

    return temp;
  }

  // Get Asset
  useEffect(() => {
    setIsloading(true);
    dispatch(adminAssetActions.getAdminAssetById(assetId));
  }, [dispatch]);

  // Populated the form
  useEffect(() => {
    if (!_.isEmpty(getAsset) && !assetName?.trim()?.length > 0) {
      const { name, description, files, client, assetType, businessValues, tags, contributor, recipients, id } = getAsset;

      setExistingFiles(files);
      setExistingRecipients(recipients);
      reset({ name, files: [], description, recipients: recipients || [], id });
      setValue('files', files);

      setdefaults({
        CLIENT: {
          value: client?._id || '',
          label: client?.name || '',
        },
      });

      setTimeout(() => {
        setdefaults((state) => ({
          ...state,
          ASSET_TYPE: { value: assetType?._id, label: assetType?.name },
          TAGS: getSelectOptions(tags, '_id'),
          BV: getSelectOptions(businessValues, '_id'),
          CONTRIBUTOR: getSelectOptions(contributor, '_id', 'displayName'),
          SHARE_LISTS: {},
        }));
        setIsloading(false);
      }, 400);
    }
  }, [getAsset]);

  // Update the form
  useEffect(() => {
    if (!_.isEmpty(updateAsset)) {
      setIsloading(false);
      setAssetStatus(assetStatusMsg.ASSET_SUCCESS);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else if (!_.isEmpty(assetErrors)) {
      setIsloading(false);
    }
  }, [assetErrors, updateAsset]);

  // React form related methods
  const {
    register,
    handleSubmit,
    setValue,
    control,
    // watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      newRecipients: [],
      newFiles: [],
    },
  });

  const assetName = useWatch({
    control,
    name: 'name',
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const client = useWatch({
    control,
    name: 'client',
    defaultValue: '',
  });

  // On Client change these fields should be emptied
  useEffect(() => {
    if (!_.isEmpty(client)) {
      setValue('businessValues', []);
      setValue('tags', []);
      setValue('assetType', '');
      setValue('contributor', []);
    }
  }, [client]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'newRecipients',
  });

  const handleAppendRecipient = () => {
    append({ fullName: '', email: '' });
  };

  function onSubmit(data) {
    setIsloading(true);
    dispatch(adminAssetActions.updateAdminAsset(data, assetId, newFiles));
  }

  // Dropzone related methods
  const onDrop = useCallback((acceptedFile) => {
    const mergeResult = [...newFiles, ...acceptedFile];
    setNewFiles(mergeResult);
    setValue('newFiles', mergeResult, { shouldValidate: true });
  });

  const removeExistingFile = (fileToRemove) => {
    const updatedList = existingFiles.filter((file) => file !== fileToRemove);
    setExistingFiles(updatedList);
    setValue('files', updatedList, { shouldValidate: true });
  };

  const removeFile = (fileToRemove) => {
    const updatedList = newFiles.filter((file) => file !== fileToRemove);
    setNewFiles(updatedList);
    setValue('newFiles', updatedList, { shouldValidate: true });
  };

  // View or edit mode
  function isViewMode(currentForm) {
    if (formTypes.VIEW === currentForm) return true;
    return false;
  }

  return (
    <AdminDashboard title={<span className="text-capitalize">Assets</span>}>
      <Loader isLoading={isloading} />
      <Container fluid className="pb-8 pt-3 pt-md-8">
        <div className="mb-2 card py-4 px-5">
          {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
          {!isViewMode(formType) ? <h1>Edit Asset</h1> : <h1>View Asset</h1>}
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Asset Name */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetName">
                <Form.Label className="text-dark fw-600">
                  Add Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control disabled={isViewMode(formType)} type="text" placeholder="Asset Name" {...register('name', { required: true })} />
              </Form.Group>
            </div>

            {/* Client  */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetClient">
                <Form.Label className="text-dark fw-600">
                  Client Id Reference <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  name="client"
                  render={({ field: { ref, ...rest } }) => (
                    <EditAsyncSelect isDisabled={isViewMode(formType)} defaultValue={defaults.CLIENT} inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.CLIENTS} {...rest} />
                  )}
                  control={control}
                  rules={{ required: true }}
                />
              </Form.Group>
            </div>

            {/* Description */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetDescription">
                <Form.Label className="text-dark fw-600">Description</Form.Label>
                <Form.Control disabled={isViewMode(formType)} as="textarea" style={{ height: '100px' }} {...register('description')} />
              </Form.Group>
            </div>

            {/* Asset tags */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetTags">
                <Form.Label className="text-dark fw-600">Tags</Form.Label>
                <Controller
                  name="tags"
                  render={({ field: { ref, ...rest } }) => (
                    <EditAsyncSelect
                      inputRef={ref}
                      selectStyles="ff-din-DemiBold"
                      type={optionTypes.TAGS}
                      isMulti
                      defaultValue={defaults.TAGS}
                      {...rest}
                      isDisabled={isViewMode(formType)}
                      clientId={dirtyFields.client && client}
                    />
                  )}
                  control={control}
                />
              </Form.Group>
            </div>

            {/* Asset Type */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetType">
                <Form.Label className="text-dark fw-600">
                  Type <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  name="assetType"
                  render={({ field: { ref, ...rest } }) => (
                    <EditAsyncSelect
                      inputRef={ref}
                      selectStyles="ff-din-DemiBold"
                      type={optionTypes.ASSET_TYPES}
                      defaultValue={defaults.ASSET_TYPE}
                      {...rest}
                      isDisabled={isViewMode(formType)}
                      clientId={dirtyFields.client && client}
                    />
                  )}
                  control={control}
                  rules={{ required: true }}
                />
              </Form.Group>
            </div>

            {/* Asset Status */}
            {/* <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetStatus">
                    <Form.Label className="text-dark fw-600">Status</Form.Label>
                    <Controller
                      name="status"
                      render={({ field: { ref, ...rest } }) => <ReactSelect inputRef={ref} selectStyles="ff-din-DemiBold" {...rest} options={parent} />}
                      control={control}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </div> */}

            {/* Asset Business Value */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetBusinessValue">
                <Form.Label className="text-dark fw-600">Business Value</Form.Label>
                <Controller
                  name="businessValues"
                  render={({ field: { ref, ...rest } }) => (
                    <EditAsyncSelect
                      inputRef={ref}
                      selectStyles="ff-din-DemiBold"
                      type={optionTypes.BUSINESS_VALUES}
                      {...rest}
                      isDisabled={isViewMode(formType)}
                      isMulti
                      defaultValue={defaults.BV}
                      clientId={dirtyFields.client && client}
                    />
                  )}
                  control={control}
                />
              </Form.Group>
            </div>

            {/* Asset Files */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetFiles">
                <Form.Label className="text-dark fw-600">Files</Form.Label>

                <ul className="p-0">
                  {existingFiles?.length > 0 && <h4 className="text-muted">Existing Files</h4>}
                  {existingFiles &&
                    existingFiles.length > 0 &&
                    existingFiles.map((file) => (
                      <li key={file.fileId} style={{ listStyle: 'none' }} className="mt-2 border border-black rounded-3">
                        <div className="px-4 py-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <i aria-hidden="true" className={`${icons[getFileExtensionCard(file.name)]} me-2 icon-big`} />
                              <div className="m-2">
                                <span>{file.name}</span>
                              </div>
                            </div>
                            {!isViewMode(formType) && (
                              <button type="button" onClick={() => removeExistingFile(file)} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                <i className="far fa-trash-alt" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>

                {!isViewMode(formType) && (
                  <>
                    <Controller
                      render={({ field }) => (
                        <Dropzone multiple {...field} onDrop={onDrop}>
                          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                            <div
                              {...getRootProps()}
                              className={`container w-100 bg-secondary p-5 text-center rounded text-muted dropZone ${errors && errors.files ? 'dropZone-error' : ''}`}
                              style={{ cursor: 'pointer' }}
                            >
                              <input {...getInputProps()} />
                              {!isDragActive && (
                                <span>
                                  <i aria-hidden="true" className="fas fa-upload me-2" />
                                  Drag &apos;n&apos; drop some files here, or click to select files
                                </span>
                              )}
                              {isDragActive && !isDragReject && (
                                <span>
                                  <i aria-hidden="true" className="fas fa-upload me-2" />
                                  Drop the files here.....
                                </span>
                              )}
                              {isDragReject && 'File type not Supported'}
                            </div>
                          )}
                        </Dropzone>
                      )}
                      name="newFiles"
                      control={control}
                    />
                    <ul className="p-0">
                      {newFiles?.length > 0 && <h4 className="mt-3 text-muted">New Files</h4>}
                      {newFiles &&
                        newFiles.length > 0 &&
                        newFiles.map((file) => (
                          <li key={uuidv4()} style={{ listStyle: 'none' }} className="mt-2 border border-black rounded-3">
                            <div className="px-4 py-1">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i aria-hidden="true" className={`${icons[getFileExtensionCard(file.name)]} me-2 icon-big`} />
                                  <div className="m-2">
                                    <span>{file.name}</span>
                                  </div>
                                </div>
                                <button type="button" onClick={() => removeFile(file)} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                  <i className="far fa-trash-alt" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </Form.Group>
            </div>

            {/* Asset Contributor */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetContributor">
                <Form.Label className="text-dark fw-600">
                  Contributor <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  name="contributor"
                  render={({ field: { ref, ...rest } }) => (
                    <EditAsyncSelect
                      inputRef={ref}
                      selectStyles="ff-din-DemiBold"
                      type={optionTypes.ASSET_CONTRIBUTORS}
                      {...rest}
                      isMulti
                      isDisabled={isViewMode(formType)}
                      clientId={dirtyFields.client && client && client}
                      defaultValue={defaults.CONTRIBUTOR}
                    />
                  )}
                  control={control}
                  rules={{ required: true }}
                />
              </Form.Group>
            </div>

            {/* Asset Recipients */}
            <div className="mt-3 border-0 row">
              <Form.Group className="mb-3" controlId="formAssetRecipients">
                <Form.Label className="text-dark fw-600">Share List</Form.Label>
                <h4 className="text-muted">Existing Recipients</h4>
                <div className="overflow-auto">
                  <table className="table table-bordered">
                    <thead>
                      <tr style={{ fontSize: '12px' }}>
                        <th scope="col">Full Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date Issued</th>
                        <th scope="col">Acknowledged Date</th>
                        <th scope="col">Acknowledged token</th>
                        <th scope="col">Preview token</th>
                      </tr>
                    </thead>
                    <tbody>
                      {existingRecipients?.length > 0 &&
                        existingRecipients.map((item) => (
                          <tr key={item._id} className="ff-din-regular" style={{ fontSize: '14px' }}>
                            <td>{item.fullName}</td>
                            <td>
                              <input readOnly value={item.email} />
                            </td>
                            <td>{item.acknowledged ? 'Acknowledged' : 'Not Acknowledged'}</td>
                            <td>{item.sharedOn?.split('T')[0]}</td>
                            <td>{item.acknowledgedOn && item.acknowledgedOn?.split('T')[0]}</td>
                            <td>
                              <input readOnly value={item.acknowledgement?.token} />
                            </td>
                            <td>
                              <input readOnly value={item.previewAsset?.token ? item.previewAsset.token : ''} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {!isViewMode(formType) && (
                  <>
                    <h4 className="text-muted">New Recipients</h4>

                    {fields?.length > 0 && (
                      <Row>
                        <Col>
                          <h4>Full Name</h4>
                        </Col>
                        <Col>
                          <h4>Email Address</h4>
                        </Col>
                        <Col />
                      </Row>
                    )}
                    <TransitionGroup component="div">
                      {fields.map((field, index) => (
                        <CSSTransition key={field.id} timeout={150} classNames="item">
                          <Row>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="Enter Full Name"
                                {...register(`newRecipients[${index}].fullName`, { required: { value: true, message: 'FullName is required' } })}
                                defaultValue={field.fullName}
                              />
                              <div className="d-block invalid-feedback">{errors.newRecipients?.[index]?.fullName && errors.newRecipients?.[index]?.fullName.message}</div>
                            </Col>
                            <Col>
                              <Form.Control
                                type="email"
                                placeholder="Enter Email Address"
                                {...register(`newRecipients[${index}].email`, {
                                  required: 'Email is required',
                                  pattern: {
                                    value: validEmailPattern,
                                    message: 'Please enter a valid email',
                                  },
                                })}
                                defaultValue={field.email}
                              />
                              <div className="d-block invalid-feedback">{errors.newRecipients?.[index]?.email && errors.newRecipients?.[index]?.email.message}</div>
                            </Col>
                            <Col>
                              <Button variant="secondary" className="u-hover-black" type="button" onClick={() => remove(index)}>
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>

                    <div className="mt-3 border-0 row">
                      <Col className="col-lg-4">
                        <Button onClick={handleAppendRecipient} className="btn btn-primary d-block u-hover-black">
                          Add Rows
                        </Button>
                      </Col>
                    </div>
                  </>
                )}
              </Form.Group>
            </div>
            {/* Submit button */}
            {!isViewMode(formType) && (
              <div className="border-0 row">
                <div className="d-flex justify-content-center">
                  <Button className="btn btn-primary btn-lg my-4 form-btn px-5 text-white" type="submit">
                    Update
                  </Button>
                </div>
              </div>
            )}

            <div style={{ backgroundColor: '#F75676' }} className={`alert alert-primary fade ${assetErrors && assetErrors.message ? 'show' : ''} rounded py-2 text-white`}>
              <span style={{ fontSize: '13px' }} className="mx-3">
                {assetErrors && assetErrors.message}
              </span>
            </div>
            <div
              style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }}
              className={`alert alert-primary fade ${assetStatus === assetStatusMsg.ASSET_SUCCESS ? 'show' : ''} rounded py-2 text-white`}
            >
              <span style={{ fontSize: '13px' }} className="mx-3">
                {assetStatus && assetStatus}
              </span>
            </div>
          </Form>
        </div>
      </Container>
    </AdminDashboard>
  );
}

export { AdminAssetEditView };
