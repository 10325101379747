/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { format } from 'date-fns';
import _, { cloneDeep, isEqual } from 'lodash';

import {
  ActivityLog,
  ActivityLogData,
  DayData,
  MODIFICATION_TYPE,
  NormalizedData,
  URLItem,
  assetFields,
  pathConfigs,
  assetMetaDataValues,
  assetMetaData,
  fromAndToValues,
  ActivityLogs,
  LeanClient,
  fileUrlAndLabelMapping,
  LeanAccount,
  SharePointSubAuditType,
} from 'utils/types/activityLog/asset/types';
import { DATE_FORMAT_MONTH_YEAR } from './types';
import { SharePointStatusEnums } from 'utils/types/assets/assets';

export const ignoredFields = Object.values(assetFields);

/**
 * Determines the type of modification based on the provided `fromData` and `toData`.
 *
 * This function examines the `fromData` and `toData` to identify the nature of the modification.
 * It returns `MODIFICATION_TYPE.ADDED` if `fromData` is an empty array or string and `toData` is non-empty.
 * It returns `MODIFICATION_TYPE.DELETE` if `fromData` is non-empty and `toData` is an empty array or string.
 * In all other cases, it returns `MODIFICATION_TYPE.EDIT`.
 *
 * @param {fromAndToValues} fromData - The initial data before modification.
 * @param {fromAndToValues} toData - The data after modification.
 * @returns {MODIFICATION_TYPE} - The type of modification (ADDED, DELETE, or EDIT).
 */
export const determineModificationType = (fromData: fromAndToValues, toData: fromAndToValues): MODIFICATION_TYPE => {
  if (((Array.isArray(fromData) && fromData.length === 0) || fromData === '' || fromData === undefined) && ((Array.isArray(toData) && toData.length > 0) || (toData !== '' && toData !== undefined))) {
    return MODIFICATION_TYPE.ADDED;
  } else if (
    ((Array.isArray(fromData) && fromData.length > 0) || (fromData !== '' && fromData !== undefined)) &&
    ((Array.isArray(toData) && toData.length === 0) || toData === '' || toData === undefined)
  ) {
    return MODIFICATION_TYPE.DELETE;
  } else {
    return MODIFICATION_TYPE.EDIT;
  }
};

/**
 * Processes asset metadata by grouping and analyzing modifications based on interested types.
 *
 * This function takes asset metadata, groups the 'from' and 'to' items by their type, and then
 * iterates over each interested type. For each type, it maps the values from the grouped data,
 * determines the modification type, and pushes the result to the provided `dataForDate`.
 *
 * @param {assetMetaData} assetMetaData - The asset metadata to process.
 * @param {string[]} interestedTypes - An array of types that are of interest in the processing.
 * @param {NormalizedData} dataForDate - The normalized data object to which the processed data is added.
 */
export const processAssetMetaData = (assetMetaData: assetMetaData, interestedTypes: string[], dataForDate: NormalizedData): void => {
  if (!assetMetaData) return;

  const groupedFrom = _.groupBy(assetMetaData.from, assetMetaDataValues.TYPE);
  const groupedTo = _.groupBy(assetMetaData.to, assetMetaDataValues.TYPE);

  interestedTypes.forEach((type) => {
    const fromValues = _.map(groupedFrom[type], assetMetaDataValues.VALUE);
    const toValues = _.map(groupedTo[type], assetMetaDataValues.VALUE);

    const isChanged = !isEqual(fromValues, toValues);

    if ((fromValues.length > 0 || toValues.length > 0) && isChanged) {
      dataForDate.data.push({
        type,
        actionType: determineModificationType(fromValues, toValues),
        from: fromValues,
        to: toValues,
        translation: getLabelForKey(type),
      });
    }
  });
};

/**
 * Processes items representing URLs and labels from two sets of data (`fromItems` and `toItems`),
 * identifies changes, and records these changes into `dataForDate`. It handles three types of changes:
 * edits, creations, and deletions. Each change is documented with details about the nature of the change,
 * including the type of change (edit, create, delete), the original and new values, and the type of data
 * changed (URL or label).
 *
 * @param {URLItem[]} fromItems - The initial set of items, representing the state before changes.
 * @param {URLItem[]} toItems - The subsequent set of items, representing the state after changes.
 * @param {{ data: ActivityLogData[] }} dataForDate - An object containing an array where change records are stored.
 * Each change record includes the type of data changed (URL or label), a translation key for display purposes,
 * the action type (modification, creation, deletion), and the original and new values.
 *
 * The function iterates through `fromItems` to find corresponding items in `toItems` by matching URLs or labels.
 * If a match is found and there is a difference in URL or label, it records an edit change. Items in `toItems`
 * not matched with any in `fromItems` are considered new and recorded as creations. Items in `fromItems` not
 * matched with any in `toItems` are considered removed and recorded as deletions.
 *
 * Each item is expected to have a unique `_id`, used to prevent processing the same item multiple times.
 * This is particularly useful for efficiently identifying and excluding items that have already been processed
 * as edits from being mistakenly processed again in creation or deletion checks.
 *
 * Note: `fileUrl` and `fileLabel` are assumed to be constants defined externally that specify the type of data being changed.
 * `fileUrlAndLabelMapping` is also assumed to be an external definition providing display names or translations for `fileUrl` and `fileLabel`.
 * `MODIFICATION_TYPE` is an enum or object containing constants `EDIT`, `CREATE`, and `DELETE` to denote the type of modification.
 *
 * @returns {void} This function does not return a value. It modifies `dataForDate.data` directly by pushing change records into it.
 */

const createEventsForChanges = (fromItems: URLItem[], toItems: URLItem[], dataForDate: { data: ActivityLogData[] }): void => {
  const fileLabel = assetFields.FILE_LABEL;
  const fileUrl = assetFields.FILE_URL;

  const processedItems = new Set<string>();

  // Edit case
  fromItems.forEach((fromItem) => {
    const toItem = toItems.find((item) => item.url === fromItem.url || item.label === fromItem.label);
    if (toItem) {
      if (toItem.url !== fromItem.url) {
        dataForDate.data.push({
          type: fileUrl,
          translation: fileUrlAndLabelMapping[fileUrl],
          actionType: MODIFICATION_TYPE.EDIT,
          from: [fromItem.url],
          to: [toItem.url],
        });
      }
      if (toItem.label !== fromItem.label) {
        dataForDate.data.push({
          type: fileLabel,
          translation: fileUrlAndLabelMapping[fileLabel],
          actionType: MODIFICATION_TYPE.EDIT,
          from: [fromItem.label],
          to: [toItem.label],
        });
      }
      processedItems.add(fromItem._id);
      processedItems.add(toItem._id);
    }
  });

  // Create case
  toItems.forEach((toItem) => {
    if (!processedItems.has(toItem._id)) {
      dataForDate.data.push({
        type: fileLabel,
        translation: fileUrlAndLabelMapping[fileLabel],
        actionType: MODIFICATION_TYPE.ADDED,
        from: [],
        to: [toItem.label],
      });
      dataForDate.data.push({
        type: fileUrl,
        translation: fileUrlAndLabelMapping[fileUrl],
        actionType: MODIFICATION_TYPE.ADDED,
        from: [],
        to: [toItem.url],
      });
      processedItems.add(toItem._id);
    }
  });

  // Delete case
  fromItems.forEach((fromItem) => {
    if (!processedItems.has(fromItem._id)) {
      dataForDate.data.push({
        type: fileLabel,
        translation: fileUrlAndLabelMapping[fileLabel],
        actionType: MODIFICATION_TYPE.DELETE,
        from: [fromItem.label],
        to: [],
      });
      dataForDate.data.push({
        type: fileUrl,
        translation: fileUrlAndLabelMapping[fileUrl],
        actionType: MODIFICATION_TYPE.DELETE,
        from: [fromItem.url],
        to: [],
      });
    }
  });
};

//TODO: add type for the subAuditType
export function handleDiffType(key: string, diffValue: any, subAuditType: string | undefined, dataForDate: NormalizedData) {
  if (diffValue) {
    let fromData: any[] = [];
    let toData: any[] = [];
    let type: MODIFICATION_TYPE = determineModificationType(fromData, toData);

    switch (key) {
      case assetFields.IMPORTANCE:
        if (diffValue.to) {
          type = MODIFICATION_TYPE.ADDED;
          fromData.push(diffValue?.from?.to || []);
          toData.push(diffValue?.to.value || []);
        } else if (diffValue.value) {
          type = determineModificationType(diffValue?.value?.from, diffValue?.value?.to);
          fromData.push(diffValue?.value?.from || []);
          toData.push(diffValue?.value.to || []);
        }
        break;
      case assetFields.DESCRIPTION:
      case assetFields.OTHERS1:
      case assetFields.OTHERS2:
      case assetFields.NAME:
        type = determineModificationType(diffValue.from, diffValue.to);

        diffValue.from && fromData.push(diffValue.from);
        diffValue.to && toData.push(diffValue.to);
        break;

      case assetFields.CONTRIBUTOR:
        const leanAccountsFrom = diffValue?.from?.map((item: any) => ({
          displayName: item.displayName,
          isActive: item.isActive,
          user: {
            email: item.user.email,
          },
          createdAt: item.createdAt,
          createdFrom: item.createdFrom,
        }));

        const leanAccountsTo = diffValue?.to?.map((item: any) => ({
          displayName: item.displayName,
          isActive: item.isActive,
          user: {
            email: item.user.email,
          },
          createdAt: item.createdAt,
          createdFrom: item.createdFrom,
        }));

        type = determineModificationType(diffValue.from, diffValue.to);
        fromData.push(leanAccountsFrom || []);
        toData.push(leanAccountsTo || []);
        break;
      case assetFields.RECIPIENTS:
        if (subAuditType === assetFields.ACKNOWLEDGEMENT) {
          fromData.push(diffValue?.fullName || diffValue?.email || '');
          fromData.push(diffValue?.email || '');
        } else {
          if (!Array.isArray(diffValue) && !diffValue?.from) {
            type = MODIFICATION_TYPE.DELETE;
            const temp = diffValue.fullName?.to || diffValue.email?.to;
            fromData.push(temp);
          } else {
            if (subAuditType === SharePointSubAuditType.SHARED) {
              type = MODIFICATION_TYPE.ADDED;
            } else {
              type = determineModificationType(diffValue.from, diffValue.to);
            }
            // Flatten the arrays
            const flattenedFrom = _.flatten(diffValue.from || []);
            const flattenedTo = _.flatten(diffValue.to || []);
            // Find objects that are in 'to' but not in 'from'
            const newRecipients = _.differenceBy(flattenedTo, flattenedFrom, '_id');
            fromData.push([]); // No need for fromData, focusing on new recipients
            toData.push(newRecipients); // Push the new recipients
          }
        }
        break;

      case assetFields.ASSET_META_DATA:
        const assetMetaData = diffValue;
        const interestedTypes = [assetFields.BUSINESS_RELATED, assetFields.COMMERCIAL_PRODUCT];
        processAssetMetaData(assetMetaData, interestedTypes, dataForDate);
        break;

      case assetFields.ASSET_TYPE:
      case assetFields.PROJECTS:
      case assetFields.DEPARTMENTS:
      case assetFields.TAGS:
      case assetFields.BUSINESS_VALUES:
        if (diffValue.from) {
          fromData = diffValue?.from?.map((value: any) => value.name) || [];
        }
        if (diffValue.to) {
          toData = diffValue?.to?.map((value: any) => value.name) || [];
        }
        type = determineModificationType(diffValue.from, diffValue.to);

        break;
      case assetFields.FILES:
        if (!subAuditType) return;
        if (diffValue.from) {
          fromData = diffValue?.from?.map((value: any) => value.name) || [];
        }
        if (diffValue.to) {
          toData = diffValue?.to?.map((value: any) => value.name) || [];
        }
        type = determineModificationType(diffValue.from, diffValue.to);

        break;

      case assetFields.URLS:
        if (Array.isArray(diffValue.from) && Array.isArray(diffValue.to)) {
          createEventsForChanges(diffValue.from, diffValue.to, dataForDate);
        }
        break;

      case assetFields.SHAREPOINT:
        fromData = [];
        toData = [];

        const fromPath = diffValue.path?.from;
        const toPath = diffValue.path?.to;

        const fromSiteName = diffValue.siteName?.from;
        const toSiteName = diffValue.siteName?.to;

        const fromStatus = diffValue.status?.from;
        const toStatus = diffValue.status?.to;

        const isChanged = fromPath !== toPath || fromSiteName !== toSiteName || fromStatus !== toStatus;

        if (isChanged) {
          fromData.push({
            path: fromPath || '',
            siteName: fromSiteName || '',
            status: fromStatus || SharePointStatusEnums.ACTIVE,
          });

          toData.push({
            path: toPath || '',
            siteName: toSiteName || '',
            status: toStatus || SharePointStatusEnums.ACTIVE,
          });
          if (subAuditType === SharePointStatusEnums.DELETED) {
            type = MODIFICATION_TYPE.DELETE;
          } else {
            type = determineModificationType(fromPath, toPath);
          }
        }
        break;

      default:
        break;
    }

    if (key !== assetFields.ASSET_META_DATA && key !== assetFields.URLS) {
      const normalizedEventData: any = {
        type: subAuditType === assetFields.ACKNOWLEDGEMENT ? assetFields.ACKNOWLEDGEMENT : key,
        translation: getLabelForKey(key),
        actionType: type,
        from: fromData,
        to: toData,
      };

      dataForDate.data.push(normalizedEventData);
    }
  }
}

export const getLabelForKey = (key: string) => {
  const config = pathConfigs.find((config) => config.path.includes(key));
  return config ? config.label : undefined;
};

export const formatDateKey = (date: string): string => format(new Date(date), DATE_FORMAT_MONTH_YEAR);

export const processDataForDate = (activityLog: ActivityLog): NormalizedData => {
  const currentDate = format(new Date(activityLog.createdAt), 'dd MMM, HH:mm');
  const actionType = activityLog.auditType;
  const normalizedData: NormalizedData = {
    entityType: activityLog.entityType,
    date: currentDate,
    actionType,
    subAuditType: activityLog.subAuditType || '',
    by: {
      account: {
        displayName: activityLog.accountId?.displayName || '',
        user: { email: activityLog.accountId?.user?.email || '' },
        isActive: activityLog.accountId?.isActive || false,
      },
      client: activityLog.clientId || '',
    },
    data: [],
  };

  return normalizedData;
};

export const processActivityLogDiffs = (activityLog: ActivityLog, dataForDate: NormalizedData) => {
  Object.keys(activityLog.diff).forEach((key) => {
    const diffValue = activityLog.diff[key];
    if (key === assetFields.SHAREPOINT) {
      // TODO: do we neet it or we can remove the commented code?
      // const hasFromValues = diffValue.path?.from || diffValue.siteName?.from || diffValue.status?.from;
      // const hasToValues = diffValue.path?.to || diffValue.siteName?.to || diffValue.status?.to;

      // if (hasFromValues && hasToValues) {
      handleDiffType(key, diffValue, activityLog.subAuditType, dataForDate);
      // }
    } else if (ignoredFields.includes(key as assetFields)) {
      handleDiffType(key, diffValue, activityLog.subAuditType, dataForDate);
    } else if (key === MODIFICATION_TYPE.DISCLAIMER) {
      const normalizedEventData: any = {
        type: key,
        translation: '',
        actionType: key,
        from: '',
        to: diffValue,
      };
      dataForDate.data.push(normalizedEventData);
    }
  });
};

export const addToNormalizedData = (normalizedData: { [key: string]: NormalizedData[] }, dateKey: string, dataForDate: NormalizedData) => {
  if (!normalizedData[dateKey]) {
    normalizedData[dateKey] = [];
  }
  normalizedData[dateKey].push(dataForDate);
};

export const processDayData = (dayData: DayData, normalizedData: { [key: string]: NormalizedData[] }) => {
  const dateKey = formatDateKey(dayData.date);

  dayData.data.forEach((activityLog: ActivityLog) => {
    const dataForDate = processDataForDate(activityLog);
    processActivityLogDiffs(activityLog, dataForDate);

    if (dataForDate.data.length > 0) {
      addToNormalizedData(normalizedData, dateKey, dataForDate);
    }
  });
};

export const normalizeActivityLogData = (data: DayData[]): ActivityLogs => {
  const normalizedData: ActivityLogs = {};
  data.forEach((dayData: DayData) => processDayData(dayData, normalizedData));
  return normalizedData;
};

/**
 * Merges existing activity logs with newly fetched logs, combining entries by month.
 * Clones the data to prevent mutation of original objects.
 * If a month exists in both data sets, their entries are concatenated.
 * If a month exists in only one set, it is added directly to the result.
 *
 * @param {ActivityLogs} existingData - The current set of activity logs.
 * @param {ActivityLogs} fetchedData - The newly fetched set of activity logs to merge with the existing data.
 * @returns {ActivityLogs} - The merged and cloned set of activity logs.
 */
export const mergeActivityLogsByMonth = (existingData: ActivityLogs, fetchedData: ActivityLogs): ActivityLogs => {
  const clonedExistingData = cloneDeep(existingData);
  const clonedFetchedData = cloneDeep(fetchedData);

  const mergedLogs: ActivityLogs = { ...clonedExistingData };

  for (const month in clonedFetchedData) {
    if (clonedFetchedData.hasOwnProperty(month)) {
      if (!mergedLogs[month]) {
        mergedLogs[month] = [];
      }
      mergedLogs[month] = mergedLogs[month].concat(clonedFetchedData[month]);
    }
  }

  return mergedLogs;
};

export const determineValueBasedOnAction = (eventActionType: string, fromValues: any[], toValues: any[]): any[] => {
  const isEditAction = eventActionType === MODIFICATION_TYPE.EDIT;
  const isDeleteAction = eventActionType === MODIFICATION_TYPE.DELETE;
  const isCreateAction = eventActionType === MODIFICATION_TYPE.CREATE;

  return isEditAction || isCreateAction ? toValues : isDeleteAction ? fromValues : toValues;
};

// Helper function to get values
export const getValues = (items: any) => items.flatMap((item: any) => item.map((item1: any) => item1));

// Helper function to get display names
export const extractDisplayNames = (items: any[]) => items.map((item) => (typeof item === 'object' ? item.displayName : item));

export const getDisplayName = (account?: LeanAccount, client?: LeanClient): string => {
  return (account && account.isActive ? account.displayName ?? account.user?.email : client?.name) ?? '';
};
