export const X_RAY_TOOLS = {
  MAPPING: 'Mapping Tool',
  EXTRACTION: 'Extraction Tool',
};

export const Environment = {
  DEVELOPMENT: 'develop',
  STAGE: 'stage',
  PRODUCTION: 'production',
};

export const TRACK_MIXPANEL_EVENT = 'TRACK_MIXPANEL_EVENT';

export const DEMO_ACCOUNT_NAMES = [
  'Matthews Demo',
  'Tangibly Demo',
  'Client Demo',
  'Calgon Carbon Demo',
  'Moveo_Demo',
  'MoveoLawFirm_Client1',
  'prodTestClient',
  'prodTestClient4',
  'test client',
  'prodTestClient9',
  'test client 1',
  'test client 3',
  'TimDemoCompany',
  'TangiblyDemo',
  'ClientTest1',
  'MoveoTest',
  'TangiblyOktaTest',
  'DemoLearnClient',
  'Client 2 Demo C',
  'LiatTest',
  'TestAug23',
  'Appolo',
];
