import { mkConfig, generateCsv, download } from 'export-to-csv';

interface CsvExportParams {
  data: Record<string, unknown>[];
  config: {
    useKeysAsHeaders?: boolean;
    filename: string;
  };
}

export const exportCsv = ({ data, config }: CsvExportParams): void => {
  if (data.length === 0) {
    return;
  }
  const csvConfig = mkConfig(config);
  const csv = generateCsv(csvConfig)(data);
  download(csvConfig)(csv);
};
