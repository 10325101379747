/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { config } from '../config/config';

const baseURL = config.apiUrl;

export const HttpClientService = {
  async post<T>(url: string, data?: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'post',
    });
  },

  async put<T>(url: string, data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'put',
    });
  },
  async get<T>(url: string, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      method: 'get',
    });
  },

  async delete<T>(url: string, options: AxiosRequestConfig = {}, data?: any): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'delete',
    });
  },

  async patch<T>(url: string, data: any, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T, any>> {
    return HttpClientService.send<T>({
      ...options,
      url,
      baseURL,
      data,
      method: 'patch',
    });
  },

  async send<T>(httpOptions: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
    // Make a copy of the options object, to avoid modifying the original
    const options = { ...httpOptions };

    // Make the request using Axios
    return axios(options);
  },
};
