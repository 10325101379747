import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PatentFromApplicationWrapper from './components/AssetCreationFromPatentWrapper';
import PatentMappingTool from './components/PatentMappingTool';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { RootState } from '_helpers/store';
import { CurrentTradeSecret } from '_hocs/XRayAssetCreation/types';
import { MAX_CHARS_IN_NAME_AND_TAG } from '_hocs/XRayAssetCreation/components/utils';

const PatentApplicationToolPage = () => {
  const [currentTradeSecret, setCurrentTradeSecret] = useState<CurrentTradeSecret>({ title: '', text: '' });
  const { patentData } = useSelector((state: RootState) => state.patent);
  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useDispatch();

  const patentNameShort = useMemo(() => patentData?.filename?.slice(0, MAX_CHARS_IN_NAME_AND_TAG).trim() ?? patentData?.patent_id ?? '', [patentData]);

  const handleDropdownItem = (currTradesecret: CurrentTradeSecret, index: number): void => {
    setCurrentTradeSecret({ ...currTradesecret, index });
    dispatch(assetsActions.setClearAsset());
  };

  return (
    <PatentFromApplicationWrapper
      clientId={clientId}
      patentNameShort={patentNameShort}
      currentTradeSecret={currentTradeSecret}
      handleIsAssetFromPatentCreated={() => dispatch(patentActions.setAssetCreatedInPatentData(currentTradeSecret?.index ?? -1))}
      handleDropdownItem={handleDropdownItem}
    >
      <PatentMappingTool clientId={clientId} />
    </PatentFromApplicationWrapper>
  );
};

export default PatentApplicationToolPage;
