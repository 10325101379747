import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Container, StyledNewChip, StyledTangiSvgIcon, UpgradeLabel } from './style';
import { SECONDARY_SHADES } from 'utils/theme';
import { ILobbyCard, LOBBY_CARD_ID } from 'pages/Lobby/types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { getUrlByCardId } from './utils';
import { NEW_CARDS } from './consts';

interface Props extends ILobbyCard {
  onLockCardClick: () => void;
}

const LobbyCard = ({ cardId, icon, isLocked = false, onLockCardClick }: Props) => {
  const { clientId } = useParams<{ clientId: string }>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const url = getUrlByCardId(cardId, clientId);
  const isNewCard = NEW_CARDS.includes(cardId);

  const handleClick = () => {
    if (isLocked) {
      onLockCardClick();
    } else {
      if (cardId === LOBBY_CARD_ID.COLLABORATE_WITH_PARTNERS) {
        dispatch(partnershipActions.toggleDrawer(true));
      }
      history.push(url);
    }
  };

  return (
    <Container onClick={handleClick} data-testid={`lobby-card-${cardId}`}>
      {isNewCard && <StyledNewChip />}
      <StyledTangiSvgIcon component={icon} color="" />
      <TangiTypography type="heading-md" weight="semibold">
        {t(`LOBBY_PAGE.CARDS.${cardId}.TITLE`)}
      </TangiTypography>
      <TangiTypography>{t(`LOBBY_PAGE.CARDS.${cardId}.SUB_TITLE`)}</TangiTypography>
      {isLocked && (
        <UpgradeLabel>
          <TangiSvgIcon component="diamond" color={SECONDARY_SHADES[800]} size="16px" />
          <TangiTypography color={SECONDARY_SHADES[800]} type="sub-body" weight="semibold">
            UPGRADE TO UNLOCK
          </TangiTypography>
        </UpgradeLabel>
      )}
    </Container>
  );
};

export default LobbyCard;
