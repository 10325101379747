import { useEffect, useRef, useState } from 'react';

import InventionTooltip from '../InventionTooltip/InventionTooltip';
import { PotentialTradeSecretBullet } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { useAssociation } from '../../../../_context/InventionDisclosure/AssociationContext';
import { StyledBulletTypography } from 'pages/InventionDisclosurePage/style';

interface Props {
  bullet: PotentialTradeSecretBullet;
}

const InventionTradeSecretBullet = ({ bullet }: Props) => {
  const [highlightedTSBulletId, setHighlightedTSBulletId] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { scrollTSContainerRef, associatedTradeSecretsBullets, scrolledToTSBulletId, setAssociatedClaims, resetAssociatedClaims, registerTSBulletsRefs } = useAssociation();

  const isAssociated = associatedTradeSecretsBullets.includes(bullet.id) || highlightedTSBulletId === bullet.id;

  // Remove highlight to the bullets associated with the claim, and add highlight to the claim is being hovered over
  const handleClaimMouseEnter = () => {
    setHighlightedTSBulletId(bullet.id);
    setAssociatedClaims(bullet?.claim_ids ?? []);
  };

  // Remove highlight of the claims associated with the bullet, potentially highlight of the claim we scrolled to, and highlight of current bullet
  const handleClaimMouseLeave = () => {
    resetAssociatedClaims();
    setHighlightedTSBulletId(null);
  };

  useEffect(() => {
    if (ref.current) {
      registerTSBulletsRefs(bullet.id, ref.current);
    }

    // Return a cleanup function to deregister on unmount
    return () => {
      registerTSBulletsRefs(bullet.id, null);
    };
  }, [bullet.id, registerTSBulletsRefs]);

  return (
    <InventionTooltip
      numberOfAssociations={bullet?.claim_ids?.length ?? 0}
      itemText={bullet.text}
      onEdit={() => {}}
      onDelete={() => {}}
      scrollRef={scrollTSContainerRef}
      onShow={handleClaimMouseEnter}
      onHide={handleClaimMouseLeave}
      isShowActions={false}
    >
      <div ref={ref} id={bullet.id}>
        <StyledBulletTypography isAssociated={isAssociated} isCurrentAssociation={bullet.id === scrolledToTSBulletId} className={`ts-bullet-${bullet.id}`}>
          {bullet.text}
        </StyledBulletTypography>
      </div>
    </InventionTooltip>
  );
};

export default InventionTradeSecretBullet;
