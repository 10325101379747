import React from 'react';

import { StyledChip } from './style';

export type ChipType = 'filled' | 'outline' | 'primary' | 'secondary' | 'tag';
export interface Props {
  text: string | React.ReactNode;
  maxWidth?: number;
  variant?: ChipType;
  className?: string;
}

export const TangiChip = ({ text, ...props }: Props) => {
  return (
    <StyledChip data-testid="tangi-chip" {...props}>
      {text}
    </StyledChip>
  );
};
