import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPredictedPortfolioGraphsResults, getPredictedPortfolioTradeSecretsTable, getPredictedPortfolioTradeSecretsExportTable } from 'redux-toolkit/thunks/predictedPortfolioThunks';
import { predictedPortfolioActions } from 'redux-toolkit/slices/predictedPortfolioSlice';
import { isFilterApplied as checkIfFilterApplied, createFiltersForServer } from './utils';

export const usePredictedPortfolio = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const initialFilter = {
    patents: [],
    years: [],
    subjects: [],
    search: '',
  };
  const [filter, setFilter] = useState(initialFilter);

  const [searchValue, setSearchValue] = useState('');
  const [enteredSearchValue, setEnteredSearchValue] = useState('');

  const pagination = useSelector((state) => state.predictedPortfolio.pagination);
  const totalTableData = useSelector((state) => state.predictedPortfolio.tableData.metadata?.[0]?.total);

  const isFilterApplied = useMemo(() => checkIfFilterApplied(filter, searchValue), [filter, searchValue]);

  const filtersForServer = useMemo(() => {
    return createFiltersForServer(filter, enteredSearchValue);
  }, [enteredSearchValue, filter]);

  const handleClearSearch = () => {
    setSearchValue('');
    setEnteredSearchValue('');
    dispatch(predictedPortfolioActions.setInitialPagination());
  };

  const handleSearch = useCallback((value) => {
    setEnteredSearchValue(value);
  }, []);

  const handleExportTable = () => {
    dispatch(getPredictedPortfolioTradeSecretsExportTable({ limit: totalTableData, page: 1, filters: filtersForServer }));
  };

  useEffect(() => {
    setFilter(initialFilter);
    setSearchValue('');
    setEnteredSearchValue('');
  }, [clientId]);

  useEffect(() => {
    dispatch(getPredictedPortfolioGraphsResults());
    dispatch(predictedPortfolioActions.setInitialPagination());
  }, []);

  useEffect(() => {
    dispatch(getPredictedPortfolioTradeSecretsTable({ ...pagination, filters: filtersForServer }));
  }, [pagination]);

  useEffect(() => {
    dispatch(predictedPortfolioActions.setPagination({ ...pagination, page: 1 }));
  }, [filtersForServer]);

  return {
    searchValue,
    filter,
    initialFilter,
    isFilterApplied,
    setFilter,
    setSearchValue,
    handleClearSearch,
    handleSearch,
    handleExportTable,
  };
};
