import styled, { css } from 'styled-components';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from '../../utils/theme';
import { StyledCard, Divider, StyledScrollDiv } from '../../utils/globalStyles';
import { IAssetOverviewProps } from './AssetOverview';

type StyledOverviewProps = Pick<IAssetOverviewProps, 'isAdmin' | 'isPreview'>;

export const ContentContainer = styled(StyledScrollDiv)<StyledOverviewProps>`
  ${({ isAdmin = false, isPreview = false }) => css`
    display: flex;
    gap: ${SPACING[4]};
    background: ${NEUTRAL_SHADES[50]};
    max-height: ${isAdmin ? 'calc(100dvh - 282px)' : !isAdmin && !isPreview && 'calc(100vh - 238px)'};
    overflow-y: auto;
    padding-top: ${isAdmin && SPACING[4]};
    margin-top: ${!isAdmin && SPACING[4]};

    ::-webkit-scrollbar-track {
      background: none;
      margin-top: ${isAdmin ? SPACING[4] : SPACING[1]};
    }

    ::-webkit-scrollbar-thumb {
      background: ${NEUTRAL_SHADES[600]};
      cursor: default;
    }
  `}
`;

export const Card = styled(StyledCard)`
  border-radius: ${SPACING[2]};
  padding: ${SPACING[4]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  height: fit-content;
  position: sticky;
  position: -webkit-sticky; // Safari
  top: ${SPACING[0]};
`;

export const MetaDataCard = styled(Card)`
  width: 384px;
`;

export const DetailsCard = styled(Card)`
  flex: 1;
`;

export const CardDivider = styled(Divider)`
  margin-bottom: ${SPACING[0]};
`;

export const ChipContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]} ${SPACING[1]};
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 6px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export const AvatarContainer = styled(FlexRow)`
  gap: 8px;
  .tangi-typography {
    text-overflow: ellipsis;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const FileContainer = styled(FlexRow)`
  gap: 10px;
  height: 40px;
  background: ${NEUTRAL_SHADES[50]};
  border-radius: ${SPACING[2]};
  cursor: pointer;
  padding-inline: ${SPACING[2.5]};
`;

export const UrlLink = styled.a`
  cursor: pointer;
  color: ${PRIMARY_SHADES[800]};
  font-size: ${SPACING[3]};
  line-height: ${SPACING[4]};
  color: ${PRIMARY_SHADES[800]};
  font-family: 'DIN2014-Regular';
  &:hover {
    color: ${PRIMARY_SHADES[800]};
    text-decoration: underline;
  }
`;

export const RefNumberContainer = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  align-items: center;
  div:last-of-type {
    cursor: pointer;
  }
`;

// --- Empty State --- //

export const EmptyDetailes = styled.div<{ isAdmin: boolean }>`
  ${({ isAdmin = false }) => css`
    align-items: center;
    justify-content: center;
    height: ${isAdmin ? 'calc(100vh - 440px)' : 'calc(100vh - 376px)'};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: ${SPACING[4]};
  `}
`;

export const PartnershipNameAndStatus = styled.div`
  width: 180px;
  .tangi-typography {
    text-overflow: ellipsis;
    max-width: 136px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const CreatedFromLine = styled.div`
  display: flex;
  width: 100%;
  gap: ${SPACING[2]};
  align-items: center;
  padding-top: ${SPACING[2]};
`;
