import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  gap: ${SPACING[3.5]};
  padding-top: ${SPACING[6.5]};
`;

const SharePointSecretsSkeleton = () => {
  return (
    <Container>
      {Array.from(Array(3)).map((n, index) => (
        <div key={index}>
          <Skeleton height={24} width={140} />
          <Skeleton height={46} />
        </div>
      ))}
    </Container>
  );
};

export default SharePointSecretsSkeleton;
