import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { TANGI_BUTTON_SIZES, TangiDropdownButton, TangiTypography } from '_components/TangiLibrary';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import PatentToolHeader from './PatentToolHeader';
import AssetCreatedChip from '_components/AssetCreatedChip/AssetCreatedChip';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { uploadPatentApplication } from 'redux-toolkit/thunks/patentThunks';
import { RootState } from '_helpers';
import { TradeSecretFromPatent } from 'utils/types/patent/patent';
import { CurrentTradeSecret, DropdownOption } from '_hocs/XRayAssetCreation/types';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { MIME_TYPES } from 'utils/enums';
import { CardTitle, CenteredGridColumn, ContentGridContainer, SubjectCard, BulletContainer, customBulletTypographyStyles, MappingContainer, RightColumnCard } from '../style';
import { MappingCard } from './MappingCard/MappingCard';
import { ActiveBulletData, ActiveBulletLocation, Bullet, GroupingAndBulletIndex, PatentData } from '../types';
import PatentExportFilesToast from '_components/PatentExportFilesToast/PatentExportFilesToast';
import { getInitialCheckboxes } from './PatentExport/utils';
import { Account } from 'utils/types/account/account';

interface IPatentMappingToolProps {
  renderOptionsWithCustomCallbacks?: (currTradesecret: CurrentTradeSecret, index: number) => DropdownOption[];
  clientId: string;
}

export const PatentMappingTool = ({ renderOptionsWithCustomCallbacks, clientId }: IPatentMappingToolProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [activeBulletLocation, setActiveBulletLocation] = useState<ActiveBulletLocation>({ globalIndex: 0 });

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount) as Account;
  const patentData = useSelector((state: RootState) => state.patent.patentData);
  const patentModel = useSelector((state: RootState) => state.patent.patentModel);

  const isUploadPatentModalOpen = useSelector((state: RootState) => state.patent.isModalOpen.uploadPatentFile);
  const metaDataLoading = useSelector((state: RootState) => state.assetMetaData.loading);

  const isAccessToContinuation = useMemo(() => Boolean(activeAccount.specialAdminPermissions?.ContinuationXRay), [activeAccount.specialAdminPermissions]);
  const isMappingToolStarted = useMemo(() => patentData.status_mapper === XRAY_JOB_STATUS.STARTED, [patentData.status_mapper]);
  const isMappingToolFailed = useMemo(() => patentData.status_mapper === XRAY_JOB_STATUS.FAILED, [patentData.status_mapper]);
  const isMappingLoading = isMappingToolStarted && !isMappingToolFailed;
  const isMappingFinished = !isMappingLoading && !isMappingToolFailed;

  const mappingStatus = {
    isMappingToolStarted,
    isMappingToolFailed,
    isMappingLoading,
    isMappingFinished,
  };

  const [activeBulletData, setActiveBulletData] = useState<ActiveBulletData>({
    index: 0,
    originalTexts: [],
    isTextForBulletFound: false,
    indexOfTextToShow: 0,
  });

  useEffect(() => {
    if (patentData?.choice_0?.length > 0 && typeof activeBulletLocation.globalIndex !== 'undefined') {
      const { groupingIndex, bulletIndex } = calculateGroupingAndBulletIndexFromGlobalIndex(activeBulletLocation.globalIndex, patentData);

      const bulletMappings = patentData?.choice_0[groupingIndex]?.mapping?.[bulletIndex] ?? [];
      setActiveBulletData((prevData) => ({
        ...prevData,
        index: activeBulletLocation.globalIndex,
        originalTexts: bulletMappings,
        isTextForBulletFound: bulletMappings.length > 0,
        indexOfTextToShow: 0,
        incrementIndexOfTextToShow: () => {
          setActiveBulletData((prevData) => {
            const newIndexToShow = (prevData.indexOfTextToShow + 1) % bulletMappings.length;
            return { ...prevData, indexOfTextToShow: newIndexToShow };
          });
        },
        decrementIndexOfTextToShow: () => {
          setActiveBulletData((prevData) => {
            const newIndexToShow = (prevData.indexOfTextToShow - 1 + bulletMappings.length) % bulletMappings.length;
            return { ...prevData, indexOfTextToShow: newIndexToShow };
          });
        },
      }));
    }
  }, [patentData, activeBulletLocation.globalIndex]);

  useEffect(() => {
    const initialCheckboxes = getInitialCheckboxes(patentData, isAccessToContinuation, t);
    dispatch(patentActions.setExportFilesCheckboxes(initialCheckboxes));

    return () => {
      dispatch(patentActions.setExportFilesCheckboxes(initialCheckboxes));
      dispatch(patentActions.setShowExportToast(false));
    };
  }, [dispatch, patentData, t]);

  const handleBulletClick = (groupingIndex: number, bulletIndex: number, originalTexts: string[]) => {
    const globalIndex = findGlobalBulletIndex(groupingIndex, bulletIndex);
    if (globalIndex !== -1) {
      setActiveBulletLocation({ globalIndex });
      setActiveBulletData({
        // Ensure this is updated with relevant data for the active bullet
        index: globalIndex, // This assumes your data structure supports this
        originalTexts,
        isTextForBulletFound: originalTexts.length > 0,
        indexOfTextToShow: 0,
      });
    }
  };

  function calculateGroupingAndBulletIndexFromGlobalIndex(globalIndex: number, patentData: PatentData): GroupingAndBulletIndex {
    let cumulativeCount = 0;
    if (patentData && patentData.choice_0)
      for (let groupingIndex = 0; groupingIndex < patentData.choice_0.length; groupingIndex++) {
        const subject = patentData.choice_0[groupingIndex];
        if (globalIndex < cumulativeCount + subject.bullets.length) {
          const bulletIndex = globalIndex - cumulativeCount;
          return { groupingIndex, bulletIndex };
        }
        cumulativeCount += subject.bullets.length;
      }
    // Fallback case
    return { groupingIndex: 0, bulletIndex: 0 };
  }

  const dataLength = useMemo(() => patentData?.choice_0?.length, [patentData]);

  const isActiveBullet = (groupingIndex: number, bulletIndex: number): boolean => {
    const globalIndex = findGlobalBulletIndex(groupingIndex, bulletIndex);
    return activeBulletLocation.globalIndex === globalIndex;
  };

  const handleUploadFile = useCallback(
    (file: File[]) => {
      batch(() => {
        dispatch(uploadPatentApplication({ clientId, files: file, patentModel }));
        dispatch(patentActions.toggleUploadPatentFileModal(false));
      });
      history.push(generateRoute(AppRoutes.PATENT_X_RAY_MAIN_PAGE, { clientId }));
    },
    [clientId, dispatch, patentModel],
  );

  const renderTurnIntoAssetDropDown = (tradeSecret: TradeSecretFromPatent, index: number) => {
    return (
      <TangiDropdownButton
        size={TANGI_BUTTON_SIZES.SMALL}
        text={t('PATENT_TOOL.DROPDOWN_BUTTON.TURN_INTO_ASSET')}
        endIcon={true}
        options={renderOptionsWithCustomCallbacks ? renderOptionsWithCustomCallbacks({ text: tradeSecret.bullets.join('\n'), title: tradeSecret.subject }, index) : []}
        data-testid="turn-into-asset-button"
        loading={metaDataLoading}
      />
    );
  };

  const renderCardTitle = (tradeSecret: TradeSecretFromPatent, index: number) => {
    return (
      <CardTitle>
        <TangiTypography type="heading-md" weight="semibold">
          {tradeSecret.subject}
        </TangiTypography>
        {tradeSecret.isAssetCreated ? <AssetCreatedChip /> : renderTurnIntoAssetDropDown(tradeSecret, index)}
      </CardTitle>
    );
  };

  const renderBullets = (bullets: string[], mapping: (string[] | null)[] | undefined, groupingIndex: number) => {
    return (
      <>
        {bullets.map((bullet, bulletIndex) => {
          const isTextForBulletFound = Boolean(mapping?.[bulletIndex]?.length);
          const originalTexts = mapping?.[bulletIndex] || [];

          return (
            <React.Fragment key={bulletIndex}>
              {!!bullet?.length && (
                <BulletContainer isActive={isActiveBullet(groupingIndex, bulletIndex)} isFound={isTextForBulletFound} onClick={() => handleBulletClick(groupingIndex, bulletIndex, originalTexts)}>
                  <TangiTypography customStyles={customBulletTypographyStyles}>{bullet}</TangiTypography>
                </BulletContainer>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const flattenBulletsForIndexing: Bullet[] = useMemo(() => {
    // TODO: fix any type
    const flatList: Bullet[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patentData.choice_0.forEach((subject: any, groupingIndex: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      subject.bullets.forEach((bullet: any, bulletIndex: any) => {
        flatList.push({ groupingIndex, bulletIndex, bullet });
      });
    });
    return flatList;
  }, [patentData]);

  const findGlobalBulletIndex = (groupingIndex: number, bulletIndex: number): number => {
    return flattenBulletsForIndexing.findIndex((item) => item.groupingIndex === groupingIndex && item.bulletIndex === bulletIndex);
  };

  const renderTradeSecretSubjectCards = () => {
    return (
      <>
        {patentData.choice_0.map((tradeSecret: TradeSecretFromPatent, index: number) => (
          <SubjectCard key={index}>
            {renderCardTitle(tradeSecret, index)}
            {!!tradeSecret?.bullets?.length && renderBullets(tradeSecret.bullets, tradeSecret?.mapping, index)}
          </SubjectCard>
        ))}
      </>
    );
  };

  return (
    <MappingContainer>
      <PatentToolHeader />
      <ContentGridContainer>
        <CenteredGridColumn>{!!dataLength && renderTradeSecretSubjectCards()}</CenteredGridColumn>
        <RightColumnCard>
          <MappingCard activeBulletData={activeBulletData} mappingStatus={mappingStatus} />
        </RightColumnCard>
        <UploadFileModal
          acceptedFilesList={[MIME_TYPES.DOC, MIME_TYPES.DOCX].join(', ')}
          isOpen={isUploadPatentModalOpen}
          title={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_PATENT_APPLICATION_FILE')}
          onClose={() => dispatch(patentActions.toggleUploadPatentFileModal(false))}
          onSubmit={handleUploadFile}
          submitButtonText={t('GENERAL.BUTTONS_TEXT.CONTINUE')}
          customDragNDropText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.DRAG_AND_DROP_WORD_FILE')}
          customDragNDropRejectText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_WORD_FILE')}
        />
      </ContentGridContainer>
      <PatentExportFilesToast />
    </MappingContainer>
  );
};

export default PatentMappingTool;
