import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 35vw;
  min-width: 100%;
  font-family: 'DIN2014-REGULAR';
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 24px;
  padding-inline: 24px;
`;

export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  margin: 0;
  padding: 0;
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const FormFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-block: 16px;
`;

export const ButtonContainer = styled.span`
  width: fit-content;
`;
