import React from 'react';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';
import { AddClaim } from './AddClaim';

const TabsContainer = styled.div`
  display: flex;
  padding: ${SPACING[0]} ${SPACING[4]};
  height: 36px;
  justify-content: flex-end;
`;
export const InventionTabs = () => {
  return (
    <TabsContainer>
      {/* <div>Tabs</div> */}
      <AddClaim />
    </TabsContainer>
  );
};
