import { ERROR_SHADES, NEUTRAL_SHADES } from 'utils/theme';
import { TangiSvgIcon } from '../../../TangiSvgIcon/TangiSvgIcon';
import { TangiTypography } from '../../../TangiTypography/TangiTypography';
import { DropZoneTextContainer } from './styles';
import { DropZoneExplanatoryTexts } from './components/DropZoneExplanatoryTexts';
import { useTranslation } from 'react-i18next';

interface DropZoneProps {
  isDragActive: boolean;
  customDragAndDropText: string;
  customDragAndDropRejectText: string;
  isEditMode: boolean;
  isDragReject: boolean;
}

export const DropZoneContent = ({ isDragActive, isDragReject, isEditMode, customDragAndDropText, customDragAndDropRejectText }: DropZoneProps) => {
  const { t } = useTranslation();
  return (
    <DropZoneTextContainer data-cy='file-drop-zone'>
      <TangiSvgIcon component={isDragReject ? 'errorIcon' : 'upload'} color={isDragReject ? ERROR_SHADES[400] : NEUTRAL_SHADES[800]} />
      <div>
        {!isDragActive && <DropZoneExplanatoryTexts isEdit={isEditMode} customDragAndDropText={customDragAndDropText} />}
        {isDragActive && !isDragReject && (
          <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]}>
            {t('ASSET_PAGE.CREATE_EDIT_ASSET.ADD_FILES.DROP_FILE')}
          </TangiTypography>
        )}
        {isDragActive && isDragReject && (
          <>
            <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]}>
              {t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_NOT_SUPPORTED')}
            </TangiTypography>
            {customDragAndDropRejectText && (
              <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]}>
                {customDragAndDropRejectText}
              </TangiTypography>
            )}
          </>
        )}
      </div>
    </DropZoneTextContainer>
  );
};
