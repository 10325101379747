import { adminClientContants } from '../_constants/adminClientContants';
import { adminClientService } from './../_services/adminClientService';
import { lawfirmService } from './../../_services/lawfirmService';

function saveAdminClientSort(sorting) {
  return {
    type: adminClientContants.ADMIN_CLIENTS_SAVE_SORT,
    sorting,
  };
}

function getAdminClients(page, searchTxt, sorting, limit = 10) {
  function request() {
    return { type: adminClientContants.ADMIN_CLIENTS_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_CLIENTS_SUCCESS, payload };
  }
  function failure() {
    return { type: adminClientContants.ADMIN_CLIENTS_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminClients(page, searchTxt, sorting, limit)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminClientFromId(clientId) {
  function request() {
    return { type: adminClientContants.ADMIN_CLIENT_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_CLIENT_SUCCESS, payload };
  }
  function failure(error) {
    return { type: adminClientContants.ADMIN_CLIENT_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminClientFromId(clientId)
      .then((clients) => {
        if (clients.data.length > 0) {
          dispatch(success(clients.data[0]));
        } else {
          dispatch(failure({ message: 'Invalid Client ID' }));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminClientCount(userId) {
  function request() {
    return { type: adminClientContants.ADMIN_CLIENTS_COUNT_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_CLIENTS_COUNT_SUCCESS, payload };
  }
  function failure() {
    return { type: adminClientContants.ADMIN_CLIENTS_COUNT_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminClientCountService(userId)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminLawFirm(userId, search, sorting) {
  function request() {
    return { type: adminClientContants.ADMIN_LAWFIRM_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_LAWFIRM_SUCCESS, payload };
  }
  function failure() {
    return { type: adminClientContants.ADMIN_LAWFIRM_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminLawFirmService(userId, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminLawFirmDelete(userId) {
  function request() {
    return { type: adminClientContants.ADMIN_LAWFIRM_DELETE_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_LAWFIRM_DELETE_SUCCESS, payload };
  }
  function failure() {
    return { type: adminClientContants.ADMIN_LAWFIRM_DELETE_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminLawFirmDeleteService(userId)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminLawFirmEdit(userId, data) {
  const { profile } = data;
  profile && delete data.profile;

  function request() {
    return { type: adminClientContants.ADMIN_LAWFIRM_EDIT_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_LAWFIRM_EDIT_SUCCESS, payload };
  }
  function failure() {
    return { type: adminClientContants.ADMIN_LAWFIRM_EDIT_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminLawFirmEditService(userId, data)
      .then((firm) => {
        if (profile) {
          lawfirmService
            .uploadLawfirmLogo(profile, firm.id)
            .then(() => {
              dispatch(success(firm));
            })
            .catch((error) => {
              dispatch(failure(error));
            });
        } else {
          dispatch(success(firm));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminLawFirmView(userId, data) {
  function request() {
    return { type: adminClientContants.ADMIN_LAWFIRM_GET_REQUEST };
  }
  function success(payload) {
    return { type: adminClientContants.ADMIN_LAWFIRM_GET_SUCCESS, payload };
  }
  function failure() {
    return { type: adminClientContants.ADMIN_LAWFIRM_GET_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminClientService
      .getAdminLawFirmViewService(userId, data)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function createAdminClient(data) {
  const { logo, lawfirm } = data;

  data.logo = '';
  lawfirm && delete data.lawfirm;

  function create() {
    return { type: adminClientContants.ADMIN_CLIENTS_CREATE };
  }

  function success(payload) {
    return { type: adminClientContants.ADMIN_CLIENTS_CREATE_SUCCESS, payload };
  }
  function failure(errors) {
    return { type: adminClientContants.ADMIN_CLIENTS_CREATE_FAILURE, errors };
  }
  function fileSuccess(payload) {
    return { type: adminClientContants.ADMIN_CLIENTS_LOGO_SUCCESS, payload };
  }
  function fileFailure(errors) {
    return { type: adminClientContants.ADMIN_CLIENTS_LOGO_FAILURE, errors };
  }
  return (dispatch) => {
    dispatch(create());
    adminClientService
      .createAdminClient(data)
      .then((client) => {
        if (logo && logo.length > 0) {
          adminClientService
            .uploadAdminClientLogo(logo, client.id)
            .then((file) => {
              dispatch(fileSuccess(file));
              dispatch(success(client));
            })
            .catch((error) => {
              dispatch(fileFailure(error));
            });
        } else {
          dispatch(success(client));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function editAdminClient(data, clientId, logoChanged) {
  const { logo, lawfirm } = data;

  if (logoChanged) data.logo = ' ';
  lawfirm && delete data.lawfirm;

  function edit() {
    return { type: adminClientContants.ADMIN_CLIENT_EDIT };
  }

  function success(payload) {
    return { type: adminClientContants.ADMIN_CLIENT_EDIT_SUCCESS, payload };
  }
  function failure(errors) {
    return { type: adminClientContants.ADMIN_CLIENT_EDIT_FAILURE, errors };
  }
  function fileSuccess(payload) {
    return { type: adminClientContants.ADMIN_CLIENTS_LOGO_SUCCESS, payload };
  }
  function fileFailure(errors) {
    return { type: adminClientContants.ADMIN_CLIENTS_LOGO_FAILURE, errors };
  }
  return (dispatch) => {
    dispatch(edit());
    adminClientService
      .editAdminClient(data, clientId)
      .then((client) => {
        if (logoChanged && logo && logo.length > 0) {
          adminClientService
            .uploadAdminClientLogo(logo, client.id)
            .then((file) => {
              dispatch(fileSuccess(file));
              dispatch(success(client));
            })
            .catch((error) => {
              dispatch(fileFailure(error));
            });
        } else {
          dispatch(success(client));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminLawFirmClear() {
  return { type: adminClientContants.ADMIN_LAWFIRM_GET_REQUEST };
}

function clearAdminClient() {
  return { type: adminClientContants.ADMIN_CLIENTS_CREATE };
}

export const adminClientActions = {
  getAdminClients,
  getAdminClientFromId,
  getAdminClientCount,
  getAdminLawFirm,
  getAdminLawFirmEdit,
  getAdminLawFirmView,
  getAdminLawFirmDelete,
  getAdminLawFirmClear,
  createAdminClient,
  clearAdminClient,
  editAdminClient,
  saveAdminClientSort,
};
