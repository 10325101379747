export enum ASSET_FILTERS {
  THIRD_PARTY = 'thirdParty',
  NO_DEPARTMENTS = 'No Departments',
  TAG = 'tag',
}

export enum FILTERS_OPTIONS_LABELS {
  PROJECT = 'Project',
  BUSINESS_VALUE = 'Business Value',
  CONTRIBUTOR = 'Contributor',
  ASSET_TYPE = 'Asset type',
  RECIPIENTS = 'Recipients',
  CATEGORIZATION = 'Categorization',
  BUSINESS_RELATED = 'Business Related',
  COMMERCIAL_PRODUCT = 'Commercial Product',
}
export enum FILTERS_ID {
  ASSET_TYPE = 'assetType',
  TAGS = 'tag',
  PROJECTS = 'project',
  BUSINESS_VALUES = 'businessValue',
  CONTRIBUTOR = 'contributor',
  IMPORTANCE = 'importance',
  COMMERCIAL_PRODUCT = 'commercialProduct',
  BUSINESS_RELATED = 'businessRelated',
  RECIPIENTS = 'recipients',
}

export const filtersMapping = {
  PROJECT: 'PROJECTS',
  BUSINESS_VALUE: 'BUSINESS_VALUES',
  CONTRIBUTOR: 'CONTRIBUTOR',
  ASSET_TYPE: 'ASSET_TYPE',
  RECIPIENTS: 'RECIPIENTS',
  CATEGORIZATION: 'IMPORTANCE',
  BUSINESS_RELATED: 'BUSINESS_RELATED',
  COMMERCIAL_PRODUCT: 'COMMERCIAL_PRODUCT',
};

export interface RenderSelectedComponentProps {
  selectedFilter: Option | null;
  filter: FilterData;
  onChange: (selectedItems: SelectedFilter[], id: string) => void;
  setSelectedOptions: React.Dispatch<React.SetStateAction<SelectedOptions>>;
  setShowFilterOptions: React.Dispatch<React.SetStateAction<boolean>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DropdownItemConfig {
  title: string;
  itemList: FilterDataItem[];
}

export type DropdownConfig = Record<FILTERS_ID, DropdownItemConfig>;

export interface Option {
  filterId: string;
  id: FILTERS_ID;
  displayName: string;
  selected: boolean;
}

export interface SelectedFilter {
  id: string;
  name: string;
  displayName?: string;
  formattedName?: string;
}

export interface SelectedOptions {
  [key: string]: string[];
}

export interface FilterDataItem {
  id: string;
  name: string;
  displayName?: string;
  formattedName?: string;
  email?: string;
}
export interface AssetMetaDataItem {
  _id: string;
  value: string;
  displayName?: string;
  formattedName?: string;
}

export interface RecipientsFilters {
  id: string;
  name: string;
}

export type Item = FilterDataItem | AssetMetaDataItem | RecipientsFilters | string;

export type FilterData = {
  [key in FILTERS_ID]: FilterDataItem[] | boolean;
} & {
  department: FilterDataItem[];
  project: FilterDataItem[];
  tag: FilterDataItem[];
  businessValue: FilterDataItem[];
  contributor: FilterDataItem[];
  assetType: FilterDataItem[];
  importance: FilterDataItem[];
  commercialProduct: FilterDataItem[];
  recipients: FilterDataItem[];
  businessRelated: FilterDataItem[];
  thirdParty: boolean;
};
