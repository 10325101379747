import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  align-items: center;
  justify-content: center;
`;