import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';

import { SPACING, SUCCESS_SHADES } from 'utils/theme';

const StyledChip = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  border-radius: ${SPACING[1]};
  height: ${SPACING[5]};
  padding: ${SPACING[2]} ${SPACING[3]};
  align-items: center;
  justify-content: center;
  background: ${SUCCESS_SHADES[50]};
`;

const AssetCreatedChip = () => {
  const { t } = useTranslation();

  return (
    <StyledChip data-testid="asset-created-chip">
      <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
      <TangiTypography color={SUCCESS_SHADES[500]}>{t('PATENT_TOOL.CONTENT.ASSET_CREATED')}</TangiTypography>
    </StyledChip>
  );
};

export default AssetCreatedChip;
