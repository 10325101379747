import { useState, useEffect } from 'react';

function useLocalStorageListener<T>(key: string, defaultValue: T): T {
  const [localStorageValue, setLocalStorageValue] = useState<T>(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : defaultValue;
    } catch (error) {
      console.error(`Error parsing localStorage key "${key}":`, error);
      return defaultValue;
    }
  });

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const item = localStorage.getItem(key);
        setLocalStorageValue(item ? (JSON.parse(item) as T) : defaultValue);
      } catch (error) {
        console.error(`Error parsing localStorage key "${key}":`, error);
        setLocalStorageValue(defaultValue);
      }
    };

    // Listen for localStorage changes
    window.addEventListener('storage', handleStorageChange);

    // Clean up the event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, defaultValue]);

  return localStorageValue;
}

export default useLocalStorageListener;
