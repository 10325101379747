import styled from 'styled-components';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';

export const FileDropContainer = styled.div<{ $height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${(props) => props.$height ?? '116px'};
  min-width: 520px;
  border-radius: 4px;
  border: 1px dashed ${NEUTRAL_SHADES[600]};
  background: ${PRIMARY_SHADES[50]};
`;
