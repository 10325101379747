import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import DataTable from 'react-data-table-component';
import { isFunction, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { deleteRecipient, shareAsset as distributeAsset } from 'redux-toolkit/thunks/assetsThunks';
import { NEUTRAL_SHADES } from '../../utils/theme';
import { TangiIconButton, TangiButton, TangiToast } from '../TangiLibrary';
import TableSkeleton from '../TableSkeleton/TableSkeleton';
import DistributeAssetModalSkeleton from '../DistributeAssetModalSkeleton/DistributeAssetModalSkeleton';
import { DeleteModal } from '../Modals/Modals';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { getSelectorOptions } from '../../utils/getOptions';
import { validateEmailPattern } from '../../utils/formUtils';
import { getNoDataDisplay } from '../../utils/NoDataTable';
import { createTableColumn, formatOptionLabel, transformToApiData, transfromDataToFormFormat } from './utils';
import { ModalContentContainer, StyledModalHeader, TitleContainer, StyledModalBody, FormContentContainer, SelectContainer, StyledForm, TableTitle, customTableStyles } from './style';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { getAllRecipients } from 'redux-toolkit/thunks/assetMetaDataThunks';

const DistributeAssetModal = ({ show, onClose, asset, dataToPopulate = null, loader = false, finishLoader = null, shouldCloseAfterCreation = false, renderNotification = null }) => {
  const { shareAsset, recipientLoading, deleteRecipientData } = useSelector((state) => state.asset);
  const { authors, loading: metadataLoading } = useSelector((state) => state.assetMetaData);
  const { permissions, activeAccount, Role, user } = useSelector((state) => state.authentication);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);

  const [recipients, setRecipients] = useState(asset?.recipients || []);
  const [options, setOptions] = useState([]);
  const [deleteModalData, setDeleteModalData] = useState({ show: false, recipientId: null });
  const [deleteToastProps, setDeleteToastProps] = useState({ show: false, text: '', type: '' });

  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });

  const tableColumns = useMemo(
    () =>
      createTableColumn({
        hasDeletePermission: permissions.Recipientdelete,
        contributors: asset?.contributor,
        handleCancelClicked: (id) => {
          setDeleteModalData({ show: true, recipientId: id });
        },
      }),
    [asset, t],
  );

  useEffect(() => {
    if (show) {
      setOptions(getSelectorOptions(authors, 'id', 'email', 'displayName'));
    }
  }, [show]);

  useEffect(() => {
    if (!isEmpty(asset) && isFunction(finishLoader)) {
      finishLoader();
    }
    if (asset?.recipients) {
      setRecipients(asset.recipients);
    }
  }, [asset, finishLoader]);

  // Form population
  useEffect(() => {
    if (!isEmpty(asset) && !isEmpty(dataToPopulate)) {
      reset(transfromDataToFormFormat({ data: dataToPopulate, recipients, authors }));
    }
  }, [asset, dataToPopulate, recipients, authors]);

  useEffect(() => {
    if (deleteRecipientData && deleteRecipientData[0]) {
      if (deleteRecipientData[0].message === 'Recipient deleted Successfully') {
        setDeleteToastProps({
          show: true,
          text: t('ASSET_PAGE.TOOLTIP.DELETED_SUCCESSFULLY', { NAME: deleteRecipientData[0].deleted?.fullName || deleteRecipientData[0].deleted?.email }),
          type: 'success',
        });
      } else {
        setDeleteToastProps({ show: true, text: deleteRecipientData[0].message || deleteRecipientData[0].error, type: 'error' });
      }
      setDeleteModalData({ show: false, recipientId: null });
      dispatch(assetsActions.setClearRecipient());
      dispatch(getAllRecipients());
    }
  }, [deleteRecipientData]);

  useEffect(() => {
    if (shareAsset && shareAsset.length) {
      const allEmailsSent = shareAsset.every((sharedAsset) => sharedAsset.shared === true);
      if (allEmailsSent) {
        setDeleteToastProps({ show: true, text: t('ASSET_PAGE.TOOLTIP.EMAILS_SENT', { NAME: shareAsset.length }), type: 'success' });
        if (shouldCloseAfterCreation) {
          handleClose();
        }
      } else {
        setDeleteToastProps({ show: true, text: t('ASSET_PAGE.TOOLTIP.SOMETHING_WENT_WRONG'), type: 'error' });
      }
      dispatch(assetsActions.setClearShareSuccess());
      dispatch(getAllRecipients());
    }
  }, [shareAsset]);

  const onSubmit = (data) => {
    const transformedData = transformToApiData(data);
    dispatch(distributeAsset({ data: transformedData, assetId: asset.id }));
    mixpanelEvents.shareAsset(asset.id, clientName, Role, user.email);
    reset({ recipients: [] });
  };

  const handleClose = () => {
    reset({ recipients: [] });
    clearErrors('recipients');
    setOptions([]);
    onClose();
  };

  const handleChange = (values) => {
    clearErrors('recipients');
    const isAlreadyRecipient = !!recipients.filter((recip) => values.some((val) => recip?.account?._id === val.value)).length;
    if (isAlreadyRecipient) {
      setError('recipients', { type: 'custom', message: t('ASSET_PAGE.ERROR_MESSAGE.RECIPIENT_EXISTS') });
    } else {
      setValue('recipients', values);
    }
  };

  const handleCreateOption = (value) => {
    clearErrors('recipients');
    const isValidEmailPattern = validateEmailPattern(value);
    if (isValidEmailPattern) {
      const newOption = { value, label: value };
      setOptions((prev) => [...prev, newOption]);
      const recipientsCurrentValues = getValues().recipients;
      if (recipientsCurrentValues) {
        setValue('recipients', [...recipientsCurrentValues, newOption]);
      } else {
        setValue('recipients', [newOption]);
      }
    } else {
      setError('recipients', { type: 'custom', message: t('ASSET_PAGE.ERROR_MESSAGE.NOT_VALID_EMAIL') });
    }
  };

  return (
    <>
      <Modal transition="Fade" show={show} onHide={handleClose} data-testid="distribute-asset">
        <ModalContentContainer>
          {loader ? (
            <DistributeAssetModalSkeleton />
          ) : (
            <>
              <StyledModalHeader>
                <TitleContainer data-testid="distribute-asset-modal-title">
                  <div>{t('ASSET_PAGE.TITLE.SEND_ASSET_NOTIFICATION', { ASSET_NAME: asset?.name || '' })}</div>
                  <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => handleClose()} />
                </TitleContainer>
                {isFunction(renderNotification) && renderNotification()}
              </StyledModalHeader>
              <StyledModalBody>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                  {/* container */}
                  <FormContentContainer>
                    <SelectContainer>
                      <Controller
                        name="recipients"
                        control={control}
                        rules={{
                          required: { value: true, message: t('ASSET_PAGE.ERROR_MESSAGE.EMAIL_IS_REQUIRED') },
                        }}
                        render={({ field }) => (
                          <CreatableSelect
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({ ...provided, zIndex: 2 }),
                              multiValueRemove: (styles, { data }) => ({
                                ...styles,
                                color: data.color,
                                ':hover': {
                                  backgroundColor: NEUTRAL_SHADES[500],
                                  color: data.color,
                                },
                              }),
                            }}
                            isLoading={metadataLoading}
                            className="ff-din-DemiBold"
                            isMulti
                            {...field}
                            options={options}
                            onCreateOption={handleCreateOption}
                            onChange={handleChange}
                            formatOptionLabel={formatOptionLabel}
                            classNamePrefix="list"
                          />
                        )}
                      />
                    </SelectContainer>
                    <TangiButton type="submit" text={t('GENERAL.BUTTONS_TEXT.NOTIFY')} smallbtn={true} disabled={recipientLoading || metadataLoading} data-testid="share-asset" />
                  </FormContentContainer>
                  <div className="d-block invalid-feedback">{errors.recipients && errors.recipients.message}</div>
                </StyledForm>
                <TableTitle>
                  {t('ASSET_PAGE.TITLE.PEOPLE_WITH_ACCESS')} <span>({recipients?.length || 0})</span>
                </TableTitle>
                <DataTable
                  fixedHeader
                  customStyles={customTableStyles}
                  sortIcon={<i className="" />}
                  fixedHeaderScrollHeight="300px"
                  className=""
                  columns={tableColumns}
                  data={recipients}
                  progressPending={recipientLoading}
                  progressComponent={<TableSkeleton />}
                  noDataComponent={getNoDataDisplay(t('ASSET_PAGE.CONTENT.NO_RECIPIENTS_YET'))}
                />
              </StyledModalBody>
            </>
          )}
        </ModalContentContainer>
        <DeleteModal
          title={t('ASSET_PAGE.MODAL.DELETE_RECIPIENT')}
          body={t('ASSET_PAGE.MODAL.RECIPIENTS_WONT_VIEW_ASSET')}
          show={deleteModalData.show}
          loading={recipientLoading}
          handleDelete={() => {
            dispatch(deleteRecipient({ assetId: asset.id, recipientId: deleteModalData.recipientId }));
          }}
          onClose={() => {
            setDeleteModalData({ show: false, recipientId: null });
          }}
        />
      </Modal>
      <TangiToast
        {...deleteToastProps}
        onSuccess={() => {
          setDeleteToastProps({ show: false, type: '', text: '' });
        }}
      />
    </>
  );
};

export default DistributeAssetModal;
