import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TangiAccountStatus, TangiButton, TangiToast, TangiIconButton, TangiTypography, TangiSvgIcon, TangiDropdownButton, TangiPopup, TANGI_BUTTON_SIZES } from '../../_components/TangiLibrary';
import { Dashboard } from '../Dashboard';
import PartnershipOrClientLogo from '_components/ThirdPartyTag/components/PartnershipOrClientLogo';
import PartnershipTableSkeleton from '../../_components/TableSkeleton/PartnershipTableSkeleton';
import { AvatarEmail } from '../../_components/Avatar/AvatarEmail';
import { Avatar } from '../../_components/Avatar/Avatar';
import { PeopleOptions } from '../../_components/PeopleOptions/PeopleOptions';
import AddEditPartnerModal from '../../_components/AddEditPartnerModal/AddEditPartnerModal';
import ViewPartnership from '../../_components/ViewPartnership/ViewPartnership';
import AssetAcknowledgmentSummary from '../../_components/AssetAcknowledgmentSummary/AssetAcknowledgmentSummary';

import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { deactivatePartnership, getPartnershipById } from 'redux-toolkit/thunks/partnershipThunks';
import { downloadAgreement } from 'redux-toolkit/thunks/agreementThunks';
import { agreementActions } from '../../_actions';
import { agreementNewActions } from 'redux-toolkit/slices/agreementSlice';

import { acknowledgedCount, extractAssetAcknowledgment } from '../../utils/acknowledgementUtils';
import { formatDate } from '../../utils/dateUtils';
import { getColumns, tableTypes, prepareAssetsTablesRows, prepareAgreementsTablesRows, renderDeactivationPopupBody, renderDeactivationPopupTitle } from './utils';
import { scrollToTop } from '../../utils/windowFuncs';
import { PARTNERSHIP_STATUS, RESULT_STATUS } from 'utils/enums';
import { BUTTON_VARIANTS, AVATAR_SIZES } from '../../utils/componentUtils';
import { NEUTRAL_SHADES, SPACING } from '../../utils/theme';
import { emptyAvatar } from '../../assets/icons';
import { FlexContainer } from 'utils/globalStyles';
import {
  Container,
  PartnerHeadline,
  ButtonContainer,
  Section,
  TitleContainer,
  SummaryContainer,
  SummaryTextContainer,
  SummaryAvatarContainer,
  NoPeopleComponent,
  DropdownItemContainer,
  DuoTitleContainer,
  DuoAvatarContainer,
  DisplayNameContainer,
  DuoCenteredContainer,
  customTableStyles,
} from './styles';

export const PartnershipPage = ({ match }) => {
  const { partnership, partnershipDeactivateSuccess, loading: partnershipLoading } = useSelector((state) => state.partnership);
  const { status: userStatus, inviteMultipleLoading, peopleToastProps } = useSelector((state) => state.users);
  const agreementLoading = useSelector((state) => state.agreement.loading);
  const agreementSuccess = useSelector((state) => state.agreement.agreementSuccess);
  const agreementToastProps = useSelector((state) => state.agreementNew.agreementToastProps);
  const downloadAgreementsLoading = useSelector((state) => state.agreementNew.agreementLoaders.download);

  const [people, setPeople] = useState([]);
  const [createdAssets, setCreatedAssets] = useState([]);
  const [sharedAssets, setSharedAssets] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [showEditPartnershipModal, setShowEditPartnershipModal] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [isEditPartnerMode, setIsEditPartnerMode] = useState(false);
  const [currentPartnerData, setCurrentPartnerData] = useState({});
  const [isDeactivatePopupVisible, setIsDeactivatePopupVisible] = useState(false);

  const dispatch = useDispatch();
  const { clientId, partnershipId } = useParams();
  const { t } = useTranslation();

  const isPartnershipDisabled = useMemo(() => partnership?.status === PARTNERSHIP_STATUS.DISABLED, [partnership]);

  const isAgreementsWithFiles = useMemo(() => {
    const isValidFile = (file) => file.fileId && file.name && file.fileType;
    return partnership?.agreements?.some((agreement) => agreement?.files?.some(isValidFile));
  }, [partnership?.agreements]);

  const handleDownloadAgreement = useCallback(
    (downloadAgreementParams) => {
      dispatch(downloadAgreement({ ...downloadAgreementParams, partnershipId }));
    },
    [dispatch, partnershipId],
  );

  useEffect(() => {
    scrollToTop();
    dispatch(getPartnershipById(match.params.partnershipId));
  }, [match.params.partnershipId]);

  useEffect(() => {
    if (partnership.accounts) {
      const sharedAssets = [];
      const createdAssets = [];

      const accounts = partnership.accounts.map((value) => {
        value.assets.sharedWith.map((recipient) => {
          sharedAssets.push({
            assetId: recipient.asset._id,
            asset: recipient.asset.name,
            account: { displayName: value.displayName, userEmail: value.user.email },
            createdAt: recipient.asset.createdAt,
            assetType: recipient.asset.assetType,
          });
        });

        value.assets.contributor.map((asset) => {
          createdAssets.push({
            assetId: asset._id,
            asset: asset.name,
            account: { displayName: value.displayName, userEmail: value.user.email },
            createdAt: asset.createdAt,
            assetType: asset.assetType,
          });
        });

        return {
          id: value.id,
          assetsCount: (
            <span>
              {value?.assets?.sharedWith?.length ? (
                <AssetAcknowledgmentSummary
                  acknowledgedCount={acknowledgedCount(value.assets.sharedWith)}
                  assetsAcknowledgmentInfo={extractAssetAcknowledgment(value.assets.sharedWith)}
                  totalShared={value.assets.sharedWith.length}
                />
              ) : (
                ''
              )}
            </span>
          ),
          name: (
            <DuoAvatarContainer>
              <AvatarEmail size={AVATAR_SIZES.XS} isLinkDisabled name={value?.displayName || value.user.email} email={value.user.email} />
              <Tippy content={value.displayName || ''}>
                <DisplayNameContainer>
                  <TangiTypography className="text-capitalize" weight="semibold">
                    {value?.displayName || value.user.email || '-'}
                  </TangiTypography>
                </DisplayNameContainer>
              </Tippy>
            </DuoAvatarContainer>
          ),
          createdAt: <TangiTypography>{value?.createdAt ? formatDate(value.createdAt) : ''}</TangiTypography>,
          editdelete: renderPeopleActions({ account: value }),
          accountStatus: <TangiAccountStatus status={value.status} />,
        };
      });

      const sharedAssetsTable = prepareAssetsTablesRows({ assets: sharedAssets, clientId });
      const createdAssetsTable = prepareAssetsTablesRows({ assets: createdAssets, clientId });

      setSharedAssets(sharedAssetsTable);
      setCreatedAssets(createdAssetsTable);
      setPeople(accounts);
    }
  }, [partnership, t]);

  useEffect(() => {
    if (partnership?.agreements) {
      const agreementsTable = prepareAgreementsTablesRows(partnership.agreements, handleDownloadAgreement, downloadAgreementsLoading);
      setAgreements(agreementsTable);
    }
  }, [t, partnership?.agreements, downloadAgreementsLoading]);

  useEffect(() => {
    if (userStatus) {
      dispatch(usersActions.setPeopleToastProps({ show: true, type: RESULT_STATUS.SUCCESS, text: userStatus }));
    }
  }, [userStatus]);

  useEffect(() => {
    if (agreementSuccess) {
      dispatch(agreementActions.clearStatus());
      dispatch(getPartnershipById(partnershipId));
    }
  }, [agreementSuccess]);

  useEffect(() => {
    if (partnershipDeactivateSuccess) {
      setIsDeactivatePopupVisible(false);
    }
  }, [partnershipDeactivateSuccess]);

  const handleOpenAddPartnerModal = () => {
    setIsEditPartnerMode(false);
    setShowPartnerModal(true);
  };
  const handleClosePartnerModal = () => {
    setIsEditPartnerMode(false);
    setShowPartnerModal(false);
    setCurrentPartnerData({});
  };
  const handleOpenEditPartnerModal = (account) => {
    setCurrentPartnerData({
      status: account.status,
      displayName: account.displayName,
      email: account.user.email,
      externalUser: account.user.externalUser,
      id: account._id,
    });
    setIsEditPartnerMode(true);
    setShowPartnerModal(true);
  };

  const renderEditTippyContentOptions = () => {
    if (isPartnershipDisabled) {
      return t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.EDIT_DISABLED');
    } else {
      return t('PARTNERSHIP_PAGE.TOOLTIP.EDIT');
    }
  };

  const renderPeopleActions = ({ account }) => {
    return (
      <FlexContainer>
        <Tippy content={renderEditTippyContentOptions()}>
          <span>
            <TangiIconButton icon="edit" onClick={() => handleOpenEditPartnerModal(account)} variant={BUTTON_VARIANTS.TERTIARY_GREY} disabled={isPartnershipDisabled} />
          </span>
        </Tippy>
        <PeopleOptions accountStatus={account.status} accountId={account._id} shouldReloadPage={true} partnership={partnership} dropdownStyles={{ right: SPACING[4] }} />
      </FlexContainer>
    );
  };

  const renderTable = (type, data, noData) => {
    return (
      <DataTable
        noDataComponent={noData ?? <NoPeopleComponent>{t('PARTNERSHIP_PAGE.TABLE.NO_ASSETS')}</NoPeopleComponent>}
        customStyles={customTableStyles}
        persistTableHead={true}
        columns={getColumns(type, isAgreementsWithFiles)}
        data={data}
        progressPending={partnershipLoading || agreementLoading || inviteMultipleLoading}
        progressComponent={<PartnershipTableSkeleton />}
        sortIcon={<i className="" />}
      />
    );
  };

  const renderSummary = () => {
    const renderOnboardedOn = () => {
      return (
        <SummaryTextContainer>
          <TangiTypography>{t('PARTNERSHIP_PAGE.SUMMARY.ONBOARDED_ON')}</TangiTypography>
          <TangiTypography weight="semibold">{partnership?.createdAt && formatDate(partnership.createdAt)}</TangiTypography>
        </SummaryTextContainer>
      );
    };
    const renderCreatedBy = () => {
      return (
        partnership?.createdBy && (
          <SummaryAvatarContainer>
            <TangiTypography>{t('PARTNERSHIP_PAGE.SUMMARY.CREATED_BY')}</TangiTypography>
            {partnership?.createdBy?.isActive ? (
              <Avatar size={AVATAR_SIZES.XS} name={partnership?.createdBy?.displayName || partnership?.createdBy?.user?.email} email={partnership?.createdBy?.user?.email || ''} />
            ) : (
              <Avatar size={AVATAR_SIZES.XS} image={emptyAvatar} />
            )}
            <TangiTypography weight="semibold">
              {partnership?.createdBy?.isActive ? partnership?.createdBy?.displayName || partnership?.createdBy?.user?.email : partnership?.createdBy?.user?.email}
            </TangiTypography>
          </SummaryAvatarContainer>
        )
      );
    };
    const renderNumOfEmployees = () => {
      return (
        <SummaryTextContainer>
          <TangiTypography>{t('PARTNERSHIP_PAGE.SUMMARY.NUMBER_OF_EMPLOYEES')}</TangiTypography>
          <TangiTypography weight="semibold">{partnership?.accounts?.length}</TangiTypography>
        </SummaryTextContainer>
      );
    };
    return (
      <SummaryContainer>
        {renderOnboardedOn()}
        {renderCreatedBy()}
        {renderNumOfEmployees()}
      </SummaryContainer>
    );
  };

  const renderPeopleSectionHeader = () => {
    return (
      <DuoTitleContainer>
        <DuoCenteredContainer>
          <TangiTypography type="heading-md" weight="semibold">
            {t('PARTNERSHIP_PAGE.HEADERS.PEOPLE')}
          </TangiTypography>
          {!!partnership?.accounts?.length && <TangiTypography color={NEUTRAL_SHADES[800]}>{`(${partnership.accounts.length})`}</TangiTypography>}
        </DuoCenteredContainer>
        {!!partnership?.accounts?.length && !partnershipLoading && (
          <ButtonContainer>
            <Tippy content={t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.EDIT_DISABLED')} disabled={!isPartnershipDisabled}>
              <span>
                <TangiButton text={t('GENERAL.BUTTONS_TEXT.ADD_USER_CAPITAL')} svgIcon="add" size={TANGI_BUTTON_SIZES.SMALL} onClick={handleOpenAddPartnerModal} disabled={isPartnershipDisabled} />
              </span>
            </Tippy>
          </ButtonContainer>
        )}
      </DuoTitleContainer>
    );
  };

  const renderAssetsSharedSectionHeader = () => {
    return (
      <DuoCenteredContainer>
        <TangiTypography type="heading-md" weight="semibold">
          {t('PARTNERSHIP_PAGE.HEADERS.ASSETS_SHARED_WITH_PARTNERS')}
        </TangiTypography>
        {!!sharedAssets?.length && <TangiTypography color={NEUTRAL_SHADES[800]}>{`(${sharedAssets.length})`}</TangiTypography>}
      </DuoCenteredContainer>
    );
  };

  const renderAssetsCreatedSectionHeader = () => {
    return (
      <DuoCenteredContainer>
        <TangiTypography type="heading-md" weight="semibold">
          {t('PARTNERSHIP_PAGE.HEADERS.ASSETS_CREATED')}
        </TangiTypography>
        {!!createdAssets?.length && <TangiTypography color={NEUTRAL_SHADES[800]}>{`(${createdAssets.length})`}</TangiTypography>}
      </DuoCenteredContainer>
    );
  };

  const renderDropdownOptions = () => {
    return [
      {
        text: (
          <DropdownItemContainer>
            <TangiSvgIcon color={isPartnershipDisabled ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK} component="deactivate" />
            <TangiTypography color={isPartnershipDisabled ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK}>{t('PARTNERSHIP_PAGE.DROPDOWN_BUTTON.DEACTIVATE')}</TangiTypography>
          </DropdownItemContainer>
        ),
        handleItem: () => setIsDeactivatePopupVisible(true),
        disabled: isPartnershipDisabled,
      },
    ];
  };

  const renderTopButtons = () => {
    return (
      <DuoCenteredContainer>
        {partnership?.status && (
          <TangiDropdownButton
            variant={BUTTON_VARIANTS.TERTIARY}
            size={TANGI_BUTTON_SIZES.SMALL}
            arrow={false}
            svgIcon="threeDotsVertical"
            options={renderDropdownOptions()}
            data-testid="partnershipOptions-dropdown-button"
            smallbtn={true}
            dropdownStyles={{ right: '132px' }}
          />
        )}
        <ButtonContainer>
          <Tippy content={t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.PARTNERSHIP_DISABLED')} disabled={!isPartnershipDisabled}>
            <span>
              <TangiButton
                disabled={isPartnershipDisabled}
                variant={BUTTON_VARIANTS.SECONDARY}
                text={t('GENERAL.BUTTONS_TEXT.EDIT')}
                svgIcon="edit"
                size={TANGI_BUTTON_SIZES.SMALL}
                onClick={() => setShowEditPartnershipModal(true)}
                data-testid="edit-partnership"
              />
            </span>
          </Tippy>
        </ButtonContainer>
      </DuoCenteredContainer>
    );
  };

  const renderPartnerHeadline = () => {
    return (
      <>
        <PartnerHeadline data-testid="partnerHeadline">
          <PartnershipOrClientLogo name={partnership?.name || ''} isPartnership={true} logo={partnership?.logo || ''} size={AVATAR_SIZES.MD} />
          <TangiTypography type="heading-lg" weight="semibold">
            {partnership?.name}
          </TangiTypography>
          {isPartnershipDisabled && <TangiAccountStatus status={partnership.status} />}
        </PartnerHeadline>
        {renderTopButtons()}
      </>
    );
  };

  const renderSummarySkeleton = () => {
    return (
      <>
        <TitleContainer>
          <Skeleton height={30} count={1} />
        </TitleContainer>
        <Section>
          <TangiTypography type="heading-md" weight="semibold">
            {t('PARTNERSHIP_PAGE.HEADERS.SUMMARY')}
          </TangiTypography>
          <Skeleton height={112} count={1} />
        </Section>
      </>
    );
  };

  return (
    <>
      <Dashboard title="Partners">
        <Container>
          {partnershipLoading || agreementLoading || inviteMultipleLoading ? (
            renderSummarySkeleton()
          ) : (
            <>
              <TitleContainer>{renderPartnerHeadline()}</TitleContainer>{' '}
              <Section>
                <TangiTypography type="heading-md" weight="semibold">
                  {t('PARTNERSHIP_PAGE.HEADERS.SUMMARY')}
                </TangiTypography>
                {renderSummary()}
              </Section>
            </>
          )}
          <Section>
            <TangiTypography type="heading-md" weight="semibold">
              {t('PARTNERSHIP_PAGE.HEADERS.DOCUMENTS')}
            </TangiTypography>
            {renderTable(tableTypes.DOCUMENTS, agreements, <NoPeopleComponent> {t('PARTNERSHIP_PAGE.TABLE.NO_DOCUMENTS')}</NoPeopleComponent>)}
          </Section>
          <Section>
            {renderPeopleSectionHeader()}
            <div className="people-table-container overflow-fix">
              {renderTable(
                tableTypes.PEOPLE,
                people,
                <NoPeopleComponent>
                  <p>{t('PARTNERSHIP_PAGE.TABLE.NO_PEOPLE_YET')}</p>
                  <ButtonContainer>
                    <TangiButton text={t('GENERAL.BUTTONS_TEXT.ADD_USER_CAPITAL')} svgIcon="add" size={TANGI_BUTTON_SIZES.SMALL} onClick={handleOpenAddPartnerModal} disabled={isPartnershipDisabled} />
                  </ButtonContainer>
                </NoPeopleComponent>,
              )}
            </div>
          </Section>
          <Section>
            {renderAssetsSharedSectionHeader()}
            {renderTable(tableTypes.ASSETS_SHARED_WITH_PARTNER, sharedAssets)}
          </Section>
          <Section>
            {renderAssetsCreatedSectionHeader()}
            {renderTable(tableTypes.ASSETS_PARTNERS_CONTRIBUTORS, createdAssets)}
          </Section>
        </Container>
        <ViewPartnership isEditMode={true} showEditModal={showEditPartnershipModal} handleCloseEditModal={() => setShowEditPartnershipModal(false)} />
        <AddEditPartnerModal isEditMode={isEditPartnerMode} show={showPartnerModal} partnerData={currentPartnerData} handleClose={handleClosePartnerModal} />
        <TangiPopup
          show={isDeactivatePopupVisible}
          body={renderDeactivationPopupBody()}
          title={renderDeactivationPopupTitle(partnership?.name || '')}
          onCancel={() => setIsDeactivatePopupVisible(false)}
          loading={partnershipLoading}
          onConfirm={() => dispatch(deactivatePartnership(partnership._id))}
          alert
        />
      </Dashboard>
      <TangiToast
        {...peopleToastProps}
        onSuccess={() => {
          batch(() => {
            dispatch(usersActions.setPeopleToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
            dispatch(usersActions.setClearUser());
          });
        }}
      />
      <TangiToast
        {...agreementToastProps}
        onError={() => {
          dispatch(agreementNewActions.setAgreementToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </>
  );
};
