import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  gap: ${SPACING[1]};
  & > :first-child {
    margin-bottom: ${SPACING[3.5]};
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
`;

export const GrantedPermissions = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const grantedPermissions = `
display: inline;
margin-right: ${SPACING[1]};
`;

export const goToMicrosoftLink = `
display: inline;
margin-left: ${SPACING[1]};
`;

export const grantedPermissionsHowToDoIt = `
cursor:pointer;
`;

export const grantModal = { maxHeight: '800px' };
