import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { createTableColum, makeTableRow } from './utils';
import TableSkeleton from '../../_components/TableSkeleton/TableSkeleton';
import { AssetTableContainer, customTableStyles, conditionalRowStyles } from './style';

export const ClientAssetsTable = ({ assets, handleDistributeAsset, loading, assetOptions, handleUnlockAsset, isAdmin, pagination }) => {
  const Role = useSelector((state) => state.authentication.Role);
  const permissions = useSelector((state) => state.authentication.permissions);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleRowClick = (row) => {
    if (row.isLocked) return;
    history.push(`/client/${row.clientId}/asset/${row.id}`);
  };

  const tableData = assets?.data?.map((value) =>
    makeTableRow({
      value,
      handleDistributeAsset,
      handleUnlockAsset,
      role: Role,
      options: assetOptions,
      permissions,
      isAdmin,
      handleRowClick,
    }),
  );

  const clientTableViewColumn = createTableColum(permissions);

  const handleChangePage = (newPage) => {
    if (newPage !== pagination.page) {
      dispatch(assetsActions.setPagination({ ...pagination, page: newPage }));
    }
  };

  return (
    <AssetTableContainer className="overflow-fix">
      <DataTable
        columns={clientTableViewColumn}
        customStyles={customTableStyles}
        sortIcon={<i className="" />}
        data={tableData}
        progressPending={loading}
        progressComponent={<TableSkeleton />}
        highlightOnHover
        onRowClicked={handleRowClick}
        conditionalRowStyles={conditionalRowStyles}
        fixedHeader={true}
        pagination
        paginationServer
        paginationTotalRows={assets?.metadata[0]?.total || 0}
        paginationPerPage={15}
        paginationDefaultPage={assets?.metadata[0]?.page || 1}
        paginationComponentOptions={{ noRowsPerPage: true }}
        onChangePage={handleChangePage}
      />
    </AssetTableContainer>
  );
};
