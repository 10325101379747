import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector, batch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { useLocation, useHistory } from 'react-router-dom';

import { TangiPopup, TangiToast } from '../../_components/TangiLibrary';
import SideMenuV2 from '_components/SideMenuV2/SideMenuV2';
import Header from '_components/Header/Header';
import AcknowledgementModal from '_components/AcknowledgementModal/AcknowledgementModal';
import LawyerHomepage from '../LawyerHomepage/LawyerHomepage';
import { setHasSeenDisclaimer } from 'redux-toolkit/slices/authSlice';
import { sendAlias } from 'redux-toolkit/thunks/usersThunks';
import { acknowledgementActions } from '../../_actions/acknowledgementActions';
import { confirmDisclaimer } from '../../redux-toolkit/thunks/authThunks';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { getLoginEntryText, getFirstEntryText } from './Dashbord.utils';
import { IRoles, lawyers } from '../../utils/roles';
import './Dashboard.scss';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { AppRoutes } from 'routing/consts';
import { authConstants, acknowledgementConstants } from '_constants';
import { PageContentWrapper } from './style';
import NewSharepointUsers from './components/NewSharepointUsers';

function Dashboard({ children, title }) {
  const { assetsNeedToBeAcknowledged, acknowledgementToastProps, updatedAssets: updatedAcknowledgedAssets } = useSelector((state) => state.acknowledgementReducer);
  const { activeAccount, loggedIn, hasSeenDisclaimer, user, Role } = useSelector((state) => state.authentication);
  const activeClient = useSelector((state) => state.lawfirm.activeClient);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [userFirstEntry, setUserFirstEntry] = useState(!activeAccount?.seenDisclaimer);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disclaimerText, setDisclaimerText] = useState('');
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);

  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);

  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);

  const isSideMenuVisible = !(isLawyer && !activeClient);

  useEffect(() => {
    if (isLawyer && !activeClient) {
      history.push(AppRoutes.DASHBOARD);
    }
  }, []);

  useEffect(() => {
    dispatch(clientActions.setClearClientResults());

    // Check if user data is available and set it in Sentry
    if (user) {
      Sentry.setUser({ email: user.email });
    }
  }, [dispatch]);

  useEffect(() => {
    const seenDisclaimerThisSession = localStorage.getItem(`seenLoginDisclaimer[${activeAccount._id}]`) === 'true';
    const seenFirstTimeDisclaimer = activeAccount?.seenDisclaimer;
    const isNotLoggedIn = loggedIn === 'false';

    // The disclaimer should not be shown if the user is a lawyer who has seen the first-time disclaimer or has no active client chosen.
    // It should also not be shown for users who are not logged in or have seen the disclaimer this session for their active account.
    const shouldReturn = (isLawyer && (!activeClient || seenFirstTimeDisclaimer)) || isNotLoggedIn || seenDisclaimerThisSession;
    if (shouldReturn) return;

    const clientName = isLawyer ? activeClient?.name : activeAccount?.client?.name;

    // Identify if user has seen the first-time disclaimer and get correct disclaimer text
    if (activeAccount) {
      const firstEntryText = getFirstEntryText(clientName);
      setUserFirstEntry(!seenFirstTimeDisclaimer);

      const disclaimerText = (
        <div className="disclaimer-container">
          {!seenFirstTimeDisclaimer && (
            <>
              <div>{firstEntryText}</div>
              <br />
            </>
          )}
          <div>{getLoginEntryText(clientName)}</div>
        </div>
      );

      setDisclaimerText(disclaimerText);
      setShowDisclaimer(true);
    }
  }, [activeAccount?.seenDisclaimer, isLawyer]);

  const handleCloseDisclaimer = () => {
    if (userFirstEntry) {
      const { _id: accountId } = activeAccount;

      batch(() => {
        dispatch(confirmDisclaimer());
        if (!isLawyer) {
          dispatch(sendAlias(accountId));
        }
      });

      mixpanelEvents.firstTimeLogin(accountId, clientName, Role, user.email);

      const firstTimeLogin = loadStateFromLocalStorage(authConstants.FIRST_TIME_LOGIN);
      const updatedLoginState = {
        clientId: accountId,
        isFirstTime: firstTimeLogin?.clientId === accountId || true,
      };

      saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, updatedLoginState);
    }

    setShowDisclaimer(false);
    const localStorageKey = `${authConstants.SEEN_LOGIN_DISCLAIMER}[${activeAccount._id}]`;
    localStorage.setItem(localStorageKey, true);
    // Manually trigger the 'storage' event to notify components of localStorage changes within the same tab
    window.dispatchEvent(new Event('storage'));
    dispatch(setHasSeenDisclaimer(true));
  };

  const handleCloseAcknowledgementModal = () => {
    const seenAcknowledgementRequests = localStorage.getItem(`${acknowledgementConstants.SEEN_ACKNOWLEDGEMENT_REQUESTS}[${activeAccount._id}]`) === 'true';
    if (!seenAcknowledgementRequests) {
      localStorage.setItem(`${acknowledgementConstants.SEEN_ACKNOWLEDGEMENT_REQUESTS}[${activeAccount._id}]`, true);
    }
    setSignatureModalOpen(false);
  };

  useEffect(() => {
    if (assetsNeedToBeAcknowledged && assetsNeedToBeAcknowledged.length && activeAccount?.role?.name !== IRoles.LAWYER) {
      const seenAcknowledgementRequests = localStorage.getItem(`${acknowledgementConstants.SEEN_ACKNOWLEDGEMENT_REQUESTS}[${activeAccount._id}]`) === 'true';
      if (hasSeenDisclaimer && !seenAcknowledgementRequests) {
        setSignatureModalOpen(true);
      }
    }
  }, [assetsNeedToBeAcknowledged, hasSeenDisclaimer, activeAccount]);

  useEffect(() => {
    if (signatureModalOpen) {
      setSignatureModalOpen(false);
    }
  }, [updatedAcknowledgedAssets]);

  return (
    <Row className="m-0 p-0">
      {isSideMenuVisible && <SideMenuV2 />}
      <PageContentWrapper $isSideMenuVisible={isSideMenuVisible} className="col-lg-0 col-sm-9 p-0">
        <TangiPopup show={showDisclaimer} title="Attention!" body={disclaimerText} checkBox={true} onConfirm={handleCloseDisclaimer} blur={true} />
        <Header title={location.pathname === '/dashboard' ? 'Dashboard' : title} />
        <NewSharepointUsers />
        <div className="content-height">{children || <LawyerHomepage />}</div>
      </PageContentWrapper>
      <AcknowledgementModal isOpen={signatureModalOpen} onClose={handleCloseAcknowledgementModal} isSingleRequest={false} items={assetsNeedToBeAcknowledged} />
      <TangiToast
        {...acknowledgementToastProps}
        onSuccess={() => {
          dispatch(acknowledgementActions.clearAcknowledgedAssets());
        }}
      />
    </Row>
  );
}

export { Dashboard };
