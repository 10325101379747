import { TangiSvgIcon } from '_components/TangiLibrary';
import styled from 'styled-components';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const CircleBackground = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: ${PRIMARY_SHADES[200]};
  width: 160px;
  height: 160px;
`;

export const StyledTangiSvgIcon = styled(TangiSvgIcon)`
  position: absolute;
  top: 63%;
  left: 62%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${SPACING[5]};
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
