import { adminAgreementContants } from '../_constants/adminAgreementContants';
import { adminAgreementService } from './../_services/adminAgreementService';

function getAdminAgreement(page, searchTxt, sorting) {
  function request() {
    return { type: adminAgreementContants.ADMIN_AGREEMENT_REQUEST };
  }
  function success(payload) {
    return { type: adminAgreementContants.ADMIN_AGREEMENT_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAgreementContants.ADMIN_AGREEMENT_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAgreementService
      .getAdminAgreement(page, searchTxt, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAgreementType(page, firmid, search, sorting) {
  function request() {
    return { type: adminAgreementContants.ADMIN_AGREEMENT_TYPE_REQUEST };
  }
  function success(payload) {
    return { type: adminAgreementContants.ADMIN_AGREEMENT_TYPE_SUCCESS, payload };
  }
  function failure() {
    return { type: adminAgreementContants.ADMIN_AGREEMENT_TYPE_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminAgreementService
      .getAdminAgreementType(page, firmid, search, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function clearCreateStatus() {
  return { type: adminAgreementContants.CLEAR_CREATE_STATUS };
}

function createAgreement() {
  return { type: adminAgreementContants.CREATING_AGREEMENT };
}

function agreementCreated(payload) {
  return { type: adminAgreementContants.AGREEMENT_CREATED, payload };
}

function agreementFailure(errors) {
  return { type: adminAgreementContants.AGREEMENT_FAILURE, errors };
}

function agreementSucess(payload) {
  return { type: adminAgreementContants.AGREEMENT_SUCCESS, payload };
}

function agreementFileSuccess(payload) {
  return { type: adminAgreementContants.AGREEMENT_FILEUPLOAD_SUCCESS, payload };
}

function agreementFileFailure(errors) {
  return { type: adminAgreementContants.AGREEMENT_FILEUPLOAD_FAILURE, errors };
}

function clearUpdateStatus() {
  return { type: adminAgreementContants.CLEAR_UPDATE_STATUS };
}

function updateAgreement() {
  return { type: adminAgreementContants.UPDATING_AGREEMENT };
}

function agreementUpdated(payload) {
  return { type: adminAgreementContants.AGREEMENT_UPDATED, payload };
}

function agreementUpdateFailure(errors) {
  return { type: adminAgreementContants.AGREEMENT_UPDATED_FAILED, errors };
}

function agreementUpdateSucess(payload) {
  return { type: adminAgreementContants.AGREEMENT_UPDATED_SUCCESS, payload };
}

function createAgreementWithFiles(data) {
  const { agreement: agreements, account } = data;
  return (dispatch) => {
    const agreementPromises = [];

    for (const agreement of agreements) {
      agreement.account = account;
      agreementPromises.push(adminAgreementService.createAgreement(agreement));
    }

    dispatch(createAgreement());
    Promise.all(agreementPromises)
      .then((responseAgreement) => {
        dispatch(agreementCreated(responseAgreement));
        const filesPromise = [];
        for (const agreement of agreements) {
          if (agreement.file && agreement.file[0]) {
            const type = agreement.agreementType;
            const id = responseAgreement.find((result) => result.agreementType == type)?.id;
            filesPromise.push(adminAgreementService.uploadAgreementFiles(agreement.file, id));
          }
        }

        if (filesPromise.length > 0) {
          Promise.all(filesPromise)
            .then((fileResults) => {
              dispatch(agreementFileSuccess(fileResults));
              dispatch(agreementSucess(responseAgreement));
            })
            .catch((error) => {
              dispatch(agreementFileFailure(error));
            });
        } else {
          dispatch(agreementSucess(responseAgreement));
        }
      })
      .catch((error) => {
        dispatch(agreementFailure(error));
      });
  };
}

function updateAgreementWithFiles(data) {
  const { agreement: agreements } = data;
  return (dispatch) => {
    const agreementPromises = [];
    const filesPromises = [];

    for (const agreement of agreements) {
      if (agreement.files?.length > 0 && !agreement.files[0]?.fileId) {
        filesPromises.push(adminAgreementService.uploadAgreementFiles(agreement.files, agreement.id));
        agreement.files = [];
      }

      agreementPromises.push(adminAgreementService.updateAgreement(agreement));
    }

    dispatch(updateAgreement());
    Promise.allSettled([...agreementPromises])
      .then((responseAgreement) => {
        dispatch(agreementUpdated(responseAgreement));

        if (filesPromises.length > 0) {
          setTimeout(() => {
            Promise.allSettled(filesPromises)
              .then((fileResults) => {
                dispatch(agreementFileSuccess(fileResults));
                dispatch(agreementUpdateSucess(responseAgreement));
              })
              .catch((error) => {
                dispatch(agreementFileFailure(error));
              });
          }, 2000);
        } else {
          dispatch(agreementUpdateSucess(responseAgreement));
        }
      })
      .catch((error) => {
        dispatch(agreementUpdateFailure(error));
      });
  };
}

export const adminAgreementActions = {
  getAdminAgreement,
  getAdminAgreementType,
  createAgreementWithFiles,
  updateAgreementWithFiles,
  clearCreateStatus,
  clearUpdateStatus,
};
