import { useMemo } from 'react';
import { NavDropdown } from 'react-bootstrap';
import styled from 'styled-components';

import { TangiSvgIcon } from '_components/TangiLibrary';
import { lawyers } from 'utils/roles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { AliasIdLink } from './aliasIdLink/AliasIdLink';
import { Account } from 'utils/types/account/account';
import { CLIENT_TYPE } from 'utils/enums';
import { Client } from 'utils/types/client/client';
import { LawFirm } from 'utils/types/lawFirm/lawFirm';
import { Role } from 'utils/types/role/role';

export const AccountItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
  .tangi-icon-container.check-icon {
    margin-left: auto;
  }
`;

export const AccountInfo = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[0]};
  span {
    color: ${NEUTRAL_SHADES.BLACK};
  }
`;

interface Props {
  account: Account;
  clientType: CLIENT_TYPE;
  aliasIdEmail: string;
  handleSwitchAccount: (account: Account) => void;
  activeAccountId: string;
}

const AccountDropdownItem = ({ account, clientType, aliasIdEmail, handleSwitchAccount, activeAccountId }: Props) => {
  const { _id: accountId, role, client, aliasId } = account;
  const { name: roleName } = role as Role;
  const { logo = '', name: clientOrLawfirmName } = account[clientType] as Client | LawFirm;

  const isLawyerRole = lawyers.includes(roleName);
  const aliasIdAndEmailExist = aliasIdEmail && aliasId;
  const aliasIdEnabled = (client as Client)?.permissions?.aliasId?.hasPermission;

  const isShowAliasId = useMemo(() => !isLawyerRole && aliasIdAndEmailExist && aliasIdEnabled, [isLawyerRole, aliasIdAndEmailExist, aliasIdEnabled]);
  return (
    <NavDropdown.Item className={`ff-din-regular fw-bolder text-muted header-dropdown ${activeAccountId === accountId && 'current-active-account'}`} onClick={() => handleSwitchAccount(account)}>
      <AccountItem>
        {logo && logo !== ' ' ? <img src={logo} alt="logo" width={24} height={24} /> : <TangiSvgIcon component="tangiLogo" />}

        <AccountInfo>
          <span>{clientOrLawfirmName ?? ''}</span>
          {roleName}
          {isShowAliasId && <AliasIdLink aliasId={aliasId} aliasIdEmail={aliasIdEmail} />}
        </AccountInfo>
        {activeAccountId === accountId && <TangiSvgIcon component="check" className="check-icon" />}
      </AccountItem>
    </NavDropdown.Item>
  );
};

export default AccountDropdownItem;
