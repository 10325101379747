import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CompliantItem from './CompliantItem/CompliantItem';
import { Container, ItemContainer, AgreementName, StatusIcon } from './style';
import warningIcon from '../../assets/img/exclamation-triangle-solid.png';
import { isUserMissingAcknowledgement } from '../../utils/acknowledgementUtils';

const CompliantTooltip = ({ agreements, tradeSecrets, userRole }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [agreementsData, setAgreementsData] = useState([]);
  const { settings } = useSelector((state) => state.clientSettings);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);

    // Add agreements to user from superadmin
    switch (agreements.length) {
      case 2:
        setAgreementsData(
          agreements.map((agreement) => {
            return { type: agreement.agreementType?.name, signed: agreement.signed };
          }),
        );
        setIsLoading(false);
        break;
      case 0:
        setAgreementsData(
          settings.agreements &&
            settings.agreements[userRole] &&
            settings.agreements[userRole]?.map((agreement) => {
              return { type: agreement?.name, signed: false };
            }),
        );
        setIsLoading(false);
        break;
      default: {
        const temp = settings.agreements && settings.agreements[userRole].find((agreement) => agreement.id !== agreements[0].agreementType?._id).name;
        setAgreementsData(
          settings.agreements && [
            { type: agreements[0].agreementType?.name, signed: agreements[0]?.signed },
            { type: temp, signed: false },
          ],
        );
        setIsLoading(false);
        break;
      }
    }
  }, [settings]);

  return (
    !isLoading &&
    agreementsData?.length === 2 && (
      <Container>
        {agreementsData.map((agreement, index) => (
          <CompliantItem key={`${agreement}-${index}`} title={agreement.type} signed={agreement.signed} />
        ))}
        <ItemContainer>
          <AgreementName>{t('PEOPLE.TOOLTIP.TRADE_SECRET_ACCESS')}</AgreementName>
          <StatusIcon>
            {tradeSecrets && tradeSecrets.length}
            {isUserMissingAcknowledgement(tradeSecrets) && <img src={warningIcon} className="m-auto" width="16" height="16" alt="warning" />}
          </StatusIcon>
        </ItemContainer>
      </Container>
    )
  );
};

export default CompliantTooltip;
