import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TANGI_BUTTON_SIZES, TangiButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import NewChip from '_components/NewChip/NewChip';
import { RootState } from '_helpers';
import { FlexContainer } from 'utils/globalStyles';
import { BUTTON_VARIANTS, NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { BannerContainer, HeaderAndChipContainer, InfoContainer, TopContainer } from './style';

const getTranslationKeys = (isConnected: boolean) => {
  return {
    descriptionTranslationKey: isConnected ? 'SETTINGS_PAGE.SHAREPOINT.CONNECTED.DESCRIPTION' : 'SETTINGS_PAGE.SHAREPOINT.NOT_CONNECTED.DESCRIPTION',
    buttonTranslationKey: isConnected ? 'GENERAL.BUTTONS_TEXT.MANAGE' : 'GENERAL.BUTTONS_TEXT.CONNECT',
  };
};

interface Props {
  onStartIntegration: () => void;
  isSharePointConnected: boolean;
}

const SharePointBanner = ({ onStartIntegration, isSharePointConnected }: Props) => {
  const loading = useSelector((state: RootState) => state.sharePoint.isSPIntegrationInProgress);
  const { t } = useTranslation();

  const { descriptionTranslationKey, buttonTranslationKey } = getTranslationKeys(isSharePointConnected);

  const buttonVariant = isSharePointConnected ? BUTTON_VARIANTS.SECONDARY : BUTTON_VARIANTS.PRIMARY;

  return (
    <BannerContainer data-testid="sp-settings-banner">
      <TopContainer>
        <HeaderAndChipContainer $isConnected={isSharePointConnected}>
          {!isSharePointConnected && <NewChip />}
          <TangiTypography weight="semibold">{t('SETTINGS_PAGE.SHAREPOINT.HEADER')}</TangiTypography>
        </HeaderAndChipContainer>
        <TangiButton
          onClick={onStartIntegration}
          variant={buttonVariant}
          size={TANGI_BUTTON_SIZES.SMALL}
          text={t(buttonTranslationKey)}
          smallbtn={true}
          loading={loading}
          data-testid="open-integration-modal"
        />
      </TopContainer>
      <InfoContainer>
        {isSharePointConnected && (
          <FlexContainer>
            <TangiSvgIcon component="completed" color={SUCCESS_SHADES[600]} size="18px" />
            <TangiTypography color={SUCCESS_SHADES[600]}>{t('SETTINGS_PAGE.SHAREPOINT.CONNECTED.SHAREPOINT_CONNECTED')}</TangiTypography>
          </FlexContainer>
        )}
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t(descriptionTranslationKey)}</TangiTypography>
      </InfoContainer>
    </BannerContainer>
  );
};

export default SharePointBanner;
