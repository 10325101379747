import { TangiTypography } from '_components/TangiLibrary';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { useTranslation } from 'react-i18next';

const GrantModalHeader = () => {
  const { t } = useTranslation();

  return (
    <TangiTypography type={TYPOGRAPHY_TYPES.HEADING_LG} weight="semibold">
      {t('SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.HEADER')}
    </TangiTypography>
  );
};

export default GrantModalHeader;
