/**
 * Converts form data into the format required by the API.
 * It extracts specific fields such as `name`, `email`, and `role` from each item in the form data,
 * formats them according to the API's expectations, and merges additional fields if provided.
 *
 * @param {Object} formData - The form data containing an array of data objects.
 * @param {Object} additionalFields - Additional fields to be added to each formatted data object.
 * @returns {Object[]} - An array of formatted data objects ready for API submission.
 */

export const formToApiData = (formData, additionalFields) => {
  const { data } = formData;
  const formattedData = data.map(({ name, email, role, ...rest }) => {
    return { email, displayName: name, role: role.value, ...rest, ...additionalFields };
  });
  return formattedData;
};

export const isValuesUnique = (values) => {
  const uniqueValues = new Set(values);
  return values.length === uniqueValues.size;
};

export const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
