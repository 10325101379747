import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiFormToggle, TangiInputV2, TangiSelectorV2, TangiTypography } from '_components/TangiLibrary';
import SectionHeader from './SectionHeader';
import { FormSchema, UserSchema } from '_components/SharePointWizard/types';
import { createableRoles } from 'utils/roles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { emailCustomTypographyStyles2, NewMembersTableContainer, NewUserRow } from '../styles';

export interface UserField extends UserSchema {
  id: string;
}

interface Props {
  newMembersFields: UserField[];
}

const NewMembers = ({ newMembersFields }: Props) => {
  const { t } = useTranslation();
  return (
    <NewMembersTableContainer>
      <SectionHeader type="new" count={newMembersFields.length} />
      {newMembersFields.map((field, index, array) => {
        const lastElements = index === array.length - 1 || index === array.length - 2;
        return (
          <NewUserRow key={field.id}>
            <Tippy content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{field.email}</TangiTypography>} placement="top-start">
              <span>
                <TangiTypography customStyles={emailCustomTypographyStyles2}>{field.email}</TangiTypography>
              </span>
            </Tippy>
            <TangiInputV2<FormSchema> required name={`users.${index}.displayName`} placeholder={t('SHARE_POINT_WIZARD.ADD_USERS.DISPLAY_NAME')} />
            <TangiSelectorV2<FormSchema> name={`users.${index}.role`} options={createableRoles} menuScroll menuPlacement={lastElements ? 'top' : 'bottom'} />
            <div style={{ marginBottom: SPACING[3.5] }}>
              <TangiFormToggle<FormSchema> name={`users.${index}.statusActive`} />
            </div>
          </NewUserRow>
        );
      })}
    </NewMembersTableContainer>
  );
};

export default NewMembers;
