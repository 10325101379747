import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { INTERVAL_IN_MILLISECONDS } from '../../../utils/enums';
import { Container } from './style';
import checkIcon from '../../../assets/icons/check-white-bg.svg';
import errorIcon from '../../../assets/icons/error-white-bg.svg';
import warningIcon from '../../../assets/icons/warning-white-bg.svg';
import './TangiToast.scss';

export const TangiToast = ({ type = 'success', text, onSuccess, onError, show, ...props }) => {
  const [localShowAlert, setLocalShowAlert] = useState(false);
  useEffect(() => {
    let closeAlertTimer;
    if (show) {
      setLocalShowAlert(true);
      closeAlertTimer = setTimeout(() => {
        if (type === 'success' && onSuccess) {
          onSuccess();
        }
        if (type === 'error' && onError) {
          onError();
        }
        setLocalShowAlert(false);
      }, INTERVAL_IN_MILLISECONDS.TwoAndHalfSeconds);
    }
    return () => {
      clearTimeout(closeAlertTimer);
    };
  }, [show, text, type]);

  return (
    <Modal centered show={localShowAlert} dialogClassName="tangi-toast-modal" data-testid="tangiToast" style={{ zIndex: 1101 }}>
      <Container className="tangi-toast" type={type} {...props}>
        <img alt="" src={getIconByStatus(type)} />
        {text}
      </Container>
    </Modal>
  );
};

const getIconByStatus = (type) => {
  switch (type) {
    case 'error':
      return errorIcon;
    case 'warning':
      return warningIcon;
    default: // success
      return checkIcon;
  }
};
