import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { BORDER_RADIUS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const TopContainer = styled(FlexContainer)`
  margin-bottom: ${SPACING[2]};
  flex-direction: column;
  justify-content: space-between;
`;

export const AddSiteBtnContainer = styled.div`
  align-self: flex-end;
  .tangi-icon-container svg.tangi-icon-svg {
    width: 10.5px;
    height: 10.5px;
  }
`;

export const SitesContainer = styled(FlexContainer)`
  gap: ${SPACING[4]};
  flex-direction: column;
  width: 100%;
`;

export const SiteSection = styled(FlexContainer)`
  flex-direction: column;
  padding: ${SPACING[3]};
  width: 100%;
  background: ${NEUTRAL_SHADES[100]};
  border-radius: ${BORDER_RADIUS[4]};
  position: relative;
  .tangi-input-v2.form-control {
    height: ${SPACING[6]};
  }
  gap: ${SPACING[1]};
`;

export const RemoveSiteBtnContainer = styled.div`
  position: absolute;
  top: ${SPACING[1]};
  right: ${SPACING[1]};
`;
