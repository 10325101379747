export const agreementConstants = {
  CREATING_AGREEMENT: 'CREATING_AGREEMENT',
  AGREEMENT_CREATED: 'AGREEMENT_CREATED',
  AGREEMENT_SUCCESS: 'AGREEMENT_SUCCESS',
  AGREEMENT_FAILURE: 'AGREEMENT_FAILURE',
  AGREEMENT_FILEUPLOAD_SUCCESS: 'AGREEMENT_FILEUPLOAD_SUCCESS',
  AGREEMENT_FILEUPLOAD_FAILURE: 'AGREEMENT_FILEUPLOAD_FAILURE',
  GET_AGREEMENT: ' GET_AGREEMENT',
  GET_AGREEMENTS: ' GET_AGREEMENTS',

  CREATE_AGREEMENT_TYPE: 'CREATE_AGREEMENT_TYPE',
  CREATE_AGREEMENT_TYPE_SUCCESS: 'CREATE_AGREEMENT_TYPE_SUCCESS',
  CREATE_AGREEMENT_TYPE_FAILED: 'CREATE_AGREEMENT_TYPE_FAILED',

  EDIT_AGREEMENT_TYPE: 'EDIT_AGREEMENT_TYPE',
  EDIT_AGREEMENT_TYPE_SUCCESS: 'EDIT_AGREEMENT_TYPE_SUCCESS',
  EDIT_AGREEMENT_TYPE_FAILED: 'EDIT_AGREEMENT_TYPE_FAILED',

  DELETE_AGREEMENT_TYPE: 'DELETE_AGREEMENT_TYPE',
  DELETE_AGREEMENT_TYPE_SUCCESS: 'DELETE_AGREEMENT_TYPE_SUCCESS',
  DELETE_AGREEMENT_TYPE_FAILED: 'DELETE_AGREEMENT_TYPE_FAILED',

  CLEAR_AGREEMENT_TYPE_SUCCESS: 'CLEAR_AGREEMENT_TYPE_SUCCESS',

  GET_AGREEMENT_TYPES: 'GET_AGREEMENT_TYPES',
  GET_AGREEMENT_TYPES_SUCCESS: 'GET_AGREEMENT_TYPES_SUCCESS',
  GET_AGREEMENT_TYPES_FAILED: 'GET_AGREEMENT_TYPES_FAILED',

  CLEAR_STATUS: 'CLEAR_STATUS',

  UPDATING_AGREEMENT: 'UPDATING_AGREEMENT',
  AGREEMENT_UPDATED: 'AGREEMENT_UPDATED',
  AGREEMENT_UPDATED_FAILED: 'AGREEMENT_UPDATED_FAILED',
  AGREEMENT_UPDATED_SUCCESS: 'AGREEMENT_UPDATED_SUCCESS',
};
