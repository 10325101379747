import styled from 'styled-components';

export const SoftLinesContainer = styled.div`
  font-size: 14px;
  color: #00000080;
  display: flex;
  justify-content: center;
`;

export const SoftLine = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
