import { IconTypes,  } from '_components/TangiLibrary';
import { ChipType } from './types';
import { ERROR_SHADES, SUCCESS_SHADES, WARNING_SHADES } from 'utils/theme';

export const VARIANT_COLOR: Record<ChipType, string> = {
    [ChipType.SUCCESS]: SUCCESS_SHADES[600],
    [ChipType.WARNING]: WARNING_SHADES[1000],
    [ChipType.ERROR]: ERROR_SHADES[500],
  };
  
  export const VARIANT_SVG: Record<ChipType, IconTypes> = {
    [ChipType.SUCCESS]: 'complaint',
    [ChipType.WARNING]: 'pendingAcknowledgment',
    [ChipType.ERROR]: 'errorIcon',
  };