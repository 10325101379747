import styled from 'styled-components';

import { Divider, FlexContainer, StyledCard } from '../../utils/globalStyles';
import { SPACING } from 'utils/theme';

export const FlexContainerColumn = styled(FlexContainer)`
  align-items: center;
  flex-direction: column;
`;

export const MainContainer = styled(StyledCard)`
  border-radius: ${SPACING[2]};
  width: 100%;
  padding: ${SPACING[4]};
  margin-top: ${SPACING[4]};
`;

export const EmptyStateContainer = styled(FlexContainerColumn)`
  gap: ${SPACING[6]};
  height: calc(100vh - 312px);
  justify-content: center;
  align-items: center;
`;

export const emptyStateTypographyStyles = `
  width: 408px;
  text-align: center;
`;

export const ActivityGridContainer = styled.div`
  display: grid;
  grid-template-columns: 168px ${SPACING[6]} 1fr;
  grid-auto-rows: auto;
  gap: ${SPACING[3]};
  width: 100%;

  > :nth-child(3n) {
    padding-right: ${SPACING[3]};
  }
`;

export const ActivityRowDivider = styled(Divider)`
  margin-bottom: ${SPACING[0]};
  margin-top: ${SPACING[3]};
`;

interface FlexSpanProps {
  marginTop?: string;
}
export const FlexSpan = styled.span<FlexSpanProps>`
  display: flex;
  gap: ${SPACING[1]};
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: ${({ marginTop }) => marginTop || SPACING[0]};
`;
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: nowrap;
`;

export const Container = styled.div`
  flex-wrap: wrap;
  gap: ${SPACING[1]};
  align-items: center;
  padding: ${SPACING[2]} ${SPACING[0]};
`;

export const ActivityAvatarContainer = styled.div`
  display: flex;
  gap: 3px;
`;
