import { useTranslation } from 'react-i18next';

import { InventionDraggableList } from '../InventionDraggableLIst/InventionDraggableList';
import InventionCard from '../InventionCard/InventionCard';
import InventionCardTitle from '../InventionCard/InventionCardTitle';
import { useAssociation } from '../../../../_context/InventionDisclosure/AssociationContext';
import { IDFResultsResponse, PotentialClaim } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { InventionTabs } from '../InventionTabs/InventionTabs';

type Data = Omit<IDFResultsResponse, 'claims'> & { claims: PotentialClaim[] };
interface Props {
  data: Data;
}

export const InventionClaim = ({ data }: Props) => {
  const { t } = useTranslation();
  const { scrollClaimsContainerRef } = useAssociation();

  const totalNumberOfBullets = (data && data.claims.length) || 0;

  const bodyText = data && <InventionDraggableList data={data.claims} />;

  const title = (
    <>
      <InventionCardTitle titleText={t('INVENTION_DISCLOSURE.PATENT_CLAIMS')} count={totalNumberOfBullets} />
      <InventionTabs />
    </>
  );

  return <InventionCard scrollRef={scrollClaimsContainerRef} title={title} body={bodyText} />;
};
