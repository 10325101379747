import { TangiTypography } from '_components/TangiLibrary';
import { Button } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { FlexContainer, StyledCard } from 'utils/globalStyles';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const CardContainer = styled(StyledCard)<{ height?: string; width?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${SPACING[4]} 0;
  padding: ${SPACING[3]};
  border-radius: ${SPACING[2]};
  height: ${({ height }) => height ?? '100%'};
  width: ${({ width }) => width ?? '100%'};
  position: relative;
`;

export const HeaderContainer = styled(FlexContainer)`
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsGroupContainer = styled.div`
  right: ${SPACING[3]};
  top: ${SPACING[3]};
`;

export const StyledButton = styled(Button)`
  border: 1px solid ${PRIMARY_SHADES[800]} !important;
  background-color: ${NEUTRAL_SHADES.WHITE} !important;
  display: flex;
  align-items: center;
  padding-inline: ${SPACING[3]} !important;
  height: ${SPACING[5]};
  :disabled {
    opacity: 1;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${PRIMARY_SHADES[800]} !important;
      border: 1px solid ${PRIMARY_SHADES[800]} !important;
    `}
`;

export const StyledButtonText = styled(TangiTypography)<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? NEUTRAL_SHADES.WHITE : PRIMARY_SHADES[800])};
`;

const CardButtonContainer = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 3px 16px 0px #0000001f;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  .tangi-icon-svg rect {
    fill: ${NEUTRAL_SHADES.WHITE};
  }
`;

export const LeftNavButtonContainer = styled(CardButtonContainer)`
  left: 24px;
`;

export const RightNavButtonContainer = styled(CardButtonContainer)`
  right: 24px;
`;
