import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { downloadAgreement } from 'redux-toolkit/thunks/agreementThunks';
import { RESULT_STATUS } from 'utils/enums';
import { AgreementLoaders, FilePayloadWithIndex } from 'utils/types/agreement/agreement';
import { ToastProps } from 'utils/types/types';

// TODO: ------ THIS IS NEW ------
// agreementReducer NEED TO BE REFACTORED AND MOVED HERE

interface agreementState {
  error: string;
  agreementToastProps: ToastProps;
  agreementLoaders: AgreementLoaders;
}

const initialState: agreementState = {
  error: '',
  agreementToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  agreementLoaders: { download: { 1: false, 2: false } },
};

const agreementSlice = createSlice({
  name: 'agreementData',
  initialState,
  reducers: {
    setAgreementToastProps(state, action: PayloadAction<ToastProps>) {
      state.agreementToastProps = action.payload;
    },
  },
  extraReducers: (builder) => {
    // downloadAgreement
    builder.addCase(downloadAgreement.pending, (state, action) => {
      // set the loader for the correct agreement
      const { agreementOrderNumber } = action.meta.arg;
      state.agreementLoaders.download[agreementOrderNumber] = true;
    });
    builder.addCase(downloadAgreement.fulfilled, (state, action: PayloadAction<FilePayloadWithIndex>) => {
      state.agreementLoaders.download[action.payload.agreementOrderNumber] = false;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(downloadAgreement.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      // TODO: fix any type
      const { agreementOrderNumber } = action.meta.arg;
      const text = i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
      state.error = action?.error?.message ?? '';
      state.agreementLoaders.download[agreementOrderNumber] = false;
      state.agreementToastProps = { show: true, text, type: RESULT_STATUS.ERROR };
    });
  },
});

export const agreementNewActions = agreementSlice.actions;

export default agreementSlice.reducer;
