import { createAsyncThunk } from '@reduxjs/toolkit';
import { csvConfig, transformData } from 'pages/TradeSecretsAnalysis/PredictedPortfolio/utils';
import ipPortfolioAdapter from 'redux-toolkit/adapters/predictedPortfolioAdapter';
import { exportCsv } from 'utils/exportToCsv';
import { PredictedPortfolioGraphsData, PredictedPortfolioTableData, PredictedPortfolioTableParmas } from 'utils/types/predictedPortfolio/predictedPortfolio';

export const getPredictedPortfolioTradeSecretsTable = createAsyncThunk<PredictedPortfolioTableData | null, PredictedPortfolioTableParmas>(
  '/predictedPortfolio/getTradeSecretsTable',
  async ({ limit, page, filters }: PredictedPortfolioTableParmas) => {
    const res: PredictedPortfolioTableData = await ipPortfolioAdapter.getPredictedPortfolioTradeSecretsTable(limit, page, filters);

    return res ?? null;
  },
);

export const getPredictedPortfolioGraphsResults = createAsyncThunk('/predictedPortfolio/getTradeSecretsGraphsResults', async () => {
  const res: PredictedPortfolioGraphsData = await ipPortfolioAdapter.getPredictedPortfolioGraphsResults();

  return res ?? null;
});

export const getPredictedPortfolioTradeSecretsExportTable = createAsyncThunk(
  '/predictedPortfolio/getPredictedPortfolioTradeSecretsExportTable',
  async ({ limit, page, filters }: PredictedPortfolioTableParmas) => {
    const res: PredictedPortfolioTableData = await ipPortfolioAdapter.getPredictedPortfolioTradeSecretsTable(limit, page, filters);

    const transformedData = transformData(res);

    exportCsv({ data: transformedData, config: csvConfig });
  },
);

export const getPredictedPortfolioModalTable = createAsyncThunk<PredictedPortfolioTableData | null, PredictedPortfolioTableParmas>(
  '/predictedPortfolio/getPredictedPortfolioModalTable',
  async ({ limit, page, filters }: PredictedPortfolioTableParmas) => {
    const res: PredictedPortfolioTableData = await ipPortfolioAdapter.getPredictedPortfolioTradeSecretsTable(limit, page, filters);

    return res ?? null;
  },
);
