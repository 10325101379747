export const assetConstants = {
  GET_ASSET_REQUEST: 'GET_ASSET_REQUEST',
  GET_ASSET_FAILURE: 'GET_ASSET_FAILURE',
};

export const assetMetaDataEnums = {
  commercialProduct: 'commercialProduct',
  businessRelated: 'businessRelated',
  project: 'project',
  tag: 'tag',
  department: 'department',
  businessValue: 'businessValue',
  assetType: 'assetType',
};

export const assetMetaDataFields = [assetMetaDataEnums.commercialProduct, assetMetaDataEnums.businessRelated];

export const responseStatus = {
  uploadStarted: 'uploadStarted',
  uploadSuccess: 'uploadSuccess',
  createSuccess: 'createSuccess',
  editSuccess: 'editSuccess',
  assetFailed: 'assetFailed',
  editFailed: 'editFailed',
};

export const MAX_PREVIEW_IN_EMBED_FILE_SIZE = 1572864;
