import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { InventionClaim } from './components/InventionClaim/InventionClaim';
import { TangiBackNavigation } from '_components/TangiLibrary/TangiBackNavigation/TangiBackNavigation';
import { TangiTypography } from '_components/TangiLibrary';
import { Dashboard } from 'pages/Dashboard';
import SeeOriginalTextModal from './components/Modals/SeeOriginalTextModal';
import InventionDisclosureWarning from './components/InventionDisclosureWarning/InventionDisclosureWarning';
import InventionTradeSecrets from './components/InventionTradeSecrets/InventionTradeSecretsSection';
import { AssociationProvider } from '../../_context/InventionDisclosure/AssociationContext';

import { OPTIMAL_WORDS } from '_components/InventionDisclosureSearch/utils';
import { RootState } from '_helpers';
import { ContentViewContainer } from 'utils/globalStyles';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { Column, ContentRow, TitleRow, TopRow, UrlLink } from './style';
import { XRAY_ERROR_MESSAGES, XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { InventionExportButton } from './components/InventionExportBtn/InventionExportButton';

const InventionDisclosure = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { response, status, details } = useSelector((state: RootState) => state.inventionDisclosure.inventionDisclosureData);
  const originalText = useSelector((state: RootState) => state.inventionDisclosure.inventionDisclosureData?.input_text);
  const { getResultLoader } = useSelector((state: RootState) => state.inventionDisclosure.idfLoaders);

  const isShortInput = useMemo(() => status === XRAY_JOB_STATUS.SUCCEEDED && details === XRAY_ERROR_MESSAGES.INVALID_INPUT, [status, details]);

  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!getResultLoader && !response?.mapping) {
      history.push(generateRoute(AppRoutes.INVENTION_DISCLOSURE_MAIN_PAGE, { clientId }));
    }
  }, [getResultLoader, clientId, response?.mapping]);

  const renderNavigationContent = () => {
    const path = generateRoute(AppRoutes.INVENTION_DISCLOSURE_MAIN_PAGE, { clientId });
    const buttonText = t('PATENT_TOOL.CONTENT.BACK_TO_INVENTION_DISCLOSURE_HOME');

    //TODO: add here the data for exporting
    return (
      <>
        <TangiBackNavigation navigationPath={path} buttonText={buttonText} />
        <InventionExportButton data={response} input_text={originalText} />
      </>
    );
  };

  const renderTitle = () => {
    return (
      <TitleRow>
        {isShortInput && <InventionDisclosureWarning warningMessage={t('INVENTION_DISCLOSURE.SHORT_INPUT', { OPTIMAL_WORDS })} />}
        <TangiTypography type="heading-xl" weight="semibold">
          {t('INVENTION_DISCLOSURE.TITLE')}
        </TangiTypography>
        <UrlLink data-testid="seeOriginalModal" onClick={() => setShowModal(true)}>
          {t('INVENTION_DISCLOSURE.SEE_ORIGINAL_TEXT')}
        </UrlLink>
        <SeeOriginalTextModal isShow={showModal} onHide={() => setShowModal(false)} bodyText={originalText || ''} />
      </TitleRow>
    );
  };

  return (
    <Dashboard title="">
      <ContentViewContainer>
        <TopRow>{renderNavigationContent()}</TopRow>
        {renderTitle()}
        <AssociationProvider>
          <ContentRow isWarning={isShortInput}>
            <Column>
              <InventionClaim data={response} />
            </Column>
            <Column>
              <InventionTradeSecrets clientId={clientId} tradeSecrets={response?.trade_secrets ?? []} />
            </Column>
          </ContentRow>
        </AssociationProvider>
      </ContentViewContainer>
    </Dashboard>
  );
};

export default InventionDisclosure;
