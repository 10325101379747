import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { Placement } from 'tippy.js';
import Tippy from '@tippyjs/react';

import { TangiTypography } from '../TangiTypography';
import { IconTypes, TangiSvgIcon } from '../TangiSvgIcon';
import { Star } from '../../Star';
import { ERROR_SHADES, NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { customStarStyles, ErrorContainer, StyledFormGroup, StyledInput, StyledLabelContainer } from './styleV2';

interface Props<T extends FieldValues> {
  name: Path<T>;
  label?: string | React.ReactNode;
  required?: boolean;
  smallText?: string;
  dataTestId?: string;
  placeholder?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  disabled?: boolean;
  labelIcon?: boolean;
  labelIconSize?: string;
  labelIconColor?: string;
  labelIconComponent?: IconTypes;
  labelTooltipContent?: React.ReactNode;
  labelTooltipPlacement?: Placement;
  renderLabelTooltipContent?: null | (() => React.ReactNode);
  autoComplete?: boolean;
}

export function TangiInputV2<T extends FieldValues>({
  label = '',
  name,
  placeholder = '',
  labelIcon,
  labelIconComponent = 'help',
  labelIconSize = SPACING[2.5],
  labelIconColor = NEUTRAL_SHADES[800],
  renderLabelTooltipContent = null,
  labelTooltipContent = null,
  labelTooltipPlacement = 'top',
  required = false,
  dataTestId = 'tangi-input-v2',
  type = 'text',
  smallText = '',
  disabled = false,
  autoComplete = true,
}: Props<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const isError = !!error;
        return (
          <StyledFormGroup controlId={name}>
            {label && (
              <StyledLabelContainer data-testid={`${dataTestId}-label-group`}>
                <TangiTypography type="body" weight="bold" color={isError ? ERROR_SHADES[500] : NEUTRAL_SHADES.BLACK}>
                  {label}
                </TangiTypography>
                {required && <Star customStyles={customStarStyles} />}
                {labelIcon && (
                  <Tippy
                    disabled={!renderLabelTooltipContent && !labelTooltipContent}
                    placement={labelTooltipPlacement}
                    content={labelTooltipContent}
                    render={renderLabelTooltipContent ? () => renderLabelTooltipContent() : undefined}
                    trigger="mouseenter"
                    animation={false}
                  >
                    <span>
                      <TangiSvgIcon component={labelIconComponent} size={labelIconSize} color={labelIconColor} />
                    </span>
                  </Tippy>
                )}
              </StyledLabelContainer>
            )}

            <StyledInput
              {...field}
              disabled={disabled}
              className="tangi-input-v2"
              data-testid={`tangi-input-${name}`}
              isInvalid={isError}
              type={type}
              placeholder={placeholder}
              autoComplete={autoComplete ? 'on' : 'off'}
            />
            {smallText && <small className="form-text text-muted txt-size">{smallText}</small>}

            <ErrorContainer>
              {isError ? (
                <TangiTypography type="sub-body" dataTestId={`${dataTestId}-error-feedback`} color={isError ? ERROR_SHADES[500] : NEUTRAL_SHADES.BLACK}>
                  {error?.message}
                </TangiTypography>
              ) : (
                <span />
              )}
            </ErrorContainer>
          </StyledFormGroup>
        );
      }}
    />
  );
}

export default TangiInputV2;
