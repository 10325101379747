/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FileRejection } from 'react-dropzone';

import { BUTTON_SIZES, BUTTON_VARIANTS } from 'utils/componentUtils';
import { TangiAlert, TangiButton, TangiIconButton, TangiTypography } from '_components/TangiLibrary';
import { RESULT_STATUS } from 'utils/enums';
import { MAX_FILE_SIZE, LARGE_FILE_CODE_ERROR, ONE_MB } from '_constants/fileConstants';
import { formatLongFileName } from 'utils/fileNameUtils';
import { Divider, StyledModal } from 'utils/globalStyles';
import { StyledModalHeader, StyledModalFooter, TitleContainer, IconAndText, PhrasesContainer, DeleteFileButtonContainer, NameContainer, FileContainer, StyledModalBody } from './style';
import { TangiDragAndDropFile } from '_components/TangiLibrary/TangiDragAndDropFile/TangiDragAndDropFile';

interface IUploadFileModalProps {
  acceptedFilesList: string;
  title: string;
  submitButtonText: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: File[]) => void;
  isEditMode?: boolean;
  customDragNDropText?: string;
  customDragNDropRejectText?: string;
  loading?: boolean;
  defaultFile?: { fileName: string; type: string }[] | null;
  phraseLineTop?: string;
  phraseLineBottom?: string;
}

interface IAlertState {
  show: boolean;
  text: string;
  type: RESULT_STATUS;
}

const MAX_REJECTED_FILENAME_LENGTH = 23;
const MAX_ACCEPTED_FILENAME_LENGTH = 45;

const UploadFileModal = ({
  title,
  isOpen,
  onClose,
  onSubmit,
  submitButtonText,
  acceptedFilesList,
  isEditMode = false,
  customDragNDropText,
  customDragNDropRejectText,
  loading = false,
  defaultFile = null,
  phraseLineTop = '',
  phraseLineBottom = '',
}: IUploadFileModalProps) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File[]>([]);
  const [alertProps, setAlertProps] = useState<IAlertState>({
    show: false,
    text: '',
    type: RESULT_STATUS.BLANK,
  });

  const resetAlertProps = () => {
    setAlertProps({ show: false, text: '', type: RESULT_STATUS.BLANK });
  };

  const setDefaultFile = () => {
    if (defaultFile) {
      const updatedFileArray: any = defaultFile.map((fileObj) => {
        const { fileName, ...rest } = fileObj;
        return { ...rest, name: fileName };
      });
      setFile(updatedFileArray);
    } else setFile([]);
  };

  const handleClose = () => {
    if (defaultFile) {
      setDefaultFile();
    }
    !isEditMode && setFile([]);
    resetAlertProps();
    onClose();
  };

  const handleDelete = () => {
    setFile([]);
  };

  useEffect(() => {
    setDefaultFile();
  }, [defaultFile]);

  const handleUploadFile = () => {
    onSubmit(file);
    setFile([]);
    resetAlertProps();
  };

  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    resetAlertProps();
    if (fileRejections.length && fileRejections[0]?.errors[0]?.code === LARGE_FILE_CODE_ERROR) {
      const rejectedFileName = formatLongFileName(fileRejections[0].file.name, MAX_REJECTED_FILENAME_LENGTH);
      const maxAcceptedFileSize = MAX_FILE_SIZE / ONE_MB;
      setAlertProps({ show: true, text: t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_TOO_LARGE', { FILE_NAME: rejectedFileName, FILE_SIZE: maxAcceptedFileSize }), type: RESULT_STATUS.ERROR });
    }
    setFile(acceptedFiles);
  }, []);

  const renderModalHeader = () => {
    return (
      <StyledModalHeader>
        <TitleContainer>
          <IconAndText>
            <TangiTypography type="heading-lg">{title}</TangiTypography>
          </IconAndText>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} icon="close" />
        </TitleContainer>
        <PhrasesContainer>
          <TangiTypography type="subheading">{phraseLineTop}</TangiTypography>
          <TangiTypography type="subheading">{phraseLineBottom}</TangiTypography>
        </PhrasesContainer>
      </StyledModalHeader>
    );
  };

  const renderFileNameContainer = () => {
    return (
      <FileContainer data-testid="uploaded-file-name">
        <NameContainer>
          <i className="ms-2 far fa-file fs-6 text-primary me-1" />
          <span>{!!file.length && formatLongFileName(file[0]?.name || '', MAX_ACCEPTED_FILENAME_LENGTH)}</span>
        </NameContainer>
        {isEditMode && (
          <DeleteFileButtonContainer data-testid="delete-uploaded-file">
            <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleDelete} icon="delete" size={BUTTON_SIZES.XS} />
          </DeleteFileButtonContainer>
        )}
      </FileContainer>
    );
  };

  const renderModalFooter = () => {
    return (
      <StyledModalFooter>
        <Divider />
        <div>
          <TangiButton text={submitButtonText} onClick={handleUploadFile} loading={loading} disabled={!Boolean(file?.length || defaultFile)} data-testid="upload-button" />
        </div>
      </StyledModalFooter>
    );
  };

  return (
    <StyledModal transition="Fade" show={isOpen} onHide={handleClose}>
      {renderModalHeader()}
      <StyledModalBody data-testid="upload-file-modal">
        <TangiDragAndDropFile
          acceptedFilesList={acceptedFilesList}
          isEditMode={isEditMode}
          onDrop={onDrop}
          renderOnSuccessCmp={!!file?.length ? renderFileNameContainer() : undefined}
          customDragAndDropText={customDragNDropText}
          customDragAndDropRejectText={customDragNDropRejectText}
        />
        <TangiAlert {...alertProps} />
      </StyledModalBody>

      {renderModalFooter()}
    </StyledModal>
  );
};

export default UploadFileModal;
