import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getPartnershipById } from 'redux-toolkit/thunks/partnershipThunks';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import PartnershipForm from '../PartnershipForm/PartnershipForm';
import { TangiTypography } from '_components/TangiLibrary';
import PartnershipInviteUsersModal from '_components/PartnershipInviteUsersModal/PartnershipInviteUsersModal';
import FinishCreationPartnerModal from '_components/FinishCreationPartnershipModal/FinishCreationPartnershipModal';
import { DuoContainer } from 'utils/globalStyles';
import { ModalContainer, clientNameStyles } from './style';

const ViewPartnership = ({ isEditMode = true, handleCloseEditModal, showEditModal }) => {
  const { addPartnerModalIsOpen, partnership } = useSelector((state) => state.partnership);
  const { activeAccount, Role } = useSelector((state) => state.authentication);
  const { partnershipId } = useParams();
  const { activeClient } = useSelector((state) => state.lawfirm);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const checkClientName = () => {
    return lawyers.includes(Role) ? activeClient?.name || '' : activeAccount?.client?.name || '';
  };

  const handleClose = () => {
    if (isEditMode) {
      handleCloseEditModal();
    }
    dispatch(partnershipActions.changeAddModalVisibility(false));
  };

  const handleCloseAddUser = () => {
    batch(() => {
      dispatch(usersActions.setClearInvitedMultiple());
      dispatch(partnershipActions.setInviteUsersModalState(false));
      dispatch(getPartnershipById(partnershipId));
    });
  };

  return (
    <>
      <Modal show={isEditMode ? showEditModal : addPartnerModalIsOpen} onHide={handleClose} transition="Fade" backdrop="static" className="partnership-modal">
        <ModalContainer>
          <Modal.Header closeButton>
            <DuoContainer>
              <TangiTypography type="heading-lg">{isEditMode ? t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.EDIT_PARTNER') : t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.CREATE_NEW_PARTNER_IN')}</TangiTypography>
              <TangiTypography type="heading-lg" weight="semibold" customStyles={clientNameStyles}>
                {isEditMode ? partnership.name : checkClientName()}
              </TangiTypography>
            </DuoContainer>
          </Modal.Header>
          <Modal.Body>
            <PartnershipForm isEditMode={isEditMode} handleClose={handleClose} />
          </Modal.Body>
        </ModalContainer>
      </Modal>
      {/*Modals */}
      <PartnershipInviteUsersModal onFinish={handleCloseAddUser} />
      <FinishCreationPartnerModal />
    </>
  );
};
export default ViewPartnership;
