import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { PRIMARY_SHADES } from 'utils/theme';
import { Border, Container, LeftSideContainer, RightSideContainer, XRaySearchContentContainer } from './styles';
import ContractXraySearch from '_components/ContractXraySearch/ContractXraySearch';

interface Props {
  onClickContractStatus: () => void;
}

export const ContractAnalyzerCard = ({ onClickContractStatus }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <LeftSideContainer>
        <TangiSvgIcon component="contractXrayBlue" color="" />
        <TangiTypography color={PRIMARY_SHADES[800]} type="subheading" weight="bold">
          {t('IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.CONTRACT_XRAY')}
        </TangiTypography>
      </LeftSideContainer>
      <Border />
      <RightSideContainer>
        <TangiTypography weight="semibold">{t('IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.CARD_TITLE')}</TangiTypography>
        <ContractXraySearch
          onClickContractStatus={onClickContractStatus}
          description={t('IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.CARD_DESCRIPTION')}
          buttonText={t('IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.ANALYZE_CONTRACT')}
          contentContainer={XRaySearchContentContainer}
        />
      </RightSideContainer>
    </Container>
  );
};
