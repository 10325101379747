import { Fragment, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RootState } from '_helpers';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import {
  CollapseContainer,
  CollapseContentContainer,
  CollapseDivider,
  CollapseKeyContainer,
  CollapseParameterContainer,
  ContentSummaryContainer,
  ParameterContainer,
  RecommendationContainer,
} from './styles';
import { ChildData, ContractState } from './types';
import { contractOptionsData, initialStates } from './consts';
import { ContractKeys } from 'utils/types/contract/contract';

export const ContractParameters = () => {
  const [openStates, setOpenStates] = useState<ContractState>(initialStates);
  const [transitioning, setTransitioning] = useState<string | null>(null);
  const { contractData } = useSelector((state: RootState) => state.contract);
  const checklist = useMemo(() => contractData?.response?.checklist_results, [contractData]);

  const toggleChild = (contractKey: ContractKeys) => {
    if (transitioning === null) {
      setOpenStates((prevOpenStates) => ({
        ...prevOpenStates,
        [contractKey]: !prevOpenStates[contractKey],
      }));
      setTransitioning(contractKey);
    }
  };

  const handleTransitionEnd = (contractKey: ContractKeys) => {
    setTransitioning(null);
    if (!openStates[contractKey]) {
      setOpenStates((prevOpenStates) => ({
        ...prevOpenStates,
        [contractKey]: false,
      }));
    }
  };

  const renderContractHeader = () => {
    return (
      <TangiTypography type="heading-md" weight="semibold">
        {t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.DOCUMENT_TRADE_SECRET_PROTECTION_SCORE')}
      </TangiTypography>
    );
  };

  const renderRecommendationContainer = (contractOption: ChildData) => {
    return (
      <RecommendationContainer data-testid={`recommendation-div-${contractOption}`}>
        <TangiTypography type="body" weight="semibold" color={PRIMARY_SHADES[600]}>
          {t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.RECOMMENDATIONS')}
        </TangiTypography>
        <TangiTypography type="body" weight="regular">
          {t(contractOption?.recommendation)}
        </TangiTypography>
      </RecommendationContainer>
    );
  };

  const renderCollapseContentContainer = (contractOption: ChildData) => {
    return (
      <Collapse in={openStates[contractOption.key]} onExited={() => handleTransitionEnd(contractOption.key)} onEntered={() => handleTransitionEnd(contractOption.key)} data-testid="collapse-item">
        <CollapseContentContainer>
          <TangiTypography type="body" weight="regular" data-testid="collapse-content">
            {t(contractOption?.content)}
          </TangiTypography>
          {!checklist[contractOption.key] && renderRecommendationContainer(contractOption)}
        </CollapseContentContainer>
      </Collapse>
    );
  };

  const renderCollapseContainer = (contractOption: ChildData) => {
    return (
      <CollapseParameterContainer key={contractOption.key} data-testid="contract-items">
        <CollapseContainer onClick={() => toggleChild(contractOption.key)}>
          <CollapseKeyContainer>
            <TangiSvgIcon component={openStates[contractOption.key] ? 'arrowUp' : 'arrowDown'} color={NEUTRAL_SHADES.BLACK} />
            <TangiTypography type="subheading" weight="regular">
              {t(contractOption?.label)}
            </TangiTypography>
          </CollapseKeyContainer>

          <TangiSvgIcon component={contractData.response.checklist_results[contractOption.key] ? 'matchIndication' : 'contractFailed'} color={NEUTRAL_SHADES.WHITE} />
        </CollapseContainer>
        {renderCollapseContentContainer(contractOption)}
      </CollapseParameterContainer>
    );
  };
  const renderContractContentContainer = () => {
    return (
      <ParameterContainer>
        <TangiTypography type="subheading" weight="semibold">
          {t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.PARAMETERS')}
        </TangiTypography>
        <CollapseDivider />
        <div>
          {contractOptionsData.map((contractOption, index) => (
            <Fragment key={contractOption.key}>
              {renderCollapseContainer(contractOption)}
              {index !== contractOptionsData.length - 1 && <CollapseDivider />}
            </Fragment>
          ))}
        </div>
      </ParameterContainer>
    );
  };

  return (
    <ContentSummaryContainer>
      {renderContractHeader()}
      {renderContractContentContainer()}
    </ContentSummaryContainer>
  );
};
