import React from 'react';
import Tippy from '@tippyjs/react';
import { Placement } from 'tippy.js';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { BreadcrumbContainer, BreadcrumbItemSpan } from './style';

interface BreadcrumbItem {
  label: React.ReactNode;
}

interface Props {
  items: BreadcrumbItem[];
  separator?: React.ReactNode;
  maxItemsToShow?: number;
  tooltipContent?: string;
  tooltipPlacement?: Placement;
}

const BreadCrumbs = ({ items, separator, maxItemsToShow, tooltipContent, tooltipPlacement = 'top-start' }: Props) => {
  const defaultSeparator = <TangiSvgIcon color={NEUTRAL_SHADES[600]} component="arrowSideRight" />;
  const shouldTruncate = maxItemsToShow ? items.length > maxItemsToShow : false;
  const truncatedItems = shouldTruncate ? [items[0], { label: <TangiTypography color={NEUTRAL_SHADES[800]}>...</TangiTypography> }, ...items.slice(-1)] : items;

  const isTooltipEnabled = !!tooltipContent;

  return (
    <div>
      <Tippy disabled={!isTooltipEnabled} content={tooltipContent || ''} placement={tooltipPlacement}>
        <BreadcrumbContainer>
          {truncatedItems.map((item, index) => (
            <React.Fragment key={index}>
              <BreadcrumbItemSpan>{item.label}</BreadcrumbItemSpan>
              <BreadcrumbItemSpan> {index < truncatedItems.length - 1 && <span className="separator">{separator || defaultSeparator}</span>}</BreadcrumbItemSpan>
            </React.Fragment>
          ))}
        </BreadcrumbContainer>
      </Tippy>
    </div>
  );
};

export default BreadCrumbs;
