import styled from 'styled-components';

import { Card } from '_components/AssetRecipients/style';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const StyledWidgetContainer = styled(Card)`
  min-height: 104px;
  gap: ${SPACING[2]};
  padding: ${SPACING[3]};
  width: 260px;
  min-height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WidgetsSectionContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
`;

export const numberCustomStyle = `
  font-size: ${SPACING[6]};
  color: ${PRIMARY_SHADES[800]};
`;
