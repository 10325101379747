import { headers, headersmultipart } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { GetLawyerClients, CreateLawFirm, UploadLawfirmLogo, GetLawyerClient } from 'utils/types/lawFirm/lawFirm';
import { lawFirmConstants } from '_constants';

class LawFirmAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;

  static lawFirmEndpoint = `${config.apiUrl}${config.apiVersion}/lawFirm`;

  static lawFirmLogoEndpoint = `${config.apiUrl}${config.apiVersion}/file/profile`;

  static lawFirmClientsEndpoint = `${config.apiUrl}${config.apiVersion}/clients`;

  async getLawyerClients({ accountId, limit = lawFirmConstants.DEFAULT_LIMIT }: GetLawyerClients) {
    const res = await HttpClientService.get(`${LawFirmAdapter.lawFirmClientsEndpoint}?lawyer=${accountId}&limit=${limit}`, { headers: headers() });
    return res.data;
  }

  async getLawyerClient({ accountId, clientId, limit = 1 }: GetLawyerClient) {
    const res = await HttpClientService.get(`${LawFirmAdapter.lawFirmClientsEndpoint}?id=${clientId}&lawyer=${accountId}&limit=${limit}`, { headers: headers() });
    return res.data;
  }

  async createLawFirm(data: CreateLawFirm) {
    const res = await HttpClientService.post(`${LawFirmAdapter.lawFirmEndpoint}/`, data, { headers: headers() });
    return res.data;
  }

  async uploadLawfirmLogo({ data, lawFirmId }: UploadLawfirmLogo) {
    const formData = new FormData();
    formData.append('file', data[0]);

    const res = await HttpClientService.post(`${LawFirmAdapter.lawFirmLogoEndpoint}`, formData, {
      headers: headersmultipart(),
      params: { lawFirmId },
    });
    return res.data;
  }
}

const lawFirmAdapter = new LawFirmAdapter();
export default lawFirmAdapter;
