import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { isEmpty, isFunction } from 'lodash';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import { TangiButton } from '../TangiButton';
import { formatLongFileName } from 'utils/fileNameUtils';
import {
  BodyContainer,
  StyledInput,
  ItemsContainer,
  MenuItem,
  StyledCheckbox,
  MenuItemTypography,
  Footer,
  FilterButton,
  IconContainer,
  StyledArrownDownIcon,
  StyledSelectedNum,
  StyledSelectedItem,
} from './style';

const MAX_ITEM_LENGTH = 31;

// The forwardRef is important
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ onClick, selectedItems, filterTitle, show, disabled, testId }, ref) => (
  <FilterButton
    className="ff-din-regular"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      if (!show) {
        onClick(e);
      }
    }}
    $isSelected={!isEmpty(selectedItems)}
    show={show}
    disabled={disabled}
    data-testid={!!testId ? `filter-button-${testId}` : 'filter-button'}
  >
    {selectedItems?.length ? (
      <>
        <StyledSelectedItem>{filterTitle}</StyledSelectedItem>
        <Tippy
          content={selectedItems.map((item, index) => (
            <div key={index}>{item.name}</div>
          ))}
          placement="bottom-end"
        >
          <StyledSelectedNum>{` / ${selectedItems.length}`}</StyledSelectedNum>
        </Tippy>
      </>
    ) : (
      <span>{filterTitle}</span>
    )}
    <IconContainer>
      <StyledArrownDownIcon $isSelected={!isEmpty(selectedItems)} className="multiselect-dropdown-icon" />
    </IconContainer>
  </FilterButton>
));

export const TangiMultiSelect = ({ filterTitle, onChange, selectedItems, itemList, customRenderOption, id, isSearch = false, disabled, testId = '' }) => {
  const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems);
  const [localItemList, setLocalItemList] = useState(itemList);
  const [searchValue, setSearchValue] = useState('');
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const dropdownRef = useRef(null);

  const isItemSelected = (id) => {
    return !!localSelectedItems.find((item) => item.id === id);
  };

  const handleToggle = () => {
    if (show) {
      setLocalSelectedItems([]);
      setSearchValue('');
    } else {
      setLocalSelectedItems(selectedItems);
      setLocalItemList(itemList);
    }
    setShow((prev) => !prev);
  };

  const handleSelectRow = (item) => {
    const isFound = isItemSelected(item.id);
    if (isFound) {
      setLocalSelectedItems((state) => state.filter((obj) => obj.id !== item.id));
    } else {
      setLocalSelectedItems((state) => [...state, { name: item?.formattedName || item?.name || item.displayName, id: item.id }]);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    if (isEmpty(value)) {
      setLocalItemList(itemList);
    } else {
      const filteredItemList = localItemList.filter((item) => {
        const name = item?.formattedName || item?.name || item.displayName;
        return name.toLowerCase()?.includes(value.toLowerCase());
      });
      setLocalItemList(filteredItemList);
    }
  };

  const handleApply = () => {
    onChange(localSelectedItems, id);
    setShow(false);
    if (searchValue !== '') {
      setSearchValue('');
    }
  };

  const handleClear = () => {
    if (!isEmpty(selectedItems)) {
      onChange([], id);
    }
    setSearchValue('');
    setLocalSelectedItems([]);
    setLocalItemList(itemList);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    const updateEventListener = () => {
      if (show) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };

    updateEventListener();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  return (
    <Dropdown onToggle={handleToggle} show={show} ref={dropdownRef}>
      <Dropdown.Toggle id={id} as={CustomToggle} selectedItems={selectedItems} filterTitle={filterTitle} show={show} disabled={disabled} testId={testId} />
      <Dropdown.Menu>
        <BodyContainer>
          {isSearch && (
            <StyledInput
              value={searchValue}
              name="search"
              placeholder={t('ASSET_PAGE.INPUT.SEARCH_OPTION')}
              onChange={(e) => handleSearch(e.target.value)}
              className="ff-din-regular"
              data-testid="multiselect-input"
            />
          )}
          <ItemsContainer>
            {localItemList.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleSelectRow(item);
                }}
                data-testid="multiselect-menu-item"
              >
                <StyledCheckbox checked={isItemSelected(item.id)} readOnly type="checkbox" />
                {isFunction(customRenderOption) ? (
                  customRenderOption(item.name)
                ) : (
                  <MenuItemTypography data-testid="multiselect-menu-text" className="ff-din-regular">
                    {formatLongFileName(item?.formattedName || item?.name || item.displayName, MAX_ITEM_LENGTH)}
                  </MenuItemTypography>
                )}
              </MenuItem>
            ))}
          </ItemsContainer>
        </BodyContainer>
        <Dropdown.Divider />
        <Footer>
          <div>
            <TangiButton
              variant="tertiary"
              text={t('GENERAL.BUTTONS_TEXT.CLEAR_ALL')}
              size="small"
              onClick={handleClear}
              disabled={isEmpty(localSelectedItems) && isEmpty(selectedItems)}
              data-testid="clear-filter"
            />
          </div>
          <div>
            <TangiButton text={t('GENERAL.BUTTONS_TEXT.APPLY')} size="small" onClick={handleApply} disabled={isEmpty(localSelectedItems) && isEmpty(selectedItems)} data-testid="apply-filter" />
          </div>
        </Footer>
      </Dropdown.Menu>
    </Dropdown>
  );
};
