import { TangiIconButton } from '_components/TangiLibrary';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SECONDARY_SHADES, SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 132px);
  margin: ${SPACING[5]};
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${SPACING[7]};
  height: calc(100% - 160px);
  position: relative;
  width: fit-content;
`;

export const NavPointsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${SPACING[4]};
  gap: ${SPACING[1.5]};
`;

export const NavPoint = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? SECONDARY_SHADES[800] : NEUTRAL_SHADES[400])};
  cursor: pointer;
`;

export const LeftNavButton = styled(TangiIconButton)`
  position: absolute;
  top: 50%;
  left: -7px;
  transform: translateY(-50%);
`;

export const RightNavButton = styled(TangiIconButton)`
  position: absolute;
  top: 50%;
  right: -7px;
  transform: translateY(-50%);
`;
