import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const ChartContainer = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  margin: ${SPACING[3]} 0;
  align-items: center;
  justify-content: center;
  gap: ${SPACING[0]};
  position: relative;
`;

export const TooltipContainer = styled(FlexContainer)`
  flex-direction: column;
  background-color: ${NEUTRAL_SHADES.WHITE};
  padding: ${SPACING[1]};
  gap: ${SPACING[2]};
  padding: ${SPACING[3]};
  border-radius: ${SPACING[1]};
  min-width: ${SPACING[10]};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
`;
export const TooltipContainerTippy = styled(FlexContainer)`
  flex-direction: column;
  background-color: ${NEUTRAL_SHADES.WHITE};
  gap: ${SPACING[2]};
  padding: ${SPACING[2]} ${SPACING[3]} ${SPACING[2]} ${SPACING[3]};
  border-radius: ${SPACING[1]};
  min-width: ${SPACING[10]};
  box-shadow: ${SPACING[0]} ${SPACING[0]} ${SPACING[2]} rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 9999 !important;
`;

export const ToolTipRow = styled(FlexContainer)`
  flex-direction: row;
  justify-content: space-between;
`;

export const ColorIcon = styled.div<{ color?: string }>`
  height: ${SPACING[2.5]};
  width: ${SPACING[2.5]};
  border-radius: 2px;
  background-color: ${({ color }) => color ?? 'transparent'};
`;

export const SubjectContainer = styled(FlexContainer)`
  gap: ${SPACING[2]};
  align-items: center;
`;
