import { formatLongFileName } from 'utils/fileNameUtils';
import { FILTERS_ID, FilterDataItem } from '../types';

export const normalizeData = (data: FilterDataItem[], id: FILTERS_ID): FilterDataItem[] => {
  return data?.map((item): FilterDataItem => {
    if (id === FILTERS_ID.COUNTRY && typeof item === 'string') {
      return { id: item, name: item };
    }

    return item as FilterDataItem;
  });
};

const MAX_ITEM_LENGTH = 25;

export const formatNameBasedOnFilter = (item: FilterDataItem) => {
  if (typeof item === 'string') {
    return formatLongFileName(item, MAX_ITEM_LENGTH);
  }
  return formatLongFileName(item.formattedName || item.name || item.displayName || item.id, MAX_ITEM_LENGTH);
};
