import React from 'react';
import { ItemContainer, AgreementName, StatusIcon } from '../style';
import successIcon from '../../../assets/img/check-circle-solid.png';
import pendingIcon from '../../../assets/img/exclamation-circle-solid.png';

const CompliantItem = ({ title, signed }) => {
  return (
    <ItemContainer>
      <AgreementName>{title}</AgreementName>
      <StatusIcon>
        <img className="m-auto" width="16" height="16" alt="..." src={signed ? successIcon : pendingIcon} />
      </StatusIcon>
    </ItemContainer>
  );
};

export default CompliantItem;
