import styled, { css } from 'styled-components';

import { Props as EmptyStateProps } from './EmptyState';
import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const EmptyStateContainer = styled(FlexContainer)<EmptyStateProps>`
  ${({ height }) => css`
    border: 1px solid ${NEUTRAL_SHADES[300]};
    background: ${NEUTRAL_SHADES[100]};
    border-radius: ${SPACING[2]};
    align-items: center;
    justify-content: center;
    height: ${height};
    margin: ${SPACING[9]} ${SPACING[4]};
    padding: ${SPACING[3]};
    width: 100%;
  `}
`;

export const EmptyStateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
