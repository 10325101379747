import { Controller, FieldError, FieldValues, Path, useFormContext } from 'react-hook-form';
import Select, { MenuPlacement } from 'react-select';

import { TangiTypography } from '../TangiTypography';
import { Star } from '../../Star';
import { Option } from './types';
import { ERROR_SHADES, NEUTRAL_SHADES } from 'utils/theme';
import { customStarStyles, ErrorContainer, StyledFormGroup, StyledLabelContainer } from './styleV2';

interface Props<T extends FieldValues> {
  name: Path<T>;
  options: Option[];
  label?: string | React.ReactNode;
  error?: FieldError;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  dataTestId?: string;
  menuScroll?: boolean;
  menuPlacement?: MenuPlacement;
}

export function TangiSelectorV2<T extends FieldValues>({
  name,
  options,
  label = '',
  required = false,
  disabled = false,
  placeholder = '',
  dataTestId = 'tangi-selector-v2',
  menuScroll = false,
  menuPlacement = 'bottom',
}: Props<T>) {
  const { control } = useFormContext<T>();
  return (
    <StyledFormGroup $menuScroll={menuScroll}>
      {label && (
        <StyledLabelContainer data-testid={`${dataTestId}-label-group`}>
          <TangiTypography type="body" weight="bold" color={NEUTRAL_SHADES.BLACK}>
            {label}
          </TangiTypography>
          {required && <Star customStyles={customStarStyles} />}
        </StyledLabelContainer>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          const isError = !!error;
          return (
            <>
              <Select<Option>
                {...field}
                isDisabled={disabled}
                options={options}
                classNamePrefix={error ? 'tangi-selector-error' : 'tangi-selector'}
                placeholder={placeholder}
                data-testid={dataTestId}
                menuShouldScrollIntoView={true}
                menuPlacement={menuPlacement}
              />
              <ErrorContainer>
                {isError ? (
                  <TangiTypography type="sub-body" dataTestId={`${dataTestId}-error-feedback`} color={isError ? ERROR_SHADES[500] : NEUTRAL_SHADES.BLACK}>
                    {error?.message}
                  </TangiTypography>
                ) : (
                  <span />
                )}
              </ErrorContainer>
            </>
          );
        }}
      />
    </StyledFormGroup>
  );
}

export default TangiSelectorV2;
