import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CardsContainer } from './styles';

const CourseCardSkeleton = () => {
  return (
    <CardsContainer>
      {[1, 2, 3, 4, 5].map((card) => (
        <Skeleton key={card} height={300} width="72vw" style={{ marginRight: '0px', marginBottom: '30px' }} />
      ))}
    </CardsContainer>
  );
};

export default CourseCardSkeleton;
