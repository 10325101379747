import React from 'react';

import { TangiButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { Content } from './style';
import { NEUTRAL_SHADES } from 'utils/theme';
import { HomeContentProps } from './types';
import { CenteredContainer } from 'utils/globalStyles';

const HomeContent = ({ svgIcon, title, subtitle, buttonText, onButtonClick }: HomeContentProps) => (
  <Content>
    <CenteredContainer>
      <TangiSvgIcon component={svgIcon} color="" />
    </CenteredContainer>
    <TangiTypography type={TYPOGRAPHY_TYPES.HEADING_LG} weight="semibold">
      {title}
    </TangiTypography>
    {subtitle && <TangiTypography type={TYPOGRAPHY_TYPES.HEADING_MD}>{subtitle}</TangiTypography>}
    <TangiButton svgIcon="arrowRight" color={NEUTRAL_SHADES.WHITE} onClick={onButtonClick} text={buttonText} endIcon={true} smallbtn />
  </Content>
);

export default HomeContent;
