import React from 'react';
import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { StyledCard } from 'utils/globalStyles';

const ScrollableBody = styled.div`
  overflow-y: auto;
  padding: ${SPACING[0]} ${SPACING[4]} ${SPACING[4]};
  margin-right: ${SPACING[1]};

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    border-radius: ${SPACING[1]};
    margin-bottom: ${SPACING[3]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[300]};
    border-radius: ${SPACING[1]};
    cursor: default;
  }
`;

export const Card = styled(StyledCard)`
  border-radius: ${SPACING[2]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;

interface Props {
  title: React.ReactNode;
  body: React.ReactNode;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const InventionCard = ({ title, body, scrollRef }: Props) => {
  return (
    <Card>
      {title}
      <ScrollableBody ref={scrollRef}>{body}</ScrollableBody>
    </Card>
  );
};

export default InventionCard;
