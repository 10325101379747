import dayjs from 'dayjs';

export const DATE_FORMAT_DDMONYYYY = 'DD MMM YYYY';

/**
 * Formats a date using the provided format string.
 * @param {Date|string|number} date - The date to format. It can be a Date object, a date string, or a Unix timestamp.
 * @param {string} [format=DATE_FORMAT_DDMONYYYY] - The format string specifying how the date should be formatted.
 *                                                 The format follows the dayjs library's format syntax.
 *                                                 Defaults to 'DD MMM YYYY' if not provided.
 * @returns {string} The formatted date as a string.
 */
export const formatDate = (date: Date | string | number, format: string = DATE_FORMAT_DDMONYYYY): string => {
  return dayjs(date).format(format);
};

/**
 * Formats a date string from the format 'YYYY-MM-DDTHH:mm:ss.sssZ' to 'MM/DD/YYYY'.
 *
 * @param {string} date - The date string in ISO format (e.g., 'YYYY-MM-DDTHH:mm:ss.sssZ').
 * @returns {string} - The formatted date string in 'MM/DD/YYYY' format.
 *
 * @example
 * const formattedDate = getFormattedStringDate('2024-09-01T12:34:56.789Z');
 * console.log(formattedDate); // Outputs: '09/01/2024'
 */
export const getFormattedStringDate = (date: string): string => {
  const dateAndTimezone = date.split('T');
  const splitedDate = dateAndTimezone[0].split('-');
  return splitedDate[1] + '/' + splitedDate[2] + '/' + splitedDate[0];
};

/**
 * Formats a date-time string by splitting the date and time components and optionally adding a comma between them.
 *
 * @param {string} dateTime - The date-time string in ISO format (e.g., 'YYYY-MM-DDTHH:mm:ss.sssZ').
 * @param {boolean} useComma - Whether to include a comma between the date and time.
 * @returns {string} - The formatted date-time string, or ' - ' if the input is undefined.
 *
 * @example
 * const formattedDateTime = getDataTime('2024-09-01T12:34:56.789Z', true);
 * console.log(formattedDateTime); // Outputs: '2024-09-01, 12:34:56'
 */
export const getDataTime = function (dateTime?: string, useComma: boolean = false): string {
  if (!dateTime) {
    return ' - ';
  }

  const [datePart, timePart] = dateTime.split('T');
  const formattedTime = timePart.split('.')[0];

  return useComma ? `${datePart}, ${formattedTime}` : `${datePart} ${formattedTime}`;
};

/**
 * Returns today's date formatted as 'DD-MM-YYYY'.
 *
 * @returns {string} - The formatted date string representing today's date.
 *
 * @example
 * const today = getTodayDate();
 * console.log(today); // Outputs: '01-09-2024' (depending on the current date)
 */
export const getTodayDate = function (): string {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
};
