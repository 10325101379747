import styled from 'styled-components';

export const CourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  #required-course {
    color: #e94a4a;
    padding: 6px;
    font-size: 14px;
  }
`;

export const CourseStatus = styled.div`
  display: flex;
  gap: 9px;
  color: ${(props) => (props.color === 'Completed' ? '#27AE60' : props.color === 'In Progress' ? '#828282' : null)};
  font-size: 15px;
  height: 21px;
`;

// export const = styled.div``;
