import { Form, InputGroup, FormControl } from 'react-bootstrap';
import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../Loader';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { Container, PasswordChecklistContainer } from './styles';
import './ResetPasswordCognito.scss';
import { TangiButton, TangiAlert } from '../TangiLibrary';
import { FormContainer } from '../LoginCognito/styles';
import PasswordChecklist from '_components/PasswordChecklist/PasswordChecklist';
import { AppRoutes } from 'routing/consts';

function ResetPasswordCognito() {
  const { forceResetPassword, confirmForgotPassword } = useContext(AuthContext);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get('username');
  const verCode = params.get('confirmation_code');

  function togglePasswordVisiblity() {
    setPasswordShown(!passwordShown);
  }
  function togglePasswordVisiblityNew() {
    setNewPasswordShown(!newPasswordShown);
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    if (password === confirmPassword) {
      try {
        const resetFunction = username && verCode ? confirmForgotPassword : forceResetPassword;
        setSpinner(true);
        await resetFunction(password, verCode, username);
        setSpinner(false);
        setAlertProps({ show: true, text: 'Success!, You will now be transfered to login with your new password', type: 'success' });
        setTimeout(() => {
          history.push(AppRoutes.LOGIN);
        }, 3000);
      } catch (error) {
        setSpinner(false);
        setAlertProps({ show: true, text: error.toString(), type: 'error' });
      }
    } else {
      setAlertProps({ show: true, text: 'New Password and Confirm Password are not same', type: 'error' });
    }
  };

  return (
    <Container>
      <Loader isLoading={isSpinner} />
      <h2 className="text-dark font-weight-normal">Enter Your New Password</h2>
      <Form style={{ width: '100%' }}>
        <FormContainer>
          <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
            <Form.Label className="text-dark fw-600 txt-size">New Password</Form.Label>
            <InputGroup>
              <FormControl
                name="password"
                onKeyUp={handleKeypress}
                onChange={(e) => setPassword(e.target.value)}
                type={newPasswordShown ? 'text' : 'password'}
                placeholder="New Password"
                aria-label="Password"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <button onClick={togglePasswordVisiblityNew} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btn btn-secondary">
                  <i className={`fas ${newPasswordShown ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true" />
                </button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <PasswordChecklistContainer>
            <PasswordChecklist rules={['minLength', 'number', 'lowercase']} minLength={12} value={password} valueAgain={''} onChange={() => {}} />
          </PasswordChecklistContainer>
          <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
            <Form.Label className="text-dark fw-600 txt-size">Confirm New Password</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                onKeyUp={handleKeypress}
                onChange={(e) => setConfirmPassword(e.target.value)}
                name="confirmPassword"
                type={passwordShown ? 'text' : 'password'}
                placeholder="Confirm New Password"
                aria-label="Password"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text id="basic-addon2">
                <button onClick={togglePasswordVisiblity} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btn btn-secondary">
                  <i className={`fas ${passwordShown ? 'fa-eye' : 'fa-eye-slash'}`} aria-hidden="true" />
                </button>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <TangiButton text="Reset Password" onClick={handleSubmit} onKeyPress={handleKeypress} type="button" />
          <TangiAlert {...alertProps} className="error-alert-login" />
        </FormContainer>
      </Form>
    </Container>
  );
}

export { ResetPasswordCognito };
