/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';
import assetMetaDataAdapter from 'redux-toolkit/adapters/assetMetaDataAdapter';
import usersAdapter from 'redux-toolkit/adapters/usersAdapter';
import { assetMetaDataCreatableParams, assetMetaDataParams } from 'utils/types/assetMetaData/assetMetaData';
import { countries } from 'countries-list';
import { AssetMetaDataValue } from 'utils/types/assets/assets';

export const createTag = createAsyncThunk('/assetMetaData/createTag', async (tag: assetMetaDataCreatableParams) => {
  const res: any = await assetMetaDataAdapter.createTag(tag);
  return res ?? null;
});

export const createProject = createAsyncThunk('/assetMetaData/createProject', async (project: assetMetaDataCreatableParams) => {
  const res: any = await assetMetaDataAdapter.createProject(project);
  return res ?? null;
});

export const createDepartment = createAsyncThunk('/assetMetaData/createDepartment', async (department: assetMetaDataCreatableParams) => {
  const res: any = await assetMetaDataAdapter.createDepartment(department);
  return res ?? null;
});

export const createAssetType = createAsyncThunk('/assetMetaData/createAssetType', async (assetType: assetMetaDataCreatableParams) => {
  const res: any = await assetMetaDataAdapter.createAssetType(assetType);
  return res ?? null;
});

export const createBusinessValue = createAsyncThunk('/assetMetaData/createBusinessValue', async (businessValue: assetMetaDataCreatableParams) => {
  const res: any = await assetMetaDataAdapter.createBusinessValue(businessValue);
  return res ?? null;
});

export const createBusinessUnit = createAsyncThunk('/assetMetaData/createBusinessUnit', async (businessUnit: assetMetaDataCreatableParams) => {
  const res: any = await assetMetaDataAdapter.createBusinessUnit(businessUnit);
  return res ?? null;
});

export const getAuthors = createAsyncThunk('/assetMetaData/getAuthors', async (clientId: string) => {
  const res: any = await usersAdapter.getAuthors(clientId);
  return res ?? null;
});

export const getAssetMetaData = createAsyncThunk('/assetMetaData/getAssetMetaData', async (clientId: string) => {
  const [authors, tags, assetTypes, businessValues, projects, departments, businessUnits, metaData] = await Promise.all([
    usersAdapter.getAuthors(clientId),
    assetMetaDataAdapter.getTags(clientId),
    assetMetaDataAdapter.getAssetTypes(clientId),
    assetMetaDataAdapter.getBusinessValues(clientId),
    assetMetaDataAdapter.getProjects(clientId),
    assetMetaDataAdapter.getDepartments(clientId),
    assetMetaDataAdapter.getBusinessUnits(clientId),
    assetMetaDataAdapter.getMetaData(clientId),
  ]);
  const returnCountries = Object.entries(countries).map((country) => {
    return { id: country[0], name: country[1].name };
  });

  return { authors, tags, assetTypes, businessValues, projects, departments, businessUnits, returnCountries, metaData };
});

export const getAllRecipients = createAsyncThunk('/assetMetaData/getAllRecipients', async () => {
  const res: any = await assetMetaDataAdapter.getAllRecipients();
  return res ?? null;
});

export const createAssetMetaData = createAsyncThunk('/assetMetaData/createAssetMetaData', async (assetMetaData: assetMetaDataParams, { rejectWithValue }) => {
  const res: AssetMetaDataValue = await assetMetaDataAdapter.createAssetMetaData(assetMetaData);
  if (res.newRecords === '') {
    return rejectWithValue('Duplicate name value');
  }
  return res ?? null;
});
