import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  color: #202020;
  width: 100%;
  font-family: 'DIN2014-Regular';
`;

export const SoftLinesContainer = styled.div`
  font-size: 14px;

  color: #00000080;
  display: flex;
  justify-content: center;
`;

export const SoftLine = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;

  .error-alert-login {
    margin-top: auto;
  }
  .password-input {
    margin-top: 8px;
  }
  .submit-button {
    margin-top: 24px;
  }
`;
