import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const ChipContentContainer = styled(FlexContainer)`
  gap: ${SPACING[1]};
  display: flex;
  align-items: flex-start;
  text-align: center;
  span {
    margin-top: 2px;
  }
`;
