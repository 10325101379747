import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';

import { SECONDARY_SHADES, SPACING } from 'utils/theme';

export const Container = styled.div`
  padding: ${SPACING[7]} 0;
  width: 942px;
  margin: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  margin-bottom: ${SPACING[6]};
`;

export const Cards = styled.div`
  display: flex;
  gap: ${SPACING[4]};
  flex-wrap: wrap;
`;

export const BannersContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[4]};
  margin-bottom: ${SPACING[4]};
`;

export const StyledSnackbar = styled.div`
  width: 100%;
  background: ${SECONDARY_SHADES[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[2.5]};
  height: 48px;
`;
