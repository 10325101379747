import { JOB_STATUS } from 'utils/enums';

export enum ADDITIONAL_XRAY_STATUS {
  FINDER_SUCCEEDED = 'finder succeeded',
}

export enum XRAY_JOB_STATUS {
  SUCCEEDED = JOB_STATUS.SUCCEEDED,
  FAILED = JOB_STATUS.FAILED,
  STARTED = JOB_STATUS.STARTED,
  FINDER_SUCCEEDED = ADDITIONAL_XRAY_STATUS.FINDER_SUCCEEDED,
  BLANK = JOB_STATUS.BLANK,
}

export enum XRAY_SOURCE_TYPE {
  FILE = 'file',
  GOOGLE = 'google',
  TEXT = 'text',
  BLANK = '',
}

// Base Xray Data type
export type XRayData = {
  status: XRAY_JOB_STATUS;
  details: string;
  created_date: string;
  source_type: XRAY_SOURCE_TYPE;
  account: string;
  client: string;
  filename: string | null;
};

export type XrayLoaders = {
  startLoader: boolean;
  getResultLoader: boolean;
};

export enum XRAY_ERROR_MESSAGES {
  INVALID_DOC_TYPE = 'Invalid document type',
  NO_FILE = 'No file sent',
  INVALID_FILE = 'Invalid file sent',
  DOC_ID_NOT_FOUND = 'Doc ID not found', //no results found for this search id
  INVALID_ACCOUND_ID = 'Invalid account ID',

  // the input is too long for the model and can't be splittend
  INPUT_TOO_LONG = 'Input too long',
  CLAIM_TOO_LONG = 'Claim too long',

  // no claims inside the document or no claim title
  CLAIMS_NOT_FOUND = 'Claims not found',
  NO_CLAIMS_IN_PATENT = 'Patent does not contain claims',

  ID_NOT_FOUND = 'ID not found', //patent id not found on Google patents
  INVALID_ID = 'Invalid ID', //patent id not a valid patent id
  BAD_AI_ANSWER = 'The AI was unable to produce a satisfactory response',
  OPEN_AI_UNAVAILABLE = 'OpenAI server is not available',
  DESCRIPTION_NOT_FOUND = 'Description not found',
  PRIVATE_MODEL_NOT_AVAILABLE = 'Private model is not available',
  INVALID_INPUT = 'Invalid input', //input is too short
}
export type WithIsAssetCreated<T> = T & { isAssetCreated: boolean };
