import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import { NEUTRAL_SHADES, PRIMARY_SHADES } from '../../utils/theme';

export const ButtonLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 20px;
  font-size: 14px;
`;

export const StyledDropdownButton = styled.button`
  outline: none;
  background: none;
  border: none;
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  ${({ isActive = false }) => css`
    font-size: 14px;
    background: ${isActive && PRIMARY_SHADES[200]};
    &:hover {
      background-color: ${isActive ? PRIMARY_SHADES[200] : NEUTRAL_SHADES[100]};
    }
    &:focus {
      background-color: ${NEUTRAL_SHADES[100]};
      color: ${NEUTRAL_SHADES.BLACK};
    }
  `}
`;
