import { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { loadStateFromLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';
import { AppRoutes } from 'routing/consts';

interface Props extends RouteProps {
  component: FunctionComponent<RouteProps>;
  unauthenticatedOnly?: boolean;
}

const PublicRoute = ({ component: Component, unauthenticatedOnly = false, ...rest }: Props) => {
  const isLoggedIn = !!loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && unauthenticatedOnly ? (
          // If the user is logged in and the route is accessible only for the authenticated users - redirect to the main page
          <Redirect to={{ pathname: AppRoutes.MAIN, state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
