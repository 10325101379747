import { useMemo } from 'react';
import { BarTooltipProps, ResponsiveBar } from '@nivo/bar';
import { Margin } from '@nivo/core';
import { AxisProps } from '@nivo/axes';

import { StackedBarChartDataObject, filterDataKeys } from '../Widgets/AssetsOverTime/assetsOverTime.logic';
import { BAR_CHART_CONSTS } from 'pages/ClientDashboard/consts';
import { aggregateData, getLegends } from './utils';
import { NEUTRAL_SHADES } from 'utils/theme';
import useBarChartTooltipHandler from './useBarChartTooltipHandler';
import { StyledTippy } from './style';

export interface Props {
  data: StackedBarChartDataObject[];
  keys: string[];
  margin?: Partial<Margin>;
  enableLabel?: boolean;
  tooltip?: (props: BarTooltipProps<StackedBarChartDataObject>) => React.ReactElement;
  padding?: number;
  labelTextColor?: string;
  labelSkipHeight?: number;
  labelFontSize?: number;
  legendTicksFontSize?: number;
  legendTextFontSize?: number;
  axisBottom?: AxisProps;
  maxItemsPerLegend?: number;
  legendItemWidth?: number;
  maxLegendItemtTextLength?: number;
  isLegend?: boolean;
  legendTranslateX?: number;
  legendTranslateY?: number;
}

const ResponsiveStackedbarChart = ({
  data,
  keys,
  margin = { top: 80, right: 20, bottom: 110, left: 60 },
  padding = 0.45,
  enableLabel = false,
  labelTextColor = NEUTRAL_SHADES.BLACK,
  labelSkipHeight = 14,
  labelFontSize = 12,
  legendTicksFontSize = 12,
  legendTextFontSize = 12,
  axisBottom = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: 32,
  },
  maxItemsPerLegend = BAR_CHART_CONSTS.MAX_ITEMS_PER_LEGEND,
  maxLegendItemtTextLength = BAR_CHART_CONSTS.MAX_LEGEND_ITEM_TEXT_LENGTH,
  legendItemWidth = BAR_CHART_CONSTS.LEGEND_ITEM_WIDTH,
  isLegend = true,
  legendTranslateX = -28,
  legendTranslateY = 55,
  tooltip,
}: Props) => {
  const legends = useMemo(() => {
    if (!isLegend) return [];
    const dataKeys = data.map((item) => filterDataKeys(item));
    const aggregatedDataKeys = aggregateData(dataKeys);

    return getLegends({ legendKeys: aggregatedDataKeys, maxItemsPerLegend, legendItemWidth, maxLegendItemtTextLength, legendTranslateX, legendTranslateY });
  }, [data]);

  const { tooltipContent, tippyRef, handleMouseEnter, handleMouseLeave } = useBarChartTooltipHandler({ keys });
  const isToolTipVisible: boolean = !!tooltipContent;
  const defaultToolTip = !tooltip && (
    <StyledTippy content={tooltipContent} visible={isToolTipVisible} interactive={true} animation="fade" theme="custom" placement="auto">
      <div ref={tippyRef} />
    </StyledTippy>
  );

  return (
    <>
      {defaultToolTip}
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="label"
        margin={margin}
        padding={padding}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => String(data[`${id}Color`])}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
          format: (e) => Math.floor(e) === e && e,
        }}
        enableGridX={true}
        enableLabel={enableLabel}
        labelSkipHeight={labelSkipHeight}
        labelTextColor={labelTextColor}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: NEUTRAL_SHADES.WHITE,
              },
              text: {
                fill: NEUTRAL_SHADES[700],
              },
            },
          },
          grid: {
            line: {
              stroke: NEUTRAL_SHADES[200],
            },
          },
          labels: {
            text: {
              fontSize: labelFontSize,
            },
          },
          legends: {
            text: {
              fontSize: legendTextFontSize,
              fontFamily: 'DIN2014-Regular',
            },
            ticks: {
              text: {
                fontSize: legendTicksFontSize,
              },
            },
          },
        }}
        tooltip={tooltip || (() => <></>)}
        ariaLabel="Bar Chart"
        isInteractive={true}
        legends={isLegend ? legends : undefined}
        onMouseEnter={(barData, event) => handleMouseEnter(barData, event)}
        onMouseLeave={handleMouseLeave}
      />
    </>
  );
};

export default ResponsiveStackedbarChart;
