import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

import { FlexContainer } from 'utils/globalStyles';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const StyledBodyContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[3.5]};
  gap: ${SPACING[3]};
  width: 100%;
  height: 100%;
`;

export const StyledSpinner = styled(Spinner)`
  color: ${PRIMARY_SHADES[600]};
  height: 50px;
  width: 50px;
  margin-bottom: ${SPACING[3.5]};
`;

export const headerCustomStyles = 'text-align: center';

export const descriptionCustomStyles = 'text-align: center;';
