import styled, { css } from 'styled-components';
import { Form } from 'react-bootstrap';

import { typeDictionary, TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { DuoContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { TangiTypography } from '_components/TangiLibrary';

const textAreaFontSize = typeDictionary[TYPOGRAPHY_TYPES.BODY];

export const StyledForm = styled(Form)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

export const TextAreaContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled = false }) => css`
    display: flex;
    flex-direction: column;
    border: 1px solid ${NEUTRAL_SHADES[600]};
    border-radius: ${SPACING[1]};
    padding: ${SPACING[1]} ${SPACING[1]} ${SPACING[0]} ${SPACING[2]};
    background-color: ${disabled ? NEUTRAL_SHADES[50] : NEUTRAL_SHADES.WHITE};
    color: ${NEUTRAL_SHADES.BLACK};
  `}
`;

export const StyledTextArea = styled.textarea`
  resize: none;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  border: none;
  font-size: ${textAreaFontSize.size};
  line-height: ${textAreaFontSize.line};
  padding-right: ${SPACING[3]};
  margin-bottom: ${SPACING[2]};
  font-family: 'DIN2014-Regular';

  :disabled {
    background-color: transparent;
    color: ${NEUTRAL_SHADES[600]};
  }

  ::-webkit-scrollbar {
    width: ${SPACING[1]};
  }
  ::-webkit-scrollbar-track {
    border-radius: ${SPACING[1]};
    margin-block: ${SPACING[1]} ${SPACING[2]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[600]};
    border-radius: ${SPACING[1]};
    cursor: default;
  }

  ::placeholder {
    color: ${NEUTRAL_SHADES[800]};
    font-weight: 400;
    font-family: 'DIN2014-Regular';
  }
`;

export const TextAreaFooter = styled(DuoContainer)`
  padding: ${SPACING[0]} ${SPACING[1]} ${SPACING[2]} ${SPACING[0]};
  justify-content: space-between;

  .tangi-typography {
    align-self: flex-end;
  }
`;

export const StyledTangiTypography = styled(TangiTypography)`
  display: flex;
`;
