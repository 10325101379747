import React, { CSSProperties } from 'react';

import { TangiIconButton } from '../TangiIconButton';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { BodyContainer, FooterContainer, HeaderContainer, ModalInnerContainer, StyledModal } from './style';

interface SlotStyles {
  style?: React.CSSProperties;
  className?: string;
}

interface Props {
  show: boolean;
  onHide: () => void;
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  containerStyle?: CSSProperties;
  slotsStyles?: {
    bodyContainer?: SlotStyles;
    headerContainer?: SlotStyles;
    footerContainer?: SlotStyles;
  };
  dataTestId?: string;
  isCloseButton?: boolean;
}

export const TangiModal = ({ show, onHide, header, body, footer, containerStyle, slotsStyles, dataTestId, isCloseButton = true }: Props) => {
  const isHeader = header || isCloseButton;
  return (
    <StyledModal dialogClassName="tangi-modal-centered" backdropClassName="tangi-modal-backdrop" contentClassName="tangi-modal-content" show={show} onHide={onHide} data-testid={`modal-${dataTestId}`}>
      <ModalInnerContainer style={containerStyle}>
        {isHeader && (
          <HeaderContainer style={slotsStyles?.headerContainer?.style} className={slotsStyles?.headerContainer?.className}>
            {header}
            {isCloseButton && <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={onHide} icon="close" />}
          </HeaderContainer>
        )}

        <BodyContainer className={`tangi-modal-body ${slotsStyles?.bodyContainer?.className || ''}`} style={slotsStyles?.bodyContainer?.style}>
          {body}
        </BodyContainer>

        {footer && (
          <FooterContainer style={slotsStyles?.footerContainer?.style} className={slotsStyles?.footerContainer?.className}>
            {footer}
          </FooterContainer>
        )}
      </ModalInnerContainer>
    </StyledModal>
  );
};

export default TangiModal;
