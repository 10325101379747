export const adminCourseContants = {
  ADMIN_COURSE_REQUEST: 'ADMIN_COURSE_REQUEST',
  ADMIN_COURSE_SUCCESS: 'ADMIN_COURSE_SUCCESS',
  ADMIN_COURSE_FAILURE: 'ADMIN_COURSE_FAILURE',
  ADMIN_COURSE_ID_REQUEST: 'ADMIN_COURSE_ID_REQUEST',
  ADMIN_COURSE_ID_SUCCESS: 'ADMIN_COURSE_ID_SUCCESS',
  ADMIN_COURSE_ID_FAILURE: 'ADMIN_COURSE_ID_FAILURE',
  ADMIN_COURSE_DELETE_REQUEST: 'ADMIN_COURSE_DELETE_REQUEST',
  ADMIN_COURSE_DELETE_SUCCESS: 'ADMIN_COURSE_DELETE_SUCCESS',
  ADMIN_COURSE_DELETE_FAILURE: 'ADMIN_COURSE_DELETE_FAILURE',
  ADMIN_CATEGORY_REQUEST: 'ADMIN_CATEGORY_REQUEST',
  ADMIN_CATEGORY_SUCCESS: 'ADMIN_CATEGORY_SUCCESS',
  ADMIN_CATEGORY_FAILURE: 'ADMIN_CATEGORY_FAILURE',
  ADMIN_CREATE_COURSE: 'ADMIN_CREATE_COURSE',
  ADMIN_CREATE_COURSE_SUCCESS: 'ADMIN_CREATE_COURSE_SUCCESS',
  ADMIN_CREATE_COURSE_FAILURE: 'ADMIN_CREATE_COURSE_FAILURE',
  ADMIN_UPDATE_COURSE: 'ADMIN_UPDATE_COURSE',
  ADMIN_UPDATE_COURSE_SUCCESS: 'ADMIN_UPDATE_COURSE_SUCCESS',
  ADMIN_UPDATE_COURSE_FAILURE: 'ADMIN_UPDATE_COURSE_FAILURE'

};
