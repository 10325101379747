export const adminAssetContants = {
  ADMIN_ASSET_REQUEST: 'ADMIN_ASSET_REQUEST',
  ADMIN_ASSET_SUCCESS: 'ADMIN_ASSET_SUCCESS',
  ADMIN_ASSET_FAILURE: 'ADMIN_ASSET_FAILURE',
  ADMIN_ASSET_TYPE_REQUEST: 'ADMIN_ASSET_TYPE_REQUEST',
  ADMIN_ASSET_TYPE_SUCCESS: 'ADMIN_ASSET_TYPE_SUCCESS',
  ADMIN_ASSET_TYPE_FAILURE: 'ADMIN_ASSET_TYPE_FAILURE',
  ADMIN_ASSET_STATUS_REQUEST: 'ADMIN_ASSET_STATUS_REQUEST',
  ADMIN_ASSET_STATUS_SUCCESS: 'ADMIN_ASSET_STATUS_SUCCESS',
  ADMIN_ASSET_STATUS_FAILURE: 'ADMIN_ASSET_STATUS_FAILURE',
  ADMIN_ASSET_BUSINESS_REQUEST: 'ADMIN_ASSET_BUSINESS_REQUEST',
  ADMIN_ASSET_BUSINESS_SUCCESS: 'ADMIN_ASSET_BUSINESS_SUCCESS',
  ADMIN_ASSET_BUSINESS_FAILURE: 'ADMIN_ASSET_BUSINESS_FAILURE',
  ADMIN_ASSET_TAGS_REQUEST: 'ADMIN_ASSET_TAGS_REQUEST',
  ADMIN_ASSET_TAGS_SUCCESS: 'ADMIN_ASSET_TAGS_SUCCESS',
  ADMIN_ASSET_TAGS_FAILURE: 'ADMIN_ASSET_TAGS_FAILURE',

  ADMIN_ASSET_TAGS_EDIT_REQUEST: 'ADMIN_ASSET_TAGS_EDIT_REQUEST',
  ADMIN_ASSET_TAGS_EDIT_SUCCESS: 'ADMIN_ASSET_TAGS_EDIT_SUCCESS',
  ADMIN_ASSET_TAGS_EDIT_FAILURE: 'ADMIN_ASSET_TAGS_EDIT_FAILURE',
  ADMIN_ASSET_TAGS_DELETE_REQUEST: 'ADMIN_ASSET_TAGS_DELETE_REQUEST',
  ADMIN_ASSET_TAGS_DELETE_SUCCESS: 'ADMIN_ASSET_TAGS_DELETE_SUCCESS',
  ADMIN_ASSET_TAGS_DELETE_FAILURE: 'ADMIN_ASSET_TAGS_DELETE_FAILURE',
  ADMIN_ASSET_TAGS_VIEW_REQUEST: 'ADMIN_ASSET_TAGS_VIEW_REQUEST',
  ADMIN_ASSET_TAGS_VIEW_SUCCESS: 'ADMIN_ASSET_TAGS_VIEW_SUCCESS',
  ADMIN_ASSET_TAGS_VIEW_FAILURE: 'ADMIN_ASSET_TAGS_VIEW_FAILURE',

  ADMIN_ASSET_TYPE_EDIT_REQUEST: 'ADMIN_ASSET_TYPE_EDIT_REQUEST',
  ADMIN_ASSET_TYPE_EDIT_SUCCESS: 'ADMIN_ASSET_TYPE_EDIT_SUCCESS',
  ADMIN_ASSET_TYPE_EDIT_FAILURE: 'ADMIN_ASSET_TYPE_EDIT_FAILURE',
  ADMIN_ASSET_TYPE_DELETE_REQUEST: 'ADMIN_ASSET_TYPE_DELETE_REQUEST',
  ADMIN_ASSET_TYPE_DELETE_SUCCESS: 'ADMIN_ASSET_TYPE_DELETE_SUCCESS',
  ADMIN_ASSET_TYPE_DELETE_FAILURE: 'ADMIN_ASSET_TYPE_DELETE_FAILURE',
  ADMIN_ASSET_TYPE_VIEW_REQUEST: 'ADMIN_ASSET_TYPE_VIEW_REQUEST',
  ADMIN_ASSET_TYPE_VIEW_SUCCESS: 'ADMIN_ASSET_TYPE_VIEW_SUCCESS',
  ADMIN_ASSET_TYPE_VIEW_FAILURE: 'ADMIN_ASSET_TYPE_VIEW_FAILURE',

  ADMIN_ASSET_BV_EDIT_REQUEST: 'ADMIN_ASSET_BV_EDIT_REQUEST',
  ADMIN_ASSET_BV_EDIT_SUCCESS: 'ADMIN_ASSET_BV_EDIT_SUCCESS',
  ADMIN_ASSET_BV_EDIT_FAILURE: 'ADMIN_ASSET_BV_EDIT_FAILURE',
  ADMIN_ASSET_BV_DELETE_REQUEST: 'ADMIN_ASSET_BV_DELETE_REQUEST',
  ADMIN_ASSET_BV_DELETE_SUCCESS: 'ADMIN_ASSET_BV_DELETE_SUCCESS',
  ADMIN_ASSET_BV_DELETE_FAILURE: 'ADMIN_ASSET_BV_DELETE_FAILURE',
  ADMIN_ASSET_BV_VIEW_REQUEST: 'ADMIN_ASSET_BV_VIEW_REQUEST',
  ADMIN_ASSET_BV_VIEW_SUCCESS: 'ADMIN_ASSET_BV_VIEW_SUCCESS',
  ADMIN_ASSET_BV_VIEW_FAILURE: 'ADMIN_ASSET_BV_VIEW_FAILURE',

  ADMIN_ASSET_CREATE: 'ADMIN_ASSET_CREATE',
  ADMIN_ASSET_CREATE_SUCCESS: 'ADMIN_ASSET_CREATE_SUCCESS',
  ADMIN_ASSET_CREATE_FAILURE: 'ADMIN_ASSET_CREATE_FAILURE',

  ADMIN_ASSET_SHARE_SUCCESS: 'ADMIN_ASSET_SHARE_SUCCESS',
  ADMIN_ASSET_SHARE_FAILURE: 'ADMIN_ASSET_SHARE_FAILURE',

  ADMIN_ASSET_FILE_SUCCESS: 'ADMIN_ASSET_FILE_SUCCESS',
  ADMIN_ASSET_FILE_FAILURE: 'ADMIN_ASSET_FILE_FAILURE',

  GET_ASSET_REQUEST: 'GET_ASSET_REQUEST',
  GET_ASSET_SUCCESS: 'GET_ASSET_SUCCESS',
  GET_ASSET_FAILURE: 'GET_ASSET_FAILURE',

  ADMIN_ASSET_UPDATE: 'ADMIN_ASSET_UPDATE',
  ADMIN_ASSET_UPDATE_SUCCESS: 'ADMIN_ASSET_UPDATE_SUCCESS',
  ADMIN_ASSET_UPDATE_FAILURE: 'ADMIN_ASSET_UPDATE_FAILURE',
};
