import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { Placement } from 'tippy.js';

import { ChipType, TangiChip, TangiTypography } from '_components/TangiLibrary';
import ClientOrPartnershipNameAndLogo from './components/ClientOrPartnershipNameAndLogo';
import { Asset } from 'utils/types/assets/assets';
import { Partnership } from 'utils/types/partnership/partnership';
import { Client } from 'utils/types/client/client';
import { IRoles } from 'utils/roles';
import { NEUTRAL_SHADES } from 'utils/theme';
import { ChipContainer, ContentContainer } from './style';
import { RootState } from '_helpers';

interface Props {
  labelText?: string;
  asset: Asset;
  variant?: ChipType;
  placement?: Placement;
}

const ThirdPartyTag = ({ labelText = 'ASSET_PAGE.TOOLTIP.THIRD_PARTY', asset, variant = 'tag', placement = 'top' }: Props) => {
  const Role = useSelector((state: RootState) => state.authentication.Role);

  const isPartner = useMemo(() => Role === IRoles.PARTNER, [Role]);

  const { t } = useTranslation();

  const renderThirdPartyTooltip = () => {
    const partnership = asset?.createdBy?.partnership as Partnership;
    const client = asset?.createdBy?.client as Client;

    return (
      <ContentContainer>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t(isPartner ? 'ASSET_PAGE.TOOLTIP.ASSET_CREATED_BY_CLIENT' : 'ASSET_PAGE.TOOLTIP.ASSET_CREATED_BY_PARTNER')}</TangiTypography>
        <ClientOrPartnershipNameAndLogo clientOrPartnership={isPartner ? client : partnership} />
      </ContentContainer>
    );
  };

  return (
    <Tippy disabled={!asset?.isThirdParty} render={() => renderThirdPartyTooltip()} trigger="mouseenter" animation={false} interactive={true} placement={placement}>
      <ChipContainer>{asset?.isThirdParty && <TangiChip text={t(labelText)} variant={variant} />}</ChipContainer>
    </Tippy>
  );
};

export default ThirdPartyTag;
