import styled, { css } from 'styled-components';

import { ReactComponent as DiscoveryIcon } from '../../../../assets/tangiIcons/discovery.svg';
import { NEUTRAL_SHADES, PRIMARY_SHADES, ERROR_SHADES, SPACING } from '../../../../utils/theme';
import { StyledAssetCard, AssetCardFooter } from '../../style';

// ----- Acknowledged asset card ----- //

export const StyledActiveAssetCard = styled(StyledAssetCard)`
  ${({ permissionView }) => css`
    background: ${NEUTRAL_SHADES.WHITE};
    :hover {
      background: ${permissionView && PRIMARY_SHADES[50]};
      box-shadow: ${permissionView && '0px 3px 16px rgba(0, 0, 0, 0.12)'};
    }
  `}
`;

export const Divider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[500]};
  margin: 0;
`;

export const AssetNumber = styled.span`
  color: ${NEUTRAL_SHADES[800]};
  margin-block: 3px;
  padding-left: 10px;
  border-left: 1px solid ${NEUTRAL_SHADES[500]};
`;

export const AssetTagsContainer = styled.div`
  font-family: 'DIN2014-Regular';
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  span:nth-of-type(1),
  span:nth-of-type(2) {
    padding-right: 10px;
    white-space: nowrap;
  }
  span:nth-of-type(1) {
    text-overflow: ellipsis;
    overflow: hidden;
    width: minmax(200px, 50%);
  }
`;

export const RecipientNumber = styled.div`
  ${({ allAcknowledged }) => css`
    font-family: 'DIN2014-DemiBold';
    color: ${allAcknowledged ? NEUTRAL_SHADES.BLACK : ERROR_SHADES[600]};
    cursor: default;
    span:first-of-type {
      margin-right: 4px;
    }
    span:nth-of-type(2) {
      font-family: 'DIN2014-Regular';
    }
  `}
`;

export const customAssetOptionsStyles = {
  divRefStyles: { position: 'relative' },
  dropdownStyles: { top: '36px', left: '-100px' },
};

export const ActiveCardFooter = styled(AssetCardFooter)`
  justify-content: space-between;
`;

export const StyledMagicIcon = styled(DiscoveryIcon)`
  margin-right: 8px;
  margin-bottom: 2px;
`;

export const ShareAssetContainer = styled.span`
  margin-left: auto;
`;

export const InActiveToolTopContainer = styled.span`
  justify-content: center;
  display: flex;
  gap: ${SPACING[1]};
`;
