import { HrProcessData } from '_components/IpAudit/types';

export const parseValuesToFormValues = (values: HrProcessData | null | undefined) => {
  const isProtocol = typeof values?.isIncorporateProtocolsConfidential === 'boolean';
  const isTraining = typeof values?.employeeTraining === 'boolean';
  return {
    ...(isProtocol && { protocol: JSON.stringify(values?.isIncorporateProtocolsConfidential) }),
    ...(isTraining && { training: JSON.stringify(values?.employeeTraining) }),
  };
};
