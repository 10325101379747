import { IdAndName } from '../types';

export interface User {
  _id?: string;
  email: string;
}

export interface Account {
  createdFrom: string;
  departments: IdAndName[];
  displayName: string;
  user: User;
  role?: IdAndName;
  status?: string;
  _id?: string;
  projects?: IdAndName[];
  tags?: IdAndName[];
  businessValues?: IdAndName[];
}

export interface AssetRawData {
  assetType: IdAndName;
  businessValues: IdAndName[];
  contributor: Account[];
  createdAt: string;
  createdBy?: Account;
  createdFrom: string;
  departments: IdAndName[];
  isActive: boolean;
  name: string;
  projects: IdAndName[];
  tags: IdAndName[];
  _id: string;
}

export enum FILTER_BY {
  ALL_DEPARTMENT = 'allDepartment',
  NO_DEPARTMENT = 'noDepartment',
}

export type FilterBy = FILTER_BY | string;

export interface AccountRawData {
  _id: string;
  status: string;
  departments: IdAndName[];
  createdFrom: string;
  role: {
    name: string;
    _id: string;
  };
  displayName: string;
  user: User;
}

export interface RecipientAccount extends Pick<Account, 'createdFrom' | 'displayName'> {
  _id: string;
  role: IdAndName;
}

export interface RecipientAsset extends Pick<AssetRawData, '_id' | 'name' | 'assetType'> {
  departments: string[];
  value?: number;
}
export interface SharedBy extends Pick<Account, 'departments' | 'createdFrom' | 'displayName' | 'user'> {
  _id: string;
  isActive: boolean;
  role: IdAndName;
  isAnotherClient: boolean;
  value?: number;
}

export interface RecipientsRawData {
  _id: string;
  account: RecipientAccount;
  email: string;
  sharedBy: SharedBy;
  acknowledged: boolean;
  asset: RecipientAsset;
}
