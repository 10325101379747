import { ErrorMessageConfig } from './types';
import { MIN_WORDS } from '../InventionDisclosureSearch/utils';
import { XRAY_ERROR_MESSAGES } from 'utils/types/xRay/xRay';

export const getXRayErrorMessage = (details: string, patentIdentifier?: string): ErrorMessageConfig => {
  const errorMessageMap: Record<string, ErrorMessageConfig> = {
    [XRAY_ERROR_MESSAGES.OPEN_AI_UNAVAILABLE]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_SATISFACTORY_RESULTS',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_SEARCHING_AGAIN',
      testId: 'no-satisfactory-results',
    },
    [XRAY_ERROR_MESSAGES.PRIVATE_MODEL_NOT_AVAILABLE]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_SATISFACTORY_RESULTS',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_SEARCHING_AGAIN',
      testId: 'no-satisfactory-results',
    },
    [XRAY_ERROR_MESSAGES.BAD_AI_ANSWER]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_SATISFACTORY_RESULTS',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_SEARCHING_AGAIN',
      testId: 'no-satisfactory-results',
    },
    [XRAY_ERROR_MESSAGES.ID_NOT_FOUND]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.WE_DID_NOT_FIND_PATENT',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_IN_CORRECT_FORMAT',
      testId: 'try-another-patent-message',
    },
    [XRAY_ERROR_MESSAGES.INVALID_ID]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.WE_DID_NOT_FIND_PATENT',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_IN_CORRECT_FORMAT',
      testId: 'try-another-patent-message',
    },
    [XRAY_ERROR_MESSAGES.NO_CLAIMS_IN_PATENT]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_CLAIMS',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_IN_CORRECT_FORMAT',
      testId: 'no-claims-message',
      boldTextParameters: { PATENT_ID: patentIdentifier || '' },
    },
    [XRAY_ERROR_MESSAGES.CLAIMS_NOT_FOUND]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_CLAIMS_IN_FILE',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.MAKE_SURE_FILE_CONTAINS_CLAIMS',
      testId: 'no-claims-file-message',
    },
    [XRAY_ERROR_MESSAGES.NO_FILE]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.PROBLEM_READING_FILE',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.PLEASE_UPLOAD_AGAIN',
      testId: 'no-file-message',
    },
    [XRAY_ERROR_MESSAGES.INVALID_DOC_TYPE]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.PROBLEM_READING_FILE',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.PLEASE_UPLOAD_ACCEPTED_FORMAT',
      testId: 'invalid-doc-type-message',
    },
    [XRAY_ERROR_MESSAGES.DOC_ID_NOT_FOUND]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_RESULTS_FOR_LATEST_SEARCH',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_SEARCHING_AGAIN',
      testId: 'no-doc-message',
    },
    [XRAY_ERROR_MESSAGES.DESCRIPTION_NOT_FOUND]: {
      boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.NO_DESCRIPTION_IN_DOCUMENT',
      text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_UPLOADING_DIFFERENT_DOCUMENT',
      testId: 'no-description-message',
    },
    [XRAY_ERROR_MESSAGES.INVALID_INPUT]: {
      boldText: 'DISCOVERY_LOBBY.INVENTION_DISCLOSURE_ERROR.TEXT_TOO_SHORT',
      text: 'DISCOVERY_LOBBY.INVENTION_DISCLOSURE_ERROR.WE_CAN_ONLY_SEARCH_MIN_WORDS',
      testId: 'input-too-short-message',
      textParameters: { MIN_WORDS },
    },
    // TODO: add the rest of the error messages for UI if necessary
    // [XRAY_ERROR_MESSAGES.INVALID_ACCOUND_ID]: {
    // },
    // [XRAY_ERROR_MESSAGES.INVALID_FILE]: {
    // },
    // [XRAY_ERROR_MESSAGES.INPUT_TOO_LONG]: {
    // },
    // [XRAY_ERROR_MESSAGES.CLAIM_TOO_LONG]: {
    // },
  };

  // Fallback
  const defaultErrorMessage: ErrorMessageConfig = {
    boldText: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.SOMETHING_WENT_WRONG',
    text: 'DISCOVERY_LOBBY.PATENT_NOTIFICATION_ERROR.TRY_SEARCHING_AGAIN',
    testId: 'something-wrong-message',
  };

  // Return the specific error message if it exists, otherwise return the fallback
  return errorMessageMap[details] ?? defaultErrorMessage;
};
