import { useState, ReactNode } from 'react';
import { Collapse } from 'react-bootstrap';
import { CSSObject } from 'styled-components';
import { isFunction } from 'lodash';

import { CollapseControlContainer } from './style';
import { TangiSvgIcon } from '../TangiSvgIcon';
import { NEUTRAL_SHADES } from 'utils/theme';

interface Props {
  label: ReactNode;
  content: ReactNode;
  labelStyles?: CSSObject;
  labelIconColor?: string;
  isOpen?: boolean;
  onToggle?: () => void;
}

export const TangiCollapse = ({ label, content, labelStyles, labelIconColor = NEUTRAL_SHADES.BLACK, isOpen, onToggle }: Props) => {
  const [internalOpen, setInternalOpen] = useState<boolean>(false);

  const open = isOpen !== undefined ? isOpen : internalOpen;

  const handleToggleClick = () => {
    if (isFunction(onToggle)) {
      onToggle();
    } else {
      setInternalOpen((prev) => !prev);
    }
  };

  return (
    <>
      <CollapseControlContainer className="tangi-collapse-control" labelStyles={labelStyles} onClick={handleToggleClick} data-testid="collapse-control">
        {label}
        <TangiSvgIcon component={open ? 'arrowUp' : 'arrowDown'} color={labelIconColor} />
      </CollapseControlContainer>

      <Collapse in={open} data-testid="collapse-item">
        <div data-testid="collapse-content">{content}</div>
      </Collapse>
    </>
  );
};
