import styled, { css } from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: ${SPACING[4]} ${SPACING[3.5]};
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING[4]};
  gap: ${SPACING[2]};
`;

export const ContentRow = styled.div<{ isWarning?: boolean }>`
  ${({ isWarning = false }) => css`
    display: flex;
    justify-content: space-between;
    gap: ${SPACING[3]};
    flex-grow: 1;
    height: ${isWarning ? 'calc(100vh - 320px)' : `calc(100vh - ${SPACING[10]})`};
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: sticky;
  position: -webkit-sticky; // Safari
  top: 0px;
`;

export const UrlLink = styled.a`
  width: fit-content;
  cursor: pointer;
  color: ${PRIMARY_SHADES[800]};
  font-size: 16px;
  line-height: 24px;
  font-family: 'DIN2014-Regular';
  text-decoration: underline;

  &:hover {
    color: ${PRIMARY_SHADES[800]};
  }
`;

// --------- SHARED STYLES ---------

export const StyledBulletTypography = styled(TangiTypography)<{ isAssociated?: boolean; isCurrentAssociation?: boolean }>`
  ${({ isAssociated = false, isCurrentAssociation = false }) => css`
    background-color: ${isCurrentAssociation ? PRIMARY_SHADES[300] : isAssociated ? PRIMARY_SHADES[200] : 'transparent'};
    border-radius: ${SPACING[1.5]};
    padding: ${SPACING[1]} ${SPACING[1.5]};
  `}}
`;
