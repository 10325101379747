import { TangiSvgIcon } from '_components/TangiLibrary';
import { CUSTOM_ASSET_TYPE, SHARE_POINT, LOCKED_ASSET, isCustomAssetType } from 'utils/assetTypes';

import { SPACING } from 'utils/theme';
import { ColorBlockContainer, SharePointIconContainer } from './style';

export const getAssetTypeForColorBlock = (assetType: string) => {
  if (!assetType.trim()) return '';

  if (assetType === LOCKED_ASSET) return LOCKED_ASSET;
  if (assetType === SHARE_POINT || !isCustomAssetType(assetType)) return assetType;

  return CUSTOM_ASSET_TYPE;
};

export const renderAssetTypeComponent = (assetTypeName: string): JSX.Element => {
  const assetTypeForColorBlock = getAssetTypeForColorBlock(assetTypeName);

  switch (assetTypeForColorBlock) {
    case SHARE_POINT:
      return (
        <SharePointIconContainer>
          <TangiSvgIcon component="sharePointAssetTypeIcon" size={SPACING[4]} />
        </SharePointIconContainer>
      );
    default:
      return (
        <ColorBlockContainer assetType={assetTypeForColorBlock}>
          <div />
          <div />
        </ColorBlockContainer>
      );
  }
};
