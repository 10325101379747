import { LANGUAGE, LANGUAGE_LABEL, languageOptions } from 'translations/enums';
import { Client } from 'utils/types/client/client';

interface AgreementOption {
  value?: string;
  label?: string;
  id?: string;
  name?: string;
}

export const defineClientLanguageValueAndLabel = (clientDetails: Client | undefined, isFirstTime: boolean) => {
  if (isFirstTime) {
    return { value: LANGUAGE.ENGLISH, label: LANGUAGE_LABEL.ENGLISH };
  }
  const value = clientDetails?.language || LANGUAGE.ENGLISH;
  const label = languageOptions.find((option) => option.value === value)?.label || LANGUAGE_LABEL.ENGLISH;
  return { value, label };
};

export const transformAgreementsData = (data: Record<string, AgreementOption[]>) => {
  const transformedData: Record<string, (string | undefined)[]> = {};
  for (const [key, value] of Object.entries(data)) {
    transformedData[key] = value.map((agreement) => agreement?.value ?? agreement?.id);
  }
  return transformedData;
};
