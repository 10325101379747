import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { BORDER_RADIUS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const StyledModal = styled(Modal)`
  .tangi-modal-centered {
    display: flex;
    align-items: center;
  }
  .tangi-modal-content {
    border-radius: ${BORDER_RADIUS[4]};
  }
  .tangi-modal-body::-webkit-scrollbar {
    width: 6px;
  }
  .tangi-modal-body::-webkit-scrollbar-track {
    background: ${NEUTRAL_SHADES.WHITE};
  }
  .tangi-modal-body::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[500]};
    border-radius: ${BORDER_RADIUS[4]};
  }
`;

export const ModalInnerContainer = styled.div`
  font-family: 'DIN2014-REGULAR';
  display: flex;
  flex-direction: column;
  font-size: ${SPACING[3]};
  color: ${NEUTRAL_SHADES.BLACK};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid ${NEUTRAL_SHADES[300]};
  border-top-left-radius: ${BORDER_RADIUS[4]};
  border-top-right-radius: ${BORDER_RADIUS[4]};
`;

export const BodyContainer = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: ${BORDER_RADIUS[4]};
  border-bottom-left-radius: ${BORDER_RADIUS[4]};
`;
