import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ExcelRenderer } from 'react-excel-renderer';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { createAssets, validateAssets } from 'redux-toolkit/thunks/assetsThunks';
import { TangiIconButton } from '../TangiLibrary';
import { excelExporters } from '../../utils/excelExporters';
import uploadLogo from '../../assets/img/CloudUpload.png';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { ErrorMessage, MessagesTitle, DownloadButton, ErrorContainer, DropZoneContainer, ModalContainer } from './style';

const AssetsValidationModal = ({ handleClose, clientId }) => {
  const acceptedFilesList = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const dispatch = useDispatch();
  const metaDataState = useSelector((state) => state.assetMetaData);
  const { uploadedBatchAssets, batchErrors, batchAssets, validatedBatch, batchLoading } = useSelector((state) => state.asset);
  const [progress, setProgress] = useState(0);
  const [noData, SetNoData] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (uploadedBatchAssets.length > 0) {
      handleClose();
    }
  }, [uploadedBatchAssets]);

  useEffect(() => {
    if (batchAssets.length === 0) {
      if (validatedBatch) SetNoData(true);
      setProgress(0);
    } else {
      checkUploadProgress();
    }
  }, [batchAssets]);

  const uploadData = () => {
    dispatch(createAssets({ batchAssets, clientId }));
    setProgress(80);
  };
  const checkUploadProgress = () => {
    if (batchAssets.length > 50) {
      setProgress(0);
    } else if (batchErrors.length === 0) {
      setProgress(80);
    } else {
      setProgress(50);
    }
  };
  const checkUploadStatus = () => {
    if (batchAssets?.length > 0 && batchAssets?.length < 51) {
      switch (batchErrors?.length) {
        case 0:
          if (!batchLoading) return 'AssetsSuccess';
          return 'UploadingAssets';
        default:
          if (!batchLoading) return 'AssetsWithErrors';
          break;
      }
    }
    return '';
  };
  const exportExcelAssets = () => {
    SetNoData(false);
    excelExporters.exportAssetsTemplate(metaDataState);
  };
  const onDrop = useCallback((acceptedFiles) => {
    SetNoData(false);
    const Assets = new Blob([acceptedFiles[0]]);
    ExcelRenderer(Assets, (err, resp) => {
      const assetsRows = resp.rows
        .slice(1)
        .map((row) => row.slice(0, 22))
        .filter((data) => data.find((value) => value));
      dispatch(validateAssets({ assetsRows, clientId }));
    });
  });
  const resetBatch = () => {
    SetNoData(false);
    dispatch(assetsActions.setResetBatchAssets());
    setProgress(0);
  };

  return (
    <ModalContainer>
      {/* temporary fix with inline styles */}
      <div className="pt-2 mb-2" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
        <div>
          <h2 className="ff-din-DemiBold ">{t('ASSET_PAGE.BATCH_UPLOAD.UPLOAD_MULTIPLE_ASSETS')}</h2>
          <h4 className="font-weight-normal text-dark ">{t('ASSET_PAGE.BATCH_UPLOAD.UP_TO_50_ASSETS')}</h4>
        </div>
        <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} disabled={batchLoading && batchAssets?.length > 0 && batchErrors?.length === 0} onClick={handleClose} />
      </div>
      <ProgressBar now={progress} />
      <br />
      {batchAssets?.length > 0 ? (
        batchAssets?.length > 50 ? (
          <ErrorMessage>{t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.CANT_UPLOAD_MORE_THAN_50')}</ErrorMessage>
        ) : (
          <MessagesTitle data-testid="batchMessage" valid={batchErrors?.length === 0}>
            {batchErrors?.length > 0
              ? `${t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.THERE_ARE')} ${batchErrors?.length} ${t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.ERRORS')}`
              : t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.NO_ERRORS_FOUND')}
          </MessagesTitle>
        )
      ) : noData && !batchLoading ? (
        <ErrorMessage>{t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.NO_ASSETS_UPLOADED')}</ErrorMessage>
      ) : (
        <div className="d-flex justify-content-end">
          <DownloadButton type="button" className="btn btn-link" onClick={() => exportExcelAssets()}>
            {t('ASSET_PAGE.BATCH_UPLOAD.BUTTONS_TEXT.DOWNLOAD_EXCEL_TEMPLATE')}
          </DownloadButton>
        </div>
      )}
      <Dropzone accept={acceptedFilesList} onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div>
            {!isDragActive && !batchLoading && batchErrors?.length === 0 && (batchAssets?.length === 0 || batchAssets?.length > 50) && (
              <DropZoneContainer {...getRootProps()} className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-3 dropZone">
                <input {...getInputProps()} />
                <span>
                  <img src={uploadLogo} alt="upload" />
                  <br />
                  {t('ASSET_PAGE.BATCH_UPLOAD.DRAG_AND_DROP')}
                </span>
              </DropZoneContainer>
            )}
            {isDragActive && !isDragReject && (
              <div {...getRootProps()} className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-3 dropZone" style={{ cursor: 'pointer' }}>
                <input {...getInputProps()} />
                <span>
                  <i aria-hidden="true" className="fas fa-upload me-2" />
                  {t('ASSET_PAGE.BATCH_UPLOAD.DROP_THE_FILES')}
                </span>
              </div>
            )}
            {isDragActive && isDragReject && (
              <div {...getRootProps()} className="container w-100 bg-danger p-5 text-center rounded text-white mt-3 dropZone" style={{ cursor: 'pointer' }}>
                <input {...getInputProps()} />
                <span> {t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.UPLOAD_ONLY_ACCEPTED_FORMATS')}</span>
              </div>
            )}
            {checkUploadStatus() === 'AssetsWithErrors' && (
              <ErrorContainer data-testid="errorsContainer" {...getRootProps()} className="container w-100 bg-secondary p-3 text-center rounded text-muted dropZone">
                {batchErrors.map((error, index) => {
                  return (
                    <ErrorMessage key={index}>
                      {t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.ROW')} {error.row}: [{error.field}]
                      {error.message === 'required'
                        ? t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.IS_REQUIRED')
                        : error.message === 'exists'
                        ? t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.DOESNT_EXIST')
                        : error.message}
                    </ErrorMessage>
                  );
                })}
              </ErrorContainer>
            )}
            {checkUploadStatus() === 'AssetsSuccess' && (
              <DropZoneContainer {...getRootProps()} className="container w-100 bg-secondary p-3 text-center rounded text-muted dropZone">
                <ErrorMessage data-testid="successContainer">
                  {batchAssets.length} {t('ASSET_PAGE.BATCH_UPLOAD.ERROR_MESSAGE.ASSETS_ADDED_TO_TANGIBLY')}
                </ErrorMessage>
              </DropZoneContainer>
            )}
            {batchLoading && (
              <DropZoneContainer {...getRootProps()} className="container w-100 bg-secondary p-5 text-center rounded text-muted mt-3 dropZone">
                <div className="d-flex flex-column align-items-center">
                  <Spinner animation="border" variant="primary" />
                  <br />
                  {checkUploadStatus() === 'UploadingAssets' ? <div>{t('ASSET_PAGE.BATCH_UPLOAD.DONT_CLOSE_WINDOW')}</div> : <div>{t('ASSET_PAGE.BATCH_UPLOAD.VALIDATING_ASSETS')}</div>}
                </div>
              </DropZoneContainer>
            )}
          </div>
        )}
      </Dropzone>
      <br />
      <div className="d-flex justify-content-end">
        <button
          className="mym-4 trans-btn btn btn-lg pxp-5 btn-primary btn-pad text-white"
          hidden={!(batchAssets?.length > 0 && batchErrors?.length > 0) && !(noData && !batchLoading && batchAssets.length === 0) && !(batchAssets?.length > 50)}
          onClick={resetBatch}
          type="submit"
        >
          {t('ASSET_PAGE.BATCH_UPLOAD.BUTTONS_TEXT.UPLOAD_NEW_FILE')}
        </button>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="mym-4 trans-btn btn btn-lg pxp-5 btn-primary btn-pad text-white"
          hidden={(batchAssets?.length > 0 && batchErrors?.length > 0) || (noData && batchAssets.length === 0) || batchAssets?.length > 50}
          disabled={!(batchErrors?.length === 0 && batchAssets?.length > 0) || (batchLoading && batchAssets?.length > 0 && batchErrors?.length === 0)}
          onClick={uploadedBatchAssets.length > 0 ? () => handleClose() : () => uploadData()}
          type="submit"
        >
          {t('ASSET_PAGE.BATCH_UPLOAD.BUTTONS_TEXT.CONFIRM_UPLOAD')}
        </button>
      </div>
    </ModalContainer>
  );
};
export default AssetsValidationModal;
