/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import FormData from 'form-data';

import { Asset, Assets, GetAllAssetsClientParams, GetAssets, GetPrivilegeLogData, PrivilegeLogAsset, DeleteAssetData, GetAssetAuditLog, AllAssetsFilesResponse } from 'utils/types/assets/assets';
import { getFilterObj } from '_services/utils/getFilterObj';
import { headers, headersmultipart, extractLanguageAndClientIdByRole, tokenHeaders } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { LANGUAGE } from 'translations/enums';
import { ActivityLogResponse } from 'utils/types/activityLog/asset/types';
import { ENTITY_TYPES, UPLOAD_FILE_TYPES } from 'utils/enums';
import { KeyAssetsFromSystemData } from '_components/IpAudit/types';

class AssetsAdapter {
  static baseEndpoint = `${config.apiUrl}${config.apiVersion}`;

  static assetsEndpoint = `${config.apiUrl}${config.apiVersion}/assets`;

  static assetEndpoint = `${config.apiUrl}${config.apiVersion}/asset`;

  static fileEndpoint = `${config.apiUrl}${config.apiVersion}/file`;
  static auditLogEndpoint = `${config.apiUrl}${config.apiVersion}/audit_log`;

  customHeaders = (token: string) => {
    if (token) {
      const header = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      } as AxiosRequestHeaders;
      return header;
    } else {
      return headers();
    }
  };

  async getAssetsById(assetId: string) {
    const res = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}`, {}, { headers: headers(), params: { id: assetId } });
    return res;
  }

  async getAssets({ sortValue, searchValue, page, limit, filter }: GetAssets): Promise<Assets> {
    const filterObj = getFilterObj(filter) ?? {};
    const params = {
      sortBy: sortValue,
      page: page,
      limit: limit,
      search: '',
    };
    if (searchValue) {
      params.search = searchValue.trim();
    }
    const res: AxiosResponse<Assets> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}`, filterObj, { headers: headers(), params });
    return res.data;
  }

  async getIpAuditAssets(): Promise<Assets> {
    const res: AxiosResponse<Assets> = await HttpClientService.get(`${AssetsAdapter.assetsEndpoint}/ip-audit`, { headers: headers() });
    return res.data;
  }

  async getPrivilegeLogData({ sortValue, searchValue, filter }: GetPrivilegeLogData): Promise<PrivilegeLogAsset[]> {
    const filterObj = getFilterObj(filter) ?? {};
    const params = {
      sortBy: sortValue,
      search: '',
    };
    if (searchValue) {
      params.search = searchValue.trim();
    }
    const res: AxiosResponse<PrivilegeLogAsset[]> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/privilege_log`, filterObj, { headers: headers(), params });
    return res.data;
  }

  async createAsset(assetData: any): Promise<Asset> {
    // eslint-disable-next-line no-unused-expressions
    assetData.files && (assetData.uploadFiles = assetData.files);
    delete assetData.files;
    const res: AxiosResponse<Asset> = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}`, assetData, { headers: headers() });
    return res.data;
  }

  async shareContributors(contributorsData: any) {
    const res = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/shareContributors`, contributorsData, { headers: headers() });
    return res.data;
  }

  async uploadFiles(assetData: any, createdAssetId: string) {
    const formData = new FormData();
    const filesToUpload = assetData.uploadFiles;
    filesToUpload.forEach((fileToUpload: any, index: string) => {
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append(index, fileToUpload.name);
    });
    formData.append('entityId', createdAssetId);

    const params = { client: assetData.client, type: UPLOAD_FILE_TYPES.ASSETS, id: createdAssetId, entityType: ENTITY_TYPES.ASSET };

    const res = await HttpClientService.post(`${AssetsAdapter.fileEndpoint}`, formData, {
      headers: headersmultipart(),
      params,
    });
    return res;
  }

  async validateAssets(assets: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/validate`, assets, { headers: headers() });
    return res.data;
  }

  async createAssets(assets: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/create_multiple`, assets, { headers: headers() });
    return res.data;
  }

  async createAssetsIpAudit(payload: KeyAssetsFromSystemData): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetsEndpoint}/create_multiple_ip_audit`, payload, { headers: headers() });
    return res.data;
  }

  async updateAsset(assetData: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.put(`${AssetsAdapter.assetEndpoint}/${assetData.id}`, assetData, { headers: headers() });
    return res.data;
  }

  async uploadUpdateFiles(assetData: any) {
    const formData = new FormData();
    const filesToUpload = assetData.newfiles;
    filesToUpload.forEach((fileToUpload: any, index: string) => {
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append(index, fileToUpload.name);
    });
    formData.append('entityId', assetData.id);

    const params = { client: assetData.client, type: UPLOAD_FILE_TYPES.ASSETS, id: assetData.id, entityType: ENTITY_TYPES.ASSET };

    const res = await HttpClientService.post(`${AssetsAdapter.fileEndpoint}`, formData, {
      headers: headersmultipart(),
      params,
    });
    return res;
  }

  async getFileUrl(data: any, token: string, isPreview: boolean) {
    const header = isPreview
      ? {
          'Content-Type': 'application/json',
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        }
      : this.customHeaders(token);

    const urlForFile = isPreview ? '/file/path' : '/file/authenticated/path';

    const res = await HttpClientService.get(`${AssetsAdapter.baseEndpoint}${urlForFile}`, {
      headers: header,
      params: { fileName: data.fileName, clientId: data.clientId, type: UPLOAD_FILE_TYPES.ASSETS, entityType: ENTITY_TYPES.ASSET, id: data.assetId },
    });
    return res.data;
  }

  async deleteFileAsset(data: DeleteAssetData, token: string, isAuthenticated: boolean) {
    const header = isAuthenticated
      ? this.customHeaders(token)
      : {
          'Content-Type': 'application/json',
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        };

    const res = await HttpClientService.delete(
      `${AssetsAdapter.fileEndpoint}/${isAuthenticated ? 'authenticated/delete' : 'delete'}?fileName=${data.fileName}&type=${UPLOAD_FILE_TYPES.ASSETS}&id=${data.assetId}`,
      {
        headers: header,
      },
    );
    return res.data;
  }

  async shareAsset(asset: any): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.post(`${AssetsAdapter.assetEndpoint}/${asset.assetId}/recipient?id=${asset.assetId}`, asset.data, { headers: headers() });
    return res.data;
  }

  async getRefNumber(clientId: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.get(`${AssetsAdapter.assetEndpoint}/refnumber/${clientId}`, { headers: headers() });
    return res.data;
  }

  async deleteAsset(assetId: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.delete(`${AssetsAdapter.assetEndpoint}/${assetId}`, { headers: headers() });
    return res.data;
  }

  async deleteRecipient(assetId: string, recipientId: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.delete(`${AssetsAdapter.assetEndpoint}/${assetId}/recipient/${recipientId}`, { headers: headers() });
    return res.data;
  }

  async acknowledgeGetAsset(token: string): Promise<any> {
    const res: AxiosResponse<any> = await HttpClientService.get(`${AssetsAdapter.assetEndpoint}/acknowledge`, {
      headers: {
        'Content-Type': 'application/json',
        [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
      },
    });
    return res.data;
  }

  async acknowledgePostAsset(code: string, token: string): Promise<any> {
    const { language } = extractLanguageAndClientIdByRole();
    const parsedCode: number = parseInt(code, 10);
    const res: AxiosResponse<any> = await HttpClientService.post(
      `${AssetsAdapter.assetEndpoint}/acknowledge`,
      { passCode: parsedCode },
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': language || LANGUAGE.ENGLISH,
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  }

  async acknowledgePreviewAsset(token: string): Promise<any> {
    const { language } = extractLanguageAndClientIdByRole();
    const res: AxiosResponse<any> = await HttpClientService.get(`${AssetsAdapter.assetEndpoint}/preview`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': language || LANGUAGE.ENGLISH,
        [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
      },
    });
    return res.data;
  }

  async acknowledgePreviewAssetPOST(token: string, otp: string): Promise<any> {
    const { language } = extractLanguageAndClientIdByRole();
    const otpCode: number = parseInt(otp, 10);
    const res: AxiosResponse<any> = await HttpClientService.post(
      `${AssetsAdapter.assetEndpoint}/preview`,
      { otpCode },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Accept-Language': language || LANGUAGE.ENGLISH,
          [tokenHeaders.AcknowledgeTokenHeader]: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  }

  async getAllAssets({ limit, clientId, accountId }: GetAllAssetsClientParams) {
    const res = await HttpClientService.post(
      `${AssetsAdapter.assetsEndpoint}`,
      { accountId },
      {
        headers: headers(),
        params: {
          client: clientId,
          limit,
        },
      },
    );
    return res.data;
  }

  async getAssetsPendingAcknowledgement(clientId: string) {
    const res = await HttpClientService.get(`${AssetsAdapter.assetsEndpoint}/pendingAcknowledgment`, {
      headers: headers(),
      params: {
        client: clientId,
      },
    });
    return res.data;
  }

  async getAssetAuditLog({ assetId, page, limit }: GetAssetAuditLog): Promise<ActivityLogResponse> {
    const res: AxiosResponse<ActivityLogResponse> = await HttpClientService.get(`${AssetsAdapter.auditLogEndpoint}/${assetId}`, {
      headers: headers(),
      params: {
        page,
        limit,
        entityType: ENTITY_TYPES.ASSET,
      },
    });
    return res.data;
  }

  async downloadAllAssetsFiles(clientId: string): Promise<AllAssetsFilesResponse> {
    const url: string = `${AssetsAdapter.assetEndpoint}/download_all_assets_files`;

    const response: AxiosResponse<AllAssetsFilesResponse> = await HttpClientService.get(url, {
      headers: headers(),
      params: {
        client: clientId,
      },
    });

    return response.data as AllAssetsFilesResponse;
  }

  async checkDownloadAssetsFilesStatus(jobId: string): Promise<Blob | AllAssetsFilesResponse> {
    const url: string = `${AssetsAdapter.assetEndpoint}/download_assets_files_status`;

    const response: AxiosResponse<ArrayBuffer> = await HttpClientService.get(url, {
      responseType: 'arraybuffer',
      headers: headers(),
      params: {
        jobId,
      },
    });

    const contentType = response.headers['content-type'];

    if (contentType === 'application/zip') {
      return new Blob([response.data], { type: 'application/zip' });
    } else {
      const text = new TextDecoder().decode(response.data);
      return JSON.parse(text) as AllAssetsFilesResponse;
    }
  }
}

const assetsAdapter = new AssetsAdapter();
export default assetsAdapter;
