import { apiCall } from './utils/restClient';
import { config } from '../config/config';
import { headers } from './utils/headers';

function recipientAcknowledgment(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/assets/recipientAcknowledgment`,
    method: 'POST',
    headers: headers(),
    data: data,
  };
  return apiCall(requestOptions);
}

export const acknowledgementService = { recipientAcknowledgment };
