import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { TableContainer } from './style';

const PartnershipTableSkeleton = () => {
  return (
    <TableContainer>
      <Skeleton height={40} count={2} />
    </TableContainer>
  );
};
export default PartnershipTableSkeleton;
