import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EmptyState from '../EmptyState/EmptyState';
import PieAndBarSkeleton from '../PieAndBarSkeleton';
import { BarChart } from 'pages/ClientDashboard/components/Charts';
import { getAxisBottomConfig, getTSBySubjects } from './tradeSecretsBySubject.logic';
import { HeatmapItem } from '../../CompetitiveAnalysis/types';
import { TRANSLATION_KEY } from '../../types';
import { RootState } from '_helpers';

import { BAR_WIDTH, BARS_NUMBER_BY_CLICK, MIN_LEGEND_ITEM_TEXT_LENGTH, MAX_LEGEND_ITEM_TEXT_LENGTH } from './consts';
import { BarChartContainer } from '../../CompetitiveAnalysis/styles';
import { getLegends } from 'pages/ClientDashboard/components/Charts/utils';
import TradeSecretBySubjectLegend from './TradeSecretBySubjectLegend';
import { NEUTRAL_SHADES, PIE_CHART_SHUFFLED } from 'utils/theme';
import { ScrolledTradeSecretsAnalysisCard } from './style';

interface Props {
  isLoading: boolean;
}

const TradeSecretsBySubject = ({ isLoading }: Props) => {
  const { graphs: { heatmap = [] } = {} } = useSelector((state: RootState) => state.competitiveAnalysis.graphsData) as {
    graphs?: { heatmap?: HeatmapItem[] };
  };

  const { t } = useTranslation();

  const cardRef = useRef<HTMLDivElement>(null);
  const [barChartPosition, setBarChartPosition] = useState<number>(0);
  const barChartCardWidth = cardRef.current ? cardRef.current.clientWidth : 0;

  const { data: barChartData, keys, aggregatedDataKeys } = useMemo(() => getTSBySubjects(heatmap), [heatmap]);
  const axisBottom = useMemo(() => getAxisBottomConfig(), [heatmap]);

  const legends = useMemo(() => {
    const maxItemsPerLegend = PIE_CHART_SHUFFLED.length; //The max number of companies is the number of colors in the pallete
    const maxLegendItemtTextLength = aggregatedDataKeys?.length > 3 ? MIN_LEGEND_ITEM_TEXT_LENGTH : MAX_LEGEND_ITEM_TEXT_LENGTH;
    const legendTranslateX = 0;
    const legendTranslateY = 0;

    return getLegends({ legendKeys: aggregatedDataKeys, maxItemsPerLegend, maxLegendItemtTextLength, legendTranslateX, legendTranslateY });
  }, [aggregatedDataKeys]);

  const fullBarChartWidth = (barChartData?.length ?? 0) * BAR_WIDTH;
  const hasNoGraphData = !Boolean(barChartData?.length);

  const handleRightClick = () => {
    setBarChartPosition((prev) => {
      const newPosition = prev - BARS_NUMBER_BY_CLICK * BAR_WIDTH;
      const isEnd = Math.abs(newPosition) + barChartCardWidth > fullBarChartWidth;
      return isEnd ? barChartCardWidth - fullBarChartWidth : newPosition;
    });
  };

  const handleLeftClick = () => {
    setBarChartPosition((prev) => {
      const newPosition = prev + BARS_NUMBER_BY_CLICK * BAR_WIDTH;
      const isStart = newPosition > 0;
      return isStart ? 0 : newPosition;
    });
  };

  return (
    <ScrolledTradeSecretsAnalysisCard
      ref={cardRef}
      isToggleButton={false}
      title={t(TRANSLATION_KEY.COMPETITIVE_BY_SUBJECT_CHART_CARD_TITLE)}
      height="336px"
      width="calc(100% - 400px)"
      hasRightArrowButton={barChartPosition > barChartCardWidth - fullBarChartWidth}
      hasLeftArrowButton={barChartPosition < 0}
      onRightClick={handleRightClick}
      onLeftClick={handleLeftClick}
      dataTestId="bar-chart-ts-analysis-card"
    >
      <>
        {isLoading ? (
          <PieAndBarSkeleton width="calc(100vw - 750px)" height={208} />
        ) : hasNoGraphData ? (
          <EmptyState height="230px" />
        ) : (
          <>
            <BarChartContainer data-testid="ts-by-subject-bar-chart-container" width={fullBarChartWidth} left={barChartPosition}>
              <BarChart
                data={barChartData}
                keys={keys}
                margin={{ top: 40, right: 20, bottom: 60, left: 50 }}
                enableLabel
                labelTextColor={NEUTRAL_SHADES.WHITE}
                axisBottom={axisBottom}
                isLegend={false}
              />
            </BarChartContainer>
            <TradeSecretBySubjectLegend legends={legends} />
          </>
        )}
      </>
    </ScrolledTradeSecretsAnalysisCard>
  );
};

export default TradeSecretsBySubject;
