import styled from 'styled-components';
import { Modal, Form } from 'react-bootstrap';

import { StyledModalBody } from '../style';
import { PRIMARY_SHADES } from '../../../utils/theme';

export const UserContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .hover-text {
    visibility: hidden;
  }
  &:hover {
    .hover-text {
      visibility: visible;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const WrongPersonText = styled.div`
  color: ${PRIMARY_SHADES[800]};
  text-decoration: underline;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
  font-family: 'DIN2014-Regular';
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

export const NameContainer = styled.div`
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #202020;
`;

export const EmailContainer = styled.div`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const StyledModalAddContributorsBody = styled(StyledModalBody)`
  display: flex;
`;

export const StyledContributorsForm = styled(Form)`
  width: 817px;
`;

export const SuggestedContainer = styled.div`
  padding-block: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
`;

export const LabelsContainer = styled.div`
  display: flex;
  div:first-of-type {
    margin-left: 200px;
    width: 176px;
  }
  font-size: 14px;
`;

export const TextAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
