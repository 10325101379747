export const partnershipFormToApiFormat = (formData) => {
  // Change fields with null to empty string
  Object.keys(formData).forEach((key) => {
    if (formData[key] === null) {
      formData[key] = '';
    }
  });
  const { name, location, country, agreements, client } = formData;
  const formattedAgreements = agreements?.map((agreement) => {
    if (agreement.signed) {
      return { ...agreement, version: agreement.version?.value };
    } else {
      return { ...agreement, version: undefined, expiry_date: undefined, signed_date: undefined };
    }
  });
  return {
    name,
    client,
    country: country?.value,
    location,
    agreements: formattedAgreements,
  };
};
export const apiToFormFormat = (partnership) => {
  const { name, location, country, logo, agreements: apiAgreements } = partnership;
  const partnershipAgreements = apiAgreements?.map((agreementItem) => {
    return formatAgreementData(agreementItem);
  });

  const accountsCountry = { label: country, value: country };
  return {
    ...partnership,
    name,
    location,
    country: accountsCountry,
    logo,
    agreements: partnershipAgreements,
  };
};
const formatAgreementData = (agreementItem) => {
  const { expiry_date: expDate, signed_date: signDate, signed, files, agreementType, _id, version } = agreementItem;
  const expiryDate = expDate?.split('T')[0] || '';
  const signedDate = signDate?.split('T')[0] || '';
  const agreementVersion = version ? { label: version?.name, value: version?._id } : null;
  return { expiry_date: expiryDate, signed_date: signedDate, signed, files, id: _id, agreementType: agreementType?._id, version: agreementVersion };
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
