import { apiCall } from './utils/restClient';
import { config } from './../config/config';
import { headers } from './utils/headers';

//Default Params
const defaults = {
  PAGE: 1,
  LIMIT: 1000,
  SORTBY: 'createdAt:asc',
};

function getTags({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/tags`,
    method: 'GET',
    headers: headers(),
    params: {
      client,
      page,
      limit,
      sortBy,
    },
  };

  return apiCall(requestOptions);
}

function createTags(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/tag`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getProjects({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/projects`,
    method: 'GET',
    headers: headers(),
    params: {
      client,
      page,
      limit,
      sortBy,
    },
  };

  return apiCall(requestOptions);
}

function getDepartments({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/departments`,
    method: 'GET',
    headers: headers(),
    params: {
      client,
      page,
      limit,
      sortBy,
    },
  };

  return apiCall(requestOptions);
}

function getBusinessUnits({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/businessUnits`,
    method: 'GET',
    headers: headers(),
    params: {
      client,
      page,
      limit,
      sortBy,
    },
  };

  return apiCall(requestOptions);
}

function createBusinessUnit(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/businessUnit`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function createProject(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/project`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function createDepartment(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/department`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getAssetTypes({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/assetTypes`,
    method: 'GET',
    headers: headers(),
    params: {
      client,
      page,
      limit,
      sortBy,
    },
  };

  return apiCall(requestOptions);
}

function createAssetType(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/assetType`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getAuthors(clientID) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/authors/${clientID}`,
    method: 'GET',
    headers: headers(),
  };
  return apiCall(requestOptions);
}

function getBusinessValues({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/businessValues`,
    method: 'GET',
    headers: headers(),
    params: {
      client,
      page,
      limit,
      sortBy,
    },
  };

  return apiCall(requestOptions);
}

function createBusinessValues(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/businessValue`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

export const assetMetadataService = {
  getTags,
  createTags,
  getProjects,
  createProject,
  getAssetTypes,
  createAssetType,
  getAuthors,
  getBusinessValues,
  createBusinessValues,
  getDepartments,
  createDepartment,
  getBusinessUnits,
  createBusinessUnit,
};
