import { CSSProperties } from 'react';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const ipAuditModalBodyStyle: CSSProperties = {
  padding: SPACING[0],
  height: 'calc(100% - 70px)',
  overflowY: 'hidden',
};

export const ipAuditStepperViewStyle: CSSProperties = {
  width: '995px',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
};

export const CheckboxContainer = styled.div<{ $disabled?: boolean }>`
  opacity: ${(props) => (props.$disabled ? 0.5 : undefined)};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
`;

export const alignTypographyWithCheckbox = 'margin-top: 5px';
