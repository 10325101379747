import { apiCall } from './utils/restClient';
import { config } from '../../config/config';
import { headers, headersmultipart } from './utils/headers';

//TODO: need to implement pagination
function getAdminClients(page = 1, searchTxt, sorting, limit = 100) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'createdAt:asc';
  var url = `${config.apiUrl}${config.apiVersion}/clients`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminClient(clientId) {
  var url = `${config.apiUrl}${config.apiVersion}/client/${clientId}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminClientFromId(clientId) {
  var url = `${config.apiUrl}${config.apiVersion}/clients?id=${clientId}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminClientCountService(user) {
  var url = `${config.apiUrl}${config.apiVersion}/asset/count`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminLawFirmService(page = 1, search, sorting, limit = 10) {
  var search = search;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'name:asc';
  var url = `${config.apiUrl}${config.apiVersion}/lawFirms`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminLawFirmEditService(firmid, data) {
  var url = `${config.apiUrl}${config.apiVersion}/lawFirm/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getAdminLawFirmDeleteService(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/lawFirm/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}
function getAdminLawFirmViewService(firmid) {
  var url = `${config.apiUrl}${config.apiVersion}/lawFirm/${firmid}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminLawFirmLawyers({ lawFirm, page, limit, sortBy }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/users`,
    method: 'POST',
    headers: headers(),
    params: { lawFirm, page, limit, sortBy },
  };

  return apiCall(requestOptions);
}

function createAdminClient(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/client`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function uploadAdminClientLogo(data, clientId) {
  var formData = new FormData();
  formData.append('file', data[0]);

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file/profile`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: {
      clientId,
    },
  };

  return apiCall(requestOptions);
}

function editAdminClient(data, clientId) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/client/${clientId}`,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function deleteAdminClient(clientId) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/client/${clientId}`,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

export const adminClientService = {
  getAdminClient,
  getAdminClients,
  getAdminClientFromId,
  getAdminClientCountService,
  getAdminLawFirmService,
  getAdminLawFirmEditService,
  getAdminLawFirmDeleteService,
  getAdminLawFirmViewService,
  getAdminLawFirmLawyers,
  createAdminClient,
  uploadAdminClientLogo,
  editAdminClient,
  deleteAdminClient,
};
