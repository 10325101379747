import React from 'react';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon } from '../TangiLibrary';
import { StyledSnackbar, SnackbarContentContainer, SnackbarMessage, SnackbarButton, StyledTangiIconButton } from './style';
import { BUTTON_VARIANTS, NEUTRAL_SHADES } from 'utils/theme';

const BannerSnackbar = ({
  handleClick,
  needToBeAcknowledgedNum,
  btnText = ['ASSET_PAGE.TOOLTIP.NAVIGATE_TO_ALL_SIGNATURE_REQUESTS'],
  headerText = ['ASSET_PAGE.TOOLTIP.ASSETS_AWAITING_YOUR_SIGNATURE', { NUMBER: needToBeAcknowledgedNum }],
  onClose,
}) => {
  const { t } = useTranslation();

  const buttonText = t(...btnText);
  const descriptionText = t(...headerText);

  return (
    <StyledSnackbar data-testid="acknowledgement-snackbar">
      <SnackbarContentContainer>
        <TangiSvgIcon component="info" color={NEUTRAL_SHADES.WHITE} />
        <SnackbarMessage>{descriptionText}</SnackbarMessage>
        <SnackbarButton type="button" onClick={handleClick} data-testid="acknowledgement-snackbar-button">
          {buttonText}
        </SnackbarButton>
      </SnackbarContentContainer>
      {!!onClose && <StyledTangiIconButton icon="close" variant={BUTTON_VARIANTS.PRIMARY} onClick={onClose} />}
    </StyledSnackbar>
  );
};

export default BannerSnackbar;
