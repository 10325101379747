import { useContext, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import SessionIsOverModal from '_components/SessionIsOverModal/SessionIsOverModal';
import { AuthContext } from '../Cognito/Auth';
import { LogoutType } from '_services/utils/MixPanel/types';

const IdleHandler = ({ children }) => {
  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const [promptOpen, setPromptOpen] = useState(false);

  const { logout } = useContext(AuthContext);

  const handleIdle = () => {
    if (loggedIn) {
      const message = 'User session has expired due to inactivity, logging out...';
      console.log(message);
      Sentry.captureMessage(message, 'info');
      logout({ logoutType: LogoutType.Automatic });
    }
  };

  const handlePrompt = () => {
    if (loggedIn) {
      setPromptOpen(true);
    }
  };

  const handleConfirm = () => {
    setPromptOpen(false);
    handleIdle();
  };

  /**
   * When the user becomes idle, the onPrompt function is called and
   * after the prompt timeout in milliseconds is reached, the onIdle function is called.
   */
  useIdleTimer({
    timeout: 1000 * 60 * 58, // idle after 58 minutes
    onIdle: handleIdle,
    debounce: 500,
    promptTimeout: 1000 * 60 * 1, // prompt timeout after 1 minute
    onPrompt: handlePrompt,
  });

  return (
    <>
      <SessionIsOverModal open={promptOpen} onConfirm={handleConfirm} />
      {children}
    </>
  );
};

export default IdleHandler;
