import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
`;

export const OverviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const Title = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  align-items: center;
`;

export const Description = styled(TangiTypography)``;

export const CompleteFlowBtnWrapper = styled.div`
  width: 150px;
`;

export const StepCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  overflow-y: scroll;
  padding-right: ${SPACING[2.5]};
  padding-bottom: 20px;
`;

export const ProgressFlowSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[1.5]};
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  align-items: center;
`;
