import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';

// Custom hook for copying text to the clipboard and managing "copied" feedback
export const useCopyToClipboard = (text: string) => {
  const [isCopiedText, setIsCopiedText] = useState<string>('');
  const { t } = useTranslation();

  const copyText = useCallback(
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();

      await navigator.clipboard.writeText(text);
      setIsCopiedText(t('PATENT_MAPPING_TOOL.TOOLTIP.COPIED'));

      const timeoutId = setTimeout(() => {
        setIsCopiedText('');
      }, INTERVAL_IN_MILLISECONDS.OneAndHalfSeconds);

      return () => clearTimeout(timeoutId);
    },
    [text, t],
  );

  return { copyText, isCopiedText };
};
