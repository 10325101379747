import { discoveryConstants } from '../_constants/discoveryConstants';

const initialState = {
  currentEmailThread: {},
  emailThreads: {},
  threadsLoading: false,
  lastEmail: {},
  lastEmailLoading: false,
  error: null,
  lastJobStatus: {},
  lastJobLoading: false,
  // tracker
  trackerAssetsData: [],
  trackerRecipientsData: { noAccounts: [], existingAccounts: [] },
  trackerResultsLoading: false,
  trackerHits: 0,
  trackerLastDateFetched: '',
  trackerError: null,
};

export const discoveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case discoveryConstants.SET_CURRENT_EMAIL_THREAD:
      return {
        ...state,
        currentEmailThread: action.threadData,
      };
    case discoveryConstants.CLEAR_CURRENT_EMAIL:
      return {
        ...state,
        currentEmailThread: {},
        lastEmail: {},
      };

    case discoveryConstants.GET_THREADS_REQUEST:
      return {
        ...state,
        threadsLoading: true,
        error: null,
      };
    case discoveryConstants.GET_THREADS_SUCCESS:
      return {
        ...state,
        emailThreads: action.payload,
        threadsLoading: false,
      };
    case discoveryConstants.GET_THREADS_FAILURE:
      return {
        ...state,
        emailThreads: {},
        threadsLoading: false,
        error: action.error,
      };

    case discoveryConstants.GET_EMAIL_BY_ID_REQUEST:
      return {
        ...state,
        lastEmailLoading: true,
        error: null,
      };
    case discoveryConstants.GET_EMAIL_BY_ID_SUCCESS:
      return {
        ...state,
        lastEmail: action.payload,
        lastEmailLoading: false,
      };
    case discoveryConstants.GET_EMAIL_BY_ID_FAILURE:
      return {
        ...state,
        lastEmail: {},
        lastEmailLoading: false,
        error: action.error,
      };

    case discoveryConstants.GET_LAST_JOB_REQUEST:
      return {
        ...state,
        lastJobLoading: true,
        error: null,
      };
    case discoveryConstants.GET_LAST_JOB_SUCCESS:
      return {
        ...state,
        lastJobStatus: action.payload,
        lastJobLoading: false,
      };
    case discoveryConstants.GET_LAST_JOB_FAILURE:
      return {
        ...state,
        lastJobStatus: {},
        lastJobLoading: false,
        error: action.error,
      };

    // tracker
    case discoveryConstants.GET_TRACKER_RESULTS_REQUEST:
      return {
        ...state,
        trackerResultsLoading: true,
        trackerError: null,
        refetchStatus: '',
      };
    case discoveryConstants.GET_TRACKER_RESULTS_SUCCESS:
      return {
        ...state,
        trackerAssetsData: action.payload.assetsData,
        trackerRecipientsData: action.payload.recipientsData,
        trackerResultsLoading: false,
        trackerHits: action.payload.hits,
        trackerLastDateFetched: action.payload.last_date_fetched,
      };
    case discoveryConstants.GET_TRACKER_RESULTS_FAILURE:
      return {
        ...state,
        trackerAssetsData: [],
        trackerRecipientsData: { noAccounts: [], existingAccounts: [] },
        trackerResultsLoading: false,
        trackerHits: 0,
        trackerLastDateFetched: '',
        trackerError: action.error,
        refetchStatus: '',
      };
    case discoveryConstants.REFETCH_TRACKER_RESULTS_SUCCESS:
      return {
        ...state,
        trackerAssetsData: action.payload.assetsData,
        trackerRecipientsData: action.payload.recipientsData,
        trackerResultsLoading: false,
        trackerHits: action.payload.hits,
        trackerLastDateFetched: action.payload.last_date_fetched,
        refetchStatus: 'success',
      };
    default:
      return state;
  }
};
