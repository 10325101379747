import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { saveStateToLocalStorage } from 'utils/localStorage';
import { RootState } from '_helpers';
import { authConstants } from '_constants';
import { IconAndText, StyledModalBody, StyledModalFooter, StyledModalHeader, TitleCardContainer } from '../style';
import { TangiButton, TangiIconButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Divider, StyledModal } from 'utils/globalStyles';
import PatentXRayVideo from '_components/PatentXRayVideo/PatentXRayVideo';

const CustomFooter = styled(StyledModalFooter)`
  justify-content: flex-end;
`;

interface IUploadFileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const XrayLobbyModal = ({ onClose, isOpen }: IUploadFileModalProps) => {
  const { t } = useTranslation();
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);

  const popupContent = useMemo(() => {
    const title = t(`DISCOVERY_LOBBY.POPUPS.PATENT_TITLE`);
    const text = t(`DISCOVERY_LOBBY.POPUPS.SUBTITLE`);
    return { title, text };
  }, []);

  const handleClose = () => {
    saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, { clientId: activeAccount._id, isFirstTime: false });
    onClose();
  };

  const renderModalHeader = () => {
    return (
      <StyledModalHeader>
        <TitleCardContainer>
          <IconAndText>
            <TangiSvgIcon component="discovery" />
            <TangiTypography type="heading-lg">{t('DISCOVERY_LOBBY.POPUPS.TITLE')}</TangiTypography>
          </IconAndText>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} icon="close" data-testid="close-modal-button" />
        </TitleCardContainer>
      </StyledModalHeader>
    );
  };

  const renderModalBody = (): JSX.Element => {
    const dataTestId = `EXTRACTION-modal`;

    return (
      <StyledModalBody data-testid={dataTestId}>
        <div>
          <PatentXRayVideo />
        </div>
        <TangiTypography type="heading-md" weight="bold">
          {popupContent.title}
        </TangiTypography>
        <TangiTypography>{popupContent.text}</TangiTypography>
      </StyledModalBody>
    );
  };

  const renderModalFooter = () => {
    return (
      <CustomFooter>
        <div>
          <TangiButton text={t('DISCOVERY_LOBBY.POPUPS.BUTTONS.OK_I_GET_IT')} onClick={handleClose} loading={false} data-testid="patent-video-ok-button" svgIcon="arrowRight" endIcon />
        </div>
      </CustomFooter>
    );
  };

  return (
    <StyledModal transition="Fade" show={isOpen} onHide={handleClose} data-testid="mapping-pop-up">
      {renderModalHeader()}
      <Divider />
      {renderModalBody()}
      <Divider />
      {renderModalFooter()}
    </StyledModal>
  );
};

export default XrayLobbyModal;
