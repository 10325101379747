import { createAsyncThunk } from '@reduxjs/toolkit';
import { csvConfig, transformData } from 'pages/TradeSecretsAnalysis/CompetitiveAnalysis/utils';
import ipCompetitiveAdapter from 'redux-toolkit/adapters/competitiveAnalysisAdapter';
import { exportCsv } from 'utils/exportToCsv';
import { CompetitiveAnalysisGraphsData, CompetitiveAnalysisTableData, CompetitiveAnalysisTableParams } from 'utils/types/competitiveAnalysis/competitiveAnalysis';

export const getCompetitiveAnalysisTradeSecretsTable = createAsyncThunk<CompetitiveAnalysisTableData | null, CompetitiveAnalysisTableParams>(
  '/competitiveAnalysis/getTradeSecretsTable',
  async ({ limit, page, filters }: CompetitiveAnalysisTableParams) => {
    const res: CompetitiveAnalysisTableData = await ipCompetitiveAdapter.getCompetitiveAnalysisTradeSecretsTable(limit, page, filters);

    return res ?? null;
  },
);

export const getCompetitiveAnalysisGraphsResults = createAsyncThunk('/competitiveAnalysis/getTradeSecretsGraphsResults', async () => {
  const res: CompetitiveAnalysisGraphsData = await ipCompetitiveAdapter.getCompetitiveAnalysisGraphsResults();

  return res ?? null;
});

export const getCompetitiveAnalysisTradeSecretsExportTable = createAsyncThunk('/competitiveAnalysis/getTradeSecretsExportTable', async ({ limit, page, filters }: CompetitiveAnalysisTableParams) => {
  const res: CompetitiveAnalysisTableData = await ipCompetitiveAdapter.getCompetitiveAnalysisTradeSecretsTable(limit, page, filters);
  const transformedData = transformData(res);

  exportCsv({ data: transformedData, config: csvConfig });
});

export const getCompetitiveAnalysisTradeSecretsTableModal = createAsyncThunk<CompetitiveAnalysisTableData | null, CompetitiveAnalysisTableParams>(
  '/competitiveAnalysis/getTradeSecretsTableModal',
  async ({ limit, page, filters }: CompetitiveAnalysisTableParams) => {
    const res: CompetitiveAnalysisTableData = await ipCompetitiveAdapter.getCompetitiveAnalysisTradeSecretsTable(limit, page, filters);

    return res ?? null;
  },
);

export const getCompetitiveAnalysisTradeSecretsExportTableModal = createAsyncThunk(
  '/competitiveAnalysis/getTradeSecretsExportTable',
  async ({ limit, page, filters }: CompetitiveAnalysisTableParams) => {
    const res: CompetitiveAnalysisTableData = await ipCompetitiveAdapter.getCompetitiveAnalysisTradeSecretsTable(limit, page, filters);
    const transformedData = transformData(res);

    exportCsv({ data: transformedData, config: csvConfig });
  },
);
