import styled, { css } from 'styled-components';
import { Dropdown } from 'react-bootstrap';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING, THEME_COLORS } from 'utils/theme';
import { ReactComponent as ArrownDown } from '../../../assets/tangiIcons/arrow-down.svg';

interface StyledArrownDownIconProps {
  $isSelected?: boolean;
}
interface FilterButtonProps {
  $isSelected?: boolean;
  show?: boolean;
}

export const StyledArrownDownIcon = styled(ArrownDown)<StyledArrownDownIconProps>`
  width: 10px;
  path {
    fill: ${({ $isSelected }) => ($isSelected ? PRIMARY_SHADES[800] : NEUTRAL_SHADES[800])};
  }
`;

export const FilterButton = styled.button<FilterButtonProps>`
  ${({ $isSelected, show }) => css`
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    min-width: 70px;
    height: 32px;
    outline: none;
    background: ${$isSelected ? PRIMARY_SHADES[200] : 'none'};
    border: 1px solid ${$isSelected || show ? THEME_COLORS.PRIMARY_DEFAULT : NEUTRAL_SHADES[600]};
    border-radius: ${SPACING[1]};
    font-size: 14px;
    font-family: 'DIN2014-Regular';
    padding-inline: ${SPACING[2]};
    color: ${$isSelected || show ? THEME_COLORS.PRIMARY_DEFAULT : NEUTRAL_SHADES[800]};
    &:hover:not(:focus) {
      border-color: ${show ? `${THEME_COLORS.PRIMARY_DEFAULT} !important` : !$isSelected && NEUTRAL_SHADES[800]};
      color: ${show ? THEME_COLORS.PRIMARY_DEFAULT : !$isSelected && NEUTRAL_SHADES[800]};
      background: ${$isSelected && PRIMARY_SHADES[100]};
      .multiselect-dropdown-icon path {
        fill: ${show ? THEME_COLORS.PRIMARY_DEFAULT : !$isSelected && NEUTRAL_SHADES[800]};
      }
    }
    & .multiselect-dropdown-icon {
      path {
        fill: ${$isSelected || show ? THEME_COLORS.PRIMARY_DEFAULT : NEUTRAL_SHADES[800]};
      }
    }
    &:focus {
      color: ${THEME_COLORS.PRIMARY_DEFAULT};
      border: 1px solid {!$isSelected && THEME_COLORS.PRIMARY_DEFAULT};
      & .multiselect-dropdown-icon {
        path {
          fill: ${THEME_COLORS.PRIMARY_DEFAULT};
        }
      }
    }
  `}
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${PRIMARY_SHADES[100]} !important;
    `}
  &:active {
    background-color: ${PRIMARY_SHADES[100]};
    color: ${NEUTRAL_SHADES.BLACK};
  }
`;

export const StyledSelectedItem = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

export const BackArrowContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
