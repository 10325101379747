import { createContext, FC, useContext, useState } from 'react';

type PopupContextType = {
  isOpenIpAuditPopup: boolean;
  setIsOpenIpAuditPopup: (open: boolean) => void;
  hasStepChanges: boolean;
  setHasStepChanges: (open: boolean) => void;
};

export const IpAuditPopupContext = createContext<PopupContextType>({
  isOpenIpAuditPopup: false,
  setIsOpenIpAuditPopup: () => {},
  hasStepChanges: false,
  setHasStepChanges: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const IpAuditPopupProvider: FC<Props> = ({ children }) => {
  const [isOpenIpAuditPopup, setIsOpenIpAuditPopup] = useState(false);
  const [hasStepChanges, setHasStepChanges] = useState(false);

  const value = {
    isOpenIpAuditPopup,
    hasStepChanges,
    setHasStepChanges,
    setIsOpenIpAuditPopup,
  };

  return <IpAuditPopupContext.Provider value={value}>{children}</IpAuditPopupContext.Provider>;
};

export const useIpAuditPopup = () => useContext(IpAuditPopupContext);
