/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { ProgressBar } from 'react-bootstrap';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { createAgreementVersion } from 'redux-toolkit/thunks/clientThunks';
import { TangiButton, TangiIconButton } from '_components/TangiLibrary';
import { Agreement } from '_components/Agreement';
import { IRoles } from 'utils/roles';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { AgreementContainer, BackButtonContainer, DoneButtonContainer, SecondPageButtonsContainer, Separator, StyledHeader, StyledModalHeader } from '../style';

interface ISecondPagePros {
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<NonNullable<unknown>>;
  control: Control<NonNullable<unknown>, object>;
  handleClose: () => void;
  activeClientId: string;
}

const SecondPage = ({ setValue, register, control, handleClose, activeClientId }: ISecondPagePros) => {
  //Redux
  const dispatch = useDispatch();
  const { modalPage } = useSelector((state: RootState) => state.partnership);
  const { agreementVersions, newAgreementVersion, newAgreementVersionIndex, loading: versionsLoading } = useSelector((state: RootState) => state.client);
  const { loading: agreementLoading } = useSelector((state: RootState) => state.agreement);
  const { settings } = useSelector((state: RootState) => state.clientSettings);

  //I18-next
  const { t } = useTranslation();

  //Functions
  const handleFiles = (acceptedFiles: any, fileRejections: any, id: string) => {
    if (acceptedFiles.length > 0) {
      setValue(id, acceptedFiles);
    }
  };

  const handleCreateOption = async (inputValue: string, index: number) => {
    dispatch(createAgreementVersion({ agreement: { name: inputValue, client: [activeClientId] }, index }));
  };

  //Side effects
  useEffect(() => {
    if (newAgreementVersion && newAgreementVersionIndex !== null) {
      const versionsValue = { value: newAgreementVersion.id, label: newAgreementVersion.name };
      setValue(`agreements[${newAgreementVersionIndex}].version`, versionsValue);
    }
  }, [newAgreementVersion]);

  //components
  const renderAddModalHeader = () => {
    return (
      <StyledHeader data-testid="add-partnership-second-page-header">
        <StyledModalHeader>{t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.ADD_RELEVANT_DOCUMENTS')}</StyledModalHeader>
        <TangiIconButton icon="close" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} />
      </StyledHeader>
    );
  };

  const renderAgreementsOptions = () => {
    if (settings.agreements) {
      settings.agreements[IRoles.PARTNER].forEach((agreementType: any, index: number) => {
        setValue(`agreements[${index}].agreementType`, agreementType.id);
      });
    }
    return (
      <AgreementContainer>
        {settings.agreements &&
          settings.agreements[IRoles.PARTNER].map((agreement: any, index: number) => (
            <Agreement
              key={index}
              register={register}
              agreementVersions={agreementVersions}
              versionsLoading={versionsLoading}
              control={control}
              agreementIndex={index}
              agreement={agreement}
              handleFiles={handleFiles}
              handleCreateOption={(inputValue: string) => handleCreateOption(inputValue, index)}
              files={undefined}
              agreementLoading={agreementLoading}
            />
          ))}
      </AgreementContainer>
    );
  };

  const renderButtonContainer = () => {
    return (
      <SecondPageButtonsContainer>
        <BackButtonContainer>
          <TangiButton
            text={t('GENERAL.BUTTONS_TEXT.BACK')}
            variant="tertiary"
            onClick={() => {
              dispatch(partnershipActions.setModalPage(modalPage - 1));
            }}
          />
        </BackButtonContainer>
        <DoneButtonContainer>
          <TangiButton text={t('GENERAL.BUTTONS_TEXT.DONE')} type="submit" onClick={undefined} data-testid="add-partnership-finish" />
        </DoneButtonContainer>
      </SecondPageButtonsContainer>
    );
  };

  return (
    <>
      {renderAddModalHeader()}
      <ProgressBar now={(modalPage - 1) * 50} />
      {renderAgreementsOptions()}
      <Separator />
      {renderButtonContainer()}
    </>
  );
};

export default SecondPage;
