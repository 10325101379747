import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TANGI_BUTTON_SIZES, TangiButton, TangiIconButton, TangiInputV2 } from '_components/TangiLibrary';
import InstructionsTooltip from '../InstructionsTooltip/InstructionsTooltip';
import { FormSchema } from '_components/SharePointWizard/types';
import { initialFolderValue } from '_components/SharePointWizard/consts';
import { BUTTON_VARIANTS } from 'utils/theme';
import { siteLinkInstructions } from '../../assets';
import { InputContainer, Container, AddFolderBtnContainer } from './style';

interface Props {
  siteIndex: number;
  isIncludeFolders: boolean;
}

const FolderLocations = ({ siteIndex, isIncludeFolders }: Props) => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<FormSchema>();

  const { fields, prepend, remove, replace } = useFieldArray({
    control,
    name: `sites.${siteIndex}.folders`,
  });

  const isFirstFolderPathValue = Boolean(watch(`sites.${siteIndex}.folders.0.path`));

  const handlePrependFolder = () => {
    prepend(initialFolderValue);
  };

  const handleRemoveFolder = (index: number) => {
    remove(index);
  };

  // Add initial folder if isIncludeFolders is true and fields are empty
  useEffect(() => {
    if (isIncludeFolders && fields.length === 0) {
      replace([initialFolderValue]);
    }
    // Remove folders if isIncludeFolders is false
    else if (!isIncludeFolders && fields.length !== 0) {
      replace([]);
    }
  }, [replace, fields, isIncludeFolders]);

  if (!isIncludeFolders) {
    return null;
  }

  return (
    <Container>
      <AddFolderBtnContainer>
        <TangiButton
          size={TANGI_BUTTON_SIZES.SMALL}
          smallbtn
          text={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.BUTTON.ADD_FOLDER')}
          disabled={!isFirstFolderPathValue}
          variant={BUTTON_VARIANTS.TERTIARY}
          onClick={handlePrependFolder}
          svgIcon="add"
          font={14}
          data-testid="add-folder"
        />
      </AddFolderBtnContainer>
      {fields.map((field, index, array) => {
        const isTheOnlyElement = array?.length === 1;
        return (
          <InputContainer key={field.id}>
            <TangiInputV2<FormSchema>
              required={isTheOnlyElement}
              label={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.LABEL.FOLDER_LOCATION')}
              placeholder={t('SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.PLACEHOLDER.FOLDER_LOCATION')}
              name={`sites.${siteIndex}.folders.${index}.path`}
              labelIcon
              labelTooltipPlacement="right"
              renderLabelTooltipContent={() => <InstructionsTooltip imageSrc={siteLinkInstructions} translationKey="SHARE_POINT_WIZARD.CONNECT_SITES_OR_FOLDERS.TOOLTIP.FOLDER_LOCATION" />}
              autoComplete={false}
            />
            {!isTheOnlyElement && <TangiIconButton customDataTestid="remove-folder" variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="close" onClick={() => handleRemoveFolder(index)} />}
          </InputContainer>
        );
      })}
    </Container>
  );
};

export default FolderLocations;
