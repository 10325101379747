import { useEffect, useMemo } from 'react';
import { useSelector, batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography, TangiCollapse } from '_components/TangiLibrary';
import SwitchClientButton from './components/SwitchClientButton';
import PartnershipCreationSection from './components/PartnershipCreationSection';
import { RootState } from '_helpers';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { toggleSideBarCollapse, setSideBarCollapseState } from 'redux-toolkit/slices/authSlice';
import { getAccessibleMenuItems } from './sideMenuV2.logic';
import { Account } from 'utils/types/account/account';
import { Client } from 'utils/types/client/client';
import { IRoles, adminsExceptLearn } from 'utils/roles';
import { AccessibleMenuItem, NavItemType } from './types';
import { NEUTRAL_SHADES } from 'utils/theme';
import { DrawerToggleIcon, MenuGroupContainer, SideBarContainer, SideBarNavItem, StyledReactRouterNavLink, CollapseContentContainer, collapseLabelStyles, LayoutColumn } from './style';

const SideMenuV2 = () => {
  const Role = useSelector((state: RootState) => state.authentication.Role);
  const activeAccount: Account = useSelector((state: RootState) => state.authentication.activeAccount);
  const collapseState = useSelector((state: RootState) => state.authentication.accountSideBarCollapseState);
  const activeClient: Client | null = useSelector((state: RootState) => state.lawfirm.activeClient);
  const isTableView: boolean = useSelector((state: RootState) => state.asset.isTableView);
  const partnerMenuIsOpen = useSelector((state: RootState) => state.partnership.partnerMenuIsOpen);
  const isAdminManagerOrLawyer = useMemo(() => adminsExceptLearn.includes(Role), [Role]);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const accessibleItems = useMemo(() => {
    const { specialAdminPermissions: accountPermissions = {} } = activeAccount || {};
    const client = activeClient ?? (activeAccount?.client && typeof activeAccount?.client === 'object' ? activeAccount.client : {});
    const clientId = activeClient?.id ?? (typeof activeAccount?.client === 'string' ? activeAccount.client : activeAccount.client?._id ?? '');

    if (Role && clientId) {
      return getAccessibleMenuItems({ role: Role, accountPermissions, client, clientId });
    }
    return [];
  }, [Role, activeAccount, activeClient]);

  useEffect(() => {
    const currentPath = accessibleItems.flatMap((item) => item?.subItems || [item]).find((menuObj) => menuObj?.path?.includes(location.pathname))?.path || null;

    if (currentPath) {
      const parentItem = accessibleItems.find((item) => item?.subItems?.some((subitem) => subitem?.path === currentPath));
      if (parentItem) {
        dispatch(setSideBarCollapseState({ [parentItem.id]: true }));
      }
    }
  }, [location?.pathname, accessibleItems, dispatch]);

  const handleNavLinkClick = () => {
    batch(() => {
      dispatch(partnershipActions.toggleDrawer(false));
      if (isTableView) {
        dispatch(assetsActions.setIsTableView(false));
      }
    });
  };

  const handleToggleCollapse = (id: string) => {
    dispatch(toggleSideBarCollapse(id));
  };

  const renderMenuItem = (item: AccessibleMenuItem) => {
    return (
      <StyledReactRouterNavLink onClick={handleNavLinkClick} exact to={item.path} activeClassName="active-react-router-link" key={item.id} data-testid={`sidebar-${item.id}`}>
        <SideBarNavItem className="sidebar-nav-item">
          <TangiSvgIcon component={item.icon} />
          <TangiTypography>{t(item.translationKey)}</TangiTypography>
        </SideBarNavItem>
      </StyledReactRouterNavLink>
    );
  };

  const renderPartnershipMenuItem = (item: AccessibleMenuItem) => {
    return (
      <SideBarNavItem
        className="sidebar-nav-item"
        isActive={partnerMenuIsOpen}
        onClick={() => dispatch(partnershipActions.toggleDrawer(!partnerMenuIsOpen))}
        aria-expanded={partnerMenuIsOpen}
        key={item.id}
        data-testid={`sidebar-${item.id}`}
      >
        <TangiSvgIcon component={item.icon} />
        <TangiTypography>{t(item.translationKey)}</TangiTypography>
        <DrawerToggleIcon component="arrowSideRight" color={NEUTRAL_SHADES[800]} />
      </SideBarNavItem>
    );
  };

  const renderCollapseLabel = (item: AccessibleMenuItem) => {
    return (
      <SideBarNavItem className="sidebar-nav-item" isCollapseLabel={true} key={item.id} data-testid={`sidebar-${item.id}`}>
        <TangiSvgIcon component={item.icon} />
        <TangiTypography>{t(item.translationKey)}</TangiTypography>
      </SideBarNavItem>
    );
  };

  const renderGroupMenuItem = (item: AccessibleMenuItem, isLastGroup: boolean = false) => {
    if (!item.subItems) return null;

    return (
      <MenuGroupContainer isLastGroup={isLastGroup} data-testid={`sidebar-${item.id}`} key={item.id}>
        <TangiCollapse
          labelStyles={collapseLabelStyles}
          labelIconColor={NEUTRAL_SHADES[800]}
          label={renderCollapseLabel(item)}
          content={<CollapseContentContainer>{renderMenuItems(item.subItems)}</CollapseContentContainer>}
          isOpen={collapseState[item.id]}
          onToggle={() => handleToggleCollapse(item.id)}
        />
      </MenuGroupContainer>
    );
  };

  const renderMenuItems = (items: AccessibleMenuItem[]) => {
    const groups = items.filter((item) => item.type === NavItemType.GROUP);

    return items.map((item) => {
      const isLastGroup = !!groups?.length && item.id === groups[groups.length - 1].id;

      if (item.type === NavItemType.ITEM) {
        return renderMenuItem(item);
      }
      if (item.type === NavItemType.PARTNERSHIP_DRAWER) {
        return renderPartnershipMenuItem(item);
      }
      if (item.type === NavItemType.GROUP) {
        return renderGroupMenuItem(item, isLastGroup);
      }
    });
  };

  return (
    <LayoutColumn className="p-0 d-none d-sm-none d-md-block">
      <SideBarContainer>
        {renderMenuItems(accessibleItems)}

        {Role === IRoles.LAWYER && <SwitchClientButton />}
      </SideBarContainer>

      {isAdminManagerOrLawyer && <PartnershipCreationSection handleNavLinkClick={handleNavLinkClick} />}
    </LayoutColumn>
  );
};

export default SideMenuV2;
