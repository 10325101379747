export interface ICourseProgress {
    _id: string;
    title: string;
    description: string | null;
    author: string;
    client: string;
    difficulty: string;
    featuredVideoUrl: string;
    createdBy: string;
    updatedBy: string;
    createdFrom: string;
    updatedFrom: string;
    date: string;
    createdAt: string;
    updatedAt: string;
    progression: IProgression | null;
}

export interface IProgression {
  user: string;
  course: string;
  status: CourseProgressionStatus;
  createdAt: string;
  updatedAt: string;
  score: string;
  id: string;
}

export enum CourseProgressionStatus {
  COMPLETED = 'Completed',
  INPROGRESS = 'In Progress',
  REQUIRED = 'Required For you',
  RETAKEQUIZ = 'Retake Quiz',
}