import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[4]};
`;

export const BreadcrumbsContainer = styled(FlexContainer)`
  display: flex;
  gap: ${SPACING[3]};
`;

export const typographyStyles = `
  display: flex;
  gap: ${SPACING[2]};
  cursor: default;
`;
