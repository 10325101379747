import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { TangiTypography, TangiButton } from '_components/TangiLibrary';
import { customBodyTypographyStyles, ModalButtonWrapper, SessionOverBody, StyledModalHeader } from './style';

interface Props {
  open: boolean;
  modalTextTranslationKey?: string;
  onConfirm: () => void;
}

const SessionIsOverModal = ({ open, modalTextTranslationKey = 'IDLE_HANDLER.SESSION_EXPIRED', onConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal size="sm" centered show={open} backdrop="static">
      <StyledModalHeader>
        <TangiTypography className="modal-title" customStyles="text-align: center" type="heading-md" weight="semibold">
          {t('IDLE_HANDLER.SESSION_ENDED')}
        </TangiTypography>
      </StyledModalHeader>
      <SessionOverBody className="modal-body">
        <TangiTypography customStyles={customBodyTypographyStyles} type="subheading">
          {t(modalTextTranslationKey)}
        </TangiTypography>
        <ModalButtonWrapper>
          <TangiButton size="small" text={t('GENERAL.BUTTONS_TEXT.OK')} onClick={onConfirm} />
        </ModalButtonWrapper>
      </SessionOverBody>
    </Modal>
  );
};

export default SessionIsOverModal;
