import { Fragment, forwardRef, useMemo } from 'react';
import styled from 'styled-components';

import { SPACING } from 'utils/theme';
import { Divider } from 'utils/globalStyles';
import WidgetCard from '../WidgetCard';
import CreatedAssets from './CreatedAssets';
import { WidgetCardProps } from '../Widgets.types';
import WidgetTitle from '../WidgetTitle';
import { useTranslation } from 'react-i18next';
import { getFormattedAssetsData } from '../Widget.logic';

const AssetsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${SPACING[3]};
  & > ${Divider}:last-child {
    display: none;
  }
`;

export interface Props extends WidgetCardProps {
  data: {
    total: number;
    assetsByThirdParty: number;
    unpublishedAssets: number;
  };
}

const AssetsData = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();

  const assetsDataItems = useMemo(() => getFormattedAssetsData(props.data.unpublishedAssets, props.data.assetsByThirdParty), [props.data]);

  const createdAssetsItems = useMemo(() => {
    return assetsDataItems.map((item) => {
      return (
        <Fragment key={item.label}>
          <CreatedAssets {...item} total={props.data.total} />
          <Divider />
        </Fragment>
      );
    });
  }, [assetsDataItems]);
  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.ASSETS_DATA')} />
        <AssetsDataContainer>{createdAssetsItems}</AssetsDataContainer>
        {props.children}
      </>
    </WidgetCard>
  );
});

export default AssetsData;
