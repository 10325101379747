import { FlexContainerColumn } from '_components/ActivityLog/style';
import { LoginHeader } from '_components/LoginHeader';
import { TangiTypography } from '_components/TangiLibrary';
import { ladder } from 'assets/img';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

const UnderConstructionWrapper = styled(FlexContainerColumn)`
  gap: ${SPACING[5]};
  justify-content: center;
  height: calc(100vh - 200px);
`;

const UnderConstruction = () => {
  const { t } = useTranslation();
  return (
    <>
      <LoginHeader />
      <UnderConstructionWrapper>
        <img width="400px" alt="ladder" src={ladder} />
        <TangiTypography weight="semibold" customStyles="font-size: 38px;">
          {t('UNDER_CONSTRUCTION_PAGE.TITLE')}
        </TangiTypography>
        <TangiTypography type="heading-md">{t('UNDER_CONSTRUCTION_PAGE.SUBTITLE')}</TangiTypography>
      </UnderConstructionWrapper>
    </>
  );
};

export default UnderConstruction;
