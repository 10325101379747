import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: ${SPACING[3.5]} ${SPACING[4]};
  border-top: 1px solid ${NEUTRAL_SHADES[300]};
  z-index: 100;
  height: 76px;
  gap: ${SPACING[2]};
`;
