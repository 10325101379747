import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Dashboard } from '../Dashboard';
import { TangiToast, TangiTypography } from '_components/TangiLibrary';
import LobbyCard from './components/LobbyCard/LobbyCard';
import RequestAccessModal from './components/RequestAccessModal/RequestAccessModal';
import SharePointIntegrationBanner from './components/SharePointIntegration/SharePointIntegrationBanner';
import useSharePointAccess from '_hooks/useSharePointAccess';
import { IpAuditPopupProvider } from '_components/IpAudit/components/context/IpAuditPopup.provider';
import { IpAuditContextProvider } from '_components/IpAudit/IpAudit.provider';
import { useClient } from '_components/IpAudit/components/Steps/hooks/useClient';
import { SharePointWizardProvider } from '_components/SharePointWizard/context/SharePointWizardProvider';
import { RootState } from '_helpers';
import { joinDiscoveryEnquiry } from 'redux-toolkit/thunks/clientThunks';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { sharePointActions } from 'redux-toolkit/slices/sharePointSlice';
import { getCardsByRole } from './utils';
import { JOB_STATUS, RESULT_STATUS } from 'utils/enums';
import { LOBBY_CARD_ID } from './types';
import { IpAudit } from '_components/IpAudit/IpAudit';
import { BannersContainer, Cards, Container, Header } from './style';
import useToast from '_hooks/useToast';

const Lobby = () => {
  const [selectedCardId, setSelectedCardId] = useState<LOBBY_CARD_ID | undefined>(undefined);

  const { showSnackbar } = useToast();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { getClientPermissions } = useClient();
  const { isSharePointEnabled, isSharePointConnected } = useSharePointAccess(clientId); // SHAREPOINT PERMISSIONS + INTEGRATION STATUS

  const isIpAuditEnabled = !!getClientPermissions()?.ipAudit?.hasPermission;

  const { activeAccount, user, Role } = useSelector((state: RootState) => state.authentication);
  const discoveryDataLoaders = useSelector((state: RootState) => state.client.discoveryDataLoaders);
  const clientToastProps = useSelector((state: RootState) => state.client.clientToastProps);
  const isWizardOpen = useSelector((state: RootState) => state.sharePoint.isWizardOpen);
  const spJobStatus: JOB_STATUS = useSelector((state: RootState) => state.sharePoint.spJobStatus);

  const requestAccessLoading = discoveryDataLoaders.joinDiscoveryLoading;
  const hasPredictedPortfolio = true; // TODO: Temporarily set to true, should be removed in next version
  const hasXrayPermission = activeAccount?.specialAdminPermissions?.Patent;
  const hasContinuationPermission = activeAccount?.specialAdminPermissions?.ContinuationXRay;

  const cards = getCardsByRole(Role, hasPredictedPortfolio, hasXrayPermission, hasContinuationPermission);

  const handleLockCardClick = (id: LOBBY_CARD_ID) => {
    setSelectedCardId(id);
  };

  const handleRequestAccess = () => {
    dispatch(
      joinDiscoveryEnquiry({
        name: user?.fullName || activeAccount?.displayName || '',
        email: user.email,
        clientName: activeAccount?.client?.name || activeAccount?.lawFirm?.name || '',
        role: Role,
      }),
    );
  };

  return (
    <>
      <Dashboard title="">
        <Container>
          <Header>
            <TangiTypography type="heading-lg" weight="semibold">
              {t('LOBBY_PAGE.TITLE')}
            </TangiTypography>
            <TangiTypography type="heading-md">{t('LOBBY_PAGE.SUB_TITLE')}</TangiTypography>
          </Header>

          <BannersContainer>
            {isSharePointEnabled && !isSharePointConnected && <SharePointIntegrationBanner onStartIntegration={() => dispatch(sharePointActions.setIsWizardOpen(true))} />}
            {isIpAuditEnabled && (
              <IpAuditContextProvider>
                <IpAuditPopupProvider>
                  <IpAudit role={Role} handleRequestAccess={handleRequestAccess} />
                </IpAuditPopupProvider>
              </IpAuditContextProvider>
            )}
          </BannersContainer>

          <Cards>
            {cards.map(({ cardId, icon, isLocked }) => (
              <LobbyCard key={cardId} cardId={cardId} icon={icon} isLocked={isLocked} onLockCardClick={() => handleLockCardClick(cardId)} />
            ))}
          </Cards>
        </Container>
        <RequestAccessModal cardId={selectedCardId} onClose={() => setSelectedCardId(undefined)} onRequestAccess={handleRequestAccess} isButtonLoading={requestAccessLoading} />
      </Dashboard>

      {isSharePointEnabled && !isSharePointConnected && (
        <SharePointWizardProvider isEditMode={isSharePointConnected} isWizardOpen={isWizardOpen} onCloseWizard={() => dispatch(sharePointActions.setIsWizardOpen(false))} />
      )}

      <TangiToast
        {...clientToastProps}
        onSuccess={() => {
          setSelectedCardId(undefined);
          dispatch(clientActions.setClientToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
        onError={() => {
          setSelectedCardId(undefined);
          dispatch(clientActions.setClientToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
      {spJobStatus === JOB_STATUS.FAILED && showSnackbar('error', 'top', 'center', t('SHARE_POINT_INTEGRATION.ERROR'))}
    </>
  );
};

export default Lobby;
