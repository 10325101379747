import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import { Star } from '../../Star';
import { controlStyles, menuListStyles, menuStyles } from './style';

// TODO: this is an old component, use V2 instead
export const TangiSelector = ({ error, control, name, options, label, required, disabled, shouldRenderLabel = true, ...props }) => {
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div controlId={name}>
      {shouldRenderLabel && (
        <>
          <Form.Label className="text-dark txt-size">{label}</Form.Label>
          {required && <Star />}
        </>
      )}
      <Controller
        rules={{ required: { value: required, message: `${label} is required` } }}
        name={name}
        render={({ field }) => (
          <Select
            isDisabled={disabled}
            classNamePrefix={error ? 'tangi-selector-error' : 'tangi-selector'}
            {...field}
            options={options}
            {...props}
            styles={{
              control: (baseStyles) => {
                return {
                  ...baseStyles,
                  ...controlStyles,
                };
              },
              menuList: (baseStyles) => {
                return props.menuScroll
                  ? {
                      ...baseStyles,
                      ...menuListStyles,
                    }
                  : baseStyles;
              },
              menu: (baseStyles) => ({ ...baseStyles, ...menuStyles }),
            }}
            menuShouldScrollIntoView={false}
          />
        )}
        control={control}
      />
      <div className="d-block invalid-feedback">{error && error.message}</div>
    </div>
  );
};
