import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  color: #202020;
  width: 100%;
  font-family: 'DIN2014-Regular';
  flex-direction: column;
`;

export const PasswordChecklistContainer = styled.div`
  display: flex;
`;
