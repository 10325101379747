import BreadCrumbs from '_components/BreadCrumbs/BreadCrumbs';
import AssetTypeColorBlock from '../../../AssetTypeColorBlock/AssetTypeColorBlock';
import { useWindowSize } from '_hooks/useWindowSize';
import { SHARE_POINT, LOCKED_ASSET, assetTypeNameFormatter } from 'utils/assetTypes';
import { parseAssetPath } from '../AssetCardActive/utils';
import { ColorAndType } from '_components/ClientAssetCards/style';
import { LockedAssetCardHeader } from './style';

const AssetCardLockedHeader = ({ asset }) => {
  const isSharePointType = !!asset?.sharePoint;
  const { isSmallScreen } = useWindowSize();
  const maxItemsToShow = isSmallScreen ? 5 : 6;

  const renderAssetNameAndType = () => {
    if (isSharePointType) {
      const breadcrumbData = parseAssetPath(asset.sharePoint);
      const { breadcrumbItems, fullPathString } = breadcrumbData || {};

      return (
        <ColorAndType>
          <AssetTypeColorBlock assetTypeName={SHARE_POINT} />
          {breadcrumbItems ? (
            <BreadCrumbs items={breadcrumbItems} maxItemsToShow={maxItemsToShow} tooltipContent={fullPathString} />
          ) : (
            <span>{asset?.assetType?.name ? assetTypeNameFormatter(asset.assetType.name) : ''}</span>
          )}
        </ColorAndType>
      );
    } else {
      return (
        <ColorAndType>
          <AssetTypeColorBlock assetTypeName={LOCKED_ASSET} />
          <span>{LOCKED_ASSET}</span>
        </ColorAndType>
      );
    }
  };

  return <LockedAssetCardHeader>{renderAssetNameAndType()}</LockedAssetCardHeader>;
};

export default AssetCardLockedHeader;
