//TODO: DO NOT TOUCH THIS
// default asset types that are created for each client
export const DEFAULT_ASSET_TYPES = {
  CONFIDENTIAL_OTHER: 'Confidential - Other',
  NON_CONFIDENTIAL: 'Non Confidential',
  TRADE_SECRET_PATENT_CANDIDATE: 'Trade Secret – Patent Candidate',
  TRADE_SECRET_UNPUBLISHED_PATENTS: 'Trade Secret - Unpublished Patents',
  TRADE_SECRET: 'Trade Secret',
  UNCLASSIFIED: 'Unclassified',
};

export const LOCKED_ASSET = 'Locked Asset';
export const CUSTOM_ASSET_TYPE = 'Custom Asset Type';
export const SHARE_POINT = 'SharePoint';

export const isCustomAssetType = (assetType: string) => {
  return !Object.values(DEFAULT_ASSET_TYPES).includes(assetType);
};

export const assetTypeNameFormatter = (name: string) => {
  if (Object.values(DEFAULT_ASSET_TYPES).includes(name)) {
    // different hyphens for 'Trade Secret – Patent Candidate' and 'Trade Secret – Unpublished Patents' in the database
    const strings = name.replaceAll('-', '–').split(' – ');
    return strings.length > 1 && strings[0] === 'Trade Secret' ? strings[1] : name;
  }
  return name;
};
