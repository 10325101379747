import { ReactNode } from 'react';
import { z } from 'zod';

import { folderSchema, schema, secretsSchema, siteSchema, userSchema } from './schema';
export enum SharePointWizardStepKey {
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  ALLOW_ACCESS = 'ALLOW_ACCESS',
  CONNECT_SITES_OR_FOLDERS = 'CONNECT_SITES_OR_FOLDERS',
  ADD_USERS = 'ADD_USERS',
  SEND_ACKNOWLEDGEMENT = 'SEND_ACKNOWLEDGEMENT',
}

// ------------------------------  Schema Types -----------------------------
export type FormSchema = z.infer<typeof schema>;
export type FormSchemaKey = keyof FormSchema;
export type IncludeContentType = z.infer<typeof siteSchema>['includeContent'];
export type FolderSchema = z.infer<typeof folderSchema>;
export type SiteSchema = z.infer<typeof siteSchema>;
export type SecretsSchema = z.infer<typeof secretsSchema>;
export type UserSchema = z.infer<typeof userSchema>;
// --------------------------------------------------------------------------

export interface SharePointWizardStep {
  id: SharePointWizardStepKey;
  component: ReactNode;
  index?: number;
  requiresAsyncValidation?: boolean;
  requiresAdditionalAsyncAction?: boolean;
  stepRequiredFormFields: FormSchemaKey[];
}

export type SharePointWizardStepsMap = Record<SharePointWizardStepKey, SharePointWizardStep>;
