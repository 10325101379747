import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import { NEUTRAL_SHADES } from 'utils/theme';

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  gap: 4px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const IconAndText = styled.div`
  display: flex;
  gap: 12px;
  .tangi-icon-container {
    margin-top: 4px;
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px;
  max-height: 432px;
  overflow-y: auto;
`;

export const StyledTextArea = styled.textarea`
  width: 656px;
  font-family: 'DIN2014-Regular';
  font-size: 16px;
  border: 1px solid ${NEUTRAL_SHADES[600]};
  border-radius: 4px;
  min-height: 264px;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding: 24px 0px 16px;
  span:first-of-type {
    margin-right: 24px;
  }
`;
