import { CSSProperties } from 'react';

import { NEUTRAL_SHADES } from '../../../utils/theme';

export const controlStyles: CSSProperties = {
  borderColor: '#dcdcdc',
};

export const menuStyles: CSSProperties = {
  zIndex: 2,
};

export const menuListStyles = {
  height: '100px',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    background: `${NEUTRAL_SHADES[600]}`,
    visibility: 'hidden',
    borderRadius: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    background: `${NEUTRAL_SHADES[600]}`,
    borderRadius: '5px',
    opacity: 1,
  },
};
