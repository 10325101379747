import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Controller, useForm, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import { assetMetaDataActions } from 'redux-toolkit/slices/assetMetaDataSlice';
import _ from 'lodash';
import { createAssetType, createBusinessValue, createTag } from 'redux-toolkit/thunks/assetMetaDataThunks';
import './AdminAssetsForm.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { adminAssetActions } from '../../../AdminRedux/_actions';
import { AsyncSelect, optionTypes } from '../../AsyncSelect';
import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { scrollToTop } from '../../../utils/windowFuncs';
import { Loader } from '../../Loader';
import { validEmailPattern } from '../../../utils/formUtils';

function AdminAssetsForm({ type, viewResult, firmid, onEditAPi }) {
  const formType = {
    TAG: 'Add New Tag',
    TYPE: 'Add New Type',
    BUSINESS_VALUE: 'Add New Business Value',
    STATUS: 'Add new status',
    ASSET: 'Add new item to Assets',
    EDIT_TAG: 'Edit Tag',
    EDIT_TYPE: 'Edit Type',
    EDIT_BV: 'Edit Business Value',
  };

  const editTypes = {
    EDIT_TAG: 'Edit Tag',
    EDIT_TYPE: 'Edit Type',
    EDIT_BV: 'Edit Business Value',
  };

  const assetStatusMsg = {
    ASSET_SUCCESS: 'Asset Created Successfully',
  };

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [isView, setIsView] = useState(false);
  const { error, status } = useSelector((state) => state.assetMetaData);

  useEffect(() => {
    dispatch(adminAssetActions.clearAdminAsset());
  }, []);

  useEffect(() => {
    if (type.includes('View')) {
      setIsView(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (status === 'success') {
      const previousClient = getValues('client');
      window.location.reload();
      reset('', {
        keepValues: false,
      });

      setTimeout(() => {
        setValue('client', previousClient);
        dispatch(assetMetaDataActions.setClearStatus());
      }, 2000);
    }
  }, [status]);

  useEffect(() => {
    if (viewResult && viewResult.id) {
      reset(transformAPItoFormData(viewResult));
    }
  }, [viewResult]);

  // Add Asset Metadata forms - Tag, Types, Business Value and its related methods
  const {
    register,
    handleSubmit,
    // watch,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const { createAsset, errors: assetErrors } = useSelector((state) => state.adminAssetReducer);
  const [assetStatus, setAssetStatus] = useState(null);
  const [isloading, setIsloading] = useState(false);

  function transformAPItoFormData(data) {
    const {
      description,
      name,
      slug,
      client: { id: client },
      isActive,
    } = data;

    return { description, name, slug, isActive, client };
  }

  function isEditMode(currentType) {
    let present = false;
    for (const key in editTypes) {
      if (editTypes[key] === currentType) {
        present = true;
      }
    }
    return present;
  }

  // Add Asset Form and its related methods
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    control: control2,
    formState: { errors: errors2 },
  } = useForm({
    mode: 'all',
    defaultValues: {
      recipients: [],
    },
  });

  useEffect(() => {
    if (!_.isEmpty(createAsset)) {
      setIsloading(false);
      setAssetStatus(assetStatusMsg.ASSET_SUCCESS);

      setTimeout(() => {
        window.location.reload();
        scrollToTop();
      }, 1500);
    } else if (!_.isEmpty(assetErrors)) {
      setIsloading(false);
    }
  }, [assetErrors, createAsset]);

  const { dirtyFields } = useFormState({
    control: control2,
  });

  const client = useWatch({
    control: control2,
    name: 'client',
    defaultValue: '',
  });

  const { fields, append, remove } = useFieldArray({
    control: control2,
    name: 'recipients',
  });

  const onDrop = useCallback((acceptedFiles) => {
    const mergeResult = [...files, ...acceptedFiles];
    setFiles(mergeResult);
    setValue2('files', mergeResult, { shouldValidate: true });
  });

  const removeFileFunc = (fileToRemove) => {
    const updatedList = files.filter((file) => file !== fileToRemove);
    setFiles(updatedList);
    setValue2('files', updatedList, { shouldValidate: true });
  };

  const handleAppendRecipient = () => {
    append({ fullName: '', email: '' });
  };

  // Sumbit for all the form types
  const onSubmit = (data) => {
    switch (type) {
      case formType.ASSET:
        setIsloading(true);
        dispatch(adminAssetActions.createAdminAsset(data));
        break;
      case formType.BUSINESS_VALUE:
        if (data.name === 'undefined') {
          data = { ...data, backgroundColor: 'rgb(240, 164, 34)' };
          dispatch(createBusinessValue(data));
        } else if (data.name === 'Cost savings') {
          data = { ...data, backgroundColor: 'rgb(62, 149, 205)' };
          dispatch(createBusinessValue(data));
        } else if (data.name === 'Process Know How') {
          data = { ...data, backgroundColor: 'rgb(196, 88, 80)' };
          dispatch(createBusinessValue(data));
        } else if (data.name === 'Revenue Generating') {
          data = { ...data, backgroundColor: 'rgb(142, 94, 162)' };
          dispatch(createBusinessValue(data));
        } else if (data.name === 'Increased Efficiency') {
          data = { ...data, backgroundColor: 'rgb(232, 195, 185)' };
          dispatch(createBusinessValue(data));
        } else if (data.name === 'Product') {
          data = { ...data, backgroundColor: 'rgb(60, 186, 159)' };
          dispatch(createBusinessValue(data));
        } else {
          dispatch(createBusinessValue(data));
        }
        break;
      case formType.STATUS:
        break;
      case formType.TAG:
        delete data.parent;
        dispatch(createTag(data));
        break;
      case formType.TYPE:
        if (data.name === 'Confidential - Other') {
          data = { ...data, backgroundColor: 'rgb(62, 149, 205)' };
          dispatch(createAssetType(data));
        } else if (data.name === 'Non Confidential') {
          data = { ...data, backgroundColor: 'rgb(232, 195, 185)' };
          dispatch(createAssetType(data));
        } else if (data.name === 'Trade Secret – Patent Candidate') {
          data = { ...data, backgroundColor: 'rgb(60, 186, 159)' };
          dispatch(createAssetType(data));
        } else if (data.name === 'Trade Secret - Unpublished Patents') {
          data = { ...data, backgroundColor: 'rgb(196, 88, 80)' };
          dispatch(createAssetType(data));
        } else if (data.name === 'Trade Secret') {
          data = { ...data, backgroundColor: 'rgb(142, 94, 162)' };
          dispatch(createAssetType(data));
        } else if (data.name === 'Unclassified') {
          data = { ...data, backgroundColor: 'rgb(240, 164, 34)' };
          dispatch(createAssetType(data));
        } else {
          dispatch(createAssetType(data));
        }

        break;
      case formType.EDIT_TAG:
        onEditAPi();
        dispatch(adminAssetActions.getAdminAssetTagsEdit(firmid, data));
        break;
      case formType.EDIT_TYPE:
        onEditAPi();
        dispatch(adminAssetActions.getAdminAssetTypeEdit(firmid, data));
        break;
      case formType.EDIT_BV:
        onEditAPi();
        dispatch(adminAssetActions.getAdminAssetBvEdit(firmid, data));
        break;
      default:
        break;
    }
  };

  return (
    <Row className="m-0">
      <Loader isLoading={isloading} />
      <div className="col-md-12 col-lg-12 mx-auto mt-4">
        <div className="border-0 rounded">
          <h3 className="font-weight-normal">{type}</h3>
          {/* <pre>{JSON.stringify(watch2(), null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(errors2, null, 2)}</pre> */}
          {type === 'Add new item to Assets' ? (
            <div className="mb-2 card p-3 px-4">
              <Form onSubmit={handleSubmit2(onSubmit)}>
                {/* Asset Name */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetName">
                    <Form.Label className="text-dark fw-600">
                      Add Title <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Asset Name" {...register2('name', { required: true })} />
                  </Form.Group>
                </div>

                {/* Client  */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetClient">
                    <Form.Label className="text-dark fw-600">
                      Client Id Reference <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      data-testid="client-test"
                      name="client"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.CLIENTS}
                          {...rest}
                          reloadOptions
                          // reloadVar={clientStatus === clientStatusMsg.CLIENT_SUCCESS && clientStatusMsg.CLIENT_SUCCESS}
                        />
                      )}
                      control={control2}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </div>

                {/* Description */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetDescription">
                    <Form.Label className="text-dark fw-600">Description</Form.Label>
                    <Form.Control as="textarea" style={{ height: '100px' }} {...register2('description')} />
                  </Form.Group>
                </div>

                {/* Asset tags */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetTags">
                    <Form.Label className="text-dark fw-600">Tags</Form.Label>
                    <Controller
                      name="tags"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.TAGS}
                          {...rest}
                          reloadOptions
                          isMulti
                          isDisabled={!dirtyFields.client}
                          reloadVar={dirtyFields.client && client}
                          clientId={dirtyFields.client && client}
                        />
                      )}
                      control={control2}
                      // rules={{ required: true }}
                    />
                  </Form.Group>
                </div>

                {/* Asset Type */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetType">
                    <Form.Label className="text-dark fw-600">
                      Type <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      name="assetType"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.ASSET_TYPES}
                          {...rest}
                          reloadOptions
                          isDisabled={!dirtyFields.client}
                          reloadVar={dirtyFields.client && client}
                          clientId={dirtyFields.client && client}
                        />
                      )}
                      control={control2}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </div>

                {/* Asset Status */}
                {/* <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetStatus">
                    <Form.Label className="text-dark fw-600">Status</Form.Label>
                    <Controller
                      name="status"
                      render={({ field: { ref, ...rest } }) => <ReactSelect inputRef={ref} selectStyles="ff-din-DemiBold" {...rest} options={parent} />}
                      control={control2}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </div> */}

                {/* Asset Business Value */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetBusinessValue">
                    <Form.Label className="text-dark fw-600">Business Value</Form.Label>
                    <Controller
                      name="businessValues"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.BUSINESS_VALUES}
                          {...rest}
                          reloadOptions
                          isMulti
                          isDisabled={!dirtyFields.client}
                          reloadVar={dirtyFields.client && client}
                          clientId={dirtyFields.client && client}
                        />
                      )}
                      control={control2}
                      // rules={{ required: true }}
                    />
                  </Form.Group>
                </div>

                {/* Asset Files */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetFiles">
                    <Form.Label className="text-dark fw-600">Files</Form.Label>

                    <Controller
                      render={({ field }) => (
                        <Dropzone multiple {...field} onDrop={onDrop}>
                          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                            <div
                              {...getRootProps()}
                              className={`container w-100 bg-secondary p-5 text-center rounded text-muted dropZone ${errors2 && errors2.files ? 'dropZone-error' : ''}`}
                              style={{ cursor: 'pointer' }}
                            >
                              <input {...getInputProps()} />
                              {!isDragActive && (
                                <span>
                                  <i aria-hidden="true" className="fas fa-upload me-2" />
                                  Drag &apos;n&apos; drop some files here, or click to select files
                                </span>
                              )}
                              {isDragActive && !isDragReject && (
                                <span>
                                  <i aria-hidden="true" className="fas fa-upload me-2" />
                                  Drop the files here.....
                                </span>
                              )}
                              {isDragReject && 'File type not Supported'}
                            </div>
                          )}
                        </Dropzone>
                      )}
                      name="files"
                      control={control2}
                      // rules={{ required: { value: true, message: 'File field is required' } }}
                    />
                    <ul className="p-0">
                      {files &&
                        files.length > 0 &&
                        files.map((acceptedFile) => (
                          <li key={uuidv4()} style={{ listStyle: 'none' }} className="mt-2 border border-black rounded-3">
                            <div className="px-4 py-1">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i aria-hidden="true" className={`${icons[getFileExtensionCard(acceptedFile.name)]} me-2 icon-big`} />
                                  <div className="m-2">
                                    <span>{acceptedFile.name}</span>
                                  </div>
                                </div>
                                <button type="button" onClick={() => removeFileFunc(acceptedFile)} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                  <i className="far fa-trash-alt" aria-hidden="true" />
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </Form.Group>
                </div>

                {/* Asset Contributor */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetContributor">
                    <Form.Label className="text-dark fw-600">
                      Contributor <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      name="contributor"
                      render={({ field: { ref, ...rest } }) => (
                        <AsyncSelect
                          inputRef={ref}
                          selectStyles="ff-din-DemiBold"
                          type={optionTypes.ASSET_CONTRIBUTORS}
                          {...rest}
                          reloadOptions
                          isDisabled={!dirtyFields.client}
                          reloadVar={dirtyFields.client && client}
                          clientId={dirtyFields.client && client}
                        />
                      )}
                      control={control2}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </div>

                {/* Asset Recipients */}
                <div className="mt-3 border-0 row">
                  <Form.Group className="mb-3" controlId="formAssetRecipients">
                    <Form.Label className="text-dark fw-600">Share List</Form.Label>

                    {fields?.length > 0 && (
                      <Row>
                        <Col>
                          <h4>Full Name</h4>
                        </Col>
                        <Col>
                          <h4>Email Address</h4>
                        </Col>
                        <Col />
                      </Row>
                    )}

                    <TransitionGroup component="div">
                      {fields.map((field, index) => (
                        <CSSTransition key={field.id} timeout={150} classNames="item">
                          <Row>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="Enter Full Name"
                                {...register2(`recipients[${index}].fullName`, { required: { value: true, message: 'FullName is required' } })}
                                defaultValue={field.fullName}
                              />
                              <div className="d-block invalid-feedback">{errors2.recipients?.[index]?.fullName && errors2.recipients?.[index]?.fullName.message}</div>
                            </Col>
                            <Col>
                              <Form.Control
                                type="email"
                                placeholder="Enter Email Address"
                                {...register2(`recipients[${index}].email`, {
                                  required: 'Email is required',
                                  pattern: {
                                    value: validEmailPattern,
                                    message: 'Please enter a valid email',
                                  },
                                })}
                                defaultValue={field.email}
                              />
                              <div className="d-block invalid-feedback">{errors2.recipients?.[index]?.email && errors2.recipients?.[index]?.email.message}</div>
                            </Col>
                            <Col>
                              <Button variant="secondary" className="u-hover-black" type="button" onClick={() => remove(index)}>
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>

                    <div className="mt-3 border-0 row">
                      <Col className="col-lg-4">
                        <Button onClick={handleAppendRecipient} className="btn btn-primary d-block u-hover-black">
                          Add Rows
                        </Button>
                      </Col>
                    </div>
                  </Form.Group>
                </div>

                {/* Submit button */}
                <div className="border-0 row">
                  <div className="d-flex justify-content-center">
                    <Button className="btn btn-primary btn-lg my-4 form-btn px-5 text-white" type="submit">
                      Add
                    </Button>
                  </div>
                </div>

                <div style={{ backgroundColor: '#F75676' }} className={`alert alert-primary fade ${assetErrors && assetErrors.message ? 'show' : ''} rounded py-2 text-white`}>
                  <span style={{ fontSize: '13px' }} className="mx-3">
                    {assetErrors && assetErrors.message}
                  </span>
                </div>
                <div
                  style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }}
                  className={`alert alert-primary fade ${assetStatus === assetStatusMsg.ASSET_SUCCESS ? 'show' : ''} rounded py-2 text-white`}
                >
                  <span style={{ fontSize: '13px' }} className="mx-3">
                    {assetStatus && assetStatus}
                  </span>
                </div>
              </Form>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)} className="ff-din-regular">
              {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label className="text-dark fs-6">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control disabled={isView} type="text" placeholder="Name" {...register('name', { required: { value: true, message: 'Name is required' } })} />
                <small className="form-text text-muted">The name is how it appears on your site.</small>
                <div className="d-block invalid-feedback">{errors.name && errors.name.message}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSlug">
                <Form.Label className="text-dark fs-6">Slug</Form.Label>
                <Form.Control disabled={isView} type="text" placeholder="Slug" {...register('slug')} />
                <small className="form-text text-muted">The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</small>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label className="text-dark fs-6">Description</Form.Label>
                <Form.Control disabled={isView} as="textarea" rows={3} placeholder="Description" {...register('description')} />
                <small className="form-text text-muted">The description is not prominent by default; however, some themes may show it.</small>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formClient">
                <Form.Label className="text-dark fs-6">
                  Client <span className="text-danger">*</span>
                </Form.Label>
                {isEditMode(type) ? (
                  <Controller
                    name="client"
                    rules={{ required: { value: true, message: 'Client field is required' } }}
                    render={({ field: { ref, ...rest } }) => (
                      <AsyncSelect
                        defaults={(isEditMode(type) || isView) && viewResult.client}
                        isEdit={isEditMode(type) || isView}
                        inputRef={ref}
                        selectStyles="ff-din-DemiBold"
                        type={optionTypes.CLIENTS}
                        {...rest}
                        isDisabled={isView}
                      />
                    )}
                    control={control}
                  />
                ) : (
                  <Controller
                    name="client"
                    rules={{ required: { value: true, message: 'Client field is required' } }}
                    render={({ field: { ref, ...rest } }) => (
                      <AsyncSelect
                        defaults={(isEditMode(type) || isView) && viewResult.client}
                        isEdit={isEditMode(type) || isView}
                        inputRef={ref}
                        selectStyles="ff-din-DemiBold"
                        isMulti
                        type={optionTypes.CLIENTS}
                        {...rest}
                        isDisabled={isView}
                      />
                    )}
                    control={control}
                  />
                )}
                <div className="d-block invalid-feedback">{errors.client && errors.client.message}</div>
              </Form.Group>

              {type === 'Add new firm' ? (
                <Form.Group className="mb-3" controlId="formFirmLogo">
                  <Form.Label>Law firm logo</Form.Label> <br />
                  <Form.Control type="file" size="lg" {...register('firmLogo')} />
                </Form.Group>
              ) : (
                ''
              )}

              <div className="d-flex">
                <Button hidden={isView} className="my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" type="submit">
                  {type}
                </Button>
              </div>
              <div className={`alert alert-primary fade ${status === 'failed' ? 'show' : ''} text-center w-100`}>{error && error.message}</div>
              <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${status === 'success' ? 'show' : ''} text-center w-100`}>
                Created Successfully
              </div>
            </Form>
          )}
        </div>
      </div>
    </Row>
  );
}

export { AdminAssetsForm };
