import _ from 'lodash';

export const getFileExtensionCard = function (url) {
  return url ? url.toLowerCase().split('.').pop() : '-';
};

export const getLawyers = function (array) {
  var lawyers = array.map(function (item) {
    return item['displayName'];
  });
  return lawyers.join(', ');
};

export const getTags = function (array) {
  var lawyers = array.map(function (item) {
    return item['name'];
  });
  return lawyers.join(', ');
};

export const normalizeString = (str) => str?.trim()?.replaceAll(' ', '')?.toLowerCase();

export const icons = {
  pdf: 'far mr-1 fa-file-pdf text-danger',
  doc: 'far mr-1 fa-file-word text-info',
  docx: 'far mr-1 fa-file-word text-info',
  xls: 'far mr-1 fa-file-excel text-success',
  xlsx: 'far mr-1 fa-file-excel text-success',
  ppt: 'far mr-1 fa-file-powerpoint text-danger',
  pptx: 'far mr-1 fa-file-powerpoint text-danger',
  jpeg: 'far mr-1 fa-image text-info',
  png: 'far mr-1 fa-image text-info',
  jpg: 'far mr-1 fa-image text-info',
  link: 'fas fa-link',
};

// recives array array a key and value and makes it an object
export const arrayToObjectByKeyValue = (arr, key, value) => {
  return _.chain(arr).keyBy(key).mapValues(value).value();
};
