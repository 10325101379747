import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'DIN2014-REGULAR';
  display: flex;
  width: 100%;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  z-index: 9999999999;
  ${({ type }) => getAlertColor(type)}
`;

const getAlertColor = (type) => {
  switch (type) {
    case 'error':
      return `
      background-color: #F7567630;
      border: 1px solid red;
      `;
    case 'info':
      return `
      background-color: #FC7C5F30;
      border: 1px solid orange;
      `;
    default:
      return `
      background-color: #4ABB7930;
      border: 1px solid green;
      `;
  }
};
