import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { BORDER_RADIUS, NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const StyledBanner = styled(FlexContainer)`
  background-color: ${PRIMARY_SHADES[200]};
  border-radius: ${BORDER_RADIUS[8]};
  padding-inline: ${SPACING[4]};
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${NEUTRAL_SHADES[300]};
`;

export const BannerBodyContainer = styled(FlexContainer)`
  gap: ${SPACING[3]};
  align-items: center;
  margin-block: ${SPACING[1]};
`;

export const NewTag = styled(FlexContainer)`
  gap: ${SPACING[1]};
  background-color: ${NEUTRAL_SHADES.WHITE};
  border-radius: ${BORDER_RADIUS[40]};
  align-items: center;
  padding: ${SPACING[1]} ${SPACING[2]};
`;
