import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { Divider } from 'utils/globalStyles';

const WidgetTableTitle = ({ suffix1, suffix2 }: { suffix1: string; suffix2: string }) => {
  const { t } = useTranslation();

  return (
    <div className="widget-table-title">
      <div className="d-flex justify-content-between">
        <TangiTypography type="x-small" color={NEUTRAL_SHADES[1000]}>
          {t(`DASHBOARD_PAGE.WIDGETS.${suffix1}`)}
        </TangiTypography>
        <TangiTypography type="x-small" color={NEUTRAL_SHADES[1000]}>
          {t(`DASHBOARD_PAGE.WIDGETS.${suffix2}`)}
        </TangiTypography>
      </div>
      <Divider />
    </div>
  );
};

export default WidgetTableTitle;
