import { RootState } from '_helpers';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { TangiButton, TangiSvgIcon } from '_components/TangiLibrary';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { StyledModal } from 'utils/globalStyles';
import { ButtonContainer, Description, StyledContainer, SvgContainer, Title } from './style';

const FinishCreationPartnerModal = () => {
  //Redux
  const { finishCreationPartnershipModalIsOpen } = useSelector((state: RootState) => state.partnership);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Params
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  const handleRedirectToAssets = () => {
    history.push(generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId }));
    batch(() => {
      dispatch(assetsActions.setIsTableView(false));
      dispatch(partnershipActions.setFinishCreationPartnershipModalState(false));
    });
  };
  return (
    <StyledModal transition="Fade" show={finishCreationPartnershipModalIsOpen} data-testid="finish-creation-partnership" onHide={() => {}}>
      <StyledContainer>
        <SvgContainer>
          <TangiSvgIcon component="confirmationIcon" color="" />
        </SvgContainer>
        <Title>{t('PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.ALL_SET')}</Title>
        <Description>{t('PARTNERSHIP_ADD_EDIT_PARTNER.DESCRIPTION.SHARE_ASSETS')}</Description>
        <ButtonContainer>
          <TangiButton onClick={handleRedirectToAssets} svgIcon="arrowRight" text={t('GENERAL.BUTTONS_TEXT.GO_TO_ASSET_PAGE')} endIcon={true} />
        </ButtonContainer>

        <ButtonContainer>
          <TangiButton
            onClick={() => {
              dispatch(partnershipActions.setFinishCreationPartnershipModalState(false));
            }}
            text={t('GENERAL.BUTTONS_TEXT.LATER')}
            variant={BUTTON_VARIANTS.TERTIARY}
            data-testid="close-finish-creation"
          />
        </ButtonContainer>
      </StyledContainer>
    </StyledModal>
  );
};

export default FinishCreationPartnerModal;
