import { useTranslation } from 'react-i18next';
import { Row, Col, Card } from 'react-bootstrap';

const AssetCardsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <Row xs={1} md={2} lg={3} className="g-4">
      <Col>
        <Card className="card-rounded-2 shadow position-relative">
          <Card.Body className="d-flex align-content-center justify-content-center flex-wrap text-center">
            <Col>
              <i aria-hidden="true" className="fas text-muted fa-2x fa-file-signature" />
              <h2 className="h2 font-weight-normal mb-0 text-muted mt-3">{t('ASSET_PAGE.CARD.NO_ASSETS_FOUND')} </h2>
            </Col>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default AssetCardsEmptyState;
