export enum SystemSecurityType {
  MCAFEE = 'mcAfee',
  PALOALTO = 'paloAlto',
  DARKTRACE = 'darkTrace',
  CYBERARK = 'cyberArk',
  TREND = 'trend',
  CISCO = 'cisco',
  CROWDSTRIKE = 'crowdStrike',
  KNOWBE4 = 'knowBe4',
  INFOSEC = 'infosec',
  SENTINELONE = 'sentinelOne',
}

export type SystemSecuritySelection = SystemSecurityType | 'noneOfTheAbove';
