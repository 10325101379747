import { BarLegendProps } from '@nivo/bar';
import { Datum } from '@nivo/legends';

import { DataType } from '../Widgets/AssetsOverTime/assetsOverTime.logic';
import { BAR_CHART_CONSTS } from 'pages/ClientDashboard/consts';
import { NEUTRAL_SHADES } from 'utils/theme';

interface GetLegendsParams {
  legendKeys: Datum[];
  maxItemsPerLegend?: number;
  legendItemWidth?: number;
  maxLegendItemtTextLength?: number;
  legendTranslateX: number;
  legendTranslateY: number;
}

/**
 * Generates an array of legend properties for a bar chart.
 *
 * @param {Object} params - Parameters for generating legends.
 * @param {Datum[]} params.legendKeys - Array of data items used for generating legend items.
 * @param {number} [params.maxItemsPerLegend=BAR_CHART_CONSTS.MAX_ITEMS_PER_LEGEND] - Maximum number of items per legend.
 * @param {number} [params.legendItemWidth=BAR_CHART_CONSTS.LEGEND_ITEM_WIDTH] - Width of each legend item.
 * @param {number} [params.maxLegendItemtTextLength=BAR_CHART_CONSTS.MAX_LEGEND_ITEM_TEXT_LENGTH] - Maximum text length for each legend item label.
 * @param {number} params.legendTranslateX - X translation for the legend.
 * @param {number} params.legendTranslateY - Y translation for the legend.
 *
 * @returns {BarLegendProps[]} Array of legend properties objects used for rendering the bar chart legends.
 */
export const getLegends = ({
  legendKeys,
  maxItemsPerLegend = BAR_CHART_CONSTS.MAX_ITEMS_PER_LEGEND,
  legendItemWidth = BAR_CHART_CONSTS.LEGEND_ITEM_WIDTH,
  maxLegendItemtTextLength = BAR_CHART_CONSTS.MAX_LEGEND_ITEM_TEXT_LENGTH,
  legendTranslateX,
  legendTranslateY,
}: GetLegendsParams): BarLegendProps[] => {
  const legends: BarLegendProps[] = [];
  let translateY = legendTranslateY;

  const legend = (data: Datum[], translateY: number): BarLegendProps => {
    return {
      data,
      dataFrom: 'keys',
      anchor: 'bottom-left',
      direction: 'row',
      justify: false,
      translateX: legendTranslateX,
      translateY,
      itemsSpacing: 2,
      itemWidth: legendItemWidth,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 1,
      symbolSize: 12,
      itemTextColor: NEUTRAL_SHADES[1000],
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 0.85,
          },
        },
      ],
    };
  };

  for (let i = 0; i < legendKeys.length; i += maxItemsPerLegend) {
    const dataSlice = legendKeys.slice(i, i + maxItemsPerLegend).map((item: Datum) => {
      const label = item.label as string;
      return {
        id: item.id,
        label: label.slice(0, maxLegendItemtTextLength) + (label.length > maxLegendItemtTextLength ? '...' : ''),
        color: item.color,
      };
    });
    legends.push(legend(dataSlice, translateY));
    translateY += 20;
  }
  return legends;
};

/**
 * Aggregates a two-dimensional array of data by type, returning a unique array of Datum objects.
 *
 * @param {DataType[][]} data - A two-dimensional array where each inner array contains objects of type DataType.
 * @returns {Datum[]} An array of Datum objects, each containing a unique type, its corresponding label, and color.
 *
 * @typedef {Object} DataType
 * @property {string} type - The type to aggregate.
 * @property {string} color - The color associated with the type.
 *
 * @typedef {Object} Datum
 * @property {number} id - A unique identifier for the datum.
 * @property {string} label - The label corresponding to the type.
 * @property {string} color - The color associated with the datum.
 *
 * @example
 * const data = [
 *   [{ type: 'Type1', color: '#2151C5' }, { type: 'Type2', color: '#467EE5' }],
 *   [{ type: 'Type1', color: '#2151C5' }, { type: 'Type3', color: '#74A1F8' }]
 * ];
 *
 * const aggregated = aggregateData(data);
 * console.log(aggregated);
 * // Output:
 * // [
 * //   { id: 0, label: 'Type1', color: '#2151C5' },
 * //   { id: 1, label: 'Type2', color: '#467EE5' },
 * //   { id: 2, label: 'Type3', color: '#74A1F8' }
 * // ]
 */
export const aggregateData = (data: DataType[][]): Datum[] => {
  const typeAggregation: Record<string, Datum> = {};

  data.forEach((innerArray) => {
    innerArray.forEach((item) => {
      const { type, color } = item;

      if (!typeAggregation[type]) {
        typeAggregation[type] = { id: Object.keys(typeAggregation).length, label: type, color };
      }
    });
  });

  const aggregatedArray = Object.values(typeAggregation);
  return aggregatedArray;
};
