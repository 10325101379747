import { apiCall } from './utils/restClient';
import { config } from '../../config/config';
import { headers } from './utils/headers';
import { passwordHashing } from './../../utils/passwordHashing';
import { headersmultipart } from '../../_services/utils/headers';

function getAdminUser(page, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'username:asc';
  var url = `${config.apiUrl}${config.apiVersion}/account/users`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function getAdminAccountById(accountId) {
  var url = `${config.apiUrl}${config.apiVersion}/account/${accountId}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAccountsById(accountId) {
  var url = `${config.apiUrl}${config.apiVersion}/account/users?id=${accountId}`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminUsers({ page = 1, limit = 100, sortBy = 'name:asc' }) {
  var url = `${config.apiUrl}${config.apiVersion}/account/users`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    params: {
      page,
      limit,
      // sortBy,
    },
  };

  return apiCall(requestOptions);
}

function getAdminUsersByClient(client, limit = 10000) {
  var url = `${config.apiUrl}${config.apiVersion}/account/users`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    params: { client, limit },
  };

  return apiCall(requestOptions);
}

function createAdminUser(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/invite`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

// function createAdminUser(data) {
//   data.password = passwordHashing.encryptPlainText(data.password);
//   const requestOptions = {
//     url: `${config.apiUrl}${config.apiVersion}/cognito/user`,
//     method: 'POST',
//     headers: headers(),
//     data: data,
//   };

//   return apiCall(requestOptions);
// }

// function createAdminExternalUser(data) {
//   data.password = passwordHashing.encryptPlainText(data.password);
//   const requestOptions = {
//     url: `${config.apiUrl}${config.apiVersion}/user`,
//     method: 'POST',
//     headers: headers(),
//     data: data,
//   };

//   return apiCall(requestOptions);
// }

function updateAdminUser(data, accountId) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/account/${accountId}`,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function uploadProfile(data, id) {
  var formData = new FormData();
  formData.append('file', data[0]);

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file/profile`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: { userId: id },
  };

  return apiCall(requestOptions);
}

function adminResetPassword(email) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/auth/reset-password`,
    method: 'POST',
    headers: headers(),
    data: { emails: [email] },
  };
  return apiCall(requestOptions);
}

export const adminUserService = {
  getAdminUser,
  getAdminUsers,
  getAdminAccountById,
  getAdminUsersByClient,
  createAdminUser,
  updateAdminUser,
  uploadProfile,
  adminResetPassword,
  getAdminAccountsById,
  createAdminUser,
  // createAdminExternalUser,
};
