import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';

import { TangiButton } from '../TangiButton';
import { NEUTRAL_SHADES } from '../../../utils/theme';

export const StyledDropdownButton = styled(TangiButton)`
  gap: 16px;
  .tangi-icon-svg {
    width: 12px;
  }
`;

export const StyledDropDown = styled.div`
  ${({ dropdownStyles }) => css`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    padding-block: 10px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    z-index: 1050;
    font-family: DIN Alternate;
    font-size: 15px;
    margin-top: 8px;
    ${!isEmpty(dropdownStyles) && dropdownStyles}
  `}
`;

export const StyledDropDownItem = styled.div`
  ${({ disabled = false }) => css`
    color: ${NEUTRAL_SHADES.BLACK};
    padding: 10px 20px;
    &.tangi-dropdown-item:hover {
      cursor: ${disabled ? 'default' : 'pointer'};
      background-color: ${!disabled && NEUTRAL_SHADES[100]};
    }
  `}
`;
