import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 72px;
`;

export const Content = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${SPACING[3]};
`;
