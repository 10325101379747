import { RootState } from '_helpers/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IRoles, lawyers } from 'utils/roles';
import { Account } from 'utils/types/account/account';
import { Client, POLICIES, PolicyType } from 'utils/types/client/client';

const isClientWithAttribute = (client: string | Client | null | undefined, field: keyof Client): client is Client => !!(client as Client)[field];

export const useClient = () => {
  const { activeAccount, role } = useSelector((state: RootState) => {
    const { activeAccount, Role } = state.authentication;
    return {
      activeAccount: activeAccount as Account,
      role: Role as IRoles,
    };
  });

  const activeClient: Client = useSelector((state: RootState) => state.lawfirm.activeClient);

  const isLawyer = useMemo(() => lawyers.includes(role), [role]);

  const idFromActiveAccount = typeof activeAccount.client === 'string' ? activeAccount?.client : activeAccount?.client?.id;

  const activeClientId = isLawyer ? activeClient?.id : idFromActiveAccount;
  const activeClientName = isLawyer ? (activeClient as Client)?.name  : (activeAccount?.client as Client)?.name;

  const { clientId } = useParams<{ clientId: string }>();

  const getClientPolicyFile = (type: PolicyType) => {
    const clientFromActiveAccount = activeAccount?.client;

    if (type === POLICIES.TRADE_SECRET) {
      const files = isLawyer ? activeClient?.policyFiles : isClientWithAttribute(clientFromActiveAccount, 'policyFiles') ? clientFromActiveAccount.policyFiles : null;

      return files?.length ? files : null;
    }

    if (type === POLICIES.LLM) {
      const files = isLawyer ? activeClient?.llmFiles : isClientWithAttribute(clientFromActiveAccount, 'llmFiles') ? clientFromActiveAccount.llmFiles : null;

      return files?.length ? files : null;
    }

    return null;
  };

  const getClientIpAudit = () => {
    const clientFromActiveAccount = activeAccount?.client;
    const ipAuditData = isLawyer ? activeClient?.ipAuditData : isClientWithAttribute(clientFromActiveAccount, 'ipAuditData') ? clientFromActiveAccount.ipAuditData : null;
    return ipAuditData;
  };

  const getClientPermissions = () => {
    const clientFromActiveAccount = activeAccount?.client;
    const permissions = isLawyer ? activeClient?.permissions : isClientWithAttribute(clientFromActiveAccount, 'permissions') ? clientFromActiveAccount.permissions : null;
    return permissions;
  };

  return {
    activeAccount,
    isLawyer,
    clientId,
    activeClientId,
    activeClient,
    activeClientName,
    getClientPolicyFile,
    getClientIpAudit,
    getClientPermissions
  };
};
