import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography, TangiModal, TangiChip, TangiButton, TANGI_BUTTON_SIZES } from '_components/TangiLibrary';
import { BUTTON_VARIANTS, NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { BodyContainer, BodyNoteContainer, ChipTextContainer, FooterContainer, HeaderContainer, newIntegrationModalBodyStyle, newIntegrationModalFooterStyle } from './style';

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onConnect: () => void;
}

const NewIntegrationModal = ({ isOpenModal, onCloseModal, onConnect }: Props) => {
  const { t } = useTranslation();

  const renderModalBody = () => {
    return (
      <BodyContainer>
        <TangiSvgIcon component="tangiblyWithSharepoint" />
        <TangiTypography type="subheading">{t('SHARE_POINT_INTEGRATION.SP_INTEGRATION_MODAL.BODY_TEXT')}</TangiTypography>
        <BodyNoteContainer>
          <TangiTypography color={NEUTRAL_SHADES[1000]} type="sub-body">
            {t('SHARE_POINT_INTEGRATION.SP_INTEGRATION_MODAL.BODY_NOTE')}
          </TangiTypography>
        </BodyNoteContainer>
      </BodyContainer>
    );
  };

  const renderModalHeader = () => {
    return (
      <HeaderContainer>
        <TangiChip className="sp-integration-chip" variant="primary" text={<ChipTextContainer>{t('GENERAL.CHIP.NEW')}</ChipTextContainer>} />
        <TangiTypography color={PRIMARY_SHADES[800]} type="heading-lg">
          {t('SHARE_POINT_INTEGRATION.SP_INTEGRATION_MODAL.HEADER_NOTE')}
        </TangiTypography>
      </HeaderContainer>
    );
  };

  const renderModalFooter = () => {
    return (
      <FooterContainer>
        <TangiButton
          size={TANGI_BUTTON_SIZES.MEDIUM}
          variant={BUTTON_VARIANTS.TERTIARY}
          text={t('GENERAL.BUTTONS_TEXT.MAYBE_LATER')}
          loading={false}
          disabled={false}
          onClick={onCloseModal}
          data-testid="sp-maybe-later"
          smallbtn={true}
        />
        <TangiButton
          size={TANGI_BUTTON_SIZES.MEDIUM}
          text={t('SHARE_POINT_INTEGRATION.SP_INTEGRATION_MODAL.CONNECT_NOW')}
          loading={false}
          disabled={false}
          onClick={onConnect}
          data-testid="connect-sp"
          smallbtn={true}
        />
      </FooterContainer>
    );
  };

  return (
    <TangiModal
      show={isOpenModal}
      onHide={onCloseModal}
      header={renderModalHeader()}
      body={renderModalBody()}
      footer={renderModalFooter()}
      slotsStyles={{ bodyContainer: { style: newIntegrationModalBodyStyle }, footerContainer: { style: newIntegrationModalFooterStyle } }}
      isCloseButton={false}
      dataTestId="sp-new-integration"
    />
  );
};

export default NewIntegrationModal;
