import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Divider, Square } from './style';
import { InfoRow } from 'pages/TradeSecretsAnalysis/components/InfoRow/InfoRow';

interface CompetitiveInfoProps {
  subject: string;
  potentialTradeSecretsCount: number;
  squareColor: string;
  company: string;
}

const CompetitiveInfo = ({ subject, potentialTradeSecretsCount, squareColor, company }: CompetitiveInfoProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <InfoRow label={t('COMPETITIVE_ANALYSIS.WIDGET.COMPANIES')} value={company || ''} />
      <Divider />
      <InfoRow label={t('PREDICTED_PORTFOLIO.CHARTS.SUBJECT')} value={subject} icon={<Square color={squareColor} />} />
      <Divider />
      <InfoRow label={t('PREDICTED_PORTFOLIO.WIDGET.POTENTIAL_TREDE_SECRETS')} value={potentialTradeSecretsCount} />
    </Container>
  );
};

export default CompetitiveInfo;
