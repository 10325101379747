import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';
import { useTranslation } from 'react-i18next';
import { debounce, isFunction } from 'lodash';

import { TangiTypography, TangiIconButton } from '_components/TangiLibrary';
import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';
import { DuoContainer } from 'utils/globalStyles';
import { PRIMARY_SHADES } from 'utils/theme';
import { AssociationsContainer, TooltipContainer, UrlLink } from './style';

interface Props {
  children: React.ReactElement;
  numberOfAssociations: number;
  itemText: string;
  onEdit: () => void;
  onDelete: () => void;
  iterateAssociatedItems?: () => void;
  scrollRef: React.RefObject<HTMLDivElement>;
  onShow?: () => void;
  onHide?: () => void;
  disabled?: boolean;
  isShowActions: boolean;
}

const InventionTooltip = ({ children, numberOfAssociations, itemText, scrollRef, disabled = false, onEdit, onDelete, iterateAssociatedItems, onShow, onHide, isShowActions }: Props) => {
  const [isCopiedText, setIsCopiedText] = useState<string>('');
  const [isHoveringElement, setIsHoveringElement] = useState(false);
  const [isHoveringTooltip, setIsHoveringTooltip] = useState(false);
  const tippyInstanceRef = useRef<Instance | null>(null); // To store the Tippy instance

  const { t } = useTranslation();

  const resetTooltipVisibility = () => {
    setIsHoveringElement(false);
    setIsHoveringTooltip(false);
  };

  const handleOnShow = () => {
    if (isFunction(onShow)) {
      onShow();
    }
  };

  const handleOnHide = () => {
    if (isFunction(onHide)) {
      onHide();
    }
  };

  useEffect(() => {
    return () => {
      resetTooltipVisibility();
    };
  }, [disabled]);

  const copyText = useCallback(async () => {
    await navigator.clipboard.writeText(itemText);
    setIsCopiedText(t('PATENT_MAPPING_TOOL.TOOLTIP.COPIED'));

    // Setup the timeout and save its ID
    const timeoutId = setTimeout(() => {
      setIsCopiedText('');
    }, INTERVAL_IN_MILLISECONDS.OneAndHalfSeconds);

    // Cleanup function to clear the timeout
    return () => clearTimeout(timeoutId);
  }, [itemText, t]);

  // Manages tooltip visibility based on element visibility within a scrollable container and hover state.
  const checkVisibilityAndManageTooltip = useCallback(() => {
    if (!scrollRef.current || !tippyInstanceRef.current) return;

    const instance = tippyInstanceRef.current;
    const referenceRect = instance.reference.getBoundingClientRect();
    const containerRect = scrollRef.current.getBoundingClientRect();

    // Check if the top of the element is visible within the scrollable container
    const isTopVisible = referenceRect.top >= containerRect.top && referenceRect.top <= containerRect.bottom;

    // Only show tooltip if the top of the element is visible and either the element or the tooltip is being hovered over
    if (isTopVisible && (isHoveringElement || isHoveringTooltip)) {
      instance.show();
    } else {
      instance.hide();
    }
  }, [isHoveringElement, isHoveringTooltip]);

  const debouncedCheckVisibility = debounce(checkVisibilityAndManageTooltip, 100);

  // Adds a debounced scroll listener to manage tooltip visibility on scroll. Cleans up on component unmount.
  useEffect(() => {
    const scrollContainer = scrollRef.current;
    scrollContainer?.addEventListener('scroll', debouncedCheckVisibility);

    // Perform an initial check
    debouncedCheckVisibility();

    return () => {
      scrollContainer?.removeEventListener('scroll', debouncedCheckVisibility);
      debouncedCheckVisibility.cancel();
    };
  }, [debouncedCheckVisibility]);

  // Attach event listeners to manage tooltip visibility based on hover states. On cleanup, removes these listeners.
  useEffect(() => {
    const instance = tippyInstanceRef.current;
    if (instance) {
      const showTooltip = () => {
        setIsHoveringElement(true);
      };

      const hideTooltip = () => {
        setIsHoveringElement(false);
      };

      instance.reference.addEventListener('mouseenter', showTooltip);
      instance.reference.addEventListener('mouseleave', hideTooltip);

      // Setup interactive tooltip hover state management
      instance.popper.addEventListener('mouseenter', () => setIsHoveringTooltip(true));
      instance.popper.addEventListener('mouseleave', () => setIsHoveringTooltip(false));

      return () => {
        instance.reference.removeEventListener('mouseenter', showTooltip);
        instance.reference.removeEventListener('mouseleave', hideTooltip);
        instance.popper.removeEventListener('mouseenter', () => setIsHoveringTooltip(true));
        instance.popper.removeEventListener('mouseleave', () => setIsHoveringTooltip(false));
      };
    }
  }, []);

  const renderClipboardCopyButton = () => {
    return (
      <Tippy content={isCopiedText || t('PATENT_MAPPING_TOOL.TOOLTIP.COPY')} placement="bottom" interactive={true} hideOnClick={false}>
        <div>
          <TangiIconButton variant="tertiary" icon="copy" onClick={copyText} />
        </div>
      </Tippy>
    );
  };

  const renderActions = () => {
    return (
      <TooltipContainer>
        <AssociationsContainer>
          <UrlLink isClickable={isFunction(iterateAssociatedItems)} onClick={iterateAssociatedItems}>
            <TangiTypography weight="semibold" color={PRIMARY_SHADES[800]}>
              {t(numberOfAssociations === 1 ? 'INVENTION_DISCLOSURE.TOOLTIP.ASSOCIATION' : 'INVENTION_DISCLOSURE.TOOLTIP.ASSOCIATIONS', { NUMBER: numberOfAssociations })}
            </TangiTypography>
          </UrlLink>
        </AssociationsContainer>
        <DuoContainer>
          {renderClipboardCopyButton()}
          {!!isShowActions && (
            <>
              <TangiIconButton variant="tertiary" icon="edit" onClick={onEdit} />
              <TangiIconButton variant="tertiary" icon="delete" onClick={onDelete} />
            </>
          )}
        </DuoContainer>
      </TooltipContainer>
    );
  };

  return (
    <Tippy
      render={renderActions}
      trigger="mouseenter"
      placement="top-end"
      animation={false}
      interactive={true}
      disabled={disabled}
      onCreate={(instance) => (tippyInstanceRef.current = instance)}
      onShow={handleOnShow}
      onHide={handleOnHide}
      offset={[-5, -5]}
      // makes tooltip to be behind the modal
      zIndex={1000}
    >
      {children}
    </Tippy>
  );
};

export default InventionTooltip;
