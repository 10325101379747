import versionInfo from '../version.json';

export const authConstants = {
  SEEN_XRAY_MAPPING: 'seenXrayMapping',
  SEEN_XRAY_EXTRACTION: 'seenXrayExtraction',

  SEEN_LOGIN_DISCLAIMER: 'seenLoginDisclaimer',
  USER: 'user',
  ACTIVATE_ACCOUNT: 'activeAccount',
  FIRST_TIME_LOGIN: 'firstTimeLogin',
  APP_VERSION: 'appVersion',
};

export const CURRENT_APP_VERSION = versionInfo.version;
