import { Form, Modal } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { NEUTRAL_SHADES } from 'utils/theme';

export const StyledAddPartnershipForm = styled(Form)`
  width: 720px;
  padding: 16px 16px 16px;
`;

export const StyledModalHeader = styled(Modal.Header)`
  font-family: 'DIN2014-Regular';
  color: black;
  font-size: 24px;
  padding-inline: 0px;
`;

export const StyledHeader = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledClientName = styled.span`
  font-family: 'DIN2014-Bold';
  margin-left: 4px;
`;

export const PhotoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
`;

export const OuterCircle = styled.div`
  display: flex;
  border: dashed 2px;
  border-color: ${NEUTRAL_SHADES[400]};
  border-radius: 50%;
  background: transparent;
  width: 130px;
  height: 130px;
  fill: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const InnerCircle = styled.div<{ $isLogo: boolean }>`
  ${({ $isLogo: isLogo }: { $isLogo: boolean }) => css`
    border-radius: 50%;
    background: ${isLogo ? 'none' : NEUTRAL_SHADES.LIGHT_GRAY};
    height: 118px;
    width: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const CameraButton = styled.button`
  border-color: transparent;
  background-color: transparent;
`;
export const LogoContainer = styled.img`
  border-radius: 50%;
  background: ${NEUTRAL_SHADES.LIGHT_GRAY};
  opacity: 0.6;
  height: 118px;
  width: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CameraImg = styled.img`
  position: absolute;
  top: 52px;
  right: 52px;
  z-index: 1;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 8px;
`;

export const Separator = styled.div`
  border-bottom: 1.5px solid #00000020;
  margin: 12px 0;
`;

export const FirstPageButtonsContainer = styled.div`
  margin-left: auto;
  width: 96px;
`;

export const SecondPageButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.div`
  width: 70px;
  margin-right: auto;
`;

export const DoneButtonContainer = styled.div`
  width: 96px;
  margin-left: auto;
`;

export const AgreementContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
