import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';
import TradeSecretsAnalysisWidget from './TradeSecretsAnalysisWidget';
import CompetitiveActionLink from '../CompetitiveActionLink/CompetitiveActionLink';
import { WidgetsSectionContainer, RowContainer } from './style';
import Skeleton from 'react-loading-skeleton';

interface Props {
  widgets: { title: TRANSLATION_KEY; number: number }[];
  isCompetitiveAnalysis?: boolean;
  isLoading?: boolean;
}
const WidgetsSection = ({ widgets, isCompetitiveAnalysis = false, isLoading = false }: Props) => {
  return (
    <RowContainer>
      <WidgetsSectionContainer>
        {widgets?.map((widget, index) =>
          isLoading ? <Skeleton key={index} height="80px" width="260px" /> : <TradeSecretsAnalysisWidget key={index} title={widget.title || ''} number={widget.number || 0} />,
        )}
      </WidgetsSectionContainer>
      {!isCompetitiveAnalysis && <CompetitiveActionLink />}
    </RowContainer>
  );
};
export default WidgetsSection;
