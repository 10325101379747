import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { RESULT_STATUS } from 'utils/enums';
import { SharedRecipient } from 'utils/types/assets/assets';
import { NEUTRAL_SHADES } from 'utils/theme';
import { TypographyContainer, TooltipSubtitle, AcknowledgedgementBullet, TooltipDivider } from './style';

interface Props {
  acknowledgedRecipientsList: SharedRecipient[];
  notAcknowledgedRecipientsList: SharedRecipient[];
}

const AcknowledgementTooltip = ({ acknowledgedRecipientsList, notAcknowledgedRecipientsList }: Props) => {
  const { t } = useTranslation();

  const MAX_RECIPIENTS = 2;

  return (
    <TypographyContainer color={NEUTRAL_SHADES.WHITE}>
      {!!acknowledgedRecipientsList?.length && (
        <>
          <TooltipSubtitle>
            <AcknowledgedgementBullet type={RESULT_STATUS.SUCCESS} />
            <TangiTypography color={NEUTRAL_SHADES.WHITE} weight="semibold">
              {t('ASSET_PAGE.TOOLTIP.ACKNOWLEDGED')}
            </TangiTypography>
          </TooltipSubtitle>
          {acknowledgedRecipientsList.slice(0, MAX_RECIPIENTS).map((recipient: SharedRecipient) => {
            return (
              <TangiTypography color={NEUTRAL_SHADES.WHITE} key={recipient._id}>
                {recipient?.account?.isActive ? recipient?.account?.displayName : recipient?.fullName || recipient.email}
              </TangiTypography>
            );
          })}
          {acknowledgedRecipientsList.length > MAX_RECIPIENTS && <TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('ASSET_PAGE.TOOLTIP.AND_MORE')}</TangiTypography>}
        </>
      )}
      {!!acknowledgedRecipientsList?.length && !!notAcknowledgedRecipientsList?.length && <TooltipDivider />}
      {!!notAcknowledgedRecipientsList?.length && (
        <>
          <TooltipSubtitle>
            <AcknowledgedgementBullet type={RESULT_STATUS.ERROR} />
            <TangiTypography color={NEUTRAL_SHADES.WHITE} weight="semibold">
              {t('ASSET_PAGE.TOOLTIP.NOT_ACKNOWLEDGED')}
            </TangiTypography>
          </TooltipSubtitle>
          {notAcknowledgedRecipientsList.slice(0, MAX_RECIPIENTS).map((recipient: SharedRecipient) => (
            <TangiTypography color={NEUTRAL_SHADES.WHITE} key={recipient._id}>
              {recipient?.account?.isActive ? recipient?.account?.displayName : recipient?.fullName}
            </TangiTypography>
          ))}
          {notAcknowledgedRecipientsList.length > MAX_RECIPIENTS && <TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('ASSET_PAGE.TOOLTIP.AND_MORE')}</TangiTypography>}
        </>
      )}
    </TypographyContainer>
  );
};

export default AcknowledgementTooltip;
