import Dropzone, { FileRejection } from 'react-dropzone';
import { MAX_FILE_SIZE } from '_constants/fileConstants';
import { FileDropContainer } from './styles';
import { DropZoneContent } from './components/DropZone/DropZoneContent';
import { CSSProperties, ReactNode } from 'react';

interface TangiDragAndDropFileProps {
  acceptedFilesList: string;
  isEditMode?: boolean;
  customDragAndDropRejectText?: string;
  customDragAndDropText?: string;
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  renderOnSuccessCmp?: ReactNode;
  height?: CSSProperties['height'];
}

export const TangiDragAndDropFile = ({
  onDrop,
  acceptedFilesList,
  isEditMode,
  customDragAndDropRejectText = '',
  customDragAndDropText = '',
  renderOnSuccessCmp,
  height,
}: TangiDragAndDropFileProps) => {
  return (
    <Dropzone accept={acceptedFilesList} onDrop={onDrop} maxSize={MAX_FILE_SIZE}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div>
          <FileDropContainer $height={height} {...getRootProps()}>
            {!(isDragActive && isDragReject) && <input {...getInputProps()} />}
            <DropZoneContent
              isEditMode={!!isEditMode}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              customDragAndDropText={customDragAndDropText}
              customDragAndDropRejectText={customDragAndDropRejectText}
            />
          </FileDropContainer>
          {!isDragReject && renderOnSuccessCmp}
        </div>
      )}
    </Dropzone>
  );
};
