import { ClientRequestParams } from '../client/client';
import { XRayData } from '../xRay/xRay';

export enum ContractKeys {
  confidentiality_provision = 'confidentiality_provision',
  trade_secret = 'trade_secret',
  clearly_defined = 'clearly_defined',
  termination = 'termination',
  returning_or_destroying = 'returning_or_destroying',
  survival_clause = 'survival_clause',
  discovering_information = 'discovering_information',
  limits_on_liability = 'limits_on_liability',
  sharing_without_restrictions = 'sharing_without_restrictions',
  mandatory_disclosure = 'mandatory_disclosure',
}

export type ContractChecklist = Record<ContractKeys, boolean | null>;

export type ContractChecklistMatch = Record<ContractKeys, string | null>;

export interface ContractResponse {
  checklist_results: ContractChecklist;
  checklist_match: ContractChecklistMatch;
}

export interface ContractData extends XRayData {
  response: ContractResponse;
  score: number;
}

export type ContractRequestInfo = {
  contractResultId: string;
  source: string;
  clientId: string;
};

export interface ContractApplicationUploadData {
  clientId: string;
  files: File[];
}

export interface GetContractLastResult extends ClientRequestParams {
  contractResultId: string;
}
