import { CSSProperties, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IconTypes, TangiChip, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { getStepItemTypographyStyle } from './utils';
import { useHandleSteps } from './useHandleSteps';
import { StepItem, StepperContainer, StepsContainer, View } from './styles';

export interface TangiStepperStep<T extends string> {
  key: T;
  name: string;
  component: ReactNode;
  index?: number;
  icon?: IconTypes;
  isComingSoon?: boolean;
}

export interface TangiStepperProps<T extends string> {
  steps: TangiStepperStep<T>[];
  slotsStyle?: {
    sideBar?: CSSProperties;
    view?: CSSProperties;
  };
  customHandleStepSkip?: (key: string) => void;
}

export const TangiStepper = <T extends string>({ steps, slotsStyle, customHandleStepSkip }: TangiStepperProps<T>) => {
  const { currentStepKey, handleStepChange, clearCurrentStepFromUrl } = useHandleSteps();

  const { t } = useTranslation();

  const currentStep = steps.find(({ key }) => currentStepKey === key);

  useEffect(() => {
    handleStepChange(steps[0].key);
    return () => {
      clearCurrentStepFromUrl();
    };
  }, []);

  const onStep = (key: string, isComingSoon: boolean) => {
    if (isComingSoon) return;
    if (customHandleStepSkip) customHandleStepSkip(key);
    else handleStepChange(key);
  };

  return (
    <StepperContainer>
      <StepsContainer style={slotsStyle?.sideBar}>
        {steps.map(({ key, name, icon, isComingSoon }) => {
          const isSelected = key === currentStepKey;
          return (
            <div key={key}>
              <StepItem onClick={() => onStep(key, !!isComingSoon)} $selected={isSelected} $disabled={!!isComingSoon}>
                <TangiTypography {...getStepItemTypographyStyle(isSelected, isComingSoon)}>{t(name)}</TangiTypography>
                {icon && <TangiSvgIcon component={icon} color="" />}
              </StepItem>
              {isComingSoon && <TangiChip text={t('IP_AUDIT.COMING_SOON')} maxWidth={100} variant="secondary" />}
            </div>
          );
        })}
      </StepsContainer>
      <View style={slotsStyle?.view}>{currentStep && currentStep.component}</View>
    </StepperContainer>
  );
};
