import React from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import GenerateFiltersDropDown from './GenerateFiltersDropDown';
import { DropdownConfig, FILTERS_ID, FilterData, FilterDataItem, RenderSelectedComponentProps, SelectedFilter } from '../types';
import { RootState } from '_helpers';

const SelectedFilterDropDown: React.FC<RenderSelectedComponentProps> = ({ selectedFilter, filter, onChange, setSelectedOptions, setShowFilterOptions, setShow }) => {
  const { authors, projects, tags, businessValues, assetTypes, commercialProduct, businessRelated, allRecipients } = useSelector((state: RootState) => state.assetMetaData);
  const importanceFields = useSelector((state: RootState) => state.clientSettings.settings.importanceFields);

  const config: DropdownConfig = {
    [FILTERS_ID.PROJECTS]: {
      title: t('ASSET_PAGE.DROP_DOWN.PROJECTS'),
      itemList: projects,
    },
    [FILTERS_ID.TAGS]: {
      title: t('ASSET_PAGE.DROP_DOWN.TAGS'),
      itemList: tags,
    },
    [FILTERS_ID.BUSINESS_VALUES]: {
      title: t('ASSET_PAGE.DROP_DOWN.BUSINESS_VALUE'),
      itemList: businessValues,
    },
    [FILTERS_ID.CONTRIBUTOR]: {
      title: t('ASSET_PAGE.DROP_DOWN.CONTRIBUTOR'),
      itemList: authors,
    },
    [FILTERS_ID.ASSET_TYPE]: {
      title: t('ASSET_PAGE.DROP_DOWN.ASSET_TYPE'),
      itemList: assetTypes,
    },
    [FILTERS_ID.IMPORTANCE]: {
      title: t('ASSET_PAGE.DROP_DOWN.CATEGORIZATION'),
      itemList: importanceFields,
    },
    [FILTERS_ID.BUSINESS_RELATED]: {
      title: t('ASSET_PAGE.DROP_DOWN.BUSINESS_RELATED'),
      itemList: businessRelated,
    },
    [FILTERS_ID.COMMERCIAL_PRODUCT]: {
      title: t('ASSET_PAGE.DROP_DOWN.COMMERCIAL_PRODUCT'),
      itemList: commercialProduct,
    },
    [FILTERS_ID.RECIPIENTS]: {
      title: t('ASSET_PAGE.DROP_DOWN.RECIPIENTS'),
      itemList: allRecipients,
    },
  };

  const getFilterDataItem = (filter: FilterData, key: FILTERS_ID): FilterDataItem[] | undefined => {
    if (key in filter && !Array.isArray(filter[key])) {
      return undefined;
    }
    return filter[key] as FilterDataItem[] | undefined;
  };

  if (!selectedFilter || !config[selectedFilter.id as keyof DropdownConfig]) {
    return null;
  }

  const selectedData = getFilterDataItem(filter, selectedFilter.id);
  const filterSelected: SelectedFilter[] = selectedData && Array.isArray(selectedData) ? (selectedData.map((item) => ({ ...item })) as SelectedFilter[]) : [];

  const currentConfig = config[selectedFilter.id as keyof DropdownConfig];

  const applySelection = (selectedItems: SelectedFilter[], id: FILTERS_ID) => {
    onChange(selectedItems, id);
    setSelectedOptions((prev) => ({
      ...prev,
      [id]: selectedItems?.map((item) => item.id),
    }));
    setShowFilterOptions(false);
    setShow(false);
  };

  const handleBackNavigation = () => {
    setShowFilterOptions(false);
  };
  return (
    <GenerateFiltersDropDown
      filterTitle={currentConfig.title}
      id={selectedFilter.id}
      selectedItems={filterSelected}
      itemList={currentConfig.itemList}
      onChange={applySelection}
      handleBackNavigation={handleBackNavigation}
    />
  );
};

export default SelectedFilterDropDown;
