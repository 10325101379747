import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { breakWordStyles, Container, SubTitleAndDescription } from './style';

const HowItWorksStep = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.HOW_IT_WORKS.DESCRIPTION')}</TangiTypography>

      <SubTitleAndDescription>
        <TangiTypography type="subheading" weight="semibold">
          {t('SHARE_POINT_WIZARD.HOW_IT_WORKS.SECURITY')}
        </TangiTypography>
        <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.HOW_IT_WORKS.SECURITY_DESCRIPTION')}</TangiTypography>
      </SubTitleAndDescription>

      <SubTitleAndDescription>
        <TangiTypography type="subheading" weight="semibold">
          {t('SHARE_POINT_WIZARD.HOW_IT_WORKS.DATA_MANAGEMENT')}
        </TangiTypography>
        <TangiTypography type="subheading" customStyles={breakWordStyles}>
          {t('SHARE_POINT_WIZARD.HOW_IT_WORKS.DATA_MANAGEMENT_DESCRIPTION')}
        </TangiTypography>
      </SubTitleAndDescription>

      <SubTitleAndDescription>
        <TangiTypography type="subheading" weight="semibold">
          {t('SHARE_POINT_WIZARD.HOW_IT_WORKS.ACTIVITY_LOG')}
        </TangiTypography>
        <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.HOW_IT_WORKS.ACTIVITY_LOG_DESCRIPTION')}</TangiTypography>
      </SubTitleAndDescription>

      <SubTitleAndDescription>
        <TangiTypography type="subheading" weight="semibold">
          {t('SHARE_POINT_WIZARD.HOW_IT_WORKS.USERS_AND_MANAGEMENT')}
        </TangiTypography>
        <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.HOW_IT_WORKS.USERS_AND_MANAGEMENT_DESCRIPTION')}</TangiTypography>
      </SubTitleAndDescription>
    </Container>
  );
};

export default HowItWorksStep;
