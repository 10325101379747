import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { TANGI_BUTTON_SIZES, TangiButton, TangiCheckbox, TangiTypography } from '_components/TangiLibrary';
import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';
import { RootState } from '_helpers';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { HasSeenInformation } from 'utils/types/account/account';
import { SPACING } from 'utils/theme';

const CustomFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${SPACING[4]};
`;

const CustomBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  height: 116px;
`;

interface Props {
  isShow: boolean;
  isHide: () => void;
  onConfirm: (data: HasSeenInformation) => void;
}

const styleCss = { height: '310px', width: '450px' };

const EditItemModal = ({ isShow, isHide, onConfirm }: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<HasSeenInformation>();
  const isLoading = useSelector((state: RootState) => state.authentication.loading);

  const onSubmit = (data: HasSeenInformation) => {
    onConfirm(data);
  };

  const renderHeader = () => {
    return <TangiTypography type="heading-lg">{t('INVENTION_DISCLOSURE.MODAL.EDIT_ITEM')}</TangiTypography>;
  };

  const renderBody = () => {
    return (
      <CustomBody>
        <TangiTypography type="subheading">{t('INVENTION_DISCLOSURE.MODAL.EDIT_ITEM_CONTENT')}</TangiTypography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TangiCheckbox label={t('INVENTION_DISCLOSURE.MODAL.DONT_SHOW_AGAIN_EDIT')} register={register} name="editInvention" />
        </form>
      </CustomBody>
    );
  };

  const renderFooter = () => {
    return (
      <CustomFooter>
        <TangiButton variant={BUTTON_VARIANTS.TERTIARY} text={t('GENERAL.BUTTONS_TEXT.CANCEL')} onClick={isHide} size={TANGI_BUTTON_SIZES.SMALL} data-testid="tangi-popup-cancel" />
        <TangiButton text={t('GENERAL.BUTTONS_TEXT.EDIT')} onClick={handleSubmit(onSubmit)} size={TANGI_BUTTON_SIZES.SMALL} data-testid="tangi-popup-confirm" svgIcon="edit" loading={isLoading} />
      </CustomFooter>
    );
  };

  return <TangiModal show={isShow} header={renderHeader()} body={renderBody()} footer={renderFooter()} onHide={isHide} containerStyle={styleCss} />;
};

export default EditItemModal;
