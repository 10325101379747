import { RootState } from '_helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { XRAY_JOB_STATUS, XrayLoaders } from 'utils/types/xRay/xRay';

/**
 * Custom hook for determining the status of an X-ray job using Redux state selectors.
 * It leverages `useMemo` for performance optimization, recalculating the job status
 * only when one of its dependencies changes.
 *
 * @template Data - Type extending an object with optional `status` and `status_finder` properties
 *                   of type `XRAY_JOB_STATUS`.
 * @template RequestInfo - Type that is either any object or `null`.
 *
 * @param {Function} loadersSelector - Selector for X-ray loaders state.
 * @param {Function} dataSelector - Selector for job data state.
 * @param {Function} [requestInfoSelector] - Optional selector for additional request info.
 * @param {Function} [isCanceledSelector] - Optional selector for job cancellation status.
 * @returns {XRAY_JOB_STATUS} The current job status based on the provided selectors.
 */

const useXRayJobStatus = <
  Data extends { status?: XRAY_JOB_STATUS; status_finder?: XRAY_JOB_STATUS }, // Ensuring Data has a status and/or status_finder property
  RequestInfo extends object | null, // Constrain RequestInfo to be an object or null
>(
  loadersSelector: (state: RootState) => XrayLoaders,
  dataSelector: (state: RootState) => Data,
  requestInfoSelector?: (state: RootState) => RequestInfo,
  isCanceledSelector?: (state: RootState) => boolean, // Making isCanceledSelector truly optional
): XRAY_JOB_STATUS => {
  const { loaders, data, requestInfo, isCanceled } = useSelector((state: RootState) => ({
    loaders: loadersSelector(state),
    data: dataSelector(state),
    requestInfo: requestInfoSelector ? requestInfoSelector(state) : null,
    isCanceled: isCanceledSelector ? isCanceledSelector(state) : false, // Default to false if isCanceledSelector is not provided
  }));

  const jobStatus = useMemo(() => {
    const isLoading = loaders.startLoader || loaders.getResultLoader || data?.status === XRAY_JOB_STATUS.STARTED;

    // Evaluate patent specific loading conditions
    const isAdditionallyLoading = data?.status_finder === XRAY_JOB_STATUS.STARTED || data?.status === XRAY_JOB_STATUS.FINDER_SUCCEEDED;
    if (isCanceled) return XRAY_JOB_STATUS.BLANK;
    if (isLoading || isAdditionallyLoading) return XRAY_JOB_STATUS.STARTED;
    //extracting scenario
    if (data?.status_finder === XRAY_JOB_STATUS.SUCCEEDED) return XRAY_JOB_STATUS.SUCCEEDED;
    if (data?.status_finder === XRAY_JOB_STATUS.FAILED) return XRAY_JOB_STATUS.FAILED;

    //contract & invention scenarios
    if (data?.status === XRAY_JOB_STATUS.SUCCEEDED && !data?.status_finder) return XRAY_JOB_STATUS.SUCCEEDED;
    if (data?.status === XRAY_JOB_STATUS.FAILED && !data?.status_finder) return XRAY_JOB_STATUS.FAILED;
    //continuation scenario
    if (data?.status === XRAY_JOB_STATUS.SUCCEEDED) return XRAY_JOB_STATUS.SUCCEEDED;
    if (data?.status === XRAY_JOB_STATUS.FAILED) return XRAY_JOB_STATUS.FAILED;

    if (data?.status && requestInfo) return data.status;
    return XRAY_JOB_STATUS.BLANK;
  }, [loaders, requestInfo, data, isCanceled]);

  return jobStatus;
};

export default useXRayJobStatus;
