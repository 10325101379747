import { headers } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { IDFTextFinderResponse, InventionDisclosureData } from 'utils/types/inventionDisclosure/inventionDisclosure';

class InventionDisclosureAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;
  static idfEndpoint = `${InventionDisclosureAdapter.baseEndPoint}/invention_disclosure`;

  async inventionDisclosureTextFinder(inventionDisclosureText: string): Promise<IDFTextFinderResponse> {
    const res = await HttpClientService.post<IDFTextFinderResponse>(`${InventionDisclosureAdapter.idfEndpoint}/`, { inventionDisclosureText }, { headers: headers() });
    return res.data;
  }

  async getInventionDisclosureResults(inventionDisclosureSearchId: string): Promise<InventionDisclosureData> {
    const res = await HttpClientService.get<InventionDisclosureData>(`${InventionDisclosureAdapter.idfEndpoint}/${inventionDisclosureSearchId}`, { headers: headers() });
    return res.data;
  }
}

const inventionDisclosureAdapter = new InventionDisclosureAdapter();
export default inventionDisclosureAdapter;
