import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'DIN2014-REGULAR';
  display: flex;
  flex-direction: column;
  min-height: 100px;
  height: fit-content;
  font-size: 16px;
  color: black;
  padding: 16px;
  gap: 16px;
`;
export const Title = styled.div`
  display: flex;
  width: 100%;
  font-size: 24px;
  color: 'black';
`;
export const Body = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 8px;
`;
export const Buttons = styled.div`
  justify-items: flex-end;
  display: flex;
  gap: 16px;
  width: fit-content;
  margin-left: auto;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  p {
    margin: 0 0 0 16px;
    font-size: 14px;
  }
`;

export const BlurContainer = styled.div`
  position: absolute;
  z-index: 101; // Sidebar has z-index of 100
  left: -20%;
  backdrop-filter: blur(5px);
  height: 100vh;
  width: 120vw;
`;

export const StyledLine = styled.hr`
  opacity: 0.1;
  margin: 0;
`;
