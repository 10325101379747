import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyWidgetProps, WidgetCardProps, WidgetDataObject } from '../Widgets.types';
import WidgetCard from '../WidgetCard';
import WidgetTitle from '../WidgetTitle';
import { PieChart } from '../../Charts';
import { useHistory, useParams } from 'react-router-dom';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';

export interface Props extends WidgetCardProps {
  data: {
    total: number;
    assetsByDepartments: WidgetDataObject[];
  };
}

const DataByDepartments = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { data } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const emptyState: EmptyWidgetProps = {
    emptyTitle: t('DASHBOARD_PAGE.WIDGETS.DATA_BY_DEPARTMENTS.EMPTY_STATE.TITLE'),
    emptyDescription: t('DASHBOARD_PAGE.WIDGETS.DATA_BY_DEPARTMENTS.EMPTY_STATE.DESCRIPTION'),
    emptyButton: {
      text: t('DASHBOARD_PAGE.WIDGETS.DATA_BY_DEPARTMENTS.EMPTY_STATE.BUTTON_CTA_ASSETS'),
      onClick: () => {
        history.push(generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId }));
      },
    },
  };

  return (
    <WidgetCard {...props} ref={ref} emptyState={emptyState}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.DATA_BY_DEPARTMENTS.TITLE')} />
        <PieChart margin={{ top: 100, right: 0, bottom: 120, left: 0 }} data={data.assetsByDepartments} total={data.total} totalText={t('DASHBOARD_PAGE.WIDGETS.TOTAL_ASSETS')} />
        {props.children}
      </>
    </WidgetCard>
  );
});

export default DataByDepartments;
