import { useEffect } from 'react';

import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';

/**
 * React hook for scheduling periodic checks on X-ray analysis processes.
 *
 * Sets up an interval to periodically execute a callback function, aimed at monitoring the status of various
 * X-ray analysis tasks, such as patent, contract, and invention disclosure. The hook initiates the interval
 * based on a provided unique search ID for an ongoing analysis process and stops the interval upon process
 * completion or component dismount.
 *
 * @param {string | undefined} resultId - ID for the X-Ray search to be monitored, initiating the interval.
 * @param {boolean} isFinished - Flag indicating whether the analysis process has finished, which stops the interval.
 * @param {() => void} checkFunction - Callback function executed at each interval.
 * @param {INTERVAL_IN_MILLISECONDS} intervalMilliseconds - Interval frequency in milliseconds.
 * @returns {void}
 */
const useXRayIntervalCheck = (resultId: string | undefined, isFinished: boolean, checkFunction: () => void, intervalMilliseconds: INTERVAL_IN_MILLISECONDS): void => {
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    // Start the interval if resultId exists and the job is not finished
    const shouldStartInterval = resultId && !isFinished;

    if (shouldStartInterval) {
      // Clear any existing interval to prevent duplicates
      if (intervalId) clearInterval(intervalId);
      // Set a new interval
      intervalId = setInterval(checkFunction, intervalMilliseconds);
    } else {
      // Clear the interval if the conditions are not met
      if (intervalId) clearInterval(intervalId);
    }

    // Cleanup function for when the component unmounts or conditions change
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [resultId, isFinished, checkFunction, intervalMilliseconds]);
};

export default useXRayIntervalCheck;
