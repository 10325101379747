import { useTranslation } from 'react-i18next';

import { TangiButton, TangiMultiSelect, TangiSearch } from '_components/TangiLibrary';
import { FilterSearch, FiltersContainer, FiltersRowContainer } from './style';
import Tippy from '@tippyjs/react';
import { FilterConfigType } from 'pages/TradeSecretsAnalysis/types';

const PortfolioFilters = ({
  isCompetitive = false,
  filtersOptions,
  searchValue,
  setSearchValue,
  filter,
  setFilter,
  initialFilter,
  isFilterApplied,
  onClearAll,
  handleSearch,
  isTableLoading,
  filterConfig = {
    enabledFilters: [FilterConfigType.Subjects, FilterConfigType.Patents, FilterConfigType.Years, FilterConfigType.Companies],
  },
}) => {
  const { t } = useTranslation();
  const { subjects, patents, years, companies } = filtersOptions;

  const subjectsOptions = subjects.map((subject) => ({ id: subject.id, displayName: subject.value }));
  const patentsOptions = patents.map((patent) => ({ id: patent.id, displayName: patent.value }));
  const yearsOptions = years.map((year) => ({ id: year, displayName: year }));
  const companiesOptions = companies?.map((company) => ({ id: company, displayName: company }));

  const isFilterEnabled = (filterType) => filterConfig.enabledFilters.includes(filterType);

  const handleClearAll = () => {
    const clearedFilters = {
      patents: [],
      years: [],
      subjects: [],
      search: '',
    };

    setFilter(() => ({
      ...clearedFilters,
      ...initialFilter,
    }));

    onClearAll();
  };

  const handleFilterChange = (selectedItems, id) => {
    setFilter({ ...filter, [id]: selectedItems });
  };

  return (
    <FilterSearch>
      <Tippy content={<div className="ff-din-regular">{t('PREDICTED_PORTFOLIO.FILTERS.SEARCH')}</div>}>
        <div>
          <TangiSearch handleSearch={handleSearch} setSearchValue={setSearchValue} searchValue={searchValue} placeholder={t('PREDICTED_PORTFOLIO.FILTERS.SEARCH')} width="250px" />
        </div>
      </Tippy>

      <FiltersRowContainer>
        <FiltersContainer data-testid="portfolio-filters">
          {isCompetitive && isFilterEnabled(FilterConfigType.Companies) && (
            <TangiMultiSelect
              filterTitle={t('PREDICTED_PORTFOLIO.FILTERS.COMPENY')}
              id="companies"
              testId="companies"
              selectedItems={filter.companies}
              itemList={companiesOptions}
              onChange={handleFilterChange}
              isSearch
              disabled={isTableLoading}
            />
          )}
          {isFilterEnabled(FilterConfigType.Patents) && (
            <TangiMultiSelect
              filterTitle={t('PREDICTED_PORTFOLIO.FILTERS.PATENT')}
              id="patents"
              testId="patents"
              selectedItems={filter.patents}
              itemList={patentsOptions}
              onChange={handleFilterChange}
              isSearch
              disabled={isTableLoading}
            />
          )}
          {isFilterEnabled(FilterConfigType.Years) && (
            <TangiMultiSelect
              filterTitle={t('PREDICTED_PORTFOLIO.FILTERS.YEAR')}
              id="years"
              testId="years"
              selectedItems={filter.years}
              itemList={yearsOptions}
              onChange={handleFilterChange}
              disabled={isTableLoading}
            />
          )}
          {isFilterEnabled(FilterConfigType.Subjects) && (
            <TangiMultiSelect
              filterTitle={t('PREDICTED_PORTFOLIO.FILTERS.SUBJECT')}
              id="subjects"
              testId="subjects"
              selectedItems={filter.subjects}
              itemList={subjectsOptions}
              onChange={handleFilterChange}
              isSearch
              disabled={isTableLoading}
            />
          )}
          {isFilterApplied && (
            <TangiButton
              smallbtn
              font={14}
              variant="tertiary"
              text={t('PREDICTED_PORTFOLIO.FILTERS.CLEAR_ALL')}
              size="small"
              onClick={handleClearAll}
              onChange={handleFilterChange}
              data-testid="clear-all-filters"
              disabled={isTableLoading}
            />
          )}
        </FiltersContainer>
      </FiltersRowContainer>
    </FilterSearch>
  );
};

export default PortfolioFilters;
