import { useState, forwardRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { isFunction, kebabCase } from 'lodash';

import { ButtonsGroupContainer, CardContainer, HeaderContainer, LeftNavButtonContainer, RightNavButtonContainer, StyledButton, StyledButtonText } from './style';
import { CHART_OPTIONS } from './type';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { ChartContainer } from '../Charts/styles';
import { NEUTRAL_SHADES } from 'utils/theme';

interface Props {
  title: string;
  isToggleButton?: boolean;
  handleToggle?: (chart: CHART_OPTIONS) => void;
  children: React.ReactElement;
  height?: string;
  width?: string;
  className?: string;
  hasLeftArrowButton?: boolean;
  hasRightArrowButton?: boolean;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  dataTestId?: string;
}

const TradeSecretsAnalysisCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      isToggleButton = false,
      handleToggle,
      children,
      height,
      width,
      className,
      hasLeftArrowButton = false,
      hasRightArrowButton = false,
      onLeftClick,
      onRightClick,
      dataTestId = 'ts-analysis-card',
    },
    ref,
  ) => {
    const [currentChart, setCurrentChart] = useState<CHART_OPTIONS>(CHART_OPTIONS.TREE);

    const handleOnClick = (chart: CHART_OPTIONS) => {
      setCurrentChart(chart);
      isFunction(handleToggle) && handleToggle(chart);
    };

    const renderChartButton = (chart: CHART_OPTIONS) => {
      return (
        <StyledButton onClick={() => handleOnClick(chart)} disabled={currentChart === chart} variant="secondary" data-testid={`chart-button-${kebabCase(chart)}`}>
          <StyledButtonText type="sub-body" disabled={currentChart === chart}>
            {chart}
          </StyledButtonText>
        </StyledButton>
      );
    };

    const toggleButton = () => {
      return (
        <ButtonsGroupContainer>
          <ButtonGroup size="sm">
            {renderChartButton(CHART_OPTIONS.TREE)}
            {renderChartButton(CHART_OPTIONS.HEAT)}
          </ButtonGroup>
        </ButtonsGroupContainer>
      );
    };

    return (
      <CardContainer height={height} width={width} className={className} ref={ref} data-testid={dataTestId}>
        <HeaderContainer>
          <TangiTypography type="heading-md" weight="semibold">
            {title}
          </TangiTypography>
          {isToggleButton && toggleButton()}
        </HeaderContainer>
        <ChartContainer>{children}</ChartContainer>
        {hasLeftArrowButton && (
          <LeftNavButtonContainer onClick={onLeftClick}>
            <TangiSvgIcon color={NEUTRAL_SHADES[800]} component="leftCircleArrow" />
          </LeftNavButtonContainer>
        )}
        {hasRightArrowButton && (
          <RightNavButtonContainer onClick={onRightClick}>
            <TangiSvgIcon color={NEUTRAL_SHADES[800]} component="rightCircleArrow" />
          </RightNavButtonContainer>
        )}
      </CardContainer>
    );
  },
);

export default TradeSecretsAnalysisCard;
