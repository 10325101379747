import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TangiButton, TangiToast } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';

import { RootState } from '_helpers';
import { downloadAllAssetsFiles, checkDownloadAssetsFilesStatus } from 'redux-toolkit/thunks/assetsThunks';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';

interface DownloadAssetsFilesButtonProps {
  clientId: string;
}

const DownloadAssetsButton = ({ clientId }: DownloadAssetsFilesButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const isDownloadAssetFilesLoading = useSelector((state: RootState) => state.asset.isDownloadAssetFilesLoading);
  const jobStatus = useSelector((state: RootState) => state.asset.downloadAssetsFilesJobStatus?.jobStatus);
  const jobId = useSelector((state: RootState) => state.asset.downloadAssetsFilesJobStatus?.jobId);
  const downloadUrl = useSelector((state: RootState) => state.asset.downloadAssetFilesUrl);
  const assets = useSelector((state: RootState) => state.asset.assets);
  const clientName = useMemo(() => {
    return activeAccount?.client ? activeAccount.client.name : activeClient?.name;
  }, [activeAccount, activeClient]);

  const [toastProps, setToastProps] = useState({ show: false, text: '', type: '' });

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (jobStatus === XRAY_JOB_STATUS.STARTED) {
      intervalId = setInterval(
        () => {
          dispatch(checkDownloadAssetsFilesStatus(jobId));
        },
        assets.length > 100 ? INTERVAL_IN_MILLISECONDS.FortySeconds : INTERVAL_IN_MILLISECONDS.TenSeconds,
      );
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [jobStatus, jobId, dispatch]);

  useEffect(() => {
    if (downloadUrl && jobStatus === XRAY_JOB_STATUS.SUCCEEDED) {
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = `${clientName}_assets_files.zip`;
      document.body.appendChild(anchor);
      anchor.click();

      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(anchor);
      setToastProps({
        show: true,
        text: t('ASSET_PAGE.TOAST.ASSETS_DOWNLOADED_SUCCESSFULLY'),
        type: 'success',
      });
    }
  }, [downloadUrl, jobStatus, clientName, t]);

  const handleDownloadClick = () => {
    dispatch(downloadAllAssetsFiles(clientId));
  };

  const handleCloseToast = () => {
    setToastProps({ ...toastProps, show: false });
  };
  return (
    <>
      <TangiButton
        variant="secondary"
        text={t('ASSET_PAGE.CONTENT.FILES')}
        onClick={handleDownloadClick}
        svgIcon="download"
        disabled={isDownloadAssetFilesLoading}
        loading={isDownloadAssetFilesLoading}
        smallbtn
      />
      {toastProps.show && <TangiToast type={toastProps.type} text={toastProps.text} show={toastProps.show} onSuccess={handleCloseToast} onError={handleCloseToast} />}
    </>
  );
};

export default DownloadAssetsButton;
