import { headers } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import {
  ValidateSPSecretsPayload,
  ValidateSPSecretsResponse,
  ValidateSPFoldersPayload,
  ValidateSPFoldersResponse,
  StartSPIntegrationResponse,
  BaseIntegrationResponse,
  SharePointMembersAndAccounts,
  StartIntegrationRequestPayload,
  IntegrationDataResponse,
  SharePointAddedUser,
  LastSyncResponseType,
} from 'utils/types/sharepoint/sharepoint';

class SharePointAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;
  static sharepointEndpoint = `${SharePointAdapter.baseEndPoint}/sharepoint`;
  static secretsEndpoint = '/secrets';
  static secretsValidationEndpoint = '/secrets-validation';
  static foldersValidationEndpoint = '/folders-validation';
  static getSPMembersEndpoint = '/members';
  static startSPIntegrationEndpoint = '/start-integration';
  static checkSPIntegrationStatusEndpoint = '/check-integration-status';
  static getIntegrationDataEndpoint = '/integration-details';
  static startUpdateIntegrationEndpoint = '/edit-integration';
  static getNewAddedUsersEndpoint = '/new-added-users';
  static dismissNewAddedUsersEndpoint = '/dismiss-new-added-users';
  static syncSharepointEndpoint = '/sync';
  static getLastSyncSharepointEndpoint = '/get-last-sync';

  async validateSPSecrets(data: ValidateSPSecretsPayload['data']): Promise<ValidateSPSecretsResponse> {
    const res = await HttpClientService.post<ValidateSPSecretsResponse>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.secretsValidationEndpoint}`, data, { headers: headers() });
    return res.data;
  }

  async validateSPFolders(data: ValidateSPFoldersPayload['data']): Promise<ValidateSPFoldersResponse> {
    const res = await HttpClientService.post<ValidateSPFoldersResponse>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.foldersValidationEndpoint}`, data, { headers: headers() });
    return res.data;
  }

  async startSPIntegration(data: StartIntegrationRequestPayload, isEditMode: boolean): Promise<StartSPIntegrationResponse> {
    const endpoint = isEditMode ? SharePointAdapter.startUpdateIntegrationEndpoint : SharePointAdapter.startSPIntegrationEndpoint;
    const res = await HttpClientService.post<StartSPIntegrationResponse>(`${SharePointAdapter.sharepointEndpoint}${endpoint}`, data, { headers: headers() });
    return res.data;
  }

  async checkSPIntegrationStatus(jobId: string): Promise<BaseIntegrationResponse> {
    const res = await HttpClientService.get<BaseIntegrationResponse>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.checkSPIntegrationStatusEndpoint}`, {
      params: { jobId },
      headers: headers(),
    });
    return res.data;
  }

  async getMembers(data: ValidateSPFoldersPayload['data']): Promise<SharePointMembersAndAccounts> {
    const res = await HttpClientService.post<SharePointMembersAndAccounts>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.getSPMembersEndpoint}`, data, { headers: headers() });
    return res.data;
  }

  async getIntegrationData(): Promise<IntegrationDataResponse> {
    const res = await HttpClientService.get<IntegrationDataResponse>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.getIntegrationDataEndpoint}`, { headers: headers() });
    return res.data;
  }

  async getNewAddedUsers(): Promise<SharePointAddedUser[]> {
    const res = await HttpClientService.get<SharePointAddedUser[]>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.getNewAddedUsersEndpoint}`, {
      headers: headers(),
    });
    return res.data;
  }

  async dismissNewAddedUsers() {
    const res = await HttpClientService.post(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.dismissNewAddedUsersEndpoint}`, {} , { headers: headers() });
    return res.data;
  }
  
  async sharepointSync() {
    const res = await HttpClientService.get<LastSyncResponseType>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.syncSharepointEndpoint}`, { headers: headers() });
    return res.data;
  }

  async getLastSharePointSync() {
    const res = await HttpClientService.get<LastSyncResponseType>(`${SharePointAdapter.sharepointEndpoint}${SharePointAdapter.getLastSyncSharepointEndpoint}`, {
      headers: headers(),
    });
    return res.data;
  }
}

const sharePointAdapter = new SharePointAdapter();
export default sharePointAdapter;
