import React, { createContext, useContext, useRef, useCallback, useState, ReactNode } from 'react';

interface IAssociationContext {
  scrollTSContainerRef: React.RefObject<HTMLDivElement>;
  scrollClaimsContainerRef: React.RefObject<HTMLDivElement>;
  associatedTradeSecretsBullets: string[];
  associatedClaims: string[];
  scrolledToTSBulletId: string | null;
  scrollToNextElement: (ids: string[]) => void;
  registerTSBulletsRefs: (id: string, element: HTMLElement | null) => void;
  setAssociatedTSBullets: (ids: string[]) => void;
  resetAssociatedTSBullets: () => void;
  setAssociatedClaims: (ids: string[]) => void;
  resetAssociatedClaims: () => void;
  resetScrolledToTSBullet: () => void;
}

interface ProviderProps {
  children: ReactNode;
}

const defaultContextValue: IAssociationContext = {
  scrollTSContainerRef: { current: null },
  scrollClaimsContainerRef: { current: null },
  scrolledToTSBulletId: null,
  associatedTradeSecretsBullets: [],
  associatedClaims: [],
  registerTSBulletsRefs: () => {},
  setAssociatedTSBullets: () => {},
  resetAssociatedTSBullets: () => {},
  setAssociatedClaims: () => {},
  resetAssociatedClaims: () => {},
  scrollToNextElement: () => {},
  resetScrolledToTSBullet: () => {},
};

const AssociationContext = createContext<IAssociationContext>(defaultContextValue);

/**
 * Provides context for managing associations between claims and trade secret bullets,
 * including functionality for scrolling through bullets and highlighting corresponding bullets.
 *
 * State management includes:
 * - Tracking associated trade secret bullets and claims.
 * - Managing the index and ID of the currently scrolled-to trade secret bullet.
 *
 * Callback functions include:
 * - Registering and updating element references.
 * - Scrolling to the next bullet in a sequence based on its ID.
 * - Setting and resetting the associations between claims and bullets.
 *
 * @param {Object} props - The properties passed to the AssociationProvider.
 * @param {ReactNode} props.children - The child components that will consume the context.
 *
 * @returns {JSX.Element} A context provider component that wraps child components,
 *                        providing them access to state and callbacks for managing
 *                        associations and interactions.
 */
export const AssociationProvider = ({ children }: ProviderProps): JSX.Element => {
  const scrollTSContainerRef = useRef<HTMLDivElement>(null);
  const scrollClaimsContainerRef = useRef<HTMLDivElement>(null);
  const tsBulletsRefs = useRef<Record<string, HTMLElement | null>>({});

  const [associatedTradeSecretsBullets, setAssociatedTradeSecretsBullets] = useState<string[]>([]);
  const [scrolledToTSBulletIndex, setScrolledToTSBulletIndex] = useState(0);
  const [scrolledToTSBulletId, setScrolledToTSBulletId] = useState<string | null>(null);

  const [associatedClaims, setAssociatedClaimIds] = useState<string[]>([]);

  const registerTSBulletsRefs = useCallback((id: string, element: HTMLElement | null) => {
    if (element === null) {
      delete tsBulletsRefs.current[id]; // Cleanup: remove the ref entry if element is null
    } else {
      tsBulletsRefs.current[id] = element; // Register or update the ref
    }
  }, []);

  // Scrolls and cycles through bullets in a loop
  const scrollToNextElement = useCallback(
    (bulletIds: string[]) => {
      if (!bulletIds?.length) return;

      const nextIndex = scrolledToTSBulletIndex % bulletIds.length; // Ensure cycling through bullets
      const nextElementId = bulletIds[nextIndex];
      const element = tsBulletsRefs.current[nextElementId];
      if (element && scrollTSContainerRef.current) {
        const topPosition = element.offsetTop - (scrollTSContainerRef.current.offsetTop ?? 0);
        scrollTSContainerRef.current.scrollTo({ top: topPosition, behavior: 'smooth' });
        setScrolledToTSBulletIndex(nextIndex + 1); // Update for the next scroll action
        setScrolledToTSBulletId(nextElementId); // Highlight the element that was scrolled to
      }
    },
    [scrolledToTSBulletIndex],
  );

  const setAssociatedTSBullets = useCallback((ids: string[]) => {
    setAssociatedTradeSecretsBullets(ids);
  }, []);

  const resetAssociatedTSBullets = useCallback(() => {
    setAssociatedTradeSecretsBullets([]);
  }, []);

  const setAssociatedClaims = useCallback((ids: string[]) => {
    setAssociatedClaimIds(ids);
  }, []);

  const resetAssociatedClaims = useCallback(() => {
    setAssociatedClaimIds([]);
  }, []);

  const resetScrolledToTSBullet = useCallback(() => {
    setScrolledToTSBulletId(null);
    setScrolledToTSBulletIndex(0);
  }, []);

  const value = {
    associatedTradeSecretsBullets,
    associatedClaims,
    scrollTSContainerRef,
    scrollClaimsContainerRef,
    scrolledToTSBulletId,
    registerTSBulletsRefs,
    scrollToNextElement,
    setAssociatedTSBullets,
    resetAssociatedTSBullets,
    setAssociatedClaims,
    resetAssociatedClaims,
    resetScrolledToTSBullet,
  };
  return <AssociationContext.Provider value={value}>{children}</AssociationContext.Provider>;
};

export const useAssociation = () => useContext(AssociationContext);
