import { apiCall } from './utils/restClient';
import { config } from '../config/config';
import { headers, headersmultipart } from './utils/headers';
import { UPLOAD_FILE_TYPES } from '../utils/enums';

// TODO: ------ PLEASE DO NOT ANYTHING NEW HERE ------
// NEED TO REFACTOR AND REMOVE THIS ACTIONS
// FOR THE NEW IMPLEMENTATION, ADD TO agreementThunks!!!

//Default Params
const defaults = {
  PAGE: 1,
  LIMIT: 20,
  SORTBY: 'createdAt:asc',
};

function createAgreementType(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreementType`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}
function deleteAgreementType(firmid) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreementType/${firmid}`,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}
function editAgreementType(data, firmid) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreementType/${firmid}`,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}
function getAgreementTypes({ client = null, page = defaults.PAGE, limit = defaults.LIMIT, sortBy = defaults.SORTBY }) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreementTypes`,
    method: 'GET',
    headers: headers(),
    params: { client, page, limit, sortBy },
  };

  return apiCall(requestOptions);
}

function createAgreement(data) {
  const payload = { ...data };
  // payload.account = accountId;
  delete payload.files;

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreement`,
    method: 'POST',
    headers: headers(),
    data: payload,
  };

  return apiCall(requestOptions);
}

function getAgreements(limit = 10) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreements`,
    method: 'GET',
    headers: headers(),
    params: { limit },
  };

  return apiCall(requestOptions);
}

function uploadAgreementFiles(data, agreementId, entityType = undefined, entityId) {
  var formData = new FormData();
  formData.append('file', data[0]);
  formData.append(0, data[0].name);
  formData.append('entityId', entityId);

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: {
      id: agreementId,
      type: UPLOAD_FILE_TYPES.AGREEMENTS,
      entityType,
    },
  };

  return apiCall(requestOptions);
}

function updateAgreement(agreement, entityType = undefined) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreement`,
    method: 'PUT',
    headers: headers(),
    data: { ...agreement },
    params: {
      entityType,
    },
  };

  return apiCall(requestOptions);
}

export const agreementService = {
  createAgreementType,
  editAgreementType,
  deleteAgreementType,
  getAgreementTypes,
  createAgreement,
  getAgreements,
  uploadAgreementFiles,
  updateAgreement,
};
