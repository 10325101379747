import { useTranslation } from 'react-i18next';

import { TANGI_BUTTON_SIZES, TangiButton } from '_components/TangiLibrary';
import { useSharePointWizardContext } from '_components/SharePointWizard/context/useSharePointWizardContext';
import { BUTTON_VARIANTS } from 'utils/theme';
import { Container, LeftButtonContainer, RightButtonsContainer } from './style';

interface Props {
  onCancel: () => void;
  onBack: () => void;
  onForward: () => void;
  loading: boolean;
  isNextDisabled?: boolean;
}

const Footer = ({ onCancel, onBack, onForward, loading, isNextDisabled = false }: Props) => {
  const { t } = useTranslation();
  const { isLastStep, isFirstStep, isEditMode } = useSharePointWizardContext();

  const nextButtonText =
    isLastStep && !isEditMode ? 'SHARE_POINT_WIZARD.MAIN.SUBMIT_BUTTON' : isLastStep && isEditMode ? 'SHARE_POINT_WIZARD.MAIN.SUBMIT_BUTTON_EDIT' : 'GENERAL.BUTTONS_TEXT.CONTINUE';

  return (
    <Container>
      <LeftButtonContainer>
        {!isFirstStep && (
          <TangiButton
            variant={BUTTON_VARIANTS.TERTIARY}
            svgIcon="arrowLeft"
            text={t('GENERAL.BUTTONS_TEXT.BACK')}
            size={TANGI_BUTTON_SIZES.MEDIUM}
            onClick={onBack}
            disabled={loading}
            data-testid="sp-wizard-back-button"
            smallbtn
          />
        )}
      </LeftButtonContainer>
      <RightButtonsContainer>
        <TangiButton variant={BUTTON_VARIANTS.TERTIARY} text={t('GENERAL.BUTTONS_TEXT.CANCEL')} size={TANGI_BUTTON_SIZES.MEDIUM} onClick={onCancel} data-testid="sp-wizard-cancel-button" smallbtn />
        <TangiButton
          text={t(nextButtonText)}
          size={TANGI_BUTTON_SIZES.MEDIUM}
          loading={loading}
          data-testid="sp-wizard-next-button"
          smallbtn
          type={isLastStep ? 'submit' : 'button'}
          onClick={onForward}
          disabled={isNextDisabled}
        />
      </RightButtonsContainer>
    </Container>
  );
};

export default Footer;
