import { SUCCESS_SHADES, WARNING_SHADES, ERROR_SHADES } from 'utils/theme';
import { ScoreValues } from './consts';

export const getProgressBarColor = (score: number) => {
  const numericScore = score;
  if (numericScore > ScoreValues.SUCCESS) {
    return SUCCESS_SHADES[300];
  } else if (numericScore > ScoreValues.ERROR && numericScore <= ScoreValues.SUCCESS) {
    return WARNING_SHADES[1100];
  } else {
    return ERROR_SHADES[500];
  }
};
