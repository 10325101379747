import { IRoles } from 'utils/roles';
import quickStartAuditUtil from './util';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import { Container, LeftSide } from './styles';
import RightSide from './components/RightSide';
import { useDispatch } from 'react-redux';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { useEffect } from 'react';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';

interface Props {
  role: IRoles;
  onQuickStartAudit: (isAdmin: boolean) => void;
}

const QuickStartAuditBanner = ({ role, onQuickStartAudit }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { numberOfSteps, numberOfCompletedSteps } = useIpAuditContext();
  const completedStepsPercentage = (numberOfCompletedSteps / numberOfSteps) * 100;
  const isAdmin = quickStartAuditUtil.isAdminCheck(role);
  const styleByRole = quickStartAuditUtil.getStyleByRole(isAdmin, completedStepsPercentage);

  useEffect(() => {
    if (completedStepsPercentage === 100) {
      dispatch(mixpanelEvents.completeIPAudit());
    }
  }, [completedStepsPercentage]);

  return (
    <Container $styleByRole={styleByRole}>
      <LeftSide>
        <TangiSvgIcon component={styleByRole.icon} color="" size="50px" />
        <section>
          <TangiTypography type="heading-md" weight="semibold">
            {t('IP_AUDIT.HEADER')}
          </TangiTypography>
          <TangiTypography>{t('IP_AUDIT.BANNER_TEXT', { TOTAL: numberOfSteps })}</TangiTypography>
        </section>
      </LeftSide>

      <RightSide isAdmin={isAdmin} onQuickStartAudit={onQuickStartAudit} />
    </Container>
  );
};

export default QuickStartAuditBanner;
