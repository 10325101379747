import { TangiTypography } from '_components/TangiLibrary';
import { StyledWidgetContainer, numberCustomStyle } from './style';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';

interface Props {
  title: TRANSLATION_KEY;
  number: number;
}

const TradeSecretsAnalysisWidget = ({ title, number }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledWidgetContainer data-testid="trade-secrets-widget">
      <TangiTypography>{t(title)}</TangiTypography>
      <TangiTypography dataTestId="widget-number" customStyles={numberCustomStyle} weight="bold">
        {number.toLocaleString()}
      </TangiTypography>
    </StyledWidgetContainer>
  );
};

export default TradeSecretsAnalysisWidget;
