import { AxiosRequestHeaders } from 'axios';
import { authConstants, lawFirmConstants } from '_constants';
import { LANGUAGE } from 'translations/enums';
import { loadStateFromLocalStorage } from 'utils/localStorage';
import { IRoles } from 'utils/roles';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';

/**
 * Extracts the language preference and client ID based on the current user's role.
 * The function first determines if the current user's role is Lawyer.
 * If the user is a lawyer, it retrieves the client ID and language preference from `activeClient`.
 * For other roles, it retrieves these values from the client associated with `activeAccount`.
 * If the language is not specified for the user or in the account/client settings, it defaults to English.
 *
 * @returns {Object} An object containing two properties: `language` and `clientId`.
 * `language` is a string representing the user's preferred language.
 * `clientId` is a string or undefined, representing the client's unique identifier.
 */
export const extractLanguageAndClientIdByRole = () => {
  const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);
  const activeClient = loadStateFromLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT);
  const user = loadStateFromLocalStorage(authConstants.USER);

  const isLawyer = activeAccount?.role?.name === IRoles.LAWYER;

  let clientId, language;

  if (isLawyer) {
    clientId = activeClient?.id;
    language = activeClient?.language;
  } else {
    clientId = activeAccount?.client?._id;
    language = activeAccount?.client?.language;
  }

  // Default language to user's language or English if not set
  language = user?.language ?? language ?? LANGUAGE.ENGLISH;

  return { language, clientId };
};

/**
 * Constructs headers for Axios HTTP requests.
 * The function creates a headers object with necessary authentication and content type information.
 * It uses a JWT token retrieved from local storage for authorization and sets the 'Content-Type' to 'application/json'.
 * Additionally, it incorporates account-specific information such as the account ID, client ID,
 * and the preferred language for the request, which are derived from local storage and user role.
 *
 * If an active account is present, the function adds 'AccountId', 'ClientId',
 * and 'Accept-Language' headers. The 'Accept-Language' header defaults to English if not specified.
 *
 * @returns {AxiosRequestHeaders} An object containing Axios request headers, including Authorization,
 * 'Content-Type', and optionally 'AccountId', 'ClientId', and 'Accept-Language' based on the active account.
 */
export const headers = () => {
  const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');

  const { language, clientId } = extractLanguageAndClientIdByRole();
  const accessToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);

  const header = {
    'Content-Type': 'application/json',
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
  } as AxiosRequestHeaders;

  if (activeAccount) {
    header.AccountId = activeAccount._id;
    header.ClientId = clientId;
    header['Accept-Language'] = language || LANGUAGE.ENGLISH;
  }
  return header;
};

/**
 * Constructs multipart/form-data headers for Axios HTTP requests.
 * This function is specifically used for creating headers suitable for sending multipart form data,
 * such as files. It sets the 'Content-Type' to 'multipart/form-data'.
 *
 * The function retrieves a JWT token from local storage for authorization purposes.
 * Additionally, it includes account-specific information, if available, such as the account ID and client ID,
 * which are obtained from local storage and user role.
 *
 * If an active account is detected, the function adds 'AccountId' and 'ClientId' headers
 * to the request headers. These headers are used for associating the request with a specific
 * account and client on the server-side.
 *
 * @returns {AxiosRequestHeaders} An object containing Axios request headers, including Authorization
 * and 'Content-Type'. Optionally includes 'AccountId' and 'ClientId' if an active account is present.
 */
export const headersmultipart = () => {
  const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');
  const { clientId } = extractLanguageAndClientIdByRole();
  const accessToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);

  const header = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } as AxiosRequestHeaders;

  if (activeAccount) {
    header.AccountId = activeAccount._id;
    header.ClientId = clientId;
  }
  return header;
};

export const tokenHeaders = {
  InvitationTokenHeader: 'Invitation-Token',
  AcknowledgeTokenHeader: 'Acknowledge-Token',
};
