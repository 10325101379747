import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING, SUCCESS_SHADES } from 'utils/theme';
import { AgreementWithFiles } from 'utils/types/agreement/agreement';

interface Props {
  agreements: AgreementWithFiles[];
}

const HoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  padding: ${SPACING[2]};
`;

const AgreementItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledAssetTitle = styled(TangiTypography)`
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledAssetDate = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  color: ${SUCCESS_SHADES[500]};
`;

const AgreementPeopleTableHover = ({ agreements }: Props) => {
  const { t } = useTranslation();

  return (
    <HoverWrapper>
      {!!agreements.length &&
        agreements.map((agreement) => {
          return (
            <AgreementItem key={agreement._id}>
              <StyledAssetTitle weight="semibold" color={NEUTRAL_SHADES[1000]}>
                {agreement.agreementType.name}
              </StyledAssetTitle>
              <StyledAssetDate>
                {agreement.signed ? (
                  <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
                ) : (
                  <TangiTypography color={NEUTRAL_SHADES[800]} type="sub-body">
                    {t('DASHBOARD_PAGE.TABLES.PEOPLE_AND_CONTRACTORS_TABLE.MISSING')}
                  </TangiTypography>
                )}
              </StyledAssetDate>
            </AgreementItem>
          );
        })}
    </HoverWrapper>
  );
};

export default AgreementPeopleTableHover;
