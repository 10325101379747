import i18next from 'i18next';

import { AssetDataItem } from './Widgets.types';
import { UserItem } from './Widgets.types';
import { ACCOUNT_STATUS } from 'utils/enums';
import { PeopleStatusItem } from './Widgets.types';

export const getFormattedAssetsData = (unpublishedAssets: number, assetsByThirdParty: number): AssetDataItem[] => {
  return [
    {
      label: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.UNPUBLISHED_ASSETS'),
      amount: unpublishedAssets,
    },
    {
      label: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.CREATED_BY_3RD_PARTY'),
      amount: assetsByThirdParty,
    },
  ];
};

export const getFormattedUsers = (employees: number, contributors: number, external: number): UserItem[] => {
  return [
    {
      role: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.EMPLOYEES'),
      amount: employees,
    },
    { role: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.CONTRIBUTORS'), amount: contributors },
    { role: i18next.t('DASHBOARD_PAGE.WIDGETS.LABEL.EXTERNAL'), amount: external },
  ];
};

export const getFormattedPeopleStatusData = (active: number, pending: number, disabled: number): PeopleStatusItem[] => {
  return [
    {
      status: ACCOUNT_STATUS.ACTIVE,
      amount: active,
    },
    {
      status: ACCOUNT_STATUS.PENDING,
      amount: pending,
    },
    {
      status: ACCOUNT_STATUS.DISABLED,
      amount: disabled,
    },
  ];
};
