import { TangiSvgIcon } from '_components/TangiLibrary';
import styled from 'styled-components';
import { PRIMARY_SHADES } from 'utils/theme';

export const CircleBackground = styled.div`
  position: relative;
  border-radius: 50%;
  background-color: ${PRIMARY_SHADES[200]};
  width: 160px;
  height: 160px;
`;

export const StyledTangiSvgIcon = styled(TangiSvgIcon)`
  position: absolute;
  top: 58%;
  left: 63%;
  transform: translate(-50%, -50%);
`;
