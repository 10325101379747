import {
  sharePointAllowAccessPopupStep1,
  sharePointAllowAccessPopupStep2,
  sharePointAllowAccessPopupStep3,
  sharePointAllowAccessPopupStep4,
  sharePointAllowAccessPopupStep5,
  sharePointAllowAccessPopupStep6,
  sharePointAllowAccessPopupStep8,
} from '_components/SharePointWizard/assets';
import { TangiButton, TangiTypography } from '_components/TangiLibrary';
import { Trans, useTranslation } from 'react-i18next';
import { NEUTRAL_SHADES } from 'utils/theme';
import { Container, doneBtnStyle, ImgContainer, imgStyle } from './style';

interface Props {
  handleClose: () => void;
}
const GrantModalBody = ({ handleClose }: Props) => {
  const { t } = useTranslation();

  const steps = [
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_1', img: sharePointAllowAccessPopupStep1 },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_2', img: sharePointAllowAccessPopupStep2 },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_3', img: sharePointAllowAccessPopupStep3 },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_4', img: sharePointAllowAccessPopupStep4 },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_5', img: sharePointAllowAccessPopupStep5 },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_6', img: sharePointAllowAccessPopupStep6 },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_7', img: null },
    { textKey: 'SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.STEP_8', img: sharePointAllowAccessPopupStep8 },
  ];

  const renderStep = ({ textKey, img }: { textKey: string; img: string | null }) => (
    <Trans key={textKey}>
      <TangiTypography color={NEUTRAL_SHADES[1000]}>{t(textKey)}</TangiTypography>

      {img && (
        <ImgContainer>
          <img src={img} alt="" style={imgStyle} />
        </ImgContainer>
      )}
    </Trans>
  );

  return (
    <Container>
      <TangiTypography color={NEUTRAL_SHADES[1100]} weight="semibold">
        {t('SHARE_POINT_WIZARD.ALLOW_ACCESS.GRANT_MODAL.SUB_HEADER')}
      </TangiTypography>

      {steps.map(renderStep)}

      <TangiButton variant="primary" text={t('GENERAL.BUTTONS_TEXT.DONE')} size="small" onClick={handleClose} style={doneBtnStyle} />
    </Container>
  );
};

export default GrantModalBody;
