import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const OptionCardsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING[3]};
  max-width: 1000px;
`;

export const CheckboxWithTextCard = styled.div`
  display: flex;
  align-items: center;
  border: none;
  padding-left: ${SPACING[3]};
`;

export const CollapseContainer = styled.div`
  padding-top: ${SPACING[3.5]};
  padding-bottom: ${SPACING[3.5]};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3.5]};
`;

export const CollapseHeader = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  padding-bottom: ${SPACING[2]};
  cursor: pointer;
`;

export const nonOfTheAboveTextStyle = 'margin-top: 5px';
