import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

interface Props {
  title: string;
  subTitle?: string;
  icon?: JSX.Element;
}

const Title = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${NEUTRAL_SHADES.BLACK};
`;

const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 6px;
  color: ${NEUTRAL_SHADES[700]};
`;

const IconContainer = styled.div`
  margin-right: ${SPACING[2]};
`;

const TitlesContainer = styled.div`
  position: absolute;
  left: ${SPACING[3]};
  top: ${SPACING[3]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const WidgetTitle = ({ title, subTitle, icon }: Props) => {
  return (
    <TitlesContainer>
      {icon && <IconContainer data-testid="widget-title-icon">{icon}</IconContainer>}
      <Title data-testid="widget-title">{title}</Title>
      {subTitle && <SubTitle data-testid="widget-subtitle">{subTitle}</SubTitle>}
    </TitlesContainer>
  );
};

export default WidgetTitle;
