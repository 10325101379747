import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { forwardRef, useMemo } from 'react';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import WidgetCard from '../WidgetCard';
import { TangiTypography } from '_components/TangiLibrary';
import UserItem from './UserItem';
import { WidgetCardProps } from '../Widgets.types';
import WidgetTitle from '../WidgetTitle';
import { getFormattedUsers } from '../Widget.logic';

const UsersWidgetContainer = styled.div`
  display: flex;
  padding: 0 ${SPACING[3]};
  flex-direction: column;
  width: 100%;
  margin-top: ${SPACING[4]};
`;

const UsersContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  flex: 1;
  align-items: center;
  overflow-x: auto;
`;

const UsersTitle = styled.div`
  position: absolute;
  right: ${SPACING[3]};
  top: ${SPACING[3]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

interface Props extends WidgetCardProps {
  data: {
    total: number;
    employees: number;
    contributors: number;
    external: number;
  };
}

const Users = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();

  const users = useMemo(() => getFormattedUsers(props.data.employees, props.data.contributors, props.data.external), [props]);

  const renderUsersTitle = (totalUsers: number) => {
    return (
      <UsersTitle data-testid="total-users">
        <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]}>
          {totalUsers} {t('DASHBOARD_PAGE.WIDGETS.COMMON.TOTAL')}
        </TangiTypography>
      </UsersTitle>
    );
  };

  const userItems = useMemo(() => {
    return users.map((user) => {
      return <UserItem key={user.role} {...user} />;
    });
  }, [users]);

  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.USERS')} />
        <UsersWidgetContainer>
          {renderUsersTitle(props.data.total)}
          <UsersContainer>{userItems}</UsersContainer>
        </UsersWidgetContainer>
        {props.children}
      </>
    </WidgetCard>
  );
});
export default Users;
