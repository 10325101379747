import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { getIconFromFileName } from 'utils/componentUtils';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { formatLongFileName } from 'utils/fileNameUtils';
import { MODIFICATION_TYPE } from 'utils/types/activityLog/asset/types';

export const FileContainer = styled(FlexContainer)`
  height: ${SPACING[6]};
  border-radius: ${SPACING[2]};
  padding-inline: 12px;
  flex-wrap: nowrap;
  align-items: center;
  background-color: ${NEUTRAL_SHADES[50]};
  max-width: 320px;

  .tangi-typography {
    white-space: nowrap;
  }
`;

const MAX_ACCEPTED_FILENAME_LENGTH = 40;

interface Props {
  fileNames: string[];
  modificationType: MODIFICATION_TYPE;
}

const ActivityFileContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ActivityFile = ({ fileNames, modificationType }: Props) => {
  const color = modificationType === MODIFICATION_TYPE.DELETE ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES[1100];

  return (
    <ActivityFileContainer>
      {fileNames.map((fileName, index) => (
        <FileContainer key={index} data-testid="activity-details-file">
          <FileNameContainer>
            {getIconFromFileName(fileName, color)}
            <TangiTypography color={color} weight="semibold">
              {formatLongFileName(fileName, MAX_ACCEPTED_FILENAME_LENGTH)}
            </TangiTypography>
          </FileNameContainer>
        </FileContainer>
      ))}
    </ActivityFileContainer>
  );
};

export default ActivityFile;
