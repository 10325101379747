import { useState, useRef, useCallback, ReactNode } from 'react';
import { ComputedDatum } from '@nivo/bar';

import TradeSecretsBySubjectTooltip from 'pages/TradeSecretsAnalysis/components/TradeSecretsBySubject/TradeSecretsBySubjectTooltip';

interface UseTooltipHandlerProps {
  keys: string[];
}

type BarChartDataObject = {
  [key: string]: string | number;
  label: string;
  total: number;
};

interface TooltipHandlerReturn {
  tooltipContent: ReactNode;
  tippyRef: React.RefObject<HTMLDivElement>;
  handleMouseEnter: (barData: ComputedDatum<BarChartDataObject>, event: React.MouseEvent) => void;
  handleMouseLeave: () => void;
}

const useBarChartTooltipHandler = ({ keys: companies }: UseTooltipHandlerProps): TooltipHandlerReturn => {
  const [tooltipContent, setTooltipContent] = useState<ReactNode>(null);
  const tippyRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = useCallback(
    (barData: ComputedDatum<BarChartDataObject>, event: React.MouseEvent) => {
      const aggregatedData = companies
        .filter((key) => !!barData?.data[key])
        .map((key) => ({
          id: key,
          value: Number(barData.data[key]),
          color: String(barData.data[`${key}Color`]),
        }));

      setTooltipContent(<TradeSecretsBySubjectTooltip title={barData.indexValue as string} barData={aggregatedData} />);

      if (tippyRef.current) {
        tippyRef.current.style.position = 'fixed';
        tippyRef.current.style.left = `${event.clientX}px`;
        tippyRef.current.style.top = `${event.clientY}px`;
      }
    },
    [companies],
  );
  const handleMouseLeave = useCallback(() => {
    setTooltipContent(null);
  }, []);

  return {
    tooltipContent,
    tippyRef,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useBarChartTooltipHandler;
