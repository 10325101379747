import { AppRoutes } from './consts';

export interface GenerateRouteParams {
  clientId?: string;
  partnershipId?: string;
  assetId?: string;
}

/**
 * Generates a route by replacing parameters in the base route with provided values and optionally appending query parameters.
 * If required parameters are missing, it returns the HOME route.
 *
 * @param {AppRoutes} baseRoute - The base route template with placeholders for parameters.
 * @param {GenerateRouteParams} params - An object containing the parameters to replace in the base route.
 * @param {Record<string, string | number>} [queryParams] - An optional object containing query parameters to append to the route.
 * @returns {string} - The generated route with parameters replaced and query parameters appended if provided, or the MAIN route if required parameters are missing.
 *
 * @example
 * // Basic usage with base route only
 * const route = generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId: '123' });
 * console.log(route); // Outputs: /client/123
 *
 * @example
 * // Usage with base route and query parameters
 * const routeWithQuery = generateRoute(AppRoutes.ASSET_PREVIEW, {}, { token: 'abc123' });
 * console.log(routeWithQuery); // Outputs: /assets/preview?token=abc123
 *
 * @example
 * // Usage with a more complex route and parameters
 * const assetRoute = generateRoute(AppRoutes.ASSET_INNER_PAGE, { clientId: '123', assetId: '456' });
 * console.log(assetRoute); // Outputs: /client/123/asset/456
 *
 * @example
 * // Usage with a complex route, parameters, and query parameters
 * const assetRouteWithQuery = generateRoute(AppRoutes.ASSET_INNER_PAGE, { clientId: '123', assetId: '456' }, { appId: '123' });
 * console.log(assetRouteWithQuery); // Outputs: /client/123/asset/456?appId=123
 *
 * @example
 * // Usage with missing required parameters
 * const incompleteRoute = generateRoute(AppRoutes.ASSET_INNER_PAGE, { clientId: '123' });
 * console.log(incompleteRoute); // Outputs: /home
 *
 * @example
 * // Usage with no query parameters
 * const routeNoQuery = generateRoute(AppRoutes.REGISTER, {}, {});
 * console.log(routeNoQuery); // Outputs: /register
 */
export const generateRoute = (baseRoute: AppRoutes, params: GenerateRouteParams, queryParams?: Record<string, string | number>): string => {
  let path = baseRoute as string;

  Object.keys(params).forEach((key) => {
    if (params[key as keyof GenerateRouteParams] !== undefined) {
      path = path.replace(`:${key}`, params[key as keyof GenerateRouteParams]!.toString());
    }
  });

  // If any placeholder remains, return the MAIN route
  if (path.includes(':')) {
    return AppRoutes.MAIN;
  }

  if (queryParams) {
    const queryString = new URLSearchParams(queryParams as Record<string, string>).toString();
    if (queryString) {
      path = `${path}?${queryString}`;
    }
  }

  return path;
};
