import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Fragment, forwardRef, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import WidgetCard from '../WidgetCard';
import WidgetTableTitle from '../../Common/WidgetTableTitle';
import WidgetTitle from '../WidgetTitle';
import ProjectByAssetsItem from './ProjectByAssetsItem';
import { EmptyWidgetProps, WidgetCardProps, WidgetDataObject } from '../Widgets.types';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { SPACING } from 'utils/theme';
import { Divider } from 'utils/globalStyles';

const ProjectsWidgetContainer = styled.div`
  display: flex;
  padding-block: 0;
  padding-inline: ${SPACING[3]} 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 12px;
  margin-top: 64px;
  overflow-y: auto;

  .widget-table-title {
    margin-right: ${SPACING[3]};
  }
`;

const ProjectByAssetsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

interface Props extends WidgetCardProps {
  data: WidgetDataObject[];
}

const ProjectsByAssets = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId } = useParams<{ clientId: string }>();

  const emptyState: EmptyWidgetProps = {
    emptyTitle: t('DASHBOARD_PAGE.WIDGETS.PROJECTS_BY_ASSETS.EMPTY_STATE.TITLE'),
    emptyDescription: t('DASHBOARD_PAGE.WIDGETS.PROJECTS_BY_ASSETS.EMPTY_STATE.DESCRIPTION'),
    emptyButton: {
      text: t('DASHBOARD_PAGE.WIDGETS.PROJECTS_BY_ASSETS.EMPTY_STATE.BUTTON_CTA_ASSETS'),
      onClick: () => {
        history.push(generateRoute(AppRoutes.ASSETS_MAIN_PAGE, { clientId }));
      },
    },
  };

  const projectItems = useMemo(() => {
    return props.data.map((item) => {
      return (
        <Fragment key={item.id}>
          <ProjectByAssetsItem {...item} />
          <Divider />
        </Fragment>
      );
    });
  }, [props.data]);

  return (
    <WidgetCard {...props} ref={ref} emptyState={emptyState}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.PROJECTS_BY_ASSETS.TITLE')} />
        <ProjectsWidgetContainer>
          <WidgetTableTitle suffix1="PROJECTS_BY_ASSETS.PROJECT" suffix2="COMMON.ASSETS" />
          <ProjectByAssetsContainer>{projectItems}</ProjectByAssetsContainer>
          {props.children}
        </ProjectsWidgetContainer>
      </>
    </WidgetCard>
  );
});

export default ProjectsByAssets;
