import { acknowledgementConstants } from '../_constants/acknowledgementConstants';

const initialState = {
  acknowledgementModalIsOpen: false,
  assetsNeedToBeAcknowledged: [],
  acknowledgementLoading: false,
  updatedAssets: [],
  acknowledgementToastProps: { show: false, text: '', type: '' },
  error: null,
};

export const acknowledgementReducer = (state = initialState, action) => {
  switch (action.type) {
    case acknowledgementConstants.OPEN_CLOSE_ACKNOWLEDGEMENT_MODAL:
      return {
        ...state,
        acknowledgementModalIsOpen: action.show,
      };
    case acknowledgementConstants.NEEDTOBE_ACKNOWLEDGED_SUCCESS:
      return {
        ...state,
        assetsNeedToBeAcknowledged: action.payload,
      };
    case acknowledgementConstants.SET_TOAST_PROPS:
      return {
        ...state,
        acknowledgementToastProps: action.props,
      };
    case acknowledgementConstants.ACKNOWLEDGEMENT_REQUESTS:
      return {
        ...state,
        updatedAssets: [],
        error: null,
        acknowledgementLoading: true,
      };
    case acknowledgementConstants.ACKNOWLEDGEMENT_FAILURE:
      return {
        ...state,
        acknowledgementLoading: false,
        updatedAssets: [],
        error: action.error,
        acknowledgementToastProps: { show: true, text: `${action.error?.message ? action.error.message : 'Something went wrong, please try again'}`, type: 'error' },
      };
    case acknowledgementConstants.ACKNOWLEDGEMENT_SUCCESS:
      return {
        ...state,
        acknowledgementLoading: false,
        updatedAssets: action.payload,
        acknowledgementToastProps: { show: true, text: `A successful signature was obtained. Below You can find ${action.payload.length} assets`, type: 'success' },
        acknowledgementModalIsOpen: false,
      };
    case acknowledgementConstants.CLEAR_ACKNOWLEDGED_ASSETS:
      return {
        ...state,
        updatedAssets: [],
        acknowledgementToastProps: { show: false, text: '', type: '' },
        acknowledgementLoading: false,
        error: null,
      };
    default:
      return state;
  }
};
