import React, { createContext, useRef } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserPool, CognitoIdToken } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import * as Sentry from '@sentry/react';

import cognitoUtil from '_services/utils/cognitoUtil';
import { config } from '../../config/config';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';
import { LOCAL_STORAGE_KEYS } from 'utils/token/localStorage';
import { AWS_SDK } from './constants';
import { LogoutType } from '_services/utils/MixPanel/types';
import { sendLogoutMixpanelEvent } from '_services/utils/MixPanel/utils';
import { AppRoutes } from 'routing/consts';

const poolData = {
  UserPoolId: config.userPoolId,
  ClientId: config.cognitoClientId,
};
const Pool = new CognitoUserPool(poolData);
export default Pool;

const AuthContext = createContext();

const Auth = ({ children }) => {
  let sessionUserAttributes = null; /// global variables to handle completeNewPasswordChallenge flow
  let currentUser = null; /// global variables to handle completeNewPasswordChallenge flow

  const isLoggingOut = useRef(false); // Prevents multiple logout calls when logout is called multiple times simultaneously
  const dispatch = useDispatch();

  const authCB = (resolve, reject, isRememberMyDevice) => {
    return {
      onSuccess: async (data) => {
        saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, data.accessToken.jwtToken);
        if (isRememberMyDevice) rememberMyDevice();
        resolve(data);
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: (data) => {
        resolve({ code: 'NEW_PASSWORD_REQUIRED', payload: data });
      },
      totpRequired: (secretCode) => {
        resolve({ code: secretCode });
      },
      mfaSetup: (challengeName) => {
        resolve({ code: challengeName });
      },
      associateSecretCode: (secret) => {
        resolve(secret);
      },
    };
  };

  const getSession = async () => {
    return new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser() || currentUser;
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            Sentry.captureException(err);
            reject(err);
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  Sentry.captureException(err);
                  reject(err);
                } else {
                  const results = {};
                  for (const attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                  sessionUserAttributes = results;
                  resolve(results);
                }
              });
            });

            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        Sentry.captureMessage('getSession: Cognito user not available, can not get session', 'error');
        reject('user not found');
      }
    });
  };

  const authenticate = async (Username, Password) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      currentUser = user;
      user.authenticateUser(authDetails, authCB(resolve, reject));
    });
  };

  const senfMfa = async (otpValue, isRememberMyDevice) => {
    return new Promise((resolve, reject) => {
      currentUser.sendMFACode(otpValue, authCB(resolve, reject, isRememberMyDevice), 'SOFTWARE_TOKEN_MFA');
    });
  };

  const associateToken = async () => {
    return new Promise((resolve, reject) => {
      currentUser.associateSoftwareToken(authCB(resolve, reject));
    });
  };

  const verifyToken = async (otpValue) => {
    return new Promise((resolve, reject) => {
      currentUser.verifySoftwareToken(otpValue, 'My TOTP device', authCB(resolve, reject));
    });
  };

  const forceResetPassword = async (newPassword) => {
    return new Promise((resolve, reject) => {
      currentUser.completeNewPasswordChallenge(newPassword, {}, authCB(resolve, reject));
    });
  };

  const forgotPassword = async (Username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool });
      currentUser = cognitoUser;
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          Sentry.captureException(err);
          reject(err.message);
        },
      });
    });
  };

  const confirmForgotPassword = (newPassword, verificationCode, Username) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool });
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve('Password confirmed!');
        },
        onFailure(err) {
          Sentry.captureException(err);
          reject(err);
        },
      });
    });
  };

  // For Mixpanel events pass relevant values for isMixpanelEvent flag and logoutType (logoutType responsible for the type of the event)
  const logout = ({ isMixpanelEvent = true, logoutType = LogoutType.Active }) => {
    if (isLoggingOut.current) return;

    isLoggingOut.current = true; // We set here the flag to true to prevent multiple logout calls
    const cognitoUser = Pool.getCurrentUser();
    const firstTimeLoginFlag = loadStateFromLocalStorage(authConstants.FIRST_TIME_LOGIN);

    if (isMixpanelEvent) {
      sendLogoutMixpanelEvent(logoutType, dispatch);
    }

    if (cognitoUser) {
      const { deviceGroup, deviceKey, randomPasswordKey } = cognitoUtil.getRememberMyDeviceTokens(cognitoUser);
      localStorage.clear();
      localStorage.setItem(deviceGroup.key, deviceGroup.value);
      localStorage.setItem(deviceKey.key, deviceKey.value);
      localStorage.setItem(randomPasswordKey.key, randomPasswordKey.value);
      cognitoUser.signOut();
    } else {
      localStorage.clear();
    }
    saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, firstTimeLoginFlag);

    // Redirect to main and reload the page simultaneously to clear redux state
    window.location.href = AppRoutes.MAIN;
  };

  const getTokensFromCode = async (code) => {
    const requestOptions = {
      url: `${config.cognitoUserPoolDomain}/oauth2/token?grant_type=authorization_code&code=${code}&client_id=${config.cognitoClientId}&redirect_uri=${config.cognitoRedirectUri}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {},
    };
    return new Promise((resolve, reject) => {
      axios(requestOptions).then(
        (result) => {
          saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, result.data.access_token);
          saveStateToLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS, result.data);
          resolve(result.data);
        },
        (error) => {
          Sentry.captureException(error);
          reject(error);
        },
      );
    });
  };

  const authenticateOkta = async (tokens) => {
    return new Promise((resolve) => {
      const IdToken = tokens.id_token;
      const userData = new CognitoIdToken({ IdToken });
      const Username = userData.payload['cognito:username'];
      const user = new CognitoUser({ Username, Pool });
      currentUser = user;
      saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, tokens.access_token);
      resolve(IdToken);
    });
  };

  async function refreshOktaToken(refreshToken) {
    const client = new CognitoIdentityProviderClient({ region: config.region });

    const authParameters = {
      REFRESH_TOKEN: refreshToken,
    };
    const input = {
      AuthFlow: AWS_SDK.AUTH_FLOW_REFRESH_TOKEN,
      AuthParameters: authParameters,
      ClientId: config.cognitoClientId,
    };

    const command = new InitiateAuthCommand(input);
    try {
      const response = await client.send(command);
      const accessToken = response.AuthenticationResult.AccessToken;
      const oktaTokens = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS);

      saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, accessToken);
      saveStateToLocalStorage(LOCAL_STORAGE_KEYS.OKTA_USER_TOKENS, { ...oktaTokens, access_token: accessToken });
    } catch (err) {
      console.error('Error refreshing Okta token');
      Sentry.captureException(err);
      logout({ logoutType: LogoutType.Automatic });
    }
  }

  const refreshCognitoToken = () => {
    if (typeof Cypress === 'object') return;
    const cognitoUser = Pool.getCurrentUser();

    if (!cognitoUser) {
      Sentry.captureMessage('refreshCognitoToken: Cognito user not available, can not refresh token', 'error');
      logout({ logoutType: LogoutType.Automatic });
      return;
    }
    cognitoUser.getSession((err, session) => {
      if (!session) {
        Sentry.captureMessage('refreshCognitoToken: no session after getSession', 'error');
      }
      if (err) {
        Sentry.captureException(err);
        logout({ logoutType: LogoutType.Automatic });
        return;
      }

      const refreshToken = session?.getRefreshToken();

      cognitoUser.refreshSession(refreshToken, (err, authResponse) => {
        if (!authResponse) {
          Sentry.captureMessage('refreshCognitoToken: No auth response, can not refresh token', 'error');
        }
        if (err) {
          Sentry.captureException(err);
          logout({ logoutType: LogoutType.Automatic });
          return;
        }
        saveStateToLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN, authResponse.accessToken.jwtToken);
      });
    });
  };

  const rememberMyDevice = () => {
    currentUser.setDeviceStatusRemembered({
      onSuccess: () => {
        Sentry.captureMessage('Device remembered successfully', 'info');
      },
      onFailure: (err) => {
        Sentry.captureException(err);
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        logout,
        getSession,
        senfMfa,
        associateToken,
        verifyToken,
        forceResetPassword,
        getTokensFromCode,
        authenticateOkta,
        forgotPassword,
        confirmForgotPassword,
        refreshCognitoToken,
        refreshOktaToken,
        sessionUserAttributes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { Auth, AuthContext };
