import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { getClientById, updateRequiredCourses } from 'redux-toolkit/thunks/clientThunks';
import { Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { Dashboard } from '../Dashboard';
import { courseActions, courseProgressionsActions } from '../../_actions';
import { LearnCourseCard } from '../../_components/LearnCourseCard';
import { LearnSingleCourse } from '../../_components/LearnSingleCourse';
import { Loader } from '../../_components/Loader';
import CourseCardSkeleton from '../../_components/CourseCardSkeleton/CourseCardSkeleton';
import { TangiButton } from '../../_components/TangiLibrary';

import './Learn.scss';
import { useTranslation } from 'react-i18next';

function Learn() {
  // Redux selectors
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { courses, loading: coursesLoading } = useSelector((state) => state.courseReducer);
  const { courseProgressions, loading: progressionsLoading } = useSelector((state) => state.courseProgressionsReducer);
  const { user, Role } = useSelector((state) => state.authentication);
  const { clientDetails, clientLoading } = useSelector((state) => state.client);
  const [newRequiredCourses, setNewRequiredCourses] = useState([]);
  const { t } = useTranslation();

  // Single course data
  const [courseById, setCourseById] = useState(null);
  const [courseByIdProgression, setCourseByIdProgression] = useState(null);
  const { search } = useLocation();
  const courseid = new URLSearchParams(search).get('courseid');

  // Fetching courses data
  useEffect(() => {
    batch(() => {
      dispatch(courseActions.getCourses());
      dispatch(getClientById(clientId));
    });

    if (Role !== 'Lawyer') {
      dispatch(courseProgressionsActions.getByUser(user._id));
    }
  }, []);

  // Edit required Courses initial state
  useEffect(() => {
    setNewRequiredCourses(clientDetails?.requiredCourses || []);
  }, [clientDetails]);

  // If url has Id -> find the correct course in courses arr
  useEffect(() => {
    if (courseid && courses.length > 0) {
      setCourseById(courses.find((course) => course.id === courseid));
    }
  }, [courseid, courses]);

  // If url has Id -> find the correct Progression in progressions arr
  useEffect(() => {
    if (courseById) {
      const currentCourseProg = courseProgressions.find((progressions) => progressions.course === courseById.id);
      setCourseByIdProgression(currentCourseProg ?? 'not found');
    }
  }, [courseById, courseProgressions]);

  const saveChanges = () => {
    dispatch(updateRequiredCourses({ clientId: clientDetails.id, updatedCourses: { requiredCourses: newRequiredCourses } }));
  };

  return (
    <Dashboard title="">
      {(coursesLoading || progressionsLoading) && <Loader />}
      {courseById && !progressionsLoading ? (
        // Signle course view
        <LearnSingleCourse course={courseById} courseProgression={courseByIdProgression} />
      ) : (
        // Cards view
        <Row data-testid="courses" className="px-39 m-3 mx-3">
          <nav className="p-0 mb-4 navbar text-top">
            <span className="navbar-text">
              <h3 className="text-clr text-blk ">{t('LEARN_PAGE.MY_COURSES')}</h3>
            </span>
            {(Role?.includes('Admin') || Role?.includes('Lawyer')) && (
              <div>
                <TangiButton
                  text={t('LEARN_PAGE.SAVE_CHANGES')}
                  disabled={newRequiredCourses === clientDetails?.requiredCourses || (newRequiredCourses.length === 0 && !clientDetails?.requiredCourses) || clientLoading}
                  onClick={saveChanges}
                />
              </div>
            )}
          </nav>
          {courses.length > 0 && !progressionsLoading && !clientLoading ? (
            courses?.map((course) => {
              const relevantProgression = courseProgressions?.find((progression) => {
                return course.id === progression.course;
              });
              return <LearnCourseCard course={course} key={course.id} status={relevantProgression?.status} setNewRequiredCourses={setNewRequiredCourses} newRequiredCourses={newRequiredCourses} />;
            })
          ) : (
            <CourseCardSkeleton />
          )}
        </Row>
      )}
    </Dashboard>
  );
}

export { Learn };
