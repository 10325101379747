import styled from 'styled-components';

import { Avatar } from '_components/Avatar';
import { SPACING } from 'utils/theme';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { emptyAvatar } from 'assets/icons';
import { CREATED_FROM } from 'utils/enums';
import { AvatarNameProps } from '../Widgets/Widgets.types';

const AvatarNameAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  justify-content: space-between;
`;

const AvatarNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  max-width: 272px;
  .tangi-typography.widget-display-name {
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type Props = AvatarNameProps & { isEmptyAvatar?: boolean };

const AvatarName = (props: Props) => {
  const isIcon = props.createdFrom === CREATED_FROM.EMAIL_FINDER || props.createdFrom === CREATED_FROM.PATENT_TOOL;
  const image = !props.displayName ? emptyAvatar : undefined;

  return (
    <AvatarNameAndIconContainer className="avatar-name-icon-container">
      <AvatarNameContainer className="avatar-name-container">
        <Avatar name={props.displayName} size={AVATAR_SIZES.XS} image={props?.isEmptyAvatar ? emptyAvatar : image} email={props.email} />
        <TangiTypography className="widget-display-name" weight="semibold">
          {props.displayName}
        </TangiTypography>
      </AvatarNameContainer>
      {isIcon && <TangiSvgIcon component="discovery" />}
    </AvatarNameAndIconContainer>
  );
};

export default AvatarName;
