import { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import WidgetCard from '../WidgetCard';
import WidgetTitle from '../WidgetTitle';
import { useTranslation } from 'react-i18next';
import { TangiTypography } from '_components/TangiLibrary';
import { WidgetCardProps } from '../Widgets.types';
import PeopleStatusItem from './PeopleStatusItem';
import { getFormattedPeopleStatusData } from '../Widget.logic';

const WidgetContainer = styled.div`
  display: flex;
  padding: 0 ${SPACING[3]};
  flex-direction: column;
  width: 100%;
  margin-top: ${SPACING[4]};
`;

const PeopleStatusContainer = styled.div`
  display: flex;
  gap: ${SPACING[3]};
  flex: 1;
  align-items: center;
  overflow-x: auto;
`;

const PeopleStatusTotalTitle = styled.div`
  position: absolute;
  right: ${SPACING[3]};
  top: ${SPACING[3]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

interface Props extends WidgetCardProps {
  data: {
    total: number;
    active: number;
    pending: number;
    disabled: number;
  };
}

const PeopleStatus = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();
  const peopleStatusData = useMemo(() => getFormattedPeopleStatusData(props.data.active, props.data.pending, props.data.disabled), [props.data]);

  const renderPeopleTitle = (total: number) => {
    return (
      <PeopleStatusTotalTitle data-testid="people-status-total">
        <TangiTypography weight="semibold" color={NEUTRAL_SHADES[800]} data-testid="total-people-status">
          {total} {t('DASHBOARD_PAGE.WIDGETS.COMMON.TOTAL')}
        </TangiTypography>
      </PeopleStatusTotalTitle>
    );
  };

  const peopleStatusItems = useMemo(() => {
    return peopleStatusData.map((item) => {
      return <PeopleStatusItem key={item.status} {...item} />;
    });
  }, [peopleStatusData]);

  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.PEOPLE_STATUS')} />
        <WidgetContainer>
          {renderPeopleTitle(props.data.total)}
          <PeopleStatusContainer>{peopleStatusItems}</PeopleStatusContainer>
        </WidgetContainer>
        {props.children}
      </>
    </WidgetCard>
  );
});

export default PeopleStatus;
