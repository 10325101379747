import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/img/CANOPY-logo.png';
import './LoginHeader.scss';
import { useLoggedInRedirect } from '../../utils/customHooks';
import { useTranslation } from 'react-i18next';
import { config } from 'config/config';
import { AppRoutes } from 'routing/consts';

function LoginHeader() {
  const { loggedIn, redirect } = useLoggedInRedirect();
  const { t } = useTranslation();

  return (
    <header>
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" className="navbar-expand-md navbar-top py-3">
        <Container className="px-3 py-2">
          <Navbar.Brand href="/">
            <img src={logo} className="tangi-logo" width="120" alt="Canopy" />
          </Navbar.Brand>
          {/* The below is the login and home buttons in login screen */}
          {!config.isUnderMaintenance && (
            <>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto" />
                <Nav className="me-2">
                  <NavLink exact activeClassName="" className="me-2" to={loggedIn ? redirect : AppRoutes.LOGIN}>
                    <i className="fas fa-home me-1 text-primary" />
                    <span className="fw-600">{loggedIn ? t('LOGIN_PAGE.NAVBAR.HOME') : t('LOGIN_PAGE.NAVBAR.LOGIN')}</span>
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
    </header>
  );
}

export { LoginHeader };
