import { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';
import styled, { css } from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { ERROR_SHADES, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const ErrorContainer = styled(FlexContainer)`
  min-height: ${SPACING[3.5]};
`;

export const customStarStyles: CSSProperties = {
  marginLeft: SPACING[0],
  marginBottom: SPACING[0],
};

export const StyledFormGroup = styled(Form.Group)<{ $menuScroll?: boolean }>`
  width: 100%;

  // Control styles
  ${({ $menuScroll: menuScroll = false }) => css`
    .tangi-selector__control {
      border-color: ${NEUTRAL_SHADES[400]};
      box-shadow: none;
      &:hover {
        border-color: ${NEUTRAL_SHADES[400]};
      }
    }

    .tangi-selector-error__control {
      border-color: ${ERROR_SHADES.BOOTSTRAP} !important;
      box-shadow: none;
      &:hover {
        border-color: ${ERROR_SHADES.BOOTSTRAP} !important;
      }
    }

    // Menu styles
    .tangi-selector__menu {
      z-index: 2;
    }

    // Menu list styles
    .tangi-selector__menu-list,
    .tangi-selector-error__menu-list {
      height: ${menuScroll ? SPACING[9] : 'auto'};
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: ${NEUTRAL_SHADES[600]};
        visibility: hidden;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${NEUTRAL_SHADES[600]};
        border-radius: 5px;
        opacity: 1;
      }
    }
  `}
`;

export const StyledLabelContainer = styled(Form.Label)`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
  margin-bottom: ${SPACING[0]};
`;
