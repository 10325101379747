import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';
import { DuoContainer } from 'utils/globalStyles';

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: 20px 24px 0px 24px;
  gap: 4px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;
export const PhrasesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const IconAndText = styled.div`
  display: flex;
  gap: 12px;
  .tangi-icon-container {
    margin-top: 4px;
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px;
  max-height: 432px;
  overflow-y: auto;
  height: 180px;
  gap: ${SPACING[3]};
  margin-top: ${SPACING[3]};
`;

export const StyledModalFooter = styled(Modal.Footer)`
  padding: ${SPACING[4]} ${SPACING[0]} ${SPACING[3]};
  div:first-of-type {
    margin-right: ${SPACING[4]};
  }
`;

export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  height: 30px;
  min-width: 520px;
  padding: 12px;
  border-radius: 4px;
  border: 1px dashed ${NEUTRAL_SHADES[600]};
  background: ${PRIMARY_SHADES[50]};
`;

export const DeleteFileButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const DropZoneTextContainer = styled(DuoContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  .tangi-typography:last-of-type {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;
