import { useContext } from 'react';

import { SharePointWizardContext } from './SharePointWizardContext';
import { SharePointWizardContextProps } from './types';

export const useSharePointWizardContext = (): SharePointWizardContextProps => {
  const context = useContext(SharePointWizardContext);
  if (!context) {
    throw new Error('useSharePointWizardContext must be used within a SharePointWizardProvider');
  }
  return context;
};
