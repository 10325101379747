import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '_helpers';

import { adminsExceptLearnAndLawyers, lawyers } from 'utils/roles';

const useRole = () => {
  const Role = useSelector((state: RootState) => state.authentication.Role);

  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);
  const isAdmin: boolean = useMemo(() => adminsExceptLearnAndLawyers.includes(Role), [Role]);

  return { Role, isLawyer, isAdmin };
};

export default useRole;
