import { EmptyStateContainer } from './style';
import { NEUTRAL_SHADES } from 'utils/theme';
import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';

interface Props {
  filterName: string;
}
const FilterEmptyState = ({ filterName }: Props) => {
  const { t } = useTranslation();

  const breakWordTypographyStyles = `
  white-space: pre-line;
  text-align: center;
  `;
  return (
    <EmptyStateContainer>
      <TangiTypography color={NEUTRAL_SHADES[800]} customStyles={breakWordTypographyStyles}>
        {t('PEOPLE.SELECT.FILTER_EMPTY_STATE', { filterName })}
      </TangiTypography>
    </EmptyStateContainer>
  );
};

export default FilterEmptyState;
