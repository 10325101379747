import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { TangiTypography, TangiButton } from '_components/TangiLibrary';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import ContractStatusNotification from '_components/ContractXraySearch/components/ContractStatusNotification';
import { uploadContractApplication } from 'redux-toolkit/thunks/contractThunks';
import { contractActions } from 'redux-toolkit/slices/contractSlice';
import { MIME_TYPES } from 'utils/enums';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import { RootState } from '_helpers';
import { removeStateFromLocalStorage } from 'utils/localStorage';
import { contractConstants } from '_constants';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const DefaultContentContainer = styled.div`
  min-height: 165px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${SPACING[0]} ${SPACING[0]} ${SPACING[2]} ${SPACING[3]};
  gap: ${SPACING[3]};
`;

interface Props {
  onClickContractStatus: () => void;
  description?: string;
  buttonText?: string;
  contentContainer?: ComponentType;
}

const ContractXraySearch = ({ description, buttonText, contentContainer, onClickContractStatus }: Props) => {
  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

  const contractData = useSelector((state: RootState) => state.contract.contractData);

  const { t } = useTranslation();
  const { clientId } = useParams<{ clientId: string }>();

  const dispatch = useDispatch();

  const contractJobStatus = useXRayJobStatus(
    (state: RootState) => state.contract.contractLoaders,
    (state: RootState) => state.contract.contractData,
    (state: RootState) => state.contract.contractRequestInfo,
    (state: RootState) => state.contract.isCanceled,
  );

  const handleUploadFile = (file: File[]) => {
    dispatch(uploadContractApplication({ files: file, clientId }));
    setShowUploadFileModal(false);
  };

  const handleCancelProcess = () => {
    removeStateFromLocalStorage(contractConstants.CONTRACT_REQUEST_INFO);
    dispatch(contractActions.resetEntireState());
  };

  const ContentContainer = contentContainer || DefaultContentContainer;

  return (
    <>
      <ContentContainer>
        <TangiTypography color={NEUTRAL_SHADES[900]}>{description ?? t('DISCOVERY_LOBBY.CARD_TEXT.UPLOAD_CONTRACT')}</TangiTypography>
        <div>
          <TangiButton smallbtn={true} text={buttonText ?? t('DISCOVERY_LOBBY.BUTTONS_TEXT.UPLOAD_CONTRACT_FILE')} onClick={() => setShowUploadFileModal(true)} data-testid="upload-contract-file" />
        </div>
        <ContractStatusNotification
          status={contractJobStatus}
          fileName={contractData?.filename || ''}
          details={contractData?.details}
          resultsNum={contractData?.response?.checklist_results ? Object.keys(contractData?.response?.checklist_results).length : 0}
          onCancel={handleCancelProcess}
          onClick={onClickContractStatus}
          contractSource={contractData?.source_type || XRAY_SOURCE_TYPE.FILE}
        />
      </ContentContainer>
      <UploadFileModal
        acceptedFilesList={[MIME_TYPES.DOC, MIME_TYPES.DOCX, MIME_TYPES.PDF].join(', ')}
        isOpen={showUploadFileModal}
        title={t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.UPLOAD_CONTRACT_APPLICATION_FILE')}
        onClose={() => setShowUploadFileModal(false)}
        onSubmit={handleUploadFile}
        submitButtonText={t('GENERAL.BUTTONS_TEXT.CONTINUE')}
        customDragNDropText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.DRAG_AND_DROP_WORD_FILE')}
        customDragNDropRejectText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_WORD_FILE')}
      />
    </>
  );
};

export default ContractXraySearch;
