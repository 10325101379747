/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AUDIT_TYPE, LeanAccount, LeanClient, SharePointSubAuditType } from 'utils/types/activityLog/asset/types';
import { TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';
import { Container, FlexDiv, FlexSpan } from '_components/ActivityLog/style';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { getDisplayName } from '_components/ActivityLog/utils';
import { Recipient } from 'utils/types/assets/assets';

interface Props {
  data: any;
  initiator: {
    account: LeanAccount | undefined;
    client: LeanClient;
  };
  auditType: string;
  subAuditType?: string;
}
const MAX_DISPLAYED_PEOPLE = 2;

const StyledSpan = styled.span`
  margin-top: ${SPACING[0.5]};
`;

export const AssetActivityNotify = ({ initiator, data, auditType, subAuditType }: Props) => {
  const { account, client } = initiator;
  const { t } = useTranslation();
  const displayText = getDisplayName(account, client);

  const isSharePointSharedEvent = subAuditType === SharePointSubAuditType.SHARED && auditType === AUDIT_TYPE.SHARE_POINT;

  const renderByAsAccount = (personAccount: any) => {
    return (
      <FlexSpan>
        <Avatar size={AVATAR_SIZES.XS} name={personAccount?.fullName || personAccount.email || ''} email={personAccount.email || ''} />
        <TangiTypography type="subheading" weight="bold">
          {personAccount?.fullName || personAccount.email}
        </TangiTypography>
      </FlexSpan>
    );
  };

  const renderNotificationText = (people: Recipient[]) => {
    if (people.length > MAX_DISPLAYED_PEOPLE) {
      return (
        <FlexSpan>
          {renderByAsAccount(people[0])},{renderByAsAccount(people[1])}
          <StyledSpan>
            {isSharePointSharedEvent ? t('ACTIVITY_LOG.ASSET.WERE_SHARED_ON_ASSET', { NAMES: people.length - 2 }) : t('ACTIVITY_LOG.ASSET.WERE_NOTIFIED', { NAMES: people.length - 2 })}
          </StyledSpan>
        </FlexSpan>
      );
    } else {
      return (
        <FlexSpan>
          {people.map((person, index) => (
            <React.Fragment key={person._id}>
              {index > 0 && ', '}
              {renderByAsAccount(person)}
            </React.Fragment>
          ))}
          {isSharePointSharedEvent
            ? t('ACTIVITY_LOG.ASSET.WAS_SHARED_ON_ASSET', {
                ACTION: people.length === 1 ? t('ACTIVITY_LOG.ASSET.WAS') : t('ACTIVITY_LOG.ASSET.WERE'),
              })
            : t('ACTIVITY_LOG.ASSET.WAS_NOTIFIED', {
                ACTION: people.length === 1 ? t('ACTIVITY_LOG.ASSET.WAS') : t('ACTIVITY_LOG.ASSET.WERE'),
              })}
        </FlexSpan>
      );
    }
  };

  return (
    <Container>
      {data.map((item: any, index: number) => (
        <FlexDiv key={index}>{renderNotificationText(item.to.flatMap((personArray: any) => personArray))}</FlexDiv>
      ))}
      <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
        {t('ACTIVITY_LOG.BY', { NAME: displayText })}
      </TangiTypography>
    </Container>
  );
};
