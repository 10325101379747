import styled, { css } from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SCROLL_GRADIENT_SIZE } from './utils';

export const horizontalLabel = 0;
export const verticalLabel = -90;

export const HeatMapChartScrollContainer = styled(FlexContainer)<{ isCompetitiveChart?: boolean }>`
  height: 100%;
  max-width: 65vw;
  overflow-x: scroll;
  margin-top: ${({ isCompetitiveChart }) => (isCompetitiveChart === true ? '-60px' : '0px')};
`;

export const HeatMapChartAndLabelContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
`;

export const RotateLabelStyle = `
transform: rotate(270deg);
`;

const ScrollGradient = css<{ height: number }>`
  position: relative;
  ${({ height }) => (height ? height : 800)};
  width: ${SCROLL_GRADIENT_SIZE}px;
`;

export const RightScrollGradient = styled.div<{ height: number }>`
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.3));
  margin-left: -${SCROLL_GRADIENT_SIZE}px;
  ${ScrollGradient};
`;
export const LeftScrollGradient = styled.div<{ height: number }>`
  background: linear-gradient(to left, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
  margin-right: -${SCROLL_GRADIENT_SIZE}px;
  z-index: 97;
  ${ScrollGradient};
`;

export const GradientContainer = styled(FlexContainer)`
  flex-direction: row;
`;
