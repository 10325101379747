import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import ThirdPartyTag from '../../../ThirdPartyTag/ThirdPartyTag';
import { AssetCardFile } from './AssetCardFile';
import { AssetActiveCardHeader } from './AssetCardActiveHeader';
import { AssetActiveCardFooter } from './AssetCardActiveFooter';
import { CREATED_FROM } from '../../../../utils/enums';
import { formatDate } from '../../../../utils/dateUtils';
import { DuoContainer } from '../../../../utils/globalStyles';
import { AssetBodyContainer, AssetName } from '../../style';
import { StyledActiveAssetCard, Divider, AssetTagsContainer, AssetNumber, StyledMagicIcon } from './style';

const AssetCardActive = ({ asset, permissions, handleDistributeAsset, options, isAdmin }) => {
  const { t } = useTranslation();

  const renderMagicIcon = (value) => {
    let tooltipContent = '';
    let isShowIcon = false;

    switch (value?.createdFrom) {
      case CREATED_FROM.EMAIL_FINDER:
        tooltipContent = `${t('ASSET_PAGE.TOOLTIP.ASSETS_CREATED_BY_EMAIL_XRAY')} ${formatDate(value?.createdAt)}`;
        isShowIcon = true;
        break;
      case CREATED_FROM.PATENT_TOOL:
        tooltipContent = `${t('ASSET_PAGE.TOOLTIP.ASSETS_CREATED_BY_PATENT_XRAY')} ${formatDate(value?.createdAt)}`;
        isShowIcon = true;
        break;
      default:
        isShowIcon = false;
    }

    return (
      isShowIcon && (
        <Tippy content={<div className="text-xs">{tooltipContent}</div>}>
          <StyledMagicIcon />
        </Tippy>
      )
    );
  };

  return (
    <StyledActiveAssetCard permissionView={permissions?.Assetview} data-testid="asset-card">
      <AssetActiveCardHeader asset={asset} permissions={permissions} isAdmin={isAdmin} options={options} />
      <Divider />
      <AssetBodyContainer>
        <DuoContainer>
          <AssetName>
            {renderMagicIcon(asset)}
            <span>{asset.name}</span>
          </AssetName>
          {asset?.isThirdParty && <ThirdPartyTag asset={asset} />}
        </DuoContainer>

        <AssetTagsContainer>
          <span>{asset.tags[0]?.name}</span>
          {asset.tags.length > 1 && (
            <Tippy
              content={asset.tags.map((item, index) => (
                <div key={index}>{item.name}</div>
              ))}
              placement="top-end"
            >
              <span>+ {asset.tags.length - 1}</span>
            </Tippy>
          )}
          <AssetNumber>{asset.refNumber}</AssetNumber>
        </AssetTagsContainer>
      </AssetBodyContainer>

      <AssetCardFile asset={asset} />
      <Divider />
      <AssetActiveCardFooter asset={asset} permissions={permissions} handleDistributeAsset={handleDistributeAsset} />
    </StyledActiveAssetCard>
  );
};

export default AssetCardActive;
