import { loadStateFromLocalStorage } from "utils/localStorage";
import { LOCAL_STORAGE_KEYS } from "utils/token/localStorage";


export const headers = () => {
  const accessToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN);
  const user = JSON.parse(localStorage.getItem('user'));
  const activeAccount = JSON.parse(localStorage.getItem('activeAccount'));
  let header;
  if (user && activeAccount) {
    header = {
      Authorization: `Bearer ${accessToken}`,
      UserId: user._id,
      AccountId: activeAccount._id,
      'Content-Type': 'application/json',
    };
  } else {
    header = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
  }
  return header;
};

export const headersmultipart = () => {
  const accessToken = loadStateFromLocalStorage(LOCAL_STORAGE_KEYS.JWT_TOKEN)
  const user = JSON.parse(localStorage.getItem('user'));
  const activeAccount = JSON.parse(localStorage.getItem('activeAccount'));
  let header;
  if (user && activeAccount) {
    header = {
      Authorization: `Bearer ${accessToken}`,
      UserId: user._id,
      AccountId: activeAccount._id,
      'Content-Type': 'multipart/form-data',
    };
  } else {
    header = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    };
  }
  return header;
};
