import { AppDispatch } from '_helpers/store';
import { getMembers, validateSPFolders, validateSPSecrets } from 'redux-toolkit/thunks/sharePointThunks';
import { FormSchema, SharePointWizardStepKey } from '../types';
import { ValidateSPFoldersPayload, ValidateSPSecretsPayload } from 'utils/types/sharepoint/sharepoint';

export const requiresAsyncValidation = (stepKey: SharePointWizardStepKey): boolean => {
  return [SharePointWizardStepKey.ALLOW_ACCESS, SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS].includes(stepKey);
};

export const requiresAdditionalAsyncAction = (stepKey: SharePointWizardStepKey): boolean => {
  return stepKey === SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS;
};

export const getAsyncActionPayloadData = (stepId: SharePointWizardStepKey, formValues: FormSchema) => {
  const secrets = { applicationId: formValues.applicationId, directoryId: formValues.directoryId, clientSecret: formValues.clientSecret };

  switch (stepId) {
    case SharePointWizardStepKey.ALLOW_ACCESS:
      return {
        stepId,
        data: { secrets },
      };
    case SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS:
    case SharePointWizardStepKey.ADD_USERS:
      return {
        stepId,
        data: {
          secrets,
          sites: formValues.sites,
        },
      };
    default:
      return null;
  }
};

export const getWizardAsyncAction = (stepId: SharePointWizardStepKey, formValues: FormSchema) => {
  const payload = getAsyncActionPayloadData(stepId, formValues);
  if (!payload) return null;

  switch (stepId) {
    case SharePointWizardStepKey.ALLOW_ACCESS:
      return (dispatch: AppDispatch) => dispatch(validateSPSecrets(payload as ValidateSPSecretsPayload));
    case SharePointWizardStepKey.CONNECT_SITES_OR_FOLDERS:
      return (dispatch: AppDispatch) => dispatch(validateSPFolders(payload as ValidateSPFoldersPayload));
    case SharePointWizardStepKey.ADD_USERS:
      return (dispatch: AppDispatch) => dispatch(getMembers(payload as ValidateSPFoldersPayload));
    default:
      return null;
  }
};
