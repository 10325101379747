import { adminAuthConstants } from '../_constants';

const user = localStorage.getItem('user');

const initialState = user ? { loggedIn: true, user, result: '', istokenExpired: false, apiKey: '', accessToken: '' } : {};

export function adminAuthentication(state = initialState, action) {
  switch (action.type) {
    case adminAuthConstants.API_KEY:
      return {
        loggedIn: state.loggedIn,
        user: state.user,
        apiKey: action.apiKey,
      };
    case adminAuthConstants.ADMIN_LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case adminAuthConstants.ADMIN_LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case adminAuthConstants.ADMIN_LOGIN_FAILURE:
      return {
        loggedIn: false,
        result: action,
      };
    case adminAuthConstants.LOGOUT:
      return {};
    case adminAuthConstants.SET_TOKEN_EXPIRED:
      return { istokenExpired: true };
    default:
      return state;
  }
}
