import styled from 'styled-components';
import { InputGroup } from 'react-bootstrap';

import { NEUTRAL_SHADES } from '../../../utils/theme';

export const StyledInputGroup = styled(InputGroup)`
  input {
    border-radius: 4px !important;
    &:hover {
      border: 1px solid ${NEUTRAL_SHADES[600]};
    }
  }
`;
