import axios from 'axios';

/**
 * Makes an API call using Axios with the provided request options.
 *
 * **Note:** This function is part of the old Redux (non-Toolkit) codebase and is intended for eventual removal
 * during future refactoring to use Redux Toolkit.
 *
 * @function
 * @param {Object} requestOptions - The configuration options for the Axios request. This includes the HTTP method, URL, headers, and data payload.
 * @returns {Promise<any>} A promise that resolves with the response data if the request is successful, or rejects with an error object if it fails.
 *
 * @example
 * const requestOptions = {
 *   method: 'GET',
 *   url: 'https://api.example.com/data',
 *   headers: { Authorization: 'Bearer token' },
 * };
 * apiCall(requestOptions)
 *   .then(data => console.log(data))
 *   .catch(error => console.error(error));
 */
export function apiCall(requestOptions) {
  return new Promise(function (resolve, reject) {
    axios(requestOptions).then(
      (result) => {
        resolve(result.data);
      },
      (error) => {
        if (error?.response) {
          reject(error?.response.data);
        } else {
          reject(error);
        }
      },
    );
  });
}
