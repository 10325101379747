import { useSnackbar, VariantType, SnackbarOrigin } from 'notistack';
import { useTranslation } from 'react-i18next';

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showSnackbar = (variant: VariantType, vertical: SnackbarOrigin['vertical'], horizontal: SnackbarOrigin['horizontal'], message: string) => {
    enqueueSnackbar(t(message), {
      variant,
      autoHideDuration: 3000,
      anchorOrigin: { vertical, horizontal },
    });
  };

  return { showSnackbar };
};

export default useToast;
