import styled, { css, CSSObject } from 'styled-components';
import { SPACING } from 'utils/theme';

export const CollapseControlContainer = styled.div<{ labelStyles?: CSSObject }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: ${SPACING[1]};
  ${({ labelStyles }) => labelStyles && css(labelStyles)};
`;
