import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next';

import { TangiChip, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import ClientOrPartnershipNameAndLogo from '_components/ThirdPartyTag/components/ClientOrPartnershipNameAndLogo';
import { Avatar } from '../../_components/Avatar/Avatar';
import { Asset, ASSET_METADATA_VALUE, File } from '../../utils/types/assets/assets';
import { RootState } from '_helpers';
import { NEUTRAL_SHADES } from '../../utils/theme';
import { formatDate } from '../../utils/dateUtils';
import { IRoles } from 'utils/roles';
import { PARTNERSHIP_STATUS } from 'utils/enums';
import { AVATAR_SIZES, getIconFromFileName } from '../../utils/componentUtils';
import { ReactComponent as Papers } from '../../assets/img/papers.svg';
import {
  ContentContainer,
  MetaDataCard,
  DetailsCard,
  CardDivider,
  ChipContainer,
  FlexColumn,
  AvatarContainer,
  UrlLink,
  RefNumberContainer,
  EmptyDetailes,
  PartnershipNameAndStatus,
  CreatedFromLine,
  FileContainer,
} from './style';
import { AssetCardFile } from '_components/ClientAssetCards/components/AssetCardActive/AssetCardFile';

export interface IAssetOverviewProps {
  asset: Asset;
  handleFileClick: (file: File) => void;
  isAdmin?: boolean;
  isPreview?: boolean;
}

const AssetOverview = ({ asset, handleFileClick, isAdmin = false, isPreview = false }: IAssetOverviewProps) => {
  const Role = useSelector((state: RootState) => state.authentication.Role);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { t } = useTranslation();
  const isSharePointType = !!asset?.sharePoint;

  // Copy reference id
  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(`${asset.client.assetsPrefix}.${asset.refNumber}`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderChips = (title: string, array: any[]) => {
    // TODO: fix any types
    return (
      <div>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{title}</TangiTypography>
        <ChipContainer>
          {array?.map((element) => (
            <TangiChip key={element._id} text={element?.name || ''} maxWidth={320} />
          ))}
        </ChipContainer>
      </div>
    );
  };

  const renderOther = (title: string, text: string) => {
    return (
      <div>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{title}</TangiTypography>
        <TangiTypography type="subheading">{text}</TangiTypography>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.DESCRIPTION')}</TangiTypography>
        <TangiTypography type="subheading">{asset.description}</TangiTypography>
      </div>
    );
  };

  const renderContributors = () => {
    return (
      <FlexColumn>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.CONTRIBUTORS')}</TangiTypography>
        <>
          {asset.contributor.map((cont) => (
            <AvatarContainer key={cont._id}>
              <Avatar size={AVATAR_SIZES.XS} name={cont?.displayName || cont?.user?.email || ''} email={cont?.user?.email || ''} />
              <TangiTypography weight="semibold">{cont?.displayName || cont?.user?.email || ''}</TangiTypography>
            </AvatarContainer>
          ))}
        </>
      </FlexColumn>
    );
  };

  const renderFiles = () => {
    const handleSharePointFile = (asset: Asset) => {
      if (asset?.sharePoint) {
        window.open(asset.sharePoint.fileLink, '_blank', 'noopener,noreferrer');
      }
    };

    return (
      <FlexColumn>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.FILES')}</TangiTypography>
        {isPartnershipDisabled() ? (
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[600]}>
            {t('ASSET_PAGE.CONTENT.NO_ACCESS')}
          </TangiTypography>
        ) : (
          <>
            {isSharePointType ? (
              <AssetCardFile asset={asset} handleFileClick={() => handleSharePointFile(asset)} isDisabled />
            ) : (
              asset.files.map((file) => (
                <FileContainer key={file._id} onClick={() => handleFileClick(file)} data-testid="file-container">
                  <div>{getIconFromFileName(file.name)}</div>
                  <TangiTypography weight="semibold">{file.name}</TangiTypography>
                </FileContainer>
              ))
            )}
          </>
        )}
      </FlexColumn>
    );
  };

  const renderUrls = () => {
    return (
      <FlexColumn>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.ASSET_LABEL_LOCATION')}</TangiTypography>
        {isPartnershipDisabled() ? (
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[600]}>
            {t('ASSET_PAGE.CONTENT.NO_ACCESS')}
          </TangiTypography>
        ) : (
          <>
            {asset.urls.map((url) => {
              const cleanUrl = url.url.replace(/^https?:\/\//, '');
              return (
                <div key={url._id}>
                  <TangiTypography type="subheading">{url.label}</TangiTypography>
                  <UrlLink href={`http://${cleanUrl}`} target="_blank" rel="noreferrer" type="subheading">
                    {url.url}
                  </UrlLink>
                </div>
              );
            })}
          </>
        )}
      </FlexColumn>
    );
  };

  const renderAssetMeta = () => {
    const metadataTypeToTranslationKey: Record<ASSET_METADATA_VALUE, string> = {
      [ASSET_METADATA_VALUE.BUSINESS_RELATED]: 'ASSET_PAGE.CONTENT.BUSINESS_RELATED',
      [ASSET_METADATA_VALUE.COMMERCIAL_PRODUCT]: 'ASSET_PAGE.CONTENT.COMMERCIAL_PRODUCT',
    };

    const { assetMetaData } = asset;

    return (
      <div>
        {assetMetaData.map((metadata, index) => {
          return (
            <div key={index}>
              <TangiTypography color={NEUTRAL_SHADES[800]}>{t(metadataTypeToTranslationKey[metadata.type])}</TangiTypography>
              <TangiTypography type="subheading">{metadata.value}</TangiTypography>
            </div>
          );
        })}
      </div>
    );
  };

  const isAssetDetails = (): boolean => {
    const hasContributor = !!asset?.contributor?.length;
    const hasFiles = !!asset?.files?.length;
    const hasUrls = !!asset?.urls?.length;
    const hasDescription = !!asset?.description;
    return hasDescription || hasContributor || hasFiles || hasUrls;
  };

  const isPartnershipDisabled = () => {
    return asset?.createdBy?.partnership?.status === PARTNERSHIP_STATUS.DISABLED;
  };

  const whoCreatedTheAsset = asset?.createdBy?.displayName || asset?.createdBy?.user?.email || '';
  return (
    <>
      <ContentContainer isAdmin={isAdmin} isPreview={isPreview}>
        {/* Metadata card */}
        <MetaDataCard>
          <TangiTypography weight="semibold" type="heading-md">
            {t('ASSET_PAGE.CONTENT.METADATA')}
          </TangiTypography>
          <CardDivider />
          <div>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.CREATED_ON')}</TangiTypography>
            <TangiTypography type="subheading">{asset?.createdAt && formatDate(asset.createdAt)}</TangiTypography>
          </div>
          <div>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.CREATED_BY')}</TangiTypography>
            <CreatedFromLine>
              <AvatarContainer>
                <Avatar size={AVATAR_SIZES.XS} name={whoCreatedTheAsset} email={asset?.createdBy?.user?.email || ''} />
                <TangiTypography weight="semibold">{whoCreatedTheAsset}</TangiTypography>
              </AvatarContainer>
              {asset?.createdBy?.partnership && <TangiTypography color={NEUTRAL_SHADES[800]}>from</TangiTypography>}
              {asset?.createdBy?.partnership && (
                <PartnershipNameAndStatus>
                  <ClientOrPartnershipNameAndLogo clientOrPartnership={Role === IRoles.PARTNER ? asset?.createdBy?.client : asset?.createdBy?.partnership} />
                </PartnershipNameAndStatus>
              )}
            </CreatedFromLine>
          </div>
          <div>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.LAST_MODIFIED_ON')}</TangiTypography>
            <TangiTypography type="subheading">{asset?.updatedAt && formatDate(asset.updatedAt)}</TangiTypography>
          </div>
          <div>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.REFERENCE_NUMBER')}</TangiTypography>
            <RefNumberContainer>
              <TangiTypography type="subheading">
                {asset.client.assetsPrefix}.{asset.refNumber}
              </TangiTypography>
              <Tippy content={isCopied ? t('ASSET_PAGE.CONTENT.COPIED') : t('ASSET_PAGE.CONTENT.COPY')} interactive={true} hideOnClick={false}>
                <div onClick={handleCopyToClipboard} data-testid="copy-reference">
                  <TangiSvgIcon component="copy" color={NEUTRAL_SHADES[800]} />
                </div>
              </Tippy>
            </RefNumberContainer>
          </div>
          {!!asset?.importance?.value && (
            <div>
              <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ASSET_PAGE.CONTENT.IMPORTANCE')}</TangiTypography>
              <TangiTypography type="subheading">{asset?.importance?.value}</TangiTypography>
            </div>
          )}
          <>
            {asset?.tags && !!asset?.tags?.length && renderChips(t('ASSET_PAGE.CONTENT.TAGS'), asset.tags)}
            {asset?.businessValues && !!asset?.businessValues?.length && renderChips(t('ASSET_PAGE.CONTENT.BUSINESS_VALUE'), asset.businessValues)}
            {asset?.projects && !!asset?.projects?.length && renderChips(t('ASSET_PAGE.CONTENT.PROJECTS'), asset?.projects)}
            {asset?.departments && !!asset?.departments?.length && renderChips(t('ASSET_PAGE.CONTENT.DEPARTMENTS'), asset?.departments)}
            {asset?.others1 && renderOther(t('ASSET_PAGE.CONTENT.OTHERS_1'), asset?.others1)}
            {asset?.others2 && renderOther(t('ASSET_PAGE.CONTENT.OTHERS_2'), asset?.others2)}
            {asset?.assetMetaData && asset?.assetMetaData.length > 0 && renderAssetMeta()}
          </>
        </MetaDataCard>

        {/* Details card */}
        <DetailsCard>
          <TangiTypography weight="semibold" type="heading-md">
            {t('ASSET_PAGE.CONTENT.DETAILS')}
          </TangiTypography>
          <CardDivider />
          {isAssetDetails() ? (
            <>
              {asset?.description && renderDescription()}
              {asset?.contributor && !!asset?.contributor?.length && renderContributors()}
              {(asset?.files?.length || isSharePointType) && renderFiles()}
              {asset?.urls && !!asset?.urls?.length && renderUrls()}
            </>
          ) : (
            <EmptyDetailes isAdmin={isAdmin}>
              <Papers />
              <TangiTypography color={NEUTRAL_SHADES[700]}>{t('ASSET_PAGE.CONTENT.NO_DETAILS_DATA')}</TangiTypography>
            </EmptyDetailes>
          )}
        </DetailsCard>
      </ContentContainer>
    </>
  );
};

export default AssetOverview;
