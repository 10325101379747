import { ContractContainer } from './styles';
import { ContractParameters } from './components/ContractParameters/ContractParameters';
import { ReactNode } from 'react';

interface Props {
  header?: ReactNode;
}

export const TangiContractContent = ({ header }: Props) => (
  <ContractContainer>
    {header}
    <ContractParameters />
  </ContractContainer>
);
