import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Divider, Square } from './style';
import { CHART_OPTIONS } from 'pages/TradeSecretsAnalysis/components/Card/type'; // Assuming this is where CHART_OPTIONS is defined
import { InfoRow } from 'pages/TradeSecretsAnalysis/components/InfoRow/InfoRow';

interface PredictedInfoProps {
  subject: string;
  potentialTradeSecretsCount: number;
  patentsCount?: number | string;
  squareColor: string;
  chartType: CHART_OPTIONS;
}

const PredictedInfo = ({ subject, potentialTradeSecretsCount, patentsCount, squareColor, chartType }: PredictedInfoProps) => {
  const { t } = useTranslation();

  const renderInfo = () => {
    switch (chartType) {
      case CHART_OPTIONS.HEAT:
        return (
          <>
            <InfoRow label={t('PREDICTED_PORTFOLIO.CHARTS.SUBJECT')} value={subject} icon={<Square color={squareColor} />} />
            <Divider />
            <InfoRow label={t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.PATENT')} value={patentsCount || ''} />
            <Divider />
            <InfoRow label={t('PREDICTED_PORTFOLIO.WIDGET.POTENTIAL_TREDE_SECRETS')} value={potentialTradeSecretsCount} />
          </>
        );

      case CHART_OPTIONS.TREE:
        return (
          <>
            <InfoRow label={t('PREDICTED_PORTFOLIO.CHARTS.SUBJECT')} value={subject} icon={<Square color={squareColor} />} />
            <Divider />
            <InfoRow label={t('PREDICTED_PORTFOLIO.WIDGET.POTENTIAL_TREDE_SECRETS')} value={potentialTradeSecretsCount} />
            <Divider />
            <InfoRow label={t('PREDICTED_PORTFOLIO.WIDGET.PATENT')} value={patentsCount || ''} />
          </>
        );

      default:
        return null;
    }
  };

  return <Container>{renderInfo()}</Container>;
};

export default PredictedInfo;
