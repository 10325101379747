import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonContainer } from './style';

const OnboardingSkeleton = () => {
  return (
    <SkeletonContainer>
      <Skeleton height={80} count={3} />
      <Skeleton height={130} count={2} />
    </SkeletonContainer>
  );
};
export default OnboardingSkeleton;
