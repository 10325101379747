import { IP_AUDIT_TEMPLATE, IpAuditActionStepSubmission, IpAuditStepKey } from '_components/IpAudit/types';
import { StepTitle } from '../components/StepTitle/StepTitle';
import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { NEUTRAL_SHADES } from 'utils/theme';
import { useTranslation } from 'react-i18next';
import { DownloadText, StepContainer, StepInnerContainer, StepTextContainer } from '../styles';
import { TangiDragAndDropFile } from '_components/TangiLibrary/TangiDragAndDropFile/TangiDragAndDropFile';
import { NOTIFICATION_VARIANTS, TangiAlert, TangiCheckbox, TangiNotification } from '_components/TangiLibrary';
import { useHandlePolicy } from '../hooks/useHandlePolicy';
import { RESULT_STATUS } from 'utils/enums';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { FileDisplay } from './FileDisplay';
import { useEffect, useState } from 'react';
import { POLICIES } from 'utils/types/client/client';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { useHandleSteps } from '_components/TangiLibrary/TangiStepper/useHandleSteps';
import { downloadPolicyTemplate } from 'redux-toolkit/thunks/clientThunks';
import { Spinner } from 'react-bootstrap';
import { useIpAuditPopup } from '../../context/IpAuditPopup.provider';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { alignTypographyWithCheckbox, CheckboxContainer } from '_components/IpAudit/styles';
import { FileRejection } from 'react-dropzone';
import useScrollToElement from '_hooks/useScrollToElement';
import { SpinnerContainer, TemplateContainer } from './styles';

export const TradeSecretPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setHasStepChanges } = useIpAuditPopup();

  const { ref: scrollRef, scrollToElement } = useScrollToElement<HTMLDivElement>();

  const tradeSecretPolicyAction: IpAuditActionStepSubmission = useSelector((state: RootState) => state.client.ipAudit[IpAuditStepKey.TRADE_SECRET_POLICY]);

  const isTemplateLoading = useSelector((state: RootState) => state.client.policyTemplateLoader);
  const { handleStepChange } = useHandleSteps();
  const {
    uploadDroppedFile,
    removeDroppedFile,
    onDropFile,
    acceptedFilesList,
    dropFailData,
    fileDetails,
    droppedFile,
    removeFileDetails,
    handleDownload,
    isDownloading,
    updateIsDeclaredNoPolicy,
    isDeclaredNoFile,
    isUserHasPolicy,
  } = useHandlePolicy(POLICIES.TRADE_SECRET);

  const [noPolicyChecked, setNoPolicyChecked] = useState(isDeclaredNoFile);

  useEffect(() => {
    noPolicyChecked && scrollToElement();
  }, [noPolicyChecked]);

  const onSubmit = async () => {
    if (noPolicyChecked) {
      await updateIsDeclaredNoPolicy(noPolicyChecked);
    } else {
      await uploadDroppedFile();
      removeDroppedFile();
    }
    dispatch(mixpanelEvents.completeIPAuditStep(POLICIES.TRADE_SECRET));
  };

  const downloadTemplate = () => {
    const fileName = IP_AUDIT_TEMPLATE.TRADE_SECRET;
    dispatch(downloadPolicyTemplate(fileName));
    dispatch(mixpanelEvents.downloadTradeSecretPolicyTemplate());
  };

  useEffect(() => {
    if (tradeSecretPolicyAction.hasData) {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.TRADE_SECRET_POLICY));
      handleStepChange(IpAuditStepKey.SYSTEM_SECURITY);
    }
  }, [tradeSecretPolicyAction.hasData]);

  const btnEnabledOption1 = isUserHasPolicy && noPolicyChecked;
  const btnEnabledOption2 = noPolicyChecked && !isDeclaredNoFile;

  const isContinueBtnEnabled = (btnEnabledOption1 || btnEnabledOption2 || !!droppedFile) && !(!noPolicyChecked && !fileDetails);

  useEffect(() => {
    if (isContinueBtnEnabled) setHasStepChanges(true);
    else setHasStepChanges(false);

    return () => setHasStepChanges(false);
  }, [isContinueBtnEnabled]);

  const removeFile = () => {
    removeFileDetails();
    dispatch(mixpanelEvents.deleteTradeSecretPolicy());
  };
  const downloadPolicy = () => {
    handleDownload();
    dispatch(mixpanelEvents.downloadTradeSecretPolicy());
  };

  const isDisableCheckbox = !!fileDetails;

  return (
    <StepContainer>
      <StepInnerContainer>
        <StepTextContainer>
          <StepTitle stepKey={IpAuditStepKey.TRADE_SECRET_POLICY} />
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
            {t('IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.DESCRIPTION')}
          </TangiTypography>
        </StepTextContainer>

        {!fileDetails ? (
          <TangiDragAndDropFile
            onDrop={(acceptedFile: File[], fileRejections: FileRejection[]) => {
              setNoPolicyChecked(false);
              onDropFile(acceptedFile, fileRejections);
            }}
            acceptedFilesList={acceptedFilesList}
            height="240px"
          />
        ) : (
          <FileDisplay details={fileDetails} onRemove={removeFile} handleDownload={downloadPolicy} isDownloading={isDownloading} />
        )}

        <TemplateContainer>
          <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]}>
            {t('IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.DOWNLOAD_POLICY')}
            <DownloadText onClick={downloadTemplate}>{` ${t('POLICY.OPTIONS.DOWNLOAD')} `}</DownloadText>
            {t('IP_AUDIT.IP_AUDIT_STEPS.STANDARD_VERSION')}
          </TangiTypography>
          {isTemplateLoading && (
            <SpinnerContainer style={{ marginLeft: '10px', marginTop: '5px' }}>
              <Spinner as="div" size="sm" animation="border" variant="primary" />
            </SpinnerContainer>
          )}
        </TemplateContainer>

        {!isDisableCheckbox && (
          <CheckboxContainer
            onClick={() => {
              setNoPolicyChecked((noPolicyChecked) => !noPolicyChecked);
            }}
          >
            <TangiCheckbox type="checkbox" data-cy="checkbox-trade-secret-policy" checked={noPolicyChecked} name="trade-secret-policy" readOnly />
            <TangiTypography customStyles={alignTypographyWithCheckbox}>{t('IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.NO_TRADE_SECRET_POLICY')}</TangiTypography>
          </CheckboxContainer>
        )}

        {noPolicyChecked && <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.FALSE_COMPLETION')}</TangiNotification>}
        <TangiAlert show={dropFailData?.isFail} text={dropFailData?.failDescription} type={RESULT_STATUS.ERROR} />
        <div ref={scrollRef}></div>
      </StepInnerContainer>
      <StepFooter onSubmit={onSubmit} isContinueBtnLoading={tradeSecretPolicyAction.isLoading} isContinueBtnDisabled={!isContinueBtnEnabled} />
    </StepContainer>
  );
};
