import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import  CoursesProgressTooltip  from './CoursesProgressTooltip';
import { NEUTRAL_SHADES, SUCCESS_SHADES } from 'utils/theme';
import { Container, ProgressWrapper , StyledProgressBar} from './style';
import { CourseProgressionStatus, ICourseProgress } from '../../utils/types/courses/course';

const CoursesProgress = ({ coursesArray }: { coursesArray: ICourseProgress[] }) => {
  const [completedCoursesNumber, setCompletedCoursesNumber] = useState(0);

  useEffect(() => {
    const num = coursesArray.filter(course =>
      course.progression?.status === CourseProgressionStatus.COMPLETED
    ).length;
    setCompletedCoursesNumber(num);
  }, [coursesArray]);

  return (
    !!coursesArray?.length && (
      <Container>
        <Tippy placement="top" animation={false} maxWidth="350px" render={() => <CoursesProgressTooltip completedCoursesNumber={completedCoursesNumber} coursesArray={coursesArray} />} >
          <ProgressWrapper>
            {completedCoursesNumber === coursesArray.length ? (
              <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />
            ) : (
              <StyledProgressBar now={(completedCoursesNumber / coursesArray.length) * 100} max={100} />
            )}
            <TangiTypography color={NEUTRAL_SHADES[800]} type="sub-body">
              {completedCoursesNumber}/{coursesArray.length}
            </TangiTypography>
          </ProgressWrapper>
        </Tippy>
      </Container>
    )
  );
};

export default CoursesProgress;
