import { TangiTypography } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';
import { NormalizedData } from 'utils/types/activityLog/asset/types';

const DisclaimerWrapper = styled.div`
  padding: ${SPACING[2]};
`;

const AssetActivityDisclaimer = ({ activity }: { activity: NormalizedData }) => {
  const { t } = useTranslation();
  const message = activity.data[0].to;

  return (
    <DisclaimerWrapper>
      <TangiTypography type="subheading">{t(message)}</TangiTypography>
    </DisclaimerWrapper>
  );
};

export default AssetActivityDisclaimer;
