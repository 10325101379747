import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import OnboardingSettings from '../../_components/OnboardingSettings/OnboardingSettings';
import Header from '../../_components/Header/Header';
import { Dashboard } from '../Dashboard';
import { Loader } from '../../_components/Loader';
import { getClientById } from 'redux-toolkit/thunks/clientThunks';
import logo from '../../assets/img/CANOPY-logo.png';
import { OnBoardingContainer, OnBoardingStartContainer } from './style';

const OnBoarding = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { activeAccount } = useSelector((state) => state.authentication);
  const { clientDetails, clientLoading: loading } = useSelector((state) => state.client);

  useEffect(() => {
    if (!clientDetails.id || activeAccount?.role?.name.includes('Lawyer')) {
      dispatch(getClientById(clientId));
    }
  }, [clientId]);

  if (loading) {
    return <Loader isLoading />;
  }
  return !activeAccount?.role?.name.includes('Lawyer') && !clientDetails?.settings ? (
    <OnBoardingStartContainer>
      <Header title={<img className="m-auto" width="200" alt="TANGIBLY" src={logo} />} />
      <OnboardingSettings clientId={clientId} />
    </OnBoardingStartContainer>
  ) : (
    <Dashboard title="/ Settings">
      <OnBoardingContainer>
        <OnboardingSettings clientId={clientId} />
      </OnBoardingContainer>
    </Dashboard>
  );
};
export default OnBoarding;
