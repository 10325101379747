import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { TangiButton } from '../../../TangiLibrary';
import ThirdPartyTag from '../../../ThirdPartyTag/ThirdPartyTag';
import AssetCardLockedHeader from './AssetCardLockedHeader';
import { AssetCardFile } from '../AssetCardActive/AssetCardFile';
import { AssetDeleted } from './AssetDeleted';
import { Asset, SharePointStatusEnums } from 'utils/types/assets/assets';
import lock from '../../../../assets/icons/lock.svg';
import { DuoContainer } from '../../../../utils/globalStyles';
import { AssetNumber, AssetTagsContainer } from '../AssetCardActive/style';
import { AssetName } from '../../style';
import { StyledLockedAssetCard, Divider, LockedAssetBodyContainer, LockedAssetCardFooter } from './style';

interface Props {
  asset: Asset;
  handleUnlockAsset: (asset: Asset) => void;
}

const AssetCardLocked = ({ asset, handleUnlockAsset }: Props) => {
  const { t } = useTranslation();

  const isDeletedSharePoint = asset?.sharePoint && asset.sharePoint.status === SharePointStatusEnums.DELETED;

  const renderAssetTags = () => {
    if (!asset.tags?.length) return null;

    const firstTag = asset.tags[0]?.name;
    const additionalTagsCount = asset.tags.length - 1;

    return (
      <AssetTagsContainer>
        <span>{firstTag}</span>
        {additionalTagsCount > 0 && (
          <Tippy
            content={asset.tags.map((item, index) => (
              <div key={index}>{item.name}</div>
            ))}
            placement="top-end"
          >
            <span>+ {additionalTagsCount}</span>
          </Tippy>
        )}
        <AssetNumber>{asset.refNumber}</AssetNumber>
      </AssetTagsContainer>
    );
  };
  // Render the entire card content
  const renderAssetContent = () => (
    <StyledLockedAssetCard data-testid="locked-asset-card">
      <AssetCardLockedHeader asset={asset} />
      <Divider />
      <LockedAssetBodyContainer>
        <DuoContainer>
          <AssetName>
            <span>{asset.name}</span>
          </AssetName>
          {asset?.isThirdParty && <ThirdPartyTag asset={asset} />}
        </DuoContainer>
        {isDeletedSharePoint && renderAssetTags()}
      </LockedAssetBodyContainer>
      <AssetCardFile asset={asset} />
      <Divider />
      {asset?.needsToBeAcknowledgedByMe && (
        <LockedAssetCardFooter>
          <TangiButton data-testid="acknowledge-asset-button" variant="secondary" text={t('ASSET_PAGE.BUTTONS_TEXT.ACKNOWLEDGE')} icon={lock} onClick={() => handleUnlockAsset(asset)} />
        </LockedAssetCardFooter>
      )}
      {isDeletedSharePoint && <AssetDeleted />}
    </StyledLockedAssetCard>
  );

  return isDeletedSharePoint ? <NavLink to={{ pathname: `/client/${asset.client._id}/asset/${asset.id}` }}>{renderAssetContent()}</NavLink> : renderAssetContent();
};

export default AssetCardLocked;
