import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { ActivityRowDivider } from '../style';
import { TranslationMappingValues, MODIFICATION_TYPE, MODIFICATION_TYPE_TRANSLATED } from 'utils/types/activityLog/asset/types';

const Container = styled(FlexContainer)`
  padding: ${SPACING[0]} ${SPACING[7]} ${SPACING[0]} ${SPACING[3.5]};
  gap: ${SPACING[5]};
  width: 70%;
  padding-top: ${SPACING[3]};
  > :nth-child(2) {
    align-self: center;
  }
`;

const LeftSide = styled(FlexContainer)`
  max-width: 60%;
  flex: 1;
  flex-direction: column;
  gap: ${SPACING[0]};
`;

const RightSide = styled(LeftSide)`
  padding-top: 28px;
`;

const DetailsTitle = styled(TangiTypography)<{ hidden?: boolean }>`
  white-space: nowrap;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;
const CollapseContentDivider = styled(ActivityRowDivider)`
  margin-left: ${SPACING[1]};
`;

const breakWordTypographyStyles = `
  word-wrap: break-word;
`;

interface Props {
  field: TranslationMappingValues | string;
  prevValueComponent?: React.ReactNode;
  currValueComponent: React.ReactNode;
  modificationType: MODIFICATION_TYPE;
  showDivider?: boolean;
  showModificationType?: boolean;
}

export const ActivityCollapseContent = (props: Props) => {
  const { t } = useTranslation();
  const showModificationType = props.showModificationType ?? true;

  const modificationTypeTranslation = MODIFICATION_TYPE_TRANSLATED[props.modificationType];

  const isEditAction = [MODIFICATION_TYPE.EDIT, MODIFICATION_TYPE.MOVED].includes(props.modificationType);

  const renderChangeDetail = () => (
    <LeftSide>
      {
        <DetailsTitle type="subheading" weight="semibold" hidden={!showModificationType}>
          {t(props.field)} {t(modificationTypeTranslation)}
        </DetailsTitle>
      }
      <TangiTypography customStyles={breakWordTypographyStyles}>{props.currValueComponent}</TangiTypography>
    </LeftSide>
  );

  const renderChangeDetailBeforeAfter = () => (
    <>
      <LeftSide>
        <DetailsTitle type="subheading" weight="semibold" hidden={!showModificationType}>
          {t(props.field)} {t(modificationTypeTranslation)}
        </DetailsTitle>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ACTIVITY_LOG.PREVIOUS_VALUE')}</TangiTypography>
        <TangiTypography customStyles={breakWordTypographyStyles}>{props.prevValueComponent}</TangiTypography>
      </LeftSide>
      <TangiSvgIcon component="arrowSideRight" />
      <RightSide>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('ACTIVITY_LOG.UPDATED_VALUE')}</TangiTypography>
        <TangiTypography customStyles={breakWordTypographyStyles}>{props.currValueComponent}</TangiTypography>
      </RightSide>
    </>
  );
  return (
    <>
      <Container>{isEditAction && props.prevValueComponent ? renderChangeDetailBeforeAfter() : renderChangeDetail()}</Container>
      {!props.showDivider && <CollapseContentDivider />}
    </>
  );
};
