import { TangiAccountStatus, TangiTypography } from '_components/TangiLibrary';
import { Partnership } from 'utils/types/partnership/partnership';
import { Client } from 'utils/types/client/client';
import { NEUTRAL_SHADES } from 'utils/theme';
import { isObjectPartnership, isPartnershipDisabled } from '../utils';
import PartnershipOrClientLogo from './PartnershipOrClientLogo';
import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';

const Container = styled(FlexContainer)`
  align-items: center;
  .avatar {
    flex-shrink: 0;
  }
`;

interface Props {
  clientOrPartnership: Partnership | Client;
}

const ClientOrPartnershipNameAndLogo = ({ clientOrPartnership }: Props) => {
  return (
    <Container data-testid="partnerHeadline">
      <PartnershipOrClientLogo isPartnership={isObjectPartnership(clientOrPartnership)} logo={clientOrPartnership?.logo?.trim() || ''} name={clientOrPartnership?.name || ''} />
      <TangiTypography weight="semibold" color={NEUTRAL_SHADES.BLACK}>
        {clientOrPartnership?.name || ''}
      </TangiTypography>
      {isObjectPartnership(clientOrPartnership) && isPartnershipDisabled(clientOrPartnership) && <TangiAccountStatus status={clientOrPartnership.status} />}
    </Container>
  );
};

export default ClientOrPartnershipNameAndLogo;
