import { useTranslation } from 'react-i18next';

import { AssetFileContainer, AssetFileIconAndName, SharePointFileContainer, SharePointAssetFileIconAndName } from '../../style';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { isPartnershipDisabled } from '_components/ThirdPartyTag/utils';
import useAssetAttachmentsInfo from '_hooks/useAssetAttachmentsInfo';
import { AssetFileNum, FileNameText, NoFilesText } from 'pages/ClientAssets/styles';
import { SharePointStatusEnums } from 'utils/types/assets/assets';

/**
 * Renders a file asset card, either for a regular asset or a SharePoint asset.
 * The card displays the file icon, name, and handles clicking events.
 * The `isDisabled` prop is used to conditionally apply styles that indicate whether the file is accessible or not.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.asset - The asset object containing file information (name, type, etc.).
 * @param {Function} [props.handleFileClick] - Optional click handler function for the file. Defaults to an empty function.
 * @param {boolean} [props.isDisabled] - A flag indicating whether the file should be displayed in a disabled state.
 *                                        When true, the UI reflects that the file is inaccessible or not interactive.
 *                                        Typically used to show a different background color and remove interactions.
 *
 * @example
 * <AssetCardFile
 *   asset={asset}
 *   handleFileClick={() => handleFileClick(file)}
 *   isDisabled={true}
 * />
 *
 * @returns {JSX.Element} The rendered file card component, with specific UI for disabled or accessible states.
 */
export const AssetCardFile = ({ asset, handleFileClick = () => {}, isDisabled = false }) => {
  const { t } = useTranslation();
  const { attachmentName, attachmentIconType, attachmentAdditionalAmount, isAttachmentFile, isAttachmentEmpty } = useAssetAttachmentsInfo({
    filesArr: asset?.files,
    urlsArr: asset?.urls,
    partnership: asset?.createdBy?.partnership,
  });

  const isSharePointType = !!asset?.sharePoint;
  const sharePointStatus = asset?.sharePoint?.status;

  const isSharePointFileAccessible = () => sharePointStatus === SharePointStatusEnums.ACTIVE;

  const renderSharePointContent = () => {
    const noAccessText = sharePointStatus === SharePointStatusEnums.DELETED ? t('ASSET_PAGE.CONTENT.FILE_DELETED') :  t('ASSET_PAGE.CONTENT.NO_ACCESS_TO_FILE');

    return isSharePointFileAccessible() ? (
      <SharePointAssetFileIconAndName isDisabled={isDisabled}>
        <TangiSvgIcon component="link" />
        {t('ASSET_PAGE.CONTENT.LINK_TO_SHAREPOINT_FILE')}
      </SharePointAssetFileIconAndName>
    ) : (
      <AssetFileIconAndName isDisabled={false}>
        <NoFilesText>{noAccessText}</NoFilesText>
      </AssetFileIconAndName>
    );
  };

  const renderSharePointFile = () => <SharePointFileContainer onClick={isSharePointFileAccessible() ? () => handleFileClick(asset) : undefined}>{renderSharePointContent()}</SharePointFileContainer>;

  const renderAssetFile = () => (
    <AssetFileContainer onClick={() => handleFileClick(asset)}>
      <AssetFileIconAndName isDisabled={isPartnershipDisabled(asset?.createdBy?.partnership)}>
        {!isAttachmentEmpty && <TangiSvgIcon component={attachmentIconType} />}
        {isAttachmentEmpty ? <NoFilesText>{attachmentName}</NoFilesText> : <FileNameText className={isAttachmentFile ? 'file-attachment-name' : ''}>{attachmentName}</FileNameText>}
      </AssetFileIconAndName>
      {!!attachmentAdditionalAmount && <AssetFileNum>{`+ ${attachmentAdditionalAmount}`}</AssetFileNum>}
    </AssetFileContainer>
  );

  return isSharePointType ? renderSharePointFile() : renderAssetFile();
};
