import { useMemo } from 'react';
import { Spinner, Toast, ToastBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { IconTypes, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { RootState } from '_helpers';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { NEUTRAL_SHADES, SPACING, WARNING_SHADES } from 'utils/theme';

const CustomToast = styled(Toast)`
  padding: ${SPACING[2]};
  .toast-header {
    justify-content: space-between;
    padding: 0 0 ${SPACING[2]};
  }
  .toast-body {
    padding: ${SPACING[2]} 0;
  }

  .btn-close {
    margin-right: 0;
  }
`;

const ToastWrapper = styled(ToastBody)`
  display: flex;
  gap: ${SPACING[2]};
  flex-direction: column;
`;

const ToastItem = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  align-items: center;
`;

const CustomSpinner = styled(Spinner)`
  width: ${SPACING[4]};
  height: ${SPACING[4]};
  padding: 10px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const customFileNameStyle = `
text-overflow: ellipsis;
text-align: left;
`;

const ContinuationAnalysisFilesToast = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { excelExportStatus, wordExportStatus, showContinuationExportToast, patentId, fileName } = useSelector((state: RootState) => state.patent.continuationData);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);

  const isAccessToExcel = useMemo(() => Boolean(activeAccount?.specialAdminPermissions?.ContinuationExcel), [activeAccount?.specialAdminPermissions]);

  const patentIdentifier = patentId || fileName || '';
  const exportedFilesWithExcel: { name: string; status: XRAY_JOB_STATUS; icon: IconTypes }[] = [
    { name: t('PATENT_MAPPING_TOOL.TOAST.CONTINUATION_ANALYSIS_WORD', { patentIdentifier }), status: wordExportStatus, icon: 'fileWord' },
    { name: t('PATENT_MAPPING_TOOL.TOAST.CONTINUATION_ANALYSIS_EXCEL', { patentIdentifier }), status: excelExportStatus, icon: 'fileExcel' },
  ];

  const exportedFilesWithoutExcel: { name: string; status: XRAY_JOB_STATUS; icon: IconTypes }[] = [
    { name: t('PATENT_MAPPING_TOOL.TOAST.CONTINUATION_ANALYSIS_WORD', { patentIdentifier }), status: wordExportStatus, icon: 'fileWord' },
  ];

  const exportedFiles: { name: string; status: XRAY_JOB_STATUS; icon: IconTypes }[] = isAccessToExcel ? exportedFilesWithExcel : exportedFilesWithoutExcel;

  const renderIconComponent = (status: XRAY_JOB_STATUS) => {
    switch (status) {
      case XRAY_JOB_STATUS.STARTED:
        return <CustomSpinner data-testid="spinner" size="sm" animation="border" variant="primary" />;
      case XRAY_JOB_STATUS.FAILED:
        return (
          <Tippy
            content={
              <TooltipContent>
                <div>{t('PATENT_MAPPING_TOOL.TOAST.WORD_NOT_EXPORTED')}</div>
                <div>{t('PATENT_MAPPING_TOOL.TOAST.PLEASE_TRY_AGAIN')}</div>
              </TooltipContent>
            }
          >
            <span>
              <TangiSvgIcon component="warning" color={WARNING_SHADES[500]} size={SPACING[4]} />
            </span>
          </Tippy>
        );
      default:
        return <TangiSvgIcon component="matchIndication" color="white" size={SPACING[4]} />;
    }
  };
  const renderToastItems = () => {
    return exportedFiles.map((item, index) => (
      <ToastItem key={index}>
        {renderIconComponent(item.status)}
        <TangiSvgIcon component={item.icon} />
        <TangiTypography weight="semibold" customStyles={customFileNameStyle}>
          {item.name}
        </TangiTypography>
      </ToastItem>
    ));
  };

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: 'fixed',
        minWidth: '430px',
        bottom: 0,
        right: 0,
        margin: '1rem',
        zIndex: 50,
        backgroundColor: NEUTRAL_SHADES.WHITE,
      }}
    >
      <CustomToast data-testid="file-export-toast" show={showContinuationExportToast} onClose={() => dispatch(patentActions.setShowContinuationExportToast(false))} style={{ minWidth: '100%' }}>
        <CustomToast.Header closeButton>
          <TangiTypography weight="semibold">{t('PATENT_MAPPING_TOOL.TOAST.FILES_EXPORT')}</TangiTypography>
        </CustomToast.Header>
        <ToastWrapper>{renderToastItems()}</ToastWrapper>
      </CustomToast>
    </div>
  );
};

export default ContinuationAnalysisFilesToast;
