import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

import { SPACING, NEUTRAL_SHADES } from '../../utils/theme';
import { FlexContainer } from '../../utils/globalStyles';

export const StyledNavbar = styled(Navbar)`
  height: 68px;
`;

export const StyledNavSubmenu = styled(FlexContainer)`
  padding-right: ${SPACING[4]};
  gap: ${SPACING[3]};
  border-right: 1px solid ${NEUTRAL_SHADES[300]};
  align-items: center;
`;
