import styled from 'styled-components';

import { SPACING } from 'utils/theme';

export const TippyCenteredDiv = styled.div`
  width: 100px;
  text-align: center;
`;

export const FlexRow = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  .tangi-typography:first-of-type {
    padding-top: ${SPACING[1]};
  }
`;

export const AddClaimContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[4]};
  width: 100%;
`;
