/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import jsPDF from 'jspdf';

import { CreatePDFData, DROPDOWN_TRANSLATION_KEYS, DropdownOption, DropdownOptionCallback } from '_hocs/XRayAssetCreation/types';
import { Option } from 'utils/types/assetMetaData/assetMetaData';
import CreateAssetDropdownOption from './CreateAssetDropdownOption';

/**
 * Maximum number of characters allowed for a original file name and tag when create asset.
 */
export const MAX_CHARS_IN_NAME_AND_TAG = 10;

/**
 * Generates a PDF document based on the provided text, title, and potential asset name. The PDF is styled and formatted
 * with predefined settings such as font size, font family, and padding. It wraps long text into new lines and adds new
 * pages as necessary.
 *
 * @param {Object} CreatePDFData - The data needed to create the PDF document.
 * @param {string} CreatePDFData.text - The main text content of the PDF.
 * @param {string} CreatePDFData.title - The title of the PDF, which is displayed at the top.
 * @param {string} [CreatePDFData.potentialAssetName] - An optional name for the potential asset, which is used to generate the PDF file name.
 * @returns {File} - A `File` object representing the generated PDF document. The file name is derived from the `potentialAssetName` and `title`, sanitized to remove any non-valid characters for file names.
 *
 * @description
 * PDF file name is combined with the potential asset name (if provided), a title of the AI grouping, and a 'TS_' prefix.
 * Non-valid characters in the patent name are removed using a regular expression pattern.
 *
 */
export const createPDF = ({ text, title, potentialAssetName }: CreatePDFData): File => {
  const CHARS_PER_LINE = 180;
  const DOC_PADDING_LEFT = 10;
  const DOC_PADDING_TOP = 20;
  const TITLE_FONT_SIZE = 16;
  const TEXT_FONT_SIZE = 12;
  const FONT_FAMILY = 'helvetica';
  const TITLE_FONT_STYLE = 'bold';
  const TEXT_FONT_STYLE = 'normal';
  const PADDING_TOP_MULTILINE_TITLE = 40;
  const PADDING_TOP_SINGLE_LINE_TITLE = 30;
  const LINE_HEIGHT = 7;
  const NOT_VALID_FILE_NAME_PATTERN = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/g;

  const doc = new jsPDF();

  doc.setFont(FONT_FAMILY, TITLE_FONT_STYLE);
  doc.setFontSize(TITLE_FONT_SIZE);
  const titleLines = doc.splitTextToSize(title, CHARS_PER_LINE); // Wrap title text to the next line if it exceeds 180 units wide
  doc.text(titleLines, DOC_PADDING_LEFT, DOC_PADDING_TOP);

  doc.setFont(FONT_FAMILY, TEXT_FONT_STYLE);
  doc.setFontSize(TEXT_FONT_SIZE);

  const lines = doc.splitTextToSize(text, CHARS_PER_LINE); // Wrap text to the next line if it exceeds 180 units wide

  let cursorY = titleLines.length > 1 ? PADDING_TOP_MULTILINE_TITLE : PADDING_TOP_SINGLE_LINE_TITLE; // Initial cursor position
  lines.forEach((line: string) => {
    const remainingHeight = doc.internal.pageSize.height - cursorY; // Check remaining height on the current page
    if (line.length > remainingHeight) {
      doc.addPage();
      cursorY = DOC_PADDING_TOP; // Reset cursor position to the top of the new page
    }
    doc.text(line, DOC_PADDING_LEFT, cursorY);
    cursorY += LINE_HEIGHT; // Add line height to cursor position
  });

  const cleanTitle = title.replaceAll(NOT_VALID_FILE_NAME_PATTERN, '').trim();
  const cleanAssetName = potentialAssetName?.replaceAll(NOT_VALID_FILE_NAME_PATTERN, '').trim();

  const fileName = (potentialAssetName ? `${cleanAssetName}_TS_${cleanTitle}` : `TS_${cleanTitle}`) + '.pdf';

  const pdfFile = new File([doc.output('blob')], fileName, { type: 'application/pdf' });
  return pdfFile;
};

/**
 * Generates an array of dropdown option objects for creating asset-related actions.
 * Each dropdown option is composed of a React component that displays the option and a callback function to handle the option's action.
 *
 * @param {DropdownOptionCallback} editAndTurnIntoAssetCB - Callback function to be executed when the "Edit and Turn Into Asset" option is selected.
 * @param {DropdownOptionCallback} turnIntoAssetCB - Callback function to be executed when the "Turn Into Asset" option is selected.
 * @returns {DropdownOption[]} An array of dropdown option objects. Each object contains a `text` property with a React component for the option, and a `handleItem` property with the callback function for the option.
 *
 * @example
 * // Generate dropdown options
 * const dropdownOptions = generateDropdownOptions(handleEditAndTurnIntoAsset, handleTurnIntoAsset);
 *
 * // dropdownOptions will be an array of objects, each with a `text` component and a `handleItem` callback
 */
export const generateDropdownOptions = (editAndTurnIntoAssetCB: DropdownOptionCallback, turnIntoAssetCB: DropdownOptionCallback): DropdownOption[] => {
  return [
    {
      text: <CreateAssetDropdownOption icon="edit" dropdownText={DROPDOWN_TRANSLATION_KEYS.EDIT_AND_TURN_INTO_ASSET} />,
      handleItem: editAndTurnIntoAssetCB,
    },
    {
      text: <CreateAssetDropdownOption icon="add" dropdownText={DROPDOWN_TRANSLATION_KEYS.TURN_INTO_ASSET} dataTestId="turn-into-asset-inDropdown" />,
      handleItem: turnIntoAssetCB,
    },
  ];
};

/**
 * Searches through an array of departments to find a department with a matching ID. Constructs and returns
 * an Option object based on the found department.
 *
 * @param {any[]} departments - An array of department objects to search through.
 * @param {string} id - The ID of the department to find.
 * @param {string} [name='name'] - An optional key specifying the property to use for the label of the Option object (default is 'name').
 * @returns {Option} An Option object containing the value and label of the found department, or an Option with empty strings if not found.
 */
export const getOptionFromDepartmentId = (departments: any, id: string, name: string = 'name'): Option => {
  const item = departments.find((department: any) => department.id === id);
  return {
    value: item ? item.id : '',
    label: item ? item[name] : '',
  };
};

/**
 * Transforms an array of department objects and an array of department IDs into an array of Option objects.
 * Iterates through the given IDs, finds the corresponding department objects, and formats them as Option objects.
 *
 * @param {Object[]} departments - An array of department objects to use as the source of data.
 * @param {string[]} _ids - An array of department IDs to find within the department objects.
 * @returns {Option[]} An array of Option objects corresponding to the given department IDs.
 */
export const getDepartmentsOptionsFromIds = (departments: NonNullable<unknown>[], _ids: string[]): Option[] => {
  const options: Option[] = [];
  _ids.forEach((_id) => {
    options.push(getOptionFromDepartmentId(departments, _id));
  });
  return options;
};
