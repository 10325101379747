export const securityCardKeys = [
    'mcAfee',
    'paloAlto',
    'darkTrace',
    'cyberArk',
    'trend',
    'cisco',
    'crowdStrike',
    'knowBe4',
    'infosec',
    'sentinelOne',
    'salesForce',
    'oracle',
    'hubSpot',
    'pipeDrive',
    'sugarCrm',
    'zohoCrm',
    'qualtrics',
    'mondaySales',
    'clickUp',
    'airBase',
    'avalara',
    'bill',
    'brex',
    'expensify',
    'gusto',
    'netSuite',
    'paddle',
    'quickBooks',
    'stripe',
    'tipalti',
    'xero',
    'lynk',
    'mfiles',
    'shelf',
    'stravito',
    'swimm',
    'egain',
    'labguru',
    'labarchives',
    'scinote',
    'benchling',
    'labware',
    'perkinelmer',
    'thermofisher',
    'labfolder',
    'rippling',
    'bamboohr',
    'adp',
    'deel',
    'ukg',
    'workday',
    'freshworks',
    'namely',
    'brandwatch',
    'klue',
    'spyfu',
    'similarweb',
    'semrush',
    'contify',
    'aimresearch',
    'adthena',
    'vanta',
    'precoro',
    'gatekeeper',
    'onspring',
    'gepsmart',
    'ivalua',
    'beeline',
    'qualio',
    'ideagen',
    'quartqms',
    'intelex',
    'loyal',
    'arena',
    'propel',
    'safetychain',
    'hibob'
  ] as const;