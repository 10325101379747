import styled, { css } from 'styled-components';

import { BUTTON_VARIANTS, BUTTON_SIZES } from 'utils/theme';
import { THEME_COLORS, NEUTRAL_SHADES, SECONDARY_SHADES, SPACING } from '../../../utils/theme';

export const StyledIconButton = styled.button`
  ${({ size, variant, $isActive: isActive }) => css`
    height: ${size}px;
    width: ${size}px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    flex-direction: row;
    ${getVariantProps(variant, isActive)}
    ${getIconDimensions(size)};
  `}
`;

const getVariantProps = (variant, isActive) => {
  switch (variant) {
    case BUTTON_VARIANTS.PRIMARY:
      return css`
        background-color: ${THEME_COLORS.PRIMARY_DEFAULT};
        path,
        svg.tangi-icon-svg path {
          fill: ${NEUTRAL_SHADES.WHITE};
        }
        :disabled {
          background-color: ${THEME_COLORS.PRIMARY_DISABLED};
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.PRIMARY_DISABLED_TEXT};
          }
        }
        :hover:not(:disabled) {
          background-color: ${THEME_COLORS.PRIMARY_HOVER};
        }
        :focus {
          background-color: ${THEME_COLORS.PRIMARY_PRESSED};
        }
      `;
    case BUTTON_VARIANTS.SECONDARY:
      return css`
        background-color: transparent;
        border: 1px solid ${THEME_COLORS.SECONDARY_DEFAULT};
        path,
        svg.tangi-icon-svg path {
          fill: ${THEME_COLORS.SECONDARY_DEFAULT};
        }
        :disabled {
          border: 1px solid ${THEME_COLORS.SECONDARY_DISABLED};
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.SECONDARY_DISABLED};
          }
        }
        :hover:not(:disabled) {
          border: 1px solid ${THEME_COLORS.SECONDARY_HOVER};
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.SECONDARY_HOVER};
          }
        }
        :focus {
          border: 1px solid ${THEME_COLORS.SECONDARY_PRESSED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.SECONDARY_PRESSED};
          }
        }
      `;
    case BUTTON_VARIANTS.TERTIARY:
      return css`
        background-color: transparent;
        path,
        svg.tangi-icon-svg path {
          fill: ${THEME_COLORS.TERTIARY_TEXT_DEFAULT};
        }
        :disabled {
          background-color: transparent;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_DISABLED};
          }
        }
        :hover:not(:disabled) {
          background-color: ${THEME_COLORS.TERTIARY_BACKGROUND_HOVER};
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_HOVER};
          }
        }
        :focus {
          background-color: ${THEME_COLORS.TERTIARY_BACKGROUND_PRESSED};
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_PRESSED};
          }
        }
      `;
    case BUTTON_VARIANTS.TERTIARY_GREY:
      return css`
        background-color: ${isActive ? THEME_COLORS.TERTIARY_GREY_BACKGROUND_HOVER : 'transparent'};
        path,
        svg.tangi-icon-svg path {
          fill: ${NEUTRAL_SHADES[1100]};
        }
        :disabled {
          background-color: transparent;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_GREY_TEXT_DISABLED};
          }
        }
        :hover:not(:disabled) {
          background-color: ${THEME_COLORS.TERTIARY_GREY_BACKGROUND_HOVER};
        }
        :focus {
          background-color: ${THEME_COLORS.TERTIARY_BACKGROUND_PRESSED};
        }
      `;
    case BUTTON_VARIANTS.SECONDARY_PURPLE:
      return css`
        background-color: ${SECONDARY_SHADES[100]} !important;
        color: ${SECONDARY_SHADES[800]} !important;
        border-radius: 40px;
        padding: ${SPACING[2]} ${SPACING[3]};
        path,
        svg.tangi-icon-svg path {
          fill: ${SECONDARY_SHADES[800]} !important;
        }
        :disabled {
          background-color: transparent;
          color: ${THEME_COLORS.TERTIARY_TEXT_DISABLED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_DISABLED} !important;
          }
        }
        :hover:not(:disabled) {
          background-color: ${SECONDARY_SHADES[300]} !important;
          color: ${SECONDARY_SHADES[900]} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${SECONDARY_SHADES[900]} !important;
          }
        }
      `;
  }
};

const getIconDimensions = (size) => {
  switch (size) {
    case BUTTON_SIZES.XS:
      return css`
        svg.tangi-icon-svg {
          height: 14px;
          width: 14px;
        }
      `;
    case BUTTON_SIZES.SM:
      return css`
        svg.tangi-icon-svg {
          height: 18px;
          width: 18px;
        }
      `;
    //TODO: Add the rest of the sizes
  }
};
