import { apiCall } from './utils/restClient';
import { config } from './../config/config';
import { headers } from './utils/headers';
import { headersmultipart } from '../AdminRedux/_services/utils/headers';

function getlawfirm(account, page) {
  var url;
  if (account.lawFirm) {
    url = `${config.apiUrl}${config.apiVersion}/clients?lawyer=${account._id}&limit=${page}`;
  } else {
    url = `${config.apiUrl}${config.apiVersion}/clients?id=${account.client}&limit=${page}`;
  }

  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function createLawFirm(data) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/lawFirm`,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function uploadLawfirmLogo(data, lawFirmId) {
  var formData = new FormData();
  formData.append('file', data[0]);

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file/profile`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: {
      lawFirmId,
    },
  };

  return apiCall(requestOptions);
}

export const lawfirmService = {
  getlawfirm,
  createLawFirm,
  uploadLawfirmLogo,
};
