import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Container, Title, Body, Buttons, CheckBoxContainer, BlurContainer, StyledLine } from './style';
import { TangiButton, TangiNotification, NOTIFICATION_VARIANTS, TANGI_BUTTON_SIZES } from '../../TangiLibrary';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import './TangiPopup.scss';

export const TangiPopup = ({ show, title, body, onConfirm, onCancel, loading, alert, checkBox, checkBoxText, blur, ...props }) => {
  const [isChecked, setIsCheck] = useState(false);
  const { t } = useTranslation();

  if (show) {
    return (
      <>
        <Modal centered show={show} backdrop="static" dialogClassName="tangi-popup" data-testid="tangi-popup">
          <Container {...props}>
            <Title>{title}</Title>
            {alert && <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('PEOPLE.MODAL.MODAL_BODY.PLEASE_READ')}</TangiNotification>}
            <Body>{body}</Body>
            {checkBox && (
              <CheckBoxContainer>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => {
                    setIsCheck((prevState) => !prevState);
                  }}
                />
                <p>{checkBoxText ?? t('PEOPLE.MODAL.MODAL_BODY.I_AGREE')}</p>
              </CheckBoxContainer>
            )}
            <StyledLine />
            <Buttons>
              {onCancel && (
                <TangiButton
                  variant={BUTTON_VARIANTS.TERTIARY}
                  text={t('GENERAL.BUTTONS_TEXT.CANCEL')}
                  onClick={onCancel}
                  disabled={loading}
                  size={TANGI_BUTTON_SIZES.SMALL}
                  data-testid="tangi-popup-cancel"
                />
              )}
              <TangiButton
                text={t('GENERAL.BUTTONS_TEXT.CONFIRM')}
                onClick={onConfirm}
                loading={loading}
                disabled={!isChecked && checkBox}
                size={TANGI_BUTTON_SIZES.SMALL}
                data-testid="tangi-popup-confirm"
              />
            </Buttons>
          </Container>
        </Modal>
        {blur && <BlurContainer />}
      </>
    );
  }
  return null;
};
