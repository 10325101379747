import styled from 'styled-components';

import { WidgetDataObject } from '../Widgets.types';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { ProgressBar } from '../../Charts';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${SPACING[3]};
`;

const StyledProjectName = styled(TangiTypography)`
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: ${SPACING[2]};
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-bottom: 0px;
`;

const ProjectByAssetsItem = ({ label, value }: WidgetDataObject) => {
  return (
    <ItemContainer data-testid="project-by-assets-item">
      <StyledProjectName>{label}</StyledProjectName>
      <StyledProgressBar now={value} hideBackground={true} />
      <TangiTypography color={NEUTRAL_SHADES[700]}>{value}</TangiTypography>
    </ItemContainer>
  );
};

export default ProjectByAssetsItem;
