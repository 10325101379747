import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '_helpers';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XrayLoaders } from 'utils/types/xRay/xRay';
import { formatLongFileName } from 'utils/fileNameUtils';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';
import XrayStatusNotification from '_components/XrayStatusNotification/XrayStatusNotification';
import { LOADING_TRANSLATION_KEY } from '_components/XrayStatusNotification/types';
import { NOTIFICATION_VARIANTS, TangiButton, TangiNotification, TangiTypography } from '_components/TangiLibrary';

const RecentResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${SPACING[2]};
`;

const SeeResultsBtnContainer = styled.div`
  margin-top: -${SPACING[2]};
  margin-left: ${SPACING[3]};
`;

const MAX_FILENAME_LENGTH = 71;

interface Props {
  status: XRAY_JOB_STATUS;
  patentIdentifier: string;
  details: string;
  resultsNum: number;
  patentSource: XRAY_SOURCE_TYPE;
  onClick: () => void;
  onCancel: () => void;
}

const PatentStatusNotification = (props: Props) => {
  const { t } = useTranslation();

  const patentLoaders: XrayLoaders = useSelector((state: RootState) => state.patent.patentLoaders);

  const renderJobSucceededNotification = () => {
    return (
      <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO} startIcon={false}>
        <TangiTypography weight="semibold" type="heading-md" color={PRIMARY_SHADES[800]}>
          {t('DISCOVERY_LOBBY.PATENT_NOTIFICATION.RECENT_SEARCH')}
        </TangiTypography>
        <RecentResultsContainer>
          {props.patentSource === XRAY_SOURCE_TYPE.GOOGLE ? (
            <TangiTypography weight="semibold" type="subheading">
              {t('DISCOVERY_LOBBY.PATENT_NOTIFICATION.FOUND_RESULTS_FROM_PATENT', { RESULT_NUM: props.resultsNum, PATENT_ID: props.patentIdentifier })}
            </TangiTypography>
          ) : (
            <TangiTypography weight="semibold" type="subheading">
              {t('DISCOVERY_LOBBY.PATENT_NOTIFICATION.FOUND_RESULTS_FROM_FILE', {
                RESULT_NUM: props.resultsNum,
                FILE_NAME: formatLongFileName(props.patentIdentifier, MAX_FILENAME_LENGTH),
              })}
            </TangiTypography>
          )}
          {props.resultsNum && (
            <SeeResultsBtnContainer>
              <TangiButton
                variant="secondary"
                text={t('DISCOVERY_LOBBY.PATENT_NOTIFICATION_BUTON.SEE_RESULTS')}
                onClick={props.onClick}
                endIcon={true}
                svgIcon="arrowRight"
                data-testid="patent-recent-results"
              />
            </SeeResultsBtnContainer>
          )}
        </RecentResultsContainer>
      </TangiNotification>
    );
  };

  return (
    <XrayStatusNotification
      status={props.status}
      loadingTranslationKey={LOADING_TRANSLATION_KEY.PATENT}
      isCancelDisabled={patentLoaders.getResultLoader || patentLoaders.startLoader}
      errorDetails={props.details}
      onCancel={props.onCancel}
      statusSucceededComponent={renderJobSucceededNotification()}
      patentIdentifier={props.patentIdentifier}
      patentSource={props.patentSource}
    />
  );
};

export default PatentStatusNotification;
