/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from '_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isFunction } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import Tippy from '@tippyjs/react';
import { Trans, useTranslation } from 'react-i18next';

import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { createMultipleUsers } from 'redux-toolkit/thunks/usersThunks';
import { TangiButton, TangiCheckbox, TangiInput, TangiSvgIcon, TangiToast } from '_components/TangiLibrary';
import { validEmailPattern } from 'utils/formUtils';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { formToApiData } from '_components/NewUsersModal/utils';
import { ACCOUNT_STATUS, CREATED_FROM } from '../../utils/enums';
import { IRoles } from 'utils/roles';
import infoIcon from '../../assets/icons/help-black-bg.svg';
import { StyledModal } from 'utils/globalStyles';
import {
  ActionButtonsContainer,
  ButtonContainer,
  Divider,
  DuoContainer,
  Separator,
  StyledAddUserComponent,
  StyledAddUsersInPartnershipForm,
  StyledHeader,
  StyledModalBody,
  StyledModalHeader,
  CheckboxContainer,
} from './style';

interface PartnershipInviteUsersModalProps {
  onFinish: () => void;
}
const PartnershipInviteUsersModal: React.FC<PartnershipInviteUsersModalProps> = ({ onFinish }: PartnershipInviteUsersModalProps) => {
  //Params
  const { clientId } = useParams<{ clientId: string }>();
  //Redux
  const { usersModalIsOpen, partnership } = useSelector((state: RootState) => state.partnership);
  const { user } = useSelector((state: RootState) => state.authentication);
  const { invitedMultipleAccounts, inviteMultipleError } = useSelector((state: RootState) => state.users);
  const [inviteAccountsToastProps, setInviteAccountsToastProps] = useState({ show: false, text: '', type: '' });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Form Props
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    defaultValues: { data: [{ email: '', name: '', externalUser: user.externalUser, role: { value: IRoles.PARTNER }, status: ACCOUNT_STATUS.DISABLED }] },
  });

  const { fields, append } = useFieldArray({
    control,
    name: 'data',
  });

  const inviteAccountsAdditionalFields = useMemo(() => {
    return { createdFrom: CREATED_FROM.PARTNERSHIP, partnership: partnership._id };
  }, [user, partnership]);

  //Functions
  const handleAddUser = () => {
    const newUser = { email: '', name: '', externalUser: user.externalUser, role: { value: IRoles.PARTNER }, status: ACCOUNT_STATUS.DISABLED };
    append(newUser);
  };

  const onSubmitForm = async (data: any) => {
    const transformedData = formToApiData(data, inviteAccountsAdditionalFields);
    const reqBody = { data: transformedData, clientId };
    dispatch(createMultipleUsers(reqBody));
    dispatch(partnershipActions.setInviteUsersModalState(false));
    reset();
  };

  useEffect(() => {
    if (invitedMultipleAccounts && invitedMultipleAccounts.length) {
      setInviteAccountsToastProps({ show: true, text: `${invitedMultipleAccounts.length} ${t('PARTNERSHIP_ADD_EDIT_PARTNER.TOAST.USER_ADDED_SUCCESSFULLY')}`, type: 'success' });
      if (isFunction(onFinish)) {
        onFinish();
      }
    }
  }, [invitedMultipleAccounts, usersModalIsOpen]);

  useEffect(() => {
    if (inviteMultipleError) {
      setInviteAccountsToastProps({ show: true, text: inviteMultipleError, type: 'error' });
    }
  }, [inviteMultipleError]);
  //components
  const renderAddModalHeader = () => {
    return (
      <StyledHeader data-testid="partnership-invite-users-header">
        <TangiSvgIcon size="32px" component="addUser" />
        <StyledModalHeader>
          <Trans t={t} i18nKey="PARTNERSHIP_ADD_EDIT_PARTNER.TITLE.ADD_USERS_TO" values={{ PARTNER_NAME: partnership.name }} />
        </StyledModalHeader>
      </StyledHeader>
    );
  };

  const renderAddUserComponent = () => {
    return (
      <>
        {fields &&
          fields.map((item: any, index: number) => {
            return (
              <StyledAddUserComponent key={index} index={index}>
                <CheckboxContainer>
                  <TangiCheckbox
                    label={t('PARTNERSHIP_ADD_EDIT_PARTNER.CHECKBOX.ACTIVE')}
                    register={register}
                    name={`data.${index}.isActive`}
                    disabled={false}
                    onClick={() => {
                      setValue(`data[${index}].status`, ACCOUNT_STATUS.PENDING);
                    }}
                  />
                  <Tippy
                    content={
                      <div>
                        <div>{t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.ACTIVE')}</div>
                        <br />
                        <div>{t('PARTNERSHIP_ADD_EDIT_PARTNER.PARTNER_TOOLTIP.NONACTIVE')}</div>
                      </div>
                    }
                  >
                    <img src={infoIcon} alt="" className="info-toggle-icon" />
                  </Tippy>
                </CheckboxContainer>
                <DuoContainer>
                  <TangiInput
                    type="email"
                    placeholder="email@example.com"
                    label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.EMAIL')}
                    name={`data.${index}.email`}
                    required
                    register={register}
                    registerRules={{
                      pattern: {
                        value: validEmailPattern,
                        message: t('PARTNERSHIP_ADD_EDIT_PARTNER.ERROR_MESSAGE.INVALID_EMAIL_MESSAGE'),
                      },
                    }}
                    smallText={''}
                    error={errors?.email}
                    disabled={false}
                  />
                  <TangiInput
                    label={t('PARTNERSHIP_ADD_EDIT_PARTNER.INPUT_LABEL.DISPLAY_NAME')}
                    name={`data.${index}.name`}
                    required
                    register={register}
                    error={errors?.name}
                    placeholder={undefined}
                    smallText={undefined}
                    registerRules={undefined}
                  />
                </DuoContainer>
                <TangiCheckbox label={t('PARTNERSHIP_ADD_EDIT_PARTNER.CHECKBOX.OKTA_MESSAGE')} register={register} name={`data.${index}.externalUser`} disabled={false} />
                <Divider />
              </StyledAddUserComponent>
            );
          })}
      </>
    );
  };

  const renderAddUserButton = () => {
    return (
      <ButtonContainer>
        <TangiButton onClick={handleAddUser} svgIcon="addUser" text={t('GENERAL.BUTTONS_TEXT.ADD_USER')} variant={BUTTON_VARIANTS.TERTIARY_GREY} data-testid="add-additional-user" />
      </ButtonContainer>
    );
  };
  const renderActionsButtons = () => {
    return (
      <ActionButtonsContainer>
        <TangiButton
          onClick={() => {
            dispatch(partnershipActions.setInviteUsersModalState(false));
            reset();
          }}
          text={t('GENERAL.BUTTONS_TEXT.LATER')}
          variant={BUTTON_VARIANTS.TERTIARY}
        />
        <TangiButton type="submit" onClick={undefined} text={t('GENERAL.BUTTONS_TEXT.ADD')} variant={BUTTON_VARIANTS.PRIMARY} data-testid="invite-multiple-partners" />
      </ActionButtonsContainer>
    );
  };

  return (
    <>
      <StyledModal transition="Fade" show={usersModalIsOpen} data-testid="add-users-partnership-modal" onHide={() => {}}>
        <StyledAddUsersInPartnershipForm onSubmit={handleSubmit(onSubmitForm)}>
          {renderAddModalHeader()}
          <StyledModalBody>
            {renderAddUserComponent()}
            {renderAddUserButton()}
          </StyledModalBody>
          <Separator />
          {renderActionsButtons()}
        </StyledAddUsersInPartnershipForm>
      </StyledModal>
      <TangiToast
        onError={undefined}
        {...inviteAccountsToastProps}
        onSuccess={() => {
          setInviteAccountsToastProps({ show: false, type: '', text: '' });
          dispatch(partnershipActions.setFinishCreationPartnershipModalState(true));
        }}
      />
    </>
  );
};

export default PartnershipInviteUsersModal;
