import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import i18next from 'i18next';

import { AvatarEmail } from '../Avatar/AvatarEmail';
import { TangiIconButton } from '../TangiLibrary';
import { Avatar } from '../Avatar';
import { AssetStatus } from '../AssetStatus';
import { formatDate } from '../../utils/dateUtils';
import { BUTTON_VARIANTS, AVATAR_SIZES } from '../../utils/componentUtils';
import emptyAvatar from '../../assets/icons/empty-avatar.svg';
import { FlexRow, AckStatusContainer } from './style';
import { TableHeaderText, TableBodyText } from '../../utils/globalStyles';

export const createTableColumn = ({ handleCancelClicked, hasDeletePermission, contributors }) => {
  return [
    {
      name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.RECIPIENTS')}</TableHeaderText>,
      selector: ({ recipient }) => recipient,
      id: 'recipient',
      minWidth: '250px',
      cell: (recipient) => {
        const initials = recipient?.fullName || recipient?.username || recipient?.email;
        const isExistingAccount = recipient.account;
        return (
          <>
            {isExistingAccount ? <Avatar size={AVATAR_SIZES.XS} name={initials} email={recipient.email} /> : <Avatar size={AVATAR_SIZES.XS} image={emptyAvatar} />}
            <TableHeaderText className="ms-2">{initials}</TableHeaderText>
          </>
        );
      },
    },
    {
      name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACK_DATE')}</TableHeaderText>,
      selector: ({ acknowledgeDate }) => acknowledgeDate,
      id: 'acknowledgeDate',
      cell: (recipient) => (recipient.acknowledgedOn ? <TableBodyText>{formatDate(recipient.acknowledgedOn)}</TableBodyText> : ''),
    },
    {
      name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACK_STATUS')}</TableHeaderText>,
      selector: ({ status }) => status,
      right: true,
      cell: (recipient) => (
        <AckStatusContainer>
          <AssetStatus status={recipient.acknowledged ? 'success' : 'pending'} />
          <TableBodyText className="ms-2">{recipient.acknowledged ? i18next.t('ASSET_PAGE.RECIPIENT_TABLE.COMPLIANT') : i18next.t('ASSET_PAGE.RECIPIENT_TABLE.PENDING')}</TableBodyText>
        </AckStatusContainer>
      ),
    },
    {
      name: <TableHeaderText>{i18next.t('ASSET_PAGE.RECIPIENT_TABLE.ACTIONS')}</TableHeaderText>,
      selector: ({ actions }) => actions,
      right: true,
      omit: !hasDeletePermission,
      maxWidth: '120px',
      cell: (recipient) => {
        const isContributor = contributors?.find((contributor) => contributor?._id === recipient?.account?._id);
        const element = recipient.acknowledged ? (
          <span />
        ) : isContributor ? (
          <Tippy
            content={
              <div>
                <div>{i18next.t('ASSET_PAGE.TOOLTIP.CONTRIBUTORS_CAN_BE_DELETED')}</div>
                <div>{i18next.t('ASSET_PAGE.TOOLTIP.ONLY_IN_EDIT_ASSET_POPUP')}</div>
              </div>
            }
            placement="top-end"
          >
            <span style={{ cursor: 'not-allowed' }}>
              <TangiIconButton icon="delete" variant={BUTTON_VARIANTS.TERTIARY_GREY} disabled={isContributor} />
            </span>
          </Tippy>
        ) : (
          <TangiIconButton icon="delete" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => handleCancelClicked(recipient._id)} />
        );
        return element;
      },
    },
  ];
};

export const formatOptionLabel = ({ value, label, displayName }) => {
  return (
    <FlexRow>
      {displayName && <AvatarEmail size={AVATAR_SIZES.XS} isLinkDisabled name={displayName} email={label} styles="me-2" data-testid="select-label-avatar" />}
      <div>
        {displayName && `${displayName} - `}
        {label}
      </div>
    </FlexRow>
  );
};

export const transformToApiData = (formData) => {
  const apiData = {};
  Object.entries(formData).forEach(([key, value]) => (apiData[key] = value.map((object) => ({ id: object.value, email: object.label }))));
  return apiData;
};

export const transfromDataToFormFormat = ({ data, recipients, authors }) => {
  const options = [];
  data.forEach((element) => {
    const isNewRecipient = !recipients.find((recipient) => recipient.email === element);
    if (isNewRecipient) {
      const existingAuthor = authors.find((author) => author.email === element);
      if (existingAuthor) {
        options.push({ value: existingAuthor.id, label: existingAuthor.email, displayName: existingAuthor.displayName });
      } else {
        options.push({ value: element, label: element });
      }
    }
  });
  return { recipients: options };
};
