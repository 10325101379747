import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  width: 900px;
  animation: fade-in 1.5s;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ImgContainer = styled.div`
  min-height: 500px;
`;

export const doneBtnStyle = {
  width: 'fit-content',
  alignSelf: 'end',
  marginButtom: SPACING[7],
};

export const imgStyle = {
  width: '100%',
  height: '100%',
};
