import { RefObject, createRef, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import DepartmentsFilter from './DepartmentsFilter';
import ClientDashboardPDF from './ClientDashboardPDF';
import { RootState } from '_helpers';
import { StyledButton } from '_components/TangiLibrary/TangiButton/style';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

const CustomButton = styled(StyledButton)`
  gap: ${SPACING[2]};
`;

const VerticalDivider = styled.div`
  background-color: ${NEUTRAL_SHADES[400]};
  width: 1.5px;
  height: 40px;
  margin: 0 8px;
`;

const ClientDashboardHeader = () => {
  const permissions = useSelector((state: RootState) => state.authentication.permissions);
  const { t } = useTranslation();

  const [isSpinnerRotating, setisSpinnerRotating] = useState(false);
  const [showPdfDownloadingModal, setShowPdfDownloadingModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pdfExportComponent: RefObject<any> = createRef();
  // TODO: fix any type
  const handleModalClose = () => setShowPdfDownloadingModal(false);

  const handleExportWithComponent = () => {
    setTimeout(() => {
      setShowPdfDownloadingModal(false);
      setisSpinnerRotating(false);
    }, 3000);
    pdfExportComponent.current?.save();
  };

  const renderExportDataButton = () => {
    return (
      <CustomButton
        data-testid="downloadDashboard"
        data-html2canvas-ignore="true"
        onClick={() => {
          setShowPdfDownloadingModal(true);
          setisSpinnerRotating(true);
          handleExportWithComponent();
        }}
        $smallbtn
      >
        <TangiTypography type="button-sm" color={NEUTRAL_SHADES.WHITE}>
          {t('DASHBOARD_PAGE.HEADER.EXPORT_DATA')}
        </TangiTypography>
        <TangiSvgIcon component="download" color={NEUTRAL_SHADES.WHITE} size="13.5px" />
      </CustomButton>
    );
  };

  const renderDownloadPDFModal = () => {
    return (
      <Modal show={showPdfDownloadingModal} onHide={handleModalClose}>
        <Modal.Body className="d-flex justify-content-between">
          <div>Your Report is being generated, please wait it may take few seconds..</div>
          <i onClick={handleModalClose} className="fas fa-times"></i>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <nav className="px-4 pt-3">
      {permissions && permissions.Dashboardclientdownloadpdf && (
        <div className=" bd-highlight">
          {isSpinnerRotating ? (
            <Spinner data-html2canvas-ignore="true" animation="border" variant="primary" />
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <TangiTypography type="heading-md" weight="semibold">
                {t('DASHBOARD_PAGE.HEADER.TITLE')}
              </TangiTypography>
              <div className="d-flex align-items-center">
                <DepartmentsFilter />
                <VerticalDivider />
                {renderExportDataButton()}
              </div>
            </div>
          )}
        </div>
      )}
      {renderDownloadPDFModal()}
      <ClientDashboardPDF ref={pdfExportComponent} />
    </nav>
  );
};

export default ClientDashboardHeader;
