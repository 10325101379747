import styled, { css } from 'styled-components';

import { typeDictionary, TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { DuoContainer } from 'utils/globalStyles';
import { IStyledTextAreaProps } from './types';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

const textAreaFontSize = typeDictionary[TYPOGRAPHY_TYPES.BODY];

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  width: 100%;
  background-color: ${NEUTRAL_SHADES.WHITE};
`;

export const StyledTextArea = styled.textarea<IStyledTextAreaProps>`
  ${({ isReadMode = false, isAssociated = false, isCurrentAssociation = false }) => {
    const baseBackgroundColor = isCurrentAssociation ? PRIMARY_SHADES[300] : isAssociated ? PRIMARY_SHADES[200] : 'transparent';
    const borderColor = !isReadMode ? NEUTRAL_SHADES[600] : 'transparent';

    return css`
      box-sizing: border-box;
      font-size: ${textAreaFontSize.size};
      resize: none;
      background-color: inherit; // Start with inherit to respect focus state
      border-radius: ${SPACING[1.5]};
      border: 1px solid ${NEUTRAL_SHADES.WHITE};
      padding: ${SPACING[1]} ${SPACING[1.5]};
      overflow: hidden;
      background-color: inherit;
      border-color: ${!isReadMode ? borderColor : 'transparent'};

      ::placeholder {
        color: ${NEUTRAL_SHADES[800]};
      }

      :not(:focus) {
        background-color: ${baseBackgroundColor};
      }
    `;
  }}
`;

// export const StyledBulletTypography = styled(TangiTypography)<{ isAssociated?: boolean; isCurrentAssociation?: boolean }>`
//   ${({ isAssociated = false, isCurrentAssociation = false }) => css`
//     background-color: ${isCurrentAssociation ? PRIMARY_SHADES[300] : isAssociated ? PRIMARY_SHADES[200] : 'transparent'};
//     border-radius: ${SPACING[1.5]};
//     padding: ${SPACING[1]} ${SPACING[1.5]};

//     :hover {
//       background-color: ${(!isAssociated || !isCurrentAssociation) && PRIMARY_SHADES[200]};
//     }
//   `}}
// `;

export const FormFooter = styled(DuoContainer)`
  justify-content: flex-end;
`;
