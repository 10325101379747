import { Client } from '../client/client';
import { Metadata } from '../types';

export interface GetLawyerClients {
  accountId: string;
  limit?: number;
}
export interface GetLawyerClient {
  accountId: string;
  limit?: number;
  clientId: string;
}

export interface CreateLawFirm {
  name: string;
  slug: string;
  description: string;
  logo?: string | FileList;
}

export interface UploadLawfirmLogo {
  data: FileList;
  lawFirmId: string;
}

export enum LAWFIRM_CREATE_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  BLANK = '',
}

export interface LawyerClients {
  data: Client[];
  metadata: Metadata[];
}

export interface LawFirm {
  _id?: string;
  logo?: string;
  logoName?: string;
  name: string;
  description?: string;
  slug: string;
  isActive?: boolean;
  createdFrom?: string;
  updatedFrom?: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}
