import { useTranslation } from 'react-i18next';

import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';
import { TANGI_BUTTON_SIZES, TangiButton, TangiTypography } from '_components/TangiLibrary';
import { BUTTON_VARIANTS } from 'utils/theme';
import { leaveModalContainerStyle, leaveModalBodyStyle, leaveModalFooterStyle, leaveModalHeaderStyle } from './style';

interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
}

const LeaveModal = ({ isOpenModal, onCloseModal, onConfirm }: Props) => {
  const { t } = useTranslation();

  const renderHeader = () => {
    return <TangiTypography type="heading-lg">{t('SHARE_POINT_WIZARD.LEAVE_WIZARD.TITLE')}</TangiTypography>;
  };

  const renderBody = () => {
    return <TangiTypography type="subheading">{t('SHARE_POINT_WIZARD.LEAVE_WIZARD.DESCRIPTION')}</TangiTypography>;
  };

  const renderFooter = () => {
    return (
      <>
        <TangiButton
          variant={BUTTON_VARIANTS.TERTIARY}
          text={t('GENERAL.BUTTONS_TEXT.CANCEL')}
          size={TANGI_BUTTON_SIZES.MEDIUM}
          onClick={onCloseModal}
          data-testid="leave-wizard-cancel-button"
          smallbtn
        />
        <TangiButton text={t('GENERAL.BUTTONS_TEXT.LEAVE')} size={TANGI_BUTTON_SIZES.MEDIUM} onClick={onConfirm} data-testid="leave-wizard-confirm-button" smallbtn />
      </>
    );
  };
  return (
    <TangiModal
      show={isOpenModal}
      header={renderHeader()}
      onHide={onCloseModal}
      body={renderBody()}
      slotsStyles={{ headerContainer: { style: leaveModalHeaderStyle }, bodyContainer: { style: leaveModalBodyStyle }, footerContainer: { style: leaveModalFooterStyle } }}
      containerStyle={leaveModalContainerStyle}
      footer={renderFooter()}
      dataTestId="leave-sharepoint"
    />
  );
};

export default LeaveModal;
