import { TangiTypography } from '_components/TangiLibrary';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

interface RowProps {
  label: string;
  value: string | number;
  icon?: React.ReactNode;
}

export const InfoRow = ({ label, value, icon }: RowProps) => (
  <Row data-testid="modal-subject">
    <TangiTypography color={NEUTRAL_SHADES[800]} weight="regular" type={TYPOGRAPHY_TYPES.SUBHEADING}>
      {label}
    </TangiTypography>
    {icon && icon}
    <TangiTypography weight="semibold" type={TYPOGRAPHY_TYPES.SUBHEADING}>
      {value}
    </TangiTypography>
  </Row>
);
