import React, { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as OTPAuth from 'otpauth';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/react';

import { authenticate as reduxAuth } from '../../redux-toolkit/thunks/authThunks';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { CognitoMFA } from '../CognitoMFA/CognitoMFA';
import { ResetPasswordModal } from '../ResetPasswordModal/ResetPasswordModal';
import { TangiButton, TangiInput, TangiAlert } from '../TangiLibrary';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';
import { AppRoutes } from 'routing/consts';
import { SoftLine, SoftLinesContainer, Container, FormContainer } from './styles';
import { useAppDispatch } from '_helpers';
import { getRedirectRoute } from 'utils/roles';

function LoginCognito() {
  const { authenticate, associateToken, logout } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [MFA, setMFA] = useState(false);
  const [parsedQRCodeValue, setParsedQRCodeValue] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm({
    mode: 'all',
  });

  const handleError = () => {
    setAlertProps({ show: true, type: 'error', text: t('LOGIN_PAGE.ERROR_OCCURRED') });
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setAlertProps({ show: false, text: '', type: '' });
    setLoading(true);
    try {
      const res = await authenticate(data.username, data.password);
      let secret;
      let totp;
      switch (res.code) {
        case 'SOFTWARE_TOKEN_MFA':
          setMFA(true);
          setLoading(false);
          break;
        case 'MFA_SETUP':
          secret = await associateToken();
          totp = new OTPAuth.TOTP({
            label: `Tangibly - ${data.username}`,
            secret,
          });
          setParsedQRCodeValue(totp.toString());
          setMFA(true);
          setLoading(false);
          break;
        case 'NEW_PASSWORD_REQUIRED':
          history.push(AppRoutes.FORCE_RESET_PASSWORD);
          setLoading(false);
          break;
        default:
          if (res.accessToken && res.idToken) {
            try {
              const authRes = await dispatch(reduxAuth({ idToken: res.idToken.jwtToken })).unwrap();

              if (!authRes.account) {
                Sentry.captureMessage("LoginCognito: Error in /authenticate. User's account doesn't exist", 'error');
                handleError();
                logout({ isMixpanelEvent: false });
                return;
              }
              const route = getRedirectRoute(authRes.account);
              setLoading(false);
              history.push(route);
            } catch (error) {
              Sentry.captureException(error);
              handleError();
              logout({ isMixpanelEvent: false });
            }
          } else {
            Sentry.captureMessage('LoginCognito: Error in authenticate cognito user: no access token or id token', 'error');
            handleError();
          }
          break;
      }
    } catch (error) {
      setAlertProps({ show: true, type: 'error', text: 'Incorrect username or password.' });
      setLoading(false);
    }
  };

  return (
    <RegistrationCard isLockGif={MFA}>
      <Container>
        {MFA ? (
          <CognitoMFA QRCodeValue={parsedQRCodeValue} />
        ) : (
          <>
            <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <FormContainer>
                <h2 className="text-dark font-weight-normal">{t('LOGIN_PAGE.LOGIN_FORM.LOGIN')}</h2>
                <TangiInput label={t('LOGIN_PAGE.LOGIN_FORM.EMAIL')} register={register} name="username" />
                <TangiInput label={t('LOGIN_PAGE.LOGIN_FORM.PASSWORD')} register={register} name="password" type="password" className="password-input" />
                <TangiButton text={t('LOGIN_PAGE.LOGIN_FORM.SIGN_IN')} type="submit" loading={loading} className="submit-button" />
                <SoftLinesContainer>
                  <SoftLine onClick={() => setShowPasswordModal(true)}>{t('LOGIN_PAGE.LOGIN_FORM.FORGOT_PASSWORD')}</SoftLine>
                </SoftLinesContainer>
                <TangiAlert {...alertProps} className="error-alert-login" />
              </FormContainer>
            </Form>
          </>
        )}
        <ResetPasswordModal show={showPasswordModal} handleClose={() => setShowPasswordModal(false)} />
      </Container>
    </RegistrationCard>
  );
}

export { LoginCognito };
