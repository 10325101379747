import { createAsyncThunk } from '@reduxjs/toolkit';

import { saveStateToLocalStorage } from 'utils/localStorage';
import contractAdapter from 'redux-toolkit/adapters/contractAdapter';
import { ContractApplicationUploadData, GetContractLastResult } from 'utils/types/contract/contract';
import { contractConstants } from '_constants';
import { contractActions } from 'redux-toolkit/slices/contractSlice';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';

export const getContractResults = createAsyncThunk('/contract/getContractResults', async ({ contractResultId, clientId }: GetContractLastResult) => {
  const res = await contractAdapter.getContractResults({ contractResultId, clientId });
  return res ?? null;
});

export const uploadContractApplication = createAsyncThunk('/contract/uploadContractApplication', async ({ files, clientId }: ContractApplicationUploadData, { dispatch }) => {
  dispatch(contractActions.setContractDataInitState());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = await contractAdapter.uploadContractApplication({ files, clientId });

  dispatch(mixpanelEvents.contractXray(files[0]?.type));
  // TODO: fix any type
  if (res) {
    dispatch(getContractResults({ contractResultId: res?.contractResultId, clientId }));
    saveStateToLocalStorage(contractConstants.CONTRACT_REQUEST_INFO, { ...res, clientId });
  }
  return res ? { ...res, clientId } : null;
});
