import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { emailPreview } from '../../assets';
import { breakWordStyles, Container, ImageContainer, StyledImage } from './style';

const SendAcknowledgementStep = () => {
  return (
    <Container>
      <TangiTypography type="subheading" customStyles={breakWordStyles}>
        <Trans>{i18next.t('SHARE_POINT_WIZARD.SEND_ACKNOWLEDGEMENT.DESCRIPTION')} </Trans>
      </TangiTypography>
      <ImageContainer>
        <StyledImage src={emailPreview} alt="email-preview" />
      </ImageContainer>
    </Container>
  );
};

export default SendAcknowledgementStep;
