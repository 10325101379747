import { formatLongFileName } from 'utils/fileNameUtils';
import { AssetMetaDataItem, FILTERS_ID, FilterDataItem, Item } from '../types';

/**
 * Normalizes an array of mixed data types based on a specific ID. This function is particularly useful
 * for handling fields like "importance" which is expected as an array of strings. When the provided ID
 * matches 'importance', each string is transformed into an object. For other IDs, the items are returned unchanged.
 *
 * @param {Array<FilterDataItem|string>} data - The array containing either strings or FilterDataItem objects.
 * @param {string} id - The specific identifier to determine how the normalization should be handled.
 *                    - When 'importance', strings are converted to objects with the string used for both `id` and `name`.
 * @returns {Array<FilterDataItem>} The array where each string under 'importance' is converted into a FilterDataItem,
 *                                  or returned as is for other IDs.
 */
export const normalizeData = (data: Item[], id: FILTERS_ID): FilterDataItem[] => {
  return data?.map((item): FilterDataItem => {
    if (id === FILTERS_ID.IMPORTANCE && typeof item === 'string') {
      return { id: item, name: item };
    } else if (id === FILTERS_ID.COMMERCIAL_PRODUCT || id === FILTERS_ID.BUSINESS_RELATED) {
      const assetMetaDataItem = item as AssetMetaDataItem;
      return { id: assetMetaDataItem._id, name: assetMetaDataItem.value };
    } else if (id === FILTERS_ID.RECIPIENTS) {
      const filterDataItem = item as FilterDataItem;
      return { id: filterDataItem.id, name: filterDataItem.name };
    }
    return item as FilterDataItem;
  });
};

const MAX_ITEM_LENGTH = 25;

export const formatNameBasedOnFilter = (item: FilterDataItem, id: FILTERS_ID) => {
  return id === FILTERS_ID.IMPORTANCE ? item.name : formatLongFileName(item.formattedName || item.name || item.displayName || item.id, MAX_ITEM_LENGTH);
};
