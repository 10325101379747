import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Star } from '_components/Star';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { CenterDiv, customStarStyles, RowDiv, statusCustomStyles, UserRow } from '../styles';

interface Props {
  count: number;
  type: 'existing' | 'new';
}

const SectionHeader = ({ count, type }: Props) => {
  const { t } = useTranslation();
  return (
    <UserRow>
      <TangiTypography type="subheading" weight="bold">
        {t(type === 'existing' ? 'SHARE_POINT_WIZARD.ADD_USERS.CURRENT_TANGIBLY_USERS' : 'SHARE_POINT_WIZARD.ADD_USERS.NEW_USERS', { COUNT: count })}
      </TangiTypography>
      <RowDiv>
        <TangiTypography>{t('SHARE_POINT_WIZARD.ADD_USERS.DISPLAY_NAME')}</TangiTypography>
        <Star customStyles={customStarStyles} />
      </RowDiv>
      <TangiTypography>{t('SHARE_POINT_WIZARD.ADD_USERS.ROLE')}</TangiTypography>
      <CenterDiv>
        <TangiTypography customStyles={statusCustomStyles}>{t('SHARE_POINT_WIZARD.ADD_USERS.STATUS')}</TangiTypography>
        <Tippy content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('SHARE_POINT_WIZARD.ADD_USERS.STATUS_TOOLTIP')}</TangiTypography>}>
          <span>
            <TangiSvgIcon component="info" color={NEUTRAL_SHADES[800]} size={SPACING[3]} />
          </span>
        </Tippy>
      </CenterDiv>
    </UserRow>
  );
};

export default SectionHeader;
