import { useTranslation } from 'react-i18next';

import XRayPageLayout from '_components/XRayPageLayout/XRayPageLayout';
import { useDispatch } from 'react-redux';
import { CircleBackground, StyledTangiSvgIcon } from './styled';
import { PRIMARY_SHADES } from 'utils/theme';
import InventionDisclosureContent from './components/InventionDisclosureContent/InventionDisclosureContent';
import { useEffect } from 'react';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { getInsights } from 'redux-toolkit/thunks/clientThunks';
import { useParams } from 'react-router-dom';

const InventionDisclosureMainPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  // const xRayInsights = useSelector((state: RootState) => state.client.xRayInsights);
  // const discoveryDataLoaders = useSelector((state: RootState) => state.client.discoveryDataLoaders);

  useEffect(() => {
    Promise.all([dispatch(getInsights({ clientId })), dispatch(patentActions.setAccounts([]))]);
  }, []);

  return (
    <XRayPageLayout
      iconComponent={
        <CircleBackground>
          <StyledTangiSvgIcon component="draftApplication" size="130px" color={PRIMARY_SHADES[800]} />
        </CircleBackground>
      }
      title={t('DISCOVERY_LOBBY.TITLE.INVENTION_DISCLOSURE')}
      subTitle={t('DISCOVERY_LOBBY.CARD_TEXT.PASTE_INVENTION_DISCLOSURE_HERE')}
      // insights={
      //   discoveryDataLoaders.insightsLoading ?
      //   <XRayInsightsSkeleton numInsights={1} /> :
      //   <>
      //     <InsightCard num={xRayInsights.assetFromInventionDisclosure} text={t('DISCOVERY_LOBBY.CARD_TEXT.ASSETS_FROM_INVENTION_DISCLOSURE')} iconType={IconType.DRAFT_APPLICATION} />
      //   </>
      // }
      showBetaChip
    >
      <InventionDisclosureContent />
    </XRayPageLayout>
  );
};

export default InventionDisclosureMainPage;
