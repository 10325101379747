import styled from 'styled-components';

import { SPACING } from 'utils/theme';

export const EmptyStateContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[4]} ${SPACING[2]};
`;
