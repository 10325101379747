import { Partnership } from './types/partnership/partnership';

/**
 * Updates or adds a partnership to the 'partnerships' array.
 *
 * If the partnership with the same '_id' already exists in the array, it will be updated.
 * If the partnership does not exist, it will be added to the array.
 *
 * @param {Partnership[]} partnerships - The array of partnership objects.
 * @param {Partnership} partnershipData - The partnership object to be updated or added, matched by its '_id'.
 * @returns {Partnership[]} A new array with the updated or added partnership. The original array is not mutated.
 */
export const updatePartnerships = (partnerships: Partnership[], partnershipData: Partnership): Partnership[] => {
  const partnershipExists = partnerships.some((partnership) => partnership._id === partnershipData._id);

  return partnershipExists ? partnerships.map((partnership) => (partnership._id === partnershipData._id ? partnershipData : partnership)) : [...partnerships, partnershipData];
};

/**
 * Removes a partnership from the 'partnerships' array by matching the '_id' field.
 * If the partnership does not exist, returns the original array.
 *
 * @param {Partnership[]} partnerships - The array of partnership objects.
 * @param {Partnership} partnershipData - The partnership object to be removed, matched by its '_id'.
 * @returns {Partnership[]} - A new array with the specified partnership removed. The original array is not mutated.
 */
export const removePartnershipById = (partnerships: Partnership[], partnershipData: Partnership): Partnership[] => {
  return partnerships.filter((partnership: Partnership) => partnership._id !== partnershipData._id);
};
