import styled from 'styled-components';

export const Container = styled.div`
  width: 400px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 10px;
  padding: 7px;
`;
export const ItemContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  font-family: 'DIN2014-Regular';
`;

export const AgreementName = styled.div`
  width: 78%;
  max-width: 260px;
`;
export const StatusIcon = styled.div`
  display: flex;
  margin-left: auto;
  width: 7%;
  align-items: center;
  justify-content: center;
`;
