import styled from 'styled-components';

import { NEUTRAL_SHADES, SECONDARY_SHADES, SPACING } from 'utils/theme';
import { TangiTypography } from '_components/TangiLibrary';
import { TangiIconButton } from '_components/TangiLibrary/TangiIconButton';

export const StyledTangiIconButton = styled(TangiIconButton)`
  position: absolute;
  right: ${SPACING[3]};
  background-color: transparent !important;
  transition: right 0.3s;
`;

export const StyledTangiTypography = styled(TangiTypography)`
  width: 80%;
`;

export const Container = styled.div`
  width: 260px;
  height: 68px;
  border-radius: 8px;
  background-color: ${SECONDARY_SHADES[100]};
  border: 1px solid ${SECONDARY_SHADES[300]};
  padding: ${SPACING[2.5]} ${SPACING[3]};
  display: flex;
  gap: ${SPACING[3]};
  align-items: center;
  position: relative;
  color: ${NEUTRAL_SHADES.BLACK};

  &:hover {
    background-color: ${SECONDARY_SHADES[200]};
    border: 1px solid ${SECONDARY_SHADES[500]};
    color: ${SECONDARY_SHADES[800]};

    ${StyledTangiIconButton} {
      right: ${SPACING[2.5]};
    }

    ${StyledTangiTypography} {
      color: ${SECONDARY_SHADES[800]};
    }
  }
`;
