import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

export const StyledTippy = styled(Tippy)`
  &.tippy-box[data-theme~='custom'] {
    background-color: transparent;
    border: 1px solid;
    box-shadow: transparent;
    color: transparent;
    border-radius: ${SPACING[1]};
  }

  &.tippy-box[data-theme~='custom'] .tippy-arrow {
    color: transparent;
  }
`;

