import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import { SECONDARY_SHADES, SPACING } from 'utils/theme';
import { Divider, FlexContainer } from 'utils/globalStyles';
import { TangiSvgIcon } from '_components/TangiLibrary';

export const TitleCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: ${SPACING[3.5]};
  margin-bottom: 0;
`;

export const IconAndText = styled(FlexContainer)`
  align-items: center;
`;

export const StyledTangiSvgIcon = styled(TangiSvgIcon)`
  margin-bottom: ${SPACING[0.5]};
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  padding: 0;
  max-width: 832px;
  height: 330px;
  gap: ${SPACING[4]};
  margin-bottom: 0;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
`;

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 368px;
  background-color: ${SECONDARY_SHADES[50]};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  width: 416px;
  justify-content: center;
  padding-right: ${SPACING[4]};
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const Point = styled.div`
  display: flex;
  gap: ${SPACING[2]};
`;
