import React from 'react';
import { Nav, Collapse } from 'react-bootstrap';
import './SuperAdminSideMenu.scss';

function SuperAdminSideMenu() {
  const urlString = window.location.href;
  const url = new URL(urlString);

  return (
    <>
      <Nav className="d-none d-sm-block d-md-block bg-white sidebar" activeKey="/">
        <div className="sidebar-sticky" />
        <Nav.Item>
          <Nav.Link href="/adminhome" aria-controls="example-collapse-text" className="text-dark py-3">
            <i className="fas fa-user text-secondary" />
            Client
          </Nav.Link>
          <Collapse in={url.pathname.includes('adminhome')}>
            <Nav.Item>
              <Nav.Link href="/adminhome/client&type=addnew" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Add New
              </Nav.Link>
            </Nav.Item>
          </Collapse>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/admin/lawfirm" aria-controls="example-collapse-text" className="text-dark py-3">
            <i className="fas fa-gavel text-secondary" />
            Law Firm
          </Nav.Link>
        </Nav.Item>
        {/* SUPERADMIN ASSETS */}
        {/* <Nav.Item>
          <Nav.Link href="/admin/assets" aria-controls="example-collapse-text" className="text-dark py-3">
            <i className="fas fa-user-shield text-secondary" />
            Assets
          </Nav.Link>
          <Collapse in={url.pathname.includes('/admin/assets')}>
            <Nav.Item>
              <Nav.Link href="/admin/assets&type=addnew" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Add New
              </Nav.Link>
              <Nav.Link href="/admin/assets&type=tags" className="text-dark py-3">
                <i className="fas fa-gavel text-secondary" />
                Tags
              </Nav.Link>
              <Nav.Link href="/admin/assets&type=type" className="text-dark py-3">
                <i className="fas fa-gavel text-secondary" />
                Type
              </Nav.Link>
              <Nav.Link href="/admin/assets&type=status" className="text-dark py-3">
                <i className="fas fa-gavel text-secondary" />
                Status
              </Nav.Link>
              <Nav.Link href="/admin/assets&type=bv" className="text-dark py-3">
                <i className="fas fa-gavel text-secondary" />
                Business value
              </Nav.Link>
            </Nav.Item>
          </Collapse>
        </Nav.Item> */}
        <Nav.Item>
          <Nav.Link href="/admin/agreement" aria-controls="example-collapse-text" className="text-dark py-3">
            <i className="fas fa-file-contract text-secondary" />
            Agreements
          </Nav.Link>
          <Collapse in={url.pathname.includes('/admin/agreement')}>
            <Nav.Item>
              <Nav.Link href="/admin/agreement&type=addnew" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Add New
              </Nav.Link>
              <Nav.Link href="/admin/agreement&type=type" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Type
              </Nav.Link>
            </Nav.Item>
          </Collapse>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/admin/course" aria-controls="example-collapse-text" className="text-dark py-3">
            <i className="fas fa-graduation-cap text-secondary" />
            Courses
          </Nav.Link>
          <Collapse in={url.pathname.includes('/admin/course')}>
            <Nav.Item>
              <Nav.Link href="/admin/course&type=addnew" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Add New
              </Nav.Link>
              <Nav.Link href="/admin/course&type=tags" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Tags
              </Nav.Link>
              <Nav.Link href="/admin/course&type=category" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Category
              </Nav.Link>
            </Nav.Item>
          </Collapse>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/admin/users" aria-controls="example-collapse-text" className="text-dark py-3">
            <i className="fas fa-user text-secondary" />
            Users
          </Nav.Link>
          <Collapse in={url.pathname.includes('/admin/users')}>
            <Nav.Item>
              <Nav.Link href="/admin/users&type=addnew" className="text-dark py-3">
                <i className="fas fa-user-plus text-secondary" />
                Add New
              </Nav.Link>
              {/* <Nav.Link href="/admin/users" className="text-dark py-3">
                <i className="fas fa-user-circle text-secondary" />
                Profile
              </Nav.Link> */}
            </Nav.Item>
          </Collapse>
        </Nav.Item>
      </Nav>
    </>
  );
}

export { SuperAdminSideMenu };
