import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { isFunction } from 'lodash';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Divider, StyledModal } from 'utils/globalStyles';
import { TangiButton, TangiDatePicker, TangiIconButton, TangiSelector, TangiSvgIcon, TangiToast, TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { BUTTON_VARIANTS, AVATAR_SIZES } from 'utils/componentUtils';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { createableRoles, createableRolesForManager, isRoleMatch, IRoles } from 'utils/roles';
import { createMultipleUsers } from 'redux-toolkit/thunks/usersThunks';
import { getInventorsAccounts } from 'redux-toolkit/thunks/patentThunks';
import { validEmailPattern } from 'utils/formUtils';
import { formToApiData, isValuesUnique, handleKeyPress } from '../utils';
import { usersActions } from 'redux-toolkit/slices/usersSlice';
import {
  AvatarContainer,
  ButtonsContainer,
  EmailContainer,
  LabelsContainer,
  NameContainer,
  StyledContributorsForm,
  StyledModalAddContributorsBody,
  StyledModalFooter,
  SuggestedContainer,
  WrongPersonText,
  UserContainer,
  UserDetailsContainer,
  TextAndIconContainer,
} from './style';
import { TangiInputContainerContributors, DuoContainer, StyledTangiInput, StyledModalHeader, ContentRowContributors, RequiredStar, TangiTypographyLabel } from '../style';

const AddContributors = ({ namesOfInventors, handleSkipAddAccounts, onClose, showAddContributorsModal, accounts, additionalFields = {}, onFinish, Role = '' }) => {
  const { invitedMultipleAccounts, inviteMultipleLoading, inviteMultipleError } = useSelector((state) => state.users);
  const [inviteAccountsToastProps, setInviteAccountsToastProps] = useState({ show: false, text: '', type: '' });

  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({});

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'data',
  });

  const onSubmit = (data) => {
    const hasDuplicates = !isValuesUnique(data.data.map((item) => item.email));
    if (hasDuplicates) {
      fields.forEach((_, index) => {
        setError(`data[${index}].email`, {
          type: 'manual',
          message: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ERROR_MESSAGE.EMAIL_SHOULD_BE_UNIQUE')}`,
        });
      });
      return;
    }
    if (data.data.length > 0) {
      const transformedData = formToApiData(data, additionalFields);
      const reqBody = { data: transformedData, clientId };
      dispatch(createMultipleUsers(reqBody));
    } else {
      dispatch(usersActions.setInvitedMultipleAccounts(accounts));
    }
  };

  const handleClose = () => {
    reset({
      data: namesOfInventors?.map((name) => ({ name, role: createableRoles[1] })),
    });
    if (accounts.length > 0 && fields?.length > 0) {
      const updatedContributors = [...invitedMultipleAccounts, ...accounts];
      dispatch(usersActions.setInvitedMultipleAccounts(updatedContributors));
    }
    clearErrors();
    onClose();
  };

  useEffect(() => {
    const uniqueNames = Array.from(new Set(namesOfInventors)); // Remove duplicates using Set

    reset({
      data: uniqueNames.map((name) => ({ name, role: createableRoles[1] })),
    });
  }, [namesOfInventors, reset]);

  useEffect(() => {
    if (inviteMultipleError) {
      setInviteAccountsToastProps({ show: true, text: inviteMultipleError, type: 'error' });
    }
  }, [inviteMultipleError]);

  useEffect(() => {
    if (invitedMultipleAccounts && invitedMultipleAccounts.length) {
      dispatch(getInventorsAccounts({ inventors: namesOfInventors, clientId }));
      setInviteAccountsToastProps({ show: true, text: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.TOAST.USERS_ADDED_SUCCESSFULLY', { NUM: invitedMultipleAccounts.length })}`, type: 'success' });
      handleClose();
      if (isFunction(onFinish)) {
        onFinish();
      }
    }
  }, [invitedMultipleAccounts]);

  const handleRemoveFromAccounts = (index) => {
    const updatedAccounts = [...accounts];
    updatedAccounts.splice(index, 1);
    dispatch(patentActions.setAccounts(updatedAccounts));
  };

  const handleRemoveAccount = (index) => {
    handleRemoveFromAccounts(index);
    if ((accounts.length === 1 && fields?.length === 1) || (accounts.length === 1 && fields?.length === 0)) {
      handleClose();
    }
  };

  return (
    <>
      <StyledModal transition="Fade" show={showAddContributorsModal} data-testid="add-accounts-modal" onHide={() => {}}>
        <StyledContributorsForm onSubmit={handleSubmit(onSubmit)}>
          <StyledModalHeader>
            <div>
              <TangiSvgIcon size="32px" component="addUser"></TangiSvgIcon>
              <div>{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ADD_INVENTORS_AS_CONTRIBUTORS')}</div>
            </div>
          </StyledModalHeader>

          <StyledModalAddContributorsBody>
            {accounts.length > 0 && (
              <>
                <TextAndIconContainer>
                  <TangiTypography weight="semibold" type="heading-md">
                    {t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.SUGGESTED_CONTRIBUTORS')}
                  </TangiTypography>
                  <Tippy content={t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.TOOLTIP.FOUND_EXISTING_USERS')} placement="right">
                    <span>
                      <TangiSvgIcon component="info" size="16px" />
                    </span>
                  </Tippy>
                </TextAndIconContainer>
                <SuggestedContainer>
                  {accounts.map((item, index) => (
                    <UserContainer key={index}>
                      <AvatarContainer>
                        <Avatar size={AVATAR_SIZES.XS} name={item.displayName}></Avatar>
                        <UserDetailsContainer>
                          <NameContainer>{item.displayName}</NameContainer>
                          <EmailContainer>{item.user?.email}</EmailContainer>
                        </UserDetailsContainer>
                      </AvatarContainer>
                      <ButtonsContainer>
                        <WrongPersonText
                          onClick={() => {
                            append({ name: item.displayName, role: createableRoles[1] });
                            handleRemoveFromAccounts(index);
                          }}
                          className="hover-text"
                        >
                          {t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.NOT_RIGHT_PERSON')}
                        </WrongPersonText>
                        <TangiIconButton icon="remove" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => handleRemoveAccount(index)} className="remove-item-button" />
                      </ButtonsContainer>
                    </UserContainer>
                  ))}
                </SuggestedContainer>
                {fields?.length > 0 && <Divider />}
              </>
            )}
            {fields?.length > 0 && (
              <>
                <TextAndIconContainer>
                  <TangiTypography weight="semibold" type="heading-md">
                    {t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.NEW_USERS')}
                  </TangiTypography>
                  <Tippy content={t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.TOOLTIP.ADD_INVENTORS_AS_USERS')} placement="right">
                    <span>
                      <TangiSvgIcon component="info" size="16px" />
                    </span>
                  </Tippy>
                </TextAndIconContainer>
                <SuggestedContainer style={{ paddingTop: '0px' }}>
                  {fields && !!fields.length && (
                    <LabelsContainer>
                      <div>
                        <Form.Label className="text-dark txt-size ff-din-regular">{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.EMAIL')}</Form.Label>
                        <RequiredStar>*</RequiredStar>
                      </div>

                      <div>
                        <Form.Label style={{ marginLeft: '18px' }} className="text-dark txt-size ff-din-regular">
                          {t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.ROLE')}
                        </Form.Label>
                      </div>

                      <div style={{ marginLeft: '150px' }}>
                        <Form.Label className="text-dark txt-size ff-din-regular">{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.HIRE_DATE')}</Form.Label>
                      </div>
                    </LabelsContainer>
                  )}
                  {fields &&
                    !!fields.length &&
                    fields.map((item, index) => (
                      <ContentRowContributors key={index} data-testid="invite-account-row">
                        <TangiTypographyLabel weight="semibold">{item.name}</TangiTypographyLabel>
                        <TangiInputContainerContributors>
                          <StyledTangiInput
                            register={register}
                            name={`data.${index}.email`}
                            error={errors.data?.[index]?.email}
                            registerRules={{
                              required: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ERROR_MESSAGE.EMAIL_IS_REQUIRED')}`,
                              pattern: {
                                value: validEmailPattern,
                                message: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ERROR_MESSAGE.INVALID_EMAIL')}`,
                              },
                            }}
                            onKeyPress={(e) => handleKeyPress(e)}
                          />
                        </TangiInputContainerContributors>
                        <DuoContainer>
                          <div className="tangi-selector-container">
                            <TangiSelector
                              label={t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.ROLE')}
                              required={false}
                              shouldRenderLabel={false}
                              name={`data.${index}.role`}
                              options={isRoleMatch(Role, IRoles.EMPLOYEE_MANAGER) ? createableRolesForManager() : createableRoles}
                              error={errors.data?.[index]?.role}
                              control={control}
                              menuScroll={true}
                            />
                          </div>
                          <div className="tangi-datepicker-container">
                            <TangiDatePicker name={`data.${index}.dateOfJoining`} error={errors.data?.[index]?.dateOfJoining} register={register} onKeyPress={(e) => handleKeyPress(e)} />
                          </div>
                        </DuoContainer>
                        <TangiIconButton
                          icon="remove"
                          variant={BUTTON_VARIANTS.TERTIARY_GREY}
                          onClick={() => {
                            remove(index);
                          }}
                          className="remove-item-button"
                        />
                      </ContentRowContributors>
                    ))}
                </SuggestedContainer>
              </>
            )}
          </StyledModalAddContributorsBody>

          <StyledModalFooter>
            <Divider />
            <span>
              <TangiButton
                variant="tertiary"
                text={t('GENERAL.BUTTONS_TEXT.SKIP')}
                size="small"
                onClick={() => {
                  reset({
                    data: namesOfInventors.map((name) => ({ name, role: createableRoles[1] })),
                  });
                  handleSkipAddAccounts();
                  clearErrors();
                }}
                disabled={inviteMultipleLoading}
                data-testid="cancel-invite-accounts"
              />
            </span>
            <span>
              <TangiButton
                text={t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.BUTTON.ADD_CONTRIBUTORS')}
                size="small"
                type="submit"
                loading={inviteMultipleLoading}
                data-testid="add-contributors-matching-patent"
              />
            </span>
          </StyledModalFooter>
        </StyledContributorsForm>
      </StyledModal>
      <TangiToast
        {...inviteAccountsToastProps}
        onSuccess={() => {
          setInviteAccountsToastProps({ show: false, type: '', text: '' });
        }}
      />
    </>
  );
};

export default AddContributors;
