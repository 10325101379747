import styled, { css } from 'styled-components';
import { ITangiSvgIconProps } from './TangiSvgIcon';

type StyledIconContainerProps = Pick<ITangiSvgIconProps, 'color' | 'size'>;

export const IconContainer = styled.div<StyledIconContainerProps>`
  ${({ color, size = undefined }) => css`
    svg.tangi-icon-svg {
      path {
        fill: ${color};
      }
      width: ${size && size};
      height: ${size && size};
    }
  `}
`;
