import styled from 'styled-components';

import TradeSecretsAnalysisCard from '../Card/TradeSecretsAnalysisCard';
import { ChartContainer } from '../Charts/styles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { HeaderContainer } from '../Card/style';
import { Divider, FlexContainer } from 'utils/globalStyles';

export const ScrolledTradeSecretsAnalysisCard = styled(TradeSecretsAnalysisCard)`
  ${ChartContainer} {
    overflow-x: hidden;
    overflow-y: hidden;
    margin: ${SPACING[0]};
  }

  ${HeaderContainer} {
    margin-bottom: -30px;
  }

  /* Fade effect on the left */
  &::before {
    content: '';
    position: absolute;
    top: 15%; /* Centers the fade vertically */
    left: ${SPACING[0]};
    width: ${SPACING[9]};
    height: 70%;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    z-index: 1;
    pointer-events: none; /* Allows clicks to pass through */
    display: ${({ hasLeftArrowButton }) => (hasLeftArrowButton ? 'block' : 'none')}; /* Show fade when left button is present */
  }

  /* Fade effect on the right */
  &::after {
    content: '';
    position: absolute;
    top: 15%; /* Centers the fade vertically */
    right: 0;
    width: ${SPACING[9]};
    height: 70%;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    z-index: 1;
    pointer-events: none; /* Allows clicks to pass through */
    display: ${({ hasRightArrowButton }) => (hasRightArrowButton ? 'block' : 'none')}; /* Show fade when right button is present */
  }
`;

export const LegendContainer = styled.div`
  position: absolute;
  display: flex;
  gap: ${SPACING[2]};
  left: ${SPACING[0]};
  bottom: ${SPACING[0]};
`;

export const LegendItem = styled.div`
  display: flex;
  gap: 3px; // The gap between the color box and the text like in Nivo
  align-items: center;
`;

export const ColorBox = styled.div`
  background: ${({ color }) => color};
  width: ${SPACING[2.5]};
  height: ${SPACING[2.5]};
`;

export const TooltipContainer = styled(FlexContainer)`
  background-color: ${NEUTRAL_SHADES.WHITE};
  width: 240px;
  border-radius: ${SPACING[1]};
  padding: ${SPACING[2]} ${SPACING[3]};
  flex-direction: column;
  gap: ${SPACING[2]};
  box-shadow: ${SPACING[0]} 3px ${SPACING[3]} ${SPACING[0]} #0000001f;
`;

export const DataContainer = styled.div`
  & > ${Divider}:last-child {
    display: none;
  }
`;

export const TypeColor = styled.div<{ color: string }>`
  width: ${SPACING[2.5]};
  height: ${SPACING[2.5]};
  border-radius: 3px;
  background-color: ${(props) => props.color};
`;

export const CustomDivider = styled(Divider)`
  margin-bottom: ${SPACING[1]};
`;

export const FlexBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TooltipTitleContainer = styled.div`
  display: flex;
  align-items: bottom;
  gap: ${SPACING[1]};
  word-break: break-all;
`;

export const TooltipDataContainer = styled(FlexBetweenContainer)`
  padding: 1px ${SPACING[0]};
  word-break: break-all;
`;

export const TooltipLabel = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  align-items: center;
  justify-content: center;
  max-width: 70%;
`;
