/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { BatchEmployeesParams, CreateMultipleUsersParams, GetUsersParams } from 'utils/types/users/users';
import { getFilterObj } from '_services/utils/getFilterObj';
import { headers, tokenHeaders } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { Account, AccountMinimalData } from 'utils/types/account/account';
import { ApiResponse } from 'utils/types/types';
import { RolesResponse } from 'utils/types/role/role';
import { UpdateFullNameParams } from 'utils/types/auth/auth';

class UsersAdapter {
  static accountEndpoint = `${config.apiUrl}${config.apiVersion}/account`;

  static userEndpoint = `${config.apiUrl}${config.apiVersion}/user`;

  async sendAlias(accountId: string) {
    const res = await HttpClientService.get(`${UsersAdapter.accountEndpoint}/aliasid/${accountId}`, { headers: headers() });
    return res.data;
  }

  async createMultipleUsers(data: CreateMultipleUsersParams) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/inviteMultiple`, data, { headers: headers() });
    return res.data;
  }

  async updateFullName(data: UpdateFullNameParams) {
    const res = await HttpClientService.put(
      `${UsersAdapter.userEndpoint}/fullname/${data.userId}`,
      { fullName: data.fullName },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.token}`,
        },
      },
    );
    return res.data;
  }

  async acceptInvitation(token: string): Promise<ApiResponse<Account | AccountMinimalData>> {
    const defaultHeaders = headers();

    const headersWithInvitationToken = {
      ...defaultHeaders,
      [tokenHeaders.InvitationTokenHeader]: token,
    };

    const res = await HttpClientService.get<Account | AccountMinimalData>(`${UsersAdapter.accountEndpoint}/acceptInvite`, {
      headers: headersWithInvitationToken,
    });

    return { data: res.data, status: res.status };
  }

  async deactivateAccount(accountId: string) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/deactivate/${accountId}`, {}, { headers: headers() });
    return res.data;
  }

  async reactivateAccount(accountId: string) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/reactivate/${accountId}`, {}, { headers: headers() });
    return res.data;
  }

  async deleteAccount(accountId: string) {
    const res = await HttpClientService.delete(`${UsersAdapter.accountEndpoint}/${accountId}`, { headers: headers() });
    return res.data;
  }

  async batchEmployees(employees: BatchEmployeesParams) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/batchEmployees`, employees, { headers: headers() });
    return res.data;
  }

  async validateEmployees(employees: BatchEmployeesParams) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/validateEmployees`, employees, { headers: headers() });
    return res.data;
  }

  async createUser(user: any) {
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/invite`, user, { headers: headers() });
    return res.data;
  }

  async updateUser(user: any, id: string) {
    delete user.agreements;
    delete user.profile;
    const res = await HttpClientService.put(`${UsersAdapter.accountEndpoint}/${id}`, user, { headers: headers() });
    return res.data;
  }

  async updateAccount(account: Partial<Account>, id: string): Promise<Account> {
    const res = await HttpClientService.put<Account>(`${UsersAdapter.accountEndpoint}/${id}`, account, { headers: headers() });
    return res.data;
  }

  async getUsers({ userRoleName, client, pagination, filter }: GetUsersParams) {
    const filterObj = getFilterObj(filter);
    const res = await HttpClientService.post(`${UsersAdapter.accountEndpoint}/users`, filterObj, {
      headers: headers(),
      params: {
        client: client,
        roleName: userRoleName,
        ...pagination,
      },
    });
    return res.data;
  }

  async getAuthors(clientId: string) {
    const res = await HttpClientService.get(`${UsersAdapter.accountEndpoint}/authors/${clientId}`, { headers: headers() });
    return res.data;
  }

  async getRoles() {
    const res = await HttpClientService.get<RolesResponse>(`${config.apiUrl}${config.apiVersion}/roles`, { headers: headers() });
    return res.data;
  }

  async getUniqueCountries() {
    const res = await HttpClientService.get<string[]>(`${config.apiUrl}${config.apiVersion}/account/countries`, { headers: headers() });
    return res.data;
  }
}

const usersAdapter = new UsersAdapter();
export default usersAdapter;
