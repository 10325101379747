import { ACCOUNT_STATUS } from '../../utils/enums';
import { TangiAccountStatus } from '../TangiLibrary';
import successIcon from '../../assets/img/check-circle-solid.png';
import pendingIcon from '../../assets/img/exclamation-circle-solid.png';
import { CompliantTrainingContainer, CompliantTrainingTypography } from './style';

export const statusOptions = [
  { id: ACCOUNT_STATUS.ACTIVE, name: ACCOUNT_STATUS.ACTIVE },
  { id: ACCOUNT_STATUS.PENDING, name: ACCOUNT_STATUS.PENDING },
  { id: ACCOUNT_STATUS.DISABLED, name: ACCOUNT_STATUS.DISABLED },
];

export const trainingProgressOptions = [
  { id: 'completedAll', name: 'Completed all' },
  { id: 'notCompletedAll', name: 'Not completed all' },
];

export const compliantProgressOptions = [
  { id: 'signedAll', name: 'Yes' },
  { id: 'notSignedAll', name: 'No' },
];

export const sharePointOptions = [
  { id: 'connected', name: 'Yes' },
  { id: 'notConnected', name: 'No' },
];

export const renderAccountStatus = (status) => {
  return <TangiAccountStatus status={status} />;
};

export const renderCompliantTraining = (name) => {
  return (
    <CompliantTrainingContainer>
      {<img className="m-auto" width="16" height="16" alt="..." src={name === 'Yes' || name === 'Completed all' ? successIcon : pendingIcon} />}
      <CompliantTrainingTypography data-testid="multiselect-menu-text" className="ff-din-regular">
        {name}
      </CompliantTrainingTypography>
    </CompliantTrainingContainer>
  );
};
