import { useTranslation } from 'react-i18next';

import { tangiblyMail } from '_constants/consts';
import { PRIMARY_SHADES } from 'utils/theme';
import { AppRoutes } from 'routing/consts';

interface Props {
  type: 'invite' | 'acknowledge' | 'preview';
}

const InvalidError = ({ type }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="justify-content-center row p-2 preview-asset">
      <div className="col-md-6 col-lg-6">
        <div className="bg-white shadow border-0 my-4 card">
          <div className="p-4 card-body">
            <div className="row">
              <div className="col-2 d-flex justify-content-center align-items-center">
                <i className="fa fa-times-circle-o fa-2x text-warning" aria-hidden="true" />
              </div>
              <div className="col">
                <h4>Invalid URL !</h4>
                <p className="card-text">
                  <small className="text-muted ">
                    The link must be either invalid or expired. <br />
                    {type === 'invite' ? (
                      <span>
                        If you know your email and password, please <a href={AppRoutes.LOGIN}>log in here</a>. <br />
                        {t('LOGIN_PAGE.TWO_FACTOR_AUTH.HAVING_TROUBLE')}
                        <a href={`mailto:${tangiblyMail}`} style={{ color: PRIMARY_SHADES[800] }}>
                          {tangiblyMail}
                        </a>
                      </span>
                    ) : (
                      <span>Please contact admin for further support.</span>
                    )}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { InvalidError };
