import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { BORDER_RADIUS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  padding: ${SPACING[2.5]};
  background-color: ${NEUTRAL_SHADES.WHITE};
  max-width: 1000px;
  height: fit-content;
  box-shadow: 0px 3px 16px 0px #0000001f;
  border-radius: ${BORDER_RADIUS[4]};
`;

export const StyledImg = styled.img`
  width: 100%;
  height: auto;
`;
