import { apiCall } from './utils/restClient';
import { config } from '../../config/config';
import { headers, headersmultipart } from './utils/headers';

function getAdminAgreement(page, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'createdAt:asc';
  var url = `${config.apiUrl}${config.apiVersion}/agreements`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };
  return apiCall(requestOptions);
}

function deleteAgreement(agreementId) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreement/${agreementId}`,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminAgreementType(page, firmid, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var url;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'name:asc';
  if (firmid) {
    url = `${config.apiUrl}${config.apiVersion}/agreementType/${firmid}`;
    const requestOptions = {
      url: url,
      method: 'GET',
      headers: headers(),
    };
    return apiCall(requestOptions);
  } else {
    url = `${config.apiUrl}${config.apiVersion}/agreementTypes`;
    const requestOptions = {
      url: url,
      method: 'GET',
      headers: headers(),
      params: { page, limit, sortBy, search },
    };
    return apiCall(requestOptions);
  }
}

function createAgreement(data, account) {
  const payload = { ...data };
  delete payload.file;
  payload.account = account;

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreement`,
    method: 'POST',
    headers: headers(),
    data: payload,
  };

  return apiCall(requestOptions);
}

function updateAgreement(agreement) {
  // const payload = { ...data, user };
  // delete payload.file;

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/agreement/${agreement?.id}`,
    method: 'PUT',
    headers: headers(),
    data: agreement,
  };

  return apiCall(requestOptions);
}

function uploadAgreementFiles(data, agreementId) {
  var formData = new FormData();
  formData.append('file', data[0]);

  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/file`,
    method: 'POST',
    headers: headersmultipart(),
    data: formData,
    params: {
      id: agreementId,
      type: 'agreements',
    },
  };

  return apiCall(requestOptions);
}

export const adminAgreementService = {
  getAdminAgreement,
  getAdminAgreementType,
  createAgreement,
  updateAgreement,
  uploadAgreementFiles,
  deleteAgreement,
};
