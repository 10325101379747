import { apiCall } from './utils/restClient';
import { config } from './../config/config';
import { headers } from './utils/headers';

function createClientSettings(clientId, settingsData) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/clientSettings/${clientId}`,
    method: 'POST',
    headers: headers(),
    data: settingsData,
  };
  return apiCall(requestOptions);
}
function getClientSettings(clientId) {
  const requestOptions = {
    url: `${config.apiUrl}${config.apiVersion}/clientSettings/${clientId}`,
    method: 'GET',
    headers: headers(),
  };
  return apiCall(requestOptions);
}
function updateClientSettings(clientId, settingsData) {
  var url = `${config.apiUrl}${config.apiVersion}/clientSettings/${clientId}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: settingsData,
  };

  return apiCall(requestOptions);
}
export const clientSettingsService = {
  createClientSettings,
  updateClientSettings,
  getClientSettings,
};
