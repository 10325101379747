import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FieldValues, Path } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { Placement } from 'tippy.js';
import Tippy from '@tippyjs/react';

import { TangiTypography } from '../TangiTypography';
import { IconTypes, TangiSvgIcon } from '../TangiSvgIcon';
import { Star } from '_components/Star';
import { ERROR_SHADES, NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { ErrorContainer, StyledInput, StyledInputButton, StyledInputGroup, StyledLabelContainer, customStarStyles } from './style';
import { Trans, useTranslation } from 'react-i18next';

interface Props<T extends FieldValues> {
  name: Path<T>;
  lableTranslationKey?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelIcon?: boolean;
  labelIconSize?: string;
  labelIconColor?: string;
  labelIconComponent?: IconTypes;
  labelTooltipContent?: React.ReactNode;
  labelTooltipPlacement?: Placement;
  renderLabelTooltipContent?: null | (() => React.ReactNode);
  savePassword?: boolean;
  dataTestId?: string;
}

export function TangiSecureInput<T extends FieldValues>({
  lableTranslationKey = '',
  name,
  placeholder = '',
  labelIcon = false,
  labelIconComponent = 'help',
  labelIconSize = SPACING[2.5],
  labelIconColor = NEUTRAL_SHADES[800],
  renderLabelTooltipContent = null,
  labelTooltipContent = null,
  labelTooltipPlacement = 'top',
  required = false,
  savePassword = true,
  disabled = false,
  dataTestId = 'tangi-secure-input',
}: Props<T>) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t } = useTranslation();
  const { control } = useFormContext<T>();

  const toggleInputVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const isError = !!error;
        return (
          <Form.Group controlId={name}>
            {lableTranslationKey && (
              <StyledLabelContainer data-testid={`${dataTestId}-label-group`}>
                <TangiTypography type="body" color={isError ? ERROR_SHADES[500] : NEUTRAL_SHADES.BLACK}>
                  <Trans t={t} i18nKey={lableTranslationKey} />
                </TangiTypography>
                {required && <Star customStyles={customStarStyles} />}
                {labelIcon && (
                  <Tippy
                    disabled={!renderLabelTooltipContent && !labelTooltipContent}
                    placement={labelTooltipPlacement}
                    content={labelTooltipContent}
                    render={renderLabelTooltipContent ? () => renderLabelTooltipContent() : undefined}
                    trigger="mouseenter"
                    animation={false}
                  >
                    <span>
                      <TangiSvgIcon component={labelIconComponent} size={labelIconSize} color={labelIconColor} />
                    </span>
                  </Tippy>
                )}
              </StyledLabelContainer>
            )}

            <StyledInputGroup $isError={isError} $isDisabled={disabled}>
              <StyledInput {...field} disabled={disabled} type={isPasswordVisible ? 'text' : 'password'} placeholder={placeholder} isInvalid={isError} autoComplete={savePassword ? 'on' : 'off'} />
              <StyledInputButton $isError={isError} $isDisabled={disabled} onClick={toggleInputVisibility} data-testid={`${name}-toggle-visibility`}>
                <TangiSvgIcon component={isPasswordVisible ? 'visibilityOn' : 'visibilityOff'} color={NEUTRAL_SHADES[800]} />
              </StyledInputButton>
            </StyledInputGroup>
            <ErrorContainer>
              {isError ? (
                <TangiTypography type="sub-body" dataTestId={`${dataTestId}-error-feedback`} color={isError ? ERROR_SHADES[500] : NEUTRAL_SHADES.BLACK}>
                  {error?.message}
                </TangiTypography>
              ) : (
                <span />
              )}
            </ErrorContainer>
          </Form.Group>
        );
      }}
    />
  );
}

export default TangiSecureInput;
