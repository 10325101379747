import { SystemKey } from './types';

export const systemKeys = ['crm', 'saas', 'mgmtEln', 'hrms', 'ci', 'vrm', 'qms'] as const;

export const keysTitleMap: Record<SystemKey, string> = {
  crm: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.CRM',
  saas: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.FINANCIAL_SAAS',
  mgmtEln: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.KNOWLEDGE_MGMT_ELN',
  hrms: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.HRMS',
  qms: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.QUALITY_MGMT_SYSYTEMS',
  ci: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.COMPETITIVE_INTELLIGENCE',
  vrm: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.VENDOR_RELATIONSHIP_MGMT',
};

export const keysTagMap = {
  crm: 'CRM',
  saas: 'Financial',
  mgmtEln: 'KM/ELN',
  hrms: 'HRMS',
  qms: 'QMS',
  ci: 'CI',
  vrm: 'VRM',
} as const;


export const assetsData = {
  crm: [
    { key: 'salesForce', name: 'Salesforce' },
    { key: 'oracle', name: 'Oracle' },
    { key: 'hubSpot', name: 'HubSpot' },
    { key: 'pipeDrive', name: 'Pipedrive' },
    { key: 'sugarCrm', name: 'SugarCRM' },
    { key: 'zohoCrm', name: 'Zoho' },
    { key: 'qualtrics', name: 'Qualtrics' },
    { key: 'mondaySales', name: 'Monday Sales' },
    { key: 'clickUp', name: 'ClickUp' },
  ],
  saas: [
    { key: 'airBase', name: 'Airbase' },
    { key: 'avalara', name: 'Avalara' },
    { key: 'bill', name: 'Bill' },
    { key: 'brex', name: 'Brex' },
    { key: 'expensify', name: 'Expensify' },
    { key: 'gusto', name: 'Gusto' },
    { key: 'netSuite', name: 'NetSuite' },
    { key: 'paddle', name: 'Paddle' },
    { key: 'quickBooks', name: 'QuickBooks' },
    { key: 'stripe', name: 'Stripe' },
    { key: 'tipalti', name: 'Tipalti' },
    { key: 'xero', name: 'Xero' },
  ],
  mgmtEln: [
    { key: 'lynk', name: 'Lynk' },
    { key: 'mfiles', name: 'M-Files' },
    { key: 'shelf', name: 'Shelf' },
    { key: 'stravito', name: 'Stravito' },
    { key: 'swimm', name: 'Swimm' },
    { key: 'egain', name: 'eGain' },
    { key: 'labguru', name: 'Labguru' },
    { key: 'labarchives', name: 'LabArchives' },
    { key: 'scinote', name: 'SciNote' },
    { key: 'benchling', name: 'Benchling' },
    { key: 'labware', name: 'LabWare' },
    { key: 'perkinelmer', name: 'PerkinElmer' },
    { key: 'thermofisher', name: 'Thermo Fisher' },
    { key: 'labfolder', name: 'Labfolder' },
  ],
  hrms: [
    { key: 'rippling', name: 'Rippling' },
    { key: 'bamboohr', name: 'BambooHR' },
    { key: 'adp', name: 'ADP' },
    { key: 'deel', name: 'Deel' },
    { key: 'ukg', name: 'UKG' },
    { key: 'workday', name: 'Workday' },
    { key: 'freshworks', name: 'Freshworks' },
    { key: 'namely', name: 'Namely' },
    { key: 'hibob', name: 'HiBob' },
  ],
  ci: [
    { key: 'brandwatch', name: 'Brandwatch' },
    { key: 'klue', name: 'Klue' },
    { key: 'spyfu', name: 'SpyFu' },
    { key: 'similarweb', name: 'Similarweb' },
    { key: 'semrush', name: 'Semrush' },
    { key: 'contify', name: 'Contify' },
    { key: 'aimresearch', name: 'AIM Research' },
    { key: 'adthena', name: 'Adthena' },
  ],
  vrm: [
    { key: 'vanta', name: 'Vanta' },
    { key: 'precoro', name: 'Precoro' },
    { key: 'gatekeeper', name: 'Gatekeeper' },
    { key: 'onspring', name: 'Onspring' },
    { key: 'gepsmart', name: 'GEP SMART' },
    { key: 'ivalua', name: 'Ivalua' },
    { key: 'beeline', name: 'Beeline' },
  ],
  qms: [
    { key: 'qualio', name: 'Qualio' },
    { key: 'ideagen', name: 'Ideagen' },
    { key: 'quartqms', name: 'QuartzQMS' },
    { key: 'intelex', name: 'Intelex' },
    { key: 'loyal', name: 'Loyal QMS' },
    { key: 'arena', name: 'Arena QMS' },
    { key: 'propel', name: 'Propel' },
    { key: 'safetychain', name: 'SafetyChain' },
  ],
};

export const noAssetSelection = 'noneOfTheAbove';
