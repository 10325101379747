import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const ImportanceContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px 16px;
  justify-content: space-between;
  align-self: stretch;
  border: 1px solid ${NEUTRAL_SHADES[400]};
  margin-top: ${SPACING[4]};
`;

export const LanguageContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid ${NEUTRAL_SHADES[400]};
`;

export const LanguageText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const AssetImportanceText = styled(LanguageText)`
  margin-bottom: ${SPACING[3]};
`;

export const InfoContainer = styled(FlexContainer)`
  gap: 4px;
`;
export const TitleContainer = styled(FlexContainer)`
  padding: 16 16 16 16;
  justify-content: space-between;
  width: 100%;
  padding-right: ${SPACING[3]};
`;

export const ConfirmButton = styled.div`
  margin-left: auto;
`;

export const SubTitle = styled.label`
  margin-top: ${SPACING[2]};
`;

export const subTitleCustomStyle = `
  margin-top: ${SPACING[5]};
`;
export const titleCustomStyle = `
  line-height: 22px;
`;

export const SettingsTable = styled.table`
  font-style: normal;
  font-size: ${SPACING[3]};
  border: 1px solid ${NEUTRAL_SHADES[400]};
`;
export const SettingsContainer = styled.div`
  margin: auto 15%;
  padding-top: ${({ isFirst }) => (isFirst ? '100px' : '0px')};
  margin-bottom: ${SPACING[2]};
`;

export const SettingsRow = styled.tr`
  height: 80px;
  padding-top: 10px;
`;

export const SettingsRowTitle = styled(SettingsRow)`
  border-bottom-color: ${NEUTRAL_SHADES[400]} !important;
`;

export const CenteredColumn = styled.td`
  width: 20%;

  .form-check-input {
    cursor: pointer;
  }
`;

export const customTippy = `
  white-space: pre-line;
`;
