import BannerSnackbar from '_components/BannerSnackbar/BannerSnackbar';
import { TangiModal, TangiTypography } from '_components/TangiLibrary';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { RootState } from '_helpers';
import useSharePointAccess from '_hooks/useSharePointAccess';
import SharePointMembersModal from 'pages/Lobby/components/SharePointMembersModal/SharePointMembersModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dismissNewAddedUsers, getNewAddedUsers } from 'redux-toolkit/thunks/sharePointThunks';
import { SPACING } from 'utils/theme';
import { SharePointAddedUser } from 'utils/types/sharepoint/sharepoint';

const NewSharepointUsers = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSharePointMembersModal, setIsSharePointMembersModal] = useState(false);

  const { clientId } = useParams<{ clientId: string }>();
  const newAddedUsers: SharePointAddedUser[] = useSelector((state: RootState) => state.sharePoint.newAddedUsers);
  const isNewAddedUsersDismissed: boolean = useSelector((state: RootState) => state.sharePoint.isNewAddedUsersDismissed);
  const { isSharePointEnabled, isSharePointConnected } = useSharePointAccess(clientId); // SHAREPOINT PERMISSIONS + INTEGRATION STATUS

  useEffect(() => {
    if (isSharePointEnabled && isSharePointConnected) {
      dispatch(getNewAddedUsers());
    }
  }, []);

  return (
    <>
      {(!!newAddedUsers?.length && !isNewAddedUsersDismissed) && (
        <BannerSnackbar
          handleClick={() => setIsSharePointMembersModal((prev) => !prev)}
          needToBeAcknowledgedNum={newAddedUsers?.length}
          btnText={['LOBBY_PAGE.BANNER.BUTTON_TEXT']}
          headerText={['LOBBY_PAGE.BANNER.HEADER_TEXT', { NUMBER: newAddedUsers?.length }]}
          onClose={() => dispatch(dismissNewAddedUsers())}
        />
      )}
      {newAddedUsers && (
        <TangiModal
          show={isSharePointMembersModal}
          header={
            <TangiTypography type={TYPOGRAPHY_TYPES.HEADING_LG} weight="semibold">
              {t('LOBBY_PAGE.SHARE_POINT_MEMBERS_MODAL.HEADER')}
            </TangiTypography>
          }
          onHide={() => setIsSharePointMembersModal(false)}
          body={<SharePointMembersModal members={newAddedUsers} />}
          containerStyle={{ padding: SPACING[2.5], maxWidth: '600px' }}
        />
      )}

    </>
  );
};

export default NewSharepointUsers;
