import { isEmpty } from 'lodash';

import { Client, ClientIpPermissionsPartial } from './types/client/client';
import { User } from './types/users/users';

export const hasIPPortfolioPermission = (client: Client | ClientIpPermissionsPartial) => {
  const ipPortfolioPermission = client?.permissions?.ipPortfolio;
  return (ipPortfolioPermission?.hasPermission && !isEmpty(ipPortfolioPermission?.portfolioId)) ?? false;
};

export const hasIPCompetitivePermission = (client: Client | ClientIpPermissionsPartial) => {
  const ipPortfolioPermission = client?.permissions?.ipCompetitive;
  return (ipPortfolioPermission?.hasPermission && !isEmpty(ipPortfolioPermission?.portfolioId)) ?? false;
};

export const hasSharePointPermission = (client: Client | null, user: User) => {
  return client?.permissions?.sharePoint?.hasPermission && user?.email === client?.permissions?.sharePoint?.integratorEmail;
};

export const hasConnectedSharePoint = (client: Client | null): boolean => {
  return client?.permissions?.sharePoint?.isConnected ?? false;
};
