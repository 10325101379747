import React, { useMemo } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import './Avatar.scss';
import defaultProfile from '../../assets/img/default_profile.png';
import { NEUTRAL_SHADES, SECONDARY_SHADES } from '../../utils/theme';
import { AVATAR_SIZES } from '../../utils/componentUtils';
import { AvatarContainerDiv, AvatarContainerLink, LetteredAvatar, AvatarImg } from './style';

function Avatar({ name = '', email = '', image = '', color = SECONDARY_SHADES[500], href = '#', styles = '', isLinkDisabled = true, tooltip = true, bordered = false, size = AVATAR_SIZES.SM }) {
  if (image === 'default') {
    // eslint-disable-next-line
    image = defaultProfile;
  }

  const bgColor = useMemo(() => {
    return image ? NEUTRAL_SHADES.WHITE : color;
  }, [image]);

  const names = name ? name.split(' ').reverse() : [];
  let initials = '';
  names.forEach((element) => {
    initials = element.slice(0, 1) + initials;
  });
  if (tooltip)
    return (
      <Tippy content={<div className="font-bold text-xs">{email} </div>} disabled={!email}>
        {!isLinkDisabled ? (
          <AvatarContainerLink size={size} bgColor={bgColor} to={href} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
            {image ? (
              <img className="text-capitalize object-cover" alt={name} src={image} />
            ) : (
              <LetteredAvatar image={image} className="fw-bolder uppercase">
                <span>{initials.toUpperCase()}</span>
              </LetteredAvatar>
            )}
          </AvatarContainerLink>
        ) : (
          <AvatarContainerDiv size={size} bgColor={bgColor} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
            {image ? (
              <AvatarImg className="text-capitalize object-cover" alt={name} src={image} data-testid="avatar-image" />
            ) : (
              <LetteredAvatar image={image} className="fw-bolder uppercase">
                <span>{initials.toUpperCase()}</span>
              </LetteredAvatar>
            )}
          </AvatarContainerDiv>
        )}
      </Tippy>
    );
  return (
    <AvatarContainerDiv size={size} bgColor={bgColor} className={`avatar ${styles} ${bordered ? 'border-2 border border-white' : ''}`}>
      {image ? <AvatarImg className="text-capitalize object-cover" alt={name} src={image} /> : <LetteredAvatar className="fw-bolder uppercase">{initials.toUpperCase()}</LetteredAvatar>}
    </AvatarContainerDiv>
  );
}

export { Avatar };
