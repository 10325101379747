import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import PatentToolHeaderError from './PatentToolHeaderError';
import { RootState } from '_helpers';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { NEUTRAL_SHADES, SPACING, THEME_COLORS } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { FlexAlignCenter, HeaderContainer, InventorsContainer, InventorsNameTypography, InventorsNamesRow, LeftColumn, customTitleTypographyStyles } from '../style';

const FlexContainerCentered = styled(FlexContainer)`
  align-items: center;
`;

const LineContainer = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  align-items: center;
`;

const DividerContainer = styled.div`
  width: ${SPACING[2]};
  height: 18px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 1px;
    background-color: ${NEUTRAL_SHADES[700]};
  }
`;

const FlexBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

// interface Props {
//   mappingStatus: {
//     isMappingToolStarted: boolean;
//     isMappingToolFailed: boolean;
//     isMappingLoading: boolean;
//     isMappingFinished: boolean;
//   };
// }

const PatentToolHeader = () => {
  const { t } = useTranslation();
  const patentData = useSelector((state: RootState) => state.patent.patentData);

  const isFile = useMemo(() => patentData.source_type === XRAY_SOURCE_TYPE.FILE, [patentData.source_type]);

  const renderPatentYear = () => {
    return (
      patentData?.year && (
        <>
          <DividerContainer />
          <LineContainer>
            <TangiTypography type="subheading">{t('PATENT_MAPPING_TOOL.TITLE.YEAR')}</TangiTypography>
            <TangiTypography type="subheading" color={NEUTRAL_SHADES[1100]} weight="semibold">
              {patentData.year}
            </TangiTypography>
          </LineContainer>
        </>
      )
    );
  };

  const renderInventors = () => {
    return (
      !!patentData?.inventors?.length && (
        <InventorsContainer>
          <TangiTypography color={NEUTRAL_SHADES[1100]}>{t('PATENT_MAPPING_TOOL.TITLE.INVENTORS')}</TangiTypography>
          <InventorsNamesRow>
            {patentData.inventors.map((inventor: string, index: number) => (
              <InventorsNameTypography color={NEUTRAL_SHADES[1100]} weight="semibold" key={index}>
                {index === patentData.inventors.length - 1 ? inventor : `${inventor},`}
              </InventorsNameTypography>
            ))}
          </InventorsNamesRow>
        </InventorsContainer>
      )
    );
  };

  const renderPatentUrl = () => {
    return (
      patentData?.url && (
        <a href={`${patentData.url}`} target="_blank" rel="noopener noreferrer">
          <TangiSvgIcon component="externalLink" color={THEME_COLORS.PRIMARY_DEFAULT} />
        </a>
      )
    );
  };

  return (
    <HeaderContainer>
      <LeftColumn>
        {isFile && patentData?.details && <PatentToolHeaderError />}
        <div>
          <TangiTypography weight="semibold" type="heading-lg" customStyles={customTitleTypographyStyles} className="patent-title">
            {isFile && patentData?.filename
              ? t('PATENT_MAPPING_TOOL.TITLE.POTENTIAL_TS', { FILE_NAME: patentData?.filename || '' })
              : !isFile && t('PATENT_MAPPING_TOOL.TITLE.POTENTIAL_TS_TITLE', { PATENT_TITLE: patentData?.title || '' })}
          </TangiTypography>
          {!isFile && (
            <>
              <FlexContainerCentered>
                <LineContainer>
                  <TangiTypography type="subheading">{t('PATENT_MAPPING_TOOL.TITLE.PATENT_NUMBER')}</TangiTypography>
                  <TangiTypography color={NEUTRAL_SHADES[1100]} type="subheading" weight="semibold">
                    {patentData?.patent_id || ''}
                  </TangiTypography>
                  {renderPatentUrl()}
                </LineContainer>
                {renderPatentYear()}
              </FlexContainerCentered>
              {renderInventors()}
            </>
          )}
        </div>

        <FlexBetweenContainer>
          <FlexAlignCenter></FlexAlignCenter>
        </FlexBetweenContainer>
      </LeftColumn>
    </HeaderContainer>
  );
};

export default PatentToolHeader;
