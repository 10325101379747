import React, { useMemo } from 'react';
import { BreadcrumbContainer, BreadcrumbPageTitle } from '../styles';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { TangiSvgIcon } from '_components/TangiLibrary';
import { useTranslation } from 'react-i18next';

interface BreadcrumbProgressProps {
  currentPage: number;
}

const BreadcrumbProgress = ({ currentPage }: BreadcrumbProgressProps) => {
  const { t } = useTranslation();

  const CREATE_ASSET_STEPS_TITLE = useMemo(() => [t('ASSET_PAGE.CREATE_EDIT_ASSET.TITLE.ASSET_DETAILS'), t('ASSET_PAGE.CREATE_EDIT_ASSET.TITLE.METADATA')], [t]);

  const renderIsNextPageIcon = (currPageIdx: number) => {
    if (currPageIdx === currentPage - 1) return <TangiSvgIcon color={NEUTRAL_SHADES[600]} component="check" />;
    return <React.Fragment>{currPageIdx}. </React.Fragment>;
  };

  return (
    <BreadcrumbContainer>
      {CREATE_ASSET_STEPS_TITLE.map((step, index) => {
        const currPageIdx = index + 1;
        return (
          <React.Fragment key={currPageIdx}>
            <BreadcrumbPageTitle type="subheading" color={currPageIdx === currentPage ? PRIMARY_SHADES[800] : NEUTRAL_SHADES[1000]}>
              {renderIsNextPageIcon(currPageIdx)}
              {step}
            </BreadcrumbPageTitle>
            {index < CREATE_ASSET_STEPS_TITLE.length - 1 && <TangiSvgIcon color={NEUTRAL_SHADES[600]} component="arrowSideRight" />}
          </React.Fragment>
        );
      })}
    </BreadcrumbContainer>
  );
};

export default BreadcrumbProgress;
