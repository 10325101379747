import React from 'react';
import { AcknowledgeReceipt } from '../../_components/AcknowledgeReceipt';
import './AcknowledgeReceiptPage.scss';

function AcknowledgeReceiptPage() {
  return (
    <div className="main">
      <div className="bg-light mt-5 p-4 container content-height">
        <AcknowledgeReceipt />
      </div>
    </div>
  );
}

export { AcknowledgeReceiptPage };
