import { courseProgressionsConstants } from '../_constants/courseProgressionsConstants';

const initialState = {
  courseProgressions: [],
  loading: false,
};

export function courseProgressionsReducer(state = initialState, action) {
  switch (action.type) {
    case courseProgressionsConstants.GETBYUSER_PROGRESSION_REQUEST:
      return {
        ...state,
        loading: true,
        courseProgressions: [],
      };
    case courseProgressionsConstants.GETBYUSER_PROGRESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        courseProgressions: action.payload,
      };
    case courseProgressionsConstants.GETBYUSER_PROGRESSION_FAILURE:
      return {
        ...state,
        loading: false,
        courseProgressions: [],
      };
    case courseProgressionsConstants.EDIT_PROGRESSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case courseProgressionsConstants.EDIT_PROGRESSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case courseProgressionsConstants.EDIT_PROGRESSION_FAILURE:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
