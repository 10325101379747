/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Form, Row, Button, Col, Table } from 'react-bootstrap';
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Loader } from '../../Loader';
import { EditAsyncSelect, optionTypes } from '../../EditAsyncSelect';
import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { agreementActions } from '../../../_actions/agreementActions';
import { adminAgreementActions } from '../../../AdminRedux/_actions/adminAgreementActions';
import { adminUserService } from '../../../AdminRedux/_services/adminUserService';
import './AgreementForm.scss';
import 'react-datepicker/dist/react-datepicker.css';

function AgreementForm({ type, mode, viewResult, firmid }) {
  const formTypes = {
    AGREEMENT: 'Add new item to Agreements',
    TYPE: 'Add new type',
    EDIT_TYPE: 'Edit Type',
  };

  const createStatus = {
    creatingAgreement: 'creatingAgreement',
    agreementCreated: 'agreementCreated',
    agreementSuccess: 'agreementSuccess',
    agreementFailed: 'agreementFailed',
  };

  const { error, status, editType } = useSelector((state) => state.agreement);
  const agreementState = useSelector((state) => state.agreement);
  const { status: agreementStatus, errors: agreementError } = useSelector((state) => state.adminAgreementReducer);

  const statusMsg = {
    createdSuccessFully: { color: '#4ABB79', message: 'Agreement created successfully' },
    createFailed: { color: '#F75676', message: agreementError?.message ? agreementError?.message : 'Agreement Created Failed' },
  };

  const [statusMsgResponse, setStatusMsgResponse] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [clearCacheKey, setClearCacheKey] = useState([uuidv4()]);

  const dispatch = useDispatch();
  const history = useHistory();
  const isView = () => mode === 'view';
  useEffect(() => {
    if (viewResult && viewResult.id) {
      reset(viewResult);
    }
  }, [viewResult]);

  useEffect(() => {
    if (status === 'failed') {
      setErrMsg('show');
      setTimeout(() => {
        setErrMsg('');
        dispatch(agreementActions.clearStatus());
      }, 3000);
    } else {
      setErrMsg('');
    }
  }, [agreementState]);

  useEffect(() => {
    switch (agreementStatus) {
      case createStatus.creatingAgreement:
        setIsloading(true);
        break;
      case createStatus.agreementCreated:
        break;
      case createStatus.agreementSuccess:
        setStatusMsgResponse(statusMsg.createdSuccessFully);
        adminUserService
          .getAdminAccountsById(account?.id)
          .then((response) => {
            reset({ account: response?.data?.[0] });
            setClearCacheKey([uuidv4()]);
          })
          .finally(() => {
            setIsloading(false);
            setTimeout(() => {
              setStatusMsgResponse({});
            }, 2000);
          });

        break;
      case createStatus.agreementFailed:
        setStatusMsgResponse(statusMsg.createFailed);
        setIsloading(false);
        break;
      default:
        break;
    }
  }, [agreementStatus]);

  useEffect(() => {
    dispatch(agreementActions.getAgreementTypes(1000));
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: {
      agreement: [
        {
          agreementType: '',
          signed_date: '',
          expiry_date: '',
          signed: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'agreement',
  });

  const agreement = useWatch({
    control: control,
    name: 'agreement',
  });

  const account = useWatch({
    control: control,
    name: 'account',
  });

  useEffect(() => {
    if (status === 'success') {
      reset('', {
        keepValues: false,
      });
      setTimeout(() => {
        window.location.reload();
        dispatch(agreementActions.clearStatus());
      }, 2000);
    }
    if (editType && editType.id) {
      dispatch(agreementActions.clearEditType());
      setTimeout(() => {
        reset({});
        history.push('/admin/agreement&type=type');
      }, 3000);
    }
  }, [status, editType]);

  function postAgreement(data) {
    data.account = data.account.id;
    dispatch(adminAgreementActions.createAgreementWithFiles(data));
  }

  const onSubmit = (data) => {
    setIsloading(true);
    switch (type) {
      case formTypes.TYPE:
        if (data.parent === 'null') {
          delete data.parent;
        }
        dispatch(agreementActions.createAgreementType(data));
        break;
      case formTypes.AGREEMENT:
        postAgreement(data);
        break;
      case formTypes.EDIT_TYPE:
        dispatch(agreementActions.editAgreementType(data, firmid));
        break;
      default:
        break;
    }
  };

  function handleFiles(files, id) {
    setValue(id, files);
  }

  const handleAppendAgreement = () => {
    append({ agreementType: '', signed_date: '', expiry_date: '' });
  };

  const removeFileFunc = (id) => {
    setValue(id, []);
  };

  const isUniquetType = async (itemId, ind) => {
    let existingAgreement = [];

    if (account?.agreements?.length > 0) {
      existingAgreement = account?.agreements.map((agree) => ({ ...agree, agreementType: agree.agreementType._id }));
    }

    for (const [index, value] of [...agreement, ...existingAgreement].entries()) {
      if (index !== ind && value.agreementType === itemId) {
        return 'Agreement type already exists';
      }
    }
    return null;
  };

  function submitMiddleWare(e) {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  function OldAgreementsTable({ agreements }) {
    return (
      <>
        {agreements?.length > 0 && (
          <Table bordered responsive hover className="rounded">
            <thead>
              <tr style={{ backgroundColor: '#e3e3e3' }}>
                <th colSpan="2">Type</th>
                <th colSpan="1">Signed File</th>
                <th colSpan="2">Signed</th>
                <th colSpan="2">Executed On</th>
                <th colSpan="2">Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {agreements?.length > 0 &&
                agreements?.map((field) => (
                  <tr key={field.id}>
                    <td colSpan="2">{field.agreementType?.name}</td>
                    <td colSpan="1">
                      {field.files &&
                        field?.files?.length > 0 &&
                        field?.files?.map((file, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <li style={{ listStyle: 'none' }} key={`${index}_${file.name}`} className="border border-black rounded-3 bg-light">
                            <div className="px-3 py-1">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center justify-content-center">
                                  <i aria-hidden="true" className={`${icons[getFileExtensionCard(file?.name)]} me-2 icon-medium `} />
                                  <span>{file?.name}</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </td>
                    <td colSpan="2">{field?.signed ? 'Signed' : 'Not Signed'}</td>
                    <td colSpan="2">{field?.signed_date?.split('T')[0]}</td>
                    <td colSpan="2">{field?.expiry_date?.split('T')[0]}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </>
    );
  }

  return (
    <Row className="m-0">
      <div className="col-md-12 col-lg-12 mx-auto">
        <div className="border-0 rounded agreement-container">
          {type === 'Add new item to Agreements' ? (
            <div className="mb-2 card">
              <Loader isLoading={isLoading} />
              <Form className="my-4 mx-4" onSubmit={submitMiddleWare}>
                <h2 className="text-dark font-weight-normal">Add Agreements</h2>
                <hr />
                <div className="px-3 mt-3 border-0">
                  <Form.Group className="mb-3" controlId="formRoles">
                    <Form.Label className="text-dark fs-6">
                      Account <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      name="account"
                      render={({ field: { ref, ...rest } }) => (
                        <EditAsyncSelect cacheUniqueId={clearCacheKey} inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.USERS_AND_CLIENT} {...rest} />
                      )}
                      control={control}
                      rules={{ required: true }}
                    />
                  </Form.Group>
                </div>

                {account?.role && (
                  <div className="px-3 mt-3 border-0">
                    <div className="text-dark fs-6">Account Role</div>
                    <div className="fs-5"> {account?.role && account?.role.name}</div>
                  </div>
                )}
                {account?.agreements?.length > 0 && (
                  <div className="px-3 mt-3 border-0 row">
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Label className="text-dark fs-6">Existing Agreements</Form.Label>
                      <Link to={`/admin/agreement/edit/${account.id}`}>
                        <Button className="btn btn-secondary rounded border-black d-block u-hover-black u-focus-black">EDIT</Button>
                      </Link>
                    </div>
                    <OldAgreementsTable agreements={account?.agreements} />
                  </div>
                )}

                <div className="px-3 mt-3 border-0">
                  <Form.Label className="text-dark fs-6">New Agreements</Form.Label>

                  <Table bordered className="rounded zoom-90 mb-0">
                    <thead>
                      <tr style={{ backgroundColor: '#e3e3e3' }}>
                        <th colSpan="3" className="col-3">
                          Type
                        </th>
                        <th colSpan="3">Signed File</th>
                        <th colSpan="1" className="col-1">
                          Signed
                        </th>
                        <th colSpan="3">Executed On</th>
                        <th colSpan="3">Expiry Date</th>
                        <th>{}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((field, index) => (
                        <tr key={field.id}>
                          <td colSpan="3" className="col-3">
                            <Controller
                              name={`agreement[${index}].agreementType`}
                              render={({ field: { ref, ...rest } }) => (
                                <EditAsyncSelect others={{ maxMenuHeight: 130 }} inputRef={ref} selectStyles="ff-din-DemiBold" type={optionTypes.AGREEMENT_TYPES} {...rest} />
                              )}
                              control={control}
                              rules={{
                                required: { value: true, message: 'Agreement Type is required' },
                                validate: (type) => isUniquetType(type, index),
                              }}
                            />

                            <div style={{ fontSize: 'inherit' }} className="d-block invalid-feedback">
                              {errors.agreement?.[index]?.agreementType && errors.agreement?.[index]?.agreementType.message}
                            </div>
                          </td>
                          <td colSpan="3">
                            {agreement?.[index]?.file?.length > 0 ? (
                              <li style={{ listStyle: 'none' }} className="border border-black rounded-3">
                                <div className="px-3 py-1">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <i aria-hidden="true" className={`${icons[getFileExtensionCard(agreement?.[index]?.file[0]?.name)]} me-2 icon-medium `} />

                                      <span>{agreement?.[index]?.file[0]?.name}</span>
                                    </div>
                                    <button type="button" onClick={() => removeFileFunc(`agreement[${index}].file`)} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                      <i className="far fa-trash-alt" aria-hidden="true" />
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              <Dropzone multiple={false} onDrop={(file) => handleFiles(file, `agreement[${index}].file`)}>
                                {({ getRootProps, getInputProps }) => (
                                  <>
                                    <div {...getRootProps()} className="u-focus-outline dropzoneAgreement text-center text-muted p-2">
                                      <input {...getInputProps()} />

                                      <p className="text-black">
                                        Drag and drop photo, or <span className="card-link text-primary text-decoration-underline color-hover">browse</span>
                                      </p>
                                    </div>
                                  </>
                                )}
                              </Dropzone>
                            )}
                          </td>

                          <td colSpan="1" className="col-1">
                            <div>
                              <input
                                style={{ height: '20px' }}
                                type="checkbox"
                                id={`agreement[${index}].signed`}
                                name={`agreement[${index}].signed`}
                                className="d-block container"
                                {...register(`agreement[${index}].signed`)}
                              />
                              <label className="d-flex justify-content-center" htmlFor={`agreement[${index}].signed`}>
                                {agreement?.[index]?.signed ? 'Signed' : 'Not Signed'}
                              </label>
                            </div>
                          </td>

                          <td colSpan="3">
                            <Form.Control required={agreement?.[index]?.signed} type="date" disabled={!agreement?.[index]?.signed} {...register(`agreement[${index}].signed_date`)} />
                          </td>
                          <td colSpan="3">
                            <Form.Control type="date" {...register(`agreement[${index}].expiry_date`)} disabled={!agreement?.[index]?.signed} />
                          </td>
                          <td colSpan="3">
                            {index !== 0 && (
                              <Button variant="secondary" className="u-hover-black rounded u-focus-black" type="button" onClick={() => remove(index)}>
                                DELETE
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="border-0 d-flex justify-content-end row">
                    <Col className="col-1">
                      <Button onClick={handleAppendAgreement} className="btn btn-secondary rounded border-black d-block u-hover-black u-focus-black">
                        ADD
                      </Button>
                    </Col>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <Button className="my-4 mx-4 px-5 fs-6 btn rounded-pill button-letter-space-none btn-primary text-white my-4 p-3" type="submit">
                    Add Agreements
                  </Button>
                </div>
              </Form>

              {statusMsgResponse?.message && (
                <div style={{ backgroundColor: statusMsgResponse?.color, borderColor: statusMsgResponse?.color }} className="alert alert-primary fade show rounded py-2 text-white text-center">
                  <span style={{ fontSize: '13px' }} className="mx-3">
                    {statusMsgResponse?.message}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)} className={isView() && `form-disabled`}>
              <Form.Group className="mb-3" controlId="formBasicDisplayName">
                <Form.Label className="text-dark fs-6">Name</Form.Label>
                <Form.Control type="text" placeholder="Name" {...register('name', { required: { value: true, message: 'Name is required' } })} />
                <small className="form-text text-muted">The name is how it appears on your site.</small>
                <div className="d-block invalid-feedback">{errors.name && errors.name.message}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label className="text-dark fs-6">Slug</Form.Label>
                <Form.Control type="text" placeholder="Slug" {...register('slug')} />
                <small className="form-text text-muted">The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.</small>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPhone">
                <Form.Label className="text-dark fs-6">Description</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Description" {...register('description')} />
                <small className="form-text text-muted">The description is not prominent by default; however, some themes may show it.</small>
              </Form.Group>
              {type === 'Add new firm' ? (
                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <Form.Label>Law firm logo</Form.Label> <br />
                  <Form.Control type="file" size="lg" {...register('firmLogo')} />
                </Form.Group>
              ) : (
                ''
              )}
              <div className="d-flex">
                {!isView() && (
                  <Button className="my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" type="submit">
                    {type}
                  </Button>
                )}
              </div>
              <div className={`alert alert-primary fade ${errMsg} text-center w-100`}>{error && error.message}</div>
              <div style={{ backgroundColor: '#4ABB79', borderColor: '#4ABB79' }} className={`alert alert-primary fade ${status === 'success' ? 'show' : ''} text-center w-100`}>
                Created Successfully
              </div>
            </Form>
          )}
        </div>
      </div>
    </Row>
  );
}

export { AgreementForm };
