import styled from 'styled-components';
import { NEUTRAL_SHADES } from 'utils/theme';
import { forwardRef } from 'react';
import { WidgetCardProps } from './Widgets.types';
import EmptyWidget from './EmptyWidget/EmptyWidget';

const WidgetCardContainer = styled.div`
  background-color: ${NEUTRAL_SHADES.WHITE};
  border: 1px solid ${NEUTRAL_SHADES[300]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'DIN2014-REGULAR';
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const WidgetCard = forwardRef<HTMLDivElement, WidgetCardProps>((props, ref) => {
  const renderContent = () => {
    if (props.isEmpty) {
      return <EmptyWidget {...props.emptyState} />;
    } else return props.children;
  };

  return (
    <WidgetCardContainer {...props} className={props.className} key={props.key} ref={ref}>
      {renderContent()}
    </WidgetCardContainer>
  );
});
export default WidgetCard;
