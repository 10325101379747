/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';
import partnershipAdapter from 'redux-toolkit/adapters/partnershipAdapter';
import { agreementActions } from '../../_actions';
import { ENTITY_TYPES, PARTNERSHIP_STATUS } from 'utils/enums';
import { removePartnershipById } from '../../utils/partnershipUtils';
import { RootState } from '_helpers/store';
import { Partnership, PartnershipCreationPayload, PartnershipUpdatePayload } from 'utils/types/partnership/partnership';

export const getPartnershipById = createAsyncThunk('/partnership/getPartnershipById', async (partnershipId: string) => {
  const res: any = await partnershipAdapter.getPartnershipById(partnershipId);
  return res ?? null;
});

export const getPartnerships = createAsyncThunk('/partnership/getPartnerships', async () => {
  const res: Partnership[] = await partnershipAdapter.getPartnerships();
  const activePartnerships = res?.filter((partner: Partnership) => partner.status === PARTNERSHIP_STATUS.ACTIVE);
  const disabledPartnerships = res?.filter((partner: Partnership) => partner.status === PARTNERSHIP_STATUS.DISABLED);
  return { active: activePartnerships ?? [], disabled: disabledPartnerships ?? [] };
});

export const createPartnership = createAsyncThunk('/partnership/createPartnership', async (partnershipToCreate: PartnershipCreationPayload, { dispatch }) => {
  const { logo, ...partnershipData } = partnershipToCreate;
  const createdPartnership: any = await partnershipAdapter.createPartnership(partnershipData);

  if (logo) {
    const logoLocation = await partnershipAdapter.uploadPartnershipsPhoto({ data: logo, partnershipId: createdPartnership.id });
    if (logoLocation) createdPartnership.logo = logoLocation;
  }
  const { agreements } = partnershipToCreate;
  const { client: clientId, id: createdPartnershipId } = createdPartnership;

  if (clientId && createdPartnershipId && agreements) {
    dispatch(agreementActions.createAgreementWithFiles(agreements, clientId, null, createdPartnership?.id, ENTITY_TYPES.PARTNERSHIPS));
  }
  const newPartnership = { _id: createdPartnershipId, ...createdPartnership };
  delete newPartnership.id;
  return createdPartnership ? newPartnership : null;
});

export const updatePartnership = createAsyncThunk('/partnership/updatePartnership', async (partnershipToUpdate: PartnershipUpdatePayload, { dispatch, getState }) => {
  const { logo, ...partnershipData } = partnershipToUpdate;
  if (logo?.length && typeof logo[0] !== 'string') {
    const timestamp = new Date().getTime();
    const logoLocation = await partnershipAdapter.uploadPartnershipsPhoto({ data: logo, partnershipId: partnershipData._id });
    if (logoLocation) partnershipToUpdate.logo = `${logoLocation}?v=${timestamp}`;
  } else if (logo?.length && typeof logo[0] === 'string') {
    partnershipToUpdate.logo = logo[0];
  }

  const updatedPartnership: any = await partnershipAdapter.updatePartnership(partnershipToUpdate);

  if (partnershipToUpdate.hasOwnProperty('agreements') && partnershipToUpdate?.agreements && partnershipToUpdate.agreements.length > 0) {
    const agreementsData = {
      agreement: partnershipToUpdate.agreements ? partnershipToUpdate.agreements : [],
    };
    dispatch(agreementActions.updateAgreementWithFiles(agreementsData, partnershipToUpdate.client, ENTITY_TYPES.PARTNERSHIPS, updatedPartnership.id));
  }
  const newPartnership = { _id: updatedPartnership.id, ...updatedPartnership };
  delete newPartnership.id;
  const state = getState() as RootState;
  const activePartnerships = removePartnershipById(state.partnership.partnerships.active, newPartnership);
  dispatch(getPartnershipById(newPartnership._id));

  return {
    updatedPartnerships: { active: [...activePartnerships, newPartnership], disabled: state.partnership.partnerships.disabled },
  };
});

export const deactivatePartnership = createAsyncThunk('/partnership/deactivatePartnership', async (partnershipId: string, { getState, dispatch }) => {
  const deactivatedPartnership: any = await partnershipAdapter.deactivatePartnership(partnershipId);
  const newPartnership = { _id: deactivatedPartnership.id, ...deactivatedPartnership };
  delete newPartnership.id;
  const state = getState() as RootState;
  const activePartnerships = removePartnershipById(state.partnership.partnerships.active, newPartnership);
  dispatch(getPartnershipById(newPartnership._id));
  return { active: activePartnerships, disabled: [...state.partnership.partnerships.disabled, newPartnership] };
});
