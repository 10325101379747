import { PDFExport } from '@progress/kendo-react-pdf';
import React, { forwardRef } from 'react';

import { PatentApplicationData, TradeSecretFromPatent } from 'utils/types/patent/patent';
import { NEUTRAL_SHADES, SECONDARY_SHADES } from 'utils/theme';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { TangiTypography } from '_components/TangiLibrary';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import PatentToolHeaderExport from './PatentToolHeaderExport';
import { PatenExportPageData, getScoreBasedOnInventorsAndAge } from './utils';
import defaultImg from '../../../../assets/img/CANOPY-logo.png';
import { BulletContainer, CardTitle, SubjectCard } from 'pages/PatentTool/style';
import {
  FooterDiv,
  LabelRow,
  LabelsContainer,
  PDFContent,
  PDFPageContainer,
  PDFSidebar,
  ProgressBarContainer,
  SidebarContainer,
  StyledProgressBar,
  StyledTangiTypography,
  StyledValueTypo,
  VulnerabilityContainer,
  customBulletTypographyStyles,
} from './style';

const PatentExportPDF = forwardRef<PDFExport, { data: PatentApplicationData }>((props, ref) => {
  const { year, choice_0, source_type, patent_id, inventors, filename } = props.data ?? {};
  const currentYear = new Date().getFullYear();
  const estimatedAge = year ? currentYear - year + 2 : currentYear;
  const numberOfBullets = choice_0?.reduce((count, item) => count + item.bullets.length, 0);
  const nameOfPatent = patent_id || filename || '';

  const scoreVol = getScoreBasedOnInventorsAndAge(inventors?.length || 0, estimatedAge);

  const renderBullets = (bullets: string[]) => {
    return (
      <>
        {bullets.map((bullet, index) => {
          return (
            <React.Fragment key={index}>
              {!!bullet?.length && (
                <BulletContainer isActive={false} isFound={false} onClick={() => {}}>
                  <TangiTypography customStyles={customBulletTypographyStyles}>{bullet}</TangiTypography>
                </BulletContainer>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const renderCardTitle = (tradeSecret: TradeSecretFromPatent) => {
    return (
      <CardTitle>
        <TangiTypography type="heading-md" weight="semibold">
          {tradeSecret.subject}
        </TangiTypography>
      </CardTitle>
    );
  };

  const renderTradeSecretSubjectCards = () => {
    return (
      <>
        {choice_0?.map((tradeSecret: TradeSecretFromPatent, index: number) => (
          <SubjectCard key={index} style={{ marginBottom: '16px' }}>
            {renderCardTitle(tradeSecret)}
            {!!tradeSecret?.bullets?.length && renderBullets(tradeSecret.bullets)}
          </SubjectCard>
        ))}
      </>
    );
  };

  const renderFooter = ({ pageNum, totalPages }: PatenExportPageData) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
      <FooterDiv>
        <TangiTypography color={NEUTRAL_SHADES[800]}>© {currentYear} Tangibly Inc</TangiTypography>
        <TangiTypography color={NEUTRAL_SHADES[800]}>|</TangiTypography>
        <TangiTypography color={NEUTRAL_SHADES[800]}>
          Page {pageNum}/{totalPages}
        </TangiTypography>
      </FooterDiv>
    );
  };

  const MyPageTemplate = ({ pageNum, totalPages }: PatenExportPageData) => {
    return (
      <>
        <PDFSidebar>
          <SidebarContainer>
            <img src={defaultImg} width="120"></img>
            <div>
              <StyledTangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="semibold" color={NEUTRAL_SHADES[1000]}>
                Estimated Number of Trade Secrets
              </StyledTangiTypography>
              <StyledValueTypo weight="bold" color={SECONDARY_SHADES[800]}>
                {numberOfBullets}
              </StyledValueTypo>
            </div>
            {source_type === XRAY_SOURCE_TYPE.GOOGLE && (
              <>
                <div>
                  <TangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="semibold" color={NEUTRAL_SHADES[1000]}>
                    Estimated Age of Oldest Trade Secret
                  </TangiTypography>
                  <StyledValueTypo weight="bold" color={SECONDARY_SHADES[800]}>
                    {estimatedAge}
                  </StyledValueTypo>
                </div>
                <div>
                  <TangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="semibold" color={NEUTRAL_SHADES[1000]}>
                    Date of Patent Filing
                  </TangiTypography>
                  <StyledValueTypo weight="bold" color={SECONDARY_SHADES[800]}>
                    {year}
                  </StyledValueTypo>
                </div>
              </>
            )}
            {typeof scoreVol !== 'undefined' && (
              <VulnerabilityContainer>
                <TangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="semibold" color={NEUTRAL_SHADES[1000]}>
                  Trade Secret Vulnerability
                </TangiTypography>
                <ProgressBarContainer>
                  <LabelsContainer>
                    <StyledProgressBar now={scoreVol} />
                    <LabelRow>
                      <StyledTangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="bold" color={SECONDARY_SHADES[800]}>
                        Low
                      </StyledTangiTypography>
                      <StyledTangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="bold" color={SECONDARY_SHADES[800]}>
                        Medium
                      </StyledTangiTypography>
                      <StyledTangiTypography type={TYPOGRAPHY_TYPES.SUB_BODY} weight="bold" color={SECONDARY_SHADES[800]}>
                        High
                      </StyledTangiTypography>
                    </LabelRow>
                  </LabelsContainer>
                </ProgressBarContainer>
              </VulnerabilityContainer>
            )}
          </SidebarContainer>
        </PDFSidebar>
        {renderFooter({ pageNum, totalPages })}
      </>
    );
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: '-10000px',
        top: 0,
        fontFamily: 'DIN2014-Bold',
      }}
    >
      <PDFExport ref={ref} fileName={`tradeSecretAnalysis${nameOfPatent}.pdf`} paperSize="A4" landscape={true} scale={0.8} pageTemplate={MyPageTemplate}>
        <PDFPageContainer>
          <PDFContent>
            <div style={{ marginBottom: '30px' }}>
              <PatentToolHeaderExport />
            </div>
            <div>{renderTradeSecretSubjectCards()}</div>
          </PDFContent>
        </PDFPageContainer>
      </PDFExport>
    </div>
  );
});

export default PatentExportPDF;
