import { SPACING } from 'utils/theme';

export const spWizardModalBodyStyle = {
  padding: SPACING[4],
  height: '494px',
};

export const spWizardModalContainerStyle = {
  width: '1036px',
};

export const spWizardModalHeaderStyle = {
  padding: `${SPACING[3.5]} ${SPACING[3.5]} ${SPACING[4]}`,
};

export const spWizardModalFooterStyle = {
  padding: `${SPACING[3.5]}`,
};
