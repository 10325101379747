/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRoles, getUserRoleAndPermission } from '../../utils/roles';
import { authConstants } from '../../_constants';
import { authenticate, confirmDisclaimer, updateSeenXrayModal, updateUserLanguage } from '../thunks/authThunks';
import { LANGUAGE, LANGUAGES } from 'translations/enums';
import { AuthenticateResponse, UpdateSeenXrayModalResponseData } from 'utils/types/auth/auth';
import { User } from 'utils/types/users/users';
import { saveStateToLocalStorage } from 'utils/localStorage';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { Account } from 'utils/types/account/account';
import { Permissions, Role } from 'utils/types/role/role';
import { updateHasSeenInformationAccount } from 'redux-toolkit/thunks/usersThunks';
import { RootState } from '_helpers';

const user = JSON.parse(localStorage?.getItem(authConstants.USER) || 'null');
const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');

export interface authState {
  loggedIn: boolean;
  permissions: Permissions | boolean | Record<string, never>;
  Role: IRoles | '';
  activeAccount: Account | null;
  user: User | null;
  loading: boolean;
  hasSeenDisclaimer: boolean;
  error: any;
  accountSideBarCollapseState: Record<string, boolean>;
}

const initialState: authState = {
  loggedIn: !!(user && activeAccount),
  permissions: activeAccount ? activeAccount?.role?.name !== 'CMS Admin' && getUserRoleAndPermission(activeAccount.role) : {},
  Role: activeAccount ? activeAccount?.role.name : '',
  activeAccount,
  user,
  loading: false,
  hasSeenDisclaimer: false,
  error: {},
  accountSideBarCollapseState: {},
};

const authSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setAccount(state, action) {
      state.activeAccount = action.payload;
    },
    setChangeOfActiveAccount(state, action: PayloadAction<Account>) {
      const { payload: account } = action;
      state.activeAccount = account;
      if (typeof account.role === 'object') {
        state.Role = account.role.name;
        state.permissions = getUserRoleAndPermission(account.role);
      }
    },
    setHasSeenDisclaimer(state, action) {
      state.hasSeenDisclaimer = action.payload;
    },
    toggleSideBarCollapse: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.accountSideBarCollapseState = {
        ...Object.keys(state.accountSideBarCollapseState).reduce((acc, key) => {
          acc[key] = key === id ? !state.accountSideBarCollapseState[key] : false;
          return acc;
        }, {} as Record<string, boolean>),
        [id]: !state.accountSideBarCollapseState[id],
      };
    },
    setSideBarCollapseState: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.accountSideBarCollapseState = action.payload;
    },
    clearAuthState(state) {
      state.activeAccount = null;
      state.user = null;
      state.loggedIn = false;
      state.Role = '';
      state.loading = false;
      state.hasSeenDisclaimer = false;
      state.error = {};
      state.accountSideBarCollapseState = {};
    },
  },
  extraReducers: (builder) => {
    //update seenXrayModal fields
    builder.addCase(updateSeenXrayModal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSeenXrayModal.fulfilled, (state, action: PayloadAction<UpdateSeenXrayModalResponseData>) => {
      state.loading = false;
      const result = action.payload.result;

      if (result && state.user) {
        if (result.hasOwnProperty(authConstants.SEEN_XRAY_EXTRACTION)) {
          state.user.seenXrayExtraction = result.seenXrayExtraction;
        }

        if (result.hasOwnProperty(authConstants.SEEN_XRAY_MAPPING)) {
          state.user.seenXrayMapping = result.seenXrayMapping;
        }
        saveStateToLocalStorage(authConstants.USER, state.user);
      }
    });
    builder.addCase(updateSeenXrayModal.rejected, (state, action) => {
      state.error = action?.error?.message || '';
      state.loading = false;
    });

    // updateLanguage
    builder.addCase(updateUserLanguage.pending, () => {});
    builder.addCase(updateUserLanguage.fulfilled, (state, action: any) => {
      if (state.user) {
        state.user.language = LANGUAGES.includes(action.payload.result.language) ? action.payload.result.language : LANGUAGE.ENGLISH;
        localStorage.setItem(authConstants.USER, JSON.stringify(state.user));
      }
    });
    builder.addCase(updateUserLanguage.rejected, (state, action: any) => {
      state.error = action.error.message;
    });

    // confirmDisclaimer
    builder.addCase(confirmDisclaimer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(confirmDisclaimer.fulfilled, (state, action: any) => {
      state.user = action.payload.user;
      state.Role = action.payload.account.role.name;
      state.permissions = getUserRoleAndPermission(action.payload.account.role);
      state.loading = false;
    });
    builder.addCase(confirmDisclaimer.rejected, () => {});

    // authenticate
    builder.addCase(authenticate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authenticate.fulfilled, (state: RootState, action: PayloadAction<AuthenticateResponse | null>) => {
      if (action.payload) {
        const { user, account } = action.payload;
        const accountRoleName = (account?.role as Role).name;
        const userEmail = user.email;
        const activeClient = state.activeClient;

        state.activeAccount = account;
        state.user = user;
        state.loggedIn = true;
        state.permissions = (account?.role as Role).name !== 'CMS Admin' && getUserRoleAndPermission(account?.role as Role);
        state.Role = accountRoleName;
        state.loading = false;

        // Mix Panel Events: login
        mixpanelEvents.login(accountRoleName, userEmail, account as Account, activeClient);
      }
    });
    builder.addCase(authenticate.rejected, (state) => {
      state.error = { message: 'No active account found' };
      state.user = null;
      state.loading = false;
    });

    // updateHasSeenInformation field
    builder.addCase(updateHasSeenInformationAccount.pending, (state) => {
      state.error = '';
      state.loading = true;
    });
    builder.addCase(updateHasSeenInformationAccount.fulfilled, (state, action: PayloadAction<Account>) => {
      state.loading = false;
      if (action.payload && state.activeAccount) {
        state.activeAccount.hasSeenInformation = action.payload.hasSeenInformation;

        saveStateToLocalStorage(authConstants.ACTIVATE_ACCOUNT, state.activeAccount);

        if (state.user) {
          const updatedAccounts = state.user.accounts?.map((acc) => {
            if (acc._id === state.activeAccount?._id) {
              return { ...acc, hasSeenInformation: action.payload.hasSeenInformation };
            }
            return acc;
          });

          const updatedUser = { ...state.user, accounts: updatedAccounts };
          state.user = updatedUser;

          saveStateToLocalStorage(authConstants.USER, updatedUser);
        }
      }
    });
    builder.addCase(updateHasSeenInformationAccount.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { setAccount, setChangeOfActiveAccount, setHasSeenDisclaimer, toggleSideBarCollapse, setSideBarCollapseState, clearAuthState } = authSlice.actions;

export default authSlice.reducer;
