import { IpAuditStepKey, IpAuditStepsInfoMap } from './types';
import { SystemSecurity } from './components/Steps/SystemSecurity/SystemSecurity';
import { TradeSecretPolicy } from './components/Steps/TradeSecretPolcy/TradeSecretPolicy';
import { LlmPolicy } from './components/Steps/LlmPolicy/LlmPolicy';
import { Overview } from './components/Steps/Overview/Overview';
import { HrProcess } from './components/Steps/HrProcess/HrProcess';
import { Contracts } from './components/Steps/Contracts/Contracts';
import { KeyAssetsFromSystem } from './components/Steps/KeyAssestsFromSystem/KeyAssetsFromSystem';

interface IpAuditStepsDataArgs {
  hide?: IpAuditStepKey[];
}

export const createIpAuditStepsData = (args?: IpAuditStepsDataArgs): IpAuditStepsInfoMap => {
  const { hide = [] } = args || {};
  return {
    [IpAuditStepKey.OVERVIEW]: {
      name: 'IP_AUDIT.IP_AUDIT_STEPS.OVERVIEW.NAME',
      component: <Overview />,
    },
    ...(!hide.includes(IpAuditStepKey.TRADE_SECRET_POLICY) && {
      [IpAuditStepKey.TRADE_SECRET_POLICY]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.TRADE_SECRET_POLICY.NAME',
        component: <TradeSecretPolicy />,
      },
    }),
    ...(!hide.includes(IpAuditStepKey.SYSTEM_SECURITY) && {
      [IpAuditStepKey.SYSTEM_SECURITY]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.NAME',
        component: <SystemSecurity />,
      },
    }),
    ...(!hide.includes(IpAuditStepKey.HR_PROCESS) && {
      [IpAuditStepKey.HR_PROCESS]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.HR_PROCESS.NAME',
        component: <HrProcess />,
      },
    }),
    ...(!hide.includes(IpAuditStepKey.LLM_POLICY) && {
      [IpAuditStepKey.LLM_POLICY]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.LLM_POLICY.NAME',
        component: <LlmPolicy />,
      },
    }),
    ...(!hide.includes(IpAuditStepKey.CONTRACTS) && {
      [IpAuditStepKey.CONTRACTS]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.CONTRACTS.NAME',
        component: <Contracts />,
      },
    }),
    ...(!hide.includes(IpAuditStepKey.KEY_ASSETS_SYSTEMS) && {
      [IpAuditStepKey.KEY_ASSETS_SYSTEMS]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.NAME',
        component: <KeyAssetsFromSystem />,
      },
    }),
    ...(!hide.includes(IpAuditStepKey.KEY_ASSETS_PATENT_X_RAY) && {
      [IpAuditStepKey.KEY_ASSETS_PATENT_X_RAY]: {
        name: 'IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_PATENT_X_RAY.NAME',
        component: null,
      },
    }),
  };
};
