/**
 * Checks if any user in the recipients array is missing acknowledgement.
 * @param {Array} recipients - An array of recipient objects.
 * @returns {boolean} - Returns true if at least one recipient has not acknowledged, false otherwise.
 */
export const isUserMissingAcknowledgement = (recipients) => {
  if (!recipients.length) return false;
  return recipients.some((recipient) => recipient.acknowledged === false);
};

/**
 * Calculates the count of recipients who have acknowledged the shared asset.
 * @param {Array} sharedWith - An array of recipient objects.
 * @returns {number} - The count of recipients who have acknowledged the shared asset.
 */
export const acknowledgedCount = (sharedWith) => {
  return sharedWith.reduce((count, recipient) => (recipient.acknowledged ? count + 1 : count), 0);
};

/**
 * Extracts asset acknowledgment information from an array of recipient objects.
 * @param {Array} sharedWith - An array of recipient objects.
 * @returns {Array} - An array of objects with asset, acknowledged, and acknowledgedOn properties.
 */
export const extractAssetAcknowledgment = (sharedWith) => {
  return sharedWith.map((recipient) => ({
    asset: recipient.asset,
    acknowledged: recipient.acknowledged,
    acknowledgedOn: recipient.acknowledgedOn,
  }));
};
