import { TangiSvgIcon } from '_components/TangiLibrary/TangiSvgIcon/TangiSvgIcon';
import { TangiCheckbox } from '_components/TangiLibrary';
import { CheckboxWithIconCard } from './styles';
import { isSvgComponentValid } from './utils';

interface Props<T extends string> {
  systemOption: T;
  svgComponent: string;
  isSelected: boolean;
  toggleSelection: (option: T) => void;
  isDisable?: boolean;
  id?: string;
}

export const SecurityCard = <T extends string>({ id, systemOption, svgComponent, isSelected, toggleSelection, isDisable }: Props<T>) => {
  return (
    <CheckboxWithIconCard onClick={() => !isDisable && toggleSelection(systemOption)}>
      <TangiCheckbox type="checkbox" data-cy={`checkbox-${id}`} checked={isSelected} readOnly name="" disabled={isDisable} />
      {isSvgComponentValid(svgComponent) && <TangiSvgIcon component={svgComponent} color="" />}
    </CheckboxWithIconCard>
  );
};
