/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';
import clientAdapter from 'redux-toolkit/adapters/clientAdapter';
import usersAdapter from 'redux-toolkit/adapters/usersAdapter';
import {
  UpdateRequiredCoursesParams,
  JoinDiscoveryEnquiryParams,
  ClientRequestParams,
  UploadFilesParams,
  DeletePolicyParams,
  ClientLanguage,
  UpdateIpAuditParams,
  OptionalIpAuditData,
  PolicyType,
  UpdateIsDeclaredNoFileParams,
} from 'utils/types/client/client';
import { GetUsersParams } from 'utils/types/users/users';
import { authConstants } from '_constants';
import { setAccount } from 'redux-toolkit/slices/authSlice';
import { downloadFile } from 'utils/downloadFile';
import { Account } from 'utils/types/account/account';
import { getLawyerClient } from './lawFirmThunks';
import { IRoles } from 'utils/roles';
import { lawFirmActions } from 'redux-toolkit/slices/lawfirmSlice';
import { LANGUAGE } from 'translations/enums';
import { RootState } from '_helpers';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { IpAuditData } from '_components/IpAudit/types';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';

export const getAgreementVersions = createAsyncThunk('/client/getAgreementVersions', async ({ client }: any) => {
  const res: any = await clientAdapter.getAgreementVersions({ client });
  return res ?? null;
});

export const getClientById = createAsyncThunk('/client/getClientById', async (clientId: string) => {
  const res: any = await clientAdapter.getClientById(clientId);
  return res ?? null;
});

export const updateRequiredCourses = createAsyncThunk('/client/updateRequiredCourses', async ({ clientId, updatedCourses }: UpdateRequiredCoursesParams) => {
  const res: any = await clientAdapter.updateRequiredCourses({ clientId, updatedCourses });
  return res ?? null;
});

export const createAgreementVersion = createAsyncThunk('/client/createAgreementVersion', async ({ agreement, index }: any) => {
  const res: any = await clientAdapter.createAgreementVersion(agreement);
  return res ? { res, index } : null;
});

export const getUsers = createAsyncThunk('/client/getUsers', async ({ userRoleName, client, pagination = {}, filter = {}, type }: GetUsersParams) => {
  const res: any = await usersAdapter.getUsers({ userRoleName, client, pagination, filter });
  return res ? { res, type } : null;
});

export const joinDiscoveryEnquiry = createAsyncThunk('/client/joinDiscovery', async ({ name, email, clientName, role, source = 'Undefined Source' }: JoinDiscoveryEnquiryParams, { dispatch }) => {
  const res: any = await clientAdapter.joinDiscoveryEnquiry({ name, email, clientName, role });
  dispatch(mixpanelEvents.requestAccess({ source }));

  return res ?? null;
});

export const getInsights = createAsyncThunk('/client/getInsights', async ({ clientId }: ClientRequestParams) => {
  const res: any = await clientAdapter.getInsights({ clientId });
  return res ?? null;
});

export const uploadFiles = createAsyncThunk('/client/uploadFiles', async ({ clientId, policyFiles, type, isLawyer, accountId, entityType }: UploadFilesParams, { dispatch }) => {
  const res: any = await clientAdapter.uploadFiles({ clientId, policyFiles, type, entityType });

  if (isLawyer) {
    await dispatch(getLawyerClient({ accountId, clientId }));
  } else {
    // Change active account and user in localstorage
    const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');

    const updatedPolicyFiles = [res.data[0]];
    activeAccount.client.policyFiles = updatedPolicyFiles;
    updateActiveAccountAndUserInLocalStorage(activeAccount);

    // Change activeAccount in redux
    dispatch(setAccount(activeAccount));
  }
  return { data: res.data?.[0] ?? null, isLawyer, uploadType: type };
});

export const uploadLlmFiles = createAsyncThunk('/client/uploadLlmFiles', async ({ clientId, policyFiles, type, isLawyer, accountId, entityType }: UploadFilesParams, { dispatch }) => {
  const res: any = await clientAdapter.uploadFiles({ clientId, policyFiles, type, entityType });

  if (isLawyer) {
    await dispatch(getLawyerClient({ accountId, clientId }));
  } else {
    // Change active account and user in localstorage
    const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');

    const updatedLlmFiles = [res.data[0]];
    activeAccount.client.llmFiles = updatedLlmFiles;

    updateActiveAccountAndUserInLocalStorage(activeAccount);

    // Change activeAccount in redux
    dispatch(setAccount(activeAccount));
  }
  return { data: res.data?.[0] ?? null, isLawyer, uploadType: type };
});

export const downloadPolicyTemplate = createAsyncThunk('/client/downloadPolicyTemplate', async (fileName: string, { dispatch }) => {
  const res: any = await clientAdapter.downloadPolicyTemplate(fileName);
  // Mix Panel Event: downloadPolicyTemplate
  dispatch(mixpanelEvents.downloadPolicyTemplate());

  if (res?.file && res?.mimeType) {
    const extractedFileName = fileName || 'downloadedFile';
    await downloadFile(res.mimeType, res.file, extractedFileName);
  }
  return res ?? null;
});

export const downloadPolicy = createAsyncThunk('/client/downloadPolicy', async ({ fileName, type }: { fileName: string; type: PolicyType }, { dispatch }) => {
  // TODO: add type of responce

  const res: any = await clientAdapter.downloadPolicy(fileName, type);
  // Mix Panel Event: delete policy
  dispatch(mixpanelEvents.downloadPolicy());

  if (res) {
    const fileName = res.file.split('/').pop();
    await downloadFile(res.mimeType, res.base64, fileName);
  }
  return res ?? null;
});

export const deletePolicy = createAsyncThunk('/client/deletePolicy', async ({ isLawyer, clientId, accountId, type, isDeleteBeforeUpload }: DeletePolicyParams, { dispatch }) => {
  const res: any = await clientAdapter.deletePolicy(clientId, type);

  if (isLawyer) {
    await dispatch(getLawyerClient({ accountId, clientId }));
  } else {
    // Change active account and user in localstorage
    const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');

    activeAccount.client.policyFiles = [];

    updateActiveAccountAndUserInLocalStorage(activeAccount);

    // Change activeAccount in redux
    dispatch(setAccount(activeAccount));
  }
  const payload = { data: res ?? null, isDeleteBeforeUpload, isLawyer };
  return payload;
});

export const deleteLlmPolicy = createAsyncThunk('/client/deleteLlmPolicy', async ({ isLawyer, clientId, accountId, type, isDeleteBeforeUpload }: DeletePolicyParams, { dispatch }) => {
  const res: any = await clientAdapter.deletePolicy(clientId, type);

  if (isLawyer) {
    await dispatch(getLawyerClient({ accountId, clientId }));
  } else {
    // Change active account and user in localstorage
    const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');

    activeAccount.client.llmFiles = [];

    updateActiveAccountAndUserInLocalStorage(activeAccount);

    // Change activeAccount in redux
    dispatch(setAccount(activeAccount));
  }
  const payload = { data: res ?? null, isDeleteBeforeUpload, isLawyer };
  return payload;
});

export const updateClientLanguage = createAsyncThunk('client/updateClientLanguage', async (data: ClientLanguage, { getState, dispatch }) => {
  const currentState: RootState = getState();
  const response: any = await clientAdapter.updateClientLanguage(data);
  const selectedLanguage = response?.result?.language || LANGUAGE.ENGLISH;
  const activeAccount = JSON.parse(localStorage?.getItem(authConstants.ACTIVATE_ACCOUNT) || 'null');
  if (activeAccount && activeAccount.client) {
    activeAccount.client.language = selectedLanguage;
    localStorage.setItem('activeAccount', JSON.stringify(activeAccount));
    dispatch(setAccount(activeAccount));
  }

  const user = JSON.parse(localStorage.getItem('user') || 'null');

  if (user) {
    const accountIndex = user?.accounts.findIndex((account: any) => account.client?._id === activeAccount?.client?._id);
    if (accountIndex !== -1 && user && user.accounts[accountIndex].client) {
      user.accounts[accountIndex].client.language = selectedLanguage;
      localStorage.setItem('user', JSON.stringify(user));
    }
  }

  if (currentState.authentication.Role === IRoles.LAWYER) {
    const updatedActiveClient = { ...currentState.lawfirm.activeClient, language: response?.result?.language || LANGUAGE.ENGLISH };
    dispatch(lawFirmActions.setActiveClient(updatedActiveClient));
  }

  return response;
});

export const updateClientIsDeclaredNoTradeSecretPolicy = createAsyncThunk(
  '/client/updateClientIsDeclaredNoTradeSecretPolicy',
  async ({ clientId, isLawyer, accountId, isDeclaredNoFile, clientName: name }: UpdateIsDeclaredNoFileParams, { dispatch }) => {
    const res: any = await clientAdapter.updateClient({
      clientId,
      body: {
        policyFiles: [{ isDeclaredNoFile }],
        name,
      },
    });

    if (isLawyer) {
      await dispatch(getLawyerClient({ accountId, clientId }));
    } else {
      // Change active account and user in localstorage
      const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);
      const updatedPolicyFiles = [{ isDeclaredNoFile }];
      activeAccount.client.policyFiles = updatedPolicyFiles;

      updateActiveAccountAndUserInLocalStorage(activeAccount);

      // Change activeAccount in redux
      dispatch(setAccount(activeAccount));
    }
    return { data: res.data?.[0] ?? null, isLawyer };
  },
);

export const updateClientIsDeclaredNoLlmPolicy = createAsyncThunk(
  '/client/updateClientIsDeclaredNoLlmPolicy',
  async ({ clientId, isLawyer, accountId, isDeclaredNoFile, clientName: name }: UpdateIsDeclaredNoFileParams, { dispatch }) => {
    const res: any = await clientAdapter.updateClient({
      clientId,
      body: {
        llmFiles: [{ isDeclaredNoFile }],
        name,
      },
    });

    if (isLawyer) {
      await dispatch(getLawyerClient({ accountId, clientId }));
    } else {
      // Change active account and user in localstorage
      const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);
      const updatedLlmFiles = [{ isDeclaredNoFile }];
      activeAccount.client.llmFiles = updatedLlmFiles;

      updateActiveAccountAndUserInLocalStorage(activeAccount);

      // Change activeAccount in redux
      dispatch(setAccount(activeAccount));
    }
    return { data: res.data?.[0] ?? null, isLawyer };
  },
);

export const updateKeyAssetsSystemNoneSelectionIpAudit = createAsyncThunk<{ ipAuditData: OptionalIpAuditData; isLawyer: boolean }, UpdateIpAuditParams>(
  'client/updateKeyAssetsSystemNoneSelectionIpAudit',
  async ({ clientId, accountId, isLawyer, ipAudit }: UpdateIpAuditParams, { dispatch }) => {
    const response = await clientAdapter.updateClientIpAudit(clientId, ipAudit);

    if (isLawyer) {
      await dispatch(getLawyerClient({ accountId, clientId }));
    } else {
      const updatedAccount = updateIpAuditInActiveAccount(response?.ipAuditData);
      dispatch(setAccount(updatedAccount));
    }

    const ipAuditData = response?.ipAuditData ?? null;

    return { ipAuditData: ipAuditData, isLawyer };
  },
);

export const updateHrProcessIpAudit = createAsyncThunk<{ ipAuditData: OptionalIpAuditData; isLawyer: boolean }, UpdateIpAuditParams>(
  'client/updateHrProcessIpAudit',
  async ({ clientId, accountId, isLawyer, ipAudit }: UpdateIpAuditParams, { dispatch }) => {
    const response = await clientAdapter.updateClientIpAudit(clientId, ipAudit);

    if (isLawyer) {
      await dispatch(getLawyerClient({ accountId, clientId }));
    } else {
      const updatedAccount = updateIpAuditInActiveAccount(response?.ipAuditData);
      dispatch(setAccount(updatedAccount));
    }

    const ipAuditData = response?.ipAuditData ?? null;

    return { ipAuditData: ipAuditData, isLawyer };
  },
);

export const updateSystemSecurityIpAudit = createAsyncThunk<{ ipAuditData: OptionalIpAuditData; isLawyer: boolean }, UpdateIpAuditParams>(
  'client/updateSystemSecurityIpAudit',
  async ({ clientId, accountId, isLawyer, ipAudit }: UpdateIpAuditParams, { dispatch }) => {
    const response = await clientAdapter.updateClientIpAudit(clientId, ipAudit);

    if (isLawyer) {
      await dispatch(getLawyerClient({ accountId, clientId }));
    } else {
      const updatedAccount = updateIpAuditInActiveAccount(response?.ipAuditData);
      dispatch(setAccount(updatedAccount));
    }

    const ipAuditData = response?.ipAuditData ?? null;

    return { ipAuditData: ipAuditData, isLawyer };
  },
);

export const updateContractsIpAudit = createAsyncThunk<{ ipAuditData: OptionalIpAuditData; isLawyer: boolean }, UpdateIpAuditParams>(
  'client/updateContractsIpAudit',
  async ({ clientId, accountId, isLawyer, ipAudit }: UpdateIpAuditParams, { dispatch }) => {
    const response = await clientAdapter.updateClientIpAudit(clientId, ipAudit);

    if (isLawyer) {
      await dispatch(getLawyerClient({ accountId, clientId }));
    } else {
      const updatedAccount = updateIpAuditInActiveAccount(response?.ipAuditData);
      dispatch(setAccount(updatedAccount));
    }

    const ipAuditData = response?.ipAuditData ?? null;

    return { ipAuditData: ipAuditData, isLawyer };
  },
);

const updateIpAuditInActiveAccount = (ipAuditData: IpAuditData) => {
  const activeAccount = loadStateFromLocalStorage(authConstants.ACTIVATE_ACCOUNT);

  activeAccount.client.ipAuditData = ipAuditData;

  updateActiveAccountAndUserInLocalStorage(activeAccount);
  return activeAccount;
};

const updateActiveAccountAndUserInLocalStorage = (activeAccount: Account) => {
  const user = loadStateFromLocalStorage(authConstants.USER);
  const accountIndex = user?.accounts?.findIndex((account: Account) => account._id === activeAccount._id);

  accountIndex === -1 ? user?.accounts.push(activeAccount) : (user.accounts[accountIndex] = activeAccount);

  saveStateToLocalStorage(authConstants.ACTIVATE_ACCOUNT, activeAccount);
  saveStateToLocalStorage(authConstants.USER, user);
};
