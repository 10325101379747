export enum FormFieldNames {
  EMAIL = 'email',
  ROLE = 'role',
  DISPLAY_NAME = 'displayName',
  TITLE = 'title',
  PHONE = 'phone',
  EMPLOYEE_ID = 'employeeId',
  COUNTRY = 'country',
  LOCATION = 'location',
  DATE_OF_JOINING = 'dateOfJoining',
  DATE_OF_LEAVING = 'dateOfLeaving',
  OTHERS_1 = 'others1',
  OTHERS_2 = 'others2',
  EXTERNAL_USER = 'externalUser',
  LINKEDIN = 'linkedin',
  DEPARTMENTS = 'departments',
  BUSINESS_UNITS = 'businessUnits',
}

export enum TranslationKeys {
  STATUS = 'PEOPLE.INVITE_FORM.INPUT_LABEL.STATUS',
  ACTIVE = 'PEOPLE.INVITE_FORM.ACTIVE_TOGGLE',
  ACTIVE_TOOLTIP = 'PEOPLE.INVITE_FORM.ACTIVE_TOOLTIP',
  NONACTIVE_TOOLTIP = 'PEOPLE.INVITE_FORM.NONACTIVE_TOOLTIP',
  EMAIL_PLACEHOLDER = 'PEOPLE.INVITE_FORM.PLACEHOLDER.EMAIL_PLACEHOLDER',
  EMAIL = 'PEOPLE.INVITE_FORM.INPUT_LABEL.EMAIL',
  VALID_EMAIL = 'PEOPLE.INVITE_FORM.VALIDATION_MESSAGE.VALID_EMAIL',
  NEVER_SHARE_EMAIL = 'PEOPLE.INVITE_FORM.VALIDATION_MESSAGE.NEVER_SHARE_EMAIL',
  ROLE = 'PEOPLE.INVITE_FORM.SELECTOR.ROLE',
  DISPLAY_NAME = 'PEOPLE.INVITE_FORM.INPUT_LABEL.DISPLAY_NAME',
  TITLE = 'PEOPLE.INVITE_FORM.INPUT_LABEL.TITLE',
  PHONE = 'PEOPLE.INVITE_FORM.INPUT_LABEL.PHONE',
  LINKEDIN_PROFILE = 'PEOPLE.INVITE_FORM.INPUT_LABEL.LINKEDIN_PROFILE',
  VALID_LINKEDIN = 'PEOPLE.INVITE_FORM.VALIDATION_MESSAGE.VALID_LINKEDIN',
  EMPLOYEE_ID = 'PEOPLE.INVITE_FORM.INPUT_LABEL.EMPLOYEE_ID',
  COUNTRY = 'PEOPLE.INVITE_FORM.INPUT_LABEL.COUNTRY',
  LOCATION = 'PEOPLE.INVITE_FORM.INPUT_LABEL.LOCATION',
  DEPARTMENTS = 'PEOPLE.INVITE_FORM.SELECTOR.DEPARTMENTS',
  BUSINESS_UNITS = 'PEOPLE.INVITE_FORM.SELECTOR.BUSINESS_UNITS',
  HIRE_DATE = 'PEOPLE.INVITE_FORM.INPUT_LABEL.HIRE_DATE',
  EXIT_DATE = 'PEOPLE.INVITE_FORM.INPUT_LABEL.EXIT_DATE',
  ADDITIONAL_INFORMATION = 'PEOPLE.INVITE_FORM.PLACEHOLDER.ENTER_ADDITIONAL_INFORMATION',
  OTHERS_1 = 'PEOPLE.INVITE_FORM.INPUT_LABEL.OTHERS_1',
  OTHERS_2 = 'PEOPLE.INVITE_FORM.INPUT_LABEL.OTHERS_2',
  AGREEMENTS = 'PEOPLE.INVITE_FORM.TITLE.AGREEMENTS',
  SELECT_A_ROLE = 'PEOPLE.INVITE_FORM.SELECT_A_ROLE',
  OKTA_MESSAGE = 'PEOPLE.INVITE_FORM.CHECKBOX_OKTA_MESSAGE',
  UPDATE_USER = 'GENERAL.BUTTONS_TEXT.UPDATE_USER',
  ADD_USER = 'GENERAL.BUTTONS_TEXT.ADD_USER',
  MANAGERS_NOTIFICATION = 'PEOPLE.INVITE_FORM.MANAGERS_NOTIFICATION',
}
