import styled from 'styled-components';

import { FlexContainer, StyledCard } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const ContainerCard = styled(StyledCard)`
  border-radius: ${SPACING[2]};
  padding: ${SPACING[4]};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  position: -webkit-sticky; // Safari
  top: 80px;
  height: 60vh;
`;

export const LoadingContainerCard = styled(ContainerCard)`
  justify-content: center;
  align-items: center;
  gap: ${SPACING[4]};
`;

export const HeaderCard = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  flex-direction: column;
`;

export const BodyCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
  flex-grow: 1;
  overflow: auto;
`;
export const BodyCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterCard = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING[1]};
`;

export const TextContainerFooter = styled(FlexContainer)`
  justify-content: flex-end;
  padding: ${SPACING[1]} ${SPACING[1]} ${SPACING[0]};
  align-items: center;
  margin-bottom: -${SPACING[1]};
  gap: ${SPACING[2.5]};
  justify-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PointerDiv = styled.div`
  cursor: pointer;
`;
