export interface ErrorMessageConfig {
  boldText: string;
  text: string;
  testId: string;
  boldTextParameters?: object;
  textParameters?: object;
}

export enum LOADING_TRANSLATION_KEY {
  PATENT = 'DISCOVERY_LOBBY.PATENT_NOTIFICATION.WE_ARE_FINDING',
  CONTRACT = 'DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.WE_ARE_FINDING',
  INVENTION_DISCLOSURE = 'DISCOVERY_LOBBY.INVENTION_DISCLOSURE_NOTIFICATION.ANALYSIS_IN_PROGRESS',
  CONTINUATION_ANALYSIS = 'DISCOVERY_LOBBY.CONTINUATION_ANALYSIS_NOTIFICATION.WE_ARE_FINDING',
}
