import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers/store';

import './index.css';
import { App } from './App';
import { Auth } from './_hocs/Cognito/Auth';

import reportWebVitals from './reportWebVitals';
import I18nConfig from './i18n';
import { GlobalStyle } from 'utils/theme';

render(
  <Provider store={store}>
    <I18nConfig />
    <Auth>
      <App />
    </Auth>
    <GlobalStyle />
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
