import React, { useEffect, PropsWithChildren, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '_helpers';
import { contractActions } from 'redux-toolkit/slices/contractSlice';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { getContractResults } from 'redux-toolkit/thunks/contractThunks';
import { getContinuationAnalysisResults, getPatentResults } from 'redux-toolkit/thunks/patentThunks';
import { INTERVAL_IN_MILLISECONDS } from 'utils/enums';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import useXRayIntervalCheck from '_hooks/useXRayIntervalCheck';
import { IDFRequestInfo } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { PatentRequestInfo } from 'utils/types/patent/patent';
import { ContractRequestInfo } from 'utils/types/contract/contract';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { getInventionDisclosureResults } from 'redux-toolkit/thunks/inventionDisclosureThunks';

export const XRayResultsWrapper = ({ children }: PropsWithChildren<NonNullable<unknown>>) => {
  const dispatch = useDispatch();

  const contractRequestInfo: ContractRequestInfo | null = useSelector((state: RootState) => state.contract.contractRequestInfo);
  const patentRequestInfo: PatentRequestInfo | null = useSelector((state: RootState) => state.patent.patentRequestInfo);
  const idfRequestInfo: IDFRequestInfo | null = useSelector((state: RootState) => state.inventionDisclosure.idfRequestInfo);
  const continuationResultId: string = useSelector((state: RootState) => state.patent.continuationResultId);

  const patentData = useSelector((state: RootState) => state.patent.patentData);

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);

  const contractJobStatus = useXRayJobStatus(
    (state) => state.contract.contractLoaders,
    (state) => state.contract.contractData,
    (state) => state.contract.contractRequestInfo,
    (state) => state.contract.isCanceled,
  );

  const idfJobStatus = useXRayJobStatus(
    (state: RootState) => state.inventionDisclosure.idfLoaders,
    (state: RootState) => state.inventionDisclosure.inventionDisclosureData,
    (state: RootState) => state.inventionDisclosure.idfRequestInfo,
  );

  const continuationJobStatus = useXRayJobStatus(
    (state: RootState) => state.patent.continuationLoaders,
    (state: RootState) => state.patent.continuationData.response,
  );

  const clientId = useMemo(() => {
    if (activeAccount?.client?._id) {
      return activeAccount?.client?._id;
    }
    if (activeClient?.id) {
      return activeClient?.id;
    }
  }, [activeAccount?.client?._id, activeClient]);

  const isPatentFinished = useMemo(() => {
    const { status_finder, status_mapper } = patentData;

    return (
      (status_finder === XRAY_JOB_STATUS.FAILED && status_mapper === XRAY_JOB_STATUS.FAILED) ||
      (status_finder === XRAY_JOB_STATUS.SUCCEEDED && status_mapper === XRAY_JOB_STATUS.FAILED) ||
      (status_finder === XRAY_JOB_STATUS.SUCCEEDED && status_mapper === XRAY_JOB_STATUS.SUCCEEDED)
    );
  }, [patentData.status_finder, patentData.status_mapper]);

  const isContractFinished = useMemo(() => {
    return contractJobStatus === XRAY_JOB_STATUS.FAILED || contractJobStatus === XRAY_JOB_STATUS.SUCCEEDED;
  }, [contractJobStatus]);

  const isIDFFinished = useMemo(() => idfJobStatus === XRAY_JOB_STATUS.FAILED || idfJobStatus === XRAY_JOB_STATUS.SUCCEEDED, [idfJobStatus]);

  const isContinuationFinished = useMemo(() => continuationJobStatus === XRAY_JOB_STATUS.SUCCEEDED || continuationJobStatus === XRAY_JOB_STATUS.FAILED, [continuationJobStatus]);

  const checkPatentResults = useCallback(() => {
    if (clientId !== patentRequestInfo?.clientId) {
      dispatch(patentActions.setPatentDataInitState());
    }
    if (patentRequestInfo?.patentResultId && clientId === patentRequestInfo?.clientId) {
      dispatch(getPatentResults({ patentResultId: patentRequestInfo?.patentResultId, clientId }));
    }
  }, [patentRequestInfo, clientId, dispatch]);

  const checkContractResults = useCallback(() => {
    if (clientId !== contractRequestInfo?.clientId) {
      dispatch(contractActions.setContractDataInitState());
    }
    if (contractRequestInfo?.contractResultId && clientId === contractRequestInfo?.clientId) {
      dispatch(getContractResults({ contractResultId: contractRequestInfo?.contractResultId, clientId }));
    }
  }, [contractRequestInfo, clientId, dispatch]);

  const checkIDFResults = useCallback(() => {
    if (clientId !== idfRequestInfo?.clientId) {
      dispatch(inventionDisclosureActions.resetStateExceptRequestInfo());
    }
    if (idfRequestInfo?.inventionDisclosureSearchId && clientId === idfRequestInfo?.clientId) {
      dispatch(getInventionDisclosureResults(idfRequestInfo?.inventionDisclosureSearchId));
    }
  }, [idfRequestInfo, clientId, dispatch]);

  const continuationAnalysisResults = useCallback(() => {
    if (continuationResultId) {
      dispatch(getContinuationAnalysisResults({ continuationResultId: continuationResultId }));
    }
  }, [dispatch, continuationResultId]);

  // IMPORTANT: The following useEffect is used to check the status of the patent, contract, and IDF results when app loads
  useEffect(() => {
    checkPatentResults();
    checkContractResults();
    checkIDFResults();
  }, [activeClient?.id]);

  // Initiate intervals to check patent, contract and idf results the process is started.
  useXRayIntervalCheck(patentRequestInfo?.patentResultId, isPatentFinished, checkPatentResults, INTERVAL_IN_MILLISECONDS.ThirtySeconds);
  useXRayIntervalCheck(contractRequestInfo?.contractResultId, isContractFinished, checkContractResults, INTERVAL_IN_MILLISECONDS.TwoAndHalfSeconds);
  useXRayIntervalCheck(idfRequestInfo?.inventionDisclosureSearchId, isIDFFinished, checkIDFResults, INTERVAL_IN_MILLISECONDS.ThirtySeconds);
  useXRayIntervalCheck(continuationResultId, isContinuationFinished, continuationAnalysisResults, INTERVAL_IN_MILLISECONDS.TenSeconds);

  return <React.Fragment>{children}</React.Fragment>;
};
