export enum MixpanelEventNames {
  Login = 'Login',
  InviteAccount = 'Invite Account',
  CreateAsset = 'Create Asset',
  Logout = 'Logout',
  ExtractTradeSecret = 'Extract Trade Secret',
  XraySearch = 'Patent Search',
  DeleteAsset = 'Delete Asset',
  EditAsset = 'Edit Asset',
  AcknowledgementAsset = 'Asset Acknowledgement',
  CloseAsset = 'Closed Asset',
  PrivilegeLogDownload = 'Privilege log download',
  ShareAsset = 'Notify Asset',
  FirstTimeLogin = 'First Time Login',
  ContractXray = 'Contract Xray',
  CustomEvent = 'Custom Event',
  AssetFilePreview = 'Asset File Preview',
  QuizCompleted = 'Quiz Completed',
  QuizStarted = 'Quiz Started',
  LogoutActive = 'Active Log Out',
  LogoutAuto = 'Auto Log Out',
  DownloadPolicy = 'Policy Downloaded',
  InventionSearch = 'Invention Disclosure Search',
  ContinuationSearch = 'Continuation Analysis Search',
  RequestAccess = 'Request Access',
  EnterPredictedPage = 'Enter Predicted Portfolio Page',
  EnterCompetitivePage = 'Enter Competitive Page',
  OpenIPAuditModal = 'Open IP Audit',
  CompleteIPAuditStep = 'Complete IP Audit Step',
  CompleteIPAudit = 'Complete IP Audit',
  DownloadTradeSecretPolicyTemplate = 'Download Trade Secret Policy Template',
  DownloadLLMPolicyTemplate = 'Download LLM Policy Template',
  UploadTradeSecretPolicy = 'Upload Trade Secret Policy',
  DownloadTradeSecretPolicy = 'Download Trade Secret Policy',
  DeleteTradeSecretPolicy = 'Delete Trade Secret Policy',
  UploadLLMPolicy = 'Upload LLM Policy',
  DownloadLLMPolicy = 'Download LLM Policy',
  DeleteLLMPolicy = 'Delete LLM Policy',
  SelectSystemSecurity = 'Select System Security',
  SelectHRProcess = 'Select HR Process',
  UploadContractFile = 'Upload Contract File',
  AnalyzeContractFile = 'Analyze Contract File',
  CancelAnalyzeContractFile = 'Cancel Analyze Contract File',
  DownloadPolicyTemplate = 'Download Policy Template',
}

export enum EventSource {
  FromSystem = 'From System',
  FromEmail = 'From Email',
}

export enum LogoutType {
  Automatic = 'automatic',
  Active = 'active',
}

export interface CommonEventProps {
  clientName: string;
  userEmail: string;
  role?: string;
  clientId?: string;
  accountId?: string;
  assetId?: string;
  selectedAssets?: string[];
  createdFrom?: string;
  createdFromTool?: string;
  isMappingTool?: boolean;
  extraData?: unknown;
  patentModel?: string;
  typeOfAction?: string;
}
export interface InviteAccountProps extends CommonEventProps {
  accountData: { id: string; email?: string; role?: string };
}
export interface ExtractTradeSecretProps extends CommonEventProps {
  clientId: string;
  patentModel: string;
  typeOfAction: string;
  extraData?: unknown;
}
export interface XraySearchProps extends CommonEventProps {
  clientId: string;
  extraData?: unknown;
}
export interface AcknowledgementAssetProps extends CommonEventProps {
  accountId: string;
  selectedAssets: string[];
}
