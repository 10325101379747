import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { AUDIT_TYPE, LeanAccount, LeanClient, SharePointSubAuditType } from 'utils/types/activityLog/asset/types';
import { ENTITY_TYPES } from 'utils/enums';
import { getDisplayName } from '../utils';
import { SharePointStatusEnums } from 'utils/types/assets/assets';

interface ActivityTypeLabelProps {
  entityType: string;
  auditType: string;
  initiator: {
    account?: LeanAccount;
    client?: LeanClient;
  };
}

interface ActivityLabelMapping {
  [key: string]: { [key: string]: string };
}

const labelMappingText: ActivityLabelMapping = {
  [AUDIT_TYPE.CREATE]: {
    [ENTITY_TYPES.ASSET]: 'ACTIVITY_LOG.ASSET.ASSET_CREATED',
  },
  [AUDIT_TYPE.EDIT]: {
    [ENTITY_TYPES.ASSET]: 'ACTIVITY_LOG.ASSET.ASSET_EDITED',
  },
  [AUDIT_TYPE.SHARE_POINT]: {
    [AUDIT_TYPE.EDIT]: 'ACTIVITY_LOG.ASSET.ASSET_EDITED',
    [SharePointSubAuditType.DELETE]: 'ACTIVITY_LOG.ASSET.ASSET_REMOVED',
    [SharePointSubAuditType.CONNECTED]: 'ACTIVITY_LOG.ASSET.ASSET_CONNECTED',
    [SharePointSubAuditType.DISCONNECTED]: 'ACTIVITY_LOG.ASSET.ASSET_DISCONNECTED',
    [SharePointStatusEnums.MOVED]: 'ACTIVITY_LOG.ASSET.ASSET_MOVED',
  },
};

export const ActivityTypeLabel = ({ entityType, auditType, initiator }: ActivityTypeLabelProps) => {
  const { t } = useTranslation();
  const { account, client } = initiator;

  const actionText = labelMappingText[auditType][entityType];
  const displayText = getDisplayName(account, client);

  return (
    <div>
      <TangiTypography type="subheading">{t(actionText)}</TangiTypography>
      <TangiTypography type="body" color={NEUTRAL_SHADES[900]}>
        {t('ACTIVITY_LOG.BY', { NAME: displayText })}
      </TangiTypography>
    </div>
  );
};
