import React from 'react';
import { Form } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { Controller } from 'react-hook-form';

import { NEUTRAL_SHADES } from '../../../utils/theme';
import { Star } from '../../Star';

export const TangiCreateableSelector = ({ error, control, name, options, label, required, isLoading, isDisabled, onCreateOption, ...props }) => {
  return (
    // eslint-disable-next-line react/no-unknown-property
    <div controlId={name}>
      <Form.Label className="text-dark txt-size">{label}</Form.Label>
      {required && <Star />}
      <Controller
        rules={{ required }}
        name={name}
        render={({ field }) => (
          <CreatableSelect
            isClearable
            isDisabled={isDisabled}
            isLoading={isLoading}
            onCreateOption={onCreateOption}
            className="ff-din-DemiBold"
            classNamePrefix="tangi-selector"
            options={options}
            {...field}
            {...props}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: error ? 'red' : '#dcdcdc',
                '&:hover': {
                  borderColor: error ? 'red' : NEUTRAL_SHADES[600],
                },
              }),
            }}
          />
        )}
        control={control}
      />
      <div className="d-block invalid-feedback">{error && error.message}</div>
    </div>
  );
};
