import { apiCall } from './utils/restClient';
import { config } from './../config/config';
import { headers } from './utils/headers';

//TODO: need to implement pagination
function getCourses(page, searchTxt, sorting, limit = 100) {
  var search = searchTxt;
  //   var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'title:asc';
  var url = `${config.apiUrl}${config.apiVersion}/courses`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, search },
  };

  return apiCall(requestOptions);
}

const getQuiz = (quizId) => {
  var url = `${config.apiUrl}${config.apiVersion}/course/quiz/${quizId}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
};

export const courseService = {
  getCourses,
  getQuiz,
};
