import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import i18next from 'i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { SharePoint, SharePointStatusEnums, SharedRecipient } from 'utils/types/assets/assets';
import { SharepointEnum } from 'utils/enums';
import { NEUTRAL_SHADES } from 'utils/theme';
import { InActiveToolTopContainer } from './style';

interface BreadcrumbItem {
  label: React.ReactNode;
}

interface StatusRendererParams {
  siteNameBreadcrumbItem: BreadcrumbItem;
  siteName?: string;
  pathSegments?: string[];
}

interface StatusRenderer {
  ({ siteNameBreadcrumbItem, siteName, pathSegments }: StatusRendererParams): {
    breadcrumbItems: BreadcrumbItem[];
    fullPathString?: string;
  };
}

const EllipsisWrapper = styled.span`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-top: 5px;
`;

const inaccessibleLabel: StatusRenderer = ({ siteNameBreadcrumbItem }) => ({
  breadcrumbItems: [
    siteNameBreadcrumbItem,
    {
      label: (
        <InActiveToolTopContainer>
          <TangiTypography color={NEUTRAL_SHADES[800]}>{i18next.t('ASSET_PAGE.TOOLTIP.INACCESSIBLE')}</TangiTypography>
          <Tippy content={<div>{i18next.t('ASSET_PAGE.TOOLTIP.SHARE_POINT_ACCESS')}</div>} placement="top-start">
            <div>
              <TangiSvgIcon component="info" size="16px" color={NEUTRAL_SHADES[800]} />
            </div>
          </Tippy>
        </InActiveToolTopContainer>
      ),
    },
  ],
});

export const statusRenderers: Record<SharePointStatusEnums, StatusRenderer> = {
  [SharePointStatusEnums.MOVED]: ({ siteNameBreadcrumbItem }) => inaccessibleLabel({ siteNameBreadcrumbItem }),
  [SharePointStatusEnums.NOT_TRACKED]: ({ siteNameBreadcrumbItem }) => inaccessibleLabel({ siteNameBreadcrumbItem }),
  [SharePointStatusEnums.DELETED]: ({ siteNameBreadcrumbItem, pathSegments = [], siteName }) => statusRenderers[SharePointStatusEnums.ACTIVE]({ siteNameBreadcrumbItem, pathSegments, siteName }),
  [SharePointStatusEnums.ACTIVE]: ({ siteNameBreadcrumbItem, pathSegments = [], siteName }) => {
    const fullPathString = [siteName, ...pathSegments.map((segment) => segment)].join(' > ');

    return {
      fullPathString,
      breadcrumbItems: [
        siteNameBreadcrumbItem,
        ...pathSegments.map((segment) => ({
          label: (
            <TangiTypography color={NEUTRAL_SHADES[1100]}>
              <EllipsisWrapper>{segment}</EllipsisWrapper>
            </TangiTypography>
          ),
        })),
      ],
    };
  },
};

// Function to parse the asset path based on its status
export const parseAssetPath = (assetData: SharePoint) => {
  const rootPath = SharepointEnum.RootFolder;
  const pathWithoutRoot = assetData.path === rootPath ? assetData.path?.replace(rootPath, SharepointEnum.Documents) : assetData.path?.replace(`${rootPath}/`, '');

  const siteNameBreadcrumbItem: BreadcrumbItem = {
    label: (
      <TangiTypography color={NEUTRAL_SHADES[1100]}>
        <EllipsisWrapper>{assetData.siteName}</EllipsisWrapper>
      </TangiTypography>
    ),
  };

  let pathSegments = pathWithoutRoot?.split('/');
  if (pathSegments?.length === 1 && pathSegments[0] === "") {
    pathSegments = [SharepointEnum.Documents];
  }

  // default: Active
  const statusRenderer: StatusRenderer = statusRenderers[assetData.status || SharePointStatusEnums.ACTIVE];
  return statusRenderer({ siteNameBreadcrumbItem, pathSegments, siteName: assetData.siteName });
};

export const createTippyContent = (recipients: SharedRecipient[]) => {
  return recipients.map((recipient, index) => {
    const displayName = recipient.fullName || recipient.email;
    return (
      <div key={index}>
        <TangiTypography color={NEUTRAL_SHADES.WHITE} weight="semibold">
          {displayName}
        </TangiTypography>
      </div>
    );
  });
};
