import { TangiTypography } from '_components/TangiLibrary';
import React from 'react';
import styled from 'styled-components';
import { SPACING } from 'utils/theme';

interface CheckboxItemProps {
  id: string;
  label: string;
  isChecked: boolean;
  onCheckboxChange: (isChecked: boolean, id: string) => void;
}

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[1]};
`;
export const CheckboxInput = styled.input`
  margin-right: ${SPACING[2]};
`;

export const CheckboxText = styled.span`
  min-width: 100%;
`;
const CheckboxItem = ({ id, label, isChecked, onCheckboxChange }: CheckboxItemProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(event.target.checked, id);
  };
  return (
    <CheckboxContainer>
      <CheckboxInput data-testid="checkboxExport" type="checkbox" checked={isChecked} onChange={handleChange} />
      <CheckboxText>
        <TangiTypography type="subheading">{label}</TangiTypography>
      </CheckboxText>
    </CheckboxContainer>
  );
};

export default CheckboxItem;
