import Lottie from 'lottie-react';
import { Footer } from '../Footer';
import { LoginHeader } from '../LoginHeader/LoginHeader';
import logo from '../../assets/img/CANOPY-logo.png';
import assets from '../../assets/img/assets.json';
import lock from '../../assets/img/lock.json';
import { RegisterContainer, FormContainer, GifContainer, GifMessage } from './styles';
import { useTranslation } from 'react-i18next';

const RegistrationCard = ({ children, isLockGif = true, differentHigh = false }) => {
  const { t } = useTranslation();

  return (
    <div className="main">
      <LoginHeader />
      <div className="bg-light mt-5 container content-height">
        <RegisterContainer differentHigh={differentHigh}>
          <FormContainer>{children}</FormContainer>
          <GifContainer>
            <img src={logo} className="" width="120" alt="Canopy" />
            <Lottie animationData={isLockGif ? lock : assets} loop={true} />
            {!isLockGif ? (
              <GifMessage dangerouslySetInnerHTML={{ __html: t('LOGIN_PAGE.CARD.TRADE_SECRETS_SECURED') }} />
            ) : (
              <GifMessage dangerouslySetInnerHTML={{ __html: t('LOGIN_PAGE.CARD.KEEPING_TRADE_SECRETS') }} />
            )}
          </GifContainer>
        </RegisterContainer>
      </div>
      <Footer />
    </div>
  );
};

export { RegistrationCard };
