import { useTranslation } from 'react-i18next';

import { TangiNotification, TangiTypography, NOTIFICATION_VARIANTS } from '../../TangiLibrary';
import { TranslationKeys } from '../types';
import { DuoContainer } from '../style';

const ManagerNotification = () => {
  const { t } = useTranslation();

  return (
    <DuoContainer>
      <div />
      <div>
        <TangiNotification centered={true} variant={NOTIFICATION_VARIANTS.INFO}>
          <TangiTypography>{t(TranslationKeys.MANAGERS_NOTIFICATION)}</TangiTypography>
        </TangiNotification>
      </div>
    </DuoContainer>
  );
};

export default ManagerNotification;
