import React, { useState, MouseEvent, ForwardedRef, useCallback } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { RootState } from '_helpers';
import { Client } from 'utils/types/client/client';
import { getLawyerClients, setActiveClientAndResetPartnerships } from 'redux-toolkit/thunks/lawFirmThunks';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { NEUTRAL_SHADES } from 'utils/theme';
import { StyledDropdown, ClientDropdownButton, customToggleTypographyStyles, DropdownItemContainer, DropdownLoaderContainer } from './style';

const MAX_CLIENT_NAME_LENGTH = 35;

interface ICustomToggleProps {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  activeClient: Client;
  isVisible: boolean;
  loading?: boolean;
}

// The forwardRef is important
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ onClick, activeClient, isVisible }: ICustomToggleProps, ref: ForwardedRef<HTMLButtonElement | null>) => {
  const { t } = useTranslation();
  /**
   * Closes the dropdown menu if it is not already shown.
   * @param {MouseEvent<HTMLElement>} event - The mouse event object.
   */
  const handleToggleShow = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!isVisible) {
      onClick(event);
    }
  };

  return (
    <ClientDropdownButton ref={ref} onClick={handleToggleShow} data-testid="switch-client-dropdown">
      <TangiTypography color={activeClient?.name ? NEUTRAL_SHADES.BLACK : NEUTRAL_SHADES[900]} customStyles={customToggleTypographyStyles}>
        {activeClient?.name ?? t('LAWYER_CLIENTS.CHOOSE_CLIENT')}
      </TangiTypography>
      <TangiSvgIcon component="arrowDown" />
    </ClientDropdownButton>
  );
});

const SwitchClientDropdown = () => {
  const { lawyerClients, activeClient, loading } = useSelector((state: RootState) => state.lawfirm);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const isTableView = useSelector((state: RootState) => state.asset.isTableView);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * Toggle the visibility state and fetch lawyer clients if needed.
   *
   * This function toggles the visibility state and, if the visibility state is changing to visible
   * and there are no lawyer clients already fetched, it dispatches an action to fetch lawyer clients for the active account.
   *
   * @function
   * @returns {void}
   */
  const handleToggle = useCallback(() => {
    if (!lawyerClients?.data?.length) dispatch(getLawyerClients({ accountId: activeAccount._id }));
    setIsVisible((prev) => !prev);
  }, [isVisible]);

  const isIdMatchingActiveClient = (clientId: string) => {
    return clientId === activeClient?.id;
  };

  /**
   * Handles the process of switching to a different client.
   *
   * @param {Client} client - The client to switch to.
   * @returns {void}
   */
  const handleSwitchAccount = (client: Client) => {
    if (!isIdMatchingActiveClient(client.id)) {
      setIsVisible(false);
      history.push(`/client/${client.id}`);

      batch(() => {
        dispatch(setActiveClientAndResetPartnerships(client));
        dispatch(patentActions.resetStateExceptRequestInfo());
        dispatch(inventionDisclosureActions.resetStateExceptRequestInfo());
        dispatch(partnershipActions.toggleDrawer(false));
        if (isTableView) {
          dispatch(assetsActions.setIsTableView(false));
        }
      });
    }
  };

  const renderSwitchClientDropdownItem = (client: Client) => {
    return (
      <Tippy content={client?.name || ''} placement="top-start" key={client.id} disabled={client?.name ? client?.name?.length < MAX_CLIENT_NAME_LENGTH : true}>
        <DropdownItemContainer isActive={isIdMatchingActiveClient(client.id)} onClick={() => handleSwitchAccount(client)}>
          <TangiTypography>{client?.name || ''}</TangiTypography>
        </DropdownItemContainer>
      </Tippy>
    );
  };

  const renderLoader = () => {
    return (
      <DropdownLoaderContainer>
        <Spinner animation="border" size="sm" variant="primary" />
      </DropdownLoaderContainer>
    );
  };

  const renderEmptyStateDropdownItem = () => {
    return (
      <DropdownItemContainer isEmptyState={true}>
        <TangiTypography>
          <TangiTypography>{t('LAWYER_CLIENTS.PROBLEM_LOADING_CLIENTS')}</TangiTypography>
        </TangiTypography>
      </DropdownItemContainer>
    );
  };

  return (
    <StyledDropdown onToggle={handleToggle} show={isVisible}>
      <Dropdown.Toggle as={CustomToggle} activeClient={activeClient} isVisible={isVisible} />
      <Dropdown.Menu>
        {!!lawyerClients?.data?.length ? lawyerClients?.data?.map((client: Client) => renderSwitchClientDropdownItem(client)) : loading ? renderLoader() : renderEmptyStateDropdownItem()}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default SwitchClientDropdown;
