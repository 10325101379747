import styled from 'styled-components';
import { BORDER_RADIUS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const CardContainer = styled.div<{ height?: string; width?: string }>`
  gap: ${SPACING[3]};
  height: ${({ height }) => height ?? 'auto'};
  width: ${({ width }) => width ?? 'auto'};
  border: 1px solid ${NEUTRAL_SHADES[400]};
  border-radius: ${BORDER_RADIUS[4]};
`;
