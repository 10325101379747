import { useTranslation } from 'react-i18next';

import XRayPageLayout from '_components/XRayPageLayout/XRayPageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import { MIME_TYPES } from 'utils/enums';
import { ButtonContainer, CircleBackground, Container, StyledTangiSvgIcon } from './styled';
import { PRIMARY_SHADES } from 'utils/theme';
import { uploadContractApplication } from 'redux-toolkit/thunks/contractThunks';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '_helpers';
import ContractStatusNotification from '_components/ContractXraySearch/components/ContractStatusNotification';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import { XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { contractConstants } from '_constants';
import { removeStateFromLocalStorage } from 'utils/localStorage';
import { contractActions } from 'redux-toolkit/slices/contractSlice';
import { TangiButton } from '_components/TangiLibrary';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';

const ContractAnalyzer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { clientId } = useParams<{ clientId: string }>();

  const contractData = useSelector((state: RootState) => state.contract.contractData);

  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

  const contractJobStatus = useXRayJobStatus(
    (state: RootState) => state.contract.contractLoaders,
    (state: RootState) => state.contract.contractData,
    (state: RootState) => state.contract.contractRequestInfo,
    (state: RootState) => state.contract.isCanceled,
  );

  const handleUploadFile = (file: File[]) => {
    dispatch(uploadContractApplication({ files: file, clientId }));
    dispatch(mixpanelEvents.analyzeContractFile());
    setShowUploadFileModal(false);
  };

  const handleCancelProcess = () => {
    removeStateFromLocalStorage(contractConstants.CONTRACT_REQUEST_INFO);
    dispatch(contractActions.resetEntireState());
    dispatch(mixpanelEvents.cancelAnalyzeContractFile());
  };

  return (
    <XRayPageLayout
      iconComponent={
        <CircleBackground>
          <StyledTangiSvgIcon component="contractXray" size="130px" color={PRIMARY_SHADES[800]} />
        </CircleBackground>
      }
      title={t('DISCOVERY_LOBBY.TITLE.CONTRACT_XRAY')}
      subTitle={t('DISCOVERY_LOBBY.CARD_TEXT.CONTRACT_ANALYZER')}
      showBetaChip
    >
      <Container>
        <ButtonContainer>
          <TangiButton smallbtn text={t('DISCOVERY_LOBBY.BUTTONS_TEXT.UPLOAD_CONTRACT_FILE')} onClick={() => setShowUploadFileModal(true)} data-testid="upload-contract-file" svgIcon="upload" />
        </ButtonContainer>
        <ContractStatusNotification
          status={contractJobStatus}
          fileName={contractData?.filename || ''}
          details={contractData?.details}
          resultsNum={contractData?.response?.checklist_results ? Object.keys(contractData?.response?.checklist_results).length : 0}
          onCancel={handleCancelProcess}
          onClick={() => {
            history.push(generateRoute(AppRoutes.CONTRACT_ANALYZER_INNER_PAGE, { clientId }));
          }}
          contractSource={contractData?.source_type || XRAY_SOURCE_TYPE.FILE}
        />
      </Container>
      <UploadFileModal
        acceptedFilesList={[MIME_TYPES.DOC, MIME_TYPES.DOCX, MIME_TYPES.PDF].join(', ')}
        isOpen={showUploadFileModal}
        title={t('DISCOVERY_LOBBY.UPLOAD_CONTRACT_APPLICATION.UPLOAD_CONTRACT_APPLICATION_FILE')}
        onClose={() => setShowUploadFileModal(false)}
        onSubmit={handleUploadFile}
        submitButtonText={t('GENERAL.BUTTONS_TEXT.CONTINUE')}
        customDragNDropText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.DRAG_AND_DROP_WORD_FILE')}
        customDragNDropRejectText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_WORD_FILE')}
      />
    </XRayPageLayout>
  );
};

export default ContractAnalyzer;
