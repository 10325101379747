import { MIME_TYPES } from 'utils/enums';
import { File as AssetFile } from 'utils/types/assets/assets';

export const isValidFileName = (fileName: string) => {
  const format = /[`!@#$%^&*()+=[\]{};':"\\|,<>/?~]/;
  return !format.test(fileName);
};

export const removeSpecialChars = (fileName: string): string => {
  return fileName.replace(/[!@#$%^&*()+=[\]{};':"\\|,<>/?~]/g, '');
};

export const handleFileName = (file: File): { file: File; isModified: boolean } => {
  const cleanFileName = removeSpecialChars(file.name);
  if (cleanFileName === file.name) {
    return { file, isModified: false };
  }
  const parts = cleanFileName.split('.');
  const name = parts.slice(0, -1).join('.') || 'file';
  const extension = parts.length > 1 ? `.${parts[parts.length - 1]}` : '';
  return { file: createNewFile(file, name + extension), isModified: true };
};

export const createNewFile = (file: File, newName: string): File => {
  return new File([file], newName, { type: file.type, lastModified: file.lastModified });
};

export const acceptedFilesList = `${MIME_TYPES.PNG}, ${MIME_TYPES.JPEG}, ${MIME_TYPES.PDF}, ${MIME_TYPES.DOC}, ${MIME_TYPES.DOCX}, ${MIME_TYPES.XLS}, ${MIME_TYPES.XLSX}, ${MIME_TYPES.PPT}, ${MIME_TYPES.PPTX}`;

/**
 * Handles duplicate filenames by appending a counter (e.g., (1), (2), etc.) to the file name if duplicates exist.
 * It checks across newly dropped files, existing asset files, and previously dropped files, ensuring that each file has a unique name.
 *
 * @param {File[]} newDroppedFiles - The array of new files that were dropped and need to be checked for duplicates.
 * @param {AssetFile[]} existingAssetFiles - The array of files that already exist in the system and need to be checked against for duplicate names.
 * @param {File[]} droppedFiles - The array of files that were previously dropped and should be considered when checking for duplicates.
 * @returns {File[]} - Returns a new array of `File` objects with unique names, where any duplicates have been resolved by appending a counter.
 */
export const handleDuplicateFilenames = (newDroppedFiles: File[], existingAssetFiles: AssetFile[], droppedFiles: File[]) => {
  const allFiles = [...existingAssetFiles, ...droppedFiles.map((droppedFile) => ({ name: droppedFile.name }))];

  const newFilesWithUniqueNames = newDroppedFiles.map((newDroppedFile) => {
    let baseName = newDroppedFile.name;
    let extension = '';

    // Split filename into baseName and extension (if any)
    const dotIndex = newDroppedFile.name.lastIndexOf('.');
    if (dotIndex !== -1) {
      baseName = newDroppedFile.name.substring(0, dotIndex);
      extension = newDroppedFile.name.substring(dotIndex);
    }

    let counter = 1;
    let newFileName = newDroppedFile.name;

    // Check for duplicates and append a counter if necessary
    while (allFiles.some((f) => f.name === newFileName)) {
      newFileName = `${baseName} (${counter})${extension}`;
      counter++;
    }
    const updatedFile = new File([newDroppedFile], newFileName, { type: newDroppedFile.type });
    allFiles.push({ name: newFileName });
    return updatedFile;
  });

  return newFilesWithUniqueNames;
};
