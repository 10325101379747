import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import RGL, { WidthProvider } from 'react-grid-layout';
import 'tippy.js/dist/tippy.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { pendingAcknowledgement } from 'redux-toolkit/thunks/assetsThunks';
import { getDashboardAssetsRawData, getDashboardAccountsRawData, getDashboardRecipientsRawData } from 'redux-toolkit/thunks/dashboardThunks';
import { Dashboard } from '../Dashboard';
import { PieChart } from './components/Charts';
import PeopleStatus from './components/Widgets/PeopleStatus/PeopleStatus';
import UsedTags from './components/Widgets/UsedTags/UsedTags';
import PeopleByRole from './components/Widgets/PeopleByRole/PeopleByRole';
import ClientDashboardHeader from './components/Header/ClientDashboardHeader';
import AssetsData from './components/Widgets/AssetsData/AssetsData';
import AssetsOverTime from './components/Widgets/AssetsOverTime/AssetsOverTime';
import Insights from './components/Widgets/Insights/Insights';
import Users from './components/Widgets/Users/Users';
import ProjectsByAssets from './components/Widgets/ProjectsByAssets/ProjectsByAssets';
import WidgetTitle from './components/Widgets/WidgetTitle';
import WidgetCard from './components/Widgets/WidgetCard';
import { Contributors } from './components/Widgets/Contributors/Contributors';
import DataByDepartments from './components/Widgets/DataByDepartments/DataByDepartments';

import { rowHeight, numberOfColumns, DASHBOARD_WIDGETS_KEYS } from './consts';
import { getLayoutLocalStorage, getTotalAssetsByDepartment, saveLayoutToLocalStorage } from './util';
import { loadStateFromLocalStorage } from '../../utils/localStorage';

import './ClientDashboard.scss';

const GridLayout = WidthProvider(RGL);

const ClientDashboard2V = () => {
  const [dashboardLayout, setDashboardLayout] = useState(getLayoutLocalStorage());
  const { clientId } = useParams();
  const {
    assetsByAssetType,
    assetsByDepartments,
    assetsByThirdParty,
    assetsByProject,
    topContributors,
    metadata,
    loaders,
    users,
    assetsByTag,
    assetsByBusinessValues,
    accountsStatus,
    accountsByRole,
    unpublishedAssets,
    mostNotifiedAsset,
    mostSharedBy,
    topAssetCreator,
  } = useSelector((state) => state.dashboard);
  const { activeAccount } = useSelector((state) => state.authentication);
  const { t } = useTranslation();

  const { assetsTotal } = metadata;
  const totalAssetsByDepartment = getTotalAssetsByDepartment(assetsByDepartments);

  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(getDashboardAssetsRawData(clientId));
      dispatch(getDashboardAccountsRawData(clientId));
      dispatch(getDashboardRecipientsRawData(clientId));
    });
  }, [clientId]);

  // TODO: check when runs
  useEffect(() => {
    const seenAcknowledgementRequests = loadStateFromLocalStorage(`seenAcknowledgementRequests[${activeAccount._id}]`);
    if (assetsTotal > 0 && !seenAcknowledgementRequests) {
      dispatch(pendingAcknowledgement(clientId));
    }
  }, [metadata, clientId, activeAccount]);

  const onLayoutChange = (layout) => {
    setDashboardLayout(layout);
    saveLayoutToLocalStorage(layout);
  };

  const renderLoadingGridLayout = () => {
    return (
      <GridLayout onLayoutChange={onLayoutChange} margin={[25, 25]} rowHeight={rowHeight} cols={numberOfColumns} className="layout" layout={dashboardLayout}>
        {dashboardLayout.map((item) => {
          return (
            <WidgetCard key={item.i}>
              <Skeleton borderRadius={8} height="100%" containerClassName="skeleton-max-container" />
            </WidgetCard>
          );
        })}
      </GridLayout>
    );
  };

  // TODO: change loader to show some of the widgets after the assets request is over and not wait for the other requests
  const renderContent = () => {
    if (loaders.assets) {
      return renderLoadingGridLayout();
    }
    return (
      <GridLayout onLayoutChange={onLayoutChange} margin={[25, 25]} rowHeight={rowHeight} cols={numberOfColumns} className="layout" layout={dashboardLayout}>
        <WidgetCard isEmpty={!assetsByAssetType?.length} key={DASHBOARD_WIDGETS_KEYS.ASSETS_BY_TYPE} data-testid={DASHBOARD_WIDGETS_KEYS.ASSETS_BY_TYPE}>
          <WidgetTitle title={t('DASHBOARD_PAGE.WIDGETS.ASSETS_BY_TYPE')} />
          <PieChart margin={{ top: 100, right: 0, bottom: 120, left: 0 }} data={assetsByAssetType} total={metadata.assetsTotal} totalText={t('DASHBOARD_PAGE.WIDGETS.TOTAL_ASSETS')} />
        </WidgetCard>
        <AssetsOverTime key={DASHBOARD_WIDGETS_KEYS.ASSETS_OVER_TIME} data-testid={DASHBOARD_WIDGETS_KEYS.ASSETS_OVER_TIME} />
        <DataByDepartments
          key={DASHBOARD_WIDGETS_KEYS.DATA_BY_DEPARTMENTS}
          data={{ total: totalAssetsByDepartment, assetsByDepartments }}
          isEmpty={!assetsByDepartments.length}
          data-testid={DASHBOARD_WIDGETS_KEYS.DATA_BY_DEPARTMENTS}
        />
        <AssetsData key={DASHBOARD_WIDGETS_KEYS.ASSETS_DATA} data={{ total: metadata.assetsTotal, unpublishedAssets, assetsByThirdParty }} data-testid={DASHBOARD_WIDGETS_KEYS.ASSETS_DATA} />
        <Users key={DASHBOARD_WIDGETS_KEYS.USERS} data={users} data-testid={DASHBOARD_WIDGETS_KEYS.USERS} />
        <Insights key={DASHBOARD_WIDGETS_KEYS.INSIGHTS} data={{ mostNotifiedAsset, mostSharedBy, topAssetCreator }} data-testid={DASHBOARD_WIDGETS_KEYS.INSIGHTS} />
        <ProjectsByAssets key={DASHBOARD_WIDGETS_KEYS.PROJECTS_BY_ASSETS} data={assetsByProject} isEmpty={!assetsByProject?.length} data-testid={DASHBOARD_WIDGETS_KEYS.PROJECTS_BY_ASSETS} />
        {/* TODO: fix empty state text for contributors */}
        <Contributors key={DASHBOARD_WIDGETS_KEYS.CONTRIBUTORS} data={topContributors} isEmpty={!topContributors?.length} data-testid={DASHBOARD_WIDGETS_KEYS.CONTRIBUTORS} />
        <PeopleStatus key={DASHBOARD_WIDGETS_KEYS.PEOPLE_STATUS} data={{ total: metadata.accountsTotal, ...accountsStatus }} data-testid={DASHBOARD_WIDGETS_KEYS.PEOPLE_STATUS} />
        <UsedTags
          key={DASHBOARD_WIDGETS_KEYS.USED_TAGS}
          data={{ tags: assetsByTag, businessValues: assetsByBusinessValues }}
          isEmpty={!assetsByTag?.length && !assetsByBusinessValues?.length}
          data-testid={DASHBOARD_WIDGETS_KEYS.USED_TAGS}
        />
        <PeopleByRole key={DASHBOARD_WIDGETS_KEYS.PEOPLE_BY_ROLE} data={{ total: metadata.accountsTotal, people: accountsByRole }} data-testid={DASHBOARD_WIDGETS_KEYS.PEOPLE_BY_ROLE} />
      </GridLayout>
    );
  };

  return (
    <Dashboard title="">
      <div>
        <ClientDashboardHeader />
        <div>{renderContent()}</div>
        <Row className="mt-4"></Row>
      </div>
    </Dashboard>
  );
};

export { ClientDashboard2V };
