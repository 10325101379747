import { mixpanelEvents } from '_services/utils/MixPanel/mixpanelConfig';
import { courseConstants } from '../_constants/courseConstants';
import { courseService } from '../_services/courseService';

function getCourses(page, searchTxt, sorting) {
  function request() {
    return {
      type: courseConstants.CLIENT_COURSES_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: courseConstants.CLIENT_COURSES_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: courseConstants.CLIENT_COURSES_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    courseService
      .getCourses(page, searchTxt, sorting)
      .then((courses) => {
        dispatch(success(courses));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

const getQuiz = (quizId) => {
  function request() {
    return {
      type: courseConstants.QUIZ_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: courseConstants.QUIZ_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: courseConstants.QUIZ_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    courseService
      .getQuiz(quizId)
      .then((quiz) => {
        dispatch(success(quiz));
        dispatch(mixpanelEvents.quizStarted());
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
};

export const courseActions = {
  getCourses,
  getQuiz,
};
