import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { SPACING } from 'utils/theme';

const TitleWrapper = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  padding: ${SPACING[4]} ${SPACING[4]} ${SPACING[0]};
`;

const cardTitleStyle = `
  font-size:${SPACING[3.5]};
  line-height:${SPACING[4]};
`;

interface Props {
  titleText: string;
  count: number;
}

const InventionCardTitle = ({ titleText, count }: Props) => {
  return (
    <TitleWrapper data-testid="invention-card-title">
      <TangiTypography weight="semibold" customStyles={cardTitleStyle}>
        {titleText}
      </TangiTypography>
      <TangiTypography weight="semibold" type="subheading">
        ({count})
      </TangiTypography>
    </TitleWrapper>
  );
};

export default InventionCardTitle;
