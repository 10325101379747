import { TradeSecretsSubjectData } from './types';
import { HexColorArray } from '../types';
import { NEUTRAL_SHADES } from 'utils/theme';

export const truncateStringToFitSquare = (label: string, squareSize: number, fontSize: number = 11) => {
  const avgCharWidth = 0.6 * fontSize;
  const maxChars = Math.floor(squareSize / avgCharWidth);

  if (label.length <= maxChars) {
    return label;
  }

  const truncatedString = label.substring(0, maxChars - 3) + '...';

  return truncatedString;
};

export const generateTreeMapColorScale = (treeMapData: TradeSecretsSubjectData[], palette: HexColorArray) => {
  if (!treeMapData || !treeMapData.length) return [];

  const arr: HexColorArray = [];

  let prev = 0;
  let index = 0;

  treeMapData.forEach((data) => {
    if (treeMapData[prev].ts_count > data.ts_count + 6 && index < palette.length - 1) {
      index += 1;
    }

    arr.push(palette[index]);

    if (data !== treeMapData[0]) {
      prev = +1;
    }
  });

  return arr;
};

export const getLegendValues = (data: TradeSecretsSubjectData[], palette: HexColorArray) => {
  const fallBackColor = NEUTRAL_SHADES.WHITE;
  const topColor = palette?.[5] ?? fallBackColor;
  const bottomColor = palette?.[palette.length - 1] ?? fallBackColor;
  const maxValue = data?.[0]?.ts_count ?? 0;
  const minValue = data?.[data.length - 1]?.ts_count ?? 0;

  return { topColor, bottomColor, maxValue, minValue };
};
