import styled from 'styled-components';

import { NOTIFICATION_VARIANTS, TangiNotification, TangiTypography } from '_components/TangiLibrary';
import { SPACING } from 'utils/theme';

const Container = styled.div`
  margin-bottom: ${SPACING[2]};
  width: fit-content;
`;

interface Props {
  warningMessage: string;
}

const InventionDisclosureWarning = ({ warningMessage }: Props) => {
  return (
    <Container>
      <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING} centered={true}>
        <TangiTypography type="subheading">{warningMessage}</TangiTypography>
      </TangiNotification>
    </Container>
  );
};

export default InventionDisclosureWarning;
