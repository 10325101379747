import i18next from 'i18next';
import styled from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';
import { SharePoint, SharePointStatusEnums } from 'utils/types/assets/assets';
import { NEUTRAL_SHADES } from 'utils/theme';
import { SharepointEnum } from 'utils/enums';
import { InActiveToolTopContainer } from '_components/ClientAssetCards/components/AssetCardActive/style';

interface BreadcrumbItem {
  label: React.ReactNode;
}

interface StatusRenderer {
  (siteName: BreadcrumbItem, pathSegments?: string[], showTooltip?: boolean): {
    breadcrumbItems: BreadcrumbItem[];
    fullPathString?: string;
  };
}
export interface SharePointPath {
  path: string;
  siteName: string;
  status: SharePointStatusEnums;
}

const EllipsisWrapper = styled.span`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin-top: 5px;
`;

export const statusRenderers: Record<string, StatusRenderer> = {
  [SharePointStatusEnums.MOVED]: (siteName, _pathSegments = []) => ({
    breadcrumbItems: [
      siteName,
      {
        label: (
          <InActiveToolTopContainer>
            <TangiTypography color={NEUTRAL_SHADES[800]}>{i18next.t('ASSET_PAGE.TOOLTIP.INACCESSIBLE')}</TangiTypography>
          </InActiveToolTopContainer>
        ),
      },
    ],
  }),
  [SharePointStatusEnums.ACTIVE]: (siteName, pathSegments = []) => {
    const breadcrumbItems = [
      siteName,
      ...pathSegments.map((segment) => ({
        label: (
          <TangiTypography color={NEUTRAL_SHADES[1100]}>
            <EllipsisWrapper>{segment}</EllipsisWrapper>
          </TangiTypography>
        ),
      })),
    ];

    const fullPathString = [siteName.label, ...pathSegments].join(' > ');

    return {
      fullPathString,
      breadcrumbItems,
    };
  },
};

// Function to parse the asset path based on its status
export const parseAssetSharePointPath = (assetData: SharePoint, showTooltip = true) => {
  if (!assetData || !assetData.path) {
    return null;
  }

  const pathWithoutRoot = assetData.path.replace(SharepointEnum.RootFolder, SharepointEnum.Documents);
  const pathSegments = pathWithoutRoot.split('/');

  if (pathSegments?.[pathSegments.length - 1] === "") {
    pathSegments.pop();
  }

  const siteName: BreadcrumbItem = {
    label: (
      <TangiTypography color={NEUTRAL_SHADES[1100]}>
        <EllipsisWrapper>{assetData.siteName || 'Unknown Site'}</EllipsisWrapper>
      </TangiTypography>
    ),
  };

  // Use the status from assetData, default to ACTIVE
  const statusRenderer: StatusRenderer = statusRenderers[assetData.status || SharePointStatusEnums.ACTIVE];

  return statusRenderer(siteName, pathSegments, showTooltip);
};
