import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { css } from 'styled-components';

import { TangiTypography } from '_components/TangiLibrary';

export function getFirstEntryText(clientName) {
  return i18next.t('DASHBOARD_PAGE.FIRST_TIME_ENTRY_TEXT', { CLIENT_NAME: clientName });
}

export function getLoginEntryText(clientName) {
  const breakWordTypographyStyles = css`
    white-space: pre-line;
  `;
  return (
    <div>
      <TangiTypography>{i18next.t('DASHBOARD_PAGE.GET_LOGIN_ENTRY_TEXT', { CLIENT_NAME: clientName })}</TangiTypography>
      <TangiTypography customStyles={breakWordTypographyStyles}>
        <Trans>{i18next.t('DASHBOARD_PAGE.GET_LOGIN_ENTRY_NOTE')} </Trans>
      </TangiTypography>
    </div>
  );
}
