import { useTranslation } from 'react-i18next';
import { TangiTypography } from '_components/TangiLibrary';
import { TableHeaderText } from 'utils/globalStyles';
import { PotentialTsTypography } from './style';

export const createTableColum = () => {
  const { t } = useTranslation();
  return [
    {
      name: <TableHeaderText>{t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.POTENTIAL_TRADE_SECRET')}</TableHeaderText>,
      selector: ({ trade_secret }) => trade_secret,
      fieldName: 'trade_secret',
      maxWidth: '750px',
      minWidth: '750px',
    },
    {
      name: <TableHeaderText>{t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.PATENT')}</TableHeaderText>,
      selector: ({ patent }) => patent,
      fieldName: 'patent',
      maxWidth: '150px',
      minWidth: '150px',
    },
    {
      name: <TableHeaderText>{t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.YEAR')}</TableHeaderText>,
      selector: ({ patent_year }) => patent_year,
      fieldName: 'patent_year',
      minWidth: '80px',
      maxWidth: '80px',
    },
    {
      name: <TableHeaderText>{t('PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.SUBJECTS')}</TableHeaderText>,
      selector: ({ subject }) => subject,
      fieldName: 'subject',
      maxWidth: '200px',
      minWidth: '200px',
    },
  ];
};

export const makeTableRow = ({ value }) => {
  return {
    trade_secret: <PotentialTsTypography>{value?.trade_secret}</PotentialTsTypography>,
    patent: <TangiTypography>{value?.patent?.value}</TangiTypography>,
    patent_year: <TangiTypography>{value?.patent_year}</TangiTypography>,
    subject: <TangiTypography>{value?.subject?.value}</TangiTypography>,
  };
};
