import { IconTypes } from '_components/TangiLibrary';
import { ADMIN_CARDS_LIST, EMPLOYEE_CARDS_LIST, HR_CARDS_LIST, LEARN_ADMIN_CARDS_LIST, LEARN_EMPLOYEE_CARDS_LIST } from './consts';
import { CardsListDicType, ILobbyCard, LOBBY_CARD_ID } from './types';
import { IRoles } from 'utils/roles';

export const getCardsByRole = (role: IRoles, hasPortfolio: boolean, hasXray: boolean, hasContinuation: boolean): ILobbyCard[] => {
  const cardsList = roleToCardsList[role] || ADMIN_CARDS_LIST;
  const isManageRole = cardsList === ADMIN_CARDS_LIST;
  if (isManageRole) {
    return getManageCards(hasPortfolio, hasXray, hasContinuation);
  }
  return getNoManageRoleCards(hasPortfolio, cardsList);
};

export function createLobbyCard(cardId: LOBBY_CARD_ID, icon: IconTypes): ILobbyCard {
  return {
    cardId,
    icon,
  };
}

export function createLockedCard(card: ILobbyCard): ILobbyCard {
  return {
    ...card,
    isLocked: true,
  };
}

const getManageCards = (hasPortfolio: boolean, hasXray: boolean, hasContinuation: boolean): ILobbyCard[] => {
  const { NO_PERMISSIONS, XRAY, XRAY_CONTINUATION, PORTFOLIO, PORTFOLIO_XRAY, PORTFOLIO_XRAY_CONTINUATION } = ADMIN_CARDS_LIST;
  const conditions = [
    { condition: hasXray && hasPortfolio && hasContinuation, result: PORTFOLIO_XRAY_CONTINUATION },
    { condition: hasXray && hasPortfolio, result: PORTFOLIO_XRAY },
    { condition: hasXray && hasContinuation, result: XRAY_CONTINUATION },
    { condition: hasXray, result: XRAY },
    { condition: hasPortfolio, result: PORTFOLIO },
  ];
  for (const { condition, result } of conditions) {
    if (condition) {
      return result;
    }
  }
  return NO_PERMISSIONS;
};

const getNoManageRoleCards = (hasPortfolio: boolean, cardsList: CardsListDicType): ILobbyCard[] => {
  const { NO_PERMISSIONS, PORTFOLIO } = cardsList;
  return hasPortfolio ? PORTFOLIO : NO_PERMISSIONS;
};

const roleToCardsList: Partial<Record<IRoles, CardsListDicType>> = {
  [IRoles.EMPLOYEE_HR]: HR_CARDS_LIST,
  [IRoles.EMPLOYEE]: EMPLOYEE_CARDS_LIST,
  [IRoles.CONTRACTOR]: EMPLOYEE_CARDS_LIST,
  [IRoles.CONTRACTOR_OTHER]: EMPLOYEE_CARDS_LIST,
  [IRoles.PARTNER]: EMPLOYEE_CARDS_LIST,
  [IRoles.LEARN_EMPLOYEE_ADMIN]: LEARN_ADMIN_CARDS_LIST,
  [IRoles.LEARN_EMPLOYEE]: LEARN_EMPLOYEE_CARDS_LIST,
  [IRoles.LEARN_CONTRACTOR]: LEARN_EMPLOYEE_CARDS_LIST,
  [IRoles.LEARN_PARTNER]: LEARN_EMPLOYEE_CARDS_LIST,
  [IRoles.LAWYER]: ADMIN_CARDS_LIST,
  [IRoles.LAWYER_ADMIN]: ADMIN_CARDS_LIST,
  [IRoles.CLIENT_ADMIN]: ADMIN_CARDS_LIST,
  [IRoles.EMPLOYEE_ADMIN]: ADMIN_CARDS_LIST,
  [IRoles.EMPLOYEE_MANAGER]: ADMIN_CARDS_LIST,
  [IRoles.CONTRACTOR_ADMIN]: ADMIN_CARDS_LIST,
};
