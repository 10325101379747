import { Permissions, Role } from '../utils/types/role/role';
import { Account } from './types/account/account';
import { generateRoute } from '../routing/generateRoute';
import { AppRoutes } from '../routing/consts';
import { Client } from './types/client/client';

export enum IRoles {
  SUPER_ADMIN = 'CMS Admin',
  LAWYER_ADMIN = 'Lawyer - Admin', //probably old and not used
  LAWYER = 'Lawyer',
  CLIENT_ADMIN = 'Client - Admin', //probably old and not used
  EMPLOYEE_ADMIN = 'Employee - Admin',
  EMPLOYEE_MANAGER = 'Employee - Manager',
  CONTRACTOR_ADMIN = 'Contractor - Admin', //probably not really used

  EMPLOYEE_HR = 'Employee - HR',
  EMPLOYEE = 'Employee',
  CONTRACTOR = 'Contractor',
  CONTRACTOR_OTHER = 'Contractor - Other',
  PARTNER = 'Partner',

  LEARN_EMPLOYEE_ADMIN = 'Learn - Employee - Admin',
  LEARN_EMPLOYEE = 'Learn - Employee',
  LEARN_CONTRACTOR = 'Learn - Contractor', //probably not really used
  LEARN_PARTNER = 'Learn - Partner', //probably not really used
}

// * * * * * ROLE GROUPS AND THEIR PERMISSIONS WITHIN THE SYSTEM * * * * *
// These role groups define the various types of users and their permissions
// within the application, categorized for easier management and access control.

/**
 * @constant
 * @type {Array<IRoles>}
 * @description SUPER_ADMIN role.
 */
export const superAdmin: Array<IRoles> = [IRoles.SUPER_ADMIN];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Admin roles, excluding Learn-specific roles.
 */
export const adminsExceptLearn: Array<IRoles> = [IRoles.LAWYER_ADMIN, IRoles.LAWYER, IRoles.CLIENT_ADMIN, IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER, IRoles.CONTRACTOR_ADMIN];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Roles that have access to the settings page.
 */
export const settingsRoles: Array<IRoles> = [IRoles.CONTRACTOR_ADMIN, IRoles.EMPLOYEE_ADMIN, IRoles.CLIENT_ADMIN, IRoles.LAWYER_ADMIN, IRoles.LAWYER];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Admin roles, including Learn-specific roles.
 */
export const admins: Array<IRoles> = [IRoles.EMPLOYEE_MANAGER, IRoles.LAWYER_ADMIN, IRoles.LAWYER, IRoles.CLIENT_ADMIN, IRoles.EMPLOYEE_ADMIN, IRoles.CONTRACTOR_ADMIN, IRoles.LEARN_EMPLOYEE_ADMIN];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Lawyer roles.
 */
export const lawyers: Array<IRoles> = [IRoles.LAWYER, IRoles.LAWYER_ADMIN];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Roles that can access assets only if they were created by them or they are recipients.
 */
export const regularRoles: Array<IRoles> = [IRoles.PARTNER, IRoles.EMPLOYEE, IRoles.EMPLOYEE_HR, IRoles.CONTRACTOR, IRoles.CONTRACTOR_OTHER];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Roles that can access X-ray features.
 */
export const specialAdmins: Array<IRoles> = [IRoles.EMPLOYEE_ADMIN, IRoles.LAWYER, IRoles.LAWYER_ADMIN, IRoles.EMPLOYEE_MANAGER];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description Admin role except Learn and Lawer roles.
 */
export const adminsExceptLearnAndLawyers: Array<IRoles> = [IRoles.EMPLOYEE_ADMIN, IRoles.EMPLOYEE_MANAGER, IRoles.CONTRACTOR_ADMIN];

/**
 * @constant
 * @type {Array<IRoles>}
 * @description All roles excluding SUPER_ADMIN
 */
export const allPermissions = Object.values(IRoles).slice(1);

/**
 * @constant
 * @type {Array<IRoles>}
 * @description All permissions excluding learn-specific roles.
 */
export const allPermissionsExceptLearn = [
  IRoles.LAWYER_ADMIN,
  IRoles.LAWYER,
  IRoles.CLIENT_ADMIN,
  IRoles.EMPLOYEE_ADMIN,
  IRoles.EMPLOYEE,
  IRoles.EMPLOYEE_MANAGER,
  IRoles.EMPLOYEE_HR,
  IRoles.CONTRACTOR_ADMIN,
  IRoles.CONTRACTOR,
  IRoles.CONTRACTOR_OTHER,
  IRoles.PARTNER,
];

// * * * * * CREATABLE ROLES FOR THE SYSTEM * * * * *
// These constants define the roles that can be created within the application,
// along with their labels for display purposes.

/**
 * @constant
 * @type {Array<Object>}
 * @description Roles that can be created within the system.
 */
export const createableRoles = [
  { value: IRoles.EMPLOYEE_ADMIN, label: 'Admin' },
  { value: IRoles.CONTRACTOR, label: 'Contractor' },
  { value: IRoles.EMPLOYEE, label: 'Employee' },
  { value: IRoles.EMPLOYEE_HR, label: 'HR' },
  { value: IRoles.EMPLOYEE_MANAGER, label: 'Manager' },
  { value: IRoles.CONTRACTOR_OTHER, label: 'Other' },
];

/**
 * @constant
 * @type {Array<Object>}
 * @description Learn-specific roles that can be created within the system.
 */
export const createableRolesLearn = [
  { value: IRoles.LEARN_EMPLOYEE_ADMIN, label: 'Admin' },
  { value: IRoles.LEARN_EMPLOYEE, label: 'Employee' },
  { value: IRoles.LEARN_CONTRACTOR, label: 'Contractor' },
];

//  * * * * * UTILITY FUNCTIONS * * * * *

/**
 * Generates the redirect route based on the account's role and client settings.
 *
 * @param {Object} account - The account object containing role and client information.
 * @param {Object} account.role - The role object of the account.
 * @param {string} account.role.name - The name of the role.
 * @param {Object} account.client - The client object of the account.
 * @param {string} [account.client._id] - The ID of the client.
 * @param {string} [account.client.id] - The ID of the client (alternative).
 * @param {Object} [account.client.settings] - The settings object of the client.
 * @returns {string} The redirect route.
 */
export const getRedirectRoute = (account: Account): string => {
  const roleName = (account?.role as Role)?.name ?? '';
  const clientId = (account?.client as Client)?._id ?? (account?.client as Client)?.id;

  switch (roleName) {
    case IRoles.SUPER_ADMIN:
      return `/adminhome`;

    case IRoles.CLIENT_ADMIN:
    case IRoles.EMPLOYEE_ADMIN:
    case IRoles.CONTRACTOR_ADMIN:
    case IRoles.EMPLOYEE_MANAGER:
      return (account?.client as Client)?.settings ? generateRoute(AppRoutes.HOME_LOBBY, { clientId }) : generateRoute(AppRoutes.FIRST_ONBOARDING, { clientId }); //Redirect to settings page if settings are not set up

    case IRoles.EMPLOYEE:
    case IRoles.CONTRACTOR:
    case IRoles.CONTRACTOR_OTHER:
    case IRoles.PARTNER:
    case IRoles.EMPLOYEE_HR:
    case IRoles.LEARN_EMPLOYEE_ADMIN:
    case IRoles.LEARN_EMPLOYEE:
    case IRoles.LEARN_CONTRACTOR:
    case IRoles.LEARN_PARTNER:
      return generateRoute(AppRoutes.HOME_LOBBY, { clientId }); //Home Lobby page

    case IRoles.LAWYER:
    case IRoles.LAWYER_ADMIN:
      return generateRoute(AppRoutes.DASHBOARD, { clientId }); //Lawyer Home page

    default:
      return generateRoute(AppRoutes.LOGIN, { clientId }); //Login page
  }
};

/**
 * Checks if a user based on their role and permissions has the ability to share an asset.
 * Specifically, partners cannot share third-party assets.
 *
 * @param {IRoles} role - The user's role.
 * @param {Permissions} permissions - Object containing user permissions.
 * @param {boolean} isAssetThirdParty - Flag indicating if the asset is third-party.
 * @returns {boolean} - Returns `true` if the user can share the asset, otherwise `false`.
 */
export const checkAssetSharePermission = (role: IRoles, permissions: Permissions, isAssetThirdParty: boolean): boolean => {
  if (role === IRoles.PARTNER && isAssetThirdParty) {
    return false;
  }
  return !!permissions?.Assetshare;
};

/**
 * Converts user role's newPermissions to a Permissions object.
 *
 * @param {Object} userRole - The role object containing permissions.
 * @param {Object} userRole.newPermissions - An object representing the new permissions.
 * @returns {Permissions} The converted Permissions object.
 */
export const getUserRoleAndPermission = (userRole: Role): Permissions => {
  const permissions: Partial<Permissions> = {};

  Object.entries(userRole.newPermissions).forEach(([key, value]) => {
    const updatedKey = key.replace(/-/g, '') as keyof Permissions;
    permissions[updatedKey] = value;
  });
  return permissions as Permissions;
};

export const createableRolesForManager = () => {
  const targetRoles = [IRoles.CONTRACTOR, IRoles.EMPLOYEE, IRoles.EMPLOYEE_MANAGER, IRoles.CONTRACTOR_OTHER];
  return createableRoles.filter((role) => targetRoles.includes(role.value));
};

export const getRoleNameContractor = (isUserIsLearnType: boolean) => {
  return isUserIsLearnType ? `${IRoles.LEARN_CONTRACTOR}` : `${IRoles.CONTRACTOR_ADMIN},${IRoles.CONTRACTOR},${IRoles.CONTRACTOR_OTHER}`;
};

export const getRoleNameEmployee = (isUserIsLearnType: boolean) => {
  return isUserIsLearnType ? `${IRoles.LEARN_EMPLOYEE_ADMIN},${IRoles.LEARN_EMPLOYEE}` : `${IRoles.EMPLOYEE_ADMIN},${IRoles.EMPLOYEE},${IRoles.EMPLOYEE_MANAGER},${IRoles.EMPLOYEE_HR}`;
};

export const isUserIsRole = (account: Account, role: IRoles) => {
  return (account?.role as Role)?.name?.includes(role);
};

export const isRoleMatch = (currentRole: IRoles, roleToMatch: string) => {
  return currentRole === roleToMatch;
};
