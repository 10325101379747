import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Row, Col, Modal, InputGroup, Form, FormControl } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './AdminAgreement.scss';
import { AdminDashboard } from '../AdminDashboard';
import { AgreementForm } from '../../../_components/Admin/AgreementForm';
import { Loader } from '../../../_components/Loader';
import { adminAgreementActions } from '../../../AdminRedux/_actions';
import { Table } from '../../../_components/Admin/DataTable/Table';
import { agreementService } from '../../../_services/agreementService';

function AdminAgreement({ match }) {
  const location = useLocation();
  const patharray = location.pathname.split('type=');
  const type = patharray && patharray[1] ? patharray[1] : '';
  const [show, setShow] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [viewResult, setViewResult] = useState({});
  const [deleteId, setDeleteId] = useState({});
  const [totalRes, setTotalRes] = useState(0);
  const [inputs, setInputs] = useState({ searchTxt: '' });
  const [usernametxt, setusernametxt] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { searchTxt } = inputs;
  const { sortBy } = useSelector((state) => state.clientAdmin);

  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setDeleteId({});
  };

  const handleShow = (deletedata) => {
    setShow(true);
    setDeleteId(deletedata);
  };

  const handleDelete = () => {
    setIsloading(true);
    agreementService.deleteAgreementType(deleteId.id).then(() => {
      setShow(false);
      setIsloading(false);
      window.location.reload();
    });
  };

  const Tableheaders = [
    { name: 'Name', field: 'name', sortable: true },
    { name: 'Description', field: 'description', sortable: true },
    { name: 'Slug', field: 'slug', sortable: true },
    { name: 'Count', field: 'lawFirm', sortable: false },
    { name: 'Actions' },
  ];

  const { agreementTypes, results } = useSelector((state) => state.adminAgreementReducer);

  const [comments, setComments] = useState([]);
  useEffect(() => {
    setIsloading(true);
    if (type === 'type') {
      dispatch(adminAgreementActions.getAdminAgreementType(1));
    } else {
      dispatch(adminAgreementActions.getAdminAgreement(1));
    }
  }, [dispatch]);

  useEffect(() => {
    if (type === 'type') {
      if (agreementTypes && agreementTypes.data && agreementTypes.data.length > 0) {
        setIsloading(false);
        setComments(agreementTypes.data);
        setTotalRes(agreementTypes && agreementTypes.metadata && agreementTypes.metadata.length > 0 ? agreementTypes.metadata[0].total : 0);
      } else {
        setTimeout(() => {
          setIsloading(false);
        }, [4000]);
        setTotalRes(0);
      }
    } else if (results && results.length > 0 && results[0].data && results[0].data.length > 0) {
      setIsloading(false);
      setComments(results[0].data);
    } else {
      setTimeout(() => {
        setIsloading(false);
      }, [4000]);
      setTotalRes(0);
    }

    if (match && match.params && (match.params.type === 'edit' || match.params.type === 'view') && type.includes('type')) {
      setIsloading(false);
      setViewResult(agreementTypes);
    }
  }, [results, agreementTypes]);

  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'Title', field: 'agreementType.name', sortable: true },
    { name: 'Name', field: 'account.displayName', sortable: true },
    { name: 'Created By', field: 'createdBy.displayName', sortable: true },
    { name: 'Date', field: 'createdAt', sortable: true },
    { name: 'Client', field: 'client.name', sortable: false },
    { name: 'Actions', field: 'actions', sortable: false },
  ];

  function onPageChanged(page) {
    setIsloading(true);
    setCurrentPage(page.currentPage);
    if (type === 'type') {
      dispatch(adminAgreementActions.getAdminAgreementType(page.currentPage, '', searchTxt && searchTxt ? searchTxt : '', sortBy));
    } else {
      dispatch(adminAgreementActions.getAdminAgreement(page.currentPage, searchTxt && searchTxt ? searchTxt : '', sortBy));
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'searchTxt' && !value) {
      if (type === 'type' || type.includes('type')) {
        dispatch(adminAgreementActions.getAdminAgreementType(1));
      } else {
        dispatch(adminAgreementActions.getAdminAgreement(1));
      }
    } else {
      setusernametxt(false);
    }
    setInputs(() => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (!searchTxt) {
      setusernametxt(true);
    } else {
      setusernametxt(false);
      setComments([]);
      setIsloading(true);
      if (type === 'type' || type.includes('type')) {
        dispatch(adminAgreementActions.getAdminAgreementType(1, null, searchTxt));
      } else {
        dispatch(adminAgreementActions.getAdminAgreement(1, searchTxt));
      }
    }
  }

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };
  useEffect(() => {
    if (match && match.params) {
      setIsloading(true);
      if ((match.params.type === 'edit' || match.params.type === 'view') && type.includes('type')) {
        dispatch(adminAgreementActions.getAdminAgreementType(1, match.params.firmid));
      }
    }
    if (match && match.params && match.params.type && match.params.type.includes('delete')) {
      handleShow();
    }
  }, [match]);

  let button;
  if (!type) {
    button = (
      <div>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Agreements</h6>
            </li>
          </ul>
          <div className="navbar-brand mx-2">
            <Button className="m-1 p-2 rounded" href="/admin/agreement&type=addnew" variant="outline-primary">
              Add New
            </Button>{' '}
          </div>
        </nav>
        <div className="bg-white border-0 card-header shadow-sm p-2 pl-2 rounded-3">
          <div className="row m-2">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                <InputGroup className="mb-3">
                  <FormControl
                    className={usernametxt ? 'border border-invalid' : ''}
                    name="searchTxt"
                    onChange={handleChange}
                    onKeyUp={handleKeypress}
                    type="text"
                    placeholder="Search"
                    aria-label="searchTxt"
                    aria-describedby="basic-addon2"
                    value={searchTxt}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                      <i className="fas fa-search" aria-hidden="true" />
                    </button>
                  </InputGroup.Text>
                </InputGroup>
                <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
              </Form.Group>
            </div>
          </div>
          <Table
            currentPage={currentPage}
            ITEMS_PER_PAGE={ITEMS_PER_PAGE}
            onPageChanged={onPageChanged}
            headers={headers}
            comments={comments}
            totalResults={results && results.length > 0 && results[0].metadata && results[0].metadata.length > 0 ? results[0].metadata[0].total : 0}
            type="agreement"
          />
        </div>
      </div>
    );
  } else if (type === 'addnew') {
    button = (
      <Row>
        <Col sm={12}>
          <AgreementForm type="Add new item to Agreements" />
        </Col>
      </Row>
    );
  } else if (type === 'type' || type.includes('type')) {
    button = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Type</h6>
            </li>
          </ul>
        </nav>
        {match.params.type && match.params.type !== 'delete' ? (
          <Row>
            <Col sm={10}>
              <AgreementForm
                type={match.params.type === 'edit' ? 'Edit Type' : 'View Type'}
                mode={match.params.type === 'edit' ? 'Edit' : 'view'}
                viewResult={viewResult}
                firmid={match.params.firmid}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <AgreementForm type="Add new type" />
            </Col>
            <Col sm={6}>
              <div className="row m-2">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <Form.Group className="col-sm-12 ps-0" controlId="formPassword">
                    <InputGroup className="mb-3">
                      <FormControl
                        className={usernametxt ? 'border border-invalid' : ''}
                        name="searchTxt"
                        onKeyUp={handleKeypress}
                        onChange={handleChange}
                        type="text"
                        placeholder="Search"
                        aria-label="searchTxt"
                        aria-describedby="basic-addon2"
                        value={searchTxt}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <button onClick={handleSubmit} type="button" className="u-hover-nocolor mr-3 p-0 text-secondary shadow-none btnn btn-secondary">
                          <i className="fas fa-search" aria-hidden="true" />
                        </button>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className={`invalid-feedback ${usernametxt ? 'd-block' : ''}`}>Please enter text to search</div>
                  </Form.Group>
                </div>
              </div>
              <Table
                currentPage={currentPage}
                ITEMS_PER_PAGE={ITEMS_PER_PAGE}
                onPageChanged={onPageChanged}
                headers={Tableheaders}
                comments={comments}
                totalResults={totalRes && totalRes ? totalRes : 0}
                param="/admin/agreement&type=type/"
                type="agreementtype"
                handleDeleteFun={handleShow}
              />
            </Col>
          </Row>
        )}
      </Row>
    );
  }
  return (
    <AdminDashboard title="Agreements">
      <Loader isLoading={isloading} />
      <Container fluid className="pb-8 pt-3 pt-md-8">
        {button}
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>You want to delete Agreement: {deleteId ? deleteId.name : ''}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </AdminDashboard>
  );
}

export { AdminAgreement };
