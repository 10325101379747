import { apiCall } from './utils/restClient';
import { config } from '../config/config';
import { headers } from './utils/headers';

function createCourseProgression(courseId, userId) {
  const url = `${config.apiUrl}${config.apiVersion}/courseProgressions`;
  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    data: { courseId, userId },
  };

  return apiCall(requestOptions);
}

const editCourseProgression = (courseId, userId, body) => {
  const url = `${config.apiUrl}${config.apiVersion}/courseProgressions/${courseId}/${userId}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: body,
  };

  return apiCall(requestOptions);
};

function getByUser(userId) {
  const url = `${config.apiUrl}${config.apiVersion}/courseProgressions/byuser/${userId}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

export const courseProgressionsService = {
  createCourseProgression,
  editCourseProgression,
  getByUser,
};
