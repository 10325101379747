import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

import OnboardingSkeleton from '../OnboardingSkeleton/OnboardingSkeleton';
import { TangiButton, TangiSvgIcon, TangiTypography } from '../../_components/TangiLibrary';
import { SharePointWizardProvider } from '_components/SharePointWizard/context/SharePointWizardProvider';
import SharePointBanner from './components/SharePointBanner';
import useSharePointAccess from '../../_hooks/useSharePointAccess';
import { getClientById, updateClientLanguage } from 'redux-toolkit/thunks/clientThunks';
import { agreementService } from '../../_services/agreementService';
import { clientSettingsActions } from '../../_actions/clientSettingsActions';
import { sharePointActions } from 'redux-toolkit/slices/sharePointSlice';
import { LANGUAGE, languageOptions } from 'translations/enums';
import { config } from 'config/config';
import { parseEnvBoolean } from 'utils/parseEnvBoolean';
import { importanceDefaultText, complianceRequirementsRoles } from '_constants/clientSettingsConstants';
import { defineClientLanguageValueAndLabel, transformAgreementsData } from './utils';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';
import { NEUTRAL_SHADES } from 'utils/theme';
import {
  SettingsContainer,
  SettingsRow,
  CenteredColumn,
  SettingsTable,
  SettingsRowTitle,
  SubTitle,
  subTitleCustomStyle,
  titleCustomStyle,
  LanguageContainer,
  LanguageText,
  InfoContainer,
  ConfirmButton,
  customTippy,
  ImportanceContainer,
  AssetImportanceText,
  TitleContainer,
} from './style';

const OnboardingSettings = ({ clientId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [agreementsTypes, setAgreementsTypes] = useState([]);
  const [isFirst, setIsFirst] = useState(false);

  const settings = useSelector((state) => state.clientSettings.settings);
  const clientSettingsLoading = useSelector((state) => state.clientSettings.loading);
  const clientDetails = useSelector((state) => state.client.clientDetails);
  const isSharePointWizardOpen = useSelector((state) => state.sharePoint.isWizardOpen);

  const { isSharePointConnected, isSharePointEnabled } = useSharePointAccess(clientId); // SHAREPOINT PERMISSIONS + INTEGRATION STATUS

  const { control, register, handleSubmit, getValues, setValue } = useForm({ mode: 'all' });

  const welcomeMessage = useMemo(() => {
    if (isFirst) {
      return t('SETTINGS_PAGE.WELCOME_MESSAGE')
        .split('.')
        .map((sentence, index, array) => (
          <TangiTypography color={NEUTRAL_SHADES[800]} customStyles={titleCustomStyle} key={index}>
            {sentence}
            {index !== array.length - 1 && '.'}
            <br />
          </TangiTypography>
        ));
    }
    return null;
  }, [isFirst, t]);

  useEffect(() => {
    dispatch(clientSettingsActions.getClientSettings(clientId));
    agreementService.getAgreementTypes({}).then((response) => {
      setAgreementsTypes(response.data);
    });

    return () => {
      dispatch(clientSettingsActions.clearSettings());
    };
  }, []);

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      setValue('permissions', settings.permissions);
      setValue('agreements', settings.agreements);
    }
  }, [settings]);

  useEffect(() => {
    if (clientDetails && clientDetails?.settings) {
      setIsFirst(false);
    } else {
      setIsFirst(true);
    }
  }, [clientDetails]);

  const getOptions = (results) => {
    const options =
      results &&
      results.map((result) => ({
        value: result.id,
        label: result.name,
      }));
    return options;
  };

  function getFilteredOptions(fieldData, name) {
    if (!fieldData.length || !name) {
      return [];
    }
    const fieldCurrentValues = getValues(name) || {};
    if (!fieldCurrentValues) {
      return getOptions(fieldData);
    }
    return fieldData.reduce((filtered, data) => {
      const fieldOption = { value: data.id, label: data.name };
      if (fieldCurrentValues.id !== fieldOption.value) {
        filtered.push(fieldOption);
      }
      return filtered;
    }, []);
  }

  const saveChanges = (data) => {
    const savedSettings = { ...settings, ...data };
    savedSettings.permissions = { ...savedSettings.permissions, ...getValues().permissions };
    savedSettings.agreements = { ...savedSettings.agreements, ...getValues().agreements };
    savedSettings.agreements = transformAgreementsData(savedSettings.agreements);
    savedSettings.clientId = clientId;

    delete savedSettings.id;
    delete savedSettings.undefined;
    delete savedSettings.peopleTableSettings;
    delete savedSettings.language;

    if (isFirst) {
      const selectedLanguage = getValues().language?.value || LANGUAGE.ENGLISH;
      dispatch(updateClientLanguage({ clientId, language: selectedLanguage }));
    }
    dispatch(clientSettingsActions[isFirst || !settings ? `createClientSettings` : `updateClientSettings`](clientId, savedSettings));

    dispatch(getClientById(clientId));
    history.push(generateRoute(AppRoutes.HOME_LOBBY, { clientId }));
    // reset(savedSettings);
  };

  if (clientSettingsLoading || !agreementsTypes.length || Object.keys(settings).length === 0) {
    return <OnboardingSkeleton />;
  }

  const LanguageSetting = () => {
    const renderLanguageDescription = () => {
      return (
        <LanguageText>
          <TangiTypography weight="semibold">{t('SETTINGS_PAGE.LANGUAGE.SYSTEM_LANGUAGE')}</TangiTypography>
          <InfoContainer>
            <TangiSvgIcon component="info" color={NEUTRAL_SHADES[800]} size="18px" />
            <TangiTypography color={NEUTRAL_SHADES[800]}>{t('SETTINGS_PAGE.LANGUAGE.YOU_CAN_CHANGE')}</TangiTypography>
          </InfoContainer>
        </LanguageText>
      );
    };

    const renderLanguageSelect = () => {
      return (
        <Tippy
          offset={[0, 1]}
          disabled={isFirst}
          content={
            <TangiTypography color={NEUTRAL_SHADES['WHITE']} type={'sub-body'} customStyles={customTippy}>
              {t('SETTINGS_PAGE.TIPPY.SETTING')}
            </TangiTypography>
          }
        >
          <div>
            <Controller
              control={control}
              name="language"
              render={({ field }) => (
                <Select
                  className="ff-din-regular mt-2"
                  isDisabled={!isFirst}
                  {...field}
                  defaultValue={defineClientLanguageValueAndLabel(clientDetails, isFirst)}
                  options={languageOptions}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '159px',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }),
                  }}
                />
              )}
            />
          </div>
        </Tippy>
      );
    };

    return (
      <LanguageContainer>
        {renderLanguageDescription()}
        {renderLanguageSelect()}
      </LanguageContainer>
    );
  };

  const renderComplianceRequirements = () => {
    return complianceRequirementsRoles.map((userRole) => (
      <CenteredColumn key={userRole}>
        {[0, 1].map((index) => (
          <div key={index}>
            {renderComplianceSelect(userRole, index)}
            <br />
          </div>
        ))}
      </CenteredColumn>
    ));
  };

  const renderComplianceSelect = (userRole, index) => {
    const userAgreements = getValues()?.agreements && getValues()?.agreements[userRole] && getValues()?.agreements[userRole][index];
    return (
      <Tippy
        disabled={isFirst}
        content={
          <TangiTypography color={NEUTRAL_SHADES['WHITE']} type={'sub-body'} customStyles={customTippy}>
            {t('SETTINGS_PAGE.TIPPY.SETTING')}
          </TangiTypography>
        }
      >
        <div>
          <Controller
            name={`agreements['${userRole}'][${index}]`}
            control={control}
            render={({ field }) => (
              <Select
                className="ff-din-regular mt-2"
                placeholder={t('SETTINGS_PAGE.PLACEHOLDER.SELECT_ONE')}
                isDisabled={!isFirst}
                {...field}
                defaultValue={{
                  value: userAgreements?.id,
                  label: userAgreements?.name,
                }}
                options={agreementsTypes ? getFilteredOptions(agreementsTypes, `agreements.${userRole}[${index === 1 ? 0 : 1}]`) : []}
              />
            )}
          />
        </div>
      </Tippy>
    );
  };

  return (
    <SettingsContainer isFirst={isFirst}>
      <TangiTypography type="heading-lg" weight="semibold" customStyles={subTitleCustomStyle}>
        {t('SETTINGS_PAGE.TITLE.ORGANIZATION_SETTINGS')}
      </TangiTypography>
      <SubTitle>{welcomeMessage}</SubTitle>
      {isSharePointEnabled && <SharePointBanner isSharePointConnected={isSharePointConnected} onStartIntegration={() => dispatch(sharePointActions.setIsWizardOpen(true))} />}
      <br />
      <Form onSubmit={handleSubmit(saveChanges)}>
        <SettingsTable className="table px-2">
          <tbody>
            <SettingsRowTitle>
              <td className="py-4 ps-3">{t('SETTINGS_PAGE.TITLE.PERMISSIONS')}</td>
              <td className="py-4">{t('SETTINGS_PAGE.TITLE.EMPLOYEES')}</td>
              <td className="py-4">{t('SETTINGS_PAGE.TITLE.CONTRACTORS')}</td>
              <td className="py-4">{t('SETTINGS_PAGE.TITLE.PARTNERS')}</td>
            </SettingsRowTitle>
            <SettingsRow>
              <td className="pt-4 ps-3">{t('SETTINGS_PAGE.UPLOAD_FILES')}</td>
              {complianceRequirementsRoles.map((role) => (
                <CenteredColumn key={role}>
                  <div className="form-check form-switch mt-3">
                    <input className="form-check-input" type="checkbox" {...register(`permissions[${role}].UPLOAD_FILES`)} />
                  </div>
                </CenteredColumn>
              ))}
            </SettingsRow>
            <SettingsRow>
              <td className="pt-4 ps-3">{t('SETTINGS_PAGE.CREATE_ASSET_TYPE')}</td>
              {complianceRequirementsRoles.map((role) => (
                <CenteredColumn key={role}>
                  <div className="form-check form-switch mt-3">
                    <input className="form-check-input" type="checkbox" {...register(`permissions[${role}].SET_ASSET_TYPE`)} />
                  </div>
                </CenteredColumn>
              ))}
            </SettingsRow>
            <SettingsRow>
              <td className="pt-4 ps-3">{t('SETTINGS_PAGE.COMPLIANCE_REQUIREMENTS')}</td>
              {renderComplianceRequirements()}
            </SettingsRow>
          </tbody>
        </SettingsTable>
        {parseEnvBoolean(config.isShowLanguage) && <LanguageSetting />}
        <ImportanceFields control={control} settings={settings} setValue={setValue} importanceDefaultText={importanceDefaultText} isFirst={isFirst} />
        <div className="d-flex justify-content-start mt-3 ms-2">
          <ConfirmButton>{isFirst ? <TangiButton type="submit" text={t('SETTINGS_PAGE.CONFIRM')} /> : <TangiButton type="submit" text={t('SETTINGS_PAGE.SAVE')} />}</ConfirmButton>
        </div>
      </Form>
      {isSharePointEnabled && (
        <SharePointWizardProvider isEditMode={isSharePointConnected} isWizardOpen={isSharePointWizardOpen} onCloseWizard={() => dispatch(sharePointActions.setIsWizardOpen(false))} />
      )}
    </SettingsContainer>
  );
};

const ImportanceFields = ({ control, setValue, settings, importanceDefaultText, isFirst }) => {
  const { t } = useTranslation();

  const resetToDefault = () => {
    importanceDefaultText.forEach((text, index) => {
      setValue(`importanceFields[${index}]`, text);
    });
  };

  useEffect(() => {
    const initialValues = settings?.importanceFields?.length > 0 ? settings.importanceFields : importanceDefaultText;
    initialValues.forEach((text, index) => {
      setValue(`importanceFields[${index}]`, text);
    });
  }, [setValue, settings]);

  const isFieldDisabled = (isFirst, settings) => {
    return isFirst ? !settings?.importanceFields || (isFirst && settings?.importanceFields?.length === 0) : !settings?.importanceFields || settings?.importanceFields?.length !== 0;
  };

  const renderImportanceDetails = () => {
    return (
      <AssetImportanceText>
        <TitleContainer>
          <TangiTypography weight="semibold">{t('SETTINGS_PAGE.IMPORTANCE_FIELDS.ASSET_CATEGORY')}</TangiTypography>
          <TangiButton
            variant="tertiary"
            text={
              <TangiTypography weight="regular" color={NEUTRAL_SHADES[700]}>
                {t('SETTINGS_PAGE.IMPORTANCE_FIELDS.RESET_TO_DEFAULT')}
              </TangiTypography>
            }
            size="small"
            onClick={resetToDefault}
            smallbtn={true}
            disabled={!settings?.importanceFields || settings?.importanceFields?.length !== 0}
          />
        </TitleContainer>
        <TangiTypography color={NEUTRAL_SHADES[800]}>{t('SETTINGS_PAGE.IMPORTANCE_FIELDS.YOU_CAN_CHANGE')}</TangiTypography>
      </AssetImportanceText>
    );
  };

  return (
    <ImportanceContainer>
      {renderImportanceDetails()}
      <Form.Group controlId="importanceFields">
        {importanceDefaultText.map((text, index) => (
          <div style={{ marginBottom: '16px' }} key={index}>
            <TangiTypography weight="bold">{`Category ${index + 1}`}</TangiTypography>
            <Controller
              name={`importanceFields[${index}]`}
              render={({ field }) => (
                <Form.Control
                  className="ff-din-DemiBold"
                  type="text"
                  style={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '22px',
                    color: NEUTRAL_SHADES[1000],
                  }}
                  defaultValue={text}
                  placeholder={`${t('SETTINGS_PAGE.IMPORTANCE_FIELDS.ENTER_CATEGORY')} ${index + 1} ${t('SETTINGS_PAGE.IMPORTANCE_FIELDS.TEXT')}...`}
                  {...field}
                  disabled={isFieldDisabled(isFirst, settings)}
                />
              )}
              control={control}
            />
          </div>
        ))}
      </Form.Group>
    </ImportanceContainer>
  );
};
export default OnboardingSettings;
