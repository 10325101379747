import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const BannerContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid ${NEUTRAL_SHADES[400]};
  padding: ${SPACING[3]};
  margin-bottom: ${SPACING[4]};
`;

export const InfoContainer = styled(FlexContainer)`
  gap: ${SPACING[1]};
  flex-direction: column;
`;

export const TopContainer = styled(FlexContainer)`
  justify-content: space-between;
  width: 100%;
`;

export const HeaderAndChipContainer = styled(FlexContainer)<{ $isConnected: boolean }>`
  flex-direction: column;
  gap: ${SPACING[1]};
  margin-bottom: ${({ $isConnected }) => $isConnected && SPACING[2]};
`;
