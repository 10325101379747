import { useCallback } from 'react';
import { Spinner, Toast, ToastBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { RootState } from '_helpers';
import { XRAY_JOB_STATUS } from 'utils/types/xRay/xRay';
import { ExportCheckboxOption } from 'pages/PatentTool/types';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { NEUTRAL_SHADES, SPACING, WARNING_SHADES } from 'utils/theme';

const CustomToast = styled(Toast)`
  padding: ${SPACING[2]};
  .toast-header {
    justify-content: space-between;
    padding: 0 0 ${SPACING[2]};
  }
  .toast-body {
    padding: ${SPACING[2]} 0;
  }

  .btn-close {
    margin-right: 0;
  }
`;

const ToastWrapper = styled(ToastBody)`
  display: flex;
  gap: ${SPACING[2]};
  flex-direction: column;
`;

const ToastItem = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  align-items: center;
`;

const CustomSpinner = styled(Spinner)`
  width: ${SPACING[4]};
  height: ${SPACING[4]};
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const PatentExportFilesToast = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const checkboxes = useSelector((state: RootState) => state.patent.exportFilesCheckboxes) as ExportCheckboxOption[];
  const isShowExportToast = useSelector((state: RootState) => state.patent.isShowExportToast);

  const renderToastItems = useCallback(() => {
    return checkboxes.map((item, index) => {
      if (!item.checked) return null;

      let iconComponent;
      if (item.isLoading) {
        iconComponent = <CustomSpinner data-testid="spinner" size="sm" animation="border" variant="primary" />;
      } else if (item.status === XRAY_JOB_STATUS.FAILED) {
        iconComponent = (
          <Tippy
            content={
              <TooltipContent>
                <div>{t('PATENT_MAPPING_TOOL.TOAST.WORD_NOT_EXPORTED')}</div>
                <div>{t('PATENT_MAPPING_TOOL.TOAST.PLEASE_TRY_AGAIN')}</div>
              </TooltipContent>
            }
          >
            <span>
              <TangiSvgIcon component="warning" color={WARNING_SHADES[500]} size={SPACING[4]} />
            </span>
          </Tippy>
        );
      } else {
        iconComponent = <TangiSvgIcon component="matchIndication" color="white" size={SPACING[4]} />;
      }

      return (
        <ToastItem key={index}>
          {iconComponent}
          <TangiSvgIcon component={item.icon} />
          <TangiTypography weight="semibold" customStyles="text-overflow: ellipsis;">
            {item.toastLabel}
          </TangiTypography>
        </ToastItem>
      );
    });
  }, [checkboxes]);

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: 'fixed',
        minWidth: '430px',
        bottom: 0,
        right: 0,
        margin: '1rem',
        zIndex: 50,
        backgroundColor: NEUTRAL_SHADES.WHITE,
      }}
    >
      <CustomToast show={isShowExportToast} onClose={() => dispatch(patentActions.setShowExportToast(false))} style={{ minWidth: '100%' }}>
        <CustomToast.Header closeButton>
          <TangiTypography weight="semibold">{t('PATENT_MAPPING_TOOL.TOAST.FILES_EXPORT')}</TangiTypography>
        </CustomToast.Header>
        <ToastWrapper>{renderToastItems()}</ToastWrapper>
      </CustomToast>
    </div>
  );
};

export default PatentExportFilesToast;
