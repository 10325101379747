import styled from 'styled-components';

import { TangiChip } from '_components/TangiLibrary';
import { SPACING } from 'utils/theme';

const ChipContainer = styled.div`
  padding-top: ${SPACING[1]};
  display: flex;
  gap: ${SPACING[1]};
  flex-wrap: wrap;
`;

interface Props {
  value: string[];
}

const ActivityChip = ({ value }: Props) => {
  return (
    <ChipContainer data-testid="activity-chip">
      {value.map((item, index) => (
        <TangiChip key={index} text={item} maxWidth={320} />
      ))}
    </ChipContainer>
  );
};

export default ActivityChip;
