import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const PageContainer = styled.div`
  display: flex;
  padding: 32px 0px;
`;

export const Column = styled(FlexContainer)`
  padding: ${SPACING[5]};
`;

export const ContentColumn = styled(Column)`
  width: 60%;
  gap: ${SPACING[3]};
`;

export const NavigationColumn = styled(Column)`
  width: 20%;
`;

export const ScoreColumn = styled(Column)`
  width: 20%;
  top: 0;
`;

export const BackToContainer = styled.div`
  font-size: 14px;
  font-family: 'DIN2014-Regular';
  display: flex;
  align-items: baseline;
  gap: ${SPACING[2]};
  margin-bottom: ${SPACING[1]};
`;
