import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dashboardActions } from 'redux-toolkit/slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';

import { TangiDropdownButton, TangiTypography } from '_components/TangiLibrary';
import { IdAndName } from 'utils/types/types';
import { RootState } from '_helpers';

enum DepartmentNames {
  AllDepartments = 'All Departments',
  NoDepartments = 'No Departments',
}

enum TranslatedKeys {
  AllDepartments = 'DASHBOARD_PAGE.HEADER.DEPARTMENTS.ALL_DEPARTMENT',
  NoDepartments = 'DASHBOARD_PAGE.HEADER.DEPARTMENTS.NO_DEPARTMENT',
}

const DepartmentsFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const departments: IdAndName[] = useSelector((state: RootState) => state.dashboard.departments);

  const [selectedItem, setSelectedItem] = useState<IdAndName>(departments[0]);

  const getDefaultText = (name: string) => {
    switch (name) {
      case DepartmentNames.AllDepartments:
        return t(TranslatedKeys.AllDepartments);
      case DepartmentNames.NoDepartments:
        return t(TranslatedKeys.NoDepartments);
      default:
        return name || t(TranslatedKeys.AllDepartments);
    }
  };

  const departmentsOptions = useMemo(
    () =>
      departments.map((item) => {
        return { text: getDefaultText(item.name), handleItem: () => handleItemClick(item) };
      }),
    [departments, t],
  );

  useEffect(() => {
    if (departments) {
      setSelectedItem(departments[0]);
    }
  }, [departments]);

  const handleItemClick = (item: IdAndName) => {
    setSelectedItem(item);

    dispatch(dashboardActions.filterDashboardDataByDepartment(item._id));
  };

  return (
    <div className="d-flex gap-2">
      <TangiTypography className="d-flex align-items-center">{t('DASHBOARD_PAGE.HEADER.DEPARTMENTS.TITLE')}</TangiTypography>
      <TangiDropdownButton text={getDefaultText(selectedItem?.name)} variant="secondary" endIcon={true} options={departmentsOptions} data-testid="department-filter-dropdown" />
    </div>
  );
};

export default DepartmentsFilter;
