import { useTranslation } from 'react-i18next';

import { TangiChip, TangiTypography } from '_components/TangiLibrary';
import { HeaderContainer } from './style';
import { NEUTRAL_SHADES } from 'utils/theme';
import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';
import Tippy from '@tippyjs/react';

interface Props {
  title: string;
  lastUpdate: string;
  chipText: TRANSLATION_KEY;
  chipTooltip: TRANSLATION_KEY;
}

const TradeSecretsAnalysisHeadline = ({ title, lastUpdate, chipText, chipTooltip }: Props) => {
  const { t } = useTranslation();
  return (
    <HeaderContainer data-testid="trade-secrets-analysis-headline">
      <TangiTypography type="heading-lg" weight="semibold">
        {title}
      </TangiTypography>
      <TangiTypography color={NEUTRAL_SHADES[800]}>{lastUpdate}</TangiTypography>
      <Tippy content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{t(chipTooltip)}</TangiTypography>}>
        <div>
          <TangiChip variant="secondary" maxWidth={200} text={t(chipText)} />
        </div>
      </Tippy>
    </HeaderContainer>
  );
};

export default TradeSecretsAnalysisHeadline;
