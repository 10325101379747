import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useClient } from './useClient';
import {
  deleteLlmPolicy,
  deletePolicy,
  downloadPolicy,
  updateClientIsDeclaredNoLlmPolicy,
  updateClientIsDeclaredNoTradeSecretPolicy,
  uploadFiles,
  uploadLlmFiles,
} from 'redux-toolkit/thunks/clientThunks';
import { ENTITY_TYPES, MIME_TYPES } from 'utils/enums';
import { DeletePolicyParams, POLICIES, PolicyFileDetails, PolicyType, UpdateIsDeclaredNoFileParams, UploadFilesParams } from 'utils/types/client/client';
import { FileRejection } from 'react-dropzone';
import { LARGE_FILE_CODE_ERROR, MAX_FILE_SIZE, MAX_REJECTED_FILENAME_LENGTH, ONE_MB } from '_constants/fileConstants';
import { useTranslation } from 'react-i18next';
import { formatLongFileName } from 'utils/fileNameUtils';
import { RootState, useAppDispatch } from '_helpers/store';

interface DropFailData {
  isFail: boolean;
  failDescription?: string;
}

export const useHandlePolicy = (policyType: PolicyType) => {
  const dispatch = useAppDispatch();
  const { isLoading: isUploadingOrDeleting, isDownloading } = useSelector((state: RootState) => {
    const { policyLoader, isDownloadingPolicy } = state.client;
    return { isLoading: policyLoader as boolean, isDownloading: isDownloadingPolicy as boolean };
  });

  const { isLawyer, clientId, activeClientId, activeAccount, activeClientName, getClientPolicyFile } = useClient();

  const initialFiles = getClientPolicyFile(policyType);

  const isUserHasPolicy = !!initialFiles?.length && !initialFiles?.[0].isDeclaredNoFile;

  const initialFileDetails = isUserHasPolicy ? initialFiles[0] : null;

  const [droppedFile, setDroppedFile] = useState<File | null>(null);

  const [fileDetails, setFileDetails] = useState<PolicyFileDetails | null>(initialFileDetails);

  const [dropFailData, setDropFailData] = useState<DropFailData | null>(null);
  const { t } = useTranslation();

  const onDropFile = (acceptedFile: File[], fileRejections: FileRejection[]) => {
    if (!acceptedFile?.length) return;
    setDropFailData(null);
    setFileDetails({ fileName: acceptedFile[0].name, fileType: acceptedFile[0].type });
    setDroppedFile(acceptedFile[0]);
    const isLargeFileRejection = fileRejections.length && fileRejections[0]?.errors[0]?.code === LARGE_FILE_CODE_ERROR;
    if (isLargeFileRejection) {
      const maxAcceptedFileSize = MAX_FILE_SIZE / ONE_MB;
      const rejectedFileName = formatLongFileName(fileRejections[0].file.name, MAX_REJECTED_FILENAME_LENGTH);
      const failDescription = t('GENERAL.UPLOAD_FILES_MODAL.FILE_IS_TOO_LARGE', { FILE_NAME: rejectedFileName, FILE_SIZE: maxAcceptedFileSize });
      setDropFailData({ isFail: true, failDescription });
    }
  };

  const removeDroppedFile = () => {
    setDroppedFile(null);
  };

  const removeFileDetails = () => {
    setFileDetails(null);
  };

  const deleteClientPolicy = async () => {
    const params: DeletePolicyParams = {
      isLawyer,
      clientId: clientId ?? activeClientId,
      accountId: activeAccount._id,
      type: policyType,
      isDeleteBeforeUpload: !!droppedFile,
    };
    if (policyType === POLICIES.TRADE_SECRET) {
      await dispatch(deletePolicy(params)).unwrap();
    } else if (policyType === POLICIES.LLM) {
      await dispatch(deleteLlmPolicy(params)).unwrap();
    }
  };

  const downloadDroppedFile = () => {
    if (!droppedFile) return;
    const blobUrl = URL.createObjectURL(droppedFile);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = droppedFile.name;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(blobUrl);
  };

  const downloadUploadedFile = () => {
    const policyFile = getClientPolicyFile(policyType);
    const fileName = policyFile?.[0]?.fileName;
    fileName && dispatch(downloadPolicy({ fileName, type: policyType }));
  };

  const handleDownload = () => {
    droppedFile ? downloadDroppedFile() : downloadUploadedFile();
  };

  const uploadDroppedFile = async () => {
    if (getClientPolicyFile(policyType)?.[0].fileName) {
      await deleteClientPolicy();
    }
    if (droppedFile) {
      const params: UploadFilesParams = {
        clientId: clientId ?? activeClientId,
        policyFiles: [droppedFile],
        type: policyType,
        isLawyer,
        accountId: activeAccount._id,
        entityType: ENTITY_TYPES.ACCOUNTS,
      };

      if (policyType === POLICIES.TRADE_SECRET) {
        dispatch(uploadFiles(params));
      } else if (policyType === POLICIES.LLM) {
        dispatch(uploadLlmFiles(params));
      }
    }
  };

  const updateIsDeclaredNoPolicy = async (isDeclaredNoFile: boolean) => {
    const isFileExists = getClientPolicyFile(policyType)?.[0].fileName;

    if (isFileExists) {
      await deleteClientPolicy();
    }
    const params: UpdateIsDeclaredNoFileParams = {
      clientId: clientId ?? activeClientId,
      isLawyer,
      accountId: activeAccount._id,
      isDeclaredNoFile,
      clientName: activeClientName || '',
    };
    if (policyType === POLICIES.TRADE_SECRET) {
      dispatch(updateClientIsDeclaredNoTradeSecretPolicy(params));
    } else if (policyType === POLICIES.LLM) {
      dispatch(updateClientIsDeclaredNoLlmPolicy(params));
    }
  };

  const acceptedFilesList = Object.values(MIME_TYPES).join(', ');

  return {
    removeDroppedFile,
    uploadDroppedFile,
    onDropFile,
    acceptedFilesList,
    dropFailData,
    isUploadingOrDeleting,
    isDownloading,
    fileDetails,
    droppedFile,
    removeFileDetails,
    isUserHasPolicy,
    downloadDroppedFile,
    downloadUploadedFile,
    handleDownload,
    updateIsDeclaredNoPolicy,
    isDeclaredNoFile: initialFiles?.[0].isDeclaredNoFile,
  };
};
