import styled, { css } from 'styled-components';
import { Card } from 'react-bootstrap';

import { NEUTRAL_SHADES, SPACING, PRIMARY_SHADES } from '../../utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const ClientCard = styled(Card)`
  ${({ isActive = false }: { isActive: boolean }) => css`
    border-radius: 0.5rem;
    border: 1px solid ${isActive ? PRIMARY_SHADES[800] : NEUTRAL_SHADES[50]};
    cursor: pointer;
    &:hover {
      border-color: ${!isActive && NEUTRAL_SHADES[500]};
    }
  `}
`;

export const LogoNameContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: ${SPACING[0]};
  text-align: center;

  .tangi-typography {
    margin-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const SubtitleContainer = styled(FlexContainer)`
  padding-block: ${SPACING[3]} ${SPACING[1]};

  .tangi-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${SPACING[1]};
  flex-direction: column;
`;
