import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, FieldValues, Path, PathValue, UnpackNestedValue, useFormContext } from 'react-hook-form';

import { Container } from './style';

interface Props<T extends FieldValues> {
  name: Path<T>;
  label: string;
  disabled?: boolean;
  isEditMode?: boolean;
  dataTestId?: string;
}

export function TangiCheckboxV2<T extends FieldValues>({ name, label, disabled = false, isEditMode = false, dataTestId = 'tangi-checkbox-v2' }: Props<T>) {
  const { control, setValue, trigger } = useFormContext<T>();

  useEffect(() => {
    if (isEditMode) setValue(name, true as UnpackNestedValue<PathValue<T, Path<T>>>);
  }, []);

  return (
    <Container $disabled={disabled}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <Form.Check
            type="checkbox"
            id={`checkbox-${name}`}
            {...field}
            checked={field.value || isEditMode || false}
            disabled={disabled}
            label={label}
            isInvalid={!!fieldState.error}
            data-testid={dataTestId}
            onChange={(e) => {
              field.onChange(e);
              trigger(name);
            }}
          />
        )}
      />
    </Container>
  );
}
