import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isFunction } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TangiButton, TangiDatePicker, TangiIconButton, TangiSelector, TangiSvgIcon, TangiToast, TangiTypography } from '_components/TangiLibrary';
import { createableRoles, createableRolesForManager, isRoleMatch, IRoles } from 'utils/roles';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { formToApiData, isValuesUnique, handleKeyPress } from './utils';
import { createMultipleUsers } from 'redux-toolkit/thunks/usersThunks';
import { validEmailPattern } from 'utils/formUtils';
import { getInventorsAccounts } from 'redux-toolkit/thunks/patentThunks';
import {
  StyledModalBody,
  StyledForm,
  DuoContainer,
  LabelsContainer2,
  StyledModalFooter,
  ContentRowContributors,
  StyledTangiInput,
  TangiInputContainerContributors,
  StyledModalHeader,
  RequiredStar,
  TangiTypographyLabel,
} from './style';
import { Divider, StyledModal } from 'utils/globalStyles';

const InviteAsContributors = ({ namesOfInventors, handleSkipAddAccounts, onClose, showInviteAsContributorsModal, onFinish, additionalFields = {}, Role = '' }) => {
  const { invitedMultipleAccounts, inviteMultipleLoading, inviteMultipleError } = useSelector((state) => state.users);
  const [inviteAccountsToastProps, setInviteAccountsToastProps] = useState({ show: false, text: '', type: '' });

  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm({});

  const { fields, remove } = useFieldArray({
    control,
    name: 'data',
  });

  const handleClose = () => {
    reset({
      data: namesOfInventors.map((name) => ({ name, role: createableRoles[1] })),
    });
    clearErrors();
    onClose();
  };

  const onSubmit = (data) => {
    const hasDuplicates = !isValuesUnique(data.data.map((item) => item.email));
    if (hasDuplicates) {
      fields.forEach((_, index) => {
        setError(`data[${index}].email`, {
          type: 'manual',
          message: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ERROR_MESSAGE.EMAIL_SHOULD_BE_UNIQUE')}`,
        });
      });
      return;
    }
    const transformedData = formToApiData(data, additionalFields);
    const reqBody = { data: transformedData, clientId };
    dispatch(createMultipleUsers(reqBody));
  };

  useEffect(() => {
    reset({
      data: namesOfInventors?.map((name) => ({ name, role: createableRoles[1] })),
    });
  }, [namesOfInventors, reset]);

  useEffect(() => {
    if (invitedMultipleAccounts && invitedMultipleAccounts.length) {
      dispatch(getInventorsAccounts({ inventors: namesOfInventors, clientId }));
      setInviteAccountsToastProps({ show: true, text: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.TOAST.USERS_ADDED_SUCCESSFULLY', { NUM: invitedMultipleAccounts.length })}`, type: 'success' });
      handleClose();
      if (isFunction(onFinish)) {
        onFinish();
      }
    }
  }, [invitedMultipleAccounts]);

  useEffect(() => {
    if (inviteMultipleError) {
      setInviteAccountsToastProps({ show: true, text: inviteMultipleError, type: 'error' });
    }
  }, [inviteMultipleError]);

  return (
    <>
      <StyledModal transition="Fade" show={showInviteAsContributorsModal} onHide={inviteMultipleLoading ? () => {} : handleClose} data-testid="invite-accounts-modal">
        <StyledModalHeader>
          <div>
            <TangiSvgIcon size="32px" component="addUser"></TangiSvgIcon>
            <div>{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ADD_INVENTORS_AS_CONTRIBUTORS')}</div>
          </div>
          <div>{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.TOOLTIP.ADD_INVENTORS_AS_USERS')}</div>
        </StyledModalHeader>

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledModalBody>
            {fields && !!fields.length && (
              <LabelsContainer2>
                <TangiTypography>
                  <Form.Label>{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.EMAIL')}</Form.Label>
                  <RequiredStar>*</RequiredStar>
                </TangiTypography>

                <TangiTypography>
                  <Form.Label>{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.ROLE')}</Form.Label>
                </TangiTypography>

                <TangiTypography>
                  <Form.Label>{t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.HIRE_DATE')}</Form.Label>
                </TangiTypography>
              </LabelsContainer2>
            )}

            {fields &&
              !!fields.length &&
              fields.map((item, index) => (
                <ContentRowContributors key={index} data-testid="invite-account-row">
                  <TangiTypographyLabel weight="semibold">{item.name}</TangiTypographyLabel>
                  <TangiInputContainerContributors data-testid="email-contributors-patent">
                    <StyledTangiInput
                      register={register}
                      name={`data.${index}.email`}
                      error={errors.data?.[index]?.email}
                      registerRules={{
                        required: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ERROR_MESSAGE.EMAIL_IS_REQUIRED')}`,
                        pattern: {
                          value: validEmailPattern,
                          message: `${t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.ERROR_MESSAGE.INVALID_EMAIL')}`,
                        },
                      }}
                      onKeyPress={(e) => handleKeyPress(e)}
                    />
                  </TangiInputContainerContributors>

                  <DuoContainer>
                    <div className="tangi-selector-container">
                      <TangiSelector
                        label={t('PATENT_TOOL.ADD_NEW_CONTRIBUTORS.INPUT_LABEL.ROLE')}
                        required
                        shouldRenderLabel={false}
                        name={`data.${index}.role`}
                        options={isRoleMatch(Role, IRoles.EMPLOYEE_MANAGER) ? createableRolesForManager() : createableRoles}
                        error={errors.data?.[index]?.role}
                        control={control}
                        menuScroll={true}
                      />
                    </div>
                    <div className="tangi-datepicker-container">
                      <TangiDatePicker name={`data.${index}.dateOfJoining`} error={errors.data?.[index]?.dateOfJoining} register={register} onKeyPress={(e) => handleKeyPress(e)} />
                    </div>
                  </DuoContainer>
                  <TangiIconButton
                    icon="remove"
                    variant={BUTTON_VARIANTS.TERTIARY_GREY}
                    onClick={() => {
                      remove(index);
                      if (fields.length === 1) {
                        onClose();
                      }
                    }}
                    className="remove-item-button"
                  />
                </ContentRowContributors>
              ))}
          </StyledModalBody>
          <StyledModalFooter>
            <Divider />
            <span>
              <TangiButton
                variant="tertiary"
                text={t('GENERAL.BUTTONS_TEXT.SKIP')}
                size="small"
                onClick={() => {
                  handleSkipAddAccounts();
                  handleClose();
                }}
                disabled={inviteMultipleLoading}
                data-testid="cancel-invite-accounts"
              />
            </span>
            <span>
              <TangiButton text="Add contributors" size="small" type="submit" loading={inviteMultipleLoading} data-testid="add-contributors-patent" disabled={dirtyFields.data === undefined} />
            </span>
          </StyledModalFooter>
        </StyledForm>
      </StyledModal>
      <TangiToast
        {...inviteAccountsToastProps}
        onSuccess={() => {
          setInviteAccountsToastProps({ show: false, type: '', text: '' });
        }}
      />
    </>
  );
};

export default InviteAsContributors;
