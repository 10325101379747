import React from 'react';
import Tippy from '@tippyjs/react/headless';
import CompliantTooltip from '../CompliantTooltip/CompliantTooltip';
import successIcon from '../../assets/img/check-circle-solid.png';
import pendingIcon from '../../assets/img/exclamation-circle-solid.png';

const Compliant = ({ agreements, tradeSecrets, userName, clientId }) => {
  return (
    <Tippy trigger="mouseenter" placement="top-end" arrow={false} render={() => <CompliantTooltip agreements={agreements} tradeSecrets={tradeSecrets} userRole={userName} clientId={clientId} />}>
      {agreements && agreements[1] && agreements[1].signed && agreements[0] && agreements[0].signed ? (
        <img className="m-auto" width="16" height="16" alt="..." src={successIcon} />
      ) : (
        <img className="m-auto" width="16" height="16" alt="..." src={pendingIcon} />
      )}
    </Tippy>
  );
};
export default Compliant;
