import styled from 'styled-components';

export const InsightContainer = styled.div<{ isEmpty: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
  gap: 5%;

  .tangi-typography:first-of-type {
    min-width: 40%;
  }
  .avatar-name-icon-container {
    max-width: 50%;
  }
  .avatar-name-container {
    max-width: 100%;
  }
  .tangi-typography.widget-display-name {
    max-width: 80%;
  }
`;
