import { useTranslation } from 'react-i18next';

import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { getDataTime } from '../../../utils/dateUtils';

const AssetItem = ({ action, asset, assetType, removeAssetFunc }) => {
  const { t } = useTranslation();

  return (
    <>
      {assetType === 'File' ? (
        <li style={{ listStyle: 'none' }} className={`${action !== 'edit' && 'list-group-item'} border border-black rounded-3`}>
          <div className="px-4 py-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <i aria-hidden="true" className={`${icons[getFileExtensionCard(asset.name)]} me-2 icon-big`} />
                {action === 'edit' ? (
                  <div className="m-2">
                    <span>{asset.name}</span>
                    <span className="text-muted fs-7 d-block m-auto ff-din-DemiBold">
                      {t('ASSET_PAGE.CREATE_EDIT_ASSET.EDIT_MODE_ASSET_ITEM.UPLOADED')}: {getDataTime(asset.uploadedOn)}
                    </span>
                  </div>
                ) : (
                  <div className="m-2">{`File: ${asset.name} Size: ${asset.size} bytes`}</div>
                )}
              </div>
              <button type="button" onClick={() => removeAssetFunc(action === 'edit' ? asset._id : asset.name, 'File')} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                <i className="far fa-trash-alt" aria-hidden="true" />
              </button>
            </div>
          </div>
        </li>
      ) : (
        <li style={{ listStyle: 'none' }} className={`${action !== 'edit' && 'list-group-item'} border border-black rounded-3`}>
          <div className="px-4 py-1">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center justify-content-center">
                <i aria-hidden="true" className={`${icons.link} me-2 icon-big text-primary`} />
                {action === 'edit' ? (
                  <div className="m-2">
                    <span>{asset.label}</span>
                    <span className="text-muted fs-7 d-block m-auto ff-din-DemiBold">Uploaded: {getDataTime(asset.uploadedOn)}</span>
                  </div>
                ) : (
                  <div className="m-2">
                    <span style={{ display: 'flex' }}>
                      File Location:<span className="text">{asset.url}</span> File Label: {asset.label}
                    </span>
                  </div>
                )}
              </div>
              <button type="button" onClick={() => removeAssetFunc(action === 'edit' ? asset._id : asset.label, 'Url')} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                <i className="far fa-trash-alt" aria-hidden="true" />
              </button>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default AssetItem;
