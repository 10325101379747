/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { Form, Table, Button, Spinner, Col, Modal, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { getFileExtensionCard, icons } from '../../../utils/fileUtils';
import { adminUserActions } from '../../../AdminRedux/_actions/adminUserActions';
import { agreementActions } from '../../../_actions/agreementActions';
import { adminAgreementService } from '../../../AdminRedux/_services/adminAgreementService';

import { Loader } from '../../../_components/Loader';
import { AdminDashboard } from '../AdminDashboard';
import { adminAgreementActions } from '../../../AdminRedux/_actions/adminAgreementActions';
// import { EditAsyncSelect, optionTypes } from '../../../_components/EditAsyncSelect';
import './AdminAgreementEditView.scss';

function AdminAgreementEditView() {
  const formTypes = { EDIT_AGREEMENT: 'edit', VIEW_AGREEMENT: 'view' };

  const agreementStatusMsgs = {
    updatingAgreement: { status: 'updatingAgreement' },
    agreementUpdated: { status: 'agreementUpdated' },
    agreementUpdateSuccess: { status: 'agreementUpdateSuccess', msg: 'Agreements updated Successfully', color: '#4ABB79' },
    agreementUpdateFailed: { status: 'agreementUpdateFailed', color: '#F75676' },
  };

  const { accountId, type: formType } = useParams();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [deleteAgreementSpinner, setDeleteAgreementSpinner] = useState(null);
  const [deleteAgreement, setDeleteAgreement] = useState({ index: '', id: '', name: '' });
  // const [defaultTypes, setDefaultTypes] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [formFiles, setFormFiles] = useState([]);
  const [defaultUser, setDefaultUser] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [updateStatus, setUpdateStatus] = useState('');

  const handleClose = () => {
    setDeleteAgreementSpinner(false);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const { types } = useSelector((state) => state.agreement);
  const { status: agreementStatus, updateAgreement } = useSelector((state) => state.adminAgreementReducer);
  const { getUsersById } = useSelector((state) => state.adminUserReducer);

  const {
    register,
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      agreement: [
        {
          agreementType: '',
          signed_date: '',
          expiry_date: '',
          signed: false,
          id: '',
          files: [],
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control: control,
    name: 'agreement',
  });

  const agreement = useWatch({
    control: control,
    name: 'agreement',
  });

  useEffect(() => {
    setFormValues(agreement);
  }, [agreement]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(adminUserActions.getAdminAccountsById(accountId));
    dispatch(agreementActions.getAgreementTypes(1000));

    return () => {
      dispatch(adminAgreementActions.clearUpdateStatus());
    };
  }, []);

  useEffect(() => {
    switch (agreementStatus) {
      case agreementStatusMsgs.updatingAgreement.status:
        break;
      case agreementStatusMsgs.agreementUpdated.status:
        break;
      case agreementStatusMsgs.agreementUpdateSuccess.status:
        setUpdateStatus(agreementStatusMsgs.agreementUpdateSuccess);
        setIsLoading(false);
        dispatch(adminUserActions.getAdminAccountsById(accountId));
        setTimeout(() => {
          setUpdateStatus('');
        }, 3000);
        break;
      case agreementStatusMsgs.agreementUpdateFailed.status:
        break;
      default:
        break;
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 5000);
  }, [agreementStatus, updateAgreement]);

  useEffect(() => {
    if (!_.isEmpty(getUsersById)) {
      if (getUsersById && getUsersById?.data.length > 0) {
        const tempAgreement = [];
        const tempAgreementType = [];
        const tempSigned = [];

        const { displayName, id, role } = getUsersById?.data[0];

        setDefaultUser({ id, displayName, role });

        for (const agreementItem of getUsersById.data[0].agreements) {
          const { expiry_date, signed_date, signed, files, agreementType, id } = agreementItem;

          const expiryDate = expiry_date ? expiry_date?.split('T')[0] : '';
          const signedDate = signed_date ? signed_date?.split('T')[0] : '';

          tempAgreementType.push({ value: agreementType._id, label: agreementType.name });
          tempSigned.push(signed);
          formFiles.push({ id, files });

          tempAgreement.push({ expiry_date: expiryDate, signed_date: signedDate, signed, files, id, agreementType: agreementType._id });
        }
        setFormValues(tempAgreement);

        reset({
          agreement: tempAgreement,
        });

        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      }
    }
  }, [getUsersById]);

  useEffect(() => {
    clearErrors('duplicates');
  }, [agreement]);

  const isViewAgreement = () => formTypes.VIEW_AGREEMENT === formType;

  function onsubmit(data) {
    setIsLoading(true);
    data.agreement.map((item, index) => {
      if (item.id === formFiles[index]?.id) {
        item.files = formFiles[index].files;
      }
      if (item.signed === false) {
        item.signed_date = '';
        item.expiry_date = '';
      }
      return item;
    });
    dispatch(adminAgreementActions.updateAgreementWithFiles(data));
  }

  function handleFiles(files, index) {
    const formValue = formFiles[index];
    formValue.files = files;

    const finalArr = Object.assign([], formFiles, { [index]: formValue });
    setFormFiles(finalArr);
  }
  const removeFileFunc = (index) => {
    const formValue = formFiles[index];
    formValue.files = [];

    const finalArr = Object.assign([], formFiles, { [index]: formValue });
    setFormFiles(finalArr);
  };

  function deleteAgreementFunc(index) {
    const { displayName } = getUsersById.data[0];
    const { id, agreementType } = agreement[index];
    const type = types && types.length > 0 && types[0].data && types[0].data.find((element) => element.id === agreementType);
    setDeleteAgreement({ agreementId: id, index, agreementType: type.name, accountDisplayName: displayName });
    handleShow();
  }

  function handleDeleteAgreement() {
    setDeleteAgreementSpinner(true);

    adminAgreementService
      .deleteAgreement(deleteAgreement.agreementId)
      .then(() => {
        // dispatch(adminUserActions.getAdminAccountsById(accountId));
        window.location.reload();
        handleClose();
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      })
      .catch(() => {});
  }
  function DeleteConfirmationPlaceholder() {
    return (
      <div className="d-flex justify-content-center content-center flex-column gap-1">
        <div className="fs-5">Are you sure?</div>
        <div className="ff-din-regular-2 text-center">
          Do you really want to delete agreement of type <br />
          <span className="ff-din-bold">{deleteAgreement?.agreementType}</span>
          <br />
          from user
          <br />
          <span className="ff-din-bold">{deleteAgreement.accountDisplayName}</span>?
        </div>
      </div>
    );
  }

  // const isUniquetType = async (itemId, ind) => {
  //   for (const [index, value] of [...agreement].entries()) {
  //     if (index !== ind && value.agreementType === itemId) {
  //       return 'Agreement type already exists';
  //     }
  //   }
  //   return null;
  // };

  // const customStyles = {
  //   container: (provided) => ({
  //     ...provided,
  //     minWidth: 200,
  //     width: 250,
  //   }),
  // };

  return (
    <>
      <AdminDashboard title="Agreements">
        <Loader isLoading={isLoading} />
        <Container fluid className="border-0 pb-8 pt-3 pt-md-8">
          <Row>
            <Col sm={12}>
              <Row className="m-0">
                <div className="col-md-12 col-lg-12 mx-auto">
                  <div className="border-0 rounded card agreement-container">
                    <Form className={`my-4 mx-4 ${isViewAgreement() && 'form-disabled'}`} onSubmit={handleSubmit(onsubmit)}>
                      <h2 className="text-dark font-weight-normal">{!isViewAgreement() ? 'Edit Agreements' : 'View Agreements'}</h2>
                      <hr />
                      <div className="px-3 mt-3 border-0">
                        <div className="text-dark fs-7">User</div>
                        <div className="fs-5"> {defaultUser?.displayName && defaultUser?.displayName}</div>
                      </div>

                      {defaultUser?.role?.name && (
                        <div className="px-3 mt-3 border-0">
                          <div className="text-dark fs-7">User Role</div>
                          <div className="fs-5"> {defaultUser?.role?.name && defaultUser?.role?.name}</div>
                        </div>
                      )}

                      <div className="px-3 mt-3 border-0 table-response">
                        <div className="text-dark fs-7">Agreements</div>
                        <Table bordered className="rounded zoom-90">
                          <thead>
                            <tr style={{ backgroundColor: '#e3e3e3' }}>
                              {/* <th colSpan="3" className="col-3">
                                Type
                              </th> */}
                              <th colSpan="3" className="col-3">
                                Signed File
                              </th>
                              <th colSpan="1" className="col-1">
                                Signed
                              </th>
                              <th colSpan="3">Executed On</th>
                              <th colSpan="3">Expiry Date</th>
                              {!isViewAgreement() && <th>{}</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {fields.map((field, index) => (
                              <tr key={field.id}>
                                {/* <td colSpan="3" className="col-3">
                                  <Controller
                                    name={`agreement[${index}].agreementType`}
                                    render={({ field: { ref, ...rest } }) => (
                                      <EditAsyncSelect
                                        styles={customStyles}
                                        defaultValue={defaultTypes[index]}
                                        inputRef={ref}
                                        selectStyles="ff-din-DemiBold"
                                        type={optionTypes.AGREEMENT_TYPES}
                                        {...rest}
                                      />
                                    )}
                                    control={control}
                                    rules={{
                                      required: { value: true, message: 'Agreement Type is required' },
                                      // validate: (type) => isUniquetType(type, index),
                                    }}
                                  />

                                  <div style={{ fontSize: 'inherit' }} className="d-block invalid-feedback">
                                    {errors.agreement?.[index]?.agreementType && errors.agreement?.[index]?.agreementType.message}
                                  </div>
                                </td> */}
                                <td colSpan="3">
                                  {formFiles[index]?.files?.length > 0 ? (
                                    <li style={{ listStyle: 'none' }} className="border border-black rounded-3">
                                      <div className="px-3 py-1">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="d-flex align-items-center justify-content-center">
                                            <i aria-hidden="true" className={`${icons[getFileExtensionCard(formFiles[index]?.files[0]?.name)]} me-2 icon-medium `} />

                                            <span>{formFiles[index]?.files[0]?.name}</span>
                                          </div>
                                          <button type="button" onClick={() => removeFileFunc(index)} className="btn del-btnt btn-sm btn btn-secondary remove-btn">
                                            <i className="far fa-trash-alt" aria-hidden="true" />
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  ) : (
                                    <Dropzone multiple={false} onDrop={(file) => handleFiles(file, index)}>
                                      {({ getRootProps, getInputProps }) => (
                                        <>
                                          <div {...getRootProps()} className="u-focus-outline dropzoneAgreement text-center text-muted p-2">
                                            <input {...getInputProps()} />

                                            <p className="text-black">
                                              Drag and drop photo, or <span className="card-link text-primary text-decoration-underline color-hover">browse</span>
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </Dropzone>
                                  )}
                                </td>

                                <td colSpan="1" className="col-1">
                                  <div>
                                    <input type="checkbox" className="d-block container h-20" id={`agreement[${index}].signed`} {...register(`agreement[${index}].signed`)} />

                                    <label className="d-flex justify-content-center text-center" htmlFor={`agreement[${index}].signed`}>
                                      {formValues[index]?.signed ? 'Signed' : 'Not Signed'}
                                    </label>
                                  </div>
                                </td>

                                <td colSpan="3">
                                  <Form.Control
                                    required={formValues[index]?.signed}
                                    type="date"
                                    disabled={!formValues[index]?.signed}
                                    {...register(`agreement[${index}].signed_date`, { required: { value: false, message: 'Signed Date is required' } })}
                                  />
                                </td>
                                <td colSpan="3">
                                  <Form.Control type="date" {...register(`agreement[${index}].expiry_date`)} disabled={!formValues[index]?.signed} />
                                </td>

                                {!isViewAgreement() && (
                                  <td colSpan="3">
                                    <Button variant="secondary" className="u-hover-black rounded u-focus-black" type="button" onClick={() => deleteAgreementFunc(index)}>
                                      DELETE
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {fields && fields.length === 0 && <div className="p-3 text-center w-100">No results found !</div>}
                        {errors?.duplicates?.type === 'duplicates' && (
                          <div className="border-0 d-flex justify-content-between row">
                            <Col className="col-12">
                              <div style={{ backgroundColor: '#FC7C5F' }} className="alert alert-primary fade show rounded py-2 text-white text-center">
                                <span style={{ fontSize: '13px' }} className="mx-3">
                                  {errors.duplicates?.message}
                                </span>
                              </div>
                            </Col>
                          </div>
                        )}
                      </div>

                      {!isViewAgreement() && (
                        <div className="d-flex justify-content-center">
                          <Button disabled={!agreement.length > 0 && true} className="my-4 mx-4 px-5 fs-6 btn rounded-pill button-letter-space-none btn-primary text-white my-4 p-3" type="submit">
                            Update Agreements
                          </Button>
                        </div>
                      )}
                      {updateStatus && (
                        <div style={{ backgroundColor: updateStatus.color, borderColor: updateStatus.color }} className="alert alert-primary fade show rounded py-2 text-white">
                          <span style={{ fontSize: '13px' }} className="mx-3">
                            {updateStatus && updateStatus.msg}
                          </span>
                        </div>
                      )}
                    </Form>
                    {/* {isViewAgreement() && (
                      <Link className="d-flex justify-content-center" to={`/admin/agreement/edit/${defaultUser.id}`}>
                        <Button className="my-4 mx-4 px-5 fs-6 btn rounded-pill button-letter-space-none btn-primary text-white my-4 p-3" type="button">
                          Edit Agreement
                        </Button>
                      </Link>
                    )} */}
                    <div className="delete-confirm">
                      <Modal show={show} onHide={handleClose} backdrop="static" className="delete-confirm">
                        <Modal.Body>
                          <DeleteConfirmationPlaceholder name="test.jpg" />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" className="asset-delete-cancel" onClick={handleClose}>
                            Cancel
                          </Button>
                          {deleteAgreementSpinner ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            <Button className="asset-delete-confirm" onClick={handleDeleteAgreement}>
                              Delete
                            </Button>
                          )}
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </AdminDashboard>
    </>
  );
}

export { AdminAgreementEditView };
