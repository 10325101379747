import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const StyledFormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[2]};
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const RadioInput = styled.input`
  margin: ${SPACING[0.5]};
  width: ${SPACING[3]};
  height: ${SPACING[3]};
  border: ${SPACING[0.5]} solid ${NEUTRAL_SHADES.WHITE};
  appearance: none;
  background-color: ${NEUTRAL_SHADES.WHITE};
  transition: background-color 0.3s, box-shadow 0.3s;
  background-clip: border-box;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 1px ${NEUTRAL_SHADES[700]};

  :checked {
    box-shadow: 0px 0px 0px 1px ${PRIMARY_SHADES[800]};
    background-color: ${PRIMARY_SHADES[800]};
  }
  :disabled {
    opacity: 0.5;
  }
  :hover:not(:checked):not(:disabled) {
    box-shadow: 0px 0px 0px 1px ${NEUTRAL_SHADES[900]};
  }
  :hover:checked:not(:disabled) {
    box-shadow: 0px 0px 0px 1px ${PRIMARY_SHADES[900]};
    background-color: ${PRIMARY_SHADES[900]};
  }
`;
