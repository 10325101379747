import React, { useMemo } from 'react';
import { TangiTypography } from '_components/TangiLibrary';
import { t } from 'i18next';
import { ContractChip } from '../ContractChip/ContractChip';
import { getScoreColor, getTextByScore } from './utils';
import { HeaderContainer } from './styles';

interface ContractHeaderProps {
  score: number;
  filename: string;
}

export const ContractHeader: React.FC<ContractHeaderProps> = ({ score, filename }) => {
  const scoreStatus = useMemo(() => getScoreColor(score), [score]);
  const textByScore = useMemo(() => getTextByScore(score), [score]);

  return (
    <HeaderContainer>
      <TangiTypography type="heading-lg">{filename}</TangiTypography>
      <ContractChip variant={scoreStatus} text={t(textByScore)} />
    </HeaderContainer>
  );
};
