import { adminUserContants } from '../_constants/adminUserContants';

const initialState = { results: [], createUser: {}, errors: {}, profile: {}, getUser: {}, updateUser: {}, resetPassword: null, getUsersById: {} };

export function adminUserReducer(state = initialState, action) {
  switch (action.type) {
    case adminUserContants.ADMIN_USER_REQUEST:
      return {
        ...state,
        results: [],
      };
    case adminUserContants.ADMIN_USER_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    case adminUserContants.ADMIN_USER_FAILURE:
      return {
        ...state,
        results: [],
      };

    case adminUserContants.CREATE_ADMIN_USER:
      return {
        ...state,
        createUser: {},
        profile: {},
      };
    case adminUserContants.CREATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        createUser: action.payload,
      };
    case adminUserContants.CREATE_ADMIN_USER_FAILURE:
      return {
        ...state,
        createUser: {},
        profile: {},
        errors: action.errors,
      };

    case adminUserContants.ADMIN_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case adminUserContants.ADMIN_USER_PROFILE_FAILURE:
      return {
        ...state,
        profile: {},
        errors: action.errors,
      };

    case adminUserContants.CLEAR_ADMIN_USER_ERRORS:
      return {
        ...state,
        errors: {},
      };

    case adminUserContants.GET_USER_REQUEST:
      return {
        ...state,
        getUser: {},
      };
    case adminUserContants.GET_USER_SUCCESS:
      return {
        ...state,
        getUser: action.payload,
      };
    case adminUserContants.GET_USER_FAILURE:
      return {
        ...state,
        getUser: {},
        errors: action.errors,
      };

    case adminUserContants.UPDATE_ADMIN_USER:
      return {
        ...state,
        updateUser: {},
        profile: {},
      };
    case adminUserContants.UPDATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        updateUser: action.payload,
      };
    case adminUserContants.UPDATE_ADMIN_USER_FAILURE:
      return {
        ...state,
        updateUser: {},
        profile: {},
        errors: action.errors,
      };

    case adminUserContants.ADMIN_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: {},
      };
    case adminUserContants.ADMIN_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: action.user,
      };
    case adminUserContants.ADMIN_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: action.error,
      };
    case adminUserContants.CLEAR_ADMIN_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: null,
      };

    case adminUserContants.GET_USERS_BY_ID_REQUEST:
      return {
        ...state,
        getUsersById: {},
      };
    case adminUserContants.GET_USERS_BY_ID_SUCCESS:
      return {
        ...state,
        getUsersById: action.payload,
      };
    case adminUserContants.GET_USERS_BY_ID_FAILURE:
      return {
        ...state,
        getUsersById: {},
        errors: action.errors,
      };
    default:
      return state;
  }
}
