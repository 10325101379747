import { IconTypes } from '_components/TangiLibrary';

export interface ILobbyCard {
  cardId: LOBBY_CARD_ID;
  icon: IconTypes;
  isLocked?: boolean;
}

export enum LOBBY_CARD_ID {
  PREDICTED_PORTFOLIO = 'PREDICTED_PORTFOLIO',
  COMPETITIVE_INTELLIGENCE = 'COMPETITIVE_INTELLIGENCE',
  CONTINUATION_ANALYSIS = 'CONTINUATION_ANALYSIS',
  PATENT_X_RAY = 'PATENT_X_RAY',
  INVENTION_DISCLOSURE = 'INVENTION_DISCLOSURE',
  ASSETS_CATALOG = 'ASSETS_CATALOG',
  ADD_EMPLOYEES = 'ADD_EMPLOYEES',
  LEARN = 'LEARN',
  SELECT_COURSES = 'SELECT_COURSES',
  TRACK_PROGRESS = 'TRACK_PROGRESS',
  COLLABORATE_WITH_PARTNERS = 'COLLABORATE_WITH_PARTNERS',
  MANAGE = 'MANAGE',
  DISCOVER = 'DISCOVER',
}

export type CardsListDicType = Record<string, ILobbyCard[]>;
