import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import InventionCard from '../InventionCard/InventionCard';
import InventionCardTitle from '../InventionCard/InventionCardTitle';
import InventionTradeSecrets from './InventionTradeSecrets';
import withAssetCreation from '_hocs/XRayAssetCreation/XRayAssetCreation';
import { useAssociation } from '../../../../_context/InventionDisclosure/AssociationContext';

import { assetsActions } from 'redux-toolkit/slices/assetsSlice';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { PotentialTradeSecret } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { CurrentTradeSecret, DropdownOption } from '_hocs/XRayAssetCreation/types';
import { CREATED_FROM } from 'utils/enums';

const InventionTradeSecretsWithAssetCreation = withAssetCreation(InventionTradeSecrets);

interface Props {
  tradeSecrets: PotentialTradeSecret[];
  clientId: string;
  optionsWithCustomCallbacks?: (currTradesecret: CurrentTradeSecret, index: number) => DropdownOption[];
}

const InventionTradeSecretsSection = ({ tradeSecrets, clientId }: Props) => {
  const [currentTradeSecret, setCurrentTradeSecret] = useState<CurrentTradeSecret>({ title: '', text: '' });

  const { scrollTSContainerRef } = useAssociation();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickOnDropdownItem = (currTradesecret: CurrentTradeSecret, index: number): void => {
    setCurrentTradeSecret({ ...currTradesecret, index });
    dispatch(assetsActions.setClearAsset());
  };

  return (
    <InventionCard
      scrollRef={scrollTSContainerRef}
      title={<InventionCardTitle titleText={t('INVENTION_DISCLOSURE.POTENTIAL_TRADE_SECRETS')} count={tradeSecrets?.length ?? 0} />}
      body={
        <InventionTradeSecretsWithAssetCreation
          tradeSecrets={tradeSecrets}
          createdFrom={CREATED_FROM.INVENTION_DISCLOSURE}
          clientId={clientId}
          currentTradeSecret={currentTradeSecret}
          onAssetCreationComplete={() => dispatch(inventionDisclosureActions.setAssetCreated(currentTradeSecret?.index ?? -1))}
          handleClickOnDropdownItem={handleClickOnDropdownItem}
        />
      }
    />
  );
};

export default InventionTradeSecretsSection;
