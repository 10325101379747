import styled from 'styled-components';
import { ACCOUNT_STATUS } from '../../../utils/enums';
import { NEUTRAL_SHADES } from 'utils/theme';

export const Container = styled.div`
  background-color: ${({ status }) => getBackgroundColor(status)};
  padding: 5px 10px;
  height: 28px;
  border-radius: 4px;
  font-family: 'DIN2014-Regular';
  width: fit-content;
  line-height: 18px;
  font-size: ${({ font = 14 }) => `${font}px`};
`;

const lightGreen = '#EDFFFA';
const lightRed = '#FFEBEB';
const lightYellow = '#FFFCE6';

const getBackgroundColor = (status) => {
  switch (status) {
    case ACCOUNT_STATUS.DISABLED:
      return lightRed;
    case ACCOUNT_STATUS.ACTIVE:
      return lightGreen;
    default: // ACCOUNT_STATUS.PENDING
      return lightYellow;
  }
};
