import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const ImageContainer = styled(FlexContainer)`
  justify-content: center;

  animation: fade-in 1.5s;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const breakWordStyles = `
  white-space: pre-line;
`;

export const StyledImage = styled.img`
  max-width: 370px;
`;
