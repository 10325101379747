import { CSSProperties } from 'react';
import { ProgressBar, ProgressBarContainer } from './styles';

interface TangiProgressBarProps {
  percentage: number;
  trackColor?: CSSProperties['color'];
  indicatorColor?: CSSProperties['color'];
}

export const TangiProgressBar = ({ percentage, trackColor, indicatorColor }: TangiProgressBarProps) => {
  return (
    <ProgressBarContainer $backgroundColor={trackColor}>
      <ProgressBar $percentage={percentage} $backgroundColor={indicatorColor} />
    </ProgressBarContainer>
  );
};
