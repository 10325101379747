import styled from 'styled-components';

import { TangiButton } from '_components/TangiLibrary';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { Form } from 'react-bootstrap';

export const PatentButtonAndInputContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${SPACING[2]};
  padding-bottom: ${SPACING[2]};
`;

export const UploadButton = styled(TangiButton)`
  margin-bottom: ${SPACING[8]};
`;

export const InputContainer = styled.div`
  width: 100%;
  font-family: 'DIN2014-Regular';
  color: ${NEUTRAL_SHADES.BLACK};

  input.tangi-input.form-control {
    height: ${SPACING[6]};
    ::placeholder {
      color: ${NEUTRAL_SHADES[900]} !important;
      font-family: 'DIN2014-Regular' !important;
    }
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING[2]};
  width: 100%;
`;
