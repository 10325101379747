import { headers, headersmultipart } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { ContractApplicationUploadData, ContractData, GetContractLastResult } from 'utils/types/contract/contract';

class ContractAdapter {
  static baseEndPoint = `${config.apiUrl}${config.apiVersion}`;

  static ContractEndpoint = `${ContractAdapter.baseEndPoint}/contracts`;

  async uploadContractApplication({ clientId, files }: ContractApplicationUploadData) {
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('fileName', files[0].name);
    formData.append('clientId', clientId);

    const res = await HttpClientService.post(`${ContractAdapter.ContractEndpoint}/upload_contract_application`, formData, {
      headers: headersmultipart(),
    });
    return res.data;
  }

  async getContractResults({ contractResultId, clientId }: GetContractLastResult): Promise<ContractData> {
    const res = await HttpClientService.post<ContractData>(`${ContractAdapter.ContractEndpoint}/get_contract_results_by_id`, { contractResultId, clientId }, { headers: headers() });

    return res.data;
  }
}

const contractAdapter = new ContractAdapter();
export default contractAdapter;
