import styled from 'styled-components';

import { ITangiSvgIconProps, IconTypes, TangiSvgIcon } from '_components/TangiLibrary';
import { PRIMARY_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';
import { AUDIT_TYPE } from 'utils/types/activityLog/asset/types';

const Container = styled.div`
  position: relative;

  ::after {
    content: '';
    position: absolute;
    background-color: ${PRIMARY_SHADES[200]};
    width: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    top: ${SPACING[6]};
    bottom: -${SPACING[3]}; //makes the line to cover the gaps
  }

  ::before {
    content: '';
    position: absolute;
    background-color: ${PRIMARY_SHADES[200]};
    width: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -${SPACING[4]};
    bottom: 100%;
  }

  &:nth-last-child(2)::after,
  &:nth-child(2)::before,
  &:nth-child(5)::before {
    display: none;
  }
`;

const IconBackground = styled(FlexContainer)`
  border-radius: 50%;
  background: ${PRIMARY_SHADES[200]};
  width: ${SPACING[6]};
  height: ${SPACING[6]};
  align-items: center;
  justify-content: center;
`;

export interface Props extends Pick<ITangiSvgIconProps, 'color' | 'size' | 'className'> {
  type?: AUDIT_TYPE;
  component?: IconTypes;
}

const ActivityIcon = (props: Props) => {
  return props?.type === AUDIT_TYPE.GROUP_TITLE ? (
    <Container />
  ) : (
    <Container>
      <IconBackground>
        <TangiSvgIcon component={props?.component ?? 'assets'} size={props?.size} className={props?.className} color={props?.color || PRIMARY_SHADES[800]} />
      </IconBackground>
    </Container>
  );
};

export default ActivityIcon;
