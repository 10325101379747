import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import TradeSecretsAnalysisCard from '../components/Card/TradeSecretsAnalysisCard';
import { ChartContainer } from '../components/Charts/styles';

export const PredictedPortfolioContainer = styled.div`
  margin: 92px ${SPACING[5]} ${SPACING[5]};
`;

export const StyledTradeSecretsAnalysisCard = styled(TradeSecretsAnalysisCard)`
  ${ChartContainer} {
    margin: 0;
  }
`;

export const CardsRow = styled.div`
  width: 100%;
  display: flex;
  gap: ${SPACING[4]};
`;

export const BarChartContainer = styled.div<{ width: number; left: number }>`
  min-width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: 100%;
  position: absolute;
  left: ${({ left }) => (left ? `${left}px` : '0')};
  right: 0;
  transition: left 1s ease-in-out;
  margin-top: ${SPACING[2]};
`;

export const breakWordTypographyStyles = `
  white-space: pre-line;
  color: ${NEUTRAL_SHADES[800]};
  font-size: 14px;
  line-height: 14px;
  margin-top: -2px;
`;
