import { BarDatum, BarTooltipProps, BarLegendProps, ResponsiveBar } from '@nivo/bar';
import { useMemo } from 'react';
import { WidgetDataObject } from '../Widgets/Widgets.types';
import styled from 'styled-components';
import { TangiTypography } from '_components/TangiLibrary';

const EMPTY_DATA = [
  {
    id: '',
    label: 'Empty',
    value: 1,
  },
];

const StyledTooltip = styled.div`
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
`;

const getFormattedData = (data: WidgetDataObject[]): BarDatum[] => {
  const formattedData = data.reduce((acc: BarDatum, item) => {
    acc[item.label] = item.value;
    acc[item.label + 'Color'] = item.color || '';
    return acc;
  }, {});
  return [formattedData];
};

const CustomTooltip = (props: BarTooltipProps<BarDatum>) => {
  const label = props.id as string;
  const value = props.value as number;

  return (
    <StyledTooltip>
      <TangiTypography type="x-small">{label}:</TangiTypography>
      <TangiTypography type="x-small">{value}</TangiTypography>
    </StyledTooltip>
  );
};

interface Props {
  data: WidgetDataObject[];
  colors: string[];
  isEmpty: boolean;
}

const legend: BarLegendProps = {
  dataFrom: 'keys',
  anchor: 'bottom-left',
  direction: 'row',
  justify: false,
  translateX: 0,
  translateY: 30,
  itemWidth: 70,
  itemHeight: 20,
  itemsSpacing: 0,
  symbolSize: 12,
  itemDirection: 'left-to-right',
};
const LEGEND_ITEMS_IN_ROW = 4;
const MAX_LEGEND_TEXT = 10;

const HorizontalBarChart = ({ data, colors, isEmpty }: Props) => {
  const enrichedData: WidgetDataObject[] = useMemo(() => {
    const renderData = !isEmpty ? data : EMPTY_DATA;
    return renderData.map((cur, i) => {
      return { ...cur, color: i < colors.length ? colors[i] : colors[0] };
    });
  }, [data]);

  const formattedData = useMemo(() => getFormattedData(enrichedData), [enrichedData]);
  const keys = enrichedData.map((item) => item.label.toString());
  const legends: BarLegendProps[] | undefined = useMemo(() => {
    if (isEmpty) return undefined;

    return keys?.length <= LEGEND_ITEMS_IN_ROW
      ? [legend]
      : [
          {
            ...legend,
            translateY: 20,
            data: enrichedData.slice(0, LEGEND_ITEMS_IN_ROW).map((cur) => {
              return {
                id: cur.id,
                label: cur.label.slice(0, MAX_LEGEND_TEXT),
                color: cur.color,
              };
            }),
          },
          {
            ...legend,
            translateY: 43,
            data: enrichedData.slice(LEGEND_ITEMS_IN_ROW, enrichedData.length).map((cur) => {
              return {
                id: cur.id,
                label: cur.label.slice(0, MAX_LEGEND_TEXT),
                color: cur.color,
              };
            }),
          },
        ];
  }, [enrichedData]);

  return (
    <ResponsiveBar
      data={formattedData}
      keys={keys}
      margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
      padding={0.3}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={({ id, data }) => String(data[`${id}Color`])}
      colorBy="id"
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      enableGridY={false}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]],
      }}
      legends={legends}
      role="application"
      ariaLabel="Horizontal Bar Chart"
      tooltip={CustomTooltip}
      theme={{ legends: { text: { fontSize: 9 } } }}
      isInteractive={!isEmpty}
    />
  );
};

export default HorizontalBarChart;
