export const courseConstants = {
  CLIENT_COURSES_REQUEST: 'CLIENT_COURSES_REQUEST',
  CLIENT_COURSES_SUCCESS: 'CLIENT_COURSES_SUCCESS',
  CLIENT_COURSES_FAILURE: 'CLIENT_COURSES_FAILURE',
  CLIENT_COURSE_ID_REQUEST: 'CLIENT_COURSE_ID_REQUEST',
  CLIENT_COURSE_ID_SUCCESS: 'CLIENT_COURSE_ID_SUCCESS',
  CLIENT_COURSE_ID_FAILURE: 'CLIENT_COURSE_ID_FAILURE',
  QUIZ_REQUEST: 'QUIZ_REQUEST',
  QUIZ_SUCCESS: 'QUIZ_SUCCESS',
  QUIZ_FAILURE: 'QUIZ_FAILURE',
};

export const courseProgressionConstants = {
  COMPLETED: 'Completed',
  INPROGRESS: 'In Progress',
  REQUIRED: 'Required For you',
  RETAKEQUIZ: 'Retake Quiz',
};
