import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();
  return (
    <TangiTypography weight="semibold" type="heading-lg">
      {t('IP_AUDIT.HEADER')}
    </TangiTypography>
  );
};
