import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

export const ContentContainer = styled.div`
  display: flex;
  padding: ${SPACING[2.5]};
  gap: ${SPACING[2]};
  flex-direction: column;
  background-color: ${NEUTRAL_SHADES.WHITE};
  width: 280px;
  border-radius: ${SPACING[2]};
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);

  .tangi-typography:nth-of-type(2) {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-wrap: none;
  }
`;
