import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { Container, StyledImg } from './style';

interface Props {
  imageSrc?: string;
  translationKey: string;
}
const InstructionsTooltip = ({ imageSrc, translationKey }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TangiTypography color={NEUTRAL_SHADES[1000]}>{t(translationKey)}</TangiTypography>
      {imageSrc && <StyledImg src={imageSrc} alt="sharepoint-instructions" />}
    </Container>
  );
};

export default InstructionsTooltip;
