import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { CustomDivider, DataContainer, FlexBetweenContainer, TooltipDataContainer, TooltipLabel, TooltipTitleContainer, TypeColor } from './style';
import { TooltipContainerTippy } from '../Charts/styles';
import { TRANSLATION_KEY } from 'pages/TradeSecretsAnalysis/types';

interface Props {
  barData: { id: string; value: number; color: string }[];
  title: string;
}
const TradeSecretsBySubjectTooltip = ({ barData: data, title }: Props) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    return (
      <TooltipTitleContainer>
        <TangiTypography type="subheading" weight="semibold">
          {title}
        </TangiTypography>
      </TooltipTitleContainer>
    );
  };
  const renderData = useMemo(() => {
    return data.map((item: { id: string; value: number; color: string }) => (
      <Fragment key={item.id}>
        <TooltipDataContainer>
          <TooltipLabel>
            <TypeColor color={item.color} />
            <TangiTypography type="sub-body">{item.id}</TangiTypography>
          </TooltipLabel>
          <TangiTypography type="sub-body" color={NEUTRAL_SHADES[800]}>
            {item.value}
          </TangiTypography>
        </TooltipDataContainer>
        <CustomDivider />
      </Fragment>
    ));
  }, [data]);

  return (
    <TooltipContainerTippy>
      {renderTitle()}
      <DataContainer>
        <FlexBetweenContainer>
          <TangiTypography type="sub-body" color={NEUTRAL_SHADES[800]}>
            {t(TRANSLATION_KEY.COMPETITIVE_BY_SUBJECT_CHART_TOOLTIP_COMPANY_TITLE)}
          </TangiTypography>
          <TangiTypography type="sub-body" color={NEUTRAL_SHADES[800]}>
            {t(TRANSLATION_KEY.COMPETITIVE_BY_SUBJECT_CHART_TOOLTIP_POTENTIAL_TRADE_SECRETS_TITLE)}
          </TangiTypography>
        </FlexBetweenContainer>
        <CustomDivider />
        {renderData}
      </DataContainer>
    </TooltipContainerTippy>
  );
};
export default TradeSecretsBySubjectTooltip;
