import { Modal } from 'react-bootstrap';

import { CreateEditAsset } from './CreateEditAsset';
import { Asset } from 'utils/types/assets/assets';
import { CREATED_FROM } from 'utils/enums';
import { Department, Tag, Option } from 'utils/types/assetMetaData/assetMetaData';

interface Props {
  asset: Partial<Asset> & { createdFrom: CREATED_FROM; name: string; tags: Tag[] | Option[]; departments: Department[] | Option[] };
  filesToAttach: File[];
  isShow: boolean;
  isPatentMode: boolean;
  onClose: () => void;
  onCreate: () => void;
  onEdit: null | (() => void);
  onHide: () => void;
}

const CreateEditAssetModal = ({ asset, filesToAttach, isShow, isPatentMode, onClose, onCreate, onHide, onEdit }: Props) => {
  return (
    <Modal transition="Fade" size="lg" className="modal-asset" show={isShow} onHide={onHide}>
      <CreateEditAsset asset={asset} filesToAttach={filesToAttach} handleCloseAsset={onClose} onCreate={onCreate} onEdit={onEdit} isPatentMode={isPatentMode} />
    </Modal>
  );
};

export default CreateEditAssetModal;
