import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

const SkeletonContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[4]};
  margin-top: ${SPACING[2]};
`;

interface Props {
  width: string | number;
  height: string | number;
}

const PieAndBarSkeleton = ({ width, height }: Props) => {
  return (
    <SkeletonContainer>
      <Skeleton width={width} height={height} count={1} />
      <Skeleton width={200} height={16} count={1} />
    </SkeletonContainer>
  );
};

export default PieAndBarSkeleton;
