/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createAsyncThunk } from '@reduxjs/toolkit';

import dashboardAdapter from 'redux-toolkit/adapters/dashboardAdapter';
import { prepareDashboardAssetsData, prepareDashboardAccountsData, prepareDashboardInsightsData } from 'utils/dashboardFuncs';

export const getDashboardAssetsRawData = createAsyncThunk('/dashboard/getDashboardAssetsRawData', async (clientId: string) => {
  const res: any = await dashboardAdapter.getDashboardAssetsRawData(clientId);
  if (!res?.data) return null;
  return prepareDashboardAssetsData({ includeActiveAndAllData: true })(res.data);
});

export const getDashboardAccountsRawData = createAsyncThunk('/dashboard/getDashboardAccountsRawData', async (clientId: string) => {
  const res: any = await dashboardAdapter.getDashboardAccountsRawData(clientId);
  if (!res?.data) return null;
  return prepareDashboardAccountsData(res.data);
});

export const getDashboardRecipientsRawData = createAsyncThunk('/dashboard/getDashboardRecipientsRawData', async (clientId: string) => {
  const res: any = await dashboardAdapter.getDashboardRecipientsRawData(clientId);
  if (!res?.data) return null;
  return prepareDashboardInsightsData(res.data);
});
