import { TANGIBLY_URL } from '_constants/consts';
import logo from '../../assets/img/CANOPY-logo.png';
import './style.scss';

function Footer() {
  const handleRedirectToDashboard = () => {
    window.location.href = TANGIBLY_URL;
  };

  return (
    <footer className="footer mt-auto py-3 bg-light mb-auto">
      <div className="container footer-bottom">
        <div className="text-lg-left d-flex justify-items-start align-items-center" onClick={handleRedirectToDashboard} style={{ cursor: 'pointer' }}>
          <span className="text-muted ff-din-DemiBold">© {new Date().getFullYear()}</span>
          <a className="text-primary hover:text-danger">
            <img src={logo} className="mx-2" width="135" alt="Canopy" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
