/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import _ from 'lodash';

import { agreementService } from '../../_services/agreementService';
import { assetMetadataService } from '../../_services/assetMetadataService';
import { adminClientService } from '../../AdminRedux/_services/adminClientService';
import { userService } from '../../_services/userService';
import { adminUserService } from '../../AdminRedux/_services/adminUserService';
import { normalizeString } from '../../utils/fileUtils';

const optionTypes = {
  ASSET_TYPES: 'assetTypes',
  ASSET_CONTRIBUTORS: 'assetContributors',
  AGREEMENT_TYPES: 'agreementTypes',
  BUSINESS_VALUES: 'businessValues',
  CLIENTS: 'clients',
  TAGS: 'tags',
  LAWFIRMS: 'lawfirms',
  USERS: 'users',
  ROLES: 'roles',
  LAWYERS: 'lawyers',
  CLIENT_LAWYERS: 'CLIENT_LAWYERS',
  USERS_AND_CLIENT: 'usersAndClient',
};

function EditAsyncSelect(props) {
  const [value, onChange] = useState([]);

  useEffect(() => {
    handleChange(value);
  }, [value]);

  useEffect(() => {
    if (props.defaultValue) {
      onChange(props.defaultValue);
    }
  }, [props.defaultValue]);

  useEffect(() => {
    if (props.clientId) {
      onChange([]);
    }
  }, [props.clientId]);

  function getSelectOptions(items, accessor = 'name') {
    const temp = [];
    items.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item && temp.push({ value: item.id, label: item[accessor] });
    });

    return temp;
  }

  function getRoleOptions(items, accessor = 'name') {
    const temp = [];
    items.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item && temp.push({ value: item.name, label: item[accessor] });
    });

    return temp;
  }

  function getSelectOptionsWithData(items, accessor = 'name') {
    const excludeList = ['lawyer', 'lawyer-admin'];
    const temp = [];
    items.forEach((item) => {
      if (!excludeList.includes(normalizeString(item?.role?.name))) {
        // eslint-disable-next-line no-unused-expressions
        item && temp.push({ value: item, label: item[accessor] });
      }
    });

    return temp;
  }

  async function getAssetContributors(client) {
    const promises = [];

    promises.push(adminClientService.getAdminClient(client), adminUserService.getAdminUsersByClient(client));

    const resultArray = await Promise.all(promises);

    const lawyers = await resultArray[0]?.lawyers;
    const clientUsers = await resultArray[1]?.data;

    const data = [...lawyers, ...clientUsers];
    return { data };
  }

  async function getOptions(optionType, page, limit, previous) {
    let response = null;
    let results = [];
    let hasMore = true;
    let accessor = 'name';
    const sortBy = 'name:asc';
    const { clientId, lawFirmId } = props;

    // If addNone is passed
    let none = [];
    if (props.addNone) {
      none = [{ value: 'null', label: 'None' }];
    }

    switch (optionType) {
      case optionTypes.AGREEMENT_TYPES:
        response = await agreementService.getAgreementTypes({ client: null, page, limit, sortBy });
        return { results: getSelectOptions(response?.[0]?.data ?? response?.data, 'name'), hasMore: false };
      case optionTypes.ASSET_CONTRIBUTORS:
        response = await getAssetContributors(clientId);
        return { results: getSelectOptions(response.data, 'displayName'), hasMore: false };
      case optionTypes.ASSET_TYPES:
        response = await assetMetadataService.getAssetTypes({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.BUSINESS_VALUES:
        response = await assetMetadataService.getBusinessValues({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.CLIENTS:
        response = await adminClientService.getAdminClients(page, null, sortBy, limit);
        break;
      case optionTypes.CLIENT_LAWYERS:
        response = await adminClientService.getAdminClient(clientId);
        return { results: getSelectOptions(response.lawyers, 'displayName'), hasMore: false };
      case optionTypes.LAWFIRMS:
        response = await adminClientService.getAdminLawFirmService(page, null, limit, sortBy);
        break;
      case optionTypes.LAWYERS:
        response = await adminClientService.getAdminLawFirmLawyers({ lawFirm: lawFirmId, page, limit: 10000, sortBy });
        accessor = 'displayName';
        break;
      case optionTypes.ROLES:
        response = await userService.getRoles({ name: null });
        return { results: getRoleOptions(response.results, 'name'), hasMore: false };
      case optionTypes.TAGS:
        response = await assetMetadataService.getTags({ client: clientId, page, limit, sortBy });
        break;
      case optionTypes.USERS_AND_CLIENT:
        response = await adminUserService.getAdminUsers({ page, limit, sortBy });
        return { results: getSelectOptionsWithData(response.data, 'displayName'), hasMore: previous !== response?.metadata[0]?.total };
      case optionTypes.USERS:
        response = await adminUserService.getAdminUsers({ page, limit, sortBy });
        accessor = 'displayName';
        break;
      default:
        break;
    }

    results = response.data;
    hasMore = previous !== response?.metadata[0]?.total;

    return { results: [...none, ...getSelectOptions(results, accessor)], hasMore };
  }

  const defaultAdditional = {
    page: 1,
    limit: 50,
    previous: 0,
  };
  const loadOpions = async (search, prevOptions, { page, limit, previous }) => {
    const data = await getOptions(props.type, page, limit, previous);

    const { results, hasMore } = data;

    if (search && search.length > 0) {
      const startsWithN = results.filter((searchRes) => searchRes.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: startsWithN,
        hasMore,
        additional: {
          page: page + 1,
          limit,
          previous: previous + results?.length,
        },
      };
    }
    return {
      options: results,
      hasMore,
      additional: {
        page: page + 1,
        limit,
        previous: previous + results?.length,
      },
    };
  };

  function handleChange(e) {
    if (props.isMulti) {
      const values = [];
      e.forEach((s) => {
        values.push(s.value);
      });
      props.onChange(values);
    } else if (!_.isEmpty(e)) {
      props.onChange(_.isArray(e) ? e[0].value : e.value);
    }
  }
  return (
    <AsyncPaginate
      styles={props.styles}
      isDisabled={props.isDisabled}
      className={props.selectStyles}
      name={props.name}
      onChange={onChange}
      onBlur={props.onBlur}
      selectRef={props.inputRef}
      value={value}
      additional={defaultAdditional}
      // isSearchable={false}
      loadOptions={loadOpions}
      isMulti={props.isMulti}
      key={props.clientId}
      cacheUniqs={props.cacheUniqueId}
      {...props.others}
    />
  );
}

export { EditAsyncSelect, optionTypes };
