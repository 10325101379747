export const XrayRoutes = {
  PATENT_X_RAY: 'patent-xray',
  CONTRACT_ANALYZER: 'contract-analyzer',
  INVENTION_DISCLOSURE: 'invention-disclosure',
  CONTINUATION_ANALYSIS: 'continuation-analysis',
} as const;

export const TradeSecretAnalysisRoutes = {
  COMPETITIVE_ANALYSIS: 'competitive-analysis',
  PREDICTED_PORTFOLIO: 'predicted-portfolio',
} as const;

export enum AppRoutes {
  // TODO: this does not include superadmin routes
  MAIN = '/',
  HOME = '/home', //currently same as MAIN
  DASHBOARD = '/dashboard', //this renders lawyer home page
  HOME_LOBBY = '/client/:clientId',

  LOGIN = '/login',
  OKTA_CALLBACK = '/login/callback',

  FORCE_RESET_PASSWORD = '/forceresetPassword',
  RESET_PASSWORD = '/resetPassword',

  REGISTER = '/register',

  CLIENT_DASHBOARD = '/client/:clientId/reports',

  ASSETS_MAIN_PAGE = '/client/:clientId/assets',
  ASSET_INNER_PAGE = '/client/:clientId/asset/:assetId',

  // ---- ROUTES TO ACKNOWLEDGE AND PREVIEW ASSETS FOR NOT LOGGED IN USERS ----
  ASSET_PREVIEW = '/assets/preview',
  ASSET_ACKNOWLEDGE = '/assets/acknowledge',
  // ---- ----

  PEOPLE = '/client/:clientId/people',

  TRAINING = '/courses/:clientId',
  QUIZ = `/courses/:clientId/quiz/:courseId`,

  SETTINGS = '/onboarding/:clientId', //settings page
  FIRST_ONBOARDING = '/firstonboarding/:clientId', //settings page for the admin that enters the system for the first time

  PATENT_X_RAY_MAIN_PAGE = '/client/:clientId/patent-xray-main-page',
  PATENT_X_RAY_INNER_PAGE = '/client/:clientId/patent-xray-inner-page',

  CONTRACT_ANALYZER_MAIN_PAGE = '/client/:clientId/contract-analyzer-main-page',
  CONTRACT_ANALYZER_INNER_PAGE = '/client/:clientId/contract-analyzer-inner-page',

  INVENTION_DISCLOSURE_MAIN_PAGE = '/client/:clientId/invention-disclosure-main-page',
  INVENTION_DISCLOSURE_INNER_PAGE = '/client/:clientId/invention-disclosure-inner-page',

  CONTINUATION_ANALYSIS_MAIN_PAGE = '/client/:clientId/continuation-analysis-main-page',

  EMAIL_TRACKER_MARKETING_PAGE = '/client/:clientId/email-tracker-main-page',

  PREDICTED_PORTFOLIO = '/client/:clientId/predicted-portfolio',
  COMPETITIVE_ANALYSIS = '/client/:clientId/competitive-analysis',

  PARTNERSHIP = '/client/:clientId/partners/:partnershipId',

  // ---- CURRENTLY NOT USED ROUTES ----
  EMAIL_FINDER = '/client/:clientId/email-finder',
  EMAIL_TRACKER = '/client/:clientId/email-tracker',
  //---- ----

  // ---- OLD ROUTES ----
  XRAY_LOBBY = '/client/:clientId/discovery',
  DISCOVERY_JOIN = '/client/:clientId/join-beta',
  //---- ----
}
