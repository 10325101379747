import styled from 'styled-components';
import { Form, Modal } from 'react-bootstrap';

import { NEUTRAL_SHADES } from '../../utils/theme';

export const ModalContentContainer = styled.div`
  width: 760px;
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 4px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-family: 'DIN2014-Regular';
  gap: 24px;
`;

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  padding: 0px 16px 20px;
  gap: 24px;
`;

export const StyledForm = styled(Form)`
  padding-bottom: 24px;
  border-bottom: 1px solid ${NEUTRAL_SHADES[300]};
`;

export const FormContentContainer = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
`;

export const SelectContainer = styled.div`
  width: 90%;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AckStatusContainer = styled(FlexRow)`
  width: 128px;
  padding-left: 40px;
`;

export const TableTitle = styled.div`
  font-size: 16px;
  font-family: 'DIN2014-DemiBold';

  & + div {
    padding-right: 4px;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: ${NEUTRAL_SHADES.WHITE};
    }
    ::-webkit-scrollbar-thumb {
      background: ${NEUTRAL_SHADES[500]};
      border-radius: 4px;
    }
  }
`;

export const customTableStyles = {
  headRow: {
    style: {
      backgroundColor: `${NEUTRAL_SHADES[200]} !important`,
      minHeight: '42px',
      maxHeight: '42px',
      zIndex: '0',
    },
  },
  headCells: {
    style: {
      paddingTop: '10px',
      paddingBottom: '10px',
      '&:nth-of-type(3)': {
        marginRight: '12px',
      },
    },
  },
  rows: {
    style: {
      maxHeight: '42px',
      minHeight: '42px',
      border: 'none',
    },
  },
};
