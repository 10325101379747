import { countries } from 'countries-list';

const getSelectorOptions = (results, value = 'id', label = 'name', customProperty = null) => {
  const options =
    results &&
    results.map((result) => {
      const option = {
        value: result[value],
        label: result[label],
      };
      if (customProperty) {
        option[customProperty] = result[customProperty];
      }
      return option;
    });
  return options;
};

const getLabelValueFormat = (arrayOfData) =>
  arrayOfData.map((data) => {
    return { label: data.name, value: data.id || data._id };
  });

const countriesOptions = () => {
  const formattedCountries = [];
  for (const [key, value] of Object.entries(countries)) {
    formattedCountries.push({ value: value.name, label: value.name });
  }
  return formattedCountries;
};

const returnValuesFromArray = (data) => {
  return data?.map((item) => (item ? item.value : ''));
};

export { getSelectorOptions, getLabelValueFormat, countriesOptions, returnValuesFromArray };
