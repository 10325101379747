import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING, THEME_COLORS } from '../../utils/theme';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

export const PeopleContainer = styled.div`
  width: 93%;
  margin: 32px auto 0;
`;

// ----- Search section ----- //

export const FilterSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

// ----- Table sections ----- //

export const SectionHeader = styled.div`
  margin-top: 40px;
  margin-bottom: ${SPACING[3]};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableContainer = styled.div`
  overflow: visible !important;

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

// ----- Table Cells ----- //
export const ActionsCell = styled.div`
  display: flex;
  gap: 8px;
`;

export const StartExitDates = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: 'DIN2014-Regular';
  padding: 10px 0px 8px 4px;
  div:last-of-type {
    font-size: 12px;
    color: ${NEUTRAL_SHADES[800]};
  }
`;

export const MagicIconContainer = styled.div`
  margin: 2px 0px 0px 8px;
`;

export const AvatarNameIconContainer = styled.div`
  display: flex;
`;
