import { useEffect, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { TangiDraggableList } from '_components/TangiLibrary/TangiDraggableList/TangiDraggableList';
import InventionTooltip from '../InventionTooltip/InventionTooltip';
import { useAssociation } from '../../../../_context/InventionDisclosure/AssociationContext';
import { InventionDisclosureFormValues, MODE_TYPE, PotentialClaim } from 'utils/types/inventionDisclosure/inventionDisclosure';
import { RootState } from '_helpers';
import { NEUTRAL_SHADES } from 'utils/theme';
import { AddClaimContainer, FlexRow, TippyCenteredDiv } from './style';
import EditableTextArea from '../EditableTextArea/EditableTextArea';
import { inventionDisclosureActions } from 'redux-toolkit/slices/inventionDisclosureSlice';
import { FormProvider, useForm } from 'react-hook-form';
import EditItemModal from '../Modals/EditItemModal';
import { updateHasSeenInformationAccount } from 'redux-toolkit/thunks/usersThunks';
import { HasSeenInformation } from 'utils/types/account/account';

interface Props {
  data: PotentialClaim[];
}
export const InventionDraggableList = ({ data }: Props) => {
  const isCreateMode = useSelector((state: RootState) => state.inventionDisclosure.modes.isClaimCreateMode);

  const initialItems: PotentialClaim[] = useMemo(() => data, [data]);
  const [items, setItems] = useState<PotentialClaim[]>(initialItems);
  const [highlightedClaimId, setHighlightedClaimId] = useState<string | null>(null);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const { associatedClaims, scrollClaimsContainerRef, setAssociatedTSBullets, resetAssociatedTSBullets, scrollToNextElement, resetScrolledToTSBullet } = useAssociation();

  const methods = useForm<InventionDisclosureFormValues>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);

  useEffect(() => {
    dispatch(inventionDisclosureActions.setReorderedClaims(items));
  }, [items]);

  useEffect(() => {
    if (activeAccount?.hasSeenInformation?.editInvention) {
      setIsShowPopup(false);
    }
  }, [activeAccount]);

  const onConfirmEdit = (data: HasSeenInformation) => {
    const { editInvention } = data;

    if (!editInvention) {
      return setIsShowPopup(false);
    }
    const updatedAccount = { hasSeenInformation: { editInvention }, role: activeAccount.role.name };
    dispatch(updateHasSeenInformationAccount({ ...updatedAccount, id: activeAccount._id }));
  };

  const handleClaimMouseEnter = (bulletIds: string[], claimId: string) => {
    setAssociatedTSBullets(bulletIds);
    setHighlightedClaimId(claimId);
  };

  const handleClaimMouseLeave = () => {
    resetAssociatedTSBullets();
    resetScrolledToTSBullet();
    setHighlightedClaimId(null);
  };

  const handleEditClaim = (claim: PotentialClaim) => {
    if (!activeAccount?.hasSeenInformation?.editInvention) {
      setIsShowPopup(true);
    }
    dispatch(inventionDisclosureActions.setClaimMode({ mode: MODE_TYPE.IS_CLAIM_EDIT_MODE, value: true, claimId: claim.id }));
    dispatch(inventionDisclosureActions.setClaimMode({ mode: MODE_TYPE.IS_CLAIM_CREATE_MODE, value: false, claimId: claim.id }));
  };

  const handleCancelEditOrCreateClaim = (claim: PotentialClaim) => {
    claim.modes.isClaimCreateMode
      ? handleOnDeleteClaim(claim)
      : dispatch(inventionDisclosureActions.setClaimMode({ mode: claim.modes.isClaimCreateMode ? MODE_TYPE.IS_CLAIM_CREATE_MODE : MODE_TYPE.IS_CLAIM_EDIT_MODE, value: false, claimId: claim.id }));
  };

  const handleSaveEditOrCreateClaim = (claim: PotentialClaim, newValue: string, index: number) => {
    methods.setValue(`claims.${index}.text`, newValue);
    dispatch(
      inventionDisclosureActions.editClaim({
        id: claim.id,
        text: newValue,
      }),
    );
    dispatch(inventionDisclosureActions.setClaimMode({ mode: MODE_TYPE.IS_CLAIM_EDIT_MODE, value: false, claimId: claim.id }));
    dispatch(inventionDisclosureActions.setClaimMode({ mode: MODE_TYPE.IS_CLAIM_CREATE_MODE, value: false, claimId: claim.id }));

    dispatch(inventionDisclosureActions.removeClaimIdFromTS(claim.id));
    dispatch(inventionDisclosureActions.resetTSFromClaim(claim.id));
    resetAssociatedTSBullets();
  };

  const handleOnDeleteClaim = (claim: PotentialClaim) => {
    dispatch(inventionDisclosureActions.deleteClaim(claim));
  };
  const dragHandleIcon = (
    <Tippy
      content={
        <TippyCenteredDiv>
          <TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('INVENTION_DISCLOSURE.TOOLTIP.DRAG_TO_REORDER_CLAIMS')}</TangiTypography>
        </TippyCenteredDiv>
      }
      placement="top-end"
    >
      <div>
        <TangiSvgIcon color={NEUTRAL_SHADES[700]} component="dragAndDrop" />
      </div>
    </Tippy>
  );

  useEffect(() => {
    setItems(data);
  }, [data]);

  //TODO: add onDelete
  const renderItem = (claim: PotentialClaim, index: number) => {
    const isAssociated = associatedClaims.includes(claim.id) || highlightedClaimId === claim.id;
    const isDisabled = claim.modes.isClaimCreateMode || claim.modes.isClaimEditMode;
    const name = `claims[${index}].text`;

    return (
      <AddClaimContainer>
        <InventionTooltip
          key={claim.id}
          numberOfAssociations={claim.bullets_ids.length}
          itemText={claim.text}
          disabled={isDisabled}
          onEdit={() => handleEditClaim(claim)}
          onDelete={() => handleOnDeleteClaim(claim)}
          iterateAssociatedItems={() => scrollToNextElement(claim?.bullets_ids ?? [])}
          scrollRef={scrollClaimsContainerRef}
          onShow={() => handleClaimMouseEnter(claim?.bullets_ids ?? [], claim.id)}
          onHide={handleClaimMouseLeave}
          isShowActions={true}
        >
          <FlexRow data-testid={`claim-bullet-${claim.id}`} key={claim.id}>
            <TangiTypography weight="semibold">{index + 1}. </TangiTypography>
            <EditableTextArea
              name={name}
              handleCancel={() => handleCancelEditOrCreateClaim(claim)}
              handleSave={(newValue) => handleSaveEditOrCreateClaim(claim, newValue, index)}
              isEditMode={claim.modes.isClaimEditMode}
              isCreateMode={claim.modes.isClaimCreateMode}
              value={claim.text}
              isAssociated={isAssociated}
            />
          </FlexRow>
        </InventionTooltip>
        <EditItemModal
          isShow={isShowPopup}
          isHide={() => {
            setIsShowPopup(false);
            dispatch(inventionDisclosureActions.setClaimMode({ mode: MODE_TYPE.IS_CLAIM_EDIT_MODE, value: false, claimId: claim.id }));
          }}
          onConfirm={(data) => onConfirmEdit(data)}
        />
      </AddClaimContainer>
    );
  };
  return (
    <FormProvider {...methods}>
      <TangiDraggableList items={items} renderItem={renderItem} onReorder={setItems} dragIcon={dragHandleIcon} isDragDisabled={isCreateMode} />
    </FormProvider>
  );
};
