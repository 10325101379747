import { BarTooltipProps } from '@nivo/bar';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { CustomDivider, DataContainer, FlexBetweenContainer, TooltipContainer, TooltipDataContainer, TooltipLabel, TooltipTitleContainer, TypeColor } from './assetOverTime.style';
import { StackedBarChartDataObject, filterDataKeys } from './assetsOverTime.logic';

const AssetsOverTimeTooltip = (props: BarTooltipProps<StackedBarChartDataObject>) => {
  const { t } = useTranslation();
  const data = useMemo(() => filterDataKeys(props.data), [props.data]);

  const renderTitle = (assetsNumber: number | string) => {
    const month = props.data.label;
    const currentYear = props.data.currentYear;

    return (
      <FlexBetweenContainer>
        <TooltipTitleContainer>
          <TangiTypography type="subheading" weight="semibold">
            {assetsNumber}
          </TangiTypography>
          <TangiTypography>{t('DASHBOARD_PAGE.WIDGETS.COMMON.ASSETS')}</TangiTypography>
        </TooltipTitleContainer>
        <TangiTypography color={NEUTRAL_SHADES[700]}>
          {month}.{currentYear}
        </TangiTypography>
      </FlexBetweenContainer>
    );
  };

  const renderData = useMemo(() => {
    return data.map((item) => (
      <Fragment key={item.type}>
        <TooltipDataContainer>
          <TooltipLabel>
            <TypeColor color={item.color} />
            <TangiTypography type="sub-body">{item.type}</TangiTypography>
          </TooltipLabel>
          <TangiTypography type="sub-body" color={NEUTRAL_SHADES[800]}>
            {item.amount}
          </TangiTypography>
        </TooltipDataContainer>
        <CustomDivider />
      </Fragment>
    ));
  }, [data]);

  return (
    <TooltipContainer>
      {renderTitle(props.data.total)}
      <DataContainer>
        <FlexBetweenContainer>
          <TangiTypography type="sub-body" color={NEUTRAL_SHADES[800]}>
            {t('DASHBOARD_PAGE.WIDGETS.COMMON.TYPES')}
          </TangiTypography>
          <TangiTypography type="sub-body" color={NEUTRAL_SHADES[800]}>
            {t('DASHBOARD_PAGE.WIDGETS.COMMON.ASSETS')}
          </TangiTypography>
        </FlexBetweenContainer>
        <CustomDivider />
        {renderData}
      </DataContainer>
    </TooltipContainer>
  );
};

export default AssetsOverTimeTooltip;
