import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[3]};
  padding: 0px 0px ${SPACING[3.5]} 0px;
`;

export const Square = styled.div<{ color?: string }>`
  width: ${SPACING[2.5]};
  height: ${SPACING[2.5]};
  background-color: ${({ color }) => color};
  border-radius: 2px;
`;

export const Divider = styled.span`
  width: 1px;
  height: 13px;
  background-color: ${NEUTRAL_SHADES[600]};
  display: inline-block;
`;
