import { acknowledgementConstants } from '../_constants/acknowledgementConstants';
import { acknowledgementService } from './../_services';

//Generic request function to start the loader
const request = () => {
  return {
    type: acknowledgementConstants.ACKNOWLEDGEMENT_REQUESTS,
  };
};

//Generic failure function to catch the error from the server
const failure = (error) => {
  return {
    type: acknowledgementConstants.ACKNOWLEDGEMENT_FAILURE,
    error,
  };
};

const setAssetsNeedToBeAcknowledged = (payload) => {
  return {
    type: acknowledgementConstants.NEEDTOBE_ACKNOWLEDGED_SUCCESS,
    payload,
  };
};

const changeModalVisibility = (show) => {
  return {
    type: acknowledgementConstants.OPEN_CLOSE_ACKNOWLEDGEMENT_MODAL,
    show,
  };
};

const setToastProps = (props) => {
  return {
    type: acknowledgementConstants.SET_TOAST_PROPS,
    props,
  };
};

const clearAcknowledgedAssets = () => {
  return {
    type: acknowledgementConstants.CLEAR_ACKNOWLEDGED_ASSETS,
  };
};

const updateRecipientsAcknowledgment = (data) => {
  const success = (payload) => {
    return {
      type: acknowledgementConstants.ACKNOWLEDGEMENT_SUCCESS,
      payload,
    };
  };
  return (dispatch) => {
    dispatch(request());
    acknowledgementService
      .recipientAcknowledgment(data)
      .then((updatedAssets) => {
        const seenAcknowledgementRequests = localStorage.getItem(`seenAcknowledgementRequests[${data.accountId}]`) === 'true';
        if (!seenAcknowledgementRequests) {
          localStorage.setItem(`seenAcknowledgementRequests[${data.accountId}]`, true);
        }
        dispatch(success(updatedAssets));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
};

export const acknowledgementActions = {
  changeModalVisibility,
  setAssetsNeedToBeAcknowledged,
  setToastProps,
  updateRecipientsAcknowledgment,
  clearAcknowledgedAssets,
};
