import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import { QuestionsContainer, NotFoundContainer } from './styles';
import { Dashboard } from '../Dashboard';
import { QuizQuestion } from '../../_components/QuizQuestion/QuizQuestion';
import { QuizAnswer } from '../../_components/QuizAnswer/QuizAnswer';
import { Loader } from '../../_components/Loader';
import { courseActions, courseProgressionsActions } from '../../_actions';
import { AlertModal } from '../../_components/Modals';

export const Quiz = () => {
  const dispatch = useDispatch();
  const { courseId, clientId } = useParams();
  const history = useHistory();
  const { quiz, quizLoading } = useSelector((state) => state.courseReducer);
  const { loading: progressionsLoading } = useSelector((state) => state.courseProgressionsReducer);
  const { user } = useSelector((state) => state.authentication);

  const [answersState, setAnswersState] = useState({});
  const [modal, setModal] = useState({});
  const [userScore, setUserScore] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  // fetch quiz by course ID
  useEffect(() => {
    if (courseId) {
      dispatch(courseActions.getQuiz(courseId));
    }
  }, [courseId]);

  // submit quiz handler
  const onSubmit = () => {
    const totalQuestion = quiz.questions.length;
    let rightAnswers = 0;
    Object.values(answersState).forEach((question) => {
      if (question.wasUserRight) {
        rightAnswers += 1;
      }
    });

    setUserScore(`${rightAnswers}/${totalQuestion}`);
  };

  const onComplete = () => {
    dispatch(courseProgressionsActions.editCourseProgression(courseId, user._id, { status: 'Completed', score: userScore }));
    while (progressionsLoading) {
      // wait for loading to become false
    }
    history.push(`/courses/${clientId}`);
  };

  return (
    <Dashboard title="Quiz">
      {quizLoading ? (
        <Loader isLoading={quizLoading} />
      ) : (
        <Row className="px-39 m-3 mx-3">
          <Col className="col-auto col-sm-12 p-0">
            <a href={`/courses/${clientId}?courseid=${courseId}`} className="btn btnback btn-secondary mb-4 shadow-none text-muted border border-black fw-lighter" type="button">
              Go back to the course
            </a>
          </Col>
          <Col className="col-auto col-sm-12">
            <h3 className="h1 font-weight-normal text-blk">{quiz?.courseId?.title}</h3>
          </Col>
          {!userScore ? (
            quiz?.questions?.length ? (
              // Quiz
              <>
                <QuestionsContainer data-testid="questions">
                  {quiz.questions?.map((question, index) => (
                    <QuizQuestion key={index} title={`question #${index + 1}`} questionData={question} setAnswersState={setAnswersState} />
                  ))}
                </QuestionsContainer>
                <Col className="col-auto col-sm-12">
                  <Button className="ms-2 my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" onClick={onSubmit} disabled={Object.keys(answersState).length !== quiz?.questions?.length}>
                    Submit Quiz
                  </Button>
                </Col>
              </>
            ) : (
              <NotFoundContainer>
                This course still doesn&apos;t have a quiz, <br /> Stay tuned!
              </NotFoundContainer>
            )
          ) : (
            // Quiz answers after submit
            <>
              <QuestionsContainer>
                <h3>Thank you for participating! You got {userScore} correct, but the learning doesn’t stop! Have a look at the correct answers before you complete the lesson.</h3>
                {Object.values(answersState)?.map((questionAndAswer, index) => (
                  <QuizAnswer key={index} title={`question #${index + 1}`} questionAndAswer={questionAndAswer} />
                ))}
              </QuestionsContainer>
              <Col className="col-auto col-sm-12">
                <br />
                <input
                  data-testid="review"
                  type="checkbox"
                  checked={checkboxChecked}
                  onChange={() => {
                    setCheckboxChecked((prevState) => !prevState);
                  }}
                />{' '}
                I have reviewed the correct answers
              </Col>
              <Col className="col-auto col-sm-12">
                <Button className="ms-2 my-4 btn btn-lg px-5 form-btn btn btn-primary text-white" onClick={onComplete} disabled={!checkboxChecked} isLoading={progressionsLoading}>
                  Complete Quiz
                </Button>
              </Col>
            </>
          )}

          <AlertModal
            type={modal.type}
            body={modal.body}
            onClose={() => {
              setModal((prevState) => {
                return { ...prevState, show: false };
              });
            }}
            show={modal.show}
          />
        </Row>
      )}
    </Dashboard>
  );
};
