export enum TRANSLATION_KEY {
  HEADER_TITLE = 'PREDICTED_PORTFOLIO.HEADER.TITLE',
  HEADER_CHIP = 'PREDICTED_PORTFOLIO.HEADER.CHIP',
  HEADER_CHIP_TOOLTIP = 'PREDICTED_PORTFOLIO.HEADER.CHIP_TOOLTIP',
  HEADER_UPDATE = 'PREDICTED_PORTFOLIO.HEADER.UPDATE',
  BANNER_TITLE = 'PREDICTED_PORTFOLIO.BANNER.TITLE',
  BANNER_INFO = 'PREDICTED_PORTFOLIO.BANNER.INFO',
  BANNER_REQUEST_ACCESS = 'PREDICTED_PORTFOLIO.BANNER.REQUEST_ACCESS',
  BANNER_LEARN_MORE = 'PREDICTED_PORTFOLIO.BANNER.LEARN_MORE',
  CARD_TITLE = 'PREDICTED_PORTFOLIO.CARD.TITLE',
  WIDGET_PATENT = 'PREDICTED_PORTFOLIO.WIDGET.PATENT',
  WIDGET_POTENTIAL_TREDE_SECRETS = 'PREDICTED_PORTFOLIO.WIDGET.POTENTIAL_TREDE_SECRETS',
  WIDGET_SUBJECTS = 'PREDICTED_PORTFOLIO.WIDGET.SUBJECTS',
  CHARTS_SUBJECT = 'PREDICTED_PORTFOLIO.CHARTS.SUBJECT',
  CHARTS_POTENTIAL_TRADE_SECRETS = 'PREDICTED_PORTFOLIO.CHARTS.POTENTIAL_TRADE_SECRETS',
  CHARTS_PATENTS = 'PREDICTED_PORTFOLIO.CHARTS.PATENTS',
  PORTFOLIO_TABLE_TITLE = 'PREDICTED_PORTFOLIO.PORTFOLIO_TABLE.TITLE',
  COMPETITIVE_TABLE_TITLE = 'COMPETITIVE_ANALYSIS.DATA_TABLE.TITLE',
  CHARTS_PATENT_NUMBER = 'PREDICTED_PORTFOLIO.CHARTS.PATENT_NUMBER',
  CHARTS_SUBJECTS = 'PREDICTED_PORTFOLIO.CHARTS.SUBJECTS',
  COMPETITIVE_COMETITORS = 'COMPETITIVE_ANALYSIS.CHART.COMPETITORS',
  COMPETITIVE_HEADER_TITLE = 'COMPETITIVE_ANALYSIS.HEADER.TITLE',
  COMPETITIVE_PERCENTAGE_CHART_CARD_TITLE = 'COMPETITIVE_ANALYSIS.CARDS.PERCENTAGE_CHART.TITLE',
  COMPETITIVE_BY_COMPANIES_CHART_CARD_TITLE = 'COMPETITIVE_ANALYSIS.CARDS.BY_COMPANIES_CHART.TITLE',
  COMPETITIVE_BY_COMPANIES_CHART_CARD_TOTAL_TEXT = 'COMPETITIVE_ANALYSIS.CARDS.BY_COMPANIES_CHART.TOTAL_TEXT',
  COMPETITIVE_BY_SUBJECT_CHART_CARD_TITLE = 'COMPETITIVE_ANALYSIS.CARDS.BY_SUBJECT_CHART.TITLE',
  COMPETITIVE_BY_SUBJECT_CHART_TOOLTIP_COMPANY_TITLE = 'COMPETITIVE_ANALYSIS.CARDS.BY_SUBJECT_CHART.TOOLTIP_COMPANY_TITLE',
  COMPETITIVE_BY_SUBJECT_CHART_TOOLTIP_POTENTIAL_TRADE_SECRETS_TITLE = 'COMPETITIVE_ANALYSIS.CARDS.BY_SUBJECT_CHART.TOOLTIP_POTENTIAL_TRADE_SECRETS_TITLE',
  WIDGET_COMPANIES = 'COMPETITIVE_ANALYSIS.WIDGET.COMPANIES',
  COMPANY = 'COMPETITIVE_ANALYSIS.DATA_TABLE.COMPANY',
}

export enum FilterConfigType {
  Subjects = 'Subjects',
  Patents = 'Patents',
  Years = 'Years',
  Companies = 'Companies',
}
