import { TangiTypography } from '_components/TangiLibrary';
import { AvatarAndNameContainer, MembersContainer, SharePointMembersModalRow, SubHeader } from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar } from '_components/Avatar';
import { TYPOGRAPHY_TYPES } from '_components/TangiLibrary/TangiTypography/style';
import { NEUTRAL_SHADES } from 'utils/theme';
import { SharePointAddedUser } from 'utils/types/sharepoint/sharepoint';
import { formatDate } from 'utils/dateUtils';

interface Props {
  members: SharePointAddedUser[];
}

const SharePointMembersModal = ({ members }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <SubHeader>
        <Trans>
          <TangiTypography color={NEUTRAL_SHADES[1000]}>{t('LOBBY_PAGE.SHARE_POINT_MEMBERS_MODAL.SUB_HEADER', { NEWMEMBERSNUM: members.length })}</TangiTypography>
        </Trans>
      </SubHeader>

      <div>
        <SharePointMembersModalRow>
          <TangiTypography color={NEUTRAL_SHADES[800]} type={TYPOGRAPHY_TYPES.BODY}>
            {t('LOBBY_PAGE.SHARE_POINT_MEMBERS_MODAL.USER')}
          </TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES[800]} type={TYPOGRAPHY_TYPES.BODY}>
            {t('LOBBY_PAGE.SHARE_POINT_MEMBERS_MODAL.ADDED_ON')}
          </TangiTypography>
        </SharePointMembersModalRow>

        <MembersContainer>
          {members.map((member, idx: number) => (
            <SharePointMembersModalRow key={idx}>
              <AvatarAndNameContainer>
                <Avatar name={member.name} />
                <TangiTypography type={TYPOGRAPHY_TYPES.SUBHEADING} weight="bold">
                  {member.name}
                </TangiTypography>
              </AvatarAndNameContainer>
              <TangiTypography>{formatDate(member.date)}</TangiTypography>
            </SharePointMembersModalRow>
          ))}
        </MembersContainer>
      </div>
    </>
  );
};

export default SharePointMembersModal;
