import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TangiIconButton } from '_components/TangiLibrary';
import { Permissions } from 'utils/types/role/role';
import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { PageHeaderContainer, PageHeader, PageHeaderButtonContainer, BackToContainer, ContentViewContainer } from 'utils/globalStyles';
import { ContentContainer, MetaDataCard, DetailsCard } from '_components/AssetOverview/style';
import { SPACING } from 'utils/theme';

export const ViewContainer = styled(ContentViewContainer)`
  margin-top: ${SPACING[4]};
`;

export const Content = styled(ContentContainer)`
  margin-top: ${SPACING[4]};
`;

export const LeftCard = styled(MetaDataCard)`
  padding: ${SPACING[0]};
  background: none;
`;

export const RightCard = styled(DetailsCard)`
  padding: ${SPACING[0]};
  background: none;
`;

interface Props {
  permissions: Permissions;
  assetSharePermission: boolean;
  goBackToAsset: () => void;
  isAdmin?: boolean;
}

const AssetSkeleton = ({ permissions, assetSharePermission, goBackToAsset, isAdmin = false }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <PageHeaderContainer>
        <PageHeader>
          <BackToContainer>
            <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={goBackToAsset} />
            <span>{t('ASSET_PAGE.BUTTONS_TEXT.BACK_TO_ALL_ASSETS')}</span>
          </BackToContainer>
          <PageHeaderButtonContainer>
            {permissions.Assetdelete && <Skeleton width={100} height={40} />}
            {permissions.Assetedit && <Skeleton width={100} height={40} />}
            {assetSharePermission && <Skeleton width={100} height={40} />}
          </PageHeaderButtonContainer>
        </PageHeader>
      </PageHeaderContainer>
      <ViewContainer>
        <Skeleton width="100%" height={isAdmin ? 148 : 88} />
        <Content isAdmin={isAdmin}>
          <LeftCard>
            <Skeleton width="100%" height={300} />
          </LeftCard>

          <RightCard>
            <Skeleton width="100%" height={300} />
          </RightCard>
        </Content>
      </ViewContainer>
    </div>
  );
};

export default AssetSkeleton;
