import { KeyAssetsSystemData, KeyAssetsSystemMappedData } from '_components/IpAudit/types';
import { AssetOption, SystemKey } from './types';
import { assetsData, keysTagMap, noAssetSelection, systemKeys } from './consts';
import i18next from 'i18next';

export const buildInitialValues = (data: KeyAssetsSystemData | null): KeyAssetsSystemMappedData => {
  const { assets, noneSelectionSystems } = data || {};
  const initialValues: KeyAssetsSystemMappedData = {
    crm: [],
    saas: [],
    mgmtEln: [],
    hrms: [],
    ci: [],
    vrm: [],
    qms: [],
  };

  if (!assets?.length && !noneSelectionSystems?.length) return initialValues;
  assets?.forEach((assetName) => {
    const regex = /: ([^()]+) \(([^()]+)\)/;
    const match = assetName.match(regex);
    const asset = match?.[1];
    const system = match?.[2];
    const systemKey = systemKeys.find((key) => keysTagMap[key] === system);
    if (systemKey && initialValues[systemKey] && asset && !initialValues[systemKey].includes(asset)) {
      initialValues[systemKey].push(asset);
    }
  });

  noneSelectionSystems?.forEach((systemKey) => {
    initialValues[systemKey] = [noAssetSelection];
  });
  
  return initialValues;
};

export const getAssetsByKey = (key: SystemKey): AssetOption[] => {
  return assetsData[key];
};

export const buildAssetName = (system: SystemKey, asset: string) => `${i18next.t('IP_AUDIT.IP_AUDIT_STEPS.KEY_ASSETS_SYSTEMS.CONFIDENTIAL_DATA')}: ${asset} (${keysTagMap[system]})`;
