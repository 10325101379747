import { useLocation, useHistory } from 'react-router-dom';

export const useHandleSteps = () => {
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);

  const currentStepKey = urlSearchParams.get('currentStep');

  const handleStepChange = (key: string) => {
    history.replace({
      pathname: location.pathname,
      search: `?currentStep=${key}`,
    });
  };

  const clearCurrentStepFromUrl = () => {
    urlSearchParams.delete('currentStep');
    history.replace({
      pathname: location.pathname,
    });
  };

  return {
    currentStepKey,
    handleStepChange,
    clearCurrentStepFromUrl,
  };
};
