import styled, { css } from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { NEUTRAL_SHADES, PRIMARY_SHADES, SPACING } from 'utils/theme';

export const TooltipContainer = styled(FlexContainer)`
  flex-direction: row;
  background: ${NEUTRAL_SHADES.WHITE};
  min-width: fit-content;
  white-space: nowrap;
  align-items: center;
  box-shadow: ${SPACING[0]} 2px ${SPACING[3]} ${SPACING[0]} rgba(0, 0, 0, 0.12);
  border-radius: ${SPACING[1]};
  padding: ${SPACING[2]} 10px;
  height: ${SPACING[6]};
`;

export const AssociationsContainer = styled.div`
  position: relative;
  padding-right: ${SPACING[2]}; /* Adjust the padding to ensure there's space for the line */

  &::after {
    content: '';
    position: absolute;
    right: ${SPACING[0]};
    top: ${SPACING[0]};
    bottom: ${SPACING[0]};
    width: 1px; /* Width of the vertical line */
    background-color: ${NEUTRAL_SHADES[300]}; /* Color of the vertical line */
  }
`;

export const UrlLink = styled.a<{ isClickable: boolean }>`
  ${({ isClickable = true }) => css`
    cursor: ${isClickable ? 'pointer' : 'default'};
    color: ${PRIMARY_SHADES[800]};
    font-size: ${SPACING[3]};
    line-height: ${SPACING[4]};
    color: ${PRIMARY_SHADES[800]};
    font-family: 'DIN2014-Regular';
    &:hover {
      color: ${PRIMARY_SHADES[800]};
      text-decoration: ${isClickable && 'underline'};
    }
  `}
`;
