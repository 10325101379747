import styled from 'styled-components';

import { SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 56vw;
  min-width: 100%;
  font-family: 'DIN2014-REGULAR';
  max-width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  gap: ${SPACING[4]};
  max-height: 76vh;
  padding: ${SPACING[0]} ${SPACING[2]} ${SPACING[4]};
  height: 100%;
  @media (max-height: 700px) {
    max-height: calc(76vh - 10vh);
  }
`;

export const DuoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${SPACING[4]};
  box-sizing: border-box;
  > div {
    flex: 1;
  }
`;

export const Separator = styled.div`
  border-bottom: 1.5px solid #00000020;
  margin: ${SPACING[2.5]} 0;
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  .agreements-title {
    color: black;
    font-size: ${SPACING[4]};
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  gap: ${SPACING[2]};
  align-items: center;
`;
