import { TangiCheckbox, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { SecurityCard } from '../../components/SecurityCard/SecurityCard';
import { CheckboxWithTextCard, CollapseContainer, CollapseHeader, OptionCardsContainer, nonOfTheAboveTextStyle } from './styles';
import { Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SUCCESS_SHADES } from 'utils/theme';
import { AssetOption, SystemKey } from '../types';
import { useIpAuditContext } from '_components/IpAudit/IpAudit.provider';

interface KeyAssetsSectionProps {
  systemKey: SystemKey;
  title: string;
  isCollapsed: boolean;
  toggleBox: () => void;
  options: AssetOption[];
  selectedOptions: string[];
  handleOptionSelection: (option: string) => void;
  handleNoneOfTheAbove: () => void;
  isNoneOfTheAboveSelected: boolean;
  dataCy: string;
  isOptionSelected: boolean;
  isDisableNonOfTheAbove?: boolean;
}

export const KeyAssetsSection = ({
  systemKey,
  title,
  isCollapsed,
  toggleBox,
  options,
  selectedOptions,
  handleOptionSelection,
  handleNoneOfTheAbove,
  isNoneOfTheAboveSelected,
  dataCy,
  isOptionSelected,
  isDisableNonOfTheAbove,
}: KeyAssetsSectionProps) => {
  const { t } = useTranslation();
  const { isAssetDisable } = useIpAuditContext();
  return (
    <>
      <CollapseHeader onClick={toggleBox}>
        <TangiSvgIcon component={isCollapsed ? 'arrowDown' : 'arrowUp'} />
        <TangiTypography type="subheading" weight="semibold">
          {title}
        </TangiTypography>
        {isOptionSelected && <TangiSvgIcon component="check" color={SUCCESS_SHADES[500]} />}
      </CollapseHeader>

      <Collapse in={isCollapsed}>
        <CollapseContainer>
          <OptionCardsContainer>
            {options.map(({ key, name }) => (
              <SecurityCard
                isDisable={isAssetDisable(systemKey, name)}
                key={key}
                id={key}
                systemOption={name}
                svgComponent={key}
                isSelected={selectedOptions.includes(name)}
                toggleSelection={handleOptionSelection}
              />
            ))}
          </OptionCardsContainer>

          <CheckboxWithTextCard onClick={() => !isDisableNonOfTheAbove && handleNoneOfTheAbove()}>
            <TangiCheckbox type="checkbox" data-cy={`none-of-the-above-${dataCy}`} checked={isNoneOfTheAboveSelected} readOnly name={dataCy} disabled={isDisableNonOfTheAbove} />
            <TangiTypography customStyles={nonOfTheAboveTextStyle}>{t('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.NONE_OF_THE_ABOVE')}</TangiTypography>
          </CheckboxWithTextCard>
          {/* TODO: Temporarily removed until updated text becomes available */}
          {/* {isNoneOfTheAboveSelected && <TangiNotification variant={NOTIFICATION_VARIANTS.WARNING}>{t('IP_AUDIT.IP_AUDIT_STEPS.SYSTEM_SECURITY.FALSE_COMPLETION')}</TangiNotification>} */}
        </CollapseContainer>
      </Collapse>
    </>
  );
};
