import NewChip from '_components/NewChip/NewChip';
import { TangiSvgIcon } from '_components/TangiLibrary';
import styled from 'styled-components';

import { NEUTRAL_SHADES, PRIMARY_SHADES, SECONDARY_SHADES, SPACING } from 'utils/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 240px;
  width: 298px;
  border-radius: 8px;
  border: 1px solid ${NEUTRAL_SHADES[300]};
  background-color: ${NEUTRAL_SHADES.WHITE};
  padding: ${SPACING[6]} ${SPACING[3]} 0 ${SPACING[3]};
  text-align: center;
  cursor: pointer;

  :hover {
    border: 1px solid ${PRIMARY_SHADES[800]};
  }
`;

export const StyledTangiSvgIcon = styled(TangiSvgIcon)`
  margin-bottom: ${SPACING[3]};
  width: 50px;
  height: 50px;
`;

export const UpgradeLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACING[1]};
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: ${SECONDARY_SHADES[100]};
  color: ${SECONDARY_SHADES[800]};
  border-radius: 0 0 8px 8px;
`;

export const StyledNewChip = styled(NewChip)`
  position: absolute;
  top: ${SPACING[2]};
  left: ${SPACING[2]};
`;
