import Tippy from '@tippyjs/react';

import { TangiAccountStatus, TangiTypography } from '_components/TangiLibrary';
import { Avatar } from '_components/Avatar';
import { AccountSharePoint } from 'utils/types/account/account';
import { AVATAR_SIZES, NEUTRAL_SHADES } from 'utils/theme';
import { DisplayName, emailCustomTypographyStyles, TableContainer, UserRow } from '../styles';
import SectionHeader from './SectionHeader';

interface Props {
  existingUsers: AccountSharePoint[];
}

const ExistingUsers = ({ existingUsers }: Props) => {
  return (
    <TableContainer>
      <SectionHeader type="existing" count={existingUsers.length} />
      {existingUsers.map((account) => (
        <UserRow key={account._id}>
          <Tippy content={<TangiTypography color={NEUTRAL_SHADES.WHITE}>{account.user.email}</TangiTypography>} placement="top-start">
            <span>
              <TangiTypography customStyles={emailCustomTypographyStyles}>{account.user.email}</TangiTypography>
            </span>
          </Tippy>
          <DisplayName>
            <Avatar size={AVATAR_SIZES.XS} name={account?.displayName || account?.user?.fullName || account.user.email} />
            <TangiTypography weight="semibold">{account?.displayName || account?.user?.fullName || account.user.email}</TangiTypography>
          </DisplayName>
          <TangiTypography weight="semibold">{account.role.name}</TangiTypography>
          <TangiAccountStatus status={account.status} />
        </UserRow>
      ))}
    </TableContainer>
  );
};

export default ExistingUsers;
