/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: Fix any types
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';

import {
  acceptInvitation,
  batchEmployees,
  createMultipleUsers,
  createUser,
  deactivateAccount,
  deleteAccount,
  getRoles,
  getUniqueCountries,
  reactivateAccount,
  sendAlias,
  updateFullName,
  updateUser,
  validateEmployees,
} from 'redux-toolkit/thunks/usersThunks';
import { AcceptInvitationResponse, Account, AccountMinimalData } from 'utils/types/account/account';
import { Role, RolesResponse } from 'utils/types/role/role';
import { RESULT_STATUS } from 'utils/enums';
import { ToastProps } from 'utils/types/types';
import { FILTERS_ID } from '_components/PeopleFilters/types';

interface usersState {
  error: string;
  status: string;
  loading: boolean;
  batchEmployees: any[];
  batchErrors: any[];
  uploadedBatchEmployees: any[];
  validatedBatch: boolean;
  batchLoading: boolean;
  invitedAccount: null | Account | AccountMinimalData;
  updateAccountStatusStatus: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  updateAccountStatusError: Object; //TODO: Fix the type
  peopleToastProps: ToastProps;
  invitedMultipleAccounts: any[];
  inviteMultipleLoading: boolean;
  inviteMultipleError: any;
  acceptInviteError: string;
  peoplePage: {
    filters: {
      options: { roles: Role[]; countries: string[]; dateOfJoining: [] };
      selectedOptions: FilterState;
      searchValue: string;
    };
  };
}

interface FilterValue {
  name: string;
  id: string;
}
interface DateRange {
  from: Date;
  to?: Date;
}

// Define types for different filters if necessary
type FilterType = string[] | FilterValue[] | DateRange;

interface FilterState {
  [key: string]: FilterType;
}
const initialState: usersState = {
  error: '',
  status: '',
  loading: false,
  batchEmployees: [],
  batchErrors: [],
  uploadedBatchEmployees: [],
  validatedBatch: false,
  batchLoading: false,
  invitedAccount: null,
  updateAccountStatusStatus: '',
  updateAccountStatusError: {},
  peopleToastProps: { show: false, text: '', type: RESULT_STATUS.BLANK },
  invitedMultipleAccounts: [],
  inviteMultipleLoading: false,
  inviteMultipleError: null,
  acceptInviteError: '',
  peoplePage: {
    filters: {
      options: {
        roles: [],
        countries: [],
        dateOfJoining: [],
      },
      selectedOptions: {
        status: [],
        dateOfJoining: [],
        compliantProgress: [],
        trainingProgress: [],
        roleId: [],
        country: [],
        businessUnit: [],
        department: [],
      },
      searchValue: '',
    },
  },
};

const usersSlice = createSlice({
  name: 'usersData',
  initialState,
  reducers: {
    setClearInvitedMultiple(state) {
      state.invitedMultipleAccounts = [];
    },
    setInvitedMultipleAccounts(state, action) {
      state.invitedMultipleAccounts = action.payload;
    },
    setPeopleToastProps(state, action: PayloadAction<ToastProps>) {
      state.peopleToastProps = action.payload;
    },
    setError(state, action: any) {
      state.error = action.error.message;
    },
    setDateRange(state, action) {
      state.peoplePage.filters.selectedOptions.dateOfJoining = action.payload.dateOfJoining;
    },
    resetDateRange(state) {
      state.peoplePage.filters.selectedOptions.dateOfJoining = [];
    },
    resetFilters(state) {
      state.peoplePage.filters.selectedOptions = {
        status: [],
        compliantProgress: [],
        trainingProgress: [],
        dateOfJoining: [],
        roleId: [],
        country: [],
        businessUnit: [],
        department: [],
      };
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.peoplePage.filters.searchValue = action.payload;
    },
    clearSearchQuery(state) {
      state.peoplePage.filters.searchValue = '';
    },
    updateFilter(state, action: PayloadAction<{ id: string; selectedItems: FilterType }>) {
      const { id, selectedItems } = action.payload;
      const isDateFilter = id === FILTERS_ID.HIRE_DATE && !Array.isArray(selectedItems);

      if (isDateFilter) {
        if (!selectedItems.from) {
          return;
        }
        const formattedFromDate = formatISO(selectedItems.from, { representation: 'date' });
        const formattedToDate = selectedItems.to ? formatISO(selectedItems.to, { representation: 'date' }) : formattedFromDate;

        state.peoplePage.filters.selectedOptions[id] = [
          { name: 'From', id: formattedFromDate },
          { name: 'To', id: formattedToDate },
        ];
      } else {
        state.peoplePage.filters.selectedOptions[id] = selectedItems;
      }
    },
    setClearUser(state) {
      state.error = '';
      state.status = '';
      state.loading = false;
      state.updateAccountStatusStatus = '';
      state.updateAccountStatusError = {};
    },
    setResetBatchEmployees(state) {
      state.validatedBatch = false;
      state.batchEmployees = [];
      state.batchErrors = [];
      state.uploadedBatchEmployees = [];
    },
  },
  extraReducers: (builder) => {
    // createMultipleUsers
    builder.addCase(createMultipleUsers.pending, (state) => {
      state.inviteMultipleLoading = true;
      state.invitedMultipleAccounts = [];
      state.inviteMultipleError = null;
    });
    builder.addCase(createMultipleUsers.fulfilled, (state, action: any) => {
      state.invitedMultipleAccounts = action.payload;
      state.inviteMultipleLoading = false;
    });
    builder.addCase(createMultipleUsers.rejected, (state, action: any) => {
      state.inviteMultipleError = action.error.message;
      state.invitedMultipleAccounts = [];
      state.inviteMultipleLoading = false;
    });

    // updateFullName
    builder.addCase(updateFullName.pending, () => {});
    builder.addCase(updateFullName.fulfilled, () => {});
    builder.addCase(updateFullName.rejected, (state, action: any) => {
      state.error = action.error.message;
    });

    // acceptInvitation
    builder.addCase(acceptInvitation.pending, (state) => {
      state.invitedAccount = null;
      state.loading = true;
      state.acceptInviteError = '';
    });
    builder.addCase(acceptInvitation.fulfilled, (state, action: PayloadAction<AcceptInvitationResponse>) => {
      if (action.payload?.account) {
        state.invitedAccount = action.payload.account;
      } else {
        state.invitedAccount = null;
        state.acceptInviteError = 'Unknown server error';
      }
      state.loading = false;
    });
    builder.addCase(acceptInvitation.rejected, (state, action: PayloadAction<unknown, string, any, SerializedError>) => {
      state.acceptInviteError = action?.error?.message ?? 'Unknown server error';
      state.loading = false;
    });

    // sendAlias
    builder.addCase(sendAlias.pending, () => {});
    builder.addCase(sendAlias.fulfilled, () => {});
    builder.addCase(sendAlias.rejected, (state, action: any) => {
      state.error = action.error.message;
    });

    // deactivateAccount
    builder.addCase(deactivateAccount.pending, (state) => {
      state.status = '';
      state.loading = true;
      state.updateAccountStatusError = {};
    });
    builder.addCase(deactivateAccount.fulfilled, (state, action: any) => {
      state.updateAccountStatusStatus = action.payload;
      state.updateAccountStatusError = {};
      state.loading = false;
    });
    builder.addCase(deactivateAccount.rejected, (state, action: any) => {
      state.updateAccountStatusError = action.error.message;
      state.updateAccountStatusStatus = '';
      state.loading = false;
    });

    // reactivateAccount
    builder.addCase(reactivateAccount.pending, (state) => {
      state.status = '';
      state.loading = true;
      state.updateAccountStatusError = {};
    });
    builder.addCase(reactivateAccount.fulfilled, (state, action: any) => {
      state.updateAccountStatusStatus = action.payload;
      state.updateAccountStatusError = {};
      state.loading = false;
    });
    builder.addCase(reactivateAccount.rejected, (state, action: any) => {
      state.updateAccountStatusError = action.error.message;
      state.updateAccountStatusStatus = '';
      state.loading = false;
    });

    // deleteAccount
    builder.addCase(deleteAccount.pending, (state) => {
      state.status = '';
      state.loading = true;
      state.updateAccountStatusError = {};
    });
    builder.addCase(deleteAccount.fulfilled, (state, action: any) => {
      state.updateAccountStatusStatus = action.payload;
      state.updateAccountStatusError = {};
      state.loading = false;
    });
    builder.addCase(deleteAccount.rejected, (state, action: any) => {
      state.updateAccountStatusError = action.error.message;
      state.updateAccountStatusStatus = '';
      state.loading = false;
    });

    // batchEmployees
    builder.addCase(batchEmployees.pending, (state) => {
      state.batchLoading = true;
      state.uploadedBatchEmployees = [];
    });
    builder.addCase(batchEmployees.fulfilled, (state, action: any) => {
      state.batchLoading = false;
      state.uploadedBatchEmployees = action.payload;
    });
    builder.addCase(batchEmployees.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.batchLoading = false;
      state.uploadedBatchEmployees = [];
    });

    // validateEmployees
    builder.addCase(validateEmployees.pending, (state) => {
      state.batchLoading = true;
      state.batchEmployees = [];
      state.batchErrors = [];
      state.validatedBatch = false;
    });
    builder.addCase(validateEmployees.fulfilled, (state, action: any) => {
      state.batchEmployees = action.payload.employees;
      state.batchErrors = action.payload.errors;
      state.validatedBatch = true;
      state.batchLoading = false;
    });
    builder.addCase(validateEmployees.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.batchEmployees = [];
      state.batchErrors = [];
      state.validatedBatch = false;
      state.batchLoading = false;
    });

    // createUser
    builder.addCase(createUser.pending, (state) => {
      state.error = '';
      state.status = '';
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action: PayloadAction<string | null>) => {
      state.loading = false;
      if (action.payload) {
        state.status = action.payload;
      }
    });
    builder.addCase(createUser.rejected, (state, action: any) => {
      state.error = action.payload?.message;
      state.status = '';
      state.loading = false;
    });

    // updateUser
    builder.addCase(updateUser.pending, (state) => {
      state.error = '';
      state.status = '';
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action: PayloadAction<string | null>) => {
      state.loading = false;
      if (action.payload) {
        state.status = action.payload;
      }
    });
    builder.addCase(updateUser.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.status = '';
      state.loading = false;
    });
    //Filters section
    builder.addCase(getRoles.pending, (state) => {
      state.error = '';
      state.loading = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action: PayloadAction<RolesResponse | null>) => {
      state.loading = false;
      if (action.payload) {
        state.peoplePage.filters.options.roles = action.payload.results;
      }
    });
    builder.addCase(getRoles.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(getUniqueCountries.pending, (state) => {
      state.error = '';
      state.loading = true;
    });
    builder.addCase(getUniqueCountries.fulfilled, (state, action: PayloadAction<string[] | null>) => {
      state.loading = false;
      if (action.payload) {
        state.peoplePage.filters.options.countries = action.payload;
      }
    });
    builder.addCase(getUniqueCountries.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;
