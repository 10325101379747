import styled from 'styled-components';
import { IpAuditQuickStartStyle } from './types';
import { SPACING } from 'utils/theme';

export const Container = styled.div<{ $styleByRole: IpAuditQuickStartStyle }>`
  background-color: ${({ $styleByRole }) => $styleByRole.backgroundColor};
  border: 1px solid ${({ $styleByRole }) => $styleByRole.borderColor};
  border-radius: ${SPACING[2]};
  padding: ${SPACING[4]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING[3]};
`;
