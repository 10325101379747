
export const MAX_WORDS = 1000;
export const MIN_WORDS = 15;
export const OPTIMAL_WORDS = 50;

/**
 * Counts the number of words in a given text string.
 * Words are defined as sequences of characters separated by whitespace.
 * Leading and trailing whitespace is ignored.
 *
 * @param {string} text The text string to count words in.
 * @return {number} The number of words in the text string.
 */
export const countWordsInText = (text: string): number => {
  const splitPattern = /\s+/; // Regex to match one or more whitespace characters
  return text
    .trim()
    .split(splitPattern)
    .filter((word) => word).length;
};
