import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BUTTON_VARIANTS } from 'utils/componentUtils';
import { TangiButton, TangiIconButton, TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Divider, StyledModal } from 'utils/globalStyles';
import { StyledModalHeader, StyledModalBody, StyledModalFooter, TitleContainer, IconAndText, CheckboxContainer, CheckboxInput, CheckboxText, BodyImage, BodyText } from './style';
import { patentXray } from '../../assets/img';

import { NEUTRAL_SHADES } from 'utils/theme';
import { updateSeenXrayModal } from 'redux-toolkit/thunks/authThunks';
import { RootState } from '_helpers';
import { saveStateToLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants/authConstants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const XrayModal = ({ onClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const user = useSelector((state: RootState) => state.authentication.user);

  const handleClose = () => {
    saveStateToLocalStorage(authConstants.SEEN_XRAY_MAPPING, true);
    saveStateToLocalStorage(authConstants.SEEN_XRAY_EXTRACTION, true);
    onClose();
  };

  const handleDontShowAgain = () => {
    saveStateToLocalStorage(authConstants.SEEN_XRAY_MAPPING, true);
    saveStateToLocalStorage(authConstants.SEEN_XRAY_EXTRACTION, true);

    if (dontShowAgain && user) {
      const data = {
        userId: user._id,
        seenXray: {
          [authConstants.SEEN_XRAY_MAPPING]: true,
          [authConstants.SEEN_XRAY_EXTRACTION]: true,
        },
      };

      dispatch(updateSeenXrayModal(data));
    }

    onClose();
  };

  const renderModalHeader = () => {
    return (
      <StyledModalHeader>
        <TitleContainer>
          <IconAndText>
            <TangiSvgIcon component="discovery" />
            <TangiTypography type="heading-lg">{t('PATENT_MAPPING_TOOL.POPUP.HOW_TO_USE_THIS_FEATURE')}</TangiTypography>
          </IconAndText>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={handleClose} icon="close" data-testid="close-button" />
        </TitleContainer>
      </StyledModalHeader>
    );
  };

  const renderModalBody = (): JSX.Element => {
    return (
      <StyledModalBody data-testid="XrayModal-body">
        <BodyImage>
          <img width="100%" alt="mapping" src={patentXray} />
        </BodyImage>
        <BodyText>
          <div>
            <TangiTypography type="heading-md" weight="bold">
              {t('PATENT_MAPPING_TOOL.POPUP.WAIT_HOW_CAN')}
            </TangiTypography>
            <TangiTypography>{t('PATENT_MAPPING_TOOL.POPUP.MAPPING_TEXT')}</TangiTypography>
          </div>
          <div>
            <TangiTypography type="heading-md" weight="bold">
              {t('PATENT_MAPPING_TOOL.POPUP.MAPPING_ANALYSIS')}
            </TangiTypography>
            <TangiTypography>{t('PATENT_MAPPING_TOOL.POPUP.EXTRACTION_TEXT')}</TangiTypography>
          </div>
        </BodyText>
      </StyledModalBody>
    );
  };

  const renderModalFooter = () => {
    return (
      <StyledModalFooter>
        <CheckboxContainer>
          <CheckboxInput
            data-testid="checkbox-button"
            type="checkbox"
            onChange={(e) => {
              setDontShowAgain(e.target.checked);
            }}
          />
          <CheckboxText>
            <TangiTypography color={NEUTRAL_SHADES[900]}>{t('PATENT_MAPPING_TOOL.POPUP.DONT_SHOW_THIS_AGAIN')}</TangiTypography>
          </CheckboxText>
        </CheckboxContainer>
        <div>
          <TangiButton text={t('PATENT_MAPPING_TOOL.POPUP.OK_I_GET_IT')} onClick={handleDontShowAgain} loading={false} data-testid="ok-button" />
        </div>
      </StyledModalFooter>
    );
  };

  return (
    <StyledModal size="lg" transition="Fade" show={isOpen} onHide={handleClose} data-testid="xRayModal">
      {renderModalHeader()}
      <Divider />
      {renderModalBody()}
      <Divider />
      {renderModalFooter()}
    </StyledModal>
  );
};

export default XrayModal;
