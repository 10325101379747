import { Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const StyledAccountNav = styled(Nav)`
  padding-left: ${SPACING[4]};
  .dropdown-toggle.nav-link {
    padding: ${SPACING[0]};
  }
`;

export const NavTitle = styled.div`
  display: flex;
  font-size: ${SPACING[3]};
  justify-content: center;
  align-items: center;
  gap: ${SPACING[2]};
`;

export const AccountsTitle = styled.div`
  padding: ${SPACING[0]} ${SPACING[3]};
  align-items: flex-start;
`;

export const UserAccountsContainer = styled.div`
  max-height: 370px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: ${NEUTRAL_SHADES.WHITE};
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${NEUTRAL_SHADES[600]};
    border-radius: 5px;
  }
`;
