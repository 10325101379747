import { IconContainer, StyledSelectedNum } from '_components/TangiLibrary/TangiMultiSelect/style';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { t } from 'i18next';

import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { Option, SelectedFilter, SelectedOptions, FilterData } from '../types';
import { FilterButton, StyledArrownDownIcon, StyledDropdownItem, StyledSelectedItem } from './style';
import { DropDownItemContainer } from '../style';

import SelectedFilterDropDown from './SelectedFilterDropDown';

interface Props {
  options: Option[];
  filter: FilterData;
  onChange: (selectedItems: SelectedFilter[], id: string) => void;
  isClearAll: boolean;
}
const MoreFilterDropDown = ({ options, filter, onChange, isClearAll }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
  const [selectedFilter, setSelectedFilter] = useState<Option | null>(null);

  const [show, setShow] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShow(false);
      setShowFilterOptions(false);
    }
  };

  useEffect(() => {
    setSelectedOptions({});
    setSelectedFilter(null);
  }, [isClearAll]);

  useEffect(() => {
    const updateEventListener = () => {
      if (show) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };

    updateEventListener();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);

  const handleSelect = (event: { preventDefault: () => void; stopPropagation: () => void }, option: Option) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedFilter(option);
    setShow(true);
    setShowFilterOptions(true);
  };

  const countActiveFilters = () => {
    return Object.values(selectedOptions).reduce((count, options) => {
      return options.length > 0 ? count + 1 : count;
    }, 0);
  };

  const isSelectedOptionsEmpty = (selectedOptions: SelectedOptions) => {
    if (Object.keys(selectedOptions).length === 0) return true;
    return Object.values(selectedOptions).every((option) => Array.isArray(option) && option.length === 0);
  };

  const handleToggle = () => {
    setShow((prev) => !prev);
    setShowFilterOptions(false);
  };

  const CustomToggle = React.forwardRef<HTMLButtonElement, { onClick?: React.MouseEventHandler<HTMLButtonElement>; show: boolean }>(({ onClick, show }, ref) => (
    <FilterButton
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      $isSelected={!isSelectedOptionsEmpty(selectedOptions)}
      show={show}
      data-testid="filter-button"
    >
      {countActiveFilters() > 0 ? (
        <>
          <StyledSelectedItem>{t('ASSET_PAGE.FILTERS.MORE_FILTERS')}</StyledSelectedItem>
          <StyledSelectedNum>{` / ${countActiveFilters()}`}</StyledSelectedNum>
        </>
      ) : (
        <span>{t('ASSET_PAGE.FILTERS.MORE_FILTERS')}</span>
      )}
      <IconContainer>
        <StyledArrownDownIcon $isSelected={show} />
      </IconContainer>
    </FilterButton>
  ));

  return (
    <div>
      <Dropdown onToggle={handleToggle} show={show} drop="down" align={{ lg: 'start' }} ref={dropdownRef}>
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-custom-components"
          onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
            setShow((prev) => !prev);
            setShowFilterOptions(false);
            e.stopPropagation();
          }}
          show={show}
        />

        {showFilterOptions ? (
          <SelectedFilterDropDown
            selectedFilter={selectedFilter}
            filter={filter}
            onChange={onChange}
            setSelectedOptions={setSelectedOptions}
            setShowFilterOptions={setShowFilterOptions}
            setShow={setShow}
          />
        ) : (
          <Dropdown.Menu>
            {options.map((option: Option) => {
              const isOptionsSelected = selectedOptions[option.id]?.length > 0;
              const selectedColor = isOptionsSelected ? PRIMARY_SHADES[800] : 'inherit';
              return (
                <StyledDropdownItem
                  data-testid={`more-filters-item`}
                  key={option.id}
                  isSelected={isOptionsSelected}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => handleSelect(event, option)}
                  eventKey={option.id}
                >
                  <DropDownItemContainer>
                    <div style={{ display: 'flex' }}>
                      <TangiTypography color={selectedColor}>{option.displayName}</TangiTypography>
                      {isOptionsSelected && (
                        <StyledSelectedNum>
                          <TangiTypography color={selectedColor}>{`/ ${selectedOptions[option.id]?.length}`}</TangiTypography>
                        </StyledSelectedNum>
                      )}
                    </div>
                    <TangiSvgIcon component="arrowSideRight" color={NEUTRAL_SHADES.BLACK} />
                  </DropDownItemContainer>
                </StyledDropdownItem>
              );
            })}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

export default MoreFilterDropDown;
