export const acknowledgementConstants = {
  OPEN_CLOSE_ACKNOWLEDGEMENT_MODAL: 'OPEN_CLOSE_ACKNOWLEDGEMENT_MODAL',
  SET_TOAST_PROPS: 'SET_TOAST_PROPS',

  NEEDTOBE_ACKNOWLEDGED_SUCCESS: 'NEEDTOBE_ACKNOWLEDGED_SUCCESS',

  ACKNOWLEDGEMENT_REQUESTS: 'ACKNOWLEDGEMENT_REQUESTS:',
  ACKNOWLEDGEMENT_SUCCESS: 'ACKNOWLEDGEMENT_SUCCESS',
  ACKNOWLEDGEMENT_FAILURE: 'ACKNOWLEDGEMENT_FAILURE',

  CLEAR_ACKNOWLEDGED_ASSETS: 'CLEAR_ACKNOWLEDGED_ASSETS',

  SEEN_ACKNOWLEDGEMENT_REQUESTS: 'seenAcknowledgementRequests',
};
