import styled from 'styled-components';
import { FlexContainer } from 'utils/globalStyles';
import { SPACING } from 'utils/theme';

export const Container = styled(FlexContainer)`
  flex-direction: column;
  gap: ${SPACING[4]};
`;

export const SubTitleAndDescription = styled(FlexContainer)`
  flex-direction: column;
`;

export const breakWordStyles = `
  white-space: pre-line;
`;
