import { apiCall } from './utils/restClient';
import { config } from '../../config/config';
import { headers } from './utils/headers';

function getAdminCourse(page, searchTxt, sorting, limit = 10) {
  var search = searchTxt;
  var sortBy = sorting?.field ? `${sorting.field}:${sorting.order}` : 'title:asc';
  var url = `${config.apiUrl}${config.apiVersion}/courses`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
    params: { page, limit, sortBy, search },
  };

  return apiCall(requestOptions);
}

function deleteAdminCourse(courseID) {
  var url = `${config.apiUrl}${config.apiVersion}/course/${courseID}`;
  const requestOptions = {
    url: url,
    method: 'DELETE',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function updateAdminCourse(id, data) {
  var url = `${config.apiUrl}${config.apiVersion}/course/${id}`;
  const requestOptions = {
    url: url,
    method: 'PUT',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

function getAdminCourseById(courseID) {
  var url = `${config.apiUrl}${config.apiVersion}/course/${courseID}`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function getAdminCategory(page) {
  var url = `${config.apiUrl}${config.apiVersion}/categorys`;
  const requestOptions = {
    url: url,
    method: 'GET',
    headers: headers(),
  };

  return apiCall(requestOptions);
}

function createAdminCourse(data) {
  var url = `${config.apiUrl}${config.apiVersion}/course`;

  const requestOptions = {
    url: url,
    method: 'POST',
    headers: headers(),
    data: data,
  };

  return apiCall(requestOptions);
}

export const adminCourseService = {
  getAdminCourse,
  getAdminCategory,
  createAdminCourse,
  getAdminCourseById,
  deleteAdminCourse,
  updateAdminCourse,
};
