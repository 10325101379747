import { WithIsAssetCreated } from './types/xRay/xRay';

/**
 * Adds an `isAssetCreated` property set to `false` to each object in an array.
 *
 * @template T - A generic parameter that extends object, representing the type of objects in the array.
 * @param {T[]} items - An array of objects of type T.
 * @returns {WithIsAssetCreated<T>[]} An array of objects of type T extended with the `isAssetCreated` property.
 *
 */
export const addIsAssetCreatedToPotentialTS = <T extends object>(items: T[]): WithIsAssetCreated<T>[] => {
  return items.map((item: T) => ({
    ...item,
    isAssetCreated: false,
  }));
};
