import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiTypography, TangiSvgIcon, TangiDropdownButton, TANGI_BUTTON_SIZES, TangiToast, IconTypes, WeightType } from '_components/TangiLibrary';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import { RootState } from '_helpers';
import { clientActions } from 'redux-toolkit/slices/clientSlice';
import { deleteLlmPolicy, deletePolicy, downloadPolicy, uploadFiles, uploadLlmFiles } from 'redux-toolkit/thunks/clientThunks';
import { UPLOAD_FILE_TYPES, ENTITY_TYPES, MIME_TYPES, RESULT_STATUS } from 'utils/enums';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { DropdownItemContainer, customDropdownStyle } from './style';
import { DeletePolicyParams, POLICIES, PolicyFileDetails, PolicyType, UploadFilesParams } from 'utils/types/client/client';
import { IpAuditStepKey } from '_components/IpAudit/types';
import { generateOptionsSection } from './utils';

interface Props {
  hasPolicyUploadPermissions: boolean;
  isTradeSecretFileExist: boolean;
  isLlmFileExist: boolean;
  isLawyer: boolean;
  activeClientId: string;
}

export interface IPolicyDropDownItemOptions {
  text: string;
  color: string;
  componentIcon?: IconTypes;
  isDisabled: boolean;
  weight?: WeightType;
  handleItem?: () => void | JSX.Element | undefined;
}

export interface UploadFileModalState {
  isOpen: boolean;
  policyType: PolicyType | null;
}

export interface GenerateOptionsSectionArgs {
  hasUploadPermissions: boolean;
  policyType: PolicyType;
  isFileExist: boolean;
  handleDownLoadOption: (policy: PolicyType) => void;
  setUploadFileModalData: Dispatch<SetStateAction<UploadFileModalState>>;
}

const uploadFileModalInitialState = { isOpen: false, policyType: null };

const PolicyDropDown = ({ hasPolicyUploadPermissions, isTradeSecretFileExist, isLlmFileExist, isLawyer, activeClientId = '' }: Props) => {
  const [uploadFileModalData, setUploadFileModalData] = useState<UploadFileModalState>(uploadFileModalInitialState);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { clientId } = useParams<{ clientId: string }>();

  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const { policyLoader: isUploadingOrDeleting, policyToastProps, isDownloadingPolicy } = useSelector((state: RootState) => state.client);

  useEffect(() => {
    return () => {
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.TRADE_SECRET_POLICY));
      dispatch(clientActions.clearIpAuditAction(IpAuditStepKey.LLM_POLICY));
    };
  }, []);

  const acceptedFilesList = Object.values(MIME_TYPES).join(', ');

  const handleDeletePolicy = useCallback(
    (isDeleteBeforeUpload?: boolean) => {
      const { policyType } = uploadFileModalData;
      if (!policyType) return;
      const params: DeletePolicyParams = {
        isLawyer,
        clientId: clientId ?? activeClientId,
        accountId: activeAccount._id,
        type: policyType,
        isDeleteBeforeUpload,
      };
      if (policyType === POLICIES.TRADE_SECRET) {
        dispatch(deletePolicy(params));
      } else if (policyType === POLICIES.LLM) {
        dispatch(deleteLlmPolicy(params));
      }
    },
    [uploadFileModalData.policyType],
  );

  const handleUploadFile = useCallback(
    (file: File[]) => {
      const { policyType } = uploadFileModalData;
      const isFileExists = policyType && (policyType === POLICIES.TRADE_SECRET ? isTradeSecretFileExist : isLlmFileExist);
      if (isFileExists) {
        const isDeleteBeforeUpload = !!file.length;
        handleDeletePolicy(isDeleteBeforeUpload);
      }
      if (file.length && policyType) {
        const params: UploadFilesParams = {
          clientId: clientId ?? activeClientId,
          policyFiles: file,
          type: policyType,
          isLawyer,
          accountId: activeAccount._id,
          entityType: ENTITY_TYPES.ACCOUNTS,
        };
        if (policyType === POLICIES.TRADE_SECRET) {
          dispatch(uploadFiles(params));
        } else if (policyType === POLICIES.LLM) {
          dispatch(uploadLlmFiles(params));
        }
      }
      setUploadFileModalData(uploadFileModalInitialState);
    },
    [uploadFileModalData.policyType],
  );

  const handleDownLoadOption = (policy: PolicyType) => {
    const { policyFiles, llmFiles } = isLawyer ? activeClient || {} : activeAccount?.client || {};

    const getPolicyFileName = () => {
      const files = policy === UPLOAD_FILE_TYPES.POLICY ? policyFiles : llmFiles;
      const fileExists = policy === UPLOAD_FILE_TYPES.POLICY ? isTradeSecretFileExist : isLlmFileExist;
      return fileExists ? files?.[0]?.fileName : '';
    };

    dispatch(downloadPolicy({ fileName: getPolicyFileName(), type: policy }));
  };

  const getDefaultFile = useCallback(() => {
    const { policyType } = uploadFileModalData;
    if ((!isTradeSecretFileExist && !isLlmFileExist) || !policyType) return null;

    const filesStr = policyType === POLICIES.LLM ? 'llmFiles' : 'policyFiles';

    const clientFiles = isLawyer ? activeClient?.[filesStr] : activeAccount?.client?.[filesStr];

    if ((clientFiles as unknown as PolicyFileDetails[])?.[0]?.isDeclaredNoFile) return null;

    return clientFiles;
    
  }, [uploadFileModalData]);

  const renderDropDownItem = (item: IPolicyDropDownItemOptions) => {
    return {
      text: (
        <DropdownItemContainer>
          {item?.componentIcon && <TangiSvgIcon color={item?.color} component={item.componentIcon} />}
          <TangiTypography color={item?.color} weight={item.weight}>
            {item?.text}
          </TangiTypography>
        </DropdownItemContainer>
      ),
      handleItem: item?.handleItem,
      disabled: item.isDisabled,
    };
  };

  const renderDropdownOptions = () => {
    const tradeSecretSection: IPolicyDropDownItemOptions[] = generateOptionsSection({
      hasUploadPermissions: hasPolicyUploadPermissions,
      policyType: POLICIES.TRADE_SECRET,
      isFileExist: isTradeSecretFileExist,
      handleDownLoadOption,
      setUploadFileModalData,
    });

    const llmSection: IPolicyDropDownItemOptions[] = generateOptionsSection({
      hasUploadPermissions: hasPolicyUploadPermissions,
      policyType: POLICIES.LLM,
      isFileExist: isLlmFileExist,
      handleDownLoadOption,
      setUploadFileModalData,
    });

    return [...tradeSecretSection, ...llmSection].map((item) => renderDropDownItem(item));
  };

  const isModalEditMode = useMemo(() => {
    const { policyType } = uploadFileModalData;
    if (!policyType) return false;
    return uploadFileModalData.policyType === POLICIES.TRADE_SECRET ? isTradeSecretFileExist : isLlmFileExist;
  }, [uploadFileModalData.policyType]);

  return (
    <>
      <Tippy content={t('POLICY.UPLOAD.TIP')} placement="bottom">
        <span>
          <TangiDropdownButton
            variant={BUTTON_VARIANTS.TERTIARY_GREY}
            size={TANGI_BUTTON_SIZES.MEDIUM}
            arrow={false}
            svgIcon="policyIcon"
            loading={isUploadingOrDeleting || isDownloadingPolicy}
            endIcon={true}
            options={renderDropdownOptions()}
            data-testid="policy-dropdown-button"
            smallbtn={true}
            dropdownStyles={customDropdownStyle}
          />
        </span>
      </Tippy>
      <UploadFileModal
        title={t(`POLICY.UPLOAD.${uploadFileModalData.policyType === POLICIES.TRADE_SECRET ? 'TRADE_SECRET_TITLE' : 'LLM_TITLE'}`)}
        submitButtonText={t('POLICY.UPLOAD.SAVE')}
        acceptedFilesList={acceptedFilesList}
        isOpen={uploadFileModalData.isOpen}
        onClose={() => setUploadFileModalData(uploadFileModalInitialState)}
        onSubmit={handleUploadFile}
        isEditMode={isModalEditMode}
        defaultFile={getDefaultFile()}
        phraseLineTop={t(`POLICY.${uploadFileModalData.policyType === POLICIES.TRADE_SECRET ? 'POLICY_PHRASE_TOP_TRADE_SECRET' : 'POLICY_PHRASE_TOP_LLM'}`)}
        phraseLineBottom={t('POLICY.POLICY_PHRASE_BOTTOM')}
        loading={isUploadingOrDeleting || isDownloadingPolicy}
      />
      <TangiToast
        {...policyToastProps}
        onSuccess={() => {
          dispatch(clientActions.setPolicyToastToastProps({ show: false, type: RESULT_STATUS.BLANK, text: '' }));
        }}
      />
    </>
  );
};

export { PolicyDropDown };
