import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { FlexContainer } from 'utils/globalStyles';

export const TooltipContainer = styled.div`
  padding-block: 10px;
  background: ${NEUTRAL_SHADES.WHITE};
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
  width: 300px;
`;

export const TooltipHeader = styled.div`
  padding: 0px 12px 8px;
  border-bottom: 1px solid ${NEUTRAL_SHADES[200]};
`;

export const AssetsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING[3]};
`;

export const RowContainer = styled(FlexContainer)`
  justify-content: space-between;
  padding-inline: 12px;
  .tangi-typography.asset-name {
    text-overflow: ellipsis;
    width: ${SPACING[9]};
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const DuoCenteredContainer = styled(FlexContainer)`
  align-items: center;
`;

export const AcknowledgementContainer = styled(DuoCenteredContainer)`
  cursor: default;
`;
