import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { getLawyerClients, getLawyerClient, createLawFirm } from 'redux-toolkit/thunks/lawFirmThunks';
import { lawFirmConstants } from '_constants';
import { Client } from 'utils/types/client/client';
import { LAWFIRM_CREATE_STATUS, LawyerClients } from 'utils/types/lawFirm/lawFirm';
import { loadStateFromLocalStorage, removeStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';

interface LawFirmState {
  lawyerClients: LawyerClients;
  status: string;
  error: string;
  loading: boolean;
  activeClient: Client | null;
  ipAuditCurrentStepSubmission:{
    isSubmitting: boolean;
    isSuccess: boolean;
  }
}

const initialState: LawFirmState = {
  lawyerClients: { metadata: [], data: [] },
  status: '',
  error: '',
  loading: false,
  activeClient: loadStateFromLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT),
  ipAuditCurrentStepSubmission: {
    isSubmitting: false,
    isSuccess: false,
  },
};

const lawFirmSlice = createSlice({
  name: 'lawFirmData',
  initialState,
  reducers: {
    clearLawFirmStatus(state) {
      state.status = '';
      state.error = '';
      state.loading = false;
    },
    setActiveClient(state, action: PayloadAction<Client | null>) {
      state.activeClient = action.payload;
      saveStateToLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT, action.payload);
    },
    resetActiveClient(state) {
      state.activeClient = null;
      saveStateToLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT, null);
    },
    updateActiveClientById(state, action: PayloadAction<string>) {
      const foundClient = state.lawyerClients?.data?.find((client: Client) => client.id === action.payload);
      if (foundClient) {
        state.activeClient = foundClient;
      }
    },
  },
  extraReducers: (builder) => {
    // getLawyerClients
    builder.addCase(getLawyerClients.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLawyerClients.fulfilled, (state, action: PayloadAction<LawyerClients>) => {
      state.lawyerClients = action.payload;
      state.loading = false;
    });
    builder.addCase(getLawyerClients.rejected, (state, action) => {
      state.error = action?.error?.message ?? i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
      state.lawyerClients = { metadata: [], data: [] };
      removeStateFromLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT);
      state.activeClient = null;
      state.loading = false;
    });

    // getLawyerClient
    builder.addCase(getLawyerClient.pending, (state) => {
      state.loading = true;
      state.ipAuditCurrentStepSubmission.isSubmitting = true;
      state.ipAuditCurrentStepSubmission.isSuccess = false;
    });
    builder.addCase(getLawyerClient.fulfilled, (state, action: PayloadAction<LawyerClients>) => {
      if (action?.payload?.data?.length) {
        const newClientData = action.payload.data[0];
        saveStateToLocalStorage(lawFirmConstants.ACTIVE_LAWYER_CLIENT, newClientData);
        state.activeClient = newClientData;
        const clientIndex = state.lawyerClients?.data?.findIndex((client: Client) => client.id === newClientData.id);
        clientIndex !== -1 && (state.lawyerClients.data[clientIndex] = newClientData);
      }
      state.loading = false;
    });
    builder.addCase(getLawyerClient.rejected, (state, action) => {
      state.error = action?.error?.message ?? i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
      state.loading = false;
    });

    // createLawFirm
    builder.addCase(createLawFirm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createLawFirm.fulfilled, (state) => {
      state.status = LAWFIRM_CREATE_STATUS.SUCCESS;
      state.loading = false;
    });
    builder.addCase(createLawFirm.rejected, (state, action) => {
      state.status = LAWFIRM_CREATE_STATUS.FAILED;
      state.error = action?.error?.message ?? i18next.t('GENERAL.ERROR.SOMETHING_WENT_WRONG');
      state.loading = false;
    });
  },
});

export const lawFirmActions = lawFirmSlice.actions;

export default lawFirmSlice.reducer;
