import { ScoreValues } from "../../consts";
import { ChipTextType, ChipType } from "../ContractChip/types";

export const getScoreColor = (score: number) => {
    const numericScore = score;
  
    if (numericScore > ScoreValues.SUCCESS) {
      return ChipType.SUCCESS;
    } else if (numericScore > ScoreValues.ERROR && numericScore <= ScoreValues.SUCCESS) {
      return ChipType.WARNING;
    } else {
      return ChipType.ERROR;
    }
  };

  export const getTextByScore = (score: number) => {
    const numericScore = score;
    if (numericScore > ScoreValues.SUCCESS) {
      return ChipTextType.SUCCESS;
    } else if (numericScore > ScoreValues.ERROR && numericScore <= ScoreValues.SUCCESS) {
      return ChipTextType.WARNING;
    } else {
      return ChipTextType.ERROR;
    }
  };
  