import { Fragment, forwardRef, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from 'react-bootstrap';

import WidgetCard from '../WidgetCard';
import WidgetTitle from '../WidgetTitle';
import UsedTagItem from './UsedTagItem';
import WidgetTableTitle from '../../Common/WidgetTableTitle';
import { TangiTypography } from '_components/TangiLibrary';
import { WidgetCardProps, WidgetDataObject } from '../Widgets.types';
import { Divider } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';

enum TAGS_OPTIONS {
  TAGS = 'Tags',
  BUSINESS_VALUE = 'Business Value',
}

const WidgetContainer = styled.div`
  display: flex;
  padding-block: 0;
  padding-inline: ${SPACING[3]} 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 12px;
  margin-top: 64px;
  overflow-y: auto;

  .widget-table-title {
    margin-right: ${SPACING[3]};
  }
`;

const ButtonsGroupContainer = styled.div`
  position: absolute;
  right: ${SPACING[3]};
  top: ${SPACING[3]};
`;

const StyledButton = styled(Button)`
  border: 1px solid ${NEUTRAL_SHADES[600]} !important;
  background-color: ${NEUTRAL_SHADES.WHITE} !important;
  display: flex;
  align-items: center;
  padding-inline: ${SPACING[2]} !important;
  height: 32px;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${NEUTRAL_SHADES[300]} !important;
      border: 1px solid ${NEUTRAL_SHADES[1000]} !important;
    `};
`;

const TopUsedItemsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledTagText = styled(TangiTypography)<{ disabled: boolean }>`
  color: ${(props) => (props.disabled ? NEUTRAL_SHADES.BLACK : NEUTRAL_SHADES[800])};
`;

interface Props extends WidgetCardProps {
  data: {
    tags: WidgetDataObject[];
    businessValues: WidgetDataObject[];
  };
}

const UsedTags = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [currentTag, setCurrentTag] = useState<TAGS_OPTIONS>(TAGS_OPTIONS.TAGS);
  const { t } = useTranslation();

  const widgetTitle = useMemo(() => {
    return currentTag === TAGS_OPTIONS.TAGS ? 'DASHBOARD_PAGE.WIDGETS.TOP_USED.TAGS_TITLE' : 'DASHBOARD_PAGE.WIDGETS.TOP_USED.BUSINESS_VALUE_TITLE';
  }, [currentTag]);

  const renderTagOption = (tag: TAGS_OPTIONS) => {
    return (
      <StyledButton onClick={() => setCurrentTag(tag)} disabled={currentTag === tag} variant="secondary" data-testid="top-used-switch">
        <StyledTagText type="sub-body" disabled={currentTag === tag}>
          {tag}
        </StyledTagText>
      </StyledButton>
    );
  };

  const tagsButtons = useMemo(() => {
    return (
      <ButtonsGroupContainer>
        <ButtonGroup size="sm">
          {renderTagOption(TAGS_OPTIONS.TAGS)}
          {renderTagOption(TAGS_OPTIONS.BUSINESS_VALUE)}
        </ButtonGroup>
      </ButtonsGroupContainer>
    );
  }, [currentTag]);

  const usedTagsItems = useMemo(() => {
    const dataToRender = currentTag === TAGS_OPTIONS.TAGS ? props.data.tags : props.data.businessValues;
    return dataToRender.map((item) => {
      return (
        <Fragment key={item.id}>
          <UsedTagItem {...item} />
          <Divider />
        </Fragment>
      );
    });
  }, [props.data, currentTag]);
  return (
    <WidgetCard {...props} ref={ref}>
      <>
        <WidgetTitle title={t(widgetTitle)} />
        <WidgetContainer>
          <WidgetTableTitle suffix1="COMMON.NAME" suffix2="COMMON.ASSETS" />
          {tagsButtons}
          <TopUsedItemsContainer>{usedTagsItems}</TopUsedItemsContainer>
          {props.children}
        </WidgetContainer>
      </>
    </WidgetCard>
  );
});

export default UsedTags;
