import styled from 'styled-components';
export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #00000040;
  border-radius: 5px;
`;

export const NotFoundContainer = styled.div`
  font-size: 22px;
`;
