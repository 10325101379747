import i18next from 'i18next';

import { IconTypes } from '_components/TangiLibrary/TangiSvgIcon/types';
import { File, URL } from 'utils/types/assets/assets';
import { isPartnershipDisabled } from '_components/ThirdPartyTag/utils';
import { Partnership } from 'utils/types/partnership/partnership';
import { getFileIcon } from 'utils/componentUtils';
import { getFileExtensionCard } from 'utils/fileUtils';

interface AttachmentInfo {
  attachmentName: string;
  attachmentIconType?: IconTypes;
  attachmentAdditionalAmount?: number;
  isAttachmentFile?: boolean;
  isAttachmentEmpty?: boolean;
}

interface Props {
  filesArr: File[] | undefined | null;
  urlsArr: URL[] | undefined | null;
  partnership: Partnership | undefined | null;
}

/**
 * Custom hook to get information about asset attachments.
 *
 * @param {Object} props - The properties object.
 * @param {File[]} props.filesArr - An array of file objects.
 * @param {URL[]} props.urlsArr - An array of URL objects.
 * @param {Object} [props.partnership=null] - The partnership object.
 *
 * @returns {Object} The attachment information.
 * @returns {string} attachmentName - The name of the attachment.
 * @returns {string|undefined} [attachmentIconType] - The type of the attachment icon.
 * @returns {number} [attachmentAdditionalAmount] - The additional amount of attachments.
 * @returns {boolean} [isAttachmentFile] - Indicates if the attachment is a file.
 * @returns {boolean} isAttachmentEmpty - Indicates if the attachment is empty.
 *
 * @example
 * const attachmentInfo = useAssetAttachmentsInfo({ filesArr, urlsArr, partnership });
 */
export const useAssetAttachmentsInfo = ({ filesArr, urlsArr, partnership }: Props) => {
  const getAttachmentsInfo = (): AttachmentInfo => {
    if (partnership && isPartnershipDisabled(partnership)) {
      const text = i18next.t('ASSET_PAGE.CONTENT.NO_ACCESS');
      return { attachmentName: text, isAttachmentEmpty: true };
    }

    if (!!filesArr?.length) {
      const text = i18next.t('ASSET_PAGE.CONTENT.UNKNOWN_FILE');
      return {
        attachmentName: filesArr[0]?.name || text,
        attachmentIconType: getFileIcon(getFileExtensionCard(filesArr[0]?.name || filesArr[0])),
        attachmentAdditionalAmount: Math.max(filesArr.length - 1, 0),
        isAttachmentFile: true,
      };
    }

    if (!!urlsArr?.length) {
      const text = i18next.t('ASSET_PAGE.CONTENT.A_LINK_IS_ATTACHED');
      return {
        attachmentName: urlsArr[0]?.label || text,
        attachmentIconType: 'link',
        attachmentAdditionalAmount: Math.max(urlsArr.length - 1, 0),
      };
    }

    return { isAttachmentEmpty: true, attachmentName: i18next.t('ASSET_PAGE.CONTENT.NO_FILES_OR_URLS_INCLUDED') };
  };

  return getAttachmentsInfo();
};

export default useAssetAttachmentsInfo;
