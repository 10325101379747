import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import { NEUTRAL_SHADES, SPACING, THEME_COLORS } from 'utils/theme';

export const StyledFormGroup = styled(Form.Group)`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: ${SPACING[2]};

  .toggle-switch {
    position: relative;
    width: 30px;
    display: inline-block;
    text-align: left;
  }

  .checkbox {
    display: none;
  }

  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 10px;
  }

  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }

  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 15px;
    padding: 0;
    font-weight: bold;
    box-sizing: border-box;
  }

  .inner:before {
    content: '';
    background-color: ${THEME_COLORS.PRIMARY_DEFAULT}60;
  }

  .inner:after {
    content: '';
    background-color: #bbb;
  }

  .switch {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    border-radius: 10px;
    background-color: ${NEUTRAL_SHADES.WHITE};
    box-shadow: 0px 0px 1px 1px #00000030;
    transition: all 0.3s ease-in 0s;
  }

  .checkbox:checked + .label .inner {
    margin-left: 0;
  }

  .checkbox:checked + .label .switch {
    right: 0px;
    background-color: ${THEME_COLORS.PRIMARY_DEFAULT};
    box-shadow: none;
  }

  .disabled {
    cursor: not-allowed;
  }

  .info-toggle-icon {
    margin-left: ${SPACING[2]};
    width: ${SPACING[3]};
  }
`;
