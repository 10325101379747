import { TangiTypography } from '_components/TangiLibrary';
import styled from 'styled-components';
import { NEUTRAL_SHADES, SPACING } from 'utils/theme';
import { UserItem as UserItemProps } from '../Widgets.types';

const UserItemContainer = styled.div<{ isEmpty: boolean }>`
  display: flex;
  height: 58px;
  padding: ${SPACING[3]};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${SPACING[2]};
  border: 1px solid ${NEUTRAL_SHADES[400]};
  flex: 1;
  background-color: ${(props) => (props.isEmpty ? NEUTRAL_SHADES[100] : NEUTRAL_SHADES.WHITE)};
`;

const UserItem = ({ role, amount }: UserItemProps) => {
  const isEmpty = !amount;

  return (
    <UserItemContainer isEmpty={isEmpty} data-testid="user-item">
      <TangiTypography weight="semibold" type="heading-lg" color={isEmpty ? NEUTRAL_SHADES[700] : NEUTRAL_SHADES.BLACK}>
        {amount}
      </TangiTypography>
      <TangiTypography type="sub-body" color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>
        {role}
      </TangiTypography>
    </UserItemContainer>
  );
};

export default UserItem;
