/**
 * Formats a long file name to display a shortened version with an ellipsis in the middle.
 *
 * If the provided file name's length exceeds the specified maximum length,
 * the function truncates the name by extracting characters from both the beginning
 * and the end of the file name and inserts an ellipsis in between.
 *
 * @param {string} [fileName=''] - The original file name to be formatted. Default is an empty string.
 * @param {number} maxLength - The maximum length of the formatted file name.
 * @returns {string} The formatted file name, shortened if necessary.
 */
export const formatLongFileName = (fileName: string = '', maxLength: number): string => {
  if (fileName.length > maxLength) {
    const charAmountBeforeAndAfter = maxLength % 2 === 0 ? (maxLength - 2) / 2 : (maxLength - 3) / 2;
    const beginning = fileName.slice(0, charAmountBeforeAndAfter);
    const end = fileName.slice(-charAmountBeforeAndAfter);
    return `${beginning}...${end}`;
  }
  return fileName;
};