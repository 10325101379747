import { courseConstants } from '../_constants/courseConstants';

const initialState = {
  courses: [],
  totalResults: 0,
  loading: false,
  quiz: null,
  quizLoading: false,
};

export function courseReducer(state = initialState, action) {
  switch (action.type) {
    case courseConstants.CLIENT_COURSES_REQUEST:
      return {
        ...state,
        loading: true,
        totalResults: 0,
        courses: [],
      };
    case courseConstants.CLIENT_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload[0].data,
        totalResults: action.payload[0].metadata[0].total,
        loading: false,
        // totalResults: action.payload.totalResults,
      };
    case courseConstants.CLIENT_COURSES_FAILURE:
      return {
        ...state,
        courses: [],
        loading: false,
      };
    case courseConstants.QUIZ_REQUEST:
      return {
        ...state,
        quizLoading: true,
        quiz: null,
      };
    case courseConstants.QUIZ_SUCCESS:
      return {
        ...state,
        quiz: action.payload[0],
        quizLoading: false,
      };
    case courseConstants.QUIZ_FAILURE:
      return {
        ...state,
        quiz: null,
        quizLoading: false,
      };

    default:
      return state;
  }
}
