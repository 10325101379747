import { CSSProperties, ReactNode } from 'react';
import { CardContainer } from './styles';

interface TangiCardProps {
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  height?: string;
  width?: string;
  onClick?: () => void;
}

export const TangiCard = ({className, children, style, height, width, onClick }: TangiCardProps) => {
  return (
    <CardContainer className={className} height={height} width={width} style={style} onClick={onClick}>
      {children}
    </CardContainer>
  );
};
