import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'tippy.js/dist/tippy.css';

import { InviteAccountForm } from '../InviteAccountForm/InviteAccountForm';
import { ViewEmployee } from '../ViewEmployee/ViewEmployee';
import { TangiIconButton } from '../TangiLibrary';
import { BUTTON_VARIANTS } from '../../utils/componentUtils';
import { ModalHeader } from './style';
import './ViewPeople.scss';

function ViewPeople({ showModal, viewData, mode }) {
  const [isEditMode, setIsEditMode] = useState(mode === 'edit');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [viewData]);

  const handleClose = () => {
    setShow(false);
    setIsEditMode(false);
  };

  useEffect(() => {
    setIsEditMode(mode === 'edit');
  }, [mode, show]);

  return (
    <div className={isEditMode ? 'edit-people' : 'view-people'}>
      <Modal show={show} className="view-people" onHide={handleClose} id="user-view-modal" transition="Fade" backdrop="static" data-testid="view-people-modal">
        <Modal.Header closeButton>
          <ModalHeader>
            {isEditMode && <TangiIconButton icon="arrowLeft" variant={BUTTON_VARIANTS.TERTIARY_GREY} onClick={() => setIsEditMode(false)} />}
            {viewData?.displayName}
          </ModalHeader>
        </Modal.Header>
        <Modal.Body id={isEditMode ? 'user-edit-modal-body' : 'user-view-modal-body'}>
          {isEditMode ? (
            <InviteAccountForm accountData={viewData} isEditMode={true} handleClose={handleClose} />
          ) : (
            <ViewEmployee userData={viewData} handleClose={handleClose} handleClickEdit={() => setIsEditMode(true)} />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { ViewPeople };
