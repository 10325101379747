import React, { useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { debounce, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { BodyContainer, Footer, MenuItem, MenuItemTypography, StyledCheckbox, StyledInput } from '_components/TangiLibrary/TangiMultiSelect/style';
import { TangiButton, TangiIconButton } from '_components/TangiLibrary';
import { FILTERS_ID, FilterDataItem, SelectedFilter } from '../types';
import { BUTTON_VARIANTS } from 'utils/theme';
import { BackArrowContainer, StyledItemsContainer } from './style';
import { formatNameBasedOnFilter, normalizeData } from './utils';
import { CenteredContainer } from 'utils/globalStyles';
import { Avatar } from '_components/Avatar';
import { AVATAR_SIZES } from 'utils/componentUtils';

interface Props {
  filterTitle: string;
  onChange: (selectedItems: SelectedFilter[], id: FILTERS_ID) => void;
  selectedItems: SelectedFilter[] | [];
  itemList: FilterDataItem[];
  id: FILTERS_ID;
  handleBackNavigation: () => void;
}
const GenerateFiltersDropDown = ({ filterTitle, onChange, selectedItems, itemList, id, handleBackNavigation }: Props) => {
  const [localSelectedItems, setLocalSelectedItems] = useState(selectedItems);
  const [localItemList, setLocalItemList] = useState(itemList);
  const [searchValue, setSearchValue] = useState('');
  const [show, setShow] = useState(true);
  const isShowAvatar = id === FILTERS_ID.RECIPIENTS || id === FILTERS_ID.CONTRIBUTOR;
  const { t } = useTranslation();

  const isItemSelected = (id: string) => {
    return !!localSelectedItems.find((item: SelectedFilter) => {
      return item.id === id;
    });
  };

  const debouncedSetLocalItemList = debounce((itemList: (FilterDataItem | string)[], value: string) => {
    if (isEmpty(value)) {
      setLocalItemList(normalizeData(itemList, id));
    } else {
      const filteredItemList = itemList.filter((item: FilterDataItem | string) => {
        let name: string;
        if (typeof item === 'string') {
          name = item;
        } else {
          name = item.formattedName || item.name || item.displayName || item.id;
        }
        return name.toLowerCase().includes(value.toLowerCase());
      });
      setLocalItemList(normalizeData(filteredItemList, id));
    }
  }, 300);

  const handleToggle = () => {
    if (show) {
      setLocalSelectedItems([]);
      setSearchValue('');
    } else {
      setLocalSelectedItems(selectedItems);
      setLocalItemList(itemList);
    }
    setShow((prev) => !prev);
  };

  const handleSelectRow = (item: FilterDataItem) => {
    const isFound = isItemSelected(item.id);
    if (isFound) {
      setLocalSelectedItems((state) => state.filter((obj) => obj.id !== item.id));
    } else {
      setLocalSelectedItems((state) => [...state, { name: item?.formattedName || item?.name || item.displayName || '', id: item.id }]);
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedSetLocalItemList(itemList, value);
  };

  const handleApply = () => {
    onChange(localSelectedItems, id);
    setShow(false);
    if (searchValue !== '') {
      setSearchValue('');
    }
  };

  const handleClear = () => {
    if (!isEmpty(selectedItems)) {
      onChange([], id);
    }
    setSearchValue('');
    setLocalSelectedItems([]);
    setLocalItemList(normalizeData(itemList, id));
  };

  useEffect(() => {
    setLocalItemList(normalizeData(itemList, id));
  }, [itemList]);

  return (
    <Dropdown onToggle={handleToggle} show={show} drop="down" align={{ lg: 'start' }}>
      <Dropdown.Menu>
        <BackArrowContainer>
          <TangiIconButton variant={BUTTON_VARIANTS.TERTIARY_GREY} icon="arrowSideLeft" onClick={() => handleBackNavigation()} />
          <span>{filterTitle}</span>
        </BackArrowContainer>

        <BodyContainer>
          <StyledInput
            value={searchValue}
            name="search"
            placeholder={t('ASSET_PAGE.INPUT.SEARCH_OPTION')}
            onChange={(e) => handleSearch(e.target.value)}
            className="ff-din-regular"
            data-testid="multiselect-input"
          />

          {localItemList?.length === 0 && searchValue === '' ? (
            <CenteredContainer>
              <Spinner animation="border" variant="primary" />
            </CenteredContainer>
          ) : (
            <StyledItemsContainer>
              {localItemList?.map((item: FilterDataItem, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleSelectRow(item);
                    }}
                    data-testid="multiselect-menu-item"
                  >
                    <StyledCheckbox checked={isItemSelected(item.id)} readOnly type="checkbox" />
                    <MenuItemTypography data-testid="multiselect-menu-text" className="ff-din-regular">
                      {isShowAvatar && <Avatar size={AVATAR_SIZES.XS} name={formatNameBasedOnFilter(item, id) || ''} email={id === FILTERS_ID.RECIPIENTS ? item.id : item.email || ''} />}
                      {formatNameBasedOnFilter(item, id)}
                    </MenuItemTypography>
                  </MenuItem>
                );
              })}
            </StyledItemsContainer>
          )}
        </BodyContainer>
        <Dropdown.Divider />
        <Footer>
          <div>
            <TangiButton
              variant="tertiary"
              text={t('GENERAL.BUTTONS_TEXT.CLEAR_ALL')}
              size="small"
              onClick={handleClear}
              disabled={isEmpty(localSelectedItems) && isEmpty(selectedItems)}
              data-testid="clear-filter"
            />
          </div>
          <div>
            <TangiButton text={t('GENERAL.BUTTONS_TEXT.APPLY')} size="small" onClick={handleApply} disabled={isEmpty(localSelectedItems) && isEmpty(selectedItems)} data-testid="apply-filter" />
          </div>
        </Footer>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GenerateFiltersDropDown;
