import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { TangiButton, TangiInput, TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';
import { InputContainer, PatentButtonAndInputContainer, InputRow, UploadButton } from './style';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE } from 'utils/types/xRay/xRay';
import { useDispatch, useSelector } from 'react-redux';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import useXRayJobStatus from '_hooks/useXRayJobStatus';
import { RootState } from '_helpers';
import { getTradeSecretsFromPatent } from 'redux-toolkit/thunks/patentThunks';
import { useHistory, useParams } from 'react-router-dom';
import PatentStatusNotification from '_components/PatentXRaySearch/components/PatentStatusNotification';
import { patentConstants } from '_constants';
import { removeStateFromLocalStorage } from 'utils/localStorage';
import { generateRoute } from 'routing/generateRoute';
import { AppRoutes } from 'routing/consts';

const PatentXRayContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { clientId } = useParams<{ clientId: string }>();

  const { patentData, patentModel } = useSelector((state: RootState) => state.patent);

  const history = useHistory();

  const patentJobStatus = useXRayJobStatus(
    (state: RootState) => state.patent.patentLoaders,
    (state: RootState) => state.patent.patentData,
    (state: RootState) => state.patent.patentRequestInfo,
  );

  const isDisabled = patentJobStatus === XRAY_JOB_STATUS.STARTED;

  const { register, handleSubmit, watch } = useForm({
    mode: 'all',
  });

  const onSubmit = (data: { exposurePatentId: string }) => {
    if (data.exposurePatentId) {
      dispatch(getTradeSecretsFromPatent({ patentId: data.exposurePatentId.trim(), clientId, patentModel }));
    }
  };

  const handleCancelProcess = () => {
    removeStateFromLocalStorage(patentConstants.PATENT_REQUEST_INFO);
    dispatch(patentActions.resetEntirePatentState());
  };

  return (
    <PatentButtonAndInputContainer onSubmit={handleSubmit(onSubmit)}>
      <InputRow>
        <InputContainer>
          <TangiInput
            className="patent-xray-input"
            register={register}
            name="exposurePatentId"
            placeholder={t('DISCOVERY_LOBBY.PLACEHOLDER.PATENT_XRAY')}
            label=""
            error={null}
            smallText=""
            registerRules={{}}
            disabled={isDisabled}
          />
        </InputContainer>
        <TangiButton smallbtn text={t('DISCOVERY_LOBBY.BUTTONS_TEXT.ANALYZE')} type="submit" data-testid="search-patent" onClick={null} disabled={isDisabled} />
      </InputRow>
      <TangiTypography weight="bold" color={NEUTRAL_SHADES[900]}>
        {t('DISCOVERY_LOBBY.TITLE.OR')}
      </TangiTypography>
      <UploadButton
        smallbtn
        text={t('DISCOVERY_LOBBY.BUTTONS_TEXT.UPLOAD_PATENT_APPLICATION')}
        disabled={isDisabled}
        onClick={() => {
          dispatch(patentActions.toggleUploadPatentFileModal(true));
        }}
        data-testid="upload-mapping-button"
        svgIcon="upload"
      />
      <PatentStatusNotification
        status={patentJobStatus}
        patentIdentifier={patentData?.filename || patentData?.patent_id || watch('exposurePatentId')}
        details={patentData?.details}
        resultsNum={patentData?.choice_0?.length || 0}
        onCancel={handleCancelProcess}
        onClick={() => history.push(generateRoute(AppRoutes.PATENT_X_RAY_INNER_PAGE, { clientId }))}
        patentSource={patentData?.source_type || XRAY_SOURCE_TYPE.BLANK}
      />
    </PatentButtonAndInputContainer>
  );
};

export default PatentXRayContent;
