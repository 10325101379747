import styled from 'styled-components';
import Skeleton from "react-loading-skeleton";
import { SPACING } from "utils/theme";

export const Container = styled.div`
  padding: ${SPACING[2]};
  display: flex;
  flex-direction: column;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${SPACING[2]};
  flex-grow: 1;
`;

export const SkeletonStyled = styled(Skeleton)`
  box-sizing: border-box;
  height: 25px;
  border-radius: ${SPACING[2]};
  margin-left: ${SPACING[2]};
`;

export const SkeletonCircle = styled(Skeleton)`
  height: 25px;
  width: 25px;
  border-radius: 50%;
`;