import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon } from '../../_components/TangiLibrary';
import { ButtonLabelContainer, StyledDropdownButton, StyledDropdownItem } from './style';

// The forwardRef is important
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ selection, onClick }, ref) => (
  <ButtonLabelContainer ref={ref}>
    {i18next.t('GENERAL.SORT_BY_DROPDOWN.SORT_BY')}
    <StyledDropdownButton
      className="ff-din-regular"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <span>{selection}</span>
      <TangiSvgIcon component="down" />
    </StyledDropdownButton>
  </ButtonLabelContainer>
));

const SortByDropdown = ({ onSelect, options, id }) => {
  const [selection, setSelection] = useState('');

  const { t, i18n } = useTranslation();

  const localOptions = !!options?.length
    ? options
    : [
        { label: t('GENERAL.SORT_BY_DROPDOWN.NEWEST'), value: 'newest' },
        { label: t('GENERAL.SORT_BY_DROPDOWN.RECENTLY_MODIFIED'), value: 'recentlyModified' },
      ];

  useEffect(() => {
    setSelection(localOptions[0].label);
  }, [i18n.language]);

  const handleClick = (item) => {
    setSelection(item.label);
    onSelect(item, id);
  };
  return (
    <Dropdown id={id}>
      <Dropdown.Toggle as={CustomToggle} selection={selection} />
      <Dropdown.Menu className="sortby-dropdown-menu">
        {localOptions.map((item, index) => (
          <StyledDropdownItem onClick={() => handleClick(item)} key={index} className="ff-din-regular" isActive={item.label === selection}>
            {item.label}
          </StyledDropdownItem>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SortByDropdown;
