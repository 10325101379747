import { useTranslation } from 'react-i18next';

import { TangiChip, TangiSvgIcon } from '_components/TangiLibrary';
import { CHIP_TRANSLATION_KEY } from 'pages/XRayLobby/components/XRaySearch';
import { BUTTON_VARIANTS, SECONDARY_SHADES } from 'utils/theme';
import { ChipContentContainer } from './styles';

const BetaChip = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <TangiChip
      variant={BUTTON_VARIANTS.SECONDARY}
      className={className}
      text={
        <ChipContentContainer>
          <TangiSvgIcon component="discovery" size="15px" color={SECONDARY_SHADES[800]} />
          <span>{t(CHIP_TRANSLATION_KEY.BETA)}</span>
        </ChipContentContainer>
      }
      maxWidth={130}
    />
  );
};

export default BetaChip;
