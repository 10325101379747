import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { ChipType, TangiChip, TangiSvgIcon } from '_components/TangiLibrary';
import { FlexContainer, StyledCard } from 'utils/globalStyles';
import { NEUTRAL_SHADES, SECONDARY_SHADES, SPACING } from 'utils/theme';

const IconContainer = styled.div<{ isWithChip?: boolean }>`
  ${({ isWithChip = false }) => css`
    min-width: 184px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${isWithChip && SPACING[3.5]};

    &::after {
      content: ''; /* Required for the pseudo-element to show */
      position: absolute;
      left: 220px;
      top: ${SPACING[3]};
      bottom: ${SPACING[3]}; /* Stretch the line to the bottom of the div */
      width: 1px;
      background-color: ${NEUTRAL_SHADES[400]};
    }
  `}
`;

const XRaySearchContainer = styled(StyledCard)`
  display: flex;
  gap: ${SPACING[4]};
  border: 1px solid ${NEUTRAL_SHADES[300]};
  border-radius: ${SPACING[2]};
  padding: ${SPACING[3]};
  align-items: center;
  margin-top: ${SPACING[3]};
  position: relative;
`;

const ChipContainer = styled.div`
  position: absolute;
  top: ${SPACING[2]};
  left: ${SPACING[2]};
`;

export const ChipContentContainer = styled(FlexContainer)`
  gap: ${SPACING[1]};
`;

export enum CHIP_TRANSLATION_KEY {
  NEW = 'DISCOVERY_LOBBY.CHIP.NEW',
  BETA = 'DISCOVERY_LOBBY.CHIP.BETA',
}

interface Props {
  iconComponent: ReactNode;
  children: ReactNode;
  chipTranslationKey?: CHIP_TRANSLATION_KEY;
  chipVariant?: ChipType;
}

const XRaySearch = ({ iconComponent, chipTranslationKey, children, chipVariant = 'secondary' }: Props) => {
  const { t } = useTranslation();

  const renderChip = () => {
    return (
      <ChipContainer>
        <TangiChip
          variant={chipVariant}
          text={
            <ChipContentContainer>
              <TangiSvgIcon component="discovery" size="15px" color={SECONDARY_SHADES[800]} />
              <span>{chipTranslationKey && t(chipTranslationKey)}</span>
            </ChipContentContainer>
          }
          maxWidth={130}
        />
      </ChipContainer>
    );
  };

  return (
    <XRaySearchContainer>
      <IconContainer isWithChip={Boolean(chipTranslationKey)}>
        {iconComponent}
        {chipTranslationKey && renderChip()}
      </IconContainer>
      {children}
    </XRaySearchContainer>
  );
};

export default XRaySearch;
