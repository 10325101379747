import { TangiTypography } from '_components/TangiLibrary/TangiTypography/TangiTypography';
import { useTranslation } from 'react-i18next';
import { RightSideContainer, LeftSideContainer, TitleContainer, disableTypographyMargin, OverViewCard } from './styles';
import { NEUTRAL_SHADES, SPACING, SUCCESS_SHADES } from 'utils/theme';
import { TangiSvgIcon } from '_components/TangiLibrary/TangiSvgIcon/TangiSvgIcon';
import { IpAuditStepData } from '_components/IpAudit/types';

export interface OverviewStepCardProps {
  onClickStep: () => void;
  stepData: IpAuditStepData & { index: number; description: string };
}

export const OverviewCard = ({ stepData, onClickStep }: OverviewStepCardProps) => {
  const { t } = useTranslation();
  const title = t(stepData.name);
  const description = t(stepData.description);

  return (
    <OverViewCard onClick={onClickStep}>
      <LeftSideContainer>
        <TitleContainer>
          <TangiTypography type="subheading" weight="bold" customStyles={disableTypographyMargin}>
            {title}
          </TangiTypography>
          <TangiTypography color={NEUTRAL_SHADES[800]} customStyles={disableTypographyMargin}>
            {t('IP_AUDIT.IP_AUDIT_STEPS.STEP', { INDEX: stepData.index })}
          </TangiTypography>
        </TitleContainer>
        <TangiTypography color={NEUTRAL_SHADES[900]} customStyles={disableTypographyMargin}>
          {description}
        </TangiTypography>
      </LeftSideContainer>
      <RightSideContainer>
        {stepData.isCompleted && (
          <TangiTypography color={SUCCESS_SHADES[500]} customStyles={`padding-bottom:${SPACING[0.5]}`}>
            {t('IP_AUDIT.IP_AUDIT_STEPS.COMPLETED')}
          </TangiTypography>
        )}
        <TangiSvgIcon component="arrowSideRight" color={NEUTRAL_SHADES[1100]} size="10px" />
      </RightSideContainer>
    </OverViewCard>
  );
};
