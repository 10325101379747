import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TangiMultiSelect, TangiButton } from '../TangiLibrary';
import { FiltersRowContainer, FiltersContainer } from './style';
import { statusOptions, compliantProgressOptions, renderAccountStatus, renderCompliantTraining } from './utils';
import { IRoles } from '../../utils/roles';
import { FILTERS_ID, FILTERS_PEOPLE_OPTIONS_LABELS, filtersMapping } from './types';
import PeopleMoreFilterDropDown from './PeopleMoreFilterDropDown/PeopleMoreFilterDropDown';
import { assetMetaDataEnums } from '_constants';
import { ASSET_FILTERS } from '_components/AssetFilters/types';
import { usersActions } from 'redux-toolkit/slices/usersSlice';

const PeopleFilters = ({ filter, isFilterApplied, handleClearSearch }) => {
  const { Role } = useSelector((state) => state.authentication);
  const { departments } = useSelector((state) => state.assetMetaData);
  const { t } = useTranslation();
  const [isClearAll, setIsClearAll] = useState(false);
  const dispatch = useDispatch();

  const options = useMemo(() => {
    return Object.entries(FILTERS_PEOPLE_OPTIONS_LABELS).map(([key, value]) => {
      const filterIdKey = filtersMapping[key];
      return {
        id: FILTERS_ID[filterIdKey],
        displayName: value,
      };
    });
  }, []);

  const handleClearAll = () => {
    setIsClearAll(true);
    dispatch(usersActions.resetFilters());
    handleClearSearch();
    setTimeout(() => setIsClearAll(false), 0);
  };

  const handleFilterChange = (selectedItems, id) => {
    dispatch(usersActions.updateFilter({ id, selectedItems }));
  };

  return (
    <FiltersRowContainer>
      <FiltersContainer className="people-filters" data-testid="people-filters">
        <TangiMultiSelect
          filterTitle={t('PEOPLE.SELECT.STATUS')}
          id="status"
          selectedItems={filter.status}
          itemList={statusOptions}
          onChange={handleFilterChange}
          isAccountStatusNeeded={true}
          customRenderOption={renderAccountStatus}
        />
        <TangiMultiSelect
          filterTitle={t('ASSET_PAGE.DROP_DOWN.DEPARTMENT')}
          id={assetMetaDataEnums.department}
          selectedItems={filter.department}
          itemList={[...departments, { name: t('ASSET_PAGE.DROP_DOWN.NO_DEPARTMENTS'), id: ASSET_FILTERS.NO_DEPARTMENTS }]}
          onChange={handleFilterChange}
          isSearch={true}
        />
        {Role !== IRoles.LEARN_EMPLOYEE_ADMIN && (
          <TangiMultiSelect
            filterTitle={t('PEOPLE.SELECT.AGREEMENTS')}
            id="compliantProgress"
            selectedItems={filter.compliantProgress}
            itemList={compliantProgressOptions}
            onChange={handleFilterChange}
            customRenderOption={renderCompliantTraining}
          />
        )}
        <PeopleMoreFilterDropDown options={options} filter={filter} onChange={handleFilterChange} isClearAll={isClearAll} />
        {isFilterApplied && (
          <TangiButton
            smallbtn={true}
            font={14}
            variant="tertiary"
            text={t('GENERAL.BUTTONS_TEXT.CLEAR_ALL')}
            size="small"
            onClick={handleClearAll}
            disabled={!isFilterApplied}
            onChange={handleFilterChange}
            data-testid="clear-all-filters"
          />
        )}
      </FiltersContainer>
    </FiltersRowContainer>
  );
};

export default PeopleFilters;
