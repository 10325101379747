import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Form } from 'react-bootstrap';

function NestedChoices({ nestIndex, control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions[${nestIndex}].choices`,
  });

  return (
    <div>
      <table className="table table-bordered">
        <thead className="thead-light">
          <tr>
            <th>#</th>
            <th>Choice</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody data-testid="choicesTable">
          {fields.map((item, k) => (
            <tr id="addr" key={item.id}>
              <td>{k + 1}</td>
              <td>
                <Form.Control data-testid="choice" type="text" {...register(`questions[${nestIndex}].choices[${k}].option`)} />
              </td>

              <td>
                <button className="btn btn-danger" type="button" onClick={() => remove(k)}>
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        type="button"
        data-testid="addChoice"
        onClick={() =>
          append({
            option: '',
            serialNumber: fields.length + 1,
          })
        }
        className="btn btn-secondary rounded border-black d-block u-hover-black u-focus-black"
      >
        ADD CHOICES
      </button>
      <hr />
    </div>
  );
}

export { NestedChoices };
