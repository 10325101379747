import ReactPlayer from 'react-player';

import { clientConstants } from '_constants';
import { PatentXrayCover } from 'assets/img';

const VIDEO_URL: string = `${clientConstants.S3_URL}/videos/EXTRACTION.mp4`;

const PatentXRayVideo = () => {
  return <ReactPlayer width="328px" height="184px" url={VIDEO_URL} controls light={PatentXrayCover} data-testid="patent-xray-video" />;
};

export default PatentXRayVideo;
