import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const Header = ({ headers, onSorting }) => {
  const [sortingField, setSortingField] = useState('');
  const [visibleTab, setVisibleTab] = useState(-1);
  const [sortToUp, setSortUp] = useState(false);

  const onSortingChange = (field, index) => {
    index === visibleTab ? setVisibleTab(-1) : setVisibleTab(index);
    setSortUp(!sortToUp);
    setSortingField(field);
    const order = field === sortingField && sortToUp ? 'asc' : 'desc';
    onSorting(field, order);
  };

  return (
    <thead>
      <tr>
        {headers.map(({ name, field, sortable }, index) => (
          <th key={name} onClick={() => (sortable ? onSortingChange(field, index) : null)}>
            {name}
            {sortable && <FontAwesomeIcon className="fs-6 text-muted" icon={index === visibleTab ? faSortUp : faSortDown} />}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Header;
