import { CSSProperties } from 'react';
import styled from 'styled-components';

import { FlexContainer } from 'utils/globalStyles';
import { BORDER_RADIUS, NEUTRAL_SHADES, SPACING } from 'utils/theme';

export const newIntegrationModalBodyStyle: CSSProperties = {
  padding: SPACING[0],
};

export const newIntegrationModalFooterStyle: CSSProperties = {
  padding: SPACING[3],
};

export const BodyContainer = styled(FlexContainer)`
  gap: ${SPACING[4]};
  width: 616px;
  padding: ${SPACING[4]};
  flex-direction: column;
  align-items: center;
`;

export const BodyNoteContainer = styled.div`
  padding-top: ${SPACING[3]};
  border-top: 1px solid ${NEUTRAL_SHADES[300]};
  width: 100%;
`;

export const HeaderContainer = styled(FlexContainer)`
  gap: ${SPACING[2]};
  align-items: center;
  .sp-integration-chip {
    border-radius: ${BORDER_RADIUS[44]};
  }
`;

export const ChipTextContainer = styled(FlexContainer)`
  height: ${SPACING[4.5]};
  align-items: center;
  justify-content: center;
`;

export const FooterContainer = styled(FlexContainer)`
  gap: ${SPACING[3]};
`;
