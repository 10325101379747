import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { zodResolver } from '@hookform/resolvers/zod';
import { useParams } from 'react-router-dom';

import { TangiToast, TangiModal } from '_components/TangiLibrary';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import LeaveModal from '../LeaveModal/LeaveModal';
import SharePointLoader from '../SharePointLoader/SharePointLoader';
import SharePointSecretsSkeleton from '../SharePointSecretsSkeleton/SharePointSecretsSkeleton';
import useRole from '_hooks/useRole';
import useEditModeFormReset from '_components/SharePointWizard/hooks/useEditModeFormReset';
import { useSharePointWizardContext } from '../../context/useSharePointWizardContext';
import { sharePointActions } from 'redux-toolkit/slices/sharePointSlice';
import { startSPIntegration } from 'redux-toolkit/thunks/sharePointThunks';
import { RootState, useAppDispatch } from '_helpers';
import { FormSchema, SharePointWizardStepKey } from '../../types';
import { schema } from '../../schema';
import { getWizardAsyncAction } from '../../utils/asyncActionsUtils';
import { JOB_STATUS } from 'utils/enums';
import { wizardFormDefaultValues } from '../../consts';
import { spWizardModalBodyStyle, spWizardModalContainerStyle, spWizardModalFooterStyle, spWizardModalHeaderStyle } from '../../style';

interface Props {
  isWizardOpen: boolean;
  onCloseWizard: () => void;
}

export const SharePointFormModal = ({ isWizardOpen = false, onCloseWizard }: Props) => {
  const [leaveWizardModalOpen, setLeaveWizardModalOpen] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  const {
    spJobStatus: jobStatus,
    membersLoading,
    isSPIntegrationInProgress,
    validationLoading,
    integrationDataLoading,
    stepsAsyncResults,
    integrationDataToastProps,
  } = useSelector((state: RootState) => state.sharePoint);

  const { isLawyer } = useRole();
  const { currentStep, nextStep, goToNextStep, setCurrentStepId, goToPreviousStep, currentStepIndex, isLastStep, spWizardSteps, initialStepId, isEditMode } = useSharePointWizardContext();

  const { clientId } = useParams<{ clientId: string }>();
  const dispatch = useAppDispatch();

  const methods = useForm<FormSchema>({
    mode: 'onTouched',
    criteriaMode: 'all',
    resolver: zodResolver(schema),
    defaultValues: wizardFormDefaultValues,
  });

  const watchedStepRequiredFields = useWatch({
    control: methods.control,
    name: currentStep.stepRequiredFormFields,
  });

  const nextButtonLoading = validationLoading || integrationDataLoading;
  const showLoader = (membersLoading || isSPIntegrationInProgress) && isWizardOpen;
  const showEditModeSkeleton = isEditMode && integrationDataLoading;

  // Handle async actions for the current step
  const handleAsyncAction = async (condition: boolean, stepId: SharePointWizardStepKey, formValues: FormSchema): Promise<boolean> => {
    if (!condition || stepsAsyncResults[stepId] !== false) {
      return true; // No async action required or it has already passed
    }

    try {
      const asyncAction = getWizardAsyncAction(stepId, formValues);
      if (asyncAction) {
        const result = await asyncAction(dispatch);
        unwrapResult(result);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleNextStep = async () => {
    if (!nextStep) return;

    const isStepFieldsValid = await methods.trigger(currentStep.stepRequiredFormFields);
    setIsNextDisabled(!isStepFieldsValid);

    if (!isStepFieldsValid) return;

    const formValues = methods.getValues();

    // Async validation for the current step if required
    const currentStepValidationPassed = await handleAsyncAction(!!currentStep?.requiresAsyncValidation, currentStep.id, formValues);
    if (!currentStepValidationPassed) return;

    // Additional async action for the current step if required (get members)
    const additionalActionPassed = await handleAsyncAction(!!currentStep?.requiresAdditionalAsyncAction, nextStep.id, formValues);

    if (additionalActionPassed) {
      goToNextStep(nextStep.id);
    }
  };

  // Reset async action result when moving back
  const handlePreviousStep = () => {
    setIsNextDisabled(false);

    const prevStep = spWizardSteps[currentStepIndex - 1];
    if (prevStep?.requiresAsyncValidation || prevStep?.requiresAdditionalAsyncAction) {
      dispatch(sharePointActions.resetStepAsyncResults([prevStep.id, currentStep.id]));
    }

    goToPreviousStep();
  };
  const handleResetFormToDefault = () => {
    methods.reset(wizardFormDefaultValues);
  };

  const handleWizardClose = () => {
    setCurrentStepId(initialStepId);
    dispatch(sharePointActions.resetEntireState());
    setLeaveWizardModalOpen(false);
    onCloseWizard();
    handleResetFormToDefault();
  };

  const onSubmit: SubmitHandler<FormSchema> = (data: FormSchema) => {
    dispatch(startSPIntegration({ formData: data, clientId, isLawyer, isEditMode }));
  };

  // Set "Next" button enabled as soon as any field changes
  useEffect(() => {
    setIsNextDisabled(false);
  }, [watchedStepRequiredFields]);

  // Close the wizard if the job status is succeeded or failed
  useEffect(() => {
    if (![JOB_STATUS.SUCCEEDED, JOB_STATUS.FAILED].includes(jobStatus)) return;
    handleWizardClose();
  }, [jobStatus]);

  // Reset the form to the integration data when the wizard is opened in edit mode
  useEditModeFormReset({ shouldFetch: isWizardOpen && isEditMode, resetForm: methods.reset });

  return (
    <>
      <FormProvider {...methods}>
        <TangiModal
          show={isWizardOpen}
          header={<Header />}
          onHide={() => {}}
          body={showEditModeSkeleton ? <SharePointSecretsSkeleton /> : currentStep.component}
          slotsStyles={{ bodyContainer: { style: spWizardModalBodyStyle }, headerContainer: { style: spWizardModalHeaderStyle }, footerContainer: { style: spWizardModalFooterStyle } }}
          containerStyle={spWizardModalContainerStyle}
          isCloseButton={false}
          dataTestId="sharepoint-wizard"
          footer={
            <Footer
              onCancel={() => setLeaveWizardModalOpen(true)}
              onForward={isLastStep ? methods.handleSubmit(onSubmit) : handleNextStep}
              onBack={handlePreviousStep}
              loading={nextButtonLoading}
              isNextDisabled={isNextDisabled}
            />
          }
        />
        <SharePointLoader showLoader={showLoader} />
        <LeaveModal isOpenModal={leaveWizardModalOpen} onCloseModal={() => setLeaveWizardModalOpen(false)} onConfirm={handleWizardClose} />
      </FormProvider>
      <TangiToast
        {...integrationDataToastProps}
        onError={() => {
          handleWizardClose();
        }}
      />
    </>
  );
};

export default SharePointFormModal;
