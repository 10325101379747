import styled, { css } from 'styled-components';

import { RESULT_STATUS } from 'utils/enums';
import { ERROR_SHADES, NEUTRAL_SHADES, SPACING, SUCCESS_SHADES } from 'utils/theme';

export const TypographyContainer = styled.div`
  min-height: ${SPACING[5]};
  max-height: ${SPACING[10]};
  display: flex;
  flex-direction: column;
`;

export const AcknowledgedgementBullet = styled.div<{ type: RESULT_STATUS }>`
  ${({ type }) => css`
    width: ${SPACING[2]};
    height: ${SPACING[2]};
    border-radius: 50%;
    margin-bottom: 2px;
    background: ${type === RESULT_STATUS.SUCCESS ? SUCCESS_SHADES[400] : type === RESULT_STATUS.ERROR && ERROR_SHADES[600]};
  `}
`;

export const TooltipSubtitle = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: ${SPACING[1.5]};
  align-items: center;
`;

export const TooltipDivider = styled.hr`
  width: 100%;
  border-bottom: 1px solid ${NEUTRAL_SHADES[600]};
  margin-block: ${SPACING[3]};
`;
