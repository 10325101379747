import { useTranslation } from 'react-i18next';
import { SharedBy } from 'utils/types/dashboard/dashboard';
import AvatarName from '../../Common/AvatarName';
import { TangiTypography } from '_components/TangiLibrary';
import { InsightContainer } from './Insights.styled';
import { useMemo } from 'react';
import { NEUTRAL_SHADES } from 'utils/theme';

interface Props {
  accounts: SharedBy[];
}
const MostSharedBy = ({ accounts }: Props) => {
  const isEmpty = !accounts.length;
  const { t } = useTranslation();

  const mostSharedByAccount = useMemo(() => (isEmpty ? null : accounts[0]), [accounts]);

  return (
    <InsightContainer isEmpty={isEmpty} data-testid="mostSharedBy">
      <TangiTypography color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>{t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.MOST_SHARED_BY')}</TangiTypography>
      {isEmpty ? (
        <TangiTypography color={isEmpty ? NEUTRAL_SHADES[800] : NEUTRAL_SHADES.BLACK}>{t('DASHBOARD_PAGE.WIDGETS.INSIGHTS.EMPTY')}</TangiTypography>
      ) : (
        mostSharedByAccount && (
          <AvatarName
            _id={mostSharedByAccount._id}
            displayName={mostSharedByAccount?.isActive ? mostSharedByAccount.displayName : mostSharedByAccount.user.email}
            email={mostSharedByAccount.user.email}
            isEmptyAvatar={mostSharedByAccount?.isActive ? false : true}
          />
        )
      )}
    </InsightContainer>
  );
};

export default MostSharedBy;
