export const courseProgressionsConstants = {
  CREATE_PROGRESSION_REQUEST: 'CREATE_PROGRESSION_REQUEST',
  CREATE_PROGRESSION_SUCCESS: 'CREATE_PROGRESSION_SUCCESS',
  CREATE_PROGRESSION_FAILURE: 'CREATE_PROGRESSION_FAILURE',
  EDIT_PROGRESSION_REQUEST: 'EDIT_PROGRESSION_REQUEST',
  EDIT_PROGRESSION_SUCCESS: 'EDIT_PROGRESSION_SUCCESS',
  EDIT_PROGRESSION_FAILURE: 'EDIT_PROGRESSION_FAILURE',
  GETBYUSER_PROGRESSION_REQUEST: 'GETBYUSER_PROGRESSION_REQUEST',
  GETBYUSER_PROGRESSION_SUCCESS: 'GETBYUSER_PROGRESSION_SUCCESS',
  GETBYUSER_PROGRESSION_FAILURE: 'GETBYUSER_PROGRESSION_FAILURE',
};
