import {
  adminCourseContants
} from '../_constants/adminCourseContants';

const initialState = {
  getCourse: [],
  getCategory: [],
  getCourseByID: [],
  deleteCourse: [],
  totalResults: "",
  statusCourse: "",
  statusUpdateCourse: ""
};


export function adminCourseReducer(state = initialState, action) {
  switch (action.type) {
    case adminCourseContants.ADMIN_COURSE_REQUEST:
      return {
        ...state,
        getCourse: [],
      };
    case adminCourseContants.ADMIN_COURSE_SUCCESS:
      return {
        ...state,
        getCourse: action.payload[0].data,
          totalResults: action.payload[0].metadata[0].total
      };
    case adminCourseContants.ADMIN_COURSE_FAILURE:
      return {
        ...state,
        getCourse: [],
      };

    case adminCourseContants.ADMIN_COURSE_DELETE_REQUEST:
      return {
        ...state,
        deleteCourse: [],
      };
    case adminCourseContants.ADMIN_COURSE_DELETE_SUCCESS:
      return {
        ...state,
        deleteCourse: action.payload.results,
      };
    case adminCourseContants.ADMIN_COURSE_DELETE_FAILURE:
      return {
        ...state,
        deleteCourse: [],
      };

    case adminCourseContants.ADMIN_COURSE_ID_REQUEST:
      return {
        ...state,
        getCourseByID: [],
      };
    case adminCourseContants.ADMIN_COURSE_ID_SUCCESS:
      return {
        ...state,
        getCourseByID: action.payload,
      };
    case adminCourseContants.ADMIN_COURSE_ID_FAILURE:
      return {
        ...state,
        getCourseByID: [],
      };

    case adminCourseContants.ADMIN_CATEGORY_REQUEST:
      return {
        ...state,
        getCategory: [],
      };
    case adminCourseContants.ADMIN_CATEGORY_SUCCESS:
      return {
        ...state,
        getCategory: action.payload,
      };
    case adminCourseContants.ADMIN_CATEGORY_FAILURE:
      return {
        ...state,
        getCategory: [],
      };

    case adminCourseContants.ADMIN_CREATE_COURSE:
      return {
        ...state,
        createCourse: {},
          statusCourse: ""
      };
    case adminCourseContants.ADMIN_CREATE_COURSE_SUCCESS:
      return {
        ...state,
        createCourse: action.res,
          statusCourse: "success"
      };
    case adminCourseContants.ADMIN_CREATE_COURSE_FAILURE:
      return {
        ...state,
        createCourse: {},
          errors: action.error,
          statusCourse: "failed"
      };
    case adminCourseContants.ADMIN_UPDATE_COURSE:
      return {
        ...state,
        updateCourse: {},
          statusUpdateCourse: ""
      };
    case adminCourseContants.ADMIN_UPDATE_COURSE_SUCCESS:
      return {
        ...state,
        updateCourse: action.res,
          statusUpdateCourse: "success"
      };
    case adminCourseContants.ADMIN_UPDATE_COURSE_FAILURE:
      return {
        ...state,
        updateCourse: {},
          errors: action.error,
          statusUpdateCourse: "failed"
      };
    default:
      return state;
  }
}
