import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './App.scss';
import PrivateRoute from '../routing/routeGuards/PrivateRoute';
import PublicRoute from '../routing/routeGuards/PublicRoute';
import IdleHandler from '../_hocs/IdleHandler/IdleHandler';
import VersionControlHandler from '_hocs/VersionControlHandler/VersionControlHandler';
import UnderConstruction from 'pages/UnderConstruction/UnderConstruction';
import { history } from '../_helpers';
import { publicRoutes, routes, superAdminRoutes } from 'routing/routes';
import { checkSentryEnv, sentryConfig } from '_services/utils/sentryConfig';
import { XRayResultsWrapper } from '_context/XrayResults/XRayResultsWrapper';
import { mixpanelConfig } from '_services/utils/MixPanel/mixpanelConfig';
import { config } from 'config/config';
import { useAxiosInterceptor } from '_hooks/useAxiosInterceptor';
import { AppRoutes } from 'routing/consts';

checkSentryEnv() && sentryConfig([...publicRoutes, ...routes, superAdminRoutes]);
mixpanelConfig();

function App() {
  useAxiosInterceptor();

  return (
    <div className="Canopy-App">
      <SnackbarProvider>
        <Router history={history}>
          <VersionControlHandler>
            <IdleHandler>
              <XRayResultsWrapper>
                <Switch>
                  {routes.map((route, index) => {
                    return <PrivateRoute key={`${route.path}-${index}`} exact={route.exact} path={route.path} component={route.component} permissions={route.permissions} />;
                  })}

                  {/* super admin */}
                  {superAdminRoutes.map((route, index) => {
                    return <PrivateRoute key={`${route.path}-${index}`} exact={route.exact} path={route.path} component={route.component} permissions={route.permissions} />;
                  })}

                  {publicRoutes.map((route, index) => {
                    return config.isUnderMaintenance ? (
                      <>
                        <Route path="/" component={UnderConstruction} />
                        <Redirect from={route.path} to={AppRoutes.MAIN} />
                      </>
                    ) : (
                      <PublicRoute key={`${route.path}-${index}`} exact={route.exact} path={route.path} component={route.component} unauthenticatedOnly={route?.unauthenticatedOnly} />
                    );
                  })}
                </Switch>
              </XRayResultsWrapper>
            </IdleHandler>
          </VersionControlHandler>
        </Router>
      </SnackbarProvider>
    </div>
  );
}

export { App };
