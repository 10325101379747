import React from 'react';
import { AssetDeletedContainer } from './style';
import { TangiTypography } from '_components/TangiLibrary';
import { NEUTRAL_SHADES } from 'utils/theme';

import { useTranslation } from 'react-i18next';

export const AssetDeleted = () => {
  const { t } = useTranslation();
  return (
    <AssetDeletedContainer>
      <TangiTypography color={NEUTRAL_SHADES[800]} weight="semibold" type="body">
        {t('ASSET_PAGE.CONTENT.ASSET_DELETED')}
      </TangiTypography>
    </AssetDeletedContainer>
  );
};
