import { useTranslation } from 'react-i18next';

import XRayPageLayout from '_components/XRayPageLayout/XRayPageLayout';
import PatentXRayContent from './components/PatentXRayContent/PatentXRayContent';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { useCallback, useEffect, useState } from 'react';
import { getInsights } from 'redux-toolkit/thunks/clientThunks';
import { patentActions } from 'redux-toolkit/slices/patentSlice';
import { useParams } from 'react-router-dom';
import UploadFileModal from '_components/UploadFileModal/UploadFileModal';
import XrayLobbyModal from '_components/PatentXRaySearch/components/XrayLobbyModal';
import { MIME_TYPES } from 'utils/enums';
import { uploadPatentApplication } from 'redux-toolkit/thunks/patentThunks';
import { CircleBackground, StyledTangiSvgIcon } from './styled';
import { PRIMARY_SHADES } from 'utils/theme';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'utils/localStorage';
import { authConstants } from '_constants';

const PatentXRay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isXrayModal, setIsXrayModal] = useState<boolean>(false);

  // const xRayInsights = useSelector((state: RootState) => state.client.xRayInsights);
  // const discoveryDataLoaders = useSelector((state: RootState) => state.client.discoveryDataLoaders);
  const activeAccount = useSelector((state: RootState) => state.authentication.activeAccount);

  const { clientId } = useParams<{ clientId: string }>();
  const { isModalOpen, patentModel } = useSelector((state: RootState) => state.patent);

  const loadFirstTimeLogin = () => {
    const storedState = loadStateFromLocalStorage(authConstants.FIRST_TIME_LOGIN);
    if (!storedState) saveStateToLocalStorage(authConstants.FIRST_TIME_LOGIN, { clientId: activeAccount._id, isFirstTime: true });
    return storedState?.isFirstTime;
  };

  useEffect(() => {
    const isFirstTimeLogin = loadFirstTimeLogin();
    setIsXrayModal(isFirstTimeLogin ?? true);
    Promise.all([dispatch(getInsights({ clientId })), dispatch(patentActions.setAccounts([]))]);
  }, []);

  const handleUploadFile = useCallback(
    (file: File[]) => {
      batch(() => {
        dispatch(uploadPatentApplication({ clientId, files: file, patentModel }));
        dispatch(patentActions.toggleUploadPatentFileModal(false));
      });
    },
    [clientId, dispatch, patentModel],
  );

  return (
    <XRayPageLayout
      iconComponent={
        <CircleBackground>
          <StyledTangiSvgIcon component="patentXray" size="130px" color={PRIMARY_SHADES[800]} />
        </CircleBackground>
      }
      title={t('DISCOVERY_LOBBY.TITLE.PATENT_XRAY')}
      subTitle={t('DISCOVERY_LOBBY.CARD_TEXT.WRITE_SMARTER_PATENT_APPLICATIONS')}
      // insights={
      //   discoveryDataLoaders.insightsLoading ?
      //   <XRayInsightsSkeleton numInsights={1} /> :
      //   <>
      //     <InsightCard num={xRayInsights.assetFromPatents} text={t('DISCOVERY_LOBBY.CARD_TEXT.ASSETS_FROM_PATENTS')} />
      //   </>
      // }
      infoText={t('DISCOVERY_LOBBY.INFO_TEXT.PATENT_XRAY')}
      onInfoClick={() => setIsXrayModal(true)}
    >
      <>
        <PatentXRayContent />
        <UploadFileModal
          acceptedFilesList={[MIME_TYPES.DOC, MIME_TYPES.DOCX].join(', ')}
          isOpen={isModalOpen.uploadPatentFile}
          title={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_PATENT_APPLICATION_FILE')}
          onClose={() => dispatch(patentActions.toggleUploadPatentFileModal(false))}
          onSubmit={handleUploadFile}
          submitButtonText={t('GENERAL.BUTTONS_TEXT.CONTINUE')}
          customDragNDropText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.DRAG_AND_DROP_WORD_FILE')}
          customDragNDropRejectText={t('DISCOVERY_LOBBY.UPLOAD_PATENT_APPLICATION.UPLOAD_WORD_FILE')}
        />
        <XrayLobbyModal isOpen={isXrayModal} onClose={() => setIsXrayModal(false)} />
      </>
    </XRayPageLayout>
  );
};

export default PatentXRay;
