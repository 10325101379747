import { adminUserContants } from '../_constants/adminUserContants';
import { adminUserService } from './../_services/adminUserService';

function getAdminUser(page, searchTxt, sorting) {
  function request() {
    return { type: adminUserContants.ADMIN_USER_REQUEST };
  }
  function success(payload) {
    return { type: adminUserContants.ADMIN_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: adminUserContants.ADMIN_USER_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminUserService
      .getAdminUser(page, searchTxt, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}
function adminResetPassword(email) {
  function request() {
    return { type: adminUserContants.ADMIN_RESET_PASSWORD_REQUEST };
  }

  function success(user) {
    return { type: adminUserContants.ADMIN_RESET_PASSWORD_SUCCESS, user };
  }

  function failure(error) {
    return { type: adminUserContants.ADMIN_RESET_PASSWORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    adminUserService
      .adminResetPassword(email)
      .then((user) => {
        dispatch(success(user));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function clearresetPassword() {
  return { type: adminUserContants.CLEAR_ADMIN_RESET_PASSWORD_REQUEST };
}

function getAdminAccountById(id) {
  function request() {
    return { type: adminUserContants.GET_USER_REQUEST };
  }
  function success(payload) {
    return { type: adminUserContants.GET_USER_SUCCESS, payload };
  }
  function failure() {
    return { type: adminUserContants.GET_USER_FAILURE };
  }
  return (dispatch) => {
    dispatch(request());
    adminUserService
      .getAdminAccountById(id)
      .then((user) => {
        dispatch(success(user));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminAccountsById(id) {
  function request() {
    return { type: adminUserContants.GET_USERS_BY_ID_REQUEST };
  }
  function success(payload) {
    return { type: adminUserContants.GET_USERS_BY_ID_SUCCESS, payload };
  }
  function failure(errors) {
    return { type: adminUserContants.GET_USERS_BY_ID_FAILURE, errors };
  }
  return (dispatch) => {
    dispatch(request());
    adminUserService
      .getAdminAccountsById(id)
      .then((user) => {
        dispatch(success(user));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function clearErrors() {
  return { type: adminUserContants.CLEAR_ADMIN_USER_ERRORS };
}

function createAdminUser(data) {
  const { profile } = data;
  profile && delete data.profile;

  function request() {
    return { type: adminUserContants.CREATE_ADMIN_USER };
  }
  function success(payload) {
    return { type: adminUserContants.CREATE_ADMIN_USER_SUCCESS, payload };
  }
  function failure(errors) {
    return { type: adminUserContants.CREATE_ADMIN_USER_FAILURE, errors };
  }

  function profileSuccess(payload) {
    return { type: adminUserContants.ADMIN_USER_PROFILE_SUCCESS, payload };
  }
  function profileFailure(errors) {
    return { type: adminUserContants.ADMIN_USER_PROFILE_FAILURE, errors };
  }

  // sepertion for users that will be created only on DB and users that will be created in cognito aswell.
  // let userServiceFunction;
  // if (data.externalUser) {
  //   userServiceFunction = adminUserService.createAdminExternalUser;
  // } else {
  //   userServiceFunction = adminUserService.createAdminUser;
  // }

  return (dispatch) => {
    dispatch(request());
    adminUserService
      .createAdminUser(data)
      .then((user) => {
        if (profile) {
          adminUserService
            .uploadProfile(profile, user.id)
            .then((res) => {
              dispatch(profileSuccess(res));
              dispatch(success(user));
            })
            .catch((error) => {
              dispatch(profileFailure(error));
            });
        } else {
          dispatch(success(user));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function updateAdminUser(data, accountId) {
  const { profile } = data;
  profile && delete data.profile;

  function request() {
    return { type: adminUserContants.UPDATE_ADMIN_USER };
  }
  function success(payload) {
    return { type: adminUserContants.UPDATE_ADMIN_USER_SUCCESS, payload };
  }
  function failure(errors) {
    return { type: adminUserContants.UPDATE_ADMIN_USER_FAILURE, errors };
  }

  function profileSuccess(payload) {
    return { type: adminUserContants.ADMIN_USER_PROFILE_SUCCESS, payload };
  }
  function profileFailure(errors) {
    return { type: adminUserContants.ADMIN_USER_PROFILE_FAILURE, errors };
  }

  return (dispatch) => {
    dispatch(request());
    adminUserService
      .updateAdminUser(data, accountId)
      .then((account) => {
        if (profile) {
          adminUserService
            .uploadProfile(profile, account.id)
            .then((res) => {
              dispatch(profileSuccess(res));
              dispatch(success(account));
            })
            .catch((error) => {
              dispatch(profileFailure(error));
            });
        } else {
          dispatch(success(account));
        }
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

export const adminUserActions = {
  getAdminUser,
  createAdminUser,
  clearErrors,
  getAdminAccountById,
  getAdminAccountsById,
  updateAdminUser,
  adminResetPassword,
  clearresetPassword,
};
