import { AxiosResponse } from 'axios';

import { headers } from '_services/utils/headers';
import { config } from '../../config/config';
import { HttpClientService } from '../../_services/HttpClientService';
import { LANGUAGE, LANGUAGES } from 'translations/enums';
import { UpdateSeenXrayModalResponseData, UpdateUserLanguage } from 'utils/types/auth/auth';

class AuthAdapter {
  static endpoint = `${config.apiUrl}${config.apiVersion}/auth`;
  static userEndpoint = `${config.apiUrl}${config.apiVersion}/user`;

  async confirmDisclaimer() {
    const res = await HttpClientService.post(`${config.apiUrl}${config.apiVersion}/account/confirmDisclaimer`, {}, { headers: headers() });
    return res;
  }

  async authenticate(idToken: string) {
    const res = await HttpClientService.post(`${AuthAdapter.endpoint}/authenticate`, { idToken }, { headers: headers() });
    return res.data;
  }

  async updateUserLanguage(data: UpdateUserLanguage) {
    const res = await HttpClientService.put(
      `${AuthAdapter.userEndpoint}/${data.userId}/updateLanguage`,
      { language: LANGUAGES.includes(data.language) ? data.language : LANGUAGE.ENGLISH },
      { headers: headers() },
    );
    return res.data;
  }

  async updateSeenXrayModal(userId: string, dataObj: NonNullable<unknown>): Promise<UpdateSeenXrayModalResponseData> {
    const res: AxiosResponse<UpdateSeenXrayModalResponseData> = await HttpClientService.patch(`${AuthAdapter.userEndpoint}/${userId}/updateSeenXrayModal`, dataObj, { headers: headers() });
    return res.data ?? null;
  }
}

const authAdapter = new AuthAdapter();
export default authAdapter;
