import PatentXRayVideo from '_components/PatentXRayVideo/PatentXRayVideo';
import { LOBBY_CARD_ID } from 'pages/Lobby/types';
import { MediaContainer } from './style';
import { ReactComponent as RequestManageModal } from '../../../../assets/img/requestManageModal.svg';
import { ReactComponent as RequestPredictedPortfolioModal } from '../../../../assets/img/requestPredictedPortfolioModal.svg';
import { IconTypes } from '_components/TangiLibrary';

export const pointsByCardId: Partial<Record<LOBBY_CARD_ID, string[]>> = {
  [LOBBY_CARD_ID.DISCOVER]: [`LOBBY_PAGE.REQUEST_ACCESS_MODAL.DISCOVER.POINT1`, `LOBBY_PAGE.REQUEST_ACCESS_MODAL.DISCOVER.POINT2`, `LOBBY_PAGE.REQUEST_ACCESS_MODAL.DISCOVER.POINT3`],
  [LOBBY_CARD_ID.MANAGE]: [
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.MANAGE.POINT1`,
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.MANAGE.POINT2`,
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.MANAGE.POINT3`,
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.MANAGE.POINT4`,
  ],
  [LOBBY_CARD_ID.PREDICTED_PORTFOLIO]: [
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.PREDICTED_PORTFOLIO.POINT1`,
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.PREDICTED_PORTFOLIO.POINT2`,
    `LOBBY_PAGE.REQUEST_ACCESS_MODAL.PREDICTED_PORTFOLIO.POINT3`,
  ],
};

export const getMediaContentByCardId = (cardId: LOBBY_CARD_ID): JSX.Element => {
  switch (cardId) {
    case LOBBY_CARD_ID.DISCOVER:
      return (
        <MediaContainer>
          <PatentXRayVideo />
        </MediaContainer>
      );
    case LOBBY_CARD_ID.MANAGE:
      return <RequestManageModal />;
    case LOBBY_CARD_ID.PREDICTED_PORTFOLIO:
      return <RequestPredictedPortfolioModal />;
    default:
      return <></>;
  }
};

export const getIconByCardId = (cardId?: LOBBY_CARD_ID): IconTypes => {
  switch (cardId) {
    case LOBBY_CARD_ID.PREDICTED_PORTFOLIO:
      return 'predictedPortfolio';
    case LOBBY_CARD_ID.MANAGE:
      return 'manage';
    case LOBBY_CARD_ID.DISCOVER:
    default:
      return 'discovery';
  }
};
