import React, { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as OTPAuth from 'otpauth';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { updateFullName } from 'redux-toolkit/thunks/usersThunks';
import { Loader } from '../Loader';
import { AuthContext } from '../../_hocs/Cognito/Auth';
import { TangiButton, TangiInput, TangiAlert } from '../TangiLibrary';
import { CognitoMFA } from '../CognitoMFA/CognitoMFA';
import { ResetPasswordModal } from '../ResetPasswordModal/ResetPasswordModal';
import { RegistrationCard } from '../RegistrationCard/RegistrationCard';
import PasswordChecklist from '_components/PasswordChecklist/PasswordChecklist';
import { SoftLine, SoftLinesContainer } from './styles';
import { FormContainer, Container } from '../LoginCognito/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RegisterLogin({ encodeToken }) {
  const { authenticate, associateToken, forceResetPassword } = useContext(AuthContext);
  const invitedAccount = useSelector((state) => state.users.invitedAccount);
  const [loading, setLoading] = useState(false);
  const [MFA, setMFA] = useState(false);
  const [ForceResetPassword, setForceResetPassword] = useState(false);
  const [parsedQRCodeValue, setParsedQRCodeValue] = useState('');
  const [alertProps, setAlertProps] = useState({ show: false, text: '', type: '' });
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      username: '',
      password: '',
      registerConfirmPassword: '',
      registerFullName: '',
      registerPassword: '',
    },
  });

  const onSubmit = async (data) => {
    setAlertProps({ show: false, text: '', type: '' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { username, password, registerPassword, registerConfirmPassword, registerFullName } = data;
    setLoading(true);
    try {
      if (ForceResetPassword) {
        if (registerPassword === registerConfirmPassword) {
          // dispatch(updateFullName({ userId: invitedAccount?.user?._id, fullName: registerFullName, token: encodeToken }));
          const codeChallenge = await forceResetPassword(registerPassword);
          if (codeChallenge.code === 'MFA_SETUP') {
            const secret = await associateToken();
            const totp = new OTPAuth.TOTP({
              label: `Tangibly - ${username}`,
              secret,
            });
            setParsedQRCodeValue(totp.toString());
            setMFA(true);
            setLoading(false);
          }
        } else {
          setAlertProps({ show: true, text: 'New Password and Confirm Password are not same', type: 'error' });
          setLoading(false);
        }
        return;
      }

      const res = await authenticate(username, password);
      let secret;
      let totp;
      switch (res.code) {
        case 'SOFTWARE_TOKEN_MFA':
          setMFA(true);
          setLoading(false);
          break;
        case 'MFA_SETUP':
          secret = await associateToken();
          totp = new OTPAuth.TOTP({
            label: 'Tangibly',
            secret,
          });
          setParsedQRCodeValue(totp.toString());
          setMFA(true);
          setLoading(false);
          break;
        case 'NEW_PASSWORD_REQUIRED':
          setForceResetPassword(true);
          setLoading(false);
          break;
        default:
          if (res.accessToken && res.idToken) {
            dispatch(authenticate(res.idToken.jwtToken, invitedAccount?._id));
          } else {
            setAlertProps({ show: true, type: 'error', text: 'Error occurred' });
            setLoading(false);
          }
          break;
      }
    } catch (error) {
      if (error.message.includes('not long enough')) {
        setAlertProps({ show: true, type: 'error', text: 'Your password is not secure enough. Passwords must be a minimum 12 characters.' });
      } else {
        setAlertProps({ show: true, type: 'error', text: error.message });
      }
      setLoading(false);
    }
  };
  return (
    <RegistrationCard isLockGif={MFA} differentHigh={ForceResetPassword}>
      <Container>
        <Loader isLoading={loading} />
        {MFA ? (
          <CognitoMFA QRCodeValue={parsedQRCodeValue} accountId={invitedAccount?._id} />
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <FormContainer>
              {!ForceResetPassword ? (
                <>
                  <h2 className="text-dark font-weight-normal">Login</h2>
                  <TangiInput label={t('LOGIN_PAGE.LOGIN_FORM.EMAIL')} register={register} name="username" type="text" />
                  <TangiInput label={t('LOGIN_PAGE.LOGIN_FORM.PASSWORD')} register={register} name="password" type="password" className="password-input" />
                  <TangiButton text={t('LOGIN_PAGE.LOGIN_FORM.SIGN_IN')} data-testid="sign-in-btn" type="submit" loading={loading} className="submit-button" />
                  <SoftLinesContainer>
                    <SoftLine onClick={() => setShowPasswordModal(true)}>Forgot password?</SoftLine>
                  </SoftLinesContainer>
                  <ResetPasswordModal show={showPasswordModal} handleClose={() => setShowPasswordModal(false)} />
                </>
              ) : (
                <>
                  <h2 className="text-dark font-weight-normal">Account Details</h2>
                  {/* <TangiInput label="Full Name" register={register} error={errors.registerFullName} name="registerFullName" /> */}
                  <TangiInput type="password" label="New Password" register={register} error={errors.registerPassword} name="registerPassword" />
                  <PasswordChecklist rules={['minLength', 'number', 'lowercase']} minLength={12} value={watch('registerPassword')} valueAgain={''} onChange={() => {}} />
                  <TangiInput label="Confirm Password" type="password" register={register} error={errors.registerConfirmPassword} name="registerConfirmPassword" />
                  <TangiButton data-testid="join-tangibly" type="submit" text="Join Tangibly" loading={loading} />
                </>
              )}
              <TangiAlert {...alertProps} className="error-alert-login" />
            </FormContainer>
          </Form>
        )}
      </Container>
    </RegistrationCard>
  );
}

export { RegisterLogin };
