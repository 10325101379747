/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: fix any types
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loadStateFromLocalStorage, removeStateFromLocalStorage } from 'utils/localStorage';
import { getContractResults, uploadContractApplication } from 'redux-toolkit/thunks/contractThunks';
import { ContractData, ContractRequestInfo } from 'utils/types/contract/contract';
import { XRAY_JOB_STATUS, XRAY_SOURCE_TYPE, XrayLoaders } from 'utils/types/xRay/xRay';
import { contractConstants } from '_constants';

interface ContractState {
  error: string;
  contractLoaders: XrayLoaders;
  contractData: ContractData;
  contractRequestInfo: null | ContractRequestInfo;
  isCanceled: boolean;
}

const initializeContractData = (): ContractData => {
  return {
    status: XRAY_JOB_STATUS.BLANK,
    details: '',
    created_date: '',
    source_type: XRAY_SOURCE_TYPE.BLANK,
    account: '',
    client: '',
    filename: '',
    score: 0,
    response: {
      checklist_results: {
        confidentiality_provision: false,
        trade_secret: false,
        clearly_defined: null,
        termination: null,
        returning_or_destroying: null,
        survival_clause: null,
        discovering_information: null,
        limits_on_liability: null,
        sharing_without_restrictions: null,
        mandatory_disclosure: null,
      },
      checklist_match: {
        confidentiality_provision: '',
        trade_secret: '',
        clearly_defined: null,
        termination: null,
        returning_or_destroying: null,
        survival_clause: null,
        discovering_information: null,
        limits_on_liability: null,
        sharing_without_restrictions: null,
        mandatory_disclosure: null,
      },
    },
  };
};

const initialState: ContractState = {
  error: '',
  contractLoaders: { startLoader: false, getResultLoader: false },
  contractData: initializeContractData(),
  contractRequestInfo: loadStateFromLocalStorage(contractConstants.CONTRACT_REQUEST_INFO),
  isCanceled: false,
};

const contractDataSlice = createSlice({
  name: 'contractData',
  initialState,
  reducers: {
    resetEntireState: () => {
      return {
        ...initialState,
      };
    },
    setCancelState(state) {
      state.isCanceled = !state.isCanceled;
    },
    setContractDataInitState(state) {
      state.error = '';
      state.contractLoaders = state.contractLoaders;
      state.contractData = initializeContractData();
      state.isCanceled = false;
    },
  },
  extraReducers: (builder) => {
    //upload Contract Application
    builder.addCase(uploadContractApplication.pending, (state) => {
      state.contractLoaders.startLoader = true;
    });
    builder.addCase(uploadContractApplication.fulfilled, (state, action) => {
      state.contractRequestInfo = action.payload;
      state.contractLoaders.startLoader = false;
    });
    builder.addCase(uploadContractApplication.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.contractData.status = XRAY_JOB_STATUS.FAILED;
      state.contractData.details = action.error.message;
      removeStateFromLocalStorage(contractConstants.CONTRACT_REQUEST_INFO);
      state.contractLoaders.startLoader = false;
    });

    // getContractResults
    builder.addCase(getContractResults.pending, (state) => {
      state.contractLoaders.getResultLoader = true;
    });
    builder.addCase(getContractResults.fulfilled, (state, action: PayloadAction<ContractData>) => {
      state.contractData = action.payload;
      state.contractLoaders.getResultLoader = false;
    });
    builder.addCase(getContractResults.rejected, (state, action: any) => {
      state.error = action.error.message;
      state.contractData.status = XRAY_JOB_STATUS.FAILED;
      state.contractData.details = action.error.message;
      removeStateFromLocalStorage(contractConstants.CONTRACT_REQUEST_INFO);
      state.contractLoaders.getResultLoader = false;
    });
  },
});

export const contractActions = contractDataSlice.actions;

export default contractDataSlice.reducer;
