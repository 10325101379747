import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TangiButton, TangiMultiSelect } from '../TangiLibrary';
import { FiltersRowContainer, FiltersContainer } from './style';
import { FilterButton } from '_components/TangiLibrary/TangiMultiSelect/style';
import { useState } from 'react';
import { ASSET_FILTERS, FILTERS_ID, FILTERS_OPTIONS_LABELS, filtersMapping } from './types';
import MoreFilterDropDown from './MoreFilterDropDown/MoreFilterDropDown';
import { assetMetaDataEnums } from '_constants';

const AssetFilters = ({ filter, setFilter, initialFilter, isFilterApplied, onClearAll }) => {
  const { departments, tags } = useSelector((state) => state.assetMetaData);
  const loading = useSelector((state) => state.asset.loading);
  const { t } = useTranslation();

  const [isThirdParty, setIsThirdParty] = useState(filter.thirdParty);
  const [isClearAll, setIsClearAll] = useState(false);

  const handleFilterChange = (selectedItems, id) => {
    setFilter({ ...filter, [id]: selectedItems });
  };

  const options = Object.entries(FILTERS_OPTIONS_LABELS).map(([key, value]) => {
    const filterIdKey = filtersMapping[key];
    return {
      id: FILTERS_ID[filterIdKey],
      displayName: value,
    };
  });

  const handleClearAll = () => {
    setFilter(initialFilter);
    onClearAll();
    setIsThirdParty(false);
    setIsClearAll((prev) => !prev);
  };

  return (
    <FiltersRowContainer>
      <FiltersContainer className="assets-filters" data-testid="assets-filters">
        <TangiMultiSelect
          filterTitle={t('ASSET_PAGE.DROP_DOWN.DEPARTMENT')}
          id={assetMetaDataEnums.department}
          selectedItems={filter.department}
          itemList={[...departments, { name: t('ASSET_PAGE.DROP_DOWN.NO_DEPARTMENTS'), id: ASSET_FILTERS.NO_DEPARTMENTS }]}
          onChange={handleFilterChange}
          isSearch={true}
          disabled={loading}
        />

        <TangiMultiSelect filterTitle={t('ASSET_PAGE.DROP_DOWN.TAG')} id={ASSET_FILTERS.TAG} selectedItems={filter.tag} itemList={tags} onChange={handleFilterChange} isSearch={true} />
        <FilterButton
          className="ff-din-regular"
          onClick={() => {
            setIsThirdParty((prev) => !prev);
            handleFilterChange(!isThirdParty, ASSET_FILTERS.THIRD_PARTY);
          }}
          $isSelected={isThirdParty}
          show={isThirdParty}
          data-testid="filter-button"
          disabled={loading}
        >
          {t('ASSET_PAGE.TOOLTIP.THIRD_PARTY')}
        </FilterButton>
        <MoreFilterDropDown options={options} filter={filter} onChange={handleFilterChange} disabled={loading} isClearAll={isClearAll} />
        {isFilterApplied && (
          <TangiButton
            smallbtn={true}
            font={14}
            variant="tertiary"
            text={t('ASSET_PAGE.BUTTONS_TEXT.CLEAR_ALL')}
            size="small"
            onClick={handleClearAll}
            disabled={loading}
            onChange={handleFilterChange}
            data-testid="clear-all-filters"
          />
        )}
      </FiltersContainer>
    </FiltersRowContainer>
  );
};

export default AssetFilters;
