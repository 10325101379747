export type CurrentTradeSecret = {
  text: string;
  title: string;
  index?: number;
};

export interface CreatePDFData extends CurrentTradeSecret {
  potentialAssetName?: string;
}

export type DropdownOptionCallback = (currTradeSecret: CurrentTradeSecret, index: number) => void;

export interface DropdownOption {
  text: JSX.Element;
  handleItem: DropdownOptionCallback;
}

export enum DROPDOWN_TRANSLATION_KEYS {
  TURN_INTO_ASSET = 'PATENT_TOOL.DROPDOWN_BUTTON.TURN_INTO_ASSET',
  EDIT_AND_TURN_INTO_ASSET = 'PATENT_TOOL.DROPDOWN_BUTTON.EDIT_AND_TURN_INTO_ASSET',
}
