/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from '_helpers';
import { useHistory, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { partnershipActions } from 'redux-toolkit/slices/partnershipSlice';
import { createPartnership } from 'redux-toolkit/thunks/partnershipThunks';
import FirstPage from './AddPartnerPages/FirstPage';
import SecondPage from './AddPartnerPages/SecondPage';
import { partnershipFormToApiFormat } from '_components/PartnershipForm/util';
import { lawyers } from 'utils/roles';
import { StyledModal } from 'utils/globalStyles';
import { StyledAddPartnershipForm } from './style';

const AddPartnershipModal = () => {
  const dispatch = useDispatch();
  //Params
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();
  //Redux
  const activeClient = useSelector((state: RootState) => state.lawfirm.activeClient);
  const { partnership, partnershipCreateSuccess } = useSelector((state: RootState) => state.partnership);
  const { activeAccount, Role } = useSelector((state: RootState) => state.authentication);
  const { addPartnerModalIsOpen, modalPage } = useSelector((state: RootState) => state.partnership);

  //local states
  const [logo, setLogo] = useState([]);

  const isLawyer = useMemo(() => lawyers.includes(Role), [Role]);

  /**
   * Calculates and memoizes the active client ID based on certain conditions.
   * If `clientId` is available, it is returned.
   * Otherwise, if the user's role is 'lawyer', the ID of the active client is returned.
   * Otherwise, the ID of the account's client is returned.
   *
   * @returns {string|null} The active client ID, or null if not available.
   */
  const activeClientId = useMemo(() => {
    return clientId ?? (lawyers.includes(Role) ? activeClient?.id : activeAccount?.client?.id);
  }, [clientId, lawyers, Role, activeClient, activeAccount]);

  //Form Props
  const { register, handleSubmit, setValue, control, reset } = useForm<any>({
    mode: 'all',
    defaultValues: {},
  });

  const {} = useFieldArray<any>({
    control,
    name: 'data',
  });

  //Functions
  const handleClose = () => {
    setLogo([]);
    batch(() => {
      dispatch(partnershipActions.setModalPage(1));
      dispatch(partnershipActions.changeAddModalVisibility(false));
    });
    reset();
  };

  /**
   * Handles the form submission for creating a partnership.
   *
   * @param {Object} data - The data from the partnership form.
   * @returns {Promise<void>} A Promise that resolves once the form submission is handled.
   */
  const onSubmitForm = async (data: any) => {
    let payload: any = partnershipFormToApiFormat(data);
    if (!isEmpty(logo)) {
      payload = {
        ...payload,
        logo: logo,
      };
    }
    payload = {
      ...payload,
      client: activeClientId,
    };
    dispatch(createPartnership(payload));
    handleClose();
  };

  const renderCorrectPage = () => {
    switch (modalPage) {
      case 1:
        return (
          <FirstPage
            register={register}
            control={control}
            handleClose={handleClose}
            logo={logo}
            setLogo={setLogo}
            activeClientId={activeClientId}
            clientName={isLawyer ? activeClient?.name || '' : activeAccount?.client?.name}
          />
        );
      case 2:
        return <SecondPage setValue={setValue} register={register} control={control} handleClose={handleClose} activeClientId={activeClientId} />;
      default:
        return null;
    }
  };

  //Side Effects
  useEffect(() => {
    if (partnershipCreateSuccess && activeClientId) {
      history.push(`/client/${activeClientId}/partners/${partnership._id}`);
      handleClose();
    }
  }, [partnershipCreateSuccess, activeClientId]);

  //Main Component
  return (
    <StyledModal transition="Fade" show={addPartnerModalIsOpen} data-testid="add-partnership-modal" onHide={() => {}}>
      <StyledAddPartnershipForm onSubmit={handleSubmit(onSubmitForm)}>{renderCorrectPage()}</StyledAddPartnershipForm>
    </StyledModal>
  );
};

export default AddPartnershipModal;
