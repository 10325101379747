import { Account } from '../../utils/types/account/account';

export enum AUDIT_TYPE {
  GROUP_TITLE = 'groupTitle',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  NOTIFY = 'notify',
}

export enum ENTITY_TYPES {
  ASSET = 'Asset',
}

export enum MODIFICATION_TYPE {
  ADDED = 'added',
  DELETED = 'deleted',
  UPDATED = 'updated',
}

export interface LeanAccount extends Pick<Account, '_id' | 'displayName' | 'isActive'> {
  user: { email: string };
  createdAt?: string;
  createdFrom?: string;
}

export const DATE_FORMAT_MONTH_YEAR = 'MMMM yyyy';
