import { useTranslation } from 'react-i18next';

import { TangiTypography } from '_components/TangiLibrary';
import TangiModal from '_components/TangiLibrary/TangiModal/TangiModal';

interface Props {
  isShow: boolean;
  onHide: () => void;
  bodyText: string;
}
const styleCss = { height: '500px', width: '650px' };

const SeeOriginalTextModal = ({ isShow, onHide, bodyText }: Props) => {
  const { t } = useTranslation();

  const renderHeader = () => {
    return <TangiTypography type="heading-lg">{t('INVENTION_DISCLOSURE.MODAL.TITLE')}</TangiTypography>;
  };

  return <TangiModal show={isShow} header={renderHeader()} body={bodyText} onHide={onHide} containerStyle={styleCss} dataTestId="tangiModal-invention" />;
};

export default SeeOriginalTextModal;
