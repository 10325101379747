import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompetitiveAnalysisGraphsResults, getCompetitiveAnalysisTradeSecretsTableModal, getCompetitiveAnalysisTradeSecretsExportTableModal } from 'redux-toolkit/thunks/competitiveAnalysisThunks';
import { competitiveAnalysisActions } from 'redux-toolkit/slices/competitiveAnalysisSlice';
import { isFilterApplied as checkIfFilterApplied, createFiltersForServer } from '../../PredictedPortfolio/utils';

export const useCompetitiveAnalysisModal = ({ isModalOpen, initialFilter }) => {
  const emptyFilters = {
    patents: [],
    years: [],
    subjects: [],
    companies: [],
    search: '',
  };
  const filters = { ...initialFilter, ...emptyFilters } ?? emptyFilters;
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const [filter, setFilter] = useState(filters);
  const [searchValue, setSearchValue] = useState('');
  const [enteredSearchValue, setEnteredSearchValue] = useState('');

  const pagination = useSelector((state) => state.competitiveAnalysis.modalPagination);
  const totalTableData = useSelector((state) => state.competitiveAnalysis.modalTable.metadata?.[0]?.total);

  const isFilterApplied = useMemo(() => checkIfFilterApplied(filter, searchValue), [filter, searchValue]);

  const filtersForServer = useMemo(() => {
    return createFiltersForServer(filter, enteredSearchValue);
  }, [enteredSearchValue, filter]);

  const handleClearSearch = () => {
    setSearchValue('');
    setEnteredSearchValue('');
    dispatch(competitiveAnalysisActions.setInitialModalPagination());
  };

  const handleSearch = useCallback((value) => {
    setEnteredSearchValue(value);
  }, []);

  const handleExportTable = () => {
    dispatch(getCompetitiveAnalysisTradeSecretsExportTableModal({ limit: totalTableData, page: 1, filters: filtersForServer }));
  };

  const resetModalState = () => {
    setFilter(filters);
    setSearchValue('');
    setEnteredSearchValue('');
    dispatch(competitiveAnalysisActions.setInitialModalPagination());
  };

  const setFilterByNodeSubject = (filters) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...filters,
    }));
    dispatch(competitiveAnalysisActions.setModalPagination({ page: 1, limit: 10 }));
  };

  useEffect(() => {
    setFilter(filters);
    setSearchValue('');
    setEnteredSearchValue('');
  }, [clientId]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getCompetitiveAnalysisGraphsResults());
      dispatch(competitiveAnalysisActions.setInitialModalPagination());
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(getCompetitiveAnalysisTradeSecretsTableModal({ ...pagination, filters: filtersForServer }));
    }
  }, [pagination]);

  useEffect(() => {
    if (isModalOpen) {
      dispatch(competitiveAnalysisActions.setModalPagination({ ...pagination, page: 1 }));
    }
  }, [filtersForServer]);

  return {
    searchValue,
    filter,
    initialFilter: filters,
    isFilterApplied,
    setFilter,
    setSearchValue,
    handleClearSearch,
    handleSearch,
    handleExportTable,
    resetModalState,
    setFilterByNodeSubject,
  };
};
