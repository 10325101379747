import React from 'react';
import { useTranslation } from 'react-i18next';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import StepIcon from '../StepIcon/StepIcon';
import { useSharePointWizardContext } from '../../context/useSharePointWizardContext';
import { getStepColor } from './utils';
import { NEUTRAL_SHADES } from 'utils/theme';
import { BreadcrumbsContainer, Container, typographyStyles } from './style';

const Header = () => {
  const { t } = useTranslation();
  const { currentStepIndex, spWizardSteps } = useSharePointWizardContext();

  return (
    <Container>
      <TangiTypography type="heading-lg">{t('SHARE_POINT_WIZARD.MAIN.TITLE')}</TangiTypography>
      <BreadcrumbsContainer data-testid="sp-wizard-breadcrumbs">
        {spWizardSteps.map((step, index, array) => (
          <React.Fragment key={step.id}>
            <TangiTypography type="subheading" color={getStepColor(step.index as number, currentStepIndex)} customStyles={typographyStyles}>
              <StepIcon stepIndex={step.index as number} currentStepIndex={currentStepIndex} />
              {t(`SHARE_POINT_WIZARD.${step.id}.TITLE`)}
            </TangiTypography>
            {index !== array.length - 1 && <TangiSvgIcon color={NEUTRAL_SHADES[600]} component="arrowSideRight" />}
          </React.Fragment>
        ))}
      </BreadcrumbsContainer>
    </Container>
  );
};

export default Header;
