import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import { CourseStatus, CourseContainer } from './style';
import successIcon from '../../assets/img/check-circle-solid.png';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';
// import inProgress from '../../assets/img/inProgress.svg';

export const LearnCourseCard = ({ course, status, newRequiredCourses, setNewRequiredCourses }) => {
  const { Role } = useSelector((state) => state.authentication);
  const { clientDetails } = useSelector((state) => state.client);
  const [isRequired, setIsRequired] = useState(clientDetails.requiredCourses?.includes(course.id));
  const { t } = useTranslation();

  // Checking if the course is required
  useEffect(() => {
    setIsRequired(clientDetails.requiredCourses?.includes(course.id));
  }, [clientDetails]);

  // Change required course handler - > If it exists removes it if not, adds it.
  const changeRequired = () => {
    let updatedCourses = [];
    const index = newRequiredCourses.indexOf(course.id);
    if (index === -1) {
      updatedCourses = [...newRequiredCourses, course.id];
    } else {
      updatedCourses = newRequiredCourses.slice();
      updatedCourses.splice(index, 1);
    }
    setNewRequiredCourses(updatedCourses);
  };

  return (
    <Row className="p-0 item-course bg-white border text-left rounded row mb-4 my-3">
      <Col className="px-0 col-sm-12 col-lg-4 col-xl-4">
        <div className="media-left media-top position-relative h-100 media">
          <a data-testid="player" href={`?courseid=${course.id}`} target="_self" className="text-blk hover-color">
            <ReactPlayer
              width="100%"
              height="300px"
              url={course.featuredVideoUrl}
              controls
              light
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </a>
        </div>
      </Col>
      <Col className="px-0 col-sm-12 col-lg-8 col-xl-8 d-flex flex-column">
        <div className="px-4 py-2 media-body">
          <CourseContainer className="navbar-text">
            <div className="h2 font-weight-bold media-header m-0 d-flex">
              <a href={`?courseid=${course.id}`} target="_self" className="text-blk hover-color">
                {course.title}
              </a>
              {!Role.includes('Admin') && !Role.includes('Lawyer') && isRequired && <p id="required-course">{t('LEARN_PAGE.REQUIRED')}</p>}
            </div>
            {status && (
              <CourseStatus color={status}>
                {status === 'Completed' && <img className="m-auto" width="12" height="12" alt="..." src={successIcon} />}
                <div>{status}</div>
              </CourseStatus>
            )}
          </CourseContainer>
        </div>
        <div className="p-4 pt-0 ">{course.description.length < 700 ? course.description : course.description.slice(0, 700).concat('...')}</div>
        {(Role.includes('Admin') || Role.includes('Lawyer')) && (
          <div className="p-4 mt-auto">
            <input data-testid="requiredCourse" checked={newRequiredCourses?.includes(course.id)} type="checkbox" onChange={changeRequired} /> {t('LEARN_PAGE.REQUIRED')}
          </div>
        )}
      </Col>
    </Row>
  );
};
