import { adminCourseContants } from '../_constants/adminCourseContants';
import { adminCourseService } from './../_services/adminCourseService';

function getAdminCourse(page, searchTxt, sorting) {
  function request() {
    return {
      type: adminCourseContants.ADMIN_COURSE_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: adminCourseContants.ADMIN_COURSE_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: adminCourseContants.ADMIN_COURSE_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    adminCourseService
      .getAdminCourse(page, searchTxt, sorting)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminCourseById(id) {
  function request() {
    return {
      type: adminCourseContants.ADMIN_COURSE_ID_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: adminCourseContants.ADMIN_COURSE_ID_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: adminCourseContants.ADMIN_COURSE_ID_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    adminCourseService
      .getAdminCourseById(id)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function deleteAdminCourse(page) {
  function request() {
    return {
      type: adminCourseContants.ADMIN_COURSE_DELETE_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: adminCourseContants.ADMIN_COURSE_DELETE_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: adminCourseContants.ADMIN_COURSE_DELETE_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    adminCourseService
      .deleteAdminCourse(page)
      .then((clients) => {
        dispatch(success(clients));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function getAdminCategory(page) {
  function request() {
    return {
      type: adminCourseContants.ADMIN_CATEGORY_REQUEST,
    };
  }

  function success(payload) {
    return {
      type: adminCourseContants.ADMIN_CATEGORY_SUCCESS,
      payload,
    };
  }

  function failure() {
    return {
      type: adminCourseContants.ADMIN_CATEGORY_FAILURE,
    };
  }
  return (dispatch) => {
    dispatch(request());
    adminCourseService
      .getAdminCategory(page)
      .then((categorys) => {
        dispatch(success(categorys));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function adminCreateCourse(data) {
  function request() {
    return {
      type: adminCourseContants.ADMIN_CREATE_COURSE,
    };
  }

  function success(res) {
    return {
      type: adminCourseContants.ADMIN_CREATE_COURSE_SUCCESS,
      res,
    };
  }

  function failure(error) {
    return {
      type: adminCourseContants.ADMIN_CREATE_COURSE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    adminCourseService
      .createAdminCourse(data)
      .then((course) => {
        dispatch(success(course));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

function updateAdminCourse(id, data) {
  function request() {
    return {
      type: adminCourseContants.ADMIN_UPDATE_COURSE,
    };
  }

  function success(res) {
    return {
      type: adminCourseContants.ADMIN_UPDATE_COURSE_SUCCESS,
      res,
    };
  }

  function failure(error) {
    return {
      type: adminCourseContants.ADMIN_UPDATE_COURSE_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    adminCourseService
      .updateAdminCourse(id, data)
      .then((course) => {
        dispatch(success(course));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
}

export const adminCourseActions = {
  getAdminCourse,
  getAdminCategory,
  adminCreateCourse,
  getAdminCourseById,
  deleteAdminCourse,
  updateAdminCourse,
};
