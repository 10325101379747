import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
// import Tippy from '@tippyjs/react';
import { useLocation } from 'react-router-dom';
import './AdminCourses.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../_components/Admin/DataTable/Table';
import { Loader } from '../../../_components/Loader';
import { AdminDashboard } from '../AdminDashboard';
import { AdminCoursesForm } from '../../../_components/Admin/AdminCoursesForm';
import { AgreementTable } from '../../../_components/Admin/AgreementTable';
import 'tippy.js/dist/tippy.css';
import { adminCourseActions } from '../../../AdminRedux/_actions';

function AdminCourses() {
  const [courseData, setCourseData] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const patharray = location.pathname.split('type=');
  const type = patharray && patharray[1] ? patharray[1] : '';
  const [isloading, setIsloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // const disable = false;

  const { getCourse, totalResults } = useSelector((state) => state.adminCourseReducer);
  const { sortBy } = useSelector((state) => state.clientAdmin);

  useEffect(() => {
    setIsloading(true);
    dispatch(adminCourseActions.getAdminCourse(1));
  }, [dispatch]);

  function onPageChanged(page) {
    setIsloading(true);
    setCurrentPage(page.currentPage);
    dispatch(adminCourseActions.getAdminCourse(page.currentPage, '', sortBy));
  }

  useEffect(() => {
    if (getCourse && getCourse.length > 0) {
      setIsloading(false);
      setCourseData(getCourse);
    } else {
      setIsloading(false);
      setCourseData([]);
    }
  }, [getCourse]);

  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: 'Title', field: 'date', sortable: true },
    { name: 'Difficulty', field: 'difficulty', sortable: true },
    { name: 'Client', field: 'client', sortable: false },
    // { name: 'User Signature', field: 'userSignature', sortable: true },
    { name: 'Date', field: 'date', sortable: false },
    { name: 'Actions', field: 'actions', sortable: false },
  ];

  const datatable3 = {
    columns: [
      {
        label: 'Name',
        field: 'name',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Description',
        field: 'description',
        width: 270,
      },
      {
        label: 'Slug',
        field: 'slug',
        width: 200,
      },
      {
        label: 'Count',
        field: 'count',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: [
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
      {
        name: (
          <div className="ff-din-DemiBold text-primary">
            Baker Botts Law Firm
            <div className="ff-din-regular fs-85">
              <a className="p-1" href="/edit">
                Edit
              </a>
              <a className="p-1" href="/edit">
                | Trash |
              </a>
              <a className=" p-1" href="/edit">
                View{' '}
              </a>
            </div>
          </div>
        ),
        description: <div className="ff-din-regular">Lorem Lorem Lorem Lorem Lorem</div>,
        slug: <div className="ff-din-regular">24</div>,
        count: <div className="ff-din-regular">24</div>,
      },
    ],
  };

  let adminHome;
  if (!type) {
    adminHome = (
      <div>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Courses</h6>
            </li>
          </ul>
          <div className="navbar-brand mx-2">
            <Button data-testid="newCourse" className="m-1 p-2 rounded" href="/admin/course&type=addnew" variant="outline-primary">
              Add New
            </Button>{' '}
          </div>
        </nav>
        <div className="bg-white border-0 card-header shadow-sm p-2 pl-2 rounded-3">
          <Table currentPage={currentPage} ITEMS_PER_PAGE={ITEMS_PER_PAGE} onPageChanged={onPageChanged} headers={headers} comments={courseData} totalResults={totalResults} type="course" />
          {/* <MDBDataTableV5 className="table-responsive" hover entries={10} pagesAmount={10} data={datatable} checkbox multipleCheckboxes /> */}
        </div>
      </div>
    );
  } else if (type === 'addnew') {
    adminHome = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Add New</h6>
            </li>
          </ul>
        </nav>
        <Col sm={12}>
          <AdminCoursesForm type="Add new item to Courses" />
        </Col>
      </Row>
    );
  } else if (type === 'editcourse' || type.includes('editcourse')) {
    adminHome = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Edit Course</h6>
            </li>
          </ul>
        </nav>
        <Col sm={12}>
          <AdminCoursesForm type="Edit course" />
        </Col>
      </Row>
    );
  } else if (type === 'addfirm') {
    adminHome = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Law Firm</h6>
            </li>
          </ul>
        </nav>
        <Col sm={6}>
          <AdminCoursesForm type="Add new firm" />
        </Col>
        <Col sm={6}>
          <AgreementTable datatable={datatable3} />
        </Col>
      </Row>
    );
  } else if (type === 'category') {
    adminHome = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Category</h6>
            </li>
          </ul>
        </nav>
        <Col sm={6}>
          <AdminCoursesForm type="Add new Category" />
        </Col>
        <Col sm={6}>
          <AgreementTable datatable={datatable3} />
        </Col>
      </Row>
    );
  } else if (type === 'tags') {
    adminHome = (
      <Row>
        <nav className="mb-3 navbar navbar-expand-md">
          <ul className="mr-auto navbar-nav">
            <li className="nav-item">
              <h6 className="h3 font-weight-bold text-dark m-0">Tags</h6>
            </li>
          </ul>
        </nav>
        <Col sm={6}>
          <AdminCoursesForm type="Add new Tags" />
        </Col>
        <Col sm={6}>
          <AgreementTable datatable={datatable3} />
        </Col>
      </Row>
    );
  }
  return (
    <AdminDashboard title="Courses">
      <Loader isLoading={isloading} />
      <Container fluid className="pb-8 pt-3 pt-md-8">
        {adminHome}
      </Container>
    </AdminDashboard>
  );
}

export { AdminCourses };
