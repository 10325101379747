import { CardsListDicType, LOBBY_CARD_ID } from './types';
import { createLobbyCard, createLockedCard } from './utils';

const lobbyCards = {
  predictedPortfolioCard: createLobbyCard(LOBBY_CARD_ID.PREDICTED_PORTFOLIO, 'lobbyPortoflio'),
  continuationAnalysisCard: createLobbyCard(LOBBY_CARD_ID.CONTINUATION_ANALYSIS, 'lobbyContinuationAnalysis'),
  patentXRayCard: createLobbyCard(LOBBY_CARD_ID.PATENT_X_RAY, 'lobbyPatent'),
  inventionDisclosureCard: createLobbyCard(LOBBY_CARD_ID.INVENTION_DISCLOSURE, 'lobbyInventionDisclosure'),
  manageCard: createLobbyCard(LOBBY_CARD_ID.MANAGE, 'lobbyManage'),
  assetsCatalogCard: createLobbyCard(LOBBY_CARD_ID.ASSETS_CATALOG, 'lobbyAssetsCatalog'),
  addEmployeesCard: createLobbyCard(LOBBY_CARD_ID.ADD_EMPLOYEES, 'lobbyAddEmployees'),
  collaborateWithPartnersCard: createLobbyCard(LOBBY_CARD_ID.COLLABORATE_WITH_PARTNERS, 'lobbyCollaborateWithPartners'),
  learnCard: createLobbyCard(LOBBY_CARD_ID.LEARN, 'lobbyLearn'),
  discoverCard: createLobbyCard(LOBBY_CARD_ID.DISCOVER, 'lobbyDiscovery'),
  selectCoursesCard: createLobbyCard(LOBBY_CARD_ID.SELECT_COURSES, 'lobbyLearn'),
  trackProgressCard: createLobbyCard(LOBBY_CARD_ID.TRACK_PROGRESS, 'lobbyAddEmployees'),
  competitiveIntelligenceCard: createLobbyCard(LOBBY_CARD_ID.COMPETITIVE_INTELLIGENCE, 'lobbyCompetitiveIntelligence'),
};

const {
  predictedPortfolioCard,
  continuationAnalysisCard,
  patentXRayCard,
  inventionDisclosureCard,
  manageCard,
  assetsCatalogCard,
  addEmployeesCard,
  collaborateWithPartnersCard,
  learnCard,
  discoverCard,
  selectCoursesCard,
  trackProgressCard,
} = lobbyCards;

const lobbyLockedCards = {
  predictedPortfolioCardLocked: createLockedCard(predictedPortfolioCard),
  discoverCardLocked: createLockedCard(discoverCard),
  manageCardLocked: createLockedCard(manageCard),
};

const { predictedPortfolioCardLocked, discoverCardLocked, manageCardLocked } = lobbyLockedCards;

export const ADMIN_CARDS_LIST = {
  NO_PERMISSIONS: [assetsCatalogCard, addEmployeesCard, collaborateWithPartnersCard, learnCard, predictedPortfolioCardLocked, discoverCardLocked],
  XRAY: [assetsCatalogCard, addEmployeesCard, collaborateWithPartnersCard, inventionDisclosureCard, patentXRayCard, predictedPortfolioCardLocked],
  XRAY_CONTINUATION: [assetsCatalogCard, addEmployeesCard, collaborateWithPartnersCard, continuationAnalysisCard, inventionDisclosureCard, predictedPortfolioCardLocked],
  PORTFOLIO: [predictedPortfolioCard, assetsCatalogCard, addEmployeesCard, collaborateWithPartnersCard, learnCard, discoverCardLocked],
  PORTFOLIO_XRAY: [predictedPortfolioCard, assetsCatalogCard, addEmployeesCard, collaborateWithPartnersCard, inventionDisclosureCard, patentXRayCard],
  PORTFOLIO_XRAY_CONTINUATION: [predictedPortfolioCard, assetsCatalogCard, addEmployeesCard, collaborateWithPartnersCard, continuationAnalysisCard, inventionDisclosureCard],
};

export const HR_CARDS_LIST: CardsListDicType = {
  NO_PERMISSIONS: [assetsCatalogCard, addEmployeesCard, learnCard, predictedPortfolioCardLocked, manageCardLocked, discoverCardLocked],
  PORTFOLIO: [predictedPortfolioCard, assetsCatalogCard, addEmployeesCard, learnCard, manageCardLocked, discoverCardLocked],
};

export const EMPLOYEE_CARDS_LIST: CardsListDicType = {
  NO_PERMISSIONS: [assetsCatalogCard, learnCard, predictedPortfolioCardLocked, manageCardLocked, discoverCardLocked],
  PORTFOLIO: [predictedPortfolioCard, assetsCatalogCard, learnCard, manageCardLocked, discoverCardLocked],
};

export const LEARN_ADMIN_CARDS_LIST: CardsListDicType = {
  NO_PERMISSIONS: [selectCoursesCard, trackProgressCard, predictedPortfolioCardLocked, manageCardLocked, discoverCardLocked],
  PORTFOLIO: [predictedPortfolioCard, selectCoursesCard, trackProgressCard, manageCardLocked, discoverCardLocked],
};

export const LEARN_EMPLOYEE_CARDS_LIST: CardsListDicType = {
  NO_PERMISSIONS: [learnCard, predictedPortfolioCardLocked, manageCardLocked, discoverCardLocked],
  PORTFOLIO: [predictedPortfolioCard, learnCard, manageCardLocked, discoverCardLocked],
};
