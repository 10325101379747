import styled, { css } from 'styled-components';
import { Button } from 'react-bootstrap';

import { THEME_COLORS, NEUTRAL_SHADES, SECONDARY_SHADES } from '../../../utils/theme';
import { TANGI_BUTTON_SIZES } from './TangiButton';
import { BUTTON_VARIANTS } from 'utils/theme';

export const StyledButton = styled(Button)`
  width: ${(props) => !props.$smallbtn && `100%`};
  min-width: ${({ size }) => (size ? getButtonDimensions(size) : `100px`)};
  padding-inline: ${(props) => props.$smallbtn && '10px'};
  font-family: 'DIN2014-REGULAR';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${({ font }) => (font ? font : 16)}px;
  border: none;
  border-radius: 4px;
  :focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: pink;
  }
  ${({ variant, $isActive: isActive = false }) => getVariantProps(variant, isActive)}
  ${({ size }) => getButtonDimensions(size)};
  img {
    margin-right: ${(props) => !props.$smallbtn && `12px`};
  }
`;

const getVariantProps = (variant, isActive = false) => {
  switch (variant) {
    case BUTTON_VARIANTS.SECONDARY:
      return css`
        background-color: transparent;
        border: 1px solid ${isActive ? THEME_COLORS.SECONDARY_PRESSED : THEME_COLORS.SECONDARY_DEFAULT};
        color: ${isActive ? THEME_COLORS.SECONDARY_PRESSED : THEME_COLORS.SECONDARY_DEFAULT};
        path,
        svg.tangi-icon-svg path {
          fill: ${isActive ? THEME_COLORS.SECONDARY_PRESSED : THEME_COLORS.SECONDARY_DEFAULT} !important;
        }
        :disabled {
          background-color: transparent;
          border: 1px solid ${THEME_COLORS.SECONDARY_DISABLED};
          color: ${THEME_COLORS.SECONDARY_DISABLED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.SECONDARY_DISABLED} !important;
          }
        }
        :hover:not(:disabled) {
          background-color: transparent;
          border: 1px solid ${THEME_COLORS.SECONDARY_HOVER};
          color: ${THEME_COLORS.SECONDARY_HOVER} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.SECONDARY_HOVER} !important;
          }
        }
        :focus {
          background-color: transparent !important;
          border: 1px solid ${THEME_COLORS.SECONDARY_PRESSED} !important;
          color: ${THEME_COLORS.SECONDARY_PRESSED} !important;
        }
      `;
    case BUTTON_VARIANTS.DANGER:
      return css`
        background-color: red;
        color: white !important;
        :hover {
          color: white !important;
        }
        :disabled {
          color: black !important;
        }
      `;
    case BUTTON_VARIANTS.TERTIARY:
      return css`
        background-color: ${isActive ? THEME_COLORS.TERTIARY_BACKGROUND_PRESSED : 'transparent'};
        color: ${isActive ? THEME_COLORS.TERTIARY_TEXT_PRESSED : THEME_COLORS.TERTIARY_TEXT_DEFAULT};
        &:not(:disabled) {
          path,
          svg.tangi-icon-svg path {
            fill: ${isActive ? THEME_COLORS.SECONDARY_PRESSED : THEME_COLORS.SECONDARY_DEFAULT} !important;
          }
        }
        :disabled {
          background-color: transparent;
          color: ${THEME_COLORS.TERTIARY_TEXT_DISABLED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_DISABLED};
          }
        }
        :hover:not(:disabled) {
          background-color: ${THEME_COLORS.TERTIARY_BACKGROUND_HOVER} !important;
          color: ${THEME_COLORS.TERTIARY_TEXT_HOVER} !important;
        }
        :focus {
          background-color: ${THEME_COLORS.TERTIARY_BACKGROUND_PRESSED} !important;
          color: ${THEME_COLORS.TERTIARY_TEXT_PRESSED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_PRESSED};
          }
        }
      `;
    case BUTTON_VARIANTS.TERTIARY_GREY:
      return css`
        background-color: ${isActive ? THEME_COLORS.TERTIARY_GREY_BACKGROUND_HOVER : 'transparent'};
        color: ${NEUTRAL_SHADES.BLACK} !important;
        path,
        svg.tangi-icon-svg path {
          fill: ${NEUTRAL_SHADES.BLACK} !important;
        }
        :disabled {
          background-color: transparent;
          color: ${THEME_COLORS.TERTIARY_TEXT_DISABLED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_DISABLED};
          }
        }
        :hover:not(:disabled) {
          background-color: ${THEME_COLORS.TERTIARY_GREY_BACKGROUND_HOVER} !important;
          color: ${NEUTRAL_SHADES.BLACK} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${NEUTRAL_SHADES.BLACK} !important;
          }
        }
        :focus {
          background-color: ${THEME_COLORS.TERTIARY_GREY_BACKGROUND_HOVER} !important;
          color: ${NEUTRAL_SHADES.BLACK} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${NEUTRAL_SHADES.BLACK} !important;
          }
        }
      `;
    case BUTTON_VARIANTS.PRIMARY_PURPLE:
      return css`
        background-color: ${SECONDARY_SHADES[800]};
        color: ${NEUTRAL_SHADES.WHITE} !important;
        path,
        svg.tangi-icon-svg path {
          fill: ${NEUTRAL_SHADES.WHITE};
        }
        :disabled {
          background-color: ${THEME_COLORS.PRIMARY_DISABLED};
          color: ${THEME_COLORS.PRIMARY_DISABLED_TEXT} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.PRIMARY_DISABLED_TEXT};
          }
        }
        :hover:not(:disabled) {
          background-color: ${SECONDARY_SHADES[900]};
          color: ${NEUTRAL_SHADES.WHITE} !important;
        }
        :focus {
          background-color: ${SECONDARY_SHADES[1000]};
          color: ${NEUTRAL_SHADES.WHITE} !important;
        }
      `;
    case BUTTON_VARIANTS.SECONDARY_PURPLE:
      return css`
        background-color: ${SECONDARY_SHADES[200]} !important;
        color: ${SECONDARY_SHADES[800]} !important;
        border-radius: 40px;
        padding: 8px 16px;
        path,
        svg.tangi-icon-svg path {
          fill: ${SECONDARY_SHADES[800]} !important;
        }
        :disabled {
          background-color: transparent;
          color: ${THEME_COLORS.TERTIARY_TEXT_DISABLED} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.TERTIARY_TEXT_DISABLED} !important;
          }
        }
        :hover:not(:disabled) {
          background-color: ${SECONDARY_SHADES[300]} !important;
          color: ${SECONDARY_SHADES[900]} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${SECONDARY_SHADES[900]} !important;
          }
        }
      `;
    case BUTTON_VARIANTS.PRIMARY_PURPLE:
    case BUTTON_VARIANTS.OUTLINE_DARK:
      return css`
        border: 1px solid ${NEUTRAL_SHADES[1100]};
        :hover:not(:disabled) {
          background-color: unset !important;
          color:${NEUTRAL_SHADES[1100]} !important
        }
      `;
    // primary
    default:
      return css`
        background-color: ${THEME_COLORS.PRIMARY_DEFAULT};
        color: ${NEUTRAL_SHADES.WHITE} !important;
        path,
        svg.tangi-icon-svg path {
          fill: ${NEUTRAL_SHADES.WHITE};
        }
        :disabled {
          background-color: ${THEME_COLORS.PRIMARY_DISABLED};
          color: ${THEME_COLORS.PRIMARY_DISABLED_TEXT} !important;
          path,
          svg.tangi-icon-svg path {
            fill: ${THEME_COLORS.PRIMARY_DISABLED_TEXT};
          }
        }
        :hover:not(:disabled) {
          background-color: ${THEME_COLORS.PRIMARY_HOVER};
          color: ${NEUTRAL_SHADES.WHITE} !important;
        }
        :focus {
          background-color: ${THEME_COLORS.PRIMARY_PRESSED};
          color: ${NEUTRAL_SHADES.WHITE} !important;
        }
      `;
  }
};

const getButtonDimensions = (size) => {
  switch (size) {
    case TANGI_BUTTON_SIZES.SMALL:
      return css`
        height: 32px;
        svg.tangi-icon-svg {
          height: 14px;
          width: 14px;
        }
      `;
    case TANGI_BUTTON_SIZES.MEDIUM:
      return css`
        height: 36px;
        svg.tangi-icon-svg {
          height: 18px;
          width: 18px;
        }
      `;
    case TANGI_BUTTON_SIZES.LARGE:
      return css`
        height: 40px;
      `;
    default:
      return css`
        height: 40px;
      `;
  }
};

export const ButtonChildrenContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
`;
