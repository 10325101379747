import React from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';

import { TangiSvgIcon, TangiTypography } from '_components/TangiLibrary';
import { useCopyToClipboard } from '_hooks/useCopyToClipboard';
import { NEUTRAL_SHADES, PRIMARY_SHADES } from 'utils/theme';
import { constructEmailWithAlias } from './utils';
import { AliasIdContainer, AliasLinkContainer, TooltipContent } from './style';

interface Props {
  aliasId?: string;
  aliasIdEmail?: string;
}

export const AliasIdLink = ({ aliasId = '', aliasIdEmail = '' }: Props) => {
  const { t } = useTranslation();

  const newEmail = constructEmailWithAlias(aliasIdEmail, aliasId);

  const { copyText, isCopiedText } = useCopyToClipboard(newEmail);

  const infoIcon = (
    <Tippy
      content={
        <TooltipContent>
          <TangiTypography color={NEUTRAL_SHADES.WHITE}>{t('PATENT_MAPPING_TOOL.TOOLTIP.ALIAS_ID_COPY')}</TangiTypography>
        </TooltipContent>
      }
      placement="top-end"
    >
      <div>
        <TangiSvgIcon component="info" color={NEUTRAL_SHADES[800]} size="18px" />
      </div>
    </Tippy>
  );

  return (
    <AliasIdContainer>
      <Tippy content={isCopiedText || t('PATENT_MAPPING_TOOL.TOOLTIP.COPY')} placement="auto" interactive={true} hideOnClick={false}>
        <AliasLinkContainer onClick={copyText}>
          {t('PATENT_MAPPING_TOOL.TOOLTIP.ALIAS_LINK')}
          <TangiSvgIcon color={PRIMARY_SHADES[800]} component="copy" />
        </AliasLinkContainer>
      </Tippy>
      {infoIcon}
    </AliasIdContainer>
  );
};
